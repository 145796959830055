<vs-dialog #restoreDialog [config]="{ dialogContainerClass: 'max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'refresh-ccw-01',
      iconTheme: 'primary',

      title: 'Restore ' + policy?.title || 'policy',
      subtitle: 'Are you sure you want to restore this policy? Doing so will create a new version of the policy as a draft and it will not overwrite this existing policy.'
    }"
  ></vs-dialog-header>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
        Cancel
      </button>
      <button class="flex-grow" vs-button size="md" theme="primary" [disabled]="saving" (click)="restore()">
        Restore
      </button>
    </div>
  </div>
</vs-dialog>
