import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastModule } from '@vsolv/ui-kit/toast';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import { StaffWebModule } from '@wsphere/staff/web';
import { PaymentMethodModule } from '../payment-method';
import { PaymentStatusPickerComponent } from './components';
import { RetryPaymentDialog, UpdateBillingInfoDialog } from './dialogs';
import { PaymentService, PaymentTaxWebService } from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    TableModule,
    UtilsModule,
    ToastModule,
    IconsModule,
    ButtonModule,
    DialogModule,
    DividerModule,
    ThemingModule,
    TooltipModule,
    UserAvatarModule,
    SelectModule,
    InputModule,
    BadgeModule,
    PaymentMethodModule,
    StaffWebModule,
  ],
  declarations: [UpdateBillingInfoDialog, RetryPaymentDialog, PaymentStatusPickerComponent],
  exports: [UpdateBillingInfoDialog, RetryPaymentDialog, PaymentStatusPickerComponent],
  providers: [PaymentService, PaymentTaxWebService],
})
export class PaymentModule {
  static forRoot(): ModuleWithProviders<PaymentModule> {
    return {
      ngModule: PaymentModule,
    };
  }
}
