<form [formGroup]="form" class="flex flex-col gap-4 m-4 text-sm font-normal">
  <p>
    Transform your customer portal with seamless chatbot integration. Once enabled, the chatbot service will be
    seamlessly integrated into your portal, enhancing user engagement and support capabilities.
  </p>
  <p>Common uses for this application include:</p>

  <div class="gap-2">
    <ul class="list-disc list-inside pl-3">
      <li>Prioritizing and routing conversations efficiently, ensuring faster customer resolutions.</li>
      <li>
        Adapting your business needs, connecting seamlessly to your tech stack, automating workflows, and extending your
        teams for complete customization.
      </li>
      <li>Working 24/7, starting conversations, asking questions, and ensuring a constant flow of opportunities.</li>
    </ul>
  </div>
  <p>Please enter your designated key to be able to enable chatbot integration process.</p>

  <div class="w-full mr-2">
    <label> Secret key</label>
    <input
      vsInput
      [ngClass]="form.controls.secret_key.value ? 'blur-sm hover:blur-none mt-1' : 'mt-1'"
      type="text"
      formControlName="secret_key"
      placeholder="Please enter your key"
    />
  </div>

  <ng-container *vsLet="canManage$ | async as canManage">
    <div
      *ngIf="canManage?.chatbotIntegration && canManage?.customBotIntegration"
      [vsTooltip]="message"
      class="flex justify-between self-end"
    >
      <button
        *ngIf="showEnabledButton"
        [disabled]="!canEnable.value || form.controls.secret_key.invalid"
        vs-button
        theme="primary"
        (click)="enable(type)"
      >
        Enable
      </button>
      <button
        *ngIf="!showEnabledButton"
        [disabled]="!canEnable.value"
        vs-button
        theme="primary"
        (click)="disableBotDialog.open()"
      >
        Disable
      </button>
    </div>
  </ng-container>

  <vs-dialog #disableBotDialog [config]="{dialogContainerClass: 'w-full md:w-96'}">
    <vs-dialog-header
      [config]="{icon: 'message-smile-square', iconTheme:'primary', title: type==='INTERCOM' ? 'Disable Custom Bot integration app?' :'Disable Chatbot integration app?' , subtitle: 'Are you sure you want to disable this app? Customers will no longer have access to chatbot support on the customer portal.', mode: 'simple', hideCloseButton: true}"
    ></vs-dialog-header>
    <div class="w-full flex flex-row gap-2 justify-between">
      <button vs-button class="w-1/2" appearance="outline" (click)="closeDisableDialog()">Cancel</button>
      <button vs-button class="w-1/2" theme="danger" (click)="disable(type)">Disable Application</button>
    </div>
  </vs-dialog>
</form>
