import { Component } from '@angular/core';
import { TableCellComponent } from '../../models';

export interface UserInfoCellProps {
  photoUrl?: string | null;
  name?: string | null;
  email?: string | null;
  emptyText?: string | null;
}

@Component({
  template: `
    <div *ngIf="data && (data.name || data.email || data.photoUrl); else elseTmpl" class="flex items-center gap-3">
      <vs-user-avatar [config]="{ photoUrl: data.photoUrl ?? undefined }" class="w-10 h-10"></vs-user-avatar>
      <div>
        <p *ngIf="data.name" class="text-sm font-medium text-gray-900">{{ data.name }}</p>
        <p *ngIf="data.email" class="text-xs font-normal text-gray-600">{{ data.email }}</p>
      </div>
    </div>
    <ng-template #elseTmpl>
      <div class="w-full text-xs font-normal text-gray-600 text-center">
        {{ data?.emptyText ?? '' }}
      </div>
    </ng-template>
  `,
})
export class UserInfoCellComponent extends TableCellComponent<UserInfoCellProps> {}
