/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyService } from '@vsolv/packages/properties/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { MultiTagCellProps, MultiTagColumn, TagCellProps, TextColumn } from '@vsolv/vectors/table';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { map, switchMap } from 'rxjs';
import { EditPolicyType, PolicyDialog } from '../../dialogs';
import { CoverageService, PolicyService } from '../../services';

@Component({
  selector: 'ws-policy-details-page',
  templateUrl: './policy-details.page.html',
  styles: [
    `
      @media (min-width: 1280px) {
        ws-policy-stats {
          max-width: calc(60% - 16px);
        }
      }
    `,
  ],
})
export class PolicyDetailsPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private securitySvc: SecurityService,
    private propertySvc: PropertyService,
    private coverageSvc: CoverageService
  ) {}

  @ViewChild('createCoverageDialog') createCoverageDialog?: PolicyDialog;

  readonly policy$ = this.policySvc.getPolicyObservable();

  currentTab = 0;

  draft = Policy.Status.DRAFT;

  editPolicyType = EditPolicyType.POLICY_DETAILS;

  createCoveragesType = EditPolicyType.CREATE_COVERAGES;

  canEdit$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_Edit', permissionKeys);
    })
  );

  properties$ = this.policy$.pipe(
    switchMap(async policy => {
      return policy ? await this.propertySvc.retrievePropertySet(policy.propertySetId) : null;
    }),
    map(propertySet => {
      return propertySet
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          propertySet.properties!.sort((a, b) => a.order - b.order).map(assignment => assignment.property!)
        : [];
    })
  );

  termColumns = [
    new TextColumn({ header: 'Order', fitContent: true }, (item: Policy.PolicyTerm) => ({
      text: '' + item.order,
    })),
    new TextColumn({ header: 'Term title' }, (item: Policy.PolicyTerm) => ({
      text: item.title,
    })),
    new TextColumn({ header: 'Term Duration' }, (item: Policy.PolicyTerm) => ({
      text: this.getDuration(item.duration),
    })),
    new MultiTagColumn<Policy.PolicyTerm>({ header: 'Payment Schedules' }, item => ({
      items: this.getScheduleBadges(item, item.paymentSchedules).items,
      limit: 5,
    })),
  ];

  private getScheduleBadges(
    term: Policy.PolicyTerm,
    paymentSchedules: Policy.PaymentSchedule[] | null
  ): MultiTagCellProps {
    if (!paymentSchedules?.length) return { items: [{ text: 'None', theme: 'default' }] };
    else {
      const items: TagCellProps[] = [];

      paymentSchedules.forEach(schedule => {
        if (schedule?.title) {
          items.push({
            text: schedule?.title || '',
            theme: 'default',
            showToolTip: true,

            tooltip:
              schedule?.title !== 'One Time Payment'
                ? {
                    header: schedule.title,
                    text: `${schedule.installments} installment${
                      schedule.installments > 1 ? 's' : ''
                    } / billed every ${Math.floor(term.duration / schedule.installments)} days with a surcharge of ${
                      schedule.rate
                    }%`,
                  }
                : undefined,
          });
        }
      });

      return { items };
    }
  }

  async downloadProvisionTemplate(policy: Policy.Model) {
    if (!policy) return;

    const response = await this.policySvc.generateProvisionTemplate(policy.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response) {
      const link = document.createElement('a');
      link.setAttribute('href', response.data);
      link.setAttribute('download', 'Provisioning.csv');
      link.click();
    }
  }

  getDuration(duration?: number | null) {
    if (!duration) return '0 Days';

    const years = Math.floor(duration / 365);
    const months = Math.floor((duration - 365 * years) / 30);
    const days = Math.floor(duration - 365 * years - 30 * months);

    return (
      (years > 0 ? years + ' Year' + (years > 1 ? 's ' : ' ') : '') +
      (months > 0 ? months + ' Month' + (months > 1 ? 's ' : ' ') : '') +
      (days > 0 ? days + ' Day' + (days > 1 ? 's' : '') : '')
    );
  }

  setEditPolicyType(tab: number) {
    this.currentTab = tab;
    switch (tab) {
      case 0:
        this.editPolicyType = EditPolicyType.POLICY_DETAILS;
        break;
      case 1:
        this.editPolicyType = EditPolicyType.POLICY_TERM;
        break;
      case 2:
        this.editPolicyType = EditPolicyType.POLICY_ASSET;
        break;
    }
  }

  async downloadCoverages(policy: Policy.Model) {
    if (!policy) return;

    const response = await this.coverageSvc.export(policy.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response) {
      const link = document.createElement('a');
      link.setAttribute('href', response.data);
      link.setAttribute('download', 'Coverages.csv');
      link.click();
    }
  }

  editPolicy(newPolicyId: string, policy: Policy.Model) {
    if (newPolicyId !== policy?.id) this.navigateTo('../' + newPolicyId);
  }

  createCoverage(event: string) {
    if (event.includes('pol_')) this.navigateTo('../' + event);
  }

  navigateTo(path?: string) {
    if (path) this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
