/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { CoverageService, PolicyService } from '../../services';
@Component({
  selector: 'ws-copy-coverage-dialog',
  templateUrl: './copy-coverage.dialog.html',
})
export class CopyCoverageDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private formBuilder: FormBuilder,
    private policySvc: PolicyService,
    private coverageSvc: CoverageService
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy: Policy.Model | null = null;
  @Input() dialogConfig?: DialogConfig;

  @Input() set coverage(coverage: Coverage.Model | null) {
    if (coverage) {
      this.coverageDetailsForm.patchValue({
        order: coverage.order,
        title: coverage.title,
        description: coverage.description,
        group: coverage.group,
        exclusions: coverage.exclusions,
        inclusions: coverage.inclusions,
      });
    }
  }

  @Output() closed = new EventEmitter<string>();

  page = 1;
  saving = false;

  draftStatus = Policy.Status.DRAFT;

  coverageDetailsForm = this.formBuilder.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
    group: ['', [Validators.required]],
    inclusions: ['', null],
    exclusions: ['', null],
    order: [0, [Validators.required]],
  });

  open() {
    this.page = 1;
    this.dialog.open();
  }

  close() {
    this.saving = false;
    this.dialog.close();
  }

  async copyCoverage() {
    if (!this.policy) throw new Error(`Unable to create coverage without a policy!`);

    this.saving = true;

    const coverageDetails = this.coverageDetailsForm.value;

    const dto: Coverage.CreateCoverageRequest = {
      canCreateNewVersion: true,
      order: Number(coverageDetails.order ?? 0),
      policyId: this.policy.id,

      title: coverageDetails.title || '',
      description: coverageDetails.description || '',
      group: coverageDetails.group || '',

      exclusions: coverageDetails.exclusions || '',
      inclusions: coverageDetails.inclusions || '',
    };

    const coverage = await this.coverageSvc.create(dto).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
    });

    if (coverage?.id) {
      if (coverage.policyId === this.policy.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Copied coverage',
          text: '<strong>' + coverageDetails.title + '</strong> has been successfully copied.',
        });

        this.policySvc.refreshPolicy();
        this.close();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text:
            'Successfully copied <strong>' +
            coverageDetails.title +
            '</strong> and created a new version of <strong>' +
            this.policy?.title +
            '</strong>.',
        });

        this.policySvc.refreshPolicy(coverage.policyId);
        this.policySvc.clearPlan();

        this.router.navigate([`../${coverage.policyId}`], { relativeTo: this.route });
      }

      this.close();
    }
  }
}
