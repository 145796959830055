<ng-container *vsLet="claim$ | async as claim">
  <vs-dialog
    *vsLet="item$ | async as item"
    #claimItemDetails
    [config]="{
      startOpen: item ? true : false,
      dialogContainerClass: 'w-[584px] max-w-[680px]'
    }"
    (closed)="!removing && item && navigateTo()"
  >
    <!-- header -->
    <vs-dialog-header
      [config]="{
        mode: 'simple',
        showDivider: true,

        icon: 'file-plus-03',
        iconTheme: 'primary',
        
        title: item ? 'Update Claim Item' : 'Add a Claim Item',
        subtitle: item ? 'Update the coverage for this claim item.' : 'Create items for each coverage needed to cover this claim.'
      }"
    >
    </vs-dialog-header>

    <form *vsLet="isCustomerPortal$ | async as isCustomerPortal" class="p-4" [formGroup]="form">
      <div class="flex flex-col gap-4">
        <p class="text-sm font-medium text-gray-500 -mb-2 mt-4">What is the issue related to?</p>

        <div class="flex flex-col gap-4 pb-4">
          <ws-coverage-picker [claim]="claim" formControlName="coverage"></ws-coverage-picker>

          <textarea
            *ngIf="form.value.coverage === null"
            vsInput
            min="3"
            max="6"
            formControlName="description"
            placeholder="Describe the issue in greater detail to help identify the coverage"
          ></textarea>
        </div>

        <ng-container *ngIf="!isCustomerPortal">
          <ws-claim-link-picker
            *vsLet="links$ | async"
            [claim]="claim"
            [id]="item?.id ?? null"
            formControlName="links"
          ></ws-claim-link-picker>
        </ng-container>

        <!-- Buttons -->
        <div
          class="flex gap-2"
          [class.justify-end]="isCustomerPortal || !item"
          [class.justify-between]="!isCustomerPortal && item"
        >
          <ng-container *ngIf="!isCustomerPortal && item && item.status === draft">
            <button vs-button size="md" theme="danger" appearance="outline" (click)="openRemoveDialog()">Remove</button>
          </ng-container>

          <div class="flex gap-2">
            <button vs-button size="md" appearance="outline" (click)="item ? navigateTo() : closeDialog()">
              Cancel
            </button>

            <button
              vs-button
              size="md"
              theme="primary"
              [disabled]="form.value.coverage === undefined || (form.value.coverage === null && !form.value.description)"
              (click)="save(claim)"
            >
              {{ item ? 'Update' : 'Save' }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </vs-dialog>
  <ws-claim-item-remove-dialog
    #removeDialog
    [claim]="claim"
    [item]="item"
    (removed)="closeRemoveDialog($event)"
  ></ws-claim-item-remove-dialog>
</ng-container>
