<vs-dialog *ngIf="policy$ | async as policy" #publishPolicyDialog [config]="{ dialogContainerClass: 'max-w-[600px]' } ">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'file-check-02',
      iconTheme: 'primary',

      title: 'Publish ' + policy?.title || 'Policy',
      subtitle: 'Are you sure you want to publish this policy? You will no longer be able to update it once published.'
    }"
  ></vs-dialog-header>

  <ng-container *vsLet="allPlansConfigured$ | async as plansConfigured">
    <ng-container *vsLet="allEmailsConfigured$ | async as emailsConfigured">
      <div class="flex flex-col gap-2">
        <p *ngIf="!policy?.termsAndConditions?.downloadUrl" class="text-danger-500 flex gap-2">
          <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
          <span class="self-center"> This policy is missing its <strong>Terms & Conditions</strong> document! </span>
        </p>

        <p *ngIf="!policy?.coverages || (policy?.coverages?.length || 0) < 1" class="text-danger-500 flex gap-2">
          <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
          <span class="self-center"> This policy doesn't have any coverages! </span>
        </p>

        <p *ngIf="!policy?.plans || !plansConfigured" class="text-danger-500 flex gap-2">
          <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
          <span class="self-center"> This policy doesn't have all of it's plans configured! </span>
        </p>

        <p *ngIf="!policy?.emails || !emailsConfigured" class="text-danger-500 flex gap-2">
          <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
          <span class="self-center"> This policy doesn't have all of it's email types configured! </span>
        </p>
      </div>

      <!-- Buttons -->
      <div class="mt-2">
        <div class="buttons flex space-x-4">
          <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
            Cancel
          </button>

          <button
            class="flex-grow"
            vs-button
            size="md"
            theme="primary"
            [disabled]="saving || !policy?.termsAndConditions?.downloadUrl || !policy?.coverages?.length || !plansConfigured || !emailsConfigured"
            (click)="publish()"
          >
            Publish
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</vs-dialog>
