/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CurrencyPipe } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { SecurityService } from '@wsphere/staff/web';
import { ClaimItem, Policy, Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import { OpenClaimDialog } from '../../../claim';

export type WarrantyCoverageWithLiabilityInfo = Partial<Warranty.WarrantyCoverage> & {
  liabilityInfo: ClaimItem.LiabilityInfo | null;
};

@Component({
  selector: 'ws-coverage-card',
  templateUrl: './coverage-card.component.html',
})
export class CoverageCardComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private currencyPipe: CurrencyPipe,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('claimDialog') claimDialog?: OpenClaimDialog;

  @PropertyListener('evaluatedLiabilityGroups') evaluatedLiabilityGroups$ = new BehaviorSubject<
    Policy.Plan.LiabilityGroup[] | null
  >(null);
  @Input() evaluatedLiabilityGroups: Policy.Plan.LiabilityGroup[] | null = null;

  @PropertyListener('warrantyCoverage') private warrantyCoverage$ =
    new BehaviorSubject<WarrantyCoverageWithLiabilityInfo | null>(null);
  @Input() warrantyCoverage!: WarrantyCoverageWithLiabilityInfo | null;

  @PropertyListener('warranty') private warranty$ = new BehaviorSubject<Warranty.Model | null>(null);
  @Input() warranty: Warranty.Model | null = null;

  @Input() item: { coverage?: any | null; liabilityInfo?: ClaimItem.LiabilityInfo | null } | null = null;

  @PropertyListener('isCustomerPortal') private isCustomerPortal$ = new BehaviorSubject<boolean>(false);
  @Input() isCustomerPortal = false;

  remainingLiabilityLimit$ = this.warrantyCoverage$.pipe(map(item => item?.liabilityInfo?.remainingLiabilityLimit));

  activated = Warranty.Status.ACTIVATED;

  canCreateClaim$ = combineLatest([this.warranty$, this.isCustomerPortal$]).pipe(
    switchMap(async ([warranty, isCustomerPortal]) => {
      if (isCustomerPortal) {
        return warranty?.status === Warranty.Status.ACTIVATED && warranty.policy?.customerPortalConfig.selfServeClaims;
      } else {
        return await this.securitySvc.hasAccess(
          'clm_OpenClaim',
          warranty?.distributor?.permissionKey ? [warranty.distributor.permissionKey] : null
        );
      }
    })
  );

  period = '';
  remainingPeriod = 0;
  isPastWaitingPeriod$ = this.warranty$.pipe(
    map(warranty => {
      const waitingPeriodEndDate = moment(warranty?.activationDate)
        .add(warranty?.policy?.claimWaitingPeriod, 'days')
        .toDate();
      if (moment().isBefore(waitingPeriodEndDate)) {
        if (moment(waitingPeriodEndDate).diff(new Date(), 'hours') > 24) {
          this.remainingPeriod = moment(waitingPeriodEndDate).diff(new Date(), 'days');
          this.period = 'days';
        } else {
          this.remainingPeriod = moment(waitingPeriodEndDate).diff(new Date(), 'hours');
          this.period = 'hours';
        }
        return false;
      } else {
        return true;
      }
    })
  );

  aggregateSubtitle$ = combineLatest([this.warranty$, this.warrantyCoverage$, this.evaluatedLiabilityGroups$]).pipe(
    switchMap(async ([_warranty, warrantyCoverage, evaluatedGroups]) => {
      if (!evaluatedGroups?.length || !warrantyCoverage?.liabilityGroups?.length) return null;

      const groups = evaluatedGroups.filter(lg => warrantyCoverage?.liabilityGroups?.some(clg => clg === lg.id));
      if (!groups?.length) return null;

      return groups.map(group => `${group.title}: ${this.currencyPipe.transform((group.limit ?? 0) / 100)}`);
    })
  );

  newClaim() {
    if (this.isCustomerPortal) {
      if (!this.warrantyCoverage) return;

      this.router.navigate(['claim/open'], {
        relativeTo: this.route,
        queryParams: { coverage: this.warrantyCoverage.coverageId },
      });
    } else {
      this.claimDialog?.openDialog(this.warranty, this.warranty?.customer);
    }
  }
}
