<div
  class="flex gap-1 justify-between min-w-[256px] h-14 pt-2 px-2 border shadow-sm bg-base rounded-lg max-w-full"
  [class.cursor-pointer]="routerLink"
  (click)="navigate()"
>
  <div class="flex gap-1 max-w-full" [style.max-width]="end.children.length ? '80%' : ''">
    <div *ngIf="imageUrl" class="flex justify-center w-12 h-12 p-1 -ml-1 -mt-[5px]">
      <img class="w-full h-full self-center rounded-md object-cover" [src]="imageUrl" />
    </div>

    <div *ngIf="icon" class="flex justify-center w-9 h-9 min-w-[36px]">
      <vs-icon class="self-center text-themed-500" size="22" [name]="icon" [theme]="theme"></vs-icon>
    </div>

    <div class="flex flex-col max-w-full overflow-hidden" [style.max-width]="end.children.length ? '80%' : ''">
      <div class="flex gap-1 max-w-full">
        <vs-icon *ngIf="titleIcon" [name]="titleIcon"></vs-icon>

        <p
          class="text-sm font-semibold text-gray-900 whitespace-nowrap overflow-hidden overflow-ellipsis"
          [class]="titleStyles"
        >
          {{ title }}
        </p>

        <ng-content select="[slot='header-end']"></ng-content>
      </div>

      <div class="flex gap-1 max-w-full">
        <vs-icon *ngIf="subtitleIcon" size="10" class="self-center" [name]="subtitleIcon"></vs-icon>

        <p
          class="text-xs font-normal text-gray-600 whitespace-nowrap overflow-hidden overflow-ellipsis"
          [class]="subtitleStyles"
        >
          <ng-content select="[slot='subtitle']"></ng-content>
          {{ subtitle }}
        </p>
      </div>
    </div>
  </div>

  <div #end>
    <ng-content select="[slot='end']"></ng-content>
  </div>
</div>
