<ng-container *vsLet="claim$ | async as claim">
  <div *ngIf="item$ | async as item" class="flex flex-col gap-4">
    <p class="font-medium text-gray-500 mb-2">Cure Details</p>

    <ws-claim-item-cure-card class="border rounded-xl" [item]="item"></ws-claim-item-cure-card>

    <div *vsLet="isCustomerPortal$ | async as isCustomerPortal">
      <div
        *vsLet="canManageCure$ | async as canManageCure"
        class="flex gap-2"
        [class.justify-end]="claim?.status !== open || !canManageCure || item.deleted"
        [class.justify-between]="claim?.status === open && canManageCure && !item.deleted"
      >
        <button
          *ngIf="!isCustomerPortal && canManageCure && claim?.status === open && !item.deleted"
          vs-button
          appearance="outline"
          (click)="navigateTo('manage-cure')"
        >
          Edit
        </button>

        <div class="flex gap-2">
          <button vs-button appearance="outline" (click)="navigateTo('', item)">Back</button>

          <button
            vs-button
            *vsLet="canAdjudicateClaimItem$ | async as canAdjudicateClaimItem"
            theme="primary"
            [disabled]="!!item.deleted || !item.cure?.title || !canAdjudicateClaimItem"
            (click)="
              isCustomerPortal
                ? navigateTo('resolve')
                : item.adjudicationReason || item.deleted || claim?.status !== open
                ? navigateTo('adjudicate')
                : navigateTo('manage-adjudication')
            "
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
