<div *vsLet="isLargeScreen$ | async as isLargeScreen">
  <form [formGroup]="form" [class]="!isLargeScreen ? 'flex flex-col gap-5 mt-4' : ''">
    <!-- Name -->
    <vs-form-field
      [appearance]="isLargeScreen ? 'horizontal' : 'vertical'"
      theme="primary"
      [config]="{ label: 'Name' }"
      [class]="isLargeScreen ? 'pt-6 pb-6' : ''"
    >
      <input vsInput placeholder="e.g., John Doe" formControlName="name" type="text" />
      <span class="text-danger" slot="hint">
        {{ form.get('name')?.hasError('required') ? 'Name is required' : '' }}
      </span>
    </vs-form-field>

    <vs-divider></vs-divider>

    <div [class]="isLargeScreen ? 'pt-6 pb-6 flex gap-8' : ''" *ngIf="roles$ | async as roles">
      <p class="label text-sm text-gray-700 font-medium w-72">Distributors</p>

      <div class="flex flex-row gap-2 flex-wrap" *ngIf="distributorsAndRoles$ | async as distributorsAndRoles">
        <ng-container *ngFor="let distributor of distributorsAndRoles">
          <div class="bg-base-white px-1 border-gray-300 border rounded-md">
            <div *ngIf="!distributor.isSuperAdmin" [vsTooltip]="{ template: tooltip }" tooltipStyle="light">
              <p class="m-1">{{ distributor.distributor?.name || 'Platform' }}</p>

              <ng-template #tooltip>
                <p>Roles For {{ distributor.distributor?.name || 'Platform' }}</p>

                <vs-divider class="mt-1 mb-1"></vs-divider>

                <div class="flex flex-row gap-2 flex-wrap">
                  <ws-role-badge
                    *ngFor="let role of distributor.roles"
                    [roleId]="role.roleId"
                    [permissionKey]="role.permissionKey"
                    [showPermissionKeyLabel]="false"
                  ></ws-role-badge>
                </div>
              </ng-template>
            </div>

            <div
              *ngIf="distributor.isSuperAdmin"
              [vsTooltip]="{
                title: 'Super Admin',
                subtitle: 'Complete access to entire platform and all distributors'
              }"
              tooltipStyle="light"
            >
              <p class="m-1">Super Admin</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <vs-divider></vs-divider>

    <!-- Email -->
    <vs-form-field
      [appearance]="isLargeScreen ? 'horizontal' : 'vertical'"
      theme="primary"
      [config]="{ label: { text: 'Email address', for: 'email-input' } }"
      [class]="isLargeScreen ? 'pt-6 pb-6' : ''"
    >
      <vs-input-container class="group">
        <vs-icon name="mail-01" slot="start"></vs-icon>
        <input placeholder="e.g., johndoe123@club.com" formControlName="email" type="email" id="email-input" />
      </vs-input-container>

      <span class="text-danger" slot="hint">
        {{ form.get('email')?.hasError('required') ? 'Email is required' : '' }}
      </span>
      <span class="text-danger" slot="hint">
        {{ form.get('email')?.hasError('email') ? 'Please enter valid email address' : '' }}
      </span>
    </vs-form-field>

    <vs-divider></vs-divider>

    <!-- Photo -->
    <div *ngIf="isLargeScreen; else mobile" class="pt-6 pb-6 flex gap-8">
      <div>
        <p class="label w-72 text-sm text-gray-700 font-medium">Your photo</p>
        <p class="text-[14px]">This will be displayed on your profile.</p>
      </div>

      <vs-form-field
        appearance="vertical"
        [config]="{
          label: { text: '', for: 'file-input' },
          description: ''
        }"
      >
        <div
          class="flex flex-col gap-1"
          slot="before-content"
          *ngIf="initialUrl || $any(fileInput).files[0]; else placeholder"
        >
          <img [vsImagePreview]="fileInput.files[0] || initialUrl" class="w-20 h-20" />
          <button
            type="button"
            *ngIf="initialUrl || $any(fileInput).files[0]"
            vsLink
            (click)="clearImage()"
            class="text-xs"
          >
            Remove
          </button>
        </div>
        <ng-template #placeholder>
          <vs-user-avatar class="w-16 h-16"> </vs-user-avatar>
        </ng-template>
        <input
          vsInput
          #fileInput="vsInput"
          formControlName="img"
          id="file-input"
          type="file"
          accept=".PNG, .JPG, .GIF"
          moreInfo="max. 800x400px"
          (change)="onImageUpload($event)"
        />
      </vs-form-field>
    </div>

    <!-- Photo for small layout-->
    <ng-template #mobile>
      <div class="flex flex-col gap-4">
        <div>
          <p class="label text-sm text-gray-700 font-medium">Your photo</p>
          <p class="text-[14px]">This will be displayed on your profile.</p>
        </div>

        <vs-form-field
          class="col-span-3"
          appearance="vertical"
          [config]="{
            label: { text: '', for: 'file-input' },
            description: ''
          }"
        >
          <div>
            <div
              class="flex flex-col gap-1 mb-5"
              slot="before-content"
              *ngIf="initialUrl || $any(fileInput).files[0]; else placeholder"
            >
              <div>
                <img [vsImagePreview]="fileInput.files[0] || initialUrl" class="w-20 h-20" />
                <button
                  *ngIf="initialUrl || $any(fileInput).files[0]"
                  vsLink
                  (click)="clearImage()"
                  type="button"
                  class="text-xs text-center w-20"
                >
                  Remove
                </button>
              </div>
            </div>

            <input
              vsInput
              #fileInput="vsInput"
              formControlName="img"
              id="file-input"
              type="file"
              accept=".SVG, .PNG, .JPG, .GIF"
              moreInfo="max. 800x400px"
            />
            <ng-template #placeholder>
              <vs-user-avatar class="w-16 h-16 mb-5"> </vs-user-avatar>
            </ng-template>
          </div>
        </vs-form-field>
      </div>
    </ng-template>

    <vs-divider></vs-divider>
    <!-- Form Save buttons -->
    <div class="pt-6 pb-6 flex justify-end gap-3">
      <button vs-button type="button" size="md" appearance="outline" [disabled]="!form.dirty" (click)="resetForm()">
        Cancel
      </button>
      <button
        vs-button
        type="button"
        size="md"
        theme="primary"
        [disabled]="!inputIsValid() || !form.dirty"
        (click)="submitForm()"
      >
        Save
      </button>
    </div>
  </form>
</div>
