<div class="flex h-2 min-w-[128px] w-full rounded-full">
  <div class="empty:hidden flex items-center gap-2 mr-2">
    <ng-content select="[slot='start']"></ng-content>
  </div>

  <!-- Bar with items -->
  <div
    *ngFor="let item of items; index as index"
    class="h-2"
    [theme]="item.theme"
    [class.bg-themed-500]="item.theme"
    [style.background-color]="item.color"
    [class.rounded-l-full]="index === 0"
    [class.rounded-r-full]="index === (items.length || 1) - 1"
    [style.width]="((item.weight / totalWeight) * 100 | number) + '%'"
  ></div>

  <!-- Bar with percentage -->
  <div
    *ngIf="percentage"
    class="rounded-l-full bg-themed-500"
    [class.rounded-r-full]="percentage === 1"
    [style.width]="(percentage || 0) * 100 + '%'"
  ></div>
  <div
    *ngIf="percentage"
    class="rounded-r-full bg-themed-100"
    [class.rounded-l-full]="percentage === 0"
    [style.width]="(1 - (percentage || 0)) * 100 + '%'"
  ></div>

  <!-- No items or percentage -->
  <div *ngIf="(!items || items?.length === 0) && !percentage" class="w-full rounded-full bg-gray-200"></div>

  <div class="empty:hidden flex items-center gap-2 ml-2">
    <ng-content select="[slot='end']"></ng-content>
  </div>
</div>
