/* eslint-disable @typescript-eslint/no-explicit-any */
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CreateCustomerOptions, FundingSource } from '@vsolv/dwolla/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { DwollaService } from '@vsolv/packages/reimbursement/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { PaginationConfig } from '@vsolv/vectors/table';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, switchMap, tap } from 'rxjs';
import { ClaimPaymentsTableComponent } from '../../components';
import { PaymentDialog } from '../../dialogs';
import { ClaimService } from '../../services';

export type Payment = Claim.ClaimPayment.Model & {
  items: Claim.ClaimItem.Model[];
  destinationName?: string;
  links: { link: Link.Model; card: InfoCardConfig }[];
};

@Component({
  selector: 'ws-claim-payments',
  templateUrl: './claim-payments.page.html',
})
export class ClaimPaymentsPage implements OnInit {
  constructor(
    private claimSvc: ClaimService,
    private linkSvc: LinksWebService,
    private securitySvc: SecurityService,
    private dwollaSvc: DwollaService,
    private portalSvc: PortalService,
    private clipboardCdk: Clipboard,
    private toastSvc: ToastService,
    private router: ActivatedRoute
  ) {}

  @ViewChild(PaymentDialog) protected paymentDialog?: PaymentDialog;
  @ViewChild(ClaimPaymentsTableComponent) protected claimPaymentsTableComponent?: ClaimPaymentsTableComponent;

  readonly claim$ = this.claimSvc.getClaim();

  protected loading = false;

  protected paginationConfig: PaginationConfig = {
    itemsPerPage: 5,
    currentPage: 1,
    pageSizes: [5, 10, 20, 50],
    totalItems: 0,
  };

  paginationQueryRequest$ = new BehaviorSubject<{ page?: number; limit?: number }>({ page: 1, limit: 5 });

  paymentsAndItems$: Observable<Payment[]> = combineLatest([this.claim$, this.paginationQueryRequest$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([claim, dto]) =>
      claim ? { claim, payments: await this.claimSvc.listPayments(claim.id, dto) } : null
    ),
    tap(data =>
      data?.payments
        ? (this.paginationConfig = {
            itemsPerPage: data.payments.meta.itemsPerPage,
            currentPage: data.payments.meta.currentPage,
            pageSizes: [5, 10, 20, 50],
            totalItems: data.payments.meta.totalItems ?? 0,
          })
        : null
    ),
    map(data => (data ? { ...data, payments: data.payments.items } : null)),
    switchMap(async data => {
      const payments = data?.payments;
      const claim = data?.claim;

      if (!payments || !claim) return [];
      const paymentsAndItems: Payment[] = [];

      await Promise.all(
        payments.map(async payment => {
          const claimPayment = await this.getPayment(payment);
          if (claimPayment) paymentsAndItems.push(claimPayment);
        })
      );

      return paymentsAndItems;
    }),
    tap(() => (this.loading = false))
  );

  private refreshPaymentMethods$ = new BehaviorSubject<null>(null);

  fundingSources$: Observable<FundingSource[] | null> = combineLatest([this.claim$, this.refreshPaymentMethods$]).pipe(
    switchMap(async ([claim]) =>
      claim?.warranty?.customer ? this.dwollaSvc.listFundingSource(claim.warranty?.customer.email) : null
    )
  );

  canViewPayment$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ViewPayments', permissionKey);
    })
  );

  canManagePayments$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManagePayments', permissionKey);
    })
  );

  canManageLink$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageLink', permissionKey);
    })
  );

  canManagePaymentMethods$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManagePaymentMethod', permissionKey);
    })
  );

  canIssuePayments$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_IssuePayment', permissionKey);
    })
  );

  canFinishPayments$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_FinishPayment', permissionKey);
    })
  );

  customer$: Observable<CreateCustomerOptions | null> = this.claim$.pipe(
    map(claim => {
      return {
        email: claim?.warranty?.customer?.email,
        lastName:
          claim?.warranty?.customer?.name.substring(claim?.warranty?.customer?.name.indexOf(' ') + 1) ||
          claim?.warranty?.customer?.name.substring(0, claim?.warranty?.customer?.name.indexOf(' ')),
        firstName:
          claim?.warranty?.customer?.name.substring(0, claim?.warranty?.customer?.name.indexOf(' ')) ||
          claim?.warranty?.customer?.name.substring(claim?.warranty?.customer?.name.indexOf(' ') + 1),
        correlationId: claim?.warranty?.customer?.id,
      } as CreateCustomerOptions;
    }),
    catchError(() => of(null))
  );

  customerId$ = this.customer$.pipe(
    switchMap(async customer => (customer ? this.dwollaSvc.findCustomer(customer.email) : null))
  );

  async refresh() {
    await this.claimSvc.refreshClaim();
  }

  refreshPaymentMethods() {
    this.refreshPaymentMethods$.next(null);
  }

  protected async copyLink(contractNumber: string, claimId: string) {
    const portalLink = await this.portalSvc.getPortalUrl();
    this.clipboardCdk.copy(
      `${portalLink.url}/warranty/${contractNumber}/claim/${claimId}/manage-reimbursement-methods`
    );
    this.toastSvc.show({
      type: 'success',
      title: 'Link Copied',
      text: 'The customer manage payment methods link has been successfully copied to the clipboard.',
    });
  }

  private async getPayment(payment: Claim.ClaimPayment.Model): Promise<Payment | null> {
    const linkItems = await this.linkSvc.list(
      { id: payment.claimId, objectType: Link.ObjectType.CLAIM },
      {
        owner: { id: payment.id, objectType: Link.ObjectType.PAYMENT },
        linkType: Link.ObjectType.CLAIM_ITEM,
      }
    );

    const items: Claim.ClaimItem.Model[] = [];

    linkItems.items.forEach(link => {
      const item = link.claimItem_1 ? link.claimItem_1 : link.claimItem_2 ? link.claimItem_2 : null;
      if (item) {
        items.push(item);
      }
    });

    const links = await this.linkSvc.list(
      { id: payment.claimId, objectType: Link.ObjectType.CLAIM },
      {
        owner: { id: payment.id, objectType: Link.ObjectType.PAYMENT },
      }
    );

    const linksAndCards = links.items.map(link => {
      const type = Link.getLinkType(link, Link.ObjectType.ACTIVITY);
      const config = Link.getLinkInfoCardConfig(link, type, payment.id);
      return { link, card: config };
    });

    const destination = payment.destination
      ? await this.dwollaSvc.getFundingSource(payment?.destination, payment.customerId)
      : null;

    return {
      ...payment,
      ...(destination && { destinationName: `${destination?.bankName} - ${destination?.name}` }),
      items,
      links: linksAndCards,
    };
  }

  ngOnInit(): void {
    this.linkSvc.refreshActivity$.next(null);
  }
}
