import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { PaginationComponent, TableComponent } from './components';
import { CellDirective } from './directives';
import { TableColumnIdsPipe } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    CdkTableModule,
    UtilsModule,
    IconsModule,
    InputModule,
    TooltipModule,
    ButtonModule,
    SelectModule,
    DragDropModule,
  ],
  declarations: [TableComponent, PaginationComponent, CellDirective, TableColumnIdsPipe],
  exports: [TableComponent, PaginationComponent],
})
export class TableModule {}
