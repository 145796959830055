<vs-input-container *vsLet="staff$ | async as staffMembers" class="h-auto min-h-[40px]">
  <vs-icon slot="start" name="search-md"></vs-icon>

  <div class="flex flex-wrap w-full">
    <div *ngFor="let user of users" class="flex gap-1 px-2 py-1 my-1 mr-1 border rounded-md max-w-[22rem]">
      <p class="truncate text-sm font-medium text-gray-700 self-center">
        {{ user.name || user.email }}
      </p>
      <vs-icon name="x" class="self-center" (click)="selectUser(user.email)"></vs-icon>
    </div>

    <input
      #input
      type="email"
      class="min-h-[40px] max-h-[40px]"
      [placeholder]="placeholder"
      [vsMenuTriggerFor]="staffMenu"
      (keyup)="searchQuery$.next(input.value); validateEmail(input.value)"
      (keydown.enter)="validEmail ? selectUser(input.value) : ''; validEmail ? (input.value = '') : ''"
    />
  </div>

  <div slot="end" tooltipStyle="light" [vsTooltip]="!validEmail ? 'Enter a valid email to send an invite to it!' : ''">
    <button
      appearance="clear"
      vs-icon-button
      [class.pointer-events-none]="!validEmail"
      (click)="selectUser(input.value); input.value = ''; searchQuery$.next('')"
    >
      <vs-icon name="user-plus-01"></vs-icon>
    </button>
  </div>

  <ng-template #staffMenu>
    <vs-menu class="max-h-[50vh] w-[468px] -ml-10">
      <ng-container *ngIf="staffMembers?.length; else noStaff">
        <button
          *ngFor="let staff of staffMembers"
          vs-menu-item
          [class.bg-gray-50]="userSelected({ name: staff.name || '', email: staff.email })"
          (click)="selectUser(staff); input.value = ''"
        >
          <div class="flex gap-2 justify-between">
            <div class="flex gap-4">
              <vs-user-avatar
                class="h-8 w-8 self-center"
                [config]="{ photoUrl: staff?.user?.photoURL ?? '' }"
              ></vs-user-avatar>

              <div class="flex flex-col">
                <p class="text-sm font-medium text-gray-900" [class.mt-1]="!staff.name">
                  {{ staff.name || staff.email }}
                </p>
                <p *ngIf="staff.name" class="text-xs font-normal text-gray-600">
                  {{ staff.email }}
                </p>
              </div>
            </div>

            <div class="flex justify-end w-6">
              <vs-icon
                *ngIf="userSelected({ name: staff.name || '', email: staff.email })"
                name="check"
                class="self-center"
              ></vs-icon>
            </div>
          </div>
        </button>
      </ng-container>

      <ng-template #noStaff>
        <p class="px-2 py-1 text-sm font-medium text-gray-900" *vsLet="canViewStaff$ | async as viewStaff">
          {{
            input.value
              ? viewStaff
                ? 'No staff members were found using that email'
                : ''
              : 'Type a name or email to start searching!'
          }}
        </p>
        <p *ngIf="input.value" class="px-2 py-1 text-sm font-medium text-gray-900">
          A registration link will be sent to this email.
        </p>
      </ng-template>
    </vs-menu>
  </ng-template>
</vs-input-container>
