import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';

@Component({
  selector: 'ws-full-admin-warning-dialog',
  templateUrl: './full-administrator-warning.dialog.html',
})
export class FullAdminWarningDialog {
  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() closed = new EventEmitter();

  _distributorName = 'the entire platform';

  open(distributorName?: string) {
    this.dialog?.open();
    this._distributorName = distributorName || 'the entire platform';
  }

  close() {
    this.dialog?.close();
    this.closed.emit();
  }
}
