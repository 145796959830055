<ng-container *ngIf="policy$ | async as policy">
  <ws-plan-picker
    [policy]="policy"
    [staffView]="staffView"
    [formControl]="planControl"
    [evaluatedPlans]="(evaluatedPlans$ | async)?.evaluatedPlans"
    [viewablePlansAndTerms]="session.viewablePlansAndTerms"
    (back)="goBack()"
    (buyNow)="next()"
    (addons)="addons.emit($event)"
    (termChanged)="termChanged.emit($event)"
  ></ws-plan-picker>
</ng-container>
