<vs-dialog [config]="{ dialogContainerClass: 'max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      showDivider: true,
      
      icon: 'link-01',
      iconTheme: 'primary',

      title: 'Create a link to ' + plan?.title || 'a policy',
      subtitle: 'This link will lead customers through a checkout flow for this policy, with the selected terms below shown to customers that qualify.'
    }"
  ></vs-dialog-header>

  <div class="px-4 py-2">
    <div class="py-2">
      <p class="text-md font-medium text-gray-900">Select policy plans</p>
      <p class="text-sm font-normal text-gray-600">
        Select the terms under this plan that you would like to offer to qualifying customers
      </p>
    </div>

    <ws-distributor-filter
      [hideAllDistributorsBadge]="true"
      permissionId="pol_CreatePolicyLink"
      (valueChanges)="selectDistributor($event)"
    ></ws-distributor-filter>

    <div #origin class="py-2">
      <vs-input-container
        #trigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        (click)="toggle()"
        [class]="overlayOpen ? '!rounded-b-none' : ''"
        class="hover:cursor-pointer"
      >
        <p
          *ngIf="!selectedTerms.length"
          class="p-0 text-center self-center align-middle text-md text-gray-500 font-normal"
        >
          Select terms to include in checkout link
        </p>
        <div *ngIf="selectedTerms.length" class="flex flex-row gap-2 px-1 align-middle self-center">
          <div
            *ngIf="selectedTerms[0] as term"
            class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
          >
            <span>{{plan?.title}} - {{term.title}}</span>
            <vs-icon
              name="x-close"
              class="hover:cursor-pointer self-center align-middle"
              (click)="$event.stopImmediatePropagation();  deselectTerm(term.id)"
            ></vs-icon>
          </div>
          <div
            *ngIf="selectedTerms[1] as term"
            class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
          >
            <span>{{plan?.title}} - {{term.title}}</span>
            <vs-icon
              name="x-close"
              class="hover:cursor-pointer self-center align-middle"
              (click)="$event.stopImmediatePropagation();  deselectTerm(term.id)"
            ></vs-icon>
          </div>
          <div
            *ngIf="selectedTerms.length > 2"
            class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
          >
            <span>+{{selectedTerms.length - 2}}</span>
          </div>
        </div>
        <vs-icon slot="end" name="chevron-down" class="transition-all" [class.rotate-180]="overlayOpen"></vs-icon>
      </vs-input-container>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="overlayOpen"
        (overlayOutsideClick)="$event.stopImmediatePropagation(); toggle()"
      >
        <!-- Terms menu -->
        <vs-card
          *vsLet="terms$ | async as terms"
          class="!rounded-b-md !rounded-t-none border border-gray-200 !shadow-md w-[536px] min-w-[287px] max-w-[536px] overflow-hidden"
        >
          <ng-container *ngFor="let term of terms">
            <label
              *vsLet="isTermSelected(term.id) as isTermSelected"
              tooltipStyle="light"
              tooltipPosition="end"
              tooltipMaxWidth="full"
              class="!w-full !justify-start !rounded-none flex p-2 gap-2 items-center cursor-pointer hover:bg-gray-50"
              [for]="term.id"
            >
              <input
                type="checkbox"
                vsInput
                [id]="term.id"
                [checked]="isTermSelected"
                (change)="isTermSelected ? deselectTerm( term.id) : selectTerm(term)"
              />

              <span class="truncate cursor-pointer w-full"> {{ term.title }} </span>

              <!-- <button vs-icon-button appearance="clear"><vs-icon name="chevron-right"></vs-icon></button> -->
            </label>
          </ng-container>
        </vs-card>
      </ng-template>
    </div>

    <div class="flex justify-end gap-2 mt-8">
      <button vs-button appearance="outline" size="md" (click)="closeDialog()">Cancel</button>
      <ng-container *vsLet="canCreatePolicyLinkPlatform$ | async as canCreatePolicyLinkPlatform">
        <button
          vs-button
          theme="primary"
          [disabled]="!canCreatePolicyLinkPlatform && !distributor"
          (click)="copyLink()"
        >
          <vs-icon name="link-01"></vs-icon>
          Copy Link
        </button>
      </ng-container>
    </div>
  </div>
</vs-dialog>
