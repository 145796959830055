import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { User } from '@vsolv/core/users/domain';

@Injectable()
export class SignedInUserResolver implements Resolve<User.Model | null> {
  currentUser: User.Model | null = null;

  resolve() {
    return this.currentUser;
  }
}
