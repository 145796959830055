import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';

@Component({
  selector: 'ws-admin-forgot-password-page',
  templateUrl: './forgot-password.page.html',
})
export class ForgotPasswordPage {
  canSendTimeOut = 0;

  constructor(private firebaseSvc: FirebaseService, private route: ActivatedRoute) {}

  formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
  });

  email?: string;
  sent = false;

  inputIsValid() {
    return this.formGroup.valid;
  }

  async submit() {
    this.email = this.formGroup.value.email || undefined;
    await this.resetPassword();
  }

  async resetPassword() {
    if (this.canSendTimeOut <= 0) {
      if (!this.email) return;
      this.sent = true;
      this.canSendTimeOut = 60;
      const sendTimeout = setInterval(() => {
        this.canSendTimeOut--;
        if (this.canSendTimeOut <= 0) {
          this.canSendTimeOut = 0;
          clearInterval(sendTimeout);
        }
      }, 1000);
      await this.firebaseSvc.forgotPassword(this.email);
    }
  }
}
