/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import {
  BadgeCellProps,
  BadgeColumn,
  ButtonColumn,
  IconButtonColumn,
  MultiBadgeCellProps,
  PaginationConfig,
  TableColumn,
  TextColumn,
  UserInfoColumn,
} from '@vsolv/vectors/table';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
import moment from 'moment';
import {
  BehaviorSubject,
  Observable,
  combineLatest,
  firstValueFrom,
  from,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { AddTeamMemberDialog, DeleteTeamMemberDialog, EditTeamMemberDialog } from '../../dialogs';
import { RoleAssignmentsService, SecurityService, StaffService } from '../../services';
import { DistributorAndRoles, DistributorRolesCellProps, DistributorRolesColumn } from './columns';

export type TeamTableFilters = Pick<Staff.ListStaffQueryRequest, 'search' | 'status' | 'roleIds' | 'distributorId'>;

@Component({
  selector: 'ws-team-members-table',
  templateUrl: './team-members-table.component.html',
})
export class TeamMembersTableComponent {
  constructor(
    private staffSvc: StaffService,
    private toastSvc: ToastService,
    private securitySvc: SecurityService,
    private roleSvc: RoleAssignmentsService
  ) {}

  @ViewChild(EditTeamMemberDialog) editMemberDialog?: EditTeamMemberDialog;
  @ViewChild(DeleteTeamMemberDialog) deleteMemberDialog?: DeleteTeamMemberDialog;
  @ViewChild(AddTeamMemberDialog) addMemberDialog?: AddTeamMemberDialog;

  @Input() disabled = false;

  @PropertyListener('permissionKey') permissionKey$ = new BehaviorSubject<string | null>(null);
  @Input() permissionKey: string | null = null;

  @PropertyListener('distributor') distributor$ = new BehaviorSubject<Distributor.Model | null>(null);
  @Input() distributor?: Distributor.Model;

  selectedMember?: Staff.Model;
  statuses = Object.keys(Staff.Status);

  private self$ = from(this.staffSvc.retrieveSelf()).pipe(shareReplay(1));
  private refresh$ = new BehaviorSubject<null>(null);

  filters: TeamTableFilters | null = null;
  private filters$ = new BehaviorSubject(this.filters);

  readonly pagination$ = new BehaviorSubject({ currentPage: 1, itemsPerPage: 10 });
  paginatedData$ = combineLatest([this.pagination$, this.filters$, this.permissionKey$, this.refresh$]).pipe(
    switchMap(
      async ([pagination, filters, permissionKey]) =>
        await this.staffSvc.list({
          page: pagination.currentPage,
          limit: pagination.itemsPerPage,
          permissionKeys: permissionKey ? [permissionKey] : null,
          ...(filters?.status && { status: filters.status }),
          ...(filters?.roleIds && { roleIds: filters.roleIds }),
          ...(filters?.search && { search: filters.search.toLowerCase() }),
          ...(filters?.distributorId && { distributorId: filters.distributorId }),
        })
    ),
    shareReplay(1)
  );

  memberCount$ = this.paginatedData$.pipe(
    map(data => data.meta.totalItems),
    startWith(0)
  );

  readonly members$: Observable<Staff.Model[]> = this.paginatedData$.pipe(
    map(data => data.items),
    startWith([])
  );

  readonly paginationConfig$: Observable<Partial<PaginationConfig>> = this.paginatedData$.pipe(
    map(data => data.meta),
    startWith(this.pagination$.value)
  );

  canEditStaffDetails$ = combineLatest([this.permissionKey$, this.distributor$]).pipe(
    switchMap(async ([permissionKey, distributor]) => {
      // If a distributor is passed you're on the distributor team page, otherwise you're on the settings team page
      if (distributor) {
        return await this.securitySvc.hasAccess('dist_EditStaffDetails', permissionKey ? [permissionKey] : null);
      } else {
        return permissionKey
          ? await this.securitySvc.hasAccess('dist_InviteStaff', [permissionKey])
          : await this.securitySvc.hasAccess('stf_EditStaffDetails', null);
      }
    })
  );

  readonly canInvite$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(
      async globalDistributors =>
        await this.securitySvc.hasAccess('stf_ManageStaff', globalDistributors?.map(dist => dist.permissionKey) || null)
    )
  );

  readonly columns$: Observable<TableColumn<unknown, unknown>[]> = combineLatest([
    this.permissionKey$,
    this.canEditStaffDetails$,
  ]).pipe(
    map(([permissionKey, canEditStaff]) => {
      return [
        new UserInfoColumn<Staff.Model>({ header: 'Name', fitContent: true }, staff => {
          return { name: staff.name, email: staff.email, photoUrl: staff.user?.photoURL };
        }),

        new BadgeColumn<Staff.Model>({ header: 'Invitation status' }, staff => {
          return this.getStaffInvitationStatusBadge(staff);
        }),

        new TextColumn<Staff.Model>({ header: 'Last signed in', fitContent: true }, staff => {
          return { text: this.formatDate(staff.user?.lastSignedIn), classes: 'text-center' };
        }),

        new TextColumn<Staff.Model>({ header: 'Account created', fitContent: true }, staff => {
          return { text: this.formatDate(staff.created), classes: 'text-center' };
        }),

        new DistributorRolesColumn<Staff.Model>({ header: 'Distributors & roles' }, staff => {
          return this.getDistributorBadges(permissionKey, staff);
        }),

        ...(canEditStaff && !this.disabled
          ? [
              new ButtonColumn<Staff.Model>({ stickyEnd: true, fitContent: true }, async staff => {
                const self = await firstValueFrom(this.self$);
                return {
                  type: 'clear',
                  rounded: true,
                  iconEnd: 'edit-01',
                  disabled: staff.status === Staff.Status.DEACTIVATED || staff.id === self.id,
                  click: () => this.openUpdateDialog(staff),
                };
              }),

              new IconButtonColumn<Staff.Model>({ stickyEnd: true, fitContent: true }, async staff => {
                const self = await firstValueFrom(this.self$);

                return {
                  type: 'clear',
                  rounded: true,
                  icon: staff.status === Staff.Status.DEACTIVATED ? 'refresh-ccw-04' : 'trash-01',
                  disabled: staff.id === self.id,
                  click:
                    staff.status === Staff.Status.DEACTIVATED
                      ? () => this.reactivateStaff(staff)
                      : () => this.openDeleteDialog(staff),
                };
              }),
            ]
          : []),
      ];
    })
  );

  private async reactivateStaff(staff: Staff.Model) {
    try {
      await this.roleSvc.inviteStaffAndGrantRoles({ email: staff.email, roles: [] });
      this.toastSvc.show({
        type: 'success',
        title: 'User re-invited',
        text: 'Your colleague will be alerted via email with an invite to join your team.',
      });
      this.refresh();
    } catch (err: any) {
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: 'Something went wrong and the invitation was not sent. Please try again.',
      });
    }
  }

  private getStaffInvitationStatusBadge(staff: Staff.Model): BadgeCellProps {
    switch (staff.status) {
      case Staff.Status.ACTIVE:
        return { text: 'Active', theme: 'success', displayStatusIcon: true };

      case Staff.Status.PENDING:
        return { text: 'Invited', theme: 'warn', displayStatusIcon: true };

      case Staff.Status.DEACTIVATED:
        return { text: 'Deactivated', theme: 'danger', displayStatusIcon: true };
    }
  }

  private getRoleBadges(
    permissionKey: string | null,
    staffRoles?: Staff.Security.RoleAssignment[]
  ): MultiBadgeCellProps {
    staffRoles = staffRoles?.filter(role => role.permissionKey === permissionKey) || [];
    if (!staffRoles?.length) return { items: [{ text: 'None', theme: 'default' }] };
    else {
      const items: BadgeCellProps[] = [];
      const roles = staffRoles.map(role => Staff.Security.getRole(role.roleId));

      roles.forEach(role => {
        if (role?.display.title) {
          items.push({
            text: role?.display.title || '',
            theme: role?.display.theme || 'default',
          });
        }
      });

      return { items };
    }
  }

  private getDistributorBadges(permissionKey: string | null, staff?: Staff.Model): DistributorRolesCellProps {
    const track = new Map<string, Set<string>>();
    const global = this.getGlobalRoleBadges(staff);
    const items: DistributorAndRoles[] = [];

    if (global) {
      track.set('global', new Set(global.roles.map(role => role.roleId)));
      items.push(global);
    }

    const staffRoles =
      staff?.roles?.filter(role =>
        permissionKey ? role.permissionKey?.startsWith(permissionKey) : role.permissionKey !== null
      ) || [];

    if (!staffRoles?.length) return { items };

    const distributors: Distributor.Model[] = [];

    staffRoles.forEach((role: any) => {
      if (!distributors.some(dist => dist && dist.id && role.distributor && dist.id === role.distributor.id)) {
        distributors.push(role.distributor);
      }
    });

    distributors.forEach(distributor => {
      const roles = staffRoles.filter(role => role.permissionKey === distributor?.permissionKey);
      const isParent = this.distributor && this.distributor.id === distributor.id;
      const isCoParent = this.isCoParent(distributor, roles, track.get('global'));
      let isSame = false;
      for (const value of track.values()) {
        isSame = this.hasSameRoles(roles, value);
        if (isSame) break;
      }
      if (roles.length && distributor && (!isSame || isParent || isCoParent)) {
        track.set(distributor.id, new Set(roles.map(role => role.roleId)));
        items.push({
          distributor,
          roles,
          ...(isParent && { theme: 'primary' }),
        });
      }
    });

    return { items };
  }

  private hasSameRoles(roles: Staff.Security.RoleAssignment[], value: Set<string>) {
    return roles.every(role => value.has(role.roleId));
  }

  private isCoParent(
    distributor: Distributor.Model,
    roles?: Staff.Security.RoleAssignment[],
    globalRoles?: Set<string>
  ) {
    const isCoParent = distributor.permissionKey.split('dist_').filter(id => id !== '').length === 1;
    if (roles && globalRoles) {
      const isSame = this.hasSameRoles(roles, globalRoles);
      return isCoParent && !isSame;
    }
    return isCoParent;
  }

  private getGlobalRoleBadges(staff?: Staff.Model): DistributorAndRoles | null {
    const roles =
      staff?.roles?.filter(role => {
        return role.permissionKey === null && role.staffId === staff.id;
      }) || [];

    if (!roles?.length) return null;

    return {
      distributor: null,
      roles,
      theme: 'primary',
    };
  }

  private formatDate(date?: Date | null) {
    return date ? moment(date).format('MMM DD, YYYY') : '-';
  }

  refresh() {
    this.refresh$.next(null);
  }

  async refreshTable(filters?: { search?: string; status?: Staff.Status; roleIds?: string[]; distributorId?: string }) {
    if (filters?.roleIds !== undefined) this.filters = { ...this.filters, roleIds: filters.roleIds };
    if (filters?.search !== undefined) this.filters = { ...this.filters, search: filters.search };
    if (filters?.status !== undefined) this.filters = { ...this.filters, status: filters.status };
    if (filters?.distributorId !== undefined) this.filters = { ...this.filters, distributorId: filters.distributorId };
    this.filters$.next(this.filters);
  }

  openUpdateDialog(item: Staff.Model) {
    this.selectedMember = item;
    this.editMemberDialog?.openDialog();
  }

  openDeleteDialog(item: Staff.Model) {
    this.selectedMember = item;
    this.deleteMemberDialog?.openDialog();
  }
}
