/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Inject, Input, OnInit, Output, Type, ViewContainerRef } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Property } from '@vsolv/packages/properties/domain';
import { ReplaySubject, Subject } from 'rxjs';
import { PropertyForm, PROPERTY_FORMS } from '../../constants';
import {
  BooleanInputFieldComponent,
  DateInputFieldComponent,
  NumberInputFieldComponent,
  ObjectInputFieldComponent,
  TextInputFieldComponent,
} from '../properties';
import { PropertyInputComponent } from '../properties/abstract-property-input.component';

@Component({
  selector: 'vs-property-input-form-field',
  template: ``,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PropertyInputFormFieldComponent,
    },
    { provide: NG_VALIDATORS, useExisting: PropertyInputFormFieldComponent, multi: true },
  ],
})
export class PropertyInputFormFieldComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(public viewContainerRef: ViewContainerRef, @Inject(PROPERTY_FORMS) private forms: PropertyForm[]) {}

  @Input() showLabel = true;
  @Input() property!: Property.Model<Property.PropertyType>;
  @Input() required = true;
  @Input() hidden = false;
  @Input() showHidden = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() extraData?: Record<string, any>;

  @Input() order = 0;

  @Input() ignoreRegisteredForm = false;

  @Output() touched = new EventEmitter();

  instance?: PropertyInputComponent;
  value: unknown;

  private onDestroy$ = new Subject<void>();
  private touched$ = new ReplaySubject<void>(1);
  disabled = false;

  validate(): ValidationErrors | null {
    return this.instance?.validate() ?? null;
  }

  private createPropertyInput(property: Property.Model<Property.PropertyType>) {
    let defaultForm: Type<PropertyInputComponent>;
    switch (property.type) {
      case Property.PropertyType.BOOLEAN:
        defaultForm = BooleanInputFieldComponent;
        break;
      case Property.PropertyType.DATE:
        defaultForm = DateInputFieldComponent;
        break;
      case Property.PropertyType.NUMBER:
        defaultForm = NumberInputFieldComponent;
        break;
      case Property.PropertyType.OBJECT:
        defaultForm = ObjectInputFieldComponent;
        break;
      case Property.PropertyType.TEXT:
        defaultForm = TextInputFieldComponent;
        break;

      default:
        throw new Error(`PropertyInputComponent not set up for ${property.type}`);
    }

    if (!this.ignoreRegisteredForm && property.formId) {
      const overrideForm = this.forms.find(form => form.id === this.property.formId);
      if (overrideForm) {
        this.setupComponent(overrideForm.component);
        return;
      }
    }

    this.setupComponent(defaultForm);
  }

  private setupComponent(form: Type<PropertyInputComponent>) {
    const component = this.viewContainerRef.createComponent(form);

    component.setInput('property', this.property);
    component.setInput('required', this.required);
    component.setInput('hidden', this.hidden);
    component.setInput('showHidden', this.showHidden);
    component.setInput('showLabel', this.showLabel);
    component.setInput('extraData', this.extraData);

    component.instance.valueChanges.subscribe((value: unknown) => (this.value = value));

    component.instance.touched.subscribe(() => this.markAsTouched());
    this.instance = component.instance;
  }

  markAsTouched() {
    this.touched.emit();
    this.touched$.next();
  }

  ngOnInit(): void {
    this.createPropertyInput(this.property);
  }

  writeValue(value: unknown): void {
    this.instance?.writeValue(value);
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.instance?.registerOnChange(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.instance?.registerOnTouched(fn);
  }

  setDisabledState?(isDisabled: boolean): void {
    this.instance?.setDisabledState(isDisabled);
  }
}
