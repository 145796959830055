<ng-container *vsLet="data$ | async as data">
  <ng-container *vsLet="canEditOrganization$ | async as canEditOrganization">
    <div>
      <div id="page-header" class="flex flex-col gap-1 pb-2">
        <h1 class="text-lg font-bold">Organization profile</h1>
        <p class="text-sm text-gray-700">Update your company photo and details here.</p>
      </div>
      <vs-divider class="flex my-4"></vs-divider>

      <div id="page-content" class="flex flex-col gap-4 pt-2 pb-8">
        <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
          <div>
            <vs-form-field
              theme="primary"
              class="pt-4 pb-4"
              appearance="responsive"
              [config]="{
              label: 'Organization name',
              hint: '',
              description: 'This will be across the administration portal.'
            }"
            >
              <input vsInput placeholder="VectorsUI" type="text" formControlName="brandName" />
            </vs-form-field>

            <vs-form-field
              theme="primary"
              class="pt-4 pb-4"
              appearance="responsive"
              [config]="{
              label: 'Tagline',
              hint: '',
              description: 'This short description will be shown in headers or below your logo.'
            }"
            >
              <input vsInput placeholder="Your home inspection guarantee" type="text" formControlName="tagLine" />
            </vs-form-field>

            <vs-form-field
              theme="primary"
              class="pt-4 pb-4"
              appearance="responsive"
              [config]="{
              label: 'Product name',
              hint: '',
              description: 'This product name will be used in emails and other descriptions of your company’s services.'
            }"
            >
              <input vsInput placeholder="Protect" type="text" formControlName="productName" />
            </vs-form-field>

            <vs-form-field
              theme="primary"
              class="pt-4 pb-4"
              appearance="responsive"
              [config]="{ label: 'Contact email', description: 'This will be across the administration and customer portals.' }"
            >
              <input vsInput required placeholder="address@email.com" type="text" formControlName="contactEmail" />
            </vs-form-field>

            <vs-form-field
              theme="primary"
              class="pt-4 pb-4"
              appearance="responsive"
              [config]="{ label: 'Contact phone', description: 'This will be across the administration and customer portals.' }"
            >
              <input vsInput required placeholder="+1 (111) 111-1111" type="text" formControlName="contactPhone" />
            </vs-form-field>

            <vs-form-field
              theme="primary"
              class="pt-4 pb-4"
              appearance="responsive"
              [config]="{ label: 'Contact address', description: 'This will be across the administration and customer portals.' }"
            >
              <vs-address-input required formControlName="contactAddress"></vs-address-input>
            </vs-form-field>
          </div>

          <div
            class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pb-10"
            *ngIf="canEditOrganization&&form.dirty"
          >
            <button
              vs-button
              class="sm:flex-none w-full sm:w-fit"
              appearance="outline"
              [disabled]="!form.dirty"
              (click)="resetForm()"
            >
              Cancel
            </button>

            <button
              vs-button
              class="sm:flex-none w-full sm:w-fit"
              theme="primary"
              [disabled]="!canEditOrganization || !inputIsValid() || !form.dirty"
              (click)="confirm()"
            >
              Save
            </button>
          </div>
        </form>
      </div>

      <!--TODO: replace component below with commented block once "dashboard configs" are implemented-->
      <ws-customer-portal-details
        [config]="portalConfigDetail"
        (refresh)="refreshPage()"
        [staff]="data?.staff"
        [canEditOrganization]="canEditOrganization || false"
      ></ws-customer-portal-details>
      <!-- TODO: Need to create a "dashboard config" to properly implement figma (below) -->
      <!-- <vs-tabs theme="primary">
      <vs-tab
        [config]="{
            label: 'Admin Dashboard'
          }"
      >
        <ws-admin-dashboard
          [adminDashboardDetail]="portalConfigDetail"
          (refresh)="refreshPage()"
        ></ws-admin-dashboard>
      </vs-tab>
      <vs-tab
        [config]="{
            label: 'Customer Portal'
          }"
      >
        <ws-customer-portal
          [customerPortalDetails]="portalConfigDetail"
        ></ws-customer-portal>
      </vs-tab>
    </vs-tabs> -->

      <div class="flex flex-col sm:flex-row sm:items-center gap-4 justify-between mt-8 mb-4">
        <div class="flex justify-between w-full">
          <div class="flex flex-col">
            <h1 class="text-lg font-semibold">Distributor Settings</h1>
            <p class="text-sm text-gray-600">Manage your distributor options here.</p>
          </div>

          <button
            *ngIf="distributorForm.dirty"
            vs-button
            theme="primary"
            [disabled]="distributorSaving || (distributorForm.value.selfServeInvoicePackage?.enabled && distributorForm.controls['selfServeInvoicePackage'].invalid)"
            (click)="saveDistributor()"
          >
            Save
          </button>
        </div>
      </div>
    </div>

    <form class="flex flex-col gap-4" [formGroup]="distributorForm">
      <vs-card
        formGroupName="selfServeInvoicePackage"
        [class]="(distributorForm.value.selfServeInvoicePackage?.enabled) ? 'transition-all' : 'transition-all !bg-transparent !border-transparent !shadow-transparent'"
      >
        <vs-card-header
          [class]="(distributorForm.value.selfServeInvoicePackage?.enabled || !canEditOrganization) ? 'transition-all' : 'transition-all !p-0 !border-transparent'"
        >
          <div class="flex">
            <label
              class="flex items-center gap-4 cursor-pointer"
              tooltipPosition="end"
              [vsTooltip]="!canEditOrganization ? 'Insufficient permissions to update settings':''"
            >
              <input
                vsInput
                type="checkbox"
                appearance="toggle"
                formControlName="enabled"
                [disabled]="!canEditOrganization"
              />

              <div class="text-sm">
                <p class="text-gray-700 font-medium">Self-Serve Invoice Packages</p>
                <p class="text-gray-600">
                  Enable distributors to self-serve retrieve their monthly invoice packages directly from the dashboard.
                </p>
              </div>
            </label>
          </div>
        </vs-card-header>

        <vs-card-content
          class="transition-all overflow-hidden [&>vs-form-field]:mb-6"
          [class.h-0]="!distributorForm.value.selfServeInvoicePackage?.enabled || !canEditOrganization"
          [class.!p-0]="!distributorForm.value.selfServeInvoicePackage?.enabled || !canEditOrganization"
        >
          <vs-form-field
            [config]="{ label: 'Invoice logo', description: 'Logo displayed at the top-left of the invoice.' }"
          >
            <div
              *ngIf="logoInput.files?.length || distiSettings?.selfServeInvoicePackage?.logo"
              class="flex flex-col gap-1"
              slot="before-content"
            >
              <img
                class="aspect-square hover:object-contain"
                [style.height]="logoInput.offsetHeight + 'px'"
                [vsImagePreview]="logoInput.files?.[0] ?? distiSettings?.selfServeInvoicePackage?.logo ?? null"
              />
            </div>
            <input #logoInput vsInput type="file" formControlName="logo" accept=".SVG, .PNG, .JPG, .GIF" />
          </vs-form-field>

          <!-- Company name -->
          <vs-form-field
            [config]="{ label: 'Company Name', description: 'Name of the company/entity that will collect payments from distributors.' }"
          >
            <vs-input-container>
              <input required type="text" placeholder="Company name" formControlName="companyName" />
              <vs-icon slot="end" name="building-07"></vs-icon>
            </vs-input-container>
          </vs-form-field>

          <!-- Main place of business -->
          <vs-form-field
            [config]="{ label: 'Main Place of Business', description: 'Address/location of the main place of business of the company/entity collecting payments from distributors.' }"
          >
            <textarea
              vsInput
              required
              rows="3"
              placeholder="Company name / Street address / City, State ZIP"
              formControlName="companyAddress"
            ></textarea>
          </vs-form-field>

          <!-- Banking details -->
          <vs-form-field
            [config]="{ label: 'Banking Details', description: 'Account details to be included in all invoices as the destination for direct deposit payments.' }"
          >
            <vs-input-group class="grid grid-cols-2" [skipMargin]="true" formGroupName="bankingDetails">
              <input
                vsInput
                type="text"
                formControlName="bank"
                placeholder="Bank name"
                style="width: calc(100% - 1px)"
                class="col-span-2 !rounded-t-md !rounded-b-none"
              />
              <input
                #accountNumberInput
                vsInput
                min="1"
                step="1"
                type="number"
                placeholder="Account number"
                formControlName="accountNumber"
                class="-mt-px !rounded-none !rounded-bl-md"
                (change)="accountNumberInput.value = accountNumberInput.value.replace('.', '')"
              />
              <input
                #routingNumberInput
                vsInput
                min="1"
                step="1"
                type="number"
                formControlName="routingNumber"
                placeholder="Wire routing number"
                class="-mt-px -ml-px !rounded-none !rounded-br-md"
                (change)="routingNumberInput.value = routingNumberInput.value.replace('.', '')"
              />
            </vs-input-group>
          </vs-form-field>

          <!-- Unit price -->
          <vs-form-field
            formArrayName="unitPrices"
            [config]="{ label: 'Unit Price', description: 'Cost charged for each warranty sold, configurable per policy plan.' }"
          >
            <div *ngFor="let policy of policies$ | async" class="mb-2">
              <vs-input-group>
                <div vsInputGroupItem style="width: calc(100% - 1px)" [class.!rounded-b-none]="policy.plans?.length">
                  <div
                    tooltipOpenDelay="500"
                    class="flex flex-col justify-center items-start w-full"
                    [vsTooltip]="{ title: policy.policyNumber, subtitle: policy.title }"
                  >
                    <p class="truncate text-xs font-semibold text-gray-700 w-full">{{ policy.title }}</p>
                    <p class="truncate text-xs text-gray-500">{{ policy.policyNumber }}</p>
                  </div>
                </div>
              </vs-input-group>

              <vs-input-group
                *ngFor="let plan of policy.plans; let last = last"
                class="grid grid-cols-2"
                [skipMargin]="true"
              >
                <div
                  vsInputGroupItem
                  class="!px-0 relative overflow-hidden -mt-px rounded-none"
                  [class.!rounded-bl-md]="last"
                >
                  <div class="absolute z-20 left-0 top-0 w-3 h-full bg-gradient-to-r from-base to-transparent"></div>
                  <div class="overflow-x-auto no-scrollbar flex">
                    <p class="whitespace-nowrap text-sm ml-3 mr-2">{{ plan.title }}</p>
                  </div>
                  <div class="absolute z-20 right-0 top-0 w-3 h-full bg-gradient-to-l from-base to-transparent"></div>
                </div>

                <ng-container *ngIf="getControlIndex(plan.id) as controlIndex; else pending">
                  <ng-container [formGroupName]="controlIndex.index">
                    <input
                      vsInput
                      currencyMask
                      type="text"
                      formControlName="price"
                      placeholder="Unit price (USD)"
                      class="-mt-px -ml-px rounded-none"
                      [class.!rounded-br-md]="last"
                      [options]="currencyMaskConfig"
                    />
                  </ng-container>
                </ng-container>

                <ng-template #pending>
                  <input
                    vsInput
                    readonly
                    type="number"
                    placeholder="Unit price (USD)"
                    class="-mt-px -ml-px rounded-none"
                    [class.!rounded-br-md]="last"
                  />
                </ng-template>
              </vs-input-group>
            </div>
          </vs-form-field>
        </vs-card-content>
      </vs-card>

      <div class="flex">
        <label
          class="flex items-center gap-4 cursor-pointer"
          tooltipPosition="end"
          [vsTooltip]="!canEditOrganization ? 'Insufficient permissions to update settings':''"
        >
          <input
            vsInput
            type="checkbox"
            appearance="toggle"
            formControlName="userRegistration"
            [disabled]="!canEditOrganization"
          />

          <div class="text-sm">
            <p class="text-gray-700 font-medium">Member registration</p>
            <p class="text-gray-600">
              Allow an initial member to register on the dashboard during the distributor onboarding process.
            </p>
          </div>
        </label>
      </div>
    </form>
  </ng-container>
</ng-container>
