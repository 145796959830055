/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CdkScrollable } from '@angular/cdk/scrolling';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { extractErrors } from '@vsolv/dev-kit/ngx';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { BehaviorSubject, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { PropertyService } from '../../services';
import { createPropertyControl } from '../properties/abstract-property-input.component';

@Component({
  selector: 'vs-property-set-input',
  templateUrl: './property-set-input.component.html',
  hostDirectives: [CdkScrollable],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PropertySetInputComponent,
    },
    { provide: NG_VALIDATORS, useExisting: PropertySetInputComponent, multi: true },
  ],
})
export class PropertySetInputComponent implements ControlValueAccessor, OnInit, OnDestroy, Validator {
  constructor(private propertySvc: PropertyService) {}

  @PropertyListener('setId') setId$ = new BehaviorSubject<string | null>(null);
  @Input() setId!: string;

  @Input() showHidden = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() extraData?: Record<string, any>;

  form!: FormGroup;

  get valid() {
    return this.form?.valid ?? false;
  }

  private _value: Record<string, unknown> = {};
  get value() {
    return this._value;
  }
  @Input() set value(value: Record<string, unknown>) {
    this._value = value;
    this.form?.patchValue(this._value);
  }

  @Input() disabled = false;

  set!: PropertySet.Model;

  protected onDestroy$ = new Subject<void>();
  protected touched$ = new ReplaySubject<void>(1);

  onChange = (_value: Record<string, unknown>) => {};
  onTouched = () => {};

  validate(): ValidationErrors | null {
    if (this.form) return extractErrors(this.form);
    return null;
  }

  writeValue(value: Record<string, unknown>): void {
    this.value = value;
  }

  registerOnChange(fn: (value: Record<string, unknown>) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
    this.touched$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.onTouched());
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async ngOnInit() {
    this.set = await this.propertySvc.retrievePropertySet(this.setId);

    this.set.properties = this.set.properties?.sort((a, b) => a.order - b.order);
    const properties: Record<string, FormControl> = {};

    for (const assignment of this.set.properties!) {
      const property = assignment.property!;
      properties[property.valueKey] = createPropertyControl(property, assignment.required);
    }

    this.form = new FormGroup(properties);
    this.form.patchValue(this.value);
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => this.onChange(value));

    if (this.disabled) this.form.disable();
    else this.form.enable();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.touched$.complete();
  }
}
