<form [formGroup]="formGroup" class="flex justify-center w-full">
  <div class="flex flex-col flex-wrap gap-3 items-center max-w-[360px] w-full">
    <img class="self-start max-h-12" src="{{ portalSvc.config.logo }}" />

    <h1 class="self-start text-3xl">Log In</h1>

    <vs-form-field class="w-full" [config]="{ label: { text: 'Email', for: 'email-input' } }">
      <input vsInput type="text" id="email-input" placeholder="example@email.com" formControlName="email" />
      <span class="text-danger" slot="hint" *ngIf="!formGroup.get('email')?.valid && formGroup.get('email')?.touched"
        >Your email address should have the following format: example@email.com</span
      >
    </vs-form-field>

    <vs-form-field class="w-full" [config]="{ label: { text: 'Password', for: 'password-input' } }">
      <input vsInput id="password-input" type="password" placeholder="Password" formControlName="password" />
    </vs-form-field>

    <p *ngIf="failed" class="text-xs text-danger-500">
      The email/password combination you entered is invalid. Please double-check and try again.
    </p>

    <button
      class="w-full z-50"
      theme="primary"
      [disabled]="!inputIsValid()"
      vs-button
      appearance="default"
      size="md"
      (click)="submit()"
    >
      <span>Continue</span>
    </button>

    <a class="text-primary z-50" routerLink="../forgot-password">Forgot Password?</a>
  </div>
</form>
