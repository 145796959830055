import { Component, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Property } from '@vsolv/packages/properties/domain';
import { AsYouType } from 'libphonenumber-js';
import { PropertyInputComponent } from '../../abstract-property-input.component';

@Component({
  selector: 'vs-text-input',
  templateUrl: './text-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextInputComponent,
    },
    { provide: NG_VALIDATORS, useExisting: TextInputComponent, multi: true },
  ],
})
export class TextInputComponent extends PropertyInputComponent {
  @Input() override property!: Property.TextModel;

  @Input() allowBlockFormat = true;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  phoneNumberKeyUp(phone: any) {
    if (phone.key === 'Backspace') return;
    this.formatPhoneNumber();
  }

  formatPhoneNumber() {
    if (this.property.config.format !== 'phone') {
      return;
    }
    const phoneNumber = this.formControl?.value;
    if (phoneNumber) {
      this.formControl.patchValue(new AsYouType('US').input(phoneNumber));
    }
  }
}
