<vs-app-page
  [config]="{
    title: 'Reports',
    icon: 'table',

    showDivider: true,
    description: 'This is an overview of all reports that can be downloaded'
  }"
>
  <vs-breadcrumbs slot="breadcrumbs" [config]="{ items: [{ label: 'Reports' }] }"></vs-breadcrumbs>

  <div class="flex flex-wrap gap-3">
    <ng-container *ngIf="canViewReports$ | async">
      <ng-container *ngIf="exportUrl$ | async as exportUrl">
        <vs-card
          class="h-64 w-64 border-1 hover:border-primary-300 ring-primary-100 hover:ring-2 transition-all cursor-pointer"
          (click)="remittanceReportDialog.open()"
        >
          <vs-card-content class="h-full">
            <div class="flex flex-col justify-between h-full">
              <vs-empty-state
                class="flex justify-center items-center self-center mt-3 h-24 max-w-[60%]"
                [config]="{
              imgsrc: 'blank-documents'
            }"
              ></vs-empty-state>
              <div class="flex flex-col">
                <p class="text-lg font-medium text-gray-900">Remittance Report</p>
                <p class="text-xs font-normal text-gray-900">
                  Review the details of all warranties provisioned and the allocation of their fees to determine how
                  much must be remitted to the insurer.
                </p>
              </div>
            </div>
          </vs-card-content>
        </vs-card>

        <vs-dialog #remittanceReportDialog (closed)="startMenuTrigger.close();endMenuTrigger.close()">
          <vs-dialog-header
            [config]="{
            mode: 'simple',
            hideCloseButton: false,
            
            icon: 'download-01',
            iconTheme: 'primary',

            title: 'Download \'Remittance Report\'',
            subtitle: 'Configure the following settings to download your report.' 
          }"
          >
          </vs-dialog-header>

          <div class="gap-2 justify-between my-4 mx-2">
            <p class="text-sm font-medium text-gray-700 self-center">Review all warranties</p>
            <div class="flex gap-x-2 my-2">
              <p class="text-sm font-medium text-gray-700 self-center">From</p>

              <input
                #startMenuTrigger="vsMenuTriggerFor"
                vsInput
                type="text"
                class="max-w-[50%]"
                [value]="startDate$.value |date"
                [vsMenuTriggerFor]="startDateMenu"
              />
              <p class="text-sm font-medium text-gray-700 self-center">To</p>
              <input
                #endMenuTrigger="vsMenuTriggerFor"
                vsInput
                type="text"
                class="max-w-[50%]"
                [value]="endDate$.value | date"
                [vsMenuTriggerFor]="endDateMenu"
              />
            </div>
            <ng-template #startDateMenu>
              <vs-card class="mt-10">
                <vs-date-picker
                  [value]="startDate$.value"
                  (selectionChanged)="startDate$.next($any($event));startMenuTrigger.close()"
                ></vs-date-picker>
              </vs-card>
            </ng-template>

            <ng-template #endDateMenu>
              <vs-card class="mt-10">
                <vs-date-picker
                  [value]="endDate$.value"
                  (selectionChanged)="endDate$.next($any($event));endMenuTrigger.close()"
                ></vs-date-picker>
              </vs-card>
            </ng-template>
          </div>

          <div slot="buttons" class="flex gap-2 w-full">
            <button vs-button class="flex-grow" appearance="outline" (click)="remittanceReportDialog.close()">
              Cancel
            </button>
            <a vs-button class="flex-grow" theme="primary" [href]="exportUrl" (click)="remittanceReportDialog.close()">
              Download
            </a>
          </div>
        </vs-dialog>
      </ng-container>
    </ng-container>

    <vs-card
      class="h-64 w-64 border-1 hover:border-primary-300 ring-primary-100 hover:ring-2 transition-all cursor-pointer"
      (click)="router.navigate(['monthly-financials'], { relativeTo: route })"
    >
      <vs-card-content class="h-full">
        <div class="flex flex-col justify-between h-full">
          <vs-empty-state
            class="flex justify-center items-center self-center mt-3 h-24 max-w-[60%]"
            [config]="{ imgsrc: 'blank-documents' }"
          ></vs-empty-state>

          <div class="flex flex-col">
            <p class="text-lg font-medium text-gray-900">Monthly Financials</p>
            <p class="text-xs font-normal text-gray-900">
              Monthly financial reporting aggregated by policy & plan. Useful for generating invoices, or alternative
              reporting.
            </p>
          </div>
        </div>
      </vs-card-content>
    </vs-card>
  </div>
</vs-app-page>
