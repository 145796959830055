export interface PermissionDisplay {
  title: string;
  description?: string;
}

export interface PermissionProps {
  id: string;
  display: PermissionDisplay;
  hidePermission?: boolean;
}

export class Permission {
  readonly id: string;
  readonly display: PermissionDisplay;
  readonly hidePermission?: boolean;

  constructor(props: PermissionProps) {
    this.id = props.id;
    this.display = props.display;
    this.hidePermission = props.hidePermission;

    registerPermission(this);
  }
}

const permissions = new Map<string, Permission>();

function registerPermission(ability: Permission) {
  if (permissions.has(ability.id)) {
    throw new Error(ability.id + ' is already registered');
  }

  permissions.set(ability.id, ability);

  return ability;
}

export function getPermission(id: string) {
  return permissions.get(id);
}

export function getPermissions(ids: string[]) {
  const permissions: Permission[] = [];

  ids.forEach(id => {
    const permission = getPermission(id);
    if (permission) permissions.push(permission);
  });

  return permissions;
}
