/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { CdkListbox } from '@angular/cdk/listbox';
import { Overlay } from '@angular/cdk/overlay';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Output,
  Pipe,
  PipeTransform,
  QueryList,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { THEME_COLOR, ThemeColor } from '@vsolv/vectors-ui/theming';
import { BehaviorSubject } from 'rxjs';
import { SelectOptionComponent } from '../select-option/select-option.component';

export type SelectListMode = 'default' | 'simple';

export interface SelectConfig {
  multiple?: boolean;
  listMode?: SelectListMode;
  placeholder?: string;
  separator?: string;
  hideSeparator?: boolean;
}

@Pipe({ name: 'optionHTML' })
export class OptionHtmlPipe implements PipeTransform {
  transform<T>(
    options: QueryList<SelectOptionComponent<T>>,
    value: T | null,
    compareWith: (v0: T | null, v1: T | null) => boolean
  ) {
    return options.find(o => compareWith(o.value ?? null, value))?.elementRef.nativeElement.innerHTML;
  }
}

@Component({
  selector: 'vs-select',
  templateUrl: './select.component.html',
  providers: [CdkListbox],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T> implements ControlValueAccessor, AfterContentInit, OnDestroy {
  constructor(
    private _overlay: Overlay,
    public elementRef: ElementRef,
    @Self() @Optional() private ngControl?: NgControl,
    @Optional() @Inject(THEME_COLOR) public themeColor?: ThemeColor
  ) {
    if (this.ngControl) {
      // Note: we provide the value accessor through here, instead of the `providers` to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
  }

  @HostBinding('class') get styles() {
    return `theme-primary-inherit relative flex items-center relative`;
  }

  @ContentChildren(SelectOptionComponent) options = new QueryList<SelectOptionComponent<T>>();

  @Input() value: readonly T[] | (T | null) = this.config?.multiple ? [] : null;
  @PropertyListener('value') value$ = new BehaviorSubject<readonly T[] | (T | null)>(this.value);

  @Input() config?: SelectConfig;

  @Output() valueChanges = new EventEmitter<readonly T[] | (T | null)>();
  @Output() openChanged = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<void>();

  touched = false;
  disabled = false;
  overlayOpen = false;

  protected scrollStrategy = this._overlay.scrollStrategies.reposition();

  keyManager?: ActiveDescendantKeyManager<SelectOptionComponent<T>>;

  @Input() compareWith: (v0: T | null, v1: T | null) => boolean = (v0: T | null, v1: T | null) => {
    switch (typeof v0) {
      case 'object':
        return JSON.stringify(v0) === JSON.stringify(v1);
      default:
        return v0 === v1;
    }
  };

  @HostListener('keydown', ['$event'])
  _handleKeydown(event: KeyboardEvent) {
    if (!this.disabled && !this.overlayOpen && !this.config?.multiple) {
      this.keyManager?.onKeydown(event);
    }
  }

  ngAfterContentInit() {
    this.keyManager = new ActiveDescendantKeyManager(this.options).withTypeAhead();
    this.keyManager.change.subscribe(() => {
      if (this.keyManager?.activeItem?.value) {
        this.selectValueChanged([this.keyManager.activeItem.value]);
      }
    });
  }

  open() {
    this.overlayOpen = true;
    this.openChanged.emit(true);
  }

  close() {
    this.overlayOpen = false;
    this.openChanged.emit(false);
  }

  toggle() {
    this.markAsTouched();
    if (this.overlayOpen) this.close();
    else this.open();
  }

  selectValueChanged(value: readonly T[]) {
    this.markAsTouched();
    this.value = this.config?.multiple ? value : value.length ? value[0] : null;

    // Close the options if not selecting multiple.
    if (!this.config?.multiple) this.close();

    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  onChange = (_value: readonly T[] | T | null) => {};
  onTouched = () => {};

  writeValue(value: readonly T[] | T | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: readonly T[] | T | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.keyManager?.destroy();
  }
}
