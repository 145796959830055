<div class="flex flex-col gap-4">
  <p class="text-gray-700 font-semibold mt-4 -mb-2">Existing Accounts</p>

  <ng-container *vsLet="(fundingSources?.length ?? 0) > 3 ? 'h-[270px] overflow-auto' : '' as overFlow">
    <form [formGroup]="existingAccount" [class]="'flex flex-col gap-4 ' + overFlow">
      <ng-container *ngFor="let fundingSource of fundingSources; let index = index">
        <label [for]="fundingSource.id" class="flex py-4 px-6 gap-4 items-center border border-primary-200 rounded-xl">
          <ng-container *ngIf="addDirective; else noDirective">
            <input
              vsInput
              type="radio"
              [value]="fundingSource.id"
              name="fundingSourceRadio"
              [id]="fundingSource.id"
              formControlName="fundingSourceRadio"
            />
          </ng-container>

          <ng-template #noDirective>
            <input
              type="radio"
              [value]="fundingSource.id"
              name="fundingSourceRadio"
              formControlName="fundingSourceRadio"
            />
          </ng-template>

          <div class="flex flex-col">
            <span class="text-gray-700 font-semibold">{{ fundingSource.bankName }}</span>
            <span class="text-sm text-gray-400">{{ fundingSource.name }}</span>
          </div>
        </label>
      </ng-container>
    </form>
  </ng-container>

  <div class="flex gap-2">
    <vs-divider class="self-center"></vs-divider>
    <span class="text-sm text-gray-500">Or</span>
    <vs-divider class="self-center"></vs-divider>
  </div>

  <ng-container *ngIf="(fundingSources?.length ?? 0) >= maxNumberOfFundingSources; else accountsUnderMaxLimit">
    <div
      [vsTooltip]="{
        title:
          (fundingSources?.length ?? 0) >= maxNumberOfFundingSources
            ? 'Maximum amount of bank accounts for this customer reached. Please use an existing account. '
            : '',
        subtitle: ''
      }"
      tooltipPosition="bottom"
    >
      <button
        (click)="changeDialogState('add')"
        class="flex p-4 w-full bg-primary-50 text-primary-700 items-center gap-4 rounded-xl disabled:opacity-60"
        [disabled]="(fundingSources?.length ?? 0) >= maxNumberOfFundingSources"
      >
        <vs-icon name="plus" size="20"></vs-icon>
        <span>Add New Bank Account</span>
      </button>
    </div>
  </ng-container>

  <ng-template #accountsUnderMaxLimit>
    <button
      (click)="changeDialogState('add')"
      class="flex p-4 w-full bg-primary-50 text-primary-700 items-center gap-4 rounded-xl disabled:opacity-60"
    >
      <vs-icon name="plus"></vs-icon>
      <span>Add New Bank Account</span>
    </button>
  </ng-template>

  <p class="text-gray-400 text-xs">
    Your banking details are securely collected by our financial partner and never stored in our systems.
  </p>

  <div class="flex gap-4 justify-end w-full">
    <button vs-button size="md" appearance="outline" (click)="close()">Cancel</button>
    <button
      (click)="confirmBankDetails()"
      vs-button
      size="md"
      theme="primary"
      [class.text-base]="!addDirective"
      [disabled]="!existingAccount.valid"
    >
      Confirm Details
    </button>
  </div>
</div>
