import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateCustomerOptions, DwollaTokenRequest, FundingSource } from '@vsolv/dwolla/domain';
import { catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class DwollaService {
  constructor(private http: HttpClient) {}

  getFundingSourceToken(customer: CreateCustomerOptions) {
    return firstValueFrom(
      this.http.post<string>(`/api/dwolla/funding-source-token`, customer).pipe(
        catchError(error => {
          firstValueFrom(
            this.http.post('/api/internal_log', {
              error: {
                message: '[DwollaService] Unable to retrieve funding source token',
                error,
              },
            })
          ).catch();

          return of(null);
        })
      )
    );
  }

  getFundingSource(fundingSourceId: string, customerId: string) {
    return firstValueFrom(
      this.http
        .get<FundingSource>(`/api/dwolla/fundingSources/retrieve`, {
          params: {
            fundingSourceId,
            customerId,
          },
        })
        .pipe(catchError(() => of(null)))
    );
  }

  listCustomerFundingSource(customerEmail: string) {
    return firstValueFrom(
      this.http
        .get<FundingSource[]>(`/api/dwolla/customers/fundingSources/list`, {
          params: {
            customerEmail,
          },
        })
        .pipe(catchError(() => of(null)))
    );
  }

  listFundingSource(customerEmail: string) {
    return firstValueFrom(
      this.http
        .get<FundingSource[]>(`/api/dwolla/fundingSources/list`, {
          params: {
            customerEmail,
          },
        })
        .pipe(catchError(() => of(null)))
    );
  }

  getToken(body: DwollaTokenRequest) {
    return firstValueFrom(this.http.post(`/api/dwolla/token`, body).pipe(catchError(() => of(null))));
  }

  findCustomer(email?: string) {
    return firstValueFrom(
      this.http
        .get<{ id: string }>(`/api/dwolla/customers/`, {
          params: {
            ...(email && { email }),
          },
        })
        .pipe(catchError(() => of(null)))
    );
  }
}
