import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { AngularSplitModule } from 'angular-split';
import { AppPageComponent, AppSplitPageComponent, EmptyStateComponent } from './components';
@NgModule({
  imports: [
    CommonModule,
    IconsModule,
    LayoutModule,
    OverlayModule,
    AngularSplitModule,
    ScrollingModule,
    DividerModule,
    TooltipModule,
  ],
  declarations: [AppPageComponent, AppSplitPageComponent, EmptyStateComponent],
  exports: [AppPageComponent, AppSplitPageComponent, EmptyStateComponent],
})
export class AppPageModule {}
