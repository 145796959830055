import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-delete-plan-dialog',
  templateUrl: './delete-plan.dialog.html',
})
export class DeletePlanDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policySvc: PolicyService,
    private toastSvc: ToastService
  ) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy?: Policy.Model | null;
  @Input() plan?: Policy.Plan.Model | null;
  @Input() dialogConfig?: DialogConfig;

  saving = false;
  draftStatus = Policy.Status.DRAFT;

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  cancel() {
    this.close();
  }

  async deletePlan() {
    if (!this.policy) throw new Error('Unable to delete policy, policy not found');
    if (!this.plan) throw new Error('Unable to delete plan, plan not found');

    this.saving = true;

    const policyId = this.policy.id;
    const planId = this.plan.id;

    const deletedPlan = await this.policySvc.deletePlan(planId, policyId).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
      this.saving = false;
    });

    if (deletedPlan?.id) {
      if (this.policy.id === deletedPlan.policyId) {
        this.toastSvc.show({
          type: 'success',
          title: 'Plan Removed',
          text: '<strong>' + this.plan?.title + '</strong> has been successfully deleted.',
        });

        this.policySvc.refreshPolicy();
        this.policySvc.clearPlan();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text:
            '<strong>' +
            this.plan?.title +
            '</strong> has been successfully deleted and a new version of <strong>' +
            this.policy.title +
            '</strong> has been created.',
        });

        this.policySvc.refreshPolicy(deletedPlan.policyId);
        this.policySvc.clearPlan();
        this.router.navigate([`../../../${deletedPlan.policyId}/plans`], { relativeTo: this.route });
      }

      this.close();
      this.saving = false;
    }
  }
}
