/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Distributor } from '@wsphere/distributors/domain';
import { PermissionId, Staff } from '@wsphere/staff/domain';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { RoleAssignmentsService } from './role-assignments.service';
import { StaffService } from './staff.service';

export interface StoredPermission {
  staffId: string;
  roleId: string;
  permissionId: string;
  permission?: Staff.Security.Permission;
  role?: Staff.Security.Role;
  permissionKey: string | null;
}

export const GLOBAL_DISTRIBUTORS_STORAGE_KEY = 'globalDistributors';
@Injectable({ providedIn: 'root' })
export class SecurityService {
  constructor(private http: HttpClient, private roleSvc: RoleAssignmentsService, private staffSvc: StaffService) {}

  /** Behaviour subject of the currently selected distributors from the global picker. Only for the admin dashboard */
  globalDistributors$ = new BehaviorSubject<Distributor.PartialModel[] | null>(null);
  /** Sets the behaviour subject for the global distributors. Only to be used by the global picker */
  setGlobalDistributors(distributors: Distributor.PartialModel[] | null) {
    this.globalDistributors$.next(distributors);
    if (distributors)
      sessionStorage.setItem(
        GLOBAL_DISTRIBUTORS_STORAGE_KEY,
        JSON.stringify(distributors.map(dist => dist.permissionKey))
      );
    else sessionStorage.removeItem(GLOBAL_DISTRIBUTORS_STORAGE_KEY);
  }

  async hasAccess(permissionId: PermissionId, permissionKeys: string[] | null) {
    const matrix = (await this.loadPermissionsMatrixFromStorage()).filter(
      permission => permission.permissionId === permissionId || permission.permissionId === '*'
    );

    if (permissionKeys && permissionKeys.length) {
      return matrix.some(
        permission =>
          permission.permissionKey === null ||
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          permissionKeys.some(key => key.startsWith(permission.permissionKey!))
      );
    } else return matrix.some(permission => permission.permissionKey === null);
  }

  async hasRole(roleId: string, permissionKey: string | null) {
    const matrix = (await this.loadPermissionsMatrixFromStorage()).filter(
      permission => permission.roleId === roleId || permission.roleId === '*'
    );

    return (
      matrix.filter(permission => (permissionKey ? permission.permissionKey?.startsWith(permissionKey) || false : true))
        .length > 0
    );
  }

  async loadPermissionsMatrixFromStorage(): Promise<StoredPermission[]> {
    const storage = sessionStorage.getItem(`permissions`);
    if (!storage) {
      const permissions = await this.roleSvc.getStaffRoles();
      if (!permissions?.length) return [];

      const matrix = (await this.savePermissionsMatrix(permissions)).map(stored => {
        return {
          ...stored,
          permission: Staff.Security.getPermission(stored.permissionId),
          role: Staff.Security.getRole(stored.roleId),
        };
      });

      return matrix;
    } else {
      return JSON.parse(storage).map((stored: StoredPermission) => {
        return {
          ...stored,
          permission: Staff.Security.getPermission(stored.permissionId),
          role: Staff.Security.getRole(stored.roleId),
        };
      });
    }
  }

  async savePermissionsMatrix(roleAssignments: Staff.Security.RoleAssignment[]) {
    if (!roleAssignments.length) return [];

    const matrix: StoredPermission[] = [];
    for (const assignment of roleAssignments) {
      const role = Staff.Security.getRole(assignment.roleId);
      role?.permissions.forEach(permissionId => {
        matrix.push({
          permissionId,
          staffId: assignment.staffId,
          roleId: assignment.roleId,
          permissionKey: assignment.permissionKey,
        });
      });
    }
    sessionStorage.setItem(`permissions`, JSON.stringify(matrix));
    return matrix;
  }

  async searchDistributorsWithPermissions(
    pagination: { page?: number; limit?: number },
    search?: string,
    parentId?: string
  ) {
    return await firstValueFrom(
      this.http.get<Staff.Security.ListDistributorsWithPermissionsQueryResponse>(`/api/distributors/roles`, {
        params: {
          page: pagination.page ?? 1,
          limit: pagination.limit ?? 10,
          ...(search && { search }),
          ...(parentId && { parentId }),
        },
      })
    );
  }
  async getDistributorsWithAccess(
    pagination: { page?: number; limit?: number },
    parentId?: string,
    permissionKeys?: string[]
  ) {
    return await firstValueFrom(
      this.http.get<Distributor.ListChildrenDistributorsQueryResponse>(`/api/distributors/roles/children`, {
        params: {
          page: pagination.page ?? 1,
          limit: pagination.limit ?? 10,
          ...(parentId && { parentId }),
          ...(permissionKeys && { permissionKeys }),
        },
      })
    );
  }

  listDistributorsWithPermissionKeys(dto: Distributor.ListDistributorsWithPermissionKeyQueryRequest) {
    return firstValueFrom(
      this.http.get<Distributor.ListDistributorsWithPermissionKeyQueryResponse>(`/api/distributors/permissionkeys`, {
        params: {
          ...(dto.page && { page: dto.page }),
          ...(dto.limit && { limit: dto.limit }),
          ...(dto.search && { search: dto.search }),
          ...(dto.permissionKeys && { permissionKeys: dto.permissionKeys }),
          permissionId: dto.permissionId,
        },
      })
    );
  }
}
