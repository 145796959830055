<label *ngIf="showLabel" class="text-sm text-gray-700 font-medium">{{ property.name }}</label>
<form
  class="ml-2 flex flex-col gap-1"
  [formGroup]="form"
  *vsLet="assignments$ | async as assignments"
  [class]="open ? 'visible' : 'hidden'"
>
  <ng-container *ngFor="let assignment of $any(assignments | sortProperties : showHidden)">
    <vs-property-input-form-field
      *ngIf="showHidden || !assignment.hidden || assignment.required"
      [property]="assignment.property!"
      [required]="assignment.required"
      [hidden]="assignment.hidden"
      [showHidden]="showHidden"
      [formControlName]="assignment.property!.valueKey"
      (touched)="markAsTouched()"
      [required]="assignment.required"
    >
    </vs-property-input-form-field>
  </ng-container>
</form>
