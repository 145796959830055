/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, HostBinding, Input } from '@angular/core';

export type EmptyStateType =
  | 'documents'
  | 'distributor'
  | 'empty-clipboard'
  | 'empty-team'
  | 'no-link'
  | 'policy-documents'
  | 'blank-documents';

export interface EmptyStateConfig {
  title?: string;
  subtitle?: string;

  imgsrc?: EmptyStateType;
}

@Component({
  selector: 'vs-empty-state',
  templateUrl: 'empty-state.component.html',
})
export class EmptyStateComponent {
  @HostBinding('class') get styles(): string {
    return 'w-full';
  }

  @Input() config?: EmptyStateConfig;
}
