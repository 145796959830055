import { Component, inject, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateCustomerOptions, FundingSource } from '@vsolv/dwolla/domain';
import { DwollaService } from '@vsolv/packages/reimbursement/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { catchError, combineLatest, map, Observable, of, switchMap } from 'rxjs';
import { Payment } from '../../pages';
import { ClaimService } from '../../services';
import { PaymentDialog } from '../payment/payment.dialog';

@Component({
  selector: 'ws-manage-payment',
  templateUrl: './manage-payment.dialog.html',
})
export class ManagePaymentDialog {
  @ViewChild('dialog') private readonly dialog?: DialogComponent;
  @ViewChild(PaymentDialog) paymentDialog?: PaymentDialog;

  private readonly claimSvc = inject(ClaimService);
  private readonly toastSvc = inject(ToastService);
  private readonly dwollaSvc = inject(DwollaService);
  private readonly linkSvc = inject(LinksWebService);
  private readonly securitySvc = inject(SecurityService);

  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  protected refreshAfterClose = false;

  protected readonly payment$ = this.route.data.pipe(
    map(data => data['payment'] as Claim.ClaimPayment.Model),
    switchMap(async payment => {
      if (!payment) return null;
      const linkItems = await this.linkSvc.list(
        { id: payment.claimId, objectType: Link.ObjectType.CLAIM },
        {
          owner: { id: payment.id, objectType: Link.ObjectType.PAYMENT },
          linkType: Link.ObjectType.CLAIM_ITEM,
        }
      );

      const items: Claim.ClaimItem.Model[] = [];

      linkItems.items.forEach(link => {
        const item = link.claimItem_1 ? link.claimItem_1 : link.claimItem_2 ? link.claimItem_2 : null;
        if (item) {
          items.push(item);
        }
      });

      const links = await this.linkSvc.list(
        { id: payment.claimId, objectType: Link.ObjectType.CLAIM },
        {
          owner: { id: payment.id, objectType: Link.ObjectType.PAYMENT },
        }
      );

      const linksAndCards = links.items.map(link => {
        const type = Link.getLinkType(link, Link.ObjectType.ACTIVITY);
        const config = Link.getLinkInfoCardConfig(link, type, payment.id);
        return { link, card: config };
      });

      const destination = payment.destination
        ? await this.dwollaSvc.getFundingSource(payment?.destination, payment.customerId)
        : null;

      return {
        ...payment,
        ...(destination && { destinationName: `${destination?.bankName} - ${destination?.name}` }),
        items,
        links: linksAndCards,
      };
    })
  );
  protected readonly claim$ = this.claimSvc.getClaim();

  protected readonly destinations$: Observable<FundingSource[] | null> = this.claim$.pipe(
    switchMap(async claim =>
      claim?.warranty?.customer ? this.dwollaSvc.listFundingSource(claim.warranty?.customer.email) : null
    )
  );

  private readonly canEdit$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManagePayments', permissionKey);
    })
  );
  private readonly canIssuePayments$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_IssuePayment', permissionKey);
    })
  );

  private readonly canFinishPayments$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_FinishPayment', permissionKey);
    })
  );

  private readonly canManagePaymentMethods$ = this.claim$?.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManagePaymentMethod', permissionKey);
    })
  );

  protected readonly permissionChecks$ = combineLatest([
    this.canEdit$,
    this.canIssuePayments$,
    this.canFinishPayments$,
    this.canManagePaymentMethods$,
  ]).pipe(
    switchMap(async ([canEdit, canIssue, canVoid, canManagePaymentMethods]) => ({
      canEdit,
      canIssue,
      canVoid,
      canManagePaymentMethods,
    }))
  );

  protected readonly customer$: Observable<CreateCustomerOptions | null> = this.claim$.pipe(
    map(claim => {
      return {
        email: claim?.warranty?.customer?.email,
        lastName:
          claim?.warranty?.customer?.name.substring(claim?.warranty?.customer?.name.indexOf(' ') + 1) ||
          claim?.warranty?.customer?.name.substring(0, claim?.warranty?.customer?.name.indexOf(' ')),
        firstName:
          claim?.warranty?.customer?.name.substring(0, claim?.warranty?.customer?.name.indexOf(' ')) ||
          claim?.warranty?.customer?.name.substring(claim?.warranty?.customer?.name.indexOf(' ') + 1),
        correlationId: claim?.warranty?.customer?.id,
      } as CreateCustomerOptions;
    }),
    catchError(() => of(null))
  );

  protected readonly customerId$ = this.customer$.pipe(
    switchMap(async customer => (customer ? this.dwollaSvc.findCustomer(customer.email) : null))
  );

  protected readonly pending = 'PENDING';

  protected readonly status: { [key in Claim.ClaimPayment.Status]: ThemeColor } = {
    [Claim.ClaimPayment.Status.DRAFT]: 'default',
    [Claim.ClaimPayment.Status.COMPLETED]: 'success',
    [Claim.ClaimPayment.Status.FAILED]: 'danger',
    [Claim.ClaimPayment.Status.ISSUED]: 'info',
    [Claim.ClaimPayment.Status.VOIDED]: 'default',
  };

  protected readonly paymentStatus = Claim.ClaimPayment.Status;

  protected close() {
    this.dialog?.close();
  }

  protected async issue(paymentId: string, claimId: string, title: string) {
    const response = await this.claimSvc.issuePayment(claimId, paymentId).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response?.id) {
      this.toastSvc.show({
        type: 'success',
        title: `Payment issued!`,
        text: `Your payment "<strong>${title}</strong>" has been successfully issued.`,
      });
      this.refreshAfterClose = true;
      this.close();
    }
  }

  protected async void(paymentId: string, claimId: string, title: string) {
    const response = await this.claimSvc.voidPayment(claimId, paymentId).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response?.id) {
      this.toastSvc.show({
        type: 'success',
        title: `Payment voided!`,
        text: `Your payment "<strong>${title}</strong>" has been successfully voided.`,
      });
      this.refreshAfterClose = true;
      this.close();
    }
  }

  protected async update(payment: Payment, claim: Claim.Model) {
    this.paymentDialog?.open(claim, payment);
  }

  protected getItemTheme(item?: Claim.ClaimItem.Model) {
    return item ? Claim.ClaimItem.getTheme(item.status) : 'default';
  }

  protected async navigateBack(claimId: string) {
    if (!this.refreshAfterClose) {
      this.router.navigate(['..'], { relativeTo: this.route });
    } else {
      this.router.navigateByUrl('/refresh', { skipLocationChange: true, state: { isLoading: true } }).then(() => {
        setTimeout(() => {
          this.router.navigateByUrl(`/claims/${claimId}/payments`);
        }, 1);
      });
    }
  }
}
