/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  Inject,
  InjectionToken,
  Injector,
  Input,
  Optional,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService, StaffService } from '@wsphere/staff/web';
import {
  combineLatest,
  distinctUntilChanged,
  from,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  switchMap,
} from 'rxjs';
import { CreateDistributorDialog } from '../../dialogs';

export const DISTRIBUTOR_PAGE_CONTENT = new InjectionToken<Promise<Type<{ distributorId: string }>>>(
  'DISTRIBUTOR_PAGE_CONTENT'
);

@Component({
  selector: 'ws-distributor-overview-page',
  templateUrl: './distributor-overview.page.html',
})
export class DistributorOverviewPage {
  constructor(
    private router: Router,
    private injector: Injector,
    private route: ActivatedRoute,
    private staffSvc: StaffService,
    private securitySvc: SecurityService,
    @Optional() @Inject(DISTRIBUTOR_PAGE_CONTENT) protected content: Promise<Type<{ distributorId: string }>> | null
  ) {}

  @ViewChild('editDialog') editDialog?: CreateDistributorDialog;
  @ViewChild('editSubDialog') editSubDialog?: CreateDistributorDialog;
  @ViewChild('createSubDialog') createSubDialog?: CreateDistributorDialog;

  @PropertyListener('distributor') private distributor$ = new ReplaySubject<Distributor.Model | undefined>(undefined);
  @Input() distributor?: Distributor.Model;

  protected contentInputs$ = this.distributor$.pipe(
    map(distributor => distributor?.id),
    distinctUntilChanged(),
    map(distributorId =>
      Injector.create({
        providers: [{ provide: 'inputs', useValue: { distributorId } }],
        parent: this.injector,
      })
    )
  );

  @Input() onSettings = false;

  @Output() distributorChanged = new EventEmitter<string>();

  staff$ = from(this.staffSvc.retrieveSelf()).pipe(shareReplay(1));

  readonly canUpdateDistributor$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(async ([distributor]) => {
      return await this.securitySvc.hasAccess(
        'dist_EditDetails',
        distributor?.permissionKey ? [distributor.permissionKey] : null
      );
    })
  );

  readonly canCreateDistributor$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(async ([distributor]) => {
      return await this.securitySvc.hasAccess(
        'dist_CreateDistributor',
        distributor?.permissionKey ? [distributor.permissionKey] : null
      );
    })
  );

  totalDistributors = 0;

  selectedSub?: Distributor.Model;
  active = Distributor.Status.ACTIVE;

  openEditDialog() {
    this.editDialog?.openDialog();
  }

  openCreateSubDialog() {
    this.createSubDialog?.openDialog();
  }

  openUpdateSubDialog(distributor: Distributor.Model) {
    this.selectedSub = distributor;
    this.editSubDialog?.openDialog();
  }

  distributorUpdated() {
    if (this.distributor) this.distributorChanged.emit(this.distributor.id);
  }

  navigateTo(path?: string) {
    if (path) this.router.navigate([`../${path}`], { relativeTo: this.route });
  }
}
