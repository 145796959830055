import { Component, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { Policy, Provisioning, Warranty } from '@wsphere/warranties/domain';
import { BehaviorSubject, map } from 'rxjs';
import { CheckoutSteps } from '../../pages';
import { ProvisioningSessionService } from '../../services';

type SummaryState = 'DESKTOP' | 'MOBILE';

@Component({
  selector: 'ws-order-summary',
  templateUrl: './order-summary.component.html',
})
export class OrderSummaryComponent implements OnInit {
  @ViewChild('confirmCancellation') confirmCancellationDialog?: DialogComponent;

  private sessionSvc = inject(ProvisioningSessionService);

  @Output() closeDialog = new EventEmitter<void>();
  @Output() updatePreview = new EventEmitter<{ sessionId: string; data: Provisioning.UpdateProvisioningSessionDto }>();

  @Input() distributor: Distributor.Model | null = null;

  @Input() session: Provisioning.ProvisioningSession | null = null;

  @Input() loading: boolean | null = null;

  @PropertyListener('_preview') preview$ = new BehaviorSubject<Provisioning.WarrantyPreview | null>(null);
  @Input() _preview: Provisioning.WarrantyPreview | null = null;

  selectedTerm$ = this.preview$.pipe(
    map(preview => preview?.session.policy?.terms.find(term => term.id === preview.session.policyTermId) ?? null)
  );

  @Input() coverages: Warranty.WarrantyCoverage[] | null = null;

  @Input() addons: Provisioning.PreviewAddon[] | null = null;

  @Input() currentStep: CheckoutSteps | null = null;

  @Input() state: SummaryState = 'DESKTOP';

  @Input() staffView = false;

  protected finalStep = CheckoutSteps.Checkout;

  protected open = false;

  innerHeight = 0;

  protected async removeAddon(
    selectedAddon: Policy.Plan.Addon,
    addons: Provisioning.PreviewAddon[],
    sessionId: string
  ) {
    const addonIds = addons.map(addon => addon.addon.id);
    const updatedList = addonIds.filter(id => id !== selectedAddon.id);
    const data: Provisioning.UpdateProvisioningSessionDto = {
      addonIds: updatedList,
    };
    this.updatePreview.emit({ sessionId, data });
  }

  cancel() {
    this.closeDialog.emit();
    this.sessionSvc.clearSession();
    this.sessionSvc.distributor$.next(null);
    this.sessionSvc.addons$.next(null);
    this.sessionSvc.preview$.next(null);
    // FIXME: Provisioning session local storage
    // if (localStorage.getItem('provisioning-session')) localStorage.removeItem('provisioning-session');
  }

  resize() {
    this.innerHeight = (window.innerHeight / 6) * 5;
  }

  getTotalAmount(preview: Provisioning.WarrantyPreview) {
    let sub = preview.subtotal;
    preview.taxes.forEach(tax => (sub += tax.amount));
    return sub;
  }

  ngOnInit(): void {
    this.resize();
  }
}
