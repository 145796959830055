import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import {
  BadgeColumn,
  MultiTagColumn,
  PaginationConfig,
  TableColumn,
  TextColumn,
  UserInfoColumn,
} from '@vsolv/vectors/table';
import { Customer } from '@wsphere/customers/domain';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService } from '@wsphere/staff/web';
import { Claim, ClaimLifecycleStep, Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { BehaviorSubject, combineLatest, firstValueFrom, map, Observable, Subject, switchMap, tap } from 'rxjs';
import { OpenClaimDialog } from '../../dialogs';
import { ClaimService } from '../../services';

export type ClaimsTableFilters = Pick<
  Claim.ListClaimsQueryRequest,
  | 'search'
  | 'statuses'
  | 'claimItemStatuses'
  | 'claimPaymentStatuses'
  | 'claimLifecycleSteps'
  | 'customerId'
  | 'distributorId'
  | 'warrantyId'
>;

@Component({
  selector: 'ws-claims-table',
  templateUrl: './claims-table.component.html',
})
export class ClaimsTableComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimsSvc: ClaimService,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('openClaimDialog') openClaimDialog?: OpenClaimDialog;

  @PropertyListener('warranty') private warranty$ = new BehaviorSubject<Warranty.Model | null>(null);
  @Input() warranty: Warranty.Model | null = null;

  @PropertyListener('hideCustomer') private hideCustomer$ = new BehaviorSubject<boolean>(false);
  @Input() hideCustomer?: boolean;

  @PropertyListener('isCustomerPortal') private isCustomerPortal$ = new BehaviorSubject<boolean>(false);
  @Input() isCustomerPortal?: boolean;

  @Input() customerId?: string;

  @Input() hideBorder?: boolean;
  @Input() hideFilters?: boolean;

  @Input() isPastWaitingPeriod!: boolean | null;
  @Input() remainingPeriod!: number | null;
  @Input() period!: string | null;

  open = Claim.Status.OPEN;
  active = Warranty.Status.ACTIVATED;

  statuses = Object.keys(Claim.Status);
  itemStatuses = Object.keys(Claim.ClaimItem.Status);
  paymentStatuses = Object.keys(Claim.ClaimPayment.Status);

  distributors: Distributor.Model[] = [];
  filters: ClaimsTableFilters | null = null;

  private onDestroy$ = new Subject<void>();

  private filters$ = new BehaviorSubject(this.filters);
  private refresh$ = new BehaviorSubject<null>(null);

  protected lifeCycleSteps$ = this.refresh$.pipe(switchMap(() => this.claimsSvc.lifecycleStepsFilter()));

  readonly pagination$ = new BehaviorSubject({ currentPage: 1, itemsPerPage: 10 });
  paginationQueryRequest$ = new BehaviorSubject<{ page?: number; limit?: number }>({ page: 1, limit: 10 });

  claims$ = combineLatest([
    this.paginationQueryRequest$,
    this.filters$,
    this.warranty$,
    this.securitySvc.globalDistributors$,
    this.refresh$,
  ]).pipe(
    switchMap(async ([pagination, filters, warranty]) => {
      return await this.claimsSvc.list({
        ...pagination,
        search: filters?.search,
        statuses: filters?.statuses,
        distributorId: filters?.distributorId,
        claimItemStatuses: filters?.claimItemStatuses,
        claimLifecycleSteps: filters?.claimLifecycleSteps,
        claimPaymentStatuses: filters?.claimPaymentStatuses,
        customerId: this.customerId ? this.customerId : filters?.customerId,
        warrantyId: filters?.warrantyId || warranty?.id,
      });
    }),
    tap(data =>
      this.distributors.length === 0
        ? (this.distributors = data?.items?.reduce((acc, claim) => {
            if (
              claim.warranty?.distributor &&
              !acc.map(distributor => distributor.id).includes(claim.warranty.distributor.id || '')
            ) {
              acc.push(claim.warranty.distributor);
            }
            return acc;
          }, [] as Distributor.Model[]))
        : 0
    )
  );

  paginationConfig$ = this.claims$.pipe(
    map((claims: Claim.ListClaimsQueryResponse) => {
      const paginationConfig: PaginationConfig = {
        itemsPerPage: claims?.meta?.itemsPerPage,
        currentPage: claims?.meta?.currentPage,
        pageSizes: [10, 20, 50],
        totalItems: claims?.meta?.totalItems || 0,
      };

      return paginationConfig;
    })
  );

  columns$: Observable<TableColumn<unknown>[]> = combineLatest([this.hideCustomer$, this.isCustomerPortal$]).pipe(
    map(([hideCustomer, isCustomerPortal]) => {
      return [
        new TextColumn({ header: 'Number', fitContent: true }, (item: Claim.Model) => {
          const claimNumberParts = item.claimNumber.split('-');
          const number = claimNumberParts[claimNumberParts.length - 2];
          const last4 = number.substring(number.length - 4, number.length);

          return {
            text: claimNumberParts[0] + '...' + last4 + '-' + claimNumberParts.pop(),
            tooltip: item?.claimNumber,
            classes: 'whitespace-nowrap',
          };
        }),

        ...(!hideCustomer
          ? [
              new UserInfoColumn({ header: 'Customer', fitContent: true }, (item: Claim.Model) => ({
                name: item?.warranty?.customer?.name,
                email: item?.warranty?.customer?.email,
                photoUrl: item?.warranty?.customer?.user?.photoURL,
              })),
            ]
          : []),

        new TextColumn({ header: 'Warranty', fitContent: true }, (item: Claim.Model) => ({
          text: item?.warranty?.plan?.title || '',
          description: item?.warranty?.contractNumber || '',
          tooltip: item?.warranty?.plan?.title,
          classes: 'truncate max-w-[164px]',
        })),

        new BadgeColumn({ header: 'Status', fitContent: true }, (item: Claim.Model) => ({
          theme: this.getBadgeStatusColor(item.status)?.color,
          text: this.getBadgeStatusColor(item.status)?.text,
          displayStatusIcon: true,
        })),

        new BadgeColumn({ header: 'Lifecycle', fitContent: true }, (item: Claim.Model) => {
          const step = this.getCurrentStep(item.claimLifecycleSteps);
          return {
            theme: this.getStepTheme(step?.status),
            text: step?.title,
            displayStatusIcon: true,
          };
        }),

        new MultiTagColumn<Claim.Model>({ header: 'Item(s)', fitContent: true }, async claim => {
          if (!claim || !claim.items) return { items: [] };

          return {
            limit: 1,
            classes: claim.items?.length ? 'w-[256px] max-w-[256px]' : '',
            items: claim.items.map(item => ({
              text: item.coverage?.title || 'Other',
              theme: this.getClaimItemStatusColor(item.status),
              displayStatusIcon: true,
            })),
          };
        }),

        ...(!isCustomerPortal
          ? [
              new MultiTagColumn<Claim.Model>({ header: 'Payments', fitContent: true }, async claim => {
                const payments = await this.claimsSvc.listPayments(claim.id);
                if (!payments || !payments.items?.length) return { items: [] };

                return {
                  limit: 1,
                  items: payments.items.map(payment => ({
                    text:
                      payment.status.substring(0, 1) +
                      payment.status.substring(1).toLowerCase() +
                      ' $' +
                      (payment.amount / 100).toFixed(2),
                    theme: this.getPaymentTheme(payment.status),
                    displayStatusIcon: true,
                  })),
                };
              }),
            ]
          : []),

        new TextColumn({ header: 'Last updated', fitContent: true }, (item: Claim.Model) => ({
          text: moment(item.modified).format('MMM D, YYYY'),
          classes: 'whitespace-nowrap',
        })),

        new TextColumn({ header: 'Opened ', fitContent: true }, (item: Claim.Model) => ({
          text: moment(item.created).format('MMM D, YYYY'),
          classes: 'whitespace-nowrap',
        })),

        new TextColumn({ header: 'Distributor', fitContent: true }, (item: Claim.Model) => ({
          theme: item.warranty?.distributor ? 'primary' : 'default',
          classes: 'border rounded-md px-2 py-1 bg-base whitespace-nowrap',
          text: item.warranty?.distributor?.name || '-',
        })),
      ];
    })
  );

  openOpenClaimDialog() {
    this.openClaimDialog?.openDialog(this.warranty, this.warranty?.customer || null);
  }

  closeClaimDialog() {
    this.refresh$.next(null);
  }

  private getCurrentStep(steps?: ClaimLifecycleStep.Model[]) {
    return steps?.find(
      step =>
        (this.isCustomerPortal ? step.visible === true : true) &&
        step.status !== ClaimLifecycleStep.Status.DONE &&
        step.status !== ClaimLifecycleStep.Status.SKIPPED
    );
  }

  private getStepTheme(status?: ClaimLifecycleStep.Status): ThemeColor {
    switch (status) {
      case ClaimLifecycleStep.Status.DONE:
        return 'success';
      case ClaimLifecycleStep.Status.IN_PROGRESS:
        return 'info';
      case ClaimLifecycleStep.Status.PENDING_CUSTOMER:
        return 'warn';
      case ClaimLifecycleStep.Status.UNSUCCESSFUL:
        return 'danger';
      default:
        return 'default';
    }
  }

  getBadgeStatusColor(status: Claim.Status): {
    color: ThemeColor;
    text: string;
    tooltip: string;
  } {
    switch (status) {
      case Claim.Status.CANCELLED:
        return {
          text: 'Canceled',
          color: 'warn',
          tooltip: 'Your claim was canceled.',
        };

      case Claim.Status.CLOSED:
        return {
          text: 'Closed',
          color: 'default',
          tooltip: 'Your claim was automatically closed after being successfully reimbursed.',
        };

      case Claim.Status.EXPIRED:
        return {
          text: 'Expired',
          color: 'danger',
          tooltip:
            'Your claim has expired after 30 days of inactivity. If you are still experiencing an issue, please open a new claim.',
        };

      default:
        return {
          text: 'Open',
          color: 'info',
          tooltip: 'Your claim has been received and is currently being processed for approval',
        };
    }
  }

  getClaimItemStatusColor(status: Claim.ClaimItem.Status): ThemeColor {
    switch (status) {
      case Claim.ClaimItem.Status.RESOLVED:
      case Claim.ClaimItem.Status.APPROVED:
        return 'success';
      case Claim.ClaimItem.Status.REJECTED:
        return 'danger';
      default:
        return 'default';
    }
  }

  getPaymentTheme(status: Claim.ClaimPayment.Status): ThemeColor {
    switch (status) {
      case Claim.ClaimPayment.Status.COMPLETED:
        return 'success';
      case Claim.ClaimPayment.Status.FAILED:
        return 'danger';
      case Claim.ClaimPayment.Status.ISSUED:
        return 'info';
      case Claim.ClaimPayment.Status.VOIDED:
      default:
        return 'default';
    }
  }

  navigateTo(claimId: string) {
    if (this.isCustomerPortal) {
      if (this.warranty?.policy?.customerPortalConfig.selfServeClaims) {
        this.router.navigate([`claim/${claimId}`], { relativeTo: this.route });
      }
    } else {
      if (this.warranty) this.router.navigate([`../../claims/${claimId}`], { relativeTo: this.route });
      else this.router.navigate([`${claimId}`], { relativeTo: this.route });
    }
  }

  async refreshTable(filters?: {
    search?: string;
    statuses?: Claim.Status[];
    itemStatuses?: Claim.ClaimItem.Status[];
    paymentStatuses?: Claim.ClaimPayment.Status[];
    customer?: Customer.Model | null;
    distributor?: Distributor.Model | null;
    claimLifecycleSteps?: string[];
    warrantyId?: string;
  }) {
    if (filters?.search !== undefined) {
      this.filters = { ...this.filters, search: filters.search };
    }
    if (filters?.statuses !== undefined) {
      this.filters = { ...this.filters, statuses: filters.statuses };
    }
    if (filters?.itemStatuses !== undefined) {
      this.filters = { ...this.filters, claimItemStatuses: filters.itemStatuses };
    }
    if (filters?.paymentStatuses !== undefined) {
      this.filters = { ...this.filters, claimPaymentStatuses: filters.paymentStatuses };
    }
    if (filters?.customer) {
      this.filters = { ...this.filters, customerId: filters.customer.id };
    }
    if (filters?.distributor !== undefined) {
      this.filters = { ...this.filters, distributorId: filters.distributor?.id };
    }

    if (filters?.claimLifecycleSteps !== undefined) {
      this.filters = { ...this.filters, claimLifecycleSteps: filters.claimLifecycleSteps };
    }

    if (filters?.warrantyId !== undefined) {
      this.filters = { ...this.filters, warrantyId: filters.warrantyId };
    }

    this.filters$.next(this.filters);
  }

  async ngOnInit() {
    const { warrantyId }: { warrantyId?: string } = await firstValueFrom(this.route.queryParams);
    if (!this.isCustomerPortal)
      this.refreshTable({
        statuses: [this.open],
        ...(warrantyId && { warrantyId }),
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
