import { Component, HostBinding, Input } from '@angular/core';

export interface CardHeaderConfig {
  title?: string;
  subtitle?: string;

  mobileWrap?: boolean;
}

@Component({
  selector: 'vs-card-header',
  templateUrl: './card-header.component.html',
})
export class CardHeaderComponent {
  @HostBinding('class') styles = 'block w-full border-b border-gray-200 p-4 sm:px-6 sm:py-4';

  @Input() config?: CardHeaderConfig;
}
