/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { IconName } from '@vsolv/vectors-ui/icons';
import { IconButtonColumn, IconColumn, TableColumn, TableComponent, TextColumn } from '@vsolv/vectors/table';
import { ClaimLifecycleStep } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { ClaimLifecycleStepDialog, DeleteClaimLifecycleStepDialog } from '../../dialogs';

@Component({
  selector: 'ws-claim-lifecycle-table',
  templateUrl: './claim-lifecycle-steps-table.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ClaimLifecycleStepsTableComponent,
    },
  ],
})
export class ClaimLifecycleStepsTableComponent implements ControlValueAccessor, OnDestroy {
  @ViewChild('createStepDialog') createStepDialog?: ClaimLifecycleStepDialog;
  @ViewChild('updateStepDialog') updateStepDialog?: ClaimLifecycleStepDialog;
  @ViewChild('deleteStepDialog') deleteStepDialog?: DeleteClaimLifecycleStepDialog;

  @ViewChild('table') table?: TableComponent<unknown>;

  @PropertyListener('value') value$ = new ReplaySubject<ClaimLifecycleStep.ClaimLifecycleStepDto[]>(1);
  @Input() value: ClaimLifecycleStep.ClaimLifecycleStepDto[] = [];

  @PropertyListener('canEdit') canEdit$ = new BehaviorSubject<boolean | null>(null);
  @Input() canEdit: boolean | null = null;

  @Input() disabled = false;

  @Input() hideSave = false;
  @Input() hideBorder = false;

  @Input() title = '';
  @Input() subtitle = '';

  @Output() save = new EventEmitter();
  @Output() stepAdded = new EventEmitter();
  @Output() valueChanges = new EventEmitter<ClaimLifecycleStep.ClaimLifecycleStepDto[]>();

  @PropertyListener('editing') editing$ = new BehaviorSubject<boolean | null>(null);
  editing = false;

  touched = false;

  selectedItemIndex: number | null = null;

  refresh$ = new BehaviorSubject(null);

  columns$: Observable<TableColumn<unknown>[]> = combineLatest([this.canEdit$, this.editing$]).pipe(
    map(([canEdit, editing]) => {
      return [
        new IconColumn({ header: 'Icon', fitContent: true }, (step: ClaimLifecycleStep.Model) => ({
          icon: step.icon as IconName,
        })),
        new TextColumn({ header: 'Title' }, (step: ClaimLifecycleStep.Model) => ({ text: step.title })),
        new TextColumn({ header: 'Description' }, (step: ClaimLifecycleStep.Model) => ({
          text: step.description,
          classes: 'w-full max-h-[40px] overflow-y-auto overflow-x-hidden scrollbar-thin',
        })),
        ...(canEdit && editing
          ? [
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (step: ClaimLifecycleStep.Model) => ({
                type: 'clear',
                rounded: true,
                icon: step.visible ? 'eye' : 'eye-off',
                click: () => this.updateVisibility(step.order),
              })),
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (step: ClaimLifecycleStep.Model) => ({
                type: 'clear',
                rounded: true,
                icon: 'trash-01',
                click: () => this.openDeleteStepDialog(step, step.order),
              })),
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (step: ClaimLifecycleStep.Model) => ({
                type: 'clear',
                rounded: true,
                icon: 'edit-01',
                click: () => this.openUpdateStepDialog(step, step.order),
              })),
            ]
          : []),
      ];
    })
  );

  onChange = (_value: ClaimLifecycleStep.ClaimLifecycleStepDto[]) => {};
  onTouched = () => {};

  selectValueChanged(value: ClaimLifecycleStep.ClaimLifecycleStepDto) {
    this.markAsTouched();

    if (this.selectedItemIndex !== null) {
      this.value[this.selectedItemIndex] = value;
    } else {
      this.value.push(value);
    }

    this.onChange(this.value);
    this.valueChanges.next(this.value);
    this.table?.refresh();
  }

  writeValue(value: ClaimLifecycleStep.ClaimLifecycleStepDto[]): void {
    if (value) this.value = value;
    else this.value = [];
  }

  registerOnChange(onChange: (_value: ClaimLifecycleStep.ClaimLifecycleStepDto[]) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  edit() {
    this.editing = !this.editing;
  }

  openCreateStepDialog(step?: ClaimLifecycleStep.Model) {
    this.selectedItemIndex = null;
    this.createStepDialog?.open(step);
  }

  addStep(step?: ClaimLifecycleStep.Model) {
    if (step) {
      if (!this.value.length) this.edit();

      step.order = this.value.length;

      this.markAsTouched();
      this.selectValueChanged(step);
    }
  }

  openUpdateStepDialog(step: ClaimLifecycleStep.Model, index: number) {
    this.selectedItemIndex = index;
    this.updateStepDialog?.open(step);
  }

  updateStep(step?: ClaimLifecycleStep.Model) {
    if (step) {
      this.markAsTouched();
      this.selectValueChanged(step);
    }
  }

  updateVisibility(index: number) {
    this.value[index] = { ...this.value[index], visible: !this.value[index].visible };
    this.valueChanges.next(this.value);
    this.table?.refresh();
  }

  openDeleteStepDialog(step: ClaimLifecycleStep.Model, index: number) {
    this.selectedItemIndex = index;
    this.deleteStepDialog?.open(step);
  }

  deleteStep() {
    this.value = this.value.filter(step => step.order !== this.selectedItemIndex);

    this.reorder(this.value);
  }

  reorder(steps: ClaimLifecycleStep.ClaimLifecycleStepDto[]) {
    this.markAsTouched();

    this.value = steps.map((step, index) => {
      step.order = index;
      return step;
    });

    this.onChange(this.value);
    this.valueChanges.next(this.value);
    this.table?.refresh();
  }

  emitSave() {
    this.save.emit();
  }

  refresh() {
    this.refresh$.next(null);
  }

  ngOnDestroy(): void {
    this.value$.complete();
  }
}
