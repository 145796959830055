import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { PropertySetInputComponent } from '@vsolv/packages/properties/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { BehaviorSubject } from 'rxjs';
import { AssetService } from '../../services';

@Component({
  selector: 'ws-create-asset-dialog',
  templateUrl: './create-asset-dialog.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CreateAssetDialog {
  constructor(private assetSvc: AssetService, private formBuilder: FormBuilder, private toastSvc: ToastService) {}

  @ViewChild('dialog') dialog!: DialogComponent;

  @ViewChild('propertyValuesInput') propertyValuesInput?: PropertySetInputComponent;

  @Input() customerId!: string;

  @Output() closed = new EventEmitter();

  @PropertyListener('propertySetId') propertySetId$ = new BehaviorSubject<string | null>(null);
  propertySetId: string | null = null;

  form = this.formBuilder.group({
    propertySet: [null as PropertySet.Model | null, Validators.required],
    name: [null as string | null],
    propertyValues: [null as Record<string, unknown> | null, Validators.required],
  });

  async selectPropertySet(propertySetId?: string) {
    if (!propertySetId) return;
    if (propertySetId === this.propertySetId) return;
    this.propertySetId = null;
    this.form.patchValue({ propertyValues: null });
    await new Promise(res => setTimeout(res, 1));
    this.propertySetId = propertySetId;
  }

  open() {
    this.dialog.open();
  }

  close(assetId?: string) {
    this.dialog.close();
    this.closed.emit(assetId);
    this.form.reset();
    this.propertySetId = null;
  }

  async submit() {
    if (!this.propertySetId) return;
    const name = this.form.value.name ?? undefined;
    const response = await this.assetSvc.createAsset({
      customerId: this.customerId,
      name,
      submission: { setId: this.propertySetId, propertyValues: this.form.value.propertyValues || {} },
    });

    if (response?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Asset created',
        text: `${name || response.id} was successfully created!`,
      });
      this.close(response.id);
    } else {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: `Failed to create the asset. Please verify the information and try again.`,
      });
    }
  }

  isValid(): boolean {
    if (!this.propertySetId) return false;
    if (!this.propertyValuesInput) return false;

    if (!this.propertyValuesInput.form?.value) return false;

    return this.propertyValuesInput.form?.valid;
  }
}
