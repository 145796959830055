import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { BehaviorSubject, Observable, ReplaySubject, map, switchMap } from 'rxjs';
import { PropertyService } from '../../services';

@Component({
  selector: 'vs-property-set-picker',
  templateUrl: './property-set-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PropertySetPickerComponent,
    },
  ],
})
export class PropertySetPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef, private propertySvc: PropertyService) {}

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<PropertySet.Model | null>();

  readonly searchQuery$ = new BehaviorSubject<string>('');

  overlayOpen = false;

  @PropertyListener('value') value$ = new ReplaySubject<PropertySet.Model>(1);
  @Input() value: PropertySet.Model | null = null;

  propertySets$: Observable<PropertySet.Model[]> = this.searchQuery$.pipe(
    switchMap(search => this.propertySvc.listPropertySets({ search })),
    map(propertySets => propertySets.items),
    switchMap(async propertySets => {
      if (this.value) {
        let selectedPropertySet = propertySets.find(propertySet => propertySet.id === this.value?.id);

        if (!selectedPropertySet) {
          selectedPropertySet = (await this.propertySvc.listPropertySets({ search: this.value.name })).items[0];
          return [selectedPropertySet, ...propertySets];
        }
      }

      return propertySets;
    })
  );

  @Input() placeholder = 'Find a Property Set...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';
  touched = false;

  @Input() compareWith: (v0: PropertySet.Model | null, v1: PropertySet.Model | null) => boolean = (
    v0: PropertySet.Model | null,
    v1: PropertySet.Model | null
  ) => {
    switch (typeof v0) {
      case 'object':
        return JSON.stringify(v0) === JSON.stringify(v1);
      default:
        return v0 === v1;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_value: PropertySet.Model | null) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  selectValueChanged(value: PropertySet.Model) {
    this.markAsTouched();
    this.value?.id === value?.id ? (this.value = null) : (this.value = value);
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: PropertySet.Model | null): void {
    this.value = value;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(onChange: (_value: PropertySet.Model | null) => {}): void {
    this.onChange = onChange;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
