import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { STAFF_PERMISSION_FALLBACK_ROUTE_DATA, STAFF_PERMISSION_ROUTE_DATA, StaffRoleGuard } from '@wsphere/staff/web';
import { PaymentGuard } from './guards';
import { StaffPaymentPage, StaffPaymentsPage } from './pages';
import { PaymentResolver } from './resolvers';
import { SalesStaffPagesModule } from './staff-pages.module';

@NgModule({
  imports: [
    SalesStaffPagesModule,
    RouterModule.forChild([
      {
        path: '',
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'py_ViewSales',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [
            { permissionId: 'dsh_ViewDashboard', route: '' },
            { permissionId: 'pol_View', route: 'policies' },
            { permissionId: 'clm_ViewDetails', route: 'claims' },
          ],
        },
        canActivate: [StaffRoleGuard],
        component: StaffPaymentsPage,
      },
      {
        path: ':paymentId',
        component: StaffPaymentPage,
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'py_ViewSales',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'py_ViewSales', route: '/sales' }],
        },
        canActivate: [PaymentGuard, StaffRoleGuard],
        resolve: { payment: PaymentResolver },
      },
    ]),
  ],
})
export class SalesStaffPagesRoutingModule {}
