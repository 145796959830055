import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { NavItem } from '@vsolv/vectors-ui/app-nav';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { SecurityService } from '@wsphere/staff/web';
import { Claim, Warranty } from '@wsphere/warranties/domain';
import { Observable, combineLatest, firstValueFrom, map, switchMap } from 'rxjs';
import { ClaimItemDetailsDialog } from '../../dialogs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-page',
  templateUrl: './claim.page.html',
})
export class ClaimPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private toastSvc: ToastService,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild('expireClaimDialog') expireClaimDialog?: DialogComponent;
  @ViewChild('cancelClaimDialog') cancelClaimDialog?: DialogComponent;
  @ViewChild('closeClaimDialog') closeClaimDialog?: DialogComponent;
  @ViewChild('addClaimItem') addClaimItem?: ClaimItemDetailsDialog;

  readonly claim$ = this.claimSvc.getClaim();

  openCreateDialog = false;

  now = new Date();

  open = Claim.Status.OPEN;
  expired = Claim.Status.EXPIRED;
  cancelled = Claim.Status.CANCELLED;

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  permissionKey$ = this.claim$.pipe(
    map(claim => (claim?.warranty?.distributor?.permissionKey ? [claim?.warranty?.distributor?.permissionKey] : null))
  );

  canViewLifecycle$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('clm_ViewLifecycle', permissionKey))
  );

  canMarkClaimAsExpired$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('clm_ExpireClaim', permissionKey))
  );

  canCancelClaim$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('clm_CancelClaim', permissionKey))
  );

  canCloseClaim$ = combineLatest([this.permissionKey$, this.claim$]).pipe(
    switchMap(async ([permissionKey, claim]) => {
      let refreshClaim = null;
      if (claim) {
        refreshClaim = await this.claimSvc.get(claim.id);
      }

      return {
        canClose: await this.securitySvc.hasAccess('clm_CloseClaim', permissionKey),
        claim: refreshClaim,
      };
    }),
    map(data => {
      if (!data.canClose) return false;
      return (
        data.claim?.items?.length &&
        !data.claim?.items?.some(
          item => item.status === Claim.ClaimItem.Status.DRAFT || item.status === Claim.ClaimItem.Status.APPROVED
        )
      );
    })
  );

  canViewCustomerDetails$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('cus_ViewDetails', permissionKey))
  );

  canViewWarranty$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('wrt_ViewWarranty', permissionKey))
  );

  canViewDistributor$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('dist_ViewDistributor', permissionKey))
  );

  canViewClaim$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('clm_ViewClaim', permissionKey))
  );

  canViewNotes$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('clm_ViewNote', permissionKey))
  );

  canViewAttachments$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('clm_ViewAttachments', permissionKey))
  );

  canViewPayments$ = this.permissionKey$.pipe(
    switchMap(async permissionKey => await this.securitySvc.hasAccess('clm_ViewPayments', permissionKey))
  );

  notesTab$: Observable<NavItem | null> = combineLatest([this.claim$, this.canViewNotes$]).pipe(
    switchMap(async ([claim, canViewNotes]) => {
      if (!canViewNotes || !claim) return null;

      const notes = await this.claimSvc.listNotes(claim.id);
      return { label: 'Notes', routerLink: './notes', badge: { text: notes.meta.totalItems + '' } };
    })
  );

  attachmentsTab$: Observable<NavItem | null> = combineLatest([this.claim$, this.canViewAttachments$]).pipe(
    switchMap(async ([claim, canViewAttachments]) => {
      if (!canViewAttachments || !claim) return null;

      const attachments = await this.claimSvc.listDocuments(claim.id);
      return { label: 'Attachments', routerLink: './attachments', badge: { text: attachments.meta.totalItems + '' } };
    })
  );

  paymentTab$: Observable<NavItem | null> = combineLatest([this.claim$, this.canViewPayments$]).pipe(
    switchMap(async ([claim, canViewPayments]) => {
      if (!canViewPayments || !claim) return null;

      const payments = await this.claimSvc.listPayments(claim.id);
      return { label: 'Payments', routerLink: './payments', badge: { text: payments.meta.totalItems + '' } };
    })
  );

  tabs$ = combineLatest([this.canViewClaim$, this.notesTab$, this.attachmentsTab$, this.paymentTab$]).pipe(
    map(([canViewClaim, notesTab, attachmentsTab, paymentTab]) => {
      const tabs: NavItem[] = [];

      if (canViewClaim) {
        tabs.push({ label: 'Overview', routerLink: './' });
        if (paymentTab) tabs.push(paymentTab);
        if (notesTab) tabs.push(notesTab);
        if (attachmentsTab) tabs.push(attachmentsTab);
      }

      return tabs;
    })
  );

  claimTheme(claim: Claim.Model): ThemeColor {
    return claim?.status === Claim.Status.OPEN
      ? 'info'
      : claim?.status === Claim.Status.CLOSED
      ? 'default'
      : claim?.status === Claim.Status.CANCELLED
      ? 'warn'
      : 'danger';
  }

  warrantyTheme(claim: Claim.Model): ThemeColor {
    const warranty = claim?.warranty;
    if (!warranty) return 'default';

    return warranty.status === Warranty.Status.ACTIVATED ? 'success' : 'danger';
  }

  openExpireDialog() {
    this.expireClaimDialog?.open();
  }

  async expire(claim: Claim.Model) {
    if (!claim) return;

    const expiredClaimId = await this.claimSvc.expire(claim.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.expireClaimDialog?.close();
    });

    if (expiredClaimId) {
      this.toastSvc.show({
        type: 'success',
        title: 'Marked Claim as Expired',
        text: `Claim has successfully been marked as expired.`,
      });

      this.expireClaimDialog?.close();
      await this.claimSvc.refreshClaim();

      this.router.onSameUrlNavigation = 'reload';

      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { refresh: true } });
    }
  }

  openCancelDialog() {
    this.cancelClaimDialog?.open();
  }

  async cancel(claim: Claim.Model) {
    if (!claim) return;

    const cancelledClaimId = await this.claimSvc.cancel(claim.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.cancelClaimDialog?.close();
    });

    if (cancelledClaimId) {
      this.toastSvc.show({
        type: 'success',
        title: 'Cancelled Claim',
        text: `Claim has successfully been cancelled.`,
      });

      this.cancelClaimDialog?.close();
      await this.claimSvc.refreshClaim();

      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { refresh: true } });
    }
  }

  openCloseDialog() {
    this.closeClaimDialog?.open();
  }

  async close(claim: Claim.Model) {
    if (!claim) return;

    const closedClaimId = await this.claimSvc.close(claim.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.closeClaimDialog?.close();
    });

    if (closedClaimId) {
      this.toastSvc.show({
        type: 'success',
        title: 'Closed Claim',
        text: `Claim has successfully been closed.`,
      });

      this.closeClaimDialog?.close();
      await this.claimSvc.refreshClaim();
    }
  }
  async ngOnInit(): Promise<void> {
    const claim = (await firstValueFrom(this.route.data))['claim'] as Claim.Model;
    await this.claimSvc.refreshClaim(claim);
  }
}
