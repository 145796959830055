import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
import { RoleAssignmentsService, StaffService } from '../../services';

@Component({
  selector: 'ws-delete-team-member-dialog',
  templateUrl: './delete-team-member.dialog.html',
})
export class DeleteTeamMemberDialog {
  constructor(
    private staffSvc: StaffService,
    private toastSvc: ToastService,
    private roleSvc: RoleAssignmentsService
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() teamMember?: Staff.Model;
  @Input() distributor?: Distributor.Model;

  @Output() memberRemoved = new EventEmitter();

  _submitting = false;

  async confirm() {
    if (!this.teamMember) throw new Error('Unable to archive team member!');
    this._submitting = true;

    try {
      if (this.distributor) {
        const roles =
          this.teamMember.roles?.filter(role =>
            role.permissionKey?.startsWith(this.distributor?.permissionKey || '')
          ) || [];

        await Promise.all(
          roles.map(async role => {
            await this.roleSvc.removeRoleAssignment({
              roleId: role.roleId,
              staffId: this.teamMember?.id || '',
              permissionKey: this.distributor?.permissionKey,
            });
          })
        );

        this.toastSvc.show({
          type: 'success',
          title: 'Member removed',
          text: `Removed ${this.teamMember?.name ?? 'user'} from ${this.distributor.name}`,
        });
      } else {
        await this.staffSvc.deactivate(this.teamMember.id);

        this.toastSvc.show({
          type: 'success',
          title: 'User removed from team',
          text: `${this.teamMember?.name || 'This user'} has been removed from your team.`,
        });
      }
    } catch (e) {
      console.error(e);
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: 'Something went wrong and the member was not removed. Please try again.',
      });
    }

    this._submitting = false;
    this.closeDialog();
  }

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.memberRemoved.emit();
    this.dialog.close();
  }
}
