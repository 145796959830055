/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Policy, Provisioning } from '@wsphere/warranties/domain';
import { Subject, map, takeUntil, tap } from 'rxjs';
import { ProvisioningSessionService } from '../../services';

@Component({
  selector: 'ws-provision-policy',
  templateUrl: './provision-policy.component.html',
})
export class ProvisionPolicyComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionSvc: ProvisioningSessionService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @HostBinding('class') _class = 'pb-24 px-6';

  protected stepCompleted = false;
  protected destroy$ = new Subject<void>();

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  protected session$ = this.sessionSvc.getCurrentSession().pipe(
    tap(session => {
      if (session?.policy) {
        this.policy.patchValue(session.policy);
        this.stepCompleted = true;
      }
    })
  );

  protected policy = new FormControl(null as Policy.Model | null);

  readonly staffView$ = this.route.data.pipe(map(data => data['staffView'] as boolean));

  private navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }

  previousPage(staffView: boolean) {
    if (staffView) this.navigateTo('/provisioning/customer');
    else this.navigateTo('/checkout');
  }

  async nextPage(session: Provisioning.ProvisioningSession | null, staffView: boolean) {
    if (this.stepCompleted && session && this.policy.value) {
      if (
        (session.policyId && this.policy.value.id === session.policyId) ||
        (!session.policyId && this.policy.value.id)
      ) {
        await this.sessionSvc.updateSession({
          sessionId: session.id,
          data: { policyId: this.policy.value?.id },
        });
      } else {
        await this.sessionSvc.updateSession({
          sessionId: session.id,
          data: {
            policyId: this.policy.value?.id,
            assetInfo: null,
            addonIds: [],
            planId: null as any,
            paymentSchedule: null as any,
          },
        });
      }
      if (staffView) this.navigateTo('/provisioning/asset');
      else this.navigateTo('/checkout/asset');
    }
  }

  ngOnInit(): void {
    this.policy.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(policy => (this.stepCompleted = !!policy));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
