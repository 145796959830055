export const RECEIVE_EMAIL_CONFIG_KEY = 'receive-email-config';

export type TReceiveEmailConfigValue = {
  enabled: boolean;
  options: {
    bodyDocument: boolean;
    autoRun: boolean;
    processors: string[];
  };
};

export class ReceiveEmailConfigValue {
  enabled: boolean;
  options: {
    bodyDocument: boolean;
    autoRun: boolean;
    processors: string[];
  };

  constructor(enabled?: boolean, bodyDocument?: boolean, autoRun?: boolean, processors?: string[]) {
    this.enabled = enabled ?? false;
    this.options = {
      bodyDocument: bodyDocument ?? false,
      autoRun: autoRun ?? false,
      processors: processors ?? [],
    };
  }
}
