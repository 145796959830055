import { Directive, HostBinding, Input } from '@angular/core';
import { InputBoxDirective } from './input-box.directive';

export type CheckBoxAppearance =
  | 'checkbox'
  | 'toggle'
  | 'radio'
  | 'checkbox-round'
  | 'checkbox-filled'
  | 'checkbox-round-filled';

@Directive({
  standalone: true,
  selector: `
    input[type="checkbox"][vsInput],
  `,
  hostDirectives: [InputBoxDirective],
})
export class CheckboxInputDirective {
  @HostBinding('class') get styles() {
    const box = `peer/checkbox appearance-none`;
    return `theme-primary-inherit ${box} ${this.getAppearanceStyles()}`;
  }

  @Input() appearance: CheckBoxAppearance = 'checkbox';

  getAppearanceStyles() {
    const checked = `
      h-4
      w-4
      min-h-[theme(space.4)]
      min-w-[theme(space.4)]
      before:absolute
      before:-inset-px
      before:content-['']
      hover:bg-themed-100
      checked:bg-themed-50
      checked:!border-themed-600
      checked:before:bg-themed-600
      indeterminate:bg-themed-50
      indeterminate:!border-themed-600
    `;
    const toggle = `
      cursor-pointer
      w-[calc(theme(space.8)+4px)]
      h-[calc(theme(space.4)+4px)]
      min-w-[calc(theme(space.8)+4px)]
      min-h-[calc(theme(space.4)+4px)]
      rounded-full
      border-none
      disabled:bg-gray-100
      bg-gray-200
      hover:bg-themed-200
      after:content-['']
      after:transition-[left]
      after:absolute
      after:rounded-full
      after:bg-base
      after:shadow-sm
      after:top-[2px]
      after:[&:not(:checked)]:left-[2px]
      after:w-4
      after:h-4
      checked:bg-themed-600
      checked:disabled:bg-themed-100
      checked:hover:[&:not(:disabled)]:bg-themed-700
      checked:after:left-[calc(100%-theme(space.4)-2px)]
    `;
    switch (this.appearance) {
      default:
      case 'checkbox':
        return `${checked} rounded-md checked:before:mask-image-check indeterminate:before:mask-image-indeterminate indeterminate:before:bg-themed-600`;
      case 'radio':
        return `${checked} rounded-full checked:before:mask-image-radio`;
      case 'toggle':
        return `${toggle}`;
      case 'checkbox-round':
        return `${checked} rounded-full checked:before:mask-image-check indeterminate:before:mask-image-indeterminate indeterminate:before:bg-themed-600`;
      case 'checkbox-filled':
        return `${checked} rounded-md before:rounded-md checked:before:mask-image-check-inverse indeterminate:before:mask-image-indeterminate-inverse indeterminate:before:bg-themed-600`;
      case 'checkbox-round-filled':
        return `${checked} rounded-full before:rounded-full checked:before:mask-image-check-inverse indeterminate:before:mask-image-indeterminate-inverse indeterminate:before:bg-themed-600`;
    }
  }
}
