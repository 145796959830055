<ng-container *vsLet="close$ | async">
  <vs-dialog
    #dialog
    (closed)="navigateTo()"
    [config]="{ dialogContainerClass: 'w-screen h-screen rounded-none !p-0 bg-base-light !max-h-full', startOpen: true, contentContainerClass:'h-full' }"
    *vsLet="isMobile$ | async as isMobile"
  >
    <ng-container *vsLet="session$ | async as session">
      <ng-container *vsLet="sessionComplete$ | async as sessionComplete">
        <div *ngIf="step$ | async as step" class="h-full" [class.flex]="!isMobile">
          <div
            #scrollElement
            class="flex flex-col items-center flex-grow w-full h-full gap-4 overflow-y-auto scrollbar-thin"
          >
            <div *ngIf="!isMobile" class="sticky top-0 z-50 pt-14 bg-base-light w-full px-6">
              <div class="flex gap-6 items-start">
                <button
                  *ngIf="step === checkoutSteps.Plan"
                  vs-button
                  appearance="clear"
                  theme="primary"
                  (click)="navigateTo('/provisioning/asset')"
                >
                  <vs-icon name="arrow-left"></vs-icon>
                  Back
                </button>
                <vs-stepper
                  *ngIf="steps$ | async as steps"
                  [config]="{steps: steps, mode: 'horizontal_navigation', activeStep: step}"
                  theme="primary"
                  class="flex-grow"
                  (stepChanged)="step$.next($event)"
                ></vs-stepper>
                <button
                  *ngIf="step === checkoutSteps.Plan"
                  vs-button
                  theme="danger"
                  (click)="summary?.confirmCancellationDialog?.open()"
                >
                  <vs-icon name="x-close"></vs-icon>
                  Cancel sale
                </button>
              </div>
            </div>
            <router-outlet></router-outlet>
          </div>
          <div
            [class.!w-0]="!isMobile && step === checkoutSteps.Plan || sessionComplete"
            [class]="!isMobile ? 'w-[360px] transition-all duration-500' : 'w-full'"
            *ngIf="!sessionComplete"
          >
            <ng-container *vsLet="distributor$ | async as distributor">
              <ng-container *vsLet="preview$ | async as preview">
                <ng-container *vsLet="coverages$ | async as coverages">
                  <ng-container *vsLet="selectedAddons$ | async as addons">
                    <ng-container *vsLet="loadingPreview$ | async as loadingPreview">
                      <ws-order-summary
                        [class.!hidden]="!isMobile && step === checkoutSteps.Plan || sessionComplete"
                        [distributor]="distributor"
                        [session]="session"
                        [_preview]="preview"
                        [coverages]="coverages"
                        [addons]="addons"
                        [currentStep]="step"
                        [loading]="loadingPreview"
                        [state]="isMobile ? 'MOBILE' : 'DESKTOP'"
                        [staffView]="true"
                        (closeDialog)="dialog.close()"
                        (updatePreview)="updatePreview($event)"
                      ></ws-order-summary>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </vs-dialog>
</ng-container>
