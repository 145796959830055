<ng-container *vsLet="staffView$ | async as staffView">
  <ng-container *vsLet="isLargeScreen$ | async as isLargeScreen">
    <ng-container *vsLet="session$ | async as session">
      <div class="relative flex flex-col items-center gap-4 mb-3">
        <div class="flex gap-2 w-full items-center">
          <div class="w-12 h-12">
            <button
              *ngIf="!isLargeScreen"
              vs-icon-button
              size="lg"
              theme="default"
              appearance="clear"
              (click)="previousPage(staffView ?? false)"
            >
              <vs-icon name="chevron-left" size="20"></vs-icon>
            </button>
          </div>

          <div class="flex justify-center items-center flex-grow" name="icon">
            <vs-featured-icon name="folder-shield" theme="primary"></vs-featured-icon>
          </div>

          <div class="w-12 h-12"></div>
        </div>

        <div name="title" class="text-3xl font-semibold text-center">
          Pick a plan to protect your {{ staffView ? "customer's " : '' }}
          {{ session?.policy?.propertySet?.name ?? 'asset' | lowercase }}
        </div>

        <div name="subtitle" class="text-sm text-gray-500">
          Please compare our plans and find the best fit for your {{ staffView ? "customer's " : '' }} needs.
        </div>
      </div>

      <div class="flex flex-wrap text-primary-500 items-center justify-center gap-[30px] m-[20px]">
        <vs-icon *ngIf="isLargeScreen" [name]="$any(session?.policy?.icon) ?? 'file-shield-02'" size="20"></vs-icon>
        <p
          class="text-4xl font-semibold text-center"
          [vsTooltip]="session?.policy?.friendlyTitle ?? session?.policy?.title"
          tooltipStyle="light"
          tooltipPosition="top"
          tooltipOpenDelay="350"
        >
          {{ session?.policy?.friendlyTitle ?? session?.policy?.title }}
        </p>

        <button
          vsTooltip="Change Policy"
          class="text-gray-700 items-center mt-2"
          (click)="staffView ? navigateTo('/provisioning/policy') : navigateTo('/checkout/policy')"
          appearance="clear"
        >
          <vs-icon name="edit-05" size="20"></vs-icon>
        </button>
      </div>

      <div class="text-sm mb-3 text-gray-400">
        <span *ngIf="session?.policy?.claimWaitingPeriod" class="self-center text-center line-clamp-2">
          Includes a waiting period of {{ session?.policy?.claimWaitingPeriod }} days before a claim can be opened.
        </span>
        <span *ngIf="!session?.policy?.claimWaitingPeriod" class="self-center text-center line-clamp-2">
          No waiting period before opening a claim. You can begin the claims process immediately.
        </span>
      </div>

      <ws-provision-plan-form
        [session]="session!"
        [formControl]="plan"
        [staffView]="staffView ?? false"
        (addons)="emitAddons($event)"
        (termChanged)="updateTerm($event)"
        (nextStep)="nextPage(session, staffView ?? false)"
        (back)="previousPage(staffView ?? false)"
      ></ws-provision-plan-form>
    </ng-container>
  </ng-container>
</ng-container>
