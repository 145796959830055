<vs-dialog [config]="{ dialogContainerClass: 'w-[480px] min-w-[480px] max-w-[90%]' }">
  <div *ngIf="!confirmed">
    <vs-dialog-header
      [config]="{mode:'simple', icon:'user-check-01', iconTheme:'primary', title: 'Looks like you already have an account with us!', subtitle: 'Use this email to receive a sign in link to the customer portal to continue your purchase.'}"
    ></vs-dialog-header>

    <div class="flex flex-col gap-2 mx-2">
      <div>
        <vs-form-field
          [config]="{label:'Email', hint: 'If you don’t recognize this email, please return to checkout to create an account with us, or sign into another account to complete your purchase.'}"
          appearance="vertical"
        >
          <vs-input-container>
            <p class="text-gray text-md align-middle self-center">{{email}}</p>
          </vs-input-container>
        </vs-form-field>
      </div>
      <div class="flex flex-row justify-between mt-6">
        <button vs-button appearance="outline" (click)="close()">Cancel</button>
        <button vs-button theme="primary" (click)="confirm()">Sign-In</button>
      </div>
    </div>
  </div>

  <!-- sent screen -->
  <div *ngIf="confirmed">
    <vs-dialog-header
      [config]="{mode:'centered', icon:'mail-01', iconTheme:'primary', title: 'Check your email'}"
    ></vs-dialog-header>
    <div class="flex flex-col justify-center items-center w-full gap-4">
      <div class="p-4 m-auto md:w-3/5">
        <p class="text-gray text-center">
          If you have an account with us, you should receive an email with sign in instructions shortly. If you don't
          receive an email to sign in, please check your spam folder.
        </p>
      </div>

      <a vs-button size="md" theme="primary" class="w-full max-w-[360px]" href="mailTo:">
        <span>Open email app</span>
      </a>
      <p class="text-gray-900">
        Didn't receive the email?
        <span>
          <button class="text-gray" [disabled]="this.canSendTimeOut > 0" (click)="confirm()">
            <span *ngIf="this.canSendTimeOut <= 0; else timeoutTimer">Send again</span>
            <ng-template #timeoutTimer>
              <span>Resend available in {{ this.canSendTimeOut }}</span>
            </ng-template>
          </button>
        </span>
      </p>
    </div>
  </div>
</vs-dialog>
