<vs-dialog #deleteDialog [config]="dialogConfig ?? { dialogContainerClass: 'max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'trash-01',
      iconTheme: 'primary',

      title: 'Delete ' + policy?.title || 'policy',
      subtitle: 'Are you sure you want to delete <i>' + policy?.title + '</i>? This action can not be undone.'
    }"
  ></vs-dialog-header>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
        Cancel
      </button>
      <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="deletePolicy()">
        Delete
      </button>
    </div>
  </div>
</vs-dialog>
