import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TeamMembersPage } from '@wsphere/staff/web';
import { AppsPage, DeveloperPage, OrganizationPage } from './pages';
import { TenantsWebModule } from './tenants-web.module';

@NgModule({
  imports: [
    TenantsWebModule,
    RouterModule.forChild([
      {
        path: '',
        component: OrganizationPage,
      },
      {
        path: 'team',
        component: TeamMembersPage,
      },
      {
        path: 'apps',
        component: AppsPage,
      },
      //TODO: billing and integrations pages
      // {
      //   path: 'billing',
      //   component: BillingPage,
      // },
      // {
      //   path: 'integrations',
      //   component: IntegrationsPage,
      // },
      {
        path: 'developer',
        component: DeveloperPage,
      },
    ]),
  ],
  providers: [],
})
export class TenantWebRoutingModule {}
