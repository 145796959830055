import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject, first, race } from 'rxjs';
import { AlertComponent, AlertConfig, ALERT_CONFIG_TOKEN } from '../components';

export const OVERLAY_REF_DATA_TOKEN = new InjectionToken<string>('OVERLAY_REF_DATA_TOKEN');

@Injectable()
export class AlertService {
  private readonly overlays$ = new BehaviorSubject<OverlayRef[]>([]);

  constructor(private overlay: Overlay, private injector: Injector) {
    this.overlays$.subscribe(overlays => {
      let top = 12;

      for (const overlay of overlays) {
        const positionStrategy = this.overlay.position().global().top(`${top}px`).right('12px');
        overlay.updatePositionStrategy(positionStrategy);

        top += overlay.overlayElement.clientHeight + 12;
      }
    });
  }

  show<T extends AlertComponent>(config: AlertConfig, componentType = AlertComponent as ComponentType<T>): T {
    const panelClass = ['transition-[margin-top]', 'duration-150', 'ease-out'];
    const overlayRef = this.overlay.create({ panelClass });

    this.overlays$.next([...this.overlays$.value, overlayRef]);

    const providers = [{ provide: ALERT_CONFIG_TOKEN, useValue: config }];
    const injector = Injector.create({ providers, parent: this.injector });

    const componentRef = overlayRef.attach(new ComponentPortal(componentType, null, injector));
    const component = componentRef.instance;

    race(component._delayCompleted, component._closeRequested)
      .pipe(first())
      .subscribe(() => {
        this.overlays$.next(this.overlays$.value.filter(ref => ref !== overlayRef));
        overlayRef.dispose();
      });

    return component;
  }
}
