<vs-dialog [config]="{ dialogContainerClass: 'max-w-[600px]' }" (closed)="removed.emit()">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,

      icon: 'file-x-02',
      iconTheme: 'warn',

      title: 'Remove Claim Item',
      subtitle:
        'Are you sure you want to remove <strong>' +
        (item?.coverage?.title || 'Other') +
        '</strong>? This action cannot be undone.'
    }"
  ></vs-dialog-header>

  <form class="flex flex-col gap-2 my-4" [formGroup]="removeForm">
    <p class="text-sm font-medium text-gray-500">Please provide a reason for removing the item</p>
    <textarea vsInput min="2" max="5" formControlName="reason"></textarea>
  </form>

  <div class="flex gap-2">
    <button vs-button appearance="outline" class="flex-1" (click)="close()">Back</button>
    <button vs-button theme="danger" class="flex-1" [disabled]="!removeForm.value.reason" (click)="remove()">
      Remove Item
    </button>
  </div>
</vs-dialog>
