import { Conditions } from '@vsolv/packages/conditions/domain';

//Make sure that any new properties added here are handled when conditions evaluate as they won't automatically work
export const properties = [
  //Customer
  new Conditions.Property({
    id: 'customer',
    valueKey: 'customer',
    type: Conditions.PropertyType.OBJECT,
    isChild: false,
    display: {
      name: 'Customer',
    },
    properties: [
      new Conditions.PropertyAssignment({
        order: 0,
        property: new Conditions.Property({
          id: 'customer_id',
          valueKey: 'id',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'ID',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 1,
        property: new Conditions.Property({
          id: 'customer_name',
          valueKey: 'name',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Name',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 2,
        property: new Conditions.Property({
          id: 'customer_email',
          valueKey: 'email',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Email',
            format: 'email',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 3,
        property: new Conditions.Property({
          id: 'customer_phone',
          valueKey: 'phone',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Phone',
            format: 'phone',
          },
        }),
      }),
    ],
  }),

  //Distributors
  new Conditions.Property({
    id: 'distributor',
    valueKey: 'distributor',
    type: Conditions.PropertyType.OBJECT,
    isChild: false,
    display: {
      name: 'Distributor',
    },
    properties: [
      new Conditions.PropertyAssignment({
        order: 0,
        property: new Conditions.Property({
          id: 'distributor_id',
          valueKey: 'id',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'ID',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 1,
        property: new Conditions.Property({
          id: 'distributor_name',
          valueKey: 'name',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Name',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 2,
        property: new Conditions.Property({
          id: 'distributor_email',
          valueKey: 'email',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Email',
            format: 'email',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 3,
        property: new Conditions.Property({
          id: 'distributor_phone',
          valueKey: 'phone',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Phone',
            format: 'phone',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 4,
        property: new Conditions.Property({
          id: 'distributor_parent',
          valueKey: 'parentId',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Parent ID',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 5,
        property: new Conditions.Property({
          id: 'distributor_permission_key',
          valueKey: 'permissionKey',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Permission Key',
          },
        }),
      }),
    ],
  }),
  //Shipping Address
  new Conditions.Property({
    id: 'shippingAddress',
    valueKey: 'shippingAddress',
    type: Conditions.PropertyType.OBJECT,
    isChild: false,
    display: {
      name: 'Shipping Address',
    },
    properties: [
      new Conditions.PropertyAssignment({
        order: 0,
        property: new Conditions.Property({
          id: 'shipping_line1',
          valueKey: 'line1',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Line 1',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 1,
        property: new Conditions.Property({
          id: 'shipping_line2',
          valueKey: 'line2',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Line 2',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 2,
        property: new Conditions.Property({
          id: 'shipping_city',
          valueKey: 'city',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'City',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 3,
        property: new Conditions.Property({
          id: 'shipping_zip',
          valueKey: 'zip',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'ZIP Code',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 4,
        property: new Conditions.Property({
          id: 'shipping_state',
          valueKey: 'state',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'State',
          },
        }),
      }),
      new Conditions.PropertyAssignment({
        order: 5,
        property: new Conditions.Property({
          id: 'shipping_country',
          valueKey: 'country',
          type: Conditions.PropertyType.TEXT,
          isChild: true,
          display: {
            name: 'Country',
            allowedValues: ['CA', 'US'],
          },
        }),
      }),
    ],
  }),
];
