import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { PolicyResolver } from '../resolvers';
import { PolicyService } from '../services';

@Injectable()
export class PolicyGuard implements CanActivate {
  constructor(private resolver: PolicyResolver, private policySvc: PolicyService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.policy = null;

    const policyId = this.findPolicyId(route);
    if (!policyId) return false;

    this.resolver.policy = await this.policySvc.getOne(policyId);
    if (this.resolver.policy === null) return this.router.createUrlTree(['/404']);
    if (!this.resolver.policy) return false;

    this.policySvc.refreshPolicy(this.resolver.policy);

    return true;
  }

  private findPolicyId(route: ActivatedRouteSnapshot): string {
    return route.params['policyId'] || (route.parent ? this.findPolicyId(route.parent) : null);
  }
}
