import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { SignInButtonComponent } from './components';

@NgModule({
  imports: [CommonModule, MatButtonModule, MatIconModule, UtilsModule],
  declarations: [SignInButtonComponent],
  exports: [SignInButtonComponent],
  // providers: [AuthService],
})
export class AuthWebModule {
  static forRoot() {
    return {
      ngModule: AuthWebModule,
    };
  }
}
