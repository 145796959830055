import { ChangeDetectorRef, Directive, HostBinding, NgZone, OnDestroy, OnInit } from '@angular/core';
import { SelectComponent } from '@vsolv/vectors-ui/select';
import { delay, Subscription } from 'rxjs';
import { InputBoxDirective } from './input-box.directive';

@Directive({
  standalone: true,
  selector: 'vs-select[vsInput]',
})
export class SelectInputDirective extends InputBoxDirective implements OnInit, OnDestroy {
  @HostBinding('class') selectInputStyles = `h-10 [&>button]:px-3`;

  sub?: Subscription;

  constructor(private select: SelectComponent<unknown>, private ngZone: NgZone, private cd: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.sub = this.select.openChanged.pipe(delay(0)).subscribe(open => (this.forceFocus = open));
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
