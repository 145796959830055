<vs-dialog
  #manageBankDialog
  [config]="{
  dialogContainerClass: 'max-w-[505px]'
}"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'bank-note-01',
      iconTheme: 'primary',

      title: 'Add payment method',
      subtitle: 'Add a payment method to be used for all transactions.',
    
      showDivider: true
    }"
  ></vs-dialog-header>

  <div class="flex flex-col gap-4">
    <div class="flex gap-4 items-center justify-center">
      <vs-add-bank-details
        [customer]="customer"
        [customerId]="customerId"
        (bankDetails)="confirmDestination($event)"
      ></vs-add-bank-details>
    </div>
  </div>
</vs-dialog>
