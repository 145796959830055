<ng-container *ngIf="state === 'select'; else paymentMethodInput">
  <div [class.hidden]="!(picker['paymentMethods$'] | async)?.length">
    <p class="mb-2 font-semibold text-gray-900">Current payment method</p>
    <vs-payment-method-picker #picker [userId]="userId$ | async" [formControl]="control"></vs-payment-method-picker>
  </div>

  <div *ngIf="(picker['paymentMethods$'] | async)?.length" class="my-2 h-8 flex items-center gap-4">
    <div class="flex-1 bg-gray-200 h-px"></div>
    <div class="text-xs font-normal text-gray-600">Or</div>
    <div class="flex-1 bg-gray-200 h-px"></div>
  </div>

  <div>
    <p class="mb-2 font-semibold text-gray-900">Add new payment method</p>

    <div class="flex gap-2">
      <button
        vs-button
        theme="primary"
        class="flex-1 h-auto !rounded-2xl py-4 px-6 border-none !bg-primary-50 hover:!bg-primary-100 active:!bg-primary-200 !text-primary-700"
        (click)="state = 'credit-card'"
      >
        <div class="flex gap-4">
          <vs-icon name="credit-card-02" size="24"></vs-icon>
          <span class="font-semibold">Credit card</span>
        </div>
      </button>

      <!-- <button
        vs-button
        theme="primary"
        class="flex-1 h-auto !rounded-2xl py-4 px-6 border-none bg-primary-50 hover:bg-primary-300 active:bg-primary-200 text-primary-700"
        (click)="state = 'bank'"
      >
        <div class="flex gap-4">
          <vs-icon name="bank" size="24"></vs-icon>
          <span class="font-semibold">Bank account</span>
        </div>
      </button> -->
    </div>
  </div>
</ng-container>

<ng-template #paymentMethodInput>
  <vs-payment-method-input
    *ngIf="state !== 'select'"
    class="mb-4"
    [type]="state"
    [userId]="userId$ | async"
    [userDetails]="userDetails"
    [address]="address"
    [staffView]="staffView"
    (adding)="backButton.disabled = true"
    (failed)="backButton.disabled = false"
    (added)="backButton.disabled = false; creditCardAdded($event)"
  ></vs-payment-method-input>

  <button #backButton vs-button appearance="outline" class="min-w-full text-sm" (click)="state = 'select'">Back</button>
</ng-template>
