import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-export-plan-coverage-configs-dialog',
  templateUrl: './export-plan-coverage-configs.dialog.html',
})
export class ExportPlanCoverageConfigsDialog {
  constructor(private policySvc: PolicyService, private toastSvc: ToastService) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() plan?: Policy.Plan.Model | null = null;
  @Input() policy?: Policy.Model | null = null;

  @Input() dialogConfig?: DialogConfig;
  @Output() closed = new EventEmitter();

  href: string | null = null;

  async downloadPricingCSV() {
    if (!this.plan || !this.policy) return;

    const response = await this.policySvc.exportPlanCoverageConfigs(this.plan.id, this.policy.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response) this.href = response.data;
  }

  open() {
    this.href = null;
    this.dialog.open();
  }

  close() {
    this.closed.emit();
    this.dialog.close();
  }

  cancel() {
    this.closed.emit();
    this.close();
  }
}
