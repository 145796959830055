import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export interface StackGroupConfig {
  // Decides what direction has priority, if left is chosen the left-most object will be given priority
  direction?: string;

  // The distance (in px) on object will be overlayed on top of the next
  coverDistance?: number;

  hoverPriority?: boolean;
}

@Component({
  selector: 'vs-stack-group',
  templateUrl: './stack-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StackGroupComponent {
  @Input() config?: StackGroupConfig;

  private get left() {
    return this.config?.direction === 'left';
  }

  private get distance() {
    return this.config?.coverDistance ?? 12;
  }

  @HostBinding('class') private get _class() {
    const direction = this.left ? 'flex-row-reverse' : '';
    const hover = this.config?.hoverPriority ? '[&>*:hover]:z-10' : '';
    const margin = '[&>*]:ml-[var(--margin-left,0)] [&>*]:mr-[var(--margin-right,0)]';
    const padding = 'pl-[var(--padding-left,0)] pr-[var(--padding-right,0)]';

    return `flex justify-start ${direction} ${hover} ${margin} ${padding}`;
  }

  @HostBinding('style') private get _style() {
    return `
      direction: ${this.left ? 'rtl' : 'ltr'};
      
      --margin-left: ${this.left ? -this.distance : 0}px;
      --padding-left: ${this.left ? this.distance : 0}px;

      --margin-right: ${this.left ? 0 : -this.distance}px;
      --padding-right: ${this.left ? 0 : this.distance}px;
    `;
  }
}
