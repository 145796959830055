<ng-container *ngIf="policy$ | async as policy">
  <ng-container *vsLet="coverages$ | async as coverages">
    <vs-card
      *vsLet="(paginationConfig$ | async)?.totalItems as totalItems"
      class="overflow-hidden"
      [class]="hideBorder ? '!border-none !shadow-none' : ''"
    >
      <ng-container *ngIf="totalItems; else noItems">
        <vs-table
          *ngIf="columns$ | async as columns"
          [columns]="columns"
          [data]="coverages?.items"
          (rowClicked)="navigateTo($event.id)"
        ></vs-table>

        <vs-pagination
          class="items-end"
          [config]="paginationConfig$ | async"
          (pagination)="paginationQueryRequest$.next({ page: $event.currentPage, limit: $event.itemsPerPage })"
        ></vs-pagination>
      </ng-container>
    </vs-card>

    <ng-template #noItems>
      <vs-card
        class="flex flex-col justify-center gap-2 p-6 mt-4"
        [class]="hideBorder ? '!rounded-none !border-none !shadow-none' : ''"
      >
        <p class="text-xl font-semibold text-gray-900 self-center">No coverages found.</p>

        <p class="font-normal text-gray-600 self-center mb-4">
          This policy doesn't have any coverages associated with it. Add one in order to publish the policy.
        </p>

        <button
          *ngIf="canEdit$ | async"
          vs-button
          theme="primary"
          class="self-center my-4"
          (click)="createCoverageDialog.open()"
        >
          <vs-icon name="plus"></vs-icon>
          New Coverage
        </button>
      </vs-card>
    </ng-template>

    <ws-policy-dialog #createCoverageDialog [policy]="policy" [editing]="coverageType"></ws-policy-dialog>

    <ws-policy-dialog
      #editCoverageDialog
      [policy]="policy"
      [editing]="coverageType"
      [coverage]="selectedCoverage"
    ></ws-policy-dialog>

    <ws-copy-coverage-dialog
      #copyCoverageDialog
      [policy]="policy"
      [coverage]="selectedCoverage"
    ></ws-copy-coverage-dialog>

    <ws-delete-coverage-dialog
      #deleteCoverageDialog
      [policy]="policy"
      [coverage]="selectedCoverage"
    ></ws-delete-coverage-dialog>
  </ng-container>
</ng-container>
