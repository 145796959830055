import { BooleanInput, NumberInput } from '@angular/cdk/coercion';
import { Platform } from '@angular/cdk/platform';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Directive, ElementRef, HostBinding, Input, NgZone } from '@angular/core';
import { take } from 'rxjs';

@Directive({
  standalone: true,
  selector: `
    textarea[vsAutosize],
  `,
})
export class TextareaAutosizeDirective extends CdkTextareaAutosize {
  @HostBinding('class') get styles() {
    return `box-content`;
  }

  /** Whether autosizing is enabled or not */
  @Input('vsAutosize')
  override get enabled(): boolean {
    return super.enabled;
  }
  override set enabled(value: BooleanInput) {
    super.enabled = value;
  }

  /** Minimum amount of rows in the textarea. */
  @Input('minRows')
  override get minRows(): number {
    return super.minRows;
  }
  override set minRows(value: NumberInput) {
    super.minRows = value;
  }

  /** Maximum amount of rows in the textarea. */
  @Input('maxRows')
  override get maxRows(): number {
    return super.maxRows;
  }
  override set maxRows(value: NumberInput) {
    super.maxRows = value;
  }

  constructor(_elementRef: ElementRef<HTMLElement>, _platform: Platform, private _ngZoneLocal: NgZone) {
    super(_elementRef, _platform, _ngZoneLocal, null);
  }

  initialResize() {
    this._ngZoneLocal.onStable.pipe(take(1)).subscribe(() => this.resizeToFitContent(true));
  }
}
