/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { Asset } from '@wsphere/assets/domain';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class AssetService {
  constructor(private http: HttpClient) {}

  async createAsset(dto: Asset.CreateAssetCommandRequest) {
    return await firstValueFrom(this.http.post<Asset.CreateAssetCommandResponse>(`/api/assets`, dto));
  }

  async updateAsset(assetId: string, dto: Asset.UpdateAssetCommandRequest) {
    return await firstValueFrom(this.http.post<Asset.UpdateAssetCommandResponse>(`/api/assets/${assetId}`, dto));
  }

  async createAssetForSales(dto: Asset.CreateAssetCommandRequest & { sessionId: string }) {
    return await firstValueFrom(this.http.post<Asset.CreateAssetCommandResponse>(`/api/assets/sales`, dto));
  }

  createAssetSubmission(assetId: string, dto: Asset.CreateAssetSubmissionCommandRequest) {
    return firstValueFrom(this.http.post<Asset.CreateAssetSubmissionCommandResponse>(`/api/assets/${assetId}`, dto));
  }

  getCustomerAssets(customerId: string, propertySetId?: string) {
    return firstValueFrom(
      this.http.get<Asset.Model[]>(`/api/assets/customer/${customerId}`, {
        params: {
          ...(propertySetId && { propertySetId }),
        },
      })
    );
  }

  getAssetValues(assetId: string) {
    return firstValueFrom(this.http.get<PropertySet.Submission>(`/api/assets/${assetId}/latest`));
  }

  getAssetValuesForSales(assetId: string, sessionId: string) {
    return firstValueFrom(this.http.get<PropertySet.Submission>(`/api/assets/${assetId}/latest-sales/${sessionId}`));
  }
}
