import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Claim } from '@wsphere/warranties/domain';

@Injectable()
export class ClaimItemResolver implements Resolve<Claim.ClaimItem.Model | null> {
  item!: Claim.ClaimItem.Model | null;

  async resolve(): Promise<Claim.ClaimItem.Model | null> {
    return this.item ?? null;
  }
}
