<form [formGroup]="form" class="flex flex-col gap-5 justify-center">
  <vs-form-field
    [theme]="form.controls.name.touched && form.controls.name.hasError('required') ? 'danger' : 'primary'"
    [config]="{ label: 'Name*' }"
    appearance="responsive"
  >
    <input
      vsInput
      type="text"
      formControlName="name"
      [placeholder]="staffView ? 'Enter customer\'s name' : 'Enter your name'"
    />
    <span class="text-danger" slot="hint" *ngIf="form.controls.name.touched && form.controls.name.hasError('required')">
      Name is required
    </span>
  </vs-form-field>

  <vs-form-field
    *vsLet="disableEmail$ | async as disableEmail"
    appearance="responsive"
    [config]="{ label: 'Email*' }"
    [theme]="form.controls.email.touched && form.controls.email.hasError('required') ? 'danger' : 'primary'"
  >
    <input
      #input
      vsInput
      type="email"
      formControlName="email"
      (input)="checkForCustomer(input.value)"
      [placeholder]="staffView ? 'Enter customer\'s email' : 'Enter your email'"
    />

    <span *ngIf="customer && form.controls.email.valid" slot="hint">
      A customer with that email already exists. Click
      <span class="text-primary-500 cursor-pointer hover:text-primary-700 transition-all" (click)="emitCustomer()">
        here
      </span>
      to use their information
    </span>

    <span
      *ngIf="form.controls.email.touched && form.controls.email.hasError('required')"
      slot="hint"
      class="text-danger"
    >
      Email is required
    </span>

    <span *ngIf="form.controls.email.touched && form.controls.email.hasError('email')" class="text-danger" slot="hint">
      Please enter a valid email
    </span>
  </vs-form-field>

  <vs-form-field theme="primary" [config]="{ label: 'Phone*' }" appearance="responsive">
    <vs-input-container>
      <input
        type="text"
        formControlName="phone"
        (keyup)="phoneNumberKeyUp($event); validateNumber()"
        (blur)="formatPhoneNumber(); validateNumber()"
        placeholder="(___) ___-____"
      />
    </vs-input-container>

    <span *ngIf="!validNumber" class="text-danger" slot="hint">Please enter a valid phone number</span>

    <span
      *ngIf="form.controls.phone.touched && form.controls.phone.hasError('required')"
      slot="hint"
      class="text-danger"
    >
      Phone number is required
    </span>
  </vs-form-field>
</form>
