<ng-container *ngIf="data$ | async as data">
  <vs-card>
    <vs-card-header
      [config]="{
        title: 'Performance',
        subtitle: 'Better understand this policy\'s activity through success metrics in the chart below.'
      }"
    >
      <vs-badge slot="after-title" theme="primary">
        {{ totalWarrantiesProvisioned }} {{ totalWarrantiesProvisioned !== 1 ? 'warranties' : 'warranty' }}
        provisioned
      </vs-badge>

      <vs-input-container slot="end">
        <label slot="start">Duration</label>
        <vs-select [value]="$any(14)" (valueChanges)="duration = $event">
          <li vs-select-option [value]="7">Past Week</li>
          <li vs-select-option [value]="14">Past 2 Weeks</li>
          <li vs-select-option [value]="30">Past Month</li>
          <li vs-select-option [value]="90">Past 3 Months</li>
          <li vs-select-option [value]="180">Past 6 Months</li>
          <li vs-select-option [value]="365">Past Year</li>
          <li vs-select-option [value]="730">Past 2 Years</li>
          <li vs-select-option [value]="1825">Past 5 Years</li>
        </vs-select>
      </vs-input-container>
    </vs-card-header>

    <vs-card-content>
      <div echarts class="w-full h-full" [autoResize]="true" [options]="data" [initOpts]="{ renderer: 'svg' }"></div>
    </vs-card-content>
  </vs-card>
</ng-container>
