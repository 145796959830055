import { StorageItem } from '../item';
import { Model } from './model';

export enum OutputStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  FAILED = 'FAILED',
}

export interface Result {
  id: string;

  created: Date;
  modified: Date;
  deleted: Date | null;

  processorId: string;
  processor?: Model;

  itemId: string;
  item?: StorageItem.Model;

  status: OutputStatus;

  outputDownloadUrl: string | null;
  externalId: string | null;
  error: object | null;
}
