import { Component, HostBinding, Input } from '@angular/core';
import { IconName } from '../../icon-name';
import { IconStyle } from '../../icon-style';

@Component({
  selector: 'vs-icon',
  templateUrl: './icon.component.html',
})
export class IconComponent {
  @HostBinding('class') styles = 'inline-block';
  @Input() size?: number | string;
  @Input() type?: IconStyle = 'line';
  @Input() name!: IconName;
}
