<ng-container *ngIf="page === pages.TERMS_AND_CONDITIONS">
  <p class="text-sm font-medium text-gray-900 mt-4">Terms & Conditions</p>
  <p class="text-sm text-gray-600 mb-4">Upload a Terms & Conditions document for this policy.</p>

  <form [formGroup]="termsAndConditionsForm">
    <!-- <vs-form-field [config]="{ label: { text: 'Legal Introduction', for: 'tc-legal-introduction-input' } }">
      <vs-rich-text-editor
        id="tc-legal-introduction-input"
        formControlName="legalIntroduction"
        [config]="{ hideToolbar: true }"
        (click)="goToPage(pages.TC_LEGAL)"
      ></vs-rich-text-editor>
    </vs-form-field>

    <vs-form-field
      class="mt-4"
      [config]="{ label: { text: 'Welcome Introduction', for: 'tc-welcome-introduction-input'} }"
    >
      <vs-rich-text-editor
        id="tc-welcome-introduction-input"
        formControlName="welcomeIntroduction"
        [config]="{ hideToolbar: true }"
        (click)="goToPage(pages.TC_WELCOME)"
      ></vs-rich-text-editor>
    </vs-form-field>

    <vs-form-field class="mt-4" [config]="{ label: { text: 'Definitions', for: 'tc-definitions-input'} }">
      <vs-rich-text-editor
        id="tc-definitions-input"
        formControlName="definitions"
        [config]="{ hideToolbar: true }"
        (click)="goToPage(pages.TC_DEFINITIONS)"
      ></vs-rich-text-editor>
    </vs-form-field> -->

    <vs-form-field>
      <vs-file-input
        formControlName="file"
        [accept]="'.PDF'"
        [moreInfo]="'Drag a PDF file here or browse to upload.'"
      ></vs-file-input>
    </vs-form-field>
  </form>

  <div
    class="flex gap-2 flex-wrap sm:flex-row mt-4"
    [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
  >
    <div
      *ngIf="!editing"
      class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
    >
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    </div>

    <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
      <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
      <span class="self-center">
        Saving will create a new policy version, the current policy will not be modified
      </span>
    </div>

    <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
      <button
        vs-button
        appearance="outline"
        class="flex-grow sm:flex-grow-0"
        [class.flex-1]="editing"
        (click)="editing ? close() : goBack()"
      >
        {{ editing ? 'Cancel' : 'Back' }}
      </button>

      <button
        vs-button
        theme="primary"
        class="flex-grow sm:flex-grow-0"
        [class.flex-1]="editing"
        [disabled]="saving || (editing && !termsAndConditionsForm.value.file)"
        (click)="editing ? saveTermsAndConditions() : goToPage(pages.COVERAGE_SUMMARY)"
      >
        {{ editing ? 'Save' : 'Next' }}
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="page === pages.COVERAGE_SUMMARY">
  <p class="text-sm font-medium text-gray-900 mt-4">Coverage Summary</p>
  <p class="text-sm text-gray-600 mb-4">Upload a Coverage Summary document for this policy.</p>

  <form [formGroup]="coverageSummaryForm">
    <!-- <vs-form-field [config]="{ label: { text: 'Legal Introduction', for: 'cs-legal-introduction-input' } }">
      <vs-rich-text-editor
        id="cs-legal-introduction-input"
        formControlName="legalIntroduction"
        [config]="{ hideToolbar: true }"
        (click)="goToPage(pages.CS_LEGAL)"
      ></vs-rich-text-editor>
    </vs-form-field>

    <vs-form-field
      class="mt-4"
      [config]="{ label: { text: 'Welcome Introduction', for: 'cs-welcome-introduction-input'} }"
    >
      <vs-rich-text-editor
        id="cs-welcome-introduction-input"
        [config]="{ hideToolbar: true }"
        formControlName="welcomeIntroduction"
        (click)="goToPage(pages.CS_WELCOME)"
      ></vs-rich-text-editor>
    </vs-form-field>

    <vs-form-field class="mt-4" [config]="{ label: { text: 'Definitions', for: 'cs-definitions-input'} }">
      <vs-rich-text-editor
        id="cs-definitions-input"
        formControlName="definitions"
        [config]="{ hideToolbar: true }"
        (click)="goToPage(pages.CS_DEFINITIONS)"
      ></vs-rich-text-editor>
    </vs-form-field> -->

    <vs-form-field>
      <vs-file-input
        formControlName="file"
        [accept]="'.PDF'"
        [moreInfo]="'Drag a PDF file here or browse to upload.'"
      ></vs-file-input>
    </vs-form-field>
  </form>

  <div
    class="flex gap-2 flex-wrap sm:flex-row mt-4"
    [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
  >
    <div
      *ngIf="!editing"
      class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
    >
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    </div>

    <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
      <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
      <span class="self-center">
        Saving will create a new policy version, the current policy will not be modified
      </span>
    </div>

    <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
      <button
        vs-button
        appearance="outline"
        class="flex-grow sm:flex-grow-0"
        [class.flex-1]="editing"
        (click)="editing ? close() : goToPage(pages.TERMS_AND_CONDITIONS)"
      >
        {{ editing ? 'Cancel' : 'Back' }}
      </button>

      <button
        vs-button
        theme="primary"
        class="flex-grow sm:flex-grow-0"
        [class.flex-1]="editing"
        [disabled]="saving || (editing && !coverageSummaryForm.value.file)"
        (click)="editing ? saveCoverageSummary() : pageComplete()"
      >
        Save
      </button>
    </div>
  </div>
</ng-container>

<div
  *ngIf="
    page === pages.TC_LEGAL ||
    page === pages.TC_WELCOME ||
    page === pages.TC_DEFINITIONS ||
    page === pages.CS_LEGAL ||
    page === pages.CS_WELCOME ||
    page === pages.CS_DEFINITIONS
  "
  class="p-4"
>
  <!-- Title -->
  <p
    *vsLet="
      (page - (page > pages.COVERAGE_SUMMARY ? pages.COVERAGE_SUMMARY : pages.TERMS_AND_CONDITIONS)) * 10
        | number as type
    "
    class="page-title"
  >
    {{ type === '1' ? 'Legal Introduction' : type === '2' ? 'Welcome Introduction' : 'Definitions' }}
  </p>
  <p class="page-subtitle">Please fill in the required fields for the document.</p>

  <!-- Form -->
  <form [formGroup]="richTextForm">
    <vs-rich-text-editor formControlName="text"></vs-rich-text-editor>
  </form>

  <!-- Buttons -->
  <div
    class="flex gap-2 flex-wrap sm:flex-row mt-4"
    [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
  >
    <div
      *ngIf="!editing"
      class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
    >
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    </div>

    <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
      <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
      <span class="self-center">
        Saving will create a new policy version, the current policy will not be modified
      </span>
    </div>

    <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
      <button
        vs-button
        appearance="outline"
        class="flex-grow sm:flex-grow-0"
        [class.flex-1]="editing"
        (click)="
          editing
            ? close()
            : goToPage(page < pages.COVERAGE_SUMMARY ? pages.TERMS_AND_CONDITIONS : pages.COVERAGE_SUMMARY)
        "
      >
        {{ editing ? 'Cancel' : 'Back' }}
      </button>

      <button
        vs-button
        theme="primary"
        class="flex-grow sm:flex-grow-0"
        [class.flex-1]="editing"
        (click)="
          saveRichText(); goToPage(page < pages.COVERAGE_SUMMARY ? pages.TERMS_AND_CONDITIONS : pages.COVERAGE_SUMMARY)
        "
      >
        Confirm
      </button>
    </div>
  </div>
</div>
