import { Component } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';
import moment from 'moment';

@Component({
  selector: 'ws-admin-sign-in-page',
  templateUrl: './sign-in.page.html',
})
export class SignInPage {
  constructor(private portalSvc: PortalService) {}
  contactEmail = this.portalSvc.config.contactEmail;
  currentYear = moment().format('YYYY');
}
