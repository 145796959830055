<ng-container *vsLet="paymentMethods$ | async as paymentMethods">
  <button
    #trigger="cdkOverlayOrigin"
    vs-button
    cdkOverlayOrigin
    appearance="outline"
    class="!w-full !p-0 !h-auto"
    [theme]="paymentMethods?.length && !!value ? 'primary' : 'default'"
    [disabled]="disabled || !paymentMethods?.length"
    (click)="overlayOpen = !overlayOpen; touched$.next()"
  >
    <div #triggerEl class="w-full py-4 px-6 flex items-center justify-start gap-6">
      <!-- Radio Button (always checked) -->
      <input
        vsInput
        type="radio"
        class="pointer-events-none !bg-transparent"
        [checked]="paymentMethods?.length && !!value"
      />

      <!-- Payment Method Item -->
      <vs-payment-method-item class="flex-1" [paymentMethod]="selectedPaymentMethod$ | async"></vs-payment-method-item>

      <!-- Expand Icon -->
      <vs-icon
        *ngIf="paymentMethods && paymentMethods.length && (paymentMethods.length > 1 || !value)"
        name="chevron-down"
        size="20"
      ></vs-icon>
    </div>
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayOpen"
    [cdkConnectedOverlayWidth]="triggerEl.clientWidth + 2"
    (overlayOutsideClick)="$event.stopImmediatePropagation(); overlayOpen = false"
  >
    <div class="w-full overflow-auto max-h-[40vh] bg-base p-1 rounded-lg border">
      <button
        *ngFor="let paymentMethod of paymentMethods"
        vs-button
        theme="primary"
        appearance="clear"
        class="!w-full !p-0 !h-auto"
        (click)="control.setValue(paymentMethod.id); overlayOpen = false"
      >
        <div class="w-full py-3 px-6 flex items-center justify-start gap-6">
          <!-- Radio Button (always checked) -->
          <input
            vsInput
            type="radio"
            class="pointer-events-none !bg-transparent"
            [formControl]="control"
            [value]="paymentMethod.id"
          />

          <!-- Payment Method Item -->
          <vs-payment-method-item class="flex-1" [paymentMethod]="paymentMethod"></vs-payment-method-item>
        </div>
      </button>
    </div>
  </ng-template>
</ng-container>
