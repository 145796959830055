import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { User } from '@vsolv/core/users/domain';
import { UserService } from '@vsolv/core/users/web';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { FileInputDirective } from '@vsolv/vectors-ui/input';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
import { BehaviorSubject, combineLatest, firstValueFrom, map, switchMap } from 'rxjs';
import { RoleAssignmentsService, SecurityService, StaffService } from '../../services';

@Component({
  selector: 'ws-user-profile-form-component',
  templateUrl: './user-profile-form-component.component.html',
})
export class UserProfileFormComponent {
  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private userSvc: UserService,
    private toastSvc: ToastService,
    private securitySvc: SecurityService,
    private roleSvc: RoleAssignmentsService,
    private breakpointObserver: BreakpointObserver,
    private staffSvc: StaffService
  ) {}

  @ViewChild('fileInput') fileInput?: FileInputDirective;

  @Input() user?: User.Model;

  @PropertyListener('staff') staff$ = new BehaviorSubject<Staff.Model | undefined>(undefined);
  @Input() staff?: Staff.Model;

  self$ = this.staffSvc.retrieveSelf();

  roles$ = combineLatest([this.self$, this.staff$]).pipe(
    switchMap(async ([self, staff]) => {
      if (!staff) {
        //no staff input, retrieve self roles
        return await this.roleSvc.getStaffRoles();
      } else {
        //staff input, if staff === self get self roles, else get staff roles
        return await this.roleSvc.getStaffRoles(staff.id !== self.id ? staff.id : undefined);
      }
    })
  );

  distributorsAndRoles$ = this.roles$.pipe(
    switchMap(async roles => {
      const distributors = (await this.securitySvc.searchDistributorsWithPermissions({ limit: 100 })).items;
      const distributorsAndRoles: {
        distributor: Distributor.PartialModel | null;
        roles: Staff.Security.RoleAssignment[];
        isSuperAdmin?: boolean;
      }[] = [];
      if (roles.some(role => role.permissionKey === null && !Staff.Security.getRole(role.roleId)?.hideRole)) {
        distributorsAndRoles.push({
          distributor: null,
          roles: roles.filter(role => role.permissionKey === null && !Staff.Security.getRole(role.roleId)?.hideRole),
        });
      }
      for (const distributor of distributors) {
        const applicableRoles = roles.filter(
          role => role.permissionKey === distributor.permissionKey && !Staff.Security.getRole(role.roleId)?.hideRole
        );
        if (!applicableRoles.length) continue;

        distributorsAndRoles.push({
          distributor,
          roles: applicableRoles,
        });
      }
      return distributorsAndRoles;
    })
  );

  initialUrl = '';
  isLargeScreen$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => !state.matches));
  profileImg: File | null = null;

  resetProfileFormValue: { name: string; email: string; img: File | null } = {
    name: '',
    email: '',
    img: null,
  };

  form = this.fb.group({
    name: [null as string | null, [Validators.required]],
    email: [null as string | null, [Validators.required, Validators.email]],
    img: new FormControl({ value: this.profileImg, disabled: false }),
    // [null as File | null],
  });

  inputIsValid() {
    return this.form.valid;
  }

  @PropertyListener('user')
  setUser(value: User.Model) {
    this.resetProfileFormValue = {
      name: value?.displayName || '',
      email: value?.email || '',
      img: this.profileImg || null,
    };
    this.resetForm();
  }

  async resetForm() {
    try {
      this.resetImage();
      this.form.reset(this.resetProfileFormValue);
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  resetImage() {
    this.fileInput?.clear();
    this.initialUrl = '';
    if (this.user?.photoURL && this.user?.photoURL.length) {
      this.initialUrl = this.user.photoURL;
      const file = new File([], this.user.photoURL.split('images/').pop() || '');
      this.profileImg = file;
    }
  }

  async updateDetails() {
    const userId = this.user?.id;
    if (!userId) return;
    const updateProfileDto: User.UpdateUserProfileRequest = {};
    const formControlValue = this.form.value;

    if (!this.user?.id) return;
    if (formControlValue.name !== this.resetProfileFormValue.name) {
      updateProfileDto.displayName = formControlValue.name || undefined;
    }
    if (formControlValue.email !== this.resetProfileFormValue.email) {
      updateProfileDto.email = formControlValue.email || '';
    }
    //<<<<<<<<<<<<<<<<<<<<<<<<<Image Change Detection >>>>>>>>>>>>>>>>>>>>>>>>>>>>
    if (formControlValue.img && formControlValue.img.name !== this.user.photoURL) {
      updateProfileDto.photo = formControlValue.img.name;
    }
    // <<<<<<<<<<<<<<<<<<<<<<< END >>>>>>>>>>>>>>>>>>>>>>>>>
    this.form.markAsPristine();
    if (formControlValue.name || formControlValue.email || formControlValue.img !== undefined) {
      try {
        const { photoUploadUrl } = await this.userSvc.updateUserProfile(updateProfileDto, this.user.id);
        const { displayName, email } = await this.userSvc.getSelf();
        if (displayName && email) {
          this.resetProfileFormValue = {
            ...this.resetProfileFormValue,
            name: displayName,
            email: email,
          };
        }
        if (photoUploadUrl && formControlValue.img) {
          await this.uploadFileToGCP(formControlValue.img, photoUploadUrl);
          this.user = await this.userSvc.getSelf();
        }

        if (this.form.get('img')?.touched && formControlValue.img === null) {
          if (this.user?.id) {
            try {
              await this.userSvc.updateUserProfile({ photo: null }, this.user.id);
              this.user = await this.userSvc.getSelf();
            } catch (e) {
              console.error(e);
            }
          }
        }

        this.toastSvc.show({
          type: 'success',
          title: 'Successfully updated profile.',
          text: 'Your profile details have been changed.',
        });
      } catch (e) {
        console.error(e);
        this.toastSvc.show({
          type: 'error',
          title: 'Error! Something went wrong',
          text: 'our apologies for the inconvenience',
        });
      }
    }
  }

  async submitForm() {
    this.form.disable();
    try {
      await this.updateDetails();
      this.form.enable();
    } catch (e) {
      this.form.enable();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onImageUpload(event: any) {
    if (!event.target.files[0].type.includes('image') || event.target.files[0].type.includes('image/svg')) {
      this.toastSvc.show({
        type: 'error',
        title: 'Invalid file',
        text: 'Uploaded file type is not supported.',
      });
      (event.target as HTMLInputElement).value = ''; // clear the file input
      event.preventDefault(); // stop the onchange event from executing
      this.form.controls.img.setValue(this.profileImg);
      this.form.controls.img.markAsUntouched();
      this.form.controls.img.markAsPristine();
      return;
    }
    const fr = new FileReader();
    fr.readAsDataURL(event.target.files[0]);
    fr.onload = e => {
      const img = new Image();
      img.src = e.target?.result as string;
      img.onload = () => {
        const { width, height } = img;
        if (width > 800 || height > 400) {
          this.toastSvc.show({
            type: 'error',
            title: 'Invalid image size',
            text: 'Uploaded image dimensions exceeded the limit of 800x400px.',
          });
          (event.target as HTMLInputElement).value = ''; // clear the file input
          event.preventDefault(); // stop the onchange event from executing
          this.form.controls.img.setValue(this.profileImg);
          this.form.controls.img.markAsUntouched();
          this.form.controls.img.markAsPristine();
          return;
        }
      };
    };
  }

  async clearImage() {
    this.fileInput?.clear();
    if (!this.initialUrl) return;
    this.initialUrl = '';
  }

  private async uploadFileToGCP(file: File, uploadUrl: string) {
    let buffer;
    if (file) buffer = await this.readFileBuffer(file);
    try {
      await firstValueFrom(this.http.put(uploadUrl, buffer));
    } catch (e) {
      console.error(e);
    }
  }
  async readFileBuffer(file: File) {
    return new Promise(res => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = event => res(event?.target?.result);
    }) as unknown as ArrayBuffer;
  }
}
