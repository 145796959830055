<vs-dialog *vsLet="isMobile$ | async as isMobile" [config]="{ dialogContainerClass: 'w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: isMobile ? 'default' : 'simple',
      hideCloseButton: false,
      
      icon: 'arrow-square-up',
      iconTheme: 'primary',

      title: 'Reactivate ' + distributor?.name || 'Distributor',
      subtitle: 'Are you sure you want to reactivate this distributor? Reactivating a distributor will grant team members the ability to provision new warranties using SFTP again. Any checkout links to policies and plans copied and sent out before reactivation will need to be recopied and sent out to customers once this distributor has been reactivated.'
    }"
  ></vs-dialog-header>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
        Cancel
      </button>

      <button
        class="flex-grow"
        vs-button
        size="md"
        theme="primary"
        [disabled]="saving"
        (click)="reactivateDistributor()"
      >
        Reactivate
      </button>
    </div>
  </div>
</vs-dialog>
