import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { catchError, first, map, of } from 'rxjs';

@Injectable()
export class UnderMaintenanceGuard implements CanActivate {
  constructor(private http: HttpClient, private router: Router, private configSvc: PortalService) {}

  canActivate() {
    return this.http.get('/v1', { responseType: 'text' }).pipe(
      first(),
      map(() => true),
      catchError(() => of(this.router.createUrlTree(['/503'])))
    );
  }
}
