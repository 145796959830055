<!-- Toggle -->
<ng-container *vsLet="isLargeScreen$ | async as isLarge">
  <vs-form-field
    class="pt-6 pb-6 gap-8"
    [config]="{
      label: { text: 'System Notifications', for: 'avatar-logo-input' },
      description:
        'These are notifications for organization updates, such as invite acceptances and password reset notices.'
    }"
    theme="primary"
  >
    <div class="flex gap-2 items-center">
      <input id="toggle" vsInput appearance="toggle" type="checkbox" checked [disabled]="true" />
      <label for="toggle">Email</label>
    </div>
  </vs-form-field>

  <vs-divider [class]="!isLarge ? 'mt-5' : ''"></vs-divider>
</ng-container>
