import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

@Injectable()
export class OneTimeTokenResolver implements Resolve<string | null> {
  oneTimeToken: string | null = null;

  resolve() {
    return this.oneTimeToken;
  }
}
