<ng-container *vsLet="staffView$ | async as staffView">
  <ng-container *vsLet="isLargeScreen$ | async as isLargeScreen">
    <ng-container *vsLet="session$ | async as session">
      <div class="relative flex flex-col items-center gap-4 mb-3">
        <div class="flex gap-2 w-full items-center">
          <div class="w-12 h-12">
            <button
              vs-icon-button
              appearance="clear"
              theme="default"
              size="lg"
              *ngIf="!isLargeScreen"
              (click)="previousPage(staffView ?? false)"
            >
              <vs-icon name="chevron-left" size="20"></vs-icon>
            </button>
          </div>
          <div class="flex justify-center items-center flex-grow" name="icon">
            <vs-featured-icon
              [name]="$any(session?.policy?.propertySet?.icon) || 'diamond-01'"
              theme="primary"
            ></vs-featured-icon>
          </div>
          <div class="w-12 h-12">
            <button
              vs-icon-button
              appearance="clear"
              theme="default"
              size="lg"
              [disabled]="!stepCompleted"
              *ngIf="!isLargeScreen"
              (click)="nextPage(session, staffView ?? false)"
            >
              <vs-icon name="chevron-right" size="20"></vs-icon>
            </button>
          </div>
        </div>
        <div name="title" class="text-3xl font-semibold leading-[38px] text-center">
          {{
            'Tell us about your ' +
              (staffView ? "customer's " : '') +
              (session?.policy?.propertySet?.name || 'asset' | lowercase) +
              '.'
          }}
        </div>

        <div name="subtitle" class="text-sm text-gray-500" *ngIf="session?.asset">
          Please verify your {{ staffView ? "customer's " : '' }} existing
          {{ session?.policy?.propertySet?.name ?? 'asset' | lowercase }}.
        </div>
        <div name="subtitle" class="text-sm text-gray-500" *ngIf="!session?.asset">
          Please enter the details of your {{ staffView ? "customer's " : '' }}
          {{ session?.policy?.propertySet?.name ?? 'asset' | lowercase }}.
        </div>
      </div>

      <div class="grid place-items-center mb-8">
        <vs-property-set-input
          #infosForm
          [setId]="session?.policy?.propertySetId ?? ''"
          [showHidden]="staffView ?? false"
          [formControl]="assetInfo"
          class="max-w-lg w-full"
          [extraData]="
            session?.customer?.address?.line1 ? { address: session?.customer?.address, staffView } : undefined
          "
        ></vs-property-set-input>
      </div>
      <div class="flex gap-3 mt-4" [class.flex-col]="!isLargeScreen">
        <button
          [class]="!isLargeScreen ? 'min-w-full' : ''"
          vs-button
          theme="primary"
          (click)="nextPage(session, staffView ?? false)"
          [disabled]="!infosForm.valid || !session"
        >
          <div class="flex gap-3 items-center">
            <span>Continue</span>
            <vs-icon *ngIf="stepCompleted" name="check"></vs-icon>
          </div>
        </button>

        <button
          [class]="!isLargeScreen ? 'min-w-full' : ''"
          vs-button
          (click)="previousPage(staffView ?? false)"
          appearance="outline"
        >
          Back
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
