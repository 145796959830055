/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { SecurityService } from '@wsphere/staff/web';
import { ClaimLifecycleStep, Policy } from '@wsphere/warranties/domain';
import { catchError, combineLatest, forkJoin, from, map, of, startWith, switchMap, tap, timer } from 'rxjs';
import { ClaimLifecycleStepsTableComponent } from '../../../claim-lifecycle-steps';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-policy-claim-lifecycle-page',
  templateUrl: './policy-claim-lifecycle.page.html',
})
export class PolicyClaimLifecyclePage {
  constructor(
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService
  ) {}

  protected savingCustomerPortalConfig = false;
  protected customerPortalConfigForm = new FormGroup({
    selfServeClaims: new FormControl(false, {
      nonNullable: true,
      validators: [Validators.required],
    }),
    claimsPlaceholder: new FormControl<string | null>(null),
  });
  protected customerPortalConfigFormChanged$ = this.customerPortalConfigForm.valueChanges.pipe(
    startWith(this.customerPortalConfigForm.value),
    switchMap(value =>
      this.policy$.pipe(map(policy => JSON.stringify(policy?.customerPortalConfig ?? {}) !== JSON.stringify(value)))
    )
  );

  @ViewChild('checkSaveDialog') checkSaveDialog?: DialogComponent;

  @ViewChild('table') table?: ClaimLifecycleStepsTableComponent;

  @Output() policyChanged = new EventEmitter<Policy.Model>();

  draft = Policy.Status.DRAFT;

  form = this.formBuilder.group({
    steps: [[] as ClaimLifecycleStep.ClaimLifecycleStepDto[]],
  });

  policy$ = this.policySvc.getPolicyObservable();

  canEdit$ = from(this.securitySvc.hasAccess('pol_EditLifecycle', null));

  patchForm$ = combineLatest([this.policy$, this.canEdit$]).pipe(
    tap(([policy, canEdit]) => {
      this.form.patchValue({ steps: policy?.claimLifecycleSteps ?? [] });

      this.customerPortalConfigForm.reset({
        selfServeClaims: policy?.customerPortalConfig.selfServeClaims ?? false,
        claimsPlaceholder: policy?.customerPortalConfig.claimsPlaceholder || null,
      });

      if (policy && canEdit) this.customerPortalConfigForm.enable();
      else this.customerPortalConfigForm.disable();
    })
  );

  checkSave() {
    this.checkSaveDialog?.open();
  }

  async save(policy: Policy.Model) {
    if (!policy) return;

    const reponse = await this.policySvc
      .updateClaimLifecycleSteps(policy.id, { steps: this.form.value.steps || [] })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

    if (reponse?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Steps updated',
        text: 'The steps for <strong>' + policy?.title + '</strong> have been successfully updated.',
      });

      this.table?.edit();
      this.policySvc.refreshPolicy();
    }
  }

  protected resetCustomerPortalConfigForm(policy: Policy.Model) {
    this.customerPortalConfigForm.reset({
      selfServeClaims: policy?.customerPortalConfig.selfServeClaims ?? false,
      claimsPlaceholder: policy?.customerPortalConfig.claimsPlaceholder || null,
    });
  }

  protected async saveCustomerPortalConfig(policyId: string) {
    this.savingCustomerPortalConfig = true;
    this.customerPortalConfigForm.disable();

    forkJoin([
      this.policySvc
        .updateCustomerPortalConfig({ policyId, customerPortalConfig: this.customerPortalConfigForm.getRawValue() })
        .pipe(catchError(() => of(null))),
      timer(750),
    ]).subscribe(([response]) => {
      this.savingCustomerPortalConfig = false;
      this.customerPortalConfigForm.enable();

      if (response) this.policySvc.refreshPolicy();
      else {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong.',
          text: "Something went wrong and the account couldn't be updated. Please try again, and contact your administrator if the issue persists.",
        });
      }
    });
  }
}
