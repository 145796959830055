<ng-container *vsLet="page$ | async as page">
  <ng-container *ngIf="salesCount; else noSales">
    <vs-table
      *ngIf="columns$ | async as columns"
      [columns]="columns"
      [data]="page?.items ?? []"
      (rowClicked)="rowClicked.emit($event)"
    ></vs-table>

    <vs-pagination
      [config]="page?.meta"
      (pagination)="pagination$.next({ page: $event.currentPage, limit: $event.itemsPerPage })"
    ></vs-pagination>
  </ng-container>

  <ng-template #noSales>
    <vs-empty-state
      class="p-8 -mt-3"
      [config]="{
        title: 'No sales found.',
        subtitle: 'Once a sale has been made, its details will appear here!',
        imgsrc: 'distributor'
      }"
    ></vs-empty-state>
  </ng-template>
</ng-container>
