<ng-container *vsLet="propertySet$ | async as propertySet">
  <div
    *ngIf="propertySet?.properties?.length && propertyValues"
    class="flex flex-col gap-1 text-gray-600 text-start text-xs max-w-full"
  >
    <ng-container *ngIf="firstOnly; else all">
      <vs-property-value-item
        [showHidden]="showHidden"
        [assignment]="propertySet!.properties![0]"
        [propertyValue]="propertyValues![propertySet!.properties![0]!.property!.valueKey]"
        [showHidden]="showHidden"
      >
      </vs-property-value-item>
    </ng-container>

    <ng-template #all>
      <vs-property-value-item
        *ngFor="let assignment of propertySet!.properties!"
        [showHidden]="showHidden"
        [assignment]="assignment"
        [propertyValue]="propertyValues![assignment.property!.valueKey]"
        [showHidden]="showHidden"
      >
      </vs-property-value-item>
    </ng-template>
  </div>
</ng-container>
