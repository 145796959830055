import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DropdownModule } from '@vsolv/vectors-ui/dropdown';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { TabComponent, TabsComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ThemingModule,
    IconsModule,
    BadgeModule,
    DropdownModule,
    RouterModule,
    TooltipModule,
  ],
  declarations: [TabsComponent, TabComponent],
  exports: [TabsComponent, TabComponent],
})
export class TabsModule {}
