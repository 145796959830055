<vs-card
  class="w-full h-60 hover:cursor-pointer hover:border-primary-200 hover:shadow-md"
  (click)="dialog.openDialog()"
>
  <div class="flex flex-col w-full h-full p-4 justify-between">
    <div class="flex flex-col gap-2 select-none">
      <div class="flex flex-row justify-between">
        <div
          class="border border-primary-200 rounded-lg bg-primary-100 w-14 h-14 flex flex-col justify-center items-center"
        >
          <vs-icon [name]="config.icon" size="40" type="duocolor" class="text-primary-600"></vs-icon>
        </div>
        <vs-badge *ngIf="this.enabled" class="w-fit h-fit theme-success">Enabled</vs-badge>
      </div>
      <div class="text-md font-medium">{{ config.title }}</div>
      <div class="text-sm font-normal">{{ config.body }}</div>
    </div>
    <div class="text-xs font-normal flex flex-row items-center gap-2">
      Powered by
      <div
        class="flex flex-row justify-between gap-1 p-[2px] pr-1 items-center rounded-2xl w-fit h-fit bg-opacity-0 border-2 border-primary-200 text-primary-800"
      >
        <div
          class="w-5 h-5 rounded-2xl bg-contain bg-center"
          [ngStyle]="{ 'background-image': 'url(' + config.poweredBy.icon + ')' }"
        ></div>
        {{ config.poweredBy.name }}
      </div>
    </div>
  </div>
</vs-card>
<ws-app-card-dialog
  #dialog
  [config]="config"
  (dialogClosed)="this.dialogClosed.emit()"
  (dialogOpened)="this.dialogOpened.emit()"
>
  <ng-content></ng-content>
</ws-app-card-dialog>
