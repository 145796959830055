/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, switchMap } from 'rxjs';
import { DistributorService } from '../../services';

@Component({
  selector: 'ws-distributor-picker',
  templateUrl: './distributor-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DistributorPickerComponent,
    },
  ],
})
export class DistributorPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(
    public elementRef: ElementRef,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService
  ) {}

  @PropertyListener('value') value$ = new ReplaySubject<Distributor.Model>(1);
  @Input() value: Distributor.Model | null = null;

  @Input() placeholder = 'Find a distributor...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() onlyActive = false;
  @Input() onbordingLinkView = false;

  @Input() themeColor: ThemeColor = 'primary';

  @Output() openChanged = new EventEmitter<boolean>();

  @Output() search = new EventEmitter<string>();
  @Output() valueChanges = new EventEmitter<Distributor.Model | null>();

  globalDistributors$ = this.securitySvc.globalDistributors$;

  readonly searchQuery$ = new BehaviorSubject<string>('');

  touched = false;
  overlayOpen = false;

  distributors$: Observable<Distributor.Model[]> = combineLatest([this.searchQuery$, this.globalDistributors$]).pipe(
    switchMap(async ([search]) => {
      let response: Distributor.ListDistributorsQueryResponse | null = null;

      if (this.onbordingLinkView) {
        response = await this.distributorSvc.listDistributorsForOnboardingLink({
          ...(search && { search }),
          ...(this.onlyActive && { status: Distributor.Status.ACTIVE }),
        });
      } else {
        response = await this.distributorSvc.listDistributors({
          ...(search && { search }),
          ...(this.onlyActive && { status: Distributor.Status.ACTIVE }),
        });
      }

      const distributors = response.items.filter(distributor => distributor.id !== this.value?.id);
      return this.value ? [this.value, ...distributors] : distributors;
    })
  );

  @Input() compareWith: (v0: Distributor.Model | null, v1: Distributor.Model | null) => boolean = (
    v0: Distributor.Model | null,
    v1: Distributor.Model | null
  ) => {
    switch (typeof v0) {
      case 'object':
        return JSON.stringify(v0) === JSON.stringify(v1);
      default:
        return v0 === v1;
    }
  };

  onChange = (_value: Distributor.Model | null) => {};
  onTouched = () => {};

  selectValueChanged(value: Distributor.Model) {
    this.markAsTouched();
    this.value = value;
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  searchFor(search: string) {
    this.search.emit(search);
    this.searchQuery$.next(search);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: Distributor.Model | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: Distributor.Model | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
