import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Staff } from '@wsphere/staff/domain';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'ws-role-badge',
  templateUrl: './role-badge.component.html',
})
export class RoleBadgeComponent {
  @PropertyListener('permissionKey') permissionKey$ = new BehaviorSubject<string | null | undefined>(undefined);
  @Input() permissionKey?: string | null;

  @PropertyListener('roleId') roleId$ = new BehaviorSubject<string | null | undefined>(undefined);
  @Input() set roleId(roleId: string) {
    this.role = Staff.Security.getRole(roleId);
    if (this.role) this.permissions = Staff.Security.getPermissionsFromRoles([this.role.id]);
  }

  @Input() removable = false;
  @Input() showPermissionKeyLabel = false;

  @Output() removed = new EventEmitter();

  role?: Staff.Security.Role;
  permissions?: Staff.Security.Permission[];

  permissionKeyLabel$ = this.permissionKey$.pipe(
    map(permissionKey => {
      if (!permissionKey) return '(Global)';
      return `(${permissionKey})`;
    })
  );

  remove() {
    if (!this.removable) return;
    this.removed.emit(this.role?.id);
  }
}
