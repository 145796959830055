/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Customer } from '@wsphere/customers/domain';
import { CustomerService } from '@wsphere/customers/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim, Warranty } from '@wsphere/warranties/domain';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { WarrantyWebService } from '../../../warranty';
import { ClaimQuickAddComponent, QuickAddAttachment, QuickAddNote } from '../../components';
import { ClaimService } from '../../services';

enum pages {
  WARRANTY_SELECTION = 1,
  COMPLAINT = 2,
  SUMMARY = 3,
}

@Component({
  selector: 'ws-open-claim-dialog',
  templateUrl: './open-claim.dialog.html',
})
export class OpenClaimDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private customerSvc: CustomerService,
    private warrantySvc: WarrantyWebService
  ) {}

  @ViewChild('openClaimDialog') openClaimDialog?: DialogComponent;
  @ViewChild('quickAddComponent') quickAddComponent?: ClaimQuickAddComponent;

  @PropertyListener('claim') claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim: Claim.Model | null = null;

  @Input() set customer(customer: Customer.Model | undefined) {
    if (customer) this.form.patchValue({ customer });
    if (this.form.value.customer && this.form.value.warranty) this.page = pages.COMPLAINT;
  }

  @Input() set warranty(warranty: Warranty.Model | null) {
    if (warranty) this.setWarranty(warranty);
  }

  @Input() isCustomerPortal = false;
  @Input() pathAfterClose: string | null = null;

  @Output() closed = new EventEmitter<boolean>();
  @Output() claimId = new EventEmitter<string>();

  page = 1;
  pages = pages;

  submitting = false;

  notes: QuickAddNote[] = [];
  attachments: QuickAddAttachment[] = [];

  form = this.formBuilder.group({
    customer: [null as Customer.Model | null, [Validators.required]],
    warranty: [null as Warranty.Model | null, [Validators.required]],
    complaint: ['', [Validators.required]],
    agree: [false],
  });

  claimInput$ = this.claim$.pipe(
    tap(claim => {
      if (claim) {
        this.form.patchValue({ complaint: claim.complaint });
        this.page = pages.COMPLAINT;
      } else {
        this.form.reset();
        this.page = pages.WARRANTY_SELECTION;
      }
    })
  );

  warranties: Warranty.Model[] | null = null;
  warranties$ = this.form.controls.customer.valueChanges.pipe(
    switchMap(async customer => {
      if (customer) {
        const response = await this.warrantySvc.openClaimWarrantyList({ customerId: customer.id });
        if (response.items?.length) this.warranties = response.items;
      }
    })
  );

  readonly isMyClaim$ = this.claim$.pipe(
    switchMap(async claim => {
      const currentUser = await this.customerSvc.retrieveSelf();
      return claim?.warranty?.customerId === currentUser?.id;
    })
  );

  canOpenClaim$ = this.isMyClaim$.pipe(
    switchMap(async isMyClaim => {
      if (isMyClaim) return true;
      else if (this.isCustomerPortal) return false;

      return await this.securitySvc.hasAccess(
        'clm_OpenClaim',
        this.securitySvc.globalDistributors$.value?.map(dist => dist.permissionKey) || null
      );
    })
  );

  openDialog(warranty?: Warranty.Model | null, customer?: Customer.Model | null) {
    if (customer) this.customer = customer;
    if (warranty) this.warranty = warranty;
    this.warranties = null;
    this.openClaimDialog?.open();
  }

  closeDialog() {
    this.form.reset();
    this.closed.emit();
    this.page = 1;
    this.openClaimDialog?.close();
  }

  setWarranty(warranty: Warranty.Model) {
    this.form.patchValue({ warranty });
  }

  warrantyTheme(): ThemeColor {
    const warranty = this.form.value.warranty;
    if (!warranty) return 'default';

    return warranty.status === Warranty.Status.ACTIVATED ? 'success' : 'danger';
  }

  addNote(note: QuickAddNote) {
    this.notes.push(note);
  }

  removeNote(note: QuickAddNote) {
    this.notes = this.notes.filter(notes => notes.title !== note.title);
  }

  addAttachment(attachment: QuickAddAttachment) {
    this.attachments.push(attachment);
  }

  removeAttachment(attachment: QuickAddAttachment) {
    this.attachments = this.attachments.filter(attachments => attachments.title !== attachment.title);
  }

  checkAgree() {
    if (this.form.value.agree) this.form.patchValue({ agree: false });
    else this.form.patchValue({ agree: true });
  }

  async submit(createItem: boolean) {
    this.submitting = true;

    const data = this.form.value;
    if (!data.warranty || !data.complaint) return;

    const claim = await this.claimSvc
      .open({
        warrantyId: data.warranty.id,
        complaint: data.complaint,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.closeDialog();
      });

    if (claim) {
      await Promise.all(
        this.notes.map(async note => {
          await this.quickAddComponent?.addNote(claim.id, note);
        })
      );
      await Promise.all(
        this.attachments.map(async attachment => await this.quickAddComponent?.addAttachment(claim.id, attachment))
      );

      this.toastSvc.show({
        type: 'success',
        title: 'Claim Opened',
        text: `A new claim has been successfully created. Claim items you create will be added under this claim.`,
      });

      this.claimId.emit(claim.id);
      this.closeDialog();

      const routeToNavigate = createItem
        ? `claims/${claim.id}`
        : this.pathAfterClose
        ? this.pathAfterClose
        : `claims/${claim.id}`;

      this.router.navigate([routeToNavigate], { queryParams: { createItem: createItem } });
    }

    this.submitting = false;
  }

  async update() {
    if (!this.claim || !this.form.value.complaint) return;

    const claim = await this.claimSvc
      .update(this.claim.id, {
        complaint: this.form.value.complaint,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.closeDialog();
      });

    if (claim) {
      this.toastSvc.show({
        type: 'success',
        title: 'Claim Updated',
        text: `<strong>Claim #${this.claim.claimNumber}</strong> has been successfully updated.`,
      });

      this.closeDialog();
    }
  }
}
