/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Staff } from '@wsphere/staff/domain';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import { SecurityService, StaffService } from '../../services';

@Component({
  selector: 'ws-staff-or-email-picker',
  templateUrl: './staff-or-email-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StaffOrEmailPickerComponent,
    },
  ],
})
export class StaffOrEmailPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef, private staffSvc: StaffService, private securitySvc: SecurityService) {}

  @PropertyListener('permissionKey') permissionKey$ = new BehaviorSubject<string | null>(null);
  @Input() permissionKey: string | null = null;

  @PropertyListener('value') value$ = new BehaviorSubject<string[]>([]);
  @Input() value: string[] = [];

  @Input() placeholder = 'Search members';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';
  touched = false;

  @Output() memberAdded = new EventEmitter<string>();
  @Output() memberRemoved = new EventEmitter<string>();
  @Output() valueChanges = new EventEmitter<string[]>();

  readonly searchQuery$ = new BehaviorSubject<string>('');

  validEmail = false;

  self$ = this.staffSvc.retrieveSelf();

  users: { name: string; email: string }[] = [];

  canViewStaff$ = combineLatest([this.self$, this.permissionKey$]).pipe(
    switchMap(async () => await this.securitySvc.hasAccess('stf_ViewStaffRoles', null))
  );

  staff$ = combineLatest([this.searchQuery$, this.self$, this.canViewStaff$, this.permissionKey$]).pipe(
    switchMap(async ([search, self, canViewStaff, permissionKey]) => ({
      self,
      response: canViewStaff
        ? await this.staffSvc.list({
            limit: 100,
            search: search?.toLowerCase(),
            permissionKeys: permissionKey ? [permissionKey] : null,
          })
        : { items: [] },
    })),
    map(({ response, self }) => response.items.filter(staff => staff.id !== self.id))
  );

  onChange = (_value: string[]) => {};
  onTouched = () => {};

  writeValue(value: string[]): void {
    this.value = value || [];
    this.users = this.value.map(email => {
      return { name: '', email };
    });
  }

  registerOnChange(onChange: (_value: string[]) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validateEmail(email: string) {
    const valid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );

    this.validEmail = !!valid;
    return this.validEmail;
  }

  async selectUser(user: Staff.Model | string) {
    let name: string = (user as any)?.name ?? '';
    const email: string = (user as any)?.email ?? user;

    if (!this.users.map(user => user.email).includes(email)) {
      if (this.validateEmail(email)) {
        try {
          const user = await this.staffSvc.retrieve(email, Staff.IdField.email);
          if (user.user && user.user.displayName) name = user.user.displayName;
        } catch (e) {
          console.error(e);
        }
        this.users.push({ name, email });
        this.value.push(email);
        this.memberAdded.emit(email);
      }
    } else {
      this.users = this.users.filter(user => user.email !== email);
      this.value = this.value.filter(val => val !== email);
      this.memberRemoved.emit(email);
    }
    this.markAsTouched();
    this.onChange(this.value);
  }

  userSelected(user: { name: string; email: string }) {
    return this.users.map(user => user.email).includes(user.email);
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
