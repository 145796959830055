<ng-container *vsLet="staffView$ | async as staffView">
  <ws-provision-warranty-complete
    *ngIf="warranty$ | async as warranty; else noWarranty"
    [warranty]="warranty"
    [staffView]="staffView ?? false"
  ></ws-provision-warranty-complete>

  <ng-template #noWarranty>
    <ng-container *vsLet="session$ | async as session">
      <div
        *vsLet="isLargeScreen$ | async as isLargeScreen"
        class="flex gap-4 {{ isLargeScreen === false ? 'flex-col' : '' }}"
      >
        <ng-container *vsLet="preview$ | async as preview">
          <ng-container *vsLet="preview?.purchase as purchase">
            <ng-container *ngIf="(baseCoverages$ | async) && (selectedAddons$ | async)">
              <ng-container *ngIf="!isLoading; else loading">
                <ng-container *ngIf="(purchase?.payments?.[0]?.amount ?? 0) > 0; else provisionWarranty">
                  <div *vsLet="selectedTerm$ | async as selectedTerm" class="flex flex-col gap-12">
                    <div class="flex flex-col gap-4 justify-center items-center text-center">
                      <vs-featured-icon name="bank" theme="primary" size="28"></vs-featured-icon>

                      <div class="text-3xl font-semibold">Checkout</div>
                      <div class="text-gray-600">
                        Please review and confirm details to finalize warranty provisioning.
                      </div>
                    </div>

                    <vs-card *vsLet="paymentSchedules$ | async as paymentSchedules">
                      <vs-card-header [config]="{ title: 'Payment' }">
                        <div class="flex items-center gap-2" *ngIf="staffView" slot="end">
                          <label class="whitespace-nowrap" for="staff-pays">Pay for Customer</label>
                          <input
                            vsInput
                            appearance="toggle"
                            type="checkbox"
                            id="staff-pays"
                            [checked]="payingForCustomer$ | async"
                            (change)="payingForCustomer$.next($any($event).target.checked)"
                          />
                        </div>
                        <div *ngIf="staffView" class="text-warn mt-2 max-w-lg">
                          <p>
                            <small>
                              {{
                                (payingForCustomer$ | async)
                                  ? "Your payment method will be used to buy this policy for the customer. To use the customer's payment methods for this purchase, uncheck the option above."
                                  : 'Payment methods associated with the customer will be used to buy this policy, you can add a new one on behalf of the customer. To use your own payment method for this purchase, toggle the option above.'
                              }}</small
                            >
                          </p>
                        </div>
                      </vs-card-header>

                      <vs-card-content class="flex flex-col gap-4">
                        <div
                          *ngIf="paymentSchedules && paymentSchedules.oneTime && paymentSchedules.installments?.length"
                          class="flex justify-center gap-2"
                        >
                          <!-- Pay now -->
                          <button
                            (click)="selectPaymentSchedule(paymentSchedules?.oneTime ?? null)"
                            vs-button
                            size="lg"
                            theme="default"
                            appearance="outline"
                            class="!justify-start !items-start !w-full !text-start !font-normal !p-2"
                            [rounded]="false"
                          >
                            <div class="flex gap-2 w-full -pt-[100px] items-center !self-center">
                              <vs-icon size="24" name="bank-note-01" class="!text-primary"></vs-icon>
                              <p class="w-full !font-semibold !text-sm">Pay now</p>
                            </div>

                            <vs-icon
                              size="16"
                              theme="primary"
                              class="text-primary-500 self-start hover:border hover:-mr-[1px] rounded-full border-themed-200"
                              [type]="(selectedPaymentSchedule?.installments || 1) === 1 ? 'solid' : 'line'"
                              [name]="(selectedPaymentSchedule?.installments || 1) === 1 ? 'check-circle' : 'circle'"
                            ></vs-icon>
                          </button>

                          <!-- Pay in installments -->
                          <button
                            vs-button
                            size="lg"
                            theme="default"
                            appearance="outline"
                            *ngFor="let paymentSchedule of paymentSchedules!.installments"
                            class="!justify-start !items-start !w-full !text-start !font-normal !p-2"
                            [rounded]="false"
                            (click)="selectPaymentSchedule(paymentSchedule)"
                          >
                            <div class="flex gap-2 w-full -pt-[100px] items-center !self-center">
                              <vs-icon size="24" name="clock-refresh" class="!text-primary"></vs-icon>

                              <p class="w-full !font-semibold !text-sm">Pay {{ paymentSchedule.title }}</p>
                            </div>

                            <vs-icon
                              size="16"
                              theme="primary"
                              class="text-primary-500 self-start hover:border hover:-mr-[1px] rounded-full border-themed-200"
                              [type]="
                                selectedPaymentSchedule?.installments === paymentSchedule.installments
                                  ? 'solid'
                                  : 'line'
                              "
                              [name]="
                                selectedPaymentSchedule?.installments === paymentSchedule.installments
                                  ? 'check-circle'
                                  : 'circle'
                              "
                            ></vs-icon>
                          </button>
                        </div>
                        <vs-select-or-create-payment-method
                          #input
                          [address]="session?.shippingAddress || null"
                          [userId]="
                            staffView
                              ? (payingForCustomer$ | async)
                                ? (currentUser$ | async)?.id || session!.customer!.userId
                                : session!.customer!.userId
                              : null
                          "
                          [userDetails]="{
                            name: session?.customer?.name ?? '',
                            email: session?.customer?.email ?? '',
                            phone: session?.customer?.phone ?? ''
                          }"
                          [formControl]="paymentMethod"
                          [staffView]="staffView ?? false"
                        ></vs-select-or-create-payment-method>

                        <div class="flex items-center gap-2">
                          <input
                            vsInput
                            #evaluate
                            type="checkbox"
                            class="cursor-pointer"
                            id="terms-and-conditions"
                            name="termsAndConditions"
                            [checked]="termsAndConditions"
                            (change)="evaluateTermsAndConditions(evaluate.checked)"
                          />

                          <label
                            vsCheckboxLabel
                            for="terms-and-conditions"
                            class="text-gray-700 text-xs cursor-pointer"
                          >
                            By checking this box, you agree to {{ staffView ? 'the' : 'our' }}

                            <a class="text-primary-500" (click)="previewPDFDialog.openDialog()">
                              Terms and Conditions
                            </a>
                            <ng-container *ngIf="staffView"> on behalf of {{ session?.customer?.name }} </ng-container>
                            .
                          </label>
                        </div>

                        <div class="flex flex-col gap-2 mt-4" *ngIf="input._state === 'select'">
                          <ng-container *vsLet="isProcessingProvisioning$ | async as isProcessingProvisioning">
                            <button
                              vs-button
                              theme="primary"
                              class="min-w-full"
                              (click)="provision(session?.id ?? '', session?.hash ?? '', staffView ?? false)"
                              [disabled]="
                                isProcessingProvisioning || paymentMethod.invalid || termsAndConditions === false
                              "
                            >
                              Confirm payment
                            </button>

                            <button
                              vs-button
                              appearance="outline"
                              class="min-w-full"
                              (click)="goBack(session, staffView ?? false)"
                            >
                              Back
                            </button>
                          </ng-container>
                        </div>
                      </vs-card-content>
                    </vs-card>
                  </div>
                </ng-container>

                <ng-template #provisionWarranty>
                  <div class="flex flex-col">
                    <div class="flex flex-col gap-4 pb-6 justify-center items-center text-center">
                      <vs-featured-icon name="check-circle" theme="primary" size="28"></vs-featured-icon>
                      <div class="text-3xl font-semibold">Checkout</div>
                      <div class="text-gray-600">
                        Please review and confirm details to finalize warranty provisioning.
                      </div>
                    </div>

                    <div class="max-w-[460px] flex flex-col gap-6">
                      <vs-card>
                        <vs-card-content class="flex flex-col p-6 gap-4">
                          <div class="text-sm">
                            With these steps concluded, you now possess all the essential details required for the
                            provisioning of this warranty for the customer.
                          </div>

                          <vs-divider></vs-divider>

                          <div class="flex items-center gap-2">
                            <input
                              vsInput
                              #evaluate
                              type="checkbox"
                              class="cursor-pointer"
                              id="terms-and-conditions"
                              name="termsAndConditions"
                              [checked]="termsAndConditions"
                              (change)="evaluateTermsAndConditions(evaluate.checked)"
                            />

                            <label
                              vsCheckboxLabel
                              for="terms-and-conditions"
                              class="text-gray-700 text-xs cursor-pointer"
                            >
                              By checking this box, you agree to {{ staffView ? 'the' : 'our' }}

                              <a class="text-primary-500" (click)="previewPDFDialog.openDialog()">
                                Terms and Conditions
                              </a>
                              <ng-container *ngIf="staffView">
                                on behalf of {{ session?.customer?.name }}
                              </ng-container>
                              .
                            </label>
                          </div>
                        </vs-card-content>
                      </vs-card>

                      <div *ngIf="(purchase?.payments?.[0]?.amount ?? 0) <= 0" class="flex gap-2">
                        <ng-container *vsLet="isProcessingProvisioning$ | async as isProcessingProvisioning">
                          <button
                            *ngIf="staffView"
                            vs-button
                            theme="primary"
                            class="w-full"
                            (click)="provision(session?.id ?? '', session?.hash ?? '', staffView)"
                            [disabled]="termsAndConditions === false || isProcessingProvisioning"
                          >
                            Confirm
                          </button>
                        </ng-container>
                        <!-- TODO: contact support? -->
                        <a *ngIf="!staffView" vs-button theme="primary" class="w-full" href="mailto:{{ contactEmail }}">
                          Contact us to provision this warranty
                        </a>
                        <button
                          vs-button
                          appearance="outline"
                          class="w-full"
                          (click)="goBack(session, staffView ?? false)"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="flex flex-col items-center justify-center w-full min-h-[350px]">
        <vs-icon name="loading-01" class="text-primary-500 animate-spin" size="50"></vs-icon>
      </div>
    </ng-template>

    <ws-preview-pdf-dialog
      #previewPDFDialog
      class="h-full"
      [document]="termsDoc"
      (closed)="previewPDFDialog.closeDialog()"
    ></ws-preview-pdf-dialog>
  </ng-template>
</ng-container>
