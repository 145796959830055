import { Component, Inject, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { API_MODE, TenantService } from '@vsolv/core/multi-tenant/web';
import { API_BASE_URL } from '@vsolv/dev-kit/ngx';
import { Payment } from '@vsolv/packages/payments/domain';
import { Customer } from '@wsphere/customers/domain';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService } from '@wsphere/staff/web';
import { Observable, combineLatest, debounceTime, map, startWith } from 'rxjs';

@Component({
  templateUrl: './payments.page.html',
})
export class StaffPaymentsPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private tenantSvc: TenantService,
    private formBuilder: FormBuilder,
    private ngFireAuth: AngularFireAuth,
    private securitySvc: SecurityService,
    @Inject(API_MODE) private apiMode: string
  ) {}

  private apiBaseUrl = inject(API_BASE_URL);

  totalPayments = 0;

  protected form = this.formBuilder.group({
    selectedCustomer: [null as Customer.Model | null],
    selectedDistributor: [null as Distributor.DistributorWithPermissionKeyQueryResponse | null],
    status: [[] as Payment.Status[]],
    warrantyId: ['' as string],
  });

  protected customerId$: Observable<string> = this.form.valueChanges.pipe(
    startWith({ ...this.form.value, selectedCustomer: { userId: '' } }),
    debounceTime(500),
    map(form => form.selectedCustomer?.userId ?? '')
  );

  protected status$: Observable<Payment.Status[] | null> = this.form.controls.status.valueChanges.pipe(
    startWith(null),
    map(status => status ?? null)
  );

  protected distributorId$: Observable<string> = this.form.valueChanges.pipe(
    startWith({ ...this.form.value }),
    debounceTime(500),
    map(form => form.selectedDistributor?.id ?? '')
  );

  protected warrantyId$: Observable<string> = this.form.controls.warrantyId.valueChanges.pipe(
    startWith(''),
    map(warrantyId => warrantyId ?? '')
  );

  protected filters$ = combineLatest([
    this.customerId$,
    this.status$,
    this.distributorId$,
    this.warrantyId$,
    this.securitySvc.globalDistributors$,
  ]).pipe(
    map(([userId, status, distributorId, warrantyId]) => {
      return {
        ...(userId ? { userId } : {}),
        ...(status ? { status } : {}),
        ...(distributorId ? { distributorId } : {}),
        ...(warrantyId ? { referenceId: warrantyId } : {}),
      };
    })
  );

  protected exportUrl$ = combineLatest([
    this.ngFireAuth.idToken,
    this.filters$,
    this.securitySvc.globalDistributors$,
  ]).pipe(
    map(([idToken, filters, globalDistributors]) => {
      const permissionKeys =
        ((globalDistributors ?? []) as Distributor.Model[]).map(item => item.permissionKey).join(',') || 'null';

      const params = [
        ...(filters.userId ? [`userId=${filters.userId}`] : []),
        ...(filters.status ? [`status=${filters.status?.join(',')}`] : []),
        ...(filters.referenceId ? [`referenceId=${filters.referenceId}`] : []),
        ...(filters.distributorId ? [`distributorId=${filters.distributorId}`] : []),
        `tenantId=${this.tenantSvc.tenantId}`,
        `permissionKeys=${permissionKeys}`,
        `mode=${this.apiMode}`,
        `idToken=${idToken}`,
      ].join('&');

      return `${this.apiBaseUrl}/v1/admin-dashboard/payments/csv?${params}`;
    })
  );

  async rowClicked(payment: Payment.Model) {
    await this.router.navigate([payment.id], { relativeTo: this.route });
  }

  setTotalPayments(items?: number) {
    if (items && !this.totalPayments) this.totalPayments = items;
  }
}
