<ng-container *vsLet="children$ | async as children">
  <button *ngIf="property.type !== 'OBJECT'; else object" vs-menu-item (click)="setValue({property, propertyPath})">
    <p>
      {{ property.name }} <small class="italics">({{ property.valueKey }})</small>
    </p>
    <vs-icon *ngIf="value?.propertyPath === propertyPath" name="check" theme="primary" slot="end"></vs-icon>
  </button>
  <ng-template #object>
    <button
      vs-menu-item
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      appearance="outline"
      class="w-full !py-3 !px-2 !h-auto"
      theme="default"
      (click)="toggle()"
      #input
    >
      <p>
        {{ property.name }} <small class="italics">({{ property.valueKey }})</small>
      </p>
      <vs-icon name="chevron-right" slot="end"></vs-icon>
    </button>
    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="overlayOpen"
      [cdkConnectedOverlayPositions]="[
        { originX: 'end', originY: 'bottom', overlayX: 'start', overlayY: 'bottom' },
        { originX: 'center', originY: 'bottom', overlayX: 'end', overlayY: 'bottom' }
      ]"
      (overlayOutsideClick)="$event.stopImmediatePropagation(); overlayOpen = false"
    >
      <vs-menu *ngIf="children?.length" class="max-h-[300px]">
        <div *ngFor="let child of $any(children)">
          <ws-condition-property-picker-item
            [property]="child"
            [propertyPath]="propertyPath + '.' + child.valueKey"
            (selectionChanged)="setValue($event); close()"
            [value]="value"
          ></ws-condition-property-picker-item>
        </div>
      </vs-menu>
    </ng-template>
  </ng-template>
</ng-container>
