<div
  *vsLet="isMobile$ | async as isMobile"
  class="flex gap-4 h-full max-w-full transition-all"
  [class.ml-4]="!isMobile"
>
  <div *ngIf="!isMobile" class="flex flex-col items-center h-full">
    <vs-icon
      class="rounded-full bg-base border p-1 cursor-pointer"
      [name]="open ? 'chevron-right-double' : 'chevron-left-double'"
      (click)="open = !open"
    ></vs-icon>

    <div class="h-full border-l"></div>
  </div>
  <div *vsLet="activities$ | async as activities" class="flex flex-col flex-grow gap-4 w-[50%]">
    <ng-container *vsLet="isMyClaim$ | async as isMyClaim">
      <ng-container *vsLet="canAddNotes$ | async as canAddNotes">
        <ng-container *vsLet="canAddDocuments$ | async as canAddDocuments">
          <vs-tabs *ngIf="isMyClaim || canAddNotes || canAddDocuments" theme="primary">
            <vs-tab *ngIf="canAddNotes || !isCustomerPortal" [config]="{ label: 'Add Notes' }">
              <ws-claim-quick-add [addType]="note"></ws-claim-quick-add>
            </vs-tab>

            <vs-tab *ngIf="isMyClaim || canAddDocuments" [config]="{ label: 'Add Attachments' }">
              <ws-claim-quick-add
                [addType]="document"
                [isCustomerPortal]="isCustomerPortal"
                [isMyClaim]="isMyClaim"
              ></ws-claim-quick-add>
            </vs-tab>
          </vs-tabs>
        </ng-container>
      </ng-container>
    </ng-container>

    <div class="flex gap-4 justify-between">
      <p *ngIf="activities?.[0] as latest" class="text-xs font-normal text-gray-400 self-center">
        Last updated: {{ latest.created | date : 'dd/MM/yy hh:mm' }}
        <span class="text-primary-500 cursor-pointer" (click)="refresh()">Refresh</span>
      </p>

      <vs-select
        vsInput
        class="self-center"
        [config]="{ placeholder: 'All links' }"
        (valueChanges)="setLinkType($event)"
      >
        <li vs-select-option [value]="null">All links</li>
        <li *ngFor="let type of linkTypes" vs-select-option [value]="type">
          {{ type.replace('C', ' C').replace('I', ' I') | titlecase }}
        </li>
      </vs-select>
    </div>

    <div *ngIf="!loading; else isLoading" class="flex flex-col">
      <ng-container *ngFor="let activity of activities; index as index">
        <ws-claim-timeline-activity
          *ngIf="activity"
          class="w-full"
          [claim]="claim"
          [activity]="activity"
          [displayType]="linkType"
          [isCustomerPortal]="isCustomerPortal"
          [hideLine]="index === (activities?.length || 0) - 1"
        ></ws-claim-timeline-activity>
      </ng-container>
    </div>

    <ng-template #isLoading>
      <div class="flex items-center justify-center min-h-[275px]">
        <vs-icon name="loading-01" class="text-primary-500 animate-spin" size="35"></vs-icon>
      </div>
    </ng-template>
  </div>
</div>
