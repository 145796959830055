<ng-container *vsLet="customer$ | async as customer">
  <ng-container *vsLet="customerId$ | async as customerId">
    <ng-container *vsLet="destinations$ | async as destinations">
      <vs-dialog
        #dialog
        (closed)="navigateTo()"
        [config]="{ dialogContainerClass: 'max-w-[565px] w-full', startOpen: true }"
      >
        <vs-dialog-header
          [config]="{
            mode: 'simple',
            showDivider: true,
            
            icon: 'bank-note-01',
            iconTheme: 'primary',
            
            title: 'Manage reimbursement methods',
            subtitle: 'View existing reimbursement accounts or add a new method to your account.'
          }"
        ></vs-dialog-header>

        <ng-container *ngIf="loadingMethods$ | async ;else methods">
          <div class="flex justify-center items-center min-h-[350px]">
            <vs-icon name="loading-01" class="text-primary-500 animate-spin" size="35"></vs-icon>
          </div>
        </ng-container>

        <ng-template #methods>
          <div class="flex flex-col pt-8 px-2 pb-5 gap-5">
            <ng-container *ngIf="(destinations && destinations.length); else noDestinations">
              <div class="flex flex-col gap-4">
                <div class="font-semibold text-gray-900">Current reimbursement methods</div>
                <div class="grid gap-2" [class]="destinations.length === 1 ? 'grid-cols-1' : 'grid-cols-2'">
                  <ng-container *ngFor="let destination of destinations">
                    <div class="flex gap-4 p-2 border rounded-lg">
                      <vs-icon name="bank" size="24" class="text-gray-500 self-center"></vs-icon>
                      <div class="flex flex-col justify-center">
                        <div class="text-gray-700 font-semibold">{{destination.bankName}}</div>
                        <div class="text-gray-500 text-xs">{{destination.name}}</div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>

            <ng-template #noDestinations>
              <p class="text-sm text-gray-600">
                There is no existing reimbursement method yet. To receive funds, please add a new reimbursement method.
              </p>
            </ng-template>

            <div *ngIf="(destinations?.length ?? 0) < maxNumberOfDestinations" class="flex flex-col gap-2">
              <div class="font-semibold text-gray-900">Add new account</div>
              <button
                vs-button
                appearance="clear"
                theme="primary"
                class="bg-primary-50 !w-full"
                size="lg"
                (click)="addPaymentMethod.open()"
              >
                <vs-icon name="bank"></vs-icon>
                <span class="font-semibold text-primary-700">Bank account</span>
              </button>
            </div>

            <vs-add-new-payment-method
              [customer]="customer"
              [customerId]="customerId?.id ?? null"
              (destination)="refresh$.next(null)"
              #addPaymentMethod
            ></vs-add-new-payment-method>
          </div>
        </ng-template>
        <div class="flex justify-end w-full">
          <button vs-button appearance="outline" theme="default" (click)="dialog.close()">Cancel</button>
        </div>
      </vs-dialog>
    </ng-container>
  </ng-container>
</ng-container>
