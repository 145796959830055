<div id="page-content" class="flex flex-col gap-8">
  <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <vs-form-field
      class="pb-10"
      [config]="{
        label: { text: 'Company logo', for: 'company-image-input' },
        description: 'Upload your company logo to be displayed at the top of the customer portal.'
      }"
    >
      <div class="flex flex-col gap-1" slot="before-content">
        <img class="w-24 h-24" [vsImagePreview]="form.controls.logo.value ?? logoUrl" />
        <!-- TODO: temporarily hiding as per Felix -->
        <!-- <button
            *ngIf="logoUrl || companyLogoInput.files[0]"
            vsLink
            (click)="companyLogoInput.clear(); resetLogo(); $event.stopImmediatePropagation()"
            class="text-xs"
          >
            Remove
          </button> -->
      </div>
      <vs-file-input
        formControlName="logo"
        [accept]="'.SVG, .PNG, .JPG, .JPEG, .GIF'"
        [moreInfo]="'max. 800x400px'"
        (change)="onImageUpload($event, 'logo')"
      ></vs-file-input>
    </vs-form-field>

    <vs-divider class="flex my-8 sm:hidden"></vs-divider>

    <vs-form-field
      class="pb-10"
      [config]="{
        label: { text: 'Avatar', for: 'avatar-logo-input' },
        description: 'Upload an icon-only (no text) version of your logo, to be displayed as the favicon in browsers.'
      }"
    >
      <div class="flex flex-col gap-1" slot="before-content">
        <img class="w-24 h-24" [vsImagePreview]="form.controls.avatar.value ?? avatarUrl" />
        <!-- TODO: temporarily hiding as per Felix -->
        <!-- <button
            vsLink
            (click)="avatarInput.clear(); resetAvatar(); $event.stopImmediatePropagation()"
            *ngIf="avatarUrl || avatarInput.files[0]"
            class="text-xs"
            [disabled]="disableAdminAvatar || staff.role!=='ADMIN'"
          >
            Remove
          </button> -->
      </div>
      <vs-file-input
        formControlName="avatar"
        [accept]="'.SVG, .PNG, .JPG, .JPEG, .GIF'"
        [moreInfo]="'max. 800x400px'"
        (change)="onImageUpload($event, 'logo')"
      ></vs-file-input>
    </vs-form-field>

    <vs-divider class="flex my-8 sm:hidden"></vs-divider>

    <vs-form-field
      class="pb-10"
      [config]="{
        label: { text: 'Primary color', for: 'color-input' },
        description:
          'Use the picker to select the primary color for your portal. It will be used across the site as the default color for buttons, links, navigation, and more.'
      }"
    >
      <ng-container *ngIf="form.get('prColor')?.valid || form.get('prColor')?.disabled; else showError" slot="hint">
        <span class="text-gray-600">A full palette is generated from your chosen color.</span>
      </ng-container>
      <ng-template #showError>
        <span class="text-danger" slot="hint">This color is not recognized.</span>
      </ng-template>
      <div class="flex relative group w-fit h-fit" slot="before-content">
        <input #primaryColorPicker vsInput [value]="primaryColorInput.color" size="lg" type="color" />
        <div
          [vsCustomTheme]="primaryColorPicker.value"
          class="flex opacity-0 transition-opacity pointer-events-none absolute inset-0 border border-themed-300 group-hover:opacity-100 group-focus-within:opacity-100 rounded-md overflow-hidden"
        >
          <div class="flex-1 h-full flex flex-col">
            <div class="flex-1 h-full bg-themed-50"></div>
            <div class="flex-1 h-full bg-themed-200"></div>
            <div class="flex-1 h-full bg-themed-400"></div>
            <div class="flex-1 h-full bg-themed-600"></div>
            <div class="flex-1 h-full bg-themed-800"></div>
          </div>
          <div class="flex-1 h-full flex flex-col">
            <div class="flex-1 h-full bg-themed-100"></div>
            <div class="flex-1 h-full bg-themed-300"></div>
            <div class="flex-1 h-full bg-themed-500"></div>
            <div class="flex-1 h-full bg-themed-700"></div>
            <div class="flex-1 h-full bg-themed-900"></div>
          </div>
        </div>
        <vs-icon
          [vsCustomTheme]="primaryColorPicker.value"
          name="palette"
          class="text-themed-100 absolute bottom-1 right-1"
        ></vs-icon>
      </div>
      <vs-color-input #primaryColorInput formControlName="prColor" [colorPicker]="primaryColorPicker"></vs-color-input>
    </vs-form-field>

    <vs-divider class="flex my-8 sm:hidden"></vs-divider>

    <vs-form-field
      [config]="{
        label: { text: 'Accent color', for: 'color-input' },
        description:
          'Use the picker to select the accent color for your portal. It will be used across the site as the secondary color for buttons, links, navigation, and more.'
      }"
    >
      <ng-container *ngIf="form.get('acColor')?.valid || form.get('acColor')?.disabled; else showError" slot="hint">
        <span class="text-gray-600">A full palette is generated from your chosen color.</span>
      </ng-container>
      <ng-template #showError>
        <span class="text-danger" slot="hint">This color is not recognized.</span>
      </ng-template>
      <div class="flex relative group w-fit h-fit" slot="before-content">
        <input #accentColorPicker vsInput [value]="accentColorInput.color" size="lg" type="color" />
        <div
          [vsCustomTheme]="accentColorPicker.value"
          class="flex opacity-0 transition-opacity pointer-events-none absolute inset-0 border border-themed-300 group-hover:opacity-100 group-focus-within:opacity-100 rounded-md overflow-hidden"
        >
          <div class="flex-1 h-full flex flex-col">
            <div class="flex-1 h-full bg-themed-50"></div>
            <div class="flex-1 h-full bg-themed-200"></div>
            <div class="flex-1 h-full bg-themed-400"></div>
            <div class="flex-1 h-full bg-themed-600"></div>
            <div class="flex-1 h-full bg-themed-800"></div>
          </div>
          <div class="flex-1 h-full flex flex-col">
            <div class="flex-1 h-full bg-themed-100"></div>
            <div class="flex-1 h-full bg-themed-300"></div>
            <div class="flex-1 h-full bg-themed-500"></div>
            <div class="flex-1 h-full bg-themed-700"></div>
            <div class="flex-1 h-full bg-themed-900"></div>
          </div>
        </div>
        <vs-icon
          [vsCustomTheme]="accentColorPicker.value"
          name="palette"
          class="text-themed-100 absolute bottom-1 right-1"
        ></vs-icon>
      </div>
      <vs-color-input #accentColorInput formControlName="acColor" [colorPicker]="accentColorPicker"></vs-color-input>
    </vs-form-field>

    <div
      class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pb-4"
      *ngIf="form.dirty && canEditOrganization"
    >
      <button
        vs-button
        class="sm:flex-none w-full sm:w-fit rounded-lg"
        size="sm"
        appearance="outline"
        [disabled]="!form.dirty"
        (click)="resetForm()"
      >
        Cancel
      </button>
      <button
        vs-button
        class="sm:flex-none w-full sm:w-fit rounded-lg"
        size="sm"
        theme="primary"
        [disabled]="!inputIsValid() || !form.dirty"
        (click)="confirm()"
      >
        Save
      </button>
    </div>
  </form>
</div>
