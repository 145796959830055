import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeColor } from '@vsolv/vectors-ui/theming';

export interface ProgressBarItemsConfig {
  items: ProgressBarItemConfig[];
}
export interface ProgressBarPercentageConfig {
  /** Number between 0 and 1 */
  percentage: number;
}
export type ProgressBarConfig = ProgressBarItemsConfig | ProgressBarPercentageConfig;

export interface ProgressBarItemConfig {
  weight: number;
  color?: string;
  theme?: ThemeColor;
}

@Component({
  selector: 'vs-progress-bar',
  templateUrl: './progress-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent {
  @Input() config?: ProgressBarConfig;

  get items() {
    return this.config && 'items' in this.config
      ? this.config?.items?.filter(item => item.weight > 0 && (item.color || item.theme))
      : [];
  }

  get percentage() {
    return this.config && 'percentage' in this.config ? this.config?.percentage : 0;
  }

  get totalWeight() {
    return this.config && 'items' in this.config
      ? this.config?.items?.reduce((acc, item) => (acc += item.weight ?? 0), 0)
      : 0;
  }
}
