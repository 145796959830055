<ng-container *vsLet="canManageEmailIngestion$ | async as canManageEmailIngestion">
  <div *vsLet="email$ | async as email" class="flex flex-col gap-6">
    <p>
      {{email ? "S" : "Once config.enabled, s"}}end emails with documents attached to the provided address. These
      documents will automatically be uploaded to the Administrator Dashboard under the Documents tab.
    </p>
    <p>
      We advise you to only share this email with people you trust to upload documents to the dashboard. If the address
      becomes compromised, you can re-roll it.
    </p>
    <ng-container *ngIf="config.enabled && email">
      <div class="flex flex-row gap-2">
        <span class="mr-2 blur-sm hover:blur-none">{{email}}</span>
        <button *ngIf="canManageEmailIngestion" vsTooltip="Copy email address" [cdkCopyToClipboard]="email">
          <vs-icon name="copy-01"></vs-icon>
        </button>
        <button *ngIf="canManageEmailIngestion" vsTooltip="Re-roll email address" (click)="rerollDialog.open()">
          <vs-icon name="refresh-cw-01"></vs-icon>
        </button>
      </div>
      <form [formGroup]="form" class="flex flex-col gap-4">
        <label class="flex flex-row">
          <input vsInput type="checkbox" formControlName="bodyDocument" class="mr-2" />
          <div class="flex flex-col">
            <span class="text-gray-700 font-medium text-sm">Enable email body document creation</span>
            <span class="text-gray-600 text-sm"
              >Save the body of any emails sent to the email address as an additional document</span
            >
          </div>
        </label>
        <label class="flex flex-row">
          <input vsInput type="checkbox" formControlName="autoRun" class="mr-2" />
          <div class="flex flex-col">
            <span class="text-gray-700 font-medium text-sm"
              >Automatically run document processes on ingested documents</span
            >
            <span class="text-gray-600 text-sm"
              >Select which processes to run on every document uploaded to the ingestion email address.</span
            >
          </div>
        </label>

        <ng-container *ngIf="allProcessors$ | async as processors">
          <vs-select
            *ngIf="form.value.autoRun"
            vsInput
            class="ml-6"
            formControlName="processors"
            [config]="{ multiple: true }"
          >
            <li *ngFor="let processor of processors" vs-select-option [value]="processor.id">{{ processor.name }}</li>
          </vs-select>
        </ng-container>
      </form>
    </ng-container>
    <div class="flex flex-row self-end gap-4" *ngIf="canManageEmailIngestion">
      <button
        vs-button
        [appearance]="config.enabled ? 'outline' : 'default'"
        [disabled]="updating"
        theme="primary"
        (click)="config.enabled ? deleteDialog.open() : createEmail()"
      >
        {{config.enabled ? 'Disable' : 'Enable'}}
      </button>
      <button
        *ngIf="config.enabled"
        vs-button
        appearance="default"
        theme="primary"
        [disabled]="disableUpdate() || updating"
        (click)="updateConfig()"
      >
        Update
      </button>
    </div>
  </div>
</ng-container>
<vs-dialog #deleteDialog [config]="{dialogContainerClass: 'w-full md:w-96'}">
  <vs-dialog-header
    [config]="{icon: 'x-circle', iconTheme:'primary', title: 'Disable email document ingestion app?', subtitle: 'Are you sure you want to disable this app? Administrators and other parties with this email address will no longer be able to upload documents via email.', mode: 'simple', hideCloseButton: true}"
  ></vs-dialog-header>
  <div class="w-full flex flex-row gap-2 justify-between">
    <button vs-button class="w-1/2" [disabled]="updating" appearance="outline" (click)="deleteDialog.close()">
      Cancel
    </button>
    <button vs-button class="w-1/2" [disabled]="updating" theme="danger" (click)="deleteEmail()">
      Disable Application
    </button>
  </div>
</vs-dialog>
<vs-dialog #rerollDialog [config]="{dialogContainerClass: 'w-full md:w-96'}">
  <vs-dialog-header
    [config]="{icon: 'refresh-cw-01', iconTheme:'primary', title: 'Re-roll address?', subtitle: 'Are you sure you want to re-roll the email address for email document ingestion? Emails sent to the current address will no longer be delivered.', mode: 'simple', hideCloseButton: true}"
  ></vs-dialog-header>
  <div class="w-full flex flex-row gap-2 justify-between">
    <button vs-button class="w-1/2" [disabled]="updating" appearance="outline" (click)="rerollDialog.close()">
      Cancel
    </button>
    <button vs-button class="w-1/2" [disabled]="updating" theme="danger" (click)="rerollEmail()">
      Re-roll address
    </button>
  </div>
</vs-dialog>
