import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isSelected',
})
export class IsSelectedPipe implements PipeTransform {
  transform(value: string, array: string[] | null): boolean {
    if (!array || !array.length) return false;
    return array.includes(value);
  }
}
