import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserService } from '@vsolv/core/users/web';
import { AuthorizeNetConfig, Payment } from '@vsolv/packages/payments/domain';
import { firstValueFrom } from 'rxjs';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Accept: any;

export interface PaymentMethodServiceConfig {
  authorizenet: AuthorizeNetConfig.Model;
}

@Injectable()
export class PaymentMethodService {
  constructor(private userSvc: UserService, private http: HttpClient) {}

  list(dto: Payment.ListPaymentMethodsRequest) {
    return firstValueFrom(
      this.http.get<Payment.ListPaymentMethodsResponse>(`/api/payment-methods${dto.forCheckout ? '/checkout' : ''}`, {
        params: {
          ...(dto.page ? { page: dto.page } : {}),
          ...(dto.limit ? { limit: dto.limit } : {}),
          ...(dto.userId ? { userId: dto.userId } : {}),
        },
      })
    );
  }

  async retrieveConfig() {
    const value = await firstValueFrom(
      this.http.get<PaymentMethodServiceConfig>(`/api/payment-methods/retrieve-service-config`)
    );
    return value;
  }

  async addCreditCard({
    cardData,
    ...dto
  }: Omit<Payment.AddCreditCardRequest, 'creditCardNonce'> & {
    cardData: { cardNumber: string; month: string; year: string; cardCode: string; zip?: string; fullName?: string };
  }) {
    const creditCardNonce = await this.exchangeCreditCardNonce(cardData);
    return await firstValueFrom(
      this.http.post<Payment.AddCreditCardResponse>(`/api/payment-methods`, {
        ...dto,
        creditCardNonce,
      })
    );
  }

  private async exchangeCreditCardNonce(cardData: {
    cardNumber: string;
    month: string;
    year: string;
    cardCode: string;
    zip?: string;
    fullName?: string;
  }) {
    const config = await this.retrieveConfig();

    return new Promise<string>((res, rej) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Accept.dispatchData({ authData: config, cardData }, (response: any) => {
        if (response.messages.resultCode === 'Error') {
          rej(response);
        }
        res(response.opaqueData.dataValue);
      });
    });
  }
}
