import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { map } from 'rxjs';
import { DistributorService } from '../../services';

@Component({
  selector: 'ws-reactivate-distributor-dialog',
  templateUrl: './reactivate-distributor.dialog.html',
})
export class ReactivateDistributorDialog {
  constructor(
    private toastSvc: ToastService,
    private distributorSvc: DistributorService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() dialogConfig?: DialogConfig;
  @Input() distributor?: Distributor.Model | null;

  @Output() closed = new EventEmitter<string>();

  saving = false;

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.dialog.close();
  }

  cancel() {
    this.closeDialog();
  }

  async reactivateDistributor() {
    this.saving = true;

    if (!this.distributor) throw new Error('Unable to reactivate distributor, distributor not found');

    const reactivatedDistributor = await this.distributorSvc
      .reactivateDistributor(this.distributor.id)
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.closeDialog();
        this.saving = false;
      });

    if (reactivatedDistributor?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Distributor Reactivated',
        text: '<strong>' + this.distributor?.name + '</strong> has been successfully reactivated.',
      });

      this.closeDialog();
      this.closed.emit(reactivatedDistributor.id);
      this.saving = false;
    }
  }
}
