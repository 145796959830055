import { Component, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PaymentTaxWebService } from '@vsolv/packages/payments/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'ws-tax-jar-dialog',
  templateUrl: './tax-jar.dialog.html',
})
export class TaxJarDialog {
  private paymentTaxSvc = inject(PaymentTaxWebService);
  private securitySvc = inject(SecurityService);
  private toastSvc = inject(ToastService);

  protected secretKeyControl = new FormControl(null as string | null);

  private refresh$ = new BehaviorSubject<null>(null);

  isEnabled = false;

  protected key$ = this.refresh$.pipe(
    switchMap(() => this.paymentTaxSvc.retrieve()),
    map(response => {
      this.secretKeyControl.setValue(response.key);
      return response.key;
    }),
    tap(key => (this.isEnabled = !!key))
  );

  protected readonly canManageTaxJarCustomKey$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('stf_TaxJarCustomKeyApp', permissionKeys);
    }),
    tap(canManage => (!canManage ? this.secretKeyControl.disable() : this.secretKeyControl.enable()))
  );

  protected async enable(secretKey: string | null) {
    if (!secretKey) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error',
        text: 'Unable to enable the TaxJar integration.',
      });
      return;
    }
    try {
      const response = await this.paymentTaxSvc.create({ secretKey });
      this.refresh$.next(null);
      if (response.key) {
        this.toastSvc.show({
          type: 'success',
          title: 'TaxJar integration app enabled',
          text: 'This application has been enabled. All of its features will be accessible unless app is disabled.',
        });
      }
    } catch (error) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error',
        text: 'Unable to enable the TaxJar integration.',
      });
    }
  }

  protected async disable() {
    try {
      const response = await this.paymentTaxSvc.delete();
      this.refresh$.next(null);
      if (response.tenantId) {
        this.toastSvc.show({
          type: 'success',
          title: 'TaxJar integration app disabled',
          text: 'This application has been disabled. None of its features will be accessible until enabled again.',
        });
      }
    } catch (error) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error',
        text: 'Unable to disable the TaxJar integration.',
      });
    }
  }

  protected async update(secretKey: string | null) {
    if (!secretKey) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error',
        text: 'Unable to update the TaxJar integration.',
      });
      return;
    }
    try {
      const response = await this.paymentTaxSvc.update({ secretKey });
      this.refresh$.next(null);
      if (response.key) {
        this.toastSvc.show({
          type: 'success',
          title: 'TaxJar integration app updated',
          text: 'This application has been updated. All of its features will be accessible unless app is disabled.',
        });
      }
    } catch (error) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error',
        text: 'Unable to update the TaxJar integration.',
      });
    }
  }
}
