<form *ngIf="set" class="flex flex-col gap-1" [formGroup]="form">
  <ng-container *ngFor="let assignment of set.properties">
    <vs-property-input-form-field
      *ngIf="showHidden || !assignment.hidden || assignment.required"
      [property]="assignment.property!"
      [hidden]="assignment.hidden"
      [showHidden]="showHidden"
      [order]="assignment.order"
      [formControlName]="assignment.property!.valueKey"
      [required]="assignment.required"
      [extraData]="extraData"
    >
    </vs-property-input-form-field>
  </ng-container>
</form>
