/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isVectorSolvRequest } from '@vsolv/dev-kit/ngx';
import { Staff } from '@wsphere/staff/domain';
import { Observable } from 'rxjs';
import { GLOBAL_DISTRIBUTORS_STORAGE_KEY } from '../services';

/** This interceptor attempts to add the global distributor ids to outgoing vsolv request headers */
@Injectable()
export class GlobalDistributorsInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isVectorSolvRequest(req)) {
      return next.handle(this.handleRequest(req));
    }
    return next.handle(req);
  }

  private handleRequest(request: HttpRequest<any>) {
    const stored = sessionStorage.getItem(GLOBAL_DISTRIBUTORS_STORAGE_KEY);
    const distributors = stored ? JSON.parse(stored) : null;
    if (distributors) return request.clone({ setHeaders: { [Staff.Security.PERMISSION_KEYS_HEADER]: distributors } });
    else return request;
  }
}
