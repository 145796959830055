/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Asset } from '@wsphere/assets/domain';
import { Provisioning } from '@wsphere/warranties/domain';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { AssetService } from '../../services';
@Component({
  selector: 'ws-asset-picker',
  templateUrl: './asset-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AssetPickerComponent,
    },
  ],
})
export class AssetPickerComponent implements ControlValueAccessor {
  constructor(private assetSvc: AssetService) {}

  @PropertyListener('session') session$ = new BehaviorSubject<Provisioning.ProvisioningSession | null>(null);
  @Input() session: Provisioning.ProvisioningSession | null = null;

  @Input() staffView = false;

  @Output() valueChanges = new EventEmitter();

  @PropertyListener('selected') selected$ = new BehaviorSubject<Asset.Model | null>(null);
  selected?: Asset.Model;

  touched = false;
  disabled = false;

  totalAssets = 0;

  assets$ = this.session$.pipe(
    switchMap(async session => {
      return session?.customerId
        ? (await this.assetSvc.getCustomerAssets(session.customerId, session?.policy?.propertySetId)).filter(
            asset => asset.submissions?.length
          )
        : [];
    }),
    tap(assets => {
      this.totalAssets = assets.length;
    })
  );

  onChange = (_selected: Asset.Model) => {};

  onTouched = () => {};

  selectAsset(asset: Asset.Model) {
    this.onTouched(); // <-- mark as touched
    this.selected = asset;
    this.onChange(this.selected); // <-- call function to let know of a change
    this.valueChanges.emit(asset);
  }
  writeValue(asset: Asset.Model): void {
    this.selected = asset;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn; // <-- save the function
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn; // <-- save the function
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
