import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { PaymentService } from '@vsolv/packages/payments/web';
import { PaymentResolver } from '../resolvers';

@Injectable()
export class PaymentGuard implements CanActivate {
  constructor(private paymentSvc: PaymentService, private resolver: PaymentResolver, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.payment = null;

    const paymentId = route.paramMap.get('paymentId');
    if (!paymentId) return false;

    const payment = await this.paymentSvc.retrieve(paymentId);
    this.resolver.payment = payment;

    if (this.resolver.payment === null) return this.router.createUrlTree(['/404']);
    if (!payment) return false;

    return true;
  }
}
