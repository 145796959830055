import { Staff } from '../lib/staff';

export type NotePermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'note_ViewNotes',
    display: {
      title: 'View Platform Notes',
      description: 'View notes across the platform',
    },
  },
  {
    id: 'note_UpdateNotes',
    display: {
      title: 'Edit Platform Notes',
      description: 'Edit notes from the platform',
    },
  },
  {
    id: 'note_RemoveNotes',
    display: {
      title: 'Delete Platform Notes',
      description: 'Delete notes from the platform',
    },
  },
  {
    id: 'note_CreateNotes',
    display: {
      title: 'Create Platform Notes',
      description: 'Create notes on the platform',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
