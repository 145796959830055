import { Staff } from '../lib/staff';

export type PropertyPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'prp_CreateSet',
    display: {
      title: 'Create Property Sets',
      description: 'View and create a property set with details and properties',
    },
  },
  {
    id: 'prp_ManageSet',
    display: {
      title: 'Manage Property Sets',
      description: 'View, remove properties, and edit property details of a property set',
    },
  },
  {
    id: 'prp_CreateProperty',
    display: {
      title: 'Create Properties',
      description: 'View and create properties',
    },
  },
  {
    id: 'prp_ManageProperty',
    display: {
      title: 'Edit Property Details',
      description: 'View and edit property details',
    },
  },
  {
    id: 'prp_DeleteProperty',
    display: {
      title: 'Delete Properties',
      description: 'Delete propetiers',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
