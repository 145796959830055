import { Component, OnInit, ViewChild } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { SecurityService } from '@wsphere/staff/web';
import { switchMap } from 'rxjs';
import { ClaimsTableComponent } from '../../components';
import { OpenClaimDialog } from '../../dialogs';

@Component({
  selector: 'ws-claims-page',
  templateUrl: './claims.page.html',
})
export class ClaimsPage implements OnInit {
  constructor(private securitySvc: SecurityService, private configSvc: PortalService) {}
  @ViewChild('openClaimDialog') openClaimDialog?: OpenClaimDialog;
  @ViewChild('table') table?: ClaimsTableComponent;

  tenantName: string | null = null;

  canOpenClaim$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(async distributors => {
      const permissionKey = distributors?.map(dist => dist.permissionKey) || null;
      return await this.securitySvc.hasAccess('clm_OpenClaim', permissionKey);
    })
  );

  openClaim() {
    this.openClaimDialog?.openDialog();
  }

  async ngOnInit() {
    const config = this.configSvc.config;
    this.tenantName = config.brandName;
  }
}
