<form [formGroup]="form">
  <div class="block justify-center overscroll-contain w-full">
    <!-- VIN -->
    <vs-form-field class="col-span-2 w-full" [config]="{ label: 'Vehicle VIN:' }">
      <vs-input-group class="w-full">
        <input
          vsInput
          type="text"
          class="w-full rounded-b-none"
          formControlName="roadVehicleVIN"
          placeholder="Enter vehicle VIN"
        />
        <button
          vs-button
          class="flex min-w-fit rounded-b-none"
          vsTooltip="Autofill Vehicle information from VIN"
          theme="primary"
          [disabled]="!form.controls.roadVehicleVIN.value"
          (click)="useVIN(form.controls.roadVehicleVIN.value || '')"
        >
          <vs-icon name="file-06"> </vs-icon>
        </button>
      </vs-input-group>
    </vs-form-field>
    <div class="border justify-center rounded-b-md w-full block grid-cols-2 gap-3 overscroll-contain -mt-px">
      <div class="flex flex-row gap-2 items-center">
        <button class="self-center" vs-icon-button appearance="clear" size="md" (click)="toggle()">
          <vs-icon [name]="expanded ? 'chevron-up' : 'chevron-down'"></vs-icon>
        </button>
        <label class="font-semibold text-sm">Vehicle Information:</label>
        <label class="text-sm text-gray-700 font-semibold gap-2 text-center">
          {{ this.form.controls.roadVehicleMake.value }}
        </label>
        <label class="text-sm text-gray-700 font-semibold gap-2 text-center">
          {{ this.form.controls.roadVehicleModel.value }}
        </label>
        <label class="text-sm text-gray-700 font-semibold gap-2 text-center">
          {{ this.form.controls.roadVehicleYear.value }}
        </label>
      </div>

      <div *ngIf="expanded" class="px-2 py-3 w-full">
        <vs-form-field
          class="col-span-2 pt-2"
          [theme]="
            form.controls.roadVehicleMake.touched && form.controls.roadVehicleMake.hasError('required')
              ? 'danger'
              : 'primary'
          "
          [config]="{ label: 'Vehicle Make:' }"
        >
          <input
            #roadVehicleMake
            #trigger="cdkOverlayOrigin"
            cdkOverlayOrigin
            vsInput
            type="text"
            formControlName="roadVehicleMake"
            placeholder="Enter vehicle make"
            (keyup)="autofillSearch$.next(roadVehicleMake.value); roadVehicleMake.value ? open() : close()"
          />
          <span
            class="flex-col justify-center text-danger-500 font-medium text-sm pl-2"
            slot="warning"
            *ngIf="form.controls.roadVehicleMake.touched && form.controls.roadVehicleMake.hasError('required')"
          >
            Vehicle make is required!
          </span>
        </vs-form-field>

        <vs-form-field class="col-span-2 pt-2" [config]="{ label: 'Vehicle Model:' }">
          <input vsInput type="text" formControlName="roadVehicleModel" placeholder="Enter vehicle model name" />
          <span
            class="flex-col justify-center text-danger-500 font-medium text-sm pl-2"
            slot="warning"
            *ngIf="form.controls.roadVehicleModel.touched && form.controls.roadVehicleModel.hasError('required')"
          >
            Model Name is required!
          </span>
        </vs-form-field>

        <vs-form-field class="col-span-2 pt-2" [config]="{ label: 'Vehicle Year:' }">
          <input vsInput type="number" formControlName="roadVehicleYear" placeholder="Enter vehicle's model year" />
          <span
            class="flex-col justify-center text-danger-500 font-medium text-sm pl-2"
            slot="warning"
            *ngIf="form.controls.roadVehicleYear.touched && form.controls.roadVehicleYear.hasError('required')"
          >
            Year is required!
          </span>
        </vs-form-field>

        <div class="flex flex-col gap-1 mt-4" *ngIf="vehicle$ | async as vehicle">
          <h3
            class="text-sm text-gray-700 font-medium cursor-pointer"
            [class.mb-3]="detailsExpanded"
            (click)="detailsExpanded = !detailsExpanded"
          >
            All Vehicle Details:
            <vs-badge class="ml-1" *ngIf="(vehicle | values | truthify)?.length as propCount">
              {{ detailsExpanded ? '' : 'Show ' }} {{ propCount }} {{ propCount !== 1 ? 'Properties' : 'Property' }}
              <vs-icon
                class="ml-1"
                [name]="detailsExpanded ? 'x-close' : 'info-circle'"
                [type]="detailsExpanded ? 'line' : 'solid'"
              ></vs-icon>
            </vs-badge>
          </h3>
          <ng-container *ngIf="detailsExpanded">
            <ng-container *ngFor="let entry of vehicle | keyvalue">
              <p *ngIf="entry.value" class="text-xs ml-2">
                <strong>{{ entry.key }}:</strong>{{ entry.value }}
              </p>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
    <span
      class="flex-col justify-center text-danger-500 font-medium text-sm ml-1 mt-1"
      slot="warning"
      *ngIf="form.controls.roadVehicleVIN.touched && form.controls.roadVehicleVIN.hasError('required')"
    >
      VIN is required!
    </span>
  </div>
</form>
