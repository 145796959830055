/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginationQueryRequest } from '@vsolv/dev-kit/ngx';
import { PropertyListener } from '@vsolv/dev-kit/rx';

import { Payment } from '@vsolv/packages/payments/domain';
import { PaymentService } from '@vsolv/packages/payments/web';
import { ThemeColorEnum } from '@vsolv/vectors-ui/theming';
import { BadgeColumn, TableColumn, TextColumn, TextSubtitleColumn, UserInfoColumn } from '@vsolv/vectors/table';
import moment from 'moment';
import { UcFirstPipe } from 'ngx-pipes';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
export type PaymentsTableFilters = Omit<Payment.ListRequest, keyof PaginationQueryRequest>;
export type PaymentItem = Payment.ListResponse['items'][number];

@Component({
  selector: 'ws-payments-table',
  templateUrl: './payments-table.component.html',
})
export class PaymentsTableComponent {
  constructor(private paymentSvc: PaymentService) {}

  @PropertyListener('filters') private filters$ = new BehaviorSubject({});
  @Input() filters: PaymentsTableFilters | null = {};

  @PropertyListener('hideCustomer') private hideCustomer$ = new BehaviorSubject<boolean>(false);
  @Input() hideCustomer = false;

  @Output() rowClicked = new EventEmitter<PaymentItem>();

  private ucFirst = new UcFirstPipe();
  salesCount = 0;

  columns$: Observable<TableColumn<unknown>[]> = this.hideCustomer$.pipe(
    map(hideCustomer => {
      return [
        new TextSubtitleColumn<PaymentItem>({ header: 'Payment' }, payment => ({
          title: payment.title,
          titleClasses: 'truncate max-w-[15vw]',

          subtitle: payment.referenceId,
          subtitleClasses: 'truncate max-w-[15vw]',
        })),

        new TextColumn<PaymentItem>({ header: 'Payment type', fitContent: true, align: 'center' }, payment => ({
          text: payment.description ?? '',
          classes: 'truncate max-w-[15vw]',
        })),

        ...(!hideCustomer
          ? [
              new UserInfoColumn<PaymentItem>({ header: 'Customer' }, payment => {
                return {
                  name: payment.user?.displayName ?? payment.user?.email ?? 'Unknown',
                  email: payment.user?.email,
                  photoUrl: payment.user?.photoURL,
                };
              }),
            ]
          : []),

        new BadgeColumn<PaymentItem>({ header: 'Status', fitContent: true, align: 'center' }, payment => ({
          text: this.ucFirst.transform(payment.status.replace(/_/g, ' ').toLowerCase()),
          displayStatusIcon: true,
          theme: {
            [Payment.Status.DRAFT]: ThemeColorEnum.default,

            [Payment.Status.VOIDED]: ThemeColorEnum.default,
            [Payment.Status.PENDING]: ThemeColorEnum.warn,

            [Payment.Status.PAID]: ThemeColorEnum.success,
            [Payment.Status.FAILED]: ThemeColorEnum.danger,
            [Payment.Status.PROCESSING]: ThemeColorEnum.info,

            [Payment.Status.REFUNDED]: ThemeColorEnum.default,
            [Payment.Status.PARTIALLY_REFUNDED]: ThemeColorEnum.default,
            [Payment.Status.PROCESSING_REFUND]: ThemeColorEnum.info,
          }[payment.status],
        })),

        new TextColumn<PaymentItem>({ header: 'Due date', fitContent: true, align: 'center' }, payment => ({
          text: payment.dueDate ? moment(payment.dueDate).fromNow() : '-',
          classes: 'truncate max-w-[15vw]',
        })),

        new TextColumn<PaymentItem>({ header: 'Total', fitContent: true, align: 'right' }, payment => ({
          text: payment.amount ? '$' + (payment.amount / 100).toFixed(2) : '-',
          classes: 'truncate max-w-[15vw]',
        })),

        new BadgeColumn<PaymentItem>({ header: 'Distributor', fitContent: true, align: 'center' }, async payment => ({
          text: this.getPaymentDistributor(payment),
          theme: this.getPaymentDistributor(payment) !== 'No Distributor' ? 'primary' : 'default',
        })),
      ];
    })
  );

  protected pagination$ = new BehaviorSubject<PaginationQueryRequest>({ page: 1, limit: 10 });
  readonly page$ = combineLatest([this.pagination$, this.filters$]).pipe(
    switchMap(([pagination, filters]) => this.paymentSvc.list({ ...pagination, ...filters })),
    shareReplay(1),
    tap(data => (this.salesCount === 0 ? (this.salesCount = data?.meta?.totalItems || 0) : 0))
  );

  getPaymentDistributor(payment: PaymentItem) {
    if (!payment.referenceId) return 'No Distributor';
    return payment.distributorName ?? 'No Distributor';
  }

  refresh() {
    this.pagination$.next(this.pagination$.value);
  }
}
