/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { extractErrors } from '@vsolv/dev-kit/ngx';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';

export type BillingDetailsInputValue = null | {
  name: string;
  phone: string;
  email: string;
  address: {
    line1: string;
    line2: string;
    city: string;
    state: string;
    zip: string;
  };
};

@Component({
  selector: 'vs-billing-details-input',
  templateUrl: './billing-details-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: BillingDetailsInputComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: BillingDetailsInputComponent, multi: true },
  ],
})
export class BillingDetailsInputComponent implements OnDestroy, ControlValueAccessor, Validator {
  constructor(private formBuilder: NonNullableFormBuilder) {
    this.form.valueChanges.subscribe(value => this.onChange(value as any));
  }

  @HostBinding('class') private _classes = 'block [&_vs-form-field[appearance=vertical]>div>div]:max-w-none';

  @Input() set value(value: BillingDetailsInputValue) {
    this.form.patchValue(
      value ?? { name: '', email: '', phone: '', address: { city: '', line1: '', line2: '', state: '', zip: '' } }
    );
  }
  get value() {
    return this.form.valid ? (this.form.value as BillingDetailsInputValue) : null;
  }

  @Input() set disabled(disabled: BooleanInput) {
    if (coerceBooleanProperty(disabled)) this.form.disable();
    else this.form.enable();
  }
  get disabled() {
    return this.form.disabled;
  }

  @Input() staffView = false;

  protected form = this.formBuilder.group({
    name: ['', [Validators.required]],
    phone: ['', [Validators.required]],
    email: ['', [Validators.required, Validators.email]],
    address: [{ city: '', line1: '', line2: '', state: '', zip: '' }, [Validators.required]],
  });

  validNumber = true;

  protected touched$ = new ReplaySubject<void>(1);
  private onDestroy$ = new Subject<void>();

  onChange = (_value: BillingDetailsInputValue) => {};

  writeValue(value: BillingDetailsInputValue): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(fn: () => void): void {
    this.touched$.pipe(takeUntil(this.onDestroy$)).subscribe(() => fn());
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(): ValidationErrors | null {
    return extractErrors(this.form);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
  validateNumber() {
    if (!this.form.get('phone')?.value) {
      this.validNumber = true;
      return;
    }
    try {
      const phone = new AsYouType('US').input(this.form.get('phone')?.value as string);
      this.validNumber = isValidPhoneNumber(phone, 'US');
      return;
    } catch (e) {
      console.log(e);
    }
    this.validNumber = false;
  }

  phoneNumberKeyUp(phone: any) {
    if (phone.key === 'Backspace') return;
    this.formatPhoneNumber();
  }

  formatPhoneNumber() {
    const phoneNumber = this.form.get('phone')?.value;
    if (phoneNumber) {
      this.form.patchValue({ phone: new AsYouType('US').input(phoneNumber) });
    }
  }
}
