<ng-container *ngIf="title && !styled">
  <p
    class="text-xs font-semibold"
    [class.text-gray-700]="style === 'light'"
    [class.text-gray-50]="style === 'dark'"
    [innerHTML]="title"
  ></p>

  <p
    *ngIf="subtitle"
    class="text-xs font-medium mt-1"
    [class.text-gray-500]="style === 'light'"
    [class.text-gray-50]="style === 'dark'"
    [innerHTML]="subtitle"
  ></p>
</ng-container>

<ng-container *ngIf="title && styled">
  <div class="flex flex-col gap-2 w-[256px]">
    <div class="flex gap-2">
      <vs-divider class="self-center"></vs-divider>
      <p class="text-sm font-normal text-gray-600 whitespace-nowrap" [innerHTML]="title"></p>
      <vs-divider class="self-center"></vs-divider>
    </div>

    <p class="text-xs font-medium text-gray-500" [innerHTML]="subtitle"></p>
  </div>
</ng-container>

<ng-container *ngIf="template">
  <span class="text-xs font-semibold" [class.text-gray-700]="style === 'light'" [class.text-gray-50]="style === 'dark'">
    <ng-container *ngTemplateOutlet="template; context: context"></ng-container>
  </span>
</ng-container>

<div [class]="'absolute w-3 h-3 origin-center ' + arrowClass" [style]="arrowStyle"></div>
