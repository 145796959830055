import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiInterceptor } from './api-interceptor.interceptor';
import { API_BASE_URL, API_PARENT_ROUTE } from './api-interceptor.token';

@NgModule()
export class ApiInterceptorModule {
  static forRoot(apiBaseUrl: string, apiParentRoute: string): ModuleWithProviders<ApiInterceptorModule> {
    return {
      ngModule: ApiInterceptorModule,
      providers: [
        { provide: API_BASE_URL, useValue: apiBaseUrl },
        { provide: API_PARENT_ROUTE, useValue: apiParentRoute },
        { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
      ],
    };
  }
}
