<form [formGroup]="form">
  <vs-form-field
    [config]="{ label: 'Name' }"
    appearance="vertical"
    [theme]="form.controls['name'].touched && form.controls['name'].errors ? 'danger' : 'primary'"
    class="mb-2"
  >
    <input vsInput type="text" formControlName="name" placeholder="Enter full name" />
    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls['name'].touched && form.controls['name'].hasError('required')"
    >
      Name is required
    </span>
  </vs-form-field>

  <vs-form-field
    [config]="{ label: 'Phone' }"
    appearance="vertical"
    class="mb-2"
    [theme]="form.controls['phone'].touched && form.controls['phone'].errors ? 'danger' : 'primary'"
  >
    <input
      vsInput
      type="text"
      formControlName="phone"
      (keyup)="phoneNumberKeyUp($event); validateNumber()"
      (blur)="formatPhoneNumber(); validateNumber()"
      placeholder="Enter phone number"
    />
    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls['phone'].touched && form.controls['phone'].hasError('required')"
    >
      Phone is required.
    </span>
    <span class="text-danger" slot="hint" *ngIf="!validNumber">Please enter a valid phone number</span>
  </vs-form-field>

  <vs-form-field
    [config]="{ label: 'Email' }"
    appearance="vertical"
    class="mb-2"
    [theme]="form.controls['email'].touched && form.controls['email'].errors ? 'danger' : 'primary'"
  >
    <input vsInput type="text" formControlName="email" placeholder="Enter email address" />
    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls['email'].touched && form.controls['email'].hasError('required')"
    >
      Email is required.
    </span>
    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls['email'].touched && form.controls['email'].hasError('email')"
    >
      Please enter a valid email address.
    </span>
  </vs-form-field>

  <vs-address-input
    formControlName="address"
    [staffView]="staffView"
    [placeholders]="{
      line1: staffView ? 'Enter your customer\'\s billing address' : 'Enter your billing address',
      line2: staffView ? 'Enter your customer\'\s billing address' : 'Enter your billing address'
    }"
  >
  </vs-address-input>
</form>
