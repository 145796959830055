<div class="flex flex-col gap-4" *vsLet="hideTransactionKey$ | async as hideTransactionKey">
  <p>
    Designed to integrate your Authorize.net key into our existing payment system - offering practical advantages for
    managing online transactions.
  </p>
  <p>
    Enter your unique Authorize.net key to enhance control and security with payment methods on the Administrator
    Dashboard.
  </p>

  <form [formGroup]="form" lass="flex flex-col gap-4">
    <label class="flex flex-col gap-2 mb-2">
      <span class="text-gray-700 font-medium text-sm">Account ID</span>
      <input
        vsInput
        type="text"
        placeholder="Please enter your account ID"
        formControlName="accountName"
        [ngClass]="form.controls.accountName.value ? 'blur-sm hover:blur-none mr-2' : 'mr-2'"
      />
    </label>

    <label class="flex flex-col gap-2 mb-2" *ngIf="!hideTransactionKey">
      <span class="text-gray-700 font-medium text-sm">Transaction Key</span>
      <input
        vsInput
        type="text"
        placeholder="Please enter your transaction key"
        formControlName="transactionKey"
        [ngClass]="form.controls.transactionKey.value ? 'blur-sm hover:blur-none mr-2' : 'mr-2'"
      />
    </label>

    <label class="flex flex-col gap-2 mb-2" *ngIf="hideTransactionKey && form.controls.clientKey.value && !editing">
      <span class="text-gray-700 font-medium text-sm">Client Key</span>
      <div class="flex">
        <input
          vsInput
          readonly
          type="text"
          placeholder="Please enter your transaction key"
          formControlName="clientKey"
          [ngClass]="form.controls.clientKey.value ? 'blur-sm hover:blur-none mr-2 border-none' : 'mr-2 border-none'"
        />
        <button
          [tooltipStyle]="'light'"
          vsTooltip="Copy client key"
          [cdkCopyToClipboard]="form.controls.clientKey.value"
          (click)="successfulCopy()"
        >
          <vs-icon name="copy-01"></vs-icon>
        </button>
      </div>
    </label>
  </form>
  <div class="flex flex-grow gap-x-2 justify-end" *vsLet="hasValue$ | async as hasValue">
    <button
      vs-button
      *ngIf="!hasValue"
      [theme]="'primary'"
      [appearance]="'default'"
      [disabled]="!form.dirty || !form.valid"
      (click)="create();"
    >
      Enable
    </button>

    <button
      vs-button
      *ngIf="hasValue && editing"
      [theme]="'primary'"
      [appearance]="'default'"
      [disabled]="!form.dirty || !form.valid"
      (click)="create();"
    >
      Update
    </button>

    <button
      vs-button
      *ngIf="hasValue && !editing"
      [theme]="'primary'"
      [appearance]="'default'"
      (click)="editing=true;this.hideTransactionKey$.next(false)"
    >
      Edit
    </button>

    <button *ngIf="hasValue" vs-button [theme]="'primary'" [appearance]="'default'" (click)="disable()">Disable</button>
  </div>
</div>
