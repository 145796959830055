<vs-dialog
  *vsLet="fundingSources$ | async as fundingSources"
  #manageBankDialog
  [config]="{
  dialogContainerClass: state === 'manage' ? 'max-w-[600px]' : 'max-w-[505px]'
}"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'archive',
      iconTheme: 'primary',

      title: state === 'manage' ? 'Manage Bank Accounts' : 'Add New Bank Account',
      subtitle: 'this account will be used for reimbursement when processing this claim. Please provide your customer bank account information to transfer the funds.',
    
      showDivider: true
    }"
  ></vs-dialog-header>

  <div class="flex flex-col gap-4">
    <div class="flex gap-4 items-center justify-center">
      <ng-container *ngIf="state === 'manage'">
        <ng-container *ngIf="fundingSources">
          <vs-manage-bank-details
            [fundingSources]="fundingSources"
            (changeState)="changeState($event)"
            (closeDialog)="manageBankDialog.close()"
            (bankDetails)="confirmBankDetails($event); manageBankDialog.close();"
          ></vs-manage-bank-details>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="state === 'add'">
        <ng-container *ngIf="customer">
          <vs-add-bank-details
            [customer]="customer"
            [customerId]="customerId"
            (bankDetails)="confirmBankDetails($event); manageBankDialog.close();"
            (closeDialog)="fundingSources ? changeState('manage') : manageBankDialog.close()"
          ></vs-add-bank-details>
        </ng-container>
      </ng-container>
    </div>
  </div>
</vs-dialog>
