import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface DropdownConfig {
  disabled?: boolean;
}

@Component({
  selector: 'vs-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent {
  @Input() config?: DropdownConfig;

  @Output() closed = new EventEmitter<void>();

  closing = false;
  _open = false;

  open() {
    this._open = true;
  }

  close() {
    this.closing = true;
    this.closed.emit();
    setTimeout(() => {
      this._open = false;
      this.closing = false;
    }, 200);
  }

  toggle() {
    this._open = !this._open;
  }
}
