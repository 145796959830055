<ng-content select="vs-tab"></ng-content>

<ng-container *ngIf="isSmall$ | async; else tabsTemplate">
  <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
</ng-container>

<div *ngIf="selectedTab?.templateRef as tabTemplateRef" id="content" class="mt-4">
  <ng-container *ngTemplateOutlet="tabTemplateRef"></ng-container>
</div>

<ng-template #tabsTemplate>
  <div id="tabs" class="w-full">
    <div class="flex flex-row justify-between w-full -m-1 p-1 scrollbar-thin overflow-x-auto overflow-y-hidden">
      <div class="flex gap-2 border-gray-200 w-full" [class.border-b]="showDivider">
        <div
          *ngFor="let tab of tabs; let index = index"
          class="group flex mb-1 align-center cursor-pointer rounded-md text-gray hover:bg-gray-50 focus-within:bg-gray-50 focus-within:text-gray-700 relative hover:text-gray-700 active:bg-transparent hover:active:bg-transparent"
          [class.!text-themed]="selectedTabIndex === index"
          [vsTooltip]="
            disabled && tab.config && index !== selectedTabIndex
              ? 'Cannot switch to ' + tab.config.label + ' while ' + disabledMessage
              : undefined
          "
          tooltipStyle="light"
          tooltipPosition="top"
        >
          <button
            *ngIf="!tab.config?.routerLink && !tab.config?.href"
            class="cursor-pointer focus:outline-none flex gap-1 items-center px-2 py-1"
            [disabled]="disabled"
            (click)="switchTo(index)"
          >
            <vs-icon *ngIf="tab.config?.iconStart" class="align-text-bottom" [name]="tab.config!.iconStart!"></vs-icon>

            <p *ngIf="tab.config?.label" class="text-sm font-semibold select-none whitespace-nowrap">
              {{ tab.config!.label }}
            </p>

            <vs-icon *ngIf="tab.config?.iconEnd" [name]="tab.config!.iconEnd!" class="align-text-bottom"></vs-icon>

            <ng-container *ngIf="tab?.labelTemplateRef as labelTemplateRef">
              <ng-container *ngTemplateOutlet="labelTemplateRef"></ng-container>
            </ng-container>

            <div
              class="absolute -bottom-1 left-0 h-2 -mb-[1px] w-full group-hover:border-b-2 group-hover:border-gray-300"
              [class.border-b-2]="selectedTabIndex === index"
              [class.!border-themed]="selectedTabIndex === index"
            ></div>
          </button>

          <a
            *ngIf="tab.config?.routerLink"
            class="cursor-pointer focus:outline-none flex gap-1 items-center px-2 py-1"
            [routerLink]="tab.config!.routerLink!"
            (click)="switchTo(index)"
          >
            <vs-icon *ngIf="tab.config?.iconStart" [name]="tab.config!.iconStart!" class="align-text-bottom"></vs-icon>

            <p *ngIf="tab.config?.label" class="text-sm font-semibold select-none whitespace-nowrap">
              {{ tab.config!.label }}
            </p>

            <vs-icon *ngIf="tab.config?.iconEnd" [name]="tab.config!.iconEnd!" class="align-text-bottom"></vs-icon>

            <ng-container *ngIf="tab?.labelTemplateRef as labelTemplateRef">
              <ng-container *ngTemplateOutlet="labelTemplateRef"></ng-container>
            </ng-container>

            <div
              class="absolute -bottom-1 left-0 h-2 -mb-[1px] w-full group-hover:border-b-2 group-hover:border-gray-300"
              [class.border-b-2]="selectedTabIndex === index"
              [class.!border-themed]="selectedTabIndex === index"
            ></div>
          </a>

          <a
            *ngIf="tab.config?.href"
            class="cursor-pointer focus:outline-none flex gap-1 items-center px-2 py-1"
            [href]="tab.config!.href!"
            [target]="tab.config!.target || '_self'"
          >
            <vs-icon *ngIf="tab.config?.iconStart" [name]="tab.config!.iconStart!" class="align-text-bottom"></vs-icon>

            <p *ngIf="tab.config?.label" class="text-sm font-semibold select-none whitespace-nowrap">
              {{ tab.config!.label }}
            </p>

            <vs-icon *ngIf="tab.config?.iconEnd" [name]="tab.config!.iconEnd!" class="align-text-bottom"></vs-icon>

            <div
              class="absolute -bottom-1 left-0 h-2 -mb-[1px] w-full group-hover:border-b-2 group-hover:border-gray-300"
              [class.border-b-2]="selectedTabIndex === index"
              [class.!border-themed]="selectedTabIndex === index"
            ></div>
          </a>
        </div>
      </div>

      <ng-content select="[slot='after-tabs']"></ng-content>
    </div>
  </div>
</ng-template>

<ng-template #dropdownTemplate>
  <vs-dropdown #dropdown id="dropdown" class="group">
    <ng-container slot="selected">
      <ng-container *ngFor="let tab of tabs; let index = index">
        <span
          class="hidden gap-1 items-center pointer-events-none focus:outline-none"
          [class.!flex]="selectedTabIndex === index"
        >
          <vs-icon
            *ngIf="tab.config?.iconStart"
            [name]="tab.config!.iconStart!"
            size="20"
            class="text-gray-500 mr-1 align-text-bottom"
          ></vs-icon>

          {{ tab.config?.label }}

          <vs-icon
            *ngIf="tab.config?.iconEnd"
            [name]="tab.config!.iconEnd!"
            size="20"
            class="text-gray-500 mr-1 align-text-bottom"
          ></vs-icon>

          <ng-container *ngIf="tab?.labelTemplateRef as labelTemplateRef">
            <ng-container *ngTemplateOutlet="labelTemplateRef"></ng-container>
          </ng-container>
        </span>
      </ng-container>
    </ng-container>

    <ng-container *ngFor="let tab of tabs; let index = index">
      <button
        [disabled]="disabled"
        *ngIf="!tab.config?.routerLink && !tab.config?.href"
        (click)="switchTo(index); dropdown.close()"
        class="group h-10 rounded-md cursor-pointer flex items-center gap-3 px-3 w-full hover:bg-gray-50 hover:text-gray-900 active:bg-transparent hover:active:bg-transparent ring-gray-100 active:ring-4 focus:ring-4 focus:outline-none"
        routerLinkActive="bg-gray-50 text-gray-900 hover:!bg-gray-100 focus:hover:!bg-gray-50 active:!bg-transparent focus:active:!bg-transparent"
      >
        <vs-icon *ngIf="tab.config?.iconStart" [name]="tab.config!.iconStart!" size="24" class="text-gray"></vs-icon>
        <span *ngIf="tab.config?.label" class="truncate overflow-ellipsis">{{ tab.config?.label }}</span>
        <vs-icon *ngIf="tab.config?.iconEnd" [name]="tab.config!.iconEnd!" size="24" class="text-gray"></vs-icon>
        <ng-container *ngIf="tab?.labelTemplateRef as labelTemplateRef">
          <ng-container *ngTemplateOutlet="labelTemplateRef"></ng-container>
        </ng-container>
      </button>

      <a
        *ngIf="tab.config?.routerLink"
        [routerLink]="tab.config!.routerLink!"
        class="group h-10 rounded-md cursor-pointer flex items-center gap-3 px-3 w-full hover:bg-gray-50 hover:text-gray-900 active:bg-transparent hover:active:bg-transparent ring-gray-100 active:ring-4 focus:ring-4 focus:outline-none"
        routerLinkActive="bg-gray-50 text-gray-900 hover:!bg-gray-100 focus:hover:!bg-gray-50 active:!bg-transparent focus:active:!bg-transparent"
      >
        <vs-icon *ngIf="tab.config?.iconStart" [name]="tab.config!.iconStart!" size="24" class="text-gray"></vs-icon>
        <span *ngIf="tab.config?.label" class="truncate overflow-ellipsis">{{ tab.config?.label }}</span>
        <vs-icon *ngIf="tab.config?.iconEnd" [name]="tab.config!.iconEnd!" size="24" class="text-gray"></vs-icon>
      </a>

      <a
        *ngIf="tab.config?.href"
        [href]="tab.config!.href!"
        [target]="tab.config!.target || '_self'"
        class="group h-10 rounded-md cursor-pointer flex items-center gap-3 px-3 w-full hover:bg-gray-50 hover:text-gray-900 active:bg-transparent hover:active:bg-transparent ring-gray-100 active:ring-4 focus:ring-4 focus:outline-none"
        routerLinkActive="bg-gray-50 text-gray-900 hover:!bg-gray-100 focus:hover:!bg-gray-50 active:!bg-transparent focus:active:!bg-transparent"
      >
        <vs-icon *ngIf="tab.config?.iconStart" [name]="tab.config!.iconStart!" size="24" class="text-gray"></vs-icon>
        <span *ngIf="tab.config?.label" class="truncate overflow-ellipsis">{{ tab.config?.label }}</span>
        <vs-icon *ngIf="tab.config?.iconEnd" [name]="tab.config!.iconEnd!" size="24" class="text-gray"></vs-icon>
      </a>
    </ng-container>
  </vs-dropdown>
</ng-template>
