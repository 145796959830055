/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { CreateCustomerOptions, DwollaTokenRequest } from '@vsolv/dwolla/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { of, switchMap } from 'rxjs';
import { DwollaService } from '../../services';
import { DwollaConfig, DWOLLA_CONFIG } from '../../tokens';
import { AddBankDetailsComponentState } from '../add-bank-details/add-bank-details.component';

declare const dwolla: any;

@Component({
  selector: 'vs-create-customer-dwolla',
  templateUrl: './create-customer-dwolla.component.html',
})
export class CreateCustomerDwollaComponent {
  @Input() customer: CreateCustomerOptions | null = null;

  @Output() customerId = new EventEmitter<string>();

  @Output() switchState = new EventEmitter<AddBankDetailsComponentState>();

  protected loading = false;

  protected termsAndPrivacy$ = of(this.portalSvc.getPortalUrl()).pipe(
    switchMap(async config => {
      const { url } = await config;
      return {
        termsOfService: `${url}/terms-of-service`,
        privacyPolicy: `${url}/privacy`,
      };
    })
  );

  constructor(
    @Optional() @Inject(DWOLLA_CONFIG) private config: DwollaConfig,
    private dwollaSvc: DwollaService,
    private toastSvc: ToastService,
    private portalSvc: PortalService
  ) {
    if (!dwolla) throw new Error('Unable to load dwolla library');
    dwolla.configure({
      environment: config.mode,
      styles: '/assets/create-customer-dwolla.component.css',
      token: async (request: DwollaTokenRequest) => await this.getDwollaAPIToken(request),
      success: async (response: any) => await this.success(response),
      error: (err: any) => Promise.resolve(err),
    });
  }

  private async getDwollaAPIToken(request: DwollaTokenRequest) {
    return await this.dwollaSvc.getToken({
      action: request.action,
    });
  }

  private async success(response: any) {
    if (response.response.code !== 'ValidationError') {
      this.loading = true;
      const id = response.response.location.split('/').at(-1);
      this.customerId.emit(id);
      this.loading = false;
      this.switchState.emit('fundingSource');
    } else {
      this.toastSvc.show({
        type: 'error',
        text: response.response._embedded.errors.map((error: { message: string }) => error.message).join(),
        title: 'Something went wrong',
      });
    }
  }
}
