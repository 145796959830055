/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { BehaviorSubject, switchMap } from 'rxjs';
import { PropertyService } from '../../services';

@Component({
  selector: 'vs-property-set-values',
  templateUrl: './property-set-values.component.html',
})
export class PropertySetValuesComponent {
  constructor(private propertySvc: PropertyService) {}

  @PropertyListener('propertySetId') propertySetId$ = new BehaviorSubject<string | null>(null);
  @Input() propertySetId!: string;

  @Input() propertyValues!: Record<string, any>;

  @Input() showHidden = false;
  @Input() firstOnly = false;

  propertySet$ = this.propertySetId$.pipe(
    switchMap(async propertySetId => {
      if (!propertySetId) return null;
      return await this.propertySvc.retrievePropertySet(propertySetId);
    })
  );
}
