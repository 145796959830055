import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WarrantyGuard, WarrantyResolver } from './guards';
import { WarrantyPageComponent, WarrantyTableComponent } from './pages';
import { WarrantyManagementModule } from './warranty-management.module';

@NgModule({
  imports: [
    WarrantyManagementModule,
    RouterModule.forChild([
      {
        path: '',
        component: WarrantyTableComponent,
      },
      {
        path: ':warrantyId',
        canActivate: [WarrantyGuard],
        resolve: { warranty: WarrantyResolver },
        component: WarrantyPageComponent,
      },
    ]),
  ],

  providers: [],
})
export class WarrantyManagementRoutingModule {}
