<vs-input-group>
  <p vsInputGroupItem class="text-xs pointer-events-none px-5">Items per page:</p>

  <vs-select
    #select
    vsInput
    class="text-sm [&>button]:gap-1.5 [&>button>vs-icon_svg-icon]:!w-[14px] [&>button>vs-icon_svg]:!w-[14px] [&>button>vs-icon_svg-icon]:!h-[14px] [&>button>vs-icon_svg]:!h-[14px]"
    [value]="_config.itemsPerPage"
    (valueChanges)="_setItemsPerPage($any($event) ?? _config.pageSizes[0])"
  >
    <li *ngFor="let pageSize of _config.pageSizes" vs-select-option [value]="pageSize">
      {{ pageSize }}
    </li>
  </vs-select>
</vs-input-group>

<vs-input-group>
  <div vsInputGroupItem class="pointer-events-none">
    <p
      class="text-xs px-3"
      vsTooltip="Showing {{ (_config.currentPage - 1) * _config.itemsPerPage + 1 }} - {{
        (_config.currentPage - 1) * _config.itemsPerPage + _config.itemsPerPage
      }} of {{ _config.totalItems }} entries"
      tooltipStyle="light"
    >
      Page {{ _config.currentPage }} of {{ _totalPages }}
    </p>
  </div>

  <button
    vs-icon-button
    appearance="outline"
    size="sm"
    class="!h-10 !w-10"
    [disabled]="_config.currentPage === 1 || _config.totalItems === 0"
    (click)="_setCurrentPage(1)"
  >
    <vs-icon name="align-left-01"></vs-icon>
  </button>

  <button
    vs-icon-button
    appearance="outline"
    size="sm"
    class="!h-10 !w-10"
    [disabled]="_config.currentPage === 1 || _config.totalItems === 0"
    (click)="_setCurrentPage(_config.currentPage - 1)"
  >
    <vs-icon name="arrow-left"></vs-icon>
  </button>

  <button
    vs-icon-button
    appearance="outline"
    size="sm"
    class="!h-10 !w-10"
    [disabled]="_config.currentPage === _totalPages || _config.totalItems === 0"
    (click)="_setCurrentPage(_config.currentPage + 1)"
  >
    <vs-icon name="arrow-right"></vs-icon>
  </button>

  <button
    vs-icon-button
    appearance="outline"
    size="sm"
    class="!h-10 !w-10"
    [disabled]="_config.currentPage === _totalPages || _config.totalItems === 0"
    (click)="_setCurrentPage(_totalPages)"
  >
    <vs-icon name="align-right-01"></vs-icon>
  </button>
</vs-input-group>
