<ng-container *ngIf="distributor">
  <ng-container *vsLet="canInviteMembers$ | async as canInviteMembers">
    <vs-card>
      <vs-card-header
        *vsLet="(table.paginationConfig$ | async) as paginationConfig"
        [config]="{
          title: 'Team Members',
          subtitle: 'Manage and view all team members in the table below.'
        }"
      >
        <vs-badge slot="after-title">
          {{ paginationConfig?.totalItems }} {{ paginationConfig?.totalItems !== 1 ? 'members' : 'member' }}
        </vs-badge>

        <button
          *ngIf="distributor?.status === activated && canInviteMembers"
          vs-button
          size="md"
          slot="end"
          theme="primary"
          (click)="openInviteMemberDialog()"
        >
          <vs-icon name="plus"></vs-icon>
          Invite Member
        </button>
      </vs-card-header>

      <vs-card-content>
        <!-- Table -->
        <ws-team-members-table
          #table
          [distributor]="distributor"
          [permissionKey]="distributor.permissionKey"
          [disabled]="distributor.status !== activated"
        ></ws-team-members-table>

        <ng-container *vsLet="(table.memberCount$ | async) as totalMembers">
          <div *ngIf="!totalMembers && distributor?.status === activated && canInviteMembers" class="flex flex-col">
            <button vs-button theme="primary" class="self-center my-6" (click)="openInviteMemberDialog()">
              <vs-icon name="users-plus"></vs-icon>
              Invite Member
            </button>
          </div>
        </ng-container>
      </vs-card-content>
    </vs-card>

    <!-- Dialogs -->
    <ws-invite-member-dialog
      #inviteMemberDialog
      [distributor]="distributor"
      (userInvited)="table.refresh()"
    ></ws-invite-member-dialog>
  </ng-container>
</ng-container>
