<vs-dialog [config]="{ dialogContainerClass: 'max-w-[500px]' }" (closed)="close(true)">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'check-circle',
      iconTheme: 'primary',

      title: (step ? 'Configure' : 'Create') + ' claim lifecycle step' + (step ? 's' : ''),
      subtitle: (step ? 'Configure the' : 'Create a') + ' claim progress step'  + (step ? 's' : '') + ' to help guide customers through their claim journey. The customer portal will display this step at the top of each claim for this policy.'
    }"
  ></vs-dialog-header>

  <div class="flex flex-col gap-4 p-4">
    <form class="flex flex-col gap-4" [formGroup]="form">
      <vs-form-field [config]="{ label: 'Title'}">
        <input vsInput type="text" formControlName="title" />
        <span *ngIf="(form.value.title?.length || 0) >= 50" slot="hint" class="text-danger">
          Your title can't be longer than 50 characters!
        </span>
      </vs-form-field>

      <div class="flex flex-col gap-2">
        <div class="flex justify-between">
          <label class="text-sm font-medium text-gray-700">Description</label>

          <div class="flex gap-2 -mt-4 -mb-2">
            <button vs-icon-button appearance="clear" class="mt-1" [vsMenuTriggerFor]="attachmentLinkMenu">
              <vs-icon name="attachment-01" size="20"></vs-icon>

              <ng-template #attachmentLinkMenu>
                <vs-menu>
                  <button *ngFor="let link of attachmentLinkInfo" vs-menu-item (click)="copyAttachmentLink(link)">
                    {{ link.text }}
                  </button>
                </vs-menu>
              </ng-template>
            </button>

            <button vs-icon-button appearance="clear" class="mt-1" [vsMenuTriggerFor]="linkMenu">
              <vs-icon name="bank-note-01" size="20"></vs-icon>

              <ng-template #linkMenu>
                <vs-menu>
                  <button vs-menu-item (click)="copyPaymentLink()">Copy manage payment method link</button>
                </vs-menu>
              </ng-template>
            </button>
          </div>
        </div>

        <vs-rich-text-editor *ngIf="showRichText" formControlName="description"></vs-rich-text-editor>
        <span *ngIf="descriptionLength >= 250" class="text-sm text-danger">
          Your description can't be longer than 250 characters!
        </span>
      </div>

      <div class="flex flex-col gap-1">
        <label class="text-sm font-medium text-gray-700">Icon</label>
        <vs-icon-picker formControlName="icon"></vs-icon-picker>
      </div>

      <div class="flex flex-col gap-1">
        <label for="status" class="text-sm font-medium text-gray-700">Status</label>
        <vs-select
          vsInput
          id="status"
          formControlName="status"
          [config]="{ placeholder: 'Select a default status for this step' }"
        >
          <li *ngFor="let status of statuses" vs-select-option [value]="status">
            <vs-badge class="self-center" [theme]="getStatusTheme($any(status))">
              {{ status.replace('_', ' ').replace('_', ' ') | titlecase }}
            </vs-badge>
          </li>
        </vs-select>
      </div>

      <div class="flex gap-2 cursor-pointer">
        <input
          vsInput
          class="mt-px"
          type="checkbox"
          id="optional-input"
          appearance="checkbox"
          formControlName="optional"
        />

        <div class="flex flex-col cursor-pointer">
          <label for="optional-input" class="text-sm font-medium text-gray-700 cursor-pointer">Optional step</label>
          <label for="optional-input" class="text-sm font-normal text-gray-600 cursor-pointer">
            Make this step optional to alert customers it is not mandatory for the completion of their claim.
          </label>
        </div>
      </div>

      <div class="flex gap-2 cursor-pointer">
        <input
          vsInput
          class="mt-px"
          type="checkbox"
          id="visible-input"
          appearance="checkbox"
          formControlName="visible"
        />

        <div class="flex flex-col cursor-pointer">
          <label for="visible-input" class="text-sm font-medium text-gray-700 cursor-pointer">
            Visible to customer
          </label>
          <label for="visible-input" class="text-sm font-normal text-gray-600 cursor-pointer">
            This step will appear in the customer portal.
          </label>
        </div>
      </div>
    </form>
  </div>

  <div slot="buttons" class="flex gap-2 justify-between lg:justify-end bg-base">
    <button
      vs-button
      size="md"
      appearance="outline"
      class="flex-grow lg:flex-grow-0"
      [disabled]="saving"
      (click)="cancel()"
    >
      Cancel
    </button>

    <button
      vs-button
      size="md"
      theme="primary"
      class="flex-grow lg:flex-grow-0"
      [disabled]="saving || !form.valid"
      (click)="step ? updateStep() : createStep()"
    >
      {{ step ? 'Update' : 'Create' }}
    </button>
  </div>
</vs-dialog>
