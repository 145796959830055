import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { PaymentMethodService } from '../../services';

@Component({
  selector: 'vs-payment-method-picker',
  templateUrl: './payment-method-picker.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: PaymentMethodPickerComponent }, //
  ],
})
export class PaymentMethodPickerComponent implements ControlValueAccessor, OnDestroy {
  @HostBinding('class') private _classes = 'block';

  @PropertyListener('userId') private userId$ = new BehaviorSubject<string | null>(null);
  @Input() userId: string | null = null;

  @Input() set value(value: string | null) {
    this.control.setValue(value);
  }
  get value() {
    return this.control.valid ? this.control.value : null;
  }

  @Input() set disabled(disabled: BooleanInput) {
    if (coerceBooleanProperty(disabled)) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }
  get disabled() {
    return this.control.disabled;
  }

  protected control = this.formBuilder.control(null as string | null, Validators.required);

  private refresh$ = new BehaviorSubject<null>(null);
  protected paymentMethods$ = this.userId$.pipe(
    distinctUntilChanged(),
    switchMap(userId =>
      this.refresh$.pipe(
        switchMap(() =>
          this.paymentMethodSvc.list({
            limit: 100,
            userId: userId ?? undefined,
            forCheckout: true,
          })
        )
      )
    ),
    switchMap(data => {
      return this.control.valueChanges.pipe(
        startWith(this.control.value),
        map(paymentMethodId => {
          return data.items.sort((a, b) =>
            a.id === paymentMethodId ? -1 : b.id === paymentMethodId ? 1 : a.created.getTime() - b.created.getTime()
          );
        })
      );
    }),
    tap(paymentMethods => !this.value && (this.value = paymentMethods[0]?.id ?? null)),
    shareReplay(1)
  );

  protected selectedPaymentMethod$ = this.paymentMethods$.pipe(
    switchMap(paymentMethods => {
      return this.control.valueChanges.pipe(
        startWith(this.value),
        distinctUntilChanged(),
        map(paymentMethodId => paymentMethods.find(pm => pm.id === paymentMethodId) ?? null)
      );
    }),
    shareReplay(1)
  );

  protected overlayOpen = false;

  private onDestroy$ = new Subject<void>();
  protected touched$ = new ReplaySubject<void>(1);

  constructor(private formBuilder: FormBuilder, private paymentMethodSvc: PaymentMethodService) {}

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => fn(this.value));
  }

  registerOnTouched(fn: () => void): void {
    this.touched$.pipe(takeUntil(this.onDestroy$)).subscribe(() => fn());
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  refresh() {
    this.refresh$.next(null);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    this.touched$.complete();
    this.refresh$.complete();
  }
}
