/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim, ClaimItem } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-details',
  templateUrl: './claim-item-details.component.html',
})
export class ClaimItemDetailsComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private linkSvc: LinksWebService,
    private securitySvc: SecurityService
  ) {}

  open = Claim.Status.OPEN;

  itemId: string | null = null;

  newClaim = new BehaviorSubject<Claim.Model | null>(null);

  readonly item$ = this.route.data.pipe(map(data => data['item'] as Claim.ClaimItem.Model));

  readonly claim$ = this.claimSvc.getClaim();

  readonly isCustomerPortal$ = this.route.data.pipe(map(data => data['isCustomerPortal'] as boolean));

  readonly customerCanEdit$ = combineLatest([this.isCustomerPortal$, this.item$, this.claim$]).pipe(
    map(([isCustomerPortal, item, claim]) => {
      if (isCustomerPortal && item.status === ClaimItem.Status.DRAFT && claim?.status === Claim.Status.OPEN)
        return true;
      return false;
    })
  );

  canManageCoverage$ = combineLatest([this.claim$, this.isCustomerPortal$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageClaimItemCure', permissionKey);
    })
  );

  linksAndConfigs$ = this.item$.pipe(
    tap(item => (this.itemId = item.id)),
    switchMap(async item =>
      item
        ? await this.linkSvc.list(
            { id: item.id, objectType: Link.ObjectType.CLAIM_ITEM },
            { owner: { id: item.id, objectType: Link.ObjectType.CLAIM_ITEM } }
          )
        : null
    ),
    map(data => data?.items),
    map(links =>
      links?.reduce((acc, link) => {
        if (!this.itemId) return acc;

        const type = Link.getLinkType(link, Link.ObjectType.CLAIM_ITEM);
        if (type === Link.ObjectType.ACTIVITY) return acc;

        const object = Link.getLinkObject(link, type, this.itemId);
        if ((object as any)?.deleted) return acc;

        const config = Link.getLinkInfoCardConfig(link, type, this.itemId);
        acc.push({ link, config });

        return acc;
      }, [] as { link: Link.Model; config: InfoCardConfig }[])
    )
  );

  getItemTheme(item?: Claim.ClaimItem.Model) {
    return item ? Claim.ClaimItem.getTheme(item.status) : 'default';
  }

  navigateTo(path?: string) {
    this.router.navigate([`./${path}`], { relativeTo: this.route });
  }
}
