/* eslint-disable @typescript-eslint/ban-types */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  OnInit,
  TemplateRef,
} from '@angular/core';

export type Position = 'top' | 'bottom' | 'start' | 'end';

export type TooltipStyle = 'light' | 'dark';

export type TooltipMaxWidth = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';

export type TooltipContent =
  | string
  | { title: string; subtitle: string; styled?: boolean }
  | { template: TemplateRef<unknown>; context?: Object };

@Component({
  templateUrl: './tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      }
    `,
  ],
})
export class TooltipComponent implements OnInit {
  @HostBinding('class') private get _class() {
    const style = this.style === 'light' ? 'bg-base border border-gray-100' : 'bg-gray-900';
    const maxWidth = `max-w-${this.maxWidth}`;
    return `relative block px-3 py-2 rounded-lg overlfow-hidden ${maxWidth} ${style}`;
  }

  _position?: Position;
  _tooltipBounds?: { top: number; right: number; bottom: number; left: number };
  _originBounds?: { top: number; right: number; bottom: number; left: number };

  style: TooltipStyle = 'light';
  maxWidth: TooltipMaxWidth = 'lg';
  originElementRef?: ElementRef;
  content?: TooltipContent;

  get title() {
    if (!this.content) return undefined;
    return typeof this.content === 'string' ? this.content : 'title' in this.content ? this.content.title : undefined;
  }

  get subtitle() {
    if (!this.content) return undefined;
    return typeof this.content !== 'string' && 'subtitle' in this.content ? this.content.subtitle : undefined;
  }

  get styled() {
    if (!this.content) return undefined;
    return typeof this.content !== 'string' && 'styled' in this.content ? true : false;
  }

  get template() {
    if (!this.content) return undefined;
    return typeof this.content !== 'string' && 'template' in this.content ? this.content.template : undefined;
  }

  get context() {
    if (!this.content) return null;
    return typeof this.content !== 'string' && 'context' in this.content ? this.content.context || null : null;
  }

  get arrowStyle() {
    switch (this._position) {
      case 'top':
      case 'bottom': {
        const originCenter =
          (this._originBounds?.left || 0) + ((this._originBounds?.right || 0) - (this._originBounds?.left || 0)) / 2;

        const tooltipCenter =
          (this._tooltipBounds?.left || 0) + ((this._tooltipBounds?.right || 0) - (this._tooltipBounds?.left || 0)) / 2;

        const delta = originCenter - tooltipCenter;
        const operator = delta < 0 ? '-' : '+';

        return `${this._position === 'top' ? 'bottom' : 'top'}: -6px; left: calc(50% ${operator} ${Math.abs(delta)}px)`;
      }

      case 'start':
        return 'top: 50%; right: -6px;';
      case 'end':
        return 'top: 50%; left: -6px;';

      default:
        return 'display: none;';
    }
  }

  get arrowClass() {
    const style = this.style === 'light' ? 'bg-base border-b border-r border-gray-100' : 'bg-gray-900';

    switch (this._position) {
      case 'top':
        return `-translate-x-1/2 rotate-45 ${style}`;
      case 'bottom':
        return `-translate-x-1/2 -rotate-[135deg] ${style}`;
      case 'start':
        return `-translate-y-1/2 -rotate-45 ${style}`;
      case 'end':
        return `-translate-y-1/2 rotate-[135deg] ${style}`;
      default:
        return style;
    }
  }

  constructor(private elementRef: ElementRef, private changeDetector: ChangeDetectorRef) {}

  ngOnInit() {
    // setTimeout is necessary for HTMLElement offset properties to have their values
    setTimeout(() => {
      this._tooltipBounds = this.getBounds(this.elementRef.nativeElement.parentElement);
      this._originBounds = this.getBounds(this.originElementRef?.nativeElement);

      this._position =
        this._tooltipBounds.bottom <= this._originBounds.top
          ? 'top'
          : this._tooltipBounds.top >= this._originBounds.bottom
          ? 'bottom'
          : this._tooltipBounds.right <= this._originBounds.left
          ? 'start'
          : this._tooltipBounds.left >= this._originBounds.right
          ? 'end'
          : undefined;

      this.changeDetector.detectChanges();
    });
  }

  private getBounds(el?: HTMLElement) {
    const bounds = el?.getBoundingClientRect();

    return {
      top: (bounds?.top || 0) + document.documentElement.scrollTop,
      right: (bounds?.right || 0) + document.documentElement.scrollLeft,
      bottom: (bounds?.bottom || 0) + document.documentElement.scrollTop,
      left: (bounds?.left || 0) + document.documentElement.scrollLeft,
    };
  }
}
