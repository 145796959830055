<div class="flex flex-col gap-2 -mt-0.5 -mx-0.5 mb-8 max-h-[500px] overflow-y-auto scrollbar-thin">
  <ws-asset-details-card
    *ngFor="let asset of assets$ | async"
    class="m-0.5"
    [asset]="asset"
    [session]="session"
    [staffView]="staffView"
    [selected]="asset.id === selected?.id"
    (clicked)="selectAsset(asset)"
  ></ws-asset-details-card>
</div>
