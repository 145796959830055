<ng-container *ngIf="!staffView; else staff">
  <div class="flex h-full w-full justify-center place-content-center" *ngIf="warranty">
    <div class="relative flex flex-col items-center gap-4 text-primary-900 text-lg">
      <vs-featured-icon name="check-circle-broken" theme="primary"></vs-featured-icon>

      <div *ngIf="warranty.customer" class="text-center">Hi {{ warranty.customer.name }},</div>

      <div class="text-3xl text-primary-800 text-center font-semibold">Thank you for your recent purchase!</div>

      <div class="py-8 relative flex flex-col items-center gap-4">
        <p>We sent you an email confirmation of your new warranty.</p>
        <p class="font-semibold">{{ warranty.contractNumber }}</p>
      </div>

      <div class="text-center">You can access the portal to view further details:</div>

      <button vs-button theme="primary" (click)="goToWarranty(warranty, staffView)" class="text-base-white">
        My coverage
      </button>
      <button vs-button theme="default" appearance="outline" (click)="closeDialog()">
        <vs-icon name="x-close" size="20"></vs-icon>
        Close
      </button>
    </div>
  </div>
</ng-container>
<ng-template #staff>
  <div class="w-full justify-center place-content-center mt-16" *ngIf="warranty">
    <div class="relative flex flex-col items-center gap-4">
      <vs-featured-icon name="check-circle-broken" theme="primary"></vs-featured-icon>
      <p class="text-center text-3xl font-semibold leading-[38px]">Thank you for your recent purchase!</p>
      <p class="text-center text-sm text-gray-500 mb-8">
        We sent your customer an email confirmation of their new warranty.
      </p>

      <p class="text-center font-semibold">{{ warranty.contractNumber }}</p>
      <div class="flex flex-col gap-2">
        <button vs-button theme="primary" class="!max-w-[296px] !w-full" (click)="reloadCurrentRoute()">
          Sell more warranties
        </button>
        <div class="flex gap-2">
          <button vs-button theme="default" appearance="outline" (click)="closeDialog()">
            <vs-icon name="x-close"></vs-icon>
            Close
          </button>
          <button vs-button theme="default" appearance="outline" (click)="goToWarranty(warranty, staffView)">
            Go to warranty
            <vs-icon name="arrow-right" size="20"></vs-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
