import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Warranty } from '@wsphere/warranties/domain';

@Injectable()
export class WarrantyResolver implements Resolve<Warranty.Model | undefined> {
  warranty?: Warranty.Model;

  async resolve() {
    return this.warranty;
  }
}
