<ng-container *ngIf="!loading; else isLoading">
  <ng-container *vsLet="termsAndPrivacy$ | async as termsAndPrivacy">
    <dwolla-customer-create
      #createCustomer
      [firstName]="customer?.firstName"
      [lastName]="customer?.lastName"
      [email]="customer?.email"
      [correlationId]="customer?.correlationId"
      [terms]="termsAndPrivacy?.termsOfService"
      [privacy]="termsAndPrivacy?.privacyPolicy"
      type="receive-only"
    ></dwolla-customer-create>
  </ng-container>
</ng-container>
<ng-template #isLoading>
  <div class="flex justify-center items-center min-h-[284px]">
    <vs-icon name="loading-01" class="animate-spin text-primary" size="40"></vs-icon>
  </div>
</ng-template>
