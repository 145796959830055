/* eslint-disable @typescript-eslint/ban-types */
import { Component, TemplateRef } from '@angular/core';
import { ButtonAppearance } from '@vsolv/vectors-ui/button';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { TableCellComponent } from '../../models';

export interface TextCellProps {
  text: string;
  tag?: string;
  classes?: string;
  description?: string | null;
  tooltip?: string | { title: string; subtitle: string } | { template: TemplateRef<unknown>; context?: Object };

  icon?: IconName;
  iconTooltip?: string | { title: string; subtitle: string } | { template: TemplateRef<unknown>; context?: Object };

  disabled?: boolean;
  buttonText?: string;
  buttonTheme?: ThemeColor;
  buttonAppearance?: ButtonAppearance;
  buttonClasses?: string;
  buttonIcon?: IconName;
  buttonClick?($event: Event): void | Promise<void>;
  cellClick?($event: Event): void | Promise<void>;
}

@Component({
  template: `
    <div *ngIf="data" class="flex gap-2 w-full" (click)="_cellClick($event)">
      <div *ngIf="data && data.description" tooltipStyle="light" [vsTooltip]="data.tooltip">
        <p
          *ngIf="data.text"
          class="text-sm font-medium text-gray-900"
          [innerHTML]="data.text"
          [class]="data.classes + getChildClasses()"
        ></p>
        <p
          *ngIf="data.description"
          class="text-xs font-normal text-gray-600"
          [innerHTML]="data.description"
          [class]="data.classes + getChildClasses()"
        ></p>
      </div>

      <p
        *ngIf="data && !data.description && data.text"
        tooltipStyle="light"
        class="self-center text-sm text-gray-600"
        [innerHTML]="data.text"
        [vsTooltip]="data.tooltip"
        [class]="data.classes + getChildClasses()"
      ></p>

      <vs-icon
        *ngIf="data.icon"
        size="18"
        tooltipStyle="light"
        class="self-center text-gray-500"
        [name]="data.icon"
        [vsTooltip]="data.iconTooltip"
      ></vs-icon>

      <button
        *ngIf="data.buttonText"
        vs-button
        type="button"
        [class]="data.buttonClasses"
        [disabled]="data.disabled || false"
        [theme]="data.buttonTheme ?? 'default'"
        [appearance]="data.buttonAppearance ?? 'default'"
        (click)="_click($event); $event.stopPropagation()"
      >
        <vs-icon *ngIf="data.buttonIcon" [name]="data.buttonIcon"></vs-icon>
        {{ data.buttonText }}
      </button>

      <div *ngIf="data.tag" class="self-center border rounded-md px-2 py-1 bg-base">
        <p class="text-xs font-medium text-gray-700 whitespace-nowrap" [innerHTML]="data.tag"></p>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        p > ::ng-deep {
          figure {
            table {
              @apply border border-r-0 border-b-0;

              tbody {
                tr {
                  td {
                    @apply min-w-[32px] p-1.5 border border-t-0 border-l-0;
                  }
                }
              }
            }
          }
        }
      }
    `,
  ],
})
export class TextCellComponent extends TableCellComponent<TextCellProps> {
  async _click($event: Event) {
    await this.data?.buttonClick?.($event);
  }

  async _cellClick($event: Event) {
    if (this.data?.cellClick) {
      await this.data?.cellClick?.($event);
      $event.stopImmediatePropagation();
    }
  }

  getChildClasses() {
    const a = '[&>p>a]:underline [&>p>a]:text-info-600';
    const ol = '[&>ol]:block [&>ol]:list-decimal [&>ol]:mt-4 [&>ol]:mb-4 [&>ol]:ml-0 [&>ol]:mr-0 [&>ol]:pl-3';
    const ul = '[&>ul]:block [&>ul]:list-disc [&>ul]:mt-4 [&>ul]:mb-4 [&>ul]:ml-0 [&>ul]:mr-0 [&>ul]:pl-3';

    return ` ${a} ${ol} ${ul}`;
  }
}
