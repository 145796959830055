<div
  *ngIf="customer"
  class="flex rounded-md cursor-pointer transition hover:bg-gray-50"
  (click)="navigateToCustomer(customer.id || '')"
>
  <div class="flex flex-col p-2 w-full">
    <div class="flex">
      <vs-icon
        size="22"
        name="user-01"
        class="text-primary-600 bg-primary-100 max-h-[40px] max-w-[40px] p-2 mr-4 rounded-full"
      ></vs-icon>

      <p class="text-lg font-semibold self-center">{{ customer.name }}</p>
    </div>

    <vs-divider class="my-1"></vs-divider>

    <div class="flex justify-between gap-4 w-full">
      <div class="flex" style="max-width: calc(100% - 56px)">
        <vs-icon size="22" name="mail-01" class="max-h-[40px] max-w-[40px] m-2 mr-6"></vs-icon>
        <p class="text-sm self-center truncate">{{ customer.email }}</p>
      </div>

      <button
        #trigger="cdkOverlayOrigin"
        vs-icon-button
        cdkOverlayOrigin
        appearance="clear"
        (click)="$event.stopPropagation(); toggle()"
      >
        <vs-icon name="send-01" class="m-2 text-gray-600"></vs-icon>
      </button>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="overlayOpen"
      [cdkConnectedOverlayPositions]="positions"
      (overlayOutsideClick)="overlayOpen ? (overlayOpen = false) : ''"
    >
      <vs-card class="bg-base-white">
        <vs-icon *ngIf="loading" name="loading-02" class="animate-spin text-primary-500 mt-1" size="24"></vs-icon>

        <button *ngIf="!loading" vs-button appearance="clear" (click)="sendWelcomeEmail()" [disabled]="loading">
          Resend welcome email
        </button>
      </vs-card>
    </ng-template>

    <vs-divider class="my-1"></vs-divider>

    <div *ngIf="customer.phone" class="flex gap-4">
      <vs-icon size="22" name="phone" class="m-2"></vs-icon>

      <p class="text-sm self-center">{{ customer.phone }}</p>
    </div>
  </div>
</div>
