import { Pipe, PipeTransform } from '@angular/core';
import { TableColumn } from '../models';

@Pipe({ name: 'vsTableColumnIds' })
export class TableColumnIdsPipe implements PipeTransform {
  transform(columns: TableColumn<unknown>[], draggable?: boolean) {
    const cols = columns.reduce((acc, col) => [...acc, ...(col.config.hidden ? [] : [col.id])], [] as string[]);

    return draggable ? ['draggable', ...cols] : cols;
  }
}
