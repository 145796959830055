import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link } from '@wsphere/links/domain';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable()
export class LinksWebService {
  constructor(private http: HttpClient) {}

  refreshActivity$ = new BehaviorSubject<null>(null);

  async create(context: { id: string; objectType: Link.ObjectType }, dto: Link.CreateLinkRequest) {
    return await firstValueFrom(
      this.http.post<Link.CreateLinkResponse>(`/api/links/${context.objectType}/${context.id}`, dto)
    );
  }

  async delete(context: { id: string; objectType: Link.ObjectType }, linkId: string) {
    return await firstValueFrom(
      this.http.delete<Link.CreateLinkResponse>(`/api/links/${context.objectType}/${context.id}/${linkId}`)
    );
  }

  async retrieve(context: { id: string; objectType: Link.ObjectType }, linkId: string) {
    return await firstValueFrom(this.http.get<Link.Model>(`/api/links/${context.objectType}/${context.id}/${linkId}`));
  }

  async list(context: { id: string; objectType: Link.ObjectType }, query: Link.ListLinksRequest) {
    return await firstValueFrom(
      this.http.get<Link.ListLinksResponse>(`/api/links/${context.objectType}/${context.id}`, {
        params: {
          ownerId: query.owner.id,
          ownerLinkType: query.owner.objectType,
          ...(query.page && { page: query.page }),
          ...(query.limit && { limit: query.limit }),
          ...(query.linkType && { linkType: query.linkType }),
        },
      })
    );
  }
}
