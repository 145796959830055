import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { CreateCustomerOptions, FundingSource } from '@vsolv/dwolla/domain';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import {
  BadgeCellProps,
  BadgeColumn,
  IconButtonColumn,
  MultiBadgeCellProps,
  MultiTagColumn,
  PaginationConfig,
  TableColumn,
  TextColumn,
} from '@vsolv/vectors/table';
import { Claim } from '@wsphere/warranties/domain';
import moment from 'moment';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject } from 'rxjs';
import { PaymentDialog } from '../../dialogs';
import { Payment } from '../../pages';

@Component({
  selector: 'ws-claim-payments-table',
  templateUrl: './payments-table.component.html',
})
export class ClaimPaymentsTableComponent {
  @ViewChild(PaymentDialog) paymentDialog?: PaymentDialog;

  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);

  @Input() payments: Payment[] | null = null;

  @PropertyListener('canEdit') private canEdit$ = new ReplaySubject<boolean>(1);
  @Input() canEdit: boolean | null = false;

  @Input() canIssue: boolean | null = false;
  @Input() canVoid: boolean | null = false;

  @PropertyListener('claim') private claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim: Claim.Model | null = null;

  @PropertyListener('destinations') protected destinations$ = new BehaviorSubject<FundingSource[] | null>(null);
  @Input() destinations: FundingSource[] | null = null;

  @Input() canManagePaymentMethods: boolean | null = null;

  @Input() customer: CreateCustomerOptions | null = null;

  @Input() customerId: string | null = null;

  @Input() paginationConfig: PaginationConfig = {
    itemsPerPage: 5,
    currentPage: 1,
    pageSizes: [5, 10, 20, 50],
    totalItems: 0,
  };

  @Output() paginationQueryRequest = new EventEmitter<{ page?: number; limit?: number }>();

  @Output() refresh = new EventEmitter<void>();
  @Output() refreshPaymentMethods = new EventEmitter<void>();

  columns$: Observable<TableColumn<unknown>[]> = combineLatest([this.canEdit$, this.claim$]).pipe(
    map(([canEdit, claim]) => [
      new TextColumn({ sticky: true, header: 'Title', fitContent: true }, ({ title }: Payment) => ({
        text: title,
      })),

      new MultiTagColumn<Payment>({ header: 'Item(s)', fitContent: true }, ({ items }: Payment) => {
        return this.getMultiBadgeClaimItems(items);
      }),

      new BadgeColumn({ header: 'Status', fitContent: true }, ({ status }: Payment) => ({
        theme: this.getBadgeStatusColor(status)?.color,
        text: this.getBadgeStatusColor(status)?.text,
        displayStatusIcon: true,
      })),

      new TextColumn({ header: 'Destination', fitContent: true }, (payment: Payment) => ({
        text: payment.destination === 'PENDING' ? 'Pending customer banking details' : payment.destinationName ?? '-',
        classes: 'whitespace-nowrap',
      })),

      new TextColumn({ header: 'Amount', fitContent: true }, ({ amount }: Payment) => ({
        text: (amount / 100).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
      })),

      new TextColumn({ header: 'Created on', fitContent: true }, ({ created }: Payment) => ({
        text: moment(created).format('MMM D, YYYY'),
        classes: 'whitespace-nowrap',
      })),

      ...(canEdit
        ? [
            new IconButtonColumn({ stickyEnd: true, fitContent: true }, (payment: Payment) => ({
              type: 'clear',
              rounded: true,
              icon: 'edit-01',
              theme: 'primary',
              click: () => this.paymentDialog?.open(claim, payment),
              disabled: ![Claim.ClaimPayment.Status.DRAFT, Claim.ClaimPayment.Status.FAILED].includes(payment.status),
            })),
          ]
        : []),
    ])
  );

  private getMultiBadgeClaimItems(items: Claim.ClaimItem.Model[]): MultiBadgeCellProps {
    if (!items || !items.length) {
      return { items: [] };
    }

    const coverageBadges: BadgeCellProps[] = items.map(item => ({
      text: item.coverage?.title || 'Other',
      theme: this.getClaimItemStatusColor(item.status),
      displayStatusIcon: true,
    }));

    return { items: coverageBadges };
  }

  private getClaimItemStatusColor(status: Claim.ClaimItem.Status): ThemeColor {
    switch (status) {
      case Claim.ClaimItem.Status.APPROVED:
      case Claim.ClaimItem.Status.RESOLVED:
        return 'success';
      case Claim.ClaimItem.Status.REJECTED:
        return 'danger';
      default:
        return 'default';
    }
  }

  getBadgeStatusColor(status: Claim.ClaimPayment.Status): {
    color: ThemeColor;
    text: string;
  } {
    switch (status) {
      case Claim.ClaimPayment.Status.DRAFT:
        return { text: 'Draft', color: 'default' };
      case Claim.ClaimPayment.Status.COMPLETED:
        return { text: 'Completed', color: 'success' };
      case Claim.ClaimPayment.Status.FAILED:
        return { text: 'Failed', color: 'danger' };
      case Claim.ClaimPayment.Status.ISSUED:
        return { text: 'Issued', color: 'info' };
      case Claim.ClaimPayment.Status.VOIDED:
        return { text: 'Voided', color: 'default' };
      default:
        return { text: 'Draft', color: 'default' };
    }
  }

  protected navigateTo(paymentId: string) {
    this.router.navigate([paymentId], { relativeTo: this.route });
  }
}
