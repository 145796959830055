<div *ngIf="(!assignment.hidden || showHidden) && propertyValue" class="max-w-full">
  <ng-container *ngIf="assignment.property as property">
    <ng-container *vsLet="childObjects$ | async as assignments">
      <div *ngIf="property.type !== 'OBJECT' && propertyValue" class="flex">
        <vs-badge
          theme="default"
          class="self-center mr-1"
          tooltipStyle="light"
          [vsTooltip]="{ template: propertyValueTooltip }"
        >
          {{ property.name }}
        </vs-badge>

        <span class="truncate">
          {{ propertyValue | transformPropertyValue : { property, showHidden, showLiteralBoolean } }}
        </span>

        <ng-template #propertyValueTooltip>
          <p class="text-xs font-semibold">{{ property.name }}</p>
          <p class="text-xs font-normal">
            {{ propertyValue | transformPropertyValue : { property, showHidden, showLiteralBoolean: true } }}
          </p>
        </ng-template>
      </div>

      <ng-container *ngIf="property.type === 'OBJECT'">
        <div class="flex gap-1">
          <vs-badge
            *ngIf="assignments?.length"
            theme="default"
            class="self-center"
            tooltipStyle="light"
            [vsTooltip]="{ template: propertyValueTooltip }"
            (click)="$event.stopImmediatePropagation(); toggleExpanded()"
          >
            {{ property.name }}
            <vs-icon [name]="expanded ? 'chevron-up' : 'chevron-down'" class="cursor-pointer"></vs-icon>
          </vs-badge>

          <vs-badge
            *ngIf="!assignments?.length && showBadge"
            theme="default"
            class="self-center"
            tooltipStyle="light"
            [vsTooltip]="{ template: propertyValueTooltip }"
          >
            {{ property.name }}
          </vs-badge>

          <span class="truncate self-center">
            {{ propertyValue | transformPropertyValue : { property, showHidden, showLiteralBoolean } }}
          </span>

          <ng-template #propertyValueTooltip>
            <p class="text-xs font-semibold">{{ property.name }}</p>
            <ng-container *vsLet="propertyValue | propertyValueToStringArray : { property, showHidden } as values">
              <p class="text-xs font-normal" *ngFor="let value of values">{{ value }}</p>
            </ng-container>
          </ng-template>
        </div>

        <ng-container *ngIf="assignments?.length && expanded">
          <div class="flex flex-col gap-1 ml-1 mt-1">
            <ng-container *ngFor="let objectAssignment of assignments">
              <vs-property-value-item
                *ngIf="objectAssignment.property?.type === 'OBJECT'"
                [assignment]="objectAssignment"
                [propertyValue]="propertyValue[objectAssignment.property!.valueKey]"
              ></vs-property-value-item>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>
