import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-create-new-version-dialog',
  templateUrl: './create-new-version.dialog.html',
})
export class CreateNewVersionDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policySvc: PolicyService,
    private toastSvc: ToastService
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy?: Policy.Model | null;
  @Input() plan?: Policy.Plan.Model | null;

  saving = false;

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  async createNewVersion() {
    if (this.saving) return;
    if (!this.policy || !this.plan) return;

    this.saving = true;

    const newVersion = await this.policySvc.duplicate(this.policy.id, true).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (newVersion?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Updated plan',
        text:
          'A new version of <strong>' +
          this.policy.title +
          '</strong> has been created with an updated version of <strong>' +
          this.plan.title +
          '</strong>.',
      });

      this.policySvc.refreshPolicy(newVersion.id);

      const newPlan = newVersion.plans?.find(plan => plan.id.startsWith(this.plan?.id?.split('-')[0] || ''));
      if (newPlan) this.policySvc.refreshPlan(newPlan);

      this.navigateTo('/policies/' + newVersion.id + '/plans/' + newPlan?.id);
    }

    this.saving = false;
    this.close();
  }

  navigateTo(path?: string) {
    console.log(path);
    if (path) this.router.navigate([`${path}`]);
  }
}
