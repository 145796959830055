import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '@vsolv/core/users/domain';
import { Payment } from '@vsolv/packages/payments/domain';
import { catchError, map, of, tap } from 'rxjs';

@Component({
  templateUrl: './invoice.page.html',
})
export class InvoicePage {
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  protected payment$ = this.route.queryParams.pipe(
    map(params => JSON.parse(params['payment']) as Payment.Model & { user: User.Model; taxes: Payment.Model[] }),
    catchError(() => of(null)),
    tap(content => !content && this.router.navigateByUrl('/404'))
  );
}
