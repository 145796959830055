/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { ReplaySubject, Subject, debounceTime, switchMap } from 'rxjs';
import { OnboardType } from '../../pages';
import { DistributorService } from '../../services';

export type DistributorDetailsInputValue = {
  name: string;
  email: string;
  phone: string;
} | null;

@Component({
  selector: 'ws-distributor-details-form',
  templateUrl: './distributor-details-form.component.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DistributorDetailsFormComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: DistributorDetailsFormComponent, multi: true },
  ],
})
export class DistributorDetailsFormComponent implements OnDestroy, ControlValueAccessor, Validator {
  constructor(private formBuilder: FormBuilder, private distributorService: DistributorService) {
    this.form.valueChanges.subscribe(value => {
      const phone = this.form.value.phone;
      if (phone) {
        const valid = isValidPhoneNumber(phone, 'US');
        if (!valid) this.form.controls['phone'].setErrors({ phone: 'Provided phone number is not valid' });
      }

      this.onChange(value as any);
    });
  }

  @Input() editing = false;
  @Input() onBoard?: OnboardType;

  @Input() set disabled(disabled: BooleanInput) {
    if (coerceBooleanProperty(disabled)) this.form.disable();
    else this.form.enable();
  }
  get disabled() {
    return this.form.disabled;
  }

  validNumber = true;
  onBoardType = OnboardType;

  private onDestroy$ = new Subject<void>();
  protected touched$ = new ReplaySubject<void>(1);

  protected form = this.formBuilder.group({
    name: [{ value: null as string | null, disabled: false }, [Validators.required, Validators.maxLength(255)]],
    email: [{ value: null as string | null, disabled: false }, [Validators.email]],
    phone: [{ value: null as string | null, disabled: false }],
  });

  protected distributorName$ = this.form.controls['name'].valueChanges.pipe(
    debounceTime(500),
    switchMap(async name => {
      try {
        if (!name || !this.onBoard) return null;
        return await this.distributorService.findByName(name);
      } catch (error) {
        return error;
      }
    })
  );

  @PropertyListener('onBoard') private resetDetailsForm() {
    this.form.reset();
  }

  onChange = (_value: DistributorDetailsInputValue) => {};
  onTouched = () => {};

  writeValue(value: DistributorDetailsInputValue): void {
    this.form.patchValue({
      name: value?.name,
      email: value?.email,
      phone: value?.phone ? new AsYouType('US').input(value.phone) : null,
    });

    this.validateNumber();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) this.form.disable();
    else this.form.enable();
  }

  validate(_control: AbstractControl<any, any>): ValidationErrors | null {
    if (this.form.valid) return null;

    let errors = {};
    if (this.form.controls['name'].errors) {
      errors = { name: this.form.controls['name'].errors };
    }
    if (this.form.controls['email'].errors) {
      errors = { ...errors, email: this.form.controls['email'].errors };
    }
    if (this.form.controls['phone'].errors) {
      errors = { ...errors, phone: this.form.controls['phone'].errors };
    }

    const phone = this.form.value.phone;
    if (phone) {
      const valid = isValidPhoneNumber(phone, 'US');
      if (!valid) {
        errors = { ...errors, phone: 'Provided phone number is not valid' };
        this.form.controls['phone'].setErrors({ phone: 'Provided phone number is not valid' });
      }
    }

    return errors;
  }

  phoneNumberKeyUp(phone: any) {
    if (phone.key === 'Backspace') return;
    this.formatPhoneNumber();
  }

  validateNumber() {
    if (!this.form.get('phone')?.value) {
      this.validNumber = true;
      return;
    }

    try {
      const phone = new AsYouType('US').input(this.form.get('phone')?.value as string);
      this.validNumber = isValidPhoneNumber(phone, 'US');
      return;
    } catch (e) {
      console.log(e);
    }

    this.validNumber = false;
  }

  formatPhoneNumber() {
    const phoneNumber = this.form.get('phone')?.value;
    if (phoneNumber) this.form.patchValue({ phone: new AsYouType('US').input(phoneNumber) });
  }

  resetForm() {
    this.form.reset({
      email: '',
      phone: '',
    });

    this.form.markAsUntouched();
    this.form.markAsPristine();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
