/* eslint-disable @typescript-eslint/no-explicit-any */
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { CustomerService } from '@wsphere/customers/web';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, Observable, switchMap, tap } from 'rxjs';
import { UploadClaimDocumentDialog, ViewClaimDocumentDialog } from '../../dialogs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-attachments',
  templateUrl: './claim-attachments.page.html',
})
export class ClaimAttachmentsPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private toastSvc: ToastService,
    private clipboardCdk: Clipboard,
    private linkSvc: LinksWebService,
    private portalSvc: PortalService,
    private securitySvc: SecurityService,
    private customerSvc: CustomerService
  ) {}

  @ViewChild('attachmentDialog') attachmentDialog?: UploadClaimDocumentDialog;
  @ViewChild('documentViewDialog') documentViewDialog?: ViewClaimDocumentDialog;

  open = Claim.Status.OPEN;
  documents: StorageItem.Model[] = [];
  selectedAttachment: StorageItem.Model | null = null;

  readonly claim$ = this.claimSvc.getClaim();

  linkButtonInfo$: Observable<{ text: string; title: string; description: string }[]> = this.claim$.pipe(
    map(claim => {
      return [
        {
          text: 'Copy inspection report request link',
          title: 'Inspection report',
          description: `This is the inspection report for ${claim?.warranty?.contractNumber}`,
        },
        {
          text: 'Copy receipt request link',
          title: 'Receipt',
          description: `This is the receipt for ${claim?.warranty?.contractNumber}`,
        },
        {
          text: 'Copy supporting evidence request link ',
          title: 'Supporting evidence',
          description: `This is supporting evidence for claim: ${claim?.warranty?.contractNumber}. It is used to better understand the complaint.`,
        },
      ];
    })
  );

  private refresh$ = new BehaviorSubject<null>(null);

  routedDocument$ = combineLatest([this.route.queryParams, this.claim$]).pipe(
    map(([params, claim]) => {
      const routeDocumentId = params?.['documentId'];

      if (routeDocumentId) {
        setTimeout(() => {
          this.selectedAttachment = this.documents.find(document => document.id === routeDocumentId) ?? null;

          if (this.selectedAttachment) {
            this.openViewDialog(this.selectedAttachment, claim);
          }
        }, 100);
      }

      this.router.navigate(['.'], { relativeTo: this.route });
    })
  );

  readonly isCustomerPortal$ = this.route.data.pipe(map(data => data['isCustomerPortal'] as boolean));

  readonly customerCanView$ = combineLatest([this.claim$, this.isCustomerPortal$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (isCustomerPortal) {
        const currentUser = await this.customerSvc.retrieveSelf();
        return claim?.warranty?.customerId === currentUser?.id;
      }
      return false;
    })
  );

  documents$ = combineLatest([this.claim$, this.isCustomerPortal$, this.refresh$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (!claim) return [];

      const documents = await this.claimSvc.listDocuments(claim.id, {
        feed: isCustomerPortal ? ['customer'] : undefined,
      });

      return documents.items;
    }),

    tap(documents => (this.documents = documents))
  );

  canViewAttachment$ = this.claim$?.pipe(
    switchMap(
      async claim =>
        await this.securitySvc.hasAccess(
          'clm_ViewAttachments',
          claim?.warranty?.distributor ? [claim.warranty.distributor.permissionKey] : null
        )
    )
  );

  canManageAttachment$ = this.claim$?.pipe(
    switchMap(
      async claim =>
        await this.securitySvc.hasAccess(
          'clm_ManageAttachments',
          claim?.warranty?.distributor ? [claim.warranty.distributor.permissionKey] : null
        )
    )
  );

  openAttachmentDialog(claimId: string, attachment?: StorageItem.Model) {
    if (attachment) this.selectedAttachment = attachment;
    else this.selectedAttachment = null;
    this.attachmentDialog?.open(claimId);
  }

  openViewDialog(document: StorageItem.Model, claim: Claim.Model | null) {
    if (!claim) return;
    this.documentViewDialog?.open({ claim: claim, document: document });
  }

  getItemTheme(item?: Claim.ClaimItem.Model) {
    return item ? Claim.ClaimItem.getTheme(item.status) : 'default';
  }

  async refresh() {
    this.refresh$.next(null);
    await this.claimSvc.refreshClaim();
  }

  async copyLink(link: { text: string; title: string; description: string }, claim: Claim.Model | null) {
    const portalLink = await this.portalSvc.getPortalUrl();
    const attachmentData = encodeURIComponent(JSON.stringify(link)).replace(/'/g, '%27');

    this.clipboardCdk.copy(
      `${portalLink.url}/warranty/${claim?.warranty?.contractNumber}/claim/${claim?.id}/manage-attachments?claimId=${claim?.id}&openDialog=true&addAttachmentData=${attachmentData}`
    );
    this.toastSvc.show({
      type: 'success',
      text: `The <strong> ${link.title} </strong> link has been copied to your clipboard and can now be sent out to customers.`,
      title: `Link copied to clipboard.`,
    });
  }

  ngOnInit(): void {
    this.linkSvc.refreshActivity$.next(null);
  }
}
