import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Customer } from '@wsphere/customers/domain';
import { Distributor } from '@wsphere/distributors/domain';
import { Provisioning, Warranty } from '@wsphere/warranties/domain';
import { BehaviorSubject, Subject, catchError, firstValueFrom, of } from 'rxjs';
import { EvaluatedAddon } from '../../policy';

@Injectable()
export class ProvisioningSessionService {
  constructor(private http: HttpClient, private firebaseSvc: FirebaseService, private toastSvc: ToastService) {}

  private session$ = new BehaviorSubject<Provisioning.ProvisioningSession | null>(null);
  distributor$ = new BehaviorSubject<Distributor.Model | null>(null);
  preview$ = new BehaviorSubject<Provisioning.WarrantyPreview | null>(null);
  addons$ = new BehaviorSubject<{ planId: string; addons: EvaluatedAddon[] }[] | null>(null);
  data$ = new BehaviorSubject<InitialData>({});
  sessionComplete$ = new BehaviorSubject<boolean>(false);
  close$ = new Subject<void>();

  async get(sessionId: string) {
    return await firstValueFrom(
      this.http.get<Provisioning.ProvisioningSession>(`/api/provision-session/${sessionId}`).pipe(
        catchError(_err => {
          return of(null);
        })
      )
    );
  }
  async evaluateSession(sessionId: string, ignoreVisibility: boolean) {
    return await firstValueFrom(
      this.http.get<Provisioning.EvaluatedSessionPlans>(`/api/provision-session/${sessionId}/evaluate`, {
        params: { ignoreVisibility },
      })
    );
  }

  async create(dto: Provisioning.CreateProvisioningSessionDto) {
    const response = await firstValueFrom(
      this.http.post<Provisioning.CreateProvisioningSessionResponse>(`/api/provision-session`, dto)
    );

    if (response.signInToken) {
      await this.firebaseSvc.customTokenSignIn(response.signInToken.signInToken, response.signInToken.tenantId);
    }

    return response.session;
  }

  async update(sessionId: string, dto: Provisioning.UpdateProvisioningSessionDto) {
    return await firstValueFrom(
      this.http.put<Provisioning.ProvisioningSession>(`/api/provision-session/${sessionId}`, dto)
    );
  }

  async preview(sessionId: string, hash: string) {
    return await firstValueFrom(
      this.http.post<Provisioning.WarrantyPreview>(`/api/provision-session/${sessionId}/preview`, { hash })
    );
  }

  async confirm(sessionId: string, hash: string, paymentMethodId?: string) {
    return await firstValueFrom(
      this.http.post<Warranty.Model>(`/api/provision-session/${sessionId}/confirm`, { hash, paymentMethodId })
    );
  }

  getCurrentSession() {
    return this.session$.asObservable();
  }

  async updateSession(preview: { sessionId: string; data: Provisioning.UpdateProvisioningSessionDto }) {
    try {
      const session = await this.update(preview.sessionId, preview.data);
      this.session$.next(session);
    } catch (err: unknown) {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong!',
      });
      console.error(err);
      throw new Error();
    }
  }

  async refreshSession(session?: Provisioning.ProvisioningSession) {
    if (this.session$.value) {
      if (session) {
        if (this.session$.value.hash !== session.hash) this.session$.next(session);
      } else {
        const id = this.session$.value.id;
        const session = await this.get(id);
        this.session$.next(session);
      }
    } else {
      if (session) {
        this.session$.next(session);
      }
    }
  }

  clearSession() {
    this.session$.next(null);
  }
}

export interface InitialData {
  customer?: Customer.Model;
  customerInfo?: {
    name: string | undefined;
    email: string | null;
    phone: string | null | undefined;
  };
  distributor?: {
    referenceId: string;
    parentId: string | undefined;
  };
  distributorId?: string | null;
  viewablePlansAndTerms?: Provisioning.ViewablePlansAndTerms;
  expiryDate?: Date;
}
