import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Provisioning } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, Subject, switchMap } from 'rxjs';
import { EvaluatedAddon } from '../../../policy';

@Component({
  selector: 'ws-provision-addon-form',
  templateUrl: './provision-addon-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProvisionAddonFormComponent,
    },
  ],
})
export class ProvisionAddonFormComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @PropertyListener('session') session$ = new BehaviorSubject<Provisioning.ProvisioningSession | null>(null);
  @Input() session: Provisioning.ProvisioningSession | null = null;

  @PropertyListener('addons') addons$ = new BehaviorSubject<{ planId: string; addons: EvaluatedAddon[] }[] | null>(
    null
  );
  @Input() addons: { planId: string; addons: EvaluatedAddon[] }[] | null = null;

  selectedTerm$ = this.session$.pipe(
    map(session => {
      return session?.policy?.terms.find(term => term.id === session.policyTermId) ?? null;
    })
  );

  protected readonly selectedPlanAddons$ = combineLatest([this.session$, this.addons$]).pipe(
    switchMap(async ([session, addons]) =>
      session && addons ? addons.find(addon => addon.planId === session.planId) : null
    ),
    map(addons => (addons ? { planId: addons.planId, addons: addons.addons } : null))
  );

  protected selectedAddons$ = this.session$.pipe(
    switchMap(async session => (session && session.addonIds && session.addonIds.length ? session.addonIds : null))
  );

  protected addonControl = new FormControl(null as string[] | null);

  private destroy$ = new Subject<void>();

  @Input() disabled = false;
  touched = false;

  @PropertyListener('staffView') staffView$ = new BehaviorSubject(false);
  @Input() staffView = false;

  private _onChange?: (value: string[] | null) => void;
  private _onTouched?: () => void;

  ngOnInit(): void {
    this.addonControl.valueChanges.subscribe(values => this._onChange?.(values));
  }

  writeValue(value: string[] | null): void {
    this.addonControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(onChange: (value: string[] | null) => void): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this._onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this._onTouched?.();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
