<ng-container *ngIf="data">
  <div class="flex gap-2">
    <ng-container *ngFor="let distributor of data.items">
      <div
        *ngIf="distributor.roles?.length"
        class="flex gap-1"
        tooltipStyle="light"
        tooltipOpenDelay="300"
        [vsTooltip]="{ template: tooltip }"
      >
        <div
          class="border px-2 py-1 rounded-md cursor-default text-xs whitespace-nowrap max-w-[150px] truncate"
          [theme]="$any(distributor?.theme) ?? 'default'"
          [class]="
            distributor.theme
              ? 'bg-themed-50 text-themed-700 border-themed-200'
              : 'bg-base text-gray-700 border-gray-300'
          "
        >
          {{ distributor?.distributor?.name || 'Global' }}
        </div>
      </div>
      <ng-template #tooltip>
        <p>
          Roles for {{ distributor.distributor?.name || 'Platform' }} and its
          {{ distributor.distributor?.name ? ' sub-distributors' : 'distributors' }}
        </p>
        <vs-divider class="mt-1 mb-2"></vs-divider>
        <div class="flex flex-row gap-2 flex-wrap">
          <ws-role-badge
            *ngFor="let role of distributor.roles"
            [roleId]="role.roleId"
            [permissionKey]="role.permissionKey"
            [showPermissionKeyLabel]="false"
          ></ws-role-badge>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
