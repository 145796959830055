import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Policy } from '@wsphere/warranties/domain';

@Injectable()
export class PlanResolver implements Resolve<Policy.Plan.Model | null> {
  plan!: Policy.Plan.Model | null;

  async resolve(): Promise<Policy.Plan.Model | null> {
    return this.plan || null;
  }
}
