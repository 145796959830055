import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Address } from '@vsolv/core/address/domain';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ReplaySubject, Subject, takeUntil } from 'rxjs';
import { BillingDetailsInputValue } from '../billing-details-input/billing-details-input.component';
import { PaymentMethodPickerComponent } from '../payment-method-picker/payment-method-picker.component';

@Component({
  selector: 'vs-select-or-create-payment-method',
  templateUrl: './select-or-create-payment-method.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: SelectOrCreatePaymentMethodComponent, multi: true }, //
  ],
})
export class SelectOrCreatePaymentMethodComponent implements ControlValueAccessor, OnDestroy {
  @HostBinding('class') private _classes = 'block';

  @ViewChild(PaymentMethodPickerComponent) private picker?: PaymentMethodPickerComponent;

  @PropertyListener('userId') userId$ = new ReplaySubject<string | null>(1);
  @Input() userId: string | null = null;

  @Input() userDetails: Pick<NonNullable<BillingDetailsInputValue>, 'name' | 'email' | 'phone'> | null = null;
  @Input() address: Address.Model | null = null;

  @Input() set value(value: string | null) {
    this.control.setValue(value);
  }
  get value() {
    return this.control.valid ? this.value : null;
  }

  @Input() set disabled(disabled: BooleanInput) {
    if (coerceBooleanProperty(disabled)) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  protected state: 'select' | 'credit-card' | 'bank' = 'select';
  get _state() {
    return this.state;
  }

  @Input() staffView = false;

  protected control = this.formBuilder.control(null as string | null, Validators.required);

  protected touched$ = new ReplaySubject<void>(1);
  private onDestroy$ = new Subject<void>();

  constructor(private formBuilder: FormBuilder) {}

  writeValue(value: string | null): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string | null) => void): void {
    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => fn(value));
  }

  registerOnTouched(fn: () => void): void {
    this.touched$.pipe(takeUntil(this.onDestroy$)).subscribe(() => fn());
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  creditCardAdded({ id }: { id: string }) {
    this.state = 'select';
    this.control.setValue(id);
  }

  refresh() {
    this.picker?.refresh();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    this.touched$.complete();
  }
}
