import { Staff } from '../lib/staff';

export type ClaimPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'clm_OpenClaim',
    display: {
      title: 'Open Claims',
      description: 'Open/create a claim (warranty/coverage/asset/customer details collected, complaint details)',
    },
  },
  {
    id: 'clm_ViewClaim',
    display: {
      title: 'View Claims',
      description:
        'View claim details (customer, asset, warranty, distributor info) + claim item details. Can also view the table of all claims on claims mgmt main page.',
    },
  },
  {
    id: 'clm_CancelClaim',
    display: {
      title: 'Cancel Claim',
      description: 'Cancel a claim',
    },
  },
  {
    id: 'clm_ExpireClaim',
    display: {
      title: 'Expire Claim',
      description: 'Expire a claim manually',
    },
  },
  {
    id: 'clm_CloseClaim',
    display: {
      title: 'Close Claim',
      description: 'Close a claim',
    },
  },
  {
    id: 'clm_EditClaim',
    display: {
      title: 'Edit Claim',
      description: 'Add & edit claim details (complaint text, cannot adjust customer, asset, warranty details)',
    },
  },
  {
    id: 'clm_ViewNote',
    display: {
      title: 'View Claim Notes',
      description: 'View notes (content, title, timestamp, related items)',
    },
  },
  {
    id: 'clm_ManageNote',
    display: {
      title: 'Manage Claim Notes',
      description:
        'Create and update a note to a claim (attach to a claim item, mention document etc only if relevant permissions)',
    },
  },
  {
    id: 'clm_RemoveNote',
    display: {
      title: 'Remove Claim Notes',
      description: 'Remove a note from a claim',
    },
  },
  {
    id: 'clm_ViewAttachments',
    display: {
      title: 'View Claim Attachments',
      description:
        'View attachments (metadata, download URL, description, title, created by, timestamp, links to (claim items, payments, etc))',
    },
  },
  {
    id: 'clm_ManageAttachments',
    display: {
      title: 'Manage Claim Attachments',
      description: 'Crate, update attachments to claim',
    },
  },
  {
    id: 'clm_RemoveAttachments',
    display: {
      title: 'Remove Claim Attachments',
      description: 'Remove attachments from claim',
    },
  },
  {
    id: 'clm_ViewPayments',
    display: {
      title: 'View Claim Payments',
      description: 'View payment details (title, description, status, source, destination, amount, related claim item)',
    },
  },
  {
    id: 'clm_ManagePayments',
    display: {
      title: 'Manage Claim Payments',
      description:
        'Create, update payments as draft( title, description, status, source, destination, amount, related claim item)',
    },
  },
  {
    id: 'clm_ManagePaymentMethod',
    display: {
      title: 'Manage Claim Payment Methods',
      description: 'Create and modify payment methods',
    },
  },
  {
    id: 'clm_IssuePayment',
    display: {
      title: 'Remove Claim Payments',
      description: 'Can issue a payment from a draft',
    },
  },
  {
    id: 'clm_FinishPayment',
    display: {
      title: 'Finish Claim Payments',
      description: 'Can fail, void, or complete a payment from draft or issued states',
    },
  },
  // Claim Item Permissions
  {
    id: 'clm_ManageClaimItemCoverage',
    display: {
      title: 'Manage Claim Item Coverage',
      description: `Set and update/remove claim item's coverage (select coverage/coverage item), create claim item is implied here`,
    },
  },
  {
    id: 'clm_ManageClaimItemCure',
    display: {
      title: 'Manage Claim Item Cure',
      description: `Set and update/remove claim item's cure (set title, description, amounts)`,
    },
  },
  {
    id: 'clm_ManageClaimItemResolution',
    display: {
      title: 'Manage Claim Item Resolution',
      description: `Set and update and remove claim item's resolution (title, description, true claim amount) (if task requires attachment upload, permission granted when related to resolution`,
    },
  },
  {
    id: 'clm_RemoveClaimItem',
    display: {
      title: 'Remove Claim Items',
      description: `Remove claim items`,
    },
  },
  {
    id: 'clm_AdjudicateClaimItem',
    display: {
      title: 'Adjudicate Claim Items',
      description: `Approve, reject claim items`,
    },
  },
  {
    id: 'clm_ResolveClaimItem',
    display: {
      title: 'Resolve Claim Items',
      description: `Set claim item status to resolved`,
    },
  },
  {
    id: 'clm_ManageLink',
    display: {
      title: 'Manage Claim Links',
      description: `Create and delete links between notes, attachments, items, payments`,
    },
  },
  {
    id: 'clm_ViewLifecycle',
    display: {
      title: 'View Claim Lifecycle',
      description: 'View the lifecycle steps of a claim',
    },
  },
  {
    id: 'clm_SetCustomerLifecycle',
    display: {
      title: 'Edit Claim Lifecycle',
      description: 'Edit the status of claim lifecycle steps as well as edit the steps as a whole.',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
