<vs-dialog
  #previewDialog
  [config]="{
      dialogContainerClass: 'w-[700px] sm:w-[500px] max-w-[700px]',
      disableBackdropDismiss: false,
      hideBackdrop: false
    }"
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,

        icon: 'tag-03',
        iconTheme: 'primary',

        title: 'Manage Groups',
        subtitle: 'Edit, remove, and create new add-on and aggregate limit groups to further tailor your customers\' warranty purchasing experience.'
      }"
  ></vs-dialog-header>

  <div class="flex flex-col gap-4">
    <div class="flex gap-2">
      <vs-divider class="self-center"></vs-divider>
      <span class="min-w-max"> Add-ons </span>
      <vs-divider class="self-center"></vs-divider>
    </div>

    <div class="flex gap-2 flex-wrap">
      <ng-container *ngFor="let addon of plan?.addons">
        <!-- Only showing custom addons, not coverage addons -->
        <vs-badge *ngIf="!addon.id.startsWith('cov_')" theme="info" (click)="openAddonDialog(addon)">
          {{ addon.title }}
          <vs-icon
            *ngIf="policy?.status === 'DRAFT'"
            name="x"
            (click)="$event.stopImmediatePropagation(); removeAddonCheck(addon)"
          ></vs-icon>
        </vs-badge>
      </ng-container>
    </div>

    <button vs-button appearance="clear" (click)="openAddonDialog()" *ngIf="policy?.status === 'DRAFT'">
      <vs-icon name="plus"></vs-icon> Create new Add-on group
    </button>

    <div class="flex gap-2">
      <vs-divider class="self-center"></vs-divider>
      <span class="min-w-max"> Aggregate Limits </span>
      <vs-divider class="self-center"></vs-divider>
    </div>

    <div class="flex gap-2 flex-wrap">
      <vs-badge
        *ngFor="let liability of plan?.liabilityGroups"
        theme="default"
        (click)="openLiabilityGroupDialog(liability)"
      >
        {{ liability.title }}
        <vs-icon
          *ngIf="policy?.status === 'DRAFT'"
          name="x"
          (click)="$event.stopImmediatePropagation(); removeLiabilityGroupCheck(liability)"
        ></vs-icon>
      </vs-badge>
    </div>

    <button vs-button appearance="clear" (click)="openLiabilityGroupDialog()" *ngIf="policy?.status === 'DRAFT'">
      <vs-icon name="plus"></vs-icon> Create new Aggregate Limit group
    </button>
  </div>

  <div class="flex justify-end mt-4">
    <button vs-button appearance="outline" (click)="close()">Cancel</button>
  </div>
</vs-dialog>

<vs-dialog #createAddonDialog>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'plus',
      iconTheme: 'primary',

      title: 'Create Included In Group',
      subtitle: 'Enter the details for the group below.'
    }"
    (close)="closeAddonDialog()"
  ></vs-dialog-header>

  <form class="flex flex-col gap-4 mb-4" [formGroup]="addonForm">
    <vs-form-field>
      <label>Title</label>
      <input vsInput type="text" formControlName="title" />
    </vs-form-field>

    <vs-form-field>
      <label>Description</label>
      <input vsInput type="text" formControlName="description" />
    </vs-form-field>
  </form>

  <div class="footer">
    <div class="buttons flex gap-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" (click)="closeAddonDialog()">Cancel</button>

      <button
        *ngIf="policy?.status === 'DRAFT'"
        class="flex-grow"
        vs-button
        size="md"
        theme="primary"
        [disabled]="!addonForm.valid"
        (click)="createAddon()"
      >
        {{ selectedAddon ? 'Update' : 'Create' }}
      </button>
    </div>
  </div>
</vs-dialog>

<vs-dialog #removeAddonDialog>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'plus',
      iconTheme: 'primary',

      title: 'Remove Included In Group?',
      subtitle: 'Are you sure you want to remove ' + (selectedAddon?.title ?? 'this included in group') + '? Doing so will also delete all coverage config blocks where it is used.'
    }"
    (close)="closeAddonDialog()"
  ></vs-dialog-header>

  <div class="footer">
    <div class="buttons flex gap-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" (click)="removeAddonDialog.close()">
        Cancel
      </button>

      <button class="flex-grow" vs-button size="md" theme="danger" (click)="removeAddon()">Remove</button>
    </div>
  </div>
</vs-dialog>

<vs-dialog #createLiabilityGroupDialog>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'plus',
      iconTheme: 'primary',

      title: 'Create Aggregate Group',
      subtitle: 'Enter the details for the group below.'
    }"
    (close)="closeAddonDialog()"
  ></vs-dialog-header>

  <form class="flex flex-col gap-4 mb-4" [formGroup]="liabilityGroupForm">
    <vs-form-field>
      <label>Title</label>
      <input vsInput type="text" formControlName="title" />
    </vs-form-field>

    <vs-form-field>
      <label>Description</label>
      <input vsInput type="text" formControlName="description" />
    </vs-form-field>

    <vs-form-field [config]="{ label: 'Limit' }">
      <vs-input-container>
        <!-- Value Property Badge -->
        <div *ngIf="liabilityGroupForm.value.limitPropertyPath; else limitInput" class="w-fit max-w-[128px] pt-[5px]">
          <vs-badge
            theme="default"
            [vsTooltip]="liabilityGroupForm.value.limitPropertyPath"
            tooltipPosition="top"
            class="w-full h-4"
          >
            <span class="w-full truncate">{{ liabilityGroupForm.value.limitPropertyPath }}</span>
            <vs-icon
              name="x-close"
              slot="end"
              class="cursor-pointer"
              (click)="setLimitPropertyPath(null); limitPropertyPicker.setValue(null)"
            ></vs-icon>
          </vs-badge>
        </div>
        <ng-template #limitInput>
          <div class="flex flex-row w-full h-full gap-2 justify-center align-middle items-center">
            <p class="text-gray-600">$</p>
            <input type="text" mask="separator.2" thousandSeparator="," formControlName="limit" />
          </div>
        </ng-template>

        <ws-condition-value-property-picker
          #limitPropertyPicker
          class="pt-1"
          slot="end"
          [propertySet]="propertySet"
          [type]="num"
          format="currency"
          [selectedPropertyPath]="liabilityGroupForm.value.limitPropertyPath"
          (selectionChanged)="setLimitPropertyPath($event.property)"
        ></ws-condition-value-property-picker>
      </vs-input-container>
    </vs-form-field>
  </form>

  <div class="footer">
    <div class="buttons flex gap-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" (click)="closeLiabilityGroupDialog()">
        Cancel
      </button>

      <button
        *ngIf="policy?.status === 'DRAFT'"
        class="flex-grow"
        vs-button
        size="md"
        theme="primary"
        [disabled]="((liabilityGroupForm.value.limit === null || liabilityGroupForm.value.limit === undefined || $any(liabilityGroupForm.value.limit) === '') && !liabilityGroupForm.value.limitPropertyPath) || !liabilityGroupForm.valid"
        (click)="createLiabilityGroup()"
      >
        {{ selectedLiabilityGroup ? 'Update' : 'Create' }}
      </button>
    </div>
  </div>
</vs-dialog>

<vs-dialog #removeLiabilityGroupDialog>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'plus',
      iconTheme: 'primary',

      title: 'Remove Aggregate Group?',
      subtitle: 'Are you sure you want to remove ' + (selectedLiabilityGroup?.title ?? 'this included in group') + '? Doing so will also delete all coverage config blocks where it is used.'
    }"
    (close)="closeAddonDialog()"
  ></vs-dialog-header>

  <div class="footer">
    <div class="buttons flex gap-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" (click)="removeLiabilityGroupDialog.close()">
        Cancel
      </button>

      <button class="flex-grow" vs-button size="md" theme="danger" (click)="removeLiabilityGroup()">Remove</button>
    </div>
  </div>
</vs-dialog>
