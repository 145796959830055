import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimItemResolver } from '../resolver';
import { ClaimService } from '../services';

@Injectable()
export class ClaimItemAdjudicationGuard implements CanActivate {
  constructor(private resolver: ClaimItemResolver, private claimSvc: ClaimService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.item = null;

    const claimId = this.findClaimId(route);
    if (!claimId) return false;

    const itemId = this.findItemId(route);
    if (!itemId) return false;

    const item = await this.claimSvc.getItem(claimId, itemId);

    if (!item?.adjudicationReason || item.status === Claim.ClaimItem.Status.DRAFT) {
      return this.router.createUrlTree([`/claims/${claimId}/${itemId}`]);
    }

    return true;
  }

  private findClaimId(route: ActivatedRouteSnapshot): string {
    return route.params['claimId'] || (route.parent ? this.findClaimId(route.parent) : null);
  }

  private findItemId(route: ActivatedRouteSnapshot): string {
    return route.params['itemId'] || (route.parent ? this.findItemId(route.parent) : null);
  }
}
