import { Staff } from '../lib/staff';

export type WebhookPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'web_View',
    display: {
      title: 'View Webhooks',
      description: 'View webhooks',
    },
  },
  {
    id: 'web_Edit',
    display: {
      title: 'Update Webhooks',
      description: 'Update webhooks',
    },
  },
  {
    id: 'web_Create',
    display: {
      title: 'Create Webhooks',
      description: 'Create webhooks',
    },
  },
  {
    id: 'web_Delete',
    display: {
      title: 'Delete Webhooks',
      description: 'Delete webhooks',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
