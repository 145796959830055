import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Warranty } from '@wsphere/warranties/domain';
import { ProvisioningSessionService } from '../../services';

@Component({
  selector: 'ws-provision-warranty-complete',
  templateUrl: './admin-provision-warranty-complete.component.html',
})
export class AdminProvisionWarrantyCompleteComponent {
  constructor(private router: Router, private sessionSvc: ProvisioningSessionService) {}
  @Input() warranty?: Warranty.Model;
  @Input() staffView = false;

  @Output() dialogClose = new EventEmitter();

  closeDialog() {
    this.sessionSvc.close$.next();
    this.sessionSvc.sessionComplete$.next(false);
  }

  reloadCurrentRoute() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
      this.sessionSvc.sessionComplete$.next(false);
    });
  }

  async goToWarranty(warranty: Warranty.Model, staffView: boolean) {
    if (!warranty) return;
    if (staffView) await this.router.navigate([`warranty/${warranty.id}`]);
    else
      await this.router.navigate([`warranty/${warranty.contractNumber}`]).then(() => {
        window.location.reload();
      });
    this.closeDialog();
  }
}
