<div class="w-full h-full flex flex-row gap-2 justify-center align-middle items-center">
  <input
    #dateInput
    [formControl]="formControl"
    class="picker-indicator-hidden w-full"
    type="date"
    placeholder="Select a closing date"
    [required]="required"
  />
  <ng-container *ngIf="showPicker">
    <button
      vs-icon-button
      size="xs"
      appearance="clear"
      slot="end"
      [vsMenuTriggerFor]="dateMenu"
      #trigger="vsMenuTriggerFor"
    >
      <vs-icon vsTooltip="Open date picker" name="calendar" size="16"></vs-icon>
    </button>

    <ng-template #dateMenu>
      <vs-menu>
        <vs-date-picker vs-menu-item (selectionChanged)="populateDate($any($event), trigger)"></vs-date-picker>
      </vs-menu>
    </ng-template>
  </ng-container>
</div>
