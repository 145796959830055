/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { extractErrors } from '@vsolv/dev-kit/ngx';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Property } from '@vsolv/packages/properties/domain';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { BehaviorSubject, ReplaySubject, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'vs-abstract-property-input',
  template: '',
})
export abstract class PropertyInputComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {
  @Input() showLabel = true;
  @Input() property!: Property.Model<Property.PropertyType>;
  @Input() required = false;
  @Input() hidden = false;
  @Input() showHidden = false;

  @PropertyListener('extraData') extraData$ = new BehaviorSubject<Record<string, any> | undefined>(undefined);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() extraData?: Record<string, any>;

  formControl!: FormControl;

  @Output() valueChanges = new EventEmitter();

  @Output() touched = new EventEmitter();

  protected onDestroy$ = new Subject<void>();
  protected touched$ = new ReplaySubject<void>(1);

  disabled = false;
  protected value: unknown;

  valid = true;

  onChange = (_value: unknown | null) => {};
  onTouched = () => {};

  markAsTouched() {
    this.touched.emit();
    this.touched$.next();
  }

  validate(): ValidationErrors | null {
    if (this.formControl) {
      const errors = extractErrors(this.formControl);
      if (errors) {
        this.valid = false;
        return errors;
      }
    }
    this.valid = true;
    return null;
  }

  writeValue(value: unknown): void {
    this.value = value;
    this.formControl?.setValue(value);
  }

  registerOnChange(fn: (value: unknown) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
    this.touched$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.onTouched());
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    if (isDisabled) this.formControl?.disable();
    else this.formControl?.enable();
  }

  ngOnInit() {
    this.createFormControl();
    this.formControl.setValue(this.value);

    if (this.disabled) this.formControl.disable();
    else this.formControl.enable();

    this.formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
      this.valueChanges.emit(value);
      this.onChange(value);
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    this.touched$.complete();
  }

  private createFormControl() {
    this.formControl = createPropertyControl(this.property, this.required);
  }
}

export function createPropertyControl(property: Property.Model<Property.PropertyType>, required: boolean): FormControl {
  switch (property.type) {
    case Property.PropertyType.BOOLEAN:
      return createBooleanControl(property as Property.BooleanModel, required);
    case Property.PropertyType.DATE:
      return createDateControl(property as Property.DateModel, required);
    case Property.PropertyType.NUMBER:
      return createNumberControl(property as Property.NumberModel, required);
    case Property.PropertyType.OBJECT:
      return createObjectControl(property as Property.ObjectModel, required);
    case Property.PropertyType.TEXT:
      return createTextControl(property as Property.TextModel, required);

    default:
      throw new Error(`${property.type} does not have a registered form control!`);
  }
}

function createBooleanControl(property: Property.BooleanModel, required: boolean) {
  const validators = getBooleanValidators(property, required);

  return new FormControl(false as boolean, validators);
}

function createDateControl(property: Property.DateModel, required: boolean) {
  const validators = getDateValidators(property, required);

  return new FormControl(null as Date | null, validators);
}

function createNumberControl(property: Property.NumberModel, required: boolean) {
  const validators = getNumberValidators(property, required);

  if (property.config.min !== undefined && property.config.min !== null) {
    validators.push(Validators.min(property.config.min));
  }

  if (property.config.max !== undefined && property.config.max !== null) {
    validators.push(Validators.max(property.config.max));
  }

  if (property.config.allowedValues?.length) {
    const validator = createAllowedValuesValidator(property.config.allowedValues);
    validators.push(validator);
  }

  return new FormControl(null as number | null, validators);
}

function createObjectControl(property: Property.ObjectModel, required: boolean) {
  const validators = [];
  if (required) validators.push(Validators.required);

  const assignments = property.properties?.sort((a, b) => a.order - b.order) ?? [];
  const controls: Record<string, FormControl> = {};

  assignments.forEach(assignment => {
    controls[assignment.property!.valueKey] = createPropertyControl(assignment.property!, assignment.required);
  });

  const control = new FormControl(controls);
  control.setValue(null);

  return control;
}

function createTextControl(property: Property.TextModel, required: boolean) {
  const validators = getTextValidators(property, required);

  return new FormControl(null as string | null, validators);
}

export function createAllowedValuesValidator(values: unknown[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return null;
    if (values.some((val: any) => val === value)) return null;

    return { allowedValues: true };
  };
}

export function createMinDateValidator(min: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return null;

    const minTime = new Date(min).getTime();
    const valueTime = new Date(value).getTime();

    if (valueTime > minTime) return { minDate: true };

    return null;
  };
}

export function createMaxDateValidator(max: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) return null;

    const maxTime = new Date(max).getTime();
    const valueTime = new Date(value).getTime();

    if (valueTime < maxTime) return { maxDate: true };

    return null;
  };
}

export function createRequiredFalseValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value) return { requiredFalse: true };

    return null;
  };
}

export function createPhoneValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value) {
      const isValid = isValidPhoneNumber(value, 'US');
      if (!isValid) return { phone: true };
    }

    return null;
  };
}

export function getPropertyValidators(property: Property.Model<Property.PropertyType>, required: boolean) {
  switch (property.type) {
    case Property.PropertyType.BOOLEAN:
      return getBooleanValidators(property as Property.BooleanModel, required);
    case Property.PropertyType.DATE:
      return getDateValidators(property as Property.DateModel, required);
    case Property.PropertyType.NUMBER:
      return getNumberValidators(property as Property.NumberModel, required);
    case Property.PropertyType.OBJECT:
      throw new Error('Cannot create validators of object, please use createObjectControl instead');
    case Property.PropertyType.TEXT:
      return getTextValidators(property as Property.TextModel, required);
  }
}

function getBooleanValidators(property: Property.BooleanModel, required: boolean) {
  const validators = [];
  if (required) validators.push(Validators.required);

  if (property.config.requiredValue === true) {
    validators.push(Validators.requiredTrue);
  } else if (property.config.requiredValue === false) {
    const validator = createRequiredFalseValidator();
    validators.push(validator);
  }

  return validators;
}

function getDateValidators(property: Property.DateModel, required: boolean) {
  const validators = [];
  if (required) validators.push(Validators.required);

  if (property.config.min) {
    const validator = createMinDateValidator(property.config.min);
    validators.push(validator);
  }

  if (property.config.max) {
    const validator = createMaxDateValidator(property.config.max);
    validators.push(validator);
  }

  return validators;
}

function getNumberValidators(property: Property.NumberModel, required: boolean) {
  const validators = [];
  if (required) validators.push(Validators.required);

  if (property.config.min !== undefined && property.config.min !== null) {
    validators.push(Validators.min(property.config.min));
  }

  if (property.config.max !== undefined && property.config.max !== null) {
    validators.push(Validators.max(property.config.max));
  }

  if (property.config.allowedValues?.length) {
    const validator = createAllowedValuesValidator(property.config.allowedValues);
    validators.push(validator);
  }

  return validators;
}

function getTextValidators(property: Property.TextModel, required: boolean) {
  const validators = [];
  if (required) validators.push(Validators.required);

  if (property.config.minLength !== undefined && property.config.minLength !== null) {
    validators.push(Validators.minLength(property.config.minLength));
  }

  if (property.config.maxLength !== undefined && property.config.maxLength !== null) {
    validators.push(Validators.maxLength(property.config.maxLength));
  }

  if (property.config.allowedValues?.length) {
    const validator = createAllowedValuesValidator(property.config.allowedValues);
    validators.push(validator);
  }

  if (property.config.format === 'email') {
    validators.push(Validators.email);
  }

  if (property.config.format === 'phone') {
    const validator = createPhoneValidator();
    validators.push(validator);
  }

  return validators;
}
