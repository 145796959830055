export enum ApiMode {
  LIVE = 'live',
  SANDBOX = 'sandbox',
}
export enum MultiTenancyConstants {
  PLATFORM_DB_CONNECTION_NAME = 'platform_db',
  TENANT_CONNECTION_NAME = 'tenant_default_db',

  TENANT_ID_HEADER_KEY = 'X-VSOLV-TID',
  APP_TYPE = 'X-VSOLV-APP-TYPE',
  API_MODE = 'X-API-MODE',

  TENANT_ID_LOCAL_STORAGE_KEY = 'VSOLV_TID',

  TENANT_INFO_LOCAL_STORAGE_KEY = 'VSOLV_TINFO',
  TENANT_INFO_EXPIRY_LOCAL_STORAGE_KEY = 'VSOLV_TINFO_EXPIRY',
}

export enum AppType {
  PLATFORM = 'PLATFORM',
  TENANT = 'TENANT',
  BASIC = 'BASIC',
}
