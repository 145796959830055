<vs-dialog
  [config]="{
      dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]'
    }"
  class=""
  (closed)="apiKeyItem=null; resetForm(); "
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,
        icon: 'pencil-01',
        iconTheme: 'primary',
        title: 'Edit API key',
        subtitle: '',
        showDivider: true
      }"
  ></vs-dialog-header>

  <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div id="dialog-content" class="pt-6">
      <div class="grid grid-cols-3 gap-4">
        <p class="col-span-1 text-sm text-gray-600 font-semibold">User</p>
        <p class="col-span-2 text-sm text-gray-400 font-normal truncate">
          {{_apiKeyItem?.user?.displayName | titlecase}}
        </p>
        <p class="col-span-1 text-sm text-gray-600 font-semibold">Last Used</p>
        <vs-badge class="col-span-2 justify-self-start" theme="info"
          >{{(_apiKeyItem?.lastUsed | date) || 'Key has not been used yet'}}</vs-badge
        >
      </div>
      <vs-divider class="flex my-4"></vs-divider>
      <div class="pt-2">
        <vs-form-field
          class="pb-4"
          theme="primary"
          appearance="responsive"
          [config]="{
        label: 'Key name'
      }"
        >
          <vs-input-container>
            <vs-icon slot="start" name="key-02"></vs-icon>
            <input placeholder="Key name" type="text" formControlName="name" />
            <vs-icon name="help-circle" slot="end" vsTooltip="Provide meaningful api-key name"></vs-icon>
          </vs-input-container>
          <span class="text-danger" slot="hint" *ngIf="form.controls.name.invalid && form.controls.name.touched"
            >Please enter a valid API key name.</span
          >
        </vs-form-field>
        <vs-form-field
          [config]="{
          label: { text: 'Expiration', for: 'date-input' },
          description: ''
        }"
        >
          <vs-input-container>
            <input
              #dateInput
              formControlName="expired"
              class="picker-indicator-hidden"
              appearance="date"
              placeholder="Select an expiration date"
            />
            <button
              vs-icon-button
              size="xs"
              appearance="clear"
              slot="end"
              [vsMenuTriggerFor]="dateMenu"
              #trigger="vsMenuTriggerFor"
            >
              <vs-icon vsTooltip="Open date picker" name="calendar" size="16"></vs-icon>
            </button>
          </vs-input-container>
          <ng-template #dateMenu>
            <vs-menu>
              <vs-date-picker vs-menu-item (selectionChanged)="populateDate($any($event), trigger);"></vs-date-picker>
            </vs-menu>
          </ng-template>
          <span
            class="text-danger"
            slot="hint"
            *ngIf="!form.get('expired')?.valid && (form.get('expired')?.dirty || form.get('expired')?.touched)"
            >Please choose a valid expiry date.</span
          >
        </vs-form-field>
      </div>
    </div>

    <div class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pt-6">
      <button vs-button class="w-full sm:w-auto" appearance="outline" [disabled]="_submitting" (click)="closeDialog()">
        Cancel
      </button>
      <button
        vs-button
        class="w-full sm:w-auto"
        theme="primary"
        (click)="confirm()"
        [disabled]="!inputIsValid() || !form.dirty  || _submitting"
      >
        Update API Key
        <vs-icon *ngIf="_submitting" name="refresh-cw-02" class="animate-spin"></vs-icon>
      </button>
    </div>
  </form>
</vs-dialog>
