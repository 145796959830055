<vs-form-field [config]="{ label: property.name + (required ? '' : ' (optional)') + ':' }" appearance="vertical">
  <!-- Input -->
  <ng-container *ngIf="property.config.format !== 'block'; else block">
    <vs-input-container>
      <vs-text-input class="w-full" #input [property]="property" [formControl]="formControl"></vs-text-input>
    </vs-input-container>

    <!-- Errors -->
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('required')">
      {{ property.name }} must have a value
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('email')">
      {{ property.name }} must be a valid email address
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('phone')">
      {{ property.name }} must be a valid phone number
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('minLength')">
      {{ property.name }} must have {{ property.config.minLength }} or more characters
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('maxLength')">
      {{ property.name }} must have {{ property.config.maxLength }} or less characters
    </span>
    <span
      class="text-danger"
      slot="hint"
      *ngIf="input.formControl.touched && input.formControl.hasError('allowedValues')"
    >
      {{ property.name }} must be one of {{ property.config.allowedValues!.join(', ') }}
    </span>
  </ng-container>

  <ng-template #block>
    <vs-text-input #input [property]="property" [formControl]="formControl"></vs-text-input>
    <!-- Errors -->
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('required')">
      {{ property.name }} must have a value
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('email')">
      {{ property.name }} must be a valid email address
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('phone')">
      {{ property.name }} must be a valid phone number
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('minLength')">
      {{ property.name }} must have {{ property.config.minLength }} or more characters
    </span>
    <span class="text-danger" slot="hint" *ngIf="input.formControl.touched && input.formControl.hasError('maxLength')">
      {{ property.name }} must have {{ property.config.maxLength }} or less characters
    </span>
    <span
      class="text-danger"
      slot="hint"
      *ngIf="input.formControl.touched && input.formControl.hasError('allowedValues')"
    >
      {{ property.name }} must be one of {{ property.config.allowedValues!.join(', ') }}
    </span>
  </ng-template>
</vs-form-field>
