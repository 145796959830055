import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { InfoCardComponent } from './components';

@NgModule({
  imports: [CommonModule, IconsModule, ThemingModule],
  declarations: [InfoCardComponent],
  exports: [InfoCardComponent],
})
export class InfoCardModule {}
