<section class="bg-base dark:bg-gray-900 h-screen flex items-center">
  <div class="py-8 px-4 mx-auto max-w-screen-md lg:py-16 lg:px-6">
    <div class="mx-auto max-w-screen-sm text-center">
      <div class="mx-auto w-fit m-4">
        <img class="w-[233px] h-[195ox]" src="assets/not-found.svg" />
      </div>

      <div class="text-left mx-auto">
        <p class="mb-6 text-lg tracking-tight text-primary-600">404 error</p>

        <p class="mb-6 text-6xl tracking-tight font-semibold text-gray-900 md:text-4xl dark:text-base">
          Whoops! We couldn't find what you're looking for.
        </p>

        <p class="mb-6 text-xl font-light text-gray-500 dark:text-gray-400">
          This page doesn’t exist. Return home or contact us for more help.
        </p>
      </div>

      <button vs-button theme="primary" class="flex-1" (click)="returnToHome()">Return Home</button>
    </div>
  </div>
</section>
