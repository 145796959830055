<ng-container *ngIf="propertyValues">
  <div class="flex flex-col gap-4">
    <vs-property-set-values
      [propertySetId]="propertySetId"
      [propertyValues]="propertyValues"
      [showHidden]="true"
    ></vs-property-set-values>
    <vs-map *ngIf="propertyValues['address'] as address" [address]="$any(address)"></vs-map>
  </div>
</ng-container>
