/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationQueryRequest } from '@vsolv/dev-kit/ngx';
import { ClaimLifecycleStep, Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, catchError, first, firstValueFrom, of } from 'rxjs';

@Injectable()
export class PolicyService {
  constructor(private http: HttpClient) {}

  private policy$ = new BehaviorSubject<Policy.Model | null>(null);

  private plan$ = new BehaviorSubject<Policy.Plan.Model | null>(null);

  getPolicyObservable() {
    return this.policy$.asObservable();
  }

  async refreshPolicy(policy?: Policy.Model | string) {
    if (policy) {
      const refreshedPolicy = await this.getOne(typeof policy === 'string' ? policy : policy.id);
      this.policy$.next(refreshedPolicy);
    } else {
      if (this.policy$.value) {
        const refreshedPolicy = await this.getOne(this.policy$.value.id);
        this.policy$.next(refreshedPolicy);
      }
    }
  }

  updateCustomerPortalConfig(props: Policy.UpdateCustomerPortalConfigRequest) {
    return this.http.post(`/api/policies/${props.policyId}/customer-portal-config`, props).pipe(first());
  }

  clearPolicy() {
    this.policy$.next(null);
  }

  async list(data: Policy.ListPoliciesQueryRequest) {
    return firstValueFrom(
      this.http
        .get<Policy.ListPoliciesQueryResponse>(`/api/policies`, {
          params: {
            page: data?.page || 1,
            limit: data?.limit || 10,
            ...(data.search && { search: data.search }),
            ...(data.status && { status: data.status }),
            ...(data.policyIds?.length && { policyIds: data.policyIds }),
            ...(data.propertySetIds?.length && { propertySetIds: data.propertySetIds }),
            ...(data.onlyLatestVersion !== undefined && { onlyLatestVersion: data.onlyLatestVersion }),
          },
        })
        .pipe(
          catchError(err => {
            console.log(err);
            throw err;
          })
        )
    );
  }

  async listForSales(
    pagination?: PaginationQueryRequest,
    propertySetIds?: string,
    status?: Policy.Status,
    policyIds?: string[],
    sessionId?: string
  ) {
    return firstValueFrom(
      this.http
        .get<Policy.ListPoliciesQueryResponse>(`/api/policies-sales`, {
          params: {
            page: pagination?.page || 1,
            limit: pagination?.limit || 10,
            ...(propertySetIds?.length && { propertySetIds }),
            ...(sessionId && { sessionId }),
            ...(status && { status }),
            ...(policyIds?.length && { policyIds }),
          },
        })
        .pipe(
          catchError(err => {
            console.log(err);
            throw err;
          })
        )
    );
  }

  async export(limit?: number) {
    return firstValueFrom(
      this.http.get<Policy.ExportPoliciesQueryResponse>(`/api/policies/export`, { params: { limit: limit || 50 } })
    );
  }

  async generateProvisionTemplate(policyId: string, planId?: string) {
    return firstValueFrom(
      this.http.get<Policy.ExportPoliciesQueryResponse>(`/api/policies/${policyId}/provision-template`, {
        params: { ...(planId && { planId }) },
      })
    );
  }

  async getOne(policyId: string) {
    return firstValueFrom(
      this.http.get<Policy.Model>(`/api/policies/${policyId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async getVersions(policyId: string) {
    return firstValueFrom(
      this.http.get<Policy.RetrievePolicyVersionsQueryResponse>(`/api/policies/${policyId}/versions`)
    );
  }

  async getWarrantyCounts(policyId: string) {
    return firstValueFrom(
      this.http
        .get<Policy.GetWarrantyCountsResponse>(`/api/policies/${policyId}/warranty_counts`)
        .pipe(catchError(() => of(null)))
    );
  }

  async getStats(policyId: string, body?: Policy.RetrievePolicyStatsQueryRequest) {
    return firstValueFrom(
      this.http
        .get<Policy.RetrievePolicyStatsQueryResponse>(`/api/policies/${policyId}/stats`, {
          params: {
            ...(body?.date && { date: body.date.toString() }),
          },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  async create(body: Policy.CreatePolicyRequest) {
    return await firstValueFrom(this.http.post<Policy.CreatePolicyResponse>(`/api/policies`, body));
  }

  async update(policyId: string, body: Policy.UpdatePolicyRequest) {
    return await firstValueFrom(this.http.patch<Policy.UpdatePolicyResponse>(`/api/policies/${policyId}`, body));
  }

  async publish(policyId: string) {
    return await firstValueFrom(
      this.http.post<Policy.PublishPolicyResponse>(`/api/policies/${policyId}/publish`, { policyId })
    );
  }

  async archive(policyId: string) {
    return await firstValueFrom(
      this.http.post<Policy.ArchivePolicyResponse>(`/api/policies/${policyId}/archive`, { policyId })
    );
  }

  async restore(policyId: string) {
    return await firstValueFrom(
      this.http.post<Policy.RestorePolicyResponse>(`/api/policies/${policyId}/restore`, { policyId })
    );
  }

  async duplicate(policyId: string, isNewVersion: boolean) {
    return await firstValueFrom(
      this.http.post<Policy.DuplicatePolicyResponse>(`/api/policies/${policyId}/duplicate`, { isNewVersion })
    );
  }

  async delete(policyId: string) {
    return await firstValueFrom(this.http.delete<Policy.DeletePolicyResponse>(`/api/policies/${policyId}`));
  }

  getPlanObservable() {
    return this.plan$.asObservable();
  }

  async refreshPlan(plan?: Policy.Plan.Model) {
    if (plan) {
      const refreshedPlan = await this.getPlan(plan.policyId, plan.id);
      this.plan$.next(refreshedPlan);
    } else {
      if (this.plan$.value) {
        const refreshedPlan = await this.getPlan(this.plan$.value.policyId, this.plan$.value.id);
        this.plan$.next(refreshedPlan);
      }
    }
  }

  clearPlan() {
    this.plan$.next(null);
  }

  async getPlan(policyId: string, planId: string) {
    return firstValueFrom(
      this.http.get<Policy.Plan.RetrievePolicyPlanQueryResponse>(`/api/policies/${policyId}/plans/${planId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async getPlans(policyId: string) {
    return firstValueFrom(
      this.http.get<Policy.Plan.ListPolicyPlansQueryResponse>(`/api/policies/${policyId}/plans`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async getPlanFees(policyId: string, planId: string, termId: string, pagination?: { page: number; limit: number }) {
    return firstValueFrom(
      this.http
        .get<Policy.PlanCoverageFee.ListPlanCoverageFeesQueryResponse>(
          `/api/policies/${policyId}/plans/${planId}/fees/${termId}`,
          {
            params: {
              page: pagination?.page ?? 1,
              limit: pagination?.limit ?? 100,
            },
          }
        )
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  async getPlanLiabilityGroups(policyId: string, planId: string, submissionId?: string) {
    return firstValueFrom(
      this.http
        .get<Policy.Plan.LiabilityGroup[]>(`/api/policies/${policyId}/plans/${planId}/groups`, {
          params: {
            ...(submissionId && { submissionId }),
          },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  async createPlan(policyId: string, body: Policy.Plan.CreatePolicyPlanRequest) {
    return await firstValueFrom(
      this.http.post<Policy.Plan.CreatePolicyPlanResponse>(`/api/policies/${policyId}/plans`, body)
    );
  }

  async updatePlan(planId: string, policyId: string, body: Policy.Plan.UpdatePolicyPlanRequest) {
    return await firstValueFrom(
      this.http.patch<Policy.Plan.UpdatePolicyPlanResponse>(`/api/policies/${policyId}/plans/${planId}`, body)
    );
  }

  async updatePlanTerm(
    planId: string,
    termId: string,
    policyId: string,
    body: Policy.Plan.UpdatePolicyPlanTermRequest
  ) {
    return await firstValueFrom(
      this.http.patch<Policy.Plan.UpdatePolicyPlanResponse>(`/api/policies/${policyId}/plans/${planId}/${termId}`, body)
    );
  }

  async setPlanVisibility(policyId: string, planId: string, visible: boolean) {
    return await firstValueFrom(
      this.http.patch<{ id: string }>(`/api/policies/${policyId}/plans/${planId}/set-visibility`, { visible: visible })
    );
  }

  async exportPlanCoverageConfigs(planId: string, policyId: string) {
    return await firstValueFrom(
      this.http.get<Policy.Plan.ExportPlanCoverageConfigsQueryResponse>(
        `/api/policies/${policyId}/plans/${planId}/export-coverage-configs`,
        {}
      )
    );
  }

  async deletePlan(planId: string, policyId: string) {
    return await firstValueFrom(
      this.http.delete<Policy.Plan.DeletePolicyPlanCommandResponse>(`/api/policies/${policyId}/plan/${planId}`, {
        body: { canCreateNewVersion: true },
      })
    );
  }

  async updateClaimLifecycleSteps(policyId: string, body: ClaimLifecycleStep.UpdateClaimLifecycleStepsRequest) {
    return await firstValueFrom(
      this.http.patch<ClaimLifecycleStep.UpdateClaimLifecycleStepsResponse>(
        `/api/policies/${policyId}/claim-lifecycle-steps`,
        body
      )
    );
  }

  async updateDocument(documentId: string, policyId: string, body: Policy.UpdatePolicyDocumentRequest) {
    return await firstValueFrom(
      this.http.patch<Policy.UpdatePolicyDocumentResponse>(`/api/policies/${policyId}/documents/${documentId}`, body)
    );
  }

  async updateEmail(emailId: string, policyId: string, body: Policy.UpdatePolicyEmailRequest) {
    return await firstValueFrom(
      this.http.patch<Policy.UpdatePolicyEmailResponse>(`/api/policies/${policyId}/emails/${emailId}`, body)
    );
  }
}
