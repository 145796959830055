<ng-template #label>
  <ng-content select="[slot='label']"></ng-content>

  <ng-container *ngIf="config?.endContent">
    <ng-container *ngTemplateOutlet="config?.endContent || null"></ng-container>
  </ng-container>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
