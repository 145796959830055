import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { map } from 'rxjs/operators';
import { NavItem } from '../nav-item';

export interface TabNavConfig {
  items?: Omit<NavItem, 'children'>[];
}

@Component({
  selector: 'vs-tab-nav',
  templateUrl: './tab-nav.component.html',
})
export class TabNavComponent {
  constructor(private breakpointObserver: BreakpointObserver) {}

  @Input() config!: TabNavConfig;

  isSmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => state.matches));
}
