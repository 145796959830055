import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { firstValueFrom } from 'rxjs';

@Component({ templateUrl: './under-maintenance.page.html' })
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class UnderMaintenancePage implements OnInit {
  constructor(private http: HttpClient, private router: Router) {}

  private portalSvc = inject(PortalService);

  async ngOnInit() {
    try {
      await firstValueFrom(this.http.get('/v1', { responseType: 'text' }));
      this.router.navigate(['']);
    } catch (err) {
      //noop
    }

    this.loadConfig();
  }

  loadConfig() {
    const localConfig = localStorage.getItem('config');
    if (localConfig) {
      this.portalSvc.setTheming(JSON.parse(localConfig));
    }
  }
}
