import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Payment } from '@vsolv/packages/payments/domain';
import { PaymentService } from '@vsolv/packages/payments/web';
import { ThemeColorEnum } from '@vsolv/vectors-ui/theming';
import { AssetService } from '@wsphere/assets/web';
import { SecurityService } from '@wsphere/staff/web';
import { WarrantyWebService } from '@wsphere/warranties/web';
import { UcFirstPipe } from 'ngx-pipes';
import { BehaviorSubject, catchError, distinctUntilChanged, map, of, shareReplay, switchMap, tap } from 'rxjs';

@Component({
  templateUrl: './payment.page.html',
})
export class StaffPaymentPage {
  constructor(private assetSvc: AssetService) {}
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private paymentSvc = inject(PaymentService);
  private warrantySvc = inject(WarrantyWebService);
  private securitySvc = inject(SecurityService);

  private _payment$ = new BehaviorSubject<Payment.Model | null>(null);
  protected payment$ = this.route.data.pipe(
    tap(() => this._payment$.next(null)),
    map(data => data['payment'] as Payment.Model),
    switchMap(payment => this._payment$.pipe(map(py => py ?? payment)))
  );

  protected warranty$ = this.payment$.pipe(
    switchMap(async payment => {
      return payment.referenceId ? await this.warrantySvc.getOneByContractNumber(payment.referenceId) : null;
    }),
    catchError(() => of(null)),
    distinctUntilChanged((p, n) => {
      return p?.id === n?.id && p?.status === n?.status;
    }),
    shareReplay(1)
  );

  canCancelWarranty$ = this.warranty$.pipe(
    switchMap(
      async warranty =>
        await this.securitySvc.hasAccess(
          'wrt_CancelWarranty',
          warranty?.distributor?.permissionKey ? [warranty.distributor.permissionKey] : null
        )
    )
  );

  protected paymentStatus$ = this.payment$.pipe(
    map(payment => ({
      label: new UcFirstPipe().transform(payment.status.replace(/_/g, ' ').toLowerCase()),
      theme: {
        [Payment.Status.DRAFT]: ThemeColorEnum.default,

        [Payment.Status.VOIDED]: ThemeColorEnum.default,
        [Payment.Status.PENDING]: ThemeColorEnum.warn,

        [Payment.Status.PAID]: ThemeColorEnum.success,
        [Payment.Status.FAILED]: ThemeColorEnum.danger,
        [Payment.Status.PROCESSING]: ThemeColorEnum.info,

        [Payment.Status.REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PARTIALLY_REFUNDED]: ThemeColorEnum.default,
        [Payment.Status.PROCESSING_REFUND]: ThemeColorEnum.info,
      }[payment.status],
    }))
  );

  async rowClicked(payment: Payment.Model) {
    await this.router.navigate(['..', payment.id], { relativeTo: this.route });
  }

  protected async refresh(paymentId: string) {
    const payment = await this.paymentSvc.retrieve(paymentId);
    this._payment$.next(payment);
  }
}
