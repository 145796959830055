import { IsNotEmpty, IsNumber } from 'class-validator';
import { Policy } from '../../policy';

export interface CoverageLiabilityInfo {
  limit: number;
  deductible: number;
  remainingCoverageItemLimit: number;
  remainingLiabilityLimit: number;
}

export interface CoverageGroupLiabilityInfo extends Policy.Plan.LiabilityGroup {
  remainingGroupLimit: number;
}

export interface LiabilityInfo extends CoverageLiabilityInfo {
  groups: CoverageGroupLiabilityInfo[];
}

export class CoverageLiabilityInfoDto {
  @IsNumber()
  @IsNotEmpty()
  limit!: number;

  @IsNumber()
  @IsNotEmpty()
  deductible!: number;

  @IsNumber()
  @IsNotEmpty()
  remainingCoverageItemLimit!: number;

  @IsNumber()
  @IsNotEmpty()
  remainingLiabilityLimit!: number;
}

export class CoverageGroupLiabilityInfoDto extends Policy.Plan.LiabilityGroupDto {
  @IsNumber()
  @IsNotEmpty()
  remainingGroupLimit!: number;
}

export class LiabilityInfoDto extends CoverageLiabilityInfoDto {
  groups!: CoverageGroupLiabilityInfoDto[];
}
