/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { extractErrors } from '@vsolv/dev-kit/ngx';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Property } from '@vsolv/packages/properties/domain';
import { BehaviorSubject, map, takeUntil } from 'rxjs';
import { PropertyInputComponent, createPropertyControl } from '../abstract-property-input.component';

@Component({
  selector: 'vs-object-input-field',
  templateUrl: './object-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ObjectInputFieldComponent,
    },
    { provide: NG_VALIDATORS, useExisting: ObjectInputFieldComponent, multi: true },
  ],
})
export class ObjectInputFieldComponent extends PropertyInputComponent implements OnInit {
  @PropertyListener('property') property$ = new BehaviorSubject(this.property);
  @Input() override property!: Property.ObjectModel;

  form!: FormGroup;
  controls: FormControl[] = [];

  open = true;

  assignments$ = this.property$.pipe(
    map(property => {
      return property.properties?.sort((a, b) => a.order - b.order) ?? [];
    })
  );

  override validate(): ValidationErrors | null {
    for (const control of this.controls) {
      const controlErrors = extractErrors(control);
      if (controlErrors) {
        this.valid = false;
        return controlErrors;
      }
    }
    this.valid = true;
    return null;
  }

  override ngOnInit() {
    super.ngOnInit();
    const assignments = this.property.properties?.sort((a, b) => a.order - b.order) ?? [];
    const controls: Record<string, FormControl> = {};

    assignments.forEach(assignment => {
      const control = createPropertyControl(assignment.property!, assignment.required);
      controls[assignment.property!.valueKey] = control;

      this.controls.push(control);
    });

    this.form = new FormGroup(controls);

    this.form.patchValue(this.value as any);

    if (this.disabled) this.form.disable();
    else this.form.enable();

    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => this.formControl.patchValue(value));
  }
}
