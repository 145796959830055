/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { FileInputDirective } from '@vsolv/vectors-ui/input';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { ReplaySubject, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';
import { ClaimLinkPickerComponent } from '../link-picker/link-picker.component';

export enum ClaimQuickAddType {
  NOTE = 'NOTE',
  DOCUMENT = 'DOCUMENT',
}

export interface QuickAddNote {
  title: string;
  content: string;
  links: Link.FormLink[];
}

export interface QuickAddAttachment {
  title: string;
  description: string;
  file: File;
  links: Link.FormLink[];
}

@Component({
  selector: 'ws-claim-quick-add',
  templateUrl: './claim-quick-add.component.html',
})
export class ClaimQuickAddComponent implements OnInit {
  constructor(
    private claimSvc: ClaimService,
    private toastSvc: ToastService,
    private linkSvc: LinksWebService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('fileInput') fileInput?: FileInputDirective;

  @ViewChild('noteLinks') linkPicker?: ClaimLinkPickerComponent;

  @PropertyListener('addType') addType$ = new ReplaySubject<ClaimQuickAddType | null>();
  @Input() addType: ClaimQuickAddType | null = null;

  @PropertyListener('claim') claim$ = this.claimSvc.getClaim();

  @Input() disabled = false;

  @Input() isCustomerPortal = false;

  @Input() isMyClaim: boolean | null = false;

  @Output() noteAdded = new EventEmitter();
  @Output() attachmentAdded = new EventEmitter();

  saving = false;
  showDescription = false;

  type = ClaimQuickAddType.NOTE;
  note = ClaimQuickAddType.NOTE;
  document = ClaimQuickAddType.DOCUMENT;

  linksAndConfigs?: { link: Link.FormLink; config: InfoCardConfig }[];

  noteForm = this.formBuilder.group({
    title: [''],
    content: ['', Validators.required],
    links: [[] as Link.FormLink[]],
  });

  documentForm = this.formBuilder.group({
    title: ['', Validators.maxLength(255)],
    description: [''],
    file: [null as File | null, [Validators.required]],
    links: [[] as Link.FormLink[]],
  });

  form = this.formBuilder.group({ ...this.documentForm, ...this.noteForm });

  canAddNotes$ = this.claim$.pipe(
    switchMap(async claim => {
      if (this.isMyClaim) return true;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageNote', permissionKey);
    })
  );

  canAddDocuments$ = this.claim$.pipe(
    switchMap(async claim => {
      if (this.isMyClaim) return true;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageAttachments', permissionKey);
    })
  );

  addTypeUpdated$ = this.addType$.pipe(tap(type => (type ? (this.type = type) : '')));

  getLinkConfig(link: Link.FormLink) {
    return this.linksAndConfigs?.find(
      linkAndConfig => (linkAndConfig.link.object as any)?.id === (link.object as any)?.id
    )?.config;
  }

  removeLink(objectId: string, type: 'note' | 'document') {
    if (type === 'note') {
      const links = this.noteForm.value.links?.filter(link => (link.object as any)?.id !== objectId);
      this.noteForm.patchValue({ links });
    } else if (type === 'document') {
      const links = this.documentForm.value.links?.filter(link => (link.object as any)?.id !== objectId);
      this.documentForm.patchValue({ links });
    }
  }

  async addNote(claimId?: string, note?: QuickAddNote) {
    this.saving = true;
    const value = note ?? this.noteForm.value;

    if (!claimId && !value.content) {
      this.saving = false;
      return;
    } else if (!claimId && value.content) {
      this.noteAdded.emit(value);
      this.noteForm.reset();
      this.saving = false;
    } else if (claimId && value.content) {
      const response = await this.claimSvc
        .createNote(claimId, {
          title: value.title ?? undefined,
          content: value.content,
          feed: ['staff'],
        })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
          this.saving = false;
        });

      if (response?.id) {
        await this.linkSvc.create(
          { id: claimId, objectType: Link.ObjectType.CLAIM },
          {
            object1: { id: claimId, objectType: Link.ObjectType.CLAIM },
            object2: { id: response.id, objectType: Link.ObjectType.NOTE },
          }
        );

        await this.claimSvc.refreshClaim();

        if (!note) {
          await this.updateNoteLinks(response.id, claimId);
          this.toastSvc.show({
            type: 'success',
            title: 'Note Added',
            text: `<strong>${value.title || 'The note'}</strong>` + ' has been successfully added.',
          });
          this.saving = false;
        }
      }
    }
  }

  async addAttachment(claimId?: string, attachment?: QuickAddAttachment) {
    const value = attachment ?? this.documentForm.value;

    if (!claimId && !value.file) return;
    else if (!claimId && value.file) {
      this.attachmentAdded.emit(value);
      this.documentForm.reset();
    } else if (claimId && value.file) {
      const filename = value.file.name;

      const response = await this.claimSvc
        .createDocument(claimId, {
          file: value.file,
          title: value.title ? value.title : filename,
          description: value.description ?? '',
          feed: this.isCustomerPortal ? ['customer', 'staff'] : ['staff'],
        })
        .catch(error => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
        });

      if (response) {
        await this.claimSvc.refreshClaim();
        if (!attachment) {
          await this.updateAttachmentLinks(response, claimId);

          this.toastSvc.show({
            type: 'success',
            title: 'Attachement Added',
            text: `<strong>${value.title || filename || 'The attachment'}</strong>` + ' has been successfully added.',
          });
        }
      }
    }
  }

  async updateNoteLinks(noteId: string, claimId: string) {
    const links = this.noteForm.value.links;

    if (links?.length) {
      await Promise.all(
        links.map(async link => {
          await this.linkSvc.create(
            { id: claimId, objectType: Link.ObjectType.CLAIM },
            {
              object1: { id: (link.object as any).id, objectType: link.type },
              object2: { id: noteId, objectType: Link.ObjectType.NOTE },
            }
          );
        })
      );
    }

    this.noteForm.reset();
  }

  async updateAttachmentLinks(documentId: string, claimId: string) {
    const links = this.documentForm.value.links;

    if (links?.length) {
      await Promise.all(
        links.map(async link => {
          await this.linkSvc.create(
            { id: claimId, objectType: Link.ObjectType.CLAIM },
            {
              object1: { id: (link.object as any).id, objectType: link.type },
              object2: { id: documentId, objectType: Link.ObjectType.STORAGE_ITEM },
            }
          );
        })
      );
    }

    this.clearFile();
    this.documentForm.reset();
  }

  onFileAdded(event: any) {
    this.documentForm.patchValue({ file: event.target.files[0] });
  }

  async clearFile() {
    this.fileInput?.clear();
    this.documentForm.patchValue({ file: null });
  }

  ngOnInit(): void {
    if (!this.addType) this.claimSvc.clearClaim();
  }
}
