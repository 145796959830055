/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { BadgeColumn, TableColumn, TextColumn, UserInfoColumn } from '@vsolv/vectors/table';
import { SecurityService } from '@wsphere/staff/web';
import { Claim, Coverage, Policy } from '@wsphere/warranties/domain';
import { map, switchMap } from 'rxjs';
import { ClaimService } from '../../../claim/services';
import { DeleteCoverageDialog, EditPolicyType, PolicyDialog } from '../../dialogs';
import { CoverageService, PolicyService } from '../../services';

@Component({
  templateUrl: './coverage.page.html',
})
export class CoveragePage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimsSvc: ClaimService,
    private policySvc: PolicyService,
    private securitySvc: SecurityService,
    private coverageSvc: CoverageService
  ) {}

  @ViewChild('editCoverageDialog') editCoverageDialog?: PolicyDialog;
  @ViewChild('deleteCoverageDialog') deleteCoverageDialog?: DeleteCoverageDialog;

  policy$ = this.policySvc.getPolicyObservable();
  coverage$ = this.coverageSvc.getCoverageObservable();

  draft = Policy.Status.DRAFT;
  published = Policy.Status.PUBLISHED;
  editType = EditPolicyType.COVERAGE;

  coverageStats: Coverage.Stats[] | null = null;

  totalClaims = 0;
  claimsTableData: Claim.ListClaimsQueryResponse | null = null;

  canEdit$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_Edit', permissionKeys);
    })
  );

  canViewClaims$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('clm_ViewClaim', permissionKeys);
    })
  );

  canCreateClaims$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('clm_OpenClaim', permissionKeys);
    })
  );

  claims$ = this.coverage$.pipe(
    switchMap(async coverage => {
      if (!coverage) return { items: [] as Claim.Model[], meta: null as any };
      return await this.claimsSvc.list({ coverageId: coverage.id });
    }),
    map(claims => claims.items)
  );

  columns: TableColumn<unknown>[] = [
    new TextColumn({ header: 'Number', sortable: false }, (claim: Claim.Model) => {
      const numberParts = claim.claimNumber.split('-');
      const text =
        numberParts.length === 3
          ? numberParts[0] + '-...' + numberParts[numberParts.length - 1].substring(6)
          : numberParts[0] +
            '-...' +
            numberParts[numberParts.length - 2].substring(6) +
            '-' +
            numberParts[numberParts.length - 1];

      return { text, classes: 'whitespace-nowrap' };
    }),
    new UserInfoColumn({ header: 'Customer', sortable: false }, (claim: Claim.Model) => ({
      name: claim?.warranty?.customer?.name,
      email: claim?.warranty?.customer?.email,
      photoUrl: claim?.warranty?.customer?.user?.photoURL,
    })),
    new BadgeColumn({ header: 'Status', sortable: false }, (claim: Claim.Model) => ({
      text: claim.status[0] + claim.status.substring(1),
      theme:
        claim.status === Claim.Status.CLOSED
          ? 'default'
          : claim.status === Claim.Status.OPEN
          ? 'info'
          : claim.status === Claim.Status.EXPIRED
          ? 'danger'
          : claim.status === Claim.Status.CANCELLED
          ? 'warn'
          : 'default',
    })),
  ];

  async refreshCoverageStats(coverageId: string) {
    this.coverageStats = (await this.coverageSvc.listStats(coverageId))?.stats || null;
    if (!this.coverageStats.length) this.coverageStats = null;

    if (this.coverageStats?.[0]) {
      this.totalClaims =
        this.coverageStats[0].closedClaims +
        this.coverageStats[0].openClaims +
        this.coverageStats[0].expiredClaims +
        this.coverageStats[0].cancelledClaims;
    }
  }

  async refreshClaims(coverageId: string) {
    this.claimsTableData = await this.claimsSvc.list({ coverageId });
  }

  async createClaimItem(claimId: string, coverageId: string) {
    await this.claimsSvc
      .createItem(claimId, {
        coverageId: coverageId,
        description: null,
      })
      .catch((err: { error: { message: any } }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: `${err?.error?.message}`,
        });
      });
  }

  navigateTo(path?: string) {
    if (path) this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
