<ng-container *ngIf="policy$ | async as policy">
  <vs-app-page
    *vsLet="versions$ | async as versions"
    class="h-[100%]"
    [config]="{ 
    title: policy?.title || 'Policy', 
    description: 'Review and manage the details, associated documents, and default communications for this policy.', 
    icon: $any(policy?.icon) ?? 'file-shield-02'
  }"
  >
    <vs-breadcrumbs
      slot="breadcrumbs"
      [config]="{ items: [{ label: 'Policies', routerLink: '..' }, { label: policy?.title || 'Policy' }] }"
    ></vs-breadcrumbs>

    <vs-badge
      slot="title-end"
      [theme]="policy?.status === draft
        ? 'info'
        : policy?.status === published
        ? 'success'
        : policy?.status === archived
        ? 'accent'
        : policy?.status === runoff
        ? 'warn'
        : 'default'"
    >
      {{ policy?.status | titlecase }}
    </vs-badge>

    <div class="flex gap-2" slot="header-end">
      <vs-input-group>
        <button
          *ngIf="canManageStatus$ | async"
          vs-button
          class="flex-grow"
          appearance="outline"
          tooltipStyle="light"
          [class]="!policy?.plans?.length ? '!text-gray-300 !cursor-default' : ''"
          [vsTooltip]="
            policy?.status === draft && !policy?.plans?.length 
              ? { title: 'Unable to publish policy', subtitle: 'To publish this policy, at least one plan must be created and all plans must have a complete pricing table.'} 
              : ''
          "
          (click)="
            policy?.plans?.length 
              ? policy?.status === draft 
              ? publishDialog.open() 
              : policy?.status === published 
              ? archiveDialog.open() 
              : restoreDialog.open() 
              : ''
          "
        >
          {{ policy?.status === draft ? 'Publish' : policy?.status === published ? 'Archive' : 'Restore' }}
        </button>

        <button
          *ngIf="policy?.status === draft && (canManageStatus$ | async)"
          vs-button
          appearance="outline"
          (click)="deleteDialog.open()"
        >
          Delete
        </button>

        <button
          *ngIf="(versions?.length || 0) > 1"
          vs-icon-button
          class="px-3 py-2"
          appearance="outline"
          [vsMenuTriggerFor]="versionMenu"
        >
          <vs-icon name="clock-rewind" size="18"></vs-icon>
        </button>
      </vs-input-group>

      <button vs-icon-button class="px-3 py-2" appearance="outline" [vsMenuTriggerFor]="optionsMenu">
        <vs-icon name="dots-vertical" size="18"></vs-icon>
      </button>
    </div>

    <ng-template #versionMenu>
      <vs-menu class="mt-2">
        <button
          *ngFor="let version of versions"
          vs-menu-item
          class="flex"
          [disabled]="version.id === policy?.id"
          (click)="switchToVersion(version.id)"
        >
          <div
            class="h-2 w-2 rounded-full"
            slot="start"
            [class.bg-info-500]="version.status === draft"
            [class.bg-success-500]="version.status === published"
            [class.bg-accent-500]="version.status === archived"
            [class.bg-warn-500]="version.status === runoff"
          ></div>

          <p class="font-medium text-sm">{{ version.policyNumber }}</p>
          <p class="font-normal text-xs">Last modified: {{ version.modified | date }}</p>

          <vs-icon
            size="20"
            slot="end"
            [name]="version.status === draft
                ? 'file-plus-02'
                : version.status === published
                ? 'file-check-02'
                : 'archive'
              "
          ></vs-icon>
        </button>
      </vs-menu>
    </ng-template>

    <ng-template #optionsMenu>
      <vs-menu class="mt-4 -mr-2" *vsLet="canCreate$ | async as canCreate">
        <button
          vs-menu-item
          tooltipStyle="light"
          (click)="policy?.status === draft ? $event.preventDefault() : linkToPolicyDialog.openDialog()"
          [disabled]="policy?.status === draft"
          [vsTooltip]="policy?.status === draft ? 'Publish this policy in order to create a link for it!' : ''"
        >
          <div class="flex gap-2 text-base !text-gray-700">
            <vs-icon name="link-01" size="18" class="self-center"></vs-icon>
            <span class="self-center">Create link to policy</span>
          </div>
        </button>

        <button
          *ngIf="policy && canCreate"
          vs-menu-item
          tooltipStyle="light"
          (click)="duplicatePolicy(policy.id)"
          vsTooltip="Duplicate this policy as an entirely new policy"
        >
          <div class="flex gap-2 text-base !text-gray-700">
            <vs-icon name="copy-01" size="18" class="self-center"></vs-icon>
            <span class="self-center">Duplicate policy</span>
          </div>
        </button>
      </vs-menu>
    </ng-template>

    <div class="mb-4">
      <vs-tab-nav *vsLet="tabs$ | async as tabs" theme="primary" [config]="{ items: tabs ?? [] }"> </vs-tab-nav>
    </div>
    <router-outlet></router-outlet>
  </vs-app-page>

  <ws-publish-policy-dialog #publishDialog [policy]="policy"></ws-publish-policy-dialog>
  <ws-archive-policy-dialog #archiveDialog [policy]="policy"></ws-archive-policy-dialog>
  <ws-restore-policy-dialog #restoreDialog [policy]="policy"></ws-restore-policy-dialog>
  <ws-delete-policy-dialog #deleteDialog [policy]="policy"></ws-delete-policy-dialog>
  <ws-link-to-policy #linkToPolicyDialog [policy]="policy"></ws-link-to-policy>
</ng-container>
