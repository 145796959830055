import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { AppCardConfig } from '../../components';

@Component({ selector: 'ws-app-card-dialog', templateUrl: './app-card.dialog.html' })
export class AppCardDialog {
  @Input() config!: AppCardConfig;

  @Input() enabled!: boolean;
  @Output() enabledChange = new EventEmitter<boolean>();

  @Output() dialogOpened = new EventEmitter<void>();
  @Output() dialogClosed = new EventEmitter<void>();

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  openDialog() {
    this.dialog.open();
    this.dialogOpened.emit();
  }

  closeDialog() {
    this.dialog.close();
    this.dialogClosed.emit();
  }
}
