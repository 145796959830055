<ng-template #selected>
  <div class="selected_outer">
    <div class="selected_inner">
      <vs-icon name="circle" type="solid" size="10"> </vs-icon>
    </div>
  </div>
</ng-template>
<ng-template #complete>
  <div class="completed">
    <vs-icon name="check" size="20"> </vs-icon>
  </div>
</ng-template>
<ng-template #incomplete>
  <div class="incomplete">
    <vs-icon name="circle" type="solid" size="10"> </vs-icon>
  </div>
</ng-template>

<div class="flex flex-col text-base-white">
  <button
    [disabled]="!config?.customerCompleted"
    appearance="clear"
    (click)="goToStep(1)"
    class="flex flex-row items-center space-x-4"
  >
    <div class="flex" *ngIf="config?.customerCompleted === true; else incomplete">
      <div *ngIf="currentStep !== 1; else selected"><ng-container *ngTemplateOutlet="complete"></ng-container></div>
    </div>
    <p>Personal info</p>
  </button>
  <div class="line"></div>
  <button
    [disabled]="!config?.policyCompleted"
    appearance="clear"
    (click)="goToStep(2)"
    class="flex flex-row items-center space-x-4"
  >
    <div class="flex" *ngIf="config?.policyCompleted === true; else incomplete">
      <div *ngIf="currentStep !== 2; else selected">
        <ng-container *ngTemplateOutlet="complete"></ng-container>
      </div>
    </div>
    <p>Policy</p>
  </button>

  <div class="line"></div>
  <button
    [disabled]="!config?.assetCompleted"
    appearance="clear"
    (click)="goToStep(3)"
    class="flex flex-row items-center space-x-4"
  >
    <div class="flex" *ngIf="config?.assetCompleted === true; else incomplete">
      <div *ngIf="currentStep !== 3; else selected"><ng-container *ngTemplateOutlet="complete"></ng-container></div>
    </div>
    <p>Asset info</p>
  </button>

  <div class="line"></div>
  <button
    [disabled]="!config?.planCompleted"
    appearance="clear"
    (click)="goToStep(5)"
    class="flex flex-row items-center space-x-4"
  >
    <div class="flex" *ngIf="config?.planCompleted === true; else incomplete">
      <div *ngIf="currentStep !== 5; else selected"><ng-container *ngTemplateOutlet="complete"></ng-container></div>
    </div>
    <p>Plans</p>
  </button>

  <div class="line"></div>
  <button
    [disabled]="!config?.checkoutCompleted"
    appearance="clear"
    (click)="goToStep(6)"
    class="flex flex-row items-center space-x-4"
  >
    <div *ngIf="config?.checkoutCompleted === true; else incomplete">
      <div *ngIf="currentStep !== 6; else selected">
        <ng-container *ngTemplateOutlet="complete"></ng-container>
      </div>
    </div>
    <p>Checkout</p>
  </button>
</div>
