/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Coverage, Policy } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-edit-plan-condition-dialog',
  templateUrl: './edit-plan-condition.dialog.html',
})
export class EditPlanConditionDialog {
  constructor(private formBuilder: FormBuilder) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() type = '';
  @Input() propertySet!: PropertySet.Model;
  @Input() plan?: Policy.Plan.Model | null;
  @Input() coverage: Coverage.Model | null = null;
  @Input() rule: Conditions.Rule<string | number | string[]> | null = null;

  @Output() closed = new EventEmitter<Conditions.Rule<string | number | string[]> | null>();

  valid = false;
  saving = false;

  form = this.formBuilder.group({
    rule: [
      { blocks: [], defaultValue: '', valuePropertyPath: null as string | null } as Conditions.Rule<
        string | number | string[]
      >,
    ],
  });

  openDialog() {
    this.dialog.open();
    if (typeof this.rule?.defaultValue === 'number') {
      this.form.patchValue({ rule: { ...this.rule, defaultValue: this.rule.defaultValue / 100 } as any });

      this.form.value.rule?.blocks.forEach(block => {
        if (typeof block.value === 'number') block.value = (block.value as number) / 100;
      });
    } else this.form.patchValue({ rule: this.rule });
  }

  closeDialog() {
    this.dialog.close();
    this.saving = false;
  }

  cancel() {
    this.closeDialog();
    this.closed.emit();
    this.saving = false;
  }

  async updateCondition() {
    this.saving = true;

    if (!this.form.value.rule) throw new Error('Unable to update condition');

    if (this.type === 'number' || typeof this.form.value.rule?.defaultValue === 'number') {
      this.form.value.rule.defaultValue = (this.form.value.rule.defaultValue as number) * 100;
      this.form.value.rule.blocks.forEach(block => (block.value = (block.value as number) * 100));
    }

    this.closed.emit(this.form.value.rule);
    this.closeDialog();
    this.saving = false;
  }
}
