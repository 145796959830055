<ng-container *vsLet="claim$ | async as claim">
  <div *ngIf="item$ | async as item" class="flex flex-col gap-4">
    <div *vsLet="isCustomerPortal$ | async as isCustomerPortal">
      <p class="font-medium text-gray-500 mb-2">Resolution Details</p>

      <div class="flex flex-col gap-4 border rounded-xl p-6">
        <div class="flex gap-2">
          <p class="text-sm font-medium text-gray-700 basis-1/4">Resolution</p>
          <p class="text-sm font-normal text-gray-600 basis-3/4 flex gap-4">
            {{ item.resolution?.title }}
            <vs-badge [theme]="badgeTheme[item.status]" [config]="{ displayStatusIcon: true }">
              {{ item.status === draft ? 'Reviewing' : (item.status | titlecase) }}
            </vs-badge>
          </p>
        </div>

        <vs-divider></vs-divider>

        <div class="flex gap-2">
          <p class="text-sm font-medium text-gray-700 basis-1/4">Description</p>
          <p class="text-sm font-normal text-gray-600 basis-3/4">{{ item.resolution?.description }}</p>
        </div>

        <vs-divider></vs-divider>

        <div class="flex gap-2" *ngIf="!isCustomerPortal">
          <p class="text-sm font-medium text-gray-700 basis-1/4 flex gap-2">
            Actual Cost
            <vs-icon
              name="info-circle"
              tooltipStyle="light"
              class="self-center text-lg text-gray-500"
              [vsTooltip]="{
                title: 'Actual Cost',
                subtitle:
                  'This is the amount that was needed to complete the cure for this claim item. Usually this value comes from the receipt of the customer\'s repair.',
                styled: true
              }"
            ></vs-icon>
          </p>

          <p class="text-sm font-normal text-gray-600 basis-3/4">
            {{ (item.resolution?.actualCost || 0) / 100 | currency }}
          </p>
        </div>

        <vs-divider *ngIf="!isCustomerPortal"></vs-divider>

        <div class="flex gap-2">
          <p class="text-sm font-medium text-gray-700 basis-1/4 flex gap-2 whitespace-nowrap">
            Covered Amount
            <vs-icon
              name="info-circle"
              tooltipStyle="light"
              class="self-center text-lg text-gray-500"
              [vsTooltip]="{
                title: 'Covered Amount',
                subtitle:
                  'This is the amount that will be covered by the customer\'s warranty. This amount is calculated by taking the lower value of the remaining limit of liability or the actual cost minus the deductible.',
                styled: true
              }"
            ></vs-icon>
          </p>

          <p class="text-sm font-normal text-gray-600 basis-3/4">{{ (item.amount?.standard || 0) / 100 | currency }}</p>
        </div>

        <vs-divider *ngIf="item?.amount?.goodwill"></vs-divider>

        <div class="flex gap-2" *ngIf="item?.amount?.goodwill">
          <p class="text-sm font-medium text-gray-700 basis-1/4 flex gap-2 whitespace-nowrap">
            Goodwill Amount
            <vs-icon
              name="info-circle"
              tooltipStyle="light"
              class="self-center text-lg text-gray-500"
              [vsTooltip]="{
                title: 'Goodwill Amount',
                subtitle:
                  'This is the amount that will be covered by the administrator as an act of goodwill towards this customer.',
                styled: true
              }"
            ></vs-icon>
          </p>

          <p class="text-sm font-normal text-gray-600 basis-3/4">{{ (item.amount?.goodwill || 0) / 100 | currency }}</p>
        </div>
      </div>

      <div
        *vsLet="canManageResolution$ | async as canManageResolution"
        class="flex gap-2 mt-4"
        [class.justify-end]="claim?.status !== open || !canManageResolution || item.deleted"
        [class.justify-between]="claim?.status === open && canManageResolution && !item.deleted"
      >
        <div
          tooltipStyle="light"
          [vsTooltip]="
            !item.cure?.title
              ? {
                  title: 'Cure Needed',
                  subtitle: 'A fully finished cure is needed before you can resolve this claim item!'
                }
              : ''
          "
        >
          <button
            *ngIf="!item.deleted && canManageResolution && claim?.status === open"
            vs-button
            appearance="outline"
            [disabled]="!item.cure?.title"
            (click)="navigateTo('manage-resolution')"
          >
            Edit
          </button>
        </div>

        <div class="flex gap-2">
          <button
            vs-button
            appearance="outline"
            (click)="isCustomerPortal ? navigateTo('cure') : navigateTo('adjudicate')"
          >
            Back
          </button>
          <button vs-button theme="primary" (click)="close('..')">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
