import { CdkMenu, CdkTargetMenuAim } from '@angular/cdk/menu';
import { Component, HostBinding } from '@angular/core';

@Component({
  selector: 'vs-menu',
  templateUrl: './menu.component.html',
  hostDirectives: [CdkMenu, CdkTargetMenuAim],
})
export class MenuComponent {
  @HostBinding('class') private get _class() {
    return `max-h-[75vh] scrollbar-thin bg-base overflow-hidden overflow-y-auto rounded-lg border border-gray-200 drop-shadow-md py-2 [&>vs-divider]:block [&>vs-divider]:!my-0.5`;
  }
}
