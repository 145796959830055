import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from '@vectorsolv/vectors-ui/file-input';
import { AddressModule } from '@vsolv/core/address/web';
import { PackagesEmailWebModule } from '@vsolv/packages/email/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DatePickerModule } from '@vsolv/vectors-ui/date-picker';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewModule } from '@vsolv/vectors-ui/image-preview';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import { SftpWebModule } from '@wsphere/sftp/web';
import { NgxCurrencyModule } from 'ngx-currency';
import {
  ApiKeysTableComponent,
  AppCardComponent,
  CustomerPortalDetailsComponent,
  NotFoundComponent,
} from './components';
import {
  AppCardDialog,
  AuthorizeNetDialog,
  DecodedKeyPreviewDialog,
  EditApiKeyDialog,
  EmailDocumentDialog,
  GenerateApiKeyDialog,
  ManageWebhooksDialog,
  RollApiKeyDialog,
  SftpDialog,
  TaxJarDialog,
} from './dialogs';
import { ChatbotDialog } from './dialogs/chatbot-dialog';
import { DeleteApiKeyDialog } from './dialogs/delete-api-key-dialog';
import { AppsPage, DeveloperPage, OrganizationPage } from './pages';

@NgModule({
  imports: [
    CommonModule,
    AddressModule,
    RouterModule,
    CardModule,
    IconsModule,
    ThemingModule,
    BadgeModule,
    DialogModule,
    DividerModule,
    NgxCurrencyModule,
    ReactiveFormsModule,
    FormsModule,
    IconsModule,
    InputModule,
    SelectModule,
    TableModule,
    ButtonModule,
    IconsModule,
    CardModule,
    InputModule,
    ImagePreviewModule,
    ThemingModule,
    ButtonModule,
    ThemingModule,
    TabsModule,
    UtilsModule,
    AlertModule,
    DividerModule,
    TooltipModule,
    UserAvatarModule,
    DatePickerModule,
    MenuModule,
    ClipboardModule,
    PackagesEmailWebModule,
    SftpWebModule,
    FileUploadModule,
  ],
  declarations: [
    NotFoundComponent,
    ApiKeysTableComponent,
    DeleteApiKeyDialog,
    EditApiKeyDialog,
    GenerateApiKeyDialog,
    RollApiKeyDialog,
    DeveloperPage,
    OrganizationPage,
    AppsPage,
    CustomerPortalDetailsComponent,
    DecodedKeyPreviewDialog,
    ManageWebhooksDialog,
    AppCardComponent,
    EmailDocumentDialog,
    AuthorizeNetDialog,
    ChatbotDialog,
    AppCardDialog,
    SftpDialog,
    TaxJarDialog,
  ],
  providers: [],
  exports: [DeveloperPage, OrganizationPage],
})
export class TenantsWebModule {}
