import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, HostBinding, Input, NgZone, ViewChild } from '@angular/core';
import { IOutputData, SplitComponent } from 'angular-split';
import { AppPageConfig } from '../app-page/app-page.component';

export interface AppSplitPageConfig extends AppPageConfig {
  appPageClass?: string;

  left?: {
    collapseThreshold: number;
    width: number;
    maxWidth?: number;
  };
  right?: {
    collapseThreshold: number;
    width: number;
    maxWidth?: number;
  };
}

// This component leverages: https://angular-split.github.io/
@Component({
  selector: 'vs-app-split-page',
  templateUrl: './app-split-page.component.html',
  styleUrls: ['./app-split-page.component.scss'],
})
export class AppSplitPageComponent implements AfterViewInit {
  @HostBinding('class') styles = 'bg-base-light';
  @Input() config!: AppSplitPageConfig;

  @ViewChild('split') split?: SplitComponent;

  leftCollapsed = false;
  leftShouldClose = false;
  transitioningLeft = false;
  @ViewChild('leftButtonOverlay') leftButtonOverlay?: CdkConnectedOverlay;

  rightCollapsed = false;
  rightShouldClose = false;
  transitioningRight = false;
  @ViewChild('rightButtonOverlay') rightButtonOverlay?: CdkConnectedOverlay;

  constructor(private ngZone: NgZone) {}

  ngAfterViewInit() {
    this.split?.dragProgress$.subscribe(progress => {
      // LEFT
      if (progress.gutterNum === 1 && this.config?.left) {
        this.leftButtonOverlay?.overlayRef.updatePosition();
        if (this.config.left?.collapseThreshold && progress.sizes[0] <= this.config.left.collapseThreshold)
          this.ngZone.run(() => (this.leftShouldClose = true));
        else this.ngZone.run(() => (this.leftShouldClose = false));
      }
      // RIGHT
      if (progress.gutterNum === 2 || (progress.gutterNum === 1 && (!this.config.left || this.leftCollapsed))) {
        this.rightButtonOverlay?.overlayRef.updatePosition();
        if (
          this.config.right?.collapseThreshold &&
          progress.sizes[this.config?.left && !this.leftCollapsed ? 2 : 1] <= this.config.right.collapseThreshold
        )
          this.ngZone.run(() => (this.rightShouldClose = true));
        else this.ngZone.run(() => (this.rightShouldClose = false));
      }
    });
  }

  checkClose(event: IOutputData) {
    if (this.leftShouldClose) {
      this.leftCollapsed = true;
      this.leftShouldClose = false;
      if (event.sizes[0] > 0) this.transitioningLeft = true;
    }
    if (this.rightShouldClose) {
      this.rightCollapsed = true;
      this.rightShouldClose = false;
      if (event.sizes[this.config?.left && !this.leftCollapsed ? 2 : 1] > 0) this.transitioningRight = true;
    }
  }
}
