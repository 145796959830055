<div *vsLet="typesAndObjects$ | async as groups" class="flex flex-col gap-2 w-full">
  <div #container class="w-full">
    <vs-input-container
      #trigger="cdkOverlayOrigin"
      vs-button
      cdkOverlayOrigin
      [class.max-w-fit]="asButton"
      [class.rounded-t-xl]="!asButton && overlayOpen"
      [class.rounded-b-none]="!asButton && overlayOpen"
      (click)="toggle()"
    >
      <vs-icon *ngIf="asButton" name="link-03" class="self-center"></vs-icon>

      <div *ngIf="!asButton" class="flex justify-center gap-4 items-center w-full">
        <vs-icon class="self-center" name="link-03"></vs-icon>
        <p class="font-semibold text-gray-500">{{ placeholder }}</p>
        <vs-icon class="self-center" [name]="overlayOpen ? 'chevron-up' : 'chevron-down'"></vs-icon>
      </div>
    </vs-input-container>
  </div>

  <ng-template
    #overlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayOpen"
    (detach)="close()"
    (overlayOutsideClick)="$event.stopImmediatePropagation(); close()"
  >
    <vs-card
      class="flex flex-col max-h-56 pb-2 overflow-x-hidden overflow-y-auto scrollbar-thin"
      [class.rounded-t-none]="!asButton"
      [style.width]="asButton ? '400px' : container.clientWidth + 'px'"
      (click)="overlay.overlayRef.updatePosition()"
    >
      <vs-input-container class="!rounded-none !border-0">
        <vs-icon slot="start" name="search-lg"></vs-icon>
        <input #input type="text" placeholder="Search" (input)="search$.next(input.value)" />
      </vs-input-container>

      <ng-container *ngIf="!selectedGroup">
        <ng-container *ngIf="groups?.length; else noGroups">
          <div
            *ngFor="let group of groups"
            class="flex justify-between items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
            (click)="selectedGroup = group"
          >
            <p class="font-medium text-gray-900 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap">
              {{ group.type.replace('I', ' I').replace('C', ' C') | titlecase }}
            </p>

            <vs-icon name="chevron-right"></vs-icon>
          </div>
        </ng-container>

        <ng-template #noGroups>
          <p class="font-medium text-gray-900 max-w-[300px] p-2">
            This item has no other items that it can be linked to yet!
          </p>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="selectedGroup">
        <div class="flex flex-col">
          <div class="flex gap-2 items-center px-4 py-2 hover:bg-gray-50 cursor-pointer" (click)="selectedGroup = null">
            <vs-icon name="chevron-left" (click)="selectedGroup = null"></vs-icon>
            <p class="text-sm font-semibold text-gray-700">
              {{ selectedGroup.type.replace('I', ' I').replace('C', ' C') | titlecase }}
            </p>
          </div>

          <div
            *ngFor="let object of selectedGroup.objects"
            class="flex gap-2 items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
            (click)="selectValueChanged(object, selectedGroup.type)"
          >
            <input vsInput type="checkbox" [checked]="selectedCheck(object)" />
            <p
              class="text-sm font-medium text-gray-700 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              {{ getObjectTitle(object, selectedGroup.type) }}
            </p>
          </div>
        </div>
      </ng-container>
    </vs-card>
  </ng-template>

  <ng-container *vsLet="linksAndConfigs$ | async as linksAndConfigs">
    <div *ngIf="!asButton && linksAndConfigs?.length" class="flex flex-wrap gap-2 mt-4">
      <vs-info-card *ngFor="let group of linksAndConfigs" class="flex-grow max-w-[256px]" [config]="group.config">
        <ng-container *vsLet="group.link.object as item">
          <vs-badge *ngIf="$any(item)?.claimId" slot="header-end" [theme]="getItemTheme($any(item))">
            {{ $any(item)?.status | titlecase }}
          </vs-badge>
        </ng-container>

        <button
          vs-icon-button
          slot="end"
          appearance="clear"
          (click)="selectValueChanged(group.link.object, group.link.type)"
        >
          <vs-icon name="x" size="lg"></vs-icon>
        </button>
      </vs-info-card>
    </div>
  </ng-container>
</div>
