import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Dashboard } from '@vsolv/packages/dashboarding/domain';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs';
import { BuiltInModel, DashboardService } from '../../services';

@Pipe({ name: 'callWithInjector' })
export class CallWithInjectorPipe implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(value: (injector: Injector) => Promise<unknown>) {
    return value(this.injector);
  }
}

@Pipe({ name: 'injectorWithInputs' })
export class InjectorWithInputs implements PipeTransform {
  constructor(private injector: Injector) {}

  transform(inputs: object) {
    return Injector.create({
      providers: [{ provide: 'inputs', useValue: inputs }],
      parent: this.injector,
    });
  }
}

@Component({
  templateUrl: './dashboards.page.html',
})
export class DashboardsPage implements OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private securitySvc: SecurityService,
    private dashboardSvc: DashboardService,
    private breakpointObserver: BreakpointObserver
  ) {}

  private refresh$ = new BehaviorSubject(null);

  private readonly isMobile$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(
    map(state => state.matches),
    distinctUntilChanged()
  );

  private readonly dashboard$ = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    startWith(null),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    switchMap(() => this.route.firstChild!.data),
    map(data => (data['dashboard'] ?? null) as Dashboard.Model | null)
  );

  private readonly globalDistributors$ = this.securitySvc.globalDistributors$;

  readonly dashboards$ = this.dashboard$.pipe(
    startWith(null),
    switchMap(() => this.dashboardSvc.listAll()),
    map(dashboards => [...this.dashboardSvc.builtInDashboards(), ...dashboards] as (Dashboard.Model | BuiltInModel)[]),
    startWith(this.dashboardSvc.builtInDashboards() as (Dashboard.Model | BuiltInModel)[])
  );

  readonly pageData$ = combineLatest([
    this.isMobile$,
    this.dashboard$,
    this.globalDistributors$,
    this.dashboards$,
    this.refresh$,
  ]).pipe(
    map(([isMobile, dashboard, globalDistributors, dashboards, _]) => ({
      isMobile,
      dashboard,
      globalDistributors,
      dashboards,
    })),
    switchMap(data => {
      return this.refresh$.pipe(
        switchMap(async () => {
          const dashboard = data.dashboard
            ? await this.dashboardSvc.retrieve(data.dashboard.id)
            : this.dashboardSvc.builtInDashboards()[0] ?? null;
          return { ...data, dashboard };
        }),
        startWith(data)
      );
    })
  );

  readonly canEdit$ = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      return await this.securitySvc.hasAccess(
        'dsh_EditDetails',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
    })
  );

  readonly canCreate$ = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      return await this.securitySvc.hasAccess(
        'dsh_CreateDashboard',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
    })
  );

  readonly canSetFavourite$ = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      return await this.securitySvc.hasAccess(
        'dsh_FavouriteDashboard',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
    })
  );

  navigateTo(id: string) {
    this.router.navigate(['/', id]);
  }

  refresh() {
    this.refresh$.next(null);
  }

  async changeIconSelection(dashboard: Dashboard.Model, canSetFavoruite: boolean | null) {
    if (canSetFavoruite) {
      const response = await this.dashboardSvc.setFavourite({
        isDefault: true,
        id: dashboard.id,
      });
      if (response) this.refresh();
    }
  }

  ngOnDestroy() {
    this.refresh$.complete();
  }
}
