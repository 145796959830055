<div
  *vsLet="isLargeScreen$ | async as isLargeScreen;"
  [class]="isLargeScreen ? 'flex justify-between items-center border-b border-gray-200 pb-5' : 'border-b border-gray-200 pb-5'"
>
  <div>
    <p class="font-semibold text-lg">Profile info</p>
    <p class="text-sm text-gray-600 font-medium mb-4">Update your photo and personal details here.</p>
  </div>
  <button vs-button size="md" appearance="outline" (click)="signOut()">
    Log out
    <vs-icon name="log-out-03" slot="end"></vs-icon>
  </button>
</div>

<ng-container *ngIf="userData$  | async as userData">
  <ws-user-profile-form-component [staff]="userData.staff" [user]="userData.user"></ws-user-profile-form-component>
</ng-container>
