import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { StepperComponent } from './components';

@NgModule({
  imports: [CommonModule, IconsModule],
  exports: [StepperComponent],
  declarations: [StepperComponent],
})
export class StepperModule {}
