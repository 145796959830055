import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Warranty } from '@wsphere/warranties/domain';
import { WarrantyWebService } from '../../../warranty/services';

@Component({
  selector: 'ws-warranty-policy-card',
  templateUrl: './warranty-policy-card.component.html',
})
export class WarrantyPolicyCardComponent {
  constructor(private router: Router, private warrantySvc: WarrantyWebService, private toastSvc: ToastService) {}

  @Input() warranty?: Warranty.Model;

  overlayOpen = false;

  draft = Warranty.Status.DRAFT;

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  async updateTermStart(date: Date) {
    if (!this.warranty) return;

    const warranty = await this.warrantySvc.update(this.warranty.id, { termStart: date }).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (warranty?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Warranty term start updated',
        text: `Successfully updated warranty term start date to ${date.toDateString()}`,
      });

      const updatedWarranty = await this.warrantySvc.getOneById(warranty.id).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

      if (updatedWarranty) this.warranty = updatedWarranty;
    }
  }

  navigateToPolicy(policyId: string | undefined) {
    this.router.navigate(['/policies', policyId]);
  }
}
