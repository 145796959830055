import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PaymentService } from '@vsolv/packages/payments/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { BehaviorSubject } from 'rxjs';

@Component({ selector: 'ws-authorize-net-dialog', templateUrl: './authorize-net.dialog.html' })
export class AuthorizeNetDialog implements OnInit {
  constructor(private paymentSvc: PaymentService, private toastSvc: ToastService) {}

  @Output() enabledChanged = new EventEmitter<boolean>();

  hasValue$ = new BehaviorSubject<boolean>(false);
  hideTransactionKey$ = new BehaviorSubject<boolean>(true);
  editing = false;

  form = new FormGroup({
    accountName: new FormControl({ value: '', disabled: false }, [Validators.required]),
    transactionKey: new FormControl({ value: '', disabled: false }, [Validators.required]),
    clientKey: new FormControl({ value: '', disabled: false }),
  });

  async ngOnInit(): Promise<void> {
    const authorizeNetConfig = await this.paymentSvc.retrieveAuthorizeNetConfig().catch(() => {
      return undefined;
    });

    if (authorizeNetConfig?.accountName) {
      this.form.patchValue({
        accountName: authorizeNetConfig.accountName,
        clientKey: authorizeNetConfig.clientKey,
      });
      this.enabledChanged.emit(true);
      this.hasValue$.next(true);
      this.hideTransactionKey$.next(true);
    } else {
      this.enabledChanged.emit(false);
      this.hasValue$.next(false);
      this.hideTransactionKey$.next(false);
    }
  }

  async create() {
    if (this.form.valid && this.form.controls.accountName.value && this.form.controls.transactionKey.value) {
      const result = await this.paymentSvc
        .createAuthorizeNetConfing({
          accountName: this.form.controls.accountName.value,
          transactionKey: this.form.controls.transactionKey.value,
        })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Error',
            text: `Unable to save the configuration: ${error.message}`,
          });
        });
      if (result) {
        this.enabledChanged.emit(true);
        this.hasValue$.next(true);
        this.hideTransactionKey$.next(true);
        this.form.patchValue({ clientKey: result.clientKey, transactionKey: '' });
        this.editing = false;
        this.form.markAsPristine();

        this.toastSvc.show({
          type: 'success',
          title: 'Success',
          text: 'The configuration has been successfully updated',
        });
      }
    }
  }

  async disable() {
    const result = await this.paymentSvc.deleteAuthorizeNetConfig().catch(() => {
      this.toastSvc.show({
        type: 'error',
        title: 'Error',
        text: 'Unable to disable the configuration',
      });
    });
    if (result) {
      this.form.reset();
      this.toastSvc.show({
        type: 'success',
        title: 'Sucess',
        text: 'Configurations successfully disabled',
      });
      this.hasValue$.next(false);
      this.hideTransactionKey$.next(false);
      this.enabledChanged.emit(false);
    }
  }

  closeDialog() {
    if (this.hasValue$.value) {
      this.hideTransactionKey$.next(true);
      this.editing = false;
    } else {
      this.hideTransactionKey$.next(false);
    }
  }

  successfulCopy() {
    this.toastSvc.show({
      type: 'success',
      title: 'Success',
      text: `Client key has been successfully copied to the clipboard`,
    });
  }
}
