import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { InputModule } from '@vsolv/vectors-ui/input';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { CreateDashboardDialog, UpdateDashboardDialog } from './dialogs';
import { DashboardService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    DialogModule,
    InputModule,
    FormsModule,
    ButtonModule,
    ThemingModule,
    AlertModule,
  ],
  providers: [DashboardService],
  declarations: [CreateDashboardDialog, UpdateDashboardDialog],
  exports: [CreateDashboardDialog, UpdateDashboardDialog],
})
export class DashboardModule {}
