import { Component, Input } from '@angular/core';
import { Property } from '@vsolv/packages/properties/domain';

@Component({
  selector: 'vs-property-badge',
  templateUrl: './property-badge.component.html',
})
export class PropertyBadgeComponent {
  @Input() property!: Property.Model<Property.PropertyType>;
  @Input() showHidden = true;
}
