import { Component, EventEmitter, Input, Output } from '@angular/core';
import { slideInOut } from '@vsolv/vectors-ui/animations';

export interface SlideOutConfig {
  showCloseButton?: boolean;
  backdropDismiss?: boolean;
}

@Component({
  selector: 'vs-slide-out',
  templateUrl: './slide-out.component.html',
  animations: [slideInOut],
})
export class SlideOutComponent {
  @Input() config?: SlideOutConfig;

  @Output() closed = new EventEmitter<void>();

  closing = false;
  _open = false;

  open() {
    this._open = true;
  }

  close() {
    this.closing = true;
    this.closed.emit();
    setTimeout(() => {
      this._open = false;
      this.closing = false;
    }, 200);
  }

  toggle() {
    this._open = !this._open;
  }
}
