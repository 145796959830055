/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { combineLatest, map, switchMap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-resolve',
  templateUrl: './claim-item-resolve.component.html',
})
export class ClaimItemResolveComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private securitySvc: SecurityService,
    private claimSvc: ClaimService
  ) {}

  readonly claim$ = this.claimSvc.getClaim();
  readonly item$ = this.route.data.pipe(map(data => data['item'] as Claim.ClaimItem.Model));
  readonly isCustomerPortal$ = this.route.data.pipe(map(data => data['isCustomerPortal'] as boolean));

  open = Claim.Status.OPEN;
  draft = Claim.ClaimItem.Status.DRAFT;
  approved = Claim.ClaimItem.Status.APPROVED;
  resolved = Claim.ClaimItem.Status.RESOLVED;

  badgeTheme: { [key in Claim.ClaimItem.Status]: ThemeColor } = {
    DRAFT: 'default',
    APPROVED: 'success',
    REJECTED: 'danger',
    RESOLVED: 'success',
  };

  canManageResolution$ = combineLatest([this.claim$, this.isCustomerPortal$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageClaimItemResolution', permissionKey);
    })
  );

  navigateTo(path?: string) {
    this.router.navigate([`../${path}`], { relativeTo: this.route });
  }

  close(path?: string) {
    this.router.navigate([`../${path}`], { relativeTo: this.route, queryParams: { closeItemDialog: true } });
  }
}
