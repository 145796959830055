import { Staff } from '../lib/staff';

export type DashboardPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'dsh_EditDetails',
    display: {
      title: 'Edit Dashboard Details',
      description: 'Edit dashboard details',
    },
  },
  {
    id: 'dsh_CreateDashboard',
    display: {
      title: 'Create Dashboard',
      description: 'Create a dashboard (automatically has dashboard owner role)',
    },
  },
  {
    id: 'dsh_FavouriteDashboard',
    display: {
      title: 'Favourite Dashboard',
      description: 'Mark dashboards as favourite',
    },
  },
  {
    id: 'dsh_ViewDashboard',
    display: {
      title: 'View Dashboard',
      description: 'View dashboard contents',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
