import { Component, Inject, Input, Optional } from '@angular/core';
import { Address } from '@vsolv/core/address/domain';
import { GoogleMapConfig, GOOGLE_MAP_CONFIG } from '../../GoogleMapConfig';

@Component({
  selector: 'vs-map',
  templateUrl: './map.component.html',
})
export class MapComponent {
  constructor(@Optional() @Inject(GOOGLE_MAP_CONFIG) config: GoogleMapConfig) {
    this.googleMapsKey = config.apikey;
  }

  googleMapsKey: string | null;

  @Input() address: Address.Model | null = null;
}
