import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@vsolv/core/users/domain';
import { firstValueFrom, interval } from 'rxjs';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {
    interval(300000).subscribe(() => {
      this.updateLastSignedIn();
    });
  }

  async getOneByExternalId(externalId: string) {
    return firstValueFrom(this.http.get<User.Model>(`/v1/users/user/${externalId}`));
  }

  async getSelf() {
    return firstValueFrom(this.http.get<User.Model>(`/v1/users/self`));
  }

  async updateLastSignedIn() {
    return firstValueFrom(this.http.post<void>(`/v1/users/signed-in`, {}));
  }

  async updateUserProfile(body: User.UpdateUserProfileRequest, userId: string) {
    return firstValueFrom(this.http.post<User.UpdateUserProfileResponse>(`/v1/users/profile/update/${userId}`, body));
  }
}
