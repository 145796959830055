<ng-container *ngIf="policy$ | async as policy">
  <ng-container *ngIf="plan$ | async as plan">
    <div
      *vsLet="canEditPlan$ | async as canEditPlans"
      class="min-w-[77%] bg-base-white flex-[10] w-full shadow-lg p-4 h-[100%] min-h-[75vh] rounded-b-lg rounded-tr-lg"
      [class.rounded-tl-lg]="roundedCorner$ | async"
    >
      <ng-container *ngIf="propertySet$ | async as propertySet">
        <div class="flex flex-col w-full">
          <div class="flex justify-between max-h-[36px]">
            <p class="text-xl font-semibold text-gray-900 self-center">Details and Pricing</p>

            <div class="flex">
              <button
                *ngIf="canEditPlans"
                vs-icon-button
                size="md"
                class="self-center"
                appearance="clear"
                [disabled]="saving"
                (click)="openEditPlanDialog()"
              >
                <vs-icon name="edit-01"></vs-icon>
              </button>

              <button
                *ngIf="canDeletePlan$ | async"
                vs-icon-button
                class="self-center"
                size="md"
                appearance="clear"
                (click)="openDeletePlanDialog()"
              >
                <vs-icon name="trash-01"></vs-icon>
              </button>
            </div>
          </div>

          <vs-divider class="my-2"></vs-divider>

          <div class="flex flex-col gap-4 my-4 mx-2">
            <div class="flex gap-2">
              <p class="text-sm font-medium text-gray-700 basis-1/5">Title</p>
              <p class="text-sm text-gray-900">{{ plan.title }}</p>
            </div>

            <div class="flex sm:flex-row flex-col gap-2">
              <p class="text-sm font-medium text-gray-700 basis-1/5">Description</p>
              <p class="text-sm text-gray-900 overflow-y-auto max-h-[80px] basis-4/5 scrollbar-thin">
                {{ plan.description }}
              </p>
            </div>

            <div class="flex sm:flex-row flex-col gap-2">
              <p class="text-sm font-medium text-gray-700 basis-1/5">Order</p>
              <p class="text-sm text-gray-900">{{ plan.order }}</p>
            </div>

            <div class="flex sm:flex-row flex-col gap-2">
              <p class="text-sm font-medium text-gray-700 basis-1/5">Conditions</p>

              <ws-condition-preview
                class="text-sm text-gray-900"
                [groupValue]="plan.conditions"
                [propertySet]="propertySet"
              ></ws-condition-preview>
            </div>

            <form *vsLet="changeVisibility$ | async" class="flex" [formGroup]="visibilityForm">
              <div
                *vsLet="canEditVisibility$ | async as canEditVisibility"
                class="flex gap-4"
                tooltipStyle="light"
                tooltipPosition="end"
                [vsTooltip]="!canEditVisibility ? 'Insufficient permissions to toggle visibility' : ''"
              >
                <input
                  vsInput
                  type="checkbox"
                  theme="primary"
                  appearance="toggle"
                  formControlName="visible"
                  [class.no-pointer-class]="canEditVisibility"
                />

                <div class="flex flex-col gap-1">
                  <p class="text-sm font-medium text-gray-700">Plan Visibility</p>
                  <p class="text-sm text-gray-600">
                    To hide this plan from customers at checkout, toggle its visibility settings by editing the plan
                    details.
                  </p>
                </div>
              </div>
            </form>
          </div>

          <ws-plan-pricing-table
            [policy]="policy"
            [plan]="plan"
            [propertySet]="propertySet"
            [canEditPlans]="!!canEditPlans"
          ></ws-plan-pricing-table>
        </div>
      </ng-container>
    </div>

    <ws-plan-dialog *ngIf="policy" #editPlanDialog [policy]="policy" [plan]="plan"></ws-plan-dialog>

    <ws-delete-plan-dialog
      *ngIf="policy && plan"
      #deletePlanDialog
      [plan]="plan"
      [policy]="policy"
    ></ws-delete-plan-dialog>
  </ng-container>
</ng-container>
