import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Dashboard } from '@vsolv/packages/dashboarding/domain';
import { DashboardService } from '../services';

@Injectable()
export class DefaultDashboardResolver implements Resolve<Dashboard.Model | null> {
  constructor(private dashboardSvc: DashboardService) {}

  async resolve(): Promise<Dashboard.Model | null> {
    return (await this.dashboardSvc.retrieveDefault()) ?? this.dashboardSvc.builtInDashboards()[0] ?? null;
  }
}
