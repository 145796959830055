import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContentCardModule } from '@vsolv/ui-kit/card';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { ToastModule } from '@vsolv/ui-kit/toast';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { TableModule } from '@vsolv/vectors/table';
import { CustomerWebModule } from '@wsphere/customers/web';
import { WarrantyCoverageModule } from '../coverage';
import { WarrantyDocumentsCardComponent } from './components';
import { SendContactNumberModalComponent } from './modals';
import { WarrantyWebService } from './services';

@NgModule({
  exports: [WarrantyDocumentsCardComponent],
  providers: [WarrantyWebService, AppPageModule],
  declarations: [SendContactNumberModalComponent, WarrantyDocumentsCardComponent],
  imports: [
    BreadcrumbsModule,
    UtilsModule,
    BadgeModule,
    IconsModule,
    InputModule,
    TableModule,
    AppPageModule,
    RouterModule,
    CommonModule,
    ContentCardModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    ToastModule,
    SelectModule,
    CustomerWebModule,
    ButtonModule,
    WarrantyCoverageModule,
    ModalModule,
  ],
})
export class WarrantyModule {}
