/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { PropertyService } from '@vsolv/packages/properties/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, switchMap } from 'rxjs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-plan-dialog',
  templateUrl: './plan.dialog.html',
})
export class PlanDialog {
  constructor(
    private router: Router,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder,
    private propertySvc: PropertyService
  ) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('plan') plan$ = new BehaviorSubject<Policy.Plan.Model | null>(null);
  @Input() plan: Policy.Plan.Model | null = null;

  @Output() closed = new EventEmitter<string>();

  page = 1;
  saving = false;
  addCondition = false;
  draft = Policy.Status.DRAFT;

  form = this.formBuilder.group({
    title: ['', [Validators.required]],
    description: [''],
    order: [0, Validators.required],
    visible: [true],
    conditions: [{ comparison: Conditions.Comparison.AND, conditions: [] } as Conditions.ConditionGroup],
  });

  propertySet$ = this.policy$.pipe(
    switchMap(async policy => {
      if (!policy) return null;
      return await this.propertySvc.retrievePropertySet(policy.propertySetId);
    })
  );

  open() {
    this.page = 1;

    if (!this.plan) {
      this.form.reset({
        order: 0,
        visible: true,
        conditions: null,
      });
    } else {
      this.form.patchValue({
        title: this.plan.title,
        description: this.plan.description,
        order: this.plan.order,
        visible: this.plan.visible,
        conditions: this.plan.conditions,
      });

      if (this.form.value.conditions) this.addCondition = true;
    }

    this.dialog.open();
  }

  close() {
    this.dialog.close();
    this.page = 1;
    this.addCondition = false;
    this.form.reset({
      order: 0,
      visible: true,
      conditions: null,
    });
  }

  async create() {
    this.saving = true;

    const planDetails = this.form.value;

    if (this.policy) {
      const dto: Policy.Plan.CreatePolicyPlanRequest = {
        canCreateNewVersion: true,
        order: Number(planDetails.order ?? 0),
        title: planDetails.title || '',
        description: planDetails.description || '',
        visible: !!planDetails.visible,
        conditions: planDetails.conditions?.conditions?.length ? planDetails.conditions : null,
      };

      const policyId = this.policy.id;

      const plan = await this.policySvc.createPlan(policyId, dto).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
        this.saving = false;
      });

      if (plan) {
        if (this.policy.id === plan.policyId) {
          this.toastSvc.show({
            type: 'success',
            title: 'Created plan',
            text: '<strong>' + planDetails.title + '</strong> has been successfully created.',
          });

          this.policySvc.refreshPlan({ id: plan.id, policyId: plan.policyId } as Policy.Plan.Model);
          this.policySvc.refreshPolicy();
        } else {
          this.toastSvc.show({
            type: 'success',
            title: 'New policy version created',
            text:
              '<strong>' +
              planDetails.title +
              '</strong> has been successfully created and a new version of <strong>' +
              this.policy.title +
              '</strong> has been created.',
          });

          this.policySvc.refreshPolicy(plan.policyId);

          const newPolicy = await this.policySvc.getOne(plan.policyId);
          if (!newPolicy) throw new Error(`Unable to find policy with id: ${plan.policyId}`);
          const newPlanId =
            newPolicy.plans?.find(plan => plan.id.startsWith(this.plan?.id?.split('-')[0] || ''))?.id || '';
          if (newPlanId) this.policySvc.refreshPlan({ id: newPlanId, policyId: newPolicy.id } as Policy.Plan.Model);

          await this.navigateTo('/policies/' + plan.policyId + '/plans/' + newPlanId);
        }

        this.close();
        this.saving = false;
      }
    }

    this.saving = false;
  }

  async update() {
    this.saving = true;

    const planDetails = this.form.value;

    if (this.policy && this.plan) {
      const policyId = this.policy.id;
      const planId = this.plan.id;

      const dto: Policy.Plan.UpdatePolicyPlanRequest = {
        canCreateNewVersion: true,
        order: Number(planDetails.order ?? 0),
        title: planDetails.title || '',
        description: planDetails.description || '',
        conditions: planDetails.conditions?.conditions?.length ? planDetails.conditions : null,
      };

      const plan = await this.policySvc.updatePlan(planId, policyId, dto).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
        this.saving = false;
      });

      if (plan) {
        if (this.policy.id === plan.policyId) {
          this.toastSvc.show({
            type: 'success',
            title: 'Updated plan',
            text: '<strong>' + planDetails.title + '</strong> has been successfully updated.',
          });

          this.policySvc.refreshPlan();
          this.policySvc.refreshPolicy();
        } else {
          this.toastSvc.show({
            type: 'success',
            title: 'New policy version created',
            text:
              '<strong>' +
              planDetails.title +
              '</strong> has been successfully updated and a new version of <strong>' +
              this.policy.title +
              '</strong> has been created.',
          });

          this.policySvc.refreshPolicy(plan.policyId);

          const newPolicy = await this.policySvc.getOne(plan.policyId);
          if (!newPolicy) throw new Error(`Unable to find policy with id: ${plan.policyId}`);
          const newPlanId =
            newPolicy.plans?.find(plan => plan.id.startsWith(this.plan?.id?.split('-')[0] || ''))?.id || '';
          if (newPlanId) this.policySvc.refreshPlan({ id: newPlanId, policyId: newPolicy.id } as Policy.Plan.Model);

          await this.navigateTo('/policies/' + plan.policyId + '/plans/' + newPlanId);
        }

        this.close();
        this.saving = false;
      }
    }

    this.saving = false;
  }

  async navigateTo(path?: string) {
    if (path) await this.router.navigate([`${path}`]);
  }
}
