<vs-dialog #dialog [config]="{ dialogContainerClass: 'w-[531px] max-w-[90%] max-h-[90%]' }">
  <vs-dialog-header
    class="self-start w-full"
    [config]="{
      mode: 'simple',
      icon: 'diamond-01',
      iconTheme: 'primary',
      title: 'Add new asset',
      subtitle: 'Follow steps to add a new asset for this customer.',
      showDivider: true
    }"
  >
  </vs-dialog-header>

  <form [formGroup]="form" class="flex flex-col gap-4 w-full mt-4">
    <!-- Asset Type -->
    <vs-form-field [config]="{ label: 'Asset type:' }">
      <vs-property-set-picker
        formControlName="propertySet"
        (valueChanges)="selectPropertySet($event?.id)"
      ></vs-property-set-picker>
    </vs-form-field>

    <!-- Asset Name -->
    <vs-form-field [config]="{ label: 'Name (optional):' }">
      <input vsInput type="text" formControlName="name" placeholder="Enter a meaningful name for this asset..." />
    </vs-form-field>

    <ng-container *ngIf="propertySetId">
      <vs-divider></vs-divider>
      <!-- Property Values -->
      <vs-property-set-input
        class="h-full max-h-[320px] overflow-y-scroll px-2 -ml-2 scrollbar-thin"
        #propertyValuesInput
        formControlName="propertyValues"
        [setId]="propertySetId"
        [showHidden]="true"
      ></vs-property-set-input>
    </ng-container>

    <!-- Buttons -->
    <div class="flex flex-row justify-end gap-3 w-full bg-base-white h-fit self-end sticky bottom-0">
      <button vs-button appearance="outline" (click)="close()">Back</button>
      <button vs-button appearance="default" theme="primary" [disabled]="!isValid()" (click)="submit()">Confirm</button>
    </div>
  </form>
</vs-dialog>
