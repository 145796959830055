import { Component } from '@angular/core';
import { ButtonAppearance } from '@vsolv/vectors-ui/button';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { TableCellComponent } from '../../models';

export interface ButtonCellProps {
  theme?: ThemeColor;

  type?: ButtonAppearance;
  rounded?: boolean;
  disabled?: boolean;

  text?: string;
  iconStart?: IconName;
  iconEnd?: IconName;

  routerLink?: string[];
  click?(): void | Promise<void>;
}

@Component({
  template: `
    <ng-container *ngIf="data">
      <div (click)="data.disabled && $event.stopImmediatePropagation()">
        <a
          *ngIf="data.routerLink; else buttonTmpl"
          vs-button
          [theme]="data.theme"
          [routerLink]="data.routerLink"
          [appearance]="data.type || 'default'"
          [rounded]="data.rounded || false"
          [disabled]="data.disabled || false"
          (click)="$event.stopPropagation()"
        >
          <vs-icon *ngIf="data.iconStart" [name]="data.iconStart" slot="start"></vs-icon>
          {{ data.text }}
          <vs-icon *ngIf="data.iconEnd" [name]="data.iconEnd" slot="end"></vs-icon>
        </a>

        <ng-template #buttonTmpl>
          <button
            vs-button
            type="button"
            [theme]="data.theme"
            [appearance]="data.type || 'default'"
            [rounded]="data.rounded || false"
            [disabled]="data.disabled || false"
            (click)="$event.stopPropagation(); _click()"
          >
            <vs-icon *ngIf="data.iconStart" [name]="data.iconStart" slot="start"></vs-icon>
            {{ data.text }}
            <vs-icon *ngIf="data.iconEnd" [name]="data.iconEnd" slot="end"></vs-icon>
          </button>
        </ng-template>
      </div>
    </ng-container>
  `,
})
export class ButtonCellComponent extends TableCellComponent<ButtonCellProps> {
  async _click() {
    await this.data?.click?.();
  }
}
