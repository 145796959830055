import { Component, EventEmitter, InjectionToken, Input, OnInit, Output } from '@angular/core';

export const DIALOG_TOKEN = new InjectionToken<DialogComponent>('DIALOG_TOKEN');

export interface DialogConfig {
  hideBackdrop?: boolean;
  disableBackdropDismiss?: boolean;

  contentContainerClass?: string;
  dialogContainerClass?: string;

  startOpen?: boolean;
}

@Component({
  selector: 'vs-dialog',
  templateUrl: './dialog.component.html',
  providers: [{ provide: DIALOG_TOKEN, useExisting: DialogComponent }],
})
export class DialogComponent implements OnInit {
  @Output() opened = new EventEmitter();
  @Output() closed = new EventEmitter<unknown>();

  @Input() config?: DialogConfig;

  _open = false;

  open() {
    this._open = true;
    this.opened.emit();
  }

  close(data?: unknown) {
    this._open = false;
    this.closed.emit(data);
  }

  ngOnInit(): void {
    if (this.config?.startOpen) this.open();
  }
}
