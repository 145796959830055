import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Customer } from '@wsphere/customers/domain';
import { BehaviorSubject, catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class CustomerService {
  constructor(private http: HttpClient) {}

  private customer$ = new BehaviorSubject<Customer.Model | null>(null);

  listCustomers(dto: Customer.ListCustomersQueryRequest, openClaim: boolean = false) {
    if (!openClaim)
      return firstValueFrom(
        this.http.get<Customer.ListCustomersQueryResponse>(`/api/customers`, {
          params: {
            ...(dto.page ? { page: dto.page } : {}),
            ...(dto.limit ? { limit: dto.limit } : {}),
            ...(dto.search && { search: dto.search }),
            ...(dto.policyIds && { policyIds: dto.policyIds }),
            ...(dto.distributorIds && { distributorIds: dto.distributorIds }),
          },
        })
      );
    else
      return firstValueFrom(
        this.http.get<Customer.ListCustomersQueryResponse>(`/api/customers/open-claim-list-customers`, {
          params: {
            ...(dto.page ? { page: dto.page } : {}),
            ...(dto.limit ? { limit: dto.limit } : {}),
            ...(dto.search && { search: dto.search }),
          },
        })
      );
  }

  async retrieveCustomer(customerId: string) {
    return await firstValueFrom(
      this.http.get<Customer.Model>(`/api/customers/${customerId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  retrieveSelf() {
    return firstValueFrom(this.http.get<Customer.Model | null>(`/api/customers/self`));
  }

  listCustomersForSales(dto: Customer.ListCustomersQueryRequest) {
    return firstValueFrom(
      this.http.get<Customer.ListCustomersQueryResponse>(`/api/customers-sales`, {
        params: {
          ...(dto.page ? { page: dto.page } : {}),
          ...(dto.limit ? { limit: dto.limit } : {}),
          ...(dto.search && { search: dto.search }),
          ...(dto.distributorIds?.length && { distributorIds: dto.distributorIds }),
        },
      })
    );
  }

  checkCustomerForSales(email: string) {
    return firstValueFrom(
      this.http.get<Customer.ListCustomersQueryResponse>(`/api/customers-sales/check`, {
        params: { email },
      })
    );
  }

  async checkIfCustomerExists(email: string) {
    return (await firstValueFrom(this.http.get<{ exists: boolean }>(`/api/customers/${email}`))).exists;
  }

  createNote(id: string, data: Customer.Notes.CreateNoteRequest): Promise<Customer.Notes.CreateNoteResponse> {
    return firstValueFrom(
      this.http.post<Customer.Notes.CreateNoteResponse>(`/api/customers/${id}/note`, {
        title: data.title,
        content: data.content,
      })
    );
  }

  listNotes(id: string, dto?: Customer.Notes.ListNotesQueryRequest) {
    return firstValueFrom(
      this.http.get<Customer.Notes.ListNotesQueryResponse>(`/api/customers/${id}/notes`, {
        params: {
          ...(dto?.page ? { page: dto.page } : {}),
          ...(dto?.limit ? { limit: dto.limit } : {}),
        },
      })
    );
  }

  deleteNote(customerId: string, noteId: string): Promise<Customer.Notes.CreateNoteResponse> {
    return firstValueFrom(
      this.http.delete<Customer.Notes.DeleteNoteResponse>(`/api/customers/${customerId}/notes/${noteId}`)
    );
  }

  updateNote(
    customerId: string,
    noteId: string,
    data: Customer.Notes.UpdateNoteRequest
  ): Promise<Customer.Notes.CreateNoteResponse> {
    return firstValueFrom(
      this.http.patch<Customer.Notes.UpdateNoteResponse>(`/api/customers/${customerId}/notes/${noteId}`, {
        title: data.title,
        content: data.content,
      })
    );
  }

  getCustomer() {
    return this.customer$.asObservable();
  }

  async refreshCustomer(customer?: Customer.Model) {
    if (this.customer$.value) {
      if (customer) {
        if (this.customer$.value.id !== customer.id) this.customer$.next(customer);
      } else {
        const id = this.customer$.value.id;
        const customer = await this.retrieveCustomer(id);
        this.customer$.next(customer);
      }
    } else {
      if (customer) {
        this.customer$.next(customer);
      }
    }
  }

  clearCustomer() {
    this.customer$.next(null);
  }
}
