<div
  *ngIf="distributor?.email === null"
  class="flex flex-row gap-2 bg-warn-50 bg-opacity-50 border border-warn-300 rounded-lg p-3"
>
  <vs-icon name="alert-triangle" size="13px" class="text-warn-700"></vs-icon>
  <div class="flex flex-col text-warn-700 text-sm font-semibold gap-2">
    <div>
      <p>This distributor doesn't have email notifications configured</p>
      <p class="font-normal">
        There is no email address associated with this distributor. To receive email notifications related to the
        progress of CSVs uploaded for warranty provisioning, please add an email to this distributors profile.
      </p>
    </div>
    <p class="hover:cursor-pointer" (click)="goToDistEmail.emit()">
      Add email to distributor profile <vs-icon name="arrow-right"></vs-icon>
    </p>
  </div>
</div>
<vs-card class="flex flex-wrap gap-4 mt-4 w-full">
  <ws-sftp-key-management class="w-full" [distributor]="distributor"></ws-sftp-key-management>
</vs-card>
