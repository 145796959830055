<vs-select
  vsInput
  class="min-w-[200px]"
  [value]="value"
  [config]="{ placeholder: 'All statuses', multiple: true }"
  (valueChanges)="selectValueChanged($event)"
>
  <li *ngFor="let status of statuses" vs-select-option [value]="status">
    <vs-badge [theme]="getTheme($any(status))"> {{ status.replace('_', ' ') | titlecase }} </vs-badge>
  </li>
</vs-select>
