/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { DatePipe } from '@angular/common';
import { Component, Input, Output, ViewChild } from '@angular/core';
import { ApiKey } from '@vsolv/packages/api-key';
import { ApiKeyService } from '@vsolv/packages/api-key/web';
import { IconButtonColumn, TextColumn } from '@vsolv/vectors/table';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, combineLatest, from, map, switchMap } from 'rxjs';
import { DeleteApiKeyDialog, EditApiKeyDialog, RollApiKeyDialog } from '../../dialogs';
import {} from '../../dialogs/delete-api-key-dialog';

@Component({
  selector: 'ws-api-keys-table',
  templateUrl: './api-keys-table.component.html',
})
export class ApiKeysTableComponent {
  constructor(private apiKeySvc: ApiKeyService, private securitySvc: SecurityService) {}

  currentApiKeyItem: ApiKey.ListApiKeysResponseType | null = null;
  @Output() totalApiKey$ = new BehaviorSubject<number>(0);
  @ViewChild('rollKeyDialog') rollKeyDialog!: RollApiKeyDialog;
  @ViewChild('editKeyDialog') editKeyDialog!: EditApiKeyDialog;
  @ViewChild('deleteKeyDialog') deleteKeyDialog!: DeleteApiKeyDialog;
  @Input() set refreshTableInput(value: boolean) {
    this.refresh$.next(null);
  }

  private refresh$ = new BehaviorSubject<null>(null);
  paginationQueryRequest$ = new BehaviorSubject<{ page?: number; limit?: number }>({ page: 1, limit: 10 });

  apiKeys$ = combineLatest([this.paginationQueryRequest$, this.refresh$]).pipe(
    switchMap(async ([pagination]) => {
      const response = await this.apiKeySvc.list(pagination.page, pagination.limit);
      this.totalApiKey$.next(response.meta.totalItems || 0);
      return response;
    })
  );

  canManageAPIKeys$ = from(this.securitySvc.hasAccess('stf_ManageAPIKeys', null));

  columns$ = this.canManageAPIKeys$.pipe(
    map(canManageAPIKeys => {
      return [
        new TextColumn({ header: 'User', sortable: false }, (item: any) => ({
          text: item.user?.displayName || item.user?.email || item.user?.id,
          classes: 'text-gray-900 capitalize',
        })),
        new TextColumn({ header: 'API key', sortable: false }, (item: any) => ({
          text: item.name,
          classes: 'text-gray-900',
        })),
        new TextColumn({ header: 'Token', sortable: false }, (item: any) => ({
          text: item.details || '-',
          classes: 'text-gray-900',
        })),
        new TextColumn({ header: 'Last used', sortable: false }, (item: any) => ({
          text: this.formatDateValue(item.lastUsed)!,
          classes: item.lastUsed == null ? 'flex pl-[25%]' : '',
        })),
        new TextColumn({ header: 'Created', sortable: false }, (item: any) => ({
          text: this.formatDateValue(item.created)!,
          classes: item.created == null ? 'flex pl-[25%]' : '',
        })),
        new TextColumn({ header: 'Expiry Date', sortable: false }, (item: any) => ({
          text: this.formatDateValue(item.expired)!,
          classes: item.expired == null ? 'flex pl-[25%]' : '',
        })),
        ...(canManageAPIKeys
          ? [
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: any) => ({
                type: 'clear',
                rounded: true,
                icon: 'refresh-cw-02',
                click: () => this.openRollKeyDialog(item),
              })),
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: any) => ({
                type: 'clear',
                rounded: true,
                icon: 'edit-01',
                click: () => this.openEditKeyDialog(item),
              })),
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: any) => ({
                type: 'clear',
                rounded: true,
                icon: 'trash-01',
                click: () => this.openDeleteKeyDialog(item),
              })),
            ]
          : []),
      ];
    })
  );

  formatDateValue(value: string) {
    if (value == null) return '-';
    else {
      const datePipe: DatePipe = new DatePipe('en-US');
      const formattedDate = datePipe.transform(value, 'MMM dd, yyyy');
      return formattedDate;
    }
  }

  refreshTable() {
    this.refresh$.next(null);
  }

  openRollKeyDialog(item: ApiKey.ListApiKeysResponseType) {
    this.currentApiKeyItem = item;
    this.rollKeyDialog.openDialog();
  }

  openEditKeyDialog(item: ApiKey.ListApiKeysResponseType) {
    this.currentApiKeyItem = item;
    this.editKeyDialog.openDialog();
  }

  openDeleteKeyDialog(item: ApiKey.ListApiKeysResponseType) {
    this.currentApiKeyItem = item;
    this.deleteKeyDialog.openDialog();
  }
}
