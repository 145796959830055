import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { DistributorService } from '@wsphere/distributors/web';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, Subject, combineLatest, from, map, takeUntil } from 'rxjs';

@Component({
  selector: 'ws-link-to-policy',
  templateUrl: './link-to-policy.dialog.html',
})
export class LinkToPolicyDialog implements OnInit, OnDestroy {
  constructor(
    private urlSvc: PortalService,
    private toastSvc: ToastService,
    private copyCdk: Clipboard,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService,
    public elementRef: ElementRef
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy?: Policy.Model | null;
  @Input() distributor: Distributor.Model | null = null;
  @Input() dialogConfig?: DialogConfig;

  @Output() closed = new EventEmitter<boolean>();

  overlayOpen = false;
  openedPlan: { plan: Policy.Plan.Model; terms: Policy.PolicyTerm[] | null } | null = null;

  link?: string;

  @PropertyListener('selectedPlans') selectedPlans$ = new BehaviorSubject<Record<string, string[]>>({});
  selectedPlans: Record<string, string[]> = {};

  private destroy$ = new Subject<void>();

  canCreatePolicyLinkPlatform$ = from(this.securitySvc.hasAccess('pol_CreatePolicyLink', null));

  plans$ = this.policy$.pipe(
    map(policy => {
      if (!policy?.plans || !policy?.terms) return [];

      const plans: { plan: Policy.Plan.Model; terms: Policy.PolicyTerm[] }[] = [];

      for (const plan of policy.plans) {
        const completedTerms: Policy.PolicyTerm[] = [];

        for (const term of policy.terms) {
          const coverageConfigs = plan.coverageConfigs[term.id];

          if (!coverageConfigs) continue;

          const coveragesInvalid = coverageConfigs.some(
            config =>
              !config ||
              !config.requirement ||
              !config.deductible ||
              !config.liabilityLimit ||
              !config.price ||
              !config.liabilityGroups ||
              config.requirement.defaultValue === null ||
              (config.deductible.defaultValue === null && !config.deductible.valuePropertyPath) ||
              (config.liabilityLimit.defaultValue === null && !config.liabilityLimit.valuePropertyPath) ||
              (config.price.defaultValue === null && !config.price.valuePropertyPath) ||
              config.liabilityGroups.defaultValue === null
          );

          if (coveragesInvalid) continue;

          completedTerms.push(term);
        }

        if (completedTerms.length) {
          plans.push({ plan, terms: completedTerms });
        }
      }

      return plans;
    })
  );

  selectedPlanValues$ = combineLatest([this.selectedPlans$, this.policy$]).pipe(
    map(([selectedPlans, policy]) => {
      if (!policy?.plans || !policy?.terms) return [];

      const values: { planId: string; termId: string | null; text: string }[] = [];

      for (const [planId, termIds] of Object.entries(selectedPlans)) {
        const plan = policy.plans?.find(plan => plan.id === planId);

        if (!plan) continue;
        if (!termIds?.length) {
          values.push({
            planId: plan.id,
            termId: null,
            text: `${plan.title.substring(0, 30) + (plan.title.length > 30 ? '...' : '')} - All terms`,
          });
        } else {
          for (const termId of termIds) {
            const term = policy.terms.find(term => term.id === termId);
            if (!term) continue;

            values.push({
              planId: plan.id,
              termId: term.id,
              text: `${plan.title.substring(0, 30) + (plan.title.length > 30 ? '...' : '')} - ${
                term.title.substring(0, 25) + (term.title.length > 25 ? '...' : '')
              }`,
            });
          }
        }
      }

      return values;
    })
  );

  toggle() {
    this.overlayOpen = !this.overlayOpen;
    this.openedPlan = null;
  }

  getSelectedTermText(planId: string, termId: string) {
    const plan = this.policy?.plans?.find(plan => plan.id === planId);
    const term = this.policy?.terms?.find(term => term.id === termId);

    if (!plan || !term) return '';

    return `${plan.title} - ${term.title}`;
  }

  openDialog() {
    this.dialog.open();
    this.selectedPlans = {};
  }

  closeDialog() {
    this.dialog.close();
  }

  cancel() {
    this.closeDialog();
    this.closed.emit(false);
  }

  selectPlan(id: string) {
    this.selectedPlans[id] = [];
    this.selectedPlans = { ...this.selectedPlans };
  }

  deselectPlan(planId: string) {
    const plans: Record<string, string[]> = {};

    for (const plan of Object.keys(this.selectedPlans).filter(plan => plan !== planId)) {
      plans[plan] = this.selectedPlans[plan];
    }

    this.selectedPlans = plans;
  }

  selectTerm(planId: string, termId: string) {
    const selectedTerms = this.selectedPlans[planId] ?? [];
    if (selectedTerms) {
      this.selectedPlans[planId] = [...selectedTerms, termId];
    } else {
      this.selectPlan(planId);
      this.selectedPlans[planId] = [termId];
    }
    this.selectedPlans = { ...this.selectedPlans };
  }

  deselectTerm(planId: string, termId: string) {
    const selectedTerms = (this.selectedPlans[planId] ?? []).filter(term => term !== termId);
    if (!selectedTerms.length) {
      this.deselectPlan(planId);
    } else {
      this.selectedPlans[planId] = [...selectedTerms.filter(term => term !== termId)];
      this.selectedPlans = { ...this.selectedPlans };
    }
  }

  selectDistributor(distributor: Distributor.Model | null) {
    this.distributor = distributor;
  }

  async copyLink() {
    const url = await this.urlSvc.getPortalUrl();
    this.link = url.url + '/checkout';

    const selectedPlanIds = Object.keys(this.selectedPlans);
    selectedPlanIds.forEach((id, index) => {
      this.link += index === 0 ? '?planId=' + id : '&planId=' + id;
      const selectedTerms = this.selectedPlans[id];
      selectedTerms.forEach(termId => (this.link += `&${id}=${termId}`));
    });

    if (this.distributor) {
      if (selectedPlanIds.length) {
        this.link += `&distributorId=${this.distributor.id}`;
      } else {
        this.link += `?distributorId=${this.distributor.id}`;
      }
    }
    this.copyCdk.copy(this.link);
    this.toastSvc.show({
      type: 'success',
      title: 'Link copied',
      text:
        'A link to <strong>' +
        this.policy?.title +
        '</strong> was successfully copied to clipboard and can now be shared.',
    });
  }

  ngOnInit(): void {
    this.securitySvc.globalDistributors$.pipe(takeUntil(this.destroy$)).subscribe(async distributors => {
      if (distributors && distributors.length) {
        this.distributor = await this.distributorSvc.getOne(distributors[0].id);
      } else {
        this.distributor = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
