<vs-dialog [config]="{ dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]' }" (closed)="resetForm()">
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,
        icon: 'key-01',
        iconTheme: 'primary',
        title: 'Generate new key',
        subtitle: 'Generate a new key with a unique and memorable name.',
        showDivider: true
      }"
  ></vs-dialog-header>

  <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
    <div id="dialog-content" class="pt-6">
      <vs-form-field
        class="pb-4"
        theme="primary"
        appearance="responsive"
        [config]="{
          label: 'Staff picker*'
        }"
      >
        <vs-select
          vsInput
          [config]="{
        placeholder: 'Select a staff you want to generate a key for'
        }"
          formControlName="user"
        >
          <li vs-select-option *ngFor="let staff of staffList" [value]="staff">
            {{staff.name || staff.user?.displayName || staff.user?.email || staff.userId}}
          </li>
        </vs-select>
        <p slot="hint">
          <span *ngIf="form.controls.user.touched && form.controls.user.invalid; else baseHint" class="text-danger">
            <ng-container> Please select a staff from the list. </ng-container>
          </span>
          <ng-template #baseHint>Select a staff to assign an api-key to.</ng-template>
        </p>
      </vs-form-field>

      <vs-form-field
        class="pb-4"
        theme="primary"
        [config]="{
    label: 'Key name',
    hint: '',
    description: ''
  }"
        appearance="responsive"
      >
        <vs-input-container class="group">
          <vs-icon name="key-02" slot="start"></vs-icon>
          <input placeholder="Key name" formControlName="name" type="text" />
          <vs-icon class="hidden text-danger group-invalid-within:inline-block" name="info-circle" slot="end"></vs-icon>
        </vs-input-container>
        <span class="text-danger" slot="hint" *ngIf="form.controls.name.invalid && form.controls.name.touched"
          >Please enter a valid API key name.</span
        >
      </vs-form-field>

      <vs-form-field
        [config]="{
          label: { text: 'Expiration', for: 'date-input' },
          description: ''
        }"
      >
        <vs-input-container>
          <input
            #dateInput
            formControlName="expired"
            class="picker-indicator-hidden"
            type="date"
            placeholder="Select an expiration date"
          />
          <button
            vs-icon-button
            size="xs"
            appearance="clear"
            slot="end"
            [vsMenuTriggerFor]="dateMenu"
            #trigger="vsMenuTriggerFor"
          >
            <vs-icon vsTooltip="Open date picker" name="calendar" size="16"></vs-icon>
          </button>
        </vs-input-container>
        <ng-template #dateMenu>
          <vs-menu>
            <vs-date-picker (selectionChanged)="populateDate($any($event), trigger);"></vs-date-picker>
          </vs-menu>
        </ng-template>
        <span
          class="text-danger"
          slot="hint"
          *ngIf="!form.get('expired')?.valid && (form.get('expired')?.dirty || form.get('expired')?.touched)"
          >Please choose a valid expiry date.</span
        >
      </vs-form-field>
    </div>

    <div class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pt-6">
      <button vs-button class="w-full sm:w-auto" appearance="outline" [disabled]="_submitting" (click)="closeDialog()">
        Cancel
      </button>
      <button
        vs-button
        class="w-full sm:w-auto"
        theme="primary"
        [disabled]="_submitting || !inputIsValid() || !form.dirty"
        (click)="confirm()"
      >
        Generate API Key
        <vs-icon *ngIf="_submitting" name="refresh-cw-02" class="animate-spin"></vs-icon>
      </button>
    </div>
  </form>
</vs-dialog>
<ws-decoded-key-preview-dialog></ws-decoded-key-preview-dialog>
