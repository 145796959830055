/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, HostBinding, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export interface RichTextEditorConfig {
  hideToolbar?: boolean;
  hideBorder?: boolean;
  showContentTopBorder?: boolean;
  squareContentBottomBorder?: boolean;
}

@Component({
  selector: 'vs-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['rich-text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RichTextEditorComponent,
    },
  ],
})
export class RichTextEditorComponent implements OnInit, ControlValueAccessor {
  constructor(private injector: Injector) {}

  @HostBinding('class') styles = 'theme-primary';

  @Input() disabled = false;
  @Input() allowMediaForm = false;
  @Input() allowFileUpload = false;
  @Input() maxLength: number | null = null;
  @Input() config?: RichTextEditorConfig;

  @Input() value = '';

  editor: any = ClassicEditor;

  touched = false;

  control?: NgControl;

  get hideToolbar() {
    return !!this.config?.hideToolbar;
  }

  get hideBorder() {
    return !!this.config?.hideBorder;
  }

  get showContentTopBorder() {
    return !!this.config?.showContentTopBorder;
  }

  get squareContentBottomBorder() {
    return !!this.config?.squareContentBottomBorder;
  }

  onChange = (_value: string) => {};
  onTouched = () => {};

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  editorChanged({ editor }: ChangeEvent) {
    const currentValue = editor.getData();
    this.onChange(currentValue);
    this.markAsTouched();
  }

  ngOnInit() {
    if (this.disabled) return;

    this.control = this.injector.get(NgControl);
    this.control.valueAccessor = this;
  }
}
