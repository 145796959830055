<ng-content select="[slot='top']"></ng-content>

<ng-container *ngIf="config?.logo">
  <ng-container *ngIf="config?.logoRouterLink !== null && config?.logoRouterLink !== undefined; else imageOnly">
    <img
      class="max-h-[200px] self-center focus:outline-none focus:ring-4 ring-gray-100 focus:rounded group"
      routerLinkActive="cursor-default"
      [src]="config.logo"
      [routerLink]="config.logoRouterLink"
      [routerLinkActiveOptions]="{ exact: true }"
      (mousedown)="$event.preventDefault()"
    />
  </ng-container>

  <ng-template #imageOnly>
    <img
      class="max-h-[200px] self-center focus:outline-none focus:ring-4 ring-gray-100 focus:rounded group"
      [src]="config.logo"
    />
  </ng-template>
</ng-container>

<div class="flex-1 min-h-[48px] empty:hidden overflow-y-auto scrollbar-thin scrollbar-margin-fix">
  <ng-content select="[slot='before-items']"></ng-content>

  <div *ngIf="config?.items?.length" class="flex flex-col gap-2 empty:hidden">
    <ng-container *ngFor="let item of config?.items; let index = index">
      <vs-sidebar-nav-item
        [config]="item"
        [open]="openedIndex === index"
        (toggled)="openedIndex = $event ? index : null"
      ></vs-sidebar-nav-item>
    </ng-container>
  </div>

  <ng-content select="[slot='after-items']"></ng-content>

  <ng-content></ng-content>
</div>

<ng-content select="[slot='bottom']"></ng-content>
