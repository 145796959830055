<div *ngIf="document$ | async as document" [class]="getBorderSizeClasses()">
  <div *vsLet="fileType$ | async as fileType" class="flex justify-center max-h-full">
    <img
      *ngIf="fileType === 'image'"
      class="object-cover"
      [src]="document.downloadUrl"
      [class]="getBorderSizeClasses()"
    />

    <div *ngIf="fileType === 'pdf' && !isSafari" class="border overflow-hidden" [class]="getBorderSizeClasses()">
      <object
        *ngIf="downloadUrl$ | async as downloadUrl"
        #obj
        style="width: calc(100% + 18px)"
        class="w-full object-contain rounded-lg h-[calc(50vh-56px)] max-h-full pointer-events-none -ml-px -mt-px"
        [type]="document.mimeType"
        [data]="downloadUrl + '#toolbar=0&navpanes=0&scrollbar=0' | safe : 'resourceUrl'"
      ></object>
    </div>

    <div
      *ngIf="(fileType !== 'image' && fileType !== 'pdf') || (fileType === 'pdf' && isSafari)"
      [class]="'bg-gray-100 flex flex-row justify-center rounded-md ' + getBorderSizeClasses()"
    >
      <vs-card [class]="'bg-base flex flex-row justify-center self-center ' + getContentSizeClasses()">
        <vs-icon [name]="getIcon(fileType)" [size]="getIconSize()" class="text-gray-400 self-center"></vs-icon>
      </vs-card>
    </div>
  </div>
</div>
