import { Injector } from '@angular/core';
import { Routes } from '@angular/router';
import { UnderMaintenanceGuard, UnderMaintenancePage } from '@vsolv/core/downtime/web';
import { BuiltInModel, BUILT_IN_DASHBOARDS } from '@vsolv/packages/dashboarding/web';
import {
  DISTRIBUTOR_PAGE_CONTENT,
  ExpiredDistributorLinkPage,
  OnboardDistributorPage,
} from '@wsphere/distributors/web';
import { InvoicePage } from '@wsphere/sales/web';
import { StaffRoleGuard, STAFF_PERMISSION_ROUTE_DATA } from '@wsphere/staff/web';
import {
  AccountSetupGuard,
  IsStaffGuard,
  OnboardDistributorGuard,
  OneTimeTokenGuard,
  RefreshGuard,
  SignedInUserGuard,
  SignedOutGuard,
  TenantConfigGuard,
} from './guards';
import { LeftNavLayoutComponent } from './layouts';
import {
  AccountSetupPage,
  ForgotPasswordPage,
  NotFoundPage,
  PasswordResetPage,
  RefreshPage,
  ReportingPage,
  SettingsPage,
  SignInPage,
} from './pages';
import { ComponentsPage } from './pages/components/components.page';
import {
  DistributorOnBoardingLinkResolver,
  EmailResolver,
  OneTimeTokenResolver,
  SignedInUserResolver,
  StaffResolver,
  TenantConfigResolver,
} from './resolvers';

export const routes: Routes = [
  { path: '404', component: NotFoundPage },
  { path: '503', component: UnderMaintenancePage },
  {
    path: '',
    canActivate: [UnderMaintenanceGuard, TenantConfigGuard],
    resolve: { config: TenantConfigResolver },
    children: [
      { path: 'invoice', component: InvoicePage },
      {
        path: 'distributor-invoice-package',
        loadComponent: () =>
          import('./pages/distributor-invoice-package/distributor-invoice-package.page').then(
            mod => mod.DistributorInvoicePackagePage
          ),
      },
      {
        path: 'expired-link',
        component: ExpiredDistributorLinkPage,
      },
      {
        path: 'on-boarding/:linkId',
        canActivate: [OnboardDistributorGuard],
        resolve: { link: DistributorOnBoardingLinkResolver },
        component: OnboardDistributorPage,
      },
      {
        path: 'sign-in',
        component: SignInPage,
        canActivate: [SignedOutGuard],
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordPage,
        canActivate: [SignedOutGuard],
      },
      {
        path: 'password-reset',
        component: PasswordResetPage,
        canActivate: [SignedOutGuard, OneTimeTokenGuard],
        resolve: { oneTimeToken: OneTimeTokenResolver, email: EmailResolver },
      },
      {
        path: '',
        canActivate: [SignedInUserGuard],
        resolve: { currentUser: SignedInUserResolver },
        children: [
          {
            path: 'account-setup',
            component: AccountSetupPage,
            canActivate: [AccountSetupGuard],
            resolve: { staff: StaffResolver },
          },
          {
            path: '',
            component: LeftNavLayoutComponent,
            canActivate: [IsStaffGuard],
            resolve: { staff: StaffResolver },
            children: [
              {
                path: 'refresh',
                component: RefreshPage,
                canActivate: [RefreshGuard],
              },
              {
                path: 'reporting/monthly-financials',
                loadComponent: () =>
                  import('./pages/reporting/monthly-financials').then(mod => mod.MonthlyFinancialsPage),
              },
              {
                path: 'reporting',
                component: ReportingPage,
              },
              {
                path: 'sales',
                loadChildren: () => import('@wsphere/sales/web').then(mod => mod.SalesStaffPagesRoutingModule),
              },
              {
                path: 'policies',
                loadChildren: () => import('@wsphere/warranties/web').then(mod => mod.PolicyRoutingModule),
              },
              {
                path: 'distributors',
                loadChildren: () => import('@wsphere/distributors/web').then(mod => mod.DistributorRoutingModule),
                providers: [
                  {
                    provide: DISTRIBUTOR_PAGE_CONTENT,
                    useFactory: () =>
                      import('./components/distributor-page-sales.component').then(
                        comp => comp.DistributorPageSalesComponent
                      ),
                  },
                ],
              },
              {
                path: 'components',
                component: ComponentsPage,
              },
              {
                path: 'user-settings',
                loadChildren: () => import('@wsphere/staff/web').then(m => m.StaffWebRoutingModule),
              },
              {
                path: 'claims',
                loadChildren: () => import('@wsphere/warranties/web').then(m => m.ClaimRoutingModule),
              },
              {
                path: 'provisioning',
                loadChildren: () => import('@wsphere/warranties/web').then(m => m.ProvisioningRoutingModule),
              },
              {
                path: 'warranty',
                loadChildren: () => import('@wsphere/warranties/web').then(m => m.WarrantyManagementRoutingModule),
              },
              {
                path: 'customers',
                loadChildren: () =>
                  import('@wsphere/customer-management/web').then(m => m.CustomerManagementRoutingModule),
              },

              {
                path: 'settings',
                component: SettingsPage,
                data: { [STAFF_PERMISSION_ROUTE_DATA]: 'stf_ViewSettings' },
                canActivate: [StaffRoleGuard],
                children: [
                  {
                    path: '',
                    loadChildren: () => import('@wsphere/tenants/web').then(mod => mod.TenantWebRoutingModule),
                  },
                ],
              },
              {
                path: 'distributor-settings',
                loadChildren: () =>
                  import('@wsphere/distributors/web').then(mod => mod.DistributorSettingsRoutingModule),
              },
              {
                path: 'documents',
                loadChildren: () => import('@vsolv/packages/storage/web').then(mod => mod.STORAGE_ROUTES),
              },
              {
                path: '',
                loadChildren: () => import('@vsolv/packages/dashboarding/web').then(mod => mod.DashboardRoutingModule),
                providers: [
                  {
                    multi: true,
                    provide: BUILT_IN_DASHBOARDS,
                    useValue: <BuiltInModel>{
                      id: 'dsh_builtin_warranties',
                      title: 'Warranties',
                      icon: 'bar-chart-square-01',
                      description: 'High-level program metrics',
                      component: (_injector: Injector) =>
                        import('./dashboards/warranties/warranties.component').then(mod => ({
                          type: mod.WarrantiesDashboardComponent,
                          inputs: {},
                        })),
                    },
                  },
                  {
                    multi: true,
                    provide: BUILT_IN_DASHBOARDS,
                    useValue: <BuiltInModel>{
                      id: 'dsh_builtin_claims',
                      title: 'Claims',
                      icon: 'bar-chart-square-01',
                      description: 'High-level program metrics',
                      component: (_injector: Injector) =>
                        import('./dashboards/claims/claims.component').then(mod => ({
                          type: mod.ClaimsDashboardComponent,
                          inputs: {},
                        })),
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '**', redirectTo: '' },
];
