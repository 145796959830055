import { Component, Input, ViewChild } from '@angular/core';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Claim, Coverage } from '@wsphere/warranties/domain';
import { WarrantyCoverageWithLiabilityInfo } from '../../../warranty-management';

@Component({
  selector: 'ws-claim-coverage-dialog',
  templateUrl: './claim-coverage.dialog.html',
})
export class ClaimCoverageDialog {
  @ViewChild(DialogComponent) dialog?: DialogComponent;

  @Input() item: Claim.ClaimItem.Model | null = null;

  @Input() coverage?: Coverage.Model | null;
  @Input() deductible = 0;
  @Input() liabilityLimit = 0;
  @Input() notApplicable = false;

  @Input() hideDeductibleAndLiabilityLimit = false;

  @Input() warrantyCoverage: WarrantyCoverageWithLiabilityInfo | null = null;

  open() {
    this.dialog?.open();
  }
}
