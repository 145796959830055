import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property } from '@vsolv/packages/properties/domain';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'ws-condition-property-picker-item',
  templateUrl: './condition-property-picker-item.component.html',
  styleUrls: ['./condition-property-picker-item.component.scss'],
})
export class ConditionPropertyPickerItemComponent {
  @PropertyListener('property') property$ = new BehaviorSubject<
    Property.Model<Property.PropertyType> | Conditions.Property | null
  >(null);
  @Input() property!: Property.Model<Property.PropertyType> | Conditions.Property;

  @Input() propertyPath!: string;

  @Input() value: {
    property: Property.Model<Property.PropertyType> | Conditions.Property;
    propertyPath: string;
  } | null = null;

  overlayOpen = false;

  @Output() selectionChanged = new EventEmitter<{
    property: Property.Model<Property.PropertyType> | Conditions.Property;
    propertyPath: string;
  } | null>();

  children$ = this.property$.pipe(
    map(property => {
      if (!property || property.type !== Property.PropertyType.OBJECT) return [];

      return (
        (property as Property.ObjectModel).properties
          ?.sort((a, b) => a.order - b.order)
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          .map(assignment => assignment.property!) ?? []
      );
    })
  );

  setValue(
    value: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null
  ) {
    this.value = value;
    this.selectionChanged.emit(value);
    // menu.close();
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }
}
