<!-- Title -->
<p *vsLet="patchValue$ | async" class="text-sm font-medium text-gray-900 mt-4">Term Details</p>
<p class="text-sm text-gray-600 mb-4">
  Add multiple terms and payments options for this policy. At minimum one term must be configured.
</p>

<div *ngIf="terms.length" class="flex flex-col gap-2 mb-2 overflow-auto max-h-96 scrollbar-thin">
  <vs-card
    *ngFor="let term of terms; let index = index"
    [class]="term.touched && !isTermValid(index) ? 'border-danger-300 shadow-danger-50' : ''"
  >
    <ng-container *vsLet="term.value.expanded as expanded">
      <!-- Form -->
      <form class="flex flex-col gap-4 p-4" [formGroup]="term">
        <div class="flex justify-between">
          <vs-form-field class="-mt-2">
            <div class="flex gap-4 items-center">
              <div class="flex gap-1">
                <label class="text-sm font-medium text-gray-700" for="title-input">Term Title</label>
              </div>

              <input
                vsInput
                id="title-input"
                type="text"
                placeholder="Enter a name for this term"
                formControlName="title"
              />
            </div>

            <span class="text-danger" slot="hint" *ngIf="term.get('title')?.touched && term.get('title')?.errors">
              Title is required. Please enter a customer friendly title.
            </span>
          </vs-form-field>

          <div class="flex justify-end">
            <button
              class="mr-0"
              vs-icon-button
              theme="default"
              appearance="clear"
              class="self-center"
              (click)="deleteTermDialog.open()"
              [disabled]="terms.length < 2"
            >
              <vs-icon name="trash-01"></vs-icon>
            </button>

            <button vs-icon-button theme="default" appearance="clear" class="self-center" (click)="toggleTerm(index)">
              <vs-icon [name]="expanded ? 'chevron-up' : 'chevron-down'"></vs-icon>
            </button>
          </div>

          <!-- Delete term dialog -->
          <vs-dialog #deleteTermDialog [config]="{ dialogContainerClass: 'w-[400px]' }">
            <vs-dialog-header
              [config]="{
                icon: 'trash-01',
                iconTheme: 'primary',
                mode: 'simple',
                title: 'Discard ' + (term.value.title || 'term') + '?',
                subtitle: 'Are you sure you want to remove this term from the policy?'
              }"
            >
            </vs-dialog-header>

            <div class="flex justify-center gap-2 p-4 w-full">
              <button vs-button class="!w-full" theme="default" appearance="outline" (click)="deleteTermDialog.close()">
                Cancel
              </button>
              <button vs-button theme="danger" class="!w-full" (click)="deleteTerm(index); deleteTermDialog.close()">
                Discard term
              </button>
            </div>
          </vs-dialog>
        </div>

        <div *ngIf="expanded">
          <vs-form-field>
            <div class="flex gap-4 items-center">
              <label class="text-sm font-medium text-gray-700" for="years-input">Duration</label>
              <vs-input-group>
                <vs-input-container>
                  <input id="years-input" placeholder="Years" type="number" min="0" formControlName="years" />
                  <span slot="end">Years</span>
                </vs-input-container>

                <vs-input-container>
                  <input id="months-input" placeholder="Months" type="number" min="0" formControlName="months" />
                  <span slot="end">Months</span>
                </vs-input-container>

                <vs-input-container>
                  <input id="days-input" placeholder="Days" type="number" min="0" formControlName="days" />
                  <span slot="end">Days</span>
                </vs-input-container>
              </vs-input-group>
            </div>

            <span
              *ngIf="
                term.touched &&
                (term.get('years')?.errors ||
                  term.get('months')?.errors ||
                  term.get('days')?.errors ||
                  (!term.value.years && !term.value.months && !term.value.days))
              "
              slot="hint"
              class="text-danger"
            >
              Please enter a valid term duration.
            </span>
          </vs-form-field>

          <vs-form-field>
            <div class="flex gap-4 items-center">
              <div class="flex gap-1">
                <label class="text-sm font-medium text-gray-700" for="order-input">Order</label>

                <vs-icon
                  name="info-circle"
                  class="self-center"
                  tooltipStyle="light"
                  [vsTooltip]="{
                    title: 'Term order',
                    subtitle: 'Input a number to set the order for displaying multiple terms offered under a policy.'
                  }"
                ></vs-icon>
              </div>

              <input
                vsInput
                id="order-input"
                type="number"
                placeholder="Enter the order this term should appear in checkout"
                formControlName="order"
              />
            </div>

            <span class="text-danger" slot="hint" *ngIf="term.get('order')?.touched && term.get('order')?.errors">
              Order is required.
            </span>
          </vs-form-field>

          <vs-form-field>
            <div class="flex items-center gap-2">
              <input
                vsInput
                id="onetime"
                type="checkbox"
                name="checkbox"
                forControllName="oneTime"
                [checked]="term.value.oneTimeCheckBox"
                (click)="oneTimeCheckBox(index)"
              />
              <label class="text-sm font-medium text-gray-700" vsCheckboxLabel for="onetime"> One-time payment </label>
            </div>
            <p class="ml-6 text-gray-700 text-sm">Customers will pay for the full warranty at checkout.</p>
          </vs-form-field>

          <vs-form-field>
            <div class="flex items-center gap-2">
              <input
                vsInput
                #installments
                id="installments"
                type="checkbox"
                name="checkbox"
                [checked]="term.value.installmentsCheckBox"
                (click)="installmentsCheckBox(index)"
              />
              <label class="text-sm font-medium text-gray-700" vsCheckboxLabel for="installments"> Installments </label>

              <vs-icon
                name="info-circle"
                tooltipStyle="light"
                [vsTooltip]="{ template: installmentTooltipTemplate }"
              ></vs-icon>
            </div>

            <p class="ml-6 text-gray-700 text-sm">
              Customers will be responsible for paying off their warranty in installments.
            </p>
          </vs-form-field>

          <ng-container *ngIf="term.value.installmentsCheckBox" formArrayName="schedules">
            <div
              class="relative flex flex-col ml-6 pl-4 my-2 gap-3 border-l-2 border-primary-100"
              *ngFor="
                let schedule of $any(term.get('schedules'))?.controls;
                let scheduleIndex = index;
                trackBy: trackByFn
              "
              [formGroupName]="scheduleIndex"
            >
              <vs-form-field>
                <div class="flex gap-4 items-center">
                  <div class="flex gap-1">
                    <label class="text-sm font-medium text-gray-700" for="installment-title">Title</label>
                  </div>
                  <input
                    vsInput
                    id="installment-title"
                    type="text"
                    placeholder="Enter a name for this installment"
                    formControlName="installmentName"
                  />
                </div>
                <span
                  class="text-danger"
                  slot="hint"
                  *ngIf="schedule.get('installmentName')?.touched && !schedule.value?.installmentName"
                >
                  Installment Title is required. Please enter a customer friendly title.
                </span>
                <div class="flex w-full justify-end">
                  <span
                    class="text-danger text-right text-sm"
                    slot="hint"
                    *ngIf="schedule.value.installmentName && schedule.value.installmentName.length > 25"
                  >
                    {{ schedule.value.installmentName.length }}/25
                  </span>
                </div>
              </vs-form-field>

              <vs-form-field
                *vsLet="
                  (term.value?.years || 0) * 365 + (term.value?.months || 0) * 30 + (term.value?.days || 0) as max
                "
                appearance="horizontal"
                [config]="{
                  label: 'Number of Installments',
                  description: schedule.value.installments
                    ? 'Installments will be billed every ' +
                      getInstallments(index, schedule.value?.installments) +
                      ' days'
                    : ''
                }"
              >
                <input
                  vsInput
                  min="2"
                  type="number"
                  id="installments-input"
                  formControlName="installments"
                  [max]="max"
                />
                <span
                  class="text-danger"
                  slot="hint"
                  *ngIf="
                    schedule.get('installments')?.touched &&
                    (schedule.get('installments')?.errors ||
                      schedule.value?.installments > max ||
                      schedule.value?.installments < 2)
                  "
                >
                  Must be greater or equal to 2. Must be less than or equal to {{ max }}
                </span>
              </vs-form-field>

              <vs-form-field appearance="horizontal" [config]="{ label: 'Surcharge per Installment' }">
                <vs-input-container>
                  <input id="percentage-input" type="number" min="-100" max="100" formControlName="percentage" />
                  <span slot="end">%</span>
                  <span
                    class="text-danger"
                    slot="hint"
                    *ngIf="schedule.get('percentage')?.touched && schedule.get('percentage')?.errors"
                  >
                    Must be greater or equal to -100. Must be less than or equal to 100
                  </span>
                </vs-input-container>
              </vs-form-field>
              <button
                vs-icon-button
                class="absolute -left-4 top-4 ml-[2px]"
                appearance="outline"
                size="xs"
                (click)="removeSchedule(term, schedule)"
              >
                <vs-icon name="x-close"></vs-icon>
              </button>
            </div>
            <button vs-button class="!w-full mt-2" (click)="addSchedule(term)" appearance="outline" size="sm">
              Add Schedule
            </button>
          </ng-container>
        </div>
      </form>
    </ng-container>
  </vs-card>
</div>

<button vs-button theme="primary" (click)="newTerm()"><vs-icon name="plus"></vs-icon> Add term</button>

<!-- Buttons -->
<div
  class="flex gap-2 flex-wrap sm:flex-row mt-4"
  [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
>
  <div
    *ngIf="!editing"
    class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
  >
    <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
  </div>

  <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
    <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
    <span class="self-center"> Saving will create a new policy version, the current policy will not be modified </span>
  </div>

  <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
    <button
      vs-button
      appearance="outline"
      class="flex-grow sm:flex-grow-0"
      [class.flex-1]="editing"
      (click)="editing ? close() : goBack()"
    >
      {{ editing ? 'Cancel' : 'Back' }}
    </button>

    <button
      vs-button
      theme="primary"
      class="flex-grow sm:flex-grow-0"
      [class.flex-1]="editing"
      [disabled]="saving || !areTermsValid()"
      (click)="editing ? save() : pageComplete()"
    >
      {{ editing ? 'Save' : 'Next' }}
    </button>
  </div>
</div>

<ng-template #installmentTooltipTemplate>
  <div class="max-w-[240px]">
    <p class="text-gray-700 text-xs font-semibold pb-1">Payment by installments</p>
    <p class="text-gray-500 text-xs font-medium">
      Time between payments is determined based on the number of installments and the duration of the warranty. An
      additional charge is calculated per payment based on the interest rate set below
    </p>
  </div>
</ng-template>
