<vs-dialog [config]="{ dialogContainerClass: 'max-w-[80vw] w-[640px]' }">
  <vs-dialog-header
    class="block mb-8"
    [config]="{
      mode: 'simple',
      showDivider: true,
      hideCloseButton: saving,

      icon: 'x-circle',
      iconTheme: 'danger',
      
      title: 'Cancel warranty',
      subtitle: 'Cancel a warranty and all future payments.'
    }"
  ></vs-dialog-header>

  <div *ngIf="warranty" class="max-h-[80vh] overflow-x-visible overflow-y-auto px-4 pb-4">
    <ng-container *vsLet="openClaims$ | async as openClaims">
      <div
        *ngIf="openClaims?.length as noOfClaims"
        class="mb-6 rounded-xl border border-danger-300 relative overflow-hidden px-3 py-2"
      >
        <div class="bg-danger-50 opacity-50 absolute w-full h-full top-0 left-0 -z-10"></div>

        <div class="grid grid-cols-[auto_1fr] gap-3">
          <div
            class="w-10 min-w-[40px] h-10 flex items-center justify-center bg-base rounded-lg border border-danger-200"
          >
            <vs-icon name="info-circle" size="20" class="text-danger-700"></vs-icon>
          </div>

          <div>
            <p class="font-semibold text-danger-700">
              Action required: active {{noOfClaims > 1 ? 'claims' : 'claim'}}!
            </p>
            <p class="text-sm font-normal text-danger-700">
              There {{noOfClaims > 1 ? 'are' : 'is'}} {{ noOfClaims > 1 ? noOfClaims : 'an' }} open {{noOfClaims > 1 ?
              'claims' : 'claim'}} under this warranty waiting to be processed. To cancel this warranty, please manage
              the {{noOfClaims > 1 ? 'claims' : 'claim'}} first.
            </p>

            <div class="mt-3 flex flex-wrap items-center gap-2">
              <a
                vs-button
                size="sm"
                theme="danger"
                class="flex-1"
                appearance="outline"
                [routerLink]="'/claims' + (noOfClaims === 1 ? '/'+openClaims![0].id : '')"
                [queryParams]="noOfClaims > 1 ? { warrantyId: warranty.id } : undefined"
              >
                <span>Go to {{noOfClaims > 1 ? 'claims' : 'the claim'}}</span>
                <vs-icon name="arrow-right" size="16"></vs-icon>
              </a>

              <a
                *ngIf="warranty.policy?.termsAndConditions?.downloadUrl"
                vs-button
                size="sm"
                class="flex-1"
                target="_blank"
                appearance="outline"
                download="Terms & Conditions.pdf"
                [href]="warranty.policy?.termsAndConditions?.downloadUrl"
              >
                <span>Review Terms & Conditions</span>
                <vs-icon name="file-06" size="16"></vs-icon>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-2 mb-6">
        <div class="grid gap-4 grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-y-3">
          <vs-info-card
            *ngIf="warranty?.customer as customer"
            [config]="{
              imageUrl: customer.user?.photoURL || undefined,
              icon: customer.user?.photoURL ? undefined : 'user-01',
  
              title: customer.name,
              subtitle: customer.email,
              subtitleIcon: 'mail-01'
            }"
          ></vs-info-card>

          <ws-asset-details-card
            *ngIf="warranty?.asset as asset"
            [asset]="asset"
            [minimized]="true"
          ></ws-asset-details-card>
        </div>
        <div class="grid gap-4 grid-cols-[repeat(auto-fit,minmax(200px,1fr))] gap-y-3">
          <vs-info-card
            *ngIf="warranty?.policy as policy"
            [config]="{
              icon: 'file-shield-02',
  
              title: policy.title,
              subtitle: 'Policy No: ' + policy.policyNumber
            }"
          ></vs-info-card>
          <vs-info-card
            *ngIf="warranty && warranty.plan"
            [config]="{
              icon: 'folder-shield',
  
              title: warranty.plan.title,
              subtitle: warranty.contractNumber
            }"
          ></vs-info-card>
        </div>
      </div>

      <p class="mb-2 font-semibold text-gray-700">Warranty Details</p>

      <div class="flex gap-4 mb-6" *ngIf="durationStats$ | async as stats">
        <div class="flex flex-col text-sm basis-1/2">
          <div class="text-gray-700">Warranty duration</div>
          <div class="text-gray-400">
            The progress bar indicates the total of completed days since this warranty was activated.
          </div>
        </div>
        <div class="flex flex-col text-sm basis-1/2 gap-2 text-gray-700">
          <div vsTooltip="Assumes linear earnings on the full retail amount.">
            <span class="font-bold">{{stats.numberOfDaysCompleted}}</span>/{{stats.termDuration}} days completed
            ({{stats.percentage}}% ‣ {{ ((stats.percentage / 100) * (warranty.coverages | pluck: 'price' | sum) / 100 ||
            0) | currency }})
          </div>
          <vs-progress-bar [config]="{percentage: stats.percentage/100}" theme="primary"></vs-progress-bar>
          <div class="flex justify-between">
            <div>{{stats.startDay | date}}</div>
            <div [class.text-gray-300]="stats.percentage !== 100">{{stats.lastDay | date}}</div>
          </div>
        </div>
      </div>

      <div class="mb-4 flex flex-wrap gap-x-4 gap-y-2">
        <div class="flex-1 grid grid-cols-2 gap-y-2 min-w-[200px]">
          <p class="truncate text-sm font-medium text-gray-700">
            {{(payments$ | async)?.length ? 'Purchased' : 'Provisioned'}} on
          </p>
          <p
            class="truncate text-sm font-normal text-gray-900"
            tooltipOpenDelay="300"
            [vsTooltip]="{
              title: 'Purchased on ' + (warranty.created | date),
              subtitle: 'Term started on ' + (warranty.termStart | date)
            }"
          >
            {{warranty.created | date}}
          </p>
          <vs-divider class="col-span-2"></vs-divider>

          <ng-container *ngIf="(payments$ | async)?.length; else noPayments">
            <p class="truncate text-sm font-medium text-gray-700">Total paid to date</p>
            <p
              *vsLet="totalPaidToDate$ | async as totalPaidToDate"
              class="truncate text-sm font-normal text-gray-900"
              tooltipOpenDelay="300"
              [vsTooltip]="{
            title: ((totalPaidToDate?.amount ?? 0) / 100 | currency) + ' paid to date',
            subtitle: (totalPaidToDate?.installments ?? 0) + ' installment' + (totalPaidToDate?.installments !== 1 ? 's' : '')
          }"
            >
              {{(totalPaidToDate?.amount ?? 0) / 100 | currency}}
            </p>
          </ng-container>
          <ng-template #noPayments>
            <p class="truncate text-sm font-medium text-gray-700">Retail price</p>
            <p class="truncate text-sm font-normal text-gray-900">
              {{ ((warranty.coverages | pluck: 'price' | sum) / 100 || 0) | currency }}
            </p>
          </ng-template>
          <vs-divider class="col-span-2"></vs-divider>
        </div>

        <div class="flex-1 grid grid-cols-2 gap-y-2 min-w-[200px]">
          <p class="truncate text-sm font-medium text-gray-700">Total claimed</p>
          <p
            *vsLet="totalClaimed$ | async as totalClaimed"
            class="truncate text-sm font-normal text-gray-900"
            [class.!text-gray-300]="!openClaims?.length && !totalClaimed?.amount"
          >
            {{(totalClaimed?.amount ?? 0) / 100 | currency}}
          </p>
          <vs-divider class="col-span-2"></vs-divider>

          <ng-container *ngIf="(payments$ | async)?.length; else nothingToRefund">
            <ng-container *vsLet="totalTaxPaid$ | async as totalTaxPaid">
              <p class="truncate text-sm font-medium text-gray-700">
                Total tax paid ({{totalTaxPaid?.percentage ?? 0}}%)
              </p>
              <p class="truncate text-sm font-normal text-gray-900">{{(totalTaxPaid?.amount ?? 0) / 100 | currency}}</p>
            </ng-container>
          </ng-container>
          <ng-template #nothingToRefund>
            <p class="truncate text-sm font-medium text-gray-700">Payment</p>
            <p class="truncate text-sm font-normal text-gray-300">External</p>
          </ng-template>
          <vs-divider class="col-span-2"></vs-divider>
        </div>
      </div>

      <p class="mt-6 mb-3 font-semibold text-gray-700">Cancellation Details</p>
      <vs-form-field
        class="mb-4"
        appearance="horizontal"
        [config]="{
          label: 'Effective Date',
          description: 'Effective date of the cancellation.'
        }"
      >
        <vs-input-container>
          <input
            #dateInput
            [value]="effectiveDate.toISOString().split('T')[0]"
            class="picker-indicator-hidden"
            type="date"
            placeholder="Select a cancellation date"
          />
          <button
            vs-icon-button
            size="xs"
            appearance="clear"
            slot="end"
            [vsMenuTriggerFor]="dateMenu"
            #trigger="vsMenuTriggerFor"
          >
            <vs-icon vsTooltip="Select date" name="calendar" size="16"></vs-icon>
          </button>
        </vs-input-container>
        <ng-template #dateMenu>
          <vs-menu>
            <vs-date-picker
              *vsLet="durationStats$ | async as stats"
              vs-menu-item
              [value]="effectiveDate"
              [minDate]="stats?.startDay"
              [maxDate]="today"
              (selectionChanged)="trigger.close(); effectiveDate = $any($event) ?? today"
            ></vs-date-picker>
          </vs-menu>
        </ng-template>
      </vs-form-field>

      <ng-container *ngIf="payments$ | async as payments">
        <ng-container *ngIf="payments.length">
          <vs-form-field
            class="mb-4"
            appearance="horizontal"
            [config]="{
              label: 'Refund amount',
              description: 'Amount to be refunded to the customer, including all applicable taxes.'
            }"
          >
            <input
              #refundInput
              vsInput
              currencyMask
              type="text"
              placeholder="$0.00"
              [formControl]="refundControl"
              [options]="currencyMaskConfig"
              (blur)="refundInput.setCustomValidity(refundControl.valid ? '' : 'invalid')"
            />

            <p *ngIf="totalPaidToDate$ | async as total" slot="hint">Max {{total.amount / 100 | currency}}</p>
          </vs-form-field>
        </ng-container>
      </ng-container>

      <div class="flex flex-wrap justify-end gap-2">
        <button vs-button appearance="outline" [disabled]="saving" (click)="close()">Cancel</button>
        <button
          vs-button
          theme="danger"
          [disabled]="saving|| refundControl.invalid || !!openClaims?.length  "
          (click)="confirmationDialog.open()"
        >
          Confirm cancellation
        </button>
      </div>
    </ng-container>
  </div>
</vs-dialog>

<vs-dialog #confirmationDialog (closed)="$event && confirm()">
  <vs-dialog-header
    *vsLet="refundControl.value | currency as refund"
    class="block mb-6"
    [config]="{
      mode: 'simple',
      showDivider: false,
      hideCloseButton: true,

      iconTheme: 'primary',
      icon: 'alert-triangle',
      
      title: 'Confirmation',
      subtitle: 'You are about to cancel this warranty' + ((payments$ | async)?.length ? ' and all future payments' : '') + (refundControl.value ? (' along with processing a ' + refund + ' refund') : '') + '. Are you sure you want to proceed?'
    }"
  ></vs-dialog-header>

  <div class="flex items-center gap-2">
    <button vs-button class="flex-1" appearance="outline" (click)="confirmationDialog.close(false)">Cancel</button>
    <button vs-button class="flex-1" theme="primary" (click)="confirmationDialog.close(true)">Confirm</button>
  </div>
</vs-dialog>
