/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import { RoleAssignmentsService } from '../../services';
import { FullAdminWarningDialog } from '../full-administrator-warning-dialog';

@Component({
  selector: 'ws-edit-team-member-dialog',
  templateUrl: './edit-team-member.dialog.html',
})
export class EditTeamMemberDialog {
  constructor(private fb: FormBuilder, private toastSvc: ToastService, private roleSvc: RoleAssignmentsService) {}

  @ViewChild(FullAdminWarningDialog) private warningDialog?: FullAdminWarningDialog;

  @PropertyListener('teamMember') staff$ = new BehaviorSubject<Staff.Model | undefined>(undefined);
  @Input() teamMember?: Staff.Model;

  @PropertyListener('permissionKey') permissionKey$ = new BehaviorSubject<string | null>(null);
  @Input() permissionKey: string | null = null;

  @Input() distributor?: Distributor.Model;

  @Output() memberUpdated = new EventEmitter();
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  _submitting = false;
  resetFormValue = {
    memberRole: null,
  };

  roles$ = combineLatest([this.staff$, this.permissionKey$]).pipe(
    switchMap(async ([staff, permissionKey]) => {
      if (!staff) return [];

      return (await this.roleSvc.getStaffRoles(staff.id)).filter(role => role.permissionKey === permissionKey);
    })
  );

  roleIds$ = this.roles$.pipe(map(roles => roles.map(role => role.roleId)));

  readonly form = this.fb.group({
    memberRole: [null as any, [Validators.required]],
  });

  async addRole(roleId: string) {
    if (!this.teamMember) throw new Error('No team member provided to dialog');
    try {
      await this.roleSvc.createRoleAssignment({
        roleId,
        staffId: this.teamMember.id,
        permissionKey: this.permissionKey || undefined,
      });
      this.toastSvc.show({
        type: 'success',
        title: 'Member successfully updated',
        text: `Added role ${Staff.Security.getRole(roleId)?.display.title} to ${this.teamMember?.name}`,
      });

      if (roleId === '*') {
        this.warningDialog?.open(this.distributor?.name);
      }
    } catch (err) {
      console.error(err);
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: 'Something went wrong and the member was not updated. Please try again.',
      });
    }
  }

  async removeRole(roleId: string) {
    if (!this.teamMember) throw new Error('No team member provided to dialog');
    try {
      await this.roleSvc.removeRoleAssignment({
        roleId,
        staffId: this.teamMember.id,
        ...(this.permissionKey && { permissionKey: this.permissionKey }),
      });
      this.toastSvc.show({
        type: 'success',
        title: 'Member successfully updated',
        text: `Removed role ${Staff.Security.getRole(roleId)?.display.title} from ${this.teamMember?.name}`,
      });
    } catch (err) {
      console.error(err);
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: 'Something went wrong and the member was not updated. Please try again.',
      });
    }
  }

  inputIsValid() {
    return this.form.valid;
  }

  async resetForm() {
    try {
      this.form.reset(this.resetFormValue);
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  closeDialog() {
    this.memberUpdated.emit();
    this.dialog.close();
  }

  openDialog() {
    this.dialog.open();
  }
}
