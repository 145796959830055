<form [formGroup]="form">
  <vs-form-field [config]="{ label: 'Name on card' }" appearance="vertical" class="mb-4">
    <input
      #nameInput
      vsInput
      required
      type="text"
      placeholder="Full name on card"
      formControlName="name"
      (blur)="touched$.next()"
      (change)="nameInput.setCustomValidity(form.controls.name.valid ? '' : 'invalid')"
    />

    <p *ngIf="form.controls.name.invalid && form.controls.name.touched" slot="hint" class="text-danger">
      Required - Please provide the cardholder's name
    </p>
  </vs-form-field>

  <vs-form-field [config]="{ label: 'Card information' }" appearance="vertical">
    <vs-input-group formGroupName="card">
      <div class="w-full">
        <vs-input-container class="!rounded-tl-md !rounded-tr-md !rounded-bl-none !rounded-br-none">
          <vs-icon slot="start" name="credit-card-02"></vs-icon>
          <input
            #cardNumberInput
            required
            type="text"
            formControlName="number"
            [mask]="cardMask$ | async"
            [validation]="false"
            placeholder="1234 1234 1234 1234"
            (blur)="touched$.next()"
            (change)="cardNumberInput.setCustomValidity(form.controls.card.controls.number.valid ? '' : 'invalid')"
          />
        </vs-input-container>

        <div class="flex">
          <input
            #cardExpirationInput
            vsInput
            required
            type="text"
            mask="00 / 00"
            placeholder="MM/YY"
            formControlName="expiration"
            class="!m-0 !-mt-px !rounded-tl-none !rounded-tr-none !rounded-bl-md !rounded-br-none"
            (blur)="touched$.next()"
            (change)="
              cardExpirationInput.setCustomValidity(form.controls.card.controls.expiration.valid ? '' : 'invalid')
            "
          />

          <input
            #cardCVCInput
            vsInput
            required
            type="text"
            mask="0009"
            placeholder="CVC"
            formControlName="cvc"
            class="!m-0 !-mt-px !-ml-px !rounded-tl-none !rounded-tr-none !rounded-bl-none !rounded-br-md"
            (blur)="touched$.next()"
            (change)="cardCVCInput.setCustomValidity(form.controls.card.controls.cvc.valid ? '' : 'invalid')"
          />
        </div>
      </div>
    </vs-input-group>
  </vs-form-field>
</form>
