import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Payment } from '@vsolv/packages/payments/domain';

@Injectable()
export class PaymentResolver implements Resolve<Payment.Model | null> {
  payment: Payment.Model | null = null;

  resolve() {
    return this.payment;
  }
}
