import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { OneTimeToken } from '@vsolv/core/users/domain';

@Injectable({ providedIn: 'root' })
export class OneTimeTokenService {
  constructor(private http: HttpClient) {}

  async getOne(token: string) {
    return firstValueFrom(this.http.get<OneTimeToken.Model>(`/v1/tokens/${token}`));
  }
}
