<div class="flex justify-center w-full">
  <div class="flex flex-col gap-4 relative min-h-[168px] w-full pt-4">
    <img class="h-48 w-48 self-center" [src]="'assets/empty-state/' + config?.imgsrc + '.svg'" />

    <div *ngIf="config?.title" class="flex flex-col self-center w-full lg:max-w-[50%] max-w-[352px] z-10">
      <p *ngIf="config?.title" class="font-semibold text-gray-900 text-center">{{ config?.title }}</p>
      <p *ngIf="config?.subtitle" class="text-sm font-normal text-gray-600 text-center">{{ config?.subtitle }}</p>
    </div>

    <div class="flex justify-center self-center lg:max-w-[50%] max-w-[352px] z-10">
      <ng-content select="[slot='button']"></ng-content>
    </div>
  </div>
</div>
