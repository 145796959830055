/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToastService } from '@vsolv/vectors-ui/alert';
import * as mime from 'mime';
import { InputBoxDirective } from './input-box.directive';

@Directive({
  standalone: true,
  exportAs: 'vsInput',
  selector: `
    input[type="file"][vsInput],
  `,
  hostDirectives: [InputBoxDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileInputDirective,
    },
  ],
})
export class FileInputDirective implements ControlValueAccessor {
  @HostBinding('class') get styles() {
    const customAppearance = `
      cursor-pointer
      text-[0]
      before:transition-colors
      before:content-['Click_to_select_file']
      before:inline-block
      before:pt-[2px]
      before:pb-[2px]
      before:text-sm
      before:text-themed-600
      before:font-semibold
      before:bg-base
      after:transition-colors
      after:inline
      after:text-sm
      after:text-gray-400
      after:bg-base
      after:leading-[17px]
      [&::-webkit-file-upload-button]:invisible
      [&::-webkit-file-upload-button]:hidden
    `;

    const disabled = `
      disabled:before:opacity-50
      disabled:after:opacity-50
    `;

    const background = `
      enabled:bg-upload
      bg-no-repeat
      [background-position:2rem]
    `;

    const afterContent = this.getAfterContent(this.moreInfo, this.isCSV);

    const box = `text-center pr-3 ${this.showIcon ? 'pl-24' : 'pl-3'} disabled:pl-8 pb-8 pt-6 w-full`;
    const dragging = this.dragging
      ? `
      bg-themed-50
      !border-2
      !border-themed-600
      before:bg-themed-50
      after:bg-themed-50
    `
      : ``;
    return `theme-primary-inherit ${box} ${background} ${customAppearance} ${afterContent} ${dragging} ${disabled}`;
  }

  @Input() showIcon = true;
  @Input() isCSV?: boolean;

  private dragging = false;

  fileOrFiles?: File | File[] | null;
  private touched = false;
  private disabled = false;

  get files() {
    return this.elementRef.nativeElement.files;
  }

  @Input() set multiple(value: boolean) {
    this.elementRef.nativeElement.multiple = value;
  }

  @Input() set accept(value: string) {
    this.elementRef.nativeElement.accept = value;
  }

  @Input() set moreInfo(value: string) {
    this.elementRef.nativeElement.moreInfo = value;
  }

  get moreInfo() {
    return this.elementRef.nativeElement.moreInfo;
  }

  errors: string[] = [];
  @Output() errorsEmitter = new EventEmitter<string[]>();

  onChange = (_file: File | File[] | null) => {};
  onTouched = () => {};
  constructor(private elementRef: ElementRef, private toastSvc: ToastService, private renderer: Renderer2) {}

  @HostListener('input')
  inputChanged() {
    try {
      this.validateAcceptedFileTypes();

      this.fileOrFiles = this.elementRef.nativeElement.multiple
        ? [...(this.fileOrFiles as File[]), ...this.elementRef.nativeElement.files]
        : this.elementRef.nativeElement.files[0];

      this.removeDuplicates();

      this.markAsTouched();
      this.onChange(this.fileOrFiles || null);
      this.elementRef.nativeElement.value = null;
    } catch (error) {
      this.toastSvc.show({
        type: 'error',
        title: 'Failure',
        text: `Invalid File: ${(error as Error)?.message}`,
      });
    }
  }

  @HostListener('dragover')
  dragOver() {
    this.dragging = true;
  }

  @HostListener('dragleave')
  @HostListener('dragend')
  @HostListener('drop')
  dragLeave() {
    this.dragging = false;
  }

  getAfterContent(moreInfo?: string, isCSV?: boolean) {
    if (!isCSV && moreInfo)
      return String.raw`after:content-['_or_drag_&_drop\a'_attr(accept)_'_('_attr(ng-reflect-more-info)_')'] whitespace-pre`;
    if (isCSV && moreInfo)
      return String.raw`before:content-['Click_to_upload_CSV'] after:content-['\a'_attr(ng-reflect-more-info)] whitespace-pre`;
    else return String.raw`after:content-['_or_drag_&_drop\a '_attr(accept)] whitespace-pre`;
  }

  clear(index?: number) {
    if (index !== null && index !== undefined && index >= 0) {
      const filesArray = Array.from(this.fileOrFiles as File[]);
      filesArray.splice(index, 1);
      this.fileOrFiles = filesArray;
    } else {
      this.elementRef.nativeElement.value = '';
      this.fileOrFiles = null;
    }

    this.removeDuplicates();
    this.markAsTouched();
    this.onChange(this.fileOrFiles);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(fileOrFiles: File | File[] | null): void {
    this.fileOrFiles = fileOrFiles;
  }

  registerOnChange(onChange: (fileOrFiles: File | File[] | null) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
    this.elementRef.nativeElement.disabled = this.disabled;
  }

  private getAcceptedMimeTypes() {
    const accept = this.elementRef.nativeElement.accept as string;
    if (!accept) return null;

    return accept
      .split(',')
      .map(type => mime.getType(type) + ' ')
      .join('');
  }

  private validateAcceptedFileTypes() {
    const multiple: boolean = this.elementRef.nativeElement.multiple;
    if (multiple) {
      const files: File[] = this.elementRef.nativeElement.files;
      for (const file of files) {
        if (!file.type) throw new Error(file.name);
        const mimes = this.getAcceptedMimeTypes();
        const isValid = mimes === null ? true : mimes.includes(file.type);
        if (!isValid) throw new Error(file.name);
      }
    } else {
      const file: File = this.elementRef.nativeElement.files[0];
      if (!file.type) throw new Error(file.name);
      const mimes = this.getAcceptedMimeTypes();
      const isValid = mimes === null ? true : mimes.includes(file.type);
      if (!isValid) throw new Error(file.name);
    }
  }

  private removeDuplicates() {
    this.errors = [];
    if (Array.isArray(this.fileOrFiles)) {
      this.fileOrFiles = this.fileOrFiles.filter((value, index, self) => {
        const multiple = self.filter(x => x.name === value.name);
        if (multiple.length > 1 && !this.errors.includes(value.name)) this.errors.push(value.name);
        return index === self.findIndex(t => t.name === value.name);
      });
    }
    this.errorsEmitter.emit(this.errors);
  }
}
