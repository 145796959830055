<vs-dialog #exportPoliciesDialog [config]="dialogConfig ?? { dialogContainerClass: 'max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'download-01',
      iconTheme: 'primary',

      title: 'Export Plan Coverage Configs',
      subtitle: 'Click the button below to begin your download'
    }"
  ></vs-dialog-header>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex justify-center">
      <a
        vs-button
        size="md"
        theme="primary"
        class="transition-all duration-300"
        [class.flex-grow]="!href"
        [appearance]="href ? 'outline' : 'default'"
        (click)="!href ? downloadPricingCSV() : ''"
      >
        <vs-icon *ngIf="href" name="download-01"></vs-icon>
        <span *ngIf="!href">Download</span>
        <a *ngIf="href" download="Exported_Plan_Coverage_Configs.csv" [href]="href">Get File</a>
      </a>
    </div>
  </div>
</vs-dialog>
