import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Policy } from '@wsphere/warranties/domain';

@Injectable()
export class PolicyResolver implements Resolve<Policy.Model | null> {
  policy!: Policy.Model | null;

  async resolve(): Promise<Policy.Model | null> {
    return this.policy || null;
  }
}
