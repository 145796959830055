import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  inject,
  Input,
} from '@angular/core';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg';
export type ButtonAppearance = 'default' | 'clear' | 'outline';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[vs-button], a[vs-button]',
  exportAs: 'vsButton',
  templateUrl: 'button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[disabled]': 'disabled',
  },
})
export class ButtonComponent {
  public readonly elementRef = inject(ElementRef);

  @HostBinding('class') get styles(): string {
    const base = `flex w-fit whitespace-nowrap justify-center items-center ring-themed-100 select-none`;
    const active = `active:ring-4`;
    const focus = `focus:outline-none focus:ring-4`;
    const disabled = `disabled:opacity-50 disabled:pointer-events-none`;
    return `${base} ${this.getTypeClasses()} ${active} ${focus} ${disabled} ${this.getSizeClasses()} ${
      this.rounded ? 'rounded-full' : 'rounded-lg'
    }`;
  }

  @Input() size: ButtonSize = 'md';
  @Input() appearance: ButtonAppearance = 'default';

  @Input()
  get rounded() {
    return this._rounded;
  }
  set rounded(value: BooleanInput) {
    this._rounded = coerceBooleanProperty(value);
  }
  private _rounded = false;

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  @HostListener('mousedown', ['$event'])
  preventKeyboardFocusAfterClick = (event: MouseEvent) => event.preventDefault();

  getTypeClasses(): string {
    switch (this.appearance) {
      default:
      case 'default':
        return 'bg-themed-500 border border-themed-600 hover:bg-themed-600 hover:border-themed-700 text-gray-50 active:bg-themed active:border-themed-600 shadow-sm';
      case 'outline':
        return 'bg-base active:bg-base hover:bg-themed-50 text-themed-700 hover:text-themed-800 active:text-themed-700  border border-themed-300 shadow-sm';
      case 'clear':
        return 'bg-transparent active:bg-transparent hover:bg-themed-50 text-themed-700 hover:text-themed-800 active:text-themed-700 ';
    }
  }

  getSizeClasses(): string {
    switch (this.size) {
      case 'xs':
        return 'h-6 px-2 gap-1 text-xs font-semibold';
      case 'sm':
      default:
        return 'h-8 px-3 gap-2 text-sm font-semibold';
      case 'md':
        return 'h-10 px-4 gap-3 text-md font-semibold';
      case 'lg':
        return 'h-12 px-4 gap-3 text-md font-semibold';
    }
  }
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'button[vs-icon-button], a[vs-icon-button]',
  exportAs: 'vsIconButton',
  templateUrl: 'button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[disabled]': 'disabled',
  },
})
export class IconButtonComponent extends ButtonComponent {
  override getSizeClasses(): string {
    switch (this.size) {
      case 'xs':
        return 'h-6 w-6 gap-1 text-xs px-3 py-2 font-semibold';
      case 'sm':
      default:
        return 'h-8 w-8 gap-2 text-sm font-semibold';
      case 'md':
        return 'h-10 w-10 gap-3 text-md font-semibold';
      case 'lg':
        return 'h-12 w-12 gap-3 text-md font-semibold';
    }
  }
}
