<ng-container *vsLet="isLargeScreen$ | async as isLargeScreen">
  <ng-container *ngIf="policies$ | async as policies">
    <ng-container *ngIf="policies.length; else noPlans">
      <!-- policy grid -->
      <div
        class="grid grid-cols-2 gap-3 max-h-[50vh] overflow-y-auto scrollbar-thin -m-1 p-1"
        [class.grid-cols-2]="policies.length > 1 && isLargeScreen"
      >
        <ws-policy-details-card
          *ngFor="let policy of policies"
          [policy]="policy"
          [staffView]="staffView"
          [selected]="policy.id === selected?.id"
          (clicked)="selectPolicy(policy)"
        ></ws-policy-details-card>
      </div>
    </ng-container>

    <ng-template #noPlans>
      <ws-no-plans-available-card
        [hideBack]="true"
        [propertySetName]="propertySet?.name || 'asset'"
      ></ws-no-plans-available-card>
    </ng-template>
  </ng-container>
</ng-container>
