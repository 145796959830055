<vs-dialog #createVersionDialog [config]="{ dialogContainerClass: 'w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,

      icon: 'plus',
      iconTheme: 'primary',

      title: 'Create New Policy Version',
      subtitle: 'Continuing will create a new version of ' + policy?.title + '.'
    }"
  ></vs-dialog-header>

  <div class="flex gap-2 text-danger-500 text-sm mb-4">
    <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
    <span class="self-center"> Saving will create a new policy version, the current policy will not be modified </span>
  </div>

  <div class="buttons flex gap-4">
    <button
      class="flex-grow"
      vs-button
      size="md"
      appearance="outline"
      [disabled]="saving"
      (click)="createVersionDialog.close()"
    >
      Cancel
    </button>

    <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="createNewVersion()">
      Create
    </button>
  </div>
</vs-dialog>
