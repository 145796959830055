import { Staff } from '../lib/staff';

export type StoragePermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'str_View',
    display: {
      title: 'View Documents',
      description: 'Ability to view uploaded documents and their details',
    },
  },
  {
    id: 'str_Upload',
    display: {
      title: 'Upload Documents',
      description: 'Ability to upload documents',
    },
  },
  {
    id: 'str_Delete',
    display: {
      title: 'Delete Documents',
      description: 'Ability to delete documents and their associated data',
    },
  },
  {
    id: 'str_Update',
    display: {
      title: 'Update Documents',
      description: 'Ability to update document details',
    },
  },
  {
    id: 'str_ViewProcessors',
    display: {
      title: 'View Document Processors',
      description: 'Ability to view details of the available document processors',
    },
  },
  {
    id: 'str_ViewProcessorRuns',
    display: {
      title: 'View Document Processor Runs',
      description: 'Ability to view document processor runs and their outputs',
    },
  },
  {
    id: 'str_CreateProcessorRun',
    display: {
      title: 'Create Document Processor Runs',
      description: 'Ability to create/initiate new document processor runs',
    },
  },
  {
    id: 'str_DeleteProcessorRun',
    display: {
      title: 'Delete Document Processor Runs',
      description: 'Ability to delete document processor runs and their outputs',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
