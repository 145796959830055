/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property } from '@vsolv/packages/properties/domain';

@Pipe({ name: 'transformPropertyValue' })
export class TransformPropertyValuePipe implements PipeTransform {
  transform(
    value: any,
    config: {
      property: Property.Model<Property.PropertyType> | Conditions.Property;
      showHidden?: boolean;
      showLiteralBoolean?: boolean;
    }
  ): string | null {
    const property = config.property;
    const showHidden = config.showHidden ?? false;
    const showLiteralBoolean = config.showLiteralBoolean ?? false;

    if (property.type === Property.PropertyType.OBJECT) {
      const assignments = (property as Property.ObjectModel)
        .properties!.filter(assignment => (showHidden ? true : !assignment.hidden))
        .sort((a, b) => a.order - b.order);
      const values: string[] = [];
      for (const assignment of assignments) {
        const property = assignment.property!;

        if (property.type === Property.PropertyType.OBJECT) continue;

        const propertyValue = this.extract(value[property.valueKey], property, showLiteralBoolean);

        if (propertyValue != null) {
          values.push(propertyValue);
        }
      }

      return values?.length ? values.join(', ') : null;
    } else return this.extract(value, property, showLiteralBoolean);
  }

  private extract(
    value: any,
    property: Property.Model<Property.PropertyType> | Conditions.Property,
    showLiteralBoolean: boolean
  ) {
    if (!value && property.type === Property.PropertyType.BOOLEAN && showLiteralBoolean) {
      return 'false';
    }

    if (!value && property.type !== Property.PropertyType.NUMBER) return null;
    const typeOfValue = typeof value;

    if (typeOfValue === 'string') {
      return value;
    } else if (typeOfValue === 'number' || !value) {
      if (property.config.format === 'currency') {
        const currencyPipe = new CurrencyPipe('en-US');

        return currencyPipe.transform((value ?? 0) / 100, 'US', 'symbol')?.replace('US', '$');
      }
      return (value as number).toString();
    } else if (typeOfValue === 'object') {
      return new Date(value).toDateString();
    } else if (typeOfValue === 'boolean') {
      if (!showLiteralBoolean && value === true) {
        return property.name;
      } else if (showLiteralBoolean) {
        return value ?? false;
      }
    }

    return null;
  }
}
