/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @angular-eslint/component-class-suffix */
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'ws-preview-pdf-dialog',
  templateUrl: './preview-pdf.dialog.html',
})
export class PreviewPDFDialog {
  constructor(private httpClient: HttpClient) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() document: Policy.Document | null = null;
  @Input() dialogConfig?: DialogConfig;

  async downloadFile() {
    if (!document || !this.document?.downloadUrl) return;

    try {
      const response = await firstValueFrom(this.httpClient.get(this.document.downloadUrl, { responseType: 'blob' }));

      const url = URL.createObjectURL(response);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', this.document.title + '.pdf');
      link.click();
    } catch (err) {
      console.log(err);
    }
  }

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.dialog.close();
  }
}
