<div class="flex flex-col justify-center items-center w-full gap-4 mt-16">
  <div class="flex flex-col justify-center items-center w-full gap-4">
    <img class="max-h-12" src="{{ portalSvc.config.logo }}" />

    <form
      class="flex flex-col flex-wrap gap-4 justify-center items-center w-full max-w-[360px]"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <ng-container *ngIf="state === 'name'">
        <h1 class="text-3xl">What should we call you?</h1>
        <p class="text-gray">Your teammates will see this name</p>

        <vs-form-field [config]="{ label: 'Name' }">
          <input vsInput type="text" required placeholder="Enter your name" formControlName="name" />
          <span class="text-danger" slot="hint" *ngIf="form.controls['name'].invalid">Name is required</span>
        </vs-form-field>

        <button
          vs-button
          class="w-full"
          theme="primary"
          [disabled]="form.controls['name'].invalid"
          (click)="state = 'password'"
          size="md"
        >
          Continue
        </button>
      </ng-container>

      <ng-container *ngIf="state === 'password'">
        <h1 class="text-3xl">Set new password</h1>
        <p class="text-gray max-w-[360px] text-center">A strong password is important for keeping your account safe.</p>

        <vs-form-field [config]="{ label: 'Password*' }">
          <input
            vsInput
            required
            #passwordInput
            type="password"
            placeholder="Choose a password"
            formControlName="password"
          />
          <span
            class="text-danger"
            slot="hint"
            *ngIf="(passwordInput.value.length > 0 && form.get('password')?.touched && form.get('password')?.errors ? 'Your password must be at least 8 characters. It must also contain uppercase letters, lowercase letters, and numbers.' : null) as error"
            >{{error}}</span
          >
        </vs-form-field>

        <vs-form-field [config]="{ label: 'Confirm password*' }">
          <input
            required
            #confirmInput
            vsInput
            type="password"
            placeholder="Confirm password"
            formControlName="confirmPassword"
          />
          <span
            class="text-danger"
            slot="hint"
            *ngIf="(passwordInput.value !== confirmInput.value && form.get('confirmPassword')?.touched ||  passwordInput.value !== confirmInput.value && passwordInput.value.length === confirmInput.value.length ? 'Passwords do not match!' : null) as error"
            >{{error}}</span
          >
        </vs-form-field>

        <button
          vs-button
          class="w-full"
          theme="primary"
          [disabled]="form.controls['password'].invalid || form.value.password !== form.value.confirmPassword"
          (click)="state = 'password'"
          size="md"
        >
          Get Started
        </button>

        <a class="flex justify-center items-center text-gray-600 cursor-pointer gap-1" (click)="state = 'name'">
          <vs-icon name="arrow-left" type="solid"></vs-icon>
          Back
        </a>
      </ng-container>
    </form>
  </div>
</div>
