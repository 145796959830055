<div *ngIf="item" class="flex flex-col gap-4 p-6">
  <div class="flex gap-2">
    <p class="text-sm font-medium text-gray-700 basis-1/4">Title</p>
    <p class="text-sm font-normal text-gray-600 basis-3/4">{{ item.cure?.title }}</p>
  </div>

  <vs-divider></vs-divider>

  <div class="flex gap-2">
    <p class="text-sm font-medium text-gray-700 basis-1/4">Description</p>
    <p class="text-sm font-normal text-gray-600 basis-3/4">{{ item.cure?.description }}</p>
  </div>

  <vs-divider></vs-divider>

  <div class="flex gap-2">
    <p class="text-sm font-medium text-gray-700 basis-1/4">Estimated Cost</p>
    <p class="text-sm font-normal text-gray-600 basis-3/4">
      {{ (item.cure?.estimatedCost || 0) / 100 | currency }}
    </p>
  </div>
</div>
