/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Asset } from '@wsphere/assets/domain';
import { AssetService } from '@wsphere/assets/web';
import { Provisioning } from '@wsphere/warranties/domain';
import { map, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { ProvisioningSessionService } from '../../services';

@Component({
  selector: 'ws-provision-asset',
  templateUrl: './provision-asset.component.html',
})
export class ProvisionAssetComponent implements OnInit, OnDestroy {
  constructor(private assetSvc: AssetService) {}

  @HostBinding('class') _class = 'pb-24 px-6';

  private sessionSvc = inject(ProvisioningSessionService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private breakpointObserver = inject(BreakpointObserver);
  private toastSvc = inject(ToastService);

  protected stepCompleted = false;
  protected destroy$ = new Subject<void>();

  protected createNew = false;

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  protected session$ = this.sessionSvc.getCurrentSession().pipe(
    tap(session => {
      if (session?.asset) {
        this.asset.patchValue(session.asset);
        this.stepCompleted = true;
      }
    })
  );

  protected asset = new FormControl(null as Asset.Model | null);

  readonly staffView$ = this.route.data.pipe(map(data => data['staffView'] as boolean));

  assets$ = this.session$.pipe(
    switchMap(async session => {
      const customerId = session?.customerId;
      const propertySetId = session?.policy?.propertySetId;
      return customerId
        ? (await this.assetSvc.getCustomerAssets(customerId, propertySetId)).filter(asset => asset.submissions?.length)
        : [];
    })
  );

  private navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }

  previousPage(staffView: boolean) {
    if (staffView) this.navigateTo('/provisioning/policy');
    else this.navigateTo('/checkout/policy');
  }

  async nextPage(session: Provisioning.ProvisioningSession | null, staffView: boolean) {
    const asset = this.asset.value?.id;
    if (!asset) {
      this.toastSvc.show({
        type: 'error',
        title: 'Invalid asset selected',
        text: `Sorry, looks like something went wrong with your asset selection. Please try again.`,
      });
      return;
    }
    if (this.stepCompleted && session && asset) {
      if ((session.assetId && asset === session.assetId) || (!session.assetId && asset)) {
        await this.sessionSvc.updateSession({
          sessionId: session.id,
          data: { assetInfo: { id: asset } },
        });
      } else {
        await this.sessionSvc.updateSession({
          sessionId: session.id,
          data: {
            assetInfo: { id: asset },
            addonIds: [],
            planId: null as any,
            paymentSchedule: null as any,
          },
        });
      }
      if (staffView) this.navigateTo('/provisioning/plan');
      else this.navigateTo('/checkout/plan');
    }
  }

  async newAsset() {
    this.createNew = true;
  }

  ngOnInit(): void {
    this.asset.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(asset => (this.stepCompleted = !!asset));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
