<ng-container *ngIf="policy$ | async as policy">
  <div *vsLet="canEdit$ | async as canEdit" class="flex flex-col gap-4">
    <div class="flex xl:flex-nowrap flex-wrap gap-4">
      <vs-card
        *vsLet="stats?.data$ | async as data"
        class="flex-1 sm:basis-1"
        [class]="data ? 'xl:min-w-[40%] min-w-full' : '-mr-4'"
      >
        <vs-card-header [config]="{ title: 'Overview', subtitle: 'Manage and view the details of this policy.' }">
          <button
            *ngIf="canEdit"
            vs-icon-button
            slot="end"
            class="-mr-2"
            appearance="clear"
            [disabled]="currentTab === 2"
            (click)="editPolicyDialog.open()"
          >
            <!-- Edit Button Disabled on asset tab as there is nothing that can be updated -->
            <vs-icon name="edit-01" size="18"></vs-icon>
          </button>
        </vs-card-header>

        <vs-card-content>
          <vs-tabs theme="primary" (tabChanged)="setEditPolicyType($event)">
            <vs-tab [config]="{ label: 'Details' }">
              <div class="flex flex-col gap-4">
                <div class="flex" *ngIf="policy.friendlyTitle !== policy.title">
                  <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Friendly Title</span>
                  <span class="text-sm"> {{ policy?.friendlyTitle }} </span>
                </div>

                <vs-divider *ngIf="policy.friendlyTitle !== policy.title"></vs-divider>

                <div class="flex">
                  <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Tagline</span>
                  <span class="text-sm"> {{ policy?.tagline }} </span>
                </div>

                <vs-divider></vs-divider>

                <ng-container *ngIf="policy?.description">
                  <div class="flex">
                    <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Description</span>
                    <span class="text-sm truncate"> {{ policy?.description }} </span>
                  </div>
                  <vs-divider></vs-divider>
                </ng-container>

                <div class="flex">
                  <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Number</span>
                  <span class="text-sm"> {{ policy?.policyNumber }} </span>
                </div>

                <vs-divider></vs-divider>

                <div class="flex">
                  <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Claim Waiting Period</span>
                  <span class="text-sm">
                    {{ (policy?.claimWaitingPeriod || 0) > 0 ? getDuration(policy?.claimWaitingPeriod) : '0 Days' }}
                  </span>
                </div>

                <vs-divider></vs-divider>

                <div class="flex">
                  <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Warranty Activation</span>
                  <span class="text-sm"> {{ policy?.requiresWarrantyActivation ? 'Manual' : 'Automatic' }} </span>
                </div>

                <vs-divider></vs-divider>

                <div class="flex">
                  <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Published Date</span>
                  <span
                    tooltipStyle="light"
                    tooltipOpenDelay="250"
                    class="text-sm cursor-default"
                    [vsTooltip]="policy.publishedDate ? { title: 'Published On', subtitle: (policy.publishedDate | date: 'long') || '' } : undefined"
                    >{{ policy?.publishedDate ? (policy.publishedDate | date) : '-' }}</span
                  >
                </div>

                <vs-divider></vs-divider>

                <div class="flex">
                  <span class="text-sm font-medium basis-1/3 min-w-[112px] max-w-[200px]">Reference ID</span>
                  <span class="text-sm"> {{ policy?.referenceId ?? '-' }} </span>
                </div>
              </div>
            </vs-tab>

            <vs-tab [config]="{ label: 'Terms' }">
              <vs-table [columns]="termColumns" [data]="policy?.terms || []"></vs-table>
            </vs-tab>

            <vs-tab [config]="{ label: 'Asset', endContent: badge }">
              <ng-template #badge>
                <vs-badge theme="default">{{ policy?.propertySet?.name | titlecase }}</vs-badge>
              </ng-template>

              <div class="flex flex-row justify-between">
                <div *vsLet="properties$ | async as properties" class="flex flex-col gap-4 w-full">
                  <div>
                    <span class="text-sm font-medium basis-1/3 max-w-[200px]">Properties</span>
                    <vs-property-set-badges
                      *ngIf="policy?.propertySetId"
                      [propertySetId]="policy!.propertySetId"
                      [showHidden]="true"
                    ></vs-property-set-badges>
                  </div>
                </div>

                <button
                  vs-icon-button
                  appearance="outline"
                  theme="default"
                  vsTooltip="Download the template used for provisioning warranties through SFTP"
                  tooltipStyle="light"
                  tooltipPosition="top"
                  (click)="downloadProvisionTemplate(policy)"
                >
                  <vs-icon name="download-01"></vs-icon>
                </button>
              </div>
            </vs-tab>
          </vs-tabs>
        </vs-card-content>
      </vs-card>

      <ws-policy-stats #stats class="max-w-full" [policy]="policy"></ws-policy-stats>
    </div>

    <vs-card class="flex-1 sm:basis-1 mb-8">
      <vs-card-header
        [config]="{ title: 'Coverages Offered', subtitle: 'Manage and view all coverages in the table below.' }"
      >
        <vs-badge slot="after-title">
          {{ policy?.coverages?.length || 0 }} {{ (policy?.coverages?.length || 0) !== 1 ? 'coverages' : 'coverage'}}
        </vs-badge>

        <vs-input-group *ngIf="policy?.coverages?.length" slot="end">
          <button *ngIf="canEdit" vs-button appearance="outline" (click)="createCoverageDialog.open()">
            <vs-icon name="plus"></vs-icon>
            New Coverage
          </button>

          <button vs-icon-button appearance="outline" (click)="downloadCoverages(policy)">
            <vs-icon name="download-01" size="18"></vs-icon>
          </button>
        </vs-input-group>
      </vs-card-header>

      <!-- Table -->
      <ws-coverages-table [hideBorder]="true"></ws-coverages-table>
    </vs-card>
  </div>

  <!-- Dialogs -->
  <ws-policy-dialog #editPolicyDialog [policy]="policy" [editing]="editPolicyType"></ws-policy-dialog>

  <ws-policy-dialog #createCoverageDialog [policy]="policy" [editing]="createCoveragesType"></ws-policy-dialog>
</ng-container>
