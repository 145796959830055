import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  imports: [CommonModule, SwiperModule, NgPipesModule, ModalModule],
  exports: [],
  declarations: [],
})
export class WarrantyCoverageModule {}
