import { Component, Input } from '@angular/core';

@Component({
  selector: 'vs-accordion',
  templateUrl: './accordion.component.html',
})
export class AccordionComponent {
  @Input() open = false;

  toggle() {
    this.open = !this.open;
  }
}
