/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { Claim, Coverage } from '@wsphere/warranties/domain';
import { map, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-details-dialog',
  templateUrl: './claim-item-details.dialog.html',
})
export class ClaimItemDetailsDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimsSvc: ClaimService,
    private formBuilder: FormBuilder,
    private linkSvc: LinksWebService
  ) {}

  @ViewChild('claimItemDetails') claimItemDetailsDialog!: DialogComponent;
  @ViewChild('removeDialog') removeDialog!: DialogComponent;

  @Output() closed = new EventEmitter();
  readonly isCustomerPortal$ = this.route.data.pipe(map(data => data['isCustomerPortal'] as boolean));

  readonly claim$ = this.claimsSvc.getClaim();

  item: Claim.ClaimItem.Model | null = null;
  item$ = this.route.data.pipe(
    map(data => data['item'] as Claim.ClaimItem.Model),
    tap(item => {
      if (!item) return;

      this.item = item;
      this.form.patchValue({ coverage: item.coverage, description: item.description });
    })
  );

  links$ = this.item$.pipe(
    switchMap(async item =>
      item
        ? await this.linkSvc.list(
            { id: item.claimId, objectType: Link.ObjectType.CLAIM },
            { owner: { id: item.id, objectType: Link.ObjectType.CLAIM_ITEM } }
          )
        : null
    ),
    map(data => data?.items),
    tap(links => {
      if (this.item && links?.length) {
        this.links = links;

        const originalLinks = Link.getPickerLinks(links, Link.ObjectType.CLAIM_ITEM, this.item.id);
        if (originalLinks?.length) {
          this.originalLinks = originalLinks;

          this.form.patchValue({
            links: originalLinks.map(link => ({ id: link.id, object: link.object, type: link.type })),
          });
        }
      }
    })
  );

  removing = false;
  loading = false;

  links: Link.Model[] | null = null;
  originalLinks: Link.FormLink[] | null = null;

  draft = Claim.ClaimItem.Status.DRAFT;

  form = this.formBuilder.group({
    coverage: ['empty' as Coverage.Model | null | string, Validators.required],
    description: [''],
    links: [[] as Link.FormLink[]],
  });

  openDialog() {
    this.claimItemDetailsDialog?.open();
  }

  closeDialog() {
    this.form.reset({ coverage: 'empty' });
    this.claimItemDetailsDialog.close();
  }

  openRemoveDialog() {
    this.removing = true;
    this.claimItemDetailsDialog.close();
    this.removeDialog?.open();
  }

  closeRemoveDialog(action?: string) {
    this.removing = false;
    if (action !== 'deleted') this.claimItemDetailsDialog.open();
    else if (action === 'deleted') this.navigateTo('../..');
  }

  async save(claim: Claim.Model | null) {
    if (!claim || (!this.form.value.coverage && this.form.value.coverage !== null)) return;

    const value = this.form.value;

    if (this.item) {
      const response = await this.claimsSvc
        .updateItem(claim.id, this.item.id, {
          coverageId: (value.coverage as Coverage.Model)?.id ?? null,
          description: value.description,
        })
        .catch((err: { error: { message: any } }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: `${err?.error?.message}`,
          });

          this.closeDialog();
        });

      if (response) {
        this.updateLinks(response.id);

        this.toastSvc.show({
          type: 'success',
          title: 'Claim item updated',
          text: `${(value.coverage as Coverage.Model)?.title || 'Other'} item has been successfully updated.`,
        });

        this.closeDialog();
        this.closed.emit();
      }
    } else {
      const response = await this.claimsSvc
        .createItem(claim.id, {
          coverageId: (value.coverage as Coverage.Model)?.id ?? null,
          description: value.description,
        })
        .catch((err: { error: { message: any } }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: `${err?.error?.message}`,
          });

          this.closeDialog();
        });

      if (response) {
        this.updateLinks(response.id);

        this.toastSvc.show({
          type: 'success',
          title: 'Claim item created',
          text: `${
            (value.coverage as Coverage.Model)?.title || 'Other'
          } item has been successfully created under Claim #${claim.claimNumber}.`,
        });

        this.closeDialog();
        this.closed.emit();
      }
    }
  }

  async updateLinks(itemId: string) {
    const links = this.form.value.links;

    // Add new links
    if (links?.length) {
      const originalLinkIds = this.originalLinks?.map(link => link.id);

      await Promise.all(
        links.map(async link => {
          if (!originalLinkIds?.includes(link.id)) {
            await this.linkSvc.create(
              { id: itemId, objectType: Link.ObjectType.CLAIM_ITEM },
              {
                object1: { id: (link.object as any).id, objectType: link.type },
                object2: { id: itemId, objectType: Link.ObjectType.CLAIM_ITEM },
              }
            );
          }
        })
      );
    }

    // Remove links
    if (this.originalLinks?.length) {
      const newLinks = links?.map(link => (link.object as any)?.id);

      await Promise.all(
        this.originalLinks?.map(async link => {
          if (link.id && !newLinks?.includes((link.object as any)?.id)) {
            await this.linkSvc.delete({ id: itemId, objectType: Link.ObjectType.CLAIM_ITEM }, link.id);
          }
        })
      );
    }
  }

  navigateTo(path?: string) {
    this.router.navigate([path ?? '..'], { relativeTo: this.route, queryParams: { closeItemDialog: true } });
  }
}
