/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
import { FullAdminWarningDialog, RoleAssignmentsService, StaffService } from '@wsphere/staff/web';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-invite-member-dialog',
  templateUrl: './invite-member.dialog.html',
})
export class InviteMemberDialog {
  constructor(
    private toastSvc: ToastService,
    private staffSvc: StaffService,
    private formBuilder: FormBuilder,
    private roleSvc: RoleAssignmentsService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild(DialogComponent) private dialog?: DialogComponent;
  @ViewChild(FullAdminWarningDialog) private warningDialog?: FullAdminWarningDialog;

  @PropertyListener('distributor') distributor$ = new BehaviorSubject<Distributor.Model | null>(null);
  @Input() distributor: Distributor.Model | null = null;

  @Output() readonly userInvited = new EventEmitter<void>();

  readonly form = this.formBuilder.group({
    emails: [[] as string[], [Validators.required, Validators.minLength(1)]],
  });

  user$ = this.staffSvc.retrieveSelf();

  saving = false;
  hoveredIndex = -1;
  allDistributorsHaveRoles = false;
  distributorsAndRoles: { distributor: Distributor.Model | null; roles: string[] }[] = [];

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  roles$ = combineLatest([this.user$, this.distributor$]).pipe(
    switchMap(async ([staff, distributor]) => {
      if (!staff) return [];

      const roles = await this.roleSvc.getStaffRoles(staff.id);

      return roles
        .filter(role => (distributor ? role.permissionKey?.includes(distributor.id) : role))
        .map(role => role.roleId);
    })
  );

  open() {
    this.allDistributorsHaveRoles = false;
    this.form.reset({ emails: [] });

    this.distributorsAndRoles = [{ distributor: this.distributor, roles: [] }];

    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }

  updateDistributor(distributor: Distributor.Model | null, index: number) {
    this.distributorsAndRoles[index].distributor = distributor;
    this.checkDistributorRoles();
  }

  addRole(index: number, roleId: string) {
    this.distributorsAndRoles[index].roles.push(roleId);
    this.checkDistributorRoles();

    if (roleId === '*') {
      this.warningDialog?.open(this.distributorsAndRoles[index].distributor?.name);
    }
  }

  removeRole(index: number, roleId: string) {
    this.distributorsAndRoles[index].roles = this.distributorsAndRoles[index].roles.filter(role => role !== roleId);
    this.checkDistributorRoles();
  }

  addGroup() {
    this.distributorsAndRoles.push({ distributor: null, roles: [] });
    this.checkDistributorRoles();
  }

  removeGroup(index: number) {
    this.distributorsAndRoles.splice(index, 1);
    this.checkDistributorRoles();
  }

  checkDistributorRoles() {
    this.allDistributorsHaveRoles = !this.distributorsAndRoles.filter(group => group.roles.length === 0).length;
  }

  async inviteStaff() {
    this.saving = true;

    const emails = this.form.value.emails;
    if (!emails) return;

    const sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms));

    await Promise.all(
      emails.map(async email => {
        await Promise.all(
          this.distributorsAndRoles.map(async (group, index) => {
            await sleep(index * 500);

            const permissionKey = group.distributor?.permissionKey;
            const roles: string[] = group.roles;

            const dto: Staff.Security.InviteStaffAndGrantRolesRequest = { email, roles, permissionKey };

            const staff = await this.roleSvc.inviteStaffAndGrantRoles(dto).catch(({ error }) => {
              this.toastSvc.show({
                type: 'error',
                title: 'Something went wrong',
                text: error.message,
              });

              this.saving = false;
            });

            if (staff?.id) {
              this.toastSvc.show({
                type: 'success',
                title: 'Invited Staff',
                text:
                  'An invite has successfully been sent to <strong>' +
                  email +
                  '</strong> for <strong>' +
                  group.distributor?.name +
                  '</strong>.',
              });
            }
          })
        );
      })
    );

    this.close();
    this.userInvited.emit();
    this.saving = false;
  }
}
