import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  NonNullableFormBuilder,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { extractErrors } from '@vsolv/dev-kit/ngx';
import moment from 'moment';
import { map, ReplaySubject, startWith, Subject, takeUntil } from 'rxjs';

export type CreditCardInputValue = null | {
  name: string;
  card: {
    number: string;
    expiration: string;
    cvc: string;
  };
};

@Component({
  selector: 'vs-credit-card-input',
  templateUrl: './credit-card-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: CreditCardInputComponent, multi: true },
    { provide: NG_VALIDATORS, useExisting: CreditCardInputComponent, multi: true },
  ],
})
export class CreditCardInputComponent implements OnDestroy, ControlValueAccessor, Validator {
  @HostBinding('class') private _classes = 'block [&_vs-form-field[appearance=vertical]>div>div]:max-w-none';

  @Input() set value(value: CreditCardInputValue) {
    this.form.patchValue(value ?? { name: '', card: { number: '', expiration: '', cvc: '' } });
  }
  get value() {
    return this.form.valid ? (this.form.value as CreditCardInputValue) : null;
  }

  @Input() set disabled(disabled: BooleanInput) {
    if (coerceBooleanProperty(disabled)) this.form.disable();
    else this.form.enable();
  }
  get disabled() {
    return this.form.disabled;
  }

  protected form = this.formBuilder.group({
    name: ['', Validators.required],
    card: this.formBuilder.group({
      cvc: ['', [Validators.required, Validators.pattern(/\d{3,4}/)]],
      number: ['', [Validators.required, Validators.pattern(/\d{13,19}/)]],
      expiration: [
        '',
        [
          Validators.required,
          Validators.pattern(/\d{4}/),
          (control: AbstractControl<string>): ValidationErrors | null => {
            if (control.value.length !== 4) return null;

            const month = Number(control.value.substring(0, 2));
            if (month < 1 || month > 12) {
              return { invalidMonth: true };
            }

            const expiration = moment(control.value, 'MMYY');
            if (moment().isSameOrAfter(expiration, 'month')) {
              return { expired: true };
            }

            return null;
          },
        ],
      ],
    }),
  });

  protected cardMask$ = this.form.controls.card.controls.number.valueChanges.pipe(
    startWith(this.form.controls.card.controls.number.value),
    map((cardNumber: string | null) => {
      if (cardNumber?.length === 13) return '0000 00000 00000';
      else if (cardNumber?.length === 14) return '0000 000000 00000';
      else if (cardNumber?.length === 15) return '0000 000000 000000';
      else if (cardNumber?.length === 16) return '0000 0000 0000 00000';
      else if (cardNumber?.length === 17) return '00000 0000 0000 00000';
      else if (cardNumber?.length === 18) return '00000 00000 0000 00000';
      else if (cardNumber?.length === 19) return '0000 0000 0000 0000 000';
      else return new Array(19).fill('0').join('');
    })
  );

  protected cardType$ = this.form.controls.card.controls.number.valueChanges.pipe(
    startWith(this.form.controls.card.controls.number.value),
    map((cardNumber: string | null) => {
      if (!cardNumber || !cardNumber.match(/\d*/)) return null;
      return (
        Object.entries(CARD_IIN_LOOKUP).find(([_, range]) => range.some(iin => cardNumber.startsWith(iin)))?.[0] ?? null
      );
    })
  );

  protected touched$ = new ReplaySubject<void>(1);
  private onDestroy$ = new Subject<void>();

  constructor(private formBuilder: NonNullableFormBuilder) {}

  writeValue(value: CreditCardInputValue): void {
    this.value = value;
  }

  registerOnChange(fn: (value: CreditCardInputValue) => void): void {
    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => fn(this.value));
  }

  registerOnTouched(fn: () => void): void {
    this.touched$.pipe(takeUntil(this.onDestroy$)).subscribe(() => fn());
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(): ValidationErrors | null {
    return extractErrors(this.form);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    this.touched$.complete();
  }
}

function range(lower: number, upper: number) {
  return new Array(upper - lower).fill(null).map((_, i) => (lower + i).toString());
}

const CARD_IIN_LOOKUP = {
  amex: ['34', '37'],
  discover: ['6011', ...range(644, 649), '65', ...range(622126, 622925)],
  jcb: [...range(3528, 3589)],
  mastercard: [...range(2221, 2720), ...range(51, 55)],
  visa: ['4'],
} as const;
