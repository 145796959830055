<div class="flex flex-col justify-center items-center w-full gap-4 mt-16">
  <div class="flex flex-col justify-center items-center w-full gap-4" *ngIf="!sent">
    <vs-featured-icon name="key-01" theme="primary"></vs-featured-icon>
    <h1 class="text-3xl">Forgot your password?</h1>
    <p class="text-gray">No worries, we'll send you reset instructions.</p>
    <form [formGroup]="formGroup" class="flex justify-center w-full">
      <div class="flex flex-col flex-wrap gap-4 justify-center items-center w-full max-w-[360px]">
        <vs-form-field [config]="{ label: 'Email' }">
          <input vsInput type="text" #emailInput placeholder="example@email.com" formControlName="email" />
          <span
            class="text-danger"
            slot="hint"
            *ngIf="!inputIsValid() && formGroup.get('email')?.touched && formGroup.value.email"
            >Your email address should have the following format: example@email.com</span
          >
        </vs-form-field>

        <button
          class="w-full"
          theme="primary"
          [disabled]="!inputIsValid()"
          vs-button
          appearance="default"
          size="md"
          (click)="submit()"
        >
          <span>Reset password</span>
        </button>
      </div>
    </form>
  </div>
  <div class="flex flex-col justify-center items-center w-full gap-4" *ngIf="sent">
    <vs-featured-icon name="mail-01" theme="primary"></vs-featured-icon>
    <h1 class="text-3xl">Check your email</h1>
    <div class="p-4 m-auto md:w-3/5">
      <p class="text-gray text-center">
        If you have an account with us, you should receive an email with password reset instructions shortly. If you
        don't receive an email to reset your password, please check your spam folder and make sure you've entered the
        email address you registered with.
      </p>
    </div>

    <a vs-button size="md" theme="primary" class="w-full max-w-[360px]" href="mailTo:">
      <span>Open email app</span>
    </a>
    <p class="text-gray-900">
      Didn't receive the email?
      <span>
        <button class="text-gray" [disabled]="this.canSendTimeOut > 0" (click)="resetPassword()">
          <span *ngIf="this.canSendTimeOut <= 0; else timeoutTimer">Send again</span>
          <ng-template #timeoutTimer>
            <span>Resend available in {{ this.canSendTimeOut }}</span>
          </ng-template>
        </button>
      </span>
    </p>
  </div>
  <a class="text-gray-600 flex justify-center items-center" routerLink="..">
    <vs-icon name="arrow-left" type="solid"></vs-icon>
    Back to log in
  </a>
</div>
