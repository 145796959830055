<vs-app-page [config]="{ title: 'User Settings', icon: 'settings-01' }">
  <vs-tab-nav
    slot="sticky"
    theme="primary"
    [config]="{
  items: [
    {  label: 'My profile', routerLink: 'my-profile' },
    {  label: 'Security', routerLink: 'security' },
    {  label: 'Notifications', routerLink: 'notifications' }
  ]
}"
  ></vs-tab-nav>
  <router-outlet></router-outlet>
</vs-app-page>
