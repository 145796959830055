<form *vsLet="hidden$ | async">
  <!-- Files galery for multiple -->
  <div
    *ngIf="multiple"
    class="flex gap-2 border rounded-xl border-gray-200 p-4 bg-base h-[90px] overflow-x-auto mb-2 max-w-[592px]"
  >
    <div *ngIf="files?.length; else placeholder" [class]="'flex gap-2 ' + ($any(files?.length) > 1 ? '' : 'w-full')">
      <ng-container *ngIf="$any(files?.length) > 1; else singleItem" class="mb-4">
        <div *ngFor="let document of $any(files); index as index" class="relative h-16 w-16 rounded-xl">
          <ng-container #image *ngIf="$any(document).type !== 'application/pdf'">
            <img vsImagePreview [vsImagePreview]="document" class="h-full w-full" />
          </ng-container>

          <ng-container *ngIf="$any(document).type === 'application/pdf'">
            <div *ngIf="!isSafari; else notSupported">
              <object
                *vsLet="getUrl($any(document)) || '' as url"
                class="pointer-events-none -ml-px -mt-px h-16 w-16 rounded-md"
                [data]="url + '#toolbar=0&navpanes=0&scrollbar=0&scrollbars=0&statusbar=0' | safe : 'resourceUrl'"
              ></object>
            </div>
          </ng-container>

          <button
            vs-icon-button
            appearance="outline"
            theme="default"
            size="xs"
            class="absolute -bottom-1 right-0 !w-6 !h-6 bg-base-white border border-gray-300 rounded-md"
            (click)="documents.clear(index)"
          >
            <vs-icon name="trash-01" class="text-gray-500"></vs-icon>
          </button>
        </div>
      </ng-container>
    </div>

    <!-- Empty array template -->
    <ng-template #placeholder>
      <vs-icon
        name="image-plus"
        class="p-2 mt-2.5 mb-2 border border-gray-200 rounded-lg text-xl leading-none text-gray-400"
      ></vs-icon>
      <p class="text-sm text-gray-400 self-center">Photos you want to upload for this claim will be listed here.</p>
    </ng-template>

    <!-- Only one item selected  -->
    <ng-template #singleItem>
      <div class="flex justify-between w-full">
        <div class="flex gap-2 w-3/4">
          <ng-container *ngIf="$any(formControl.value)[0].type !== 'application/pdf'">
            <img vsImagePreview [vsImagePreview]="$any(formControl.value)[0]" class="w-[60px] h-[60px]" />
          </ng-container>

          <ng-container *ngIf="$any(formControl.value)[0].type === 'application/pdf'">
            <div *ngIf="!isSafari; else notSupported">
              <object
                *vsLet="getUrl($any(formControl.value)[0]) || '' as url"
                class="pointer-events-none -ml-px -mt-px h-16 w-16 rounded-md"
                [data]="url + '#toolbar=0&navpanes=0&scrollbar=0&scrollbars=0&statusbar=0' | safe : 'resourceUrl'"
              ></object>
            </div>
          </ng-container>

          <div class="align-center pt-2 line-clamp-1">
            <p class="line-clamp-1 text-sm text-gray-500">
              {{ $any(formControl.value)[0]?.name }}
            </p>
            <p class="text-sm text-gray-400">{{ ($any(formControl.value)[0]?.size / 1000 / 1000).toFixed(2) }} MB</p>
          </div>
        </div>

        <button
          class="justify-end border border-gray-300 rounded-lg h-9 w-9 mt-3 pt-1.5"
          vs-icon-button
          appearance="outline"
          theme="default"
          (click)="documents.clear(0); $event.preventDefault()"
        >
          <vs-icon name="trash-01" class="text-gray-500 !align-center"></vs-icon>
        </button>
      </div>
    </ng-template>
  </div>

  <!-- Single file preview -->
  <div
    *ngIf="hidden"
    class="flex justify-between bg-base px-3 pt-1 h-[96px] transition-all"
    [class.border]="!hideBorder"
    [class.rounded-xl]="!hideBorder"
    [class.hover:border-3]="!hideBorder"
    [class.border-gray-200]="!hideBorder"
    [class.hover:border-primary-200]="!hideBorder"
  >
    <div class="m-3 flex gap-2 w-3/4">
      <ng-container *ngIf="$any(formControl.value).type !== 'application/pdf'">
        <img vsImagePreview [vsImagePreview]="formControl.value" class="w-16 h-16" />
      </ng-container>

      <ng-container *ngIf="$any(formControl.value).type === 'application/pdf'">
        <div *ngIf="!isSafari; else notSupported" class="overflow-hidden max-w-[64px] border rounded-md">
          <object
            *vsLet="getUrl($any(formControl.value)) || '' as url"
            style="width: calc(100% + 18px)"
            class="max-h-full pointer-events-none -ml-px -mt-px"
            [data]="url + '#toolbar=0&navpanes=0&scrollbar=0&scrollbars=0&statusbar=0' | safe : 'resourceUrl'"
          ></object>
        </div>
      </ng-container>

      <div class="align-center pt-2 line-clamp-1">
        <p class="line-clamp-1 text-sm text-gray-500">
          {{ $any(formControl.value)?.name }}
        </p>
        <p class="line-clamp-1 text-sm text-gray-400">
          {{ ($any(formControl.value)?.size / 1000 / 1000).toFixed(2) }} MB
        </p>
      </div>
    </div>

    <button
      class="justify-end border border-gray-300 rounded-lg h-9 w-9 mx-4 mt-6 pt-1.5"
      vs-icon-button
      appearance="outline"
      theme="default"
      (click)="documents.clear(); $event.preventDefault()"
    >
      <vs-icon name="trash-01" class="text-gray-500 self-center"></vs-icon>
    </button>
  </div>

  <!-- Input (drag'n'drop) component -->
  <div
    [hidden]="hidden"
    [class.border]="!hideBorder"
    [class.rounded-xl]="!hideBorder"
    [class.hover:ring-4]="!hideBorder"
    [class.border-gray-200]="!hideBorder"
    [class.ring-primary-300]="!hideBorder"
    [class]="'bg-blue-600 bg-base-white h-[96px] hover:border-primary-100 transition-all w-full relative ' + dragging"
    (drop)="setOnDragleave()"
    (dragend)="setOnDragleave()"
    (dragover)="setOnDragover()"
    (dragleave)="setOnDragleave()"
  >
    <div class="absolute w-full h-[96px]">
      <input
        #inputClick
        #documents="vsInput"
        vsInput
        type="file"
        class="opacity-0 background-opacity-0 !w-full !h-full"
        [accept]="accept"
        [multiple]="multiple"
        [formControl]="formControl"
        (errorsEmitter)="errors.next($event)"
      />
    </div>

    <div class="flex p-5 text-gray-400 gap-2 absolute pointer-events-none">
      <vs-icon name="upload-cloud-02" class="text-gray-600 w-10 h-10 m-1 p-3 rounded-full bg-gray-50"></vs-icon>
      <div class="flex flex-col self-center">
        <label class="text-sm flex">
          <p class="text-primary-500">Click to upload</p>
          &nbsp;
          <p>or drag and drop</p>
        </label>
        <label class="text-sm"> {{ accept }} {{ moreInfo }}</label>
      </div>
    </div>
  </div>

  <p *ngIf="this.errors.value.length" class="text-sm text-danger-500 mt-4">
    {{ this.errors.value }} already added. Please try to upload another one.
  </p>
</form>

<ng-template #notSupported>
  <div class="bg-gray-100 flex flex-row justify-center rounded-md w-16 h-16">
    <vs-card class="bg-base flex flex-row justify-center self-center w-10 h-10">
      <vs-icon name="attachment-02" size="18" class="text-gray-400 self-center"></vs-icon>
    </vs-card>
  </div>
</ng-template>
