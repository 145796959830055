<vs-dialog
  *vsLet="noteUpdate$ | async"
  #dialog
  [config]="{ dialogContainerClass: 'w-[584px] max-w-[800px]' }"
  (closed)="refreshActivity(); form.reset();"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'sticker-square',
      iconTheme: 'primary',

      title: note ? 'Edit ' + (note.title || 'Note') : 'Add New Note',
      subtitle: note ? 'Edit the details of ' + (note.title || 'the note') + '.' : 'Create a new note for this claim.'
    }"
  ></vs-dialog-header>

  <form class="flex flex-col gap-4 p-4" [formGroup]="form">
    <vs-form-field>
      <div class="flex gap-4 items-center w-[518px]">
        <span class="text-sm font-medium text-gray-700 basis-1/4">Title (Optional)</span>
        <input vsInput type="text" formControlName="title" placeholder="Enter note title" />
      </div>
    </vs-form-field>

    <!-- The first is used for creating notes, the second is used for updating them -->
    <vs-rich-text-editor *ngIf="!note" formControlName="content"></vs-rich-text-editor>
    <vs-rich-text-editor *ngIf="note" formControlName="content"></vs-rich-text-editor>

    <ws-claim-link-picker
      formControlName="links"
      class="w-full"
      [claim]="claim"
      [id]="note?.id ?? null"
    ></ws-claim-link-picker>

    <div class="flex items-center gap-2">
      <input vsInput type="checkbox" name="checkbox" formControlName="visibleToCustomer" />
      <p class="text-sm font-normal text-gray-700">Make this note visible to the customer</p>
    </div>

    <div
      *vsLet="canRemoveNotes$ | async as canRemoveNotes"
      class="flex gap-2 mt-4"
      [class.justify-end]="!note || !canRemoveNotes"
      [class.justify-between]="note && canRemoveNotes"
    >
      <div
        *vsLet="claim$ | async as claim"
        [vsTooltip]="claim?.status !== open ? 'Cannot delete notes on unopened claims' : ''"
      >
        <button
          *ngIf="note && canRemoveNotes"
          vs-button
          [disabled]="claim?.status !== open"
          appearance="outline"
          (click)="claim?.status === open ? openDeleteDialog() : null"
        >
          Delete
        </button>
      </div>

      <div class="flex gap-2">
        <button vs-button appearance="outline" (click)="closeDialog()">Cancel</button>
        <button vs-button theme="primary" [disabled]="!form.value.content" (click)="save()">Save</button>
      </div>
    </div>
  </form>
</vs-dialog>

<vs-dialog #deleteDialog [config]="{ dialogContainerClass: 'w-[500px] max-w-[800px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'file-plus-03',
      iconTheme: 'primary',

      title: 'Delete ' + (note?.title ?? 'Note'),
      subtitle: 'Are you sure you want to remove ' + (note?.title ?? 'this note') + '? This action can not be undone.'
    }"
  ></vs-dialog-header>

  <div class="flex justify-between gap-2 mt-4 mx-4">
    <button vs-button appearance="outline" class="basis-1/2" (click)="openDialog()">Back</button>
    <button vs-button theme="danger" class="basis-1/2" (click)="remove()">Delete Note</button>
  </div>
</vs-dialog>
