import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { RichTextEditorComponent } from './components';

@NgModule({
  imports: [CommonModule, UtilsModule, FormsModule, ReactiveFormsModule, CKEditorModule, ThemingModule],
  exports: [RichTextEditorComponent],
  declarations: [RichTextEditorComponent],
})
export class RichTextEditorModule {}
