import { Pipe, PipeTransform } from '@angular/core';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';

@Pipe({ name: 'sortProperties' })
export class PropertyAssignmentSortPipe implements PipeTransform {
  transform(
    assignments: PropertySet.PropertyAssignment[] | Property.ObjectPropertyAssignment[] | null | undefined,
    showHidden = true
  ): PropertySet.PropertyAssignment[] | Property.ObjectPropertyAssignment[] {
    if (!assignments?.length) return [];
    const sortedAssignments = assignments.sort((a, b) => a.order - b.order);

    return showHidden
      ? sortedAssignments
      : // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (sortedAssignments as any).filter((assignment: PropertySet.PropertyAssignment) => assignment.hidden === false);
  }
}
