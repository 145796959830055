import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Property } from '@vsolv/packages/properties/domain';
import { BehaviorSubject } from 'rxjs';
import { PropertyWithAddress } from '../shipping-address-property-picker.component';

@Component({
  selector: 'ws-shipping-address-property-picker-item',
  templateUrl: './shipping-address-property-picker-item.component.html',
})
export class ShippingAddressPropertyPickerItemComponent {
  @PropertyListener('property') property$ = new BehaviorSubject<PropertyWithAddress | null>(null);
  @Input() propertyWithAddress!: PropertyWithAddress;

  @Input() propertyPath!: string;

  @Input() value: { property: Property.Model<Property.PropertyType>; propertyPath: string } | null = null;

  overlayOpen = false;

  @Output() selectionChanged = new EventEmitter<{
    property: Property.Model<Property.PropertyType>;
    propertyPath: string;
  } | null>();

  setValue(value: { property: Property.Model<Property.PropertyType>; propertyPath: string } | null) {
    this.value = value;
    this.selectionChanged.emit(value);
    // menu.close();
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }
}
