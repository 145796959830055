<div class="px-4 py-5 flex items-start gap-5">
  <vs-featured-icon *ngIf="config.icon" [name]="config.icon" [theme]="config.iconTheme"></vs-featured-icon>

  <div class="flex flex-col gap-1 overflow-hidden">
    <p *ngIf="config.title" [innerHTML]="config.title | safe : 'html'" class="text-gray-900 text-sm font-semibold"></p>
    <p
      *ngIf="config.text"
      [innerHTML]="config.text | safe : 'html'"
      class="text-gray-600 text-sm text-ellipsis overflow-hidden"
    ></p>
    <ng-content></ng-content>
  </div>

  <button
    *ngIf="!config.hideCloseButton"
    vs-icon-button
    appearance="clear"
    [rounded]="true"
    class="-mt-[14px] -mr-2 ml-auto p-3"
    (click)="close()"
  >
    <vs-icon name="x-close"></vs-icon>
  </button>
</div>

<div *ngIf="config.autoClose" [theme]="config.iconTheme" class="h-1 bg-themed-100">
  <div class="h-full bg-themed" [style.width]="(_approximateElapsedTime / (config.closeDelay ?? 0)) * 100 + '%'"></div>
</div>
