/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { ThemeColorEnum } from '@vsolv/vectors-ui/theming';
import {
  BadgeColumn,
  IconButtonColumn,
  PaginationConfig,
  ProgressBarColumn,
  TableColumn,
  TextColumn,
  TextSubtitleColumn,
} from '@vsolv/vectors/table';
import { SecurityService } from '@wsphere/staff/web';
import { Policy, Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, startWith, switchMap, tap } from 'rxjs';
import {
  ArchivePolicyDialog,
  EditPolicyType,
  PolicyDialog,
  PublishPolicyDialog,
  RestorePolicyDialog,
} from '../../dialogs';
import { PolicyService } from '../../services';

export type PolicyTableFilters = Pick<Policy.ListPoliciesQueryRequest, 'search' | 'status'>;

@Component({ templateUrl: './policies.page.html' })
export class PoliciesPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('createPolicyDialog') createPolicyDialog?: PolicyDialog;
  @ViewChild('editPolicyDialog') editPolicyDialog?: PolicyDialog;
  @ViewChild('publishPolicyDialog') publishPolicyDialog!: PublishPolicyDialog;
  @ViewChild('archivePolicyDialog') archivePolicyDialog?: ArchivePolicyDialog;
  @ViewChild('restorePolicyDialog') restorePolicyDialog?: RestorePolicyDialog;

  @ViewChild('buttonRef') copyButton!: TemplateRef<ElementRef>;
  @ViewChild('draftTooltipRef') draftTooltipRef!: TemplateRef<ElementRef>;
  @ViewChild('publishedTooltipRef') publishedTooltipRef!: TemplateRef<ElementRef>;
  @ViewChild('archiveTooltipRef') archiveTooltipRef!: TemplateRef<ElementRef>;
  @ViewChild('runoffTooltipRef') runoffTooltipRef!: TemplateRef<ElementRef>;

  policyCount = 0;
  statuses = Object.keys(Policy.Status);

  selectedPolicy: Policy.Model | null = null;
  editPolicyType = EditPolicyType.POLICY_DETAILS;

  canCreate$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_Create', permissionKeys);
    })
  );

  canEdit$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_Edit', permissionKeys);
    })
  );

  canManageStatus$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_ManageStatus', permissionKeys);
    })
  );

  private refresh$ = new BehaviorSubject<null>(null);

  filters: PolicyTableFilters | null = null;
  private filters$ = new BehaviorSubject(this.filters);

  readonly pagination$ = new BehaviorSubject({ currentPage: 1, itemsPerPage: 10 });

  private paginatedData$ = combineLatest([
    this.pagination$,
    this.filters$,
    this.securitySvc.globalDistributors$,
    this.refresh$,
  ]).pipe(
    switchMap(
      async ([pagination, filters]) =>
        await this.policySvc.list({
          page: pagination.currentPage,
          limit: pagination.itemsPerPage,
          search: filters?.search,
          status: filters?.status,
        })
    ),
    tap(data => (this.policyCount = data?.meta?.totalItems || 0)),
    shareReplay(1)
  );

  readonly policies$: Observable<Policy.Model[]> = this.paginatedData$.pipe(
    map(data => data.items),
    startWith([])
  );

  readonly paginationConfig$: Observable<Partial<PaginationConfig>> = this.paginatedData$.pipe(
    map(data => data.meta),
    startWith(this.pagination$.value)
  );

  columns$: Observable<TableColumn<unknown>[]> = combineLatest([this.canEdit$, this.canManageStatus$]).pipe(
    map(([canEdit, canManageStatus]) => {
      return [
        new TextSubtitleColumn<unknown>({ header: 'Title' }, (item: any) => ({
          title: item.title,
          subtitle: item.policyNumber,

          tooltipStyle: 'light',
          tooltipCloseDelay: 1000,
          tooltipTemplate: this.copyButton,
        })),

        new BadgeColumn({ header: 'Status' }, (item: any) => ({
          text: item.status.charAt(0) + item.status.slice(1).toLowerCase(),
          displayStatusIcon: true,
          tooltipStyle: 'light',
          tooltipTemplate:
            item.status === Policy.Status.DRAFT
              ? this.draftTooltipRef
              : item.status === Policy.Status.PUBLISHED
              ? undefined
              : item.status === Policy.Status.ARCHIVED
              ? this.archiveTooltipRef
              : item.status === Policy.Status.RUNOFF
              ? this.runoffTooltipRef
              : undefined,
          theme:
            item.status === Policy.Status.DRAFT
              ? 'info'
              : item.status === Policy.Status.PUBLISHED
              ? 'success'
              : item.status === Policy.Status.ARCHIVED
              ? 'default'
              : item.status === Policy.Status.RUNOFF
              ? 'warn'
              : 'default',
        })),

        new TextColumn({ header: 'Date created' }, (item: any) => ({
          text: moment(item.created as Date).format('MMM DD, YYYY'),
        })),

        new ProgressBarColumn({ header: 'No. of warranties' }, async (item: any) => {
          const counts = await this.policySvc.getWarrantyCounts(item.id).catch(() => null);
          if (!counts) return { endText: '', config: { items: [] } };

          const total = Object.values(counts).reduce((acc, count) => acc + count, 0);
          if (!total) return { endText: '', config: { items: [] } };

          return {
            startText: this.largeNumberFormat(total),
            config: {
              items: [
                { theme: ThemeColorEnum.default, weight: counts[Warranty.Status.DRAFT] },
                {
                  theme: ThemeColorEnum.success,
                  weight: counts[Warranty.Status.ACTIVATED] + counts[Warranty.Status.REGISTERED],
                },
                { theme: ThemeColorEnum.warn, weight: counts[Warranty.Status.EXPIRED] },
                { theme: ThemeColorEnum.danger, weight: counts[Warranty.Status.CANCELLED] },
              ],
            },
          };
        }),
        ...(canManageStatus
          ? [
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: any) => ({
                type: 'clear',
                rounded: true,
                icon:
                  item.status === Policy.Status.DRAFT
                    ? 'file-check-02'
                    : item.status === Policy.Status.PUBLISHED
                    ? 'archive'
                    : item.status === Policy.Status.ARCHIVED || item.status === Policy.Status.RUNOFF
                    ? 'refresh-ccw-01'
                    : 'delete',
                tooltipText:
                  item.status === Policy.Status.DRAFT
                    ? 'Publish'
                    : item.status === Policy.Status.PUBLISHED
                    ? 'Archive'
                    : item.status === Policy.Status.ARCHIVED || item.status === Policy.Status.RUNOFF
                    ? 'Restore'
                    : undefined,
                click: () => {
                  item.status === Policy.Status.DRAFT
                    ? this.openPublishPolicyDialog(item)
                    : item.status === Policy.Status.PUBLISHED
                    ? this.openArchivePolicyDialog(item)
                    : this.openRestorePolicyDialog(item);
                },
              })),
            ]
          : []),
        ...(canEdit
          ? [
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: any) => ({
                type: 'clear',
                rounded: true,
                icon: 'edit-01',
                tooltipText: 'Edit',
                click: () => this.openEditPolicyDialog(item),
              })),
            ]
          : []),
      ];
    })
  );

  async refreshTable(filters?: { search?: string; status?: Policy.Status }) {
    if (filters?.search !== undefined) this.filters = { ...this.filters, search: filters.search };
    if (filters?.status !== undefined) this.filters = { ...this.filters, status: filters.status };
    this.filters$.next(this.filters);
  }

  async downloadPolicies() {
    const response = await this.policySvc.export().catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response) {
      const link = document.createElement('a');
      link.setAttribute('href', response.data);
      link.setAttribute('download', 'Policies.csv');
      link.click();
    }
  }

  openEditPolicyDialog(policy: Policy.Model) {
    this.selectedPolicy = policy;
    this.editPolicyDialog?.open();
  }

  openPublishPolicyDialog(policy: Policy.Model) {
    this.selectedPolicy = policy;
    this.publishPolicyDialog.open();
  }

  openArchivePolicyDialog(policy: Policy.Model) {
    this.selectedPolicy = policy;
    this.archivePolicyDialog?.open();
  }

  openRestorePolicyDialog(policy: Policy.Model) {
    this.selectedPolicy = policy;
    this.restorePolicyDialog?.open();
  }

  async createPolicy(event: string) {
    this.navigateTo(event);
  }

  editPolicy(policyId: string) {
    if (policyId.includes('pol_')) this.navigateTo(policyId);
    this.selectedPolicy = null;
  }

  publishPolicy(published: boolean) {
    this.publishPolicyDialog.close();
    if (published) this.refreshTable();
  }

  archivePolicy(archived: boolean) {
    this.archivePolicyDialog?.close();
    if (archived) this.refreshTable();
  }

  restorePolicy(path: string) {
    this.restorePolicyDialog?.close();
    if (path) this.refreshTable();
  }

  navigateTo(policyId?: string) {
    if (policyId) this.router.navigate([`${policyId}`], { relativeTo: this.route });
  }

  copyPolicyNumber(policyNum: string) {
    this.toastSvc.show({
      type: 'success',
      title: 'Policy number copied',
      text:
        'Policy number <strong>' + policyNum + '</strong> was successfully copied to clipboard and can now be shared.',
    });
  }

  async ngOnInit() {
    this.refreshTable();
  }

  private largeNumberFormat(value: number) {
    return new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: value >= 1000 ? 2 : 0 }).format(
      value
    );
  }
}
