<div class="flex flex-col gap-2 w-[256px]">
  <div class="flex gap-2">
    <vs-divider class="self-center"></vs-divider>
    <p class="text-center text-sm font-normal text-gray-600 min-w-max max-w-[256px]">{{ title }}</p>
    <vs-divider class="self-center"></vs-divider>
  </div>

  <p class="text-xs font-medium text-gray-500">{{ description }}</p>

  <p *ngIf="(amount || 0) >= 0" class="text-xs font-medium text-gray-400">
    Aggregate limit of liability: {{ (amount || 0) / 100 | currency }}
  </p>
</div>
