<vs-app-page
  *vsLet="filters$ | async as filters"
  [config]="{
    title: 'Sales',
    icon: 'wallet-03',
    showDivider: true,
    description: 'This is an overview of all sales filed across your organization.'
  }"
>
  <vs-breadcrumbs slot="breadcrumbs" [config]="{ items: [{ label: 'Sales', routerLink: [] }] }"></vs-breadcrumbs>

  <vs-badge *vsLet="setTotalPayments((table.page$ | async)?.meta?.totalItems)" slot="title-end">
    {{ totalPayments }} sale{{totalPayments !== 1 ? 's' : ''}}
  </vs-badge>

  <a
    *ngIf="exportUrl$ | async as exportUrl"
    vs-button
    theme="primary"
    slot="header-end"
    class="w-full"
    download="payments.csv"
    [href]="exportUrl"
  >
    <vs-icon name="download-01"></vs-icon>
    Export Sales
  </a>

  <form [formGroup]="form">
    <!-- Filters -->
    <div class="flex gap-2 flex-wrap">
      <div class="flex gap-2 justify-between flex-wrap flex-grow">
        <ws-customer-picker formControlName="selectedCustomer" class="flex-grow basis-1/4"></ws-customer-picker>
        <ws-payment-status-picker formControlName="status" class="flex-grow basis-1/4"></ws-payment-status-picker>
      </div>

      <div class="flex gap-2 justify-between flex-wrap flex-grow">
        <ws-distributor-filter
          class="flex-grow basis-1/4"
          permissionId="py_ViewSales"
          formControlName="selectedDistributor"
        ></ws-distributor-filter>

        <vs-input-container class="min-w-[200px] flex-grow basis-1/4">
          <vs-icon name="search-lg" slot="start"></vs-icon>
          <input type="text" formControlName="warrantyId" placeholder="Warranty Id..." />
        </vs-input-container>
      </div>
    </div>
  </form>

  <vs-card class="my-4 overflow-hidden">
    <ws-payments-table #table [filters]="filters" (rowClicked)="rowClicked($event)"></ws-payments-table>
  </vs-card>
</vs-app-page>
