<ng-container *ngIf="!minimize; else minimized">
  <ng-container *ngIf="checkbox; else noCheckbox">
    <div class="flex w-full gap-1 !py-4 h-[60px] border-y items-center">
      <input
        *ngIf="checkbox"
        #checkbox
        type="checkbox"
        vsInput
        [checked]="select"
        (change)="selected.emit(checkbox.checked)"
      />
      <button class="flex flex-grow justify-between items-center" (click)="open = !open">
        <div class="flex gap-1">
          <div class="text-primary-500 font-semibold">{{ header }}</div>
          <ng-container [ngTemplateOutlet]="postTitle"></ng-container>
        </div>
        <vs-icon [name]="open ? 'chevron-up' : 'chevron-down'"></vs-icon>
      </button>
    </div>
  </ng-container>
  <ng-template #noCheckbox>
    <button
      vs-button
      appearance="clear"
      theme="default"
      class="justify-between !w-full !py-4 h-[60px] border-y rounded-none"
      (click)="toggle()"
    >
      <div class="flex gap-1">
        <div class="text-lg">{{ header }}</div>
        <ng-container [ngTemplateOutlet]="postTitle"></ng-container>
      </div>
      <vs-icon [name]="open ? 'chevron-up' : 'chevron-down'"></vs-icon>
    </button>
  </ng-template>
</ng-container>
<ng-template #minimized>
  <ng-container *ngIf="checkbox; else noCheckbox">
    <div class="flex justify-between w-full items-center">
      <div class="flex items-center gap-1">
        <input
          *ngIf="checkbox"
          #checkbox
          type="checkbox"
          vsInput
          [checked]="select"
          (change)="selected.emit(checkbox.checked)"
        />
        <button class="flex items-center gap-1" (click)="toggle()">
          <div class="text-primary-500 font-semibold">{{ header }}</div>
          <vs-icon [name]="open ? 'chevron-up' : 'chevron-down'"></vs-icon>
        </button>
        <ng-container [ngTemplateOutlet]="postTitle"></ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="headerEnd"></ng-container>
    </div>
  </ng-container>
  <ng-template #noCheckbox>
    <button
      vs-button
      appearance="clear"
      theme="default"
      class="justify-between !w-full h-6 !px-0 py-1 rounded-none"
      (click)="toggle()"
    >
      <div class="flex items-center gap-1">
        <div class="text-primary-500 font-semibold">{{ header }}</div>
        <vs-icon [name]="open ? 'chevron-up' : 'chevron-down'"></vs-icon>
        <ng-container [ngTemplateOutlet]="postTitle"></ng-container>
      </div>
      <ng-container [ngTemplateOutlet]="headerEnd"></ng-container>
    </button>
  </ng-template>
</ng-template>

<ng-template #postTitle>
  <ng-content select="[slot='post-title']"></ng-content>
</ng-template>

<ng-template #headerEnd>
  <ng-content select="[slot='header-end']"></ng-content>
</ng-template>
