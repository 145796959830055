<div id="searchAndFilterBar" class="flex flex-col sm:flex-row justify-between gap-4 pb-6">
  <div class="flex flex-col sm:flex-row w-full gap-4">
    <vs-form-field
      [config]="{
        label: '',
        description: ''
      }"
    >
      <vs-select
        vsInput
        [config]="{
          placeholder: '(TODO): Role Picker'
        }"
      >
        <li
          vs-select-option
          *ngFor="
            let option of [
              { name: 'Option 1' },
              { name: 'Option 2' },
              { name: 'Option 3' },
              { name: 'Option 4' },
              { name: 'Option 5' }
            ]
          "
          [value]="option"
        >
          {{ option.name }}
        </li>
      </vs-select>
    </vs-form-field>
    <vs-form-field
      [config]="{
        label: '',
        description: ''
      }"
    >
      <vs-select
        vsInput
        [config]="{
          placeholder: '(TODO): Staff Picker'
        }"
      >
        <li
          vs-select-option
          *ngFor="
            let option of [
              { name: 'Option 1' },
              { name: 'Option 2' },
              { name: 'Option 3' },
              { name: 'Option 4' },
              { name: 'Option 5' }
            ]
          "
          [value]="option"
        >
          {{ option.name }}
        </li>
      </vs-select>
    </vs-form-field>
    <vs-badge class="justify-self-start" theme="danger">(TODO) Filters</vs-badge>
  </div>
  <div class="flex">
    <vs-badge theme="danger" class="justify-self-start">(TODO) Search by Name & Role</vs-badge>
  </div>
</div>
