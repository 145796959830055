import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'vs-input-group',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        ::ng-deep *:not(:hover):not(:focus-within):not(:active) {
          @apply invalid-within:z-10;
        }
      }
    `,
  ],
})
export class InputGroupComponent {
  @HostBinding('class') get styles() {
    const borderOverlap = this.skipMargin ? '' : `[&>:not(:first-child)]:-ml-px`;
    const indexOfFocus = ` [&_:hover]:z-20 [&_:focus-within]:z-30 [&_:active]:z-30`;
    const fixRoundedBorders = `[&>:not(:first-child)]:rounded-l-none [&>:not(:last-child)]:rounded-r-none`;
    return `flex ${borderOverlap} ${indexOfFocus} ${fixRoundedBorders}`;
  }

  @Input() skipMargin = false;
}
