<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="pointer-events-none"
  [cdkConnectedOverlayOpen]="_open"
  [cdkConnectedOverlayOrigin]="{ x: 0, y: 0 }"
  [cdkConnectedOverlayHasBackdrop]="true"
  cdkConnectedOverlayBackdropClass="backdrop-blur-sm"
  [cdkConnectedOverlayDisableClose]="true"
>
  <div class="w-screen h-screen pointer-events-none">
    <div
      class="absolute top-0 left-0 w-full h-full bg-gray-500 opacity-20 z-0"
      [class.cursor-pointer]="config?.backdropDismiss ?? true"
      [class.pointer-events-auto]="config?.backdropDismiss ?? true"
      (click)="close()"
    ></div>
    <div *ngIf="!closing" class="relative h-full pointer-events-auto z-10 bg-base w-fit" @slideInOut>
      <ng-content></ng-content>
      <button vs-icon-button appearance="clear" (click)="close()" class="absolute -right-10 top-2 !bg-transparent">
        <vs-icon name="chevron-left-double" *ngIf="config?.showCloseButton ?? true" size="24"></vs-icon>
      </button>
    </div>
  </div>
</ng-template>
