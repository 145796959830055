<vs-app-page
  (mouseenter)="indeterminate.indeterminate = true"
  [config]="{
    icon: 'cube-04',
    title: 'UI Components',
    description: 'This page is hidden from the navigation in production, use it as a kitchen sink to develop and test new UI components.'
  }"
>
  <form [formGroup]="colorFormGroup">
    <vs-card>
      <!-- Responsive Form Fields -->
      <vs-card-header
        [config]="{
      title: 'Responsive Form Fields',
      subtitle: 'Use these fields to organize forms and label your inputs.'
    }"
      >
        <vs-icon name="file-06" slot="before-title" class="text-gray-700"></vs-icon>
      </vs-card-header>
      <vs-card-content class="flex flex-col gap-4">
        <vs-form-field theme="success">
          <label for="explicit-text-input">Explicit Configuration</label>
          <sup slot="after-label" class="text-info"><vs-icon name="star-04"></vs-icon></sup>
          <span slot="description">This is explicitely composed using projection 'slots'.</span>
          <input vsInput id="explicit-text-input" type="text" placeholder="Type something here..." />
          <span slot="hint">
            <vs-icon name="lightbulb-02"></vs-icon>
            Hint can include other elements such as icons!
          </span>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Projected Elements', for: 'projected-text-input' },
          description: 'This is configured with the config @Input().',
          hint: 'Hint must be a string!'
        }"
        >
          <input vsInput id="projected-text-input" type="text" placeholder="Type something here..." />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          appearance="horizontal"
          [config]="{
            label: 'Fixed Horizontal',
            description: 'This field is fixed in horizontal mode.'
          }"
        >
          <input vsInput id="fixedh-text-input" type="text" placeholder="Type something here..." />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          appearance="vertical"
          [config]="{
          label: { text: 'Fixed Vertical', for: 'fixedv-text-input' },
          description: 'This field is fixed in vertical mode.'
        }"
        >
          <input vsInput id="fixedv-text-input" type="text" placeholder="Type something here..." />
        </vs-form-field>
        <vs-divider></vs-divider>
      </vs-card-content>

      <!-- Input States -->
      <vs-card-header
        [config]="{
      title: 'Input States',
      subtitle: 'The \'vsInput\' directive is a host directive for \'vsFocusRing\' so it inherits the focus/disabled/readonly states.'
    }"
      >
        <vs-icon name="target-02" slot="before-title" class="text-gray-700"></vs-icon>
      </vs-card-header>
      <vs-card-content class="flex flex-col gap-4">
        <vs-form-field
          [config]="{
          label: { text: 'Active Input', for: 'active-state-input' },
          description: 'Nothing special, regular state.'
        }"
        >
          <input vsInput id="active-state-input" type="text" placeholder="Type something here..." />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          theme="info"
          [config]="{
          label: { text: 'Theme Override', for: 'theme-override-state-input' },
          description: 'This input\'s style was inherited from the form-field even if the default is set to primary.'
        }"
        >
          <input vsInput id="theme-override-state-input" type="text" placeholder="Type something here..." />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Disabled Input', for: 'disabled-state-input' },
          description: 'This input cannot be used.'
        }"
        >
          <input vsInput id="disabled-state-input" type="text" disabled placeholder="Can't type here..." />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Read-Only Input', for: 'readonly-state-input' },
          description: 'This input can only be read not updated.'
        }"
        >
          <input vsInput id="readonly-state-input" type="text" readonly value="This is read-only" />
        </vs-form-field>
        <vs-divider></vs-divider>
      </vs-card-content>

      <!-- Container Types -->
      <vs-card-header
        [config]="{
      title: 'Input Containers',
      subtitle: 'Complexe inputs require different composition models.'
    }"
      >
        <vs-icon name="layout-left" slot="before-title" class="text-gray-700"></vs-icon>
      </vs-card-header>
      <vs-card-content class="flex flex-col gap-4">
        <vs-form-field
          [config]="{
          label: { text: 'Input Container', for: 'container-input' },
          description: 'The \'vs-input-container\' allows you to slot prefixes and suffixes inside the input box.'
        }"
        >
          <vs-input-container class="group">
            <vs-badge theme="info" slot="start">Prefix</vs-badge>
            <input id="container-input" placeholder="Type something here..." />
            <vs-icon name="info-circle" slot="end"></vs-icon>
          </vs-input-container>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Input Group', for: 'complex-text-input' },
          description: 'The \'vs-input-group\' groups components together with overlapping borders.'
        }"
        >
          <vs-input-group>
            <p vsInputGroupItem>https://</p>
            <input vsInput id="complex-text-input" type="url" placeholder="Paste a url here.." />
          </vs-input-group>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Input with CTA', for: 'cta-text-input' },
          description: 'The \'vs-input-group\' also work with buttons.'
        }"
        >
          <vs-input-group>
            <input vsInput id="cta-text-input" type="text" placeholder="Type something here.." />
            <button vs-button appearance="outline">
              <vs-icon slot="start" name="copy-01"></vs-icon>
              Copy
            </button>
          </vs-input-group>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: 'Button Groups',
          description: 'The \'vs-input-group\' can also be used to create button groups.'
        }"
        >
          <div class="flex gap-2">
            <vs-input-group>
              <button vs-button appearance="outline">Button 1</button>
              <button vs-button appearance="outline">Button 2</button>
              <button vs-button appearance="outline">Button 3</button>
            </vs-input-group>
            <vs-input-group>
              <button vs-button size="sm" appearance="outline">More</button>
              <button vs-icon-button size="sm">
                <vs-icon name="refresh-cw-01"></vs-icon>
              </button>
            </vs-input-group>
          </div>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Now You\'re Crazy!', for: 'crazy-input' },
          description: 'A combination of the above gives you ultimate power!'
        }"
        >
          <vs-input-group>
            <button vs-button appearance="outline">
              <vs-icon name="bell-off-03"></vs-icon>
            </button>
            <vs-input-container class="group">
              <a vsLink theme="info" type="clear" slot="start" href="http://localhost:3334/docs" target="_blank">
                API
              </a>
              <input id="crazy-input" placeholder="Type something here..." />
            </vs-input-container>
            <vs-select vsInput>
              <li
                vs-select-option
                *ngFor="let option of ['Option 1','Option 2','Option 3','Option 4','Option 5']"
                [value]="option"
              >
                {{option}}
              </li>
            </vs-select>
          </vs-input-group>
        </vs-form-field>
        <vs-divider></vs-divider>
      </vs-card-content>

      <!-- Input Field Types -->
      <vs-card-header
        [config]="{
      title: 'Input Field Types',
      subtitle: 'Native input fields styled by directives and some more custome ones.'
    }"
      >
        <vs-icon name="text-input" slot="before-title" class="text-gray-700"></vs-icon>
      </vs-card-header>
      <vs-card-content class="flex flex-col gap-4">
        <vs-form-field
          [config]="{
          label: { text: 'Text Input', for: 'basic-text-input' },
          description: 'This is a simple text input. Use the \'vsInput\' directive.'
        }"
        >
          <input vsInput id="basic-text-input" type="text" placeholder="Type something here..." />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Password Input', for: 'password-text-input' },
          description: 'Nothing special for the password type. Use the \'vsInput\' directive.'
        }"
        >
          <vs-input-container>
            <vs-icon name="lock-keyhole-square" slot="start"></vs-icon>
            <input
              id="password-text-input"
              [type]="showPassword.checked ? 'text' : 'password'"
              value="Hey beautiful ❤️"
              placeholder="Type your password here..."
            />
            <vs-icon
              slot="end"
              class="hover:text-gray-900 active:text-gray-400"
              (click)="showPassword.checked = !showPassword.checked"
              [name]="showPassword.checked ? 'eye' : 'eye-off'"
            ></vs-icon>
          </vs-input-container>
          <input #showPassword type="checkbox" class="hidden" />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: 'Currency Input',
          description: 'This is a currency input that uses a mask and a vs-select inside a vs-input-container.'
        }"
        >
          <vs-input-container>
            <p class="text-gray-600" slot="start">$</p>
            <input type="text" placeholder="100.00" mask="separator.2" thousandSeparator="," />
            <vs-select slot="end" [value]="'USD'" [config]="{ placeholder: 'CUR' }">
              <li vs-select-option *ngFor="let option of ['CAD','USD']" [value]="option">{{option}}</li>
            </vs-select>
          </vs-input-container>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Email Input (With Validation)', for: 'email-input' },
          description: 'This input natively has an invalid value. You can also use .setCustomValidity(\'Error\') to trigger this state manually.'
        }"
        >
          <vs-input-container class="group">
            <vs-icon name="at-sign" slot="start"></vs-icon>
            <input
              #emailInput
              id="email-input"
              type="email"
              value="Not an email @!@#"
              placeholder="Type your email here..."
            />
            <vs-icon
              class="hidden text-danger group-invalid-within:inline-block"
              name="info-circle"
              slot="end"
            ></vs-icon>
          </vs-input-container>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Date Picker', for: 'date-input' },
          description: 'This is a native input of type=\'date\' in a vs-input-container with a date-picker.'
        }"
        >
          <vs-input-container>
            <input #dateInput class="picker-indicator-hidden" type="date" />
            <button vs-icon-button size="xs" appearance="clear" (click)="dateInput.value = '1996-03-09'" slot="end">
              <vs-icon vsTooltip="TODO: Date Picker" name="calendar" size="16"></vs-icon>
            </button>
          </vs-input-container>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
            label: { text: 'Color Picker', for: 'color-input' },
            description: 'This is a native input of type=\'color\' used as a picker for a vs-color-input component. Also added some extra fluff to make it a theme picker of sorts.'
          }"
        >
          <ng-container *ngIf="colorFormGroup.get('colorInput')?.valid; else showError" slot="hint">
            <span class="text-gray-600">A full palette is generated from your chosen color.</span>
          </ng-container>
          <ng-template #showError>
            <span class="text-danger" slot="hint">This color is not recognized.</span>
          </ng-template>
          <div class="flex relative group w-fit h-fit" slot="before-content">
            <input #colorPicker vsInput size="lg" type="color" />
            <div
              [vsCustomTheme]="colorPicker.value"
              class="flex opacity-0 transition-opacity pointer-events-none absolute inset-0 border border-themed-300 group-hover:opacity-100 group-focus-within:opacity-100 rounded-md overflow-hidden"
            >
              <div class="flex-1 h-full flex flex-col">
                <div class="flex-1 h-full bg-themed-50"></div>
                <div class="flex-1 h-full bg-themed-200"></div>
                <div class="flex-1 h-full bg-themed-400"></div>
                <div class="flex-1 h-full bg-themed-600"></div>
                <div class="flex-1 h-full bg-themed-800"></div>
              </div>
              <div class="flex-1 h-full flex flex-col">
                <div class="flex-1 h-full bg-themed-100"></div>
                <div class="flex-1 h-full bg-themed-300"></div>
                <div class="flex-1 h-full bg-themed-500"></div>
                <div class="flex-1 h-full bg-themed-700"></div>
                <div class="flex-1 h-full bg-themed-900"></div>
              </div>
            </div>
            <vs-icon
              [vsCustomTheme]="colorPicker.value"
              name="palette"
              class="text-themed-100 absolute bottom-1 right-1 pointer-events-none"
            ></vs-icon>
          </div>
          <vs-color-input formControlName="colorInput" [colorPicker]="colorPicker"></vs-color-input>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
        label: { text: 'File Input', for: 'file-input' },
        description: 'This is a native input of type=\'file\' with a file preview once a file is selected.'
      }"
        >
          <div *ngFor="let file of $any(fileInput).files" class="flex flex-col gap-1" slot="before-content">
            <img class="w-20 h-20" [vsImagePreview]="file" />
            <button vsLink (click)="fileInput.clear()" class="text-xs">Remove</button>
          </div>
          <input
            vsInput
            #fileInput="vsInput"
            formControlName="fileInput"
            id="file-input"
            type="file"
            accept=".SVG, .PNG, .JPG, .GIF"
            moreInfo="max. 800x400px"
          />
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Simple Checkboxes', for: 'checkbox-input-0' },
          description: 'This is a set of checkboxes, simply use the \'vsInput\' directive with type=\'checkbox\'.'
        }"
        >
          <div class="flex gap-6 items-center">
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-2">
                <input vsInput id="checkbox-input-0" type="checkbox" name="checkbox" checked />
                <label vsCheckboxLabel for="checkbox-input-0">Option 1</label>
              </div>
              <div class="flex items-center gap-2">
                <input vsInput id="checkbox-input-1" type="checkbox" name="checkbox" #indeterminate />
                <label vsCheckboxLabel for="checkbox-input-1">Option 2 (Indeterminate)</label>
              </div>
              <div class="flex items-center gap-2">
                <input vsInput id="checkbox-input-2" type="checkbox" name="checkbox" disabled />
                <label vsCheckboxLabel for="checkbox-input-2">Option 3 (Disabled)</label>
              </div>
            </div>
            <div class="flex flex-col gap-2 p-2 h-fit border rounded-md">
              <p class="text-sm text-gray">Other Looks</p>
              <div class="flex items-center gap-3">
                <input vsInput appearance="checkbox-filled" type="checkbox" checked />
                <input vsInput appearance="checkbox-round" type="checkbox" checked />
                <input vsInput appearance="checkbox-round-filled" type="checkbox" checked />
                <input vsInput appearance="toggle" type="checkbox" checked />
                <input vsInput appearance="toggle" type="checkbox" disabled />
                <input vsInput appearance="toggle" type="checkbox" checked disabled />
              </div>
            </div>
          </div>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: { text: 'Simple Radios', for: 'radio-input-0' },
          description: 'This is a set of radio buttons, simply use the \'vsInput\' directive with type=\'radio\'.'
        }"
        >
          <div class="flex flex-col gap-2">
            <div class="flex items-center gap-2">
              <input vsInput id="radio-input-0" type="radio" name="radio" checked />
              <label vsRadioLabel for="radio-input-0">Option 1</label>
            </div>
            <div class="flex items-center gap-2">
              <input vsInput id="radio-input-1" type="radio" name="radio" />
              <label vsRadioLabel for="radio-input-1">Option 2</label>
            </div>
            <div class="flex items-center gap-2">
              <input vsInput id="radio-input-2" type="radio" name="radio" />
              <label vsRadioLabel for="radio-input-2">Option 3</label>
            </div>
            <div class="flex items-center gap-2">
              <input vsInput id="radio-input-3" type="radio" name="radio" disabled />
              <label vsRadioLabel for="radio-input-3">Option 4 (Disabled)</label>
            </div>
          </div>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: 'Single Select',
          description: 'This is a custom select necessary to show a nice dropdown list.'
        }"
        >
          <vs-select vsInput>
            <li
              vs-select-option
              *ngFor="let option of [{ name: 'Option 1'},{ name: 'Option 2'},{ name: 'Option 3'},{ name: 'Option 4'},{ name: 'Option 5'}]"
              [value]="option"
            >
              {{option.name}}
            </li>
          </vs-select>
        </vs-form-field>
        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: 'Multi Select',
          description: 'This is a custom select configured to allow multiple results.'
        }"
        >
          <vs-select vsInput [config]="{ multiple: true }" formControlName="selectObj">
            <li
              vs-select-option
              *ngFor="let option of [{ name: 'Option 1'},{ name: 'Option 2'},{ name: 'Option 3'},{ name: 'Option 4'},{ name: 'Option 5'}]"
              [value]="option"
            >
              {{option.name}}
            </li>
            <li vs-select-option [value]="{ name: 'custom' }">
              <div class="px-2 bg-info-200 text-info-700 text-sm">Custom Item</div>
            </li>
          </vs-select>
        </vs-form-field>

        <vs-divider></vs-divider>
        <vs-form-field
          [config]="{
          label: 'Textarea',
          description: 'This is a textarea with the vsInput directive. It can also have the vsAutosize directive to grow/shrink automatically.'
        }"
        >
          <textarea
            vsInput
            #textarea
            [vsAutosize]="autosize.checked"
            [class.!resize-y]="!autosize.checked"
            minRows="2"
            maxRows="5"
            placeholder="You can type a lot of text here..."
          ></textarea>
          <span class="flex gap-2 items-center" slot="hint">
            <input vsInput id="autosize-toggle" #autosize type="checkbox" checked (change)="textarea.click()" />
            <label for="autosize-toggle">Autosize</label>
          </span>
        </vs-form-field>
        <vs-divider></vs-divider>
      </vs-card-content>
    </vs-card>
  </form>
</vs-app-page>
