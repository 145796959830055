import { Component, Input, ViewChild } from '@angular/core';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-archive-policy-dialog',
  templateUrl: './archive-policy.dialog.html',
})
export class ArchivePolicyDialog {
  constructor(private policySvc: PolicyService, private toastSvc: ToastService) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy?: Policy.Model | null;
  @Input() dialogConfig?: DialogConfig;

  saving = false;

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  async archive() {
    this.saving = true;

    if (!this.policy) throw new Error('Unable to archive policy, policy not found');

    const archivedPolicy = await this.policySvc.archive(this.policy.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
      this.saving = false;
    });

    if (archivedPolicy?.id) {
      this.toastSvc.show({
        type: 'success',
        title: archivedPolicy.status === Policy.Status.ARCHIVED ? 'Archived policy' : 'Policy staged to be archived',
        text:
          archivedPolicy.status === Policy.Status.ARCHIVED
            ? '<strong>' + this.policy?.title + '</strong> has been successfully archived.'
            : '<strong>' +
              this.policy.title +
              '</strong> has remaining active warranties. It has been made inactive, and staged to archive once all associated warranties have expired.',
      });

      this.close();
      this.saving = false;
      this.policySvc.refreshPolicy(archivedPolicy.id);
    }
  }
}
