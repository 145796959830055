<ng-container *vsLet="items$ | async as items">
  <ng-container *vsLet="(metadata$ | async)?.totalItems as totalItems">
    <div *ngIf="totalItems || filters?.search || filters?.status" class="flex justify-between gap-4 mt-4">
      <vs-select
        vsInput
        theme="primary"
        class="min-w-[20%] max-w-[25%]"
        [config]="{ placeholder: 'All statuses' }"
        (valueChanges)="refreshTable({ status: $event })"
      >
        <li vs-select-option [disabled]="!filters?.status" [value]="null">All statuses</li>
        <li *ngFor="let status of statuses" vs-select-option [value]="status">{{ status | titlecase }}</li>
      </vs-select>

      <vs-input-container theme="primary" class="max-w-[25%]">
        <vs-icon name="search-lg" slot="start"></vs-icon>
        <input #searchInput vsInput (keyup)="refreshTable({ search: searchInput.value })" placeholder="Search" />
      </vs-input-container>
    </div>

    <ng-container *ngIf="totalItems; else noItems">
      <div class="rounded-xl overflow-hidden border border-gray-200 shadow-sm mt-4">
        <vs-table
          *ngIf="columns$ | async as columns"
          [data]="items"
          [columns]="columns"
          (rowClicked)="navigateTo($event.id)"
        ></vs-table>

        <vs-pagination [config]="paginationConfig$ | async" (pagination)="pagination$.next($event)"></vs-pagination>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #noItems>
    <div class="flex flex-col justify-center gap-2 p-6">
      <p class="text-xl font-semibold text-gray-900 self-center">
        {{
          filters?.search
            ? 'No claim items found with that search query.'
            : filters?.status
            ? 'No ' + (filters?.status | lowercase) + ' claim items found for this claim.'
            : 'No claim items found for this claim.'
        }}
      </p>
      <p class="font-normal text-gray-600 self-center mb-4">
        {{
          filters?.search
            ? 'Adjust the filters to view claim items related to this claim.'
            : filters?.status
            ? 'Once a claim item has been ' +
              ((filters?.status === itemStatus.DRAFT ? 'added' : filters?.status) | lowercase) +
              ', its details will appear here.'
            : 'Once a claim item has been added, its details will appear here.'
        }}
      </p>

      <ng-container *vsLet="claim$ | async as claim">
        <button
          *ngIf="(canOpenClaim$ | async) && claim?.status === open"
          vs-button
          appearance="outline"
          class="self-center"
          (click)="openCreateItemDialog()"
        >
          <vs-icon name="file-plus-03"></vs-icon> New Claim Item
        </button>
      </ng-container>
    </div>
  </ng-template>
</ng-container>

<ng-template #coverageTooltip let-title="title" let-description="description" let-amount="amount">
  <ws-coverage-tooltip [title]="title" [description]="description" [amount]="amount"></ws-coverage-tooltip>
</ng-template>

<ws-claim-item-details-dialog #createItemDialog (closed)="refreshTable()"></ws-claim-item-details-dialog>

<ws-claim-item-remove-dialog
  #removeItemDialog
  [claim]="claim"
  [item]="selectedItem"
  (removed)="removeDialogClosed()"
></ws-claim-item-remove-dialog>
