import { Highlightable } from '@angular/cdk/a11y';
import { CdkOption } from '@angular/cdk/listbox';
import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'li[vs-select-option]',
  templateUrl: './select-option.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: CdkOption,
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property, @angular-eslint/no-input-rename
      inputs: ['cdkOption:value', 'cdkOptionDisabled:disabled', 'cdkOptionTypeaheadLabel:typeaheadLabel'],
    },
  ],
})
export class SelectOptionComponent<T> implements Highlightable {
  @Input() value?: T;
  @Input() disabled?: boolean;
  @Input() label?: string;
  private isSelected = false;
  @HostBinding('class') get styles() {
    const selectStates = this.isSelected
      ? 'aria-selected:bg-gray-50 aria-selected:hover:bg-gray-100 hover:bg-gray-50'
      : '';
    const focusStates = 'aria-selected:focus-visible:bg-primary-50 focus:outline-none focus-visible:bg-primary-50';
    const hoverState = 'hover:bg-primary-50 hover:outline-none';
    const disabledStates =
      'aria-disabled:cursor-not-allowed aria-disabled:opacity-50 aria-disabled:hover:bg-transparent';
    return `group flex gap-1 truncate justify-between items-center text-gray-900 py-1 px-1.5 h-10 rounded-md select-none cursor-pointer ${disabledStates} ${focusStates} ${hoverState} ${selectStates}`;
  }

  constructor(public elementRef: ElementRef) {}

  getLabel() {
    return this.label?.normalize('NFD') ?? (this.elementRef.nativeElement.textContent as string).normalize('NFD');
  }

  setActiveStyles(): void {
    this.isSelected = true;
  }

  setInactiveStyles(): void {
    this.isSelected = false;
  }
}
