import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class SignedOutGuard implements CanActivate {
  constructor(private router: Router, private firebaseSvc: FirebaseService) {}

  async canActivate(): Promise<boolean | UrlTree> {
    const firebaseUser = await firstValueFrom(this.firebaseSvc.authUser$);
    if (firebaseUser) return this.router.createUrlTree(['..']);

    return true;
  }
}
