<vs-dialog
  *vsLet="canOpenClaim$ && claimInput$ | async"
  #openClaimDialog
  [config]="{ dialogContainerClass: 'max-w-[600px]' }"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,

      icon: 'file-plus-03',
      iconTheme: 'primary',

      title: isCustomerPortal ? 'Edit your complaint' : claim ? 'Edit customer\'s complaint' : 'Create a New Claim',
      subtitle: isCustomerPortal ? 'Manager your issue\'s details below' : claim ? 'Manage your customer\'s issue details below.' : 'Follow these steps to create a new claim for your customer.'
    }"
  ></vs-dialog-header>

  <div class="flex flex-col gap-4 p-4">
    <vs-stepper
      *ngIf="!claim"
      class="mb-4"
      theme="primary"
      [config]="{
        activeStep: page,
        mode: 'horizontal',
        steps: [
          { title: 'Warranty Selection', subtitle: 'Select a customer and a warranty', allowJumpToStep: true },
          { title: 'Complaint', subtitle: 'Describe the issue', allowJumpToStep: form.value.warranty ? true : false },
          { title: 'Summary', subtitle: 'Upload related documents', allowJumpToStep: form.value.complaint ? true : false }
        ]
      }"
      (stepChanged)="page = $event"
    ></vs-stepper>

    <form [formGroup]="form">
      <div *ngIf="page === pages.WARRANTY_SELECTION" class="flex flex-col gap-4">
        <p *ngIf="!form.value.customer" class="text-sm font-medium text-gray-500 -mb-2">
          Please select a customer for this claim.
        </p>
        <ws-customer-picker formControlName="customer" [openClaim]="true"></ws-customer-picker>

        <ng-container *vsLet="warranties$ | async">
          <div *ngIf="warranties?.length" class="flex flex-col gap-2">
            <p class="font-medium text-gray-500">Warranties</p>
            <p class="text-sm font-medium text-gray-700">Please select a warranty that covers the complaint</p>

            <div class="flex flex-col gap-2 max-h-[256px] overflow-y-auto scrollbar-thin">
              <div
                *ngFor="let warranty of warranties"
                class="border rounded-lg p-4 cursor-pointer"
                [class.border-2]="form.value.warranty?.id === warranty.id"
                [class.border-primary-500]="form.value.warranty?.id === warranty.id"
                (click)="setWarranty(warranty)"
              >
                <div class="flex justify-between">
                  <div class="flex gap-4">
                    <vs-icon name="home-03" size="24" class="self-center text-primary-500 -mt-1"></vs-icon>

                    <div class="flex flex-col">
                      <p class="text-sm font-medium text-gray-900">{{ warranty?.policy?.title }}</p>
                      <p class="text-sm font-normal text-gray-500">#{{ warranty.contractNumber }}</p>
                    </div>
                  </div>

                  <input
                    vsInput
                    type="radio"
                    class="cursor-pointer"
                    [checked]="form.value.warranty?.id === warranty.id"
                  />
                </div>

                <div class="flex gap-4 mt-4">
                  <div *ngIf="warranty.propertySetSubmission as propertyValues" class="flex flex-col w-full">
                    <vs-property-set-values
                      [propertySetId]="propertyValues.setId"
                      [propertyValues]="propertyValues.value"
                      [showHidden]="true"
                    ></vs-property-set-values>

                    <vs-divider class="my-2"></vs-divider>

                    <div class="flex gap-4">
                      <div class="flex gap-2">
                        <vs-icon size="14" name="clock-stopwatch" class="text-gray-500"></vs-icon>
                        <p class="text-xs font-normal text-gray-500">{{ warranty.termDuration }} Day Duration</p>
                      </div>

                      <div class="flex gap-2">
                        <vs-icon size="14" name="calendar-date" class="text-gray-500"></vs-icon>
                        <p class="text-xs font-normal text-gray-500">{{ warranty.expiryDate | date }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="flex gap-2 justify-end">
          <button
            vs-button
            theme="primary"
            [disabled]="!form.value.customer || !form.value.warranty"
            (click)="page = pages.COMPLAINT"
          >
            Next
          </button>
        </div>
      </div>

      <div *ngIf="page === pages.COMPLAINT" class="flex flex-col gap-4">
        <p class="text-sm font-medium text-gray-700">{{ isCustomerPortal ? 'Your' : 'Customer\'s' }} complaint</p>

        <textarea vsInput minRows="3" maxRows="5" formControlName="complaint"></textarea>

        <div class="flex gap-2 justify-end">
          <button
            vs-button
            appearance="outline"
            (click)="!claim ? (page = pages.WARRANTY_SELECTION) : openClaimDialog.close()"
          >
            {{ !claim ? 'Back' : 'Cancel' }}
          </button>

          <button
            vs-button
            theme="primary"
            [disabled]="!form.value.complaint"
            (click)="claim ? update() : page = pages.SUMMARY"
          >
            {{ claim ? 'Update' : 'Next' }}
          </button>
        </div>
      </div>

      <div *ngIf="page === pages.SUMMARY" class="flex flex-col gap-4">
        <div class="flex flex-col gap-4 -mx-1 px-1">
          <div class="flex gap-2 flex-wrap">
            <vs-info-card
              *ngIf="form.value.customer as customer"
              [class]="form.value.warranty?.distributor ? 'basis-[49%] max-w-[49%]' : 'basis-[100%] min-w-[100%]'"
              [config]="{
                imageUrl: customer.user?.photoURL ?? undefined,
                icon: customer.user?.photoURL ? undefined : 'user-01',

                title: customer.name,
                subtitle: customer.email,
                subtitleIcon: 'mail-01'
              }"
            ></vs-info-card>

            <vs-info-card
              *ngIf="form.value.warranty?.asset as asset"
              [class]="form.value.warranty?.distributor ? 'basis-[49%] max-w-[49%]' : 'basis-[100%] min-w-[100%]'"
              [config]="{
                icon: $any(asset.propertySet?.icon || 'home-03'),
                title: asset?.name
              }"
            >
              <vs-property-set-values
                slot="subtitle"
                [firstOnly]="true"
                [showHidden]="true"
                [propertySetId]="asset.propertySetId"
                [propertyValues]="form.value.warranty?.propertySetSubmission?.value || {}"
              ></vs-property-set-values>
            </vs-info-card>

            <vs-info-card
              *ngIf="form.value.warranty as warranty"
              [class]="form.value.warranty.distributor ? 'basis-[49%] max-w-[49%]' : 'basis-[100%] min-w-[100%]'"
              [config]="{
                icon: 'folder-shield',
                title: warranty.plan?.title,
                subtitle: warranty.contractNumber
              }"
            >
              <vs-badge *vsLet="warrantyTheme() as theme" slot="header-end" [theme]="theme">
                <vs-icon name="circle" type="solid" size="8" [class]="'text-' + theme + '-500'"></vs-icon>
              </vs-badge>
            </vs-info-card>

            <vs-info-card
              *ngIf="form.value.warranty?.distributor as distributor"
              class="basis-[49%] max-w-[49%]"
              [config]="{
                icon: 'package',
                title: distributor.name,
                subtitle: distributor.email ?? distributor.phone ?? distributor.id
              }"
            ></vs-info-card>
          </div>

          <div class="flex flex-col gap-2 min-h-[64px]">
            <vs-divider></vs-divider>

            <p class="text-sm font-medium text-gray-700">{{ isCustomerPortal ? 'Your' : 'Customer\'s' }} complaint</p>
            <p class="text-sm font-normal text-gray-900 min-h-[40px] max-h-[200px] overflow-y-auto scrollbar-thin">
              {{ form.value.complaint }}
            </p>

            <vs-divider></vs-divider>
          </div>

          <ws-claim-quick-add
            #quickAddComponent
            (noteAdded)="addNote($event)"
            (attachmentAdded)="addAttachment($event)"
          ></ws-claim-quick-add>

          <div *ngIf="notes?.length || attachments?.length" class="flex flex-wrap gap-2">
            <vs-info-card
              *ngFor="let note of notes"
              class="flex-grow max-w-[50%]"
              [config]="{ title: (note.title || 'Note'), subtitle: note.content, icon: 'sticker-square' }"
            >
              <button vs-button slot="end" appearance="clear" (click)="removeNote(note)">
                <vs-icon name="x"></vs-icon>
              </button>
            </vs-info-card>

            <vs-info-card
              *ngFor="let attachment of attachments"
              class="flex-grow max-w-[50%]"
              [config]="{ title: attachment.title || 'Document' , subtitle: attachment.description  || attachment?.file?.name, icon: 'attachment-02' }"
            >
              <button vs-icon-button slot="end" appearance="clear" (click)="removeAttachment(attachment)">
                <vs-icon name="x" size="lg"></vs-icon>
              </button>
            </vs-info-card>
          </div>

          <div class="flex items-center gap-2 mx-2">
            <input #checkbox vsInput type="checkbox" name="checkbox" class="cursor-pointer" formControlName="agree" />
            <p class="text-sm font-normal text-gray-700 cursor-pointer" (click)="checkAgree()">
              By checking this box you agree to the
              <span class="font-medium text-primary-500 cursor-pointer" (click)="previewPDFDialog.openDialog()">
                Terms And Conditions
              </span>
              on behalf of {{ form.value.customer?.name }}
            </p>
          </div>
        </div>
      </div>
    </form>
  </div>

  <div *ngIf="page === pages.SUMMARY" slot="buttons" class="flex justify-between px-4 py-2">
    <button
      vs-button
      theme="primary"
      class="!bg-primary-50 hover:!bg-primary-100 !text-primary-500 !border-none"
      [disabled]="!form.value.agree"
      (click)="submit(true)"
    >
      <vs-icon name="file-plus-03"></vs-icon> Finish, and add claim item
    </button>

    <div class="flex gap-2 justify-end">
      <button vs-button appearance="outline" (click)="page = pages.COMPLAINT">Back</button>
      <button vs-button theme="primary" [disabled]="!form.value.agree || submitting" (click)="submit(false)">
        Finish
      </button>
    </div>
  </div>
</vs-dialog>

<ws-claim-preview-pdf-dialog
  #previewPDFDialog
  class="h-full"
  [document]="form.value.warranty?.policy?.termsAndConditions ?? null"
  (closed)="previewPDFDialog.closeDialog()"
></ws-claim-preview-pdf-dialog>
