import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { Color, ThemeUtils } from '../utils';

@Directive({
  standalone: true,
  selector: '[vsCustomTheme]',
})
export class CustomThemeDirective {
  private _themeColor: string | number | Color | null = null;

  @Input('vsCustomTheme')
  set customTheme(value: string | number | Color | null) {
    this._themeColor = value;
    this.updateTheme();
  }
  get customTheme(): string | number | Color | null {
    return this._themeColor;
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

  updateTheme() {
    if (this._themeColor) {
      const themeStyles = ThemeUtils.getStyles(this._themeColor, 'theme');
      this.renderer.setProperty(this.elementRef.nativeElement, 'style', themeStyles);
    } else {
      this.renderer.setProperty(this.elementRef.nativeElement, 'style', null);
    }
  }
}
