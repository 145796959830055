import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { DistributorService } from '@wsphere/distributors/web';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, Subject, combineLatest, from, map, takeUntil } from 'rxjs';

@Component({
  selector: 'ws-generate-link-dialog',
  templateUrl: './generate-link.dialog.html',
})
export class GenerateLinkDialog implements OnInit, OnDestroy {
  constructor(
    private urlSvc: PortalService,
    private toastSvc: ToastService,
    private copyCdk: Clipboard,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService,
    public elementRef: ElementRef
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy?: Policy.Model | null;

  @PropertyListener('plan') plan$ = new BehaviorSubject<Policy.Plan.Model | null>(null);
  @Input() plan?: Policy.Plan.Model | null;

  @Input() distributor: Distributor.Model | null = null;
  @Input() dialogConfig?: DialogConfig;

  @Output() closed = new EventEmitter<boolean>();

  overlayOpen = false;
  openedPlan: { plan: Policy.Plan.Model; terms: Policy.PolicyTerm[] | null } | null = null;

  link?: string;

  @PropertyListener('selectedTerms') selectedTerms$ = new BehaviorSubject<Policy.PolicyTerm[]>([]);
  selectedTerms: Policy.PolicyTerm[] = [];

  private destroy$ = new Subject<void>();

  canCreatePolicyLinkPlatform$ = from(this.securitySvc.hasAccess('pol_CreatePolicyLink', null));

  terms$ = combineLatest([this.policy$, this.plan$]).pipe(
    map(([policy, plan]) => {
      if (!plan || !policy?.terms) return [];

      const completedTerms: Policy.PolicyTerm[] = [];

      for (const term of policy.terms) {
        const coverageConfigs = plan.coverageConfigs[term.id];

        if (!coverageConfigs) continue;

        const coveragesInvalid = coverageConfigs.some(
          config =>
            !config ||
            !config.requirement ||
            !config.deductible ||
            !config.liabilityLimit ||
            !config.price ||
            !config.liabilityGroups ||
            config.requirement.defaultValue === null ||
            (config.deductible.defaultValue === null && !config.deductible.valuePropertyPath) ||
            (config.liabilityLimit.defaultValue === null && !config.liabilityLimit.valuePropertyPath) ||
            (config.price.defaultValue === null && !config.price.valuePropertyPath) ||
            config.liabilityGroups.defaultValue === null
        );

        if (coveragesInvalid) continue;

        completedTerms.push(term);
      }

      return completedTerms;
    })
  );

  toggle() {
    this.overlayOpen = !this.overlayOpen;
    this.openedPlan = null;
  }

  openDialog() {
    this.dialog.open();
    this.selectedTerms = [];
  }

  closeDialog() {
    this.dialog.close();
  }

  cancel() {
    this.closeDialog();
    this.closed.emit(false);
  }

  selectTerm(term: Policy.PolicyTerm) {
    this.selectedTerms = [...this.selectedTerms, term];
  }

  deselectTerm(termId: string) {
    this.selectedTerms = this.selectedTerms.filter(term => term.id !== termId);
  }

  selectDistributor(distributor: Distributor.Model | null) {
    this.distributor = distributor;
  }

  isTermSelected(termId: string) {
    return this.selectedTerms.some(term => term.id === termId);
  }

  async copyLink() {
    if (!this.plan) return;
    const url = await this.urlSvc.getPortalUrl();
    this.link = url.url + '/checkout';
    const planId = this.plan.id;
    this.link += '?planId=' + planId;
    this.selectedTerms.forEach(term => (this.link += `&${planId}=${term.id}`));

    if (this.distributor) {
      this.link += `&distributorId=${this.distributor.id}`;
    }

    this.copyCdk.copy(this.link);
    this.toastSvc.show({
      type: 'success',
      title: 'Link copied',
      text:
        'A link to <strong>' +
        this.policy?.title +
        '</strong> was successfully copied to clipboard and can now be shared.',
    });
  }

  ngOnInit(): void {
    this.securitySvc.globalDistributors$.pipe(takeUntil(this.destroy$)).subscribe(async distributors => {
      if (distributors && distributors.length) {
        this.distributor = await this.distributorSvc.getOne(distributors[0].id);
      } else {
        this.distributor = null;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
