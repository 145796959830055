<vs-dialog [config]="{ dialogContainerClass: 'max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      showDivider: true,
      
      icon: 'link-01',
      iconTheme: 'primary',

      title: 'Create a link to ' + policy?.title || 'a policy',
      subtitle: 'This link will lead customers through a checkout flow for a specific policy, with the selected plans below shown to customers that qualify.'
    }"
  ></vs-dialog-header>

  <div class="px-4 py-2">
    <div class="py-2">
      <p class="text-md font-medium text-gray-900">Select policy plans</p>
      <p class="text-sm font-normal text-gray-600">
        Select the plans under this policy that you would like to offer to qualifying customers. Plans that are hidden
        by default can be selected to be shown only through this link.
      </p>
    </div>

    <ws-distributor-filter
      [hideAllDistributorsBadge]="true"
      permissionId="pol_CreatePolicyLink"
      (valueChanges)="selectDistributor($event)"
    ></ws-distributor-filter>

    <div #origin class="py-2" *vsLet="selectedPlanValues$ | async as values">
      <vs-input-container
        #trigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        (click)="toggle()"
        [class]="overlayOpen ? '!rounded-b-none' : ''"
        class="hover:cursor-pointer"
      >
        <p *ngIf="!values?.length" class="p-0 text-center self-center align-middle text-md text-gray-500 font-normal">
          Select plans to include in checkout link
        </p>
        <div *ngIf="values && values.length" class="flex flex-row gap-2 px-1 align-middle self-center">
          <div
            *ngIf="values[0] as value"
            class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
          >
            <span>{{value.text}}</span>
            <vs-icon
              name="x-close"
              class="hover:cursor-pointer self-center align-middle"
              (click)="$event.stopImmediatePropagation(); value.termId ? deselectTerm(value.planId, value.termId) : deselectPlan(value.planId)"
            ></vs-icon>
          </div>
          <div
            *ngIf="values[1] as value"
            class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
          >
            <span>{{value.text}}</span>
            <vs-icon
              name="x-close"
              class="hover:cursor-pointer self-center align-middle"
              (click)="$event.stopImmediatePropagation();  value.termId ? deselectTerm(value.planId, value.termId) : deselectPlan(value.planId)"
            ></vs-icon>
          </div>
          <div
            *ngIf="values.length > 2"
            class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
          >
            <span>+{{values.length - 2}}</span>
          </div>
        </div>
        <vs-icon slot="end" name="chevron-down" class="transition-all" [class.rotate-180]="overlayOpen"></vs-icon>
      </vs-input-container>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="overlayOpen"
        (overlayOutsideClick)="$event.stopImmediatePropagation(); toggle()"
      >
        <!-- Plans menu -->
        <vs-card
          *ngIf="!openedPlan"
          class="!rounded-b-md !rounded-t-none border border-gray-200 !shadow-md w-[536px] min-w-[287px] max-w-[536px] overflow-hidden"
        >
          <ng-container *ngFor="let planAndTerms of plans$ | async">
            <ng-container *ngIf="planAndTerms.plan as plan">
              <label
                *vsLet="planAndTerms.terms.length === 1 as isPlanAndTerm"
                tooltipStyle="light"
                tooltipPosition="end"
                tooltipMaxWidth="full"
                class="!w-full !justify-start !rounded-none flex p-2 gap-2 items-center cursor-pointer hover:bg-gray-50"
                [for]="plan.id"
              >
                <input
                  type="checkbox"
                  vsInput
                  [id]="plan.id"
                  [checked]="!!selectedPlans[plan.id]"
                  (change)="!!selectedPlans[plan.id] ? (isPlanAndTerm ? deselectTerm(plan.id, planAndTerms.terms[0].id) : deselectPlan(plan.id)) : (isPlanAndTerm ? selectTerm(plan.id, planAndTerms.terms[0].id) : selectPlan(plan.id))"
                />

                <span class="truncate cursor-pointer w-full">
                  {{ plan.title + ( isPlanAndTerm ? ' - ' + planAndTerms.terms[0].title : '')}}
                </span>
                <ng-container *ngIf="!!selectedPlans[plan.id] && !isPlanAndTerm">
                  <vs-badge *vsLet="selectedPlans[plan.id] as selectedTerms" theme="default"
                    >{{selectedTerms?.length ?? 0}} selected</vs-badge
                  >
                </ng-container>

                <button *ngIf="!isPlanAndTerm" vs-icon-button appearance="clear" (click)="openedPlan=planAndTerms">
                  <vs-icon name="chevron-right"></vs-icon>
                </button>
              </label>
            </ng-container>
          </ng-container>
        </vs-card>

        <!-- Terms menu -->
        <vs-card
          *ngIf="openedPlan"
          class="!rounded-b-md !rounded-t-none border border-gray-200 !shadow-md w-[536px] min-w-[287px] max-w-[536px] overflow-hidden"
        >
          <button
            vs-button
            appearance="clear"
            (click)="openedPlan = null"
            class="!w-full rounded-b-none px-2 rounded-tl-none"
          >
            <vs-icon name="chevron-left"></vs-icon>
            <span class="flex-grow text-start text-gray-500">{{ openedPlan.plan.title }}</span>
          </button>
          <div *ngIf="selectedPlans[openedPlan.plan.id]?.length" class="flex flex-row gap-2 mb-2 px-1">
            <div
              *ngIf="selectedPlans[openedPlan.plan.id][0] as termId"
              class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
            >
              <span>{{getSelectedTermText(openedPlan.plan.id, termId)}}</span>
              <vs-icon
                name="x-close"
                class="hover:cursor-pointer self-center align-middle"
                (click)="deselectTerm(openedPlan.plan.id, termId)"
              ></vs-icon>
            </div>
            <div
              *ngIf="selectedPlans[openedPlan.plan.id][1] as termId"
              class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
            >
              <span>{{getSelectedTermText(openedPlan.plan.id, termId)}}</span>
              <vs-icon
                name="x-close"
                class="hover:cursor-pointer self-center align-middle"
                (click)="deselectTerm(openedPlan.plan.id, termId)"
              ></vs-icon>
            </div>
            <div
              *ngIf="selectedPlans[openedPlan.plan.id].length > 2"
              class="border rounded-md px-2 py-1 bg-base flex flex-row gap-1 self-center align-middle text-sm text-gray-600"
            >
              <span>+{{selectedPlans[openedPlan.plan.id].length -2}}</span>
            </div>
          </div>
          <vs-divider></vs-divider>

          <ng-container *ngFor="let term of openedPlan.terms">
            <label
              *vsLet="!!selectedPlans[openedPlan.plan.id]?.includes(term.id) as isTermSelected"
              tooltipStyle="light"
              tooltipPosition="end"
              tooltipMaxWidth="full"
              class="!w-full !justify-start !rounded-none flex p-2 gap-2 items-center cursor-pointer hover:bg-gray-50"
              [for]="term.id"
            >
              <input
                type="checkbox"
                vsInput
                [id]="term.id"
                [checked]="isTermSelected"
                (change)="isTermSelected ? deselectTerm(openedPlan.plan.id, term.id) : selectTerm(openedPlan.plan.id, term.id)"
              />

              <span class="truncate cursor-pointer w-full"> {{ term.title }} </span>
            </label>
          </ng-container>
        </vs-card>
      </ng-template>
    </div>

    <div class="flex justify-end gap-2 mt-8">
      <button vs-button appearance="outline" size="md" (click)="closeDialog()">Cancel</button>
      <ng-container *vsLet="canCreatePolicyLinkPlatform$ | async as canCreatePolicyLinkPlatform">
        <button
          vs-button
          theme="primary"
          [disabled]="(!canCreatePolicyLinkPlatform && !distributor) || (selectedPlans === {} && !distributor)"
          (click)="copyLink()"
        >
          <vs-icon name="link-01"></vs-icon>
          Copy Link
        </button>
      </ng-container>
    </div>
  </div>
</vs-dialog>
