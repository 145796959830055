import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '@vsolv/core/users/domain';

import { ToastService } from '@vsolv/vectors-ui/alert';
import { map } from 'rxjs';
import { FirebaseService } from '../../services';
import { customPasswordStrengthValidator } from '../../validators';

@Component({
  selector: 'vs-user-security-form',
  templateUrl: './user-security-form.component.html',
})
export class UserSecurityFormComponent {
  constructor(
    private fb: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private firebaseSvc: FirebaseService,
    private toastSvc: ToastService
  ) {}

  @Input() user?: User.Model;

  isLargeScreen$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => !state.matches));

  form = this.fb.group({
    currentPassword: [
      null as string | null,
      [Validators.required, Validators.minLength(8), customPasswordStrengthValidator()],
    ],
    newPassword: [
      null as string | null,
      [Validators.required, Validators.minLength(8), customPasswordStrengthValidator()],
    ],
    confirmNewPassword: [
      null as string | null,
      [Validators.required, Validators.minLength(8), customPasswordStrengthValidator()],
    ],
  });

  inputIsValid() {
    const { newPassword, confirmNewPassword } = this.form.value;
    if (!newPassword || !confirmNewPassword) return false;
    return this.form.valid && newPassword === confirmNewPassword;
  }

  async reset() {
    try {
      this.form.reset({ currentPassword: null, newPassword: null, confirmNewPassword: null });
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  async updatePassword() {
    try {
      const { currentPassword, newPassword, confirmNewPassword } = this.form.value;
      if (!currentPassword || !newPassword || !confirmNewPassword || confirmNewPassword !== newPassword) return;

      const isReauthenticated = await this.firebaseSvc.reauthenticate(currentPassword);
      if (!isReauthenticated) {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong while authenticating you.',
          text: 'Please double check your password and try again.',
        });

        return;
      }

      if (!this.user?.id) return;
      await this.firebaseSvc.updatePassword(newPassword);
    } catch (e) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error! Something went wrong ',
        text: 'please try again',
      });
    }
  }

  async submit() {
    if (!this.user?.id) return;
    if (!this.inputIsValid()) return;
    this.form.disable();

    try {
      await this.updatePassword();
      this.reset();

      this.toastSvc.show({
        type: 'success',
        title: 'Successfully updated password',
        text: 'Your password has been changed',
      });
    } catch (e) {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong when updating your password.',
        text: 'Please try again.',
      });
    }

    this.form.enable();
  }
}
