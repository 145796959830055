/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Config } from '@vsolv/packages/portal-config/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Staff } from '@wsphere/staff/domain';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'ws-customer-portal-details',
  templateUrl: './customer-portal-details.component.html',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class CustomerPortalDetailsComponent {
  constructor(
    private fb: FormBuilder,
    private portalSvc: PortalService,
    private toastSvc: ToastService,
    private http: HttpClient
  ) {}

  @Input() config!: Config.PortalConfig;
  @Output() refresh = new EventEmitter();
  @Input() staff!: Staff.Model;
  @Input() canEditOrganization?: boolean;

  updatedCustomerPortalValues: Config.UpdatePortalConfigDetailsRequestDto = {};
  logoFile: File | null = null;
  logoUrl = '';
  avatarFile: File | null = null;
  avatarUrl = '';

  form = this.fb.group({
    logo: new FormControl({ value: this.logoFile, disabled: false }),
    avatar: new FormControl({ value: this.avatarFile, disabled: false }),
    prColor: new FormControl({ value: '', disabled: false }, Validators.required),
    acColor: new FormControl({ value: '', disabled: false }, Validators.required),

    //TODO: disabled functionality as per Felix
    // useAdminLogo: new FormControl(
    //   { value: this.disableAdminLogo, disabled: this.disableAdminLogo },
    //   Validators.required
    // ),
    // useAdminAvatar: new FormControl(
    //   { value: this.disableAdminAvatar, disabled: this.disableAdminAvatar },
    //   Validators.required
    // ),
    // useAdminPrColor: new FormControl(
    //   { value: this.disableAdminPrColor, disabled: this.disableAdminPrColor },
    //   Validators.required
    // ),
    // useAdminAcColor: new FormControl(
    //   { value: this.disableAdminAcColor, disabled: this.disableAdminAcColor },
    //   Validators.required
    // ),
  });

  resetFormValue: { prColor: string; acColor: string; logo: File | null; avatar: File | null } = {
    prColor: '',
    acColor: '',
    logo: null,
    avatar: null,
  };

  @PropertyListener('config') setCustomerPortalDetails(value: Config.PortalConfig) {
    this.resetFormValue = {
      prColor: value?.prColor || '',
      acColor: value?.acColor || '',
      logo: this.logoFile || null,
      avatar: this.avatarFile || null,
    };
    this.resetForm();
  }
  @PropertyListener('canEditOrganization')
  setState(value: boolean) {
    value ? this.form.enable() : this.form.disable();
  }

  inputIsValid() {
    return this.form.valid;
  }

  async resetForm() {
    try {
      this.resetImages();
      this.form.reset(this.resetFormValue);
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  resetImages() {
    this.logoFile = null;
    this.logoUrl = '';
    if (this.config?.logo && this.config?.logo.length) {
      this.resetFormValue.logo = this.logoFile;
      this.logoUrl = this.config.logo;
    }

    this.avatarFile = null;
    this.avatarUrl = '';
    if (this.config?.avatar && this.config?.avatar.length) {
      this.resetFormValue.avatar = this.avatarFile;
      this.avatarUrl = this.config.avatar;
    }
  }

  onImageUpload(event: any, field: string) {
    if (!event.target.files[0].type.includes('image')) {
      this.toastSvc.show({
        type: 'error',
        title: 'Invalid file',
        text: 'Uploaded file type is not supported.',
      });
      (event.target as HTMLInputElement).value = ''; // clear the file input
      event.preventDefault(); // stop the onchange event from executing
      if (field === 'logo') {
        this.form.controls.logo.setValue(this.logoFile);
        this.form.controls.logo.markAsUntouched();
        this.form.controls.logo.markAsPristine();
      } else {
        this.form.controls.avatar.setValue(this.avatarFile);
        this.form.controls.avatar.markAsUntouched();
        this.form.controls.avatar.markAsPristine();
      }
      return;
    }
    const fr = new FileReader();
    fr.readAsDataURL(event.target.files[0]);
    fr.onload = e => {
      const img = new Image();
      img.src = e.target?.result as string;
      img.onload = () => {
        const { width, height } = img;
        if (width > 800 || height > 400) {
          this.toastSvc.show({
            type: 'error',
            title: 'Invalid image size',
            text: 'Uploaded image dimensions exceeded the limit of 800x400px.',
          });
          (event.target as HTMLInputElement).value = ''; // clear the file input
          event.preventDefault(); // stop the onchange event from executing
          if (field === 'logo') {
            this.form.controls.logo.setValue(this.logoFile);
            this.form.controls.logo.markAsUntouched();
            this.form.controls.logo.markAsPristine();
          } else {
            this.form.controls.avatar.setValue(this.avatarFile);
            this.form.controls.avatar.markAsUntouched();
            this.form.controls.avatar.markAsPristine();
          }
          return;
        }
      };
    };
  }

  async confirm() {
    this.form.disable();
    try {
      await this.updateDetails();
      this.resetForm();
      this.toastSvc.show({
        type: 'success',
        title: 'Organization settings updated',
        text: 'Your administration portal settings have been successfully updated.',
      });
    } catch (e: any) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error',
        text: `${e['status'] + ': ' + e['statusText']}`,
      });
    }
    this.form.enable();
  }

  async updateDetails() {
    const formControlValue = this.form.value;

    const dto: Config.UpdatePortalConfigDetailsRequestDto = {
      prColor: '',
      acColor: '',
    };

    dto.prColor = formControlValue.prColor || '#f79523';
    dto.acColor = formControlValue.acColor || '#4d4d4d';

    if (formControlValue.logo && formControlValue.logo.name !== this.config?.logo) {
      dto.logo = formControlValue.logo.name;
    }

    if (formControlValue.avatar && formControlValue.avatar.name !== this.config?.avatar) {
      dto.avatar = formControlValue.avatar.name;
    }

    const result = await this.portalSvc.updateConfig(dto);
    if (formControlValue.logo && result.logoUploadUrl) {
      await this.uploadFileToGCP(formControlValue.logo, result.logoUploadUrl);
    }
    if (formControlValue.avatar && result.avatarUploadUrl) {
      await this.uploadFileToGCP(formControlValue.avatar, result.avatarUploadUrl);
    }
    this.config = result.config;
    this.refresh.emit(result.config);
  }

  async removePortalConfigLogo() {
    try {
      await this.portalSvc.updateConfig({ logo: null });
      this.form.patchValue({ logo: null });
    } catch (e) {
      console.error(e);
    }
  }

  async removePortalConfigAvatar() {
    try {
      await this.portalSvc.updateConfig({ avatar: null });
      this.form.patchValue({ avatar: null });
    } catch (e) {
      console.error(e);
    }
  }

  resetLogo() {
    this.logoUrl = '';
  }

  resetAvatar() {
    this.avatarUrl = '';
  }

  private async uploadFileToGCP(file: File, uploadUrl: string) {
    let buffer;
    if (file) buffer = await this.readFileBuffer(file);
    await firstValueFrom(this.http.put(uploadUrl, buffer)).catch(err => console.error(err));
  }
  async readFileBuffer(file: File) {
    return new Promise(res => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = event => res(event?.target?.result);
    }) as unknown as ArrayBuffer;
  }
}
