<div class="px-6">
  <ng-container *ngIf="email">
    <div class="flex flex-row items-center justify-start py-6">
      <img [src]="tenantLogo" [alt]="tenantName" height="40" width="150" />
    </div>
    <div class="pt-8" [innerHtml]="'Hello, Customer! <br\><br\>' + email.content | safe : 'html'"></div>
    <ng-container *ngIf="email?.type === 'Warranty Provisioned'">
      <div class="flex flex-row items-center pt-8 px-8">
        <img [src]="email.instructionImage" [alt]="tenantName" class="w-full" />
      </div>
    </ng-container>
    <div class="py-6">
      <button vs-button theme="primary" appearance="default" size="lg">{{ emailButton }}</button>
    </div>
    <div class="pb-6">
      Thanks,
      <br />
      {{ tenantName }}
    </div>
  </ng-container>
</div>
