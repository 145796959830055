/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService, StaffService } from '@wsphere/staff/web';
import { Observable, ReplaySubject, combineLatest, from, shareReplay, switchMap } from 'rxjs';
import { InviteMemberDialog } from '../../dialogs';

@Component({
  selector: 'ws-distributor-team-page',
  templateUrl: './distributor-team.page.html',
})
export class DistributorTeamPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private staffSvc: StaffService,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('inviteMemberDialog') InviteMemberDialog?: InviteMemberDialog;

  @Input() distributor?: Distributor.Model;

  @PropertyListener('distributor') private distributor$ = new ReplaySubject<Distributor.Model | undefined>(undefined);
  staff$ = from(this.staffSvc.retrieveSelf()).pipe(shareReplay(1));

  readonly canInviteMembers$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(async ([distributor]) => {
      return await this.securitySvc.hasAccess(
        'dist_InviteStaff',
        distributor?.permissionKey ? [distributor.permissionKey] : null
      );
    })
  );

  activated = Distributor.Status.ACTIVE;

  openInviteMemberDialog() {
    this.InviteMemberDialog?.open();
  }

  navigateTo(path?: string) {
    if (path) this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
