<ng-container *ngIf="!property.config.allowedValues?.length; else enum">
  <input
    *ngIf="!allowBlockFormat || property.config.format !== 'block'; else block"
    type="text"
    [formControl]="formControl"
    [required]="required"
    (keyup)="phoneNumberKeyUp($event)"
    (blur)="formatPhoneNumber()"
    [placeholder]="
      property.config.format === 'phone' ? '(___) ___-____' : 'Please enter ' + (property.name | lowercase) + '...'
    "
  />
</ng-container>

<ng-template #enum>
  <vs-select [formControl]="formControl" class="!w-full pt-[6px]">
    <li vs-select-option *ngFor="let option of property.config.allowedValues" [value]="option">
      {{ option }}
    </li>
  </vs-select>
</ng-template>

<ng-template #block>
  <textarea vsInput id="text-input" type="text" [formControl]="formControl"></textarea>
</ng-template>
