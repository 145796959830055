import { Component } from '@angular/core';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { TableCellComponent } from '@vsolv/vectors/table';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
export interface DistributorAndRoles {
  distributor: Distributor.Model | null;
  roles: Staff.Security.RoleAssignment[];
  theme?: ThemeColor;
}

export interface DistributorRolesCellProps {
  items: DistributorAndRoles[];

  click?(): void | Promise<void>;
}

@Component({
  templateUrl: './distributor-roles.component.html',
})
export class DistributorRolesCellComponent extends TableCellComponent<DistributorRolesCellProps> {
  async _click() {
    await this.data?.click?.();
  }
}
