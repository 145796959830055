import { Component, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Property } from '@vsolv/packages/properties/domain';
import { MenuTriggerForDirective } from '@vsolv/vectors-ui/menu';
import { PropertyInputComponent } from '../../abstract-property-input.component';

@Component({
  selector: 'vs-date-property-input',
  templateUrl: './date-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent,
    },
    { provide: NG_VALIDATORS, useExisting: DateInputComponent, multi: true },
  ],
})
export class DateInputComponent extends PropertyInputComponent {
  @Input() override property!: Property.DateModel;

  @Input() showPicker = true;

  override writeValue(value: unknown): void {
    if (value) {
      if (typeof value === 'string') {
        value = new Date(value).toISOString().split('T')[0];
      } else if (typeof value === 'object') {
        value = (value as Date).toISOString().split('T')[0];
      }
    }

    this.value = value;
    this.formControl?.setValue(value);
  }

  populateDate(event: Date, menu: MenuTriggerForDirective) {
    this.formControl.patchValue(event.toISOString().split('T')[0]);
    menu.close();
  }
}
