/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Staff } from '@wsphere/staff/domain';
import { SecurityService } from '@wsphere/staff/web';
import { map, switchMap } from 'rxjs';
import { GenerateApiKeyDialog } from '../../dialogs';

@Component({
  selector: 'ws-web-developer-page',
  templateUrl: './developer.page.html',
})
export class DeveloperPage {
  constructor(private route: ActivatedRoute, private securitySvc: SecurityService) {}

  @ViewChild('generateApiKeyDialog') generateApiKeyDialog!: GenerateApiKeyDialog;
  apiKeys = 0;
  refreshTableInput?: boolean;

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  currentStaff$ = this.route.parent!.parent!.parent!.data.pipe(map(data => data['staff'] as Staff.Model));

  canManageAPIKeys$ = this.currentStaff$.pipe(
    switchMap(async _staff => await this.securitySvc.hasAccess('stf_ManageAPIKeys', null))
  );

  openGenerateDialog() {
    this.generateApiKeyDialog.openDialog();
  }

  updateKeyValue(keys: number) {
    this.apiKeys = keys;
  }

  closeDialog() {
    this.generateApiKeyDialog.closeDialog();
  }
}
