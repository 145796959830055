import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@vsolv/core/users/web';
import { customPasswordStrengthValidator, FirebaseService } from '@vsolv/packages/firebase/web';
import { firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'ws-admin-password-reset-page',
  templateUrl: './password-reset.page.html',
})
export class PasswordResetPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firebaseSvc: FirebaseService,
    private userSvc: UserService
  ) {}

  formGroup = new FormGroup({
    password: new FormControl('', [Validators.required, Validators.minLength(8), customPasswordStrengthValidator()]),
    confirmPassword: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      customPasswordStrengthValidator(),
    ]),
  });

  oneTimeToken: string | null = null;
  email: string | null = null;

  success = false;

  async ngOnInit() {
    this.oneTimeToken = await firstValueFrom(this.route.data.pipe(map(data => data['oneTimeToken'])));
    this.email = await firstValueFrom(this.route.data.pipe(map(data => data['email'])));
  }

  inputIsValid() {
    return this.formGroup.valid;
  }

  async enter() {
    await this.router.navigate(['../']);
  }
  async back() {
    await this.firebaseSvc.signOut();
    await this.router.navigate(['sign-in']);
  }

  async submit() {
    const newPassword = this.formGroup.value.password;
    const confirmPassword = this.formGroup.value.confirmPassword;

    if (!this.oneTimeToken || !this.email || !this.inputIsValid() || !newPassword || newPassword !== confirmPassword)
      return;

    const customToken = await this.firebaseSvc.exchangeToken(this.email, this.oneTimeToken);
    if (!customToken) return;

    await this.firebaseSvc.customTokenSignIn(customToken.signInToken, customToken.tenantId);

    try {
      await this.firebaseSvc.updatePassword(newPassword);
      this.success = true;
    } catch (e) {
      console.error(e);
      this.success = false;
    }
  }
}
