import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Documents } from '@vsolv/packages/documents/domain';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { Claim, ClaimLifecycleStep } from '@wsphere/warranties/domain';
import { BehaviorSubject, catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class ClaimService {
  private http = inject(HttpClient);

  private claim$ = new BehaviorSubject<Claim.Model | null>(null);

  list(data: Claim.ListClaimsQueryRequest) {
    return firstValueFrom(
      this.http.get<Claim.ListClaimsQueryResponse>(`/api/claims`, {
        params: {
          ...(data.page && { page: data.page }),
          ...(data.limit && { limit: data.limit }),
          ...(data.search && { search: data.search }),
          ...(data.statuses && { statuses: data.statuses }),
          ...(data.warrantyId && { warrantyId: data.warrantyId }),
          ...(data.coverageId && { coverageId: data.coverageId }),
          ...(data.customerId && { customerId: data.customerId }),
          ...(data.claimNumber && { claimNumber: data.claimNumber }),
          ...(data.distributorId && { distributorId: data.distributorId }),
          ...(data.claimItemStatuses && { claimItemStatuses: data.claimItemStatuses }),
          ...(data.claimPaymentStatuses && { claimPaymentStatuses: data.claimPaymentStatuses }),
          ...(data.claimLifecycleSteps && { claimLifecycleSteps: data.claimLifecycleSteps }),
        },
      })
    );
  }

  async get(claimId: string) {
    return await firstValueFrom(
      this.http.get<Claim.Model>(`/api/claims/${claimId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  open(data: Claim.OpenClaimRequest) {
    return firstValueFrom(
      this.http.post<Claim.OpenClaimResponse>(`/api/claims`, {
        warrantyId: data.warrantyId,
        complaint: data.complaint,
        notes: data.notes,
        ...(data.items && { items: data.items }),
      })
    );
  }

  update(claimId: string, data: Claim.UpdateClaimRequest) {
    return firstValueFrom(
      this.http.patch<Claim.ExpireClaimResponse>(`/api/claims/${claimId}`, {
        complaint: data.complaint,
      })
    );
  }

  updateClaimLifecycleSteps(claimId: string, data: ClaimLifecycleStep.UpdateClaimLifecycleStepsRequest) {
    return firstValueFrom(
      this.http.patch<ClaimLifecycleStep.UpdateClaimLifecycleStepsResponse>(
        `/api/claims/${claimId}/claim-lifecycle-steps`,
        data
      )
    );
  }

  expire(claimId: string) {
    return firstValueFrom(this.http.patch<Claim.ExpireClaimResponse>(`/api/claims/${claimId}/expire`, {}));
  }

  cancel(claimId: string) {
    return firstValueFrom(this.http.patch<Claim.ExpireClaimResponse>(`/api/claims/${claimId}/cancel`, {}));
  }

  close(claimId: string) {
    return firstValueFrom(this.http.patch<Claim.ExpireClaimResponse>(`/api/claims/${claimId}/close`, {}));
  }

  listItems(claimId: string, data?: Claim.ClaimItem.ListClaimItemsQueryRequest) {
    return firstValueFrom(
      this.http.get<Claim.ClaimItem.ListClaimItemsQueryResponse>(`/api/claims/${claimId}/items`, {
        params: {
          ...(data?.page && { page: data.page }),
          ...(data?.limit && { limit: data.limit }),
          ...(data?.status && { status: data.status }),
          ...(data?.search && { search: data.search }),
          ...(data?.withDeleted && { withDeleted: data.withDeleted }),
        },
      })
    );
  }

  getItem(claimId: string, itemId: string) {
    return firstValueFrom(
      this.http.get<Claim.ClaimItem.Model>(`/api/claims/${claimId}/items/${itemId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  createItem(claimId: string, data: Claim.ClaimItem.CreateClaimItemRequest) {
    return firstValueFrom(
      this.http.post<Claim.ClaimItem.CreateClaimItemResponse>(`/api/claims/${claimId}/items`, {
        ...(data.description && { description: data.description }),
        ...(data.coverageId ? { coverageId: data.coverageId } : { coverageId: null }),
      })
    );
  }

  updateItem(claimId: string, itemId: string, data: Claim.ClaimItem.UpdateClaimItemRequest) {
    return firstValueFrom(
      this.http.patch<Claim.ClaimItem.UpdateClaimItemResponse>(`/api/claims/${claimId}/items/${itemId}`, {
        ...(data.cure && { cure: data.cure }),
        ...(data.amount && { amount: data.amount }),
        ...(data.resolution && { resolution: data.resolution }),
        ...(data.coverageId && { coverageId: data.coverageId }),
        ...(data.description && { description: data.description }),
      })
    );
  }

  approveItem(claimId: string, itemId: string, reason: string) {
    return firstValueFrom(
      this.http.patch<Claim.ClaimItem.ApproveClaimItemResponse>(`/api/claims/${claimId}/items/${itemId}/approve`, {
        reason,
      })
    );
  }

  rejectItem(claimId: string, itemId: string, reason: string) {
    return firstValueFrom(
      this.http.patch<Claim.ClaimItem.ApproveClaimItemResponse>(`/api/claims/${claimId}/items/${itemId}/reject`, {
        reason,
      })
    );
  }

  resolveItem(claimId: string, itemId: string, data: Claim.ClaimItem.ResolveClaimItemRequest) {
    return firstValueFrom(
      this.http.patch<Claim.ClaimItem.ApproveClaimItemResponse>(`/api/claims/${claimId}/items/${itemId}/resolve`, {
        ...(data.amount && { amount: data.amount }),
        ...(data.resolution && { resolution: data.resolution }),
      })
    );
  }

  redraftItem(claimId: string, itemId: string, reason?: string | null) {
    return firstValueFrom(
      this.http.patch<Claim.ClaimItem.RedraftClaimItemResponse>(`/api/claims/${claimId}/items/${itemId}/redraft`, {
        ...(reason ? { reason } : { reason: null }),
      })
    );
  }

  removeItem(claimId: string, itemId: string, deletedReason: string) {
    return firstValueFrom(
      this.http.delete<Claim.ClaimItem.DeleteClaimItemResponse>(`/api/claims/${claimId}/items/${itemId}`, {
        params: { deletedReason },
      })
    );
  }

  listNotes(claimId: string, data?: Claim.Notes.ListClaimNotesQueryRequest) {
    return firstValueFrom(
      this.http.get<Claim.Notes.ListClaimNotesQueryResponse>(`/api/claims/${claimId}/notes`, {
        params: {
          ...(data?.page && { page: data.page }),
          ...(data?.limit && { limit: data.limit }),
          ...(data?.search && { search: data.search }),
          ...(data?.createdById && { createdById: data.createdById }),
        },
      })
    );
  }

  createNote(claimId: string, data: Claim.Notes.CreateClaimNoteRequest) {
    return firstValueFrom(
      this.http.post<Claim.Notes.CreateClaimNoteResponse>(`/api/claims/${claimId}/notes`, {
        title: data.title,
        content: data.content,
        feed: data.feed,
      })
    );
  }

  updateNote(claimId: string, noteId: string, data: Claim.Notes.UpdateClaimNoteRequest) {
    return firstValueFrom(
      this.http.patch<Claim.Notes.UpdateClaimNoteResponse>(`/api/claims/${claimId}/notes/${noteId}`, {
        title: data.title,
        content: data.content,
        feed: data.feed,
      })
    );
  }

  deleteNote(claimId: string, noteId: string) {
    return firstValueFrom(
      this.http.delete<Claim.Notes.DeleteClaimNoteResponse>(`/api/claims/${claimId}/notes/${noteId}`)
    );
  }

  async listDocuments(claimId: string, dto?: Claim.Documents.ListClaimDocumentsRequest) {
    return await firstValueFrom(
      this.http.get<Claim.Documents.ListClaimDocumentsResponse>(`/api/claims/${claimId}/documents`, {
        params: {
          ...(dto?.page && { page: dto.page }),
          ...(dto?.limit && { limit: dto.limit }),
          ...(dto?.search && { search: dto.search }),
          ...(dto?.feed?.length && { feed: dto.feed }),
          ...(dto?.extension && { extension: dto.extension }),
        },
      })
    );
  }

  async getDocument(claimId: string, documentId: string) {
    return await firstValueFrom(
      this.http.get<Documents.RetrieveDocumentResponse>(`/api/claims/${claimId}/documents/${documentId}`)
    );
  }

  async createDocument(claimId: string, data: Claim.Documents.CreateClaimDocumentRequest) {
    const formData = new FormData();
    for (const key in data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (data as any)[key];
      if (value !== undefined && value !== null) formData.set(key, value);
    }

    const response = await firstValueFrom(
      this.http.post<StorageItem.UploadCommandResponse>(`/api/claims/${claimId}/documents`, formData)
    ).catch(err => {
      throw new Error(err.message);
    });

    return response.id;
  }

  async updateDocument(
    claimId: string,
    documentId: string,
    dto: { title?: string; description?: string | null; visibleToCustomer?: boolean }
  ) {
    return await firstValueFrom(
      this.http.patch<Documents.UpdateDocumentResponse>(`/api/claims/${claimId}/documents/${documentId}`, {
        title: dto.title,
        description: dto.description,
        ...(dto.visibleToCustomer !== undefined && { feed: dto.visibleToCustomer ? ['staff', 'customer'] : ['staff'] }),
      })
    );
  }

  async removeDocument(claimId: string, documentId: string) {
    return await firstValueFrom(
      this.http.delete<Documents.DeleteDocumentCommandResponse>(`/api/claims/${claimId}/documents/${documentId}`)
    );
  }

  listPayments(claimId: string, data?: Claim.ClaimPayment.ListClaimPaymentsQueryRequest) {
    return firstValueFrom(
      this.http.get<Claim.ClaimPayment.ListClaimPaymentQueryResponse>(`/api/claims/${claimId}/payments`, {
        params: {
          ...(data?.page && { page: data.page }),
          ...(data?.limit && { limit: data.limit }),
          ...(data?.search && { search: data.search }),
          ...(data?.createdById && { createdById: data.createdById }),
        },
      })
    );
  }

  async getPayment(claimId: string, paymentId: string) {
    return await firstValueFrom(
      this.http.get<Claim.ClaimPayment.RetrieveClaimPaymentQueryResponse>(
        `/api/claims/${claimId}/payments/${paymentId}`
      )
    );
  }

  createPayment(claimId: string, data: Claim.ClaimPayment.CreateClaimPaymentRequest) {
    return firstValueFrom(
      this.http.post<Claim.ClaimPayment.CreateClaimPaymentResponse>(`/api/claims/${claimId}/payments`, {
        ...(data.title && { title: data.title }),
        ...(data.description && { description: data.description }),
        ...(data.destination && { destination: data.destination }),
        ...(data.amount && { amount: data.amount }),
        ...(data.customerId && { customerId: data.customerId }),
      })
    );
  }

  updatePayment(claimId: string, paymentId: string, data: Claim.ClaimPayment.UpdateClaimPaymentRequest) {
    return firstValueFrom(
      this.http.patch<Claim.ClaimPayment.UpdateClaimPaymentResponse>(`/api/claims/${claimId}/payments/${paymentId}`, {
        ...(data.title && { title: data.title }),
        ...(data.description && { description: data.description }),
        ...(data.destination && { destination: data.destination }),
        ...(data.amount && { amount: data.amount }),
      })
    );
  }

  issuePayment(claimId: string, paymentId: string) {
    return firstValueFrom(this.http.patch<{ id: string }>(`/api/claims/${claimId}/payments/${paymentId}/issue`, {}));
  }

  voidPayment(claimId: string, paymentId: string) {
    return firstValueFrom(this.http.patch<{ id: string }>(`/api/claims/${claimId}/payments/${paymentId}/void`, {}));
  }

  getClaim() {
    return this.claim$.asObservable();
  }

  async refreshClaim(claim?: Claim.Model) {
    if (this.claim$.value) {
      if (claim) {
        if (this.claim$.value.id !== claim.id) this.claim$.next(claim);
      } else {
        const id = this.claim$.value.id;
        const claim = await this.get(id);
        this.claim$.next(claim);
      }
    } else {
      if (claim) {
        this.claim$.next(claim);
      }
    }
  }

  clearClaim() {
    this.claim$.next(null);
  }

  lifecycleStepsFilter() {
    return firstValueFrom(this.http.get<string[]>(`/api/lifecycle-steps-filter`));
  }
}
