<p class="text-sm font-medium text-gray-900 mt-4">Policy Details</p>
<p class="text-sm text-gray-600 mb-4">Add a name, short description, number, and waiting period for this policy.</p>

<form *vsLet="patchValue$ | async" class="flex flex-col gap-4" [formGroup]="form">
  <vs-form-field>
    <div class="flex gap-4 items-center">
      <span class="text-sm font-medium text-gray-700 basis-1/5">Title</span>
      <input vsInput id="title-input" type="text" formControlName="title" placeholder="Enter the policy title" />
    </div>
  </vs-form-field>

  <vs-form-field>
    <div class="flex gap-4 items-center">
      <span class="text-sm font-medium text-gray-700 basis-1/5">
        Friendly Title
        <vs-icon
          name="info-circle"
          tooltipStyle="light"
          class="text-base !text-gray-900 self-center -mb-0.5"
          [vsTooltip]="{
            title: 'Friendly Title',
            subtitle:
              'This title will be displayed to customers and distributors when selecting a policy at checkout. We recommend a short title that explains what type of asset this policy would cover.'
          }"
        ></vs-icon>
      </span>

      <input
        vsInput
        id="title-input"
        type="text"
        formControlName="friendlyTitle"
        placeholder="Enter the policy's friendly title"
      />
    </div>

    <div class="flex w-full justify-end">
      <span
        *ngIf="form.value.friendlyTitle && form.value.friendlyTitle.length > 50"
        slot="hint"
        class="text-danger text-right text-sm"
      >
        {{ form.value.friendlyTitle.length }} / 50
      </span>
    </div>
  </vs-form-field>

  <vs-form-field>
    <div class="flex gap-4 items-center">
      <label class="text-sm font-medium text-gray-700 basis-1/5">Tagline</label>
      <input
        vsInput
        id="tagline-input"
        type="text"
        formControlName="tagline"
        placeholder="Enter the policy's short description"
      />
    </div>

    <div class="flex w-full justify-end">
      <span
        *ngIf="form.value.tagline && form.value.tagline.length > 100"
        slot="hint"
        class="text-danger text-right text-sm"
      >
        {{ form.value.tagline.length }} / 100
      </span>
    </div>
  </vs-form-field>

  <vs-form-field>
    <div class="flex gap-4 items-start">
      <label class="text-sm font-medium text-gray-700 basis-1/5">Description</label>
      <textarea
        vsInput
        id="description-input"
        formControlName="description"
        placeholder="Enter the policy's description"
      ></textarea>
    </div>
  </vs-form-field>

  <vs-form-field>
    <label class="flex gap-1 text-sm font-medium text-gray-700 basis-1/5">
      Icon
      <vs-icon
        name="info-circle"
        tooltipStyle="light"
        class="text-base !text-gray-900 self-center"
        [vsTooltip]="{
          title: 'Icon',
          subtitle: 'This icon will be displayed to customers and distributors when selecting a policy at checkout.'
        }"
      ></vs-icon>
    </label>

    <vs-icon-picker formControlName="icon"></vs-icon-picker>
  </vs-form-field>

  <vs-form-field *ngIf="!editing">
    <div class="flex gap-4 items-center">
      <label class="text-sm font-medium text-gray-700 basis-1/5">Prefix</label>
      <input
        class="basis-2/6 ml-2"
        vsInput
        id="prefix-input"
        type="text"
        formControlName="prefix"
        vsTooltip="Once you set this policy's prefix, it cannot be adjusted."
        tooltipStyle="light"
        placeholder="ABC"
      />

      <label class="text-sm font-medium text-gray-700 basis-1/5">Number</label>
      <input vsInput id="number-input" type="text" class="basis-2/6" formControlName="policyNumber" readonly="true" />
    </div>
    <span
      *ngIf="form.value.prefix && form.get('prefix')?.errors?.['pattern']"
      slot="hint"
      class="text-danger text-left text-sm"
    >
      Prefix must only contain letters
    </span>
  </vs-form-field>

  <vs-form-field>
    <div class="flex flex-row">
      <div class="flex gap-1 self-center basis-1/2">
        <label class="text-sm font-medium text-gray-700" for="claim-input">Claim Waiting Period</label>

        <vs-icon
          class="self-center"
          name="info-circle"
          tooltipStyle="light"
          [vsTooltip]="{ template: waitingPeriodTooltipTemplate }"
        ></vs-icon>
      </div>

      <vs-input-container class="basis-1/2">
        <input id="claim-input" placeholder="Days" type="number" min="0" formControlName="claimWaitingPeriod" />
        <span slot="end">Days</span>
      </vs-input-container>
    </div>
  </vs-form-field>

  <vs-form-field>
    <div class="flex items-center gap-2">
      <input vsInput id="activation" type="checkbox" name="activation" formControlName="requiresWarrantyActivation" />
      <label class="text-sm font-medium text-gray-700" vsCheckboxLabel for="activation">
        Warranties require activation
      </label>
    </div>
    <p class="ml-6 text-gray-700 text-sm">
      Customers will have to activate their warranty the first time they visit the customer portal.
    </p>
  </vs-form-field>
</form>

<!-- Buttons -->
<div
  class="flex gap-2 flex-wrap sm:flex-row mt-4"
  [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
>
  <div
    *ngIf="!editing"
    class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
  >
    <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
  </div>

  <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
    <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
    <span class="self-center"> Saving will create a new policy version, the current policy will not be modified </span>
  </div>

  <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
    <button vs-button appearance="outline" class="flex-grow sm:flex-grow-0" [class.flex-1]="editing" (click)="close()">
      Cancel
    </button>

    <button
      vs-button
      size="md"
      theme="primary"
      class="flex-grow sm:flex-grow-0"
      [class.flex-1]="editing"
      [disabled]="saving || form.invalid || !form.dirty"
      (click)="editing ? save() : pageComplete()"
    >
      {{ editing ? 'Save' : 'Next' }}
    </button>
  </div>
</div>

<ng-template #waitingPeriodTooltipTemplate>
  <div class="max-w-[240px]">
    <p class="text-gray-700 text-xs font-semibold pb-1">Claim waiting period</p>
    <p class="text-gray-500 text-xs font-medium">
      This is the number of days a customer must wait before filing a claim on their newly purchased warranty.
    </p>
  </div>
</ng-template>
