<button
  class="w-full h-full max-w-xl"
  (click)="selectPolicy()"
  (mouseenter)="hovering = true"
  (mouseleave)="hovering = false"
  *ngIf="policy$ | async as policy"
>
  <vs-card [class]="selected ? 'border-2 border-primary' : ''" class="h-full w-full hover:bg-gray-50">
    <vs-card-content class="relative top-0 flex flex-col text-left w-full h-full gap-3">
      <div class="flex gap-3 w-full h-full">
        <vs-featured-icon
          [name]="$any(policy.icon) ?? 'file-shield-02'"
          size="60px"
          theme="primary"
          class="self-center my-auto"
          borderStyle="full"
        ></vs-featured-icon>
        <div class="w-full flex flex-col justify-center align-middle">
          <p
            class="font-semibold text-2xl max-h-16 overflow-hidden text-ellipsis"
            [vsTooltip]="policy.friendlyTitle ?? policy.title"
            tooltipPosition="top"
            tooltipStyle="light"
          >
            {{ policy.friendlyTitle ?? policy.title }}
          </p>
          <p class="text-gray-700 text-sm font-normal">{{ policy.tagline }}</p>
        </div>
        <div class="w-4">
          <vs-icon
            *ngIf="selected"
            theme="primary"
            class="text-primary-500 self-start ßrounded-full border-themed-200"
            type="solid"
            name="check-circle"
          ></vs-icon>
          <vs-icon
            *ngIf="!selected && hovering"
            theme="default"
            class="text-gray-300 self-start rounded-full"
            type="line"
            name="circle"
          ></vs-icon>
        </div>
      </div>
      <ng-container *ngIf="policy.description">
        <vs-divider></vs-divider>
        <p class="text-sm text-gray-700">{{ policy.description }}</p>
      </ng-container>
    </vs-card-content>
  </vs-card>
</button>
