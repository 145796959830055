import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { PropertyService } from '@vsolv/packages/properties/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { SecurityService } from '@wsphere/staff/web';
import { combineLatest, switchMap, tap } from 'rxjs';
import { DeletePlanDialog, PlanDialog } from '../../dialogs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-plan-details',
  templateUrl: './plan-details.component.html',
})
export class PlanDetailsComponent {
  constructor(
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private propertySvc: PropertyService
  ) {}

  @ViewChild('editPlanDialog') editPlanDialog?: PlanDialog;
  @ViewChild('deletePlanDialog') deletePlanDialog?: DeletePlanDialog;

  readonly policy$ = this.policySvc.getPolicyObservable();
  readonly plan$ = this.policySvc.getPlanObservable();

  saving = false;
  selectedPlanId = '';

  visibilityForm = this.formBuilder.group({
    visible: new FormControl({ value: false, disabled: false }),
  });

  canEditPlan$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const globalDistIds = globalDistributors ? globalDistributors?.map(dist => dist.id) : null;
      return await this.securitySvc.hasAccess('pol_EditVisibility', globalDistIds);
    })
  );

  canEditVisibility$ = combineLatest([this.plan$, this.securitySvc.globalDistributors$]).pipe(
    switchMap(async ([plan, globalDistributors]) => {
      const globalDistIds = globalDistributors ? globalDistributors?.map(dist => dist.id) : null;
      const hasAccess = await this.securitySvc.hasAccess('pol_EditVisibility', globalDistIds);

      this.visibilityForm.controls.visible.reset({
        disabled: !hasAccess,
        value: plan?.visible ?? false,
      });

      return hasAccess;
    })
  );

  canDeletePlan$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const globalDistIds = globalDistributors ? globalDistributors?.map(dist => dist.id) : null;
      return await this.securitySvc.hasAccess('pol_DeletePlan', globalDistIds);
    })
  );

  changeVisibility$ = combineLatest([this.plan$, this.visibilityForm.valueChanges]).pipe(
    switchMap(async ([plan, form]) => {
      if (!this.selectedPlanId.length || plan?.id !== this.selectedPlanId) {
        this.selectedPlanId = plan?.id || '';
        return;
      }

      const visible = form.visible;
      if (
        plan?.id !== undefined &&
        plan.policyId !== undefined &&
        visible !== null &&
        visible !== undefined &&
        plan.visible !== visible
      ) {
        this.visibilityForm.disable({ emitEvent: false });
        await this.policySvc.setPlanVisibility(plan.policyId, plan.id, visible).then(() => {
          this.toastSvc.show({
            type: 'success',
            title: 'Success',
            text: 'Successfully updated the visibility of the plan',
          });
        });

        await this.policySvc.refreshPolicy();
        await this.policySvc.refreshPlan();
        this.visibilityForm.enable({ emitEvent: false });
      }
    })
  );

  propertySet$ = this.policy$.pipe(
    switchMap(async policy => {
      if (!policy) return null;
      return await this.propertySvc.retrievePropertySet(policy.propertySetId);
    })
  );

  roundedCorner$ = combineLatest([this.policy$, this.plan$]).pipe(
    tap(([policy, plan]) => {
      const plans = policy?.plans?.sort((a, b) => (a.title > b.title ? -1 : a.order > b.order ? -1 : 1));
      return plans?.[0].id !== plan?.id;
    })
  );

  openEditPlanDialog() {
    this.editPlanDialog?.open();
  }

  openDeletePlanDialog() {
    this.deletePlanDialog?.open();
  }
}
