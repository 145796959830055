<vs-input-container
  #trigger="cdkOverlayOrigin"
  cdkOverlayOrigin
  class="h-auto min-h-[40px]"
  (click)="$event.preventDefault(); $event.stopImmediatePropagation(); toggle()"
>
  <div class="flex flex-wrap w-full gap-1 my-px">
    <p *ngIf="!value.length || rolesOutside" class="font-normal text-gray-400">{{ filter ? 'Select' : 'Add' }} roles</p>

    <ng-container *ngIf="!rolesOutside">
      <ws-role-badge
        *ngFor="let roleId of value$ | async"
        [roleId]="roleId"
        [removable]="!disabled"
        (removed)="removeRole($event)"
      ></ws-role-badge>
    </ng-container>
  </div>

  <vs-icon slot="end" name="help-circle" tooltipStyle="light" [vsTooltip]="{ template: tooltip }"></vs-icon>
</vs-input-container>

<div *ngIf="rolesOutside" class="flex flex-wrap gap-1 mt-2">
  <ws-role-badge
    *ngFor="let roleId of value$ | async"
    [roleId]="roleId"
    [removable]="!disabled"
    (removed)="removeRole($event)"
  ></ws-role-badge>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOpen]="overlayOpen"
  [cdkConnectedOverlayOrigin]="trigger"
  (overlayOutsideClick)="close()"
>
  <vs-card
    *vsLet="groupsAndRoles$ | async as groups"
    class="my-2 !rounded-md !shadow-md min-w-fit"
    [style.width]="elementRef.nativeElement.offsetWidth + 'px'"
  >
    <vs-input-container class="!border-x-0 border-t-0 !rounded-b-none">
      <vs-icon slot="start" name="search-md"></vs-icon>

      <input
        #input
        type="text"
        [disabled]="disabled"
        [placeholder]="filter ? 'Search roles' : placeholder"
        (keydown.enter)="input.value = ''"
        (input)="searchQuery$.next($any($event.target).value)"
      />
    </vs-input-container>

    <div *ngIf="!selectedGroup" class="px-2 py-3 max-h-60 overflow-auto scrollbar-thin">
      <div
        *ngIf="showAdmin"
        class="flex gap-2 px-2 py-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
        (click)="value.includes(fullAdmin) ? removeRole(fullAdmin) : addRole(fullAdmin)"
      >
        <div class="flex gap-2">
          <input vsInput type="checkbox" class="self-center" [checked]="value.includes(fullAdmin)" />

          <span class="text-sm font-semibold text-gray-900">Full Administrator</span>
        </div>
      </div>

      <div
        *ngFor="let group of groups"
        class="flex justify-between w-full px-2 py-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
        (click)="selectedGroup = group"
      >
        <div class="flex gap-2">
          <input
            #checkbox
            vsInput
            type="checkbox"
            class="self-center"
            [checked]="hasAllPermissions(group.roles)"
            (click)="$event.stopImmediatePropagation()"
            (change)="checkbox.checked ? selectAll(group.roles) : removeAll(group.roles)"
          />

          <span class="text-sm font-semibold text-gray-900">{{ group.group }}</span>
        </div>

        <vs-icon size="18" name="chevron-right" class="self-center"></vs-icon>
      </div>
    </div>

    <div *ngIf="selectedGroup" class="px-2 py-3 max-h-60 overflow-auto scrollbar-thin">
      <button
        class="flex gap-2 w-full mb-2 px-2 py-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
        (click)="selectedGroup = null"
      >
        <vs-icon size="18" name="chevron-left" class="self-center text-primary-600"></vs-icon>

        <span class="text-sm font-medium text-primary-600"> {{ selectedGroup.group }} </span>
      </button>

      <div
        *ngFor="let role of selectedGroup.roles"
        class="flex gap-2 px-2 py-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
        (click)="value.includes(role.id) ? removeRole(role.id) : addRole(role.id)"
      >
        <div class="flex gap-2">
          <input vsInput type="checkbox" class="self-center" [checked]="value.includes(role.id)" />

          <span class="text-sm font-semibold text-gray-900">{{ role?.display?.title }}</span>
        </div>
      </div>
    </div>
  </vs-card>
</ng-template>

<ng-template #tooltip>
  <div class="p-1">
    <p class="text-xs font-normal text-gray-700">
      Permissions are organized into three distinct roles, each catering to different responsibilities within the admin
      portal features:
    </p>

    <br />

    <ol start="1" class="text-xs font-semibold text-gray-700 pl-4" style="list-style-type: decimal">
      <li>
        Manager:
        <ul class="text-xs font-normal text-gray-700 pl-2" style="list-style-type: disc">
          <li class="pl-2">Full control and access to all features.</li>
          <li class="pl-2">Authority to create, manage, and remove information.</li>
          <li class="pl-2">Ideal for team leaders and administrators</li>
        </ul>
      </li>

      <li>
        Editor:
        <ul class="text-xs font-normal text-gray-700 pl-2" style="list-style-type: disc">
          <li class="pl-2">Ability to create and manage information.</li>
          <li class="pl-2">Restricted from certain administrative functions.</li>
          <li class="pl-2">Suitable for staff members responsible for content management</li>
        </ul>
      </li>

      <li>
        Viewer:
        <ul class="text-xs font-normal text-gray-700 pl-2" style="list-style-type: disc">
          <li class="pl-2">Read-only access to view information.</li>
          <li class="pl-2">Cannot manage or perform administrative tasks.</li>
          <li class="pl-2">Useful for those who need access for informational purposes only.</li>
        </ul>
      </li>
    </ol>

    <br />

    <p class="text-xs font-normal text-gray-700">
      *This role-based approach enhances security and efficiency by tailoring access to match specific functions.
    </p>
  </div>
</ng-template>
