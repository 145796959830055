<!-- Load the HTML of option elements but hide them so we can manually reference them -->
<div class="hidden">
  <ng-content></ng-content>
</div>

<ng-container *vsLet="value$ | async as value">
  <!-- Selected Content / Button -->
  <button
    #dropdownButton
    #trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    type="button"
    class="flex gap-2 items-center justify-between w-full h-full disabled:opacity-50 max-w-full focus:outline-none !pl-0"
    [disabled]="disabled"
    (keydown)="config?.multiple ? toggle() : undefined"
    (click)="$event.preventDefault(); $event.stopImmediatePropagation(); toggle()"
  >
    <div class="relative overflow-hidden rounded-lg">
      <div class="absolute top-0 left-0 h-full w-2 bg-gradient-to-r from-base to-transparent"></div>

      <div
        *ngIf="(value | isArray) ? $any(value)?.length : value; else placeholder"
        class="flex w-full items-center whitespace-nowrap overflow-auto no-scrollbar"
        [class.gap-1]="config?.hideSeparator"
      >
        <ng-container *ngIf="value | isArray; else singleValue">
          <ng-container *ngFor="let option of $any(value); let last = last">
            <span
              class="text-gray-900 flex first:ml-3 last:mr-2"
              [innerHTML]="options | optionHTML : option : compareWith | safe : 'html'"
            >
              that
            </span>
            <span class="whitespace-pre flex" *ngIf="!last && !config?.hideSeparator">
              {{ config?.separator ?? ', ' }}
            </span>
          </ng-container>
        </ng-container>

        <ng-template #singleValue>
          <span
            class="text-gray-900 flex first:ml-3 last:mr-2"
            [innerHTML]="options | optionHTML : $any(value) : compareWith | safe : 'html'"
          ></span>
        </ng-template>
      </div>

      <div class="absolute top-0 right-0 h-full w-2 bg-gradient-to-l from-base to-transparent"></div>
    </div>

    <ng-template #placeholder>
      <span class="truncate text-gray-400 flex ml-3 mr-2">
        {{ config?.placeholder || 'Select a value...' }}
      </span>
    </ng-template>

    <vs-icon class="text-gray transition-all" [class.rotate-180]="overlayOpen" name="chevron-down" size="20"></vs-icon>
  </button>

  <!-- Select Overlay -->
  <ng-template
    #overlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayOpen"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
    (attach)="open()"
    (detach)="close()"
    (overlayOutsideClick)="$event.stopImmediatePropagation(); close()"
  >
    <vs-card
      cdkTrapFocus
      cdkTrapFocusAutoCapture
      class="my-3 !rounded-md !shadow-md min-w-fit"
      [theme]="themeColor || 'primary'"
      [style.width]="elementRef.nativeElement.clientWidth + 'px'"
    >
      <div class="px-2 py-3 max-h-60 overflow-auto scrollbar-thin">
        <ul
          #optionsList
          vs-select-options-list
          [disabled]="disabled"
          [compareWith]="compareWith"
          [selectMultiple]="config?.multiple || false"
          [selectValue]="(value | isArray) ? $any(value) : value ? [value] : []"
          (selectValueChange)="selectValueChanged($any($event.value))"
        >
          <li
            *ngFor="let option of options"
            #option
            vs-select-option
            class="overflow-ellipsis"
            [value]="option.value"
            [disabled]="option.disabled"
            [typeaheadLabel]="option.elementRef.nativeElement.innerHTML"
            [innerHTML]="option.elementRef.nativeElement.innerHTML | safe : 'html'"
          ></li>
        </ul>
      </div>
    </vs-card>
  </ng-template>
</ng-container>
