<vs-dialog [config]="{ dialogContainerClass: 'max-w-[846px] w-full'}">
  <vs-dialog-header
    [config]="{
    mode: 'simple',
    hideCloseButton: false,
    
    icon: 'eye',
    iconTheme: 'primary',

    showDivider: true,

    title: 'Email Preview: '+ email?.type,
    subtitle: 'Preview what email will be sent out to customers'
  }"
  ></vs-dialog-header>

  <div class="flex flex-col p-4 pt-8 gap-4">
    <div class="border border-gray-300 rounded-lg py-3 px-6">{{email?.subject}}</div>
    <div class="border border-gray-300 max-h-[502px] h-full overflow-auto rounded-lg">
      <ws-email-preview [email]="email" [emailButton]="emailButton"></ws-email-preview>
    </div>
  </div>
</vs-dialog>
