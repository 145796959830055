import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { BreadcrumbsItemComponent } from './components';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';

@NgModule({
  imports: [CommonModule, IconsModule, ButtonModule, RouterModule, ThemingModule],
  declarations: [BreadcrumbsComponent, BreadcrumbsItemComponent],
  exports: [BreadcrumbsComponent, BreadcrumbsItemComponent],
})
export class BreadcrumbsModule {}
