/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Injector, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, map } from 'rxjs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-condition-rule-builder',
  templateUrl: './condition-rule-builder.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ConditionRuleBuilderComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionRuleBuilderComponent,
    },
  ],
})
export class ConditionRuleBuilderComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(
    private injector: Injector,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder
  ) {
    this.form.valueChanges.subscribe(value => this.onChange(value as any));
  }

  @Input() type = 'string';
  @Input() propertySet!: PropertySet.Model;
  @Input() coverage: Coverage.Model | null = null;

  @PropertyListener('plan') plan$ = new BehaviorSubject<Policy.Plan.Model | null>(null);
  @Input() plan?: Policy.Plan.Model | null;

  num = Property.PropertyType.NUMBER;

  touched = false;
  control?: NgControl;

  filteredAddons$ = this.plan$.pipe(map(plan => plan?.addons?.filter(addon => !addon.id.includes('cov_'))));

  form = this.formBuilder.group({
    blocks: this.formBuilder.array([]),
    defaultValue: [
      this.type === 'string' ? '' : this.type === 'number' ? 0 : ([] as string[]),
      [
        // Validators.required,
        this.type === 'string'
          ? Validators.minLength(1)
          : this.type === 'number' || this.type === 'object'
          ? Validators.min(0)
          : null,
      ],
    ],
    valuePropertyPath: [null as string | null],
  });

  onChange = (_value: Conditions.Rule<string | number | string[]>) => {};
  onTouched = () => {};

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(rule: Conditions.Rule<string | number | string[]> | null): void {
    const controls = this.form.get('blocks') as FormArray;
    for (let i = 0; i <= controls.length; i++) controls.removeAt(0);

    if (rule) {
      this.form.patchValue({
        defaultValue: rule.defaultValue as any,
        blocks: [],
        valuePropertyPath: rule.valuePropertyPath ?? null,
      });
      if (rule.blocks?.length) rule.blocks.forEach(block => this.addBlock(block));
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  validate(): ValidationErrors | null {
    if (
      (this.form.value.defaultValue === null || this.form.value.defaultValue === undefined) &&
      !this.form.value.valuePropertyPath
    ) {
      return {
        defaultValueOrProperty: [{ required: true }],
      };
    }

    let missingCondition = false;
    if ((this.form.value.blocks?.length || 0) > 0) {
      this.form.value.blocks?.forEach(block => {
        if (!(block as any)?.condition?.conditions?.length) missingCondition = true;
      });
    }
    if (missingCondition) return { condition: 'At least one condition is required' };

    return this.form.valid
      ? null
      : {
          blocks: this.form.get('blocks')?.errors,
          defaultValue: this.form.get('defaultValue')?.errors,
        };
  }

  get blocks() {
    return this.form.get('blocks') as FormArray;
  }

  addBlock(block?: Conditions.Block<string | number | string[]>) {
    const newBlock = block
      ? new FormControl({
          condition: block.condition,
          value: block.value,
          valuePropertyPath: block.valuePropertyPath ?? null,
        } as Conditions.Block<string | number | string[]>)
      : new FormControl({
          condition: {
            comparison: Conditions.Comparison.AND,
            conditions: [],
            valuePropertyPath: null,
          },
          value: this.type === 'string' ? '' : this.type === 'number' ? 0 : [],
        } as Conditions.Block<string | number | string[]>);

    this.blocks.push(newBlock);
  }

  removeBlock(index: number) {
    this.blocks.removeAt(index);
  }

  async addIndividualAddon(value: any) {
    if (!this.plan || !this.plan.addons || !this.coverage) return;

    if ((value as any)?.includes('cov_') && !this.plan.addons?.map(group => group.id).includes(this.coverage.id)) {
      this.plan.addons.push({
        id: this.coverage.id,
        title: this.coverage.title || '',
        description: this.coverage.description || '',
      });

      await this.policySvc
        .updatePlan(this.plan.id, this.plan.policyId, { canCreateNewVersion: false, addons: this.plan.addons })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
        });
    }

    if ((value as any)?.includes('cov_')) this.form.patchValue({ defaultValue: this.coverage.id as any });
  }

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.control.valueAccessor = this;
  }

  setValuePropertyPath(
    property: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null
  ) {
    this.form.patchValue({
      valuePropertyPath: property?.propertyPath ?? null,
      defaultValue: null,
    });
  }
}
