/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Warranty } from '@wsphere/warranties/domain';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'ws-warranty-status-picker',
  templateUrl: './warranty-status-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: WarrantyStatusPickerComponent,
    },
  ],
})
export class WarrantyStatusPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef) {}

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<Warranty.Status[] | null>();

  readonly searchQuery$ = new BehaviorSubject<string>('');

  @Input() value: Warranty.Status[] | null = null;
  @PropertyListener('value')
  value$ = new ReplaySubject<Warranty.Status>(1);

  @Input() placeholder = 'All statuses...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';

  touched = false;
  overlayOpen = false;

  protected _status: { value: Warranty.Status; theme: ThemeColor }[] = [
    { value: Warranty.Status.DRAFT, theme: 'default' },
    { value: Warranty.Status.ACTIVATED, theme: 'success' },
    { value: Warranty.Status.REGISTERED, theme: 'default' },
    { value: Warranty.Status.EXPIRED, theme: 'warn' },
    { value: Warranty.Status.CANCELLED, theme: 'warn' },
  ];

  onChange = (_value: Warranty.Status[] | null) => {};
  onTouched = () => {};

  selectValueChanged(value: any) {
    this.markAsTouched();

    if (typeof value === 'object') this.value = value;
    else if (value) this.value = [value];
    else this.value = null;

    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: Warranty.Status[] | null): void {
    this.value = value;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(onChange: (_value: Warranty.Status[] | null) => {}): void {
    this.onChange = onChange;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
