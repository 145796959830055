/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, map } from 'rxjs';
import { EditPlanConditionDialog } from '../../dialogs';
import { PolicyService } from '../../services';

export interface PlanCoverageTextBoxConfig {
  text?: string;
  darkBackground?: boolean;
}

@Component({
  selector: 'ws-plan-coverage-text-box',
  templateUrl: './plan-coverage-text-box.component.html',
})
export class PlanCoverageTextBoxComponent {
  constructor(private policySvc: PolicyService, private toastSvc: ToastService) {}

  @ViewChild('editConditionDialog') editConditionDialog?: EditPlanConditionDialog;

  @Input() config?: PlanCoverageTextBoxConfig | null = null;

  @Input() type = 'string';
  @Input() editing = false;
  @Input() disabled = false;
  @Input() propertySet!: PropertySet.Model;
  @Input() plan: Policy.Plan.Model | null = null;
  @Input() policy: Policy.Model | null = null;
  @Input() coverage?: Coverage.Model | null = null;
  @Input() addonGroups: Policy.Plan.Addon[] | null = null;
  @Input() rule: Conditions.Rule<string | number | string[]> | null = null;

  @Output() ruleUpdated = new EventEmitter<Conditions.Rule<string | number | string[]> | null>();

  hovered = false;
  overlayOpen = false;

  base = 'BASE';
  notApplicable = 'NOT_APPLICABLE';

  search$ = new BehaviorSubject<string>('');

  filteredGroups$ = this.search$.pipe(
    map(search =>
      this.addonGroups?.filter(
        group => group.title.toLowerCase().includes(search.toLowerCase()) && !group.id.includes('cov_')
      )
    )
  );

  getAddonName(id: any) {
    if (typeof id !== 'string') return;
    if (id.includes('cov_')) return 'Individual Add-on';
    else if (id === 'BASE') return 'Base';
    else if (id === 'NOT_APPLICABLE') return 'Not Applicable';

    const addon = this.plan?.addons?.find(addon => addon.id === id);
    return addon ? addon.title : null;
  }

  openEditConditionDialog() {
    this.editConditionDialog?.openDialog();
  }

  async selectAddon() {
    if (!this.plan || !this.plan.addons || !this.rule || !this.coverage) return;

    if (!this.addonGroups?.map(group => group.id).includes(this.coverage?.id)) {
      this.plan.addons.push({
        id: this.coverage.id,
        title: this.coverage.title || '',
        description: this.coverage.description || '',
      });

      await this.policySvc
        .updatePlan(this.plan.id, this.plan.policyId, { canCreateNewVersion: false, addons: this.plan.addons })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
        });

      this.rule.defaultValue = this.coverage.id;
    } else if (this.rule.defaultValue !== this.coverage.id) {
      this.rule.defaultValue = this.coverage.id;
    } else this.rule.defaultValue = '';
  }

  selectValue(value: string) {
    if (!this.rule) return;

    if (!this.rule.defaultValue) this.rule.defaultValue = value;
    else if (this.rule.defaultValue !== value) this.rule.defaultValue = value;
    else this.rule.defaultValue = '';
  }

  removeValue(index?: number) {
    if (!this.rule) return;

    if (index !== undefined) this.rule.blocks.splice(index, 1);
    else this.rule.defaultValue = '';

    this.updatedRule(this.rule);
  }

  updatedRule(rule: Conditions.Rule<string | number | string[]> | null) {
    this.ruleUpdated.emit(rule);
  }
}
