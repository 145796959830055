<form [formGroup]="form">
  <p class="font-semibold text-gray-900 mb-4">Billing info</p>

  <label vsCheckboxLabel for="user-info" class="flex items-center gap-2 mb-2">
    <input #user id="user-info" vsInput type="checkbox" (change)="user.checked ? fillUser() : removeUser()" checked />
    <span>Use my details</span>
  </label>

  <label vsCheckboxLabel for="home-address" class="flex items-center gap-2 mb-2">
    <input
      #shipping
      vsInput
      id="home-address"
      type="checkbox"
      (change)="shipping.checked ? fillShipping() : removeShippingAddress()"
    />
    <span>Use my home address</span>
  </label>

  <vs-billing-details-input
    class="mb-4"
    formControlName="billingDetails"
    [staffView]="staffView"
  ></vs-billing-details-input>

  <ng-container [ngSwitch]="type">
    <ng-container *ngSwitchCase="'credit-card'">
      <p class="font-semibold text-gray-900 mb-4">New credit card</p>
      <vs-credit-card-input class="mb-4" formControlName="creditCard"></vs-credit-card-input>

      <button
        vs-button
        theme="primary"
        class="min-w-full text-sm"
        [disabled]="form.invalid || processing"
        (click)="addCreditCard()"
      >
        Add new credit card
        <vs-icon class="animate-spin" [class.hidden]="!processing" name="refresh-cw-02"></vs-icon>
      </button>

      <div *ngIf="errors.length" class="ml-1 text-danger text-sm">
        <p *ngFor="let error of errors" class="mt-1">{{ error }}</p>
      </div>
    </ng-container>
  </ng-container>
</form>
