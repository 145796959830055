<div *ngIf="mode === 'vertical'" class="flex flex-col gap-[2px]">
  <div
    *ngFor="let step of steps; index as index"
    class="flex gap-4"
    [class.cursor-pointer]="step.allowJumpToStep || step.routerLink"
    (click)="onClick(step, index)"
  >
    <div class="flex flex-col gap-[2px]">
      <div
        class="flex justify-center min-w-[40px] max-w-[40px] h-10 min-h-[40px] max-h-[40px] rounded-full border-2 transition-all duration-300"
        [class]="step.allowJumpToStep ? 'hover:border-primary-200 cursor-pointer' : ''"
        [class.bg-primary]="index <= activeStep - 1"
        [class.bg-gray-100]="index > activeStep - 1"
        [class.border-primary-200]="index === activeStep - 1"
        [class.border-base-white]="index !== activeStep - 1"
      >
        <img *ngIf="index < activeStep - 1" src="assets/tick.svg" class="self-center w-4 h-4" />
        <vs-icon
          *ngIf="index >= activeStep - 1"
          size="12"
          type="solid"
          name="circle"
          class="self-center text-base-white"
        ></vs-icon>
      </div>

      <div *ngIf="index < steps.length - 1" class="h-full min-h-[56px] w-[2px] rounded-full self-center">
        <div
          class="w-full rounded bg-primary-500 transition-all duration-300"
          [style.height]="index < activeStep - 1 ? '100%' : '0px'"
        ></div>
        <div
          class="w-full rounded bg-gray-100 transition-all duration-300"
          [style.height]="index >= activeStep - 1 ? '100%' : '0px'"
        ></div>
      </div>
    </div>

    <div class="flex flex-col gap-1">
      <p
        class="font-semibold transition-all duration-300"
        [innerHTML]="step.title"
        [class]="index === activeStep - 1 ? 'text-themed-700' : 'text-gray-700'"
      ></p>
      <p
        class="transition-all duration-300"
        [innerHTML]="step.subtitle"
        [class]="index === activeStep - 1 ? 'text-themed-600' : 'text-gray-600'"
      ></p>
    </div>
  </div>
</div>

<div *ngIf="mode === 'horizontal'" class="flex gap-2 w-full">
  <div
    *ngFor="let step of steps; index as index"
    class="flex flex-col gap-2 flex-1"
    [class.cursor-pointer]="step.allowJumpToStep || step.routerLink"
    (click)="onClick(step, index)"
  >
    <div class="flex w-full h-[2px] rounded-full self-center">
      <div
        class="w-full h-[2px] rounded bg-themed-500 transition-all duration-300"
        [style.width]="index < activeStep ? '100%' : '0px'"
      ></div>

      <div
        class="w-full h-[2px] rounded bg-gray-100 transition-all duration-300"
        [style.width]="index >= activeStep ? '100%' : '0px'"
      ></div>
    </div>

    <div class="flex flex-col gap-1">
      <p
        class="text-sm font-semibold transition-all duration-300"
        [innerHTML]="step.title"
        [class]="index === activeStep - 1 ? 'text-themed-700' : 'text-gray-700'"
      ></p>

      <p
        class="text-xs transition-all duration-300"
        [innerHTML]="step.subtitle"
        [class]="index === activeStep - 1 ? 'text-themed-600' : 'text-gray-600'"
      ></p>
    </div>
  </div>
</div>

<div *ngIf="mode === 'horizontal_navigation'" class="flex justify-center">
  <div
    *ngFor="let step of steps; index as index; let first = first; let last = last"
    class="flex flex-col justify-center items-center"
    [class.cursor-pointer]="step.allowJumpToStep || step.routerLink"
    (click)="onClick(step, index)"
  >
    <ng-container *ngIf="!step.hideStep">
      <div class="flex items-center">
        <div class="w-full min-w-[40px] -ml-[1px] h-[2px] rounded-full self-center">
          <ng-container *ngIf="!first && !checkPreviousSteps(steps, index)">
            <div
              class="w-full rounded bg-themed-500 transition-all duration-300"
              [style.height]="index <= activeStep - 1 ? '100%' : '0px'"
            ></div>
            <div
              class="w-full rounded bg-gray-200 transition-all duration-300"
              [style.height]="index > activeStep - 1 ? '100%' : '0px'"
            ></div>
          </ng-container>
        </div>
        <div class="flex items-center justify-between h-10 -ml-1">
          <div
            class="flex justify-center rounded-full outline outline-2 transition-all duration-300 z-50"
            [class]="step.allowJumpToStep ? 'hover:outline-themed-500 cursor-pointer' : ''"
            [class.bg-themed-50]="index <= activeStep - 1"
            [class.outline-offset-0]="index < activeStep - 1"
            [class.outline-themed-200]="index <= activeStep - 1"
            [class.outline-gray-200]="index > activeStep - 1"
            [class]="
              index < activeStep - 1
                ? 'outline-offset-0 h-[34px] min-w-[34px] max-w-[34px] min-h-[34px] max-h-[34px]'
                : 'h-10 -outline-offset-[6px] min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px]'
            "
          >
            <vs-icon
              name="check"
              *ngIf="index < activeStep - 1"
              class="self-center text-primary-500"
              size="16"
            ></vs-icon>
            <vs-icon
              *ngIf="index >= activeStep - 1"
              size="12"
              type="solid"
              name="circle"
              class="self-center"
              [class]="index <= activeStep - 1 ? 'text-themed-500' : 'text-gray-200'"
            ></vs-icon>
          </div>
        </div>

        <div class="w-full min-w-[40px] h-[2px] rounded-full self-center -ml-1">
          <ng-container *ngIf="index < steps.length - 1">
            <div
              class="w-full rounded bg-themed-500 transition-all duration-300"
              [style.height]="index < activeStep - 1 ? '100%' : '0px'"
            ></div>
            <div
              class="w-full rounded bg-gray-200 transition-all duration-300"
              [style.height]="index >= activeStep - 1 ? '100%' : '0px'"
            ></div>
          </ng-container>
        </div>
      </div>

      <div class="flex items-center flex-col gap-1 max-w-[83px] min-h-[32px] justify-center">
        <p
          class="font-semibold transition-all duration-300 text-xs text-center"
          [innerHTML]="step.title"
          [class]="index === activeStep - 1 ? 'text-themed-700' : 'text-gray-700'"
        ></p>
      </div>
    </ng-container>
  </div>
</div>
