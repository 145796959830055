import { Component, EventEmitter, HostBinding, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService } from '@wsphere/staff/web';
import { map, switchMap, tap } from 'rxjs';
import { ProvisioningSessionService } from '../../services';

@Component({
  selector: 'ws-provision-distributor',
  templateUrl: './provision-distributor.component.html',
})
export class ProvisionDistributorComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private securitySvc: SecurityService,
    private sessionSvc: ProvisioningSessionService
  ) {}

  @HostBinding('class') _class = 'pb-24 px-6';

  @Output() completed = new EventEmitter();
  @Output() distributor = new EventEmitter<Distributor.Model | null>();

  distributorControl = new FormControl(null as Distributor.Model | null);

  globalDistributors$ = this.securitySvc.globalDistributors$;

  hasGlobalAccess$ = this.globalDistributors$.pipe(
    switchMap(() =>
      Promise.all([
        this.securitySvc.hasAccess('wrt_CreateWarranty', null),
        this.securitySvc.hasAccess('wrt_CreateDraftWarranty', null),
      ])
    ),
    map(([hasAccess, hasDraftAccess]) => hasAccess || hasDraftAccess)
  );

  protected session$ = this.sessionSvc.getCurrentSession().pipe(
    tap(session => {
      if (session?.distributor) {
        this.distributorControl.patchValue(session.distributor);
      }
    })
  );

  complete() {
    this.sessionSvc.distributor$.next(this.distributorControl.value);
    this.navigateTo('customer');
  }

  skip() {
    this.navigateTo('customer');
  }

  private navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }
}
