import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { AlertComponent } from './components';
import { AlertService, ToastService } from './services';

@NgModule({
  imports: [CommonModule, OverlayModule, IconsModule, ThemingModule, ButtonModule, UtilsModule],
  declarations: [AlertComponent],
  providers: [AlertService, ToastService],
})
export class AlertModule {}
