import { Staff } from '../lib/staff';

export type TransactionPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'txn_ViewTransaction',
    display: {
      title: 'View Transactions',
      description: 'Ability to view transactions details',
    },
  },
  {
    id: 'txn_CreateTransaction',
    display: {
      title: 'Create Transaction',
      description: 'Ability to create transactions',
    },
  },
  {
    id: 'txn_VoidTransaction',
    display: {
      title: 'Void Transactions',
      description: 'Ability to void transaction',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
