import { Component, OnInit } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Address } from '@vsolv/core/address/domain';
import { takeUntil } from 'rxjs';
import { StaticPropertyFormComponent } from '../static-property-form.component';

@Component({
  selector: 'vs-address-property-input',
  templateUrl: './address-property-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AddressPropertyInputComponent,
    },
    { provide: NG_VALIDATORS, useExisting: AddressPropertyInputComponent, multi: true },
  ],
})
export class AddressPropertyInputComponent extends StaticPropertyFormComponent implements OnInit {
  addressCheckbox = new FormControl(false);

  useAddress(checked: boolean, address: Address.Model) {
    if (!checked) return;
    this.formControl.setValue(address);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const customerAddress = this.extraData && this.extraData['address']?.line1 ? this.extraData['address'] : undefined;
    if (customerAddress) {
      this.formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
        this.addressCheckbox.setValue(JSON.stringify(customerAddress) === JSON.stringify(value));
      });
    }
  }
}
