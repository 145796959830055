import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageSecurityService {
  constructor(
    public readonly canUpload$: Observable<boolean>,
    public readonly canUpdate$: Observable<boolean>,
    public readonly canDelete$: Observable<boolean>,
    public readonly canView$: Observable<boolean>,
    public readonly canViewProcessors$: Observable<boolean>,
    public readonly canViewProcessorRuns$: Observable<boolean>,
    public readonly canCreateProcessorRun$: Observable<boolean>,
    public readonly canDeleteProcessorRun$: Observable<boolean>
  ) {}
}
