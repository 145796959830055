import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { DropdownModule } from '@vsolv/vectors-ui/dropdown';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import {
  SidebarNavComponent,
  SidebarNavItemComponent,
  SidebarNavUserComponent,
  TabNavComponent,
  TabNavItemComponent,
} from './components';

export * from './components';

@NgModule({
  declarations: [
    SidebarNavComponent,
    SidebarNavItemComponent,
    SidebarNavUserComponent,
    TabNavComponent,
    TabNavItemComponent,
  ],
  imports: [CommonModule, RouterModule, IconsModule, ThemingModule, DropdownModule, UserAvatarModule, BadgeModule],
  exports: [
    SidebarNavComponent,
    SidebarNavItemComponent,
    SidebarNavUserComponent,
    TabNavComponent,
    TabNavItemComponent,
  ],
})
export class AppNavModule {}
