<div #inputContainer class="min-w-[200px]">
  <vs-input-container>
    <vs-icon slot="start" name="search-md"></vs-icon>

    <input
      #input
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      class="min-h-[40px] max-h-[40px]"
      [value]="(value$ | async)?.name"
      [placeholder]="placeholder"
      (click)="toggle()"
      (input)="searchQuery$.next(input.value)"
    />
  </vs-input-container>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="overlayOpen"
  [cdkConnectedOverlayOffsetX]="-input.offsetLeft"
  (overlayOutsideClick)="$event.target !== input ? (overlayOpen = false) : null"
>
  <vs-menu *vsLet="customers$ | async as customers" [style.width.px]="inputContainer.offsetWidth" class="my-2">
    <ng-container *ngIf="customers?.length; else noCustomers">
      <button *ngFor="let customer of customers" vs-menu-item (click)="selectValueChanged(customer); input.value = ''">
        <div class="flex gap-2 justify-between">
          <div class="flex gap-4">
            <vs-user-avatar
              class="h-8 w-8 self-center"
              [config]="{ photoUrl: customer?.user?.photoURL ?? '' }"
            ></vs-user-avatar>

            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" [class.mt-1]="!customer.name">
                {{ customer.name }}
              </p>
              <p *ngIf="customer.name" class="text-xs font-normal text-gray-600">
                {{ customer.email }}
              </p>
            </div>
          </div>

          <div class="flex justify-end w-6">
            <vs-icon *ngIf="value?.id === customer.id" size="18" name="check" class="self-center"></vs-icon>
          </div>
        </div>
      </button>
    </ng-container>

    <ng-template #noCustomers>
      <p class="px-2 py-1 text-sm font-medium text-gray-900">No customers were found</p>
    </ng-template>
  </vs-menu></ng-template
>
