<ng-container *vsLet="$any(property).properties | sortProperties : showHidden as assignments">
  <vs-badge theme="default" *ngIf="!assignments?.length">{{ property.name }}</vs-badge>
  <vs-badge
    theme="default"
    *ngIf="assignments?.length"
    [vsTooltip]="{ template: tooltip }"
    tooltipStyle="light"
    tooltipPosition="top"
    >{{ property.name }}</vs-badge
  >
  <ng-template #tooltip>
    <ng-container>
      <div class="flex flex-col gap-1">
        <p class="text-sm font-semibold">{{ property.name }}</p>
        <p class="text-xs text-gray-500" *ngFor="let assignment of $any(assignments)">
          {{ assignment.property!.name }}
        </p>
      </div>
    </ng-container>
  </ng-template>
</ng-container>
