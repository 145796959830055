/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService, StaffService } from '@wsphere/staff/web';
import { Observable, combineLatest, from, shareReplay, switchMap } from 'rxjs';
import { DistributorTableComponent } from '../../components';
import { CreateDistributorDialog, CreateDistributorOnboardingLinkDialog } from '../../dialogs';

@Component({ templateUrl: './distributors.page.html' })
export class DistributorsPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private staffSvc: StaffService,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('table') table?: DistributorTableComponent;

  @ViewChild('editDistributorDialog') editDistributorDialog?: CreateDistributorDialog;
  @ViewChild('createDistributorDialog') createDistributorDialog!: CreateDistributorDialog;
  @ViewChild('createLinkDialog') createLinkDialog?: CreateDistributorOnboardingLinkDialog;

  staff$ = from(this.staffSvc.retrieveSelf()).pipe(shareReplay(1));

  readonly canCreateDistributor$: Observable<boolean> = combineLatest([
    this.securitySvc.globalDistributors$,
    this.staff$,
  ]).pipe(
    switchMap(async () => {
      return await this.securitySvc.hasAccess('dist_CreateDistributor', null);
    })
  );

  readonly canOnboardDistributor$: Observable<boolean> = combineLatest([
    this.securitySvc.globalDistributors$,
    this.staff$,
  ]).pipe(
    switchMap(async ([globalDistributor]) => {
      const permissionKeys = globalDistributor?.map(dist => dist.permissionKey) ?? null;
      return await this.securitySvc.hasAccess('dist_OnboardDistributor', permissionKeys);
    })
  );

  openCreateDistributorDialog() {
    this.createDistributorDialog.openDialog();
  }

  navigateTo(distributorId?: string) {
    if (distributorId) this.router.navigate([`${distributorId}`], { relativeTo: this.route });
    else this.router.navigate([`../`], { relativeTo: this.route });
  }
}
