import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ReceiveEmailConfigValue } from '@vsolv/packages/email/domain';
import { ReceiveEmailService } from '@vsolv/packages/email/web';
import { ProcessorService } from '@vsolv/packages/storage/web';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { SecurityService } from '@wsphere/staff/web';
import isEqual from 'lodash.isequal';
import { BehaviorSubject, from, shareReplay, Subject, switchMap, takeUntil, tap } from 'rxjs';

@Component({ selector: 'ws-email-document-dialog', templateUrl: './email-document.dialog.html' })
export class EmailDocumentDialog implements OnInit, OnDestroy {
  constructor(
    private emailSvc: ReceiveEmailService,
    private processorSvc: ProcessorService,
    private securitySvc: SecurityService
  ) {}

  allProcessors$ = from(this.processorSvc.list()).pipe(shareReplay(1));

  email$ = new BehaviorSubject<string | null>('');

  config = new ReceiveEmailConfigValue();

  canManageEmailIngestion$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('stf_ManageEmailIngestionApp', permissionKeys);
    }),
    tap(canManage => (!canManage ? this.form.disable() : this.form.enable()))
  );

  @Output() enabledChanged = new EventEmitter<boolean>();

  @ViewChild('deleteDialog') deleteDialog!: DialogComponent;
  @ViewChild('rerollDialog') rerollDialog!: DialogComponent;

  updating = false;

  form = new FormGroup({
    bodyDocument: new FormControl({ value: false, disabled: true }),
    autoRun: new FormControl({ value: false, disabled: false }),
    processors: new FormControl<string[]>({ value: [], disabled: false }),
  });

  private subject = new Subject<void>();

  async ngOnInit(): Promise<void> {
    this.form.controls.autoRun.valueChanges
      .pipe(takeUntil(this.subject))
      .subscribe(() => this.form.controls.processors.setValue([]));
    await this.reset();
  }

  ngOnDestroy(): void {
    this.subject.next();
    this.subject.complete();
  }

  async reset() {
    this.config = await this.emailSvc.getConfig();
    if (this.config.enabled) {
      const email = await this.emailSvc.retrieveAddress();
      if (email) this.email$.next(email.email);
      this.form.patchValue(this.config.options);
    }
    this.enabledChanged.emit(this.config.enabled);
  }

  disableUpdate() {
    if (this.form.controls.autoRun.value === true && this.form.controls.processors.value?.length === 0) return true;
    return isEqual(this.form.getRawValue(), this.config.options);
  }

  async createEmail() {
    this.updating = true;
    await this.emailSvc.createAddress().then(async email => {
      this.email$.next(email.email);
      this.updating = false;
      this.config.enabled = true;
      this.enabledChanged.emit(true);
      if (!this.disableUpdate()) await this.updateConfig();
    });
  }

  async rerollEmail() {
    this.updating = true;
    await this.emailSvc.refreshAddress().then(val => {
      this.email$.next(val.email);
      this.updating = false;
      this.rerollDialog.close();
    });
  }

  async deleteEmail() {
    this.updating = true;
    await this.emailSvc.deleteAddress().then(async () => {
      this.updating = false;
      this.config.enabled = false;
      this.config.options.autoRun = false;
      this.config.options.bodyDocument = false;
      this.form.reset({ bodyDocument: false, autoRun: false, processors: [] });
      this.deleteDialog.close();
      this.enabledChanged.emit(false);
    });
  }

  async updateConfig() {
    this.updating = true;
    this.config.options.autoRun = this.form.controls.autoRun.value ?? this.config.options.autoRun;
    this.config.options.bodyDocument = this.form.controls.bodyDocument.value ?? this.config.options.bodyDocument;
    this.config.options.processors = this.config.options.autoRun
      ? this.form.controls.processors.value ?? this.config.options.processors ?? []
      : [];
    await this.emailSvc.setConfig(this.config);
    this.updating = false;
  }
}
