import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { EvaluatedAddon } from '../plan-picker/plan-picker.component';

@Component({
  selector: 'ws-addon-picker',
  templateUrl: './addon-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AddonPickerComponent,
    },
  ],
})
export class AddonPickerComponent implements ControlValueAccessor {
  @PropertyListener('addons') addons$ = new BehaviorSubject<{ planId: string; addons: EvaluatedAddon[] } | null>(null);
  @Input() addons: { planId: string; addons: EvaluatedAddon[] } | null = null;

  @PropertyListener('selectedTerm') selectedTerm$ = new BehaviorSubject<Policy.PolicyTerm | null>(null);
  @Input() selectedTerm: Policy.PolicyTerm | null = null;

  @Input() selectedAddons: string[] | null = null;
  @PropertyListener('selectedAddons') protected selectedAddons$ = new BehaviorSubject<string[] | null>(
    this.selectedAddons
  );

  @Input() disabled = false;

  private touched = false;

  private _onChange?: (selectedAddons: string[]) => void;
  private _onTouched?: () => void;

  addonPrices$ = combineLatest([this.selectedTerm$, this.addons$]).pipe(
    map(([term, addons]) => {
      const hasOneTime = term?.paymentSchedules.some(schedule => schedule.installments === 1) ?? false;
      const installmentSchedules = term?.paymentSchedules.filter(schedule => schedule.installments > 1) ?? [];

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const addonPrices: Record<string, any> = {};

      if (!addons?.addons.length) return addonPrices;

      for (const addon of addons.addons) {
        const installments = [];
        for (const schedule of installmentSchedules) {
          const price = this.calculatePrice(addon.price, schedule.installments, schedule.rate);
          installments.push({ price, count: schedule.installments });
        }
        addonPrices[addon.addonId] = {
          price: hasOneTime ? addon.price : installments.length ? installments[0].price : addon.price,
          hasOneTime,
          installments,
          duration: term?.duration ?? 0,
        };
      }

      return addonPrices;
    })
  );

  calculatePrice(amount: number, installments: number, rate: number) {
    let rateIncrease = 0;
    if (rate !== 0) {
      rateIncrease = (amount * rate) / 100;
    }
    return Math.floor((amount + rateIncrease) / installments);
  }

  selectAddon(addonId: string) {
    if (this.selectedAddons?.includes(addonId)) return; //nothing to update
    this.markAsTouched();
    const addonIds: string[] =
      this.selectedAddons && this.selectedAddons.length ? [...this.selectedAddons, addonId] : [addonId];
    this.selectedAddons = addonIds;
    this._onChange?.(this.selectedAddons);
  }

  deselectAddon(addonId: string) {
    if (this.selectedAddons && this.selectedAddons.length && this.selectedAddons.includes(addonId)) {
      this.markAsTouched();
      this.selectedAddons = [...this.selectedAddons.filter(id => id !== addonId)];
      this._onChange?.(this.selectedAddons);
    }
  }

  writeValue(selectedAddons: string[]): void {
    this.selectedAddons = selectedAddons;
  }

  registerOnChange(onChange: (selectedAddons: string[]) => void): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this._onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this._onTouched?.();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isSelected(addonId: string) {
    const isSelected = JSON.stringify(this.selectedAddons).includes(addonId);
    return isSelected ? addonId : null;
  }
}
