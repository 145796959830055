<vs-app-page
  *vsLet="(table?.memberCount$ | async) as totalMembers"
  class="!p-0"
  [config]="{
    isSubPage: true,
    title: 'Team Members',
    description: 'Manage your team members and their account permissions here.'
  }"
>
  <vs-badge *ngIf="totalMembers" slot="title-end">
    {{ totalMembers }} {{ totalMembers !== 1 ? 'Members' : 'Member' }}
  </vs-badge>

  <button
    *ngIf="totalMembers && (canInvite$ | async)"
    vs-button
    size="md"
    slot="header-end"
    theme="primary"
    (click)="openAddDialog()"
  >
    <vs-icon name="plus"></vs-icon>
    Invite Member
  </button>

  <!-- Table -->
  <ws-team-members-table #table></ws-team-members-table>

  <ws-add-team-member-dialog #addTeamMemberDialog (staffInvited)="table.refresh()"></ws-add-team-member-dialog>
</vs-app-page>
