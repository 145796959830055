/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { getPropertyValidators } from '@vsolv/packages/properties/web';

@Component({
  selector: 'ws-condition-builder',
  templateUrl: './condition-builder.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ConditionBuilderComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionBuilderComponent,
    },
  ],
})
export class ConditionBuilderComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(private formBuilder: FormBuilder, private injector: Injector) {
    this.form.valueChanges.subscribe(value => this.onChange(value as any));
  }

  @Output() conditionRemoved = new EventEmitter<null>();

  @Input() propertySet!: PropertySet.Model;

  touched = false;
  control?: NgControl;

  properties: Conditions.Property[] = [];
  filteredProperties = this.properties;
  selectedProperty: Conditions.Property | Property.Model<Property.PropertyType> | null = null;

  bool = Conditions.PropertyType.BOOLEAN;
  text = Conditions.PropertyType.TEXT;
  num = Conditions.PropertyType.NUMBER;
  date = Conditions.PropertyType.DATE;

  isNull = Conditions.Operator.IS_NULL;
  isNotNull = Conditions.Operator.IS_NOT_NULL;

  generalOperators = [
    Conditions.Operator.EQUALS,
    Conditions.Operator.GREATER_THAN,
    Conditions.Operator.GREATER_THAN_EQUALS,
    Conditions.Operator.LESS_THAN,
    Conditions.Operator.LESS_THAN_EQUALS,
    Conditions.Operator.NOT_EQUALS,
    Conditions.Operator.IS_NULL,
    Conditions.Operator.IS_NOT_NULL,
  ];
  textOperators = [
    Conditions.Operator.EQUALS,
    Conditions.Operator.NOT_EQUALS,
    Conditions.Operator.CONTAINS,
    Conditions.Operator.STARTS_WITH,
    Conditions.Operator.NOT_STARTS_WITH,
    Conditions.Operator.IS_NULL,
    Conditions.Operator.IS_NOT_NULL,
  ];
  booleanOperators = [
    Conditions.Operator.EQUALS,
    Conditions.Operator.NOT_EQUALS,
    Conditions.Operator.IS_NULL,
    Conditions.Operator.IS_NOT_NULL,
  ];

  form = this.formBuilder.group({
    propertyId: ['', [Validators.required, Validators.minLength(1)]],
    operator: ['', [Validators.required]],
    value: ['' as Conditions.PropertyValueType],
    type: [null as Conditions.PropertyType | null, Validators.required],
    valuePropertyId: [null as string | null],
  });

  onChange = (_value: Conditions.Rule<string | number | string[]>) => {};
  onTouched = () => {};

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(condition: Conditions.Condition<Conditions.PropertyValueType> | null): void {
    if (condition) {
      this.form.patchValue({
        propertyId: condition.propertyId,
        operator: Object.values(Conditions.Operator).includes(condition.operator) ? condition.operator : null,
        value: condition.value,
        type: condition.type,
        valuePropertyId: condition.valuePropertyId ?? null,
      });
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  validate(): ValidationErrors | null {
    const operator = this.form.get('operator')?.value;

    let valueErrors = this.form.get('value')?.errors;
    if (
      valueErrors &&
      (operator === Conditions.Operator.CONTAINS ||
        operator === Conditions.Operator.STARTS_WITH ||
        operator === Conditions.Operator.NOT_STARTS_WITH)
    ) {
      const errors = Object.keys(valueErrors).filter(error => error !== 'phone' && error !== 'email');
      if (!errors.length) {
        valueErrors = null;
      }
    }

    const propertyErrors = this.form.get('propertyId')?.errors;
    const operatorErrors = this.form.get('operator')?.errors;

    return propertyErrors ||
      operatorErrors ||
      (valueErrors && operator !== this.isNotNull && operator !== this.isNull && !this.form.value.valuePropertyId)
      ? {
          propertyId: propertyErrors,
          operator: operatorErrors,
          value: valueErrors,
        }
      : null;
  }

  updateValue(value: boolean) {
    this.form.patchValue({ value: value });
  }

  removeCondition() {
    this.conditionRemoved.emit();
  }

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.control.valueAccessor = this;
  }

  setPropertyId(
    property: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null,
    clearValue: boolean
  ) {
    this.form.patchValue({
      propertyId: property?.propertyPath ?? null,
      ...(clearValue && { value: null, valuePropertyId: null, operator: Conditions.Operator.EQUALS }),
      type: property?.property.type ?? null,
    });
    if (property) {
      this.form
        .get('value')
        ?.setValidators(getPropertyValidators(property.property as Property.Model<Property.PropertyType>, false));
    }

    this.selectedProperty = property?.property ?? null;
  }

  setValuePropertyId(
    property: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null
  ) {
    this.form.patchValue({
      valuePropertyId: property?.propertyPath ?? null,
      value: null,
    });
  }
}
