import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property } from '@vsolv/packages/properties/domain';
import { BehaviorSubject } from 'rxjs';
import { PropertyWithSameType } from '../condition-value-property-picker.component';

@Component({
  selector: 'ws-condition-value-property-picker-item',
  templateUrl: './condition-value-property-picker-item.component.html',
})
export class ConditionValuePropertyPickerItemComponent {
  @PropertyListener('property') property$ = new BehaviorSubject<PropertyWithSameType | null>(null);
  @Input() propertyWithSameType!: PropertyWithSameType;

  @Input() propertyPath!: string;

  @Input() value: {
    property: Property.Model<Property.PropertyType> | Conditions.Property;
    propertyPath: string;
  } | null = null;

  overlayOpen = false;

  @Output() selectionChanged = new EventEmitter<{
    property: Property.Model<Property.PropertyType> | Conditions.Property;
    propertyPath: string;
  } | null>();

  setValue(
    value: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null
  ) {
    this.value = value;
    this.selectionChanged.emit(value);
    // menu.close();
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }
}
