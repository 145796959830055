<ng-container *vsLet="config$ | async as config">
  <vs-app-scaffold
    *vsLet="isMobile$ | async as isMobile"
    class="max-w-screen max-h-screen fixed left-0 right-0 top-0 bottom-0"
  >
    <vs-toolbar *ngIf="isMobile" slot="top">
      <button
        vs-icon-button
        slot="start"
        appearance="clear"
        class="cursor-pointer h-6"
        (click)="slideOut.open()"
        (mouseenter)="menuBtnHover = true"
        (mouseleave)="menuBtnHover = false"
      >
        <vs-icon *ngIf="isMobile" #icon size="24" [name]="menuBtnHover ? 'chevron-right-double' : 'menu-02'"></vs-icon>
      </button>

      <a
        *ngIf="config?.logo"
        routerLink=""
        routerLinkActive="cursor-default"
        class="block focus:outline-none focus:ring-4 ring-gray-100 focus:rounded group"
        [routerLinkActiveOptions]="{ exact: true }"
        (mousedown)="$event.preventDefault()"
      >
        <img
          class="w-fit max-h-10 max-w-full group-focus:px-2 group-focus:py-[2px]"
          [src]="config?.logo"
          [class.max-h-8]="isMobile$ | async"
        />
      </a>
    </vs-toolbar>

    <vs-slide-out #slideOut>
      <ng-container *ngTemplateOutlet="leftNav"></ng-container>
    </vs-slide-out>

    <ng-container *ngIf="!isMobile" slot="left">
      <ng-container *ngTemplateOutlet="leftNav"></ng-container>
    </ng-container>

    <ng-template #leftNav>
      <vs-sidebar-nav
        class="bg-base h-full"
        [class.border-r]="!isMobile"
        [class.border-gray-200]="!isMobile"
        [config]="{
          logo: (config$ | async)?.logo!,
          logoRouterLink: '',
          items: []
        }"
      >
        <div class="flex flex-col h-full">
          <div class="flex h-full justify-center">
            <vs-stepper
              class="self-center -mt-16"
              theme="primary"
              [config]="{ activeStep: -1, steps: steps }"
            ></vs-stepper>
          </div>

          <div>
            <vs-divider></vs-divider>
            <button class="mt-2" vs-button size="md" appearance="clear" slot="after-items" routerLink="">
              <vs-icon name="log-in-02"></vs-icon>
              Sign In
            </button>
          </div>
        </div>
      </vs-sidebar-nav>
    </ng-template>

    <div class="h-screen max-w-[600px] flex flex-col justify-center m-auto items-center">
      <div class="flex justify-center">
        <img class="w-48 h-48" src="assets/distributors/no-link.svg" />
      </div>
      <p class="text-center text-md text-primary-700 mt-8">400 error</p>
      <p class="text-center text-3xl text-primary-800 font-semibold mt-3">This registration link is no longer valid!</p>
      <p class="text-center text-sm text-gray-400 mt-6">
        Sorry, we can no longer register new distributors through this link. If you need a new registration link, please
        contact your representative.
      </p>
      <button class="mt-6" vs-button theme="primary">Contact representative</button>
    </div>
  </vs-app-scaffold>
</ng-container>
