import { Directive, HostBinding } from '@angular/core';
import { InputBoxDirective } from './input-box.directive';

@Directive({
  standalone: true,
  selector: '[vsInputGroupItem]',
  hostDirectives: [InputBoxDirective],
})
export class InputGroupItemDirective {
  @HostBinding('class') get styles() {
    const box = `h-10 flex items-center rounded-md px-3 bg-base`;
    const text = `text-md text-gray-600`;
    return `${box} ${text}`;
  }
}
