<vs-dialog *vsLet="isMobile$ | async as isMobile" [config]="{ dialogContainerClass: 'w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: isMobile ? 'default' : 'simple',
      hideCloseButton: false,
      
      icon: 'alert-triangle',
      iconTheme: 'warn',

      title: 'Deactivate ' + distributor?.name || 'Distributor',
      subtitle: 'Are you sure you want to deactivate this distributor? Deactivating a distributor will prevent team members and customers from provisioning new warranties using SFTP or checkout links to policies and plans. You will also no longer be able to onboard new sub-distributors or team members to this distributor. All sub-distributors will also be deactivated. <br><br> Members will still have access to the read-only SFTP credentials. <br><br>This distributor can be reactivated in the future.'
    }"
  ></vs-dialog-header>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
        Cancel
      </button>

      <button
        class="flex-grow"
        vs-button
        size="md"
        theme="danger"
        [disabled]="saving"
        (click)="deactivateDistributor()"
      >
        Deactivate
      </button>
    </div>
  </div>
</vs-dialog>
