<ng-container *vsLet="staffView$ | async as staffView">
  <ng-container *vsLet="isLargeScreen$ | async as isLargeScreen">
    <ng-container *vsLet="session$ | async as session">
      <ng-container *vsLet="assets$ | async as assets">
        <ng-container *ngIf="assets && assets.length > 0 && !createNew; else newAssets">
          <div class="relative flex flex-col items-center gap-4 mb-2">
            <div class="flex gap-2 w-full items-center">
              <div class="w-12 h-12">
                <button
                  vs-icon-button
                  appearance="clear"
                  theme="default"
                  size="lg"
                  *ngIf="!isLargeScreen"
                  (click)="previousPage(staffView ?? false)"
                >
                  <vs-icon name="chevron-left" size="20"></vs-icon>
                </button>
              </div>
              <div class="flex justify-center items-center flex-grow" name="icon">
                <vs-featured-icon
                  [name]="$any(session?.policy?.propertySet?.icon) || 'diamond-01'"
                  theme="primary"
                ></vs-featured-icon>
              </div>
              <div class="w-12 h-12">
                <button
                  vs-icon-button
                  appearance="clear"
                  theme="default"
                  size="lg"
                  [disabled]="!stepCompleted || !session?.customerId || !session?.policyId"
                  *ngIf="!isLargeScreen"
                  (click)="nextPage(session, staffView ?? false)"
                >
                  <vs-icon name="chevron-right" size="20"></vs-icon>
                </button>
              </div>
            </div>
            <div name="title" class="text-3xl font-semibold leading-[38px] text-center">
              {{
                'Tell us about your ' +
                  (staffView ? "customer's " : '') +
                  (session?.policy?.propertySet?.name | lowercase) +
                  '.'
              }}
            </div>

            <div name="subtitle" class="text-sm text-gray-600 mb-8" *ngIf="assetPicker.totalAssets > 0">
              Please select your {{ staffView ? "customer's " : '' }} existing
              {{ session?.policy?.propertySet?.name ?? 'asset' | lowercase }}.
            </div>
          </div>

          <div>
            <ws-asset-picker
              #assetPicker
              [session]="session"
              [formControl]="asset"
              [staffView]="staffView ?? false"
            ></ws-asset-picker>

            <div class="flex justify-evenly place-items-center" *ngIf="assetPicker.totalAssets > 0">
              <vs-divider></vs-divider>
              <pre> <span class="font-sans subpixel-antialiased text-gray-600 text-xs">Or</span> </pre>
              <vs-divider></vs-divider>
            </div>

            <button
              vs-button
              theme="primary"
              appearance="outline"
              class="bg-primary-50 text-primary-700 !w-full font-semibold mt-3"
              (click)="newAsset()"
            >
              <vs-icon name="plus"></vs-icon>
              Add new asset
            </button>
          </div>
          <div class="flex gap-3 mt-4" [class.flex-col]="!isLargeScreen">
            <button
              [class]="!isLargeScreen ? 'min-w-full' : ''"
              vs-button
              theme="primary"
              (click)="nextPage(session, staffView ?? false)"
              [disabled]="!stepCompleted || !session?.customerId || !session?.policyId"
            >
              <div class="flex gap-3 items-center">
                <span>Continue</span>
                <vs-icon *ngIf="stepCompleted" name="check"></vs-icon>
              </div>
            </button>

            <button
              [class]="!isLargeScreen ? 'min-w-full' : ''"
              vs-button
              (click)="previousPage(staffView ?? false)"
              appearance="outline"
            >
              Back
            </button>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #newAssets>
        <ws-provision-confirmation></ws-provision-confirmation>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
