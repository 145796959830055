import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { StorageItemProcessor } from '@vsolv/packages/storage/domain';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'any' })
export class ProcessorService {
  private http = inject(HttpClient);

  list(): Promise<StorageItemProcessor.Model[]> {
    return firstValueFrom(this.http.get<StorageItemProcessor.Model[]>('/api/storage-processors'));
  }

  execute(itemId: string, processorId: string): Promise<void> {
    return firstValueFrom(this.http.post<void>(`/api/storage-processors/${processorId}`, { itemId }));
  }

  deleteResult(resultId: string): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`/api/storage-processor-results/${resultId}`));
  }

  async loadOutput(result: StorageItemProcessor.Result, maxLength = 10240) {
    if (!result.outputDownloadUrl) return null;

    const url = new URL(result.outputDownloadUrl);
    if (maxLength > 0) url.searchParams.append('maxLength', '' + maxLength);

    const res = await fetch(url.toString());

    const truncatedFrom = parseInt(res.headers.get('X-VS-TRUNCATED-FROM') ?? '0');
    const truncatedTo = parseInt(res.headers.get('X-VS-TRUNCATED-TO') ?? '0');

    return {
      truncatedFrom: isNaN(truncatedFrom) ? 0 : truncatedFrom,
      truncatedTo: isNaN(truncatedTo) ? 0 : truncatedTo,
      data: await res.json(),
    };
  }
}
