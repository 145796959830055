import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { SecurityService } from '@wsphere/staff/web';
import { Warranty } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import { WarrantyWebService } from '../../../warranty/services';

@Component({
  selector: 'ws-warranty-page',
  templateUrl: './warranty-page.component.html',
})
export class WarrantyPageComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private securitySvc: SecurityService,
    private warrantySvc: WarrantyWebService
  ) {}

  draft = Warranty.Status.DRAFT;
  activated = Warranty.Status.ACTIVATED;
  cancelled = Warranty.Status.CANCELLED;
  expired = Warranty.Status.EXPIRED;
  registered = Warranty.Status.REGISTERED;

  updatedWarranty$ = new BehaviorSubject<Warranty.Model | null>(null);

  warranty$ = combineLatest([this.route.data, this.updatedWarranty$]).pipe(
    map(([data, warranty]) => warranty ?? (data['warranty'] as Warranty.Model))
  );

  canFinalize$ = this.warranty$.pipe(
    switchMap(warranty =>
      this.securitySvc.hasAccess(
        'wrt_CreateWarranty',
        warranty.distributor ? [warranty.distributor.permissionKey] : null
      )
    )
  );

  canCancelWarranty$ = this.warranty$.pipe(
    switchMap(
      async warranty =>
        await this.securitySvc.hasAccess(
          'wrt_CancelWarranty',
          warranty?.distributor?.permissionKey ? [warranty.distributor.permissionKey] : null
        )
    )
  );

  overlayOpen = false;
  canUpdate$ = this.warranty$.pipe(
    switchMap(warranty => {
      return this.securitySvc.hasAccess(
        'wrt_UpdateWarranty',
        warranty.distributor?.permissionKey ? [warranty.distributor.permissionKey] : null
      );
    })
  );

  async updateTermStart(warranty: Warranty.Model, date: Date) {
    const updated = await this.warrantySvc.update(warranty.id, { termStart: date }).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (updated?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Warranty term start updated',
        text: `Successfully updated warranty term start date to ${date.toDateString()}`,
      });

      const updatedWarranty = await this.warrantySvc.getOneById(updated.id).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

      if (updatedWarranty) {
        this.updatedWarranty$.next(updatedWarranty);
      }
    }
  }

  updateCancelled(warranty: Warranty.Model) {
    const updatedWarranty = warranty;
    if (warranty) updatedWarranty.status = Warranty.Status.CANCELLED;
    this.updatedWarranty$.next(updatedWarranty);
  }

  async register(warranty: Warranty.Model) {
    const response = await this.warrantySvc.finalize(warranty.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response) {
      this.toastSvc.show({
        type: 'success',
        title: 'Registered Warranty',
        text:
          '<strong>' +
          (warranty?.policy?.title ?? warranty.contractNumber) +
          '</strong> has been successfully registered.',
      });

      const updatedWarranty = await this.warrantySvc.getOneById(warranty.id);
      if (updatedWarranty) this.updatedWarranty$.next(updatedWarranty);
    }
  }

  async delete(warranty: Warranty.Model) {
    const response = await this.warrantySvc.delete(warranty.id).catch(({ error }) => {
      this.toastSvc.show({ type: 'error', title: 'Something went wrong', text: error.message });
    });

    if (response) {
      this.toastSvc.show({
        type: 'success',
        title: 'Warranty Deleted',
        text:
          '<strong>' +
          (warranty?.policy?.title ?? warranty.contractNumber) +
          '</strong> has been successfully deleted.',
      });

      await this.router.navigate(['..'], { relativeTo: this.route });
    }
  }
}
