<ng-container *ngIf="policy$ | async as policy">
  <ng-container *ngIf="coverage$ | async as coverage">
    <vs-app-page
      *vsLet="canEdit$ | async as canEdit"
      [config]="{ title: coverage?.title || 'Coverage', description: 'Last modified: ' + (coverage?.modified | date)}"
    >
      <vs-breadcrumbs
        slot="breadcrumbs"
        [config]="{ items: [{ label: 'Policies', routerLink: '../../..' }, { label: policy?.title || 'Policy', routerLink: '../..'}, { label: coverage?.title || 'Coverage' }] }"
      ></vs-breadcrumbs>

      <vs-badge slot="title-end">{{ coverage?.group }}</vs-badge>

      <button *ngIf="canEdit" vs-button slot="header-end" appearance="outline" (click)="deleteCoverageDialog.open()">
        <vs-icon name="trash-01" slot="start" class="text-themed-500"></vs-icon>
        Delete Coverage
      </button>

      <div *ngIf="coverage" class="flex gap-4 -mt-4">
        <vs-card>
          <vs-card-header [config]="{ title: 'Overview', subtitle: 'Manage and view the details of this coverage.' }">
            <button *ngIf="canEdit" vs-icon-button slot="end" appearance="clear" (click)="editCoverageDialog?.open()">
              <vs-icon name="edit-01"></vs-icon>
            </button>
          </vs-card-header>

          <vs-card-content class="flex flex-col gap-4">
            <div class="flex">
              <span class="text-sm font-medium basis-1/3 max-w-[200px]">Title</span>
              <span class="text-sm"> {{ coverage.title }} </span>
            </div>

            <vs-divider></vs-divider>

            <div class="flex">
              <span class="text-sm font-medium basis-1/3 max-w-[200px]">Description</span>
              <span class="text-sm"> {{ coverage.description }} </span>
            </div>

            <vs-divider></vs-divider>

            <div class="flex">
              <span class="text-sm font-medium basis-1/3 max-w-[200px]">Group</span>
              <span class="text-sm"> {{ coverage.group }} </span>
            </div>

            <vs-divider></vs-divider>

            <div class="flex">
              <span class="text-sm font-medium basis-1/3 max-w-[200px]">Inclusions</span>
              <textarea [vsAutosize]="true" readonly class="text-sm"> {{ coverage.inclusions }} </textarea>
            </div>

            <vs-divider></vs-divider>

            <div class="flex">
              <span class="text-sm font-medium basis-1/3 max-w-[200px]">Exclusions</span>
              <textarea [vsAutosize]="true" readonly class="text-sm"> {{ coverage.exclusions }} </textarea>
            </div>

            <vs-divider></vs-divider>

            <div class="flex">
              <span class="text-sm font-medium basis-1/3 max-w-[200px]">Order</span>
              <textarea [vsAutosize]="true" readonly class="text-sm"> {{ coverage.order }} </textarea>
            </div>

            <vs-divider></vs-divider>

            <div class="flex">
              <span class="text-sm font-medium basis-1/3 max-w-[200px]">Reference ID</span>
              <textarea [vsAutosize]="true" readonly class="text-sm"> {{ coverage.referenceId }} </textarea>
            </div>
          </vs-card-content>
        </vs-card>

        <vs-card *ngIf="canViewClaims$ | async" class="overflow-hidden">
          <!-- Performance -->
          <!-- <span class="title">Performance</span>
          <p class="subtitle -mt-4">
            Better understand this coverage's activity through success metrics in the chart below
          </p>

          <div class="h-[150px] mb-4"></div> -->

          <!-- Recent Claims -->
          <vs-card-header
            *vsLet="claims$ | async as claims"
            [config]="{ title: 'Recent Claims', subtitle: 'Preview the most recently modified claims under this coverage' }"
          >
            <vs-badge slot="after-title" [vsTooltip]="{ template: statsInfo }" [tooltipPosition]="'end'">
              {{ claimsTableData?.meta?.totalItems }} {{ claimsTableData?.meta?.totalItems === 1 ? 'Claim' : 'Claims' }}

              <vs-progress-bar
                *ngIf="coverageStats"
                [config]="
                {
                  items: [
                    { weight: coverageStats[0].openClaims, theme: 'info' },
                    { weight: coverageStats[0].closedClaims, theme: 'default' },
                    { weight: coverageStats[0].cancelledClaims, theme: 'warn' },
                    { weight: coverageStats[0].expiredClaims, theme: 'danger' },
                  ]
                }
              "
              ></vs-progress-bar>
            </vs-badge>

            <button
              *ngIf="(canCreateClaims$ | async) && policy?.status === published &&( claimsTableData?.meta?.totalItems ?? 0 < 1)"
              vs-button
              slot="end"
              size="md"
              theme="primary"
              (click)="claimDialog?.openDialog()"
            >
              <vs-icon name="plus"></vs-icon>
              New claim
            </button>
          </vs-card-header>

          <vs-card-content *vsLet="claims$ | async as claims">
            <vs-table *ngIf="claims?.length" class="-mt-1 -mx-6" [data]="claims" [columns]="columns"></vs-table>

            <div *ngIf="!claimsTableData || claimsTableData.items.length <= 0" class="flex justify-between gap-2">
              <div class="flex flex-col">
                <span class="text-primary-700">No Claims</span>
                <span class="text-sm text-themed-600">There are no claims filed under this coverage</span>
              </div>

              <button
                vs-button
                *ngIf="(canCreateClaims$ | async) && policy?.status === published"
                size="md"
                theme="primary"
                (click)="claimDialog?.openDialog()"
              >
                <vs-icon name="plus"></vs-icon>
                New claim
              </button>
            </div>

            <div
              *ngIf="claimsTableData && (claimsTableData.items.length > 0)"
              class="flex justify-end gap-2 pt-3 items-center"
            >
              <button
                vs-button
                class="border-themed-300 text-themed-700"
                size="md"
                appearance="outline"
                (click)="navigateTo('../../../../claims')"
              >
                <vs-icon name="link-external-01" class="text-themed-500" slot="start"></vs-icon>
                View all claims
              </button>
            </div>
          </vs-card-content>
        </vs-card>
      </div>
    </vs-app-page>

    <ws-delete-coverage-dialog
      #deleteCoverageDialog
      [policy]="policy"
      [coverage]="coverage"
    ></ws-delete-coverage-dialog>

    <ws-policy-dialog
      *ngIf="policy && coverage"
      #editCoverageDialog
      [policy]="policy"
      [editing]="editType"
      [coverage]="coverage"
    ></ws-policy-dialog>

    <ws-open-claim-dialog
      #claimDialog
      (claimId)="createClaimItem($event, coverage!.id)"
      (closed)="refreshClaims(coverage!.id)"
    ></ws-open-claim-dialog>

    <ng-template #statsInfo>
      <ng-container *ngIf="coverageStats">
        <span>
          <vs-icon name="circle" type="solid" class="text-success"></vs-icon> {{ coverageStats[0].openClaims }} open
          claims
        </span>
        <br />
        <span>
          <vs-icon name="circle" type="solid" class="text-info"></vs-icon> {{ coverageStats[0].closedClaims }} closed
          claims
        </span>
        <br />
        <br />
        <span>
          <vs-icon name="circle" type="solid" class="text-themed"></vs-icon> {{ coverageStats[0].cancelledClaims }}
          cancelled claims
        </span>
        <br />
        <span>
          <vs-icon name="circle" type="solid" class="text-warn"></vs-icon> {{ coverageStats[0].expiredClaims }} expired
          claims
        </span>
        <br />
        <!-- Leave these commented stats here as we might need to show either of them in future -->
        <!-- <span>
          <vs-icon name="circle" type="solid" class="text-danger"></vs-icon> {{ coverageStats[0].rejectedClaims }}
          rejected claims
        </span> -->
      </ng-container>

      <p *ngIf="!coverageStats">This coverage currently has no claims!</p>
    </ng-template>
  </ng-container>
</ng-container>
