import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { TooltipComponent } from './components';
import { TooltipDirective } from './directives';

@NgModule({
  imports: [CommonModule, DividerModule],
  declarations: [TooltipDirective, TooltipComponent],
  exports: [TooltipDirective],
})
export class TooltipModule {}
