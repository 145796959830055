<ng-container *vsLet="config$ | async as config">
  <div class="flex justify-center">
    <vs-icon class="text-primary-500" name="check-circle-broken" size="91" theme="primary"></vs-icon>
  </div>
  <div class="text-3xl text-primary-800 text-center font-semibold mt-9">You're all set!</div>
  <p class="max-w-[603px] mt-6 px-5 text-center">
    While we create your distributor on the {{ config?.brandName }} Administrator Dashboard, please complete your
    registration with the email we’ve sent you to enter the dashboard.
  </p>
  <vs-divider class="mt-6 mb-9"></vs-divider>
  <p class="text-center text-sm max-w-[603px] px-5">
    Encounter an issue during this process? Let us know and we'll get back to you as soon as possible!
    <a class="underline text-primary-700" theme="primary" href="mailto:{{ contactEmail }}"> Contact us </a>
  </p>
</ng-container>
