import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-delete-policy-dialog',
  templateUrl: './delete-policy.dialog.html',
})
export class DeletePolicyDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy?: Policy.Model | null;
  @Input() dialogConfig?: DialogConfig;

  saving = false;

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  cancel() {
    this.close();
  }

  async deletePolicy() {
    if (!this.policy) throw new Error('Unable to delete policy, policy not found');

    if (this.policy.status !== Policy.Status.DRAFT) {
      throw new Error(`Unable to delete policy with status: ${this.policy.status}`);
    }

    this.saving = true;

    const deletedPolicy = await this.policySvc.delete(this.policy.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
      this.saving = false;
    });

    if (deletedPolicy?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Policy Removed',
        text: '<strong>' + this.policy?.title + '</strong> has been successfully deleted.',
      });

      this.close();
      this.saving = false;
      this.policySvc.clearPolicy();
      this.router.navigate(['../'], { relativeTo: this.route });
    }
  }
}
