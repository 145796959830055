import { Staff } from './lib/staff';
import { RoleProps } from './lib/staff/roles/role';
import { AccountPermissionId, Permissions as AccountPermissions } from './registrations/accounts';
import { AssetPermissionId, Permissions as AssetPermissions } from './registrations/assets';
import { ClaimPermissionId, Permissions as ClaimPermissions } from './registrations/claims';
import { CustomerPermissionId, Permissions as CustomerPermissions } from './registrations/customers';
import { DashboardPermissionId, Permissions as DashboardPermissions } from './registrations/dashboards';
import { DistributorPermissionId, Permissions as DistributorPermissions } from './registrations/distributors';
import { DocumentPermissionId, Permissions as DocumentPermissions } from './registrations/documents';
import { NotePermissionId, Permissions as NotePermissions } from './registrations/notes';
import { PolicyPermissionId, Permissions as PolicyPermissions } from './registrations/policies';
import { PropertyPermissionId, Permissions as PropertyPermissions } from './registrations/properties';
import { ReportingPermissionId, Permissions as ReportingPermissions } from './registrations/reports';
import { SalePermissionId, Permissions as SalePermissions } from './registrations/sales';
import { StaffPermissionId, Permissions as StaffPermissions } from './registrations/staff';
import { StoragePermissionId, Permissions as StoragePermissions } from './registrations/storage';
import { TransactionPermissionId, Permissions as TransactionPermissions } from './registrations/transactions';
import { WarrantyPermissionId, Permissions as WarrantyPermissions } from './registrations/warranties';
import { WebhookPermissionId, Permissions as WebhookPermissions } from './registrations/webhooks';

export type PermissionId =
  | '*'
  | CustomerPermissionId
  | DashboardPermissionId
  | PolicyPermissionId
  | ClaimPermissionId
  | SalePermissionId
  | DistributorPermissionId
  | DocumentPermissionId
  | StaffPermissionId
  | WarrantyPermissionId
  | NotePermissionId
  | AssetPermissionId
  | PropertyPermissionId
  | WebhookPermissionId
  | StoragePermissionId
  | AccountPermissionId
  | TransactionPermissionId
  | ReportingPermissionId;

const _Permissions = [
  ...CustomerPermissions,
  ...DashboardPermissions,
  ...PolicyPermissions,
  ...ClaimPermissions,
  ...SalePermissions,
  ...DistributorPermissions,
  ...DocumentPermissions,
  ...StaffPermissions,
  ...WarrantyPermissions,
  ...NotePermissions,
  ...AssetPermissions,
  ...PropertyPermissions,
  ...WebhookPermissions,
  ...StoragePermissions,
  ...AccountPermissions,
  ...TransactionPermissions,
  ...ReportingPermissions,
];

new Staff.Security.Permission({
  id: '*',
  hidePermission: true,
  display: {
    title: 'All Permissions',
    description: 'Ability to perform every action on the platform',
  },
});

const Roles: RoleProps[] = [
  {
    id: '*',
    hideRole: false,
    group: 'Platform Management',
    permissions: ['*'],
    display: { title: 'Full Administrator', description: 'Assigns all permissions' },
  },
  {
    id: 'cus_Viewer',
    group: 'Customers',
    permissions: ['cus_ViewDetails', 'cus_ViewNote'],
    display: { title: 'Customer Viewer', theme: 'default' },
  },
  {
    id: 'cus_Editor',
    group: 'Customers',
    permissions: [
      'cus_ViewDetails',
      'cus_EditDetails',
      'cus_ViewNote',
      'cus_ManageNote',
      'cus_RemoveNote',
      'cus_GetCustomerSignInLink',
    ],
    display: { title: 'Customer Editor', theme: 'info' },
  },
  {
    id: 'cus_Manager',
    group: 'Customers',
    permissions: [
      'cus_ViewDetails',
      'cus_EditDetails',
      'cus_Create',
      'cus_Delete',
      'cus_ViewNote',
      'cus_ManageNote',
      'cus_RemoveNote',
    ],
    display: { title: 'Customer Manager', theme: 'primary' },
  },
  {
    id: 'stf_Viewer',
    group: 'Settings Management',
    permissions: ['stf_ViewSettings', 'stf_ViewStaffRoles', 'stf_ViewStaff', 'stf_ViewAPIKeys', 'stf_ViewApps'],
    display: { title: 'Settings Viewer' },
  },
  {
    id: 'stf_Editor',
    group: 'Settings Management',
    permissions: [
      'stf_ManageAPIKeys',
      'stf_EditSettings',
      'stf_ViewStaff',
      'stf_ViewSettings',
      'stf_ViewStaffRoles',
      'stf_ViewAPIKeys',
      'stf_ViewApps',
    ],
    display: { title: 'Settings Editor', theme: 'info' },
  },
  {
    id: 'stf_Manager',
    group: 'Settings Management',
    permissions: [
      'stf_ManageAPIKeys',
      'stf_EditSettings',
      'stf_ManageStaff',
      'stf_GrantRoles',
      'stf_EditRoles',
      'stf_ViewStaff',
      'stf_ViewSettings',
      'stf_ViewStaffRoles',
      'stf_EditStaffDetails',
      'stf_ViewAPIKeys',
      'stf_ManageAuthNetApp',
      'stf_ViewApps',
      'stf_ManageEmailIngestionApp',
      'sft_ManageSFTPApp',
      'stf_ManageCustomBotIntegrationApp',
      'stf_ManageChatbotIntegrationApp',
      'stf_TaxJarCustomKeyApp',
    ],
    display: { title: 'Settings Manager', theme: 'primary' },
  },
  {
    id: 'dsh_Viewer',
    group: 'Dashboard Management',
    permissions: ['dsh_ViewDashboard', 'dsh_FavouriteDashboard'],
    display: { title: 'Dashboard Viewer' },
  },
  {
    id: 'dsh_Editor',
    group: 'Dashboard Management',
    permissions: ['dsh_ViewDashboard', 'dsh_FavouriteDashboard', 'dsh_CreateDashboard', 'dsh_EditDetails'],
    display: { title: 'Dashboard Editor', theme: 'info' },
  },
  {
    id: 'pol_Viewer',
    group: 'Policy Management',
    permissions: ['pol_View', 'pol_CreatePolicyLink', 'pol_CreatePlanLink', 'pol_ViewLifecycle'],
    display: { title: 'Policy Viewer' },
  },
  {
    id: 'pol_Editor',
    group: 'Policy Management',
    permissions: [
      'pol_View',
      'pol_CreatePolicyLink',
      'pol_CreatePlanLink',
      'pol_Edit',
      'pol_EditVisibility',
      'pol_EditPlan',
      'pol_ManageStatus',
      'pol_ViewLifecycle',
    ],
    display: { title: 'Policy Editor', theme: 'info' },
  },
  {
    id: 'pol_Manager',
    group: 'Policy Management',
    permissions: [
      'pol_View',
      'pol_CreatePolicyLink',
      'pol_CreatePlanLink',
      'pol_Edit',
      'pol_EditVisibility',
      'pol_EditPlan',
      'pol_ManageStatus',
      'pol_Create',
      'pol_CreatePlan',
      'pol_DeletePlan',
      'pol_ViewLifecycle',
      'pol_EditLifecycle',
    ],
    display: { title: 'Policy Manager', theme: 'primary' },
  },
  {
    id: 'pol_FeeSetup',
    group: 'Policy Management',
    permissions: [
      'pol_View',
      'pol_CreatePolicyLink',
      'pol_CreatePlanLink',
      'pol_Edit',
      'pol_EditVisibility',
      'pol_EditPlan',
      'pol_ManageStatus',
      'pol_Create',
      'pol_CreatePlan',
      'pol_DeletePlan',
      'pol_ViewLifecycle',
      'pol_EditLifecycle',
      'pol_FeeSetup',
    ],
    display: { title: 'Policy Fee Manager', theme: 'default' },
  },
  {
    id: 'clm_Viewer',
    group: 'Claim Management',
    permissions: ['clm_ViewClaim', 'clm_ViewNote', 'clm_ViewPayments', 'clm_ViewAttachments', 'clm_ViewLifecycle'],
    display: { title: 'Claim Viewer' },
  },
  {
    id: 'clm_Opener',
    group: 'Claim Management',
    permissions: [
      'clm_ViewClaim',
      'clm_ViewNote',
      'clm_ViewPayments',
      'clm_ViewAttachments',
      'clm_ViewLifecycle',
      'clm_OpenClaim',
      'clm_ManageAttachments',
    ],
    display: { title: 'Claim Opener' },
  },
  {
    id: 'clm_Editor',
    group: 'Claim Management',
    permissions: [
      'clm_OpenClaim',
      'clm_ViewClaim',
      'clm_ViewLifecycle',
      'clm_EditClaim',
      'clm_SetCustomerLifecycle',
      'clm_ViewNote',
      'clm_ManageNote',
      'clm_RemoveNote',
      'clm_ManageAttachments',
      'clm_ViewAttachments',
      'clm_RemoveAttachments',
      'clm_ViewPayments',
      'clm_ManagePayments',
      'clm_ManageClaimItemCoverage',
      'clm_ManageClaimItemCure',
      'clm_ManageClaimItemResolution',
      'clm_ManagePaymentMethod',
      'clm_ManageLink',
    ],
    display: { title: 'Claim Editor', theme: 'info' },
  },
  {
    id: 'clm_Manager',
    group: 'Claim Management',
    permissions: [
      'clm_OpenClaim',
      'clm_CancelClaim',
      'clm_ViewClaim',
      'clm_ViewLifecycle',
      'clm_EditClaim',
      'clm_SetCustomerLifecycle',
      'clm_ViewNote',
      'clm_ManageNote',
      'clm_RemoveNote',
      'clm_ManageAttachments',
      'clm_ViewAttachments',
      'clm_RemoveAttachments',
      'clm_ViewPayments',
      'clm_ManagePayments',
      'clm_IssuePayment',
      'clm_FinishPayment',
      'clm_RemoveClaimItem',
      'clm_ManageClaimItemCoverage',
      'clm_ManageClaimItemCure',
      'clm_ManageClaimItemResolution',
      'clm_AdjudicateClaimItem',
      'clm_ResolveClaimItem',
      'clm_CloseClaim',
      'clm_ExpireClaim',
      'clm_ManageLink',
      'clm_ManagePaymentMethod',
    ],
    display: { title: 'Claim Manager', theme: 'primary' },
  },
  {
    id: 'py_Viewer',
    group: 'Sales Management',
    permissions: ['py_ViewSales', 'wrt_ViewWarranty'],
    display: { title: 'Sales Viewer' },
  },
  {
    id: 'py_Editor',
    group: 'Sales Management',
    permissions: ['py_ViewSales', 'py_ViewPaymentMethods', 'py_UpdateBilling', 'wrt_ViewWarranty'],
    display: { title: 'Sales Editor', theme: 'info' },
  },
  {
    id: 'py_Manager',
    group: 'Sales Management',
    permissions: [
      'py_ViewSales',
      'py_UpdateBilling',
      'py_RetryPayment',
      'py_ViewPaymentMethods',
      'py_AddPayments',
      'wrt_ViewWarranty',
      'wrt_CreateWarranty',
      'wrt_UpdateWarranty',
    ],
    display: { title: 'Sales Manager', theme: 'primary' },
  },
  {
    id: 'dist_Viewer',
    group: 'Distributor Management',
    permissions: ['dist_ViewDistributors', 'dist_ViewDistributor', 'dist_ViewStaff'],
    display: { title: 'Distributor Viewer' },
  },
  {
    id: 'dist_Editor',
    group: 'Distributor Management',
    permissions: [
      'dist_ViewDistributors',
      'dist_ViewDistributor',
      'dist_EditDetails',
      'dist_ResetSFTP',
      'dist_RemoveStaff',
      'dist_ViewStaff',
      'dist_EditStaffDetails',
    ],
    display: { title: 'Distributor Editor', theme: 'info' },
  },
  {
    id: 'dist_Manager',
    group: 'Distributor Management',
    permissions: [
      'dist_ViewDistributors',
      'dist_ViewDistributor',
      'dist_CreateDistributor',
      'dist_EditDetails',
      'dist_ResetSFTP',
      'dist_RemoveStaff',
      'dist_OnboardDistributor',
      'dist_CreateOnboardingLink',
      'dist_BreakOnboardingLink',
      'dist_ViewOnboardingLinks',
      'dist_EditStatus',
      'dist_InviteCoworkers',
      'dist_InviteStaff',
      'dist_ViewStaff',
      'dist_EditStaffDetails',
    ],
    display: { title: 'Distributor Manager', theme: 'primary' },
  },
  {
    id: 'wrt_Manager',
    group: 'Warranty Management',
    permissions: [
      'wrt_CancelWarranty',
      'wrt_ViewWarranty',
      'wrt_CreateWarranty',
      'wrt_UpdateWarranty',
      'py_AddPayments',
    ],
    display: { title: 'Warranty Manager', theme: 'primary' },
  },
  {
    id: 'wrt_Editor',
    group: 'Warranty Management',
    permissions: [
      'wrt_ViewWarranty',
      'wrt_CreateWarranty',
      'wrt_CreateDraftWarranty',
      'wrt_UpdateWarranty',
      'py_AddPayments',
    ],
    display: { title: 'Warranty Editor', theme: 'info' },
  },
  {
    id: 'wrt_Drafter',
    group: 'Warranty Management',
    permissions: ['wrt_ViewWarranty', 'wrt_CreateDraftWarranty', 'wrt_UpdateWarranty'],
    display: { title: 'Warranty Drafter', theme: 'info' },
  },
  {
    id: 'wrt_Viewer',
    group: 'Warranty Management',
    permissions: ['wrt_ViewWarranty'],
    display: { title: 'Warranty Viewer', theme: 'default' },
  },
  {
    id: 'doc_Manager',
    group: 'Document Management',
    permissions: ['doc_ViewDocuments', 'doc_UpdateDocuments', 'doc_RemoveDocuments', 'doc_UploadDocuments'],
    display: { title: 'Document Manager', theme: 'primary' },
  },
  {
    id: 'doc_Editor',
    group: 'Document Management',
    permissions: ['doc_ViewDocuments', 'doc_UpdateDocuments'],
    display: { title: 'Document Editor', theme: 'info' },
  },
  {
    id: 'doc_Viewer',
    group: 'Document Management',
    permissions: ['doc_ViewDocuments'],
    display: { title: 'Document Viewer', theme: 'default' },
  },
  {
    id: 'note_Manager',
    group: 'Note Management',
    permissions: ['note_ViewNotes', 'note_UpdateNotes', 'note_RemoveNotes', 'note_CreateNotes'],
    display: { title: 'Note Manager', theme: 'primary' },
  },
  {
    id: 'note_Editor',
    group: 'Note Management',
    permissions: ['note_ViewNotes', 'note_UpdateNotes'],
    display: { title: 'Note Editor', theme: 'info' },
  },
  {
    id: 'note_Viewer',
    group: 'Note Management',
    permissions: ['note_ViewNotes'],
    display: { title: 'Note Viewer', theme: 'default' },
  },
  {
    id: 'ast_Manager',
    group: 'Asset Management',
    permissions: ['ast_ViewAsset', 'ast_ManageAsset', 'ast_CreateAsset', 'ast_DeleteAsset'],
    display: { title: 'Asset Manager', theme: 'primary' },
  },
  {
    id: 'ast_Editor',
    group: 'Asset Management',
    permissions: ['ast_ViewAsset', 'ast_ManageAsset'],
    display: { title: 'Asset Editor', theme: 'info' },
  },
  {
    id: 'ast_Viewer',
    group: 'Asset Management',
    permissions: ['ast_ViewAsset'],
    display: { title: 'Asset Viewer', theme: 'default' },
  },
  {
    id: 'prp_Manager',
    group: 'Property Management',
    permissions: ['prp_CreateSet', 'prp_ManageSet', 'prp_CreateProperty', 'prp_ManageProperty', 'prp_DeleteProperty'],
    display: { title: 'Property Set Manager', theme: 'primary' },
  },
  {
    id: 'prp_Editor',
    group: 'Property Management',
    permissions: ['prp_ManageSet', 'prp_ManageProperty'],
    display: { title: 'Property Set Editor', theme: 'info' },
  },
  {
    id: 'prp_Viewer',
    group: 'Property Management',
    permissions: [],
    display: { title: 'Property Set Viewer', theme: 'default' },
  },
  {
    id: 'web_Viewer',
    group: 'Webhook Management',
    permissions: ['web_View'],
    display: { title: 'Webhook Viewer' },
  },
  {
    id: 'web_Editor',
    group: 'Webhook Management',
    permissions: ['web_View', 'web_Edit'],
    display: { title: 'Webhook Editor', theme: 'info' },
  },
  {
    id: 'web_Manager',
    group: 'Webhook Management',
    permissions: ['web_View', 'web_Edit', 'web_Create', 'web_Delete'],
    display: { title: 'Webhook Manager', theme: 'primary' },
  },
  {
    id: 'acct_Manager',
    group: 'Account Management',
    permissions: ['acct_ViewAccount', 'acct_ManageAccount', 'acct_CreateAccount'],
    display: { title: 'Account Manager', theme: 'primary' },
  },
  {
    id: 'acct_Editor',
    group: 'Account Management',
    permissions: ['acct_ViewAccount', 'acct_ManageAccount'],
    display: { title: 'Account Manager', theme: 'primary' },
  },
  {
    id: 'acct_Viewer',
    group: 'Account Management',
    permissions: ['acct_ViewAccount'],
    display: { title: 'Account Viewer', theme: 'primary' },
  },
  {
    id: 'txn_Manager',
    group: 'Transaction Management',
    permissions: ['txn_ViewTransaction', 'txn_VoidTransaction', 'txn_CreateTransaction'],
    display: { title: 'Transaction Manager', theme: 'primary' },
  },
  {
    id: 'txn_Editor',
    group: 'Transaction Management',
    permissions: ['txn_ViewTransaction', 'txn_VoidTransaction'],
    display: { title: 'Transaction Editor', theme: 'primary' },
  },
  {
    id: 'txn_Viewer',
    group: 'Transaction Management',
    permissions: ['txn_ViewTransaction'],
    display: { title: 'Transaction Viewer', theme: 'primary' },
  },
  {
    id: 'str_Manager',
    group: 'Storage',
    permissions: [
      'str_View',
      'str_ViewProcessors',
      'str_ViewProcessorRuns',
      'str_Update',
      'str_Upload',
      'str_Delete',
      'str_CreateProcessorRun',
      'str_DeleteProcessorRun',
    ],
    display: { title: 'Storage Manager', theme: 'primary' },
  },
  {
    id: 'str_Editor',
    group: 'Storage',
    permissions: ['str_View', 'str_ViewProcessors', 'str_ViewProcessorRuns', 'str_Update'],
    display: { title: 'Storage Editor', theme: 'info' },
  },
  {
    id: 'str_Viewer',
    group: 'Storage',
    permissions: ['str_View', 'str_ViewProcessors', 'str_ViewProcessorRuns'],
    display: { title: 'Storage Viewer', theme: 'default' },
  },
  {
    id: 'rep_Viewer',
    group: 'Report Management',
    permissions: ['rep_ViewReports'],
    display: { title: 'Report Viewer', theme: 'primary' },
  },
  {
    id: 'rep_Manager',
    group: 'Report Management',
    permissions: ['rep_ViewReports', 'rep_GenerateReports'],
    display: { title: 'Report Manager', theme: 'primary' },
  },
];
Roles.forEach(r => new Staff.Security.Role(r));
