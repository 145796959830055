<ng-container *ngIf="payment">
  <div class="relative" [class]="static ? 'h-[calc(100vh-4rem)]' : ''">
    <div class="flex flex-col p-4 gap-4">
      <div *ngIf="static" class="flex flex-col gap-4">
        <div class="pt-6 pb-12"><img [src]="logo" width="201" /></div>
        <div class="h-12 font-semibold text-gray-700">Transaction: {{ payment.id }}</div>
      </div>

      <div class="flex justify-between flex-wrap gap-3">
        <div class="text-2xl text-gray-700">
          {{ payment.amount / 100 | currency }} {{ payment.paymentDate ? '| Paid on' : '| Due on' }}
          {{
            payment.paymentDate || payment.dueDate
              ? (payment.paymentDate || payment.dueDate | date : 'MMM d, yyyy')
              : '-'
          }}
        </div>

        <a
          *ngIf="!static && payment.receiptUrl"
          download
          vs-button
          size="sm"
          target="_blank"
          appearance="outline"
          class="min-w-[32px]"
          [href]="payment.receiptUrl"
        >
          <vs-icon slot="start" name="download-01"></vs-icon>
          Download receipt
        </a>
      </div>
      <vs-divider class="mb-4"></vs-divider>
      <div class="flex flex-wrap justify-between gap-8">
        <div class="flex flex-col gap-2 flex-1">
          <div class="text-xs font-semibold text-gray-900">Bill from</div>
          <div class="flex flex-col">
            <p class="text-sm font-semibold text-gray-900">
              {{ brandName }}
            </p>
            <p class="text-xs font-normal text-gray-700">
              <a class="hover:text-gray-500" href="mailto:{{ contactEmail }}">
                {{ contactEmail }}
              </a>
              <a *ngIf="contactPhone" class="hover:text-gray-500" href="tel:{{ contactPhone }}">
                | {{ contactPhone }}
              </a>
            </p>
            <p *ngIf="contactAddress as address" class="text-xs font-normal text-gray-700 whitespace-normal">
              {{ address.line1 }}<ng-container *ngIf="address.line2">, {{ address.line2 }} </ng-container><br />
              {{ address.city }} {{ address.state }} {{ address.zip }}<br />
              {{ address.country }}
            </p>
          </div>
        </div>
        <div class="flex flex-wrap gap-8 flex-1">
          <div class="flex flex-col gap-2 flex-1">
            <div class="text-xs font-semibold text-gray-900">Bill to</div>
            <div class="flex flex-col">
              <p class="text-sm font-semibold text-gray-900">
                {{ payment.user.displayName ? payment.user.displayName : payment.user.email }}
              </p>
              <a class="text-xs font-normal text-gray-700 hover:text-gray-500" href="mailto:{{ payment.user.email }}">
                {{ payment.user.email }}
              </a>
              <ng-container *vsLet="payment.paymentMethod?.billingAddress as address">
                <p class="text-xs font-normal text-gray-700 whitespace-normal" *ngIf="address">
                  {{ address.line1 }}<ng-container *ngIf="address.line2">, {{ address.line2 }} </ng-container><br />
                  {{ address.city }} {{ address.state }} {{ address.zip }}<br />
                  {{ address.country }}
                </p>
              </ng-container>
            </div>
          </div>
          <div class="flex flex-col gap-2 flex-1">
            <div class="text-xs font-semibold text-gray-900">Ship to</div>
            <div class="flex flex-col">
              <p class="text-sm font-semibold text-gray-900" *ngIf="payment.shipToName">
                {{ payment.shipToName }}
              </p>
              <a
                class="text-xs font-normal text-gray-700 hover:text-gray-500"
                href="mailto:{{ payment.shipToEmail }}"
                *ngIf="payment.shipToEmail"
              >
                {{ payment.shipToEmail }}
              </a>
              <ng-container *vsLet="payment.shippingAddress as address">
                <p class="text-xs font-normal text-gray-700 whitespace-normal" *ngIf="address">
                  {{ address.line1 }}<ng-container *ngIf="address.line2">, {{ address.line2 }} </ng-container><br />
                  {{ address.city }} {{ address.state }} {{ address.zip }}<br />
                  {{ address.country }}
                </p>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-2 mb-4">
        <div class="flex justify-between gap-8">
          <div class="flex flex-col gap-2 basis-1/2">
            <vs-divider></vs-divider>
            <div class="flex justify-between gap-2">
              <span class="text-gray-700 whitespace-nowrap text-sm font-medium">Created on</span>
              <span class="text-gray-900 whitespace-nowrap text-sm font-normal text-center">
                {{ payment.created | date : 'MMM d, yyyy' }}</span
              >
            </div>
            <vs-divider></vs-divider>
            <div class="flex justify-between gap-2">
              <span class="text-gray-700 whitespace-nowrap text-sm font-medium">{{
                payment.paymentDate ? 'Paid on' : 'Due on'
              }}</span>
              <span class="text-gray-900 whitespace-nowrap text-sm font-normal text-center">
                {{
                  payment.paymentDate || payment.dueDate
                    ? (payment.paymentDate || payment.dueDate | date : 'MMM d, yyyy')
                    : '-'
                }}
              </span>
            </div>
            <vs-divider></vs-divider>
          </div>
          <div class="flex flex-col gap-2 basis-1/2">
            <ng-container *ngFor="let group of payment.propertyBag; let firstGroup = first">
              <ng-container *ngFor="let property of group; let firstProp = first">
                <div class="flex gap-2 flex-col">
                  <vs-divider *ngIf="firstGroup && firstProp"></vs-divider>
                  <div class="flex gap-4 justify-between">
                    <div class="text-gray-700 whitespace-nowrap text-sm font-medium">{{ property.label }}</div>
                    <div class="text-gray-900 whitespace-nowrap text-sm font-normal text-right">
                      {{ property.value }}
                    </div>
                  </div>
                  <vs-divider></vs-divider>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="flex flex-wrap-reverse gap-4 items-center justify-between">
        <div class="flex-col">
          <div class="font-semibold text-gray-900">{{ payment.title }}</div>
          <div class="text-gray-500 text-sm font-medium">{{ payment.referenceId }}</div>
        </div>

        <div *ngIf="!static" class="flex flex-wrap items-center gap-2">
          <!-- Resend Reminder Email -->
          <button
            *ngIf="payment.status === 'FAILED' && !hideResendReminderButton && (canRetryBillingInfo$ | async)"
            vs-button
            size="sm"
            class="flex-1"
            appearance="outline"
            (click)="resendPaymentReminderDialog.open()"
          >
            Resend payment reminder
          </button>

          <!-- Retry Payment -->
          <button
            *ngIf="payment.status === 'FAILED' && (canRetryBillingInfo$ | async)"
            vs-button
            size="sm"
            class="flex-1"
            theme="primary"
            (click)="retryPaymentDialog.open()"
          >
            Retry payment
          </button>

          <!-- Update Billing Info -->
          <button
            *ngIf="payment.status === 'PENDING' && (canUpdateBillingInfo$ | async)"
            vs-button
            size="sm"
            class="flex-1"
            appearance="outline"
            (click)="updateBillingInfoDialog.open()"
          >
            Update Billing Info
          </button>
        </div>
      </div>

      <div class="grid grid-cols-[1fr_auto_auto] gap-x-20 gap-y-2 text-sm">
        <vs-divider class="col-span-3"></vs-divider>
        <ng-container *ngFor="let breakdown of payment.priceBreakdown; let last = last">
          <div class="col-span-2 truncate">
            <span class="font-semibold"> {{ breakdown.title }}{{ breakdown.subtitle ? ' - ' : '' }} </span>
            {{ breakdown.subtitle }}
          </div>
          <div class="text-right">{{ breakdown.amount / 100 | currency }}</div>
          <vs-divider class="col-span-3"></vs-divider>
        </ng-container>

        <div class="col-start-2 font-semibold text-gray-700 truncate">Subtotal</div>
        <div class="text-gray-900 text-right font-semibold">{{ payment.subtotal / 100 | currency }}</div>
        <vs-divider class="col-start-2 col-span-2"></vs-divider>

        <ng-container *ngFor="let tax of payment.taxes; let last = last">
          <div class="col-start-2 font-medium text-gray-700 truncate">{{ tax.label }} {{ tax.rate * 100 }}%</div>
          <div class="text-gray-900 text-right">{{ tax.amount / 100 | currency }}</div>
          <vs-divider class="col-span-3"></vs-divider>
        </ng-container>

        <div class="col-start-2 font-semibold text-gray-700 truncate">Total</div>
        <div class="text-gray-900 text-right font-semibold">{{ payment.amount / 100 | currency }}</div>

        <vs-divider class="col-start-2 col-span-2"></vs-divider>

        <ng-container
          *ngIf="
            (static && payment.paymentMethod) || (!static && payment.paymentMethod && (canViewPaymentMethods$ | async))
          "
        >
          <div class="col-start-2 font-semibold text-gray-700 truncate flex items-center">Payment Method</div>
          <div class="text-gray-900 text-right font-semibold">
            <span class="text-gray-700 whitespace-nowrap text-sm font-medium"></span>
            <span class="text-gray-900 whitespace-nowrap text-sm font-normal text-center">
              <vs-payment-method-item [paymentMethod]="payment.paymentMethod" [minify]="true"></vs-payment-method-item>
            </span>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="static" class="absolute bottom-0 flex items-center gap-2">
      <p class="text-sm font-semibold text-gray-900">
        {{ brandName }}
      </p>
      -
      <a class="text-xs font-normal text-gray-600 hover:text-gray-500" href="mailto:{{ contactEmail }}">
        {{ contactEmail }}
      </a>
    </div>
  </div>

  <vs-update-billing-info-dialog
    #updateBillingInfoDialog
    [payment]="payment"
    (saved)="paymentMethodChanged($event.paymentMethodId)"
  ></vs-update-billing-info-dialog>

  <vs-retry-payment-dialog
    #retryPaymentDialog
    [payment]="payment"
    (saved)="paymentRetried($event)"
  ></vs-retry-payment-dialog>

  <vs-dialog #resendPaymentReminderDialog (closed)="$event && resendPaymentReminder()">
    <vs-dialog-header
      class="block mb-6"
      [config]="{
        mode: 'simple',
        showDivider: false,
        hideCloseButton: true,

        iconTheme: 'primary',
        icon: 'alert-circle',

        title: 'Resend payment reminder',
        subtitle: 'Send a reminder to complete payment to ' + payment.user.displayName + ' at ' + payment.user.email
      }"
    ></vs-dialog-header>

    <div class="flex items-center gap-2">
      <button vs-button class="flex-1" appearance="outline" (click)="resendPaymentReminderDialog.close(false)">
        Cancel
      </button>
      <button vs-button class="flex-1" theme="primary" (click)="resendPaymentReminderDialog.close(true)">
        Confirm
      </button>
    </div>
  </vs-dialog>
</ng-container>
