<div class="flex flex-col sm:flex-row gap-4 my-4">
  <!-- Overview -->
  <vs-card class="flex-1 sm:basis-1">
    <vs-card-header
      [config]="{ 
        title: 'Distributor Details', 
        subtitle: 'Manage and view the details of this distributor.' 
      }"
    >
      <button
        *ngIf="distributor?.status === active && (canUpdateDistributor$ | async)"
        vs-icon-button
        slot="end"
        rounded="true"
        appearance="clear"
        class="-mr-2"
        (click)="openEditDialog()"
      >
        <vs-icon name="edit-01" size="18"></vs-icon>
      </button>
    </vs-card-header>

    <vs-card-content class="grid gap-4 text-sm" style="grid-template-columns: auto 1fr">
      <div class="font-medium">Name</div>
      <div class="truncate" [vsTooltip]="distributor?.name" tooltipStyle="light" tooltipOpenDelay="300">
        {{distributor?.name}}
      </div>

      <vs-divider class="col-span-2"></vs-divider>

      <div class="font-medium">Email</div>
      <div class="truncate" [vsTooltip]="distributor?.email || undefined" tooltipStyle="light" tooltipOpenDelay="300">
        {{distributor?.email ?? ''}}
      </div>

      <vs-divider class="col-span-2"></vs-divider>

      <div class="font-medium">Phone</div>
      <div class="truncate" [vsTooltip]="distributor?.phone || undefined" tooltipStyle="light" tooltipOpenDelay="300">
        {{distributor?.phone ?? ''}}
      </div>

      <vs-divider class="col-span-2"></vs-divider>

      <div class="font-medium">Reference ID</div>
      <div
        class="truncate"
        [vsTooltip]="distributor?.referenceId || undefined"
        tooltipStyle="light"
        tooltipOpenDelay="300"
      >
        {{distributor?.referenceId ?? ''}}
      </div>
    </vs-card-content>
  </vs-card>

  <ng-container *ngIf="content | async as comp">
    <ng-container *ngComponentOutlet="comp; injector: (contentInputs$ | async) ?? undefined"></ng-container>
  </ng-container>
</div>

<vs-card *ngIf="distributor">
  <ng-template #newSubDist let-theme="theme">
    <button
      *ngIf="(canCreateDistributor$ | async) && distributor?.status === active"
      vs-button
      [theme]="theme"
      [appearance]="theme ? 'default' : 'outline'"
      (click)="openCreateSubDialog()"
    >
      <vs-icon name="package-plus"></vs-icon>
      New Distributor
    </button>
  </ng-template>

  <vs-card-header
    *vsLet="(table.paginationConfig$ | async)?.totalItems as totalDistributors"
    [config]="{
      title: 'Sub-Distributors',
      subtitle: 'Manage and view all sub-distributors registered under this distributors in the table below.'
    }"
  >
    <vs-badge slot="after-title">
      {{ totalDistributors }} {{ totalDistributors === 1 ? 'distributor' : 'distributors'}}
    </vs-badge>

    <div slot="end" class="flex max-w-min gap-4">
      <ng-container
        *ngIf="(totalDistributors || 0) > 0"
        slot="header-end"
        [ngTemplateOutlet]="newSubDist"
      ></ng-container>

      <!-- <button vs-icon-button appearance="outline" size="md" class="px-3 py-2">
        <vs-icon name="download-01" (click)="exportCoveragesDialog?.openDialog()"></vs-icon>
      </button> -->
    </div>
  </vs-card-header>

  <vs-card-content>
    <ws-distributor-table #table [distributor]="distributor" [onSettings]="onSettings">
      <ng-container
        slot="button"
        [ngTemplateOutlet]="newSubDist"
        [ngTemplateOutletContext]="{ theme: 'primary' }"
      ></ng-container>
    </ws-distributor-table>
  </vs-card-content>
</vs-card>

<!-- Dialogs -->
<!-- Edit distributor -->
<ws-create-distributor-dialog
  #editDialog
  [editing]="true"
  [distributor]="distributor"
  (closed)="distributorUpdated()"
></ws-create-distributor-dialog>

<!-- Create sub -->
<ws-create-distributor-dialog
  #createSubDialog
  [distributor]="distributor"
  (closed)="navigateTo($event)"
></ws-create-distributor-dialog>

<!-- Edit sub -->
<ws-create-distributor-dialog
  #editSubDialog
  [editing]="true"
  [distributor]="selectedSub"
  (closed)="distributorUpdated()"
></ws-create-distributor-dialog>
