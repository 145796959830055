<vs-dialog
  [config]="{
      dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]'
    }"
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,

        icon: 'user-minus-01',
        iconTheme: 'danger',

        title: 'Remove ' + (teamMember?.name ?? 'Team Member'),
        subtitle: 'Are you sure you want to remove ' + (teamMember?.name ?? 'this member') + ' from ' + (distributor ? distributor.name : 'the team') + '?'
      }"
  ></vs-dialog-header>

  <div class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pt-6">
    <button vs-button class="w-full sm:w-auto" appearance="outline" [disabled]="_submitting" (click)="closeDialog()">
      Cancel
    </button>

    <button vs-button class="w-full sm:w-auto" theme="danger" [disabled]="_submitting" (click)="confirm()">
      Yes, I'm sure
      <vs-icon *ngIf="_submitting" name="refresh-cw-02" class="animate-spin"></vs-icon>
    </button>
  </div>
</vs-dialog>
