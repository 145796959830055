<ng-container *ngIf="policy$ | async as policy">
  <ng-container *ngIf="canView$ | async as canView">
    <div *vsLet="refreshDocuments$ | async" class="flex gap-4 h-[100%] mt-4">
      <!-- Nav -->
      <div class="flex flex-[2] flex-col gap-4">
        <ng-container *ngFor="let document of documents">
          <div
            *vsLet="selectedDocument?.id === document.id as selected"
            class="sm:flex hidden"
            [class]="getNavboxStyle(document.id)"
            [style.width]="selected ? 'calc(100% + 20px)' : '100%'"
            [style.box-shadow]="selected ? '-2px 4px 10px -10px black' : ''"
            (click)="selectDocument(document)"
          >
            <div class="sm:flex justify-between hidden">
              <p class="font-medium text-lg self-center">{{ document.title }}</p>

              <div class="flex gap-1" [class.mr-4]="selected">
                <button
                  *ngIf="canEdit$ | async"
                  vs-icon-button
                  appearance="clear"
                  (click)="openEditDocumentDialog(document?.title)"
                >
                  <vs-icon name="edit-01" size="18"></vs-icon>
                </button>

                <button
                  *ngIf="canView"
                  vs-icon-button
                  appearance="clear"
                  [disabled]="!selectedDocument?.downloadUrl"
                  (click)="previewDocument(document)"
                >
                  <vs-icon name="download-01" size="18"></vs-icon>
                </button>
              </div>
            </div>

            <vs-divider class="my-2"></vs-divider>

            <p class="font-normal text-sm text-gray-600 mb-2">Created: {{ document.created | date }}</p>
          </div>

          <!-- Mobile view -->
          <div class="sm:hidden flex flex-col">
            <p class="text-lg font-semibold text-gray-900 text-center mb-2">{{ document.title }}</p>

            <object
              *ngIf="selectedDocument?.downloadUrl && canView"
              class="p-4 bg-base w-full h-[75vh] rounded-lg"
              frameBorder="0"
              scrolling="auto"
              [data]="(selectedDocument && selectedDocument.downloadUrl ?? '') + '#toolbar=0&navpanes=0&scrollbar=0' | safe : 'resourceUrl'"
            ></object>

            <p *ngIf="!selectedDocument?.downloadUrl" class="p-3">
              <i>This document doesn't exist yet! Click the pencil icon to create it!</i>
            </p>
          </div>
        </ng-container>
      </div>

      <!-- Document -->
      <div
        class="sm:flex hidden bg-base flex-[6] w-full shadow-lg p-3 h-[100%] min-h-[75vh] rounded-b-lg rounded-tr-lg"
        [class.rounded-tl-lg]="selectedDocument?.title !== 'Terms & Conditions'"
      >
        <ng-container *ngIf="(loading$ | async) === false">
          <div class="w-full h-[75vh] rounded-lg">
            <p *ngIf="!selectedDocument?.downloadUrl" class="p-3">
              <i>This document doesn't exist yet! Click the pencil icon to create it!</i>
            </p>
            <object
              frameBorder="0"
              scrolling="auto"
              class="w-full h-[75vh] rounded-lg"
              [data]="(canView && selectedDocument && selectedDocument.downloadUrl) ? (selectedDocument.downloadUrl  + '#toolbar=0&navpanes=0&scrollbar=0' | safe : 'resourceUrl') : ('' | safe : 'resourceUrl') "
            ></object>
          </div>
        </ng-container>
      </div>
    </div>

    <ws-policy-dialog *ngIf="policy" #editDocumentsDialog [policy]="policy" [editing]="editType"></ws-policy-dialog>

    <ws-preview-pdf-dialog
      *ngIf="policy"
      #previewPDFDialog
      class="h-full"
      [document]="selectedDocument"
    ></ws-preview-pdf-dialog>
  </ng-container>
</ng-container>
