import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { heightExpansion } from '@vsolv/vectors-ui/animations';
import { NavItem } from '../nav-item';

export interface SideBarNavItem extends NavItem {
  hideDetailArrow?: boolean;
  showDefaultIcon?: boolean;
}

@Component({
  selector: 'vs-sidebar-nav-item',
  templateUrl: './sidebar-nav-item.component.html',
  animations: [heightExpansion],
})
export class SidebarNavItemComponent {
  @HostBinding('class') styles = 'w-full text-gray-700 font-semibold select-none';

  showIcon = false;

  @Input() config?: SideBarNavItem;
  @Input() open = false;

  @Output() toggled = new EventEmitter<boolean>();

  linkHover = false;

  protected click() {
    if (this.config?.children?.length) {
      this.open = !this.open;
      this.toggled.emit(this.open);
    }
  }
}
