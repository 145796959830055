import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Distributor } from '@wsphere/distributors/domain';

@Injectable()
export class DistributorOnBoardingLinkResolver implements Resolve<Distributor.OnboardingLink.Model | null> {
  link: Distributor.OnboardingLink.Model | null = null;

  resolve() {
    return this.link;
  }
}
