import { Staff } from '../lib/staff';

export type AccountPermissionId = (typeof Permissions)[number]['id'];

export const Permissions = <const>[
  {
    id: 'acct_ViewAccount',
    display: {
      title: 'View Account/s',
      description: 'Ability to view account/s details',
    },
  },
  {
    id: 'acct_ManageAccount',
    display: {
      title: 'Edit existing accounts',
      description: 'Ability to update properties of account',
    },
  },
  {
    id: 'acct_CreateAccount',
    display: {
      title: 'Create Accounts',
      description: 'Ability to create new accounts',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
