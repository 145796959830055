/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Property } from '@vsolv/packages/properties/domain';

@Pipe({ name: 'propertyValueToStringArray' })
export class PropertyValueToStringArrayPipe implements PipeTransform {
  transform(value: any, config: { property: Property.Model<Property.PropertyType>; showHidden?: boolean }): string[] {
    const property = config.property;
    const showHidden = config.showHidden ?? false;

    if (property.type === Property.PropertyType.OBJECT) {
      const assignments = (property as Property.ObjectModel)
        .properties!.filter(assignment => (showHidden ? true : !assignment.hidden))
        .sort((a, b) => a.order - b.order);
      const values: string[] = [];
      for (const assignment of assignments) {
        const property = assignment.property!;

        if (property.type === Property.PropertyType.OBJECT) continue;

        const propertyValue = this.extract(value[property.valueKey], property);

        if (propertyValue != null) {
          values.push(`${property.name}: ${propertyValue}`);
        }
      }

      return values;
    } else {
      const propertyValue = this.extract(value, property);
      return [`${property.name}: ${propertyValue}`];
    }
  }

  private extract(value: any, property: Property.Model<Property.PropertyType>) {
    if (!value) return null;
    const typeOfValue = typeof value;

    if (typeOfValue === 'string') {
      return value;
    } else if (typeOfValue === 'number') {
      if (property.config.format === 'currency') {
        const currencyPipe = new CurrencyPipe('en-US');

        return currencyPipe.transform(value / 100, 'US', 'symbol')?.replace('US', '$');
      }
      return (value as number).toString();
    } else if (typeOfValue === 'object') {
      return new Date(value).toDateString();
    } else if (typeOfValue === 'boolean') {
      return value ? 'true' : 'false';
    }

    return null;
  }
}
