import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { ClaimLifecycleStep } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-delete-claim-lifecycle-step-dialog',
  templateUrl: './delete-claim-lifecycle-step.dialog.html',
})
export class DeleteClaimLifecycleStepDialog {
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() step?: ClaimLifecycleStep.Model | null = null;

  @Output() closed = new EventEmitter<ClaimLifecycleStep.Model>();

  open(step?: ClaimLifecycleStep.Model) {
    if (step) this.step = step;
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  cancel() {
    this.close();
  }

  async deleteStep() {
    if (!this.step) throw new Error('Unable to create step, step not found');

    this.closed.emit(this.step);
    this.close();
  }
}
