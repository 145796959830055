<vs-dialog
  *vsLet="item$ | async as claimItem"
  #detailsDialog
  [config]="{ dialogContainerClass: 'max-w-[800px]', startOpen: true }"
  (closed)="navigateTo()"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'file-plus-03',
      iconTheme: 'primary',

      title: 'item details',
      subtitle: 'Manage and resolve claim items by following the steps below.'
    }"
  >
    <div
      *ngIf="claim$ | async as claim"
      #trigger="vsMenuTriggerFor"
      slot="before-title"
      class="flex gap-2 text-lg font-semibold text-gray-900 h-7 bg-gray-50 rounded-lg px-2 -mt-px"
      [vsMenuTriggerFor]="(claim.items?.length || 0) > 1 ? itemMenu : undefined"
      (click)="menuOpen = !menuOpen"
    >
      <span class="max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap">
        {{ claimItem?.coverage?.title ?? 'Other' }}
      </span>

      <vs-icon
        *ngIf="(claim.items?.length || 0)> 1"
        class="self-center mt-0.5"
        [name]="menuOpen ? 'chevron-up' : 'chevron-down'"
      ></vs-icon>

      <ng-template #itemMenu>
        <vs-menu class="mt-1">
          <ng-container *ngFor="let item of claim?.items">
            <div
              *ngIf="item.id !== claimItem?.id"
              vs-menu-item
              class="flex gap-2 px-4 py-3 cursor-pointer hover:bg-gray-50"
              (click)="trigger.close(); switchItems(item.id)"
            >
              <span class="max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap">
                {{ item?.coverage?.title ?? 'Other' }}
              </span>

              <vs-badge
                *vsLet="checkIfIncluded(claim, item) as included"
                class="self-center"
                [theme]="included ? 'success' : 'default'"
              >
                {{ included ? 'Included' : 'Excluded' }}
              </vs-badge>
            </div>
          </ng-container>
        </vs-menu>
      </ng-template>
    </div>
  </vs-dialog-header>

  <div class="flex flex-col p-4">
    <vs-stepper
      *ngIf="steps$ | async as steps"
      class="mb-4"
      theme="primary"
      [config]="{ activeStep: page, mode: 'horizontal', steps: steps }"
      (stepChanged)="page = $event"
    ></vs-stepper>

    <router-outlet></router-outlet>
  </div>
</vs-dialog>
