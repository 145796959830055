import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { DashboardResolver } from '../resolvers';
import { DashboardService } from '../services';

@Injectable()
export class DashboardGuard implements CanActivate {
  constructor(private dashboardSvc: DashboardService, private resolver: DashboardResolver, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const id = route.paramMap.get('id');
    if (!id) return this.router.createUrlTree(['/']);

    this.resolver.dashboard = await this.dashboardSvc.retrieve(id).catch(() => null);
    if (!this.resolver.dashboard) return this.router.createUrlTree(['/']);

    return true;
  }
}
