import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { Policy } from '@wsphere/warranties/domain';
import { EditPlanConditionDialog } from '../../dialogs';

export interface PlanCoverageMonetaryBoxConfig {
  text?: number;
  darkBackground?: boolean;
}

@Component({
  selector: 'ws-plan-coverage-monetary-box',
  templateUrl: './plan-coverage-monetary-box.component.html',
})
export class PlanCoverageMonetaryBoxComponent {
  @Input() config?: PlanCoverageMonetaryBoxConfig;

  @Input() type = 'number';
  @Input() editing = false;
  @Input() disabled = false;
  @Input() propertySet!: PropertySet.Model;
  @Input() plan: Policy.Plan.Model | null = null;
  @Input() rule: Conditions.Rule<string | number | string[]> | null = null;

  @Output() ruleUpdated = new EventEmitter<Conditions.Rule<string | number | string[]> | null>();

  @ViewChild('editConditionDialog') editConditionDialog?: EditPlanConditionDialog;

  hovered = false;

  numberRule(rule: Conditions.Rule<string | number | string[]> | null) {
    return rule?.defaultValue === null || typeof rule?.defaultValue === 'number'
      ? (rule as Conditions.Rule<number>)
      : null;
  }

  openEditConditionDialog() {
    this.editConditionDialog?.openDialog();
  }

  updateDefault(value: number) {
    if (!this.rule) return;
    this.rule.defaultValue = value;
    this.updatedRule(this.rule);
  }

  removeValuePropertyPath() {
    if (!this.rule) return;
    this.rule.defaultValue = '';
    this.rule.valuePropertyPath = null;
    this.updatedRule(this.rule);
  }

  updateBlock(value: number, index: number) {
    if (!this.rule) return;
    this.rule.blocks[index].value = value;
    this.updatedRule(this.rule);
  }

  updatedRule(rule: Conditions.Rule<string | number | string[]> | null) {
    this.ruleUpdated.emit(rule);
  }

  isNotANumber(value: number): boolean {
    return isNaN(value);
  }
}
