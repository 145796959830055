import { Component, inject, Input } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { Policy } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-email-preview',
  templateUrl: './email-preview.component.html',
})
export class EmailPreviewComponent {
  @Input() email: Policy.Email | null = null;
  @Input() emailButton: string | null = null;

  private portalSvc = inject(PortalService);
  protected tenantLogo = this.portalSvc.config.logo;
  protected tenantName = this.portalSvc.config.brandName;
}
