/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CreateCustomerOptions, FundingSource } from '@vsolv/dwolla/domain';
import { DwollaService } from '@vsolv/packages/reimbursement/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { Claim } from '@wsphere/warranties/domain';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject } from 'rxjs';
import { Payment } from '../../pages';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-payment-dialog',
  templateUrl: './payment.dialog.html',
})
export class PaymentDialog {
  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 1,
  };

  @ViewChild(DialogComponent) private readonly dialog?: DialogComponent;

  @Output() refresh = new EventEmitter<void>();
  @Output() refreshPaymentMethods = new EventEmitter<void>();

  protected lazyLoad$ = new BehaviorSubject<boolean>(true);

  @Input() canManagePaymentMethods: boolean | null = null;
  @Input() customer: CreateCustomerOptions | null = null;

  @Input() customerId: string | null = null;

  private formBuilder = inject(FormBuilder);
  private claimSvc = inject(ClaimService);
  private dwollaSvc = inject(DwollaService);
  private toastSvc = inject(ToastService);
  private linkSvc = inject(LinksWebService);

  protected readonly claim$ = this.claimSvc.getClaim();
  protected readonly payment$ = new BehaviorSubject<Claim.ClaimPayment.Model | null>(null);

  protected readonly destinations$ = new BehaviorSubject<FundingSource[] | null>(null);
  @Input() set destinations(destinations: FundingSource[] | null) {
    this.destinations$.next(destinations);
  }
  get destinations() {
    return this.destinations$.value;
  }

  private originalLinks: Link.FormLink[] | null = null;
  protected isEditing = false;

  protected paymentForm = this.formBuilder.group({
    title: ['', [Validators.required, Validators.maxLength(255)]],
    description: ['', Validators.required],
    destination: [null as FundingSource | string | null],
    amount: [null as number | null, [Validators.min(0.01)]],
    links: [[] as Link.FormLink[]],
  });

  async open(claim: Claim.Model | null, payment?: Payment) {
    if (payment) await this.initializeForm(payment);
    this.dialog?.open();
    this.loadForm();
  }

  async save(claim: Claim.Model, editing: boolean, paymentId?: string) {
    if (this.paymentForm.value.title) {
      const customerId = claim.warranty?.customerId;

      const destination =
        typeof this.paymentForm.value.destination === 'string'
          ? this.paymentForm.value.destination
          : this.paymentForm.value.destination?.id;

      const payment: Claim.ClaimPayment.CreateClaimPaymentRequest | Claim.ClaimPayment.UpdateClaimPaymentRequest = {
        title: this.paymentForm.value.title,
        description: this.paymentForm.value.description ?? null,
        destination,
        amount: this.paymentForm.value.amount ?? 0,
        ...(customerId && !editing && { customerId }),
      };
      this.lazyLoad$.next(true);
      const response = !editing
        ? await this.createPayment(claim, payment as Claim.ClaimPayment.CreateClaimPaymentRequest)
        : paymentId
        ? await this.updatePayment(claim, paymentId, payment as Claim.ClaimPayment.UpdateClaimPaymentRequest)
        : null;

      if (response?.id) {
        await this.updateLinks(response);
        this.toastSvc.show({
          type: 'success',
          title: `Payment ${!editing ? 'created' : 'updated'}!`,
          text: `Your ${!editing ? 'new' : ''} payment "<strong>${payment.title}</strong>" has been successfully ${
            !editing ? 'created' : 'updated'
          }.`,
        });
        this.lazyLoad$.next(false);
        this.refresh.emit();
        this.close();
      }
    }
  }

  private async createPayment(claim: Claim.Model, payment: Claim.ClaimPayment.CreateClaimPaymentRequest) {
    const newPayment = await this.claimSvc.createPayment(claim.id, payment).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
      this.lazyLoad$.next(false);
      this.close();
    });
    if (newPayment?.id) {
      await this.linkSvc.create(
        { id: claim.id, objectType: Link.ObjectType.CLAIM },
        {
          object1: { id: claim.id, objectType: Link.ObjectType.CLAIM },
          object2: { id: newPayment.id, objectType: Link.ObjectType.PAYMENT },
        }
      );
    }
    return newPayment;
  }

  private async updatePayment(
    claim: Claim.Model,
    paymentId: string,
    payment: Claim.ClaimPayment.UpdateClaimPaymentRequest
  ) {
    return await this.claimSvc.updatePayment(claim.id, paymentId, payment).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
      this.lazyLoad$.next(false);
      this.close();
    });
  }

  async updateLinks(payment: { id: string; claimId: string }) {
    const links = this.paymentForm.controls.links.value;

    // Add new links
    if (links?.length) {
      const originalLinkObjectIds = this.originalLinks?.map(link => (link.object as any)?.id);
      const originalLinkIds = this.originalLinks?.map(link => link.id);

      await Promise.all(
        links.map(async link => {
          if (!originalLinkIds?.includes(link.id) && !originalLinkObjectIds?.includes((link.object as any)?.id)) {
            await this.linkSvc.create(
              { id: payment.claimId, objectType: Link.ObjectType.CLAIM },
              {
                object1: { id: (link.object as any).id, objectType: link.type },
                object2: { id: payment.id, objectType: Link.ObjectType.PAYMENT },
              }
            );
          }
        })
      );
    }

    // Remove links
    if (this.originalLinks?.length) {
      const newLinks = links?.map(link => (link.object as any)?.id);

      await Promise.all(
        this.originalLinks?.map(async link => {
          if (link.id && !newLinks?.includes((link.object as any)?.id)) {
            await this.linkSvc.delete({ id: payment.claimId, objectType: Link.ObjectType.CLAIM }, link.id);
          }
        })
      );
    }
  }

  close() {
    this.dialog?.close();
  }

  protected refreshDestinations() {
    this.refreshPaymentMethods.emit();
  }

  private async getLinks(paymentId: string, claimId: string): Promise<Link.FormLink[] | null> {
    const links = (
      await this.linkSvc.list(
        { id: claimId, objectType: Link.ObjectType.CLAIM },
        { owner: { id: paymentId, objectType: Link.ObjectType.PAYMENT } }
      )
    ).items;
    let originalLinks: Link.FormLink[] = [];
    if (links.length) {
      originalLinks = Link.getPickerLinks(links, Link.ObjectType.PAYMENT, paymentId);
      if (originalLinks.length) {
        this.originalLinks = originalLinks;
      }
    }
    return originalLinks.length
      ? originalLinks.map(link => ({ id: link.id, object: link.object, type: link.type }))
      : null;
  }

  private async initializeForm(payment: Payment) {
    this.isEditing = true;
    this.paymentForm.reset();
    this.payment$.next(payment);
    const links = await this.getLinks(payment.id, payment.claimId);
    const destination = payment.destination
      ? await this.dwollaSvc.getFundingSource(payment.destination, payment.customerId)
      : null;
    this.paymentForm.patchValue({
      title: payment.title,
      description: payment.description,
      destination: destination,
      amount: payment.amount > 0 ? payment.amount / 100 : null,
      links: links,
    });
    this.loadForm();
  }

  private loadForm() {
    this.lazyLoad$.next(false);
    setTimeout(() => {
      this.lazyLoad$.next(true);
    }, 500);
  }
}
