import { Component, ViewChild } from '@angular/core';
import { IconButtonColumn, IconColumn, TableColumn, TextColumn } from '@vsolv/vectors/table';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { combineLatest, from, map, Observable } from 'rxjs';
import { EditEmailTemplateDialog, EmailPreviewDialog } from '../../dialogs';
import { PolicyService } from '../../services/policy.service';

@Component({
  selector: 'ws-policy-communications-page',
  templateUrl: './policy-communications.page.html',
})
export class PolicyCommunicationsPage {
  constructor(private policySvc: PolicyService, private securitySvc: SecurityService) {}

  @ViewChild(EmailPreviewDialog) emailPreviewDialog!: EmailPreviewDialog;
  @ViewChild('editEmailTemplateDialog') editEmailTemplateDialog!: EditEmailTemplateDialog;

  policy$ = this.policySvc.getPolicyObservable();

  selectedPolicyEmail!: Policy.Email | null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectedEmailTemplate: any;

  canView$ = from(this.securitySvc.hasAccess('pol_View', null));
  canEdit$ = from(this.securitySvc.hasAccess('pol_Edit', null));

  columns$: Observable<TableColumn<unknown>[]> = combineLatest([this.canView$, this.canEdit$]).pipe(
    map(([canView, canEdit]) => {
      return [
        new IconColumn<Policy.Email>({ fitContent: true, align: 'center', customCellClasses: 'pr-0' }, item => ({
          icon: item.enabled ? 'check-circle' : null,
          hideBackground: true,
          theme: 'default',
          size: 18,
        })),
        new TextColumn<Policy.Email>({ header: 'Type' }, item => ({
          text: item.type,
          classes: 'truncate',
        })),
        new TextColumn<Policy.Email>({ header: 'Subject' }, item => ({
          text: item.subject ?? '',
          classes: 'max-w-[200px] truncate',
        })),
        new TextColumn<Policy.Email>({ header: 'Byline' }, item => ({
          text: item.byline ?? '',
          classes: 'max-w-[200px] truncate',
        })),
        new TextColumn<Policy.Email>({ header: 'Content' }, item => {
          const text = item.content
            ?.replace(/<[^>]*>?/gm, '')
            .replace(/&nbsp;/g, ' ')
            .replace(/&lt;/g, '<')
            .replace(/&gt;/g, '>')
            .replace(/&amp;/g, '&');
          return {
            text: text ?? '',
            classes: 'max-w-[200px] truncate',
          };
        }),
        ...(canView
          ? [
              new IconButtonColumn<Policy.Email>({ stickyEnd: true, fitContent: true }, item => ({
                type: 'clear',
                rounded: true,
                icon: 'eye',
                click: () => this.openEmailPreviewDialog(item),
              })),
            ]
          : []),
        ...(canEdit
          ? [
              new IconButtonColumn<Policy.Email>({ stickyEnd: true, fitContent: true }, item => ({
                type: 'clear',
                rounded: true,
                icon: 'edit-01',
                click: () => this.openEmailTempEditDialog(item),
              })),
            ]
          : []),
      ];
    })
  );

  openEmailPreviewDialog(policyEmail: Policy.Email) {
    this.selectedEmailTemplate = policyEmail;
    this.emailPreviewDialog.open(policyEmail);
  }

  openEmailTempEditDialog(policyEmail: Policy.Email) {
    this.selectedPolicyEmail = null;

    setTimeout(() => (this.selectedPolicyEmail = policyEmail), 1);
    this.editEmailTemplateDialog?.open();
  }
}
