import { HttpClient } from '@angular/common/http';
import { inject, Injectable, InjectionToken, Injector, Type } from '@angular/core';
import { Dashboard } from '@vsolv/packages/dashboarding/domain';
import { firstValueFrom, tap } from 'rxjs';

export interface BuiltInModel extends Dashboard.Model {
  component: (injector: Injector) => Promise<{ type: Type<unknown>; inputs: unknown }>;
}

export const BUILT_IN_DASHBOARDS = new InjectionToken<BuiltInModel | Array<BuiltInModel>>('BUILT_IN_DASHBOARDS');

@Injectable()
export class DashboardService {
  private http = inject(HttpClient);
  private builtIns = inject(BUILT_IN_DASHBOARDS, { optional: true });

  private dashboards = new Map<string, Dashboard.Model>();

  builtInDashboards(): Array<BuiltInModel> {
    return Array.isArray(this.builtIns) ? this.builtIns : this.builtIns ? [this.builtIns] : [];
  }

  async list(params: Dashboard.ListDashboardsRequest) {
    return await firstValueFrom(
      this.http
        .get<Dashboard.ListDashboardsResponse>('/api/dashboards', {
          params: {
            ...(params.page ? { page: params.page.toString() } : {}),
            ...(params.limit ? { limit: params.limit.toString() } : {}),
          },
        })
        .pipe(tap(page => page.items.forEach(dashboard => this.dashboards.set(dashboard.id, dashboard))))
    );
  }

  async listAll() {
    const result: Dashboard.Model[] = [];

    let page: Dashboard.ListDashboardsResponse | null = await this.list({ page: 1, limit: 100 });

    while (page?.items?.length) {
      result.push(...page.items);

      page =
        page.meta.currentPage < (page.meta.totalPages ?? 0)
          ? await this.list({ page: page.meta.currentPage + 1, limit: 100 })
          : null;
    }

    return result;
  }

  async retrieve(id: string) {
    return (
      this.builtInDashboards().find(d => d.id === id) ??
      (this.dashboards.has(id)
        ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          this.dashboards.get(id)!
        : await firstValueFrom(
            this.http
              .get<Dashboard.Model>(`/api/dashboards/${id}`)
              .pipe(tap(dashboard => dashboard && this.dashboards.set(id, dashboard)))
          ))
    );
  }

  async retrieveDefault() {
    for (const [_, dashboard] of this.dashboards.entries()) {
      if (dashboard.isDefault) return dashboard;
    }

    return await firstValueFrom(
      this.http
        .get<Dashboard.Model | null>('/api/dashboards/default')
        .pipe(tap(dashboard => dashboard && this.dashboards.set(dashboard.id, dashboard)))
    );
  }

  async create(props: Dashboard.CreateDashboardRequest) {
    return await firstValueFrom(this.http.post<Dashboard.CreateDashboardResponse>('/api/dashboards', props));
  }

  async update({ id, ...props }: Dashboard.UpdateDashboardRequest) {
    return await firstValueFrom(
      this.http.patch<Dashboard.UpdateDashboardResponse>(`/api/dashboards/${id}`, props).pipe(
        tap(() => {
          this.dashboards.delete(id);
          if (props.isDefault === true) {
            for (const [_, dashboard] of this.dashboards) {
              if (dashboard.id !== id && dashboard.isDefault) {
                this.dashboards.set(dashboard.id, { ...dashboard, isDefault: false });
              }
            }
          }
        })
      )
    );
  }

  async setFavourite({ id, ...props }: Pick<Dashboard.UpdateDashboardRequest, 'id' | 'isDefault'>) {
    return await firstValueFrom(
      this.http.patch<Dashboard.UpdateDashboardResponse>(`/api/dashboards/${id}/set-favourite`, props).pipe(
        tap(() => {
          this.dashboards.delete(id);
          if (props.isDefault === true) {
            for (const [_, dashboard] of this.dashboards) {
              if (dashboard.id !== id && dashboard.isDefault) {
                this.dashboards.set(dashboard.id, { ...dashboard, isDefault: false });
              }
            }
          }
        })
      )
    );
  }
}
