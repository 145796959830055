/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Inject, Input, Optional, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { DwollaTokenRequest } from '@vsolv/dwolla/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { BehaviorSubject } from 'rxjs';
import { DwollaService } from '../../services';
import { DwollaConfig, DWOLLA_CONFIG } from '../../tokens';

declare const dwolla: any;

@Component({
  selector: 'vs-create-funding-source-dwolla',
  templateUrl: './create-funding-source-dwolla.component.html',
})
export class CreateFundingSourceDwollaComponent {
  @Input() customerId: string | null = null;
  @Input() isCustomerRequest = false;

  @PropertyListener('refresh') refresh$ = new BehaviorSubject<boolean>(true);
  @Input() refresh = true;

  @Output() closeDialog = new EventEmitter<{ id: string }>();

  protected loading = false;

  constructor(
    @Optional() @Inject(DWOLLA_CONFIG) private config: DwollaConfig,
    private dwollaSvc: DwollaService,
    private toastSvc: ToastService
  ) {
    if (!dwolla) throw new Error('Unable to load dwolla library');
    dwolla.configure({
      environment: config.mode,
      styles: '/assets/create-funding-source-dwolla.component.css',
      token: async (request: DwollaTokenRequest) => await this.getDwollaAPIToken(request),
      success: async (response: any) => await this.success(response),
      error: (err: any) => Promise.resolve(err),
    });
  }

  async getDwollaAPIToken(request: DwollaTokenRequest) {
    return await this.dwollaSvc.getToken({
      action: request.action,
      ...(!this.isCustomerRequest && this.customerId && { dwollaCustomerId: this.customerId }),
    });
  }

  async success(response: any) {
    if (response.response.code !== 'ValidationError') {
      this.loading = true;
      const id = response.response.location.split('/').at(-1);
      this.closeDialog.emit({ id });
      this.loading = false;
    } else {
      this.toastSvc.show({
        type: 'error',
        text: response.response._embedded.errors.map((error: { message: string }) => error.message).join(),
        title: 'Something went wrong',
      });
    }
  }
}
