import { Staff } from '../lib/staff';

export type CustomerPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'cus_ViewDetails',
    display: {
      title: 'View Customers',
      description: 'View customers',
    },
  },
  {
    id: 'cus_Create',
    display: {
      title: 'Create Customers',
      description: 'Create customers',
    },
  },
  {
    id: 'cus_Delete',
    display: {
      title: 'Delete Customers',
      description: 'Delete customers',
    },
  },
  {
    id: 'cus_EditDetails',
    display: {
      title: 'Edit Customers',
      description: 'Edit customer details',
    },
  },
  {
    id: 'cus_ViewNote',
    display: {
      title: 'View Customers Notes',
      description: 'View Customers Notes',
    },
  },
  {
    id: 'cus_ManageNote',
    display: {
      title: 'Manage Customers Notes',
      description: 'Manage Customers Notes',
    },
  },
  {
    id: 'cus_RemoveNote',
    display: {
      title: 'Remove Customers Notes',
      description: 'Remove Customers Notes',
    },
  },
  {
    id: 'cus_GetCustomerSignInLink',
    display: {
      title: 'Generate a new sign in link for a customer',
      description: 'Generate Sign In Link',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
