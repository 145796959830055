<vs-dialog
  #previewDialog
  [config]="{
      dialogContainerClass: 'w-4/5 sm:w-[500px] max-w-[700px]',
      disableBackdropDismiss: false,
      hideBackdrop: false
    }"
  (closed)="onClose()"
  class="z-50"
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,
        icon: 'check',
        iconTheme: 'primary',
        title: 'SFTP Password Created',
        subtitle: 'The SFTP credentials for your distributor have been successfully set. Please copy this password to your clipboard now.'
      }"
  ></vs-dialog-header>
  <vs-input-container class="w-fill hidden sm:block">
    <input type="text" [value]="key" readonly class="w-full" />
  </vs-input-container>

  <div class="footer flex sm:justify-end gap-1 mt-2">
    <div class="w-full sm:w-fit sm:flex-row flex flex-col justify-end">
      <button
        vs-button
        class="grow w-full mb-3 sm:mb-0 sm:mr-2 order-last sm:order-first"
        (click)="dialog.close()"
        theme="default"
        appearance="outline"
      >
        Close
      </button>
      <button
        vs-button
        class="grow w-full mb-3 sm:mb-0"
        theme="primary"
        [cdkCopyToClipboard]="key || ''"
        (click)="copy()"
      >
        Copy
      </button>
    </div>
  </div>
</vs-dialog>
