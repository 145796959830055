import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StaffRoleGuard, STAFF_PERMISSION_FALLBACK_ROUTE_DATA, STAFF_PERMISSION_ROUTE_DATA } from '@wsphere/staff/web';
import { DistributorsWebModule } from './distributors-web.module';
import { DistributorGuard } from './guards';
import { DistributorPage, DistributorsPage } from './pages';
import { DistributorResolver } from './resolvers';

@NgModule({
  imports: [
    DistributorsWebModule,
    RouterModule.forChild([
      {
        path: '',
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'dist_ViewDistributors',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [
            { permissionId: 'dsh_ViewDashboard', route: '' },
            { permissionId: 'pol_View', route: 'policies' },
            { permissionId: 'clm_ViewDetails', route: 'claims' },
            { permissionId: 'py_ViewSales', route: 'sales' },
          ],
        },
        canActivate: [StaffRoleGuard],
        component: DistributorsPage,
      },
      {
        path: ':distributorId',
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'dist_ViewDistributor',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'dist_ViewDistributors', route: '/distributors' }],
        },
        canActivate: [DistributorGuard, StaffRoleGuard],
        resolve: { distributor: DistributorResolver },
        component: DistributorPage,
      },
    ]),
  ],
})
export class DistributorRoutingModule {}
