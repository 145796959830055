import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { TabNavItemConfig } from '@vsolv/vectors-ui/app-nav';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { combineLatest, map, switchMap } from 'rxjs';
import { ArchivePolicyDialog, DeletePolicyDialog, PublishPolicyDialog, RestorePolicyDialog } from '../../dialogs';
import { PolicyService } from '../../services';

@Component({ templateUrl: './policy.page.html' })
export class PolicyPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('publishDialog') publishDialog!: PublishPolicyDialog;
  @ViewChild('archiveDialog') archiveDialog!: ArchivePolicyDialog;
  @ViewChild('restoreDialog') restoreDialog!: RestorePolicyDialog;
  @ViewChild('deleteDialog') deleteDialog!: DeletePolicyDialog;

  readonly policy$ = this.policySvc.getPolicyObservable();

  draft = Policy.Status.DRAFT;
  published = Policy.Status.PUBLISHED;
  archived = Policy.Status.ARCHIVED;
  runoff = Policy.Status.RUNOFF;

  canCreate$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_Create', permissionKeys);
    })
  );

  canManageStatus$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_ManageStatus', permissionKeys);
    })
  );

  canViewClaimLifecycle$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_ViewLifecycle', permissionKeys);
    })
  );

  tabs$ = combineLatest([this.canViewClaimLifecycle$]).pipe(
    map(([canViewLifecycle]) => {
      const tabs: TabNavItemConfig[] = [];

      tabs.push({ label: 'Details', routerLink: './' });
      tabs.push({ label: 'Plans', routerLink: './plans', exact: false });
      tabs.push({ label: 'Documents', routerLink: './documents' });
      if (canViewLifecycle) tabs.push({ label: 'Claim Lifecycle', routerLink: './claim-lifecycle' });
      tabs.push({ label: 'Communications', routerLink: './communications' });

      return tabs;
    })
  );

  versions$ = this.policy$.pipe(
    switchMap(async policy => {
      const policyVersion = policy
        ? await this.policySvc.getVersions(policy.id).catch(({ error }) => {
            this.toastSvc.show({ type: 'error', title: 'Unable to load policy version', text: error.message });
            return null;
          })
        : null;
      return policyVersion;
    })
  );

  async duplicatePolicy(policyId: string) {
    const response = await this.policySvc.duplicate(policyId, false).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Policy has been duplicated',
        text: `${response.title} has been successfully duplicated`,
      });

      this.navigateTo(response.id);
    }
  }

  switchToVersion(versionId: string) {
    this.policySvc.refreshPolicy(versionId);
    this.policySvc.clearPlan();

    this.navigateTo(
      versionId +
        (this.route.snapshot.firstChild?.routeConfig?.path ? '/' + this.route.snapshot.firstChild.routeConfig.path : '')
    );
  }

  navigateTo(policyId?: string) {
    if (policyId) this.router.navigate([`../${policyId}`], { relativeTo: this.route });
    else this.router.navigate([`../`], { relativeTo: this.route });
  }
}
