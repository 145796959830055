import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Staff } from '@wsphere/staff/domain';
import { StaffService } from '@wsphere/staff/web';
import { StaffResolver } from '../resolvers';

@Injectable()
export class IsStaffGuard implements CanActivate {
  constructor(
    private firebaseSvc: FirebaseService,
    private resolver: StaffResolver,
    private staffSvc: StaffService,
    private router: Router,
    private toastSvc: ToastService
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    try {
      const staff = await this.staffSvc.retrieveSelf();

      if (!staff || staff.status === Staff.Status.DEACTIVATED) {
        await this.firebaseSvc.signOut();
        return this.notFound();
      } else if (staff.status === Staff.Status.PENDING) {
        return this.router.createUrlTree(['../account-setup']);
      }
      this.resolver.staff = staff;
      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err);
      if (err.status === 403) {
        this.toastSvc.show({
          type: 'error',
          title: 'Access Denied',
          text: "Looks like you don't have access to this page. Please try re-authenticating. If the problem still persists, get in touch with your administrator.",
        });
      } else {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong.',
        });
      }

      await this.firebaseSvc.signOut();
      return this.notFound();
    }
  }

  private notFound() {
    return this.router.createUrlTree(['../sign-in']);
  }
}
