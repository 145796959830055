import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NotificationsPage, SecurityPage, UserProfilePage, UserSettingsPage } from './pages';
import { StaffWebModule } from './staff-web.module';

const routes: Routes = [
  {
    path: '',
    component: UserSettingsPage,
    children: [
      {
        path: 'my-profile',
        component: UserProfilePage,
      },
      {
        path: 'security',
        component: SecurityPage,
      },
      {
        path: 'notifications',
        component: NotificationsPage,
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, StaffWebModule, RouterModule.forChild(routes)],
})
export class StaffWebRoutingModule {}
