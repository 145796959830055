import { inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RefreshGuard implements CanActivate {
  private router = inject(Router);

  canActivate(): boolean | UrlTree {
    const isLoading: boolean = this.router.getCurrentNavigation()?.extras.state?.['isLoading'];
    if (!isLoading) return this.router.createUrlTree(['/']);
    return isLoading;
  }
}
