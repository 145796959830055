/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, EventEmitter, HostBinding, inject, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Distributor } from '@wsphere/distributors/domain';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, switchMap } from 'rxjs';
import { SecurityService } from '../../services';

@Component({
  selector: 'ws-distributor-filter',
  templateUrl: './distributor-filter.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DistributorFilterComponent,
    },
  ],
})
export class DistributorFilterComponent implements ControlValueAccessor, OnDestroy {
  @HostBinding('class') private _classes = 'relative';

  private securitySvc = inject(SecurityService);
  protected elementRef = inject(ElementRef);

  protected touched = false;
  protected overlayOpen = false;

  @PropertyListener('value') protected readonly value$ = new ReplaySubject<Distributor.Model>(1);
  @Input() value: Distributor.Model | null = null;

  @Input() placeholder = 'Find a distributor...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';

  @Input() hideAllDistributorsBadge = false;

  @Output() openChanged = new EventEmitter<boolean>();

  @Output() search = new EventEmitter<string>();
  @Output() valueChanges = new EventEmitter<Distributor.Model | null>();

  @Input() permissionId!: string;
  @PropertyListener('permissionId') protected readonly permissionId$ = new ReplaySubject<string>(1);

  @Input() permissionKey: string | null = null;
  @PropertyListener('permissionKey') protected readonly permissionKey$ = new BehaviorSubject<string | null>(
    this.permissionKey
  );

  protected readonly searchQuery$ = new BehaviorSubject<string>('');
  private globalDistributors$ = this.securitySvc.globalDistributors$;

  protected distributors$: Observable<Distributor.ListDistributorsWithPermissionKeyQueryResponse> = combineLatest([
    this.searchQuery$,
    this.permissionId$,
    this.permissionKey$,
    this.globalDistributors$,
  ]).pipe(
    switchMap(async ([search, permissionId, permissionKey, globalDistributors]) => {
      return await this.securitySvc.listDistributorsWithPermissionKeys({
        ...(search && { search }),
        permissionId,
        permissionKeys: permissionKey ? [permissionKey] : globalDistributors?.map(dist => dist.permissionKey) ?? null,
      });
    })
  );

  @Input() compareWith: (v0: Distributor.Model | null, v1: Distributor.Model | null) => boolean = (
    v0: Distributor.Model | null,
    v1: Distributor.Model | null
  ) => {
    switch (typeof v0) {
      case 'object':
        return JSON.stringify(v0) === JSON.stringify(v1);
      default:
        return v0 === v1;
    }
  };

  onChange = (_value: Distributor.Model | null) => {};
  onTouched = () => {};

  selectValueChanged(value: Distributor.Model) {
    this.markAsTouched();
    this.value = value;
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  searchFor(search: string) {
    this.search.emit(search);
    this.searchQuery$.next(search);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: Distributor.Model | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: Distributor.Model | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  remove() {
    this.markAsTouched();
    this.value = null;
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
