import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { BadgeCellComponent } from './badge.component';

@NgModule({
  imports: [CommonModule, BadgeModule, ThemingModule, TooltipModule, ButtonModule, IconsModule],
  declarations: [BadgeCellComponent],
})
export class BadgeColumnModule {}
