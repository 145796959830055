<div class="border-b border-gray-200 pb-5">
  <div>
    <p class="font-semibold text-lg">Password</p>
    <p class="text-sm text-gray-600 font-medium">Please enter your current password to change your password.</p>
  </div>
</div>

<ng-container *ngIf="user$ | async as user">
  <vs-user-security-form [user]="user"></vs-user-security-form>
</ng-container>
