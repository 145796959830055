export enum Fee {
  PREMIUM_TAX = 'PREMIUM_TAX',
  COMMISSION = 'COMMISSION',
  UNDERWRITING_FEE = 'UNDERWRITING_FEE',
  RISK_MANAGEMENT_FEE = 'RISK_MANAGEMENT_FEE',
  REINSURANCE_FEE = 'REINSURANCE_FEE',
  SURPLUS_LINE_TAX = 'SURPLUS_LINE_TAX',
  INSURER_PREMIUM = 'INSURER_PREMIUM',
  ADMINISTRATIVE_RESERVE = 'ADMINISTRATIVE_RESERVE',
  ADMINISTRATOR_FEE = 'ADMINISTRATOR_FEE',
  OBLIGOR_FEE = 'OBLIGOR_FEE',
  CLIP_FEE = 'CLIP_FEE',
  PLATFORM_FEE = 'PLATFORM_FEE',
  TECHNOLOGY_FEE = 'TECHNOLOGY_FEE',
  POLICY_INSURANCE_FEE = 'POLICY_INSURANCE_FEE',
  DOCUMENTATION_FEE = 'DOCUMENTATION_FEE',
  REGULATORY_COMPLIANCE_FEE = 'REGULATORY_COMPLIANCE_FEE',
  REGULATORY_SURCHARGE = 'REGULATORY_SURCHARGE',
  RESERVE = 'RESERVE',
  CAPITAL_ALLOCATION_FEE = 'CAPITAL_ALLOCATION_FEE',
  ACTUARIAL_FEE = 'ACTUARIAL_FEE',
  CLAIMS_PROCESSING_FEE = 'CLAIMS_PROCESSING_FEE',
  LOSS_ADJUSTMENT_EXPENSE = 'LOSS_ADJUSTMENT_EXPENSE',
  SERVICE_NETWORK_FEE = 'SERVICE_NETWORK_FEE',
  CUSTOMER_SERVICE_FEE = 'CUSTOMER_SERVICE_FEE',
  AUDIT_FEE = 'AUDIT_FEE',
  MARKETING_FEE = 'MARKETING_FEE',
  RENEWAL_AND_TRANSACTION_FEES = 'RENEWAL_AND_TRANSACTION_FEES',
  RENEWAL_AND_PROCESSING_FEE = 'RENEWAL_AND_PROCESSING_FEE',
  TRANSACTION_FEE = 'TRANSACTION_FEE',
  OTHER_FEE = 'OTHER_FEE',
}
