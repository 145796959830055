<ng-container *vsLet="steps$ | async as steps">
  <vs-card
    *ngIf="!isCustomerPortal || steps?.length"
    tooltipStyle="light"
    class="flex flex-col overflow-hidden transition-all duration-300"
    [class.min-h-[116px]]="steps?.length"
    [class.min-h-[100px]]="!steps?.length"
    [class]="editing ? 'max-h-[5000px]' : expanded ? 'max-h-[256px]' : steps?.length ? 'max-h-[124px]' : 'max-h-[84px]'"
    [vsTooltip]="
      isCustomerPortal && claim?.status === expired ? 'Your claim is expired and no further actions can be taken.' : ''
    "
  >
    <div
      class="flex gap-2 justify-between"
      [class]="editing ? 'max-h-[0px]' : steps?.length ? 'max-h-[124px]' : 'max-h-[84px]'"
    >
      <div class="flex w-full pt-2 mx-2 mb-1 overflow-x-auto scrollbar-thin">
        <div *ngIf="steps?.length; else noSteps" class="flex w-full">
          <div
            *ngFor="let step of steps; index as index"
            id="{{ 'step_' + index }}"
            class="flex flex-col gap-2 min-w-[168px] max-w-[168px] pt-3 pb-2 px-1 mr-1 rounded-xl bg-opacity-20 hover:bg-gray-400/10 cursor-pointer transition-all ease-in-out"
            [class.bg-gray-200]="index === selectedStepIndex"
            (click)="
              editing ? '' : index === selectedStepIndex ? expand() : !expanded ? expand() : '';
              selectedStepIndex = index
            "
          >
            <ng-container *vsLet="getTheme(step.status) as theme">
              <div class="flex justify-center">
                <vs-icon
                  size="12"
                  class="p-1 ml-2 border-2 rounded-full"
                  [class]="step.status !== done"
                  [class.border-dashed]="step.optional"
                  [class.ring-4]="index === currentStep"
                  [class.ring-themed-100]="index === currentStep"
                  [type]="step.status === done ? 'solid' : 'line'"
                  [theme]="step.status === done ? 'primary' : theme"
                  [name]="step.status === done ? 'tick' : $any(step.icon)"
                  [class.!bg-themed-50]="index === currentStep || step.status === done"
                  [class.!text-themed-400]="index === currentStep || step.status === done"
                  [class.!border-themed-400]="index === currentStep || step.status === done"
                  [class]="
                    index <= currentStep
                      ? step.status === skipped
                        ? 'text-gray-400 bg-gray-50 border-gray-200'
                        : 'text-primary-500 bg-primary-50 border-primary-500'
                      : 'text-gray-400 bg-base border-gray-200'
                  "
                ></vs-icon>

                <div
                  class="self-center flex-grow h-0.5"
                  [class]="
                    index !== (steps?.length || 0) ? (currentStep > index ? 'bg-primary-500' : 'bg-gray-200') : ''
                  "
                ></div>

                <div
                  class="self-center flex-grow h-0.5 pr-4 -mr-5"
                  [class.!to-themed-500]="index === currentStep - 1"
                  [class.!from-primary-500]="index === currentStep - 1"
                  [class.!bg-gradient-to-r]="index === currentStep - 1 && index !== (steps?.length || 0)"
                  [theme]="steps?.length && index + 1 < (steps?.length || 0) ? getTheme(steps?.[index + 1]?.status || skipped) : 'default'"
                  [class]="
                    index !== (steps?.length || 0) ? (currentStep > index ? 'bg-primary-500' : 'bg-gray-200') : ''
                  "
                ></div>
              </div>

              <div class="flex flex-col gap-1 px-2">
                <p class="text-sm font-medium text-gray-700 truncate">{{ step.title }}</p>

                <div
                  *vsLet="getTooltip(step.status) as tooltip"
                  tooltipStyle="light"
                  [vsTooltip]="{ title: tooltip?.title || '', subtitle: tooltip?.subtitle || '' }"
                >
                  <vs-badge
                    #trigger="cdkOverlayOrigin"
                    cdkOverlayOrigin
                    [theme]="theme"
                    [config]="{ displayStatusIcon: true }"
                    [class.pointer-events-none]="isCustomerPortal"
                    (click)="openStatusPicker(index); $event.stopImmediatePropagation()"
                  >
                    {{ step.status.replace('_', ' ').replace('_', ' ') | titlecase }}

                    <vs-icon
                      *ngIf="!isCustomerPortal"
                      name="chevron-down"
                      class="transition-all"
                      [class.rotate-180]="overlayOpen && index === selectedStepIndex"
                    ></vs-icon>

                    <ng-template
                      *ngIf="!isCustomerPortal"
                      cdkConnectedOverlay
                      [cdkConnectedOverlayOrigin]="trigger"
                      [cdkConnectedOverlayOpen]="overlayOpen && index === selectedStepIndex"
                      (overlayOutsideClick)="$event.stopImmediatePropagation(); closeStatusPicker()"
                    >
                      <vs-card class="flex flex-col mt-2 py-2 !rounded-md">
                        <div
                          *ngFor="let status of statuses"
                          class="flex justify-between items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
                          (click)="updateStatus($any(status))"
                        >
                          <vs-badge
                            [theme]="getTheme($any(status))"
                            [config]="{ displayStatusIcon: true }"
                            (click)="openStatusPicker(index)"
                          >
                            {{ status.replace('_', ' ').replace('_', ' ') | titlecase }}
                          </vs-badge>
                        </div>
                      </vs-card>
                    </ng-template>
                  </vs-badge>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="flex flex-1">
            <div class="bg-gray-200 mt-[23px] flex-1 h-0.5 bg-gradient-to-r from-gray-200 to-base"></div>
          </div>
        </div>

        <ng-template #noSteps>
          <div
            class="flex flex-col w-full mt-4 overflow-hidden transition-all duration-300"
            [class]="expanded ? 'max-h-[200px] mb-4' : 'max-h-[62px]'"
          >
            <p class="font-semibold text-gray-900 self-center -mt-1">No lifecycle steps have been added yet.</p>
            <p class="text-sm font-normal text-gray-600 self-center mb-4">
              Add lifecycle steps to show the customer how their claim is progressing.
            </p>
          </div>
        </ng-template>
      </div>

      <vs-input-group class="py-4 pl-2 pr-4">
        <button
          *ngIf="!editing && (canEdit$ | async)"
          vs-button
          appearance="outline"
          [disabled]="editing && !form.dirty"
          (click)="editing ? save() : steps?.length ? edit() : table?.openCreateStepDialog()"
        >
          <vs-icon [name]="editing ? 'save-02' : 'edit-02'"></vs-icon>
        </button>
      </vs-input-group>
    </div>

    <vs-divider *ngIf="!editing" class="mt-px"></vs-divider>

    <div
      *ngIf="steps && selectedStepIndex !== null && selectedStepIndex >= 0 && selectedStepIndex < steps.length"
      class="flex gap-2 px-4 overflow-hidden transition-all duration-300"
      [class]="expanded ? 'max-h-[1000px]' : 'max-h-[0px]'"
    >
      <p class="text-sm font-medium text-gray-700 my-4 w-44 overflow-hidden">{{ steps[selectedStepIndex].title }}</p>

      <div
        class="flex flex-col gap-4 my-4 pl-4 w-full border border-y-0 border-r-0 overflow-auto scrollbar-thin transition-all ease-in-out"
      >
        <p
          class="text-xs font-normal text-gray-600"
          [class]="getChildClasses()"
          [innerHTML]="steps[selectedStepIndex].description"
        ></p>

        <span *ngIf="steps[selectedStepIndex].optional" class="text-xs font-normal text-gray-600">
          This step is optional.
        </span>
      </div>
    </div>

    <form *ngIf="!isCustomerPortal" [formGroup]="form" [class]="editing ? 'max-h-[5000px]' : 'max-h-[0px]'">
      <ws-claim-lifecycle-table
        #table
        formControlName="steps"
        title="Edit Progress Steps"
        subtitle="Review and configure the steps that will appear in the customer portal for this claim. Changes will be reflected in the above timeline once the table is saved."
        [hideSave]="true"
        [hideBorder]="true"
        [canEdit]="canEdit$ | async"
        (save)="save()"
        (valueChanges)="form.markAsDirty()"
        (stepAdded)="expanded = false; editing = true"
      >
        <vs-input-group slot="buttons">
          <button vs-button appearance="outline" (click)="discardDialog.open()">
            <vs-icon name="x"></vs-icon>
          </button>

          <button
            *ngIf="canEdit$ | async"
            vs-button
            appearance="outline"
            [disabled]="(!editing && !steps?.length) || (editing && !form.dirty)"
            (click)="editing ? save() : edit()"
          >
            <vs-icon name="save-02"></vs-icon>
          </button>
        </vs-input-group>
      </ws-claim-lifecycle-table>
    </form>
  </vs-card>
</ng-container>

<vs-dialog #discardDialog [config]="{ dialogContainerClass: 'max-w-[500px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',

      icon: 'trash-01',
      iconTheme: 'warn',

      title: 'Discard Changes',
      subtitle:
        'Are you sure you want discard changes made to the claim lifecycle steps for <strong> Claim #' +
        claim?.claimNumber +
        '</strong>?'
    }"
  ></vs-dialog-header>

  <div class="flex gap-2">
    <button vs-button appearance="outline" class="flex-1" (click)="discardDialog.close()">Cancel</button>
    <button vs-button theme="danger" class="flex-1" (click)="discard(); discardDialog.close()">Discard Changes</button>
  </div>
</vs-dialog>
