<vs-dialog
  *vsLet="item?.coverage as itemCoverage"
  [config]="{
    dialogContainerClass: 'max-w-xl w-full',
    disableBackdropDismiss: false,
    hideBackdrop: false
  }"
>
  <div *ngIf="item?.description || coverage || itemCoverage" class="flex flex-col gap-2 p-2">
    <div class="flex gap-2 items-center">
      <span class="text-md font-semibold">
        {{ item?.description ? 'Other' : coverage?.title || itemCoverage?.title }}
      </span>
      <ng-container *ngIf="notApplicable">
        <vs-badge theme="danger">Not Applicable</vs-badge>
      </ng-container>
    </div>

    <vs-badge *ngIf="coverage?.group || itemCoverage?.group" class="w-fit">
      {{ coverage?.group || itemCoverage?.group }}
    </vs-badge>
    <p *ngIf="item?.description || coverage?.description || itemCoverage?.description">
      {{ item?.description || coverage?.description || itemCoverage?.description }}
    </p>

    <ng-container *ngIf="warrantyCoverage?.liabilityInfo">
      <vs-divider></vs-divider>
      <ws-claim-item-liability-limit [item]="warrantyCoverage"></ws-claim-item-liability-limit>
    </ng-container>

    <ng-container *ngIf="coverage?.inclusions || itemCoverage?.inclusions">
      <vs-divider></vs-divider>

      <div class="flex gap-2 items-center">
        <vs-icon size="22" name="shield-tick" class="text-primary"></vs-icon>
        <h3 class="text-gray-900 font-semibold">Inclusions</h3>
      </div>

      <div class="text-sm font-normal">{{ coverage?.inclusions || itemCoverage?.inclusions }}</div>
    </ng-container>

    <ng-container *ngIf="coverage?.exclusions || itemCoverage?.exclusions">
      <vs-divider></vs-divider>

      <div class="flex items-center gap-2">
        <vs-icon size="22" name="shield-off" class="text-primary"></vs-icon>
        <h3 class="text-gray-900 font-semibold">Exclusions</h3>
      </div>

      <div class="text-sm font-normal">{{ coverage?.exclusions || itemCoverage?.exclusions }}</div>
    </ng-container>

    <div *ngIf="itemCoverage" class="flex flex-col gap-2">
      <vs-divider></vs-divider>
      <ws-claim-item-liability-limit [item]="item"></ws-claim-item-liability-limit>
      <vs-divider></vs-divider>
    </div>

    <div class="flex justify-end mt-4 w-full">
      <button vs-button class="text-sm font-semibold text-gray-700" appearance="outline" (click)="dialog?.close()">
        Close
      </button>
    </div>
  </div>
</vs-dialog>
