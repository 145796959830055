<vs-dialog
  #dialog
  [config]="{ disableBackdropDismiss: submitting, dialogContainerClass: 'w-[584px] max-w-[600px] max-h-[90%]' }"
  (closed)="closed.emit()"
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        showDivider: true,

        title: 'Add attachment',
        subtitle: 'Edit the details of an attachment for your claim.',
        
        icon: 'attachment-02', iconTheme: 'primary',
        hideCloseButton: submitting
      }"
  ></vs-dialog-header>

  <form [formGroup]="form" class="flex flex-col gap-4 mx-4 mt-4">
    <!-- Title -->
    <vs-form-field appearance="responsive" [config]="{label: 'Title'}">
      <input vsInput type="text" formControlName="title" />
      <div class="text-center">
        <span
          class="text-sm font-normal text-danger"
          *ngIf="form.controls.title.touched && form.controls.title.invalid"
          slot="hint"
        >
          Max length 255 characters
        </span>
      </div>
    </vs-form-field>

    <!-- Description -->
    <vs-form-field appearance="responsive" [config]="{ label: 'Description' }" class="w-full">
      <textarea vsInput formControlName="description"></textarea>
    </vs-form-field>

    <vs-form-field appearance="vertical" [config]="{ label: 'Document' }">
      <vs-file-input
        formControlName="file"
        [accept]="'.PNG, .JPG, .JPEG, .GIF, .PDF'"
        [moreInfo]="'max. 800x400px'"
      ></vs-file-input>
    </vs-form-field>

    <!-- Links inks -->
    <div *ngIf="!isCustomerPortal" class="w-full">
      <ws-claim-link-picker
        *vsLet="claim$ | async as claim"
        formControlName="links"
        [claim]="claim"
      ></ws-claim-link-picker>
    </div>

    <!-- Customer Visibility -->
    <div class="flex flex-row gap-2" *ngIf="!isCustomerPortal">
      <input vsInput type="checkbox" class="self-center" formControlName="visibleToCustomer" />
      <small class="self-center">Make this attachment visible to customer</small>
    </div>
  </form>

  <!-- Buttons -->
  <div *vsLet="isMobile$ | async as isMobile" slot="buttons">
    <div class="flex flex-row justify-end gap-2 mx-4 my-4" *ngIf="!isMobile">
      <button vs-button appearance="outline" [disabled]="submitting" (click)="close()">Cancel</button>
      <button vs-button theme="primary" [disabled]="!form.dirty || form.invalid || submitting" (click)="submit()">
        Save
      </button>
    </div>

    <div class="flex flex-row justify-center gap-3 mt-4" *ngIf="isMobile">
      <button vs-button appearance="outline" class="flex-grow" [disabled]="submitting" (click)="close()">Cancel</button>

      <button
        vs-button
        theme="primary"
        class="flex-grow"
        [disabled]="!form.dirty || form.invalid || submitting"
        (click)="submit()"
      >
        Save
      </button>
    </div>
  </div>
</vs-dialog>
