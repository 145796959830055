import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { WarrantyWebService } from '../../warranty/services';
import { WarrantyResolver } from './warranty.resolver';

@Injectable()
export class WarrantyGuard implements CanActivate {
  constructor(private resolver: WarrantyResolver, private warrantySvc: WarrantyWebService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.warranty = undefined;
    const warrantyId = this.findWarrantyId(route);
    const warranty = await this.warrantySvc.getOneById(warrantyId);
    if (warranty === null) return this.router.createUrlTree(['/404']);
    if (!warranty) return false;

    this.resolver.warranty = warranty;

    return true;
  }

  private findWarrantyId(route: ActivatedRouteSnapshot): string {
    return route.params['warrantyId'] || (route.parent ? this.findWarrantyId(route.parent) : null);
  }
}
