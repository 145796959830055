import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Policy, Provisioning } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, switchMap, tap } from 'rxjs';
import { EvaluatedAddon, PolicyService } from '../../../policy';
import { ProvisioningSessionService } from '../../services';

interface PlanInfoFormValues {
  plan: Policy.Plan.Model | null;
  addonIds: string[] | null;
  termId: string | null;
}

@Component({
  selector: 'ws-provision-plan-form',
  templateUrl: './provision-plan-form.component.html',
  styleUrls: ['./provision-plan-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProvisionPlanFormComponent,
    },
  ],
})
export class ProvisionPlanFormComponent implements ControlValueAccessor, OnInit {
  constructor(private sessionSvc: ProvisioningSessionService, private policySvc: PolicyService) {}

  @Output() nextStep = new EventEmitter();
  @Output() back = new EventEmitter();
  @Output() evaluation = new EventEmitter<Provisioning.EvaluatedSessionPlans | null>();

  @Output() termChanged = new EventEmitter<string>();

  @PropertyListener('session') session$ = new BehaviorSubject<Provisioning.ProvisioningSession | null>(null);
  @Input() session!: Provisioning.ProvisioningSession;

  planControl = new FormControl();

  @Input() disabled = false;
  touched = false;

  @PropertyListener('staffView') staffView$ = new BehaviorSubject(false);
  @Input() staffView = false;

  @Output() addons = new EventEmitter<{ planId: string; addons: EvaluatedAddon[] }[]>();

  evaluatedPlans$ = combineLatest([this.session$, this.staffView$]).pipe(
    switchMap(async ([session, staffView]) => {
      return session ? await this.sessionSvc.evaluateSession(session.id, staffView || false) : null;
    }),
    tap(evaluation => this.evaluation.emit(evaluation))
  );

  policy$ = this.session$.pipe(
    switchMap(async session => {
      if (!session || !session.policyId) return null;
      if (session.policy) return session.policy;
      return await this.policySvc.getOne(session.policyId);
    })
  );

  private _onChange?: (value: PlanInfoFormValues) => void;
  private _onTouched?: () => void;

  next() {
    this.nextStep.emit();
  }

  ngOnInit(): void {
    this.planControl.valueChanges.subscribe(values => this._onChange?.(values));
  }

  writeValue(value: PlanInfoFormValues | null): void {
    this.planControl.setValue(
      {
        plan: value?.plan || null,
        addonIds: value?.addonIds || null,
        termId: value?.termId ?? null,
      },
      { emitEvent: false }
    );
  }

  registerOnChange(onChange: (value: PlanInfoFormValues) => void): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this._onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this._onTouched?.();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  goBack() {
    this.back.emit();
  }
}
