<ng-container *ngIf="item">
  <div
    *ngIf="item.liabilityInfo"
    class="flex justify-center gap-1 w-fit overflow-auto scrollbar-thin"
    tooltipStyle="light"
    [vsTooltip]="{ template: item.coverage?.id ? liability : other }"
  >
    <ng-container *ngIf="item.liabilityInfo?.deductible">
      <p class="flex gap-1 text-sm font-normal text-gray-700">
        <vs-icon class="self-center" name="bank-note-02"></vs-icon>
        <span>{{
          item.coverage?.id ? (item.liabilityInfo.deductible / 100 | currency : 'USD' : 'symbol' : '3.0') : '-'
        }}</span>
        <span>Deductible</span>
      </p>

      <div class="border-r w-px h-5"></div>
    </ng-container>

    <p class="flex gap-1 text-sm font-normal text-gray-700">
      <vs-icon class="self-center" name="currency-dollar-circle"></vs-icon>
      <span
        *ngIf="item.liabilityInfo.limit !== item.liabilityInfo.remainingLiabilityLimit"
        class="text-gray-300 line-through"
      >
        {{ item.liabilityInfo.limit / 100 | currency }}
      </span>

      <span>{{
        item.coverage?.id
          ? (item.liabilityInfo.remainingLiabilityLimit / 100 | currency : 'USD' : 'symbol' : '3.0')
          : '-'
      }}</span>
      <span>Limit</span>
    </p>

    <vs-icon *ngIf="item.coverage?.id" class="self-center text-gray-400" name="help-circle"></vs-icon>

    <ng-template #liability>
      <div class="flex flex-col mb-2">
        <div class="flex bg-gray-50 rounded-t-xl">
          <p class="text-xs font-medium text-gray-600 p-3 min-w-[144px] max-w-[144px]">Coverage</p>
          <p class="text-xs font-medium text-gray-600 whitespace-nowrap p-3 min-w-[128px] max-w-[128px]">
            Original Limit
          </p>
          <p class="text-xs font-medium text-gray-600 whitespace-nowrap p-3 min-w-[128px] max-w-[128px]">
            Remaining Limit
          </p>
        </div>

        <vs-divider></vs-divider>

        <ng-container *ngIf="item.liabilityInfo?.groups?.length">
          <ng-container *ngFor="let group of item.liabilityInfo?.groups">
            <div class="flex">
              <p class="text-sm font-normal text-gray-600 p-3 min-w-[144px] max-w-[144px]">{{ group.title }}</p>
              <p class="text-sm font-normal text-gray-600 p-3 min-w-[128px] max-w-[128px]">
                {{ (group.limit ?? 0) / 100 | currency }}
              </p>
              <p class="text-sm font-normal text-gray-600 p-3 min-w-[128px] max-w-[128px]">
                {{ group.remainingGroupLimit / 100 | currency }}
              </p>
            </div>

            <vs-divider></vs-divider>
          </ng-container>
        </ng-container>

        <div class="flex">
          <p class="text-sm font-normal text-gray-600 p-3 min-w-[144px] max-w-[144px] truncate">
            {{ item.coverage?.title }}
          </p>
          <p class="text-sm font-normal text-gray-600 p-3 min-w-[128px] max-w-[128px]">
            {{ item.liabilityInfo.limit / 100 | currency }}
          </p>
          <p class="text-sm font-normal text-gray-600 p-3 min-w-[128px] max-w-[128px]">
            {{ item.liabilityInfo.remainingCoverageItemLimit / 100 | currency }}
          </p>
        </div>

        <vs-divider></vs-divider>
      </div>

      <p class="flex gap-2 text-sm font-semibold text-gray-700">
        <vs-icon name="currency-dollar-circle" class="self-center"></vs-icon>
        <span>Remaining Liability Limit:</span>
        <span>{{ item.liabilityInfo.remainingLiabilityLimit / 100 | currency }}</span>
      </p>
    </ng-template>
    <ng-template #other> </ng-template>
  </div>
</ng-container>
