import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@vsolv/core/users/web';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Policy, Provisioning, Warranty } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, firstValueFrom, map, switchMap, tap } from 'rxjs';
import { ProvisioningSessionService } from '../../services';

@Component({
  selector: 'ws-warranty-preview-card',
  templateUrl: './warranty-preview-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarrantyPreviewCardComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private portalSvc: PortalService,
    private userSvc: UserService,
    private sessionSvc: ProvisioningSessionService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @Input() termsAndConditions = false;
  @PropertyListener('termsAndConditions') termsAndConditions$ = new BehaviorSubject<boolean>(this.termsAndConditions);

  @Output() back = new EventEmitter();

  @PropertyListener('selectedPaymentSchedule') selectedPaymentSchedule$ =
    new BehaviorSubject<Policy.PaymentSchedule | null>(null);
  selectedPaymentSchedule: Policy.PaymentSchedule | null = null;

  isLoading = true;

  termsDoc: Policy.Document | null = null;

  paymentMethod = new FormControl(null as string | null, [Validators.required]);

  contactEmail = this.portalSvc.config.contactEmail;

  protected session$ = this.sessionSvc.getCurrentSession();

  protected preview$ = this.sessionSvc.preview$.asObservable();

  protected warranty$ = new BehaviorSubject<Warranty.Model | null>(null);

  protected payingForCustomer$ = new BehaviorSubject<boolean>(false);

  protected isProcessingProvisioning$ = new BehaviorSubject<boolean>(false);

  protected currentUser$ = this.userSvc.getSelf();

  readonly staffView$ = this.route.data.pipe(map(data => data['staffView'] as boolean));

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  selectedTerm$ = this.preview$.pipe(
    map(preview => preview?.session.policy?.terms.find(term => term.id === preview.session.policyTermId) ?? null)
  );

  paymentSchedules$ = combineLatest([this.selectedTerm$, this.selectedPaymentSchedule$]).pipe(
    switchMap(async ([term, selectedPaymentSchedule]) => {
      const oneTime = term?.paymentSchedules.find(schedule => schedule.installments === 1) ?? null;
      const installments =
        term?.paymentSchedules
          .filter(schedule => schedule.installments !== 1)
          .sort((a, b) => b.installments - a.installments) ?? [];

      if (!selectedPaymentSchedule && (oneTime || installments.length)) {
        await this.selectPaymentSchedule(oneTime ?? installments[0]);
      }

      return { oneTime, installments };
    })
  );

  baseCoverages$ = this.preview$.pipe(
    tap(preview =>
      preview?.warranty.policy?.termsAndConditions
        ? (this.termsDoc = preview.warranty.policy.termsAndConditions)
        : undefined
    ),
    map(preview => preview?.warranty.coverages.filter(coverage => coverage.requirement === 'BASE'))
  );

  selectedAddons$ = this.preview$.pipe(
    map(preview => preview?.addons),
    tap(() => (this.isLoading = false))
  );

  evaluateTermsAndConditions(termsAndConditions: boolean) {
    this.termsAndConditions = termsAndConditions;
  }

  provision(sessionId: string, hash: string, staffView: boolean) {
    this.confirm(sessionId, hash, staffView, this.paymentMethod.value);
  }

  goBack(session: Provisioning.ProvisioningSession | null, staffView: boolean) {
    const addons = this.sessionSvc.addons$.value;
    const hasAddons = addons?.find(addon => addon.planId === session?.plan?.id)?.addons.length;
    if (staffView) {
      if (!hasAddons) this.navigateTo('/provisioning/plan');
      else this.navigateTo('/provisioning/addon');
    } else {
      if (!hasAddons) this.navigateTo('/checkout/plan');
      else this.navigateTo('/checkout/addon');
    }
  }

  private navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }

  async confirm(sessionId: string, hash: string, staffView: boolean, paymentMethodId?: string | null) {
    try {
      this.isProcessingProvisioning$.next(true);
      const warranty = await this.sessionSvc.confirm(sessionId, hash, paymentMethodId || undefined);
      this.warranty$.next(warranty);

      this.sessionSvc.sessionComplete$.next(true);
      this.sessionSvc.clearSession();
      this.sessionSvc.distributor$.next(null);
      this.sessionSvc.addons$.next(null);
      this.sessionSvc.preview$.next(null);

      // FIXME: Provisioning session local storage
      // if (!staffView) localStorage.removeItem('provisioning-session');

      this.toastSvc.show({
        type: 'success',
        title: 'Warranty Provisioned',
        text: `Your contract number is ${warranty.contractNumber}`,
      });

      this.isProcessingProvisioning$.next(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      console.error(err);

      this.isProcessingProvisioning$.next(false);

      if (err.status === 409) {
        this.toastSvc.show({
          type: 'error',
          title: 'Cannot provision warranty',
          text: `This session already has a warranty provisioned`,
        });
      } else {
        this.toastSvc.show({
          type: 'error',
          title: 'Cannot provision warranty',
          text: `Something went wrong`,
        });
      }
    }
  }

  async selectPaymentSchedule(paymentSchedule: Policy.PaymentSchedule | null) {
    const preview = await firstValueFrom(this.preview$);
    if (!preview) return;
    this.selectedPaymentSchedule = paymentSchedule;

    await this.sessionSvc.updateSession({
      sessionId: preview.session.id,
      data: { paymentSchedule: paymentSchedule ?? undefined },
    });
    // const response = await this.sessionSvc.preview(preview.session.id, preview.)
  }
}
