/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { PropertySet } from '@vsolv/packages/properties/domain';

@Component({
  selector: 'ws-condition-group-builder',
  templateUrl: './condition-group-builder.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ConditionGroupBuilderComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionGroupBuilderComponent,
    },
  ],
})
export class ConditionGroupBuilderComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(private formBuilder: FormBuilder, private injector: Injector) {
    this.form.valueChanges.subscribe(value => this.onChange(value as any));
  }

  @Input() propertySet!: PropertySet.Model;
  @Input() hideClose = false;
  @Output() conditionRemoved = new EventEmitter<number>();

  touched = false;
  control?: NgControl;
  comparisons = [Conditions.Comparison.AND, Conditions.Comparison.OR];

  form = this.formBuilder.group({
    comparison: [Conditions.Comparison.AND, Validators.required],
    conditions: this.formBuilder.array([], Validators.required),
  });

  onChange = (_value: Conditions.Rule<string | number | string[]>) => {};
  onTouched = () => {};

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(group: Conditions.ConditionGroup | null): void {
    this.conditions.clear();

    if (group && group.conditions?.length !== this.conditions?.length) {
      this.form.patchValue({ comparison: group.comparison });

      if (!group.conditions?.length) this.addCondition();
      else {
        for (const condition of group.conditions) {
          const cond = this.getCondition(condition);
          if (cond) this.addCondition(cond);
          else {
            const grp = this.getGroup(condition);
            if (grp) this.addGroup(grp);
          }
        }
      }
    } else {
      this.addCondition();
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  validate(): ValidationErrors | null {
    if (this.hideClose && !this.form.value.conditions?.length) return null;

    if (this.conditions.controls?.length === 1) {
      const cond = this.getFormCondition(this.conditions.controls[0]);
      const operator = cond?.operator;
      if (!cond?.propertyId) return { conditions: ['Each condition must have a property'] };
      if (
        operator !== Conditions.Operator.IS_NULL &&
        operator !== Conditions.Operator.IS_NOT_NULL &&
        !cond.valuePropertyId &&
        !cond?.value &&
        cond.value !== false
      )
        return { conditions: ['Each condition must have a value'] };
    }

    return this.form.valid
      ? null
      : {
          comparison: this.form.get('comparison')?.errors,
          conditions: this.form.get('conditions')?.errors,
        };
  }

  get conditions() {
    return this.form.get('conditions') as FormArray;
  }

  getCondition(condition: any) {
    return (condition as any)?.operator ? (condition as Conditions.Condition<Conditions.PropertyValueType>) : null;
  }

  getGroup(group: any) {
    return (group as any)?.comparison ? (group as Conditions.ConditionGroup) : null;
  }

  getFormCondition(condition: AbstractControl) {
    return condition.value?.operator ? (condition.value as Conditions.Condition<Conditions.PropertyValueType>) : null;
  }

  getFormGroup(group: AbstractControl) {
    return group.value?.comparison ? (group.value as Conditions.ConditionGroup) : null;
  }

  addCondition(condition?: Conditions.Condition<Conditions.PropertyValueType>) {
    const newCondition = condition
      ? new FormControl({
          propertyId: condition.propertyId,
          operator: condition.operator,
          value: condition.value,
          type: condition.type,
          valuePropertyId: condition.valuePropertyId ?? null,
        })
      : new FormControl({
          propertyId: null,
          operator: Conditions.Operator.EQUALS,
          value: null,
          type: null,
          valuePropertyId: null,
        });

    this.conditions.push(newCondition);
  }

  addGroup(group?: Conditions.ConditionGroup) {
    const newGroup = group
      ? new FormControl({ comparison: group.comparison, conditions: group.conditions })
      : new FormControl({ comparison: Conditions.Comparison.AND });

    this.conditions.push(newGroup);
  }

  discardChanges() {
    const controls = this.form.get('conditions') as FormArray;
    this.conditions.removeAt(controls.controls.length - 1);
  }

  removeCondition(index: number) {
    this.conditions.removeAt(index);
  }

  removeGroup() {
    this.conditionRemoved.emit();
  }

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.control.valueAccessor = this;
  }
}
