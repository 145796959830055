<vs-card class="flex flex-col gap-8 items-center justify-center text-center lg:p-20 px-6 py-8">
  <div>
    <svg width="229" height="166" viewBox="0 0 229 166" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="117.5" cy="80.5" r="80" class="fill-primary-200" />
      <circle cx="33.5" cy="20.5" r="8" class="fill-primary-100" />
      <circle cx="205.5" cy="126.5" r="6" class="fill-primary-100" />
      <circle cx="32.5" cy="138.5" r="10" class="fill-primary-100" />
      <circle cx="217.5" cy="38.5" r="10" class="fill-primary-100" />
      <circle cx="198.5" cy="11.5" r="7" class="fill-primary-100" />
      <g filter="url(#filter0_dd_972_104915)">
        <path
          d="M63.5783 124.714L116.03 100.255C118.663 99.0271 119.802 95.8968 118.574 93.2633L87.5988 26.836L66.6234 19.2016L23.7087 39.213C21.0753 40.441 19.9359 43.5714 21.1639 46.2048L56.5865 122.169C57.8145 124.802 60.9448 125.942 63.5783 124.714Z"
          class="fill-primary-100"
        />
        <path d="M66.623 19.2017L87.5984 26.8361L73.2935 33.5066L66.623 19.2017Z" class="fill-primary-300" />
      </g>
      <g filter="url(#filter1_dd_972_104915)">
        <path
          d="M88.6514 106.839H146.525C149.431 106.839 151.786 104.484 151.786 101.578V28.2837L136.002 12.5H88.6514C85.7457 12.5 83.3901 14.8555 83.3901 17.7612V101.578C83.3901 104.484 85.7457 106.839 88.6514 106.839Z"
          class="fill-primary-100"
        />
        <path d="M136.002 12.5L151.786 28.2837H136.002V12.5Z" class="fill-primary-300" />
      </g>
      <g filter="url(#filter2_dd_972_104915)">
        <path
          d="M118.411 100.224L170.862 124.682C173.496 125.91 176.626 124.771 177.854 122.138L208.83 55.7103L201.195 34.7349L158.281 14.7235C155.647 13.4955 152.517 14.6348 151.289 17.2683L115.866 93.2322C114.638 95.8657 115.778 98.996 118.411 100.224Z"
          class="fill-primary-100"
        />
        <path d="M201.195 34.7349L208.83 55.7102L194.525 49.0398L201.195 34.7349Z" class="fill-primary-300" />
      </g>
      <g filter="url(#filter3_b_972_104915)">
        <rect x="89.5" y="88.5" width="56" height="56" rx="28" class="fill-primary-500" fill-opacity="0.4" />
        <path
          d="M118.667 110.667L117.365 108.064C116.991 107.315 116.804 106.94 116.524 106.666C116.277 106.424 115.979 106.24 115.652 106.128C115.283 106 114.864 106 114.026 106H109.567C108.26 106 107.607 106 107.108 106.254C106.668 106.478 106.312 106.835 106.088 107.274C105.833 107.773 105.833 108.427 105.833 109.733V110.667M105.833 110.667H123.567C125.527 110.667 126.507 110.667 127.256 111.048C127.914 111.384 128.45 111.919 128.785 112.578C129.167 113.326 129.167 114.306 129.167 116.267V121.4C129.167 123.36 129.167 124.34 128.785 125.089C128.45 125.748 127.914 126.283 127.256 126.619C126.507 127 125.527 127 123.567 127H111.433C109.473 127 108.493 127 107.745 126.619C107.086 126.283 106.551 125.748 106.215 125.089C105.833 124.34 105.833 123.36 105.833 121.4V110.667ZM117.5 122.917C117.5 122.917 121 121.248 121 118.746V115.827L118.448 114.915C117.835 114.695 117.164 114.695 116.551 114.915L114 115.827V118.746C114 121.248 117.5 122.917 117.5 122.917Z"
          stroke="white"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_972_104915"
          x="-1.05957"
          y="12.5312"
          width="141.857"
          height="154.406"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_972_104915" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_972_104915" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_972_104915" />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_972_104915" result="effect2_dropShadow_972_104915" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_972_104915" result="shape" />
        </filter>
        <filter
          id="filter1_dd_972_104915"
          x="63.3901"
          y="12.5"
          width="108.396"
          height="134.339"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_972_104915" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_972_104915" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_972_104915" />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_972_104915" result="effect2_dropShadow_972_104915" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_972_104915" result="shape" />
        </filter>
        <filter
          id="filter2_dd_972_104915"
          x="93.6426"
          y="12.5"
          width="141.857"
          height="154.406"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_972_104915" />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_972_104915" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_972_104915" />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_972_104915" result="effect2_dropShadow_972_104915" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_972_104915" result="shape" />
        </filter>
        <filter
          id="filter3_b_972_104915"
          x="81.5"
          y="80.5"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_972_104915" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_972_104915" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_972_104915"
          x1="60.4968"
          y1="124.918"
          x2="18.6972"
          y2="49.5087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9D7FE" />
          <stop offset="1" stop-color="#F4EBFF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_972_104915"
          x1="85.772"
          y1="105.723"
          x2="79.7583"
          y2="19.7131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9D7FE" />
          <stop offset="1" stop-color="#F4EBFF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_972_104915"
          x1="116.273"
          y1="97.9951"
          x2="147.172"
          y2="17.5024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#E9D7FE" />
          <stop offset="1" stop-color="#F4EBFF" />
        </linearGradient>
      </defs>
    </svg>
  </div>

  <div class="flex flex-col gap-2">
    <div class="text-xl font-semibold">There are no plans for your {{ propertySetName | lowercase }} at this time.</div>
    <div class="text-gray-600">
      To find the right plan, reach out to our customer service representatives
      {{ contactEmail ? 'at ' + contactEmail : '' }} and they'll help you get covered.
    </div>
  </div>

  <div class="flex items-center justify-center gap-4">
    <a vs-button theme="primary" href="mailto:{{ contactEmail }}">Get in touch</a>
    <button *ngIf="!hideBack" vs-button theme="default" appearance="outline" (click)="goBack()">Back</button>
  </div>
</vs-card>
