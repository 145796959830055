<div class="flex sm:flex-row justify-between flex-wrap sm:flex-nowrap gap-4" [class.flex-col]="config?.mobileWrap">
  <div class="flex-[4]">
    <div class="flex flex-wrap items-center gap-2">
      <ng-content select="[slot='before-title']"></ng-content>
      <h2 class="text-lg font-semibold text-themed-900">{{ config?.title }}</h2>
      <ng-content select="[slot='after-title']"></ng-content>
    </div>

    <p *ngIf="config?.subtitle" class="text-sm text-themed-600">{{ config?.subtitle }}</p>
  </div>

  <div
    #end
    class="flex justify-center sm:flex-[0] flex-grow"
    [class.flex-1]="end.children.length"
    [class.hidden]="!end.children.length"
  >
    <ng-content select="[slot='end']"></ng-content>
  </div>
</div>
<ng-content></ng-content>
