<vs-app-page
  *ngIf="warranty$ | async as warranty"
  class="flex-grow"
  [config]="{
    title: warranty.policy?.title || '',
    description: '# ' + warranty.contractNumber || '',
    icon: 'folder-shield'
  }"
>
  <vs-breadcrumbs
    slot="breadcrumbs"
    [config]="{
      items: [{ label: 'Warranties', routerLink: '..' }, { label: warranty.contractNumber }]
    }"
  ></vs-breadcrumbs>

  <span class="inline-flex items-center justify-center h-full min-w-fit" slot="title-end" icon="folder-shield">
    <vs-badge
      [config]="{ displayStatusIcon: true }"
      [theme]="
        warranty.status === activated
          ? 'success'
          : warranty.status === cancelled
          ? 'warn'
          : warranty.status === expired
          ? 'warn'
          : 'default'
      "
    >
      {{ warranty.status | titlecase }}</vs-badge
    >
  </span>

  <ng-container
    slot="header-end"
    *ngIf="(warranty.status === 'REGISTERED' || warranty.status === 'ACTIVATED') && (canCancelWarranty$ | async)"
  >
    <button vs-button theme="danger" appearance="outline" class="ml-auto" (click)="cancelWarrantyDialog.open()">
      Cancel warranty
    </button>

    <ws-cancel-warranty-dialog
      #cancelWarrantyDialog
      [warranty]="warranty"
      (cancelled)="updateCancelled(warranty)"
    ></ws-cancel-warranty-dialog>
  </ng-container>

  <vs-input-group *ngIf="warranty.status === draft" slot="header-end">
    <button *ngIf="canFinalize$ | async" vs-button appearance="outline" (click)="registerDialog.open()">
      Register
    </button>

    <ng-container *ngIf="canUpdate$ | async">
      <button
        #trigger="cdkOverlayOrigin"
        vs-button
        cdkOverlayOrigin
        appearance="outline"
        (click)="overlayOpen = !overlayOpen; $event.stopImmediatePropagation()"
      >
        Update
      </button>

      <button vs-button appearance="outline" (click)="deleteDialog.open()">Delete</button>

      <ng-template
        #overlay="cdkConnectedOverlay"
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="overlayOpen"
        (overlayOutsideClick)="overlayOpen ? (overlayOpen = false) : ''"
      >
        <vs-card>
          <vs-date-picker
            [value]="warranty?.termStart"
            (selectionChanged)="updateTermStart(warranty, $any($event)); overlayOpen = false"
          ></vs-date-picker>
        </vs-card>
      </ng-template>
    </ng-container>
  </vs-input-group>

  <div class="flex sm:flex-col flex-wrap gap-4 w-full">
    <div class="flex gap-4 max-w-full sm:min-w-full">
      <div class="flex-1 sm:max-w-[33%] max-w-full">
        <ws-warranty-overview-card [warranty]="warranty"></ws-warranty-overview-card>
      </div>

      <div class="flex-[2] sm:max-w-[66%] max-w-full">
        <ws-warranty-recent-claims-card [warranty]="warranty"></ws-warranty-recent-claims-card>
      </div>
    </div>

    <vs-card>
      <ws-coverage-carousel [warranty]="warranty"></ws-coverage-carousel>
    </vs-card>
  </div>

  <vs-dialog #registerDialog [config]="{ dialogContainerClass: 'max-w-[500px]' }">
    <vs-dialog-header
      [config]="{
        mode: 'simple',

        icon: 'info-circle',
        iconTheme: 'primary',

        title: 'Finalize Warranty',
        subtitle:
          'Are you sure you want to finalize this warranty <strong>#' +
          warranty.contractNumber +
          '</strong>? This action cannot be undone. The customer will be notified via email and able to access the customer portal.'
      }"
    >
    </vs-dialog-header>

    <div slot="buttons" class="flex gap-2">
      <button vs-button appearance="outline" class="flex-grow" (click)="registerDialog.close()">Cancel</button>
      <button vs-button theme="primary" class="flex-grow" (click)="register(warranty); registerDialog.close()">
        Finalize
      </button>
    </div>
  </vs-dialog>

  <vs-dialog #deleteDialog [config]="{ dialogContainerClass: 'max-w-[500px]' }">
    <vs-dialog-header
      [config]="{
        mode: 'simple',

        icon: 'alert-circle',
        iconTheme: 'danger',

        title: 'Delete Warranty',
        subtitle:
          'Are you sure you want to delete this warranty <strong>#' +
          warranty.contractNumber +
          '</strong>? This action cannot be undone.'
      }"
    >
    </vs-dialog-header>

    <div slot="buttons" class="flex gap-2">
      <button vs-button appearance="outline" class="flex-grow" (click)="deleteDialog.close()">Cancel</button>
      <button vs-button theme="danger" class="flex-grow" (click)="delete(warranty); deleteDialog.close()">
        Delete
      </button>
    </div>
  </vs-dialog>
</vs-app-page>
