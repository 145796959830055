import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { BadgeColumn, IconButtonColumn, PaginationConfig, TableColumn, TextColumn } from '@vsolv/vectors/table';
import { CustomerService } from '@wsphere/customers/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { IPaginationMeta } from 'nestjs-typeorm-paginate';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  shareReplay,
  startWith,
  switchMap,
} from 'rxjs';
import { ClaimItemDetailsDialog } from '../../dialogs';
import { ClaimService } from '../../services';

export type ClaimItemTableFilters = Pick<
  Claim.ClaimItem.ListClaimItemsQueryRequest,
  'search' | 'status' | 'withDeleted'
>;

@Component({
  selector: 'ws-claim-item-table',
  templateUrl: './claim-item-table.component.html',
})
export class ClaimItemTableComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private securitySvc: SecurityService,
    private customerSvc: CustomerService
  ) {}

  @ViewChild('removeItemDialog') removeItemDialog!: DialogComponent;
  @ViewChild('createItemDialog') createItemDialog!: ClaimItemDetailsDialog;

  @ViewChild('coverageTooltip') coverageTooltip!: TemplateRef<ElementRef>;

  @Input() isCustomerPortal = false;

  _claim?: Claim.Model;
  @PropertyListener('claim') claim$ = new ReplaySubject<Claim.Model | undefined>(undefined);
  @Input() claim: Claim.Model | null = null;

  selectedItem: Claim.ClaimItem.Model | null = null;

  open = Claim.Status.OPEN;
  itemStatus = Claim.ClaimItem.Status;
  statuses = Object.keys(Claim.ClaimItem.Status);

  private refresh$ = new BehaviorSubject<null>(null);

  filters: ClaimItemTableFilters | null = null;
  private filters$ = new BehaviorSubject(this.filters);

  readonly pagination$ = new BehaviorSubject({ currentPage: 1, itemsPerPage: 10 });

  private readonly isMyClaim$ = this.claim$.pipe(
    switchMap(async claim => {
      const currentUser = await this.customerSvc.retrieveSelf();
      return claim?.warranty?.customerId === currentUser?.id;
    })
  );

  canOpenClaim$ = combineLatest([this.claim$, this.isMyClaim$]).pipe(
    switchMap(async ([claim, isMyClaim]) => {
      if (isMyClaim) return true;
      else if (this.isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_OpenClaim', permissionKey);
    })
  );

  canManageItemCure$ = combineLatest([this.claim$, this.isMyClaim$]).pipe(
    switchMap(async ([claim, isMyClaim]) => {
      if (isMyClaim) return true;
      else if (this.isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageClaimItemCure', permissionKey);
    })
  );

  canManageItemAdjudication$ = combineLatest([this.claim$, this.isMyClaim$]).pipe(
    switchMap(async ([claim, isMyClaim]) => {
      if (isMyClaim) return true;
      else if (this.isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_AdjudicateClaimItem', permissionKey);
    })
  );

  canManageItemResolution$ = combineLatest([this.claim$, this.isMyClaim$]).pipe(
    switchMap(async ([claim, isMyClaim]) => {
      if (isMyClaim) return true;
      else if (this.isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageClaimItemResolution', permissionKey);
    })
  );

  canRemoveItems$ = combineLatest([this.claim$, this.isMyClaim$]).pipe(
    switchMap(async ([claim, isMyClaim]) => {
      if (this.isCustomerPortal && isMyClaim) return true;
      else if (this.isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_RemoveClaimItem', permissionKey);
    })
  );

  paginatedData$ = combineLatest([
    this.claim$,
    this.pagination$,
    this.filters$,
    this.securitySvc.globalDistributors$,
    this.refresh$,
  ]).pipe(
    switchMap(async ([claim, pagination, filters]) => {
      if (!claim) return { items: [], meta: { currentPage: 1, itemCount: 0, itemsPerPage: 10 } };

      return await this.claimSvc.listItems(claim.id, {
        page: pagination.currentPage,
        limit: pagination.itemsPerPage,
        withDeleted: filters?.withDeleted,
        status: filters?.status,
        search: filters?.search,
      });
    }),
    shareReplay(1)
  );

  readonly items$: Observable<Claim.ClaimItem.Model[]> = this.paginatedData$.pipe(map(data => data.items));
  readonly metadata$: Observable<IPaginationMeta> = this.paginatedData$.pipe(map(data => data.meta));

  readonly paginationConfig$: Observable<Partial<PaginationConfig>> = this.paginatedData$.pipe(
    map(data => data.meta),
    startWith(this.pagination$.value)
  );

  columns$: Observable<TableColumn<unknown>[]> = combineLatest([
    this.claim$,
    this.canManageItemCure$,
    this.canManageItemAdjudication$,
    this.canManageItemResolution$,
    this.canRemoveItems$,
    this.refresh$,
  ]).pipe(
    map(([claim, canManageCure, canManageAdjudication, canManageResolution, canRemoveItems]) => {
      if (!this.isCustomerPortal) {
        return [
          new TextColumn<Claim.ClaimItem.Model>({ header: 'Item' }, item => ({
            text: item.coverage?.title ?? 'Other',
            classes: 'line-clamp-1 max-w-[300px] overflow-hidden overflow-ellipsis',
            icon: 'info-circle',
            iconTooltip: {
              template: this.coverageTooltip,
              context: {
                title: item.coverage?.title ?? 'Other',
                description: item.coverage?.description ?? item.description,
                amount: item.liabilityInfo?.remainingLiabilityLimit,
              },
            },
          })),

          new TextColumn<Claim.ClaimItem.Model>({ header: 'Cure' }, item =>
            this.isCustomerPortal || !canManageCure || item.deleted
              ? {
                  text: item.cure?.title ?? (claim?.status !== Claim.Status.OPEN ? '' : '-'),
                  tag:
                    typeof item.cure?.estimatedCost === 'number'
                      ? 'Est. $' + (item.cure.estimatedCost / 100).toFixed(2)
                      : undefined,
                  cellClick: () => this.navigateTo(item.id + '/cure'),
                }
              : {
                  text: item.cure?.title ?? (claim?.status !== Claim.Status.OPEN ? '-' : ''),
                  tag:
                    typeof item.cure?.estimatedCost === 'number'
                      ? 'Est. $' + (item.cure.estimatedCost / 100).toFixed(2)
                      : undefined,

                  buttonIcon: 'plus',
                  buttonTheme: 'primary',
                  buttonAppearance: 'outline',
                  buttonClasses: 'border-none',
                  disabled: claim?.status !== Claim.Status.OPEN,
                  buttonText: !item.cure?.title ? (claim?.status !== Claim.Status.OPEN ? '' : 'Set Cure') : '',
                  buttonClick: () => this.navigateTo(item.id + '/manage-cure'),
                  cellClick: () => (item.cure?.title ? this.navigateTo(item.id + '/cure') : undefined),
                }
          ),

          new BadgeColumn<Claim.ClaimItem.Model>({ header: 'Adjudication' }, item => {
            return !this.isCustomerPortal &&
              !item.deleted &&
              claim?.status === Claim.Status.OPEN &&
              item.status === Claim.ClaimItem.Status.DRAFT &&
              canManageAdjudication
              ? {
                  buttonIcon: 'plus',
                  buttonTheme: 'primary',
                  buttonAppearance: 'outline',
                  buttonClasses: 'border-none',
                  disabled: claim?.status !== Claim.Status.OPEN || !item.cure?.title || !canManageAdjudication,
                  buttonText:
                    item.status === Claim.ClaimItem.Status.DRAFT && claim?.status === Claim.Status.OPEN
                      ? 'Adjudicate'
                      : '',
                  buttonClick: () => this.navigateTo(item.id + '/manage-adjudication'),
                }
              : claim?.status === Claim.Status.CANCELLED || claim?.status === Claim.Status.EXPIRED
              ? { emptyState: true }
              : {
                  text: item.deleted
                    ? 'Removed'
                    : item.status === Claim.ClaimItem.Status.DRAFT
                    ? 'Reviewing'
                    : item.status.charAt(0) + item.status.slice(1).toLowerCase(),
                  theme: item.deleted ? 'info' : Claim.ClaimItem.getTheme(item.status),
                  displayStatusIcon: true,
                  click: () => (canManageAdjudication ? this.navigateTo(item.id + '/adjudicate') : undefined),
                };
          }),

          new TextColumn<Claim.ClaimItem.Model>({ header: 'Resolution' }, item =>
            item.deleted ||
            !canManageResolution ||
            this.isCustomerPortal ||
            item.status === Claim.ClaimItem.Status.REJECTED
              ? {
                  text: item.resolution?.title ?? (claim?.status !== Claim.Status.OPEN ? '' : '-'),
                  tag:
                    typeof item.resolution?.actualCost === 'number'
                      ? '$' + (item.resolution.actualCost / 100).toFixed(2)
                      : undefined,
                  cellClick: () => this.navigateTo(item.id + '/resolve'),
                }
              : {
                  text: item.resolution?.title ?? (claim?.status !== Claim.Status.OPEN ? '-' : ''),
                  tag:
                    typeof item.resolution?.actualCost === 'number'
                      ? '$' + (item.resolution.actualCost / 100).toFixed(2)
                      : undefined,
                  cellClick: () => (item.resolution?.title ? this.navigateTo(item.id + '/resolve') : undefined),

                  buttonIcon: 'plus',
                  buttonTheme: 'primary',
                  buttonAppearance: 'outline',
                  buttonClasses: 'border-none',
                  disabled:
                    claim?.status !== Claim.Status.OPEN ||
                    !item.adjudicationReason ||
                    item.status === Claim.ClaimItem.Status.DRAFT,
                  buttonText: !item.resolution?.title
                    ? claim?.status !== Claim.Status.OPEN
                      ? ''
                      : 'Set Resolution'
                    : '',
                  buttonClick: () => this.navigateTo(item.id + '/manage-resolution'),
                }
          ),

          new TextColumn<Claim.ClaimItem.Model>({ header: 'Covered Amount' }, item => {
            if (!item.amount?.goodwill || this.isCustomerPortal) {
              const total =
                item.amount?.total || item.amount?.total === 0
                  ? '<strong>$' + (item.amount.total / 100).toFixed(2) + '</strong>'
                  : '-';
              return { text: `${total}` };
            }

            const standard =
              item.amount?.standard || item.amount.standard === 0
                ? '<strong>$' + (item.amount.standard / 100).toFixed(2) + '</strong>'
                : '-';
            const goodwill = '$' + (item.amount.goodwill / 100).toFixed(2);

            return { text: `${standard} (+${goodwill} GW)` };
          }),

          ...(this.isCustomerPortal || canManageCure || canManageAdjudication || canManageResolution
            ? [
                new IconButtonColumn<Claim.ClaimItem.Model>({ stickyEnd: true, fitContent: true }, item => ({
                  type: 'clear',
                  rounded: true,
                  icon: 'edit-01',
                  disabled:
                    (this.isCustomerPortal && item.status !== Claim.ClaimItem.Status.DRAFT) ||
                    claim?.status !== Claim.Status.OPEN ||
                    !!item.deleted,
                  click: () => this.navigateTo(item.id),
                })),
              ]
            : []),

          ...(canRemoveItems
            ? [
                new IconButtonColumn<Claim.ClaimItem.Model>({ stickyEnd: true, fitContent: true }, item => ({
                  type: 'clear',
                  rounded: true,
                  icon: 'trash-01',
                  disabled:
                    claim?.status !== Claim.Status.OPEN ||
                    item.status !== Claim.ClaimItem.Status.DRAFT ||
                    !!item.deleted,
                  click: () => this.openRemoveItemDialog(item),
                })),
              ]
            : []),
        ];
      } else
        return [
          new TextColumn<Claim.ClaimItem.Model>({ header: 'Item' }, item => ({
            text: item.coverage?.title ?? 'Other',
            classes: 'line-clamp-1 max-w-[300px] overflow-hidden overflow-ellipsis',
            icon: 'info-circle',
            iconTooltip: {
              template: this.coverageTooltip,
              context: {
                title: item.coverage?.title ?? 'Other',
                description: item.coverage?.description ?? item.description,
                amount: item.liabilityInfo?.remainingLiabilityLimit,
              },
            },
          })),

          new TextColumn<Claim.ClaimItem.Model>({ header: 'Resolution' }, item =>
            item.deleted ||
            !canManageResolution ||
            this.isCustomerPortal ||
            item.status === Claim.ClaimItem.Status.REJECTED
              ? {
                  text: item.resolution?.title ?? (claim?.status !== Claim.Status.OPEN ? '' : '-'),
                  tag:
                    typeof item.resolution?.actualCost === 'number'
                      ? '$' + (item.resolution.actualCost / 100).toFixed(2)
                      : undefined,
                  cellClick: () => this.navigateTo(item.id + '/resolve'),
                }
              : {
                  text: item.resolution?.title ?? (claim?.status !== Claim.Status.OPEN ? '-' : ''),
                  tag:
                    typeof item.resolution?.actualCost === 'number'
                      ? '$' + (item.resolution.actualCost / 100).toFixed(2)
                      : undefined,
                  cellClick: () => (item.resolution?.title ? this.navigateTo(item.id + '/resolve') : undefined),

                  buttonIcon: 'plus',
                  buttonTheme: 'primary',
                  buttonAppearance: 'outline',
                  buttonClasses: 'border-none',
                  disabled:
                    claim?.status !== Claim.Status.OPEN ||
                    !item.adjudicationReason ||
                    item.status === Claim.ClaimItem.Status.DRAFT,
                  buttonText: !item.resolution?.title
                    ? claim?.status !== Claim.Status.OPEN
                      ? ''
                      : 'Set Resolution'
                    : '',
                  buttonClick: () => this.navigateTo(item.id + '/manage-resolution'),
                }
          ),

          new TextColumn<Claim.ClaimItem.Model>({ header: 'Covered Amount' }, item => {
            if (!item.amount?.goodwill || this.isCustomerPortal) {
              const total =
                item.amount?.total || item.amount?.total === 0
                  ? '<strong>$' + (item.amount.total / 100).toFixed(2) + '</strong>'
                  : '-';
              return { text: `${total}` };
            }

            const standard =
              item.amount?.standard || item.amount.standard === 0
                ? '<strong>$' + (item.amount.standard / 100).toFixed(2) + '</strong>'
                : '-';
            const goodwill = '$' + (item.amount.goodwill / 100).toFixed(2);

            return { text: `${standard} (+${goodwill} GW)` };
          }),

          ...(this.isCustomerPortal || canManageCure || canManageAdjudication || canManageResolution
            ? [
                new IconButtonColumn<Claim.ClaimItem.Model>({ stickyEnd: true, fitContent: true }, item => ({
                  type: 'clear',
                  rounded: true,
                  icon: 'edit-01',
                  disabled:
                    (this.isCustomerPortal && item.status !== Claim.ClaimItem.Status.DRAFT) ||
                    claim?.status !== Claim.Status.OPEN ||
                    !!item.deleted,
                  click: () => this.navigateTo(item.id),
                })),
              ]
            : []),

          ...(canRemoveItems
            ? [
                new IconButtonColumn<Claim.ClaimItem.Model>({ stickyEnd: true, fitContent: true }, item => ({
                  type: 'clear',
                  rounded: true,
                  icon: 'trash-01',
                  disabled:
                    claim?.status !== Claim.Status.OPEN ||
                    item.status !== Claim.ClaimItem.Status.DRAFT ||
                    !!item.deleted,
                  click: () => this.openRemoveItemDialog(item),
                })),
              ]
            : []),
        ];
    })
  );

  openCreateItemDialog() {
    this.createItemDialog.openDialog();
  }

  openRemoveItemDialog(item: Claim.ClaimItem.Model) {
    this.selectedItem = item;
    this.removeItemDialog?.open();
  }

  removeDialogClosed() {
    this.selectedItem = null;
    this.refreshTable();
  }

  async refreshTable(filters?: { search?: string; status?: Claim.ClaimItem.Status; withDeleted?: boolean }) {
    if (filters?.search !== undefined) this.filters = { ...this.filters, search: filters.search };
    if (filters?.status !== undefined) this.filters = { ...this.filters, status: filters.status };
    if (filters?.withDeleted !== undefined) this.filters = { ...this.filters, withDeleted: filters.withDeleted };
    this.filters$.next(this.filters);
  }

  navigateTo(path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
