import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FeaturedIconComponent, IconComponent, IconEmptyStateComponent, IconPickerComponent } from './components';

export * from './components';
export * from './icon-name';
export * from './icon-style';

@NgModule({
  imports: [CommonModule, HttpClientModule, AngularSvgIconModule.forRoot(), TooltipModule],
  exports: [IconComponent, FeaturedIconComponent, IconPickerComponent, IconEmptyStateComponent],
  declarations: [IconComponent, FeaturedIconComponent, IconPickerComponent, IconEmptyStateComponent],
})
export class IconsModule {}
