<div class="flex justify-center w-full">
  <div class="flex flex-col gap-4 relative min-h-[168px] w-full pt-4">
    <img
      class="min-w-[480px] max-w-[480px] absolute top-0 left-[50%] translate-x-[-50%] z-0"
      [src]="config?.full ? 'assets/empty-state-full.svg' : 'assets/empty-state.svg'"
    />

    <vs-icon
      class="text-gray-700 self-center h-fit w-fit shadow-sm z-10"
      [name]="name || 'info-circle'"
      [size]="config?.full ? 36 : 24"
      [class]="
        config?.full
          ? 'mt-[176px] p-[30px] bg-gradient-to-b from-gray-100 to-gray-300 rounded-full '
          : 'p-3 border rounded-lg'
      "
    ></vs-icon>

    <div *ngIf="config?.title" class="flex flex-col self-center w-full lg:max-w-[50%] max-w-[352px] z-10">
      <p *ngIf="config?.title" class="font-semibold text-gray-900 text-center">{{ config?.title }}</p>
      <p *ngIf="config?.subtitle" class="text-sm font-normal text-gray-600 text-center">{{ config?.subtitle }}</p>
    </div>

    <div class="flex justify-center w-full z-10" [class.mb-16]="config?.full">
      <ng-content select="[slot='button']"></ng-content>
    </div>
  </div>
</div>
