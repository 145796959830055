/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Note } from '@vsolv/packages/notes/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-note-dialog',
  templateUrl: './claim-note.dialog.html',
})
export class ClaimNoteDialog {
  constructor(
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private linkSvc: LinksWebService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('dialog') dialog?: DialogComponent;
  @ViewChild('deleteDialog') deleteDialog?: DialogComponent;

  @PropertyListener('note') note$ = new BehaviorSubject<Note.Model | null>(null);
  @Input() note: Note.Model | null = null;

  @PropertyListener('claim') claim$ = this.claimSvc.getClaim();
  @Input() claim: Claim.Model | null = null;

  @Output() closed = new EventEmitter();

  open = Claim.Status.OPEN;
  links: Link.Model[] | null = null;
  originalLinks: Link.FormLink[] | null = null;

  canRemoveNotes$ = this.claim$.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_RemoveNote', permissionKey);
    })
  );

  refresh$ = new BehaviorSubject(null);

  links$ = combineLatest([this.claim$, this.note$, this.refresh$]).pipe(
    switchMap(async ([claim, note]) =>
      note && claim
        ? await this.linkSvc.list(
            { id: claim.id, objectType: Link.ObjectType.CLAIM },
            { owner: { id: note.id, objectType: Link.ObjectType.NOTE } }
          )
        : null
    ),
    map(data => data?.items),
    tap(links => (this.links = links ?? null))
  );

  noteUpdate$ = combineLatest([this.note$, this.links$]).pipe(
    tap(([note, links]) => {
      if (note) {
        if (links?.length) {
          const originalLinks = Link.getPickerLinks(links, Link.ObjectType.NOTE, note.id);
          if (originalLinks?.length) {
            this.originalLinks = originalLinks;

            this.form.patchValue({
              links: originalLinks.map(link => ({ id: link.id, object: link.object, type: link.type })),
            });
          }
        }

        this.form.patchValue({
          title: note.title,
          content: note.content,
          visibleToCustomer: note.feed.includes('customer'),
        });
      } else this.form.reset();
    })
  );

  form = this.formBuilder.group({
    title: ['', Validators.required],
    content: ['', Validators.required],
    links: [[] as Link.FormLink[]],
    visibleToCustomer: [false],
  });

  openDialog() {
    this.deleteDialog?.close();
    this.dialog?.open();
  }

  closeDialog() {
    this.closed.emit();
    this.form.reset();
    this.dialog?.close();
    this.originalLinks = null;
    this.refresh$.next(null);
  }

  refreshActivity() {
    this.linkSvc.refreshActivity$.next(null);
  }

  openDeleteDialog() {
    this.dialog?.close();
    this.deleteDialog?.open();
  }

  closeDeleteDialog() {
    this.closeDialog();
    this.deleteDialog?.close();
  }

  async save() {
    const value = this.form.value;

    if (!this.claim || !value.content) return;

    if (!this.note) {
      const response = await this.claimSvc
        .createNote(this.claim.id, {
          title: value.title !== '' ? value.title : null,
          content: value.content,
          feed: value.visibleToCustomer ? ['customer', 'staff'] : ['staff'],
        })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });

          this.closeDialog();
        });

      if (response?.id) {
        await this.linkSvc.create(
          { id: this.claim.id, objectType: Link.ObjectType.CLAIM },
          {
            object1: { id: this.claim.id, objectType: Link.ObjectType.CLAIM },
            object2: { id: response.id, objectType: Link.ObjectType.NOTE },
          }
        );

        this.originalLinks = null;
        this.updateLinks(response.id);

        this.toastSvc.show({
          type: 'success',
          title: 'Note Added',
          text: `<strong>${value.title || 'The note'}</strong>` + ' has been successfully added.',
        });

        this.closeDialog();
      }
    } else {
      const response = await this.claimSvc
        .updateNote(this.claim.id, this.note.id, {
          title: value.title !== '' ? value.title : null,
          content: value.content,
          feed: value.visibleToCustomer ? ['customer', 'staff'] : ['staff'],
        })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });

          this.closeDialog();
        });

      if (response?.id) {
        this.updateLinks(response.id);

        this.toastSvc.show({
          type: 'success',
          title: 'Note Updated',
          text: `<strong>${value.title ?? 'The note'}</strong>` + ' has been successfully updated.',
        });

        this.closeDialog();
      }
    }

    this.closeDialog();
  }

  async remove() {
    if (this.note && this.claim) {
      const response = await this.claimSvc.deleteNote(this.claim.id, this.note.id).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.closeDeleteDialog();
      });

      if (response?.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Note Deleted',
          text: `<strong>${this.note.title ?? 'The note'}</strong>` + ' has been successfully deleted from this claim.',
        });

        this.closeDeleteDialog();
      }
    }
  }

  async updateLinks(noteId: string) {
    if (!this.claim) return;

    const links = this.form.value.links;

    // Add new links
    if (links?.length) {
      const originalLinkIds = this.originalLinks?.map(link => link.id);

      await Promise.all(
        links.map(async link => {
          if (!originalLinkIds?.includes(link.id)) {
            await this.linkSvc.create(
              { id: this.claim!.id, objectType: Link.ObjectType.CLAIM },
              {
                object1: { id: (link.object as any).id, objectType: link.type },
                object2: { id: noteId, objectType: Link.ObjectType.NOTE },
              }
            );
          }
        })
      );
    }

    // Remove links
    if (this.originalLinks?.length) {
      const newLinks = links?.map(link => (link.object as any)?.id);

      await Promise.all(
        this.originalLinks?.map(async link => {
          if (link.id && !newLinks?.includes((link.object as any)?.id)) {
            await this.linkSvc.delete({ id: this.claim!.id, objectType: Link.ObjectType.CLAIM }, link.id);
          }
        })
      );
    }
  }
}
