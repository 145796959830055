<div *vsLet="selectedAddons$ | async as selectedAddons" class="flex flex-col">
  <ng-container *vsLet="addonPrices$ | async as addonPrices">
    <ng-container *ngFor="let addon of addons?.addons; let index = index; let first = first; let last = last">
      <ng-container
        *vsLet="
          addonPrices
            ? addonPrices[addon.addonId]
            : { price: 0, hasOneTime: true, installments: [], duration: 0 } as prices
        "
      >
        <!-- price template -->
        <ng-template #addonPrice>
          <ng-container *ngIf="prices.price">
            <div
              [vsTooltip]="
                (prices.hasOneTime && prices.installments.length) || prices.installments.length > 2
                  ? { template: pricesTooltip }
                  : undefined
              "
              tooltipStyle="light"
              tooltipPosition="top"
            ></div>

            <!--extra price tooltip -->
            <ng-template #pricesTooltip>
              <div>
                <p *ngIf="prices.hasOneTime">
                  <span>
                    {{ prices.price / 100 | currency : 'USD' : 'symbol' : (prices.price % 100 === 0 ? '1.0' : '1.2') }}
                  </span>
                  <span>/one-time payment </span>
                </p>
                <p *ngFor="let installment of prices.installments">
                  <span>
                    {{
                      installment.price / 100
                        | currency : 'USD' : 'symbol' : (installment.price % 100 === 0 ? '1.0' : '1.2')
                    }}
                  </span>
                  <span>/{{ installment.count | installment : prices.duration }} </span>
                </p>
              </div>
            </ng-template>
          </ng-container>
        </ng-template>

        <ng-container *vsLet="addon.addonId | isSelected : selectedAddons as selected">
          <ng-container *ngIf="addon.addonId.startsWith('cov'); else group">
            <div
              class="max-w-[600px] flex justify-between w-full items-center p-4 border rounded-xl bg-base-white gap-2"
              [class.border-primary-500]="selected"
              [class.mb-4]="!last"
            >
              <div class="flex items-center gap-2 overflow-hidden">
                <input
                  type="checkbox"
                  vsInput
                  #checkbox
                  [checked]="selected"
                  (change)="checkbox.checked ? selectAddon(addon.addon.id) : deselectAddon(addon.addon.id)"
                />

                <button (click)="CoverageDialog.open()" class="flex items-center gap-1 truncate">
                  <span class="truncate">{{ addon.addon.title }}</span>
                </button>

                <!-- price -->
                <ng-container [ngTemplateOutlet]="addonPrice"></ng-container>
              </div>

              <span
                *ngIf="prices.hasOneTime || !prices.installments.length ? prices.price : 0 as price"
                class="whitespace-nowrap"
              >
                +
                {{ (price || 0) / 100 | currency : 'USD' : 'symbol' : ((price || 0) % 100 === 0 ? '1.0' : '1.2') }}
              </span>
            </div>

            <ng-template #AddonAggLimit>
              <ng-container
                [ngTemplateOutlet]="AggLimit"
                [ngTemplateOutletContext]="{ coverage: addon.coverages[0] }"
              ></ng-container>
            </ng-template>

            <ws-preview-group-addons
              #CoverageDialog
              [addon]="addon"
              [checked]="selected ?? false"
              (selected)="$event ? selectAddon(addon.addonId) : deselectAddon(addon.addon.id)"
            ></ws-preview-group-addons>
          </ng-container>

          <ng-template #group>
            <div class="p-4 border rounded-xl bg-base-white" [class.border-primary-500]="selected" [class.mb-4]="!last">
              <vs-accordion [open]="true" #addonGroupAccordion>
                <ws-coverage-accordion-header
                  slot="header"
                  [header]="addon.addon.title"
                  [minimize]="true"
                  [checkbox]="true"
                  [select]="!!selected"
                  [open]="addonGroupAccordion.open"
                  (toggled)="addonGroupAccordion.toggle()"
                  (selected)="$event ? selectAddon(addon.addon.id) : deselectAddon(addon.addon.id)"
                >
                  <div slot="post-title">
                    <ng-container [ngTemplateOutlet]="addonPrice"></ng-container>
                  </div>
                  <vs-icon
                    slot="header-end"
                    [vsTooltip]="
                      'To view the limits of liability for ' +
                      addon.addon.title +
                      ', expand this bundle to view the individual coverages'
                    "
                    tooltipMaxWidth="xs"
                    tooltipStyle="light"
                    name="minus"
                  ></vs-icon>
                </ws-coverage-accordion-header>

                <!-- Coverages -->
                <div slot="content" class="flex flex-col gap-2 py-2">
                  <ng-container *ngFor="let coverage of addon.coverages">
                    <div *vsLet="coverage.price as price" class="flex justify-between">
                      <vs-icon
                        vs-icon-button
                        class="mt-1"
                        name="info-circle"
                        (click)="CoverageDialog.open()"
                        [vsTooltip]="'View additional addon information'"
                      ></vs-icon>
                      <span class="truncate overflow-hidden w-[220px] mr-[11rem]">{{ coverage.coverage?.title }} </span>
                      <span
                        [vsTooltip]="
                          coverage.liabilityGroupObjects?.length ? { template: GroupAddonAggLimit } : undefined
                        "
                        tooltipStyle="light"
                        tooltipMaxWidth="md"
                      >
                        +
                        {{
                          (price || 0) / 100 | currency : 'USD' : 'symbol' : ((price || 0) % 100 === 0 ? '1.0' : '1.2')
                        }}
                      </span>
                    </div>
                    <ng-template #GroupAddonAggLimit>
                      <ng-container [ngTemplateOutlet]="AggLimit" [ngTemplateOutletContext]="{coverage}"></ng-container>
                    </ng-template>
                  </ng-container>
                </div>
              </vs-accordion>
            </div>
            <ws-preview-group-addons
              #CoverageDialog
              [addon]="addon"
              [selectedAddon]="index"
              [checked]="selected ?? false"
              (selected)="$event ? selectAddon(addon.addonId) : deselectAddon(addon.addon.id)"
            ></ws-preview-group-addons>
          </ng-template>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #AggLimit let-coverage="coverage">
  <div class="flex flex-col gap-2 pb-2">
    <div class="text-xs">Aggregate limits for {{ coverage.coverage?.title }}</div>
    <div class="flex flex-col min-w-[200px] outline outline-gray-200 rounded-lg">
      <div
        class="flex justify-between py-3 px-6 text-left text-xs font-medium text-gray-600 bg-base border-b border-gray-200 whitespace-nowrap"
      >
        <div>Title</div>
        <div>Limit</div>
      </div>
      <div class="flex flex-col">
        <ng-container *ngFor="let group of coverage.liabilityGroupObjects; let index = index">
          <div
            class="flex justify-between bg-base py-2 px-6 border-b border-gray-200"
            [class.bg-gray-50]="index % 2 === 1"
          >
            <div>{{ group.title }}</div>
            <div>{{ group.limit / 100 | currency }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
