import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ws-coverage-accordion-header',
  templateUrl: './coverage-accordion-header.component.html',
})
export class CoverageAccordionHeaderComponent {
  @Input() open = false;
  @Input() minimize = false;
  @Input() select = false;
  @Input() checkbox = false;
  @Input() header = 'Coverage';

  @Output() selected = new EventEmitter<boolean>();
  @Output() toggled = new EventEmitter<boolean>();

  protected toggle() {
    this.open = !this.open;
    this.toggled.emit(this.open);
  }
}
