import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewModule } from '@vsolv/vectors-ui/image-preview';
import { InputModule } from '@vsolv/vectors-ui/input';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { UserNotificationSettingsComponent } from './components';
import { UserIdInterceptor } from './interceptors';
import { OneTimeTokenService, UserService, UserStorageService } from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BadgeModule,
    ThemingModule,
    DividerModule,
    IconsModule,
    InputModule,
    UserAvatarModule,
    ImagePreviewModule,
    UtilsModule,
    ButtonModule,
    TooltipModule,
  ],
  providers: [UserService, UserStorageService, OneTimeTokenService],
  declarations: [UserNotificationSettingsComponent],
  exports: [UserNotificationSettingsComponent],
})
export class UsersWebModule {
  static forRoot() {
    return {
      ngModule: UsersWebModule,
      providers: [{ provide: HTTP_INTERCEPTORS, useClass: UserIdInterceptor, multi: true }],
    };
  }
}
