import { Component } from '@angular/core';

export enum Type {
  INTERCOM = 'INTERCOM',
  TYPEFORM = 'TYPEFORM',
}
@Component({ selector: 'ws-web-apps-page', templateUrl: './apps.page.html' })
export class AppsPage {
  emailEnabled = false;
  intercomEnabled = false;
  chatBotEnabled = false;
  chatBotType = Type;
  sftpEnabled = false;
  authorizeNetEnabled = false;
}
