import { Fee } from './fee';

export const FeesAndGroups = [
  {
    title: 'Premium Related Fees',
    fees: [
      Fee.PREMIUM_TAX,
      // Fee.COMMISSION,
      // Fee.UNDERWRITING_FEE,
      // Fee.RISK_MANAGEMENT_FEE,
      // Fee.REINSURANCE_FEE,
      // Fee.SURPLUS_LINE_TAX,
      Fee.INSURER_PREMIUM,
    ],
  },
  {
    title: 'Administrative and Compliance Fees',
    fees: [
      // Fee.ADMINISTRATIVE_RESERVE,
      // Fee.ADMINISTRATOR_FEE,
      Fee.OBLIGOR_FEE,
      Fee.CLIP_FEE,
      // Fee.PLATFORM_FEE,
      // Fee.TECHNOLOGY_FEE,
      // Fee.POLICY_INSURANCE_FEE,
      // Fee.DOCUMENTATION_FEE,
    ],
  },
  // {
  //   title: 'Regulatory and Compliance Fees',
  //   fees: [Fee.REGULATORY_COMPLIANCE_FEE, Fee.REGULATORY_SURCHARGE],
  // },
  // {
  //   title: 'Risk and Capital Management Fees',
  //   fees: [Fee.RESERVE, Fee.CAPITAL_ALLOCATION_FEE, Fee.ACTUARIAL_FEE],
  // },
  // {
  //   title: 'Claims-Related Fees',
  //   fees: [Fee.CLAIMS_PROCESSING_FEE, Fee.LOSS_ADJUSTMENT_EXPENSE],
  // },
  // {
  //   title: 'Service and Maintenance Fees',
  //   fees: [Fee.SERVICE_NETWORK_FEE, Fee.CUSTOMER_SERVICE_FEE, Fee.AUDIT_FEE],
  // },
  // {
  //   title: 'Sales and Marketing Fees',
  //   fees: [Fee.MARKETING_FEE, Fee.RENEWAL_AND_TRANSACTION_FEES, Fee.RENEWAL_AND_PROCESSING_FEE, Fee.TRANSACTION_FEE],
  // },
  // {
  //   title: 'Misc. Fees',
  //   fees: [Fee.OTHER_FEE],
  // },
];
