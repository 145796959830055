import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { StepperStepConfig } from '@vsolv/vectors-ui/stepper';
import { Provisioning } from '@wsphere/warranties/domain';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { OrderSummaryComponent } from '../../components';
import { ProvisioningSessionService } from '../../services';

export enum CheckoutSteps {
  Distributor = 1,
  Customer = 2,
  Policy = 3,
  Asset = 4,
  Plan = 5,
  Addon = 6,
  Checkout = 7,
}

@Component({
  templateUrl: './provision.page.html',
})
export class ProvisionPage implements OnInit, OnDestroy {
  @ViewChild(DialogComponent) dialog?: DialogComponent;
  @ViewChild(OrderSummaryComponent) summary?: OrderSummaryComponent;
  @ViewChild('scrollElement') private scrollElement?: ElementRef<HTMLElement>;

  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionSvc: ProvisioningSessionService,
    private toastSvc: ToastService,
    private breakpointObserver: BreakpointObserver
  ) {
    router.events.pipe(takeUntil(this.destroy$)).subscribe(() => this.setPage());
    this.step$
      .pipe(takeUntil(this.destroy$), distinctUntilChanged())
      .subscribe(() => this.scrollElement?.nativeElement.scrollTo(0, 0));
  }

  protected checkoutSteps = CheckoutSteps;

  protected sessionComplete$ = this.sessionSvc.sessionComplete$.asObservable();
  protected distributor$ = this.sessionSvc.distributor$.asObservable();
  protected session$ = this.sessionSvc.getCurrentSession();
  protected addons$ = this.sessionSvc.addons$.asObservable();
  protected close$ = this.sessionSvc.close$.asObservable().pipe(tap(() => this.dialog?.close()));

  protected step$ = new BehaviorSubject<CheckoutSteps>(CheckoutSteps.Distributor);

  private steps: StepperStepConfig[] = [
    {
      title: 'Distributor',
      routerLink: '',
      allowJumpToStep: true,
    },
    {
      title: 'Customer',
      routerLink: 'customer',
      allowJumpToStep: true,
    },
    {
      title: 'Policy',
      routerLink: 'policy',
    },
    {
      title: 'Asset',
      routerLink: 'asset',
    },
    {
      title: 'Plan',
      routerLink: 'plan',
    },
    {
      title: 'Addon',
      hideStep: true,
      routerLink: 'addon',
    },
    {
      title: 'Checkout',
      routerLink: 'checkout',
    },
  ];

  protected steps$: Observable<StepperStepConfig[]> = combineLatest([this.step$, this.session$, this.addons$]).pipe(
    map(([step, session, addons]) => {
      if (![CheckoutSteps.Distributor, CheckoutSteps.Customer].includes(step))
        this.steps[step - 1].allowJumpToStep = true;
      if (step === CheckoutSteps.Distributor) {
        this.steps = this.steps.map((step, index) =>
          ![CheckoutSteps.Distributor, CheckoutSteps.Customer].includes(index + 1)
            ? {
                ...step,
                allowJumpToStep: false,
              }
            : step
        );
      }
      const hasAddons = !!addons?.find(addon => addon.planId === session?.planId)?.addons.length;
      this.steps[CheckoutSteps.Addon - 1].hideStep = !hasAddons;
      return this.steps;
    })
  );

  protected loadingPreview$ = new BehaviorSubject<boolean>(false);

  protected preview$ = this.session$.pipe(
    switchMap(async session => {
      if (session && session.plan && session.policyId !== session.plan.policyId) return null;
      if (!session || !session.plan) {
        if (session && session.plan && this.step$.value > CheckoutSteps.Checkout) {
          this.step$.next(CheckoutSteps.Checkout);
          this.sessionSvc.refreshSession(session);
        } else return null;
      }
      this.loadingPreview$.next(true);
      return await this.sessionSvc.preview(session.id, session.hash);
    }),
    tap(preview => this.sessionSvc.preview$.next(preview)),
    tap(() => this.loadingPreview$.next(false))
  );

  protected coverages$ = this.preview$.pipe(
    map(preview => (preview ? preview.warranty.coverages.filter(coverage => coverage.requirement === 'BASE') : null))
  );

  protected selectedAddons$ = this.preview$.pipe(
    map(preview => (preview ? preview.addons : null)),
    map(addons => (addons ? addons.sort((a, b) => a.addon.id.localeCompare(b.addon.id)) : null))
  );

  protected isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  protected navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], { relativeTo: this.route });
  }

  protected async updatePreview(preview: { sessionId: string; data: Provisioning.UpdateProvisioningSessionDto }) {
    try {
      const session = await this.sessionSvc.update(preview.sessionId, preview.data);
      this.sessionSvc.refreshSession(session);
    } catch (err: unknown) {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong!',
      });
      console.error(err);
      throw new Error();
    }
  }

  ngOnInit(): void {
    this.sessionSvc.clearSession();
    this.sessionSvc.distributor$.next(null);
    this.sessionSvc.addons$.next(null);
    this.sessionSvc.preview$.next(null);
  }

  setPage() {
    switch (this.route.snapshot.children?.[0].routeConfig?.path) {
      case '':
        this.step$.next(CheckoutSteps.Distributor);
        break;
      case 'customer':
        this.step$.next(CheckoutSteps.Customer);
        break;
      case 'policy':
        this.step$.next(CheckoutSteps.Policy);
        break;
      case 'asset':
        this.step$.next(CheckoutSteps.Asset);
        break;
      case 'plan':
        this.step$.next(CheckoutSteps.Plan);
        break;
      case 'addon':
        this.step$.next(CheckoutSteps.Addon);
        break;
      case 'checkout':
        this.step$.next(CheckoutSteps.Checkout);
        break;
      default:
        this.step$.next(CheckoutSteps.Distributor);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
