import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantService } from '@vsolv/core/multi-tenant/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { SftpKeyManagementComponent, SftpService } from '@wsphere/sftp/web';
import { SecurityService } from '@wsphere/staff/web';
import isEqual from 'lodash.isequal';
import { switchMap, tap } from 'rxjs';

@Component({ selector: 'ws-sftp-dialog', templateUrl: './sftp-dialog.html' })
export class SftpDialog implements OnInit {
  constructor(
    private sftpSvc: SftpService,
    private toastSvc: ToastService,
    private tenantSvc: TenantService,
    private securitySvc: SecurityService
  ) {}

  config: { tenantEmail: string | null; distributorEnabled: boolean; tenantEnabled: boolean } = {
    tenantEmail: '',
    distributorEnabled: false,
    tenantEnabled: false,
  };

  canManageSFTP$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('sft_ManageSFTPApp', permissionKeys);
    }),
    tap(canManage => (!canManage ? this.form.disable() : this.form.enable()))
  );

  @Output() enabledChanged = new EventEmitter<boolean>();

  @ViewChild('deleteDialog') deleteDialog!: DialogComponent;
  @ViewChild('rerollDialog') rerollDialog!: DialogComponent;
  @ViewChild('keyManager') keyManager!: SftpKeyManagementComponent;

  updating = false;

  form = new FormGroup({
    email: new FormControl({ value: '', disabled: false }, [Validators.email, Validators.required]),
    distEnabled: new FormControl({ value: false, disabled: false }),
  });

  async ngOnInit(): Promise<void> {
    await this.reset();
  }

  async reset() {
    const config = await this.sftpSvc.getSettings();
    if (config) {
      this.form.patchValue({
        email: config.tenantEmail,
        distEnabled: config.distributorEnabled,
      });
      this.config = config;
      this.enabledChanged.emit(config.tenantEnabled);
    }
  }

  disableUpdate() {
    const val = isEqual(this.form.value, {
      distEnabled: this.config.distributorEnabled,
      email: this.config.tenantEmail,
    });
    return val || this.form.invalid;
  }

  async enable() {
    this.updating = true;
    await this.sftpSvc.updateSettings({ tenantEnabled: true, tenantEmail: this.form.value.email }).then(async val => {
      this.config = val;
      try {
        const tenant = await this.tenantSvc.getTenantIdFromDomain();
        if (!tenant) throw new Error('Tenant not found');
        let key = null;
        const credentials = await this.sftpSvc.getCredentials(tenant);
        if (credentials) {
          key = await this.sftpSvc.rollCredentials(credentials.id, tenant);
        } else {
          key = await this.sftpSvc.createCredentials(tenant);
        }
        if (!key) throw new Error('Key not created');
        this.keyManager.copyKeyDialog.openDialog(key.key);
      } catch (e) {
        console.warn(`Cannot auto create SFTP credentials: ${e}`);
      }
      this.enabledChanged.emit(true);
      this.form.reset({ email: this.config.tenantEmail, distEnabled: this.config.distributorEnabled });
      this.updating = false;
    });
  }

  async disable() {
    this.updating = true;
    await this.sftpSvc
      .updateSettings({
        tenantEnabled: false,
        tenantEmail: '',
        distributorEnabled: false,
      })
      .then(val => {
        this.config = val;
        this.form.reset();
        this.updating = false;
        this.enabledChanged.emit(false);
        this.deleteDialog.close();
      });
  }

  async updateConfig() {
    this.updating = true;
    await this.sftpSvc
      .updateSettings({
        distributorEnabled: this.form.controls.distEnabled.value ?? this.config.distributorEnabled,
        tenantEmail: this.form.controls.email.value ?? this.config.tenantEmail,
      })
      .then(val => {
        this.config = val;
        this.updating = false;
        this.toastSvc.show({ type: 'success', title: 'SFTP Config Updated' });
      });
  }
}
