<ng-container *vsLet="staffView$ | async as staffView">
  <ng-container *vsLet="isLargeScreen$ | async as isLargeScreen">
    <ng-container *vsLet="session$ | async as session">
      <div class="relative flex flex-col items-center gap-4 mb-2">
        <div class="flex gap-2 w-full items-center">
          <div class="w-12 h-12">
            <button
              *ngIf="!isLargeScreen"
              vs-icon-button
              size="lg"
              theme="default"
              appearance="clear"
              (click)="previousPage(staffView ?? false)"
            >
              <vs-icon name="chevron-left" size="20"></vs-icon>
            </button>
          </div>

          <div class="flex justify-center items-center flex-grow" name="icon">
            <vs-featured-icon name="file-shield-02" theme="primary"></vs-featured-icon>
          </div>

          <div class="w-12 h-12">
            <button
              vs-icon-button
              appearance="clear"
              theme="default"
              size="lg"
              [disabled]="!stepCompleted"
              *ngIf="!isLargeScreen"
              (click)="nextPage(session, staffView ?? false)"
            >
              <vs-icon name="chevron-right" size="20"></vs-icon>
            </button>
          </div>
        </div>

        <div name="title" class="text-3xl font-semibold leading-[38px] text-center">Select a policy</div>
        <div name="subtitle" class="text-sm text-gray-600 mb-8">
          {{
            staffView
              ? "Please select the policy that best suits this customer's needs."
              : 'Please select the policy that best suits your needs.'
          }}
        </div>
      </div>

      <ws-policy-picker [session]="session" [formControl]="policy" [staffView]="staffView ?? false"></ws-policy-picker>

      <div class="flex gap-3 mt-4" [class.flex-col]="!isLargeScreen">
        <button
          vs-button
          theme="primary"
          [class]="!isLargeScreen ? 'min-w-full' : ''"
          [disabled]="!stepCompleted || !session"
          (click)="nextPage(session, staffView ?? false)"
        >
          <div class="flex gap-3 items-center">
            <span>Continue</span>
            <vs-icon *ngIf="stepCompleted" name="check"></vs-icon>
          </div>
        </button>

        <button
          [class]="!isLargeScreen ? 'min-w-full' : ''"
          vs-button
          (click)="previousPage(staffView ?? false)"
          appearance="outline"
        >
          Back
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
