/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
import { CdkMenuTrigger } from '@angular/cdk/menu';
import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[vsMenuTriggerFor]',
  exportAs: 'vsMenuTriggerFor',
  hostDirectives: [
    {
      directive: CdkMenuTrigger,
      inputs: ['cdkMenuTriggerFor: vsMenuTriggerFor', 'cdkMenuPosition: vsMenuPosition'],
    },
  ],
})
export class MenuTriggerForDirective {
  @Input() vsMenuTriggerFor?: TemplateRef<unknown>;

  constructor(private cdkMenuTrigger: CdkMenuTrigger) {}

  open() {
    this.cdkMenuTrigger.open();
  }

  close() {
    this.cdkMenuTrigger.close();
  }
}
