import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { ClaimItemResolver } from '../resolver';
import { ClaimService } from '../services';

@Injectable()
export class ClaimItemGuard implements CanActivate {
  constructor(private resolver: ClaimItemResolver, private claimSvc: ClaimService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.item = null;

    const claimId = this.findClaimId(route);
    if (!claimId) return false;

    const itemId = this.findItemId(route);
    if (!itemId) return false;

    this.resolver.item = await this.claimSvc.getItem(claimId, itemId);

    if (!this.resolver.item) return false;

    return true;
  }

  private findClaimId(route: ActivatedRouteSnapshot): string {
    return route.params['claimId'] || (route.parent ? this.findClaimId(route.parent) : null);
  }

  private findItemId(route: ActivatedRouteSnapshot): string {
    return route.params['itemId'] || (route.parent ? this.findItemId(route.parent) : null);
  }
}
