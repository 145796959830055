/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@vsolv/packages/portal-config/domain';
import { map } from 'rxjs';

@Component({ templateUrl: './expired-distributor-link.page.html' })
export class ExpiredDistributorLinkPage {
  constructor(private route: ActivatedRoute, private breakpointObserver: BreakpointObserver) {}

  steps: any[] = [{}, {}, {}, {}];
  menuBtnHover = false;

  readonly config$ = this.route.data.pipe(map(data => data['config'] as Config.PortalConfig));

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));
}
