/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, Observable, ReplaySubject, map, switchMap } from 'rxjs';
import { PolicyService } from '../../../policy';

@Component({
  selector: 'ws-policy-filter-picker',
  templateUrl: './policy-filter-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PolicyFilterPickerComponent,
    },
  ],
})
export class PolicyFilterPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef, private policySvc: PolicyService) {}

  @PropertyListener('value') value$ = new ReplaySubject<Policy.Model>(1);
  @Input() value: Policy.Model[] | null = null;

  @Input() placeholder = 'All policies...';
  @Input() required = false;
  @Input() disabled = false;

  @Output() valueChanges = new EventEmitter<Policy.Model[] | null>();

  touched = false;
  overlayOpen = false;

  readonly searchQuery$ = new BehaviorSubject<string>('');

  policies$: Observable<Policy.Model[]> = this.searchQuery$.pipe(
    switchMap(() => this.policySvc.list({ limit: 100 })),
    map(policies => policies?.items)
  );

  onChange = (_value: Policy.Model[] | null) => {};

  onTouched = () => {};

  selectValueChanged(value: any) {
    this.markAsTouched();

    if (typeof value === 'object') this.value = value;
    else if (value) this.value = [value];
    else this.value = null;

    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  writeValue(value: Policy.Model[] | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: Policy.Model[] | null) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
