<vs-dialog
  #previewDialog
  [config]="{
      dialogContainerClass: 'w-[700px] sm:w-[500px] max-w-[700px]',
      disableBackdropDismiss: false,
      hideBackdrop: false
    }"
  class="z-50"
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,
        icon: 'key-01',
        iconTheme: 'primary',
        title: 'Your secret key',
        subtitle: 'Please keep the key below safe as you will need this to access our API. We do not store this key and thus cannot not retrieve it if lost.'
      }"
  ></vs-dialog-header>
  <vs-input-container>
    <input type="text" [value]="key" readonly />
    <button vs-icon-button size="xs" appearance="clear" slot="end" [cdkCopyToClipboard]="key || ''" (click)="copy()">
      <vs-icon vsTooltip="Copy to clipboard" name="clipboard" size="16"></vs-icon>
    </button>
  </vs-input-container>
</vs-dialog>
