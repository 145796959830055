import { Component, TemplateRef } from '@angular/core';
import { TooltipStyle } from '@vsolv/vectors-ui/tooltip';
import { TableCellComponent } from '../../models';

export interface TextSubtitleCellProps {
  title?: string | null;
  subtitle?: string | null;
  titleClasses?: string;
  subtitleClasses?: string;
  tooltipStyle?: TooltipStyle;
  tooltipCloseDelay?: number;
  tooltipTemplate?: TemplateRef<unknown>;
}

@Component({
  template: `
    <div
      *ngIf="data"
      class="flex flex-col items-start text-start"
      [vsTooltip]="
        data.tooltipTemplate
          ? { template: data.tooltipTemplate, context: { subtitle: data.subtitle } }
          : { title: data.title || '', subtitle: data.subtitle || '' }
      "
      [tooltipStyle]="data.tooltipStyle || 'dark'"
      [tooltipCloseDelay]="data.tooltipCloseDelay || 0"
      tooltipOpenDelay="500"
    >
      <p *ngIf="data.title" class="text-sm font-medium text-gray-900 {{ data.titleClasses }}">
        {{ data.title }}
      </p>

      <p *ngIf="data.subtitle" class="text-xs font-normal text-gray-600 {{ data.subtitleClasses }}">
        {{ data.subtitle }}
      </p>
    </div>
  `,
})
export class TextSubtitleCellComponent extends TableCellComponent<TextSubtitleCellProps> {}
