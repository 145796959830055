import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';

export interface PolicyAsset {
  propertySet: PropertySet.Model | null;
}

@Component({
  selector: 'ws-policy-asset',
  templateUrl: './policy-asset.component.html',
})
export class PolicyAssetComponent {
  constructor(private router: Router, private route: ActivatedRoute, private formBuilder: FormBuilder) {}

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('editing') editing$ = new BehaviorSubject<boolean>(false);
  @Input() editing = false;

  @Input() value: PolicyAsset | null = null;

  @Output() closed = new EventEmitter();
  @Output() prevPage = new EventEmitter<PolicyAsset>();
  @Output() pageCompleted = new EventEmitter<PolicyAsset>();

  saving = false;

  draft = Policy.Status.DRAFT;

  form = this.formBuilder.group({
    propertySet: [null as PropertySet.Model | null, [Validators.required]],
  });

  patchValue$ = combineLatest([this.policy$, this.editing$]).pipe(
    tap(([policy, editing]) => {
      if (!editing) {
        if (this.value) {
          this.form.patchValue(this.value);
          this.form.markAsDirty();
        }
      } else {
        if (!policy) return;
        this.form.patchValue({ propertySet: policy.propertySet });
      }
    })
  );

  close() {
    this.policy = null;
    this.editing = false;

    this.form.reset();
    this.closed.emit();
  }

  goBack() {
    this.prevPage.emit({
      propertySet: this.form.value.propertySet || null,
    });
  }

  pageComplete() {
    this.pageCompleted.emit({
      propertySet: this.form.value.propertySet || null,
    });
  }

  // Currently there no updates for assets. This next function is a placeholder for later
  // async save() {
  //   if (!this.policy) return;

  //   this.saving = true;
  //   const value = this.form.value;

  //   const policy = await this.policySvc
  //     .update(this.policy.id, {
  //       propertySet: this.form.value.propertySet || null
  //     })
  //     .catch(({ error }) => {
  //       this.toastSvc.show({
  //         type: 'error',
  //         title: 'Something went wrong',
  //         text: error.message,
  //       });

  //       this.closed.emit();
  //       this.saving = false;
  //     });

  //   if (policy) {
  //     if (policy.id === this.policy.id) {
  //       this.toastSvc.show({
  //         type: 'success',
  //         title: 'Updated policy',
  //         text: '<strong>' + this.policy.title + '</strong> has been successfully updated.',
  //       });
  //     } else {
  //       this.toastSvc.show({
  //         type: 'success',
  //         title: 'New policy version created',
  //         text: 'An updated version of <strong>' + policy.title + '</strong> has been successfully created.',
  //       });

  //       this.navigateTo(policy.id);
  //     }

  //     this.policySvc.refreshPolicy();
  //     this.saving = false;
  //     this.closed.emit();
  //   }
  // }

  navigateTo(path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
