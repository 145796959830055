import { Component, HostBinding, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { BehaviorSubject, combineLatest, switchMap } from 'rxjs';
import { PropertyService } from '../../services';

@Component({
  selector: 'vs-property-set-badges',
  templateUrl: './property-set-badges.component.html',
})
export class PropertySetBadgesComponent {
  @HostBinding('class') private _style = 'flex flex-wrap gap-2';
  constructor(private propertySvc: PropertyService) {}

  @PropertyListener('propertySetId') propertySetId$ = new BehaviorSubject<string | null>(null);
  @Input() propertySetId!: string;

  @PropertyListener('showHidden') showHidden$ = new BehaviorSubject(true);
  @Input() showHidden = true;

  propertySet$ = combineLatest([this.propertySetId$, this.showHidden$]).pipe(
    switchMap(async ([propertySetId, showHidden]) => {
      if (!propertySetId) return null;
      const propertySet = await this.propertySvc.retrievePropertySet(propertySetId);

      propertySet.properties = propertySet.properties?.sort((a, b) => a.order - b.order);
      if (!showHidden) {
        propertySet.properties = propertySet.properties?.filter(assignment => !assignment.hidden);
      }
      return propertySet;
    })
  );
}
