import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from '@vsolv/packages/portal-config/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { map } from 'rxjs';

@Component({
  selector: 'ws-distributor-onboard-complete',
  templateUrl: './distributor-onboard-complete.component.html',
})
export class DistributorOnboardCompleteComponent {
  constructor(private route: ActivatedRoute, private portalSvc: PortalService) {}

  contactEmail = this.portalSvc.config.contactEmail;
  readonly config$ = this.route.data.pipe(map(data => data['config'] as Config.PortalConfig));
}
