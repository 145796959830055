<div *ngIf="item$ | async as item" class="flex flex-col gap-4">
  <ng-container *vsLet="claim$ | async as claim">
    <ws-claim-item-details-card class="border rounded-xl" [claim]="claim" [item]="item"></ws-claim-item-details-card>

    <div class="flex gap-2 flex-wrap">
      <vs-info-card
        *ngFor="let linkAndConfig of linksAndConfigs$ | async"
        class="max-w-[254px]"
        [config]="linkAndConfig.config"
      >
        <vs-badge
          *ngIf="linkAndConfig.link.claimItem_1 && linkAndConfig.link.claimItem_2"
          slot="header-end"
          [theme]="
            getItemTheme(
              linkAndConfig.link.claimItemId_1 !== item.id
                ? linkAndConfig.link.claimItem_1
                : linkAndConfig.link.claimItem_2
            )
          "
        >
          {{
            (linkAndConfig.link.claimItemId_1 !== item.id
              ? linkAndConfig.link.claimItem_1.status
              : linkAndConfig.link.claimItem_2.status
            ) | titlecase
          }}
        </vs-badge>
      </vs-info-card>
    </div>

    <div *vsLet="isCustomerPortal$ | async as isCustomerPortal">
      <div
        *vsLet="canManageCoverage$ | async as canManageCoverage"
        class="flex gap-2"
        [class.justify-end]="claim?.status !== open || !canManageCoverage || item.deleted"
        [class.justify-between]="
          claim?.status === open && ((customerCanEdit$ | async) || canManageCoverage) && !item.deleted
        "
      >
        <button
          *ngIf="((customerCanEdit$ | async) || canManageCoverage) && claim?.status === open && !item.deleted"
          vs-button
          appearance="outline"
          (click)="navigateTo('manage-details')"
        >
          Edit
        </button>

        <button vs-button theme="primary" (click)="navigateTo('cure')">Next</button>
      </div>
    </div>
  </ng-container>
</div>
