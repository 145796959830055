import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import chroma from 'chroma-js';

export type Color = chroma.Color;

export class ThemeUtils {
  static readonly ACCEPTED_COLORS_BLURB = 'Hex codes or colors from the W3C/X11 specification are recognized.';
  static readonly ACCEPTED_COLORS_BLURB_HTML = `
    Hex codes or colors from the
    <a class="text-primary-200" href="https://www.w3schools.com/colors/colors_x11.asp" target="_blank">
      W3C/X11 specification <vs-icon name="link-external-01"></vs-icon>
    </a>
    are recognized.
  `;

  static validateColor: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const valid = ThemeUtils.valid(control.value);
    return control.value && !valid
      ? {
          invalidColor: {
            value: control.value,
            message: ThemeUtils.ACCEPTED_COLORS_BLURB,
            messageHtml: ThemeUtils.ACCEPTED_COLORS_BLURB_HTML,
          },
        }
      : null;
  };

  static getColor(color: string | number) {
    return chroma(color) as Color;
  }

  static valid(color: unknown) {
    return chroma.valid(color);
  }

  static getHex(color: string | number | Color) {
    return chroma(color).hex();
  }

  static getStyles(color: string | number | Color, colorName: string) {
    // color = '#22c55e'; // Manual color overwrite for testing
    const SCALE_SIZE = 1000;
    const palette = Array.from(Array(SCALE_SIZE).keys()).map(i => {
      const hslColor = chroma(color).hsl();
      return chroma.hsl(hslColor[0], hslColor[1], 1 - i / (SCALE_SIZE - 1));
    });
    const colorsRGB = palette.map(a => chroma(a).rgb());
    const DEFAULT = `--vs-${colorName}: var(--vs-${colorName}-500);`;
    const scale = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];
    return [
      DEFAULT,
      ...scale.map(
        s => `--vs-${colorName}-${s}: ${colorsRGB[s - 1][0]} ${colorsRGB[s - 1][1]} ${colorsRGB[s - 1][2]};`
      ),
    ].join('\n');
  }
}
