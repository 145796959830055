import { Staff } from '../lib/staff';

export type WarrantyPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'wrt_ViewWarranty',
    display: {
      title: 'View warranty',
      description: 'View warranties',
    },
  },
  {
    id: 'wrt_CreateWarranty',
    display: {
      title: 'Create warranties',
      description: 'Ability to create waranties and finalize draft warranties',
    },
  },
  {
    id: 'wrt_CreateDraftWarranty',
    display: {
      title: 'Sell draft warranties',
      description: 'Ability to create draft waranties',
    },
  },
  {
    id: 'wrt_UpdateWarranty',
    display: {
      title: 'Update warranty',
      description: 'Update the warranty details',
    },
  },
  {
    id: 'wrt_CancelWarranty',
    display: {
      title: 'Cancel warranty',
      description: 'Cancel a warranty and its upcoming payments if applicable',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
