import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Warranty } from '@wsphere/warranties/domain';
import { catchError, firstValueFrom, lastValueFrom, of, toArray } from 'rxjs';

@Injectable()
export class WarrantyWebService {
  constructor(private http: HttpClient) {}

  getOneByCustomerEmail(email: string) {
    return firstValueFrom(
      this.http.get<Warranty.Model>(`/api/warranties/email`, { params: { email } }).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async getWarrantiesByCustomer(email: string) {
    return lastValueFrom(
      this.http.get<Warranty.Model>(`/api/warranties/email`, { params: { email } }).pipe(
        catchError(err => {
          console.log(err);
          return of(null);
        }),
        toArray()
      )
    );
  }

  getOneById(id: string) {
    return firstValueFrom(
      this.http
        .get<Warranty.Model>(`/api/warranties/${id}`, {
          params: {
            idField: 'id',
          },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  getOneByContractNumber(contractNumber: string) {
    return firstValueFrom(
      this.http
        .get<Warranty.Model>(`/api/warranties/${contractNumber}`, {
          params: {
            idField: 'contractNumber',
          },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  getOneByAssetId(assetId: string) {
    return firstValueFrom(
      this.http
        .get<Warranty.Model>(`/api/warranties/${assetId}`, {
          params: {
            idField: 'assetId',
          },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  getOneByWarrantyId(warrantyId: string) {
    return firstValueFrom(
      this.http
        .get<Warranty.Model>(`/api/warranties/${warrantyId}`, {
          params: {
            idField: 'id',
          },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  getOneWithClaims(id: string) {
    return firstValueFrom(
      this.http.get<Warranty.RetrieveWithClaimsResponse>(`/api/warranties/${id}/with-claims`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  list(params: Warranty.ListRequest): Promise<Warranty.ListResponse> {
    return firstValueFrom(
      this.http.get<Warranty.ListResponse>(`/api/warranties`, {
        params: {
          ...(params.userId && { userId: params.userId }),
          ...(params.status && { status: params.status }),
          ...(params.assetId && { assetId: params.assetId }),
          ...(params.page ? { page: params.page.toString() } : {}),
          ...(params.policyIds && { policyIds: params.policyIds }),
          ...(params.limit ? { limit: params.limit.toString() } : {}),
          ...(params.customerId && { customerId: params.customerId }),
          ...(params.warrantyId && { warrantyId: params.warrantyId }),
          ...(params.distributorId && { distributorId: params.distributorId }),
        },
      })
    );
  }

  openClaimWarrantyList(params: Warranty.ListRequest): Promise<Warranty.ListResponse> {
    return firstValueFrom(
      this.http.get<Warranty.ListResponse>(`/api/openClaimWarranties`, {
        params: {
          ...(params.page ? { page: params.page.toString() } : {}),
          ...(params.limit ? { limit: params.limit.toString() } : {}),
          ...(params.customerId && { customerId: params.customerId }),
          ...(params.userId && { userId: params.userId }),
          ...(params.assetId && { assetId: params.assetId }),
          ...(params.status && { status: params.status }),
          ...(params.warrantyId && { warrantyId: params.warrantyId }),
          ...(params.policyIds && { policyIds: params.policyIds }),
        },
      })
    );
  }

  customerWarrantyList(params: Warranty.ListRequest): Promise<Warranty.ListResponse> {
    return firstValueFrom(
      this.http.get<Warranty.ListResponse>(`/api/customerWarranties`, {
        params: {
          ...(params.page ? { page: params.page.toString() } : {}),
          ...(params.limit ? { limit: params.limit.toString() } : {}),
          ...(params.customerId && { customerId: params.customerId }),
          ...(params.userId && { userId: params.userId }),
          ...(params.assetId && { assetId: params.assetId }),
          ...(params.status && { status: params.status }),
          ...(params.warrantyId && { warrantyId: params.warrantyId }),
          ...(params.policyIds && { policyIds: params.policyIds }),
        },
      })
    );
  }

  async update(warrantyId: string, body: Warranty.UpdateWarrantyRequest) {
    return await firstValueFrom(
      this.http.patch<Warranty.UpdateWarrantyResponse>(`/api/warranties/${warrantyId}`, body).pipe(
        catchError(err => {
          console.log(err);
          throw err;
        })
      )
    );
  }

  async finalize(warrantyId: string, body?: Warranty.FinalizeWarrantyRequest) {
    return await firstValueFrom(
      this.http.post<Warranty.FinalizeWarrantyResponse>(`/api/warranties/${warrantyId}/finalize`, body).pipe(
        catchError(err => {
          console.log(err);
          throw err;
        })
      )
    );
  }

  async delete(warrantyId: string) {
    return await firstValueFrom(
      this.http.delete<Warranty.DeleteWarrantyResponse>(`/api/warranties/${warrantyId}`).pipe(
        catchError(err => {
          console.log(err);
          throw err;
        })
      )
    );
  }

  async activateWarranty(warrantyId: string, body: Warranty.Activate) {
    return await firstValueFrom(
      this.http.post<Warranty.Activate>(`/api/warranties/${warrantyId}/activate`, body)
    ).catch(() => undefined);
  }

  cancelWarranty(warrantyId: string, effectiveDate: Date, refundAmount?: number) {
    return firstValueFrom(
      this.http.post<Warranty.CancelWarrantyResponse>(`/api/warranties/${warrantyId}/cancel`, {
        cancellationDate: effectiveDate,
        refundAmount,
      })
    );
  }

  async calculateLiabilityLimit(warrantyId: string, coverageIds: string[]) {
    return await firstValueFrom(
      this.http.post<Warranty.CalculateLiabilityLimitResponse[]>(
        `/api/warranties/${warrantyId}/calculate-liability-limit`,
        {
          warrantyId,
          coverageIds,
        }
      )
    );
  }

  async sendWelcomeEmail(warrantyId: string) {
    return await firstValueFrom(this.http.post(`/api/warranties/${warrantyId}/send-welcome-email`, {}));
  }

  getDurationStats(id: string) {
    return firstValueFrom(
      this.http.get<Warranty.RetrieveWarrantyDurationStatsQueryResponse>(`/api/warranties/${id}/duration/stats`)
    );
  }
}
