import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReceiveEmailConfigValue } from '@vsolv/packages/email/domain';
import { catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class ReceiveEmailService {
  constructor(private http: HttpClient) {}

  async createAddress() {
    return await firstValueFrom(this.http.post<{ email: string }>(`/api/email/receive`, {}));
  }

  async deleteAddress() {
    return await firstValueFrom(this.http.delete<void>(`/api/email/receive`));
  }

  async refreshAddress() {
    return await firstValueFrom(this.http.patch<{ email: string }>(`/api/email/receive`, {}));
  }

  async retrieveAddress() {
    return await firstValueFrom(
      this.http.get<{ email: string }>(`/api/email/receive`).pipe(
        catchError(err => {
          console.error(err);
          return of(null);
        })
      )
    );
  }

  async getConfig() {
    return await firstValueFrom(
      this.http.get<ReceiveEmailConfigValue>('/api/email/receive/config').pipe(
        catchError(err => {
          console.error(err);
          return of(new ReceiveEmailConfigValue());
        })
      )
    );
  }

  async setConfig(config: ReceiveEmailConfigValue) {
    return await firstValueFrom(this.http.patch<ReceiveEmailConfigValue>('/api/email/receive/config', { config }));
  }
}
