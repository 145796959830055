/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { Staff } from '@wsphere/staff/domain';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class StaffService {
  constructor(private http: HttpClient, private firebaseSvc: FirebaseService) {}

  async retrieveSelf() {
    return await firstValueFrom(this.http.get<Staff.Model>(`/api/staff/self`));
  }

  async retrieve(uniqueId: string, idField?: Staff.IdField) {
    return await firstValueFrom(
      this.http.get<Staff.Model>(`/api/staff/${uniqueId}`, { params: { idField: idField as string } })
    );
  }

  async list(dto: Staff.ListStaffQueryRequest) {
    return await firstValueFrom(
      this.http.get<Staff.ListStaffQueryResponse>(`/api/staff`, {
        params: {
          ...(dto.page && { page: dto.page }),
          ...(dto.limit && { limit: dto.limit }),
          ...(dto.search && { search: dto.search }),
          ...(dto.status && { status: dto.status }),
          ...(dto.roleIds && { roleIds: dto.roleIds }),
          ...(dto.distributorId && { distributorId: dto.distributorId }),
          ...(dto.permissionKeys !== undefined && {
            permissionKeys: dto.permissionKeys?.length ? dto.permissionKeys : 'null',
          }),
        },
      })
    );
  }

  async count(dto: Staff.CountStaffQueryRequest) {
    return await firstValueFrom(
      this.http.get<Staff.CountStaffQueryResponse>(`/api/staff/count`, {
        params: {
          ...(dto.permissionKey && { permissionKey: dto.permissionKey }),
          ...(dto.includeChildren && { includeChildren: dto.includeChildren }),
        },
      })
    );
  }

  async listRoleAssignmentsByDistributor(distributorId: string, dto: { page: number; limit: number }) {
    return await firstValueFrom(
      this.http.get<Staff.Security.ListDistributorsWithPermissionsQueryResponse>(
        `/api/staff/role-assignments/${distributorId}`,
        {
          params: { ...(dto.page && { page: dto.page }), ...(dto.limit && { limit: dto.limit }) },
        }
      )
    );
  }

  async invite(dto: Staff.InviteStaffRequest) {
    return await firstValueFrom(this.http.post<Staff.InviteStaffResponse>('/api/staff/invite', dto));
  }

  async setupAccount(dto: Staff.SetupAccountRequest) {
    const response = await firstValueFrom(this.http.post<Staff.SetupAccountResponse>('/api/staff/setup-account', dto));
    //Changing the password invalidates the current firebase authentication, so we need to reauthenticate.
    await this.firebaseSvc.reauthenticate(dto.password);
    return response;
  }

  async deactivate(id: string) {
    return await firstValueFrom(this.http.post<Staff.DeactivateStaffResponse>(`/api/staff/${id}/deactivate`, {}));
  }

  async update(dto: Staff.UpdateStaffRequest) {
    return await firstValueFrom(this.http.post<Staff.UpdateStaffResponse>('/api/staff/update', dto));
  }
}
