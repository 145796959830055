import { Directive, HostBinding } from '@angular/core';
import { InputBoxDirective } from './input-box.directive';

@Directive({
  standalone: true,
  selector: `
    input[type="text"][vsInput],
    input[type="password"][vsInput],
    input[type="tel"][vsInput],
    input[type="url"][vsInput],
    input[type="email"][vsInput],
    input[type="number"][vsInput]
  `,
  hostDirectives: [InputBoxDirective],
})
export class TextInputDirective {
  @HostBinding('class') get styles() {
    const box = `h-10 px-3 w-full rounded-md`;
    const text = `text-md text-gray-900 truncate`;
    return `theme-primary-inherit ${box} ${text}`;
  }
}
