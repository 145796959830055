import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Model } from '../model';
import { Status } from './status';

export function included(claim: Model, coverageId: string) {
  return (
    claim.warranty?.coverages?.some(
      coverage => coverage.coverageId === coverageId && coverage.requirement !== 'NOT_APPLICABLE'
    ) ?? false
  );
}

export function getTheme(status: Status): ThemeColor {
  switch (status) {
    case Status.APPROVED:
    case Status.RESOLVED:
      return 'success';
    case Status.REJECTED:
      return 'danger';
    default:
      return 'default';
  }
}
