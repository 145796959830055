import { Staff } from '../lib/staff';

export type ReportingPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'rep_ViewReports',
    display: {
      title: 'View Platform Reports',
      description: 'View the reporting page for the whole platform.',
    },
  },
  {
    id: 'rep_GenerateReports',
    display: {
      title: 'Generate Reports',
      description: 'Generate reports available from the reporting page.',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
