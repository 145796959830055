<ng-container *vsLet="properties$ | async as properties">
  <ng-container *vsLet="selectedProperty$ | async">
    <vs-icon
      [name]="$any(propertySet?.icon) || 'home-03'"
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      (click)="toggle()"
      [vsTooltip]="'Select a property to use as the value'"
      class="cursor-pointer"
    >
    </vs-icon>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="overlayOpen"
      (overlayOutsideClick)="$event.stopImmediatePropagation(); overlayOpen = false"
    >
      <vs-menu class="max-h-[300px]">
        <p class="mx-1 text-sm text-gray-600" *ngIf="!properties?.length">No matching properties found!</p>
        <div *ngFor="let propertyWithSameType of properties">
          <ws-condition-value-property-picker-item
            [propertyWithSameType]="propertyWithSameType"
            [propertyPath]="propertyWithSameType.property.valueKey"
            (selectionChanged)="setValue($event); close()"
            [value]="value"
          ></ws-condition-value-property-picker-item>
        </div>
      </vs-menu>
    </ng-template>
  </ng-container>
</ng-container>
