import { Component, Input } from '@angular/core';

@Component({
  selector: 'ws-claim-item-liability-limit',
  templateUrl: './claim-item-liability-limit.component.html',
})
export class ClaimItemLiabilityLimitComponent {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() item: any | null = null;
}
