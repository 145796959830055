import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { CoverageResolver } from '../resolvers/coverage.resolver';
import { CoverageService } from '../services';

@Injectable()
export class CoverageGuard implements CanActivate {
  constructor(private resolver: CoverageResolver, private coverageSvc: CoverageService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.coverage = null;

    const coverageId = this.findCoverageId(route);
    if (!coverageId) return false;

    this.resolver.coverage = await this.coverageSvc.getOne(coverageId);
    if (!this.resolver.coverage) return false;

    this.coverageSvc.refreshCoverage(this.resolver.coverage);

    return true;
  }

  private findCoverageId(route: ActivatedRouteSnapshot): string {
    return route.params['coverageId'] || (route.parent ? this.findCoverageId(route.parent) : null);
  }
}
