<vs-dialog
  *vsLet="isMobile$ | async as isMobile"
  [config]="{ dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]' }"
>
  <vs-dialog-header
    [config]="{
      mode: isMobile ? 'default' : 'simple',
      hideCloseButton: false,

      icon: 'link-01',
      iconTheme: 'primary',
      
      title: 'Invite member' + (distributor ? '' : 's') + ' to distributor' + (distributor ? '' : 's'),
      subtitle: 'Select which distributors you would like to invite the members to, then select the roles the should have fot those distributors.',
      
      showDivider: true
    }"
  ></vs-dialog-header>

  <form [formGroup]="form" class="flex flex-col gap-4 pt-4 -mx-1 px-1">
    <vs-form-field [config]="{ label: 'Enter members to invite' }">
      <ws-staff-or-email-picker formControlName="emails"></ws-staff-or-email-picker>
    </vs-form-field>

    <div class="flex flex-col gap-4 max-h-[350px] overflow-auto scrollbar-thin">
      <div
        *ngFor="let group of distributorsAndRoles; index as index"
        class="flex flex-col gap-4 rounded-md border p-4 relative"
        (mouseenter)="hoveredIndex = index"
        (mouseleave)="hoveredIndex = -1"
      >
        <vs-icon
          *ngIf="hoveredIndex === index && distributorsAndRoles.length > 1"
          name="x"
          size="18"
          class="absolute top-0 right-0 p-2 cursor-pointer"
          (click)="removeGroup(index)"
        ></vs-icon>

        <vs-form-field [config]="{ label: 'Select a distributor to invite members to' }">
          <ws-distributor-picker
            [value]="group.distributor"
            (valueChanges)="updateDistributor($event, index)"
          ></ws-distributor-picker>
        </vs-form-field>

        <vs-form-field [config]="{ label: 'Select roles to give members for this distributor' }">
          <ws-role-picker
            [permissionKey]="group.distributor?.permissionKey || ''"
            (roleAdded)="addRole(index, $event)"
            (roleRemoved)="removeRole(index, $event)"
          ></ws-role-picker>
        </vs-form-field>
      </div>
    </div>

    <button vs-button appearance="clear" class="mb-4 -mt-2">
      <vs-icon name="plus"></vs-icon>
      <p class="font-semibold text-gray-600 text-sm" (click)="addGroup()">Add members to another distributor</p>
    </button>
  </form>

  <div class="flex justify-end gap-2">
    <button vs-button appearance="outline" [disabled]="saving" (click)="close()">Cancel</button>
    <button
      vs-button
      theme="primary"
      [disabled]="saving || !form.value.emails?.length || !allDistributorsHaveRoles"
      (click)="inviteStaff()"
    >
      Send Invites
    </button>
  </div>
</vs-dialog>
<ws-full-admin-warning-dialog></ws-full-admin-warning-dialog>
