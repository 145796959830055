<vs-dialog *vsLet="isMobile$ | async as isMobile" [config]="{ dialogContainerClass: 'w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: isMobile ? 'default' : 'simple',
      hideCloseButton: false,
      
      icon: 'archive',
      iconTheme: 'warn',

      title: 'Archive ' + distributor?.name || 'Distributor',
      subtitle: 'Are you sure you want to archive this distributor? Archiving a distributor will prevent team members and customers from provisioning new warranties using SFTP or checkout links to policies and plans. You will also no longer be able to onboard new sub-distributors or team members to this distributor. All sub-distributors will also be archived. SFTP access will be revoked and this action cannot be undone.'
    }"
  ></vs-dialog-header>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
        Cancel
      </button>

      <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="archiveDistributor()">
        Archive
      </button>
    </div>
  </div>
</vs-dialog>
