import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { DatePickerComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    ThemingModule,
    IconsModule,
    UtilsModule,
    InputModule,
    FormsModule,
    ReactiveFormsModule,
    ThemingModule,
    MenuModule,
  ],
  declarations: [DatePickerComponent],
  exports: [DatePickerComponent],
})
export class DatePickerModule {}
