import { Component, EventEmitter, HostBinding, inject, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Payment } from '@vsolv/packages/payments/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { PaymentService } from '../../services';

@Component({
  selector: 'vs-update-billing-info-dialog',
  templateUrl: './update-billing-info.dialog.html',
})
export class UpdateBillingInfoDialog {
  @HostBinding('class') private _classes = 'contents';

  private toastSvc = inject(ToastService);
  private formBuilder = inject(FormBuilder);
  private paymentSvc = inject(PaymentService);

  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() saved = new EventEmitter<Payment.ChangePaymentMethodResponse>();

  protected _payment?: Payment.Model;
  @Input() set payment(payment: Payment.Model) {
    this._payment = payment;
    this.reset();
  }

  protected control = this.formBuilder.control(
    { value: this.payment?.paymentMethodId ?? null, disabled: false },
    Validators.required
  );

  protected saving = false;

  open() {
    this.reset();
    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }

  protected async save() {
    this.saving = true;

    try {
      const paymentId = this._payment?.id;
      if (!paymentId) throw new Error('Unable to update billing details.');

      const paymentMethodId = this.control.value;
      if (!paymentMethodId) throw new Error('Unable to update billing details.');

      const currentPaymentMethodId = this._payment?.paymentMethodId ?? null;
      if (paymentMethodId === currentPaymentMethodId) {
        throw new Error('Make sure to select a different payment method or add a new one.');
      }

      const result = await this.paymentSvc.changePaymentMethod(paymentId, paymentMethodId);
      this.saved.emit(result);

      this.toastSvc.show({
        type: 'success',
        title: 'Billing details updated',
        text: 'The payment method has been updated',
      });

      this.close();
    } catch (err) {
      console.error(err);
      this.toastSvc.show({
        title: 'Unable to update billing details',
        type:
          err instanceof Error && err.message === 'Make sure to select a different payment method or add a new one.'
            ? 'warning'
            : 'error',
        text: err instanceof Error ? err.message : 'Unknown Error',
      });
    } finally {
      this.saving = false;
    }
  }

  private reset() {
    this.control.reset({ value: this._payment?.paymentMethodId ?? null, disabled: false });
  }
}
