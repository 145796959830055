/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { combineLatest, debounceTime, firstValueFrom, map, switchMap, tap } from 'rxjs';
import { GenerateLinkDialog, PlanDialog } from '../../dialogs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-policy-plans-page',
  templateUrl: './policy-plans.page.html',
})
export class PolicyPlansPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild('createPlanDialog') createPlanDialog?: PlanDialog;
  @ViewChild('generateLinkDialog') generateLinkDialog?: GenerateLinkDialog;

  readonly isSmall$ = this.breakpointObserver.observe([Breakpoints.Small]).pipe(map(state => state.matches));

  readonly policy$ = this.policySvc.getPolicyObservable();
  readonly plan$ = this.policySvc.getPlanObservable();

  policyPlans: Policy.Plan.Model[] | null = [];

  selectedPlanId: string | null = null;

  draft = Policy.Status.DRAFT;
  published = Policy.Status.PUBLISHED;

  refreshPlans$ = combineLatest([this.policy$, this.plan$]).pipe(
    debounceTime(100),
    tap(async ([policy, plan]) => {
      if (policy) {
        const plans = (await this.policySvc.getPlans(policy.id))?.items;

        if (plans?.length) {
          if (!this.selectedPlanId) {
            // Initial load and refresh
            if (this.route.firstChild) {
              const routePlan = (await firstValueFrom(this.route.firstChild?.data))['plan'] as Policy.Plan.Model;
              this.selectPlan(routePlan);
            } else this.selectPlan(plans[0]);
          } else if (plan) {
            // If a plan is given select it
            if (plan.id !== this.selectedPlanId) this.selectedPlanId = plan.id;
          } else {
            const planToSelect = plans.find(plan => plan.id.includes(this.selectedPlanId?.split('-')[0] || ''));
            // Select similar plan if new policy has one
            if (planToSelect) this.selectPlan(planToSelect);
            // Select default for deletion, and navigation to a policy without similar plans
            else this.selectPlan(plans[0]);
          }

          this.policyPlans = plans;
        } else this.policyPlans = null;
      }
    })
  );

  canCreatePlan$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const globalDistIds = globalDistributors ? globalDistributors?.map(dist => dist.id) : null;
      return await this.securitySvc.hasAccess('pol_CreatePlan', globalDistIds);
    })
  );

  async selectPlan(plan: Policy.Plan.Model | null) {
    if (plan) {
      this.selectedPlanId = plan.id;
      this.policySvc.refreshPlan(plan);
      this.navigateTo('/policies/' + plan.policyId + '/plans/' + plan.id);
    }
  }

  openCreatePlanDialog() {
    this.createPlanDialog?.open();
  }

  async downloadProvisionTemplate(policyId: string, planId: string) {
    const response = await this.policySvc.generateProvisionTemplate(policyId, planId).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });
    });

    if (response) {
      const link = document.createElement('a');
      link.setAttribute('href', response.data);
      link.setAttribute('download', 'Provisioning.csv');
      link.click();
    }
  }

  async openGenerateLinkDialog() {
    this.generateLinkDialog?.openDialog();
  }

  navigateTo(path?: string) {
    if (path) this.router.navigate([`${path}`]);
  }
}
