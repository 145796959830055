import { Staff } from '../lib/staff';

export type StaffPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'stf_ViewStaff',
    display: {
      title: 'View Staff',
      description: 'View staff profiles',
    },
  },
  {
    id: 'stf_ManageAPIKeys',
    display: {
      title: 'Manage Personal Access Token',
      description: 'Manage all API keys regardless of if created by self (delete, reroll, edit)',
    },
  },
  {
    id: 'stf_ViewSettings',
    display: {
      title: 'View Tenant Settings',
      description: 'View tenant settings (name, email, colours, etc)',
    },
  },
  {
    id: 'stf_EditSettings',
    display: {
      title: 'Edit Tenant Settings',
      description: 'Edit tenant settings (name, email, colours, etc)',
    },
  },
  {
    id: 'stf_ViewStaffRoles',
    display: {
      title: 'View Staff Roles',
      description: 'View staff roles and profiles',
    },
  },
  {
    id: 'stf_ManageStaff',
    display: {
      title: 'Invite and Remove Staff',
      description: 'Invite and remove staff',
    },
  },
  {
    id: 'stf_EditStaffDetails',
    display: {
      title: 'Manage Staff Details',
      description: 'Manage the details of staff',
    },
  },
  {
    id: 'stf_GrantRoles',
    display: {
      title: 'Grant/Revoke User Roles',
      description: 'Grant/Revoke roles of user',
    },
  },
  {
    id: 'stf_EditRoles',
    display: {
      title: 'Edit/Remove Staff Roles',
      description: 'Edit and remove team members roles',
    },
  },
  {
    id: 'stf_ViewAPIKeys',
    display: {
      title: 'View API keys',
      description: 'View API keys',
    },
  },

  {
    id: 'stf_ManageAuthNetApp',
    display: {
      title: 'Manage Authorize.Net config',
      description: 'View, Create, Update, Delete Authorize.Net config',
    },
  },
  {
    id: 'stf_ViewApps',
    display: {
      title: 'View Apps',
      description:
        'View app details on app page; cannot enable or disable apps, but can open apps for more info about the app (cant view sensitive info as part of app - that belongs to manage permissions)',
    },
  },
  {
    id: 'stf_ManageEmailIngestionApp',
    display: {
      title: 'Manage Email Ingestion App',
      description:
        'Can view email ingestion option on app page: can toggle app on and off, can enable/disable email body document creation, can copy email to clipboard',
    },
  },
  {
    id: 'sft_ManageSFTPApp',
    display: {
      title: 'Manage SFTP App',
      description:
        "Can view the app's credentials tab, can copy + reroll credentials, can view settings tab and can enable/disable credentials",
    },
  },
  {
    id: 'stf_ManageCustomBotIntegrationApp',
    display: {
      title: 'Manage Custom Bot Integration App',
      description: 'Can view the secret key, enable, and disable the app',
    },
  },
  {
    id: 'stf_ManageChatbotIntegrationApp',
    display: {
      title: 'Manage Chat Bot Integration App',
      description: 'Can view the secret key, enable, and disable the app',
    },
  },
  {
    id: 'stf_TaxJarCustomKeyApp',
    display: {
      title: 'Manage TaxJar Custom Key App',
      description: 'Can view the app, enable, disable the app and update the key value for the app',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
