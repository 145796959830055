import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ONE_TIME_TOKEN } from '@vsolv/core/users/domain';
import { OneTimeTokenService } from '@vsolv/core/users/web';
import { EmailResolver, OneTimeTokenResolver } from '../resolvers';

@Injectable()
export class OneTimeTokenGuard implements CanActivate {
  constructor(
    private router: Router,
    private tokenResolver: OneTimeTokenResolver,
    private emailResolver: EmailResolver,
    private tokenSvc: OneTimeTokenService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.tokenResolver.oneTimeToken = null;
    const providedToken = this.findParam(route, ONE_TIME_TOKEN);
    const email = this.findParam(route, 'email');

    if (!providedToken || !email) return this.notFound();

    const token = await this.tokenSvc.getOne(providedToken);

    if (!token || token.used || (token.expired && token.expired.getTime() <= Date.now())) return this.notFound();

    this.tokenResolver.oneTimeToken = providedToken;
    this.emailResolver.email = email;

    return true;
  }

  private notFound() {
    return this.router.createUrlTree(['../sign-in']);
  }

  private findParam(route: ActivatedRouteSnapshot, param: string): string {
    return route.queryParams[param] || (route.parent ? this.findParam(route.parent, param) : null);
  }
}
