import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKey } from '@vsolv/packages/api-key';
import { catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class SftpService {
  constructor(private http: HttpClient) {}

  /**
   * Create SFTP credentials for a distributor/tenant. Tenant access requires global permissions.
   *
   * @param {string} distributorOrTenantID Distributor/Tenant ID
   */
  async createCredentials(distributorOrTenantID: string): Promise<ApiKey.CreateApiKeyResponse> {
    return firstValueFrom(this.http.post<ApiKey.CreateApiKeyResponse>(`/api/sftp/${distributorOrTenantID}`, null));
  }

  /**
   * Get SFTP credentials for a distributor/tenant. Tenant access requires global permissions.
   *
   * @param {string} distributorOrTenantID Distributor/Tenant ID
   */
  async getCredentials(distributorOrTenantID: string): Promise<ApiKey.GetCredentialsQueryResponse | null> {
    return firstValueFrom(
      this.http
        .get<ApiKey.GetCredentialsQueryResponse>(`/api/sftp/${distributorOrTenantID}`)
        .pipe(catchError(() => of(null)))
    );
  }

  /**
   * Reroll SFTP credentials for a given distributor/tenant. Tenant access requires global permissions.
   *
   * @param {string} keyId Key ID
   * @param {string} distributorOrTenantID Distributor/Tenant ID
   */
  async rollCredentials(keyId: string, distributorOrTenantID: string) {
    return await firstValueFrom(
      this.http.patch<ApiKey.RollApiKeyResponse>(`/api/sftp/${distributorOrTenantID}`, { keyId })
    );
  }

  async getSettings() {
    return firstValueFrom(
      this.http
        .get<{ tenantEnabled: boolean; distributorEnabled: boolean; tenantEmail: string | null }>(`/api/sftp/settings`)
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  async updateSettings(data: { tenantEnabled?: boolean; distributorEnabled?: boolean; tenantEmail?: string | null }) {
    return firstValueFrom(
      this.http.patch<{ tenantEnabled: boolean; distributorEnabled: boolean; tenantEmail: string | null }>(
        `/api/sftp/settings`,
        { ...data }
      )
    );
  }
}
