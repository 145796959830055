import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PaymentMethodModule, PaymentModule } from '@vsolv/packages/payments/web';
import { PropertiesWebModule } from '@vsolv/packages/properties/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InfoCardModule } from '@vsolv/vectors-ui/info-card';
import { InputModule } from '@vsolv/vectors-ui/input';
import { ProgressBarModule } from '@vsolv/vectors-ui/progress-bar';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import { AssetsWebModule } from '@wsphere/assets/web';
import { CustomerWebModule } from '@wsphere/customers/web';
import { StaffWebModule } from '@wsphere/staff/web';
import { WarrantyManagementModule } from '@wsphere/warranties/web';
import { NgxCurrencyModule } from 'ngx-currency';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { PaymentInvoiceComponent, PaymentsTableComponent } from './components';
import { PaymentGuard } from './guards';
import { InvoicePage, StaffPaymentPage, StaffPaymentsPage } from './pages';
import { PaymentResolver } from './resolvers';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    MomentModule,

    CardModule,
    BadgeModule,
    TableModule,
    UtilsModule,
    IconsModule,
    DialogModule,
    ButtonModule,
    ThemingModule,
    AppPageModule,
    DividerModule,
    PaymentModule,
    PaymentMethodModule,
    TooltipModule,
    BreadcrumbsModule,
    InputModule,
    CustomerWebModule,
    FormsModule,
    UserAvatarModule,

    NgPipesModule,

    NgxCurrencyModule,
    StaffWebModule,

    PropertiesWebModule,
    InfoCardModule,
    AssetsWebModule,
    ProgressBarModule,
    WarrantyManagementModule,
  ],

  declarations: [
    // Pages
    StaffPaymentsPage,
    StaffPaymentPage,
    InvoicePage,

    // Components
    PaymentsTableComponent,
    PaymentInvoiceComponent,
  ],
  exports: [
    InvoicePage,

    // Components
    PaymentsTableComponent,
    PaymentInvoiceComponent,
  ],
  providers: [PaymentGuard, PaymentResolver],
})
export class SalesStaffPagesModule {}
