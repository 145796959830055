<ng-container *vsLet="propertySet$ | async as propertySet">
  <ng-container *vsLet="properties$ | async as properties">
    <ng-container *vsLet="selectedProperty$ | async">
      <vs-input-container
        #trigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        (click)="toggle()"
        [class]="roundRightBorder ? '' : ' rounded-r-none'"
      >
        <div *ngIf="value?.propertyPath" class="w-fit max-w-[96px] pt-[5px]">
          <vs-badge theme="default" [vsTooltip]="value!.propertyPath" tooltipPosition="top" class="w-full h-4">
            <span class="w-full truncate">{{ value!.propertyPath }}</span>
          </vs-badge>
        </div>
        <vs-icon slot="end" name="chevron-down"></vs-icon>
      </vs-input-container>

      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="overlayOpen"
        (overlayOutsideClick)="$event.stopImmediatePropagation(); overlayOpen = false"
      >
        <vs-menu class="max-h-[300px]">
          <div *ngFor="let property of properties">
            <ws-condition-property-picker-item
              [property]="property"
              [propertyPath]="property.valueKey"
              (selectionChanged)="setValue($event, true); close()"
              [value]="value"
            ></ws-condition-property-picker-item>
          </div>
        </vs-menu>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
