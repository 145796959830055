import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { SftpKeyManagementComponent } from './components';
import { CopySftpKeyDialog, RerollSFTPKeyDialog } from './dialogs';
import { SftpService } from './services';

@NgModule({
  imports: [
    CommonModule,
    CardModule,
    IconsModule,
    ThemingModule,
    DividerModule,
    DialogModule,
    InputModule,
    FormsModule,
    ReactiveFormsModule,
    ClipboardModule,
    UtilsModule,
    ButtonModule,
    TooltipModule,
  ],
  providers: [SftpService],
  declarations: [SftpKeyManagementComponent, CopySftpKeyDialog, RerollSFTPKeyDialog],
  exports: [SftpKeyManagementComponent, CopySftpKeyDialog, RerollSFTPKeyDialog],
})
export class SftpWebModule {}
