import { Injectable } from '@angular/core';
import { AlertConfig } from '../components';
import { AlertService } from './alert.service';

export type ToastType = 'info' | 'success' | 'warning' | 'error';

export interface ToastConfig extends Pick<AlertConfig, 'title' | 'text'> {
  type: ToastType;
}

@Injectable()
export class ToastService {
  constructor(private alertSvc: AlertService) {}

  show({ type, ...config }: ToastConfig) {
    return this.alertSvc.show({ ...config, ...this.getTypeConfig(type) });
  }

  private getTypeConfig(type: ToastType): Pick<AlertConfig, 'icon' | 'iconTheme'> {
    switch (type) {
      case 'info':
        return { icon: 'alert-circle', iconTheme: 'info' };

      case 'success':
        return { icon: 'check-circle', iconTheme: 'success' };

      case 'warning':
        return { icon: 'alert-circle', iconTheme: 'warn' };

      case 'error':
        return { icon: 'alert-triangle', iconTheme: 'danger' };
    }
  }
}
