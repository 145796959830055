/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationQueryRequest } from '@vsolv/dev-kit/ngx';
import { Distributor } from '@wsphere/distributors/domain';
import { Sales } from '@wsphere/sales/domain';
import { catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class DistributorService {
  constructor(private http: HttpClient) {}

  listDistributors(dto: Distributor.ListDistributorsQueryRequest) {
    return firstValueFrom(
      this.http.get<Distributor.ListDistributorsQueryResponse>(`/api/distributors`, {
        params: {
          ...(dto.page && { page: dto.page }),
          ...(dto.limit && { limit: dto.limit }),
          ...(dto.search && { search: dto.search }),
          ...(dto.status && { status: dto.status }),
          ...(dto.includeSubDistributors && { includeSubDistributors: dto.includeSubDistributors }),
          ...(dto.distributorIds && { distributorIds: dto.distributorIds }),
        },
      })
    );
  }

  listDistributorsForSales(dto: Distributor.ListDistributorsQueryRequest) {
    return firstValueFrom(
      this.http.get<Distributor.ListDistributorsQueryResponse>(`/api/distributors-sales`, {
        params: {
          ...(dto.page ? { page: dto.page } : {}),
          ...(dto.limit ? { limit: dto.limit } : {}),
          ...(dto.search && { search: dto.search }),
        },
      })
    );
  }

  listDistributorsForOnboardingLink(dto: Distributor.ListDistributorsQueryRequest) {
    return firstValueFrom(
      this.http.get<Distributor.ListDistributorsQueryResponse>(`/api/distributors-onboarding`, {
        params: {
          ...(dto.page && { page: dto.page }),
          ...(dto.limit && { limit: dto.limit }),
          ...(dto.search && { search: dto.search }),
          ...(dto.status && { status: dto.status }),
          ...(dto.distributorIds && { distributorIds: dto.distributorIds }),
        },
      })
    );
  }

  async createDistributor(body: Distributor.CreateDistributorRequest) {
    return await firstValueFrom(this.http.post<Distributor.CreateDistributorResponse>(`/api/distributors`, body));
  }

  async getSettings() {
    return firstValueFrom(
      this.http.get<Distributor.RetrieveDistributorsSettingsResponse>(`/api/distributors/settings`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async updateSettings(data: Distributor.UpdateDistributorsSettingsRequest) {
    return firstValueFrom(this.http.patch<boolean>(`/api/distributors/settings`, { ...data }));
  }

  async createOnboardingLink(body: Distributor.OnboardingLink.CreateLinkRequest) {
    return await firstValueFrom(
      this.http.post<Distributor.OnboardingLink.CreateLinkResponse>(`/api/distributors/onboarding-links`, body)
    );
  }

  async listOnboardingLinks(
    distributorId: string,
    showExpiredLinks: boolean,
    globalDistributors: string[],
    pagination?: PaginationQueryRequest
  ) {
    return await firstValueFrom(
      this.http.get<Distributor.OnboardingLink.ListLinksQueryResponse>('/api/distributors/onboarding-links', {
        params: {
          page: pagination?.page || 1,
          limit: pagination?.limit || 5,
          ...(distributorId && { distributorId }),
          showExpiredLinks,
          globalDistributors,
        },
      })
    );
  }

  async count(permissionKey: string) {
    return await firstValueFrom(
      this.http
        .get<Distributor.CountDistributorsQueryResponse>(`/api/distributors/count`, { params: { permissionKey } })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  async getOne(distributorId: string) {
    return await firstValueFrom(
      this.http.get<Distributor.Model>(`/api/distributors/${distributorId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async updateDistributor(distributorId: string, body: Distributor.UpdateDistributorRequest) {
    return await firstValueFrom(
      this.http.patch<Distributor.UpdateDistributorResponse>(`/api/distributors/${distributorId}`, body)
    );
  }

  async archiveDistributor(distributorId: string) {
    return await firstValueFrom(
      this.http.patch<Distributor.ArchiveDistributorResponse>(`/api/distributors/${distributorId}/archive`, {})
    );
  }

  async deactivateDistributor(distributorId: string) {
    return await firstValueFrom(
      this.http.patch<Distributor.DeactivateDistributorResponse>(`/api/distributors/${distributorId}/deactivate`, {})
    );
  }

  async reactivateDistributor(distributorId: string) {
    return await firstValueFrom(
      this.http.patch<Distributor.ReactivateDistributorResponse>(`/api/distributors/${distributorId}/reactivate`, {})
    );
  }

  async breakOnboardingLink(linkId: string) {
    return await firstValueFrom(
      this.http.post<Distributor.OnboardingLink.BreakLinkResponse>(
        `/api/distributors/onboarding-links/${linkId}/break`,
        {}
      )
    );
  }

  async validateOnboardingLink(linkId: string) {
    return await firstValueFrom(
      this.http.get<Distributor.OnboardingLink.RetrieveLinkQueryResponse>(
        `/api/distributors/onboarding-links/${linkId}`,
        {
          params: { validate: true },
        }
      )
    );
  }

  async findByName(name: string) {
    return await firstValueFrom(
      this.http.get<Distributor.Model>(`/api/distributors/public/name/${name}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async createWithLink(linkId: string, data: Distributor.CreateDistributorWithLinkRequest) {
    return await firstValueFrom(
      this.http.post<Distributor.OnboardingLink.RetrieveLinkQueryResponse>(
        `/api/distributors/create-with-link/${linkId}`,
        data
      )
    );
  }

  async getSalesStats(distributorId: string) {
    return firstValueFrom(
      this.http.get<Sales.RetrieveDistributorPaymentStatsResponse>(`/api/sales/${distributorId}/stats`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }
}
