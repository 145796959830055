/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Property } from '@vsolv/packages/properties/domain';
import { PropertyInputComponent } from '../../abstract-property-input.component';

@Component({
  selector: 'vs-number-input-field',
  templateUrl: './form-field.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputFieldComponent,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: NumberInputFieldComponent,
      multi: true,
    },
  ],
})
export class NumberInputFieldComponent extends PropertyInputComponent {
  @Input() override property!: Property.NumberModel;
}
