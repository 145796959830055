/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { Claim } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-claim-item-details-card',
  templateUrl: './claim-item-details-card.component.html',
})
export class ClaimItemDetailsCardComponent {
  @Input() item: Claim.ClaimItem.Model | null = null;
  @Input() claim: Claim.Model | null = null;

  checkIfIncluded() {
    if (!this.claim || !this.item?.coverageId) return false;

    return Claim.ClaimItem.included(this.claim, this.item.coverageId);
  }
}
