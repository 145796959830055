import { Component, ElementRef, TemplateRef } from '@angular/core';
import { BadgeConfig } from '@vsolv/vectors-ui/badge';
import { ButtonAppearance } from '@vsolv/vectors-ui/button';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { TooltipStyle } from '@vsolv/vectors-ui/tooltip';
import { TableCellComponent } from '../../models';

export interface BadgeCellProps extends BadgeConfig {
  theme?: ThemeColor;
  text?: string;
  tooltipStyle?: TooltipStyle;
  tooltipTemplate?: TemplateRef<ElementRef>;
  tooltipText?: string;
  tooltipContext?: object;

  click?($event: Event): void | Promise<void>;

  disabled?: boolean;
  buttonText?: string;
  buttonTheme?: ThemeColor;
  buttonAppearance?: ButtonAppearance;
  buttonClasses?: string;
  buttonIcon?: IconName;
  buttonClick?($event: Event): void | Promise<void>;

  emptyState?: boolean;
}

@Component({
  template: `
    <ng-container *ngIf="data">
      <p *ngIf="data.emptyState" class="text-xs text-gray-600">-</p>

      <div
        *ngIf="data.text"
        [vsTooltip]="
          data.tooltipTemplate
            ? { template: data.tooltipTemplate, context: { $implicit: data.tooltipContext ?? null } }
            : data.tooltipText
        "
        [tooltipStyle]="data.tooltipStyle || 'dark'"
      >
        <vs-badge
          *ngIf="data.click"
          (click)="$event.stopPropagation(); _click($event)"
          [theme]="data.theme"
          [config]="data"
        >
          {{ data.text }}
        </vs-badge>

        <vs-badge *ngIf="!data.click" [theme]="data.theme" [config]="data">
          {{ data.text }}
        </vs-badge>
      </div>

      <button
        *ngIf="data.buttonText"
        vs-button
        type="button"
        [class]="data.buttonClasses"
        [disabled]="data.disabled || false"
        [theme]="data.buttonTheme ?? 'default'"
        [appearance]="data.buttonAppearance ?? 'default'"
        (click)="_buttonClick($event); $event.stopPropagation()"
      >
        <vs-icon *ngIf="data.buttonIcon" [name]="data.buttonIcon"></vs-icon>
        {{ data.buttonText }}
      </button>
    </ng-container>
  `,
})
export class BadgeCellComponent extends TableCellComponent<BadgeCellProps> {
  async _click($event: Event) {
    await this.data?.click?.($event);
  }

  async _buttonClick($event: Event) {
    await this.data?.buttonClick?.($event);
  }
}
