<div *ngIf="config" class="flex flex-col gap-6 mb-8">
  <ng-content select="[slot='breadcrumbs']"></ng-content>

  <div class="flex justify-between flex-col sm:flex-row gap-4">
    <div class="flex flex-col overflow-hidden">
      <h1
        class="flex gap-2 items-center text-gray-900 font-semibold"
        [class.text-xl]="config.isSubPage"
        [class.text-3xl]="!config.isSubPage"
      >
        <vs-icon *ngIf="config.icon" [name]="config.icon" size="30" class="text-gray-700"></vs-icon>
        <div
          [vsTooltip]="config.title"
          tooltipOpenDelay="350"
          tooltipPosition="top"
          tooltipStyle="light"
          class="truncate"
        >
          {{ config.title }}
        </div>
        <ng-content select="[slot='title-end']"></ng-content>
      </h1>

      <p class="text-sm text-gray-600 mt-1 empty:hidden">
        <ng-content select="[slot='before-description']"></ng-content>{{ config.description }}
      </p>
    </div>

    <div #end [class.hidden]="!end.children.length">
      <ng-content select="[slot='header-end']"></ng-content>
    </div>

    <vs-divider *ngIf="end.children.length && !config.showDivider" class="sm:hidden -mb-4"></vs-divider>
  </div>

  <vs-divider *ngIf="config.showDivider"></vs-divider>

  <div class="sticky top-0 empty:hidden"><ng-content select="[slot='sticky']"></ng-content></div>
</div>

<ng-content></ng-content>
