<div *ngIf="config" class="mb-4">
  <ng-template #icon>
    <vs-featured-icon [name]="config.icon" [theme]="config.iconTheme || 'default'"></vs-featured-icon>
  </ng-template>

  <ng-template #title>
    <div class="flex gap-2">
      <ng-content select="[slot='before-title']"></ng-content>
      <p class="text-lg font-semibold text-gray-900 pb-1 break-words" [innerHtml]="config.title"></p>
      <ng-content select="[slot='after-title']"></ng-content>
    </div>

    <p
      *ngIf="config.subtitle"
      class="text-sm font-normal text-gray-600 max-w-max break-words"
      [innerHtml]="config.subtitle"
    ></p>

    <ng-content></ng-content>
  </ng-template>

  <ng-template #button>
    <button
      *ngIf="!config.hideCloseButton"
      vs-icon-button
      class="text-xl"
      size="lg"
      appearance="clear"
      rounded="true"
      (click)="closeDialog()"
    >
      <vs-icon name="x-close"></vs-icon>
    </button>
  </ng-template>

  <ng-container [ngSwitch]="config.mode">
    <ng-container *ngSwitchCase="'simple'">
      <div class="flex items-start justify-between gap-4 mt-2">
        <ng-container *ngTemplateOutlet="icon"></ng-container>

        <div class="mt-[2px] flex-grow">
          <ng-container *ngTemplateOutlet="title"></ng-container>
        </div>

        <div class="flex justify-end">
          <ng-container *ngTemplateOutlet="button"></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'centered'">
      <div class="relative flex flex-col items-center gap-4">
        <div class="absolute top-0 right-0">
          <ng-container *ngTemplateOutlet="button"></ng-container>
        </div>

        <ng-container *ngTemplateOutlet="icon"></ng-container>
        <div class="text-center">
          <ng-container *ngTemplateOutlet="title"></ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="flex justify-between mb-4">
        <ng-container *ngTemplateOutlet="icon"></ng-container>
        <ng-container *ngTemplateOutlet="button"></ng-container>
      </div>

      <ng-container *ngTemplateOutlet="title"></ng-container>
    </ng-container>
  </ng-container>
</div>

<vs-divider *ngIf="config?.showDivider" class="-ml-4 mr-4" style="width: calc(100% + 32px)"></vs-divider>
