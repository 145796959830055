<ng-container *vsLet="distributors$ | async as distributors">
  <ng-container *ngIf="distributorCount; else noDistributors">
    <div class="flex justify-between gap-4 mt-4">
      <div class="flex gap-4">
        <vs-select
          vsInput
          class="w-[200px]"
          [config]="{ placeholder: 'All statuses' }"
          (valueChanges)="refreshTable({ status: $event })"
        >
          <li *ngFor="let status of statuses" vs-select-option [value]="status">
            <vs-badge [theme]="getTheme($any(status))" [config]="{ displayStatusIcon: true }">
              {{ status | titlecase }}
            </vs-badge>
          </li>
        </vs-select>

        <div *ngIf="!distributor" class="flex gap-2">
          <input
            #includeSubDistributors
            vsInput
            type="checkbox"
            theme="primary"
            class="self-center"
            appearance="toggle"
            tooltipStyle="light"
            style="border-radius: 12px"
            (input)="refreshTable({ includeSubDistributors: includeSubDistributors.checked })"
          />

          <label for="includeSubDistributors" class="text-sm font-medium text-gray-700 self-center cursor-pointer">
            Show sub-distributors
          </label>
        </div>
      </div>

      <vs-input-container class="max-w-[25%]">
        <vs-icon name="search-lg" slot="start"></vs-icon>
        <input #searchInput vsInput (keyup)="refreshTable({ search: searchInput.value })" placeholder="Search" />
      </vs-input-container>
    </div>

    <div class="rounded-xl overflow-hidden border border-gray-200 shadow-sm mt-4">
      <vs-table [columns]="columns" [data]="distributors" (rowClicked)="navigateTo($event.id)"></vs-table>

      <vs-pagination [config]="paginationConfig$ | async" (pagination)="pagination$.next($event)"></vs-pagination>
    </div>
  </ng-container>

  <ng-template #noDistributors>
    <div class="flex justify-center mt-4" [class]="_distributor ? '' : 'border rounded-2xl p-8 bg-base-white shadow'">
      <vs-empty-state
        [config]="{
          title: _distributor ? 'No sub-distributors found under this distributor.' : 'No distributors found.',
          subtitle:
            'To preview more ' +
            (_distributor ? 'sub-' : '') +
            'distributors you need to invite or add them, so you can manage them and their activities here.',
          imgsrc: 'distributor'
        }"
      >
        <ng-content slot="button" select="[slot='button']"></ng-content>
      </vs-empty-state>
    </div>
  </ng-template>
</ng-container>

<!-- Dialogs -->
<ws-create-distributor-dialog
  #addDistributorDialog
  [distributor]="_distributor"
  (closed)="navigateTo($event)"
></ws-create-distributor-dialog>
