import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Customer } from '@wsphere/customers/domain';
import { Warranty } from '@wsphere/warranties/domain';
import { WarrantyWebService } from '../../../warranty/services';

@Component({
  selector: 'ws-warranty-customer-card',
  templateUrl: './warranty-customer-card.component.html',
})
export class WarrantyCustomerCardComponent {
  constructor(private router: Router, private toastSvc: ToastService, private warrantySvc: WarrantyWebService) {}

  @Input() customer: Customer.Model | null = null;
  @Input() warranty!: Warranty.Model;

  overlayOpen = false;
  positions = [
    new ConnectionPositionPair({ originX: 'end', originY: 'center' }, { overlayX: 'start', overlayY: 'center' }),
  ];
  loading = false;

  navigateToCustomer(userId: string) {
    this.router.navigate(['/customers', userId]);
  }

  async sendWelcomeEmail() {
    this.loading = true;
    await this.warrantySvc.sendWelcomeEmail(this.warranty.id).catch(() => {
      this.toastSvc.show({
        type: 'error',
        title: 'Welcome email failed to resend ',
        text: `The welcome email for  <strong>${this.warranty.policy?.title}</strong>  failed to resend to <strong>${this.customer?.name}</strong>. Please try again or contact the customer.`,
      });
    });
    this.toastSvc.show({
      type: 'success',
      title: 'Welcome email resent',
      text: `<strong>${this.customer?.name}</strong> should see a welcome email for <strong>${this.warranty.policy?.title}</strong> in their inbox shortly.`,
    });
    this.loading = false;
    this.close();
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }
  close() {
    this.overlayOpen = false;
  }
}
