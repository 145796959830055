/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, forwardRef, Input, OnDestroy, Output } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { Customer } from '@wsphere/customers/domain';
import { Distributor } from '@wsphere/distributors/domain';
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { BehaviorSubject, combineLatest, map, Subscription } from 'rxjs';
import { CustomerService } from '../../services';

export interface CustomerInfo {
  email: string;
  name: string;
  phone: string;
}
@Component({
  selector: 'ws-customer-details-input',
  templateUrl: './customer-details-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CustomerDetailsInputComponent),
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => CustomerDetailsInputComponent),
    },
  ],
})
export class CustomerDetailsInputComponent implements ControlValueAccessor, Validator, OnDestroy {
  constructor(private fb: FormBuilder, private firebaseSvc: FirebaseService, private customerSvc: CustomerService) {}

  @PropertyListener('staffView') staffView$ = new BehaviorSubject(false);
  @Input() staffView = false;

  @Input() distributor: Distributor.Model | null = null;

  @Input() set value(value: CustomerInfo | undefined) {
    this.writeValue(value || null);
  }

  @Output() customerAdded = new EventEmitter<Customer.Model>();

  subscription?: Subscription;

  validNumber = true;
  customer: Customer.Model | null = null;

  form = this.fb.group({
    email: [{ value: null as string | null, disabled: false }, [Validators.required, Validators.email]],
    name: [{ value: null as string | null, disabled: false }, Validators.required],
    phone: [{ value: null as string | null, disabled: false }, [Validators.required]],
  });

  signedIn$ = this.firebaseSvc.authUser$;
  disableEmail$ = combineLatest([this.staffView$, this.signedIn$]).pipe(
    map(([staffView, signedIn]) => {
      if (!staffView && signedIn) {
        this.form.patchValue({ email: signedIn.email });
        this.form.get('email')?.disable();
        return true;
      } else {
        this.form.get('email')?.enable();
        return false;
      }
    })
  );

  onChange = (_customerInfo: CustomerInfo) => {};
  onTouched = () => {};

  writeValue(value: CustomerInfo | null): void {
    if (value) {
      this.form.setValue({
        email: value.email ?? null,
        name: value.name ?? null,
        phone: value?.phone ? new AsYouType('US').input(value.phone) : null,
      });
    }
    this.validateNumber();
  }

  registerOnChange(fn: any): void {
    this.subscription = this.form.valueChanges.subscribe(fn);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    if (disabled) this.form.disable();
    else this.form.enable();
  }

  async checkForCustomer(search: string) {
    if (!search.includes('@') || !search.includes('.')) return;

    const customers = this.staffView
      ? await this.customerSvc.checkCustomerForSales(search)
      : await this.customerSvc.listCustomers({ search });

    if (customers.items?.length === 1) this.customer = customers.items[0];
    else this.customer = null;
  }

  emitCustomer() {
    if (this.customer) this.customerAdded.emit(this.customer);
  }

  validateNumber() {
    if (!this.form.get('phone')?.value) {
      this.validNumber = true;
      return;
    }
    try {
      const phone = new AsYouType('US').input(this.form.get('phone')?.value as string);
      this.validNumber = isValidPhoneNumber(phone, 'US');
      return;
    } catch (e) {
      console.log(e);
    }
    this.validNumber = false;
  }

  phoneNumberKeyUp(phone: any) {
    if (phone.key === 'Backspace') return;
    this.formatPhoneNumber();
  }

  formatPhoneNumber() {
    const phoneNumber = this.form.get('phone')?.value;
    if (phoneNumber) {
      this.form.patchValue({ phone: new AsYouType('US').input(phoneNumber) });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }
    let errors = {};
    if (this.form.controls['email'].errors) {
      errors = { email: this.form.controls['email'].errors };
    }
    if (this.form.controls['name'].errors) {
      errors = { name: this.form.controls['name'].errors };
    }
    if (this.form.controls['phone'].errors) {
      errors = { phone: this.form.controls['phone'].errors };
    }
    return errors;
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
