/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Address } from '@vsolv/core/address/domain';
import { Config } from '@vsolv/packages/portal-config/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Distributor } from '@wsphere/distributors/domain';
import { DistributorService } from '@wsphere/distributors/web';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '@wsphere/warranties/web';
import { CurrencyMaskConfig } from 'ngx-currency';
import {
  BehaviorSubject,
  combineLatest,
  distinctUntilChanged,
  firstValueFrom,
  map,
  of,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'ws-web-organization-page',
  templateUrl: './organization.page.html',
})
export class OrganizationPage implements OnInit, OnDestroy {
  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private portalSvc: PortalService,
    private formBuilder: FormBuilder,
    private policySvc: PolicyService,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService
  ) {}

  private onDestroy$ = new Subject<void>();

  readonly currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: false,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 1,
  };

  protected distiSettings?: Distributor.RetrieveDistributorsSettingsResponse | null;
  private refresh$ = new BehaviorSubject<null>(null);
  staff$ = this.route.parent!.parent!.parent!.data.pipe(map(data => data['staff']));
  private readonly globalDistributors$ = this.securitySvc.globalDistributors$;

  config$ = this.refresh$.pipe(
    switchMap(async () => {
      const configData = this.portalSvc.getConfigData;
      this.portalConfigDetail = configData;
      this.resetForm();
      return configData;
    })
  );

  data$ = combineLatest([this.config$, this.staff$, this.refresh$]).pipe(
    map(([config, staff, refresh]) => ({
      config,
      staff,
      refresh,
    }))
  );

  canEditOrganization$ = this.globalDistributors$.pipe(
    switchMap(async globalDist => {
      return this.securitySvc.hasAccess('stf_EditSettings', globalDist?.map(dist => dist.permissionKey) || null);
    }),
    tap(canEdit => {
      canEdit ? this.form.enable() : this.form.disable();
      canEdit ? this.distributorForm.enable() : this.distributorForm.disable();
    })
  );

  distributorSaving = false;

  portalConfigDetail!: Config.PortalConfig;
  resetFormValue = {
    brandName: '',
    tagLine: '',
    productName: '',
    contactEmail: '',
    contactPhone: '',
    contactAddress: <Address.Model | null>null,
  };

  form = this.formBuilder.group({
    brandName: new FormControl({ value: '', disabled: false }, Validators.required),
    tagLine: new FormControl({ value: '', disabled: false }, Validators.required),
    productName: new FormControl({ value: '', disabled: false }, Validators.required),
    contactEmail: new FormControl({ value: '', disabled: false }, [Validators.required, Validators.email]),
    contactPhone: new FormControl({ value: '', disabled: false }, [Validators.required]),
    contactAddress: new FormControl({ value: <Address.Model | null>null, disabled: false }, [Validators.required]),
  });

  protected policies$ = of(null).pipe(
    switchMap(async () => {
      const policies: Policy.Model[] = [];

      let page: null | Policy.ListPoliciesQueryResponse = await this.policySvc.list({ limit: 100 });
      while (page) {
        policies.push(...page.items);
        page =
          page.meta.currentPage < (page.meta.totalPages ?? 0)
            ? await this.policySvc.list({ limit: 100, page: page.meta.currentPage + 1 })
            : null;
      }

      return policies;
    }),
    switchMap(async policies => {
      for (const policy of policies) {
        policy.plans = await this.policySvc.getPlans(policy.id).then(data =>
          data?.items?.map(plan => {
            return plan;
          })
        );
      }

      return policies.filter(pol => pol.plans?.length);
    }),
    shareReplay(1)
  );

  distributorForm = this.formBuilder.group({
    userRegistration: new FormControl({ value: false, disabled: false }),
    selfServeInvoicePackage: this.formBuilder.group({
      enabled: [false as boolean, Validators.required],
      logo: [null as File | null],
      companyName: [null as string | null, Validators.required],
      companyAddress: [null as string | null, Validators.required],
      bankingDetails: this.formBuilder.group({
        bank: [null as string | null],
        accountNumber: [null as string | null, [Validators.pattern(/\d+/)]],
        routingNumber: [null as string | null, [Validators.pattern(/\d+/)]],
      }),
      unitPrices: this.formBuilder.array<
        FormGroup<{
          plan: FormControl<string>;
          price: FormControl<number | null>;
        }>
      >([]),
    }),
  });

  resetFormValues() {
    this.resetFormValue = {
      brandName: this.portalConfigDetail.brandName || '',
      productName: this.portalConfigDetail.productName || '',
      tagLine: this.portalConfigDetail.tagLine || '',
      contactEmail: this.portalConfigDetail.contactEmail || '',
      contactPhone: this.portalConfigDetail.contactPhone || '',
      contactAddress: this.portalConfigDetail.contactAddress || null,
    };
  }

  inputIsValid() {
    return this.form.valid;
  }

  async resetForm() {
    try {
      this.resetFormValues();
      this.form.reset(this.resetFormValue);
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  async confirm() {
    this.form.disable();
    try {
      await this.updateOrgDetails();
      this.resetForm();
      this.toastSvc.show({
        type: 'success',
        title: 'Organization settings updated',
        text: 'Your administration portal settings have been successfully updated.',
      });
    } catch (e) {
      this.toastSvc.show({
        type: 'success',
        title: 'Error',
        text: `${e}`,
      });
    }
    this.form.enable();
  }

  async saveDistributor() {
    if (
      !this.distributorForm.dirty ||
      (this.distributorForm.value.selfServeInvoicePackage?.enabled &&
        this.distributorForm.controls['selfServeInvoicePackage'].invalid)
    ) {
      return;
    }
    this.distributorSaving = true;

    const value = this.distributorForm.value;

    const toBase64 = (file: File) => {
      return new Promise<string | null>(res => {
        const reader = new FileReader();

        reader.onload = () => {
          const img = document.createElement('img');

          img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = (img.width * 64) / img.height;
            canvas.height = 64;

            const ctx = canvas.getContext('2d')!;
            ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

            const dataUrl = canvas.toDataURL(file.type);

            canvas.remove();
            img.remove();

            res(dataUrl);
          };

          img.src = reader.result as string;
        };

        reader.readAsDataURL(file);
      });
    };

    const logoFile = this.distributorForm.value.selfServeInvoicePackage?.logo;
    const logoUrl = logoFile ? await toBase64(logoFile) : null;

    const updated = await this.distributorSvc
      .updateSettings({
        canRegisterUsers: !!value.userRegistration,
        selfServeInvoicePackage: {
          enabled: this.distributorForm.value.selfServeInvoicePackage?.enabled ?? false,

          logo: logoUrl,
          companyName: this.distributorForm.value.selfServeInvoicePackage?.companyName,
          companyAddress: this.distributorForm.value.selfServeInvoicePackage?.companyAddress,

          bankingDetails: {
            bank: this.distributorForm.value.selfServeInvoicePackage?.bankingDetails?.bank ?? null,
            accountNumber: this.distributorForm.value.selfServeInvoicePackage?.bankingDetails?.accountNumber ?? null,
            routingNumber: this.distributorForm.value.selfServeInvoicePackage?.bankingDetails?.routingNumber ?? null,
          },

          unitPrices:
            (this.distributorForm.value.selfServeInvoicePackage?.unitPrices
              ?.filter(p => p.plan)
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              .map(p => ({ ...p, price: !p.price ? null : p.price * 100 })) as any) ?? [],
        },
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.distributorSaving = false;
      });

    if (updated) {
      this.toastSvc.show({
        type: 'success',
        title: 'Distributor Settings Updated',
        text: 'Successfully updated distributor settings for your organization.',
      });

      this.distributorForm.markAsUntouched();
      this.distributorForm.reset(this.distributorForm.value);

      this.distributorForm.updateValueAndValidity();

      this.distributorSaving = false;
    }
  }

  async updateOrgDetails() {
    const formControlValue = this.form.value;

    const updatedPortalConfig: Config.UpdatePortalConfigDetailsRequestDto = {};

    if (formControlValue.brandName !== this.resetFormValue.brandName) {
      updatedPortalConfig.brandName = formControlValue.brandName || '';
    }

    if (formControlValue.tagLine !== this.resetFormValue.tagLine) {
      updatedPortalConfig.tagLine = formControlValue.tagLine || '';
    }

    if (formControlValue.productName !== this.resetFormValue.productName) {
      updatedPortalConfig.productName = formControlValue.productName || '';
    }

    if (formControlValue.contactEmail !== this.resetFormValue.contactEmail) {
      updatedPortalConfig.contactEmail = formControlValue.contactEmail || '';
    }

    if (formControlValue.contactPhone !== this.resetFormValue.contactPhone) {
      updatedPortalConfig.contactPhone = formControlValue.contactPhone || '';
    }

    if (formControlValue.contactAddress !== this.resetFormValue.contactAddress) {
      updatedPortalConfig.contactAddress = formControlValue.contactAddress || null;
    }

    const result = await this.portalSvc.updateConfig(updatedPortalConfig);
    this.portalConfigDetail = result.config;
  }

  refreshPage() {
    this.refresh$.next(null);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  async ngOnInit() {
    this.distributorForm.controls.selfServeInvoicePackage.controls.enabled.valueChanges
      .pipe(takeUntil(this.onDestroy$), startWith(false), distinctUntilChanged())
      .subscribe(enabled => this.toggleSelfServe(!!enabled));

    this.distiSettings = await this.distributorSvc.getSettings();
    const hasAccess = await this.securitySvc.hasAccess('stf_EditSettings', null);

    if (this.distiSettings) {
      this.distributorForm.disable();

      this.distributorForm.controls.userRegistration.reset(this.distiSettings.canRegisterUsers ?? null);

      this.distributorForm.controls.selfServeInvoicePackage.reset({
        enabled: this.distiSettings.selfServeInvoicePackage?.enabled ?? false,

        logo: null,
        companyName: this.distiSettings.selfServeInvoicePackage?.companyName ?? null,
        companyAddress: this.distiSettings.selfServeInvoicePackage?.companyAddress ?? null,

        bankingDetails: {
          bank: this.distiSettings.selfServeInvoicePackage?.bankingDetails?.bank ?? null,
          accountNumber: this.distiSettings.selfServeInvoicePackage?.bankingDetails?.accountNumber ?? null,
          routingNumber: this.distiSettings.selfServeInvoicePackage?.bankingDetails?.routingNumber ?? null,
        },

        unitPrices: [],
      });

      const array = this.distributorForm.controls.selfServeInvoicePackage.controls.unitPrices;
      array.clear();

      const policies = await firstValueFrom(this.policies$);

      policies.forEach(policy => {
        policy.plans?.forEach(plan => {
          const price = this.distiSettings?.selfServeInvoicePackage?.unitPrices?.find(p => p.plan === plan.id)?.price;

          array.insert(
            array.controls.length,
            this.formBuilder.group({
              plan: [plan.id as string, Validators.required],
              price: [
                (price === null || price === undefined ? null : price / 100) as number | null,
                [Validators.min(0)],
              ],
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
            }) as any
          );
        });
      });

      if (hasAccess) this.distributorForm.enable();
      if (!this.distributorForm.value.selfServeInvoicePackage?.enabled) {
        this.toggleSelfServe(false);
      }
    }
  }

  getControlIndex(planId: string) {
    return {
      index: this.distributorForm.controls.selfServeInvoicePackage.controls.unitPrices.controls.findIndex(
        c => c.value.plan === planId
      ),
    };
  }

  private toggleSelfServe(enabled: boolean) {
    Object.entries(this.distributorForm.controls.selfServeInvoicePackage.controls)
      .filter(([key]) => key !== 'enabled')
      .map(([_, control]) => control)
      .forEach(control => {
        if (enabled) control.enable();
        else control.disable();
      });
  }
}
