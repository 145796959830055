import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressModule } from '@vsolv/core/address/web';
import { ToastModule } from '@vsolv/ui-kit/toast';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DatePickerModule } from '@vsolv/vectors-ui/date-picker';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import {
  AddressPropertyInputComponent,
  BooleanInputFieldComponent,
  DateInputComponent,
  DateInputFieldComponent,
  HomeInspectionPropertyInputComponent,
  NumberInputComponent,
  NumberInputFieldComponent,
  ObjectInputFieldComponent,
  PropertyInputFormFieldComponent,
  PropertySetBadgesComponent,
  PropertySetValuesComponent,
  PropertyValueItemComponent,
  TextInputComponent,
  TextInputFieldComponent,
  VehiclePropertyInputComponent,
} from './components';
import { PropertyBadgeComponent } from './components/property-badge/property-badge.component';
import { PropertySetInputComponent } from './components/property-set-input/property-set-input.component';
import { PropertySetPickerComponent } from './components/property-set-picker/property-set-picker.component';
import { PROPERTY_FORMS, PropertyForm } from './constants';
import { PropertyAssignmentSortPipe, TransformPropertyValuePipe } from './pipes';
import { PropertyValueToStringArrayPipe } from './pipes/property-value-to-string.pipe';
import { PropertyService } from './services';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    UtilsModule,
    ToastModule,
    IconsModule,
    ButtonModule,
    DialogModule,
    DividerModule,
    ThemingModule,
    TooltipModule,
    UserAvatarModule,
    SelectModule,
    InputModule,
    DatePickerModule,
    MenuModule,
    AddressModule,
    OverlayModule,
    BadgeModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgPipesModule,
  ],
  providers: [PropertyService],
  declarations: [
    //Pipes
    PropertyAssignmentSortPipe,
    TransformPropertyValuePipe,
    PropertyValueToStringArrayPipe,

    //Components
    AddressPropertyInputComponent,
    VehiclePropertyInputComponent,
    PropertyInputFormFieldComponent,
    BooleanInputFieldComponent,
    DateInputFieldComponent,
    NumberInputFieldComponent,
    ObjectInputFieldComponent,
    TextInputFieldComponent,
    PropertySetInputComponent,
    PropertySetPickerComponent,
    PropertyBadgeComponent,
    PropertySetBadgesComponent,
    HomeInspectionPropertyInputComponent,
    PropertyValueItemComponent,
    PropertySetValuesComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
  ],
  exports: [
    //Pipes
    PropertyAssignmentSortPipe,
    TransformPropertyValuePipe,

    //Components
    PropertySetInputComponent,
    PropertySetPickerComponent,
    PropertyBadgeComponent,
    PropertySetBadgesComponent,
    AddressPropertyInputComponent,
    VehiclePropertyInputComponent,
    HomeInspectionPropertyInputComponent,
    PropertyValueItemComponent,
    PropertySetValuesComponent,
    TextInputComponent,
    NumberInputComponent,
    DateInputComponent,
  ],
})
export class PropertiesWebModule {
  static forRoot(forms: PropertyForm[]): ModuleWithProviders<PropertiesWebModule> {
    return {
      ngModule: PropertiesWebModule,
      providers: [{ provide: PROPERTY_FORMS, useValue: forms }],
    };
  }
}
