import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';

@Component({
  selector: 'ws-copy-sftp-key-dialog',
  templateUrl: './copy-sftp-key.dialog.html',
})
export class CopySftpKeyDialog {
  constructor(private toastSvc: ToastService) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;
  @ViewChild('keyInput') keyInput!: ElementRef;

  @Output() closed = new EventEmitter<null>();

  key?: string;

  openDialog(key: string) {
    this.key = key;
    this.dialog.open();
  }

  onClose() {
    this.closed.emit();
  }

  copy() {
    this.toastSvc.show({
      type: 'success',
      text: 'Your secret key is now copied to your clipboard!',
    });
  }
}
