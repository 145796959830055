<table
  cdk-table
  class="w-full border-separate"
  style="border-spacing: 0"
  cdkDropList
  [dataSource]="data ?? []"
  [trackBy]="trackBy"
  (cdkDropListDropped)="drop($event)"
>
  <ng-container cdkColumnDef="draggable">
    <th
      cdk-header-cell
      *cdkHeaderCellDef
      class="py-3 px-6 text-left text-xs font-medium text-gray-600 bg-base border-b border-gray-200 whitespace-nowrap"
    ></th>

    <td
      cdk-cell
      *cdkCellDef="let item"
      class="py-2 px-6 bg-base border-b border-gray-200 w-px"
      [class.group-hover:bg-gray-100]="rowClicked.observed"
      [class.group-odd:bg-gray-50]="config?.banding !== false"
    >
      <ng-container vsTableCell [item]="item" [column]="draggableColumn"></ng-container>
    </td>
  </ng-container>

  <ng-container
    *ngFor="let column of columns"
    [cdkColumnDef]="column.id"
    [sticky]="column.config.sticky"
    [stickyEnd]="column.config.stickyEnd"
  >
    <th
      cdk-header-cell
      *cdkHeaderCellDef
      class="py-3 px-6 text-left text-xs font-medium text-gray-600 bg-base border-b border-gray-200 whitespace-nowrap"
    >
      <div
        [class]="
          'group flex items-center gap-2' +
          (column.config.sortable ? ' cursor-pointer hover:text-gray-900 select-none' : '') +
          (column.config.align === 'center' ? ' justify-center' : '') +
          (column.config.align === 'right' ? ' justify-end' : '')
        "
        (click)="column.config.sortable && toggleSort(column.id)"
      >
        <span>{{ column.config.header }}</span>
        <vs-icon
          *ngIf="column.config.sortable || column.config.sort"
          [class]="!column.config.sort ? 'opacity-50 group-hover:opacity-100' : ''"
          [name]="
            !column.config.sort
              ? 'switch-vertical-01'
              : column.config.sort === 'DESC'
              ? 'arrow-narrow-down'
              : 'arrow-narrow-up'
          "
        ></vs-icon>
      </div>
    </th>

    <td
      cdk-cell
      *cdkCellDef="let item"
      class="py-2 px-6 bg-base border-b border-gray-200 {{ column.config.customCellClasses ?? '' }}"
      [class.group-hover:bg-gray-100]="rowClicked.observed"
      [class.group-odd:bg-gray-50]="config?.banding !== false"
      [class.w-px]="column.config.fitContent"
    >
      <ng-container vsTableCell [item]="item" [column]="column"></ng-container>
    </td>
  </ng-container>

  <ng-container *vsLet="columns | vsTableColumnIds : config?.draggable as cols">
    <tr cdk-header-row *cdkHeaderRowDef="cols" class="bg-base"></tr>
    <tr
      *cdkRowDef="let row; columns: cols"
      cdk-row
      cdkDrag
      cdkDragLockAxis="y"
      cdkDragBoundary=".cdk-table"
      class="group bg-base odd:bg-gray-50"
      [cdkDragDisabled]="!config?.draggable"
      [class.cursor-pointer]="rowClicked.observed"
      (click)="rowClicked.emit(row)"
    ></tr>
  </ng-container>
</table>
