/* eslint-disable @typescript-eslint/ban-types */
import { Component, TemplateRef } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { TableCellComponent } from '../../models';

export interface IconCellProps {
  icon: IconName | null;
  theme?: ThemeColor;
  size?: number;
  hideBackground?: boolean;

  iconTooltip?: string | { title: string; subtitle: string } | { template: TemplateRef<unknown>; context?: Object };

  click?($event: Event): void | Promise<void>;
}

@Component({
  template: `
    <div
      *ngIf="data && data.icon"
      class="flex justify-center p-2 rounded-full"
      [class.bg-themed-50]="!data.hideBackground"
      [theme]="data.theme ?? 'primary'"
      (click)="_click($event)"
    >
      <vs-icon
        tooltipStyle="light"
        class="self-center text-themed-500"
        [name]="data.icon"
        [size]="data.size ?? 24"
        [vsTooltip]="data.iconTooltip"
        [theme]="data.theme ?? 'primary'"
      ></vs-icon>
    </div>
  `,
})
export class IconCellComponent extends TableCellComponent<IconCellProps> {
  async _click($event: Event) {
    await this.data?.click?.($event);
  }
}
