import { Operator } from './operator';
import { PropertyType, PropertyValueType } from './property';

export enum Comparison {
  AND = 'AND',
  OR = 'OR',
}

export interface Condition<T extends PropertyValueType> {
  propertyId: string;
  operator: Operator;
  type: PropertyType;
  value: T | null;
  valuePropertyId?: string | null;
}

export interface ConditionGroup {
  comparison: Comparison;
  conditions: (Condition<PropertyValueType> | ConditionGroup)[];
}
