/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Distributor } from '@wsphere/distributors/domain';
import { DistributorService } from '@wsphere/distributors/web';
import { SecurityService } from '@wsphere/staff/web';
import { Provisioning } from '@wsphere/warranties/domain';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'ws-provisioning-distributor-picker',
  templateUrl: './provisioning-distributor-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProvisioningDistributorPickerComponent,
    },
  ],
})
export class ProvisioningDistributorPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(
    public elementRef: ElementRef,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService
  ) {}

  @PropertyListener('value') value$ = new ReplaySubject<Distributor.Model>(1);
  @Input() value: Distributor.Model | null = null;

  @PropertyListener('session') session$ = new BehaviorSubject<Provisioning.ProvisioningSession | null>(null);
  @Input() session: Provisioning.ProvisioningSession | null = null;

  @Input() placeholder = 'Find a distributor...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<Distributor.Model | null>();

  touched = false;
  overlayOpen = false;

  readonly searchQuery$ = new BehaviorSubject<string>('');

  readonly globalDistributors$ = this.securitySvc.globalDistributors$;

  distributors$: Observable<Distributor.Model[]> = combineLatest([
    this.searchQuery$,
    this.session$,
    this.globalDistributors$,
  ]).pipe(
    switchMap(async ([search]) => {
      return await this.distributorSvc.listDistributorsForSales({ search });
    }),
    map(distributor => distributor.items),
    tap(data => {
      if (data.length === 1) {
        this.selectValueChanged(data[0]);
        this.valueChanges.next(data[0]);
      }
    })
  );

  onChange = (_value: Distributor.Model | null) => {};
  onTouched = () => {};

  @Input() compareWith: (v0: Distributor.Model | null, v1: Distributor.Model | null) => boolean = (
    v0: Distributor.Model | null,
    v1: Distributor.Model | null
  ) => {
    switch (typeof v0) {
      case 'object':
        return v0?.id === v1?.id;
      default:
        return v0 === v1;
    }
  };

  selectValueChanged(value: Distributor.Model) {
    this.markAsTouched();
    this.value = value;
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  searchFor(search: string) {
    this.searchQuery$.next(search);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: Distributor.Model | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: Distributor.Model | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
