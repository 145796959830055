import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { PlanResolver } from '../resolvers';
import { PolicyService } from '../services';

@Injectable()
export class PlanGuard implements CanActivate {
  constructor(private resolver: PlanResolver, private policySvc: PolicyService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.plan = null;

    const policyId = this.findPolicyId(route);
    if (!policyId) return false;

    const planId = this.findPlanId(route);
    if (!planId) return false;

    this.resolver.plan = await this.policySvc.getPlan(policyId, planId);
    if (!this.resolver.plan) return false;

    return true;
  }

  private findPolicyId(route: ActivatedRouteSnapshot): string {
    return route.params['policyId'] || (route.parent ? this.findPolicyId(route.parent) : null);
  }

  private findPlanId(route: ActivatedRouteSnapshot): string {
    return route.params['planId'] || (route.parent ? this.findPolicyId(route.parent) : null);
  }
}
