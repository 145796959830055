import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';

interface SelectOptionObj {
  name: string;
}

@Component({
  selector: 'ws-web-components-page',
  templateUrl: './components.page.html',
})
export class ComponentsPage implements OnInit {
  constructor(private formBuilder: FormBuilder) {}

  colorFormGroup = this.formBuilder.group({
    selectObj: [[{ name: 'Option 2' }, { name: 'Option 4' }] as SelectOptionObj[]],
    colorInput: [null],
    fileInput: [null as File | null],
  });

  ngOnInit() {
    // this.colorFormGroup.get('fileInput')?.disable();
    this.colorFormGroup.valueChanges.subscribe(console.log);
  }
}
