/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Distributor } from '@wsphere/distributors/domain';
import { BehaviorSubject, ReplaySubject, firstValueFrom, map, switchMap } from 'rxjs';
import { GLOBAL_DISTRIBUTORS_STORAGE_KEY, SecurityService } from '../../services';

export interface PaginatedDistributor {
  distributor: Distributor.PartialModel | null;
  page: number;
  totalPages: number;
  children: PaginatedDistributor[];
}

export interface PaginatedDistributorNavigation {
  parent: PaginatedDistributor | null;
  current: PaginatedDistributor;
}

@Component({
  selector: 'ws-global-distributors-picker',
  templateUrl: './global-distributors-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GlobalDistributorsPickerComponent,
    },
  ],
})
export class GlobalDistributorsPickerComponent implements OnInit, OnDestroy {
  constructor(private securitySvc: SecurityService, public elementRef: ElementRef) {}

  @Input() value: Distributor.PartialModel[] | null = [];
  @PropertyListener('value') value$ = new BehaviorSubject<Distributor.PartialModel[] | null>(null);

  @Input() placeholder = 'Select a distributor...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<Distributor.PartialModel[] | null | undefined>();

  protected readonly paginatedDistributorNavigation$ = new ReplaySubject<PaginatedDistributorNavigation>(1);

  protected breadcrumbs = new Map<string, PaginatedDistributorNavigation>();

  readonly searchQuery$ = new BehaviorSubject<string>('');

  overlayOpen = false;
  touched = false;

  hasGlobalAccess = false;

  loaded = false;
  loading = false;
  theEnd = false;

  loadedDistributors: PaginatedDistributor | null = null;

  searchedDistributors$ = this.searchQuery$.pipe(
    switchMap(async search => {
      const crumbs = Array.from(this.breadcrumbs.keys());
      const distributorId = crumbs[crumbs.length - 1];

      return await this.securitySvc.searchDistributorsWithPermissions(
        {},
        search || undefined,
        distributorId === 'home' ? undefined : distributorId
      );
    }),
    map(results => results.items)
  );

  async loadMore(parent: PaginatedDistributor, parentId?: string) {
    if (this.theEnd) return;
    if (!this.loading && parent.page < parent.totalPages) {
      this.loading = true;
      parent.page++;
      const children = await this.securitySvc.getDistributorsWithAccess({ page: parent.page, limit: 10 }, parentId);
      if (parent.page === parent.totalPages) this.theEnd = true;
      const child = children.items.map(child => {
        return {
          distributor: child,
          page: 1,
          totalPages: -1,
          children: [],
        };
      });
      parent.children = [...parent.children, ...child];
      this.loading = false;
    }
  }

  async loadChildren(parent: PaginatedDistributor, parentId: string) {
    if (parent.totalPages === -1) {
      const children = await this.securitySvc.getDistributorsWithAccess({}, parentId);

      parent.page = 1;
      parent.totalPages = children.meta.totalPages || 0;
      parent.children = children.items.map(child => ({ distributor: child, page: 1, totalPages: -1, children: [] }));
    }
  }

  async ngOnInit() {
    await this.securitySvc.loadPermissionsMatrixFromStorage();
    const permissions = await this.securitySvc.loadPermissionsMatrixFromStorage();
    this.hasGlobalAccess = permissions.some(permission => permission.permissionKey === null);

    const stored = sessionStorage.getItem(GLOBAL_DISTRIBUTORS_STORAGE_KEY);
    if (stored) {
      const storedDistributorIds = JSON.parse(stored) as string[];
      if (storedDistributorIds.length) {
        const selectedDistributors = await this.securitySvc.getDistributorsWithAccess(
          { page: 1, limit: storedDistributorIds.length },
          undefined,
          storedDistributorIds
        );
        if (selectedDistributors.items.length) {
          this.selectValueChanged(selectedDistributors.items);
        }
      }
    }

    const initialDistributors = await this.securitySvc.getDistributorsWithAccess({});
    this.loadedDistributors = {
      distributor: null,
      page: 1,
      totalPages: initialDistributors.meta.totalPages || 0,
      children:
        initialDistributors.items.map(distributor => {
          return {
            distributor,
            page: 1,
            totalPages: -1,
            children: [],
          };
        }) ?? [],
    };

    this.loadedDistributors.children.map(async child => {
      child.distributor ? await this.loadChildren(child, child.distributor.id) : '';
    });

    this.paginatedDistributorNavigation$.next({ parent: null, current: this.loadedDistributors });
    this.breadcrumbs.set('home', { parent: null, current: this.loadedDistributors });
    const globalDistributors = await firstValueFrom(this.securitySvc.globalDistributors$);

    if (!this.hasGlobalAccess && !globalDistributors) {
      this.value = this.loadedDistributors.children.length ? [this.loadedDistributors.children[0].distributor!] : [];
      this.securitySvc.setGlobalDistributors(this.value && this.value.length > 0 ? this.value : null);
    } else {
      this.value = globalDistributors?.length ? globalDistributors : [];
      this.securitySvc.setGlobalDistributors(this.value && this.value.length > 0 ? this.value : null);
    }
  }

  searchIncludes(distributor: Distributor.PartialModel, list: Distributor.PartialModel[]) {
    return list.some(dist => dist.id === distributor.id);
  }

  onChange = (_value: Distributor.PartialModel[] | null | undefined) => {};
  onTouched = () => {};

  selectValueChanged(value: Distributor.PartialModel[] | null) {
    this.searchQuery$.next('');
    // When "All Distributors" is selected, it adds null to the array, so we check for that and change the value to null
    if (value && value.some(v => v === null)) value = null;
    this.markAsTouched();
    this.value = value ? value.filter(value => value !== null) : value;
    this.onChange(this.value);
    this.valueChanges.next(this.value);
    this.securitySvc.setGlobalDistributors(value && value.length > 0 ? value : null);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  select(distributor: PaginatedDistributor) {
    if (!distributor.distributor) return;
    if (!this.value) this.selectValueChanged([distributor.distributor]);
    else this.selectValueChanged([...this.value, distributor.distributor]);
  }

  remove(distributor: PaginatedDistributor) {
    const children = distributor.children?.length ? this.getDistributorChild(distributor, false, []) : [];
    if (this.value) {
      const filterChildDistributors = this.value.filter(
        distributor => !children.some(children => children.id === distributor.id)
      );
      const filteredDistributors = filterChildDistributors.filter(dist => dist.id !== distributor.distributor?.id);
      this.selectValueChanged(filteredDistributors);
    }
  }

  getDistributorChild(
    distributor: PaginatedDistributor,
    removeSelectedChildren: boolean,
    children: Distributor.PartialModel[]
  ) {
    const result = [...children];
    if (distributor.children?.length) {
      distributor.children.forEach(children => {
        if (children.distributor) {
          if (removeSelectedChildren) {
            if (!this.searchIncludes(children.distributor, this.value ?? [])) {
              result.push(children.distributor);
            }
          } else {
            if (this.searchIncludes(children.distributor, this.value ?? [])) {
              result.push(children.distributor);
            }
          }
        }
        this.getDistributorChild(children, removeSelectedChildren, result);
      });
    }
    return result;
  }

  selectDistributor(distributor: Distributor.PartialModel) {
    if (!this.value) {
      this.selectValueChanged([distributor]);
    } else {
      this.selectValueChanged([...this.value, distributor]);
    }
  }

  removeDistributor(distributor: Distributor.PartialModel) {
    if (this.value) {
      this.selectValueChanged(this.value.filter(dist => dist.id !== distributor.id));
    }
  }

  async navigateAndSave(distributorId: string, route: PaginatedDistributorNavigation) {
    route.current.children.map(async child => {
      child.distributor ? await this.loadChildren(child, child.distributor.id) : '';
    });

    this.paginatedDistributorNavigation$.next(route);
    this.breadcrumbs.set(distributorId, route);
  }

  navigate(distributorId: string) {
    if (this.breadcrumbs.has(distributorId)) {
      const route = this.breadcrumbs.get(distributorId);
      if (route) {
        route.current.children.map(async child => {
          child.distributor ? await this.loadChildren(child, child.distributor.id) : '';
        });

        this.paginatedDistributorNavigation$.next(route);
      }
    }
  }

  writeValue(value: Distributor.PartialModel[] | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: Distributor.PartialModel[] | null | undefined) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
