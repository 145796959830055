import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Dashboard } from '@vsolv/packages/dashboarding/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { DashboardService } from '../../services';

@Component({
  selector: 'vs-create-dashboard-dialog',
  templateUrl: './create-dashboard.dialog.html',
})
export class CreateDashboardDialog {
  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() created = new EventEmitter<Dashboard.CreateDashboardResponse>(true);

  private _submitting = false;
  get submitting() {
    return this._submitting;
  }

  private _forceIsDefault = false;

  readonly form = this.formBuilder.group({
    title: [null as string | null, [Validators.required]],
    description: [null as string | null, [Validators.required]],
    embedUrl: [null as string | null, [Validators.required]],
    isDefault: [false, [Validators.required]],
  });

  constructor(
    private toastSvc: ToastService,
    private formBuilder: FormBuilder,
    private dashboardSvc: DashboardService
  ) {}

  open(forceIsDefault?: boolean) {
    this._forceIsDefault = forceIsDefault ?? false;

    this.reset();
    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }

  reset() {
    this.form.reset();

    if (this._forceIsDefault) {
      this.form.controls.isDefault.reset(true);
      this.form.controls.isDefault.disable();
    } else {
      this.form.controls.isDefault.reset(false);
      this.form.controls.isDefault.enable();
    }
  }

  async submit() {
    this._submitting = true;
    this.form.disable();

    const { title, description, embedUrl, isDefault } = this.form.getRawValue();

    try {
      if (!title || !description || !embedUrl) throw new Error('Invalid form data');

      const response = await this.dashboardSvc.create({
        isDefault: isDefault ?? false,
        icon: 'bar-chart-square-02',
        description,
        embedUrl,
        title,
      });

      this.toastSvc.show({
        type: 'success',
        title: 'Dashboard created!',
        text: `Your new dashboard "<strong>${title}</strong>" has been successfully created.`,
      });

      this.close();

      this.created.emit(response);
    } catch (err) {
      console.error(err);
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: `Something went wrong when creating your dashboard "<strong>${title}</strong>", please try again.`,
      });
    }

    this._submitting = false;

    this.form.enable();
    if (this._forceIsDefault) {
      this.form.controls.isDefault.reset(true);
      this.form.controls.isDefault.disable();
    }
  }
}
