import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { map, switchMap } from 'rxjs';
import { ClaimItemDetailsDialog, OpenClaimDialog } from '../../dialogs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-overview',
  templateUrl: './claim-overview.page.html',
})
export class ClaimOverviewPage implements AfterViewInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private securitySvc: SecurityService
  ) {
    this.route.parent?.queryParams.subscribe(async params => {
      this.openCreateDialog = params?.['createItem'] === 'true';
      this.closeItemDialog = params?.['closeItemDialog'] === 'true';
      if (this.closeItemDialog) {
        await this.refresh();
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: null });
      }
    });
  }

  @ViewChild('claimDialog') claimDialog?: OpenClaimDialog;
  @ViewChild('createItemDialog') createItemDialog!: ClaimItemDetailsDialog;

  routeRefresh$ = this.route.queryParams.pipe(
    map(async params => {
      const refresh = params['refresh'] === 'true';
      if (refresh) {
        await this.refresh();
        this.router.navigate(['.'], { relativeTo: this.route, queryParams: null });
      }
    })
  );

  readonly claim$ = this.claimSvc.getClaim();

  canViewClaim$ = this.claim$.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ViewClaim', permissionKey);
    })
  );

  canEditClaim$ = this.claim$.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;

      return await this.securitySvc.hasAccess('clm_EditClaim', permissionKey);
    })
  );

  canOpenClaim$ = this.claim$.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_OpenClaim', permissionKey);
    })
  );

  open = Claim.Status.OPEN;
  claimId: string | null = null;
  openCreateDialog: boolean | null = null;
  closeItemDialog: boolean | null = null;

  openClaimDialog() {
    this.claimDialog?.openDialog();
  }

  openCreateItemDialog() {
    this.createItemDialog?.openDialog();
  }

  async refresh() {
    await this.claimSvc.refreshClaim();
  }

  ngAfterViewInit(): void {
    if (this.openCreateDialog) {
      this.openCreateItemDialog();
      this.router.navigate(['.'], { relativeTo: this.route });
    }
  }
}
