import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import {
  AddBankDetailsComponent,
  CreateCustomerDwollaComponent,
  CreateFundingSourceDwollaComponent,
  ManageBankDetailsComponent,
  PaymentMethodPickerComponent,
} from './components';
import { AddNewPaymentMethodDialog, ManageBankingDetailsDialog } from './dialog';
import { DwollaService } from './services';
import { DwollaConfig, DWOLLA_CONFIG } from './tokens';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    RouterModule,
    ReactiveFormsModule,
    OverlayModule,
    DialogModule,
    UtilsModule,
    IconsModule,
    ThemingModule,
    DividerModule,
    InputModule,
    ButtonModule,
    FormsModule,
    SelectModule,
    TooltipModule,
  ],
  providers: [DwollaService],
  exports: [
    ManageBankingDetailsDialog,
    ManageBankDetailsComponent,
    AddBankDetailsComponent,
    PaymentMethodPickerComponent,
    AddNewPaymentMethodDialog,
  ],
  declarations: [
    ManageBankingDetailsDialog,
    ManageBankDetailsComponent,
    AddBankDetailsComponent,
    CreateCustomerDwollaComponent,
    CreateFundingSourceDwollaComponent,
    PaymentMethodPickerComponent,
    AddNewPaymentMethodDialog,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReimbursementWebModule {
  static forRoot(config: DwollaConfig): ModuleWithProviders<ReimbursementWebModule> {
    return {
      ngModule: ReimbursementWebModule,
      providers: [{ provide: DWOLLA_CONFIG, useValue: config }],
    };
  }
}
