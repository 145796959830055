import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { InputContainerComponent, InputGroupComponent } from './components';
import { ColorInputComponent } from './components/color-input/color-input.component';
import {
  CheckboxInputDirective,
  CheckboxLabelDirective,
  ColorInputDirective,
  DateInputDirective,
  FileInputDirective,
  InputBoxDirective,
  InputGroupItemDirective,
  RadioInputDirective,
  SelectInputDirective,
  TextareaAutosizeDirective,
  TextareaInputDirective,
  TextInputDirective,
} from './directives';

export * from './components';
export * from './directives';

@NgModule({
  imports: [
    CommonModule,
    InputBoxDirective,
    InputGroupItemDirective,
    SelectInputDirective,
    TextInputDirective,
    CheckboxInputDirective,
    CheckboxLabelDirective,
    TextareaInputDirective,
    RadioInputDirective,
    DateInputDirective,
    ColorInputDirective,
    FileInputDirective,
    TextareaAutosizeDirective,
    IconsModule,
    TooltipModule,
    ThemingModule,
    ReactiveFormsModule,
    UtilsModule,
  ],
  declarations: [InputContainerComponent, InputGroupComponent, ColorInputComponent, ColorInputComponent],
  exports: [
    InputContainerComponent,
    InputGroupComponent,
    InputBoxDirective,
    InputGroupItemDirective,
    SelectInputDirective,
    TextInputDirective,
    CheckboxInputDirective,
    CheckboxLabelDirective,
    TextareaInputDirective,
    RadioInputDirective,
    DateInputDirective,
    ColorInputDirective,
    FileInputDirective,
    TextareaAutosizeDirective,
    ColorInputComponent,
  ],
})
export class InputModule {}
