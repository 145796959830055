import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import {
  BadgeColumn,
  MultiTagColumn,
  PaginationConfig,
  TableColumn,
  TextColumn,
  UserInfoColumn,
} from '@vsolv/vectors/table';
import { Customer } from '@wsphere/customers/domain';
import { SecurityService } from '@wsphere/staff/web';
import { Policy, Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  map,
  Observable,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { WarrantyWebService } from '../../../warranty/services';

export type WarrantyListItem = Warranty.ListResponse['items'][number];

@Component({
  selector: 'ws-warranty-table',
  templateUrl: './warranty-table.component.html',
})
export class WarrantyTableComponent {
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private securitySvc: SecurityService,
    private warrantySvc: WarrantyWebService
  ) {}

  protected readonly activated = Warranty.Status.ACTIVATED;

  readonly refresh$ = new BehaviorSubject(null);
  readonly pagination$ = new BehaviorSubject({ currentPage: 1, itemsPerPage: 10 });
  warrantyCount = 0;

  readonly canSellWarranty$: Observable<boolean> = this.securitySvc.globalDistributors$.pipe(
    switchMap(async globalDistributor => {
      const permissionKeys = globalDistributor?.map(dist => dist.permissionKey) ?? null;

      const [createAccess, createDraftAccess] = await Promise.all([
        this.securitySvc.hasAccess('wrt_CreateWarranty', permissionKeys),
        this.securitySvc.hasAccess('wrt_CreateDraftWarranty', permissionKeys),
      ]);

      return createAccess || createDraftAccess;
    })
  );

  protected form = this.formBuilder.group({
    selectedCustomer: [null as Customer.Model | null],
    status: [[] as Warranty.Status[]],
    warrantyId: ['' as string],
    policyIds: [[] as Policy.Model[]],
  });

  protected customerId$: Observable<string> = this.form.controls.selectedCustomer.valueChanges.pipe(
    startWith(null),
    debounceTime(500),
    map(customer => customer?.id ?? '')
  );

  protected status$: Observable<Warranty.Status[] | null> = this.form.controls.status.valueChanges.pipe(
    startWith(null),
    map(status => status ?? null)
  );

  protected policyIds$: Observable<string[] | null> = this.form.controls.policyIds.valueChanges.pipe(
    startWith(null),
    map(policyIds => policyIds?.map(policy => policy.id) ?? null)
  );

  protected warrantyId$: Observable<string> = this.form.controls.warrantyId.valueChanges.pipe(
    startWith(''),
    map(warrantyId => warrantyId ?? '')
  );

  protected filters$ = combineLatest([this.customerId$, this.status$, this.warrantyId$, this.policyIds$]).pipe(
    map(([customerId, status, warrantyId, policyIds]) => {
      return {
        ...(customerId ? { customerId } : {}),
        ...(status ? { status } : {}),
        ...(policyIds ? { policyIds } : {}),
        ...(warrantyId ? { referenceId: warrantyId } : {}),
      };
    })
  );

  private paginatedData$ = combineLatest([
    this.pagination$,
    this.filters$,
    this.securitySvc.globalDistributors$,
    this.refresh$,
  ]).pipe(
    switchMap(
      async ([pagination, filters, _globalDistributor]) =>
        await this.warrantySvc.list({
          page: pagination.currentPage,
          limit: pagination.itemsPerPage,
          warrantyId: filters.referenceId,
          customerId: filters.customerId,
          status: filters.status,
          policyIds: filters.policyIds,
        })
    ),
    shareReplay(1),
    tap(data => (this.warrantyCount === 0 ? (this.warrantyCount = data?.meta?.totalItems || 0) : 0))
  );

  readonly warranties$: Observable<WarrantyListItem[]> = combineLatest([this.paginatedData$, this.filters$]).pipe(
    map(([listResponse]) => listResponse.items),
    startWith([])
  );

  readonly totalWarranties$: Observable<number> = combineLatest([this.paginatedData$, this.filters$]).pipe(
    map(([listResponse]) => listResponse.meta.totalItems || 0),
    startWith(0)
  );

  readonly paginationConfig$: Observable<Partial<PaginationConfig>> = this.paginatedData$.pipe(
    map(data => data.meta),
    startWith(this.pagination$.value)
  );

  columns: TableColumn<unknown>[] = [
    new TextColumn<WarrantyListItem>({ header: 'Warranties' }, warranty => ({
      text: warranty.plan?.title || '',
      description: warranty.contractNumber,
      classes: 'whitespace-nowrap',
    })),

    new UserInfoColumn<WarrantyListItem>({ header: 'Customer', fitContent: true }, warranty => ({
      name: warranty?.customer?.name,
      email: warranty?.customer?.email,
      photoUrl: warranty?.customer?.user?.photoURL,
    })),

    new TextColumn<WarrantyListItem>({ header: 'Policy' }, warranty => ({
      text: warranty.policy?.title || '',
      description: warranty.policy?.policyNumber,
      classes: 'max-w-[200px] truncate',
      tooltip: warranty.policy?.title || '',
    })),

    new BadgeColumn<WarrantyListItem>({ header: 'Status' }, warranty => ({
      text: (warranty?.status.charAt(0) || '') + (warranty?.status.slice(1).toLowerCase() || ''),
      displayStatusIcon: true,
      theme:
        warranty?.status === Warranty.Status.ACTIVATED
          ? 'success'
          : warranty?.status === Warranty.Status.REGISTERED
          ? 'default'
          : warranty?.status === Warranty.Status.EXPIRED
          ? 'warn'
          : warranty?.status === Warranty.Status.CANCELLED
          ? 'warn'
          : 'default',
    })),

    new TextColumn<WarrantyListItem>({ header: 'Sales' }, async warranty => {
      const total = this.currencyPipe.transform((warranty.total ?? 0) / 100) ?? '$0.00';
      return { text: total };
    }),

    new TextColumn<WarrantyListItem>({ header: 'Created' }, warranty => ({
      text: moment(warranty.created).format('MMM DD, YYYY'),
      classes: 'whitespace-nowrap',
    })),

    new TextColumn<WarrantyListItem>({ header: 'Term start' }, warranty => ({
      text: moment(warranty.termStart).format('MMM DD, YYYY'),
      classes: 'whitespace-nowrap',
    })),

    new TextColumn<WarrantyListItem>({ header: 'Expiry' }, warranty => ({
      text: moment(warranty.expiryDate, ['YYYY-MM-DD', moment.ISO_8601], true).isValid()
        ? moment(warranty.expiryDate).format('MMM DD, YYYY')
        : moment(warranty.activationDeadline).format('MMM DD, YYYY'),
      classes: 'whitespace-nowrap',
    })),

    new MultiTagColumn<WarrantyListItem>({ header: 'Distributor', fitContent: true }, warranty => ({
      items: [{ text: warranty.distributor?.name || '-' }],
    })),
  ];

  async rowClicked(warranty: WarrantyListItem) {
    await this.router.navigate(['warranty', warranty.id]);
  }

  async openNewWarranty() {
    await this.router.navigate(['provisioning']);
  }
}
