<ng-container *ngIf="policy$ | async as policy">
  <ng-container *vsLet="plan$ | async as selectedPlan">
    <div *vsLet="refreshPlans$ | async" class="flex sm:flex-row flex-col gap-4 pb-6">
      <div
        *ngIf="policyPlans?.length"
        class="sm:flex flex-[2] flex-col items-start gap-4 hidden min-w-[256px] max-w-[256px]"
      >
        <ng-container *ngFor="let plan of policyPlans">
          <div
            *vsLet="selectedPlan?.id === plan.id as selected"
            class="cursor-pointer border-l-4 border-transparent py-2 pl-4 pr-2 bg-base shadow-md rounded-lg overflow-hidden"
            [style.box-shadow]="selected ? '-2px 4px 10px -10px black' : ''"
            [style.width]="selected ? 'calc(100% + 1.25rem)' : '100%'"
            [class.!border-primary-500]="selected"
            [class.!rounded-r-none]="selected"
            [class.!z-10]="selected"
            (click)="selectPlan(plan)"
          >
            <div class="flex flex-col gap-1 overflow-hidden" [style.width]="selected ? 'calc(100% - 1.25rem)' : '100%'">
              <div class="w-full flex items-center justify-between gap-2">
                <div class="text-lg font-medium min-w-[80px] max-w-[150px] truncate">{{ plan.title }}</div>
                <div class="flex-1 flex items-center justify-end">
                  <vs-icon
                    class="text-gray-700"
                    [name]="plan.visible ? 'eye' : 'eye-off'"
                    [class.pr-2]="policy?.status !== draft"
                  ></vs-icon>

                  <button
                    *ngIf="policy?.status === published"
                    vs-icon-button
                    size="sm"
                    appearance="clear"
                    tooltipStyle="light"
                    tooltipPosition="top"
                    vsTooltip="Create a link to the customer portal checkout for this plan"
                    (click)="$event.stopImmediatePropagation(); openGenerateLinkDialog()"
                  >
                    <vs-icon name="link-01"></vs-icon>
                  </button>

                  <button
                    vs-icon-button
                    size="sm"
                    appearance="clear"
                    theme="default"
                    vsTooltip="Download the template used for provisioning warranties through SFTP"
                    tooltipStyle="light"
                    tooltipPosition="top"
                    (click)="$event.stopImmediatePropagation(); downloadProvisionTemplate(policy!.id, plan.id)"
                  >
                    <vs-icon name="download-01"></vs-icon>
                  </button>
                </div>
              </div>

              <vs-divider></vs-divider>

              <div class="font-normal text-sm truncate text-gray-700">Created: {{ plan.created | date }}</div>
            </div>
          </div>
        </ng-container>

        <button
          *ngIf="canCreatePlan$ | async"
          class="self-center"
          vs-button
          theme="primary"
          size="sm"
          (click)="openCreatePlanDialog()"
        >
          <vs-icon name="file-plus-03"></vs-icon>
          Add Plan
        </button>
      </div>

      <vs-input-container *ngIf="policyPlans?.length && (isSmall$ | async)" class="sm:hidden flex w-full">
        <vs-select *ngIf="policyPlans" class="w-full" [value]="policyPlans[0].title">
          <li *ngFor="let plan of policyPlans" vs-select-option [value]="plan.title" (click)="selectPlan(plan)">
            {{ plan.title }}
          </li>
        </vs-select>
      </vs-input-container>

      <div *ngIf="policyPlans?.length" class="w-full overflow-hidden">
        <router-outlet></router-outlet>
      </div>

      <vs-card *ngIf="!policyPlans?.length" class="flex justify-center w-full">
        <div class="flex flex-col gap-4 justify-center h-[75vh]">
          <vs-empty-state
            [config]="{
            title: 'No plans found for this policy',
            subtitle: 'No plans have been created for this policy. To publish this policy for customers to purchase, create your first plan.',
            imgsrc: 'policy-documents'
          }"
          >
          </vs-empty-state>
          <div
            class="self-center"
            tooltipStyle="light"
            [vsTooltip]="
              !policy?.coverages?.length
                ? {
                    title: 'Unable to create plans',
                    subtitle: 'To create a plan, at least one coverage must be created first!'
                  }
                : ''
            "
          >
            <button vs-button theme="primary" [disabled]="!policy?.coverages?.length" (click)="openCreatePlanDialog()">
              <vs-icon name="plus"></vs-icon> New plan
            </button>
          </div>
        </div>
      </vs-card>
    </div>

    <ws-plan-dialog #createPlanDialog [policy]="policy"></ws-plan-dialog>
    <ws-generate-link-dialog #generateLinkDialog [plan]="selectedPlan" [policy]="policy"></ws-generate-link-dialog>
  </ng-container>
</ng-container>
