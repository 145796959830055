<div #origin>
  <button
    vs-button
    #destination
    #trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    appearance="outline"
    class="!w-full !py-3 !px-2 !h-auto"
    theme="default"
    [disabled]="disabled"
    (click)="toggle()"
  >
    <div class="flex items-center gap-3 text-gray-900 text-sm w-full font-normal">
      <ng-container *ngIf="!value; else hasValue">
        <span class="flex-grow text-left">{{ placeholder }}</span>
        <vs-icon [name]="overlayOpen ? 'chevron-up' : 'chevron-down'"></vs-icon>
      </ng-container>

      <ng-template #hasValue>
        <span *ngIf="value !== pending" class="flex flex-grow text-left items-center overflow-hidden">
          <span>{{ $any(value)?.bankName }}</span>
          <vs-icon name="minus"></vs-icon>
          <span class="text-sm font-normal max-w-[159px] truncate">{{ $any(value)?.name }}</span>
        </span>

        <span *ngIf="value === pending" class="flex gap-2 text-sm font-normal flex-grow">
          Pending customer billing details
          <vs-icon
            name="info-circle"
            tooltipStyle="light"
            class="text-gray-400 self-center"
            [vsTooltip]="{ template: pendingTooltip }"
          ></vs-icon>
        </span>

        <vs-icon [name]="overlayOpen ? 'chevron-up' : 'chevron-down'"></vs-icon>
      </ng-template>
    </div>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="overlayOpen"
  [cdkConnectedOverlayMinWidth]="origin.offsetWidth"
  (overlayOutsideClick)="$event.stopImmediatePropagation(); overlayOpen = false"
>
  <vs-card class="my-3 bg-base !rounded-md border border-gray-200 !shadow-md min-w-fit w-full">
    <div *ngIf="canAddPaymentMethod && (destinations?.length ?? 0) < maxNumberOfDestinations" class="py-1 px-2 w-full">
      <button vs-button appearance="outline" class="!w-full" (click)="addPaymentMethod.open()">
        <vs-icon name="plus"></vs-icon>
        Add New
      </button>
    </div>

    <ng-container *ngIf="!loading; else isLoading">
      <ng-container *vsLet="_destinations$ | async as _destinations">
        <ng-container *ngIf="_destinations && _destinations.length">
          <button
            *ngFor="let destination of _destinations"
            vs-button
            appearance="clear"
            theme="default"
            [class.bg-gray-100]="destination.id === $any(value)?.id"
            [class.text-sm]="destination.id === $any(value)?.id"
            [class.font-semibold]="destination.id === $any(value)?.id"
            [class.!text-gray-500]="destination.id !== $any(value)?.id"
            class="!text-sm font-normal !w-full !justify-start"
            (click)="destination.id === $any(value)?.id ? remove(destination) : select(destination)"
          >
            <span class="flex items-center gap-1 truncate">
              {{ destination.bankName }}
              <vs-icon name="minus"></vs-icon>
              {{ destination.name }}
            </span>
          </button>
        </ng-container>
      </ng-container>

      <vs-divider></vs-divider>

      <button
        vs-button
        appearance="clear"
        class="!w-full !justify-start"
        (click)="value ? remove(pending) : select(pending)"
      >
        <div class="flex justify-between w-full">
          <div class="flex gap-2">
            <p class="font-medium text-gray-900">Pending customer billing details</p>

            <vs-icon
              name="info-circle"
              tooltipStyle="light"
              class="text-gray-400 self-center"
              [vsTooltip]="{ template: pendingTooltip }"
            ></vs-icon>
          </div>

          <vs-icon *ngIf="value === pending" name="check" class="text-primary-500 self-center"></vs-icon>
        </div>
      </button>
    </ng-container>

    <ng-template #isLoading>
      <div class="flex justify-center items-center min-h-[180px]">
        <vs-icon name="loading-01" class="animate-spin text-primary-500" size="20"></vs-icon>
      </div>
    </ng-template>
  </vs-card>
</ng-template>

<vs-add-new-payment-method
  [customer]="customer"
  [customerId]="customerId"
  (destination)="emitSavedDestination($event)"
  #addPaymentMethod
></vs-add-new-payment-method>

<ng-template #pendingTooltip>
  <div class="max-w-[300px]">
    <p class="text-xs font-semibold text-gray-700">Payment will be visible to customer</p>

    <p class="text-xs font-medium mt-1 text-gray-500">
      If payment destination is set to <strong>Pending customer billing details</strong>, the payment will be visible
      via the customer portal, and will prompt the customer to enter their banking details before the payment can be
      completed.
    </p>
  </div>
</ng-template>
