import { Platform } from '@angular/cdk/platform';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { IconName } from '@vsolv/vectors-ui/icons';
import { BehaviorSubject, map, Observable } from 'rxjs';

export type FileType = 'image' | 'pdf' | null;

@Component({
  selector: 'ws-claim-document-preview',
  templateUrl: './claim-document-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClaimDocumentPreviewComponent {
  constructor(public platform: Platform) {}

  @PropertyListener('document') document$ = new BehaviorSubject<StorageItem.Model | null>(null);
  @Input() document!: StorageItem.Model;

  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' = 'lg';

  @Input() scrollable = false;

  isSafari = this.platform.SAFARI;

  fileType$: Observable<FileType> = this.document$.pipe(
    map(document => {
      if (!document?.extension) return null;

      if (document.extension.toLowerCase() == '.pdf') return 'pdf';

      const imageTypes = ['.gif', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg'];
      if (imageTypes.includes(document.extension.toLowerCase())) return 'image';

      return null;
    })
  );

  protected downloadUrl$ = this.document$.pipe(
    map(item => {
      if (!item?.downloadUrl) return null;

      const url = new URL(item.downloadUrl);
      url.searchParams.set('embedded', 'true');
      url.hash = '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0';

      return url.toString();
    })
  );

  getBorderSizeClasses(): string {
    switch (this.size) {
      case 'xs':
        return 'rounded-md w-12 h-12';
      case 'sm':
        return 'rounded-md w-16 h-16';
      case 'md':
      default:
        return 'rounded-md w-24 h-24';
      case 'lg':
        return 'rounded-lg w-36 h-36';
      case 'xl':
        return 'rounded-xl w-44 h-44';
    }
  }

  getContentSizeClasses(): string {
    switch (this.size) {
      case 'xs':
        return 'w-8 h-8';
      case 'sm':
        return 'w-10 h-10';
      case 'md':
      default:
        return 'w-12 h-12';
      case 'lg':
        return 'w-14 h-14';
      case 'xl':
        return 'w-16 h-16';
    }
  }

  getIconSize(): number {
    switch (this.size) {
      case 'xs':
        return 12;
      case 'sm':
        return 14;
      case 'md':
      default:
        return 16;
      case 'lg':
        return 20;
      case 'xl':
        return 24;
    }
  }

  getIcon(fileType: FileType): IconName {
    switch (fileType) {
      case 'pdf':
        return 'file-06';

      default:
        return 'attachment-02';
    }
  }
}
