import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from '@vectorsolv/vectors-ui/file-input';
import { AddressModule } from '@vsolv/core/address/web';
import { PropertiesWebModule } from '@vsolv/packages/properties/web';
import { ReimbursementWebModule } from '@vsolv/packages/reimbursement/web';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { DropdownModule } from '@vsolv/vectors-ui/dropdown';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewModule } from '@vsolv/vectors-ui/image-preview';
import { InfoCardModule } from '@vsolv/vectors-ui/info-card';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { RichTextEditorModule } from '@vsolv/vectors-ui/rich-text-editor';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { StackGroupModule } from '@vsolv/vectors-ui/stack-group';
import { StepperModule } from '@vsolv/vectors-ui/stepper';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { VectorsUiUtilsModule } from '@vsolv/vectors-ui/utils';
import { TableModule } from '@vsolv/vectors/table';
import { CustomerWebModule } from '@wsphere/customers/web';
import { LinksWebModule } from '@wsphere/links/web';
import { StaffWebModule } from '@wsphere/staff/web';
import { NgxCurrencyModule } from 'ngx-currency';
import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';
import { ClaimLifecycleStepsModule } from '../claim-lifecycle-steps';
import { WarrantyWebService } from '../warranty';
import {
  ClaimDocumentPreviewComponent,
  ClaimDocumentViewerComponent,
  ClaimItemAdjudicateComponent,
  ClaimItemCureCardComponent,
  ClaimItemCureComponent,
  ClaimItemDetailsCardComponent,
  ClaimItemDetailsComponent,
  ClaimItemLiabilityLimitComponent,
  ClaimItemResolveComponent,
  ClaimItemTableComponent,
  ClaimLifecycleComponent,
  ClaimLinkPickerComponent,
  ClaimPaymentsTableComponent,
  ClaimQuickAddComponent,
  ClaimsTableComponent,
  ClaimTimelineActivityComponent,
  ClaimTimelineComponent,
  CoveragePickerComponent,
  CoverageTooltipComponent,
} from './components';
import {
  ClaimCoverageDialog,
  ClaimItemAdjudicateDialog,
  ClaimItemCureDialog,
  ClaimItemDetailsDialog,
  ClaimItemDialog,
  ClaimItemRemoveDialog,
  ClaimItemResolveDialog,
  ClaimNoteDialog,
  ClaimPreviewPDFDialog,
  EditClaimDocumentDialog,
  ManagePaymentDialog,
  ManagePaymentMethodsDialog,
  OpenClaimDialog,
  PaymentDialog,
  UploadClaimDocumentDialog,
  ViewClaimDocumentDialog,
} from './dialogs';
import {
  ClaimGuard,
  ClaimItemAdjudicationGuard,
  ClaimItemGuard,
  ClaimItemManageGuard,
  ClaimPaymentGuard,
} from './guards';
import {
  ClaimAttachmentsPage,
  ClaimNotesPage,
  ClaimOverviewPage,
  ClaimPage,
  ClaimPaymentsPage,
  ClaimsPage,
} from './pages';
import { ClaimItemResolver, ClaimPaymentResolver, ClaimResolver } from './resolver';
import { ClaimService } from './services';

@NgModule({
  providers: [
    ClaimService,
    WarrantyWebService,
    AppPageModule,
    ClaimGuard,
    ClaimItemGuard,
    ClaimItemManageGuard,
    ClaimResolver,
    ClaimItemResolver,
    ClaimItemAdjudicationGuard,
    ClaimPaymentResolver,
    ClaimPaymentGuard,
  ],
  declarations: [
    // Pages
    ClaimsPage,
    ClaimPage,
    ClaimOverviewPage,
    ClaimNotesPage,
    ClaimAttachmentsPage,
    ClaimPaymentsPage,

    // Components
    ClaimsTableComponent,
    ClaimItemTableComponent,
    CoverageTooltipComponent,
    ClaimLifecycleComponent,
    ClaimTimelineComponent,
    ClaimTimelineActivityComponent,
    ClaimItemDetailsComponent,
    ClaimItemDetailsCardComponent,
    ClaimItemCureComponent,
    ClaimItemCureCardComponent,
    ClaimItemAdjudicateComponent,
    ClaimItemResolveComponent,
    ClaimItemLiabilityLimitComponent,
    ClaimQuickAddComponent,
    CoveragePickerComponent,
    ClaimPaymentsTableComponent,
    ClaimDocumentViewerComponent,
    ClaimDocumentPreviewComponent,
    ClaimLinkPickerComponent,

    // Dialogs
    OpenClaimDialog,
    ClaimItemDialog,
    ClaimCoverageDialog,
    ClaimItemDetailsDialog,
    ClaimItemCureDialog,
    ClaimItemAdjudicateDialog,
    ClaimItemResolveDialog,
    ClaimItemRemoveDialog,
    ClaimNoteDialog,
    UploadClaimDocumentDialog,
    ViewClaimDocumentDialog,
    EditClaimDocumentDialog,
    PaymentDialog,
    ManagePaymentDialog,
    ManagePaymentMethodsDialog,
    ClaimPreviewPDFDialog,
  ],
  imports: [
    CommonModule,
    UtilsModule,
    VectorsUiUtilsModule,
    ModalModule,
    NgPipesModule,
    RouterModule,
    OverlayModule,
    AppPageModule,
    TableModule,
    CardModule,
    AppNavModule,
    ButtonModule,
    ThemingModule,
    DividerModule,
    IconsModule,
    DialogModule,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    UserAvatarModule,
    DividerModule,
    BadgeModule,
    SelectModule,
    MenuModule,
    StepperModule,
    RichTextEditorModule,
    InfoCardModule,

    ImagePreviewModule,
    StackGroupModule,
    AngularFireModule,
    AngularFireStorageModule,
    TabsModule,
    TooltipModule,
    BreadcrumbsModule,
    AlertModule,
    AddressModule,
    MomentModule,
    NgxCurrencyModule,
    MatTooltipModule,
    CustomerWebModule,
    StaffWebModule,
    LinksWebModule,
    RichTextEditorModule,
    ReimbursementWebModule,
    PropertiesWebModule,
    FileUploadModule,
    ClaimLifecycleStepsModule,
  ],
  exports: [
    // Components
    ClaimsTableComponent,
    ClaimItemTableComponent,
    ClaimLifecycleComponent,
    ClaimTimelineComponent,
    ClaimTimelineActivityComponent,
    ClaimPaymentsTableComponent,
    ClaimDocumentViewerComponent,
    ClaimDocumentPreviewComponent,
    ClaimLinkPickerComponent,

    // Dialogs
    OpenClaimDialog,
    ClaimCoverageDialog,
    ClaimItemDetailsDialog,
    ClaimNoteDialog,
    ViewClaimDocumentDialog,
    UploadClaimDocumentDialog,
    ManagePaymentMethodsDialog,
    ClaimItemLiabilityLimitComponent,
  ],
})
export class ClaimModule {}
