/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PropertySetInputComponent } from '@vsolv/packages/properties/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Asset } from '@wsphere/assets/domain';
import { BehaviorSubject, switchMap } from 'rxjs';
import { AssetService } from '../../services';

@Component({
  selector: 'ws-edit-asset-dialog',
  templateUrl: './edit-asset-dialog.component.html',
})
export class EditAssetDialog {
  constructor(private assetSvc: AssetService, private formBuilder: FormBuilder, private toastSvc: ToastService) {}

  @ViewChild('dialog') dialog!: DialogComponent;

  @ViewChild('propertyValuesInput') propertyValuesInput?: PropertySetInputComponent;

  @PropertyListener('asset') asset$ = new BehaviorSubject<Asset.Model | null>(null);
  asset: Asset.Model | null = null;

  @Output() closed = new EventEmitter();

  assetValues$ = this.asset$.pipe(
    switchMap(async asset => {
      if (!asset) {
        this.form.patchValue({ name: null, propertyValues: null });
        return null;
      } else if (asset.values) {
        this.form.patchValue({ name: asset.name, propertyValues: (asset.values || null) as any });
        return asset.values;
      } else {
        const latestValues = await this.assetSvc.getAssetValues(asset.id);
        asset.values = latestValues?.value;
        this.form.patchValue({ name: asset.name, propertyValues: (asset.values ?? null) as any });
        return asset.values;
      }
    })
  );

  form = this.formBuilder.group({
    name: [null as string | null, Validators.required],
    propertyValues: [null as any, Validators.required],
  });

  async open(asset: Asset.Model) {
    this.asset = null;
    this.form.reset();
    await new Promise(res => setTimeout(res, 1));
    this.asset = asset;
    this.dialog.open();
  }

  close(assetId?: string) {
    this.dialog.close();
    this.closed.emit(assetId);
    this.form.reset();
  }

  async submit() {
    if (!this.asset) return;

    const name = this.form.value.name ?? undefined;
    const propertyValues = this.form.value.propertyValues;

    if (!name || !propertyValues) return;

    const response = await this.assetSvc.updateAsset(this.asset.id, {
      name,
      propertyValues,
    });

    if (response?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Asset update',
        text: `${name || this.asset.id} was successfully updated!`,
      });
      this.close(response.id);
    } else {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: `Failed to update the asset. Please verify the information and try again.`,
      });
    }
  }

  isValid(): boolean {
    if (!this.form.value.name) return false;

    if (!this.propertyValuesInput) return false;

    if (!this.propertyValuesInput.form?.value) return false;

    return this.propertyValuesInput.form?.valid;
  }
}
