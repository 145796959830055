import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { FirebaseService } from '../../services';

@Component({
  selector: 'vs-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styleUrls: ['./sign-in-form.component.scss'],
})
export class SignInFormComponent {
  constructor(private firebaseSvc: FirebaseService, private router: Router, public portalSvc: PortalService) {}

  formGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  failed = false;

  inputIsValid() {
    return this.formGroup.valid;
  }

  async submit() {
    this.failed = false;
    const email = this.formGroup.value.email;
    const password = this.formGroup.value.password;
    if (!this.inputIsValid() || !email || !password) return;
    try {
      const user = await this.firebaseSvc.emailAndPasswordSignIn(email, password);
      if (user) {
        await this.router.navigate(['..']);
      }
    } catch (err) {
      this.failed = true;
    }
  }
}
