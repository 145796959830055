<div>
  <div class="border-b border-gray-200 pb-5">
    <p class="font-semibold text-lg">Notification Settings</p>
    <p class="text-sm text-gray-600 font-medium">
      We may still send you important notifications about your account outside of your notification settings.
    </p>
  </div>

  <vs-notification-settings></vs-notification-settings>
</div>
