<vs-dialog #archiveDialog [config]="{ dialogContainerClass: 'max-h-full max-w-[90%] w-[900px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'dataflow-02',
      iconTheme: 'primary',

      title: 'Update Condition',
      subtitle: 'Provide the details for the condition'
    }"
  ></vs-dialog-header>

  <form [formGroup]="form">
    <ws-condition-rule-builder
      [plan]="plan"
      [type]="type"
      [coverage]="coverage"
      [propertySet]="propertySet"
      formControlName="rule"
    ></ws-condition-rule-builder>
  </form>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex gap-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
        Cancel
      </button>

      <button
        class="flex-grow"
        vs-button
        size="md"
        theme="primary"
        [disabled]="saving || !form.valid"
        (click)="updateCondition()"
      >
        Update
      </button>
    </div>
  </div>
</vs-dialog>
