<ng-container *vsLet="(canManageNotes$ | async) as canManageNotes">
  <vs-card *ngIf="canViewNotes$ && claim$ | async as claim" theme="default" class="flex flex-col gap-4">
    <ng-container *vsLet="noteAndLinks$ | async as groups">
      <vs-card-header [config]="{ title: 'Notes', subtitle: 'Manage and view all notes for this claim below.' }">
        <vs-badge slot="after-title" theme="default">
          {{ groups?.length || 0 }} Note{{ groups?.length !== 1 ? 's' : '' }}
        </vs-badge>

        <button
          *ngIf="canManageNotes && groups?.length"
          vs-button
          slot="end"
          theme="primary"
          (click)="openNoteDialog()"
        >
          <vs-icon name="sticker-square"></vs-icon>
          New Note
        </button>
      </vs-card-header>

      <vs-card-content *vsLet="routedNote$ | async">
        <ng-container *ngIf="groups?.length; else noNotes">
          <ng-container *ngFor="let group of groups; index as index">
            <div *ngIf="group.note as note" class="flex flex-col gap-4">
              <div class="flex justify-between">
                <div class="flex gap-4">
                  <div class="flex gap-1">
                    <vs-user-avatar
                      class="w-5 h-5 self-center"
                      [config]="{ photoUrl: note?.createdBy?.photoURL }"
                    ></vs-user-avatar>

                    <p class="text-sm font-medium text-gray-600 self-center">{{ note?.createdBy?.displayName }}</p>
                  </div>

                  <div class="flex gap-1">
                    <vs-icon name="calendar-date" class="self-center text-gray-600"></vs-icon>
                    <p class="text-sm font-medium text-gray-600 self-center whitespace-nowrap">
                      {{ note?.created | date }}
                    </p>
                  </div>

                  <div class="flex gap-1">
                    <vs-icon name="clock" class="self-center text-gray-600"></vs-icon>
                    <p class="text-sm font-medium text-gray-600 self-center whitespace-nowrap">
                      {{ note?.modified | date : 'h:mm a' }}
                    </p>
                  </div>
                </div>

                <button *ngIf="canManageNotes" vs-icon-button appearance="clear" (click)="openNoteDialog(note)">
                  <vs-icon name="edit-01"></vs-icon>
                </button>
              </div>

              <p *ngIf="note.title" class="text-md font-semibold text-gray-900">{{ note.title }}</p>

              <vs-rich-text-editor
                class="-my-4"
                [disabled]="true"
                [value]="note.content"
                [config]="{ hideToolbar: true, hideBorder: true }"
              ></vs-rich-text-editor>

              <div class="flex flex-wrap gap-2">
                <ng-container *ngFor="let linkAndConfig of group.links">
                  <ng-container *vsLet="linkAndConfig.link as link">
                    <vs-info-card
                      *ngIf="link?.claimId_1 !== claim.id && link?.claimId_2 !== claim.id && !link?.activityId_1"
                      class="flex-grow max-w-[50%] rounded-lg transition-all"
                      [config]="linkAndConfig.config"
                      [class]="link?.document_1 ? 'hover:shadow cursor-pointer' : ''"
                      (click)="link?.document_1 ? openDocumentDialog(claim, $any(link?.document_1)) : ''"
                    >
                      <vs-badge
                        *ngIf="link?.claimItem_1 || link?.claimItem_2"
                        slot="header-end"
                        [theme]="getItemTheme(link?.claimItem_1 || link?.claimItem_2 || undefined)"
                      >
                        {{ link?.claimItem_1?.status || link?.claimItem_2?.status | titlecase}}
                      </vs-badge>
                    </vs-info-card>
                  </ng-container>
                </ng-container>
              </div>

              <vs-divider *ngIf="index < (groups?.length || 0) - 1" class="my-4"></vs-divider>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #noNotes>
          <div class="flex flex-col justify-center gap-2 p-6">
            <p class="text-xl font-semibold text-gray-900 self-center">No notes have been added to this claim.</p>
            <p class="font-normal text-gray-600 self-center mb-4">
              Once a note has been added, its details will appear here.
            </p>

            <button
              *ngIf="canManageNotes"
              vs-button
              appearance="outline"
              class="self-center"
              (click)="openNoteDialog()"
            >
              <vs-icon name="sticker-square"></vs-icon> New Note
            </button>
          </div>
        </ng-template>
      </vs-card-content>
    </ng-container>

    <ws-claim-note-dialog #noteDialog [claim]="claim" [note]="selectedNote" (closed)="refresh()"></ws-claim-note-dialog>
  </vs-card>
</ng-container>

<ws-view-claim-document-dialog #documentDialog></ws-view-claim-document-dialog>
