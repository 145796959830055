import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiKey } from '@vsolv/packages/api-key';
import { ApiKeyService } from '@vsolv/packages/api-key/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';

@Component({
  selector: 'ws-delete-api-key-dialog',
  templateUrl: './delete-api-key.dialog.html',
})
export class DeleteApiKeyDialog {
  constructor(private apiKeySvc: ApiKeyService, private toastSvc: ToastService) {}

  @Output() refresh = new EventEmitter();
  @ViewChild(DialogComponent) dialog!: DialogComponent;
  @Input() apiKeyItem: ApiKey.ListApiKeysResponseType | null = null;

  _submitting = false;

  async confirm() {
    if (!this.apiKeyItem?.id) return;
    this._submitting = true;

    try {
      await this.apiKeySvc.disable(this.apiKeyItem?.id);
      this.toastSvc.show({
        type: 'success',
        title: 'API successfully deleted!',
        text: `Key ${this.apiKeyItem?.name} has been deleted and can no longer be used.`,
      });
    } catch (e) {
      console.error(e);
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: `Something went wrong and the api-key was not removed. Please try again.`,
      });
    }
    this._submitting = false;
    this.closeDialog();
  }

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.refresh.emit();
    this.dialog.close();
  }
}
