<vs-dialog
  *vsLet="canCreateLink$ | async as canCreateLink"
  [config]="{ dialogContainerClass:  'w-[560px]' }"
  (closed)="closeDialog(true)"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,

      icon: 'link-01',
      iconTheme: 'primary',

      title: page === pages.MANAGE_LINKS 
        ? 'Distributor onboarding links.' 
        : page === pages.VIEW_LINK_DETAILS 
        ? 'View distributor onboarding link details' 
        : 'Configure new distributor onboarding link.',
      subtitle: page === pages.MANAGE_LINKS 
        ? 'Preview, copy, and mange existing links, and create new general distributor onboarding links for your dashboard.' 
        : page === pages.VIEW_LINK_DETAILS 
        ? 'Preview and configuration details of this onboarding link, and copy it to your clipboard for sharing.' 
        : 'Configure the process users with this link will experience when setting up their distributor on our dashboard.',
      
      showDivider: true
    }"
  ></vs-dialog-header>

  <div *ngIf="page === pages.ONBOARDING_LINK" class="flex flex-col">
    <ng-container *vsLet="distributor$ | async as distributor">
      <form [formGroup]="linkDetails" class="flex flex-col gap-2">
        <vs-form-field class="mt-4" [config]="{ label: 'Parent distributor for onboarding link' }">
          <ws-distributor-picker
            [value]="distributor"
            [onlyActive]="true"
            [onbordingLinkView]="true"
            (valueChanges)="selectDistributor($event)"
          ></ws-distributor-picker>
        </vs-form-field>

        <vs-form-field>
          <div class="flex flex-col">
            <div class="flex gap-4">
              <input
                vsInput
                id="link-expired"
                type="checkbox"
                name="checkbox"
                formControlName="linkExpired"
                class="self-center"
              />
              <p class="label" for="link-expired">Set link expiration</p>
            </div>

            <div class="flex ml-8">
              <p class="sub-text" for="link-expired">
                After the link expires, it can no longer be shared or used to onboard distributors to the dashboard.
              </p>
            </div>
          </div>
        </vs-form-field>

        <vs-form-field *ngIf="linkDetails.value.linkExpired">
          <div class="flex gap-4">
            <vs-input-container class="basis-1/2">
              <input
                id="link-expired-days"
                placeholder="days"
                name="checkbox"
                formControlName="days"
                min="0"
                type="number"
              />
              <span slot="end">Days</span>
            </vs-input-container>
          </div>
        </vs-form-field>

        <div class="flex flex-col mt-2" *ngIf="userSelection">
          <p class="label">Member registration</p>
          <p class="sub-text">
            Selecting 'Allow member registration' will let the user signup as a team member of the distributor and
            access the dashboard.
          </p>
        </div>

        <vs-form-field>
          <div class="flex flex-col" *ngIf="userSelection">
            <div class="flex items-center gap-2 mb-2">
              <input vsInput [value]="false" name="canCreateUser" formControlName="canCreateUser" type="radio" />
              <span class="text-gray-700 text-sm">Create distributor only</span>
            </div>

            <div class="flex items-center gap-2">
              <input vsInput [value]="true" name="canCreateUser" formControlName="canCreateUser" type="radio" />
              <span class="text-gray-700 text-sm">Allow member registration</span>
            </div>
          </div>
        </vs-form-field>
      </form>
    </ng-container>

    <div class="footer">
      <div class="page-markers">
        <div class="page-marker-active"></div>
        <div *ngIf="userSelection" class="page-marker"></div>
        <div class="page-marker"></div>
      </div>

      <div class="buttons">
        <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="cancel(4)">Cancel</button>
        <div
          [vsTooltip]="canCreateLink ? '' : 'Please select a parent distributor to configure a new distributor onboarding link'"
        >
          <button
            vs-button
            theme="primary"
            class="w-full sm:w-auto"
            [disabled]="!linkDetails.controls.canCreateUser.valid || !canCreateLink"
            (click)="goTo(2)"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- User Roles -->
  <div *ngIf="page === pages.USER_ROLES && !!linkDetails.value.canCreateUser" class="flex flex-col gap-4">
    <ng-container *vsLet="roles$ | async as roles">
      <form [formGroup]="rolesFormGroup" class="mt-4">
        <vs-form-field
          [config]="{
            label: 'Initial member roles',
            description: 'Select the roles you would like the first member of the newly registered distributor to receive upon signing up. These roles will determine what features on the dashboard they have access to.'
          }"
        >
          <ws-role-picker
            formControlName="roles"
            [value]="$any(rolesFormGroup.value.roles)"
            [permissionKey]="distributor?.permissionKey ?? null"
            (roleAdded)="addRoleOption($event)"
            (roleRemoved)="removeRoleOption($event)"
          ></ws-role-picker>
        </vs-form-field>
      </form>
    </ng-container>

    <div class="footer">
      <div class="page-markers">
        <div class="page-marker-active"></div>
        <div class="page-marker-active"></div>
        <div class="page-marker"></div>
      </div>
      <div class="buttons">
        <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="goTo(1)">Back</button>
        <div
          [vsTooltip]="canCreateLink ? '' : 'Please select a parent distributor to configure a new distributor onboarding link'"
        >
          <button vs-button theme="primary" class="w-full sm:w-auto" (click)="goTo(3)" [disabled]="!canCreateLink">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Review Link Details -->
  <div *ngIf="page === pages.REVIEW_LINK_DETAILS" class="flex flex-col">
    <div class="flex flex-col gap-4">
      <div class="flex flex-col mt-3">
        <span class="text-sm text-gray-700 font-medium">Review link details</span>
        <span class="text-sm text-gray-600 w-[448px]">
          Before creating this link, please review its configuration details as they will affect how new distributors
          are registered to the dashboard.
        </span>
      </div>

      <div class="grid grid-cols-3 mt-3">
        <span class="label col-span-1">Parent distributor </span>
        <span class="text col-span-2">{{ distributor?.name ?? 'No parent distributor' }} </span>
      </div>

      <vs-divider></vs-divider>

      <div class="grid grid-cols-3">
        <span class="label col-span-1">Link expiry </span>
        <span class="text col-span-2">
          {{ linkDetails.value.linkExpired ? (linkDetails.value.days + (linkDetails.value.days === 1 ? ' day' : '
          days')) : '-' }}
        </span>
      </div>

      <vs-divider *ngIf="userSelection"></vs-divider>

      <div *ngIf="userSelection" class="grid grid-cols-3">
        <span class="label col-span-1">Member registration</span>
        <span class="text col-span-2"> {{ linkDetails.value.canCreateUser ? 'Yes': 'No'}} </span>
      </div>

      <vs-divider *ngIf="userSelection"></vs-divider>

      <div *ngIf="userSelection && linkDetails.controls.canCreateUser.value" class="grid grid-cols-3">
        <span class="label col-span-1">Initial member roles</span>
        <div *ngIf="rolesFormGroup.value.roles?.length" class="flex flex-wrap col-span-2 gap-1 mb-2">
          <ws-role-badge *ngFor="let role of rolesFormGroup.value.roles" [roleId]="role"></ws-role-badge>
        </div>
        <div *ngIf="!rolesFormGroup.value.roles?.length" class="flex flex-wrap col-span-2 gap-1 mb-2">
          <span class="text col-span-2"> {{'-'}} </span>
        </div>
      </div>
    </div>

    <div class="footer">
      <div class="page-markers">
        <div *ngIf="userSelection" class="page-marker-active"></div>
        <div class="page-marker-active"></div>
        <div class="page-marker-active"></div>
      </div>

      <div class="buttons">
        <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="goTo(2)">Back</button>
        <div
          [vsTooltip]="canCreateLink ? '' : 'Please select a parent distributor to configure a new distributor onboarding link'"
        >
          <button vs-button theme="primary" class="w-full sm:w-auto" (click)="create()" [disabled]="!canCreateLink">
            Create
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Manage Links Table -->
  <div *ngIf="page === pages.MANAGE_LINKS">
    <ng-container *ngIf="onboardingLinkData$ | async as data">
      <div class="flex flex-col">
        <div class="flex mt-4 mb-4 justify-between" *ngIf="canEditDistributor$ | async">
          <div class="flex self-center">
            <input vsInput id="visible-input" appearance="toggle" type="checkbox" [formControl]="showExpiredLink" />
            <p class="title">Show expired links</p>
          </div>

          <div *ngIf="data.items?.length && isOnboardingLinkPresentInTable">
            <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="goTo(1)">
              <vs-icon name="plus"></vs-icon>New Link
            </button>
          </div>
        </div>

        <ng-container *ngIf="data.items.length && isOnboardingLinkPresentInTable; else showNewLink">
          <vs-card
            class="rounded-xl overflow-hidden border border-gray-200 shadow-sm mt-4"
            *vsLet="canEditLink$ | async"
          >
            <vs-table
              class="max-h-[450px]"
              [data]="data.items"
              [columns]="(columns$ | async) ?? []"
              (rowClicked)="navigateTo($event)"
            ></vs-table>

            <vs-pagination
              class="items-end"
              [config]="data.meta"
              (pagination)="paginationQueryRequest$.next({ page: $event.currentPage, limit: $event.itemsPerPage })"
            ></vs-pagination>
          </vs-card>

          <div class="flex justify-end mt-4">
            <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="closeDialog()">Close</button>
          </div>
        </ng-container>
      </div>

      <ng-template #showNewLink>
        <div class="w-full mt-4 p-3 flex gap-4 justify-between bg-gray-50 rounded-lg">
          <div>
            <p class="text-sm font-medium text-primary-500">No active onboarding links created for this distributor</p>
            <p class="text-xs font-normal text-gray-600 mt-1">Once you create a link, it will be listed here</p>
          </div>

          <button vs-button class="self-center" size="md" theme="primary" (click)="goTo(1)">
            <vs-icon name="plus"></vs-icon>
            New Link
          </button>
        </div>

        <div class="flex justify-end mt-4">
          <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="closeDialog()">Close</button>
        </div>
      </ng-template>
    </ng-container>
  </div>

  <!-- View Link Details -->
  <div *ngIf="page === pages.VIEW_LINK_DETAILS" class="flex flex-col">
    <div class="flex flex-col gap-4">
      <div class="grid grid-cols-3 mt-3">
        <span class="label col-span-1">Parent distributor </span>
        <span class="text col-span-2">{{ linkDetails.value.distributor?.name ?? 'No parent distributor' }} </span>
      </div>

      <vs-divider></vs-divider>

      <div class="grid grid-cols-3">
        <span class="label col-span-1">Link expiry </span>
        <span class="text col-span-2">
          {{ !linkDetails.value.linkExpired ? !linkDetails.value.days ? '-' : (linkDetails.value.days +
          (linkDetails.value.days === 1 ? ' day' :' days')) : 'Expired' }}
        </span>
      </div>

      <vs-divider *ngIf="userSelection"></vs-divider>

      <div *ngIf="userSelection" class="grid grid-cols-3">
        <span class="label col-span-1">Member registration</span>
        <span class="text col-span-2"> {{ linkDetails.value.canCreateUser ? 'Yes': 'No'}} </span>
      </div>

      <vs-divider *ngIf="userSelection"></vs-divider>

      <div
        *ngIf="userSelection && rolesFormGroup.value.roles?.length  && linkDetails.controls.canCreateUser.value"
        class="grid grid-cols-3"
      >
        <span class="label col-span-1">Initial member roles</span>
        <div class="col-span-2 flex gap-1 flex-wrap">
          <vs-badge *ngFor="let opt of rolesFormGroup.value.roles">{{ opt }}</vs-badge>
        </div>
      </div>
    </div>

    <!-- Review Link Details -->
    <div class="footer">
      <div class="page-markers">
        <div *ngIf="userSelection" class="page-marker-active"></div>
        <div class="page-marker-active"></div>
        <div class="page-marker-active"></div>
      </div>

      <div class="buttons">
        <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="goTo(4)">Back</button>

        <button
          *ngIf="(canEditLink$ | async) && (canEditDistributor$ | async)"
          vs-button
          theme="primary"
          class="w-full sm:w-auto"
          [disabled]="linkDetails.value.linkExpired"
          (click)="copyLink(selectedLinkId)"
        >
          Copy Link
        </button>
      </div>
    </div>
  </div>
</vs-dialog>

<ws-break-distributor-onboarding-link-dialog
  #breakDistributorOnboardingLink
  [linkId]="selectedLinkId"
  (closed)="closeBreakLinkDialog($event)"
>
</ws-break-distributor-onboarding-link-dialog>
<ws-full-admin-warning-dialog></ws-full-admin-warning-dialog>
