import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@vsolv/core/users/domain';
import { UserService } from '@vsolv/core/users/web';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Payment, PaymentTax } from '@vsolv/packages/payments/domain';
import { PaymentService } from '@vsolv/packages/payments/web';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Staff } from '@wsphere/staff/domain';
import { SecurityService } from '@wsphere/staff/web';
import { WarrantyWebService } from '@wsphere/warranties/web';
import { BehaviorSubject, ReplaySubject, combineLatest, from, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-payment-invoice',
  templateUrl: './payment-invoice.component.html',
})
export class PaymentInvoiceComponent {
  constructor(
    private userSvc: UserService,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private portalSvc: PortalService,
    private paymentSvc: PaymentService,
    private securitySvc: SecurityService,
    private warrantySvc: WarrantyWebService
  ) {}

  @Output() readonly refresh = new EventEmitter<void>();

  @Input() static = false;
  @Input() hideResendReminderButton = false;

  protected readonly logo = this.portalSvc.config.logo;
  protected readonly brandName = this.portalSvc.config.brandName;
  protected readonly contactEmail = this.portalSvc.config.contactEmail;
  protected readonly contactPhone = this.portalSvc.config.contactPhone;
  protected readonly contactAddress = this.portalSvc.config.contactAddress;

  @PropertyListener('payment') payment$ = new ReplaySubject<Payment.Model | null>(1);
  @Input() payment?: Payment.Model & { user: User.Model; taxes: PaymentTax.Model[] };

  @Input() showPaymentMethod = false;
  @PropertyListener('showPaymentMethod') showPaymentMethod$ = new BehaviorSubject<boolean>(this.showPaymentMethod);

  currentUser$ = from(this.userSvc.getSelf());

  warranty$ = this.payment$.pipe(
    switchMap(async payment =>
      payment?.referenceId ? await this.warrantySvc.getOneByContractNumber(payment.referenceId) : null
    )
  );

  distributor$ = this.warranty$.pipe(map(warranty => warranty?.distributor));

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  currentStaff$ = this.route.parent!.parent!.data.pipe(map(data => data['staff'] as Staff.Model));

  canUpdateBillingInfo$ = combineLatest([this.payment$, this.currentUser$, this.currentStaff$, this.distributor$]).pipe(
    switchMap(async ([payment, user, staff, dist]) => {
      if (payment?.userId === user.id) return true;
      return staff ? await this.securitySvc.hasAccess('py_UpdateBilling', dist ? [dist?.permissionKey] : null) : null;
    })
  );

  canRetryBillingInfo$ = combineLatest([this.payment$, this.currentUser$, this.currentStaff$, this.distributor$]).pipe(
    switchMap(async ([payment, user, staff, dist]) => {
      if (payment?.userId === user.id) return true;
      return staff ? await this.securitySvc.hasAccess('py_RetryPayment', dist ? [dist.permissionKey] : null) : null;
    })
  );

  canViewPaymentMethods$ = combineLatest([this.currentStaff$, this.showPaymentMethod$]).pipe(
    switchMap(async ([staff, showPaymentMethod]) =>
      !showPaymentMethod
        ? staff
          ? await this.securitySvc.hasAccess(
              'py_ViewPaymentMethods',
              this.securitySvc.globalDistributors$.value?.map(dist => dist.permissionKey) || null
            )
          : null
        : showPaymentMethod
    )
  );

  protected paymentMethodChanged(paymentMethodId: string) {
    if (this.payment) {
      this.payment.paymentMethod = undefined;
      this.payment.paymentMethodId = paymentMethodId;
    }

    this.refresh.emit();
  }

  protected async resendPaymentReminder() {
    if (!this.payment) {
      this.toastSvc.show({
        title: 'Error Resending Payment Reminder',
        type: 'error',
        text: 'Unknown Error',
      });
      return;
    }
    await this.paymentSvc.resendPaymentReminder(this.payment.id);
    this.toastSvc.show({
      type: 'success',
      title: 'Payment Reminder Resent',
      text: 'The payment reminder has been successfully resent.',
    });
  }

  protected paymentRetried(result: Payment.RetryPaymentResponse) {
    if (this.payment) {
      this.payment.status = result.status;
    }

    this.refresh.emit();
  }
}
