/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { FileInputDirective } from '@vsolv/vectors-ui/input';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { BehaviorSubject, map } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-upload-claim-document-dialog',
  templateUrl: './upload-document.dialog.html',
})
export class UploadClaimDocumentDialog implements AfterViewInit {
  constructor(
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private linkSvc: LinksWebService,
    private formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver
  ) {
    try {
      this.route.parent?.queryParams.subscribe(params => {
        if (this.isCustomerPortal) return;

        this.openDialog = params?.['openDialog'];
        this.claimId = this.claimId ?? params?.['claimId'];
        this.addAttachmentData = params?.['addAttachmentData'] ? JSON.parse(params?.['addAttachmentData']) : null;

        if (this.addAttachmentData) this.isCustomerPortal = true;
      });
    } catch (err) {
      this.toastSvc.show({ type: 'warning', text: 'Url might be incorrect or broken', title: 'URL warning' });
    }
  }

  @ViewChild('fileInput') fileInput?: FileInputDirective;
  @ViewChild('dialog') dialog!: DialogComponent;

  @Input() isCustomerPortal: boolean | null = null;

  @Output() uploaded = new EventEmitter();
  @Output() closed = new EventEmitter();
  @Output() deleted = new EventEmitter();

  @PropertyListener('claimId') claimId$ = new BehaviorSubject<string | null>(null);
  claimId!: string;

  openDialog = null;
  submitting = false;
  addAttachmentData?: { title: string; description: string } | null;

  claim$ = this.claimSvc.getClaim();

  refresh$ = new BehaviorSubject(null);

  form = this.formBuilder.group({
    title: [null as string | null, Validators.maxLength(255)],
    description: [null as string | null],
    file: [null as File | null, [Validators.required]],
    visibleToCustomer: [false],
    links: [[] as Link.FormLink[]],
  });

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  open(claimId: string) {
    this.claimId = claimId;
    this.dialog.open();
  }

  close() {
    this.form.reset();
    this.dialog?.close();
  }

  refresh() {
    this.refresh$.next(null);
  }

  async submit() {
    if (this.submitting) return;
    this.submitting = true;

    try {
      const title = this.form.value.title;
      const description = this.form.value.description;
      const visibleToCustomer = this.form.value.visibleToCustomer;
      const file = this.form.value.file;

      if (!file) return;
      const filename = file.name;

      const result = await this.claimSvc
        .createDocument(this.claimId, {
          file,
          title: title ?? filename,
          description: description ?? undefined,
          feed: visibleToCustomer ? ['staff', 'customer'] : ['staff'],
        })
        .catch(error => {
          this.submitting = false;
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
        });

      if (result) {
        this.updateAttachmentLinks(result);

        this.toastSvc.show({
          type: 'success',
          title: 'Attachement Added',
          text: `<strong>${title || filename || 'The attachment'}</strong>` + ' has been successfully added.',
        });

        this.submitting = false;
        if (result) this.uploaded.emit(null);
        this.close();
      }
    } catch (err) {
      this.submitting = false;

      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: (err as Error).message,
      });
    }
  }

  async updateAttachmentLinks(documentId: string) {
    const links = this.form.value.links;

    // Add new links
    if (links?.length) {
      await Promise.all(
        links.map(async link => {
          await this.linkSvc.create(
            { id: this.claimId, objectType: Link.ObjectType.CLAIM },
            {
              object1: { id: (link.object as any).id, objectType: link.type },
              object2: { id: documentId, objectType: Link.ObjectType.STORAGE_ITEM },
            }
          );
        })
      );
    }
  }

  onFileAdded(event: any) {
    this.form.patchValue({ file: event.target.files[0] });
  }

  async clearFile() {
    this.fileInput?.clear();
    this.form.patchValue({ file: null });
  }

  ngAfterViewInit(): void {
    if (this.addAttachmentData)
      this.form.patchValue({
        title: this.addAttachmentData.title ?? '',
        description: this.addAttachmentData?.description,
      });
    if (this.openDialog) this.open(this.claimId);
  }
}
