import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Policy, Provisioning } from '@wsphere/warranties/domain';
import { Subject, firstValueFrom, map, takeUntil, tap } from 'rxjs';
import { EvaluatedAddon } from '../../../policy';
import { ProvisioningSessionService } from '../../services';

interface PlanInfo {
  termId: string | null;
  plan: Policy.Plan.Model;
  addonIds: string[];
}

@Component({
  selector: 'ws-provision-plan',
  templateUrl: './provision-plan.component.html',
})
export class ProvisionPlanComponent implements OnInit, OnDestroy {
  @HostBinding('class') _class = 'pb-24 px-6';
  private sessionSvc = inject(ProvisioningSessionService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private breakpointObserver = inject(BreakpointObserver);

  protected stepCompleted = false;
  protected destroy$ = new Subject<void>();

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  protected session$ = this.sessionSvc.getCurrentSession().pipe(
    tap(session => {
      if (session?.plan && session?.addonIds && session.policyTermId) {
        this.plan.patchValue({
          plan: session.plan,
          termId: session.policyTermId,
          addonIds: session.addonIds,
        });
        this.stepCompleted = true;
      }
    })
  );

  protected plan = new FormControl(null as PlanInfo | null);

  readonly staffView$ = this.route.data.pipe(map(data => data['staffView'] as boolean));

  protected navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }

  previousPage(staffView: boolean) {
    if (staffView) this.navigateTo('/provisioning/asset');
    else this.navigateTo('/checkout/asset');
  }

  async nextPage(session: Provisioning.ProvisioningSession | null, staffView: boolean) {
    if (this.stepCompleted && session && this.plan.value) {
      await this.sessionSvc.updateSession({
        sessionId: session.id,
        data: {
          policyTermId: this.plan?.value?.termId || '',
          planId: this.plan?.value?.plan?.id,
          addonIds: this.plan?.value?.addonIds,
        },
      });

      const addons = this.sessionSvc.addons$.value;
      const hasAddons = addons?.find(addon => addon.planId === this.plan?.value?.plan?.id)?.addons.length;

      if (staffView) {
        if (!hasAddons) this.navigateTo('/provisioning/checkout');
        else this.navigateTo('/provisioning/addon');
      } else {
        if (!hasAddons) this.navigateTo('/checkout/checkout');
        else this.navigateTo('/checkout/addon');
      }
    }
  }

  emitAddons(addons: { planId: string; addons: EvaluatedAddon[] }[] | null) {
    this.sessionSvc.addons$.next(addons);
  }

  async updateTerm(termId: string) {
    const session = await firstValueFrom(this.session$);
    if (!session) return;

    this.sessionSvc.updateSession({ sessionId: session.id, data: { policyTermId: termId } });
  }

  ngOnInit(): void {
    this.plan.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(plan => (this.stepCompleted = !!plan?.plan?.id));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
