import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@vsolv/core/users/web';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { combineLatest, from, map } from 'rxjs';
import { StaffService } from '../../services';

@Component({
  templateUrl: './user-profile.page.html',
})
export class UserProfilePage {
  isLargeScreen$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => !state.matches));
  constructor(
    private router: Router,
    private userSvc: UserService,
    private staffSvc: StaffService,
    private firebaseSvc: FirebaseService,
    private breakpointObserver: BreakpointObserver
  ) {}

  private staff$ = from(this.staffSvc.retrieveSelf());
  private user$ = from(this.userSvc.getSelf());

  userData$ = combineLatest([this.staff$, this.user$]).pipe(map(([staff, user]) => ({ staff, user })));

  async signOut() {
    await this.firebaseSvc.signOut();
    await this.router.navigate(['sign-in']);
  }
}
