import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FileUploadModule } from '@vectorsolv/vectors-ui/file-input';
import { PropertiesWebModule } from '@vsolv/packages/properties/web';
import { ContentCardModule } from '@vsolv/ui-kit/card';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AccordionModule } from '@vsolv/vectors-ui/accordion';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { DropdownModule } from '@vsolv/vectors-ui/dropdown';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { ProgressBarModule } from '@vsolv/vectors-ui/progress-bar';
import { RichTextEditorModule } from '@vsolv/vectors-ui/rich-text-editor';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { TableModule } from '@vsolv/vectors/table';
import { CustomerWebModule } from '@wsphere/customers/web';
import { DistributorsWebModule } from '@wsphere/distributors/web';
import { StaffWebModule } from '@wsphere/staff/web';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { ClaimModule } from '../claim';
import { ClaimLifecycleStepsModule } from '../claim-lifecycle-steps';
import { WarrantyCoverageModule } from '../coverage';
import {
  AddonPickerComponent,
  ConditionBlockBuilderComponent,
  ConditionBuilderComponent,
  ConditionGroupBuilderComponent,
  ConditionPreviewComponent,
  ConditionPropertyPickerComponent,
  ConditionPropertyPickerItemComponent,
  ConditionRuleBuilderComponent,
  ConditionValuePropertyPickerComponent,
  ConditionValuePropertyPickerItemComponent,
  CoverageAccordionHeaderComponent,
  CoveragesTableComponent,
  EmailPreviewComponent,
  NewFeeButtonComponent,
  NoPlansAvailableCardComponent,
  PlanCoverageGroupBoxComponent,
  PlanCoverageMonetaryBoxComponent,
  PlanCoverageTextBoxComponent,
  PlanDetailsComponent,
  PlanPickerComponent,
  PlanPricingTableComponent,
  PolicyAssetComponent,
  PolicyCoveragesComponent,
  PolicyDetailsCardComponent,
  PolicyDetailsComponent,
  PolicyDocumentsComponent,
  PolicyFilterPickerComponent,
  PolicyPickerComponent,
  PolicyStatsComponent,
  PolicyTermComponent,
  PolicyTermPickerComponent,
  ShippingAddressPropertyPickerComponent,
  ShippingAddressPropertyPickerItemComponent,
} from './components';
import {
  ArchivePolicyDialog,
  CopyCoverageDialog,
  CopyTermConfigDialog,
  CreateNewVersionDialog,
  DeleteCoverageDialog,
  DeletePlanDialog,
  DeletePolicyDialog,
  EditEmailTemplateDialog,
  EditPlanConditionDialog,
  EmailPreviewDialog,
  ExportPlanCoverageConfigsDialog,
  GenerateLinkDialog,
  LinkToPolicyDialog,
  ManagePlanGroupsDialog,
  PlanDialog,
  PolicyDialog,
  PreviewGroupAddonsDialog,
  PreviewPDFDialog,
  PublishPolicyDialog,
  RestorePolicyDialog,
} from './dialogs';
import { CoverageGuard, PlanGuard, PolicyGuard } from './guards';
import {
  CoveragePage,
  PoliciesPage,
  PolicyClaimLifecyclePage,
  PolicyCommunicationsPage,
  PolicyDetailsPage,
  PolicyDocumentsPage,
  PolicyPage,
  PolicyPlansPage,
} from './pages';
import { ConditionPropertyPipe, DurationPipe, InstallmentPipe, IsSelectedPipe } from './pipes';
import { CoverageResolver, PlanResolver, PolicyResolver } from './resolvers';
import { CoverageService, PolicyService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ContentCardModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UtilsModule,
    AppPageModule,
    BadgeModule,
    IconsModule,
    ButtonModule,
    ThemingModule,
    TableModule,
    DialogModule,
    DividerModule,
    AppNavModule,
    CardModule,
    BreadcrumbsModule,
    WarrantyCoverageModule,
    InputModule,
    FormsModule,
    ClaimModule,
    SelectModule,
    RichTextEditorModule,
    MenuModule,
    ProgressBarModule,
    TooltipModule,
    AlertModule,
    IconsModule,
    TabsModule,
    CustomerWebModule,
    DropdownModule,
    BadgeModule,
    ClipboardModule,
    NgxMaskPipe,
    NgxMaskDirective,
    DistributorsWebModule,
    OverlayModule,
    PropertiesWebModule,
    OverlayModule,
    AccordionModule,
    FileUploadModule,
    ClaimLifecycleStepsModule,
    StaffWebModule,
    NgxEchartsModule,
  ],
  providers: [
    PolicyGuard,
    PolicyResolver,
    PlanGuard,
    PlanResolver,
    PolicyService,
    CoverageGuard,
    CoverageResolver,
    CoverageService,
  ],
  declarations: [
    // Pages
    PoliciesPage,
    PolicyPage,
    PolicyDetailsPage,
    PolicyPlansPage,
    PolicyDocumentsPage,
    PolicyCommunicationsPage,
    PolicyClaimLifecyclePage,
    CoveragePage,

    // Dialogs
    PolicyDialog,
    PublishPolicyDialog,
    ArchivePolicyDialog,
    RestorePolicyDialog,
    EmailPreviewDialog,
    DeletePolicyDialog,
    CopyCoverageDialog,
    DeleteCoverageDialog,
    PreviewPDFDialog,
    CopyCoverageDialog,
    EditEmailTemplateDialog,
    PlanDialog,
    EditPlanConditionDialog,
    ExportPlanCoverageConfigsDialog,
    DeletePlanDialog,
    GenerateLinkDialog,
    LinkToPolicyDialog,
    ManagePlanGroupsDialog,
    CopyTermConfigDialog,
    CreateNewVersionDialog,

    // Pipes
    ConditionPropertyPipe,
    DurationPipe,
    InstallmentPipe,
    IsSelectedPipe,

    // Components
    PolicyDetailsComponent,
    PolicyAssetComponent,
    PolicyTermComponent,
    PolicyCoveragesComponent,
    PolicyDocumentsComponent,
    PolicyStatsComponent,
    CoveragesTableComponent,
    PlanDetailsComponent,
    PlanPricingTableComponent,
    PlanCoverageTextBoxComponent,
    PlanCoverageMonetaryBoxComponent,
    PlanCoverageGroupBoxComponent,
    ConditionRuleBuilderComponent,
    ConditionBlockBuilderComponent,
    ConditionGroupBuilderComponent,
    ConditionBuilderComponent,
    ConditionPreviewComponent,
    PlanPickerComponent,
    PolicyPickerComponent,
    PolicyDetailsCardComponent,
    NoPlansAvailableCardComponent,
    EmailPreviewComponent,
    ConditionPropertyPickerComponent,
    ConditionPropertyPickerItemComponent,
    ShippingAddressPropertyPickerItemComponent,
    ShippingAddressPropertyPickerComponent,
    AddonPickerComponent,
    PreviewGroupAddonsDialog,
    ConditionValuePropertyPickerItemComponent,
    ConditionValuePropertyPickerComponent,
    PolicyTermPickerComponent,
    PolicyFilterPickerComponent,
    NewFeeButtonComponent,
    CoverageAccordionHeaderComponent,
  ],
  exports: [
    // Pages
    PoliciesPage,
    PolicyPage,
    PolicyDetailsPage,
    PolicyPlansPage,
    PolicyDocumentsPage,
    PolicyCommunicationsPage,
    CoveragePage,

    // Dialogs
    PolicyDialog,
    PublishPolicyDialog,
    ArchivePolicyDialog,
    RestorePolicyDialog,
    EmailPreviewDialog,
    DeletePolicyDialog,
    CopyCoverageDialog,
    DeleteCoverageDialog,
    PreviewPDFDialog,
    CopyCoverageDialog,
    EditEmailTemplateDialog,
    PlanDialog,
    EditPlanConditionDialog,
    ExportPlanCoverageConfigsDialog,
    DeletePlanDialog,
    GenerateLinkDialog,
    LinkToPolicyDialog,
    ManagePlanGroupsDialog,

    //Pipes
    DurationPipe,
    InstallmentPipe,

    // Components
    PlanCoverageTextBoxComponent,
    PlanCoverageMonetaryBoxComponent,
    PlanCoverageGroupBoxComponent,
    ConditionRuleBuilderComponent,
    ConditionBlockBuilderComponent,
    ConditionGroupBuilderComponent,
    ConditionBuilderComponent,
    ConditionPreviewComponent,
    PlanPickerComponent,
    PolicyPickerComponent,
    PolicyDetailsCardComponent,
    AddonPickerComponent,
    PolicyFilterPickerComponent,
  ],
})
export class PolicyModule {}
