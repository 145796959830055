<ng-container *ngIf="userData$ | async as userData">
  <vs-app-scaffold class="max-w-screen max-h-screen">
    <!-- <div slot="top" class="h-10 bg-primary-600">Top</div> -->
    <!-- <div class="flex p-4 gap-4">
    <vs-icon name="target-04" type="solid" class="text-primary"></vs-icon>
    <vs-icon name="target-04" type="duotone" class="text-primary"></vs-icon>
    <vs-icon name="target-04" type="duocolor" class="text-primary"></vs-icon>
    <vs-icon name="target-04" class="text-primary"></vs-icon>
  </div> -->

    <vs-toolbar *ngIf="isMobile$ | async" slot="top">
      <button
        vs-icon-button
        appearance="clear"
        slot="start"
        (mouseenter)="menuBtnHover = true"
        (mouseleave)="menuBtnHover = false"
        (click)="slideOut.open()"
        class="cursor-pointer h-6"
      >
        <vs-icon
          #icon
          [name]="menuBtnHover ? 'chevron-right-double' : 'menu-02'"
          size="24"
          *ngIf="isMobile$ | async"
        ></vs-icon>
      </button>

      <a
        *ngIf="(config$ | async)?.logo"
        (mousedown)="$event.preventDefault()"
        routerLink=""
        routerLinkActive="cursor-default"
        [routerLinkActiveOptions]="{ exact: true }"
        class="block focus:outline-none focus:ring-4 ring-gray-100 focus:rounded group"
      >
        <img
          [src]="(config$ | async)?.logo"
          [class.w-fit]="(config$ | async)?.logo?.endsWith('.svg')"
          [class.w-auto]="!(config$ | async)?.logo?.endsWith('.svg')"
          class="max-h-10 max-w-full group-focus:px-2 group-focus:py-[2px]"
          [class.max-h-8]="isMobile$ | async"
        />
      </a>
      <button
        vs-user-avatar
        slot="end"
        class="w-9 h-9"
        [config]="{
          photoUrl: userData.photoURL,
          routerLink: 'user-settings/my-profile'
        }"
      ></button>
    </vs-toolbar>

    <vs-slide-out #slideOut>
      <ng-container *ngTemplateOutlet="leftNav"></ng-container>
    </vs-slide-out>

    <ng-container *ngIf="(isMobile$ | async) === false" slot="left">
      <ng-container *ngTemplateOutlet="leftNav"></ng-container>
    </ng-container>

    <ng-template #leftNav>
      <vs-sidebar-nav
        *ngIf="navItems$ | async as navItems"
        class="bg-base h-full"
        [class.border-r]="(isMobile$ | async) === false"
        [class.border-gray-200]="(isMobile$ | async) === false"
        [config]="{
          logo: (config$ | async)?.logo!,
          logoRouterLink: '',
          items: navItems
        }"
      >
        <div slot="before-items" class="pb-8">
          <ws-global-distributors-picker></ws-global-distributors-picker>
        </div>

        <button
          *ngIf="canSellWarranty$ | async"
          slot="after-items"
          class="bg-primary-50 text-sm font-semibold text-primary-700 px-4 py-2.5 w-full rounded-lg flex items-center justify-center gap-3 mt-8"
          routerLink="provisioning"
        >
          <vs-icon name="plus"></vs-icon>
          <span>Quick Sale</span>
        </button>

        <div slot="bottom" class="flex flex-col gap-2">
          <vs-sidebar-nav-item
            [config]="{
              icon: 'life-buoy-01',
              label: 'Support'
            }"
          ></vs-sidebar-nav-item>

          <vs-sidebar-nav-item
            *ngIf="showDistributorSettings$ | async"
            [config]="{
              icon: 'package-search',
              label: 'Distributor Settings',
              routerLink: 'distributor-settings',
              exact: false
            }"
          ></vs-sidebar-nav-item>

          <vs-sidebar-nav-item
            *ngIf="canOpenSettings$ | async"
            [config]="{
              icon: 'settings-01',
              label: 'Settings',
              routerLink: 'settings',
              exact: false
            }"
          ></vs-sidebar-nav-item>

          <vs-sidebar-nav-item
            *ngIf="!config.production"
            [config]="{
              icon: 'cube-04',
              label: 'UI Components',
              routerLink: 'components',
              exact: false
            }"
          ></vs-sidebar-nav-item>

          <vs-divider class="mb-2"></vs-divider>

          <div [vsTooltip]="userData.email" tooltipPosition="top" class="flex items-center justify-center h-10">
            <button
              vs-sidebar-nav-user
              [config]="{
                displayName: userData.displayName,
                email: userData.email,
                photoUrl: userData.photoURL,
                routerLink: 'user-settings/my-profile'
              }"
            ></button>
          </div>

          <!-- <vs-sign-out-button style="width: 100%" (signedOut)="signedOut()"></vs-sign-out-button> -->
        </div>
      </vs-sidebar-nav>
    </ng-template>
    <!-- <div slot="before-content" class="h-10 w-full bg-primary-300">Before Content</div> -->
    <router-outlet></router-outlet>
    <!-- <div slot="after-content" class="h-10 w-full bg-primary-300">After Content</div> -->
    <!-- <div slot="right" class="w-20 h-full bg-primary-400">Right</div> -->
    <!-- <div slot="bottom" class="h-20 bg-primary-600">Bottom</div> -->
  </vs-app-scaffold>
</ng-container>
