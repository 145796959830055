import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { CreateCustomerOptions, FundingSource } from '@vsolv/dwolla/domain';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Observable, ReplaySubject, switchMap, tap } from 'rxjs';
import { AddBankDetailsComponent, ManageBankDetailsComponent } from '../../components';
import { DwollaService } from '../../services';

export type ManageBankingDetailsDialogState = 'add' | 'manage';

@Component({
  selector: 'vs-manage-banking-details-dialog',
  templateUrl: './manage-banking-details.dialog.html',
})
export class ManageBankingDetailsDialog {
  @ViewChild(DialogComponent) dialog?: DialogComponent;
  @ViewChild(ManageBankDetailsComponent) manageBankDetails?: ManageBankDetailsComponent;
  @ViewChild(AddBankDetailsComponent) addBankDetails?: AddBankDetailsComponent;

  @Output() bankDetails = new EventEmitter<{ fundingSourceId: string }>();

  state!: ManageBankingDetailsDialogState;

  constructor(private dwollaSvc: DwollaService) {}

  @PropertyListener('customer')
  private customer$ = new ReplaySubject<CreateCustomerOptions>(1);

  @Input() customer: CreateCustomerOptions | null = null;

  @Input() customerId: string | null = null;

  fundingSources$: Observable<FundingSource[] | null> = this.customer$.pipe(
    switchMap(async customer => (customer ? this.dwollaSvc.listFundingSource(customer.email) : null)),
    tap(fundingSources => this.changeState(fundingSources && fundingSources.length ? 'manage' : 'add'))
  );

  open() {
    this.dialog?.open();
    this.manageBankDetails?.existingAccount.reset({ fundingSourceRadio: '' });
  }

  changeState(state: ManageBankingDetailsDialogState) {
    this.state = state;
  }

  confirmBankDetails({ fundingSourceId }: { fundingSourceId: string }) {
    this.bankDetails.emit({ fundingSourceId });
  }
}
