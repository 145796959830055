import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VsolvConfigService {
  constructor(private http: HttpClient) {}
  async get(key: string) {
    return await firstValueFrom(this.http.get<{ url: string }>(`/api/vsolv-config/${key}`));
  }
}
