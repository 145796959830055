import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { ApiMode } from '@vsolv/core/multi-tenant/domain';

import { API_MODE, TenantIdInterceptor } from './interceptors';
import { TenantInfoService, TenantService } from './services';

@NgModule({
  imports: [CommonModule],
  providers: [TenantService, TenantInfoService],
})
export class MultiTenancyWebModule {
  /** Registers the TenantIdInterceptor to handle requests and responses with tenant id */
  static forRoot(apiMode: ApiMode): ModuleWithProviders<MultiTenancyWebModule> {
    return {
      ngModule: MultiTenancyWebModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TenantIdInterceptor, multi: true },
        { provide: API_MODE, useValue: apiMode },
      ],
    };
  }
}
