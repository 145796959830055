import { IsNotEmpty, IsNumber, IsOptional, IsString } from 'class-validator';

export interface LiabilityGroup {
  id: string;

  title: string;
  description: string | null;

  /**
   * Monetary amount stored in cents
   */
  limit: number | null;

  limitPropertyPath?: string | null;
}

export class LiabilityGroupDto {
  @IsString()
  @IsNotEmpty()
  id!: string;

  @IsString()
  @IsNotEmpty()
  title!: string;

  @IsString()
  @IsOptional()
  description!: string | null;

  /**
   * Monetary amount stored in cents
   */
  @IsNumber()
  @IsOptional()
  limit!: number | null;

  @IsString()
  @IsOptional()
  limitPropertyPath?: string | null;
}
