import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { SftpService } from '../../services';

@Component({
  selector: 'ws-reroll-sftp-key-dialog',
  templateUrl: './reroll-sftp-key.dialog.html',
})
export class RerollSFTPKeyDialog {
  constructor(
    private firebaseSvc: FirebaseService,
    private fb: FormBuilder,
    private toastSvc: ToastService,
    private sftpSvc: SftpService,
    private clipboard: Clipboard
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() dialogConfig?: DialogConfig;
  @Input() distributorOrTenantId?: string;
  @Input() host?: string;
  @Input() keyId?: string;

  @Output() closed = new EventEmitter<string>();

  saving = false;
  newKey: null | string = null;

  form = this.fb.group({
    password: ['', [Validators.required]],
  });
  invalidPassword = false;

  inputIsValid() {
    return this.form.valid;
  }

  openDialog(key?: string) {
    if (key) this.newKey = key;
    this.dialog.open();
  }

  resetForm() {
    this.form.reset({ password: '' });
    this.form.markAsPristine();
  }

  resetDialog() {
    if (this.newKey) this.newKey = null;
    this.invalidPassword = false;
    this.resetForm();
  }

  closeDialog() {
    this.resetDialog();
    this.dialog.close();
  }

  async rerollKey() {
    if (this.keyId && this.distributorOrTenantId) {
      const { password } = this.form.getRawValue();
      if (password) {
        this.saving = true;
        this.form.disable();
        try {
          if (await this.firebaseSvc.reauthenticate(password)) {
            const response = await this.sftpSvc.rollCredentials(this.keyId, this.distributorOrTenantId);
            this.resetForm();
            this.closed.emit(response.id);
            this.newKey = response.key;
            this.invalidPassword = false;
          } else this.invalidPassword = true;
        } catch (e) {
          this.toastSvc.show({ type: 'error', title: 'Error during key reroll' });
        }
        this.form.enable();
        this.saving = false;
      }
    }
  }

  copy(value: string) {
    const copied = this.clipboard.copy(value);
    if (copied) this.toastSvc.show({ type: 'success', title: 'Password copied' });
    else this.toastSvc.show({ type: 'error', title: 'Error copying password', text: 'Please try again' });
  }
}
