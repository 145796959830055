<div class="flex items-center gap-2">
  <input
    vsInput
    id="checkbox"
    type="checkbox"
    [appearance]="property.config.format ?? 'checkbox'"
    [formControl]="formControl"
    [required]="required"
  />
  <label vsCheckboxLabel for="checkbox">{{ property.name }}</label>
  <span class="text-danger" slot="hint" *ngIf="formControl.touched && formControl.hasError('requiredTrue')">
    {{ property.name }} must be true
  </span>
  <span class="text-danger" slot="hint" *ngIf="formControl.touched && formControl.hasError('requiredFalse')">
    {{ property.name }} must be false
  </span>
</div>
