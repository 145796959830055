import { Component } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';
import { TableCellComponent } from '../../models';
import { ButtonCellProps } from '../button';

export interface IconButtonCellProps extends Omit<ButtonCellProps, 'text' | 'iconStart' | 'iconEnd'> {
  icon: IconName;
  tooltipText?: string;
}

@Component({
  template: `
    <ng-container *ngIf="data">
      <div (click)="data.disabled && $event.stopImmediatePropagation()">
        <a
          *ngIf="data.routerLink; else buttonTmpl"
          vs-icon-button
          [theme]="data.theme"
          [routerLink]="data.routerLink"
          [appearance]="data.type || 'default'"
          [rounded]="data.rounded || false"
          [disabled]="data.disabled || false"
          (click)="$event.stopPropagation()"
        >
          <vs-icon class="text-lg" [name]="data.icon"></vs-icon>
        </a>

        <ng-template #buttonTmpl>
          <button
            vs-icon-button
            type="button"
            [theme]="data.theme"
            [appearance]="data.type || 'default'"
            [rounded]="data.rounded || false"
            [disabled]="data.disabled || false"
            [vsTooltip]="data.tooltipText"
            tooltipStyle="light"
            tooltipPosition="top"
            (click)="$event.stopPropagation(); _click()"
          >
            <vs-icon class="text-lg" [name]="data.icon"></vs-icon>
          </button>
        </ng-template>
      </div>
    </ng-container>
  `,
})
export class IconButtonCellComponent extends TableCellComponent<IconButtonCellProps> {
  async _click() {
    await this.data?.click?.();
  }
}
