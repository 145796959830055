export enum Status {
  DRAFT = 'DRAFT',

  VOIDED = 'VOIDED',
  PENDING = 'PENDING',

  PAID = 'PAID',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',

  REFUNDED = 'REFUNDED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PROCESSING_REFUND = 'PROCESSING_REFUND',
}
