import { AbstractControl, FormArray, FormGroup, ValidationErrors } from '@angular/forms';

export function extractErrors(control: AbstractControl): ValidationErrors | null {
  if (control.valid) return null;

  return {
    ...control.errors,
    ...Object.entries(control instanceof FormGroup || control instanceof FormArray ? control.controls : {}).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: extractErrors(value) }),
      {} as ValidationErrors
    ),
  };
}
