import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressModule } from '@vsolv/core/address/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { NgPipesModule } from 'ngx-pipes';
import {
  BillingDetailsInputComponent,
  CreditCardInputComponent,
  PaymentMethodInputComponent,
  PaymentMethodItemComponent,
  PaymentMethodPickerComponent,
  SelectOrCreatePaymentMethodComponent,
} from './components';
import { PaymentMethodService } from './services';

@NgModule({
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // VectorsUI
    FormsModule,
    InputModule,
    SelectModule,
    IconsModule,
    ButtonModule,
    ThemingModule,
    UtilsModule,
    OverlayModule,

    NgPipesModule,
    AddressModule,
    NgxMaskPipe,
    NgxMaskDirective,
  ],
  declarations: [
    BillingDetailsInputComponent,
    CreditCardInputComponent,
    PaymentMethodInputComponent,
    PaymentMethodPickerComponent,
    SelectOrCreatePaymentMethodComponent,
    PaymentMethodItemComponent,
  ],
  exports: [SelectOrCreatePaymentMethodComponent, PaymentMethodItemComponent],
  providers: [PaymentMethodService],
})
export class PaymentMethodModule {}
