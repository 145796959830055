<form *vsLet="form.value as group" [formGroup]="form">
  <div *ngIf="group" class="flex flex-col gap-2 max-h-[256px] overflow-y-auto scrollbar-thin">
    <ws-condition-group-builder [propertySet]="propertySet" formControlName="condition"></ws-condition-group-builder>

    <vs-form-field *ngIf="type === 'string'" appearance="horizontal">
      <label
        [vsTooltip]="{
          title: 'Condition block output',
          subtitle: 'This is what the outcome value of the cell will be if all of this blocks conditions are met.'
        }"
        tooltipStyle="light"
      >
        Value
      </label>
      <vs-select class="flex-1" vsInput formControlName="value">
        <li class="mb-2" vs-select-option value="BASE">
          <vs-badge theme="primary"> Base </vs-badge>
        </li>

        <li *ngIf="coverage" class="mb-2" vs-select-option [value]="coverage.id" (click)="addIndividualAddon()">
          <vs-badge theme="info"> Individual Add-on </vs-badge>
        </li>

        <li *ngFor="let addon of filteredAddons$ | async" vs-select-option class="mb-2" [value]="addon.id">
          <vs-badge theme="info"> {{ addon.title }} </vs-badge>
        </li>

        <li class="mb-2" vs-select-option value="NOT_APPLICABLE">
          <vs-badge theme="danger"> Not Applicable </vs-badge>
        </li>
      </vs-select>
    </vs-form-field>

    <vs-form-field *ngIf="type === 'number'" appearance="horizontal">
      <label>Value</label>
      <vs-input-container>
        <div
          *ngIf="!form.value.valuePropertyPath"
          class="flex flex-row w-full h-full gap-2 justify-center align-middle items-center"
        >
          <p class="text-gray-600">$</p>
          <input type="text" mask="separator.2" thousandSeparator="," formControlName="value" />
        </div>

        <!-- Value Property Badge -->
        <div *ngIf="form.value.valuePropertyPath" class="w-fit max-w-[128px] pt-[5px]">
          <vs-badge theme="default" [vsTooltip]="form.value.valuePropertyPath" tooltipPosition="top" class="w-full h-4">
            <span class="w-full truncate">{{ form.value.valuePropertyPath }}</span>
            <vs-icon
              name="x-close"
              slot="end"
              class="cursor-pointer"
              (click)="setValuePropertyPath(null); valuePropertyPicker.setValue(null)"
            ></vs-icon>
          </vs-badge>
        </div>
        <ws-condition-value-property-picker
          #valuePropertyPicker
          class="pt-1"
          slot="end"
          [propertySet]="propertySet"
          [type]="num"
          format="currency"
          [selectedPropertyPath]="form.value.valuePropertyPath"
          (selectionChanged)="setValuePropertyPath($event.property)"
        ></ws-condition-value-property-picker>
      </vs-input-container>
    </vs-form-field>

    <vs-form-field *ngIf="type === 'object'" appearance="horizontal" [config]="{ label: 'Value' }">
      <vs-select class="flex-1" vsInput [config]="{ multiple: true }" formControlName="value">
        <ng-container *ngIf="plan && plan.liabilityGroups?.length">
          <li *ngFor="let group of plan.liabilityGroups" vs-select-option class="mb-2" [value]="group.id">
            {{ group.title }}
          </li>
        </ng-container>

        <li class="mb-2" vs-select-option value="nogroup">No Group</li>
      </vs-select>
    </vs-form-field>
  </div>
</form>
