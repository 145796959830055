import { Pipe, PipeTransform } from '@angular/core';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';

@Pipe({ name: 'conditionProperty' })
export class ConditionPropertyPipe implements PipeTransform {
  transform(propertyId: string, propertySet: PropertySet.Model) {
    return this.getPropertyFromId(propertyId, [
      ...Conditions.getProperties(),
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      ...(propertySet?.properties?.map(assignment => assignment.property!) ?? []),
    ]);
  }

  private getPropertyFromId(
    propertyId: string,
    properties: (Property.Model<Property.PropertyType> | Conditions.Property)[]
  ): Property.Model<Property.PropertyType> | Conditions.Property | undefined {
    for (const property of properties) {
      if (property.valueKey === propertyId) {
        return property;
      } else if (propertyId.startsWith(property.valueKey)) {
        return this.getPropertyFromId(
          propertyId.replace(property.valueKey + '.', ''),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (property as Property.ObjectModel).properties?.map(assignment => assignment.property!) ?? []
        );
      }
    }
    return undefined;
  }
}
