<vs-dialog #deleteCoverageDialog [config]="dialogConfig ?? { dialogContainerClass: 'max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,

      icon: 'archive',
      iconTheme: 'primary',

      title:'Delete ' + (coverage?.title ?? 'Coverage') + '?',
      subtitle: 'Are you sure you want to delete <i>' + coverage?.title + '</i>? ' + (policy?.status === draft 
        ? 'It will no longer be a coverage offered with ' + (policy?.title ? ('\'' + policy?.title + '\'') : 'its parent policy') + '.'
        : 'Deleting it will cause a new policy version to be created with all other coverages but this one.')
    }"
  ></vs-dialog-header>

  <div *ngIf="policy?.status !== draft" class="flex gap-2 text-sm text-danger-500 mb-4">
    <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
    <span class="self-center"> Saving will create a new policy version, the current policy will not be modified </span>
  </div>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="close()">
        Cancel
      </button>

      <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="delete()">
        Delete
      </button>
    </div>
  </div>
</vs-dialog>
