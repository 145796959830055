/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Platform } from '@angular/cdk/platform';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FileInputDirective } from '@vsolv/vectors-ui/input';
import { BehaviorSubject, tap } from 'rxjs';

@Component({
  selector: 'vs-file-input',
  templateUrl: './file-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: FileInputComponent,
    },
  ],
})
export class FileInputComponent implements ControlValueAccessor {
  constructor(public platform: Platform) {}

  @ViewChild(FileInputDirective) documents!: FileInputDirective;

  @Input() multiple = false;
  @Input() disabled = false;
  @Input() required = false;
  @Input() hideBorder = false;

  @Input() accept = '';
  @Input() moreInfo = '';

  dragging = '';
  isSafari = this.platform.SAFARI;

  @Input() set value(value: File | File[] | null) {
    this.formControl.setValue(value);
    this.writeValue(value);
    this.valueChanges.next(value);
  }

  @Output() valueChanges = new EventEmitter<File | File[] | null>();

  errors = new BehaviorSubject<string[]>([]);

  protected formControl = new FormControl(null as File | File[] | null);

  protected hidden = false;
  hidden$ = this.formControl.valueChanges.pipe(
    tap((value: File | File[] | null) => {
      if (this.multiple) this.hidden = false;
      else if (!value) this.hidden = false;
      else this.hidden = true;
    })
  );

  protected get files() {
    return Array.isArray(this.formControl.value)
      ? this.formControl.value
      : this.formControl.value
      ? [this.formControl.value]
      : null;
  }

  getUrl(file: File) {
    return URL.createObjectURL(file);
  }

  onChange = (_value: File | File[] | null) => {};
  onTouched = () => {};

  writeValue(_value: File | File[] | null): void {
    this.formControl.setValue(_value);
    const file = this.formControl.value && !Array.isArray(this.formControl.value) ? this.formControl.value : null;
    if (file && !this.hidden && !this.multiple) this.hidden = true;
  }

  registerOnChange(onChange: (_value: File | File[] | null) => {}): void {
    this.onChange = onChange;
    this.formControl.valueChanges.subscribe(onChange);
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
    this.formControl.touched;
  }

  setOnDragover() {
    this.dragging = 'bg-primary-50 border-primary-100 border-4';
  }

  setOnDragleave() {
    this.dragging = '';
  }
}
