import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { DIALOG_TOKEN, DialogComponent } from '../dialog/dialog.component';

export interface DialogHeaderConfig {
  mode?: 'default' | 'simple' | 'centered';
  hideCloseButton?: boolean;

  icon: IconName;
  iconTheme?: ThemeColor;

  title: string;
  subtitle?: string;
  showDivider?: boolean;
}

@Component({
  selector: 'vs-dialog-header',
  templateUrl: './dialog-header.component.html',
})
export class DialogHeaderComponent {
  @Input() config?: DialogHeaderConfig;
  @Output() closed = new EventEmitter();

  constructor(@Inject(DIALOG_TOKEN) private dialog: DialogComponent) {}

  closeDialog() {
    this.dialog.close();
    this.closed.emit();
  }
}
