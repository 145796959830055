/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TenantService } from '@vsolv/core/multi-tenant/web';
import { Config } from '@vsolv/packages/portal-config/domain';
import { ThemeUtils } from '@vsolv/vectors-ui/theming';
import { catchError, firstValueFrom, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PortalService {
  constructor(private http: HttpClient, private tenSvc: TenantService) {}
  // expiry Time(Days) for localStorage config object and new request will be sent to the Server.
  queryTime = 1;

  config!: Config.PortalConfig;

  async getConfig(id: string) {
    this.config = this.findFromLocalStorage(id) || (await this.getOne(id));
    return this.config;
  }

  get getConfigData() {
    return this.config;
  }

  async getPortalUrl() {
    return await firstValueFrom(this.http.get<{ url: string }>(`/v1/configuration/url`));
  }

  async getOne(id: string) {
    const config = await firstValueFrom(
      this.http.get<Config.PortalConfig>(`/v1/configuration/tenant_id/${id}`).pipe(catchError(() => of(null)))
    );

    if (config && this.validateConfig(config)) {
      localStorage.setItem('config', JSON.stringify({ ...config, tenantId: id }));
      localStorage.setItem('config-timestamp', JSON.stringify(new Date().getTime()));
    } else {
      localStorage.removeItem('config');
      localStorage.removeItem('config-timestamp');
    }

    return config;
  }

  findFromLocalStorage(tenantId: string | undefined) {
    const storedTimeStamp = localStorage.getItem('config-timestamp');
    const currentTimeStamp = new Date().getTime();

    if (storedTimeStamp) {
      // 10 minutes
      if (Math.abs(JSON.parse(storedTimeStamp) - currentTimeStamp) > this.queryTime * 10 * 60 * 1000) {
        return null;
      }
    }

    const config = localStorage.getItem('config');

    if (config) {
      const result = JSON.parse(config);

      if (tenantId === result.tenantId && this.validateConfig(result)) {
        return result;
      } else {
        localStorage.removeItem('config');
        localStorage.removeItem('config-timestamp');
        return null;
      }
    }
  }

  private validateConfig(config: Config.PortalConfig) {
    if (
      !config.productName ||
      !config.tagLine ||
      !config.prColor ||
      !config.logo ||
      !config.avatar ||
      !config.brandName
    ) {
      return false;
    }

    return true;
  }

  setTheming(config: Config.PortalConfig) {
    const css = `:root {
      ${config.prColor ? ThemeUtils.getStyles(config.prColor, 'primary') : ''}
      ${config.acColor ? ThemeUtils.getStyles(config.acColor, 'accent') : ''}
    `;

    const style: HTMLStyleElement = document.createElement('style');
    style.appendChild(document.createTextNode(css));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);
  }

  async updateConfig(portalConfigDto: Config.UpdatePortalConfigDetailsRequestDto) {
    const response = await firstValueFrom(
      this.http.post<Config.UpdatePortalConfigDetailsResponseDto>('/api/portal-config/update', portalConfigDto)
    );
    this.updateLocalStorage(response.config);
    this.setTheming(response.config);
    this.config = response.config;
    return response;
  }

  updateLocalStorage(config: Config.PortalConfig) {
    if (config) {
      const updatedConfig = JSON.stringify(config);
      localStorage.setItem('config', updatedConfig);
      localStorage.setItem('config-timestamp', JSON.stringify(new Date().getTime()));
    } else {
      localStorage.removeItem('config');
      localStorage.removeItem('config-timestamp');
    }
    return;
  }
}
