/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { DistributorService } from '@wsphere/distributors/web';
import { DistributorOnBoardingLinkResolver } from '../resolvers';

@Injectable()
export class OnboardDistributorGuard implements CanActivate {
  constructor(
    private router: Router,
    private distributorSvc: DistributorService,
    private resolver: DistributorOnBoardingLinkResolver
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.link = null;
    const linkId: string = await route.params['linkId'];

    if (linkId) {
      try {
        const distributorLink = await this.distributorSvc.validateOnboardingLink(linkId);
        this.resolver.link = distributorLink;
        return distributorLink?.id ? true : this.notFound();
      } catch (e) {
        return this.notFound();
      }
    }
    return this.notFound();
  }

  private notFound() {
    return this.router.createUrlTree(['../expired-link']);
  }
}
