/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { map } from 'rxjs';
import { DistributorDetailsInputValue } from '../../components';
import { DistributorService } from '../../services';

@Component({
  selector: 'ws-create-distributor-dialog',
  templateUrl: './create-distributor.dialog.html',
})
export class CreateDistributorDialog {
  constructor(
    private toastSvc: ToastService,
    private formBuilder: FormBuilder,
    private distributorSvc: DistributorService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() closed = new EventEmitter<string>();

  @Input() editing = false;

  _distributor: Distributor.Model | null = null;

  @Input() set distributor(distributor: Distributor.Model | undefined) {
    if (!distributor) return;

    this._distributor = distributor;
    if (this.editing) {
      this.form.patchValue({
        distributorDetails: {
          name: distributor.name,
          email: distributor.email || '',
          phone: distributor.phone || '',
        },
      });
    }
  }

  saving = false;

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  protected form = this.formBuilder.group({
    distributorDetails: [null as DistributorDetailsInputValue, [Validators.required]],
  });

  openDialog() {
    this.dialog?.open();
  }

  closeDialog() {
    this.form.reset({ distributorDetails: null });
    this.dialog?.close();
  }

  async submit() {
    const { distributorDetails } = this.form.value;
    this.form.disable();

    const dto = {
      name: distributorDetails?.name || '',
      email: distributorDetails?.email?.length ? distributorDetails.email.toLowerCase() : undefined,
      phone: distributorDetails?.phone?.length ? distributorDetails?.phone : undefined,
    };

    this.saving = true;
    this.form.disable();

    if (this._distributor && this.editing) {
      const distributor = await this.distributorSvc.updateDistributor(this._distributor.id, dto).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.closeDialog();
        this.saving = false;
      });

      if (distributor?.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Updated Distributor',
          text: 'Successfully updated the details of <strong>' + dto.name + '</strong>.',
        });

        this.closeDialog();
        this.closed.emit(distributor.id);
        this.saving = false;
      }
    } else if (this._distributor) {
      const distributor = await this.distributorSvc
        .createDistributor({ ...dto, parentId: this._distributor.id })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });

          this.closeDialog();
          this.saving = false;
        });

      if (distributor?.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Distributor Created',
          text: 'Successfully created <strong>' + dto.name + '</strong>.',
        });

        this.closeDialog();
        this.closed.emit(distributor.id);
        this.saving = false;
      }
    } else {
      const distributor = await this.distributorSvc.createDistributor(dto).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.closeDialog();
        this.saving = false;
      });

      if (distributor?.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Distributor successfully created',
          text: '<strong>' + dto.name + '</strong> has been successfully added to the dashboard.',
        });

        this.closeDialog();
        this.closed.emit(distributor.id);
        this.saving = false;
      }
    }

    this.form.enable();
  }
}
