<vs-dialog #archiveDialog [config]="dialogConfig ?? { dialogContainerClass: 'w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'archive',
      iconTheme: 'primary',

      title: 'Archive ' + policy?.title || 'Policy',
      subtitle: 'Are you sure you want to archive <i>' + policy?.title + '</i>? Existing warranties under this policy will remain active, however this policy will no longer be sold and will be marked as run-off and then archived once it has no active warranties.'
    }"
  ></vs-dialog-header>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="close()">
        Cancel
      </button>

      <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="archive()">
        Archive
      </button>
    </div>
  </div>
</vs-dialog>
