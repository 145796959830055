import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { ClaimLifecycleStep, Policy } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-claim-lifecycle-step-dialog',
  templateUrl: './claim-lifecycle-step.dialog.html',
})
export class ClaimLifecycleStepDialog {
  constructor(
    private formBuilder: FormBuilder,
    private clipboard: Clipboard,
    private toastSvc: ToastService,
    private portalSvc: PortalService
  ) {
    this.form.valueChanges.subscribe(value => {
      this.descriptionLength = value.description?.replace(/<.*?>/g, '')?.replace('&nbsp;', ' ')?.length || 0;
    });
  }

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy?: Policy.Model | null;
  @Input() step?: ClaimLifecycleStep.Model | null = null;

  @Output() closed = new EventEmitter<ClaimLifecycleStep.Model>();

  saving = false;
  showRichText = false;

  descriptionLength = 0;

  statuses = Object.keys(ClaimLifecycleStep.Status);

  form = this.formBuilder.group({
    icon: ['circle', Validators.required],
    title: ['', [Validators.required, Validators.maxLength(50)]],
    description: [''],
    status: [null as ClaimLifecycleStep.Status | null],
    optional: [false],
    visible: [true],
  });

  attachmentLinkInfo: { text: string; title: string; description: string }[] = [
    {
      text: 'Copy inspection report request link',
      title: 'Inspection report',
      description: `This is the inspection report for :contractNumber`,
    },
    {
      text: 'Copy receipt request link',
      title: 'Receipt',
      description: `This is the receipt for :contractNumber`,
    },
    {
      text: 'Copy supporting evidence request link ',
      title: 'Supporting evidence',
      description: `This is supporting evidence for claim: :contractNumber. It is used to better understand the complaint.`,
    },
  ];

  open(step?: ClaimLifecycleStep.Model) {
    if (step) {
      this.form.patchValue({
        icon: step.icon,
        title: step.title,
        description: step.description,
        status: step.status,
        optional: step.optional,
        visible: step.visible,
      });
      this.step = step;
    }

    this.showRichText = true;
    this.dialog.open();
  }

  close(fromClose?: boolean) {
    this.showRichText = false;
    this.form.reset();
    this.saving = false;

    if (!fromClose) this.dialog.close();
  }

  cancel() {
    this.close();
    this.closed.emit();
  }

  getStatusTheme(status: ClaimLifecycleStep.Status): ThemeColor {
    switch (status) {
      case ClaimLifecycleStep.Status.DONE:
        return 'success';
      case ClaimLifecycleStep.Status.IN_PROGRESS:
        return 'info';
      case ClaimLifecycleStep.Status.PENDING_CUSTOMER:
        return 'warn';
      case ClaimLifecycleStep.Status.UNSUCCESSFUL:
        return 'danger';
      default:
        return 'default';
    }
  }

  async copyAttachmentLink(link: { text: string; title: string; description: string }) {
    const portalLink = await this.portalSvc.getPortalUrl();
    const attachmentData = encodeURIComponent(JSON.stringify(link)).replace(/'/g, '%27').replace('%3A', ':');

    this.clipboard.copy(
      `${portalLink.url}/warranty/:contractNumber/claim/:claimId/manage-attachments?claimId=:claimId&openDialog=true&addAttachmentData=${attachmentData}`
    );

    this.toastSvc.show({
      type: 'success',
      text: `The <strong> ${link.title} </strong> link has been copied to your clipboard and can now be sent out to customers.`,
      title: `Link copied to clipboard.`,
    });
  }

  async copyPaymentLink() {
    const portalLink = await this.portalSvc.getPortalUrl();
    this.clipboard.copy(`${portalLink.url}/warranty/:contractNumber/claim/:claimId/manage-reimbursement-methods`);

    this.toastSvc.show({
      type: 'success',
      title: 'Link Copied',
      text: 'The customer manage payment methods link has been successfully copied to the clipboard.',
    });
  }

  async createStep() {
    this.saving = true;

    const value = this.form.value;
    if (!value.title) return;
    else {
      this.closed.emit({
        id: '',
        order: 0,
        icon: value.icon || 'circle',
        title: value.title,
        description: value.description || '',
        status: value.status ?? ClaimLifecycleStep.Status.NOT_STARTED_YET,
        optional: !!value.optional,
        visible: !!value.visible,
      });

      this.close();
    }
  }

  async updateStep() {
    this.saving = true;

    if (!this.step) throw new Error('Unable to create step, step not found');

    const value = this.form.value;
    if (!value.title) return;
    else {
      this.closed.emit({
        id: this.step.id,
        order: this.step.order,
        icon: value.icon || 'circle',
        title: value.title,
        description: value.description || '',
        status: value.status ?? ClaimLifecycleStep.Status.NOT_STARTED_YET,
        optional: !!value.optional,
        visible: !!value.visible,
      });

      this.close();
    }
  }
}
