<ng-container *vsLet="canEditStaffDetails$ | async">
  <ng-container *vsLet="paginatedData$ | async">
    <div class="flex justify-between gap-4 mb-4">
      <div class="flex gap-4">
        <vs-select vsInput [config]="{ placeholder: 'All statuses' }" (valueChanges)="refreshTable({ status: $event })">
          <li *ngFor="let status of statuses" vs-select-option [value]="status">{{ status | titlecase }}</li>
        </vs-select>

        <ws-role-picker
          class="min-w-[256px] max-w-[256px]"
          [filter]="true"
          (valueChanges)="refreshTable({ roleIds: $event })"
        ></ws-role-picker>

        <ws-distributor-filter
          (valueChanges)="refreshTable({ distributorId: $event?.id })"
          permissionId="stf_ViewSettings"
          [permissionKey]="distributor?.permissionKey ?? null"
        ></ws-distributor-filter>
      </div>

      <vs-input-container class="max-w-[256px]">
        <vs-icon name="search-lg" slot="start"></vs-icon>
        <input #searchInput vsInput (keyup)="refreshTable({ search: searchInput.value })" placeholder="Search" />
      </vs-input-container>
    </div>

    <div class="rounded-xl overflow-hidden border border-gray-200 shadow-sm">
      <vs-table [columns]="(columns$ | async) ?? []" [data]="members$ | async"></vs-table>
      <vs-pagination [config]="paginationConfig$ | async" (pagination)="pagination$.next($event)"></vs-pagination>
    </div>
  </ng-container>
</ng-container>

<!-- Dialogs -->
<ws-edit-team-member-dialog
  [distributor]="distributor"
  [teamMember]="selectedMember"
  (memberUpdated)="refresh()"
  [permissionKey]="permissionKey"
></ws-edit-team-member-dialog>

<ws-delete-team-member-dialog
  [distributor]="distributor"
  [teamMember]="selectedMember"
  (memberRemoved)="refresh()"
></ws-delete-team-member-dialog>

<ws-add-team-member-dialog
  [permissionKey]="distributor?.id ?? null"
  (staffInvited)="refresh()"
  [distributor]="distributor"
></ws-add-team-member-dialog>
