<vs-dialog
  #dialog
  [config]="{
    dialogContainerClass: 'max-w-xl w-full',
    disableBackdropDismiss: false,
    hideBackdrop: false
  }"
>
  <ng-container
    [ngTemplateOutlet]="preview"
    [ngTemplateOutletContext]="{
      coverage: addon?.coverages?.[selectedAddon]?.coverage,
      deductible: addon?.coverages?.[selectedAddon]?.deductible,
      liabilityLimit: addon?.coverages?.[selectedAddon]?.liabilityLimit,
      current: selectedAddon,
      last: (addon?.coverages?.length ?? 1) - 1
    }"
  ></ng-container>
  <ng-template
    #preview
    let-coverage="coverage"
    let-deductible="deductible"
    let-liabilityLimit="liabilityLimit"
    let-last="last"
    let-current="current"
  >
    <div *ngIf="coverage" class="flex flex-col gap-2 p-2">
      <label [for]="addon?.addonId" class="flex gap-2 items-center">
        <input
          type="checkbox"
          #checkbox
          [id]="addon?.addonId"
          vsInput
          theme="primary"
          (change)="selected.emit(checkbox.checked)"
          [checked]="checked"
        />
        <div class="text-primary-500 font-semibold">{{addon?.addon?.title}}</div>
        <vs-badge theme="success" *ngIf="addon?.price as price"
          >{{ price / 100 | currency : 'USD' : 'symbol' : (price % 100 === 0 ? '1.0' : '1.2') }}</vs-badge
        >
      </label>
      <vs-divider></vs-divider>
      <div class="flex justify-between items-center">
        <div class="flex gap-2 items-center">
          <span class="text-md font-semibold">{{ coverage?.title}}</span>
          <vs-badge theme="primary">{{ coverage?.group }}</vs-badge>
        </div>
        <div *ngIf="last + 1 > 1" class="flex gap-4 items-center">
          <button
            *ngIf="current !== 0"
            vs-icon-button
            size="md"
            appearance="clear"
            theme="primary"
            (click)="selectedAddon = current - 1"
          >
            <vs-icon name="chevron-left" size="20" class="text-primary-500"></vs-icon>
          </button>
          <button
            *ngIf="current !== last"
            vs-icon-button
            size="sm"
            appearance="clear"
            theme="primary"
            (click)="selectedAddon = current + 1"
          >
            <vs-icon name="chevron-right" size="20" class="text-primary-500"></vs-icon>
          </button>
        </div>
      </div>
      <p>{{ coverage?.description }}</p>

      <vs-divider></vs-divider>

      <ng-container *ngIf="coverage?.inclusions">
        <div class="flex gap-2 items-center">
          <vs-icon size="22" name="shield-tick" class="text-primary"></vs-icon>
          <h3 class="text-gray-900 font-semibold">Inclusions</h3>
        </div>

        <div class="text-sm font-normal">{{ coverage?.inclusions}}</div>

        <vs-divider></vs-divider>
      </ng-container>

      <ng-container *ngIf="coverage?.exclusions">
        <div class="flex items-center gap-2">
          <vs-icon size="22" name="shield-off" class="text-primary"></vs-icon>
          <h3 class="text-gray-900 font-semibold">Exclusions</h3>
        </div>

        <div class="text-sm font-normal">{{ coverage?.exclusions }}</div>
      </ng-container>

      <div
        *ngIf="!hideDeductibleAndLiabilityLimit"
        class="flex justify-center items-center gap-4 border-y border-gray-200 py-2"
      >
        <span class="flex gap-1 items-center">
          <vs-icon name="bank-note-02"></vs-icon>
          {{ deductible / 100 | currency : 'USD' : 'symbol' : '1.0-2' }} Deductible
        </span>

        <span class="flex gap-1 items-center">
          <vs-icon name="currency-dollar-circle"></vs-icon>
          <span> {{ (liabilityLimit || 0) / 100 | currency : 'USD' : 'symbol' : '1.0-2' }} </span>
          Liability Limit
        </span>
      </div>

      <div class="flex justify-end mt-4 w-full">
        <button vs-button class="text-sm font-semibold text-gray-700" appearance="outline" (click)="dialog?.close()">
          Close
        </button>
      </div>
    </div>
  </ng-template>
</vs-dialog>
