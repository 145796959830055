<ng-container *vsLet="key$ | async as key">
  <div class="flex flex-col gap-4 text-gray-700 text-sm">
    <div class="text-justify">
      The TaxJar Custom Key app simplifies tax recording for your transactions by allowing administrators to integrate
      their own TaxJar API key. Once enabled, this feature ensures precise and automated tax calculations directly
      within your organization's dashboard, enhancing compliance and financial accuracy.
    </div>
    <div class="flex flex-col gap-1.5 pb-4">
      <label for="secret" class="font-medium">Secret key</label>
      <input
        type="text"
        [ngClass]="secretKeyControl.value ? 'blur-sm hover:blur-none mt-1' : 'mt-1'"
        vsInput
        theme="primary"
        [formControl]="secretKeyControl"
        id="secret"
      />
    </div>
    <div class="flex justify-end items-center gap-2" *ngIf="canManageTaxJarCustomKey$ | async">
      <button
        vs-button
        appearance="outline"
        theme="default"
        [disabled]="!secretKeyControl.value"
        (click)="key ? disableBotDialog.open() : enable(secretKeyControl.value)"
      >
        {{key ? 'Disable' : 'Enable'}}
      </button>
      <button
        vs-button
        appearance="default"
        theme="primary"
        [disabled]="(!key) || (key && secretKeyControl.value === key)"
        (click)="update(secretKeyControl.value)"
      >
        Update key
      </button>
    </div>
  </div>
</ng-container>

<vs-dialog #disableBotDialog [config]="{dialogContainerClass: 'w-full md:w-96'}">
  <vs-dialog-header
    [config]="{icon: 'coins-stacked-03', iconTheme:'primary', title: 'Disable TaxJar custom key app?' , subtitle: 'Are you sure you want to disable this app? Customers will no longer have access to chatbot support on the customer portal.', mode: 'simple', hideCloseButton: true}"
  ></vs-dialog-header>
  <div class="w-full flex flex-row gap-2 justify-between">
    <button vs-button class="w-1/2" appearance="outline" (click)="disableBotDialog.close();">Cancel</button>
    <button vs-button class="w-1/2" theme="danger" (click)="disable(); disableBotDialog.close()">
      Disable Application
    </button>
  </div>
</vs-dialog>
