/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { AssetService } from '@wsphere/assets/web';
import { Provisioning } from '@wsphere/warranties/domain';
import { map, Subject, takeUntil } from 'rxjs';
import { ProvisioningSessionService } from '../../services';

@Component({
  selector: 'ws-provision-confirmation',
  templateUrl: './provision-confirmation.component.html',
})
export class ProvisionConfirmationComponent implements OnInit, OnDestroy {
  constructor(
    private sessionSvc: ProvisioningSessionService,
    private route: ActivatedRoute,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private toastSvc: ToastService,
    private assetSvc: AssetService
  ) {}

  @HostBinding('class') _class = 'pb-24 px-6';

  protected stepCompleted = false;
  protected destroy$ = new Subject<void>();

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  protected session$ = this.sessionSvc.getCurrentSession();

  protected assetInfo = new FormControl(null as Record<string, unknown> | null);

  readonly staffView$ = this.route.data.pipe(map(data => data['staffView'] as boolean));

  private navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }

  previousPage(staffView: boolean) {
    if (staffView) this.navigateTo('/provisioning/policy');
    else this.navigateTo('/checkout/policy');
  }

  async nextPage(session: Provisioning.ProvisioningSession | null, staffView: boolean) {
    let data: Provisioning.UpdateProvisioningSessionDto | null = null;

    const assetInfo = await this.createAsset(session);
    if (!assetInfo?.id) {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong while creating your asset!',
        text:
          (assetInfo as any)?.message ||
          `Sorry, looks like something went wrong creating your asset. Please verify your information and try again`,
      });
      return;
    }
    data = { assetInfo: { id: assetInfo.id, assetSubmissionId: assetInfo.submissionId } };

    if (this.stepCompleted && session && data.assetInfo?.id) {
      await this.sessionSvc.updateSession({
        sessionId: session.id,
        data,
      });
      if (staffView) {
        this.navigateTo('/provisioning/plan');
      } else {
        this.navigateTo('/checkout/plan');
      }
    }
  }

  async createAsset(session: Provisioning.ProvisioningSession | null) {
    if (!session) return;
    if (!session.policy) return;

    const assetInfo = this.assetInfo.value;
    if (!assetInfo || !this.assetInfo.valid) {
      this.toastSvc.show({
        type: 'error',
        title: 'Cannot create asset',
        text: 'Please provide all required information in order to create a new asset',
      });
    }
    if (assetInfo) {
      try {
        return await this.assetSvc.createAssetForSales({
          sessionId: session.id,
          customerId: session.customerId,
          submission: { setId: session.policy.propertySetId, propertyValues: assetInfo },
        });
      } catch (err) {
        console.error(err);
        return undefined;
      }
    } else return undefined;
  }

  ngOnInit(): void {
    this.assetInfo.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(assetInfo => (this.stepCompleted = !!assetInfo));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
