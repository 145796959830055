<ng-container *ngIf="canViewPayment$ && claim$ | async as claim">
  <ng-container *vsLet="canManagePayments$ | async as canManagePayments">
    <ng-container *vsLet="canManagePaymentMethods$ | async as canManagePaymentMethods">
      <ng-container *vsLet="customer$ | async as customer">
        <ng-container *vsLet="customerId$ | async as customerId">
          <vs-card *vsLet="fundingSources$ | async as fundingSources" theme="default" class="flex flex-col gap-4">
            <ng-container *vsLet="paymentsAndItems$ | async as payments">
              <vs-card-header
                [config]="{ title: 'Payments', subtitle: 'Manage and view all payments for this claim below.' }"
              >
                <vs-badge slot="after-title" theme="default">
                  {{ paginationConfig.totalItems || 0 }} payment{{ paginationConfig.totalItems !== 1 ? 's' : '' }}
                </vs-badge>

                <div class="flex gap-2 items-center" slot="end">
                  <button
                    *ngIf="canManagePayments && payments?.length"
                    vs-button
                    theme="primary"
                    (click)="paymentDialog?.open(claim)"
                  >
                    <vs-icon name="plus"></vs-icon>
                    New Payment
                  </button>

                  <button
                    *ngIf="canManageLink$ | async"
                    vs-icon-button
                    appearance="outline"
                    [vsMenuTriggerFor]="linkMenu"
                  >
                    <vs-icon name="link-external-01" size="20"></vs-icon>

                    <ng-template #linkMenu>
                      <vs-menu class="mt-2">
                        <button vs-menu-item (click)="copyLink(claim.warranty?.contractNumber ?? '', claim.id)">
                          Copy manage payment method link
                        </button>
                      </vs-menu>
                    </ng-template>
                  </button>
                </div>
              </vs-card-header>

              <ng-container *ngIf="!loading; else isLoading">
                <vs-card-content class="!pt-0">
                  <ws-claim-payments-table
                    *ngIf="payments?.length; else noPayments"
                    [claim]="claim"
                    [customer]="customer"
                    [payments]="payments"
                    [canEdit]="canManagePayments"
                    [destinations]="fundingSources"
                    [customerId]="customerId?.id ?? null"
                    [paginationConfig]="paginationConfig"
                    [canIssue]="canIssuePayments$ | async"
                    [canVoid]="canFinishPayments$ | async"
                    [canManagePaymentMethods]="canManagePaymentMethods"
                    (refresh)="refresh()"
                    (refreshPaymentMethods)="refreshPaymentMethods()"
                    (paginationQueryRequest)="paginationQueryRequest$.next($event)"
                  ></ws-claim-payments-table>

                  <ng-template #noPayments>
                    <div class="flex flex-col justify-center gap-2 p-6">
                      <p class="text-xl font-semibold text-gray-900 self-center">
                        No payments have been made for this claim.
                      </p>
                      <p class="font-normal text-gray-600 self-center mb-4">
                        Once a payment has been made, its details will appear here.
                      </p>

                      <button
                        vs-button
                        appearance="outline"
                        class="self-center"
                        *ngIf="canManagePayments"
                        (click)="paymentDialog?.open(claim)"
                      >
                        <vs-icon name="bank-note-01"></vs-icon> New Payment
                      </button>
                    </div>
                  </ng-template>
                </vs-card-content>
              </ng-container>

              <ng-template #isLoading>
                <div class="flex items-center justify-center min-h-[350px]">
                  <vs-icon name="loading-01" class="animate-spin text-primary-500" size="40"></vs-icon>
                </div>
              </ng-template>
            </ng-container>

            <ws-claim-payment-dialog
              [customer]="customer"
              [customerId]="customerId?.id ?? null"
              [destinations]="fundingSources"
              [canManagePaymentMethods]="canManagePaymentMethods"
              (refresh)="refresh()"
              (refreshPaymentMethods)="refreshPaymentMethods()"
            ></ws-claim-payment-dialog>
          </vs-card>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<router-outlet></router-outlet>
