<ng-container *ngIf="type === 'HOME'">
  <form [formGroup]="form" class="grid grid-cols-2 gap-3 justify-center">
    <!-- Line 1 -->
    <vs-form-field
      class="col-span-2"
      [theme]="form.controls.line1.touched && form.controls.line1.hasError('required') ? 'danger' : 'primary'"
      [config]="{ label: 'Address line 1:' }"
    >
      <input vsInput type="text" formControlName="line1" placeholder="Enter your address" />

      <span
        class="text-danger"
        slot="hint"
        *ngIf="form.controls.line1.touched && form.controls.line1.hasError('required')"
      >
        Address line 1 is required
      </span>
    </vs-form-field>

    <!-- Line 2 -->
    <vs-form-field class="col-span-2" [config]="{ label: 'Address line 2 (optional):' }">
      <input vsInput type="text" formControlName="line2" placeholder="Enter your address" />
    </vs-form-field>

    <!-- City -->
    <vs-form-field
      class="col-span-2"
      [theme]="form.controls.city.touched && form.controls.city.hasError('required') ? 'danger' : 'primary'"
      [config]="{ label: 'City' }"
    >
      <input vsInput type="text" formControlName="city" placeholder="Enter your city" />

      <span
        class="text-danger"
        slot="hint"
        *ngIf="form.controls.city.touched && form.controls.city.hasError('required')"
      >
        City is required
      </span>
    </vs-form-field>

    <!-- Zip -->
    <vs-form-field
      class="col-span-1"
      [theme]="form.controls.zip.touched && form.controls.zip.hasError('required') ? 'danger' : 'primary'"
      [config]="{ label: 'Zip' }"
    >
      <input vsInput type="text" formControlName="zip" placeholder="Enter your zip code" />

      <span class="text-danger" slot="hint" *ngIf="form.controls.zip.touched && form.controls.zip.hasError('required')"
        >Zip is required</span
      >
      <span class="text-danger" slot="hint" *ngIf="form.controls.zip.touched && form.controls.zip.hasError('pattern')"
        >Zip has invalid value</span
      >
    </vs-form-field>

    <!-- State -->
    <vs-form-field
      class="col-span-1"
      [theme]="form.controls.state.touched && form.controls.state.hasError('required') ? 'danger' : 'primary'"
      [config]="{ label: 'State' }"
    >
      <div *vsLet="states$ | async"></div>
      <vs-select vsInput type="text" formControlName="state" [config]="{ placeholder: 'Select your state' }">
        <li vs-select-option *ngFor="let state of stateValues$ | async" [value]="state.abbreviation">
          {{ state.name }}
        </li>
      </vs-select>

      <span
        class="text-danger"
        slot="hint"
        *ngIf="form.controls.state.touched && form.controls.state.hasError('required')"
      >
        State is required
      </span>
    </vs-form-field>

    <!-- Country -->
    <vs-form-field
      class="col-span-2"
      [theme]="
        (form.controls.country.touched && form.controls.country.hasError('required')) ||
        form.controls.country.hasError('pattern')
          ? 'danger'
          : 'primary'
      "
      [config]="{ label: 'Country' }"
    >
      <vs-select [config]="{ placeholder: 'Select your country' }" vsInput type="text" formControlName="country">
        <li vs-select-option *ngFor="let country of COUNTRIES" [value]="country.abbreviation">
          {{ country.name }}
        </li>
      </vs-select>

      <span
        class="text-danger"
        slot="hint"
        *ngIf="form.controls.country.touched && form.controls.country.hasError('required')"
        >Country is required</span
      >
      <span
        class="text-danger"
        slot="hint"
        *ngIf="form.controls.country.touched && form.controls.country.hasError('pattern')"
        >Valid country values are 'US', 'CA'
      </span>
    </vs-form-field>
  </form>
</ng-container>
