<div class="flex gap-1 mb-2" *ngIf="extraData && extraData['address']?.line1">
  <input
    #checkbox
    vsInput
    id="checkbox"
    type="checkbox"
    class="self-center"
    [formControl]="addressCheckbox"
    (click)="useAddress(checkbox.checked, extraData['address'])"
  />

  <label class="ml-1" vsCheckboxLabel for="checkbox">
    {{ extraData['staffView'] ? 'Use customer’s home address' : 'Use home address' }}
  </label>
</div>

<vs-address-input
  [formControl]="formControl"
  [staffView]="extraData ? extraData['staffView'] ?? false : false"
></vs-address-input>
