<vs-app-page
  *ngIf="payment$ | async as payment"
  [config]="{
    title: payment.title,
    description: 'This is a preview of all details related to this transaction'
  }"
>
  <vs-breadcrumbs
    slot="breadcrumbs"
    [config]="{ items: [{ label: 'Sales', routerLink: '..' }, { label: payment.title }] }"
  ></vs-breadcrumbs>

  <vs-badge
    *ngIf="paymentStatus$ | async as status"
    [config]="{ displayStatusIcon: true }"
    [theme]="status.theme"
    slot="title-end"
  >
    {{ status.label }}
  </vs-badge>

  <ng-container *ngIf="warranty$ | async as warranty" slot="header-end">
    <ng-container
      *ngIf="(warranty.status === 'REGISTERED' || warranty.status === 'ACTIVATED') && (canCancelWarranty$ | async)"
    >
      <button vs-button theme="danger" appearance="outline" class="ml-auto" (click)="cancelWarrantyDialog.open()">
        Cancel warranty
      </button>

      <ws-cancel-warranty-dialog
        #cancelWarrantyDialog
        [warranty]="warranty"
        (cancelled)="refresh(payment.id)"
      ></ws-cancel-warranty-dialog>
    </ng-container>
  </ng-container>

  <vs-divider class="mb-8"></vs-divider>

  <vs-card class="my-6 overflow-hidden">
    <vs-card-content class="!p-6">
      <ws-payment-invoice
        *ngIf="payment.user && payment.taxes"
        [payment]="$any(payment)"
        (refresh)="refresh(payment.id)"
      ></ws-payment-invoice>
    </vs-card-content>
  </vs-card>

  <vs-card
    *ngIf="payment.referenceId"
    class="my-6 overflow-hidden hidden"
    [class.hidden]="!(relatedTransactionsTable.page$ | async)?.meta?.totalItems"
  >
    <vs-card-header
      class="flex items-end justify-between"
      [config]="{ title: 'Related Transactions' }"
    ></vs-card-header>

    <ws-payments-table
      #relatedTransactionsTable
      [filters]="{ referenceId: payment.referenceId, excludeIds: [payment.id] }"
      (rowClicked)="rowClicked($event)"
    ></ws-payments-table>
  </vs-card>
</vs-app-page>
