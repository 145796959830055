<ckeditor
  [data]="value"
  [editor]="editor"
  [disabled]="disabled"
  [style.--max-height]="hideToolbar ? '150px' : '300px'"
  [style.--hide-toolbar]="hideToolbar ? 'none' : 'initial'"
  [style.--round-editor]="hideToolbar ? '0.375rem' : '0px'"
  [style.--hide-border]="hideBorder ? 'none' : '1px solid rgb(var(--vs-gray-300))'"
  [style.--square-bottom]="squareContentBottomBorder ? '' : '0.375rem'"
  [style.--top-border]="
    showContentTopBorder
      ? '1px solid rgb(var(--vs-gray-300))'
      : hideBorder
      ? 'none'
      : '1px solid rgb(var(--vs-gray-300))'
  "
  [style.--ck-border-radius]="'0.375rem'"
  [style.--ck-color-button-on-color]="'rgb(var(--vs-gray-50))'"
  [style.--ck-color-list-button-on-background]="'rgb(var(--vs-theme-500, var(--vs-gray-500)) / var(--tw-bg-opacity))'"
  [style.--ck-color-button-on-background]="'rgb(var(--vs-theme-500, var(--vs-gray-500)) / var(--tw-bg-opacity))'"
  [style.--ck-color-list-button-on-background-focus]="
    'rgb(var(--vs-theme-500, var(--vs-gray-500)) / var(--tw-bg-opacity))'
  "
  [style.--ck-color-button-default-hover-background]="
    'rgb(var(--vs-theme-500, var(--vs-gray-600)) / var(--tw-bg-opacity))'
  "
  [style.--ck-color-button-default-active-background]="
    'rgb(var(--vs-theme-500, var(--vs-gray-600)) / var(--tw-bg-opacity))'
  "
  [style.--ck-color-button-on-hover-background]="'rgb(var(--vs-theme-500, var(--vs-gray-600)) / var(--tw-bg-opacity))'"
  [style.--ck-color-button-on-active-background]="'rgb(var(--vs-theme-500, var(--vs-gray-600)) / var(--tw-bg-opacity))'"
  [style.--ck-color-list-button-hover-background]="
    'rgb(var(--vs-theme-500, var(--vs-gray-600)) / var(--tw-bg-opacity))'
  "
  [style.--focus-border]="
    maxLength && value.length > maxLength ? '1px solid rgb(var(--vs-danger-300))' : '1px solid rgb(var(--vs-theme-300))'
  "
  [style.--focus-shadow]="
    'var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) ' +
    (maxLength && value.length > maxLength ? 'rgb(var(--vs-danger-100))' : 'rgb(var(--vs-theme-100))')
  "
  [style.--display-file-icon]="allowFileUpload ? 'inherit' : 'none'"
  [config]="allowMediaForm ? {} : { removePlugins: ['MediaEmbed'], mediaEmbed: {} }"
  (change)="editorChanged($event)"
>
</ckeditor>
