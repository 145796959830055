import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { ToastComponent } from '../components/toast/toast.component';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private overlay: Overlay) {}

  displayToast(message: string, type: string, delay: number) {
    const config = new OverlayConfig({
      positionStrategy: this.overlay.position().global().centerVertically().centerHorizontally(),
    });

    const overlayRef = this.overlay.create(config);

    const toast = overlayRef.attach(new ComponentPortal(ToastComponent));
    toast.instance.message = message;
    toast.instance.type = type;

    setTimeout(() => {
      overlayRef.detach();
      overlayRef.dispose();
    }, delay);
  }
}
