/* eslint-disable @angular-eslint/no-input-rename */
import { coerceNumberProperty, NumberInput } from '@angular/cdk/coercion';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Position, TooltipComponent, TooltipContent, TooltipMaxWidth, TooltipStyle } from '../components';

@Directive({ selector: '[vsTooltip]', exportAs: 'vsTooltip' })
export class TooltipDirective implements OnDestroy {
  @Input('vsTooltip') content?: TooltipContent;

  @Input('tooltipPosition') position: Position = 'top';
  @Input('tooltipStyle') style: TooltipStyle = 'dark';
  @Input('tooltipMaxWidth') maxWidth: TooltipMaxWidth = 'lg';

  private _openDelay = 0;
  @Input('tooltipOpenDelay') set openDelay(value: NumberInput) {
    this._openDelay = coerceNumberProperty(value);
  }

  private _closeDelay = 0;
  @Input('tooltipCloseDelay') set closeDelay(value: NumberInput) {
    this._closeDelay = coerceNumberProperty(value);
  }

  private overlayRef?: OverlayRef = this.overlay.create();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private openTimeout?: any;
  private closing = false;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay,
    router: Router
  ) {
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(() => this.dispose());
  }

  @HostListener('mouseenter')
  private _mouseenter() {
    if (!this.content) return;
    this.closing = false;

    if (this.overlayRef === undefined) this.overlayRef = this.overlay.create();
    if (this.overlayRef?.hasAttached()) this.overlayRef?.detach();

    const positionConfigs = this.getPositionConfigs();
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withViewportMargin(OFFSET_SIZE)
      .withPositions(positionConfigs);

    this.overlayRef?.updatePositionStrategy(positionStrategy);

    const open = () => {
      const tooltipRef = this.overlayRef?.attach(new ComponentPortal(TooltipComponent));
      if (tooltipRef) {
        tooltipRef.instance.originElementRef = this.elementRef;
        tooltipRef.instance.content = this.content;
        tooltipRef.instance.style = this.style;
        tooltipRef.instance.maxWidth = this.maxWidth;
      }
    };

    if (this._openDelay) this.openTimeout = setTimeout(() => open(), this._openDelay);
    else open();
  }

  @HostListener('mouseleave')
  private _mouseleave() {
    if (this.openTimeout) clearTimeout(this.openTimeout);
    this.openTimeout = undefined;

    if (this._closeDelay) {
      this.closing = true;
      setTimeout(() => {
        if (this.closing) {
          this.overlayRef?.detach();
          this.closing = false;
        }
      }, this._closeDelay);
    } else this.overlayRef?.detach();
  }

  private getPositionConfigs() {
    switch (this.position) {
      case 'top':
        return [TOP_POSITION, BOTTOM_POSITION];
      case 'bottom':
        return [BOTTOM_POSITION, TOP_POSITION];
      case 'start':
        return [START_POSITION, END_POSITION];
      case 'end':
        return [END_POSITION, START_POSITION];
    }
  }

  ngOnDestroy() {
    this.dispose();
  }

  private dispose() {
    this.overlayRef?.dispose();
    this.overlayRef = undefined;
  }
}

const OFFSET_SIZE = 12;

const TOP_POSITION: ConnectedPosition = {
  originX: 'center',
  originY: 'top',
  overlayX: 'center',
  overlayY: 'bottom',
  offsetX: 0,
  offsetY: -OFFSET_SIZE,
};

const BOTTOM_POSITION: ConnectedPosition = {
  originX: 'center',
  originY: 'bottom',
  overlayX: 'center',
  overlayY: 'top',
  offsetX: 0,
  offsetY: OFFSET_SIZE,
};

const START_POSITION: ConnectedPosition = {
  originX: 'start',
  originY: 'center',
  overlayX: 'end',
  overlayY: 'center',
  offsetX: -OFFSET_SIZE * 2,
  offsetY: 0,
};

const END_POSITION: ConnectedPosition = {
  originX: 'end',
  originY: 'center',
  overlayX: 'start',
  overlayY: 'center',
  offsetX: OFFSET_SIZE,
  offsetY: 0,
};
