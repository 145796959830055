import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { CardModule } from '@vsolv/vectors-ui/card';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { GOOGLE_MAP_CONFIG, GoogleMapConfig } from './GoogleMapConfig';
import { MapComponent } from './components';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { AddressPipe } from './pipes';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    FormsModule,
    ReactiveFormsModule,
    InputModule,
    ThemingModule,
    CardModule,
    IconsModule,
    SelectModule,
    UtilsModule,
    BadgeModule,
    MenuModule,
    OverlayModule,
  ],
  declarations: [MapComponent, AddressPipe, AddressInputComponent],
  exports: [MapComponent, AddressPipe, AddressInputComponent],
})
export class AddressModule {
  static forRoot(config: GoogleMapConfig): ModuleWithProviders<AddressModule> {
    return {
      ngModule: AddressModule,
      providers: [{ provide: GOOGLE_MAP_CONFIG, useValue: config }],
    };
  }
}
