<div #inputContainer class="min-w-[200px]">
  <vs-input-container>
    <div slot="start">
      <vs-badge *ngIf="!hideAllDistributorsBadge && value === null">All Distributors</vs-badge>
      <vs-badge class="flex items-center" *ngIf="value" (click)="remove()">
        {{ value.name }}
        <vs-icon name="x" class="text-primary"></vs-icon>
      </vs-badge>
    </div>
    <input
      #distributorInfo
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      type="text"
      [disabled]="disabled"
      [placeholder]="placeholder"
      (input)="searchFor($any($event.target).value)"
      (click)="$event.preventDefault(); $event.stopImmediatePropagation(); toggle()"
    />
  </vs-input-container>
</div>
<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="overlayOpen"
  [cdkConnectedOverlayOffsetX]="-distributorInfo.offsetLeft"
  (detach)="overlayOpen = false; openChanged.emit(false)"
  (overlayOutsideClick)="$event.target !== distributorInfo ? (overlayOpen = false) : ''"
  (attach)="openChanged.emit(true)"
>
  <vs-card
    cdkTrapFocus
    cdkTrapFocusAutoCapture
    class="absolute top-0 my-2 !rounded-md !shadow-md min-w-fit"
    [theme]="themeColor"
    [style.width.px]="inputContainer.offsetWidth"
    *vsLet="distributors$ | async as distributors"
  >
    <div
      *ngIf="distributors?.items?.length; else noDistributors"
      class="px-2 py-3 max-h-60 overflow-auto scrollbar-thin"
    >
      <ul
        vs-select-options-list
        [disabled]="disabled"
        [compareWith]="compareWith"
        [selectValue]="value ? [value] : []"
        (selectValueChange)="selectValueChanged($event.value[0])"
      >
        <li
          vs-select-option
          *ngFor="let distributor of distributors?.items"
          [value]="distributor"
          class="overflow-ellipsis"
        >
          <vs-badge>{{ distributor.name }}</vs-badge>
        </li>
      </ul>
    </div>

    <ng-template #noDistributors>
      <p class="p-4 text-sm font-medium text-gray-900">No distributors were found using that search</p>
    </ng-template>
  </vs-card>
</ng-template>
