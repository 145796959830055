/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';

import { ActivatedRoute, Router } from '@angular/router';
import { isVectorSolvRequest } from '@vsolv/dev-kit/ngx';

/** This interceptor is meant to redirect the user to the service unavailable page*/
@Injectable()
export class DowntimeInterceptor implements HttpInterceptor {
  constructor(private router: Router, private route: ActivatedRoute) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isVectorSolvRequest(req)) {
      return next.handle(req).pipe(
        catchError((err: HttpErrorResponse) => {
          console.error(err);
          this.handleResponse(err);
          return throwError(() => err);
        })
      );
    }
    return next.handle(req);
  }

  /** Incoming responses are intercepted and scanned for error 503, Service Unavailable */
  private handleResponse(response: HttpErrorResponse) {
    if (response.status === 503) {
      this.router.navigate(['503']);
    }
  }
}
