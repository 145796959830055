import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';

export interface FormFieldConfig {
  label?:
    | string
    | {
        text: string;
        for?: string;
      };
  labelClass?: string;
  description?: string;
  hint?: string;
}

export type FormFieldAppearance = 'horizontal' | 'vertical' | 'responsive';

@Component({
  selector: 'vs-form-field',
  templateUrl: './form-field.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFieldComponent implements AfterViewInit {
  @HostBinding('class') get styles() {
    return `block w-full ${this.appearance === 'responsive' ? '@container' : ''}`;
  }

  @Input() config?: FormFieldConfig;
  @Input() appearance: FormFieldAppearance = 'responsive';

  _inputElId?: string;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    this.findInputElement();
  }

  getAppearanceClasses() {
    switch (this.appearance) {
      case 'responsive':
        return `gap-2 flex-col @2xl:flex-row @2xl:gap-8`;
      case 'horizontal':
        return `gap-8`;
      case 'vertical':
        return `gap-2 flex-col`;
      default:
        return `gap-2 flex-col @2xl:flex-row @2xl:gap-8`;
    }
  }

  @PropertyListener('config')
  private findInputElement() {
    if (typeof this.config?.label === 'string') {
      const element = this.elementRef.nativeElement.querySelector('input,textarea,select') as HTMLElement | undefined;
      if (element && !element.id) element.setAttribute('id', 'form-field-input-' + Math.random());

      this._inputElId = element?.id;
    }
  }
}
