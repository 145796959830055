import { A11yModule } from '@angular/cdk/a11y';
import { CdkListboxModule } from '@angular/cdk/listbox';
import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { CardModule } from '@vsolv/vectors-ui/card';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { NgPipesModule } from 'ngx-pipes';
import { OptionHtmlPipe, SelectComponent, SelectOptionComponent, SelectOptionsListComponent } from './components';

export * from './components';

@NgModule({
  declarations: [SelectComponent, SelectOptionComponent, SelectOptionsListComponent, OptionHtmlPipe],
  exports: [SelectComponent, SelectOptionComponent, SelectOptionsListComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ThemingModule,
    IconsModule,
    NgPipesModule,
    UtilsModule,
    CardModule,
    OverlayModule,
    CdkListboxModule,
    CdkMenuModule,
    A11yModule,
  ],
})
export class SelectModule {}
