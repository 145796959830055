import { Component, HostBinding, HostListener, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Subject, takeUntil } from 'rxjs';

export interface UserAvatarConfig {
  photoUrl?: string | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routerLink?: string | any[] | null;
}

@Component({
  selector: 'vs-user-avatar, button[vs-user-avatar]',
  templateUrl: './user-avatar.component.html',
})
export class UserAvatarComponent implements OnDestroy {
  private _baseStyles = 'block group rounded-full overflow-hidden';
  @HostBinding('class') styles = this._baseStyles;

  @PropertyListener('config') config$ = new Subject<UserAvatarConfig>();
  @Input() config?: UserAvatarConfig;

  private destroy$ = new Subject<void>();

  constructor(private router: Router, private route: ActivatedRoute) {
    this.config$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      // this.tabindex = this.config?.routerLink ? 0 : -1; // Make focusable if routing
      this.styles =
        this._baseStyles +
        (config.routerLink
          ? ' cursor-pointer ring-themed-100 active:ring-4 border border-transparent hover:border-themed-300 focus:outline-none focus:ring-4 focus:border-themed-300'
          : '');
    });
  }

  @HostListener('click')
  routeOnClick() {
    if (this.config?.routerLink)
      this.router.navigate(
        typeof this.config?.routerLink === 'string' ? [this.config?.routerLink] : this.config?.routerLink,
        { relativeTo: this.route }
      );
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
