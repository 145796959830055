import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FundingSource } from '@vsolv/dwolla/domain';
import { ManageBankingDetailsDialogState } from '../../dialog';

@Component({
  selector: 'vs-manage-bank-details',
  templateUrl: './manage-bank-details.component.html',
})
export class ManageBankDetailsComponent {
  @Output() closeDialog = new EventEmitter<void>();
  @Output() changeState = new EventEmitter<ManageBankingDetailsDialogState>();
  @Output() bankDetails = new EventEmitter<{ fundingSourceId: string }>();

  @Input() fundingSources?: FundingSource[];

  @Input() addDirective = true;

  maxNumberOfFundingSources = 6; // Number of funding sources allowed for each customer in dwolla

  constructor(private formBuilder: FormBuilder) {}

  existingAccount = this.formBuilder.group({
    fundingSourceRadio: ['', Validators.required],
  });

  close() {
    this.closeDialog.emit();
  }

  changeDialogState(state: ManageBankingDetailsDialogState) {
    this.changeState.emit(state);
  }

  confirmBankDetails() {
    if (!this.existingAccount.value.fundingSourceRadio) return;
    this.bankDetails.emit({ fundingSourceId: this.existingAccount.value.fundingSourceRadio });
    this.close();
  }
}
