import { HttpClient } from '@angular/common/http';
import { Component, forwardRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { BehaviorSubject, catchError, firstValueFrom, map, of, takeUntil } from 'rxjs';
import { StaticPropertyFormComponent } from '../static-property-form.component';

export interface VehicleResponse {
  Results: [
    {
      Make: string;
      MakeID: string;
      Model: string;
      ModelID: string;
      Manufacturer: string;
      ManufacturerId: string;
      ModelYear: string;
      VehicleType: string;
      FuelTypePrimary: string;
      FuelInjectionType: string;
      EngineConfiguration: string;
      EngineCylinders: string;
      EngineManufacturer: string;
      EngineModel: string;
      BodyClass: string;
      Doors: string;
      Windows: string;
      OtherEngineInfo: string;
      Trim: string;
      Trim2: string;
      Series: string;
      Series2: string;
    }
  ];
}

@Component({
  selector: 'vs-vehicle-property-input',
  templateUrl: './vehicle-property-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => VehiclePropertyInputComponent),
    },
    { provide: NG_VALIDATORS, useExisting: VehiclePropertyInputComponent, multi: true },
  ],
})
export class VehiclePropertyInputComponent extends StaticPropertyFormComponent implements OnInit {
  constructor(private fb: FormBuilder, private http: HttpClient, private toastSvc: ToastService) {
    super();
  }
  vehicleCheckbox = new FormControl(false);

  overlayOpen = false;
  expanded = false;
  detailsExpanded = false;

  autofillSearch$ = new BehaviorSubject<string>('');

  form = this.fb.group({
    roadVehicleVIN: [{ value: null as string | null, disabled: false }, Validators.required],
    roadVehicleMake: [{ value: null as string | null, disabled: false }, Validators.required],
    roadVehicleModel: [{ value: null as string | null, disabled: false }, Validators.required],
    roadVehicleYear: [
      { value: null as number | null, disabled: false },
      [Validators.required, Validators.pattern('^[0-9]*$'), Validators.minLength(4), Validators.maxLength(4)],
    ],
  });

  vehicle$ = new BehaviorSubject<VehicleResponse['Results'][number] | null>(null);

  formValid$ = this.form.statusChanges.pipe(map(status => status === 'VALID'));

  async useVIN(vin: string) {
    // use api to get vehicle details
    const vehicle = await firstValueFrom(
      this.http.get<VehicleResponse>(`https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinValues/${vin}?format=json`).pipe(
        catchError(err => {
          console.error(err);
          return of(null);
        })
      )
    );

    if (!vehicle) {
      this.toastSvc.show({
        title: 'Vehicle not found',
        text: 'Could not find vehicle. Please check your VIN and try again.',
        type: 'warning',
      });
      return;
    }

    const results = vehicle.Results[0];
    this.vehicle$.next(results);

    if (!results) {
      this.toastSvc.show({
        title: 'Vehicle not found',
        text: 'Could not find vehicle with vin + ' + vin,
        type: 'warning',
      });
    }
    if (!results.Make) {
      this.toastSvc.show({
        title: 'Could not find Vehicle Make',
        text: 'Could not find vehicle make with vin ' + vin,
        type: 'warning',
      });
    }
    if (!results.Model) {
      this.toastSvc.show({
        title: 'Could not find Vehicle Model',
        text: 'Could not find vehicle model with vin ' + vin,
        type: 'warning',
      });
    }
    if (!results.ModelYear) {
      this.toastSvc.show({
        title: 'Could not find Vehicle Year',
        text: 'Could not find vehicle year with vin ' + vin,
        type: 'warning',
      });
    }

    this.form.patchValue({
      roadVehicleVIN: vin,
      roadVehicleMake: vehicle.Results[0].Make,
      roadVehicleModel: vehicle.Results[0].Model,
      roadVehicleYear: parseInt(vehicle.Results[0].ModelYear) || new Date().getFullYear(),
    });
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.expanded = !this.expanded;
  }

  override ngOnInit(): void {
    super.ngOnInit();

    const customerVehicle =
      this.extraData && this.extraData['vehicle']?.roadVehicleMake ? this.extraData['vehicle'] : undefined;
    if (customerVehicle) {
      this.formControl.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(value => {
        this.vehicleCheckbox.setValue(JSON.stringify(customerVehicle) === JSON.stringify(value));
      });
    }

    this.form.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(_value => {
      this.form.valid ? this.formControl.setValue(this.form.value) : this.formControl.setValue(null);
    });
  }
}
