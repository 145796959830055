/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-input-rename */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';

export interface PaginationConfig {
  pageSizes: number[];

  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
}

@Component({
  selector: 'vs-pagination',
  templateUrl: './pagination.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent {
  constructor(private changeDetector: ChangeDetectorRef) {}

  @HostBinding('class') private get _class() {
    return `flex items-center justify-end gap-4 px-6 py-3 bg-base`;
  }

  @Output() readonly currentPage = new EventEmitter<number>();
  @Output() readonly itemsPerPage = new EventEmitter<number>();
  @Output() readonly pagination = new EventEmitter<{
    currentPage: number;
    previousPage: number;
    itemsPerPage: number;
    previousItemsPerPage: number;
  }>();

  @Input('config') set config(value: Partial<PaginationConfig> | undefined | null) {
    const pageSizes = value?.pageSizes?.length ? value.pageSizes : [10, 20, 50];
    const itemsPerPage =
      value?.itemsPerPage && pageSizes.includes(value.itemsPerPage) ? value.itemsPerPage : pageSizes[0];

    this._config = {
      pageSizes,

      currentPage: value?.currentPage ?? 1,
      itemsPerPage,
      totalItems: value?.totalItems ?? 0,
    };
  }

  _config: PaginationConfig = {
    pageSizes: [10, 20, 50],

    currentPage: 1,
    itemsPerPage: 10,
    totalItems: 0,
  };

  get _totalPages() {
    return Math.ceil(this._config.totalItems / this._config.itemsPerPage);
  }

  get currentValue() {
    return { currentPage: this._config.currentPage, itemsPerPage: this._config.itemsPerPage };
  }

  _setItemsPerPage(itemsPerPage: number) {
    if (this._config.pageSizes.includes(itemsPerPage)) {
      const previousItemsPerPage = this._config.itemsPerPage;
      this._config.itemsPerPage = itemsPerPage;

      const previousPage = this._config.currentPage;
      this._config.currentPage = 1;

      this.currentPage.emit(1);
      this.itemsPerPage.emit(itemsPerPage);

      this.pagination.emit({
        itemsPerPage,
        previousItemsPerPage,

        previousPage,
        currentPage: this._config.currentPage,
      });

      this.changeDetector.detectChanges();
    }
  }

  _setCurrentPage(currentPage: number) {
    if (currentPage > 0 && currentPage <= this._totalPages) {
      const previousPage = this._config.currentPage;
      this._config.currentPage = currentPage;

      this.currentPage.emit(currentPage);
      this.pagination.emit({
        currentPage,
        previousPage,
        itemsPerPage: this._config.itemsPerPage,
        previousItemsPerPage: this._config.itemsPerPage,
      });

      this.changeDetector.detectChanges();
    }
  }
}
