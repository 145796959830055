import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomThemeDirective, ThemeDirective } from './directives';

export * from './directives';

@NgModule({
  declarations: [ThemeDirective],
  providers: [],
  imports: [CommonModule, CustomThemeDirective],
  exports: [ThemeDirective, CustomThemeDirective],
})
export class ThemingModule {}
