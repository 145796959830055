<vs-app-page
  *vsLet="filters$ | async as filters"
  [config]="{
    title: 'Warranties',
    icon: 'folder-shield',
    showDivider: true,
    description: 'This is an overview of all warranties filed across your organization.'
  }"
>
  <vs-breadcrumbs slot="breadcrumbs" [config]="{ items: [{ label: 'Warranties' }] }"></vs-breadcrumbs>

  <vs-badge *ngIf="totalWarranties$ | async as totalWarranties" slot="title-end">
    {{ totalWarranties }} {{ totalWarranties === 1 ? 'Warranty' : 'Warranties' }}
  </vs-badge>

  <!-- Buttons -->
  <div *ngIf="warrantyCount > 0" class="flex w-full sm:max-w-min gap-4" slot="header-end">
    <button
      *ngIf="canSellWarranty$ | async"
      vs-button
      size="md"
      theme="primary"
      class="flex-grow"
      (click)="openNewWarranty()"
    >
      <vs-icon name="folder-plus"></vs-icon>
      New Warranty
    </button>
  </div>

  <form [formGroup]="form">
    <!-- Filters -->
    <div class="flex gap-2 flex-wrap">
      <ws-customer-picker
        formControlName="selectedCustomer"
        class="w-[212px] lg:flex-grow-0 flex-grow"
      ></ws-customer-picker>

      <ws-policy-filter-picker
        formControlName="policyIds"
        class="w-[212px] lg:flex-grow-0 flex-grow"
      ></ws-policy-filter-picker>

      <ws-warranty-status-picker
        formControlName="status"
        class="w-[212px] lg:flex-grow-0 flex-grow"
      ></ws-warranty-status-picker>

      <vs-input-container class="w-[212px] ml-auto lg:flex-grow-0 flex-grow">
        <vs-icon name="search-lg" slot="start"></vs-icon>
        <input type="text" placeholder="Warranty ID..." formControlName="warrantyId" />
      </vs-input-container>
    </div>
  </form>

  <ng-container *vsLet="paginationConfig$ | async as paginationConfig">
    <!-- Table -->
    <ng-container *ngIf="warrantyCount > 0; else noWarranties">
      <div class="rounded-xl overflow-hidden border border-gray-200 shadow-sm mt-4">
        <vs-table
          *vsLet="warranties$ | async as warranties"
          [columns]="columns"
          [data]="warranties"
          (rowClicked)="rowClicked($event)"
        ></vs-table>

        <vs-pagination [config]="paginationConfig" (pagination)="pagination$.next($event)"> </vs-pagination>
      </div>
    </ng-container>

    <ng-template #noWarranties>
      <vs-card *ngIf="!warrantyCount" class="flex flex-col justify-center gap-2 p-6 mt-4">
        <vs-empty-state
          [config]="{
            title:
              filters?.customerId || filters?.referenceId
                ? 'No warranties found with that search query.'
                : filters?.status?.length
                ? 'No ' + (filters?.status?.join(', ') | lowercase) + ' warranties found.'
                : filters?.policyIds
                ? 'No warranties found under the selected policies.'
                : 'No warranties found.',
            subtitle:
              filters?.customerId || filters?.referenceId
                ? 'Adjust the filters to view warranties for your organization.'
                : filters?.status?.length
                ? 'Once a warranty has been ' +
                  (filters?.status?.includes(activated) ? 'purchased' : 'set to one of the selected statuses') +
                  ', its details will appear here.'
                : filters?.policyIds
                ? 'Once a warranty has been purchased under selected policies their details will appear here.'
                : 'Once a warranty has been purchased, its details will appear here.',

            imgsrc: 'empty-clipboard'
          }"
        ></vs-empty-state>
        <button
          *ngIf="canSellWarranty$ | async"
          size="md"
          vs-button
          class="self-center"
          theme="primary"
          (click)="openNewWarranty()"
        >
          <vs-icon name="folder-plus"></vs-icon>
          New Warranty
        </button>
      </vs-card>
    </ng-template>
  </ng-container>
</vs-app-page>
