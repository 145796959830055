import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Component({
  selector: 'ws-checkout-customer-exists-sign-in-dialog',
  templateUrl: './customer-checkout-sign-in.dialog.html',
})
export class CheckoutCustomerExistsSignInDialog implements OnInit {
  constructor(private route: ActivatedRoute, private firebaseSvc: FirebaseService) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  email = '';
  confirmed = false;
  canSendTimeOut = 0;

  queryParams!: ParamMap;
  @PropertyListener('queryParams') queryParams$ = new BehaviorSubject<ParamMap | undefined>(this.queryParams);

  async ngOnInit() {
    this.queryParams = await firstValueFrom(this.route.queryParamMap);
  }

  open(email: string) {
    this.confirmed = false;
    this.email = email;
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  async confirm() {
    if (this.canSendTimeOut <= 0) {
      if (!this.email) return;
      this.confirmed = true;
      this.canSendTimeOut = 60;
      const sendTimeout = setInterval(() => {
        this.canSendTimeOut--;
        if (this.canSendTimeOut <= 0) {
          this.canSendTimeOut = 0;
          clearInterval(sendTimeout);
        }
      }, 1000);
      //send link
      await this.sendSignInLink();
    }
  }

  private async sendSignInLink() {
    let redirect = 'checkout';
    const keys = this.queryParams.keys;

    for (let index = 0; index < keys.length; index++) {
      if (index === 0) redirect += '?';

      const key = keys[index];

      const params = this.queryParams.getAll(key).join(`&${key}=`);
      redirect += `${key}=${params}`;
      if (index < keys.length - 1) redirect += '&';
    }

    await this.firebaseSvc.generateSignInLink(this.email, redirect);
  }
}
