import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'installment',
})
export class InstallmentPipe implements PipeTransform {
  transform(installments?: number, duration?: number): string {
    if (!duration || !installments) return '';

    const installmentFrequency = Math.floor(duration / installments);

    return `${installmentFrequency} day${installmentFrequency === 1 ? '' : 's'}`;
  }
}
