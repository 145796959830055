import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Dashboard } from '@vsolv/packages/dashboarding/domain';

@Injectable()
export class DashboardResolver implements Resolve<Dashboard.Model | null> {
  dashboard: Dashboard.Model | null = null;

  resolve(): Dashboard.Model | null {
    return this.dashboard;
  }
}
