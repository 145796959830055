import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { ONE_TIME_TOKEN } from '@vsolv/core/users/domain';
import { OneTimeTokenService, UserService } from '@vsolv/core/users/web';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { firstValueFrom } from 'rxjs';
import { SignedInUserResolver } from '../resolvers';

@Injectable()
export class SignedInUserGuard implements CanActivate {
  constructor(
    private router: Router,
    private firebaseSvc: FirebaseService,
    private userSvc: UserService,
    private resolver: SignedInUserResolver,
    private tokenSvc: OneTimeTokenService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.currentUser = null;
    const firebaseUser = await firstValueFrom(this.firebaseSvc.authUser$);
    if (!firebaseUser) {
      const providedToken: string = route.queryParams[ONE_TIME_TOKEN];
      const email: string = route.queryParams['email'];
      if (providedToken && email) {
        const token = await this.tokenSvc.getOne(providedToken);
        if ((token && !token.used) || (token.expired && token.expired.getTime() <= Date.now())) {
          const customToken = await this.firebaseSvc.exchangeToken(email, providedToken);
          if (!customToken) return this.notFound();

          const firebaseUser = await this.firebaseSvc.customTokenSignIn(customToken.signInToken, customToken.tenantId);
          if (firebaseUser) return this.router.createUrlTree(['']);
        }
      }
      return this.notFound();
    }

    const valid = await this.firebaseSvc.validateCurrentUser();
    if (!valid) return this.notFound();

    try {
      const user = await this.userSvc.getSelf();
      if (!user) return this.router.createUrlTree(['../404']);
      this.resolver.currentUser = user;
      return true;
    } catch (err) {
      console.error(err);
      await this.firebaseSvc.signOut();
      return this.notFound();
    }
  }

  private notFound() {
    return this.router.createUrlTree(['../sign-in']);
  }
}
