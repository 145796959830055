import { Directive, Input, OnChanges, ViewContainerRef } from '@angular/core';
import { TableColumn } from '../models';

@Directive({ selector: '[vsTableCell]' })
export class CellDirective implements OnChanges {
  @Input() column?: TableColumn<unknown>;
  @Input() item?: unknown;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnChanges() {
    this.viewContainerRef.clear();

    if (this.column && this.item) {
      const component = this.viewContainerRef.createComponent(this.column.component);

      component.instance._item = this.item;
      component.instance._data = this.column.data;
      component.instance._config = this.column.config;
    }
  }
}
