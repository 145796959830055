<form
  [formGroup]="form"
  #addressForm
  class="grid grid-cols-2 gap-3 justify-center overscroll-contain max-w-lg"
  *vsLet="placeholders$ | async as placeholders"
>
  <!-- Line 1 -->
  <vs-form-field
    class="col-span-2"
    [theme]="form.controls.line1.touched && form.controls.line1.hasError('required') ? 'danger' : 'primary'"
    [config]="{ label: 'Address line 1:' }"
  >
    <input
      #line1
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      vsInput
      type="text"
      formControlName="line1"
      [placeholder]="placeholders?.line1"
      (keyup)="autofillSearch$.next(line1.value); line1.value ? open() : close()"
    />

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="overlayOpen"
      [cdkConnectedOverlayOffsetX]="-line1.offsetLeft"
      (overlayOutsideClick)="$event.target !== line1 ? (overlayOpen = false) : null"
    >
      <vs-menu *ngIf="values$ | async as values" class="w-full my-2">
        <button *ngFor="let value of values" vs-menu-item (click)="requestAutofill(value.place_id)">
          {{ value.description }}
        </button>
      </vs-menu>
    </ng-template>

    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls.line1.touched && form.controls.line1.hasError('required')"
    >
      Address line 1 is required
    </span>
  </vs-form-field>

  <!-- Line 2 -->
  <vs-form-field class="col-span-2" [config]="{ label: 'Address line 2 (optional):' }">
    <input vsInput type="text" formControlName="line2" [placeholder]="placeholders?.line2" />
  </vs-form-field>

  <!-- City -->
  <vs-form-field
    class="col-span-2"
    [theme]="form.controls.city.touched && form.controls.city.hasError('required') ? 'danger' : 'primary'"
    [config]="{ label: 'City' }"
  >
    <input vsInput type="text" formControlName="city" [placeholder]="placeholders?.city" />

    <span class="text-danger" slot="hint" *ngIf="form.controls.city.touched && form.controls.city.hasError('required')">
      City is required
    </span>
  </vs-form-field>

  <!-- Country -->
  <vs-form-field
    class="col-span-2"
    [theme]="
      (form.controls.country.touched && form.controls.country.hasError('required')) ||
      form.controls.country.hasError('pattern')
        ? 'danger'
        : 'primary'
    "
    [config]="{ label: 'Country' }"
  >
    <vs-select [config]="{ placeholder: placeholders?.country }" vsInput type="text" formControlName="country">
      <li vs-select-option *ngFor="let country of COUNTRIES" [value]="country.abbreviation">
        {{ country.name }}
      </li>
    </vs-select>

    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls.country.touched && form.controls.country.hasError('required')"
    >
      Country is required
    </span>
    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls.country.touched && form.controls.country.hasError('pattern')"
    >
      Valid country values are 'US', 'CA'
    </span>
  </vs-form-field>
  <!-- Zip -->
  <vs-form-field
    class="col-span-1"
    [theme]="form.controls.zip.touched && form.controls.zip.hasError('required') ? 'danger' : 'primary'"
    [config]="{ label: 'Zip' }"
  >
    <input vsInput type="text" formControlName="zip" [placeholder]="placeholders?.zip" #zip />

    <span class="text-danger" slot="hint" *ngIf="form.controls.zip.touched && form.controls.zip.hasError('required')">
      Zip is required
    </span>
    <span class="text-danger" slot="hint" *ngIf="form.controls.zip.touched && form.controls.zip.hasError('pattern')">
      Zip has invalid value
    </span>
  </vs-form-field>

  <!-- State -->
  <vs-form-field
    class="col-span-1"
    [theme]="
      (form.controls.country.touched && form.controls.country.hasError('required')) ||
      form.controls.country.hasError('pattern')
        ? 'danger'
        : 'primary'
    "
    [config]="{ label: 'State' }"
  >
    <div *vsLet="states$ | async"></div>
    <vs-select
      [config]="{ placeholder: placeholders?.state }"
      vsInput
      class="rounded-md text-md text-gray-900"
      [style.width.px]="!form.value.state ? addressForm.offsetWidth / 2 - 12 : undefined"
      type="text"
      formControlName="state"
    >
      <li vs-select-option *ngFor="let state of stateValues$ | async" [value]="state.abbreviation">
        {{ state.name }}
      </li>
    </vs-select>
    <span
      class="text-danger"
      slot="hint"
      *ngIf="form.controls.state.touched && form.controls.state.hasError('required')"
    >
      State is required
    </span>
  </vs-form-field>
</form>
