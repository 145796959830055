<ng-container *vsLet="canViewClaims$ | async as canViewClaims">
  <vs-card *ngIf="warranty$ | async as warranty" class="h-full">
    <vs-card-header
      *vsLet="(table.paginationConfig$ | async)?.totalItems as totalClaims"
      [config]="{
        title: 'Recent Claims',
        subtitle: 'Preview the most recently modified claims under this coverage.'
      }"
    >
      <vs-badge slot="after-title"> {{ totalClaims }} Claim{{ totalClaims !== 1 ? 's' : '' }} </vs-badge>

      <button
        *ngIf="(totalClaims || 0 > 0) && (canCreateClaim$ | async) && warranty?.status === 'ACTIVATED'"
        vs-button
        slot="end"
        appearance="outline"
        (click)="openClaim()"
      >
        <vs-icon name="plus" class="mr-1"></vs-icon>
        New Claim
      </button>
    </vs-card-header>

    <div class="p-4 -mt-4">
      <ws-claims-table
        #table
        [hideBorder]="true"
        [hideFilters]="true"
        [warranty]="warranty"
        [customerId]="warranty.customerId"
        [isPastWaitingPeriod]="isPastWaitingPeriod$ | async"
        [period]="period"
        [remainingPeriod]="remainingPeriod"
      ></ws-claims-table>
    </div>
  </vs-card>
</ng-container>

<ws-open-claim-dialog #openClaimDialog></ws-open-claim-dialog>
