/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs';
import { TeamMembersTableComponent } from '../../components';
import { AddTeamMemberDialog } from '../../dialogs';
import { SecurityService } from '../../services';

@Component({
  selector: 'ws-web-team-page',
  templateUrl: './team-members.page.html',
})
export class TeamMembersPage {
  constructor(private route: ActivatedRoute, private securitySvc: SecurityService) {}

  @ViewChild('table') table?: TeamMembersTableComponent;

  @ViewChild(AddTeamMemberDialog) addTeamMemberDialog!: AddTeamMemberDialog;

  globalDistributors$ = this.securitySvc.globalDistributors$;

  readonly canInvite$ = this.globalDistributors$.pipe(
    switchMap(
      async globalDistributors =>
        await this.securitySvc.hasAccess('stf_ManageStaff', globalDistributors?.map(dist => dist.permissionKey) || null)
    )
  );

  openAddDialog() {
    this.addTeamMemberDialog.open();
  }
}
