<ng-container *vsLet="claims$ | async as claims">
  <ng-container *vsLet="(paginationConfig$ | async)?.totalItems as totalItems">
    <div
      *ngIf="!hideFilters && (totalItems || filters?.search || filters?.statuses?.length)"
      class="flex gap-2 justify-between items-center flex-wrap mt-4"
    >
      <div class="flex py-1.5 pl-2 gap-2 max-w-[78%] overflow-x-auto scrollbar-thin">
        <ws-customer-picker
          *ngIf="!isCustomerPortal && !customerId"
          class="w-[212px] flex-grow"
          (valueChanges)="refreshTable({ customer: $event })"
        ></ws-customer-picker>

        <vs-select
          vsInput
          class="w-[212px] flex-grow"
          [config]="{ multiple: true, placeholder: 'All statuses' }"
          (valueChanges)="refreshTable({ statuses: $event })"
        >
          <li *ngFor="let status of statuses" vs-select-option [value]="status">
            <vs-badge
              *vsLet="getBadgeStatusColor($any(status)) as theme"
              [theme]="theme?.color"
              [config]="{ displayStatusIcon: true }"
            >
              {{ status.replace('CANCELLED', 'CANCELED') | titlecase }}
            </vs-badge>
          </li>
        </vs-select>

        <vs-select
          vsInput
          class="w-[212px] flex-grow"
          [config]="{ multiple: true, placeholder: 'Claim item statuses' }"
          (valueChanges)="refreshTable({ itemStatuses: $event })"
        >
          <li *ngFor="let status of itemStatuses" vs-select-option [value]="status">
            <vs-badge
              *vsLet="getClaimItemStatusColor($any(status)) as theme"
              [theme]="theme"
              [config]="{ displayStatusIcon: true }"
            >
              {{ status | titlecase }}
            </vs-badge>
          </li>
        </vs-select>

        <vs-select
          vsInput
          class="w-[212px] flex-grow"
          [config]="{ multiple: true, placeholder: 'Payment statuses' }"
          (valueChanges)="refreshTable({ paymentStatuses: $event })"
        >
          <li *ngFor="let status of paymentStatuses" vs-select-option [value]="status">
            <vs-badge
              *vsLet="getPaymentTheme($any(status)) as theme"
              [theme]="theme"
              [config]="{ displayStatusIcon: true }"
            >
              {{ status | titlecase }}
            </vs-badge>
          </li>
        </vs-select>

        <ws-distributor-filter
          permissionId="clm_ViewClaim"
          placeholder="Select a Distributor"
          (valueChanges)="refreshTable({ distributor: $event })"
          class="w-[212px] flex-grow"
        ></ws-distributor-filter>

        <ng-container *ngIf="lifeCycleSteps$ | async as lifeCycleSteps">
          <vs-select
            class="w-[212px] flex-grow"
            *ngIf="lifeCycleSteps.length"
            vsInput
            [config]="{ multiple: true, placeholder: 'Lifecycle Steps' }"
            (valueChanges)="refreshTable({ claimLifecycleSteps: $event })"
          >
            <li *ngFor="let step of lifeCycleSteps" vs-select-option [value]="step">
              {{ step | titlecase }}
            </li>
          </vs-select>
        </ng-container>
      </div>

      <div class="py-1.5 self-start">
        <vs-input-container class="max-w-[212px] ml-auto">
          <vs-icon name="search-lg" slot="start"></vs-icon>
          <input #searchInput vsInput placeholder="Search" (keyup)="refreshTable({ search: searchInput.value })" />
        </vs-input-container>
      </div>
    </div>

    <div
      *ngIf="totalItems !== 0; else noItems"
      class="rounded-xl overflow-hidden mt-4"
      [class]="!hideBorder ? 'border border-gray-200 shadow-sm' : ''"
    >
      <vs-table
        *ngIf="columns$ | async as columns"
        [columns]="columns"
        [data]="claims?.items"
        (rowClicked)="navigateTo($event.id)"
      ></vs-table>

      <vs-pagination
        class="items-end"
        [config]="paginationConfig$ | async"
        (pagination)="paginationQueryRequest$.next({ page: $event.currentPage, limit: $event.itemsPerPage })"
      ></vs-pagination>
    </div>
  </ng-container>

  <ng-template #noItems>
    <div #placeholder class="empty:hidden">
      <ng-content select="[slot='placeholder']"></ng-content>
    </div>

    <vs-card
      *ngIf="!placeholder.children.length"
      class="flex flex-col justify-center gap-2 p-6 mt-4"
      [class.!border-none]="isCustomerPortal || hideBorder"
      [class.!shadow-none]="isCustomerPortal || hideBorder"
    >
      <vs-empty-state
        class="-mt-4"
        [config]="{
          title: filters?.search
            ? 'No claim items found with that search query.'
            : filters?.statuses?.length
            ? 'No ' + (filters?.statuses?.join(', ') | lowercase) + ' claims found.'
            : filters?.distributorId
            ? 'No claims found under that distributor.'
            : 'No claims found.',
          subtitle: filters?.search
            ? 'Adjust the filters to view claims for your organization.'
            : filters?.statuses?.length
            ? 'Once a claim has been ' +
              (filters?.statuses?.includes(open)
                ? 'added'
                : 'set to ' + (statuses.length === 1 ? statuses[0] : 'one of those statuses')) +
              ', its details will appear here.'
            : filters?.distributorId
            ? 'Once a claim has been created with that distributor its details will appear here.'
            : warranty && warranty.status !== active
            ? 'This warranty is expired an no new claims can be created under it.'
            : 'Once a claim item has been added, its details will appear here.',
          imgsrc: 'empty-clipboard'
        }"
      >
      </vs-empty-state>
      <div
        class="self-center"
        appearance="default"
        theme="primary"
        (click)="isCustomerPortal ? navigateTo('open') : openOpenClaimDialog()"
        [vsTooltip]="
          remainingPeriod && remainingPeriod > 0 && !isPastWaitingPeriod
            ? 'Remaining ' + remainingPeriod + ' ' + period + ' of the waiting period.'
            : ''
        "
        tooltipStyle="light"
      >
        <button
          *ngIf="!isCustomerPortal || warranty?.status === active"
          vs-button
          theme="primary"
          [disabled]="isPastWaitingPeriod === false"
          (click)="isCustomerPortal ? navigateTo('open') : openOpenClaimDialog()"
        >
          <vs-icon name="file-plus-03"></vs-icon> New Claim
        </button>
      </div>
    </vs-card>
  </ng-template>
</ng-container>

<ws-open-claim-dialog #openClaimDialog (closed)="refreshTable()"></ws-open-claim-dialog>
