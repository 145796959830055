/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { combineLatest, map, switchMap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-cure',
  templateUrl: './claim-item-cure.component.html',
})
export class ClaimItemCureComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private securitySvc: SecurityService,
    private claimSvc: ClaimService
  ) {}

  readonly claim$ = this.claimSvc.getClaim();
  readonly item$ = this.route.data.pipe(map(data => data['item'] as Claim.ClaimItem.Model));
  readonly isCustomerPortal$ = this.route.data.pipe(map(data => data['isCustomerPortal'] as boolean));

  open = Claim.Status.OPEN;

  canManageCure$ = combineLatest([this.claim$, this.isCustomerPortal$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageClaimItemCoverage', permissionKey);
    })
  );

  canAdjudicateClaimItem$ = combineLatest([this.claim$, this.isCustomerPortal$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_AdjudicateClaimItem', permissionKey);
    })
  );

  navigateTo(path?: string, item?: Claim.ClaimItem.Model) {
    this.router.navigate([path ? `../${path}` : `../../${item?.id}`], { relativeTo: this.route });
  }
}
