<ng-container *vsLet="canManageAPIKeys$ | async as canManageAPIKeys">
  <div>
    <div id="page-header" class="flex flex-col sm:flex-row gap-4 justify-between items-center mb-8">
      <div class="flex flex-col">
        <div class="flex flex-row content-start gap-1 mb-2">
          <h1 class="text-lg font-bold">API keys</h1>
          <vs-badge class="col-span-2 justify-self-start">{{apiKeys + ' key' + (apiKeys === 1 ? '' : 's')}}</vs-badge>
        </div>
        <p class="text-sm text-gray-700">Authenticate API requests with the keys below.</p>
      </div>
      <button vs-button size="md" theme="primary" [disabled]="!canManageAPIKeys" (click)="openGenerateDialog()">
        Generate new key
      </button>
    </div>

    <!-- API Key Table -->
    <ws-api-keys-table (totalApiKey$)="updateKeyValue($event)" #apiKeysTable> </ws-api-keys-table>

    <!-- Generate API Key Dialog -->
    <ws-generate-api-key-dialog
      #generateApiKeyDialog
      (refresh)="apiKeysTable.refreshTable()"
    ></ws-generate-api-key-dialog>
  </div>
</ng-container>
