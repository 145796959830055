<ng-container *ngIf="policy$ | async as policy">
  <ng-container *ngIf="plan$ | async as plan">
    <ng-template #pricingTableButtons>
      <div theme="default" class="flex -m-1 p-1">
        <button
          *ngIf="canEditPlans && policy?.status === 'DRAFT' && selectedTermId && plan.coverageConfigs[selectedTermId]"
          vs-icon-button
          class="self-center"
          size="md"
          appearance="clear"
          theme="default"
          vsTooltip="Delete this term's coverage configuration"
          tooltipStyle="light"
          (click)="openDiscardTermConfigDialog()"
        >
          <vs-icon name="file-x-03"></vs-icon>
        </button>

        <button
          *ngIf="!editing && canEditPlans"
          vs-button
          appearance="clear"
          class="self-center"
          tooltipStyle="light"
          vsTooltip="Edit this term's coverage configuration"
          (click)="
            policy?.status !== 'DRAFT' || (selectedTermId && plan && plan.coverageConfigs[selectedTermId])
              ? editPricing()
              : openConfigureTermDialog()
          "
        >
          <vs-icon name="edit-01"></vs-icon>
        </button>

        <button
          *ngIf="editing"
          vs-button
          appearance="clear"
          class="self-center"
          tooltipStyle="light"
          vsTooltip="Save this term's coverage configuration"
          [disabled]="saving"
          (click)="saveCheck()"
        >
          <vs-icon name="save-01"></vs-icon>
        </button>

        <button
          vs-icon-button
          class="self-center"
          size="md"
          appearance="clear"
          theme="default"
          *ngIf="canEditPlans"
          vsTooltip="Manage this plan's addons and aggregate limit groups"
          tooltipStyle="light"
          (click)="openManageGroupsDialog()"
        >
          <vs-icon name="tag-03"></vs-icon>
        </button>

        <button
          vs-icon-button
          class="self-center"
          size="md"
          appearance="clear"
          theme="default"
          vsTooltip="Export this plan's coverage configurations"
          tooltipStyle="light"
          (click)="openExportCoverageConfigsDialog()"
        >
          <vs-icon name="download-01"></vs-icon>
        </button>
      </div>
    </ng-template>

    <!-- Terms Pricing Tables -->
    <div *ngIf="isSmall$ | async" class="flex w-full justify-end">
      <ng-container [ngTemplateOutlet]="pricingTableButtons"></ng-container>
    </div>

    <vs-tabs
      *vsLet="selectedTerm$ | async as selectedTerm"
      theme="primary"
      disabledMessage="editing another term's coverage configuration"
      [disabled]="editing"
      (tabChanged)="selectedTermId = policy!.terms[$event].id"
    >
      <div slot="after-tabs" class="border-b border-gray-200">
        <ng-container [ngTemplateOutlet]="pricingTableButtons"></ng-container>
      </div>

      <vs-tab *ngFor="let term of policy?.terms" [config]="{ label: term.title }">
        <ng-container
          *ngIf="plan?.coverageConfigs?.[term.id]?.length; else noConfiguration"
          [ngTemplateOutlet]="pricingTable"
          [ngTemplateOutletContext]="{ coverageConfig: plan?.coverageConfigs?.[term.id] || '', termId: term.id }"
        ></ng-container>

        <ng-template #noConfiguration>
          <vs-icon-empty-state
            name="table"
            [config]="{
              title: 'No pricing table has been configured for this term.',
              subtitle:
                'Please configure the pricing table for this term for it to appear as an option for customers at checkout.'
            }"
          >
            <div
              slot="button"
              tooltipStyle="light"
              [vsTooltip]="!policy.coverages?.length ? 'Configure a coverage first!' : ''"
            >
              <button
                *ngIf="canEditPlans && policy?.status === 'DRAFT'"
                vs-button
                theme="primary"
                appearance="default"
                [disabled]="!policy.coverages?.length"
                (click)="openConfigureTermDialog()"
              >
                <vs-icon name="edit-01"></vs-icon>
                Configure table
              </button>
            </div>
          </vs-icon-empty-state>
        </ng-template>
      </vs-tab>

      <ws-copy-term-config-dialog
        #configureTermConfigDialog
        [policy]="policy"
        [selectedTermId]="selectedTermId"
        [coverageConfigs]="plan?.coverageConfigs ?? null"
        (configure)="configureTerm(selectedTermId ?? '', $event.configs, $event.fees)"
      ></ws-copy-term-config-dialog>

      <vs-dialog #discardTermConfigDialog [config]="{ dialogContainerClass: 'max-w-[400px]' }">
        <vs-dialog-header
          [config]="{
            icon: 'file-x-03',
            iconTheme: 'primary',
            mode: 'simple',
            title: 'Discard pricing table values for ' + (selectedTerm?.title ?? 'selected term') + '?',
            subtitle:
              'Are you sure you want to erase all values and coverage configurations for this term? You can still re-configure this term’s pricing table after erasing its current configuration.'
          }"
        ></vs-dialog-header>

        <div class="flex gap-3 justify-center pt-5 pb-2">
          <button
            vs-button
            theme="default"
            class="!w-full"
            appearance="outline"
            (click)="discardTermConfigDialog.close()"
          >
            Cancel
          </button>

          <button
            vs-button
            theme="danger"
            class="!w-full"
            (click)="editing = true; removeTermConfig(selectedTermId ?? ''); discardTermConfigDialog.close()"
          >
            Discard Table
          </button>
        </div>
      </vs-dialog>
    </vs-tabs>

    <ng-template #pricingTable let-coverageConfig="coverageConfig" let-termId="termId">
      <div class="flex relative">
        <div class="flex flex-col left-0 pt-1">
          <p
            class="text-xs font-medium text-gray-600 min-h-[32px] min-w-[128px] max-w-[144px] h-8 px-4 py-[6px] border-r border-b bg-base-white sticky left-0 z-10"
          >
            Coverage
          </p>

          <div
            *ngFor="let coverageConfig of coverageConfig; index as index"
            class="grid grid-flow-col relative max-w-[144px]"
          >
            <ng-container *vsLet="findCoverage(coverageConfig) as coverage">
              <ws-plan-coverage-text-box
                class="flex-1 border-r border-b overflow-hidden !cursor-default sticky left-0 z-[10]"
                [config]="{ text: coverage?.title, darkBackground: index % 2 === 0 }"
              ></ws-plan-coverage-text-box>
            </ng-container>
          </div>
        </div>

        <div *ngIf="propertySet$ | async as propertySet" class="flex w-full pt-1 overflow-x-auto scrollbar-thin">
          <div class="flex flex-col flex-grow max-w-[256px]">
            <p
              class="text-xs font-medium text-gray-600 min-h-[32px] min-w-[128px] max-w-[256px] h-8 px-4 py-[6px] border-r border-b"
            >
              Included In
            </p>

            <div *ngFor="let coverageConfig of coverageConfig; index as index" class="grid grid-flow-col relative">
              <ng-container *vsLet="findCoverage(coverageConfig) as coverage">
                <ws-plan-coverage-text-box
                  class="flex-1 border-r border-b max-w-[256px]"
                  [plan]="plan"
                  [type]="'string'"
                  [policy]="policy"
                  [editing]="editing"
                  [coverage]="coverage"
                  [disabled]="!canEditPlans"
                  [propertySet]="propertySet"
                  [addonGroups]="plan?.addons || null"
                  [rule]="coverageConfig.requirement"
                  [config]="{ darkBackground: index % 2 === 0 }"
                  (ruleUpdated)="updateConfig(termId, coverageConfig.coverageId, $event, 'requirement')"
                ></ws-plan-coverage-text-box>
              </ng-container>
            </div>
          </div>

          <div class="flex flex-col flex-grow max-w-[256px]">
            <p
              class="text-xs font-medium text-gray-600 min-h-[32px] min-w-[128px] max-w-[256px] h-8 px-4 py-[6px] border-r border-b"
            >
              Deductible
            </p>

            <div *ngFor="let coverageConfig of coverageConfig; index as index" class="grid grid-flow-col relative">
              <ws-plan-coverage-monetary-box
                class="flex-1 border-r border-b max-w-[256px]"
                [plan]="plan"
                [type]="'number'"
                [editing]="editing"
                [disabled]="!canEditPlans"
                [propertySet]="propertySet"
                [rule]="coverageConfig.deductible"
                [config]="{ darkBackground: index % 2 === 0 }"
                (ruleUpdated)="updateConfig(termId, coverageConfig.coverageId, $event, 'deductible')"
              ></ws-plan-coverage-monetary-box>
            </div>
          </div>

          <div class="flex flex-col flex-grow max-w-[256px]">
            <p
              class="text-xs font-medium text-gray-600 min-h-[32px] min-w-[128px] max-w-[256px] h-8 px-4 py-[6px] border-r border-b"
            >
              Liability Limit
            </p>

            <div *ngFor="let coverageConfig of coverageConfig; index as index" class="grid grid-flow-col relative">
              <ws-plan-coverage-monetary-box
                class="flex-1 border-r border-b max-w-[256px]"
                [plan]="plan"
                [type]="'number'"
                [editing]="editing"
                [disabled]="!canEditPlans"
                [propertySet]="propertySet"
                [rule]="coverageConfig.liabilityLimit"
                [config]="{ darkBackground: index % 2 === 0 }"
                (ruleUpdated)="updateConfig(termId, coverageConfig.coverageId, $event, 'liabilityLimit')"
              ></ws-plan-coverage-monetary-box>
            </div>
          </div>

          <div class="flex flex-col flex-grow max-w-[256px]">
            <p
              class="text-xs font-medium text-gray-600 min-h-[32px] min-w-[128px] max-w-[256px] h-8 pl-4 py-[6px] border-r border-b"
            >
              Aggregate Limits
            </p>

            <div *ngFor="let coverageConfig of coverageConfig; index as index" class="grid grid-flow-col relative">
              <ws-plan-coverage-group-box
                class="flex-1 border-r border-b max-w-[256px]"
                [plan]="plan"
                [type]="'object'"
                [editing]="editing"
                [disabled]="!canEditPlans"
                [propertySet]="propertySet"
                [rule]="coverageConfig.liabilityGroups"
                [liabilityGroups]="plan?.liabilityGroups || null"
                [config]="{ darkBackground: index % 2 === 0 }"
                (ruleUpdated)="updateConfig(termId, coverageConfig.coverageId, $event, 'liabilityGroups')"
              ></ws-plan-coverage-group-box>
            </div>
          </div>

          <ng-container *ngIf="canEditFees$ | async as canEditFees">
            <ng-container *vsLet="fees$ | async">
              <div
                *ngFor="let group of feesAndTypes$ | async"
                class="flex flex-col flex-grow max-w-[256px]"
                (focusout)="refreshCosts$.next(null)"
              >
                <div
                  *ngIf="group.fees.length"
                  class="min-h-[32px] min-w-[128px] max-w-[256px]"
                  [class]="editing ? '' : 'h-8 px-4 py-[6px] border-r border-b'"
                >
                  <div *ngIf="!editing || !canEditFees" class="flex gap-1 max-w-[256px]">
                    <p class="text-xs font-medium text-gray-600 truncate">{{ group.fees[0].title }}</p>
                    <vs-icon
                      name="help-circle"
                      tooltipStyle="light"
                      class="text-xs text-gray-400 self-center"
                      [vsTooltip]="{ template: tooltip }"
                    ></vs-icon>
                  </div>

                  <ng-template #tooltip>
                    <div class="flex flex-col gap-2 max-w-[200px]">
                      <p class="text-xs font-normal text-gray-700 px-2 py-1 rounded-md border max-w-max self-center">
                        {{ getFeeName($any(group?.fees?.[0]?.feeType)) | titlecase }}
                      </p>

                      <p class="text-xs font-normal text-gray-500">
                        This is the {{ group?.fees?.[0]?.title }} column. It contributes to the sum of the cost column.
                      </p>
                    </div>
                  </ng-template>

                  <vs-input-container
                    *ngIf="editing && canEditFees"
                    tooltipStyle="light"
                    style="width: calc(100% + 1px)"
                    class="text-xs font-medium text-gray-600 min-w-[129px] max-h-[33px] pl-4 pb-[6px] pt-[3px] -mt-px -ml-px border-gray-200 hover:border-primary-300 focus:border-primary-300 border-t-transparent focus:border-t-primary-300 hover:border-t-primary-300 !rounded-none !ring-0 !shadow-none truncate hover:z-10 focus:z-10"
                    [vsTooltip]="{ template: tooltip }"
                  >
                    <input
                      #input
                      type="text"
                      [value]="group.fees[0].title"
                      (focusout)="
                        input.value !== group.fees[0].title
                          ? updateFeeTitle(group.fees[0].feeType, group.fees[0].title, input.value, termId)
                          : ''
                      "
                    />

                    <button
                      vs-button
                      slot="end"
                      theme="default"
                      appearance="clear"
                      class="max-h-[20px] max-w-[20px] !p-[2px]"
                      (click)="removeFeeDialog.open(); actionFees = group.fees; actionFeeName = group.fees[0].title"
                    >
                      <vs-icon size="16" name="x"></vs-icon>
                    </button>
                  </vs-input-container>
                </div>

                <div *ngFor="let fee of group.fees; index as index" class="grid grid-flow-col relative">
                  <ws-plan-coverage-monetary-box
                    type="number"
                    class="flex-1 border-r border-b max-w-[256px]"
                    [plan]="plan"
                    [rule]="fee.rule"
                    [editing]="editing && !!canEditFees"
                    [disabled]="!canEditPlans"
                    [propertySet]="propertySet"
                    [config]="{ darkBackground: index % 2 === 0 }"
                    (ruleUpdated)="updateFeeRule(fee, $event)"
                  ></ws-plan-coverage-monetary-box>
                </div>
              </div>

              <div *vsLet="costs$ | async as costs" class="flex flex-col flex-grow">
                <div class="relative max-w-[256px]">
                  <ws-new-fee-button *ngIf="editing" (addedFees)="addFees($event, termId)"></ws-new-fee-button>

                  <p
                    class="text-xs font-medium text-gray-600 min-h-[32px] min-w-[128px] max-w-[256px] h-8 px-4 py-[6px] border-r border-b"
                  >
                    Cost
                    <vs-icon
                      name="help-circle"
                      tooltipStyle="light"
                      tooltipMaxWidth="sm"
                      class="text-gray-400 ml-1 -mb-[2px]"
                      [vsTooltip]="{
                        title: 'Plan Cost',
                        subtitle: 'The cost column displays the total of all fee columns created for this plan.'
                      }"
                    ></vs-icon>
                  </p>
                </div>

                <div *ngFor="let coverageConfig of coverageConfig; index as index" class="grid grid-flow-col relative">
                  <ws-plan-coverage-monetary-box
                    *vsLet="findCost(costs, coverageConfig.coverageId) as cost"
                    class="flex-1 border-r border-b max-w-[256px]"
                    [config]="{ text: cost || 0, darkBackground: index % 2 === 0 }"
                  ></ws-plan-coverage-monetary-box>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <div class="flex flex-col flex-grow max-w-[256px]">
            <p
              class="text-xs font-medium text-gray-600 min-h-[32px] min-w-[128px] max-w-[256px] h-8 px-4 py-[6px] border-b"
            >
              Retail Price

              <vs-icon
                name="help-circle"
                tooltipStyle="light"
                tooltipMaxWidth="sm"
                class="text-gray-400 ml-1 -mb-[2px]"
                [vsTooltip]="{
                  title: 'Retail Price',
                  subtitle:
                    'These are the prices that will be displayed to the customer at checkout. <br><br>Pricing values will only take effect for this term if there are configured payment schedules. If no payment schedules have been configured, the price will always be $0 in checkout.'
                }"
              ></vs-icon>
            </p>

            <div *ngFor="let coverageConfig of coverageConfig; index as index" class="grid grid-flow-col relative">
              <ws-plan-coverage-monetary-box
                class="flex-1 border-b max-w-[256px]"
                [plan]="plan"
                [type]="'number'"
                [editing]="editing"
                [disabled]="!canEditPlans"
                [propertySet]="propertySet"
                [rule]="coverageConfig.price"
                [config]="{ darkBackground: index % 2 === 0 }"
                (ruleUpdated)="updateConfig(termId, coverageConfig.coverageId, $event, 'price')"
              ></ws-plan-coverage-monetary-box>
            </div>
          </div>
        </div>
      </div>
    </ng-template>

    <vs-dialog #pricingMissingInfoDialog [config]="{ dialogContainerClass: 'w-[600px]' }">
      <vs-dialog-header
        [config]="{
          mode: 'simple',
          hideCloseButton: false,

          icon: 'save-01',
          iconTheme: 'warn',

          title: 'Continue with missing information?',
          subtitle:
            'Until all information has been entered into the pricing table for this plan, the policy cannot be published and the plan cannot be sold. The following columns are missing values: ' +
            savePricingList +
            'Are you sure you want to continue with missing information?'
        }"
      ></vs-dialog-header>

      <div class="buttons flex gap-4">
        <button
          class="flex-grow"
          vs-button
          size="md"
          appearance="outline"
          [disabled]="saving"
          (click)="pricingMissingInfoDialog.close()"
        >
          Cancel
        </button>

        <button class="flex-grow" vs-button size="md" theme="primary" [disabled]="saving" (click)="save()">
          Save Plan
        </button>
      </div>
    </vs-dialog>

    <vs-dialog #duplicateTitleDialog [config]="{ dialogContainerClass: 'w-[450px]' }">
      <vs-dialog-header
        [config]="{
          mode: 'simple',
          hideCloseButton: false,

          icon: 'alert-triangle',
          iconTheme: 'warn',

          title: 'Duplicate fee columns',
          subtitle:
            'You have two fee columns of the same fee type with the same name, ' +
            actionFeeName +
            '.<br><br>These changes cannot be saved. Please change one of the fee column\'s titles or change its fee type.'
        }"
      ></vs-dialog-header>

      <div class="flex justify-end">
        <button
          vs-button
          size="md"
          theme="danger"
          class="flex-grow"
          (click)="duplicateTitleDialog.close(); actionFeeName = ''"
        >
          Discard Changes
        </button>
      </div>
    </vs-dialog>

    <vs-dialog #removeFeeDialog [config]="{ dialogContainerClass: 'w-[450px]' }">
      <vs-dialog-header
        [config]="{
          mode: 'simple',
          hideCloseButton: false,

          icon: 'x-circle',
          iconTheme: 'warn',

          title: 'Remove fee column?',
          subtitle:
            'Are you sure you want to delete the <strong>' +
            actionFeeName +
            '</strong> column?' +
            (policy?.succeededById === null
              ? ' It will not be accounted for in sales for this plan.'
              : ' It will no longer be accounted for in future sales for this plan.')
        }"
      ></vs-dialog-header>

      <div class="flex gap-2">
        <button
          vs-button
          size="md"
          class="flex-grow"
          appearance="outline"
          [disabled]="saving"
          (click)="removeFeeDialog.close(); actionFees = null"
        >
          Cancel
        </button>

        <button vs-button size="md" theme="danger" class="flex-grow" (click)="removeFeeGroup()">Delete Column</button>
      </div>
    </vs-dialog>

    <ws-create-new-version-dialog #createVersionDialog [plan]="plan" [policy]="policy"></ws-create-new-version-dialog>

    <ws-manage-plan-groups-dialog
      #manageGroupsDialog
      [plan]="plan"
      [propertySet]="propertySet"
      [policy]="policy"
    ></ws-manage-plan-groups-dialog>

    <ws-export-plan-coverage-configs-dialog
      *ngIf="policy && plan"
      #exportCoverageConfigsDialog
      [plan]="plan"
      [policy]="policy"
    >
    </ws-export-plan-coverage-configs-dialog>
  </ng-container>
</ng-container>
