import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaginationQueryRequest } from '@vsolv/dev-kit/nest';
import { Coverage } from '@wsphere/warranties/domain';
import { BehaviorSubject, catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class CoverageService {
  constructor(private http: HttpClient) {}

  private coverage$ = new BehaviorSubject<Coverage.Model | null>(null);

  getCoverageObservable() {
    return this.coverage$.asObservable();
  }

  async refreshCoverage(coverage?: Coverage.Model) {
    if (coverage) {
      const refreshedPolicy = await this.getOne(coverage.id);
      this.coverage$.next(refreshedPolicy);
    } else {
      if (this.coverage$.value) {
        const refreshedCoverage = await this.getOne(this.coverage$.value.id);
        this.coverage$.next(refreshedCoverage);
      }
    }
  }

  clearCoverage() {
    this.coverage$.next(null);
  }

  list(policyId: string, pagination?: PaginationQueryRequest) {
    return firstValueFrom(
      this.http.get<Coverage.ListCoveragesQueryResponse>(`/api/coverages`, {
        params: {
          policyId,
          page: pagination?.page || 1,
          limit: pagination?.limit || 5,
        },
      })
    );
  }

  async export(policyId: string, limit?: number) {
    return firstValueFrom(
      this.http
        .get<Coverage.ExportCoveragesQueryResponse>('/api/coverages/export', {
          params: { policyId, limit: limit || 50 },
        })
        .pipe(
          catchError(err => {
            console.log(err);
            throw err;
          })
        )
    );
  }

  getOne(coverageId: string) {
    return firstValueFrom(
      this.http.get<Coverage.Model>(`/api/coverages/${coverageId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async create(body: Coverage.CreateCoverageRequest) {
    return await firstValueFrom(
      this.http.post<Coverage.CreateCoverageResponse>(`/api/coverages`, { ...body, canCreateNewVersion: true }).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async createMany(body: Coverage.CreateCoveragesRequest) {
    return await firstValueFrom(
      this.http.post<Coverage.CreateCoveragesResponse>(`/api/coverages/create-many`, body).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async update(coverageId: string, body: Coverage.UpdateCoverageRequest) {
    return await firstValueFrom(
      this.http.patch<Coverage.UpdateCoverageResponse>(`/api/coverages/${coverageId}`, body).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async replaceExisting(policyId: string, body: Coverage.ReplaceExistingRequest) {
    return await firstValueFrom(
      this.http.post<Coverage.ReplaceExistingResponse>(`/api/coverages/${policyId}`, body).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  async delete(coverageId: string) {
    return firstValueFrom(
      this.http
        .delete<Coverage.DeleteCoverageResponse>(`/api/coverages/${coverageId}`, {
          body: { canCreateNewVersion: true },
        })
        .pipe(
          catchError(err => {
            if (err.status === 404) return of(null);
            throw err;
          })
        )
    );
  }

  listStats(coverageId: string) {
    return firstValueFrom(
      this.http.get<Coverage.RetrieveCoverageStatsQueryResponse>(`/api/coverages/${coverageId}/stats`)
    );
  }
}
