<vs-app-page
  *vsLet="distributor$ | async as distributor"
  class="h-fit pb-4"
  [config]="{ 
    title: distributor?.name || 'Distributor', 
    description: 'Review and manage the details, associated members, and SFTP credentials for this distributor.', 
    icon: 'package'
  }"
>
  <vs-breadcrumbs *ngIf="!hideBreadcrumbs" slot="breadcrumbs">
    <vs-breadcrumbs-item [config]="{ label: 'Distributors', routerLink: '..' }"></vs-breadcrumbs-item>
    <vs-breadcrumbs-item
      *ngIf="distributor?.parent"
      [config]="{ label: distributor?.parent?.name}"
      [routerLink]="['../', distributor?.parent?.id]"
    ></vs-breadcrumbs-item>
    <vs-breadcrumbs-item [config]="{ label: distributor?.name}"></vs-breadcrumbs-item>
  </vs-breadcrumbs>

  <vs-badge
    slot="title-end"
    [theme]="distributor?.status === activated ? 'success' : distributor?.status === deactivated ? 'info' : 'default'"
  >
    {{ distributor?.status | titlecase }}
  </vs-badge>

  <div
    *ngIf="distributor?.status !== archived"
    slot="header-end"
    class="flex gap-2 justify-between lg:max-w-min w-full"
  >
    <ng-container *vsLet="checkoutLink$ | async as checkoutLink">
      <div class="flex flex-row">
        <vs-input-group *ngIf="(canUpdateStatus$ | async) && (canEditDistributor$ | async); else noEdit " class="ml-2">
          <!-- Add permissions check -->
          <button
            vs-button
            appearance="outline"
            class="flex text-base !text-gray-700"
            (click)="distributor?.status === activated ? deactivateDistributorDialog.openDialog() : reactivateDistributorDialog.openDialog()"
          >
            {{ distributor?.status === activated ? "Deactivate" : "Reactivate" }}
          </button>

          <button
            vs-button
            appearance="outline"
            class="flex text-base !text-gray-700"
            (click)="archiveDistributorDialog.openDialog()"
          >
            Archive
          </button>
          <button
            *ngIf="distributor?.status === activated"
            vs-icon-button
            appearance="outline"
            [vsMenuTriggerFor]="menu"
            class="gap-2 flex-row"
          >
            <vs-icon name="chevron-down"></vs-icon>
          </button>
        </vs-input-group>
        <ng-template #noEdit>
          <button
            *ngIf="distributor?.status === activated"
            vs-button
            appearance="outline"
            [vsMenuTriggerFor]="menu"
            class="gap-2 flex-row"
          >
            Links
            <vs-icon name="chevron-down"></vs-icon>
          </button>
        </ng-template>
      </div>

      <ng-template #menu>
        <vs-menu class="mt-1" *ngIf="canViewDistributor$ | async">
          <button vs-menu-item class="!p-0 [&>div]:!rounded-none" (triggered)="createLinkDialog.openDialog()">
            <vs-icon name="link-external-01" slot="start"></vs-icon>
            <span>Onboarding Links</span>
          </button>

          <button
            vs-menu-item
            class="!p-0 [&>div]:!rounded-none"
            [disabled]="!checkoutLink"
            (triggered)="copyCheckoukLink(checkoutLink)"
          >
            <vs-icon name="copy-01" slot="start"></vs-icon>
            <span>Copy Checkout Link</span>
          </button>
        </vs-menu>
      </ng-template>
    </ng-container>
  </div>

  <vs-tabs #tabs theme="primary">
    <vs-tab [config]="{ label: 'Overview' }">
      <ws-distributor-overview-page
        #overview
        theme="default"
        [distributor]="distributor ?? undefined"
        [onSettings]="hideBreadcrumbs"
        (distributorChanged)="refreshDistributor($event)"
      ></ws-distributor-overview-page>
    </vs-tab>

    <vs-tab *ngIf="(canViewStaff$ | async) === true" [config]="{ label: 'Team' }">
      <ws-distributor-team-page theme="default" [distributor]="distributor ?? undefined"></ws-distributor-team-page>
    </vs-tab>

    <vs-tab *ngIf="distributor?.status !== archived && (sftpEnabled$ | async) === true" [config]="{ label: 'SFTP' }">
      <ws-distributor-sftp-page
        theme="default"
        [distributor]="distributor"
        (goToDistEmail)="tabs.switchTo(0); overview.openEditDialog()"
      ></ws-distributor-sftp-page>
    </vs-tab>
  </vs-tabs>

  <ws-archive-distributor-dialog
    #archiveDistributorDialog
    [distributor]="distributor"
    (closed)="refreshDistributor($event)"
  >
  </ws-archive-distributor-dialog>

  <ws-deactivate-distributor-dialog
    #deactivateDistributorDialog
    [distributor]="distributor"
    (closed)="refreshDistributor($event)"
  ></ws-deactivate-distributor-dialog>

  <ws-reactivate-distributor-dialog
    #reactivateDistributorDialog
    [distributor]="distributor"
    (closed)="refreshDistributor($event)"
  ></ws-reactivate-distributor-dialog>

  <ws-create-distributor-onboarding-link-dialog
    #createLinkDialog
    [distributor]="distributor"
    [selectedPage]="manageLinks"
    [showRelevantLinks]="true"
  >
  </ws-create-distributor-onboarding-link-dialog>
</vs-app-page>
