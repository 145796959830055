import { Model } from './model';

export enum EmailType {
  warrantyProvisioned = 'Warranty Provisioned',
  claimApproved = 'Claim Approved',
}

export interface Email {
  readonly id: string;
  enabled: boolean;

  readonly created: Date;
  readonly modified: Date;

  policyId: string;
  policy?: Model;

  type: EmailType;

  subject: string | null;
  content: string | null;
  byline: string | null;

  instructionImage: string | null;
}
