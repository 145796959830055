import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { ClaimPaymentResolver } from '../resolver';
import { ClaimService } from '../services';

@Injectable()
export class ClaimPaymentGuard implements CanActivate {
  constructor(private resolver: ClaimPaymentResolver, private claimSvc: ClaimService) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.payment = null;

    const claimId = this.findClaimId(route);
    if (!claimId) return false;

    const paymentId = this.findPaymentId(route);
    if (!paymentId) return false;

    this.resolver.payment = await this.claimSvc.getPayment(claimId, paymentId);
    if (!this.resolver.payment) return false;

    return true;
  }

  private findPaymentId(route: ActivatedRouteSnapshot): string {
    return route.params['paymentId'] || (route.parent ? this.findPaymentId(route.parent) : null);
  }

  private findClaimId(route: ActivatedRouteSnapshot): string {
    return route.params['claimId'] || (route.parent ? this.findClaimId(route.parent) : null);
  }
}
