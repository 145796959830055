import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AddressModule } from '@vsolv/core/address/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import { CustomerDetailsInputComponent, CustomerPickerComponent } from './components';
import { CustomerService } from './services';

@NgModule({
  imports: [
    CommonModule,
    BreadcrumbsModule,
    AppPageModule,
    BadgeModule,
    ReactiveFormsModule,
    IconsModule,
    FormsModule,
    ThemingModule,
    SelectModule,
    InputModule,
    UserAvatarModule,
    UtilsModule,
    OverlayModule,
    CardModule,
    TableModule,
    BreadcrumbsModule,
    AppPageModule,
    IconsModule,
    DividerModule,
    AddressModule,
    TabsModule,
    ButtonModule,
    MenuModule,
  ],
  providers: [CustomerService],
  declarations: [CustomerDetailsInputComponent, CustomerPickerComponent],
  exports: [CustomerDetailsInputComponent, CustomerPickerComponent],
})
export class CustomerWebModule {}
