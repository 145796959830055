<ng-container *vsLet="link$ | async as link">
  <ng-container *ngIf="object">
    <ng-container *ngIf="!displayType || displayType === type">
      <div *ngIf="activity" class="flex gap-3">
        <div class="flex flex-col items-center relative">
          <vs-user-avatar
            *ngIf="activity.triggeredBy?.photoURL; else noPhoto"
            class="w-10 min-h-[40px] max-h-[40px] border-base-white border shadow"
            [config]="{ photoUrl: activity.triggeredBy?.photoURL }"
          ></vs-user-avatar>

          <ng-template #noPhoto>
            <div class="shadow rounded-full">
              <div
                style="min-height: 40px"
                class="flex justify-center min-w-10 max-w-10 w-10 min-h-10 max-h-10 h-10 rounded-full border-base-white border bg-primary-50 shadow"
                [style.color]="getTypeColor(type)"
                [class.text-primary-600]="type === claimType || type === claimItem"
              >
                <p class="font-medium text-primary-600 self-center">{{ getInitials(activity.triggeredBy) }}</p>
              </div>
            </div>
          </ng-template>

          <div *ngIf="!hideLine" class="w-px h-full border-r"></div>

          <div *ngIf="activity.icon" class="absolute -right-1 top-6 h-[18px] rounded-full shadow">
            <vs-icon
              size="11"
              class="text-base-white rounded-full border border-base p-0.5 shadow"
              [style.background]="getTypeColor(type)"
              [class.bg-primary-500]="type === claimType || type === claimItem"
              [name]="$any(activity.icon)"
            ></vs-icon>
          </div>
        </div>

        <div
          *ngIf="link"
          class="flex flex-col gap-3 w-full min-w-[254px] bg-base-white mb-4 rounded-lg border-l-4 px-4 py-3 overflow-hidden transition-all shadow"
          [class.h-28]="open"
          [class.h-11]="!open"
          [style.border-color]="getTypeColor(type)"
          [class.border-primary-500]="type === claimType || type === claimItem"
        >
          <div class="flex gap-4 items-center justify-between cursor-pointer" (click)="open = !open">
            <span class="overflow-hidden" tooltipStyle="light" [vsTooltip]="{ template: activityText }">
              <span *ngTemplateOutlet="activityText"></span>
            </span>

            <ng-template #activityText>
              <p class="text-sm font-semibold text-gray-700 whitespace-nowrap overflow-hidden overflow-ellipsis">
                {{ activity.triggeredBy?.displayName || 'System' }}

                <span *ngIf="!badgeText">{{ activity.text | stripTags }}</span>
                <span *ngIf="leadingText">{{ leadingText }}</span>
                <span
                  *ngIf="badgeColor"
                  class="text-xs bg-themed-50 text-themed-600 px-1.5 py-0.5 rounded-2xl mr-0.5"
                  [theme]="badgeColor"
                >
                  <vs-icon name="circle" size="6" type="solid" class="text-themed-500 mb-px"></vs-icon>
                  {{ badgeText | titlecase }}
                </span>
                <span *ngIf="trailingText">{{ trailingText }}</span>

                <span
                  [style.color]="getTypeColor(type)"
                  [class.text-primary-500]="type === claimType || type === claimItem"
                >
                  {{ type === document ? 'Document' : (type?.replace('I', ' I')?.replace('C', ' C') | titlecase) }}
                </span>

                {{
                  type === note
                    ? '"' + getNoteContent($any(object)?.content) + '"'
                    : type === document
                    ? '"' + $any(object)?.title + '"'
                    : config?.title
                }}
              </p>
            </ng-template>

            <div class="flex gap-2">
              <p class="text-xs font-normal text-gray-400 whitespace-nowrap">
                {{ activity.created | date : 'dd/MM/yy hh:mm a' }}
              </p>

              <vs-icon [name]="open ? 'chevron-up' : 'chevron-down'"></vs-icon>
            </div>
          </div>

          <vs-info-card
            *ngIf="config"
            class="w-fit"
            tooltipStyle="light"
            [config]="config"
            [vsTooltip]="
              link.note_1
                ? { title: link.note_1.title ?? 'Note Content', subtitle: getNoteContent(link.note_1.content) }
                : undefined
            "
          >
            <vs-badge *ngIf="link.claimItem_1" slot="header-end" [theme]="getItemTheme(link.claimItem_1)">
              {{ link?.claimItem_1?.status | titlecase }}
            </vs-badge>
          </vs-info-card>
        </div>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
