<ng-container *vsLet="config$ | async as config">
  <vs-app-scaffold
    *vsLet="isMobile$ | async as isMobile"
    class="max-w-screen max-h-screen fixed left-0 right-0 top-0 bottom-0"
  >
    <vs-toolbar *ngIf="isMobile" slot="top">
      <button
        vs-icon-button
        slot="start"
        appearance="clear"
        class="cursor-pointer h-6"
        (click)="slideOut.open()"
        (mouseenter)="menuBtnHover = true"
        (mouseleave)="menuBtnHover = false"
      >
        <vs-icon *ngIf="isMobile" #icon size="24" [name]="menuBtnHover ? 'chevron-right-double' : 'menu-02'"></vs-icon>
      </button>

      <a
        *ngIf="config?.logo"
        routerLink=""
        routerLinkActive="cursor-default"
        class="block focus:outline-none focus:ring-4 ring-gray-100 focus:rounded group"
        [routerLinkActiveOptions]="{ exact: true }"
        (mousedown)="$event.preventDefault()"
      >
        <img
          class="w-fit max-h-10 max-w-full group-focus:px-2 group-focus:py-[2px]"
          [src]="config?.logo"
          [class.max-h-8]="isMobile$ | async"
        />
      </a>
    </vs-toolbar>

    <vs-slide-out #slideOut>
      <ng-container *ngTemplateOutlet="leftNav"></ng-container>
    </vs-slide-out>

    <ng-container *ngIf="!isMobile" slot="left">
      <ng-container *ngTemplateOutlet="leftNav"></ng-container>
    </ng-container>

    <ng-template #leftNav>
      <vs-sidebar-nav
        class="bg-base h-full"
        [class.border-r]="!isMobile"
        [class.border-gray-200]="!isMobile"
        [config]="{
          logo: (config$ | async)?.logo!,
          logoRouterLink: '',
          items: []
        }"
      >
        <div class="flex flex-col h-full">
          <div class="flex h-full">
            <vs-stepper
              class="self-center -mt-16"
              theme="primary"
              [config]="{ activeStep: distributorLink?.canCreateUser ? page : page - 1, steps: steps}"
            ></vs-stepper>
          </div>

          <div>
            <vs-divider></vs-divider>
            <button class="mt-2" vs-button size="md" appearance="clear" slot="after-items" routerLink="">
              <vs-icon name="log-in-02"></vs-icon>
              Sign In
            </button>
          </div>
        </div>
      </vs-sidebar-nav>
    </ng-template>

    <div class="flex items-center h-full justify-center">
      <div *ngIf="pages.USER_DETAILS === page && distributorLink?.canCreateUser">
        <form
          [formGroup]="userForm"
          (keydown.enter)="$event.preventDefault()"
          class="flex flex-grow items-center px-8 justify-center"
        >
          <div class="flex flex-col">
            <div class="flex gap-6" [class.flex-col]="isMobile">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{ page }}
                <vs-icon name="arrow-right"></vs-icon>
              </div>

              <div class="flex flex-col gap-3 max-w-[600px]">
                <div class="text-3xl font-medium">Let's get you set up on our dashboard.</div>

                <p class="text-xl tex-gray-900">
                  Tell us your name and provide an email so we can send you the login link to the {{config?.brandName}}
                  Administrator Dashboard.
                </p>

                <vs-form-field [config]="{ label: 'Member Name *' }" appearance="responsive">
                  <vs-input-container>
                    <input #nameInput required formControlName="name" type="text" placeholder="Enter member's name" />
                    <vs-icon name="help-circle" slot="end" vsTooltip="Name of the staff member"></vs-icon>
                  </vs-input-container>

                  <p
                    *ngIf="userForm.controls.name.touched && userForm.controls.name.hasError('required')"
                    slot="hint"
                    class="text-danger"
                  >
                    Please enter the member's name.
                  </p>
                </vs-form-field>

                <vs-form-field [config]="{ label: 'Member Email *' }" appearance="responsive">
                  <vs-input-container>
                    <input required formControlName="email" type="email" placeholder="Enter member's email" />
                    <vs-icon name="help-circle" slot="end" vsTooltip="Email address of the staff member"></vs-icon>
                  </vs-input-container>
                  <span
                    class="text-danger"
                    slot="hint"
                    *ngIf="userForm.controls.email.touched && userForm.controls.email.hasError('email')"
                    >Please enter a valid email.</span
                  >

                  <span
                    class="text-danger"
                    slot="hint"
                    *ngIf="userForm.controls.email.touched && userForm.controls.email.hasError('required')"
                    >Please provide an email.</span
                  >
                </vs-form-field>

                <div class="flex gap-2 mt-4">
                  <button
                    vs-button
                    theme="primary"
                    class="w-full sm:w-auto"
                    [disabled]="userForm.invalid"
                    (click)="goTo(2)"
                  >
                    Next
                    <vs-icon name="arrow-right"></vs-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div *ngIf="pages.DISTRIBUTOR_OR_AGENT_DETAILS === page" class="mr-4">
        <div class="flex flex-grow items-center px-8 justify-center">
          <div class="flex flex-col">
            <div class="flex gap-6" [class.flex-col]="isMobile">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{ distributorLink?.canCreateUser ? page : page-1}}
                <vs-icon name="arrow-right"></vs-icon>
              </div>

              <div class="flex flex-col gap-3 max-w-[600px]">
                <p class="text-3xl font-medium">Now, let's set up your distributor.</p>
                <p class="text-xl tex-gray-900">
                  Tell us the name and contact information for your distributor so we can create a file for it on our
                  dashboard.
                </p>

                <form [formGroup]="form">
                  <ws-distributor-details-form
                    [onBoard]="!isAgent ? OnboardType.DISTRIBUTOR: OnboardType.AGENT"
                    formControlName="distributorDetails"
                  ></ws-distributor-details-form>

                  <p class="mt-6 font-semibold text-sm text-gray-600">
                    <span *ngIf="!isAgent; else agentPage"
                      >Is your distributor an independent agent? Enter your distributor as an agent
                    </span>
                    <ng-template #agentPage>
                      <span>Is your distributor a company? Register your distributor as a company </span>
                    </ng-template>
                    <span (click)="toggleDistributor()" class="cursor-pointer text-primary-500">here.</span>
                  </p>
                  <div class="flex gap-2 mt-6">
                    <button
                      vs-button
                      theme="primary"
                      class="w-full sm:w-auto"
                      [disabled]="form.controls['distributorDetails'].invalid"
                      (click)="goTo(3)"
                    >
                      Next
                      <vs-icon name="arrow-right"></vs-icon>
                    </button>

                    <button
                      vs-button
                      theme="default"
                      appearance="outline"
                      class="w-full sm:w-auto"
                      (click)="back(1)"
                      *ngIf="distributorLink?.canCreateUser"
                    >
                      Back
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="pages.OVERVIEW === page">
        <div class="flex flex-grow items-center px-8 justify-center">
          <div class="flex flex-col">
            <div class="flex gap-6" [class.flex-col]="isMobile">
              <div class="flex gap-2 text-3xl text-primary-500 items-center self-start">
                {{ distributorLink?.canCreateUser ? page : page - 1 }}
                <vs-icon name="arrow-right"></vs-icon>
              </div>

              <div class="flex flex-col gap-4 max-w-[600px]">
                <p class="text-3xl font-medium max-w-[600px]">Please make sure we have the correct information.</p>

                <div class="flex mt-4">
                  <span class="label basis-1/2"> Distributor name: </span>
                  <span> {{ form.value.distributorDetails?.name ?? '-' }} </span>
                </div>

                <div class="flex">
                  <span class="label basis-1/2"> Distributor email: </span>
                  <span> {{form.value.distributorDetails?.email ?? '-' }} </span>
                </div>

                <div class="flex">
                  <span class="label basis-1/2"> Distributor phone: </span>
                  <span> {{ form.value.distributorDetails?.phone ?? '-' }} </span>
                </div>

                <ng-container *ngIf="!userForm.invalid">
                  <vs-divider></vs-divider>

                  <div class="flex">
                    <span class="label basis-1/2"> Member name: </span>
                    <span> {{ userForm.value.name ?? '-' }} </span>
                  </div>

                  <div class="flex">
                    <span class="label basis-1/2"> Member email: </span>
                    <span> {{ userForm.value.email ?? '-' }} </span>
                  </div>
                </ng-container>

                <vs-card
                  *ngIf="userForm.value.email && userForm.value.name"
                  class="bg-danger-50/50 border border-warn-300 px-3 py-2"
                >
                  <div class="flex gap-4 items-center warning">
                    <div class="p-3 bg-base border border-warn-200 rounded-lg flex items-center justify-center">
                      <vs-icon size="17" name="alert-circle" theme="warn" class="text-warn-700"></vs-icon>
                    </div>
                    <div class="flex flex-col">
                      <p class="text-md font-semibold text-warn-700">Pending member registration.</p>
                      <p class="self-center text-sm max-w-[524px] text-warn-700">
                        Please check your email to confirm registration. If you already have an account with us, your
                        settings will be updated.
                      </p>
                    </div>
                  </div>
                </vs-card>

                <div class="flex gap-2 mt-4">
                  <button vs-button theme="primary" class="w-full sm:w-auto" (click)="submit()">
                    Submit
                    <vs-icon name="check"></vs-icon>
                  </button>

                  <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="back(2)">Back</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ws-distributor-onboard-complete *ngIf="page === 4"> </ws-distributor-onboard-complete>
    </div>
  </vs-app-scaffold>
</ng-container>
