<div
  class="flex w-full max-w-[256px] h-full min-h-[36px] max-h-[36px] px-4 py-2 relative overflow-hidden"
  [class.bg-gray-50]="config?.darkBackground"
  [class.px-4]="!editing || (editing && $any(rule?.defaultValue)?.length)"
  [class.py-2]="!editing || (editing && $any(rule?.defaultValue)?.length)"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
>
  <div class="flex gap-2 w-full max-w-full">
    <p *ngIf="config?.text" class="text-sm text-gray-900 break-all">{{ config?.text }}</p>

    <div
      *ngIf="rule"
      class="flex gap-2 w-full max-w-full min-h-[40px] overflow-x-auto scrollbar-thin"
      [class.pointer-events-none]="!editing"
    >
      <ng-container *vsLet="getGroup(rule.defaultValue) as groups">
        <vs-badge
          *ngIf="groups?.length"
          class="max-h-[20px]"
          [theme]="groups && groups?.length === 1 && groups[0] === 'nogroup' ? 'default' : 'primary'"
          (click)="openEditConditionDialog()"
        >
          <ng-container *ngFor="let group of groups; index as index">
            <span *vsLet="getGroupName(group) as group">
              {{ group + (index < (groups?.length || 0) - 1 ? ', ' : '') }}
            </span>
          </ng-container>

          <vs-icon
            *ngIf="editing"
            name="x"
            class="flex self-center"
            (click)="removeValue(); $event.stopImmediatePropagation()"
          ></vs-icon>
        </vs-badge>

        <vs-input-container
          *ngIf="editing && !$any(rule.defaultValue)?.length"
          #trigger="cdkOverlayOrigin"
          cdkOverlayOrigin
          style="background: none"
          class="text-sm text-gray-900 border-none h-full w-full !px-4 py-2 rounded-none shadow-none gap-0"
          (click)="overlayOpen = !overlayOpen"
        >
          <input #input type="text" (input)="this.search$.next(input.value)" />

          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="overlayOpen"
            (overlayOutsideClick)="$event.target !== input ? (overlayOpen = false) : null"
          >
            <vs-menu>
              <button *ngFor="let group of filteredGroups$ | async" vs-menu-item (click)="selectValue(group)">
                <vs-badge theme="default" class="max-h-5"> {{ group.title }} </vs-badge>
              </button>

              <button
                vs-menu-item
                class="flex"
                (click)="manageGroupsDialog.openLiabilityGroupDialog(); overlayOpen = false"
              >
                <vs-icon name="plus" size="18" class="-mb-1"></vs-icon> Add Limit Group
              </button>
            </vs-menu>
          </ng-template>
        </vs-input-container>
      </ng-container>

      <ng-container *ngFor="let block of rule.blocks; index as index">
        <vs-badge
          *vsLet="getGroup(block.value) as groups"
          class="max-h-[20px]"
          [theme]="groups && groups?.length === 1 && groups[0] === 'nogroup' ? 'default' : 'info'"
          (click)="openEditConditionDialog()"
        >
          <ng-container *ngFor="let group of groups; index as index">
            <span *vsLet="getGroupName(group) as group">
              {{ group + (index < (groups?.length || 0) - 1 ? ', ' : '') }}
            </span>
          </ng-container>

          <vs-icon
            *ngIf="editing"
            name="x"
            class="flex self-center"
            (click)="removeValue(index); $event.stopImmediatePropagation()"
          ></vs-icon>
        </vs-badge>
      </ng-container>

      <vs-badge *ngIf="!rule?.blocks?.length && !rule.defaultValue && !editing" theme="default" class="max-h-5">
        No value set
      </vs-badge>
    </div>

    <div *ngIf="!rule && !config?.text" class="w-[30%] border"></div>
  </div>

  <button
    *ngIf="editing && hovered && plan && !disabled"
    vs-icon-button
    size="sm"
    appearance="clear"
    class="absolute top-0 right-0 m-1"
    (click)="openEditConditionDialog()"
  >
    <vs-icon name="dataflow-02"></vs-icon>
  </button>
</div>

<ws-edit-plan-condition-dialog
  *ngIf="plan && type && rule"
  #editConditionDialog
  [plan]="plan"
  [type]="type"
  [rule]="rule"
  [propertySet]="propertySet"
  (closed)="updatedRule($event)"
>
</ws-edit-plan-condition-dialog>

<ws-manage-plan-groups-dialog #manageGroupsDialog [plan]="plan" [policy]="policy"></ws-manage-plan-groups-dialog>
