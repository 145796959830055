import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PhoneFormatPipeModule } from './pipes';
import { DurationPipeModule } from './pipes/duration-pipe/duration-pipe.module';

@NgModule({
  imports: [CommonModule, DurationPipeModule, PhoneFormatPipeModule],
  exports: [DurationPipeModule, PhoneFormatPipeModule],
})
export class VectorsUiUtilsModule {}
