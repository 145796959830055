/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Claim } from '@wsphere/warranties/domain';
import { map, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-adjudicate-dialog',
  templateUrl: './claim-item-adjudicate.dialog.html',
})
export class ClaimItemAdjudicateDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private formBuilder: FormBuilder
  ) {}

  readonly claim$ = this.claimSvc.getClaim();

  readonly item$ = this.route.data.pipe(
    map(data => data['item'] as Claim.ClaimItem.Model),
    tap(item => {
      if (item) {
        this.form.patchValue({
          status: item.status === Claim.ClaimItem.Status.RESOLVED ? Claim.ClaimItem.Status.APPROVED : item.status,
          reason: item.adjudicationReason,
        });
      }
    })
  );

  form = this.formBuilder.group({
    status: [Claim.ClaimItem.Status.DRAFT as Claim.ClaimItem.Status, Validators.required],
    reason: ['', Validators.required],
  });

  reviewing = Claim.ClaimItem.Status.DRAFT;
  approved = Claim.ClaimItem.Status.APPROVED;
  rejected = Claim.ClaimItem.Status.REJECTED;

  changeStatus(status: Claim.ClaimItem.Status) {
    this.form.patchValue({ status });
  }

  async save(item: Claim.ClaimItem.Model) {
    const value = this.form.value;

    if (value.status === Claim.ClaimItem.Status.APPROVED) {
      if (!value.reason) return;

      const itemId = await this.claimSvc.approveItem(item.claimId, item.id, value.reason).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.navigateTo();
      });

      if (itemId) {
        this.toastSvc.show({
          type: 'success',
          title: (item.coverage?.title ?? 'Other') + ' Item Approved',
          text: 'The ' + (item.coverage?.title ?? 'Other') + ' item has been successfully adjudicated as approved.',
        });

        this.navigateTo();
      }
    } else if (value.status === Claim.ClaimItem.Status.REJECTED) {
      if (!value.reason) return;

      const itemId = await this.claimSvc.rejectItem(item.claimId, item.id, value.reason).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.navigateTo();
      });

      if (itemId) {
        this.toastSvc.show({
          type: 'success',
          title: (item.coverage?.title ?? 'Other') + ' Item Rejected',
          text: 'The ' + (item.coverage?.title ?? 'Other') + ' item has been successfully adjudicated as rejected.',
        });

        this.navigateTo();
      }
    } else if (value.status === Claim.ClaimItem.Status.DRAFT) {
      const itemId = await this.claimSvc.redraftItem(item.claimId, item.id, value.reason).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.navigateTo();
      });

      if (itemId) {
        this.toastSvc.show({
          type: 'success',
          title: (item.coverage?.title ?? 'Other') + ' Item Redrafted',
          text: 'The ' + (item.coverage?.title ?? 'Other') + ' item has been successfully set as draft.',
        });

        this.navigateTo();
      }
    }

    this.navigateTo();
  }

  navigateTo(path?: string) {
    this.router.navigate([path ?? `../adjudicate`], { relativeTo: this.route });
  }
}
