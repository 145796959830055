/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UserService } from '@vsolv/core/users/web';
import { Config } from '@vsolv/packages/portal-config/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { SlideOutComponent } from '@vsolv/vectors-ui/slide-out';
import { Distributor } from '@wsphere/distributors/domain';
import { Subject, filter, firstValueFrom, map, takeUntil } from 'rxjs';
import { DistributorDetailsInputValue } from '../../components';
import { DistributorService } from '../../services';

export enum OnboardType {
  AGENT = 'Agent',
  DISTRIBUTOR = 'Distributor',
}

enum pages {
  USER_DETAILS = 1,
  DISTRIBUTOR_OR_AGENT_DETAILS = 2,
  OVERVIEW = 3,
}

@Component({
  templateUrl: './onboard-distributor.page.html',
})
export class OnboardDistributorPage implements OnDestroy, OnInit {
  constructor(
    private router: Router,
    private userSvc: UserService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private breakpointObserver: BreakpointObserver,
    private distributorSvc: DistributorService,
    private toastSvc: ToastService
  ) {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e instanceof NavigationStart)
      )
      .subscribe(_ => this.slideOut?.close());
  }

  @ViewChild('slideOut') slideOut?: SlideOutComponent;
  page = 1;
  tabNumber = 0;
  pages = pages;

  menuBtnHover = false;
  OnboardType = OnboardType;
  distributorLink: Distributor.OnboardingLink.Model | null = null;

  steps: any[] = [];

  destroy$ = new Subject<void>();
  isAgent = false;

  readonly config$ = this.route.data.pipe(map(data => data['config'] as Config.PortalConfig));

  userForm = this.formBuilder.group({
    name: [null as string | null, Validators.required],
    email: [null as string | null, [Validators.required, Validators.email]],
  });

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  form = this.formBuilder.group({
    distributorDetails: [null as DistributorDetailsInputValue, [Validators.required]],
  });

  goTo(number: number) {
    this.page = number;
  }

  back(number: number) {
    this.page = number;
  }

  async submit() {
    const { distributorDetails } = this.form.value;
    const { name, email } = this.userForm.value;

    let response;
    if (this.distributorLink && this.form.valid) {
      response = await this.distributorSvc
        .createWithLink(this.distributorLink.id, {
          distributorDetails: {
            name: distributorDetails?.name || '',
            email: distributorDetails?.email?.length ? distributorDetails.email.toLowerCase() : undefined,
            phone: distributorDetails?.phone?.length ? distributorDetails?.phone : undefined,
          },
          ...(this.userForm.valid && { memberDetails: { name: name ?? '', email: email ?? '' } }),
        })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
        });
    }

    if (response) this.page = 4;
  }

  toggleDistributor() {
    this.isAgent = !this.isAgent;
  }

  async ngOnInit() {
    this.distributorLink = await firstValueFrom(this.route.data.pipe(map(data => data['link'])));
    !this.distributorLink?.canCreateUser ? (this.page = 2) : '';

    if (this.distributorLink?.canCreateUser) {
      this.steps.push({ title: 'New member details', subtitle: 'Register the first member for this distributor.' });
    }
    this.steps.push({ title: 'Distributor details', subtitle: "Enter your distributor's details." });
    this.steps.push({ title: 'Review information', subtitle: 'Ensure all information is correctly entered.' });
    this.steps.push({ title: 'Confirmation', subtitle: 'Confirm successful registration.' });

    const user = await this.userSvc.getSelf();
    if (user && this.distributorLink?.canCreateUser) {
      this.userForm.patchValue({ name: user.displayName, email: user.email });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
