import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BadgeColumn,
  IconButtonColumn,
  PaginationConfig,
  ProgressBarColumn,
  TableColumn,
  TextColumn,
} from '@vsolv/vectors/table';
import { SecurityService } from '@wsphere/staff/web';
import { Coverage } from '@wsphere/warranties/domain';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, combineLatest, from, map, switchMap } from 'rxjs';
import { CopyCoverageDialog, DeleteCoverageDialog, EditPolicyType, PolicyDialog } from '../../dialogs';
import { CoverageService, PolicyService } from '../../services';

export type CoverageTableFilters = Coverage.ListCoveragesQueryRequest;

@Component({
  selector: 'ws-coverages-table',
  templateUrl: './coverages-table.component.html',
})
export class CoveragesTableComponent implements OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policySvc: PolicyService,
    private securitySvc: SecurityService,
    private coverageSvc: CoverageService
  ) {}

  @ViewChild('createCoverageDialog') createCoverageDialog?: PolicyDialog;
  @ViewChild('editCoverageDialog') editCoverageDialog?: PolicyDialog;
  @ViewChild('deleteCoverageDialog') deleteCoverageDialog?: DeleteCoverageDialog;
  @ViewChild('copyCoverageDialog') copyCoverageDialog?: CopyCoverageDialog;

  @Input() hideBorder = false;

  coverageType = EditPolicyType.COVERAGE;

  filters: CoverageTableFilters | null = null;

  selectedCoverage: Coverage.Model | null = null;

  private onDestroy$ = new Subject<void>();

  private filters$ = new BehaviorSubject(this.filters);
  private refresh$ = new BehaviorSubject<null>(null);

  readonly policy$ = this.policySvc.getPolicyObservable();

  readonly pagination$ = new BehaviorSubject({ currentPage: 1, itemsPerPage: 10 });
  paginationQueryRequest$ = new BehaviorSubject<{ page?: number; limit?: number }>({ page: 1, limit: 10 });

  canEdit$ = from(this.securitySvc.hasAccess('pol_Edit', null));

  coverages$ = combineLatest([this.policy$, this.paginationQueryRequest$, this.filters$, this.refresh$]).pipe(
    switchMap(async ([policy, pagination]) => {
      if (!policy)
        return {
          items: [],
          meta: {
            itemsPerPage: 0,
            currentPage: 1,
            pageSizes: [10, 20, 50],
            totalItems: 0,
            itemCount: 0,
          },
        };

      return await this.coverageSvc.list(policy.id, { ...pagination });
    })
  );

  paginationConfig$ = this.coverages$.pipe(
    map((coverages: Coverage.ListCoveragesQueryResponse) => {
      const paginationConfig: PaginationConfig = {
        itemsPerPage: coverages?.meta?.itemsPerPage,
        currentPage: coverages?.meta?.currentPage,
        pageSizes: [10, 20, 50],
        totalItems: coverages?.meta?.totalItems || 0,
      };

      return paginationConfig;
    })
  );

  columns$: Observable<TableColumn<unknown>[] | []> = this.canEdit$.pipe(
    map(canEdit => {
      return [
        new TextColumn({ header: '#', fitContent: true }, (item: Coverage.Model) => ({
          text: '' + item.order,
        })),

        new TextColumn({ header: 'Title' }, (item: Coverage.Model) => ({
          text: item.title,
          classes: !item.id ? 'text-md font-semibold' : '',
        })),

        new BadgeColumn({ header: 'Group' }, (item: Coverage.Model) => ({
          text: item?.group ? item.group : '',
          theme: item?.group ? 'primary' : 'default',
        })),

        new TextColumn({ header: 'Last modified' }, (item: Coverage.Model) => ({
          text: item?.modified ? moment(item.modified as Date).format('MMM DD, YYYY') : '',
        })),

        new ProgressBarColumn({ header: 'No. of claims' }, async (item: Coverage.Model) => {
          const response = await this.coverageSvc.listStats(item.id);
          const stats = response.stats;
          const totalStats = stats
            ? stats[0]?.openClaims + stats[0]?.closedClaims + stats[0]?.cancelledClaims + stats[0]?.expiredClaims
            : 0;

          return {
            endText: totalStats ? totalStats + '' : '',
            config: {
              items: [
                { theme: 'info', weight: stats[0]?.openClaims || 0 },
                { theme: 'default', weight: stats[0]?.closedClaims || 0 },
                { theme: 'warn', weight: stats[0]?.cancelledClaims || 0 },
                { theme: 'danger', weight: stats[0]?.expiredClaims || 0 },
              ],
            },
          };
        }),

        ...(canEdit
          ? [
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: Coverage.Model) => ({
                type: 'clear',
                rounded: true,
                icon: 'copy-04',
                click: () => {
                  this.selectedCoverage = item;
                  this.copyCoverageDialog?.open();
                },
                disabled: !item?.id,
              })),
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: Coverage.Model) => ({
                type: 'clear',
                rounded: true,
                icon: 'trash-01',
                disabled: !item?.id,
                click: () => {
                  this.selectedCoverage = item;
                  this.deleteCoverageDialog?.open();
                },
              })),
              new IconButtonColumn({ stickyEnd: true, fitContent: true }, (item: Coverage.Model) => ({
                type: 'clear',
                rounded: true,
                icon: 'edit-01',
                disabled: !item?.id,
                click: () => {
                  this.selectedCoverage = item;
                  this.editCoverageDialog?.open();
                },
              })),
            ]
          : []),
      ];
    })
  );

  editCoverage(event: string) {
    this.navigateTo('../' + event);
  }

  navigateTo(coverageId: string) {
    this.router.navigate([`coverages/${coverageId}`], { relativeTo: this.route });
  }

  // This is here for if filters get implemented
  // async refreshTable(filters?: {
  //   search?: string;
  // }) {
  //   if (filters?.search !== undefined) {
  //     this.filters = { ...this.filters, search: filters.search };
  //   }

  //   this.filters$.next(this.filters);
  // }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
