import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthorizeNetConfig, Payment } from '@vsolv/packages/payments/domain';
import { catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class PaymentService {
  constructor(private http: HttpClient) {}

  list(dto: Payment.ListRequest) {
    return firstValueFrom(
      this.http.get<Payment.ListResponse>('/api/payments', {
        params: { ...dto, excludeIds: dto.excludeIds?.join(',') ?? '', status: dto.status?.join(',') ?? '' },
      })
    );
  }

  customerPaymentList(dto: Payment.ListRequest) {
    return firstValueFrom(
      this.http.get<Payment.ListResponse>('/api/payments/customerPayments', {
        params: { ...dto, excludeIds: dto.excludeIds?.join(',') ?? '', status: dto.status?.join(',') ?? '' },
      })
    );
  }

  async retrieve(paymentId: string) {
    return await firstValueFrom(
      this.http.get<Payment.RetrieveResponse>(`/api/payments/${paymentId}`).pipe(
        catchError(err => {
          if (err.status === 404) return of(null);
          throw err;
        })
      )
    );
  }

  changePaymentMethod(paymentId: string, paymentMethodId: string) {
    return firstValueFrom(
      this.http.post<Payment.ChangePaymentMethodResponse>(`/api/payments/${paymentId}/payment-method`, {
        paymentMethodId,
      })
    );
  }

  retryPayment(paymentId: string, paymentMethodId?: string) {
    return firstValueFrom(
      this.http.post<Payment.RetryPaymentResponse>(`/api/payments/${paymentId}/retry`, {
        paymentMethodId,
      })
    );
  }

  resendPaymentReminder(paymentId: string) {
    return firstValueFrom(
      this.http.post<Payment.ResendPaymentReminderResponse>(`/api/payments/${paymentId}/resend-reminder`, {})
    );
  }

  async createAuthorizeNetConfing(body: AuthorizeNetConfig.Model) {
    const value = await firstValueFrom(
      this.http.post<AuthorizeNetConfig.CreateResponse>(`/api/authorize-net`, { ...body })
    );
    return value;
  }
  deleteAuthorizeNetConfig() {
    return firstValueFrom(this.http.delete<boolean>(`/api/authorize-net`, {}));
  }

  retrieveAuthorizeNetConfig() {
    return firstValueFrom(this.http.get<AuthorizeNetConfig.RetrieveResponse>(`/api/authorize-net`, {}));
  }
}
