import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { MenuComponent, MenuItemComponent } from './components';
import { MenuTriggerForDirective } from './directives';

@NgModule({
  imports: [CommonModule, OverlayModule, ButtonModule, IconsModule, CdkMenuModule],
  declarations: [MenuComponent, MenuItemComponent, MenuTriggerForDirective],
  exports: [MenuComponent, MenuItemComponent, MenuTriggerForDirective],
})
export class MenuModule {}
