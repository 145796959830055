import { Component, Input, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, map } from 'rxjs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-publish-policy-dialog',
  templateUrl: './publish-policy.dialog.html',
})
export class PublishPolicyDialog {
  constructor(private policySvc: PolicyService, private toastSvc: ToastService) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy?: Policy.Model | null;

  saving = false;

  allPlansConfigured$ = this.policy$.pipe(
    map(policy => {
      if (!policy || !policy.plans?.length) return false;

      for (const plan of policy.plans) {
        if (!plan.title) return false;
        if (!plan.coverageConfigs) return false;

        const termConfigs = Object.entries(plan.coverageConfigs);
        if (termConfigs.length === 0) return false;

        let valid = true;
        termConfigs.forEach(termConfig => {
          const coveragesInvalid = termConfig[1]?.some(
            config =>
              !config ||
              !config.requirement ||
              !config.deductible ||
              !config.liabilityLimit ||
              !config.price ||
              !config.liabilityGroups ||
              config.requirement.defaultValue === '' ||
              config.requirement.defaultValue === null ||
              (config.deductible.defaultValue === null && !config.deductible.valuePropertyPath) ||
              (config.liabilityLimit.defaultValue === null && !config.liabilityLimit.valuePropertyPath) ||
              //only validate price config if term has payment schedules
              (policy.terms.find(term => term.id === termConfig[0])?.paymentSchedules?.length &&
                config.price.defaultValue === null &&
                !config.price.valuePropertyPath) ||
              config.liabilityGroups.defaultValue === null
          );
          if (coveragesInvalid) valid = false;
        });

        if (!valid) return false;
      }

      return true;
    })
  );

  allEmailsConfigured$ = this.policy$.pipe(
    map(policy => {
      if (!policy || !policy.emails?.length) return false;

      return !policy.emails.some(email => !email.subject || !email.content);
    })
  );

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  cancel() {
    this.close();
  }

  async publish() {
    this.saving = true;

    if (!this.policy) throw new Error('Unable to publish policy, policy not found');

    if (this.policy.status !== Policy.Status.DRAFT) {
      throw new Error(`Unable to publish policy with status: ${this.policy.status}`);
    }

    const publishedPolicy = await this.policySvc.publish(this.policy.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
      this.saving = false;
    });

    if (publishedPolicy?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Published policy',
        text: '<strong>' + this.policy?.title + '</strong> has been successfully published.',
      });

      this.close();
      this.saving = false;
      this.policySvc.refreshPolicy();
    }
  }
}
