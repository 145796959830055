<ng-container *vsLet="searchedDistributors$ | async as searchedDistributors">
  <button
    vs-button
    #distributorInfo
    #trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    appearance="outline"
    class="!w-full !py-3 !px-2 !h-auto"
    theme="default"
    [disabled]="disabled"
    (click)="toggle()"
  >
    <div #origin class="flex items-center gap-3 text-gray-700 font-semibold w-full">
      <ng-container *ngIf="hasGlobalAccess && (!value?.length || !searchedDistributors?.length); else hasValue">
        <div class="bg-gray-100 border-2 shadow-sm rounded-lg p-2 flex items-center">
          <vs-icon name="package" class="text-2xl"></vs-icon>
        </div>

        <span class="flex-grow text-left">All Distributors</span>
        <vs-icon name="chevron-down" class="transition-all" [class.rotate-180]="overlayOpen"></vs-icon>
      </ng-container>

      <ng-template #hasValue>
        <ng-container *ngIf="value?.length === 1; else multipleSelected">
          <div class="bg-gray-100 border-2 shadow-sm rounded-lg p-2 flex items-center">
            <vs-icon name="package" class="text-2xl"></vs-icon>
          </div>

          <span class="flex flex-col flex-grow text-left">
            <span class="max-w-[159px] truncate">{{ value?.[0]?.name }}</span>
            <span class="text-gray-600 text-sm font-normal max-w-[159px] truncate">{{ value?.[0]?.id }}</span>
          </span>
          <vs-icon [name]="overlayOpen ? 'chevron-up' : 'chevron-down'"></vs-icon>
        </ng-container>

        <ng-template #multipleSelected>
          <ng-container *ngIf="value$ | async">
            <vs-stack-group
              [vsTooltip]="{ template: tooltip }"
              tooltipPosition="bottom"
              tooltipStyle="light"
              [config]="{ hoverPriority: false }"
            >
              <div class="bg-gray-200 border-2 border-base shadow-sm rounded-lg p-2 flex items-center"></div>
              <div class="bg-gray-200 border-2 border-base shadow-sm rounded-lg p-2 flex items-center"></div>
              <div class="bg-gray-100 border-2 border-base shadow-sm rounded-lg p-2 flex items-center">
                <vs-icon name="package" class="text-2xl"></vs-icon>
              </div>
            </vs-stack-group>

            <span class="flex flex-col flex-grow text-left">
              <span>{{ value?.length }} selected</span>
              <span class="text-gray-600 text-sm font-normal max-w-[159px] truncate">
                <ng-container *ngFor="let dist of value">
                  {{ dist.id }}
                </ng-container>
              </span>
            </span>
            <vs-icon [name]="overlayOpen ? 'chevron-up' : 'chevron-down'"></vs-icon>
          </ng-container>
        </ng-template>
      </ng-template>
    </div>
  </button>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayOpen"
    (overlayOutsideClick)="$event.stopImmediatePropagation(); overlayOpen = false"
  >
    <vs-card
      class="my-3 !rounded-md border border-gray-200 !shadow-md w-full min-w-[287px] max-w-[287px] overflow-hidden"
    >
      <ng-container [ngTemplateOutlet]="common"></ng-container>

      <ng-container *ngIf="searchQuery$.value.length > 0; else notSearching">
        <ng-container *ngIf="searchedDistributors?.length; else noDistributors">
          <ng-container *ngFor="let distributor of searchedDistributors">
            <ng-container *vsLet="distributor | isSelected : value ?? [] as isSelected">
              <label
                tooltipStyle="light"
                tooltipPosition="end"
                tooltipMaxWidth="full"
                class="!w-full !justify-start !rounded-none flex p-2 gap-2 items-center cursor-pointer hover:bg-gray-50"
                [for]="distributor.id"
                [class.bg-gray-100]="isSelected"
                [vsTooltip]="{ template: distributorTooltip }"
              >
                <input
                  type="checkbox"
                  vsInput
                  [id]="distributor.id"
                  [checked]="isSelected"
                  (change)="isSelected ? removeDistributor(distributor) : selectDistributor(distributor)"
                />

                <span
                  class="truncate cursor-pointer"
                  [style.max-width.px]="origin.offsetWidth - origin.offsetWidth * 0.3"
                >
                  {{ distributor.name }}
                </span>
              </label>

              <ng-template #distributorTooltip>
                <div class="flex flex-col gap-2 py-2">
                  <div class="flex gap-3 px-2">
                    <div class="bg-gray-100 border-2 shadow-sm rounded-lg p-2 flex items-center">
                      <vs-icon name="package" class="text-2xl"></vs-icon>
                    </div>
                    <span class="flex flex-col justify-center flex-grow text-left text-gray-500">
                      <span>{{ distributor.name }}</span>
                      <span class="text-sm font-normal">{{ distributor.id }}</span>
                    </span>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #noDistributors>
          <div class="px-3 py-2">
            <span class="text-gray-500">No distributors were found with that search</span>
          </div>
        </ng-template>
      </ng-container>

      <ng-template #notSearching>
        <ng-container *ngIf="paginatedDistributorNavigation$ | async as paginatedDistributorNavigation">
          <ng-container
            [ngTemplateOutlet]="menu"
            [ngTemplateOutletContext]="paginatedDistributorNavigation"
          ></ng-container>
        </ng-container>

        <div class="flex w-full justify-center items-center h-9" *ngIf="loading">
          <vs-icon name="loading-02" size="20" class="animate-spin text-primary"></vs-icon>
        </div>
      </ng-template>
    </vs-card>
  </ng-template>

  <ng-template #menu let-parent="parent" let-current="current">
    <ng-container *ngIf="current.children.length">
      <div
        wsScrollTracker
        class="overflow-auto"
        [style.height.px]="36 * current.children.length"
        [style.max-height.px]="36 * 5"
        (scrollEnded)="$event ? loadMore(current, current.distributor?.id) : undefined"
      >
        <ng-container *ngFor="let distributor of current.children">
          <ng-container *vsLet="distributor.distributor! | isSelected : value ?? [] as isSelected">
            <ng-container *ngIf="distributor.children.length; else noChild">
              <div
                tooltipStyle="light"
                tooltipPosition="end"
                tooltipMaxWidth="full"
                class="flex justify-between text-gray-500 w-full cursor-pointer h-9"
                [class.!bg-gray-100]="isSelected"
                [class.text-gray-700]="isSelected"
                [vsTooltip]="{ template: paginatedDistributorTooltip }"
              >
                <label
                  class="flex gap-2 px-2 pty-[6px] items-center flex-grow cursor-pointer hover:bg-gray-50"
                  [for]="distributor.distributor.id"
                >
                  <input
                    type="checkbox"
                    vsInput
                    [checked]="isSelected"
                    [id]="distributor.distributor.id"
                    (change)="isSelected ? remove(distributor) : select(distributor); $event.stopPropagation()"
                  />

                  <span class="truncate" [style.max-width.px]="origin.offsetWidth - origin.offsetWidth * 0.3">
                    {{ distributor.distributor?.name }}
                  </span>
                </label>

                <vs-icon
                  size="20"
                  name="chevron-right"
                  class="self-center p-2 hover:bg-gray-50"
                  (click)="
                    navigateAndSave(distributor.distributor.id, { parent: current, current: distributor });
                    $event.stopImmediatePropagation()
                  "
                ></vs-icon>
              </div>
            </ng-container>

            <ng-template #noChild>
              <label
                tooltipStyle="light"
                tooltipPosition="end"
                tooltipMaxWidth="full"
                class="flex justify-start items-center gap-2 p-2 text-gray-500 w-full cursor-pointer h-9 hover:bg-gray-50"
                [for]="distributor.distributor.id"
                [class.bg-gray-100]="isSelected"
                [vsTooltip]="{ template: paginatedDistributorTooltip }"
                [class.!text-gray-700]="isSelected"
              >
                <input
                  type="checkbox"
                  vsInput
                  [checked]="isSelected"
                  [id]="distributor.distributor.id"
                  (change)="isSelected ? remove(distributor) : select(distributor)"
                />

                <span
                  class="truncate cursor-pointer"
                  [style.max-width.px]="origin.offsetWidth - origin.offsetWidth * 0.3"
                >
                  {{ distributor.distributor?.name }}
                </span>
              </label>
            </ng-template>

            <ng-template #paginatedDistributorTooltip>
              <div class="flex flex-col gap-2 py-2">
                <div class="flex gap-3 px-2">
                  <div class="bg-gray-100 border-2 shadow-sm rounded-lg p-2 flex items-center">
                    <vs-icon name="package" class="text-2xl"></vs-icon>
                  </div>
                  <div class="flex flex-col justify-center flex-grow flex-wrap text-left text-gray-500">
                    <div class="break-words">{{ distributor.distributor.name }}</div>
                    <span class="text-sm font-normal">{{ distributor.distributor.id }}</span>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-template>

  <ng-template #tooltip>
    <div class="flex flex-col gap-2 py-2" [style.width.px]="origin.offsetWidth">
      <ng-container *ngFor="let distributor of value; let last = last">
        <div class="flex gap-3 px-2">
          <div class="bg-gray-100 border-2 shadow-sm rounded-lg p-2 flex items-center">
            <vs-icon name="package" class="text-2xl"></vs-icon>
          </div>
          <span class="flex flex-col flex-grow text-left">
            <span [style.max-width.px]="origin.offsetWidth - origin.offsetWidth * 0.3" class="truncate">
              {{ distributor.name }}
            </span>
            <span
              [style.max-width.px]="origin.offsetWidth - origin.offsetWidth * 0.3"
              class="text-gray-600 text-sm font-normal truncate"
            >
              {{ distributor.id }}
            </span>
          </span>
        </div>
        <vs-divider class="!w-full" *ngIf="!last"></vs-divider>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #common>
    <ng-container *ngIf="paginatedDistributorNavigation$ | async as paginatedDistributorNavigation">
      <ng-container
        *ngIf="paginatedDistributorNavigation.parent"
        [ngTemplateOutlet]="breadcrumb"
        [ngTemplateOutletContext]="paginatedDistributorNavigation"
      ></ng-container>

      <vs-input-container class="rounded-none shadow-none border-x-0 border-t-0 mt-1 focus-within:!ring-0">
        <vs-icon name="search-lg" slot="start"></vs-icon>
        <input
          type="text"
          (input)="searchQuery$.next($any($event.target).value)"
          placeholder="Search Distributors..."
        />
      </vs-input-container>

      <label
        *ngIf="this.hasGlobalAccess && !paginatedDistributorNavigation.parent"
        for="all"
        class="!w-full !justify-start !rounded-none flex p-2 gap-2 items-center border-b text-gray-500 cursor-pointer"
        [class.bg-gray-100]="!value || !value.length"
        [class.!text-gray-700]="!value || !value.length"
        [style.min-width.px]="trigger.elementRef.nativeElement.clientWidth"
        (click)="selectValueChanged(null)"
      >
        <input
          type="checkbox"
          vsInput
          id="all"
          [checked]="!value || !value.length"
          [disabled]="!value || !value.length"
        />

        <span>All Distributors</span>
      </label>
    </ng-container>
  </ng-template>

  <ng-template #breadcrumb let-parent="parent" let-current="current">
    <div class="flex items-center border border-b border-t-0 border-x-0">
      <ng-container *ngIf="parent?.distributor?.name">
        <button
          vs-icon-button
          appearance="clear"
          class="px-2 rounded-tr-none rounded-b-none"
          (click)="navigate('home')"
        >
          <vs-icon name="home-line" class="text-gray-500"></vs-icon>
        </button>

        <button
          vs-icon-button
          appearance="clear"
          class="pt-2 px-2 rounded-none"
          (click)="navigate(parent.distributor.id)"
        >
          <vs-icon name="dots-horizontal"></vs-icon>
        </button>
      </ng-container>

      <button
        vs-button
        appearance="clear"
        (click)="!parent?.distributor?.name ? navigate('home') : navigate(parent.distributor.id)"
        class="!w-full rounded-b-none"
        [class.px-2]="parent?.distributor?.name"
        [class.rounded-tl-none]="parent?.distributor?.name"
      >
        <vs-icon name="chevron-left"></vs-icon>
        <span class="flex-grow text-start text-primary-700">{{ current.distributor.name }}</span>
      </button>
    </div>
  </ng-template>
</ng-container>
