<form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
  <div id="dialog-content" class="pt-6 flex flex-col gap-6">
    <vs-form-field
      *vsLet="distributorName$ | async as distributorName"
      appearance="responsive"
      [config]="{ label: (onBoard === onBoardType.AGENT ? 'Agent' : 'Distributor') + ' name' }"
    >
      <input
        vsInput
        type="text"
        formControlName="name"
        [placeholder]="'Enter ' + (onBoard === onBoardType.AGENT ? 'agent' : 'distributor') + ' name'"
      />

      <p *ngIf="!editing" slot="hint">
        <span *ngIf="form.controls.name.touched && form.get('name')?.hasError('required')" class="text-danger">
          {{ 'Please enter ' + (onBoard === onBoardType.AGENT ? 'an agent' : 'a distributor') + ' name.' }}
        </span>
        <span *ngIf="form.controls.name.touched && form.get('name')?.hasError('maxlength')" class="text-danger">
          Maximum character limit reached.
        </span>
        <span *ngIf="form.value.name && distributorName" class="text-danger">
          A distributor with this name already exists, please make sure you have not already registered this
          distributor.
        </span>
      </p>
    </vs-form-field>

    <vs-form-field
      [config]="{ label: (onBoard === onBoardType.AGENT ? 'Agent' : 'Distributor') + ' email' }"
      appearance="responsive"
    >
      <input
        vsInput
        formControlName="email"
        type="email"
        [placeholder]="'Enter ' + (onBoard === onBoardType.AGENT ? 'agent' : 'distributor') + ' email'"
      />

      <p *ngIf="!editing" slot="hint">
        <span
          *ngIf="form.controls.email.touched && form.controls.email.value && form.controls.email.invalid"
          class="text-danger"
        >
          Please enter a valid email address.
        </span>
      </p>
    </vs-form-field>

    <vs-form-field
      appearance="responsive"
      [config]="{ label: (onBoard === onBoardType.AGENT ? 'Agent' : 'Distributor') + ' phone' }"
    >
      <vs-input-container>
        <input
          type="text"
          formControlName="phone"
          placeholder="(___) ___-____"
          (keyup)="phoneNumberKeyUp($event); validateNumber()"
          (blur)="formatPhoneNumber(); validateNumber()"
        />
      </vs-input-container>

      <p *ngIf="!editing" slot="hint">
        <span
          *ngIf="form.controls.phone.touched && form.controls.phone.value && form.controls.phone.invalid"
          class="text-danger"
        >
          Please enter a valid phone number.
        </span>
      </p>
    </vs-form-field>
  </div>
</form>
