/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Clipboard } from '@angular/cdk/clipboard';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { VsolvConfigService } from '@vsolv/config/web';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { IconButtonColumn, TextColumn } from '@vsolv/vectors/table';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
import { FullAdminWarningDialog, RoleAssignmentsService, SecurityService } from '@wsphere/staff/web';
import moment from 'moment';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { DistributorService } from '../../services';
import { BreakDistributorOnboardingLinkDialog } from '../break-distributor-onboarding-link/break-distributor-onboarding-link.dialog';

export enum SelectedPageType {
  ONBOARDING_LINK = 'ONBOARDING_LINK',
  USER_ROLES = 'USER_ROLES',
  REVIEW_LINK_DETAILS = 'REVIEW_LINK_DETAILS',
  MANAGE_LINKS = 'MANAGE_LINKS',
  VIEW_LINK_DETAILS = 'VIEW_LINK_DETAILS',
}

enum pages {
  ONBOARDING_LINK = 1,
  USER_ROLES = 2,
  REVIEW_LINK_DETAILS = 3,
  MANAGE_LINKS = 4,
  VIEW_LINK_DETAILS = 5,
}

@Component({
  selector: 'ws-create-distributor-onboarding-link-dialog',
  styleUrls: ['create-distributor-onboarding-link.dialog.scss'],
  templateUrl: './create-distributor-onboarding-link.dialog.html',
})
export class CreateDistributorOnboardingLinkDialog {
  constructor(
    private copyCdk: Clipboard,
    private toastSvc: ToastService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private configSvc: VsolvConfigService,
    private distributorSvc: DistributorService,
    private roleAssignmentSvc: RoleAssignmentsService
  ) {}

  @ViewChild('breakDistributorOnboardingLink') breakDistributorOnboardingLink?: BreakDistributorOnboardingLinkDialog;
  @ViewChild(FullAdminWarningDialog) private warningDialog?: FullAdminWarningDialog;
  @Input() dialogConfig?: DialogConfig;

  @Input() showRelevantLinks = false;

  @PropertyListener('distributor') distributor$ = new BehaviorSubject<Distributor.Model | null>(null);
  @Input() distributor?: Distributor.Model | null;

  private _refreshList$ = new BehaviorSubject<string>('');

  _selectedPage: SelectedPageType | null = null;
  @Input() set selectedPage(value: SelectedPageType | null) {
    this._selectedPage = value;

    switch (value) {
      case SelectedPageType.ONBOARDING_LINK:
        this.page = pages.ONBOARDING_LINK;
        break;
      case SelectedPageType.USER_ROLES:
        this.page = pages.USER_ROLES;
        break;
      case SelectedPageType.REVIEW_LINK_DETAILS:
        this.page = pages.REVIEW_LINK_DETAILS;
        break;
      case SelectedPageType.MANAGE_LINKS:
        this.page = pages.MANAGE_LINKS;
        this.page = 4;
        break;
      case SelectedPageType.VIEW_LINK_DETAILS:
        this.page = pages.VIEW_LINK_DETAILS;
        break;
      default:
        this.page = pages.ONBOARDING_LINK;
    }
  }

  get selectedPage() {
    return this._selectedPage;
  }

  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() closed = new EventEmitter<string>();

  refresh$ = new BehaviorSubject(null);

  page = 4;
  pages = pages;
  selectedLinkId = '';
  userSelection = false;

  isOnboardingLinkPresentInTable = false;

  linkDetails = this.formBuilder.group({
    linkExpired: [false as boolean, [Validators.required]],
    days: [0, [Validators.min(0)]],
    canCreateUser: [false as boolean | null, Validators.required],
    distributor: [null as Distributor.Model | null, Validators.required],
  });

  rolesFormGroup = this.formBuilder.group({
    roles: [[] as string[]],
  });

  showExpiredLink = new FormControl(false, [Validators.required]);

  paginationQueryRequest$ = new BehaviorSubject<{ page: number; limit: number }>({ page: 1, limit: 5 });

  roles$: Observable<(Staff.Security.Role | undefined)[]> = this.refresh$.pipe(
    switchMap(() => this.roleAssignmentSvc.getStaffRoles()),
    map(role => role.map(item => Staff.Security.getRole(item.roleId))),
    shareReplay(1)
  );

  readonly canEditDistributor$: Observable<boolean | null> = this.distributor$.pipe(
    switchMap(async distributor =>
      distributor
        ? await this.securitySvc.hasAccess(
            'dist_EditDetails',
            distributor.permissionKey ? [distributor.permissionKey] : null
          )
        : null
    )
  );

  onboardingLinkData$ = combineLatest([
    this.distributor$,
    this.paginationQueryRequest$,
    this.showExpiredLink.valueChanges.pipe(startWith(false)),
    this.securitySvc.globalDistributors$,
    this._refreshList$,
  ]).pipe(
    switchMap(async ([distributor, paginationQueryRequest, showExpiredLinks, globalDistributors]) => {
      const distributorIds = globalDistributors?.map(dist => dist.id) ?? [];

      const links = await this.distributorSvc
        .listOnboardingLinks(distributor?.id || '', showExpiredLinks || false, distributorIds, {
          page: paginationQueryRequest.page || 1,
          limit: paginationQueryRequest.limit || 5,
        })
        .catch(() => {
          return {
            items: [],
            meta: { currentPage: 1, itemCount: 0, itemsPerPage: paginationQueryRequest.limit || 5 },
          } as Distributor.OnboardingLink.ListLinksQueryResponse;
        });

      return { items: links.items, meta: { ...links.meta, pageSizes: [5, 10, 25, 50] } };
    }),
    tap(val => {
      this.isOnboardingLinkPresentInTable = !!val.items;
    })
  );

  canEditLink$ = combineLatest([this.distributor$, this.securitySvc.globalDistributors$]).pipe(
    switchMap(async ([distributor, globalDistributors]) => {
      const globalPermissionKeys = globalDistributors ? globalDistributors?.map(dist => dist.id) : null;

      const permissionKeys = distributor ? [distributor.permissionKey] : globalPermissionKeys;
      return await this.securitySvc.hasAccess('dist_ViewOnboardingLinks', permissionKeys);
    })
  );

  hasGlobalAccess$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(async () => this.securitySvc.hasAccess('dist_CreateOnboardingLink', null))
  );

  canCreateLink$ = combineLatest([this.distributor$, this.hasGlobalAccess$]).pipe(
    switchMap(async ([distributor, hasGlobalAccess]) => {
      const canCreateLink = distributor
        ? await this.securitySvc.hasAccess(
            'dist_CreateOnboardingLink',
            distributor ? [distributor.permissionKey] : null
          )
        : false;
      return canCreateLink || hasGlobalAccess;
    })
  );

  columns$ = this.canEditLink$.pipe(
    map(hasAccess => {
      return [
        new TextColumn<Distributor.OnboardingLink.Model>({ header: 'Link' }, link => ({
          text: moment(link.created).format('MMM D, YYYY'),
          classes: 'whitespace-nowrap',
        })),

        new TextColumn<Distributor.OnboardingLink.Model>({ header: 'Days left', fitContent: true }, link => ({
          text: link.expiry
            ? link.expiry.getTime() >= Date.now()
              ? Math.round((link.expiry.getTime() - Date.now()) / (1000 * 60 * 60 * 24)) + ' days'
              : 'Expired'
            : '-',
          classes: 'whitespace-nowrap',
        })),

        new TextColumn<Distributor.OnboardingLink.Model>({ header: 'Registered', fitContent: true }, (link: any) => ({
          text: link.registeredCount,
        })),

        new TextColumn<Distributor.OnboardingLink.Model>({ header: 'Parent Distributor', fitContent: true }, link => ({
          text: link.distributorId ? link?.distributor?.name || '' : 'No Distributor',
          classes: 'rounded-[8px] px-2 py-1 border border-gray-300 whitespace-nowrap',
        })),

        new IconButtonColumn<Distributor.OnboardingLink.Model>({ stickyEnd: true, fitContent: true }, link => ({
          type: 'clear',
          rounded: true,
          disabled: link.expiry ? (Math.round((link?.expiry?.getTime() || 0) - Date.now()) > 0 ? false : true) : false,
          tooltip: true,
          icon: 'link-01',

          click: async () => {
            try {
              const url = await this.configSvc.get('dashboard_config');
              const linkUrl = `${url.url}/on-boarding/${link.id}`;
              this.copyCdk.copy(linkUrl);
              this.toastSvc.show({
                type: 'success',
                title: 'Link Copied',
                text: 'The onboarding link has been successfully copied to the clipboard.',
              });
            } catch (err) {
              this.toastSvc.show({
                type: 'error',
                title: 'Error! something went wrong.',
                text: 'link is unable to get copied.',
              });
            }
          },
        })),

        ...(hasAccess
          ? [
              new IconButtonColumn<Distributor.OnboardingLink.Model>({ stickyEnd: true, fitContent: true }, link => ({
                type: 'clear',
                rounded: true,
                disabled: link.expiry
                  ? Math.round((link?.expiry?.getTime() || 0) - Date.now()) > 0
                    ? false
                    : true
                  : false,
                icon: 'link-broken-02',

                click: () => {
                  this.selectedLinkId = link.id;
                  this.breakDistributorOnboardingLink?.openDialog();
                },
              })),
            ]
          : []),
      ];
    })
  );

  async openDialog() {
    this.dialog?.open();
    const settings = await this.distributorSvc.getSettings();
    this.userSelection = settings?.canRegisterUsers ?? false;
    if (!this.userSelection) {
      this.linkDetails.patchValue({ canCreateUser: false });
    }
  }

  closeDialog(internalClose?: boolean) {
    this.linkDetails.patchValue({
      linkExpired: null,
      days: 0,
      canCreateUser: null,
      distributor: null,
    });

    this.rolesFormGroup.patchValue({ roles: [] });
    this.showExpiredLink.setValue(false);

    this.distributor?.id ? (this.page = this.pages.MANAGE_LINKS) : (this.page = 4);
    if (!internalClose) this.dialog?.close();
  }

  goTo(number: number) {
    if (number === pages.ONBOARDING_LINK && !this.showRelevantLinks) {
      this.distributor = null;
    }
    if (!this.linkDetails.value.canCreateUser) {
      !this.linkDetails.value.canCreateUser && this.page === pages.REVIEW_LINK_DETAILS
        ? (this.page = pages.ONBOARDING_LINK)
        : this.page === pages.ONBOARDING_LINK
        ? (this.page = pages.REVIEW_LINK_DETAILS)
        : (this.page = number);
    } else {
      this.page = number;
    }

    if (number === 1) {
      this.linkDetails.patchValue({
        linkExpired: null,
        days: 0,
      });
      this.rolesFormGroup.patchValue({ roles: [] });
      this.linkDetails.patchValue({ canCreateUser: false });
    } else if (number === 4) {
      this.showExpiredLink.patchValue(false);
    }
  }

  cancel(number: number) {
    this.page = number;
  }

  async create() {
    const roles = this.rolesFormGroup.value.roles;

    const dto = {
      canCreateUser: this.linkDetails.value.canCreateUser ? true : false,
      ...(this.linkDetails.value.linkExpired && { expiry: this.linkDetails.value.days || 0 }),
      roles: roles || [],
      ...((this.distributor?.id || this.linkDetails.value.distributor?.id) && {
        distributorId: this.distributor?.id || this.linkDetails.value.distributor?.id,
      }),
    };

    const createOnboardingLink = await this.distributorSvc.createOnboardingLink(dto).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something Went Wrong',
        text: error.message,
      });
    });

    if (createOnboardingLink) {
      this.toastSvc.show({
        type: 'success',
        title: 'Onboarding Link created',
        text: ' Onboarding has been successfully created.',
      });
      createOnboardingLink ? (this.page = pages.MANAGE_LINKS) : '';
      if (!this.showRelevantLinks) this.distributor = null;
      this._refreshList$.next('');
    }
    if (this.distributor?.id) {
      this.page = pages.MANAGE_LINKS;
    }
  }

  async breakLink(distributorOnboardingLinkId: string) {
    const brokenLink = await this.distributorSvc.breakOnboardingLink(distributorOnboardingLinkId).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something Went Wrong',
        text: error.message,
      });
    });

    if (brokenLink) {
      this.toastSvc.show({
        type: 'success',
        title: 'Link Broken',
        text: 'The onboarding link has been successfully broken.',
      });

      this._refreshList$.next('Link broken');
    }
  }

  async copyLink(linkId?: string) {
    try {
      const url = await this.configSvc.get('dashboard_config');
      const link = `${url.url}/on-boarding/${linkId}`;
      this.copyCdk.copy(link);

      this.toastSvc.show({
        type: 'success',
        title: 'Link Copied',
        text: 'The onboarding link has been successfully copied to the clipboard.',
      });
    } catch (err) {
      this.toastSvc.show({
        type: 'error',
        title: 'Error! something went wrong.',
        text: 'link is unable to get copied.',
      });
    }
  }

  async addRoleOption(roleId: string) {
    if (roleId === '*') {
      this.warningDialog?.open('the new distributor');
    }
  }

  async removeRoleOption(roleId: string) {
    const updated = this.rolesFormGroup.value.roles?.filter(item => item !== roleId);
    this.rolesFormGroup.setValue({ roles: updated ?? [] });
  }

  closeBreakLinkDialog(event: boolean) {
    if (event) this._refreshList$.next('Link broken');
  }

  navigateTo(link: Distributor.OnboardingLink.Model) {
    this.goTo(pages.VIEW_LINK_DETAILS);
    this.selectedLinkId = link.id;
    const checkExpiry = link?.expiry ? ((link?.expiry?.getTime() || 0) >= Date.now() ? false : true) : false;
    const daysLeft =
      !checkExpiry && link?.expiry
        ? Math.round(((link?.expiry?.getTime() || 0) - Date.now()) / (1000 * 60 * 60 * 24))
        : null;

    this.rolesFormGroup.value.roles = link.roles.map(role => Staff.Security.getRole(role)?.display.title ?? role);

    this.linkDetails.patchValue({
      linkExpired: checkExpiry,
      days: daysLeft,
      canCreateUser: link.canCreateUser,
      distributor: link.distributor,
    });
  }

  selectDistributor(distributor: Distributor.Model | null) {
    this.linkDetails.patchValue({
      distributor: distributor,
    });
    this.distributor = distributor;
    this.distributor$.next(distributor);
  }
}
