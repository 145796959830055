/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Payment } from '@vsolv/packages/payments/domain';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ws-payment-status-picker',
  templateUrl: './payment-status-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PaymentStatusPickerComponent,
    },
  ],
})
export class PaymentStatusPickerComponent implements ControlValueAccessor, OnInit, OnDestroy {
  constructor(public elementRef: ElementRef) {}

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<Payment.Status[] | null>();

  readonly searchQuery$ = new BehaviorSubject<string>('');

  @PropertyListener('value') value$ = new ReplaySubject<Payment.Status>(1);
  @Input() value: Payment.Status[] | null = null;

  @Input() placeholder = 'Select a status...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';

  touched = false;
  overlayOpen = false;

  statuses: string[] = [];
  draft = Payment.Status.DRAFT;
  failed = Payment.Status.FAILED;
  paid = Payment.Status.PAID;
  partial = Payment.Status.PARTIALLY_REFUNDED;
  pending = Payment.Status.PENDING;
  processing = Payment.Status.PROCESSING;
  refunded = Payment.Status.REFUNDED;
  voided = Payment.Status.VOIDED;

  onChange = (_value: Payment.Status[] | null) => {};
  onTouched = () => {};

  selectValueChanged(value: any) {
    this.markAsTouched();

    if (typeof value === 'object') this.value = value;
    else if (value) this.value = [value];
    else this.value = null;

    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: Payment.Status[] | null): void {
    this.value = value;
  }
  registerOnChange(onChange: (_value: Payment.Status[] | null) => {}): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  getTheme(status: Payment.Status) {
    switch (status) {
      case Payment.Status.DRAFT:
      case Payment.Status.VOIDED:
      case Payment.Status.REFUNDED:
      case Payment.Status.PARTIALLY_REFUNDED:
        return 'default';
      case Payment.Status.PENDING:
        return 'warn';
      case Payment.Status.PAID:
        return 'success';
      case Payment.Status.FAILED:
        return 'danger';
      case Payment.Status.PROCESSING:
      case Payment.Status.PROCESSING_REFUND:
        return 'info';
    }
  }

  ngOnInit() {
    this.statuses = Object.keys(Payment.Status);
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
