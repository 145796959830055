import { Component } from '@angular/core';

@Component({
  template: `
    <div class="flex h-screen justify-center items-center">
      <vs-icon name="loading-02" class="text-primary-500 animate-spin" size="80"></vs-icon>
    </div>
  `,
})
export class RefreshPage {}
