import { ApiMode } from '@vsolv/core/multi-tenant/domain';
import { apiMode } from './api-mode';

export const environment = {
  apiMode,
  production: false,
  dwollaMode: 'sandbox' as 'prod' | 'sandbox',
  apiBaseUrl: 'https://api.vsolv.app',
  firebase:
    apiMode === ApiMode.LIVE
      ? {
          apiKey: 'AIzaSyB8L6FGrbKqmhaF0ZMqd5hXFNjy69HlvqU',
          authDomain: 'vectorsolv-dev.firebaseapp.com',
          databaseURL: 'https://vectorsolv-dev.firebaseio.com',
          projectId: 'vectorsolv-dev',
          storageBucket: 'vectorsolv-dev.appspot.com',
          messagingSenderId: '506696867165',
          appId: '1:506696867165:web:99c3a9be71fd7227db9cb4',
          measurementId: 'G-7T0XCSPT5S',
        }
      : {
          apiKey: 'AIzaSyB8L6FGrbKqmhaF0ZMqd5hXFNjy69HlvqU',
          authDomain: 'vectorsolv-dev.firebaseapp.com',
          databaseURL: 'https://vectorsolv-dev.firebaseio.com',
          projectId: 'vectorsolv-dev',
          storageBucket: 'vectorsolv-dev.appspot.com',
          messagingSenderId: '506696867165',
          appId: '1:506696867165:web:be57a38011f5c51cdb9cb4',
          measurementId: 'G-CD9P8SW7Q3',
        },
  googleMapsKey: 'AIzaSyA3sERb1ZRoz8apia6GHqWmUAGXbWdYh2Q',
};
