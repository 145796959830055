import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Property } from '@vsolv/packages/properties/domain';
import { PropertyService } from '@vsolv/packages/properties/web';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

export interface PropertyWithAddress {
  property: Property.Model<Property.PropertyType>;
  children?: PropertyWithAddress[];
  isAddress: boolean;
}

@Component({
  selector: 'ws-shipping-address-property-picker',
  templateUrl: './shipping-address-property-picker.component.html',
})
export class ShippingAddressPropertyPickerComponent {
  constructor(private propertySvc: PropertyService) {}

  @Input() disabled = false;

  @PropertyListener('propertySetId') propertySetId$ = new BehaviorSubject<string | null>(null);
  @Input() propertySetId: string | null = null;

  overlayOpen = false;

  @PropertyListener('selectedPropertyPath') selectedPropertyPath$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() selectedPropertyPath?: string | null;

  @Input() value: { property: Property.Model<Property.PropertyType>; propertyPath: string } | null = null;

  @Output() selectionChanged = new EventEmitter<{
    property: {
      property: Property.Model<Property.PropertyType>;
      propertyPath: string;
    } | null;
    clearValue: boolean;
  }>();

  properties$ = this.propertySetId$.pipe(
    switchMap(async setId => {
      if (!setId) return [];

      const propertySet = await this.propertySvc.retrievePropertySet(setId);

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.mapProperties(propertySet.properties!.map(assignment => assignment.property!));
    })
  );

  selectedProperty$ = combineLatest([this.properties$, this.selectedPropertyPath$]).pipe(
    map(([properties, selectedPropertyPath]) => {
      if (selectedPropertyPath && selectedPropertyPath !== this.value?.propertyPath) {
        const property = this.getProperty(
          selectedPropertyPath,
          properties.map(prop => prop.property)
        );
        if (property) {
          this.setValue({ property, propertyPath: selectedPropertyPath }, false);
        }
      }
    })
  );

  private getProperty(
    propertyPath: string,
    properties: Property.Model<Property.PropertyType>[]
  ): Property.Model<Property.PropertyType> | undefined {
    for (const property of properties) {
      if (property.valueKey === propertyPath) {
        return property;
      } else if (propertyPath.startsWith(property.valueKey)) {
        return this.getProperty(
          propertyPath.replace(property.valueKey + '.', ''),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (property as Property.ObjectModel).properties?.map(assignment => assignment.property!) ?? []
        );
      }
    }
    return undefined;
  }

  setValue(
    value: { property: Property.Model<Property.PropertyType>; propertyPath: string } | null,
    clearValue: boolean
  ) {
    this.value = value;
    this.selectionChanged.emit({ property: value, clearValue });
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  isAddress(property: Property.Model<Property.PropertyType>) {
    if (property.type !== Property.PropertyType.OBJECT) return false;

    if (property.valueKey === 'address') return true;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const children = (property as Property.ObjectModel).properties!.map(assignment => assignment.property!.valueKey);

    return (
      children.includes('line1') &&
      children.includes('line2') &&
      children.includes('city') &&
      children.includes('zip') &&
      children.includes('state') &&
      children.includes('country')
    );
  }

  containsAddressObject(property: Property.Model<Property.PropertyType>) {
    if (property.type !== Property.PropertyType.OBJECT) return false;
    if (this.isAddress(property)) return true;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (const child of (property as Property.ObjectModel).properties!) {
      const containsAddress = this.containsAddressObject(child.property as Property.ObjectModel);
      if (containsAddress) return true;
    }

    return false;
  }

  private mapProperties(properties: Property.Model<Property.PropertyType>[]) {
    const validProperties: PropertyWithAddress[] = [];
    for (const property of properties) {
      if (property.type !== Property.PropertyType.OBJECT) continue;

      if (this.isAddress(property)) {
        validProperties.push({ property: property as Property.ObjectModel, isAddress: true });
      } else if (this.containsAddressObject(property)) {
        const children = this.mapProperties(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (property as Property.ObjectModel).properties!.map(assignment => assignment.property!)
        );
        validProperties.push({ property: property, children, isAddress: false });
      }
    }
    return validProperties;
  }
}
