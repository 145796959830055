/* eslint-disable @angular-eslint/component-selector */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface StepperStepConfig {
  title: string;
  subtitle?: string;

  allowJumpToStep?: boolean;
  routerLink?: string;
  hideStep?: boolean;
}

export interface StepperConfig {
  mode?: 'vertical' | 'horizontal' | 'horizontal_navigation';

  activeStep?: number;
  steps?: StepperStepConfig[];
}

@Component({
  selector: 'vs-stepper',
  templateUrl: './stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  @Input() set config(config: StepperConfig) {
    this.mode = config?.mode ?? 'vertical';
    this.steps = config.steps || [];
    this.selectedStep = config.activeStep || 1;
  }

  @Output() stepChanged = new EventEmitter<number>();

  mode = 'vertical';
  steps: StepperStepConfig[] = [];

  activeStep = 1;
  set selectedStep(step: number) {
    if (step === -1) this.activeStep = -1;
    else if (!step || step < 1) this.activeStep = 1;
    else if (step > this.steps.length) this.activeStep = this.steps.length;
    else this.activeStep = step;

    this.stepChanged.emit(this.activeStep);
  }

  onClick(step: StepperStepConfig, index: number) {
    if (step.allowJumpToStep) this.selectedStep = index + 1;
    if (step.routerLink !== undefined) this.navigateTo(step.routerLink);
  }

  navigateTo(path: string) {
    this.router.navigate([`./${path}`], { relativeTo: this.route });
  }

  checkPreviousSteps(steps: StepperStepConfig[], index: number) {
    for (let i = 0; i < index; i++) {
      if (steps[i].hideStep !== true) {
        return false;
      }
    }
    return true;
  }
}
