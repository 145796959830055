import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-remove-dialog',
  templateUrl: './claim-item-remove.dialog.html',
})
export class ClaimItemRemoveDialog {
  constructor(private formBuilder: FormBuilder, private claimSvc: ClaimService, private toastSvc: ToastService) {}

  @ViewChild(DialogComponent) dialog?: DialogComponent;

  @Input() claim: Claim.Model | null = null;
  @Input() item: Claim.ClaimItem.Model | null = null;

  @Output() removed = new EventEmitter<string>();

  removeForm = this.formBuilder.group({
    reason: ['', Validators.required],
  });

  open() {
    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }

  async remove() {
    if (!this.claim || !this.item || !this.removeForm.value.reason) return;

    const removedItemId = await this.claimSvc
      .removeItem(this.claim.id, this.item.id, this.removeForm.value.reason)
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.dialog?.close();
      });

    if (removedItemId) {
      this.toastSvc.show({
        type: 'success',
        title: 'Item Removed',
        text: `Successfully removed ${this.item.coverage?.title} from the claim.`,
      });

      this.removeForm.reset();
      this.removed.emit('deleted');
      this.close();

      this.claimSvc.refreshClaim();
    }
  }
}
