import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Claim } from '@wsphere/warranties/domain';

@Injectable()
export class ClaimPaymentResolver implements Resolve<Claim.ClaimPayment.Model | null> {
  payment!: Claim.ClaimPayment.Model | null;

  async resolve(): Promise<Claim.ClaimPayment.Model | null> {
    return this.payment ?? null;
  }
}
