<ng-container *ngIf="refresh$ | async">
  <ng-container *ngIf="!loading; else isLoading">
    <dwolla-funding-source-create [customerId]="customerId"> </dwolla-funding-source-create>
  </ng-container>
  <ng-template #isLoading>
    <div class="flex justify-center items-center min-h-[284px]">
      <vs-icon name="loading-01" class="animate-spin text-primary" size="40"></vs-icon>
    </div>
  </ng-template>
</ng-container>
