import { Component } from '@angular/core';
@Component({ selector: 'ws-app-root', template: '<router-outlet></router-outlet>' })
export class AppComponent {}

//merge conflict
// export interface AppConfig {
//   logo: string;
// }
// @Component({
//   selector: 'ws-dashboard-web-root',
//   templateUrl: './app.component.html',
//   styleUrls: ['./app.component.scss'],
// })
// export class AppComponent implements OnDestroy {
//   @ViewChild('slideOut') slideOut?: SlideOutComponent;

//   config: AppConfig = {
//     logo: 'assets/logo.svg',
//   };

//   destroy$ = new Subject<void>();
//   isSmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => state.matches));

//   isMobile$ = this.breakpointObserver
//     .observe([Breakpoints.XSmall, Breakpoints.Small])
//     .pipe(map(state => state.matches));

//   open = false;
//   menuBtnHover = false;

//   constructor(private breakpointObserver: BreakpointObserver, private router: Router) {
//     this.router.events
//       .pipe(
//         takeUntil(this.destroy$),
//         filter(e => e instanceof NavigationStart)
//       )
//       .subscribe(_ => this.slideOut?.close());
//   }

//   ngOnDestroy() {
//     this.destroy$.next();
//     this.destroy$.complete();
//   }
// }
