/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, map } from 'rxjs';
import { EditPlanConditionDialog } from '../../dialogs';

export interface PlanCoverageGroupBoxConfig {
  text?: string;
  darkBackground?: boolean;
}

@Component({
  selector: 'ws-plan-coverage-group-box',
  templateUrl: './plan-coverage-group-box.component.html',
})
export class PlanCoverageGroupBoxComponent {
  @ViewChild('editConditionDialog') editConditionDialog?: EditPlanConditionDialog;

  @Input() config?: PlanCoverageGroupBoxConfig;

  @Input() type = 'group';
  @Input() editing = false;
  @Input() disabled = false;
  @Input() propertySet!: PropertySet.Model;
  @Input() plan: Policy.Plan.Model | null = null;
  @Input() policy: Policy.Model | null = null;
  @Input() liabilityGroups: Policy.Plan.LiabilityGroup[] | null = null;
  @Input() rule: Conditions.Rule<string | number | string[]> | null = null;

  @Output() ruleUpdated = new EventEmitter<Conditions.Rule<string | number | string[]> | null>();

  hovered = false;
  overlayOpen = false;

  search$ = new BehaviorSubject<string>('');

  filteredGroups$ = this.search$.pipe(
    map(search => this.liabilityGroups?.filter(group => group.title.toLowerCase().includes(search.toLowerCase())))
  );

  getGroup(value: any) {
    return value instanceof Array ? (value as string[]) : null;
  }

  getGroupName(id: string | null) {
    const group = this.plan?.liabilityGroups?.find(group => group.id === id);
    return group ? group.title : 'No Group';
  }

  openEditConditionDialog() {
    this.editConditionDialog?.openDialog();
  }

  selectValue(value: Policy.Plan.LiabilityGroup) {
    if (!this.rule) return;

    if (!(this.rule.defaultValue as string[])?.length) {
      this.rule.defaultValue = [value.id];
    } else if (!(this.rule.defaultValue as string[])?.includes(value.id)) {
      (this.rule.defaultValue as string[])?.push(value.id);
    } else {
      (this.rule.defaultValue as string[])?.filter(id => id !== value.id);
    }
  }

  removeValue(index?: number) {
    if (!this.rule) return;

    if (index !== undefined) this.rule.blocks.splice(index, 1);
    else this.rule.defaultValue = '';

    this.updatedRule(this.rule);
  }

  updatedRule(rule: Conditions.Rule<string | number | string[]> | null) {
    this.ruleUpdated.emit(rule);
  }
}
