import { Component, Input, ViewChild } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { SecurityService } from '@wsphere/staff/web';
import { Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { BehaviorSubject, map, switchMap } from 'rxjs';
import { OpenClaimDialog } from '../../../claim/dialogs';

@Component({
  selector: 'ws-warranty-recent-claims-card',
  templateUrl: './warranty-recent-claims-card.component.html',
})
export class WarrantyRecentClaimsComponent {
  constructor(private securitySvc: SecurityService) {}

  @ViewChild(OpenClaimDialog) newClaimDialog!: OpenClaimDialog;

  @PropertyListener('warranty') warranty$ = new BehaviorSubject<Warranty.Model | null>(null);
  @Input() warranty?: Warranty.Model | null;

  period = '';
  remainingPeriod = 0;
  isPastWaitingPeriod$ = this.warranty$.pipe(
    map(warranty => {
      const waitingPeriodEndDate = moment(warranty?.activationDate)
        .add(warranty?.policy?.claimWaitingPeriod, 'days')
        .toDate();
      if (moment().isBefore(waitingPeriodEndDate)) {
        if (moment(waitingPeriodEndDate).diff(new Date(), 'hours') > 24) {
          this.remainingPeriod = moment(waitingPeriodEndDate).diff(new Date(), 'days');
          this.period = 'days';
        } else {
          this.remainingPeriod = moment(waitingPeriodEndDate).diff(new Date(), 'hours');
          this.period = 'hours';
        }
        return false;
      } else {
        return true;
      }
    })
  );

  canViewClaims$ = this.warranty$.pipe(
    switchMap(warranty =>
      this.securitySvc.hasAccess(
        'clm_ViewClaim',
        warranty?.distributor?.permissionKey ? [warranty.distributor.permissionKey] : null
      )
    )
  );

  canCreateClaim$ = this.warranty$.pipe(
    switchMap(warranty =>
      this.securitySvc.hasAccess(
        'clm_OpenClaim',
        warranty?.distributor?.permissionKey ? [warranty.distributor.permissionKey] : null
      )
    )
  );

  openClaim() {
    if (!this.warranty) return;

    this.newClaimDialog.openDialog(this.warranty, this.warranty.customer);
  }
}
