<vs-dialog #archiveDialog [config]="{ dialogContainerClass: 'w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'link-broken-02',
      iconTheme: 'primary',

      title: 'Break link?',
      subtitle: 'Are you sure you want to break this link? <br><br> People with the current invite link will no longer be able to register their distributor. <br><br> This action cannot be undone.'
    }"
  ></vs-dialog-header>
  <!-- Buttons -->
  <div class="footer mt-7">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" (click)="cancel()">Cancel</button>

      <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="breakLink()">
        Break Link
      </button>
    </div>
  </div>
</vs-dialog>
