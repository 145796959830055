import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '../../services';
@Component({
  selector: 'ws-restore-policy-dialog',
  templateUrl: './restore-policy.dialog.html',
})
export class RestorePolicyDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policySvc: PolicyService,
    private toastSvc: ToastService
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy?: Policy.Model | null;

  saving = false;

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  cancel() {
    this.dialog.close();
  }

  async restore() {
    this.saving = true;

    if (!this.policy) throw new Error('Unable to restore policy, policy not found');

    if (this.policy.status !== Policy.Status.ARCHIVED && this.policy.status !== Policy.Status.RUNOFF) {
      throw new Error(`Unable to restore policy with status: ${this.policy.status}`);
    }

    const newPolicy = await this.policySvc.duplicate(this.policy.id, true).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
      this.saving = false;
    });

    if (newPolicy?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Restored policy',
        text: '<strong>' + this.policy?.title + '</strong> has been successfully restored.',
      });

      this.close();
      this.saving = false;
      this.policySvc.refreshPolicy(newPolicy.id);
      this.policySvc.clearPlan();

      this.router.navigate(
        [
          '../' +
            newPolicy.id +
            (this.route.snapshot.firstChild?.routeConfig?.path
              ? '/' + this.route.snapshot.firstChild.routeConfig.path
              : ''),
        ],
        { relativeTo: this.route }
      );
    }
  }
}
