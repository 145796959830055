<div class="flex items-center justify-center w-14 h-14 rounded-full bg-themed-50" [class.animate-ping]="animated">
  <div
    [class]="
      'flex items-center justify-center w-10 h-10 rounded-full' + (borderStyle === 'double' ? ' bg-themed-100' : '')
    "
  >
    <svg-icon class="text-themed-600" [src]="'assets/icons/' + type + '/' + name + '.svg'"></svg-icon>
  </div>
</div>
<div *ngIf="animated" class="absolute top-0 flex items-center justify-center w-14 h-14 rounded-full bg-themed-50">
  <div
    [class]="
      'flex items-center justify-center w-10 h-10 rounded-full' + (borderStyle === 'double' ? ' bg-themed-100' : '')
    "
  >
    <svg-icon class="text-themed-600" [src]="'assets/icons/' + type + '/' + name + '.svg'"></svg-icon>
  </div>
</div>
