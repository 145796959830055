import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { EvaluatedAddon } from '../../components';

@Component({
  selector: 'ws-preview-group-addons',
  templateUrl: './preview-group-addons.dialog.html',
})
export class PreviewGroupAddonsDialog {
  @ViewChild(DialogComponent) dialog?: DialogComponent;

  @Input() addon: EvaluatedAddon | null = null;
  @Input() selectedAddon = 0;
  @Input() hideDeductibleAndLiabilityLimit = false;
  @Input() checked = false;

  @Output() selected = new EventEmitter<boolean>();

  open() {
    this.dialog?.open();
  }
}
