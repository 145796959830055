import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core';
import { map, startWith, Subject, takeUntil } from 'rxjs';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'vs-tabs',
  templateUrl: './tabs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  constructor(private breakpointObserver: BreakpointObserver, private changeDetector: ChangeDetectorRef) {}

  @Input() showDivider = true;
  @Input() disabled = false;
  @Input() disabledMessage = 'tabs are disabled';

  @ContentChildren(TabComponent) tabs?: QueryList<TabComponent>;

  @Output() tabChanged = new EventEmitter<number>();

  readonly isSmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => state.matches));

  private _selectedTabIndex = 0;
  get selectedTabIndex() {
    return this._selectedTabIndex;
  }

  get selectedTab() {
    return this.tabs?.get(this.selectedTabIndex);
  }

  private _onDestroy$ = new Subject<void>();

  switchTo(index: number) {
    if (index < 0 || (this.tabs?.length && index > this.tabs.length - 1)) return;
    this._selectedTabIndex = index;

    this.tabChanged.emit(this._selectedTabIndex);
    this.updateTabSelection();

    this.changeDetector.detectChanges();
  }

  ngAfterContentInit() {
    this.tabs?.changes.pipe(startWith(this.tabs), takeUntil(this._onDestroy$)).subscribe(() =>
      setTimeout(() => {
        this.updateTabSelection();
        this.changeDetector.detectChanges();
      })
    );
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private updateTabSelection() {
    this.tabs?.forEach((tab, index) => {
      tab['_selected'] = index === this._selectedTabIndex;
    });
  }
}
