/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, switchMap, tap } from 'rxjs';
import { EditPolicyType, PolicyDialog, PreviewPDFDialog } from '../../dialogs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-policy-documents-page',
  templateUrl: './policy-documents.page.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyDocumentsPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private policySvc: PolicyService,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('editDocumentsDialog') editDocumentsDialog?: PolicyDialog;
  @ViewChild('previewPDFDialog') previewPDFDialog?: PreviewPDFDialog;

  readonly policy$ = this.policySvc.getPolicyObservable();

  editType: EditPolicyType | null = EditPolicyType.TERMS_AND_CONDITIONS;

  documents: Policy.Document[] = [];
  selectedDocument: Policy.Document | null = null;

  loading$ = new BehaviorSubject<boolean>(false);

  refreshDocuments$ = this.policy$.pipe(
    tap(async policy => {
      this.loading$.next(true);

      if (policy) {
        if (policy && policy.termsAndConditions && policy.coverageSummary) {
          this.documents = [policy.termsAndConditions, policy.coverageSummary];

          if (this.selectedDocument?.id === policy.coverageSummaryId) this.selectDocument(policy.coverageSummary);
          else this.selectDocument(policy.termsAndConditions);
        } else {
          this.documents = [];
          this.selectedDocument = null;
        }
      }

      setTimeout(() => this.loading$.next(false), 100);
    })
  );

  canView$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_View', permissionKeys);
    })
  );

  canEdit$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('pol_Edit', permissionKeys);
    })
  );

  getNavboxStyle(id: string) {
    const selected =
      id === this.selectedDocument?.id
        ? 'border-l-4 -ml-1 border-primary-500 rounded-r-none z-10'
        : 'border-1 cursor-pointer';
    return `flex flex-col bg-base pl-4 pr-2 py-2 rounded-lg shadow-md ${selected}`;
  }

  selectDocument(document?: Policy.Document) {
    if (document) this.selectedDocument = document;
  }

  openEditDocumentDialog(type?: string) {
    if (type === 'Terms & Conditions') this.editType = EditPolicyType.TERMS_AND_CONDITIONS;
    else this.editType = EditPolicyType.COVERAGE_SUMMARY;

    this.editDocumentsDialog?.open();
  }

  previewDocument(document: Policy.Document) {
    this.selectedDocument = document;
    this.previewPDFDialog?.openDialog();
  }

  navigateTo(path?: any) {
    if (path) this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
