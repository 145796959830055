<button
  vs-button
  type="button"
  [theme]="'primary'"
  (click)="inspectionDialog.open()"
  class="text-primary-700 !w-full !max-w-lg font-semibold mt-3"
  appearance="clear"
>
  <vs-icon *ngIf="!disabled" [name]="!formControl.value ? 'plus' : 'pencil-01'"></vs-icon>
  {{ disabled ? 'View inspection report' : formControl.value ? 'Edit inspection report' : 'Add inspection report' }}
</button>

<vs-dialog
  #inspectionDialog
  [config]="{
    dialogContainerClass: 'w-[600px] max-w-[90%] max-h-[90%] flex flex-col ',
    disableBackdropDismiss: formControl.invalid
  }"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      icon: 'bank',
      iconTheme: 'primary',
      hideCloseButton: false,
      title: 'Add inspection report',
      subtitle: 'Enter the inspector details and report results for this home.',
      showDivider: true
    }"
  ></vs-dialog-header>
  <div class="overflow-y-scroll">
    <vs-property-input-form-field
      [showLabel]="false"
      [ignoreRegisteredForm]="true"
      [property]="property"
      [required]="required"
      [hidden]="hidden"
      [showHidden]="showHidden"
      [formControl]="formControl"
      [disabled]="disabled"
    ></vs-property-input-form-field>
  </div>

  <div class="flex sm:flex-row sm:justify-end gap-3 pt-4">
    <ng-container *ngIf="!disabled; else closed">
      <button vs-button appearance="outline" (click)="close()">Cancel</button>
      <span [vsTooltip]="formControl.invalid ? 'The form is either incomplete or has some errors.' : ''">
        <button
          vs-button
          theme="primary"
          class="w-full sm:w-auto"
          [disabled]="!formControl.value || formControl.invalid"
          (click)="$event.preventDefault(); close()"
        >
          Add report
        </button>
      </span>
    </ng-container>
    <ng-template #closed>
      <button vs-button appearance="outline" (click)="close()">Close</button>
    </ng-template>
  </div>
</vs-dialog>
