<ng-container *vsLet="groupsAndCoverages$ | async as groups">
  <vs-input-container
    #trigger="cdkOverlayOrigin"
    vs-button
    cdkOverlayOrigin
    class="flex justify-between items-center px-3 py-2"
    (click)="toggle()"
  >
    <div class="flex gap-2 items-center">
      <span class="font-normal text-gray-500 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap">
        {{ value !== undefined ? (value ? value.title : 'Other') : 'Please select an issue' }}
      </span>

      <vs-badge *ngIf="value !== undefined" [theme]="value ? (included ? 'success' : 'danger') : 'default'">
        {{ value ? (included ? 'Included' : 'Excluded') : 'None' }}
      </vs-badge>
    </div>

    <vs-icon class="self-center" [name]="overlayOpen ? 'chevron-up' : 'chevron-down'"></vs-icon>
  </vs-input-container>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayOpen"
    (overlayOutsideClick)="$event.stopImmediatePropagation(); close()"
  >
    <vs-card class="flex flex-col w-[516px] max-h-56 pb-2 overflow-x-hidden overflow-y-auto scrollbar-thin !rounded-md">
      <div>
        <vs-input-container class="rounded-b-none !border-t-0 !border-x-0 !shadow-none">
          <vs-icon slot="start" name="search-lg"></vs-icon>
          <input #input type="text" (input)="search$.next(input.value)" />
        </vs-input-container>
      </div>

      <ng-container *ngIf="!selectedGroup">
        <div
          *ngFor="let group of groups"
          class="flex justify-between items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
          (click)="selectedGroup = group.coverages"
        >
          <p class="font-medium text-gray-900 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap">
            {{ group.group }}
          </p>

          <vs-icon name="chevron-right"></vs-icon>
        </div>

        <div
          class="flex justify-between items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
          (click)="selectValueChanged(null)"
        >
          <p
            class="flex font-medium text-gray-900 gap-2 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap"
          >
            Other
            <vs-badge class="self-center" theme="default"> None </vs-badge>
          </p>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedGroup">
        <div class="flex flex-col">
          <div class="flex gap-2 items-center px-4 py-2 hover:bg-gray-50 cursor-pointer" (click)="selectedGroup = null">
            <vs-icon name="chevron-left"></vs-icon>
            <p class="text-sm font-semibold text-gray-700">
              {{ selectedGroup[0].coverage.group }}
            </p>
          </div>

          <div
            *ngFor="let coverage of selectedGroup"
            class="flex gap-2 items-center px-4 py-2 hover:bg-gray-50 cursor-pointer"
            (click)="selectValueChanged(coverage.coverage, coverage.included)"
          >
            <input vsInput type="checkbox" [checked]="value?.id === coverage.coverage.id" />
            <p
              class="text-sm font-medium text-gray-700 max-w-[300px] overflow-hidden overflow-ellipsis whitespace-nowrap"
            >
              {{ coverage.coverage.title }}
            </p>

            <vs-badge [theme]="coverage.included ? 'success' : 'danger'">
              {{ coverage.included ? 'Included' : 'Excluded' }}
            </vs-badge>
          </div>
        </div>
      </ng-container>
    </vs-card>
  </ng-template>
</ng-container>
