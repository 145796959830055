<vs-card *vsLet="value$ | async as steps" [class.border-none]="hideBorder" [class.shadow-none]="hideBorder">
  <vs-card-header
    [config]="{
      title: title || 'Claim Progress Steps',
      subtitle:
        subtitle ||
        'Review and configure the steps that will appear in the customer portal for claims filed under their claim.'
    }"
  >
    <div *ngIf="canEdit$ | async" slot="end" class="flex gap-2">
      <ng-content select="[slot='buttons']"></ng-content>

      <button
        *ngIf="!hideSave && (steps?.length || touched)"
        vs-button
        appearance="outline"
        [disabled]="editing && !touched"
        (click)="editing ? emitSave() : edit()"
      >
        <vs-icon [name]="editing ? 'save-02' : 'edit-01'"></vs-icon>
        {{ editing ? 'Save' : 'Edit' }}
      </button>

      <button *ngIf="editing || !steps?.length" vs-button theme="primary" (click)="openCreateStepDialog()">
        <vs-icon name="plus"></vs-icon>
        Add step
      </button>
    </div>
  </vs-card-header>

  <vs-card-content class="!px-0 !pb-0 overflow-hidden !rounded-b-xl">
    <div *ngIf="steps?.length; else noSteps" class="overflow-hidden w-full">
      <vs-table
        #table
        [data]="value$ | async"
        [columns]="(columns$ | async) ?? []"
        [config]="{ draggable: editing ? (canEdit$ | async) || false : false }"
        (rowDragged)="reorder($event)"
      ></vs-table>
    </div>

    <ng-template #noSteps>
      <div class="flex flex-col justify-center gap-2 p-6 mt-4">
        <p class="text-xl font-semibold text-gray-900 self-center">No claim lifecycle steps.</p>

        <p class="font-normal text-gray-600 self-center mb-4">
          There are currently no claim lifecycle steps. Add steps to show customers the proper order to complete their
          claim.
        </p>
      </div>
    </ng-template>
  </vs-card-content>
</vs-card>

<ws-claim-lifecycle-step-dialog
  #createStepDialog
  (closed)="addStep($event); stepAdded.emit()"
></ws-claim-lifecycle-step-dialog>
<ws-claim-lifecycle-step-dialog #updateStepDialog (closed)="updateStep($event)"></ws-claim-lifecycle-step-dialog>
<ws-delete-claim-lifecycle-step-dialog
  #deleteStepDialog
  (closed)="deleteStep()"
></ws-delete-claim-lifecycle-step-dialog>
