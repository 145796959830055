import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AnimationsModule } from '@vsolv/vectors-ui/animations';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { SlideOutComponent } from './components';

export * from './components';

@NgModule({
  declarations: [SlideOutComponent],
  imports: [CommonModule, OverlayModule, AnimationsModule, IconsModule, ButtonModule],
  exports: [SlideOutComponent],
})
export class SlideOutModule {}
