import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Distributor } from '@wsphere/distributors/domain';

@Component({
  selector: 'ws-distributor-sftp-page',
  templateUrl: './distributor-sftp.page.html',
})
export class DistributorSFTPPage {
  @Input() distributor: Distributor.Model | null = null;

  @Output() goToDistEmail = new EventEmitter();

  activeStatus = Distributor.Status.ACTIVE;
}
