/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, debounceTime, map, of, ReplaySubject, startWith, switchMap } from 'rxjs';
import { ClaimService } from '../../services';
import { EditClaimDocumentDialog } from '../edit-document/edit-document.dialog';

@Component({
  selector: 'ws-view-claim-document-dialog',
  templateUrl: './view-document.dialog.html',
})
export class ViewClaimDocumentDialog {
  constructor(
    private claimSvc: ClaimService,
    private linkSvc: LinksWebService,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @Input() isCustomerPortal: boolean | null = null;

  @ViewChild('dialog') dialog?: DialogComponent;
  @ViewChild('editDialog') editDialog?: EditClaimDocumentDialog;

  @Output() updated = new EventEmitter();

  protected readonly claim$ = new ReplaySubject<Claim.Model>(1);

  private readonly claimDocument$ = new ReplaySubject<StorageItem.Model>(1);

  private readonly refresh$ = new BehaviorSubject<null>(null);

  private readonly clearDocument$ = new BehaviorSubject<boolean>(false);

  protected isLoading$ = new BehaviorSubject<boolean>(true);

  protected readonly document$ = combineLatest([this.claimDocument$, this.claim$, this.clearDocument$]).pipe(
    switchMap(([document, claim, clearDocument]) => {
      if (clearDocument) return of(null);
      return this.refresh$.pipe(
        startWith(document),
        debounceTime(300),
        switchMap(async () => {
          return await this.claimSvc.getDocument(claim.id, document.id);
        })
      );
    })
  );

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  canEdit$ = this.claim$.pipe(
    switchMap(async claim => {
      if (this.isCustomerPortal && claim.status === Claim.Status.EXPIRED) return false;
      if (this.isCustomerPortal) return true;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim.warranty.distributor.permissionKey]
        : null;

      return await this.securitySvc.hasAccess('clm_ManageAttachments', permissionKey);
    })
  );

  links$ = combineLatest([this.claim$, this.document$]).pipe(
    switchMap(async ([claim, document]) => {
      if (!document || !claim) return [];

      const links = await this.linkSvc.list(
        { id: claim.id, objectType: Link.ObjectType.CLAIM },
        { owner: { id: document.id, objectType: Link.ObjectType.STORAGE_ITEM } }
      );

      return links.items.reduce((acc, link) => {
        const type = Link.getLinkType(link, Link.ObjectType.STORAGE_ITEM);
        if (type === Link.ObjectType.ACTIVITY) return acc;

        const object = Link.getLinkObject(link, type, document.id);
        if ((object as any)?.deleted) return acc;

        const config = Link.getLinkInfoCardConfig(link, type, document.id);
        acc.push({ link, config });

        return acc;
      }, [] as { link: Link.Model; config: InfoCardConfig }[]);
    })
  );

  getItemTheme(item?: Claim.ClaimItem.Model) {
    return item ? Claim.ClaimItem.getTheme(item.status) : 'default';
  }

  open(props: { claim: Claim.Model; document: StorageItem.Model }) {
    this.clearDocument$.next(false);
    this.claim$.next(props.claim);
    this.claimDocument$.next(props.document);
    this.dialog?.open();
    setTimeout(() => this.isLoading$.next(false), 300);
  }

  close() {
    this.dialog?.close();
    this.updated.emit(null);
  }

  openEditDialog(props: { claim: Claim.Model; document: StorageItem.Model }) {
    this.dialog?.close();
    this.editDialog?.open(props);
  }

  editDialogClosed(action: string) {
    if (action === 'cancel') this.dialog?.open();
    if (action === 'deleted') this.dialog?.close();
    else if (action !== undefined) {
      this.refresh();
      this.dialog?.open();
    } else this.updated.emit(null);
  }

  refresh() {
    this.refresh$.next(null);
    this.clearDocument$.next(false);
    this.isLoading$.next(false);

    this.claimSvc.refreshClaim();
  }

  refreshActivity() {
    this.clearDocument$.next(true);
    this.isLoading$.next(true);
    this.linkSvc.refreshActivity$.next(null);
  }
}
