<div *ngIf="canViewClaim$ && claim$ | async as claim" theme="default" class="flex flex-col gap-4">
  <vs-card *vsLet="routeRefresh$ | async">
    <vs-card-header [config]="{ title: 'Overview', subtitle: 'Manage and view the details of this claim.' }">
      <button
        *ngIf="(canEditClaim$ | async) && claim.status === open"
        vs-button
        slot="end"
        appearance="clear"
        (click)="openClaimDialog()"
      >
        <vs-icon name="edit-01"></vs-icon>
      </button>
    </vs-card-header>

    <div class="flex gap-2 mx-6 my-4 max-w-full overflow-hidden">
      <div class="basis-1/4">
        <p class="text-sm font-medium text-gray-700 whitespace-nowrap">Customer's complaint</p>
      </div>

      <div class="basis-3/4 max-w-[65%] overflow-hidden">
        <p class="text-sm font-normal text-gray-900 max-w-full overflow-hidden">{{ claim.complaint }}</p>
      </div>
    </div>
    <vs-divider class="col-span-2"></vs-divider>

    <div class="flex gap-2 mx-6 my-4 max-w-full overflow-hidden">
      <div class="basis-1/4">
        <p class="text-sm font-medium text-gray-700 whitespace-nowrap">Reference ID</p>
      </div>

      <div class="basis-3/4 max-w-[65%] overflow-hidden">
        <p class="text-sm font-normal text-gray-900 max-w-full overflow-hidden">{{ claim.referenceId ?? '' }}</p>
      </div>
    </div>
  </vs-card>

  <vs-card *vsLet="claim.items?.length as itemCount">
    <vs-card-header
      *vsLet="(table.paginationConfig$ | async)?.totalItems as totalItems"
      [config]="{ title: 'Claim Items', subtitle: 'Manage and view all claim items in the table below.' }"
    >
      <vs-badge slot="after-title"> {{ totalItems ?? 0 }} Claim Item{{ totalItems === 1 ? '' : 's' }} </vs-badge>

      <div slot="end" class="flex gap-3 max-h-10">
        <input
          #input
          vsInput
          type="checkbox"
          theme="primary"
          class="self-center"
          appearance="toggle"
          tooltipStyle="light"
          [vsTooltip]="{ template: removedItems }"
          (input)="table.refreshTable({ withDeleted: input.checked })"
        />
        <ng-template #removedItems>
          <span class="text-sm font-semibold text-gray-700 self-center whitespace-nowrap">Show removed items</span>
        </ng-template>

        <button
          *ngIf="(canOpenClaim$ | async) && totalItems && claim.status === open"
          vs-button
          appearance="outline"
          (click)="openCreateItemDialog()"
        >
          <vs-icon name="file-plus-03"></vs-icon> New Claim Item
        </button>
      </div>
    </vs-card-header>

    <div class="px-4 pb-4">
      <ws-claim-item-table #table [claim]="claim"></ws-claim-item-table>
    </div>
  </vs-card>

  <ws-open-claim-dialog #claimDialog [claim]="claim" (closed)="refresh()"></ws-open-claim-dialog>
</div>
<ws-claim-item-details-dialog #createItemDialog (closed)="refresh()"></ws-claim-item-details-dialog>

<router-outlet></router-outlet>
