<div *vsLet="SftpCredentials$ | async as credentials" class="flex flex-col w-full" [class.p-4]="distributor">
  <div class="flex flex-col sm:flex-row sm:justify-between sm:items-center">
    <div *ngIf="distributor">
      <span class="text-lg font-semibold">SFTP</span>
      <p class="text-gray-600 text-sm pt-1">Manage SFTP credentials for this distributor.</p>
    </div>
    <ng-container *ngIf="!credentials && (canEditCredentials$ | async)">
      <div class="w-full mt-2 mb-2 sm:m-0 sm:max-w-min">
        <button
          vs-button
          theme="primary"
          size="md"
          (click)="createUser()"
          class="w-full sm:max-w-min"
          [disabled]="creating"
        >
          <vs-icon name="plus"></vs-icon>
          Create SFTP Credentials
        </button>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="credentials && (canViewCredentials$ | async)">
    <div class="flex flex-col gap-2 pt-2">
      <div class="flex gap-4 flex-row items-center">
        <span class="w-32 text-gray-700 text-sm font-medium whitespace-nowrap">Host</span>
        <span class="w-full text-gray-900 text-sm truncate"> {{ credentials.host }} </span>
        <button vs-icon-button class="rounded-md" appearance="clear" size="md" (click)="copy(credentials.host, 'host')">
          <vs-icon name="copy-01"></vs-icon>
        </button>
      </div>

      <vs-divider></vs-divider>

      <div class="flex gap-4 flex-row items-center h-10">
        <span class="w-32 text-gray-700 text-sm font-medium whitespace-nowrap">
          Port <vs-icon name="info-circle" [vsTooltip]="'The default port for SFTP is 22.'"></vs-icon>
        </span>
        <span class="w-full text-gray-900 text-sm truncate">22</span>
        <button vs-icon-button class="rounded-md" appearance="clear" size="md" (click)="copy('22', 'port')">
          <vs-icon name="copy-01"></vs-icon>
        </button>
      </div>

      <vs-divider></vs-divider>

      <div class="flex gap-4 flex-row items-center">
        <span class="w-32 text-gray-700 text-sm font-medium whitespace-nowrap">Username</span>
        <span class="w-full text-gray-900 text-sm truncate"> {{ credentials.username }} </span>
        <button
          vs-icon-button
          class="rounded-md"
          appearance="clear"
          size="md"
          (click)="copy(credentials.username, 'username')"
        >
          <vs-icon name="copy-01"></vs-icon>
        </button>
      </div>

      <vs-divider></vs-divider>

      <div class="flex gap-4 flex-row items-center">
        <span class="w-32 text-gray-700 text-sm font-medium whitespace-nowrap">Password</span>
        <span class="w-full text-gray-900 text-sm truncate"
          ><input id="readonly-state-input" type="text" [value]="credentials.details" readonly #inputTarget
        /></span>
        <button
          vs-icon-button
          class="rounded-md"
          appearance="clear"
          size="md"
          (click)="reroll()"
          [disabled]="(canEditCredentials$ | async) === false"
        >
          <vs-icon name="refresh-cw-05"></vs-icon>
        </button>
      </div>
    </div>
    <ng-container *ngIf="id$ | async as id">
      <ws-reroll-sftp-key-dialog
        #rerollSFTPKeyDialog
        [distributorOrTenantId]="id"
        [host]="credentials.host"
        [keyId]="credentials.id"
        (closed)="refresh()"
      ></ws-reroll-sftp-key-dialog>
    </ng-container>
  </ng-container>
</div>
<ws-copy-sftp-key-dialog #copyKeyDialog (closed)="refresh()"></ws-copy-sftp-key-dialog>
