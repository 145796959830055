/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Staff } from '@wsphere/staff/domain';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';
import { SecurityService } from '../../services';

@Component({
  selector: 'ws-role-picker',
  templateUrl: './role-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RolePickerComponent,
    },
  ],
})
export class RolePickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef, private securitySvc: SecurityService) {}

  @HostBinding('class') _class = 'block';

  @Input() filter = false;
  @Input() required = false;
  @Input() disabled = false;
  @Input() placeholder = 'Search roles';
  @Input() themeColor: ThemeColor = 'primary';

  @Input() rolesOutside = false;

  touched = false;

  @PropertyListener('permissionKey') permissionKey$ = new BehaviorSubject<string | null>(null);
  @Input() permissionKey: string | null = null;

  @PropertyListener('value') value$ = new BehaviorSubject<string[]>([]);
  @Input() value: string[] = [];

  @Output() roleAdded = new EventEmitter<string>();
  @Output() roleRemoved = new EventEmitter<string>();

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<string[]>();

  readonly searchQuery$ = new BehaviorSubject<string>('');

  fullAdmin = '*';
  showAdmin = false;
  overlayOpen = false;

  selectedGroup: { group: string; roles: Staff.Security.Role[] } | null = null;

  groupsAndRoles$ = combineLatest([this.searchQuery$, this.value$, this.permissionKey$]).pipe(
    switchMap(async ([search, value, permissionKey]) => {
      const allRoles = Staff.Security.getAllRoles().filter(role => !role.hideRole);
      const availableRoles: Staff.Security.Role[] = [];

      for (const role of allRoles) {
        let hasRole = true;
        for (const permission of role.permissions) {
          const hasPermission = await this.securitySvc.hasAccess(permission, permissionKey ? [permissionKey] : null);
          if (!hasPermission) {
            hasRole = false;
            break;
          } else {
            if (permission === this.fullAdmin) this.showAdmin = true;
          }
        }
        if (hasRole) availableRoles.push(role);
      }

      const unselectedRoles = availableRoles.filter(role => !value.some(roleId => role?.id === roleId));
      return !search
        ? unselectedRoles
        : unselectedRoles.filter(role => role?.display.title.toLowerCase().includes(search.toLowerCase()));
    }),
    map(roles => {
      const groups = roles.reduce((acc, role) => {
        if (!acc.includes(role.group)) acc.push(role.group);
        return acc;
      }, [] as string[]);

      const groupsAndRoles: { group: string; roles: Staff.Security.Role[] }[] = [];

      groups.forEach(group => {
        const groupRoles = roles.filter(role => role.group === group && role.group !== 'Platform Management');
        if (groupRoles.length) groupsAndRoles.push({ group, roles: groupRoles });
      });

      return groupsAndRoles;
    })
  );

  onChange = (_value: string[]) => {};
  onTouched = () => {};

  writeValue(value: string[]): void {
    this.value = value || [];
  }

  registerOnChange(onChange: (_value: string[]) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  hasAllPermissions(roles: Staff.Security.Role[]) {
    let hasAll = true;

    roles.forEach(role => {
      if (hasAll && !this.value.includes(role.id)) hasAll = false;
    });

    return hasAll;
  }

  selectAll(roles: Staff.Security.Role[]) {
    console.log(roles);
    roles.forEach(role => {
      if (!this.value.includes(role.id)) this.addRole(role.id);
    });
  }

  removeAll(roles: Staff.Security.Role[]) {
    roles.forEach(role => {
      if (this.value.includes(role.id)) this.removeRole(role.id);
    });
  }

  addRole(roleId: string) {
    this.markAsTouched();
    this.value.push(roleId);
    this.onChange(this.value);
    this.valueChanges.next(this.value);
    this.roleAdded.emit(roleId);
  }

  removeRole(roleId: string) {
    this.value = this.value.filter(value => value !== roleId);
    this.onChange(this.value);
    this.valueChanges.next(this.value);
    this.roleRemoved.emit(roleId);
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
