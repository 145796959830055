/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { map, switchMap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-adjudicate',
  templateUrl: './claim-item-adjudicate.component.html',
})
export class ClaimItemAdjudicateComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private securitySvc: SecurityService,
    private claimSvc: ClaimService
  ) {}

  readonly claim$ = this.claimSvc.getClaim();
  readonly item$ = this.route.data.pipe(map(data => data['item'] as Claim.ClaimItem.Model));

  open = Claim.Status.OPEN;
  draft = Claim.ClaimItem.Status.DRAFT;

  canManageAdjudication$ = this.claim$.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_AdjudicateClaimItem', permissionKey);
    })
  );

  navigateTo(path?: string) {
    this.router.navigate([`../${path}`], { relativeTo: this.route });
  }
}
