import { Component, HostBinding } from '@angular/core';
import { InputBoxDirective } from '../../directives';

@Component({
  selector: 'vs-input-container',
  hostDirectives: [InputBoxDirective],
  template: `
    <ng-content select="[slot='start']"></ng-content>
    <div class="flex gap-2 justify-between flex-1 h-full overflow-hidden">
      <ng-content></ng-content>
    </div>
    <ng-content select="[slot='end']"></ng-content>
  `,
})
export class InputContainerComponent {
  @HostBinding('class') get styles() {
    const clearInputStyles = `
      [&_input]:bg-transparent
      [&_input:not([slot='end'])]:w-full
      [&_input]:h-full
      [&_input:focus]:outline-none
      [&_input]:truncate
      [&_input]:cursor-[inherit]
    `;
    const iconStyles = `[&_vs-icon:not([class*='text-']):not([class*='theme-'])]:text-gray-500 [&_vs-icon[class*='theme-']]:text-themed-500`;
    const box = `flex gap-3 items-center h-10 px-3 w-full rounded-md`;
    const text = `text-md text-gray-900`;
    return `theme-primary-inherit ${box} ${text} ${clearInputStyles} ${iconStyles}`;
  }
}
