<div [class]="_containerClasses">
  <div class="empty:hidden flex items-center gap-2">
    <ng-content select="[slot='start']"></ng-content>
  </div>

  <div class="flex-1 text-start">
    <ng-content></ng-content>
  </div>

  <div class="empty:hidden flex items-center gap-2">
    <ng-content select="[slot='end']"></ng-content>
    <vs-icon name="chevron-right" class="text-themed-500 hidden group-[.cdk-menu-trigger]/menuitem:block"></vs-icon>
  </div>
</div>
