export const IconNames = [
  'activity',
  'activity-heart',
  'add-column',
  'airplay',
  'airpods',
  'alarm-clock',
  'alarm-clock-check',
  'alarm-clock-minus',
  'alarm-clock-off',
  'alarm-clock-plus',
  'alert-circle',
  'alert-hexagon',
  'alert-octagon',
  'alert-square',
  'alert-triangle',
  'align-bottom-01',
  'align-bottom-02',
  'align-center',
  'align-horizontal-centre-01',
  'align-horizontal-centre-02',
  'align-justify',
  'align-left',
  'align-left-01',
  'align-left-02',
  'align-right',
  'align-right-01',
  'align-right-02',
  'align-top-arrow-01',
  'align-top-arrow-02',
  'align-vertical-center-01',
  'align-vertical-center-02',
  'anchor',
  'annotation',
  'annotation-alert',
  'annotation-check',
  'annotation-dots',
  'annotation-heart',
  'annotation-info',
  'annotation-plus',
  'annotation-question',
  'annotation-x',
  'announcement-01',
  'announcement-02',
  'announcement-03',
  'archive',
  'arrow-block-down',
  'arrow-block-left',
  'arrow-block-right',
  'arrow-block-up',
  'arrow-circle-broken-down',
  'arrow-circle-broken-down-left',
  'arrow-circle-broken-down-right',
  'arrow-circle-broken-left',
  'arrow-circle-broken-right',
  'arrow-circle-broken-up',
  'arrow-circle-broken-up-left',
  'arrow-circle-broken-up-right',
  'arrow-circle-down',
  'arrow-circle-down-left',
  'arrow-circle-down-right',
  'arrow-circle-left',
  'arrow-circle-right',
  'arrow-circle-up',
  'arrow-circle-up-left',
  'arrow-circle-up-right',
  'arrow-down',
  'arrow-down-left',
  'arrow-down-right',
  'arrow-left',
  'arrow-narrow-down',
  'arrow-narrow-down-left',
  'arrow-narrow-down-right',
  'arrow-narrow-left',
  'arrow-narrow-right',
  'arrow-narrow-up',
  'arrow-narrow-up-left',
  'arrow-narrow-up-right',
  'arrow-right',
  'arrow-square-down',
  'arrow-square-down-left',
  'arrow-square-down-right',
  'arrow-square-left',
  'arrow-square-right',
  'arrow-square-up',
  'arrow-square-up-left',
  'arrow-square-up-right',
  'arrow-up',
  'arrow-up-left',
  'arrow-up-right',
  'arrows-down',
  'arrows-left',
  'arrows-right',
  'arrows-triangle',
  'arrows-up',
  'asterisk-01',
  'asterisk-02',
  'at-sign',
  'atom-01',
  'atom-02',
  'attachment-01',
  'attachment-02',
  'award-01',
  'award-02',
  'award-03',
  'award-04',
  'award-05',
  'backpack',
  'bank',
  'bank-note-01',
  'bank-note-02',
  'bank-note-03',
  'bar-chart-01',
  'bar-chart-02',
  'bar-chart-03',
  'bar-chart-04',
  'bar-chart-05',
  'bar-chart-06',
  'bar-chart-07',
  'bar-chart-08',
  'bar-chart-09',
  'bar-chart-10',
  'bar-chart-11',
  'bar-chart-12',
  'bar-chart-circle-01',
  'bar-chart-circle-02',
  'bar-chart-circle-03',
  'bar-chart-square-01',
  'bar-chart-square-02',
  'bar-chart-square-03',
  'bar-chart-square-down',
  'bar-chart-square-minus',
  'bar-chart-square-plus',
  'bar-chart-square-up',
  'bar-line-chart',
  'battery-charging-01',
  'battery-charging-02',
  'battery-empty',
  'battery-full',
  'battery-low',
  'battery-mid',
  'beaker-01',
  'beaker-02',
  'bell-01',
  'bell-02',
  'bell-03',
  'bell-04',
  'bell-minus',
  'bell-off-01',
  'bell-off-02',
  'bell-off-03',
  'bell-plus',
  'bell-ringing-01',
  'bell-ringing-02',
  'bell-ringing-03',
  'bell-ringing-04',
  'bezier-curve-01',
  'bezier-curve-02',
  'bezier-curve-03',
  'bluetooth-connect',
  'bluetooth-off',
  'bluetooth-on',
  'bluetooth-signal',
  'bold-01',
  'bold-02',
  'bold-square',
  'book-closed',
  'book-open-01',
  'book-open-02',
  'bookmark',
  'bookmark-add',
  'bookmark-check',
  'bookmark-minus',
  'bookmark-x',
  'box',
  'brackets',
  'brackets-check',
  'brackets-ellipses',
  'brackets-minus',
  'brackets-plus',
  'brackets-slash',
  'brackets-x',
  'briefcase-01',
  'briefcase-02',
  'browser',
  'brush-01',
  'brush-02',
  'brush-03',
  'building-01',
  'building-02',
  'building-03',
  'building-04',
  'building-05',
  'building-06',
  'building-07',
  'building-08',
  'bus',
  'calculator',
  'calendar',
  'calendar-check-01',
  'calendar-check-02',
  'calendar-date',
  'calendar-heart-01',
  'calendar-heart-02',
  'calendar-minus-01',
  'calendar-minus-02',
  'calendar-plus-01',
  'calendar-plus-02',
  'camera-01',
  'camera-02',
  'camera-03',
  'camera-lens',
  'camera-off',
  'camera-plus',
  'car-01',
  'car-02',
  'certificate-01',
  'certificate-02',
  'chart-breakout-circle',
  'chart-breakout-square',
  'check',
  'check-circle',
  'check-circle-broken',
  'check-done-01',
  'check-done-02',
  'check-heart',
  'check-square',
  'check-square-broken',
  'check-verified-01',
  'check-verified-02',
  'check-verified-03',
  'chevron-down',
  'chevron-down-double',
  'chevron-left',
  'chevron-left-double',
  'chevron-right',
  'chevron-right-double',
  'chevron-selector-horizontal',
  'chevron-selector-vertical',
  'chevron-up',
  'chevron-up-double',
  'chrome-cast',
  'circle',
  'circle-cut',
  'clapperboard',
  'clipboard',
  'clipboard-attachment',
  'clipboard-check',
  'clipboard-download',
  'clipboard-minus',
  'clipboard-plus',
  'clipboard-x',
  'clock',
  'clock-check',
  'clock-fast-forward',
  'clock-plus',
  'clock-refresh',
  'clock-rewind',
  'clock-snooze',
  'clock-stopwatch',
  'cloud-01',
  'cloud-02',
  'cloud-03',
  'cloud-blank-01',
  'cloud-blank-02',
  'cloud-lightning',
  'cloud-moon',
  'cloud-off',
  'cloud-raining-01',
  'cloud-raining-02',
  'cloud-raining-03',
  'cloud-raining-04',
  'cloud-raining-05',
  'cloud-raining-06',
  'cloud-snowing-01',
  'cloud-snowing-02',
  'cloud-sun-01',
  'cloud-sun-02',
  'cloud-sun-03',
  'code-01',
  'code-02',
  'code-browser',
  'code-circle-01',
  'code-circle-02',
  'code-circle-03',
  'code-snippet-01',
  'code-snippet-02',
  'code-square-01',
  'code-square-02',
  'codepen',
  'coins-01',
  'coins-02',
  'coins-03',
  'coins-04',
  'coins-hand',
  'coins-stacked-01',
  'coins-stacked-02',
  'coins-stacked-03',
  'coins-stacked-04',
  'coins-swap-01',
  'coins-swap-02',
  'colors',
  'columns-01',
  'columns-02',
  'columns-03',
  'command',
  'compass',
  'compass-01',
  'compass-02',
  'compass-03',
  'container',
  'contrast-01',
  'contrast-02',
  'contrast-03',
  'copy-01',
  'copy-02',
  'copy-03',
  'copy-04',
  'copy-05',
  'copy-06',
  'copy-07',
  'corner-down-left',
  'corner-down-right',
  'corner-left-down',
  'corner-left-up',
  'corner-right-down',
  'corner-right-up',
  'corner-up-left',
  'corner-up-right',
  'cpu-chip-01',
  'cpu-chip-02',
  'credit-card-01',
  'credit-card-02',
  'credit-card-check',
  'credit-card-down',
  'credit-card-download',
  'credit-card-edit',
  'credit-card-lock',
  'credit-card-minus',
  'credit-card-plus',
  'credit-card-refresh',
  'credit-card-search',
  'credit-card-shield',
  'credit-card-up',
  'credit-card-upload',
  'credit-card-x',
  'crop-01',
  'crop-02',
  'cryptocurrency-01',
  'cryptocurrency-02',
  'cryptocurrency-03',
  'cryptocurrency-04',
  'cube-01',
  'cube-02',
  'cube-03',
  'cube-04',
  'cube-outline',
  'currency-bitcoin',
  'currency-bitcoin-circle',
  'currency-dollar',
  'currency-dollar-circle',
  'currency-ethereum',
  'currency-ethereum-circle',
  'currency-euro',
  'currency-euro-circle',
  'currency-pound',
  'currency-pound-circle',
  'currency-ruble',
  'currency-ruble-circle',
  'currency-rupee',
  'currency-rupee-circle',
  'currency-yen',
  'currency-yen-circle',
  'cursor-01',
  'cursor-02',
  'cursor-03',
  'cursor-04',
  'cursor-box',
  'cursor-click-01',
  'cursor-click-02',
  'data',
  'database-01',
  'database-02',
  'database-03',
  'dataflow-01',
  'dataflow-02',
  'dataflow-03',
  'dataflow-04',
  'delete',
  'diamond-01',
  'diamond-02',
  'dice-1',
  'dice-2',
  'dice-3',
  'dice-4',
  'dice-5',
  'dice-6',
  'disc-01',
  'disc-02',
  'distribute-spacing-horizontal',
  'distribute-spacing-vertical',
  'divide-01',
  'divide-02',
  'divide-03',
  'divider',
  'dotpoints-01',
  'dotpoints-02',
  'dots-grid',
  'dots-horizontal',
  'dots-vertical',
  'download-01',
  'download-02',
  'download-03',
  'download-04',
  'download-cloud-01',
  'download-cloud-02',
  'drop',
  'droplets-01',
  'droplets-02',
  'droplets-03',
  'dropper',
  'edit-01',
  'edit-02',
  'edit-03',
  'edit-04',
  'edit-05',
  'equal',
  'equal-not',
  'eraser',
  'expand-01',
  'expand-02',
  'expand-03',
  'expand-04',
  'expand-05',
  'expand-06',
  'eye',
  'eye-off',
  'face-content',
  'face-frown',
  'face-happy',
  'face-id',
  'face-id-square',
  'face-neutral',
  'face-sad',
  'face-smile',
  'face-wink',
  'fast-backward',
  'fast-forward',
  'feather',
  'figma',
  'file-01',
  'file-02',
  'file-03',
  'file-04',
  'file-05',
  'file-06',
  'file-07',
  'file-attachment-01',
  'file-attachment-02',
  'file-attachment-03',
  'file-attachment-04',
  'file-attachment-05',
  'file-check-01',
  'file-check-02',
  'file-check-03',
  'file-code-01',
  'file-code-02',
  'file-download-01',
  'file-download-02',
  'file-download-03',
  'file-heart-01',
  'file-heart-02',
  'file-heart-03',
  'file-lock-01',
  'file-lock-02',
  'file-lock-03',
  'file-minus-01',
  'file-minus-02',
  'file-minus-03',
  'file-plus-01',
  'file-plus-02',
  'file-plus-03',
  'file-question-01',
  'file-question-02',
  'file-question-03',
  'file-search-01',
  'file-search-02',
  'file-search-03',
  'file-shield-01',
  'file-shield-02',
  'file-shield-03',
  'file-x-01',
  'file-x-02',
  'file-x-03',
  'film-01',
  'film-02',
  'film-03',
  'filter-funnel-01',
  'filter-funnel-02',
  'filter-lines',
  'fingerprint-01',
  'fingerprint-02',
  'fingerprint-03',
  'fingerprint-04',
  'flag-01',
  'flag-02',
  'flag-03',
  'flag-04',
  'flag-05',
  'flag-06',
  'flash',
  'flash-off',
  'flex-align-bottom',
  'flex-align-left',
  'flex-align-right',
  'flex-align-top',
  'flip-backward',
  'flip-forward',
  'folder',
  'folder-check',
  'folder-closed',
  'folder-code',
  'folder-download',
  'folder-lock',
  'folder-minus',
  'folder-plus',
  'folder-question',
  'folder-search',
  'folder-shield',
  'folder-x',
  'framer',
  'gaming-pad-01',
  'gaming-pad-02',
  'gift-01',
  'gift-02',
  'git-branch-01',
  'git-branch-02',
  'git-commit',
  'git-merge',
  'git-pull-request',
  'glasses-01',
  'glasses-02',
  'globe-01',
  'globe-02',
  'google-chrome',
  'graduation-hat-01',
  'graduation-hat-02',
  'grid-01',
  'grid-02',
  'grid-03',
  'grid-dots-blank',
  'grid-dots-bottom',
  'grid-dots-horizontal-center',
  'grid-dots-left',
  'grid-dots-outer',
  'grid-dots-right',
  'grid-dots-top',
  'grid-dots-vertical-center',
  'hand',
  'hard-drive',
  'hash-01',
  'hash-02',
  'heading-01',
  'heading-02',
  'heading-square',
  'headphones-01',
  'headphones-02',
  'heart',
  'heart-circle',
  'heart-hand',
  'heart-hexagon',
  'heart-octagon',
  'heart-rounded',
  'heart-square',
  'hearts',
  'help-circle',
  'help-octagon',
  'help-octagon-1',
  'help-square',
  'hexagon-01',
  'hexagon-02',
  'home-01',
  'home-02',
  'home-03',
  'home-04',
  'home-05',
  'home-line',
  'home-smile',
  'horizontal-bar-chart-01',
  'horizontal-bar-chart-02',
  'horizontal-bar-chart-03',
  'hourglass-01',
  'hourglass-02',
  'hourglass-03',
  'hurricane-01',
  'hurricane-02',
  'hurricane-03',
  'image-01',
  'image-02',
  'image-03',
  'image-04',
  'image-05',
  'image-check',
  'image-down',
  'image-indent-left',
  'image-indent-right',
  'image-left',
  'image-plus',
  'image-right',
  'image-up',
  'image-user',
  'image-user-check',
  'image-user-down',
  'image-user-left',
  'image-user-plus',
  'image-user-right',
  'image-user-up',
  'image-user-x',
  'image-x',
  'inbox-01',
  'inbox-02',
  'infinity',
  'info-circle',
  'info-hexagon',
  'info-octagon',
  'info-square',
  'intersect-circle',
  'intersect-square',
  'italic-01',
  'italic-02',
  'italic-square',
  'key-01',
  'key-02',
  'keyboard-01',
  'keyboard-02',
  'laptop-01',
  'laptop-02',
  'layer-single',
  'layers-three-01',
  'layers-three-02',
  'layers-two-01',
  'layers-two-02',
  'layout-alt-01',
  'layout-alt-02',
  'layout-alt-03',
  'layout-alt-04',
  'layout-bottom',
  'layout-grid-01',
  'layout-grid-02',
  'layout-left',
  'layout-right',
  'layout-top',
  'left-indent-01',
  'left-indent-02',
  'letter-spacing-01',
  'letter-spacing-02',
  'life-buoy-01',
  'life-buoy-02',
  'lightbulb-01',
  'lightbulb-02',
  'lightbulb-03',
  'lightbulb-04',
  'lightbulb-05',
  'lightning-01',
  'lightning-02',
  'line-chart-down-01',
  'line-chart-down-02',
  'line-chart-down-03',
  'line-chart-down-04',
  'line-chart-down-05',
  'line-chart-up-01',
  'line-chart-up-02',
  'line-chart-up-03',
  'line-chart-up-04',
  'line-chart-up-05',
  'line-height',
  'link-01',
  'link-02',
  'link-03',
  'link-04',
  'link-05',
  'link-broken-01',
  'link-broken-02',
  'link-external-01',
  'link-external-02',
  'list',
  'loading-01',
  'loading-02',
  'loading-03',
  'lock-01',
  'lock-02',
  'lock-03',
  'lock-04',
  'lock-keyhole-circle',
  'lock-keyhole-square',
  'lock-unlocked-01',
  'lock-unlocked-02',
  'lock-unlocked-03',
  'lock-unlocked-04',
  'log-in-01',
  'log-in-02',
  'log-in-03',
  'log-in-04',
  'log-out-01',
  'log-out-02',
  'log-out-03',
  'log-out-04',
  'luggage-01',
  'luggage-02',
  'luggage-03',
  'magic-wand-01',
  'magic-wand-02',
  'mail-01',
  'mail-02',
  'mail-03',
  'mail-04',
  'mail-05',
  'map-01',
  'map-02',
  'mark',
  'marker-pin-01',
  'marker-pin-02',
  'marker-pin-03',
  'marker-pin-04',
  'marker-pin-05',
  'marker-pin-06',
  'maximize-01',
  'maximize-02',
  'medical-circle',
  'medical-cross',
  'medical-square',
  'menu-01',
  'menu-02',
  'menu-03',
  'menu-04',
  'menu-05',
  'message-alert-circle',
  'message-alert-square',
  'message-chat-circle',
  'message-chat-square',
  'message-check-circle',
  'message-check-square',
  'message-circle-01',
  'message-circle-02',
  'message-dots-circle',
  'message-dots-square',
  'message-heart-circle',
  'message-heart-square',
  'message-notification-circle',
  'message-notification-square',
  'message-plus-circle',
  'message-plus-square',
  'message-question-circle',
  'message-question-square',
  'message-smile-circle',
  'message-smile-square',
  'message-square-01',
  'message-square-02',
  'message-text-circle-01',
  'message-text-circle-02',
  'message-text-square-01',
  'message-text-square-02',
  'message-x-circle',
  'message-x-square',
  'microphone-01',
  'microphone-02',
  'microphone-off-01',
  'microphone-off-02',
  'microscope',
  'minimize-01',
  'minimize-02',
  'minus',
  'minus-circle',
  'minus-square',
  'modem-01',
  'modem-02',
  'monitor-01',
  'monitor-02',
  'monitor-03',
  'monitor-04',
  'monitor-05',
  'moon-01',
  'moon-02',
  'moon-eclipse',
  'moon-star',
  'mouse',
  'move',
  'music-note-01',
  'music-note-02',
  'music-note-plus',
  'navigation-pointer-01',
  'navigation-pointer-02',
  'navigation-pointer-off-01',
  'navigation-pointer-off-02',
  'notification-box',
  'notification-message',
  'notification-text',
  'octagon',
  'package',
  'package-check',
  'package-minus',
  'package-plus',
  'package-search',
  'package-x',
  'paint',
  'paint-pour',
  'palette',
  'paperclip',
  'paragraph-spacing',
  'paragraph-wrap',
  'passcode',
  'passcode-lock',
  'passport',
  'pause-circle',
  'pause-square',
  'pen-tool-01',
  'pen-tool-02',
  'pen-tool-minus',
  'pen-tool-plus',
  'pencil-01',
  'pencil-02',
  'pencil-line',
  'pentagon',
  'percent-01',
  'percent-02',
  'percent-03',
  'perspective-01',
  'perspective-02',
  'phone',
  'phone-01',
  'phone-02',
  'phone-call-01',
  'phone-call-02',
  'phone-hang-up',
  'phone-incoming-01',
  'phone-incoming-02',
  'phone-outgoing-01',
  'phone-outgoing-02',
  'phone-pause',
  'phone-plus',
  'phone-x',
  'pie-chart-01',
  'pie-chart-02',
  'pie-chart-03',
  'pie-chart-04',
  'piggy-bank-01',
  'piggy-bank-02',
  'pilcrow-01',
  'pilcrow-02',
  'pilcrow-square',
  'pin-01',
  'pin-02',
  'placeholder',
  'plane',
  'play',
  'play-circle',
  'play-square',
  'plus',
  'plus-circle',
  'plus-square',
  'podcast',
  'power-01',
  'power-02',
  'power-03',
  'presentation-chart-01',
  'presentation-chart-02',
  'presentation-chart-03',
  'printer',
  'puzzle-piece-01',
  'puzzle-piece-02',
  'qr-code-01',
  'qr-code-02',
  'receipt',
  'receipt-check',
  'recording-01',
  'recording-02',
  'recording-03',
  'reflect-01',
  'reflect-02',
  'refresh-ccw-01',
  'refresh-ccw-02',
  'refresh-ccw-03',
  'refresh-ccw-04',
  'refresh-ccw-05',
  'refresh-cw-01',
  'refresh-cw-02',
  'refresh-cw-03',
  'refresh-cw-04',
  'refresh-cw-05',
  'repeat-01',
  'repeat-02',
  'repeat-03',
  'repeat-04',
  'reverse-left',
  'reverse-right',
  'right-indent-01',
  'right-indent-02',
  'rocket-01',
  'rocket-02',
  'roller-brush',
  'route',
  'rows-01',
  'rows-02',
  'rows-03',
  'rss-01',
  'rss-02',
  'ruler',
  'safe',
  'sale-01',
  'sale-02',
  'sale-03',
  'sale-04',
  'save-01',
  'save-02',
  'save-03',
  'scale-01',
  'scale-02',
  'scale-03',
  'scales-01',
  'scales-02',
  'scan',
  'scissors-01',
  'scissors-02',
  'scissors-cut-01',
  'scissors-cut-02',
  'search-lg',
  'search-md',
  'search-refraction',
  'search-sm',
  'send-01',
  'send-02',
  'send-03',
  'server-01',
  'server-02',
  'server-03',
  'server-04',
  'server-05',
  'server-06',
  'settings-01',
  'settings-02',
  'settings-03',
  'settings-04',
  'share-01',
  'share-02',
  'share-03',
  'share-04',
  'share-05',
  'share-06',
  'share-07',
  'shield-01',
  'shield-02',
  'shield-03',
  'shield-dollar',
  'shield-off',
  'shield-plus',
  'shield-tick',
  'shield-zap',
  'shopping-bag-01',
  'shopping-bag-02',
  'shopping-bag-03',
  'shopping-cart-01',
  'shopping-cart-02',
  'shopping-cart-03',
  'shuffle-01',
  'shuffle-02',
  'signal-01',
  'signal-02',
  'signal-03',
  'simcard',
  'skew',
  'skip-back',
  'skip-forward',
  'slash-circle-01',
  'slash-circle-02',
  'slash-divider',
  'slash-octagon',
  'sliders-01',
  'sliders-02',
  'sliders-03',
  'sliders-04',
  'snowflake-01',
  'snowflake-02',
  'spacing-height-01',
  'spacing-height-02',
  'spacing-width-01',
  'spacing-width-02',
  'speaker-01',
  'speaker-02',
  'speaker-03',
  'speedometer-01',
  'speedometer-02',
  'speedometer-03',
  'speedometer-04',
  'square',
  'stand',
  'star-01',
  'star-02',
  'star-03',
  'star-04',
  'star-05',
  'star-06',
  'star-07',
  'stars-01',
  'stars-02',
  'stars-03',
  'sticker-circle',
  'sticker-square',
  'stop',
  'stop-circle',
  'stop-square',
  'strikethrough-01',
  'strikethrough-02',
  'strikethrough-square',
  'subscript',
  'sun',
  'sun-setting-01',
  'sun-setting-02',
  'sun-setting-03',
  'sunrise',
  'sunset',
  'switch-horizontal-01',
  'switch-horizontal-02',
  'switch-vertical-01',
  'switch-vertical-02',
  'table',
  'tablet-01',
  'tablet-02',
  'tag-01',
  'tag-02',
  'tag-03',
  'target-01',
  'target-02',
  'target-03',
  'target-04',
  'target-05',
  'telescope',
  'terminal',
  'terminal-browser',
  'terminal-circle',
  'terminal-square',
  'text-input',
  'thermometer-01',
  'thermometer-02',
  'thermometer-03',
  'thermometer-cold',
  'thermometer-warm',
  'thumbs-down',
  'thumbs-up',
  'ticket-01',
  'ticket-02',
  'toggle-01-left',
  'toggle-01-right',
  'toggle-02-left',
  'toggle-02-right',
  'toggle-03-left',
  'toggle-03-right',
  'tool-01',
  'tool-02',
  'train',
  'tram',
  'transform',
  'translate-01',
  'translate-02',
  'trash-01',
  'trash-02',
  'trash-03',
  'trash-04',
  'trend-down-01',
  'trend-down-02',
  'trend-up-01',
  'trend-up-02',
  'triangle',
  'trophy-01',
  'trophy-02',
  'truck-01',
  'truck-02',
  'tv-01',
  'tv-02',
  'tv-03',
  'type-01',
  'type-02',
  'type-square',
  'type-strikethrough-01',
  'type-strikethrough-02',
  'umbrella-01',
  'umbrella-02',
  'umbrella-03',
  'underline-01',
  'underline-02',
  'underline-square',
  'upload-01',
  'upload-02',
  'upload-03',
  'upload-04',
  'upload-cloud-01',
  'upload-cloud-02',
  'usb-flash-drive',
  'user-01',
  'user-02',
  'user-03',
  'user-check-01',
  'user-check-02',
  'user-circle',
  'user-down-01',
  'user-down-02',
  'user-edit',
  'user-left-01',
  'user-left-02',
  'user-minus-01',
  'user-minus-02',
  'user-plus-01',
  'user-plus-02',
  'user-right-01',
  'user-right-02',
  'user-square',
  'user-up-01',
  'user-up-02',
  'user-x-01',
  'user-x-02',
  'users-01',
  'users-02',
  'users-03',
  'users-check',
  'users-down',
  'users-edit',
  'users-left',
  'users-minus',
  'users-plus',
  'users-right',
  'users-up',
  'users-x',
  'variable',
  'video-recorder',
  'video-recorder-off',
  'virus',
  'voicemail',
  'volume-max',
  'volume-min',
  'volume-minus',
  'volume-plus',
  'volume-x',
  'wallet-01',
  'wallet-02',
  'wallet-03',
  'wallet-04',
  'wallet-05',
  'watch-circle',
  'watch-square',
  'waves',
  'webcam-01',
  'webcam-02',
  'wifi',
  'wifi-off',
  'wind-01',
  'wind-02',
  'wind-03',
  'world-01',
  'world-02',
  'world-03',
  'world-04',
  'world-05',
  'world-06',
  'x',
  'x-circle',
  'x-close',
  'x-square',
  'youtube',
  'zap',
  'zap-circle',
  'zap-fast',
  'zap-off',
  'zap-square',
  'zoom-in',
  'zoom-out',
] as const;

export type IconName = (typeof IconNames)[number];
