import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Claim } from '@wsphere/warranties/domain';
import { ClaimItemResolver } from '../resolver';
import { ClaimService } from '../services';

@Injectable()
export class ClaimItemManageGuard implements CanActivate {
  constructor(private resolver: ClaimItemResolver, private claimSvc: ClaimService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    this.resolver.item = null;

    const claimId = this.findClaimId(route);
    if (!claimId) return false;

    const itemId = this.findItemId(route);
    if (!itemId) return false;

    const isCustomerPortal = this.findIsCustomerPortal(route);

    const claim = await this.claimSvc.get(claimId);

    this.resolver.item = await this.claimSvc.getItem(claimId, itemId);

    if (claim?.status !== Claim.Status.OPEN) {
      const parent = this.parentPage(state.url);
      this.router.navigate([`${parent}`]);
      return false;
    }

    if (isCustomerPortal && this.resolver.item?.status !== Claim.ClaimItem.Status.DRAFT) {
      const parent = this.parentPage(state.url);
      this.router.navigate([`${parent}`]);
      return false;
    }

    if (!this.resolver.item) return false;

    return true;
  }

  parentPage(url: string) {
    let to = url.lastIndexOf('/');
    to = to == -1 ? url.length : to + 1;
    return url.substring(0, to);
  }

  private findClaimId(route: ActivatedRouteSnapshot): string {
    return route.params['claimId'] || (route.parent ? this.findClaimId(route.parent) : null);
  }

  private findItemId(route: ActivatedRouteSnapshot): string {
    return route.params['itemId'] || (route.parent ? this.findItemId(route.parent) : null);
  }

  private findIsCustomerPortal(route: ActivatedRouteSnapshot): string {
    return route.data['isCustomerPortal'] || (route.parent ? this.findItemId(route.parent) : null);
  }
}
