<ng-container *ngIf="document$ | async as document">
  <ng-container *vsLet="fileType$ | async as fileType">
    <ng-container *ngIf="fileType !== null; else notSupported">
      <div class="flex justify-center" [class]="fileType === 'pdf' && isSafari ? 'h-fit' : 'h-[55vh]'">
        <img
          *ngIf="fileType === 'image'"
          class="rounded-xl min-h-[42vh] overflow-hidden h-[100%]"
          [src]="document.downloadUrl"
        />
        <ng-container *ngIf="fileType === 'pdf'">
          <object
            *ngIf="downloadUrl$ | async as downloadUrl"
            #obj
            style="width: calc(100% + 18px)"
            class="w-full object-contain rounded-lg h-[calc(50vh-56px)] max-h-full -ml-px -mt-px"
            [type]="document.mimeType"
            [data]="downloadUrl + '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0' | safe : 'resourceUrl'"
          ></object>
        </ng-container>
      </div>
    </ng-container>

    <ng-template #notSupported>
      <div #empty class="h-[calc(50vh-56px)] flex flex-col items-center justify-center">
        <div class="max-w-sm text-center flex flex-col items-center justify-center">
          <svg width="220" height="160" viewBox="0 0 220 160" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="110" cy="80" r="80" fill="#E2E8F0" />
            <circle cx="26" cy="20" r="8" fill="#F1F5F9" />
            <circle cx="198" cy="126" r="6" fill="#F1F5F9" />
            <circle cx="25" cy="138" r="10" fill="#F1F5F9" />
            <circle cx="210" cy="46" r="10" fill="#F1F5F9" />
            <circle cx="191" cy="11" r="7" fill="#F1F5F9" />
            <g filter="url(#filter0_dd_1182_2409)">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M113.486 16C96.7495 16 81.9448 24.2701 72.9354 36.9466C69.9934 36.2528 66.9253 35.8857 63.7714 35.8857C41.8063 35.8857 24 53.692 24 75.6571C24 97.6223 41.8063 115.429 63.7714 115.429H163.2C182.42 115.429 198 99.8481 198 80.6286C198 61.4091 182.42 45.8286 163.2 45.8286C161.835 45.8286 160.488 45.9072 159.164 46.0601C151.546 28.3784 133.961 16 113.486 16Z"
                fill="#F8FAFC"
              />
              <circle cx="63.7714" cy="75.6572" r="39.7714" fill="url(#paint0_linear_1182_2409)" />
              <circle cx="113.486" cy="65.7143" r="49.7143" fill="url(#paint1_linear_1182_2409)" />
              <circle cx="163.2" cy="80.6286" r="34.8" fill="url(#paint2_linear_1182_2409)" />
            </g>
            <g filter="url(#filter1_b_1182_2409)">
              <rect x="82" y="84" width="56" height="56" rx="28" fill="#344054" fill-opacity="0.4" />
              <path
                d="M120.5 122.5L116.417 118.417M119.333 111.417C119.333 116.893 114.893 121.333 109.417 121.333C103.94 121.333 99.5 116.893 99.5 111.417C99.5 105.94 103.94 101.5 109.417 101.5C114.893 101.5 119.333 105.94 119.333 111.417Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_dd_1182_2409"
                x="4"
                y="16"
                width="214"
                height="139.429"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1182_2409" />
                <feOffset dy="8" />
                <feGaussianBlur stdDeviation="4" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
                />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1182_2409" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feMorphology radius="4" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1182_2409" />
                <feOffset dy="20" />
                <feGaussianBlur stdDeviation="12" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
                />
                <feBlend mode="normal" in2="effect1_dropShadow_1182_2409" result="effect2_dropShadow_1182_2409" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1182_2409" result="shape" />
              </filter>
              <filter
                id="filter1_b_1182_2409"
                x="74"
                y="76"
                width="72"
                height="72"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1182_2409" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1182_2409" result="shape" />
              </filter>
              <linearGradient
                id="paint0_linear_1182_2409"
                x1="33.2326"
                y1="49.3796"
                x2="103.543"
                y2="115.429"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#D0D5DD" />
                <stop offset="0.350715" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_1182_2409"
                x1="75.3123"
                y1="32.8673"
                x2="163.2"
                y2="115.428"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#D0D5DD" />
                <stop offset="0.350715" stop-color="white" stop-opacity="0" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_1182_2409"
                x1="136.478"
                y1="57.6358"
                x2="198"
                y2="115.429"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#D0D5DD" />
                <stop offset="0.350715" stop-color="white" stop-opacity="0" />
              </linearGradient>
            </defs>
          </svg>

          <div class="mt-6 text-xl font-semibold text-gray-900">No document preview</div>
          <div class="text-gray-600">
            No preview is available for '{{ document.mimeType }}' documents. Please download the document to view it.
          </div>

          <a
            *ngIf="document.downloadUrl"
            vs-button
            class="mt-6"
            appearance="outline"
            [href]="document.downloadUrl"
            [download]="filename$ | async"
          >
            <vs-icon size="20" name="download-01"></vs-icon>
            Download
          </a>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
