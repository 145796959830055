import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';
import { BreadcrumbsItemConfig } from '../breadcrumbs-item/breadcrumbs-item.component';

export const BREADCRUMBS_INJECTOR = 'BREADCRUMBS_INJECTOR';

export interface BreadcrumbsConfig {
  separatorIcon?: IconName;
  items?: BreadcrumbsItemConfig[];
}

@Component({
  selector: 'vs-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: BREADCRUMBS_INJECTOR, useExisting: BreadcrumbsComponent }],
})
export class BreadcrumbsComponent {
  @Input() config?: BreadcrumbsConfig;

  @HostBinding('class') private get _class() {
    return `block`;
  }
}
