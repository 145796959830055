import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import { TenantService } from '@vsolv/core/multi-tenant/web';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, combineLatest, filter, map, switchMap } from 'rxjs';
import { CopySftpKeyDialog, RerollSFTPKeyDialog } from '../../dialogs';
import { SftpService } from '../../services';

@Component({
  selector: 'ws-sftp-key-management',
  templateUrl: './sftp-key-management.component.html',
})
export class SftpKeyManagementComponent {
  constructor(
    @Inject(SftpService) private sftpSvc: SftpService,
    private toastSvc: ToastService,
    private clipboard: Clipboard,
    private securitySvc: SecurityService,
    private tenantSvc: TenantService
  ) {}

  @ViewChild(CopySftpKeyDialog) copyKeyDialog!: CopySftpKeyDialog;
  @ViewChild(RerollSFTPKeyDialog) rerollKeyDialog!: RerollSFTPKeyDialog;

  @PropertyListener('distributor') distributor$ = new BehaviorSubject<Distributor.Model | null>(null);
  @Input() distributor: Distributor.Model | null = null;

  @PropertyListener('checkStaffPermissions') checkStaffPermissions$ = new BehaviorSubject<boolean>(false);
  @Input() checkStaffPermissions = false;

  creating = false;

  refresh$ = new BehaviorSubject(null);

  id$ = this.distributor$.pipe(
    switchMap(async dist => (dist ? dist.id : await this.tenantSvc.getTenantIdFromDomain()))
  );

  SftpCredentials$ = combineLatest([this.id$, this.refresh$]).pipe(
    map(([id, _refresh]) => id),
    filter((id): id is string => !!id),
    switchMap(async id => await this.sftpSvc.getCredentials(id))
  );

  canViewCredentials$ = combineLatest([this.checkStaffPermissions$, this.distributor$]).pipe(
    switchMap(async ([checkStaff, dist]) =>
      !checkStaff
        ? await this.securitySvc.hasAccess('dist_ViewDistributor', dist ? [dist.permissionKey] : null)
        : await this.securitySvc.hasAccess('stf_ViewApps', dist ? [dist.permissionKey] : null)
    )
  );

  canEditCredentials$ = combineLatest([this.checkStaffPermissions$, this.distributor$]).pipe(
    switchMap(async ([checkStaff, distributor]) => {
      if (distributor && distributor.status !== Distributor.Status.ACTIVE) return false;
      if (!checkStaff)
        return await this.securitySvc.hasAccess('dist_ResetSFTP', distributor ? [distributor.permissionKey] : null);
      return await this.securitySvc.hasAccess('sft_ManageSFTPApp', distributor ? [distributor.permissionKey] : null);
    })
  );

  copy(stringToCopy: string, stringName: 'host' | 'username' | 'port') {
    if (this.clipboard.copy(stringToCopy)) {
      this.toastSvc.show({
        type: 'success',
        title: `Copied ${stringName}!`,
      });
    } else this.toastSvc.show({ type: 'error', title: `Error copying ${stringName}`, text: 'Please try again' });
  }

  reroll() {
    this.rerollKeyDialog.dialog.open();
  }

  refresh() {
    this.refresh$.next(null);
  }

  async createUser() {
    this.creating = true;
    let apiKey = null;
    const id = this.distributor ? this.distributor.id : await this.tenantSvc.getTenantIdFromDomain();
    if (id) apiKey = await this.sftpSvc.createCredentials(id);
    this.creating = false;
    if (apiKey) this.copyKeyDialog.openDialog(apiKey.key);
  }
}
