import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Staff } from '..';
import { PermissionId } from '../../../_registrations';

export interface RoleDisplay {
  title: string;
  description?: string;
  theme?: ThemeColor;
}

export interface RoleProps {
  id: string;
  group: string;
  permissions: PermissionId[];
  display: RoleDisplay;
  hideRole?: boolean;
}

export class Role {
  readonly id: string;
  readonly group: string;
  readonly permissions: PermissionId[];
  readonly display: RoleDisplay;
  readonly hideRole?: boolean;

  constructor(props: RoleProps) {
    this.id = props.id;
    this.group = props.group;
    this.permissions = props.permissions;

    this.display = props.display;
    this.hideRole = props.hideRole;

    registerRole(this);
  }
}

const roles = new Map<string, Role>();

function registerRole(role: Role) {
  if (roles.has(role.id)) {
    throw new Error(role.id + ' is already registered');
  }

  roles.set(role.id, role);
  return role;
}

export function getAllRoles() {
  return Array.from(roles.values());
}

export function getRole(id: string) {
  return roles.get(id);
}

export function getRolesWithPermission(permissionId: PermissionId) {
  const applicableRoles: Role[] = [];

  roles.forEach(role => {
    if (role.permissions.includes(permissionId) || role.permissions.includes('*')) {
      applicableRoles.push(role);
    }
  });

  return applicableRoles;
}

export function getRolesWithPermissions(permissions: PermissionId[]) {
  const roles = new Set<Role>();
  permissions.forEach(permission => getRolesWithPermission(permission).forEach(role => (role ? roles.add(role) : {})));
  return Array.from(roles.values());
}

export function getPermissionsFromRoles(roleIds: string[]) {
  const permissions = new Set<Staff.Security.Permission>();

  roleIds.forEach(id => {
    const role = getRole(id);
    if (role) {
      Staff.Security.getPermissions(role.permissions).forEach(permission => permissions.add(permission));
    }
  });

  return Array.from(permissions.values());
}
