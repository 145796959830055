import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { IconName } from '@vsolv/vectors-ui/icons';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';
import { PolicyService } from '../../services';

export interface PolicyDetails {
  title: string;
  friendlyTitle: string;

  tagline: string;
  description?: string;
  prefix: string;
  icon: IconName;

  policyNumber: string;
  claimWaitingPeriod: number;
  requiresWarrantyActivation: boolean;
}

@Component({
  selector: 'ws-policy-details',
  templateUrl: './policy-details.component.html',
})
export class PolicyDetailsComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder
  ) {}

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('editing') editing$ = new BehaviorSubject<boolean>(false);
  @Input() editing = false;

  @Input() value: PolicyDetails | null = null;

  @Output() closed = new EventEmitter();
  @Output() pageCompleted = new EventEmitter<PolicyDetails>();

  saving = false;

  draft = Policy.Status.DRAFT;

  form = this.formBuilder.group({
    title: ['', [Validators.required]],
    friendlyTitle: ['', [Validators.maxLength(50)]],

    tagline: ['', [Validators.required, Validators.maxLength(100)]],
    description: ['', []],
    prefix: ['', [Validators.required, Validators.pattern('[a-zA-Z][a-zA-Z ]*')]],
    icon: ['file-shield-02' as string | null, Validators.required],

    policyNumber: ['', [Validators.required]],
    claimWaitingPeriod: [0, [Validators.min(0)]],
    requiresWarrantyActivation: [false as boolean],
  });

  patchValue$ = combineLatest([this.policy$, this.editing$]).pipe(
    tap(([policy, editing]) => {
      if (!editing) {
        if (!this.value) {
          const year = new Date().getFullYear().toString();
          const random = Math.random().toString().slice(-4);

          this.form.patchValue({ policyNumber: year + random });
        } else {
          this.form.patchValue(this.value);
          this.form.markAsDirty();
        }
      } else {
        if (!policy) return;

        const policyNumber = policy.policyNumber.split('-');
        this.form.patchValue({
          title: policy.title,
          friendlyTitle: policy?.friendlyTitle,

          tagline: policy.tagline,
          description: policy.description,
          prefix: policyNumber[0],
          icon: policy.icon ?? 'file-shield-02',

          policyNumber: policyNumber[1],
          claimWaitingPeriod: policy.claimWaitingPeriod,
          requiresWarrantyActivation: policy.requiresWarrantyActivation ?? false,
        });
      }
    })
  );

  close() {
    this.policy = null;
    this.editing = false;

    this.form.reset();
    this.closed.emit();
  }

  pageComplete() {
    this.pageCompleted.emit({
      title: this.form.value.title || '',
      friendlyTitle: this.form.value.friendlyTitle || '',

      tagline: this.form.value.tagline || '',
      description: this.form.value.description || '',
      prefix: this.form.value.prefix || '',
      icon: (this.form.value.icon as IconName) || 'file-shield-02',

      policyNumber: this.form.value.policyNumber || '',
      claimWaitingPeriod: this.form.value.claimWaitingPeriod || 0,
      requiresWarrantyActivation: !!this.form.value.requiresWarrantyActivation,
    });
  }

  async save() {
    if (!this.policy) return;

    this.saving = true;
    const value = this.form.value;

    const policy = await this.policySvc
      .update(this.policy.id, {
        canCreateNewVersion: true,

        title: value.title || '',
        friendlyTitle: value.friendlyTitle || undefined,

        tagline: value.tagline || '',
        description: value.description || '',
        icon: value.icon || 'file-shield-02',

        claimWaitingPeriod: value.claimWaitingPeriod || 0,
        requiresWarrantyActivation: value.requiresWarrantyActivation ?? false,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.closed.emit();
        this.saving = false;
      });

    if (policy) {
      if (policy.id === this.policy.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Updated policy',
          text: '<strong>' + value.title + '</strong> has been successfully updated.',
        });

        this.policySvc.refreshPolicy();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text: 'An updated version of <strong>' + value.title + '</strong> has been successfully created.',
        });

        this.policySvc.refreshPolicy(policy.id);
        this.policySvc.clearPlan();

        this.navigateTo('../' + policy.id);
      }

      this.saving = false;
      this.closed.emit();
    }
  }

  navigateTo(path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
