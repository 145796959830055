<vs-dialog [config]="{ dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      hideCloseButton: true,

      icon: 'alert-circle',
      iconTheme: 'primary',
      
      title: 'Warning!',
      subtitle:'You have selected the role of Full Administrator. This role will grant the staff member all permissions across ' + _distributorName +'.'
    }"
  ></vs-dialog-header>

  <div class="flex flex-col-reverse sm:flex-row sm:justify-end gap-3 pt-4">
    <button vs-button appearance="outline" class="w-full sm:w-auto" (click)="close()">Close</button>
  </div>
</vs-dialog>
