import { Activity } from '@vsolv/packages/activities/domain';
import { Documents } from '@vsolv/packages/documents/domain';
import { Note } from '@vsolv/packages/notes/domain';
import { StatusChange } from '@vsolv/packages/status-change/domain';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { Asset } from '@wsphere/assets/domain';
import { Customer } from '@wsphere/customers/domain';
import { Distributor } from '@wsphere/distributors/domain';
import { Claim, Policy, Warranty } from '@wsphere/warranties/domain';

export enum ObjectType {
  DISTRIBUTOR = 'distributor',
  POLICY = 'policy',
  PLAN = 'plan',
  CUSTOMER = 'customer',
  WARRANTY = 'warranty',
  ASSET = 'asset',
  CLAIM = 'claim',
  CLAIM_ITEM = 'claimItem',
  ACTIVITY = 'activity',
  STATUS_CHANGE = 'statusChange',
  NOTE = 'note',
  DOCUMENT = 'document',
  PAYMENT = 'payment',
  STORAGE_ITEM = 'storageItem',
}
export interface Model {
  id: string;

  distributorId_1: string | null;
  distributor_1?: Distributor.Model | null;

  distributorId_2: string | null;
  distributor_2?: Distributor.Model | null;

  policyId_1: string | null;
  policy_1?: Policy.Model | null;

  policyId_2: string | null;
  policy_2?: Policy.Model | null;

  planId_1: string | null;
  plan_1?: Policy.Plan.Model | null;

  planId_2: string | null;
  plan_2?: Policy.Plan.Model | null;

  customerId_1: string | null;
  customer_1?: Customer.Model | null;

  customerId_2: string | null;
  customer_2?: Customer.Model | null;

  warrantyId_1: string | null;
  warranty_1?: Warranty.Model | null;

  warrantyId_2: string | null;
  warranty_2?: Warranty.Model | null;

  assetId_1: string | null;
  asset_1?: Asset.Model | null;

  assetId_2: string | null;
  asset_2?: Asset.Model | null;

  claimId_1: string | null;
  claim_1?: Claim.Model | null;

  claimId_2: string | null;
  claim_2?: Claim.Model | null;

  claimItemId_1: string | null;
  claimItem_1?: Claim.ClaimItem.Model | null;

  claimItemId_2: string | null;
  claimItem_2?: Claim.ClaimItem.Model | null;

  statusChangeId_1: string | null;
  statusChange_1?: StatusChange.Model | null;

  statusChangeId_2: string | null;
  statusChange_2?: StatusChange.Model | null;

  activityId_1: string | null;
  activity_1?: Activity.Model | null;

  activityId_2: string | null;
  activity_2?: Activity.Model | null;

  noteId_1: string | null;
  note_1?: Note.Model | null;

  noteId_2: string | null;
  note_2?: Note.Model | null;

  documentId_1: string | null;
  document_1?: Documents.Model | null;

  documentId_2: string | null;
  document_2?: Documents.Model | null;

  paymentId_1: string | null;
  payment_1?: Claim.ClaimPayment.Model | null;

  paymentId_2: string | null;
  payment_2?: Claim.ClaimPayment.Model | null;

  storageItemId_1: string | null;
  storageItem_1?: StorageItem.Model | null;

  storageItemId_2: string | null;
  storageItem_2?: StorageItem.Model | null;
}
