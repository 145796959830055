<button
  #dropdownButton
  cdkOverlayOrigin
  #trigger="cdkOverlayOrigin"
  (click)="_open = !overlay.open"
  [disabled]="config?.disabled ?? false"
  class="w-full h-10 border border-gray-300 font-medium px-3 py-2 text-gray-900 flex items-center gap-2 rounded-md bg-base shadow-sm hover:border-themed active:ring-4 focus:outline-none focus:ring-4 ring-themed-100"
>
  <ng-content select="[slot='selected']"></ng-content>
  <vs-icon name="chevron-down" size="20" class="ml-auto mr-1 text-gray"></vs-icon>
</button>

<ng-template
  #overlay="cdkConnectedOverlay"
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="_open"
  (overlayOutsideClick)="$event.target !== dropdownButton ? (_open = false) : ''"
>
  <div
    class="mt-2 m-[1px] py-1 flex flex-col bg-base rounded-md shadow"
    [style.width]="dropdownButton.clientWidth + 'px'"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
