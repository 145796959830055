import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { customPasswordStrengthValidator } from '@vsolv/packages/firebase/web';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { StaffService } from '@wsphere/staff/web';
import { firstValueFrom, map } from 'rxjs';

@Component({
  selector: 'ws-admin-account-setup-page',
  templateUrl: './account-setup.page.html',
})
export class AccountSetupPage implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private staffSvc: StaffService,
    public portalSvc: PortalService
  ) {}

  state: 'name' | 'password' = 'name';

  form = new FormGroup({
    name: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required, Validators.minLength(8), customPasswordStrengthValidator()]),
    confirmPassword: new FormControl(null, [
      Validators.required,
      Validators.minLength(8),
      customPasswordStrengthValidator(),
    ]),
  });

  async submit() {
    const { name, password } = this.form.value;
    if (!name || !password) return;

    await this.staffSvc.setupAccount({ name, password });
    await this.router.navigate(['..']);
  }

  async ngOnInit() {
    const staffName = await firstValueFrom(this.route.data.pipe(map(data => data['staff'].name)));
    if (staffName) this.form.patchValue({ name: staffName });
  }
}
