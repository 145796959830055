import { Component } from '@angular/core';
import { ProgressBarConfig } from '@vsolv/vectors-ui/progress-bar';
import { Position, TooltipContent, TooltipMaxWidth, TooltipStyle } from '@vsolv/vectors-ui/tooltip';
import { TableCellComponent } from '../../models';

export interface ProgressBarCellProps {
  config: ProgressBarConfig;

  startText?: string;
  startTextClasses?: string;

  endText?: string;
  endTextClasses?: string;

  click?(): void | Promise<void>;

  tooltip?: {
    content?: TooltipContent;

    /** @default top */
    position?: Position;
    /** @default dark */
    style?: TooltipStyle;
    /** @default 200 */
    maxWidth?: TooltipMaxWidth;

    /** @default 0 */
    openDelay?: number;
    /** @default 0 */
    closeDelay?: number;
  };
}

@Component({
  template: `
    <ng-container *ngIf="data">
      <vs-progress-bar
        [config]="data.config"
        [vsTooltip]="data.tooltip?.content"
        [tooltipPosition]="data.tooltip?.position ?? 'top'"
        [tooltipStyle]="data.tooltip?.style ?? 'dark'"
        [tooltipMaxWidth]="data.tooltip?.maxWidth ?? 'lg'"
        [tooltipOpenDelay]="data.tooltip?.openDelay ?? 0"
        [tooltipCloseDelay]="data.tooltip?.closeDelay ?? 0"
        (click)="$event.stopPropagation(); _click()"
      >
        <p *ngIf="data?.startText" slot="start" class="font-normal text-xs" [class]="data.startTextClasses">
          {{ data.startText }}
        </p>
        <p *ngIf="data?.endText" slot="end" class="font-normal text-xs">{{ data.endText }}</p>
      </vs-progress-bar>
    </ng-container>
  `,
})
export class ProgressBarCellComponent extends TableCellComponent<ProgressBarCellProps> {
  async _click() {
    await this.data?.click?.();
  }
}
