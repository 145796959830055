/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NgControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, map } from 'rxjs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-condition-block-builder',
  templateUrl: './condition-block-builder.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ConditionBlockBuilderComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ConditionBlockBuilderComponent,
    },
  ],
})
export class ConditionBlockBuilderComponent implements OnInit, ControlValueAccessor, Validator {
  constructor(
    private injector: Injector,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder
  ) {
    this.form.valueChanges.subscribe(value => this.onChange(value as any));
  }

  @Input() propertySet!: PropertySet.Model;
  @Input() coverage: Coverage.Model | null = null;

  @PropertyListener('plan') plan$ = new BehaviorSubject<Policy.Plan.Model | null>(null);
  @Input() plan?: Policy.Plan.Model | null = null;

  @Output() blockUpdated = new EventEmitter<Conditions.Block<string | number | string[]>>();

  type = '';

  num = Property.PropertyType.NUMBER;

  touched = false;
  control?: NgControl;

  form = this.formBuilder.group({
    condition: [null, Validators.required],
    value: [
      this.type === 'string' ? '' : this.type === 'number' ? 0 : ([] as string[]),
      // [Validators.required]
    ],
    valuePropertyPath: [null as string | null],
  });

  filteredAddons$ = this.plan$.pipe(map(plan => plan?.addons?.filter(addon => !addon.id.includes('cov_'))));

  onChange = (_value: Conditions.Rule<string | number | string[]>) => {};
  onTouched = () => {};

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(block: Conditions.Block<string | number | string[]> | null): void {
    if (block) {
      this.form.patchValue({
        condition: (block.condition as any) || [],
        value: typeof block.value === 'number' ? (block.value === 0 ? null : block.value) : (block.value as any),
        valuePropertyPath: block.valuePropertyPath ?? null,
      });
      this.type = typeof block.value;
    }
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  validate(): ValidationErrors | null {
    if ((this.form.value.value === null || this.form.value.value === undefined) && !this.form.value.valuePropertyPath) {
      return {
        valueOrProperty: [{ required: true }],
      };
    }

    return this.form.valid
      ? null
      : {
          condition: this.form.get('condition')?.errors,
          value: this.form.get('value')?.errors,
        };
  }

  async addIndividualAddon() {
    if (!this.plan || !this.plan.addons || !this.coverage) return;

    if (!this.plan.addons?.map(group => group.id).includes(this.coverage.id)) {
      this.plan.addons.push({
        id: this.coverage.id,
        title: this.coverage.title || '',
        description: this.coverage.description || '',
      });

      await this.policySvc
        .updatePlan(this.plan.id, this.plan.policyId, { canCreateNewVersion: false, addons: this.plan.addons })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
        });
    }
  }

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.control.valueAccessor = this;
  }

  setValuePropertyPath(
    property: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null
  ) {
    this.form.patchValue({
      valuePropertyPath: property?.propertyPath ?? null,
      value: null,
    });
  }
}
