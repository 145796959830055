/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, ApplicationRef, Component, forwardRef, Inject, Input, OnDestroy } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Loader } from '@googlemaps/js-api-loader';
import { Address } from '@vsolv/core/address/domain';
import { extractErrors } from '@vsolv/dev-kit/ngx';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Asset } from '@wsphere/assets/domain';
import { BehaviorSubject, combineLatest, distinctUntilChanged, map, Subscription, switchMap, tap } from 'rxjs';
import { GoogleMapConfig, GOOGLE_MAP_CONFIG } from '../../GoogleMapConfig';

@Component({
  selector: 'vs-address-input',
  templateUrl: './address-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AddressInputComponent),
    },
    { provide: NG_VALIDATORS, useExisting: AddressInputComponent, multi: true },
  ],
})
export class AddressInputComponent implements ControlValueAccessor, OnDestroy, AfterViewInit {
  constructor(
    private fb: FormBuilder,
    @Inject(GOOGLE_MAP_CONFIG) config: GoogleMapConfig,
    private appRef: ApplicationRef
  ) {
    this.googleMapsKey = config.apikey;
  }

  overlayOpen = false;

  googleMapsKey: string;
  mapsAutocompleteService?: google.maps.places.AutocompleteService | null;
  mapsPlacesService?: google.maps.places.PlacesService | null;

  @PropertyListener('staffView') staffView$ = new BehaviorSubject(false);
  @Input() staffView = false;

  private _placeholders: { [keys in keyof Address.Model]?: string } = {
    line1: this.staffView ? "Enter your customer's address" : 'Enter your address',
    line2: this.staffView ? "Enter your customer's address" : 'Enter your address',
    zip: this.staffView ? "Enter your customer's zip code" : 'Enter your zip code',
    city: this.staffView ? "Enter your customer's city" : 'Enter your city',
    state: this.staffView ? "Select your customer's state" : 'Select your state',
    country: this.staffView ? "Select your customer's country" : 'Select your country',
  };

  @PropertyListener('placeholders') private _placeholders$ = new BehaviorSubject<
    { [keys in keyof Address.Model]?: string } | null
  >(null);
  @Input() placeholders: { [keys in keyof Address.Model]?: string } | null = null;

  placeholders$ = combineLatest([this.staffView$, this._placeholders$]).pipe(
    map(([staffView, placeholders]) => {
      const defaultPlaceholders = {
        line1: staffView ? "Enter your customer's address" : 'Enter your address',
        line2: staffView ? "Enter your customer's address" : 'Enter your address',
        zip: staffView ? "Enter your customer's zip code" : 'Enter your zip code',
        city: staffView ? "Enter your customer's city" : 'Enter your city',
        state: staffView ? "Select your customer's state" : 'Select your state',
        country: staffView ? "Select your customer's country" : 'Select your country',
      };
      if (!placeholders) return defaultPlaceholders;
      else return { ...defaultPlaceholders, ...placeholders };
    })
  );

  COUNTRIES = Asset.COUNTRIES;

  form = this.fb.group({
    line1: [{ value: null as string | null, disabled: false }, Validators.required],
    line2: [{ value: null as string | null, disabled: false }],
    zip: [{ value: null as string | null, disabled: false }, Validators.required],
    city: [{ value: null as string | null, disabled: false }, Validators.required],
    state: [{ value: null as string | null, disabled: false }, Validators.required],
    country: [{ value: null as string | null, disabled: false }, [Validators.required, Validators.pattern(/US|CA/)]],
  });

  autofillSearch$ = new BehaviorSubject<string>('');

  values$ = this.autofillSearch$.pipe(
    switchMap(async value => {
      if (value && this.mapsAutocompleteService) {
        const response = await this.mapsAutocompleteService.getPlacePredictions({
          input: value,
          componentRestrictions: { country: ['CA', 'US'] },
          types: ['street_address'],
        });
        return response.predictions;
      } else return [];
    })
  );

  stateValues$ = new BehaviorSubject<{ name: string; abbreviation: string }[]>([]);

  states$ = this.form.controls.country.valueChanges.pipe(
    distinctUntilChanged(),
    tap(country => this.validateForm(country)),
    tap(country => {
      if (!this.validateState(this.form.value.state ?? null, country)) {
        this.form.controls.state.reset(null);
      }

      if (this.form.controls.zip.invalid) {
        this.form.controls.zip.reset(null);
      }
    })
  );

  subscription?: Subscription;

  ngAfterViewInit() {
    const loader = new Loader({
      apiKey: this.googleMapsKey,
      version: 'weekly',
      libraries: ['places'],
    });

    loader
      .importLibrary('places')
      .then(places => {
        this.mapsPlacesService = new places.PlacesService(document.createElement('div'));
        this.mapsAutocompleteService = new places.AutocompleteService();
        if (!this.mapsAutocompleteService || !this.mapsPlacesService)
          console.warn('Unable to connect to Google Maps Places API. Address predictions are unavailable.');
      })
      .catch(() => console.warn('Unable to connect to Google Maps Places API. Address predictions are unavailable.'));
  }

  onChange = (_homeAsset: Asset.Model) => {
    /* noop */
  };

  onTouched = () => {
    /* noop */
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  writeValue(value: any): void {
    //TODO(advan) refactor
    let country = value?.country ?? value?.address?.country;
    if (country !== 'US' && country !== 'CA') country = null;
    const state = value?.state ?? value?.address?.state ?? null;

    this.validateForm(country);

    if (value) {
      this.form.setValue({
        line1: value.line1 || value.address?.line1 || null,
        line2: value.line2 || value.address?.line2 || null,
        zip: value.zip || value.address?.zip || null,
        city: value.city || value.address?.city || null,
        state: this.validateState(state, country),
        country: country,
      });
    }
  }
  registerOnChange(fn: any): void {
    this.subscription = this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
      return;
    }
    this.form.enable();
  }

  //set the correct zip validators and load the correct states
  validateForm(country: string | null) {
    this.form.controls.zip.clearValidators();

    if (country === 'CA') {
      this.form.controls.zip.addValidators([
        Validators.pattern('^(?!.*[DFIOQU|dfioqu])[A-VXY|a-vxy][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$'),
        Validators.required,
      ]);
      this.stateValues$.next([...Asset.CA_PROVINCES]);
    } else if (country === 'US') {
      this.form.controls.zip.addValidators([Validators.pattern('^\\d{5}(?:[-\\s]\\d{4})?$'), Validators.required]);
      this.stateValues$.next(Asset.US_STATES);
    } else {
      this.stateValues$.next([...Asset.CA_PROVINCES, ...Asset.US_STATES]);
    }

    this.form.updateValueAndValidity();
  }

  validateState(state: string | null, country: string | null) {
    if (
      state &&
      ((country === 'US' && !Asset.US_STATES.some(item => item.abbreviation === state)) ||
        (country === 'CA' && !Asset.CA_PROVINCES.some(item => item.abbreviation === state)) ||
        (country === null &&
          (!Asset.US_STATES.some(item => item.abbreviation === state) ||
            !Asset.CA_PROVINCES.some(item => item.abbreviation === state))))
    ) {
      return null;
    }
    return state;
  }

  requestAutofill(placeId: string) {
    this.autofillSearch$.next('');
    if (this.mapsPlacesService && this.googleMapsKey) {
      this.mapsPlacesService?.getDetails({ placeId, fields: ['address_component'] }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          let zip = '';
          let city = '';
          let country = '';
          let state = '';
          let street_number = '';
          let route = '';
          if (place) {
            if (place.address_components) {
              street_number =
                place.address_components
                  .filter(c => c.types.some(t => t === 'street_number'))[0]
                  ?.short_name.toString() ?? null;
              route = place.address_components.filter(c => c.types.some(t => t === 'route'))[0].long_name.toString();
              zip =
                place.address_components.filter(c => c.types.some(t => t === 'postal_code'))[0]?.long_name.toString() ??
                '';

              city = this.getCity(place);

              country = place.address_components.filter(c => c.types.some(t => t === 'country'))[0].short_name;
              state = place.address_components.filter(c => c.types.some(t => t === 'administrative_area_level_1'))[0]
                .short_name;
              this.writeValue({
                line1: `${street_number ? street_number + ' ' : ''}${route}`,
                zip,
                city,
                state,
                country,
                line2: null,
              });
              this.form.markAsDirty();
              this.appRef.tick();
            }
          } else {
            console.warn('Warning: PlaceID does not match any known place');
          }
        } else console.warn(`Unable to autofill address details: Google Maps API Status: ${status}`);
      });
    } else console.error(`Error: Google Maps unavailable after predictions`);
  }

  getCity(place: google.maps.places.PlaceResult) {
    const typesToCheck = ['postal_town', 'locality', 'administrative_area_level_3', 'sublocality_level_1'];
    let city = '';
    for (const type of typesToCheck) {
      const component = place.address_components?.find(c => c.types.includes(type));
      if (component) {
        city = component.long_name;
        break;
      }
    }
    return city;
  }

  validate(): ValidationErrors | null {
    return extractErrors(this.form);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }
}
