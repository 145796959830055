import { Staff } from '../lib/staff';

export type AssetPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'ast_ViewAsset',
    display: {
      title: 'View Asset Details',
      description: 'View details of asset for a customer, can see asset name, id, values of properties.',
    },
  },
  {
    id: 'ast_ManageAsset',
    display: {
      title: 'Manage Asset Details',
      description: 'Edit existing asset name and property values for a customer',
    },
  },
  {
    id: 'ast_CreateAsset',
    display: {
      title: 'Create assets for customers',
      description: 'Create new assets for customers',
    },
  },
  {
    id: 'ast_DeleteAsset',
    display: {
      title: 'Delete customer assets',
      description: 'Delete asset for a customer',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
