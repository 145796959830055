import { Component, Input } from '@angular/core';

@Component({
  selector: 'ws-warranty-asset-card',
  templateUrl: './warranty-asset-card.component.html',
})
export class WarrantyAssetCardComponent {
  @Input() propertySetId!: string;
  @Input() propertyValues!: Record<string, unknown>;
}
