import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { DialogComponent, DialogHeaderComponent } from './components';

@NgModule({
  imports: [CommonModule, OverlayModule, IconsModule, ButtonModule, ThemingModule, DividerModule],
  declarations: [DialogComponent, DialogHeaderComponent],
  exports: [DialogComponent, DialogHeaderComponent],
})
export class DialogModule {}
