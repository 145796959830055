<vs-dialog
  #reactivateDialog
  [config]="{ dialogContainerClass: 'w-4/5 sm:w-[500px] max-w-[700px]' }"
  (closed)="resetDialog()"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      icon: newKey ? 'check' : 'lock-01',
      iconTheme: newKey ? 'success' : 'primary',
      title: 'Reroll SFTP Password',
      subtitle: newKey ? 'Password successfully reset. Please copy it to your clipboard now.' : 'To secure your credentials, use your password for the dashboard below to reset your SFTP password'
    }"
  ></vs-dialog-header>

  <div *ngIf="!newKey; else newKeyValueDisplay">
    <vs-divider></vs-divider>
    <div class="flex flex-col m-2 [&>div]:pt-3 [&>div]:pb-3">
      <div class="flex flex-col sm:flex-row">
        <span class="font-bold w-32">Host</span>
        <span class="sm:max-w-[300px] truncate w-full">{{host}}</span>
      </div>
      <div class="flex flex-col sm:flex-row">
        <span class="font-bold w-32">Username</span>
        <span class="sm:max-w-[300px] truncate w-full">{{distributorOrTenantId}}</span>
      </div>
    </div>
    <vs-divider></vs-divider>
  </div>
  <ng-template #newKeyValueDisplay>
    <input vsInput type="text" readonly [value]="newKey" class="hidden sm:block mt-3 mb-3" />
  </ng-template>

  <!-- Buttons -->
  <div class="footer">
    <div *ngIf="!newKey" class="mx-2 my-4">
      <p class="text-md text-gray-900 font-semibold">Authentication required</p>
      <form [formGroup]="form">
        <vs-form-field
          theme="primary"
          [config]="{label: 'Confirm password', hint: '', description: ''}"
          appearance="responsive"
        >
          <vs-input-container class="group">
            <vs-icon
              *ngIf="((!form.get('password')?.valid && (form.get('password')?.dirty || form.get('password')?.touched)) || invalidPassword) && !saving"
              class="text-danger group-invalid-within:inline-block"
              name="info-circle"
              slot="start"
            ></vs-icon>
            <input
              #passwordInput
              placeholder="Password"
              [type]="showPassword.checked ? 'text' : 'password'"
              formControlName="password"
              (failed)="passwordInput.setCustomValidity('Invalid Password')"
            />
            <vs-icon
              slot="end"
              class="hover:text-gray-900 active:text-gray-400"
              (click)="showPassword.checked = !showPassword.checked"
              [name]="showPassword.checked ? 'eye' : 'eye-off'"
            ></vs-icon>
          </vs-input-container>
          <input #showPassword type="checkbox" class="hidden" />
          <span
            class="text-danger"
            slot="hint"
            *ngIf="((!form.get('password')?.valid && (form.get('password')?.dirty || form.get('password')?.touched)) || invalidPassword) && !saving"
            >Please enter a valid password.</span
          >
        </vs-form-field>
      </form>
    </div>
    <div class="flex sm:justify-end">
      <div class="flex sm:flex-row flex-col sm:justify-end w-full sm:w-fit">
        <button
          class="grow w-full mb-3 sm:mb-0 sm:mr-2 order-last sm:order-first"
          vs-button
          [disabled]="saving"
          (click)="closeDialog()"
          appearance="outline"
        >
          {{newKey ? 'Close' : 'Cancel'}}
        </button>
        <button
          class="grow w-full mb-3 sm:mb-0"
          vs-button
          theme="primary"
          [disabled]="!newKey && (saving || !inputIsValid() || !form.dirty)"
          (click)="newKey ? copy(newKey) : rerollKey()"
        >
          {{newKey ? 'Copy' : 'Reset'}}
        </button>
      </div>
    </div>
  </div>
</vs-dialog>
