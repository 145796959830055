/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { SecurityService } from '@wsphere/staff/web';
import { Policy, Provisioning } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, Observable, switchMap, tap } from 'rxjs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-policy-picker',
  templateUrl: './policy-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PolicyPickerComponent,
    },
  ],
})
export class PolicyPickerComponent implements ControlValueAccessor {
  constructor(
    private policySvc: PolicyService,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @PropertyListener('staffView') staffView$ = new BehaviorSubject<boolean>(false);
  @Input() staffView = false;

  @PropertyListener('propertySet') propertySet$ = new BehaviorSubject<PropertySet.Model | null>(null);
  @Input() propertySet: PropertySet.Model | null = null;

  @PropertyListener('session') session$ = new BehaviorSubject<Provisioning.ProvisioningSession | null>(null);
  @Input() session: Provisioning.ProvisioningSession | null = null;

  @PropertyListener('selected') selected$ = new BehaviorSubject<Policy.Model | null>(null);
  selected?: Policy.Model;

  @PropertyListener('page') page$ = new BehaviorSubject(1);
  page = 0;

  totalPolicies = 0;

  touched = false;
  disabled = false;

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  policies$: Observable<Policy.Model[]> = combineLatest([this.session$, this.propertySet$, this.staffView$]).pipe(
    switchMap(async ([session, propertySet, staffView]) => {
      if (!session) return { items: [] };

      const data = await this.policySvc.listForSales(
        { limit: 50 },
        propertySet?.id,
        Policy.Status.PUBLISHED,
        session.viewablePolicyIds,
        session.id
      );

      if (staffView) {
        const key = session.distributor?.permissionKey ? [session.distributor.permissionKey] : null;
        const hasAccess = await this.securitySvc.hasAccess('wrt_CreateWarranty', key);
        const hasDraftAccess = await this.securitySvc.hasAccess('wrt_CreateDraftWarranty', key);

        if (hasDraftAccess && !hasAccess) {
          data.items = data.items.filter(policy => !policy.terms.some(term => term.paymentSchedules.length));
        }
      } else {
        data.items = data.items.filter(policy => {
          if (session.viewablePolicyIds?.includes(policy.id)) {
            return true;
          }

          if (policy.plans?.some(plan => plan.visible)) {
            return true;
          }

          return false;
        });
      }

      return data;
    }),
    tap(data => {
      this.totalPolicies = data.items.length || 0;
    }),
    map(data => data.items),
    tap(policies => {
      if (policies?.length === 1) {
        this.writeValue(policies[0]);
        this.selectPolicy(policies[0]);
      }
    })
  );

  selectedIndex$ = combineLatest([this.selected$, this.policies$]).pipe(
    map(([selected, policies]) => {
      if (selected) return policies.findIndex(policy => policy.id === selected.id);
      return 0;
    }),
    map(index => (index === -1 ? 0 : index)),
    tap(index => {
      this.page = index;
    })
  );

  onChange = (_selected: Policy.Model) => {};

  onTouched = () => {};

  selectPolicy(policy: Policy.Model) {
    this.onTouched();
    this.selected = policy;
    this.onChange(this.selected);
  }

  writeValue(policy: Policy.Model): void {
    this.selected = policy;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
