import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  ProvisionAddonComponent,
  ProvisionAssetComponent,
  ProvisionCustomerInfoComponent,
  ProvisionDistributorComponent,
  ProvisionPlanComponent,
  ProvisionPolicyComponent,
  WarrantyPreviewCardComponent,
} from './components';
import {
  SessionAddonGuard,
  SessionAssetGuard,
  SessionCheckoutGuard,
  SessionGuard,
  SessionPlanGuard,
  SessionPolicyGuard,
} from './guards';
import { ProvisionPage } from './pages';
import { ProvisioningWebModule } from './provisioning.module';

const routes: Routes = [
  {
    path: '',
    component: ProvisionPage,
    children: [
      {
        path: '',
        component: ProvisionDistributorComponent,
      },
      {
        path: 'customer',
        data: { staffView: true },
        component: ProvisionCustomerInfoComponent,
      },
      {
        path: 'policy',
        data: { staffView: true },
        component: ProvisionPolicyComponent,
        canActivate: [SessionGuard, SessionPolicyGuard],
      },
      {
        path: 'asset',
        data: { staffView: true },
        component: ProvisionAssetComponent,
        canActivate: [SessionGuard, SessionAssetGuard],
      },
      {
        path: 'plan',
        data: { staffView: true },
        component: ProvisionPlanComponent,
        canActivate: [SessionGuard, SessionPlanGuard],
      },
      {
        path: 'addon',
        data: { staffView: true },
        component: ProvisionAddonComponent,
        canActivate: [SessionGuard, SessionAddonGuard],
      },
      {
        path: 'checkout',
        data: { staffView: true },
        component: WarrantyPreviewCardComponent,
        canActivate: [SessionGuard, SessionCheckoutGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ProvisioningWebModule],
})
export class ProvisioningRoutingModule {}
