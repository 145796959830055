<ng-container *vsLet="properties$ | async as properties">
  <ng-container *vsLet="selectedProperty$ | async">
    <vs-input-container
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      (click)="toggle()"
      [vsTooltip]="value?.propertyPath || 'Please select a property'"
    >
      <input
        type="text"
        [value]="value?.property?.name || value?.propertyPath"
        placeholder="Select a property..."
        [disabled]="disabled"
      />
      <vs-icon slot="end" name="chevron-down"></vs-icon>
    </vs-input-container>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayOrigin]="trigger"
      [cdkConnectedOverlayOpen]="overlayOpen"
      (overlayOutsideClick)="$event.stopImmediatePropagation(); overlayOpen = false"
    >
      <vs-menu class="max-h-[300px]">
        <p class="mx-1 text-sm text-gray-600" *ngIf="!properties?.length">No address properties found!</p>
        <div *ngFor="let propertyWithAddress of properties">
          <ws-shipping-address-property-picker-item
            [propertyWithAddress]="propertyWithAddress"
            [propertyPath]="propertyWithAddress.property.valueKey"
            (selectionChanged)="setValue($event, true); close()"
            [value]="value"
          ></ws-shipping-address-property-picker-item>
        </div>
      </vs-menu>
    </ng-template>
  </ng-container>
</ng-container>
