import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';

export interface TabItemConfig {
  iconStart?: IconName;
  iconEnd?: IconName;

  label?: string;

  endContent?: TemplateRef<unknown>;

  routerLink?: string | string[];

  target?: '_blank' | '_self';
  href?: string;
}

@Component({
  selector: 'vs-tab',
  templateUrl: './tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabComponent {
  @Input() config?: TabItemConfig;

  @ViewChild('content') templateRef?: TemplateRef<unknown>;

  @ViewChild('label') labelTemplateRef?: TemplateRef<unknown>;

  private _selected = false;
  get selected() {
    return this._selected;
  }
}
