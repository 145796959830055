import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaymentTax } from '@vsolv/packages/payments/domain';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentTaxWebService {
  constructor(private http: HttpClient) {}

  retrieve() {
    return firstValueFrom(this.http.get<PaymentTax.TaxJar.RetrieveResponse>(`/api/taxjar`));
  }

  create(data: PaymentTax.TaxJar.CreateRequest) {
    return firstValueFrom(
      this.http.post<PaymentTax.TaxJar.CreateResponse>(`/api/taxjar`, {
        secretKey: data.secretKey,
      })
    );
  }

  update(data: PaymentTax.TaxJar.UpdateRequest) {
    return firstValueFrom(
      this.http.patch<PaymentTax.TaxJar.UpdateResponse>(`/api/taxjar`, {
        secretKey: data.secretKey,
      })
    );
  }

  delete() {
    return firstValueFrom(this.http.delete<PaymentTax.TaxJar.DeleteResponse>(`/api/taxjar`));
  }
}
