import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { STAFF_PERMISSION_FALLBACK_ROUTE_DATA, STAFF_PERMISSION_ROUTE_DATA, StaffRoleGuard } from '@wsphere/staff/web';
import { PlanDetailsComponent } from './components';
import { CoverageGuard, PlanGuard, PolicyGuard } from './guards';
import {
  CoveragePage,
  PoliciesPage,
  PolicyClaimLifecyclePage,
  PolicyCommunicationsPage,
  PolicyDetailsPage,
  PolicyDocumentsPage,
  PolicyPage,
  PolicyPlansPage,
} from './pages';
import { PolicyModule } from './policy.module';
import { CoverageResolver, PlanResolver, PolicyResolver } from './resolvers';

@NgModule({
  imports: [
    PolicyModule,
    RouterModule.forChild([
      {
        path: '',
        component: PoliciesPage,
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'pol_View',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [
            { permissionId: 'dsh_ViewDashboard', route: '' },
            { permissionId: 'dist_ViewDistributors', route: 'distributors' },
            { permissionId: 'clm_ViewDetails', route: 'claims' },
            { permissionId: 'py_ViewSales', route: 'sales' },
          ],
        },
        canActivate: [StaffRoleGuard],
      },
      {
        path: ':policyId',
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'pol_View',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'pol_View', route: '/policies' }],
        },
        canActivate: [PolicyGuard, StaffRoleGuard],
        resolve: { policy: PolicyResolver },
        component: PolicyPage,

        children: [
          {
            path: '',
            component: PolicyDetailsPage,
          },
          {
            path: 'plans',
            component: PolicyPlansPage,
            children: [
              {
                path: ':planId',
                component: PlanDetailsComponent,
                canActivate: [PlanGuard],
                resolve: { plan: PlanResolver },
              },
            ],
          },
          {
            path: 'documents',
            component: PolicyDocumentsPage,
          },
          {
            path: 'claim-lifecycle',
            component: PolicyClaimLifecyclePage,
            data: {
              [STAFF_PERMISSION_ROUTE_DATA]: 'pol_ViewLifecycle',
              [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'pol_ViewLifecycle', route: '/:policyId' }],
            },
            canActivate: [StaffRoleGuard],
          },
          {
            path: 'communications',
            component: PolicyCommunicationsPage,
          },
        ],
      },
      {
        path: ':policyId/coverages/:coverageId',
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'pol_View',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'pol_View', route: '/policy/:policyId' }],
        },
        canActivate: [PolicyGuard, CoverageGuard, StaffRoleGuard],
        resolve: { policy: PolicyResolver, coverage: CoverageResolver },
        component: CoveragePage,
      },
    ]),
  ],
  providers: [],
})
export class PolicyRoutingModule {}
