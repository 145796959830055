import { PropertyType } from './property';

export enum Operator {
  EQUALS = '=',
  NOT_EQUALS = '!=',

  GREATER_THAN = '>',
  GREATER_THAN_EQUALS = '>=',

  LESS_THAN = '<',
  LESS_THAN_EQUALS = '<=',

  CONTAINS = 'contains',
  STARTS_WITH = 'starts with',
  NOT_STARTS_WITH = 'does not start with',
  IS_NULL = 'is empty',
  IS_NOT_NULL = 'is not empty',
}

export function getPropertyOperatorsForType(type: PropertyType): Operator[] {
  switch (type) {
    case PropertyType.BOOLEAN:
      return [Operator.EQUALS, Operator.NOT_EQUALS, Operator.IS_NULL, Operator.IS_NOT_NULL];

    case PropertyType.NUMBER:
      return [
        Operator.EQUALS,
        Operator.NOT_EQUALS,
        Operator.GREATER_THAN,
        Operator.GREATER_THAN_EQUALS,
        Operator.LESS_THAN,
        Operator.LESS_THAN_EQUALS,
        Operator.IS_NOT_NULL,
        Operator.IS_NULL,
      ];

    case PropertyType.DATE:
      return [
        Operator.EQUALS,
        Operator.NOT_EQUALS,
        Operator.GREATER_THAN,
        Operator.GREATER_THAN_EQUALS,
        Operator.LESS_THAN,
        Operator.LESS_THAN_EQUALS,
        Operator.IS_NULL,
        Operator.IS_NOT_NULL,
      ];

    case PropertyType.TEXT:
      return [
        Operator.EQUALS,
        Operator.NOT_EQUALS,
        Operator.GREATER_THAN,
        Operator.GREATER_THAN_EQUALS,
        Operator.LESS_THAN,
        Operator.LESS_THAN_EQUALS,
        Operator.CONTAINS,
        Operator.STARTS_WITH,
        Operator.NOT_STARTS_WITH,
        Operator.IS_NULL,
        Operator.IS_NOT_NULL,
      ];
    default:
      return [];
  }
}
