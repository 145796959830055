<vs-user-avatar
  class="w-10 h-10"
  [config]="{
    photoUrl: config.photoUrl
  }"
></vs-user-avatar>

<div class="flex-1 overflow-hidden">
  <h4 class="text-sm font-semibold">{{ config.displayName }}</h4>
  <p class="text-sm truncate">{{ config.email }}</p>
</div>
<vs-icon
  name="chevron-right"
  size="20"
  class="text-gray-400 ml-auto mr-[2px] invisible group-hover:visible group-active:text-gray"
></vs-icon>
