<vs-dialog [config]="{dialogContainerClass: 'w-full lg:w-1/2'}" (closed)="this.dialogClosed.emit()">
  <vs-dialog-header
    [config]="{hideCloseButton: false, icon: config.icon, iconTheme: 'primary', title: config.title , subtitle: config.body , showDivider: true, mode: 'simple'}"
  >
    <div class="flex flex-row gap-4 my-2 font-normal text-xs text-gray-700">
      <div class="flex flex-row items-center gap-2">
        Powered by
        <div
          class="flex flex-row justify-between gap-1 p-[2px] pr-1 items-center rounded-2xl w-fit h-fit bg-opacity-0 border-2 border-primary-200 text-primary-800"
        >
          <div
            class="w-5 h-5 rounded-2xl bg-contain bg-center"
            [ngStyle]="{ 'background-image': 'url(' + config.poweredBy.icon + ')' }"
          ></div>
          {{ config.poweredBy.name }}
        </div>
      </div>
      <!-- <div class="border-l-2 border-l-themed-100"></div>
      <div>Some charges may apply</div> -->
    </div>
  </vs-dialog-header>
  <div class="flex flex-col gap-4 p-4">
    <ng-content></ng-content>
  </div>
</vs-dialog>
