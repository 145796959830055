/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Claim } from '@wsphere/warranties/domain';
import { map, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-resolve-dialog',
  templateUrl: './claim-item-resolve.dialog.html',
})
export class ClaimItemResolveDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private formBuilder: FormBuilder
  ) {}

  item: Claim.ClaimItem.Model | null = null;
  readonly item$ = this.route.data.pipe(
    map(data => data['item'] as Claim.ClaimItem.Model),
    tap(item => {
      if (item) {
        this.form.patchValue({
          title: item.resolution?.title,
          description: item.resolution?.description,
          cost:
            item.resolution?.actualCost !== null && item.resolution?.actualCost !== undefined
              ? (item.resolution?.actualCost || 0) / 100
              : null,
          covered:
            item.amount?.standard !== null && item.amount?.standard !== undefined
              ? (item.amount?.standard || 0) / 100
              : null,
          goodwill:
            item.amount?.goodwill !== null && item.amount?.goodwill !== undefined
              ? (item.amount?.goodwill || 0) / 100
              : null,
        });

        if (item.status === Claim.ClaimItem.Status.REJECTED) this.validateRejected();
      }
    })
  );

  form = this.formBuilder.group({
    title: ['', [Validators.required, Validators.maxLength(255)]],
    description: ['', Validators.required],
    cost: [null as number | null, Validators.required],
    covered: [null as number | null, [Validators.required]],
    goodwill: [null as number | null],
  });

  async save(item: Claim.ClaimItem.Model) {
    const value = this.form.value;

    if (item.status !== Claim.ClaimItem.Status.REJECTED) {
      const valid = this.validateCovered(item, value);
      if (typeof valid !== 'number') return;
    }

    const resolution: Claim.ClaimItem.ResolutionDto = {
      title: value.title ?? null,
      description: value.description ?? null,
      actualCost: (value.cost || 0) * 100,
    };

    const amount: Claim.AmountDto = {
      total: ((value.covered || 0) + (value.goodwill || 0)) * 100,
      standard: (value.covered || 0) * 100,
      goodwill: (value.goodwill || 0) * 100,
    };

    const itemId = await this.claimSvc
      .resolveItem(item.claimId, item.id, {
        amount,
        resolution,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.navigateTo();
      });

    if (itemId) {
      this.toastSvc.show({
        type: 'success',
        title: (item.coverage?.title ?? 'Other') + ' Item Resolved',
        text: 'The ' + (item.coverage?.title ?? 'Other') + ' item has been successfully resolved.',
      });

      this.navigateTo();
    }

    this.navigateTo();
  }

  maxCovered(cost: number, item: Claim.ClaimItem.Model) {
    const min = Math.min(
      cost - (item.liabilityInfo?.deductible || 0) / 100,
      (item.liabilityInfo?.remainingLiabilityLimit || 0) / 100
    );
    return Math.max(0, min);
  }

  validateCovered(
    item: Claim.ClaimItem.Model,
    value: Partial<{
      cost: number | null;
      covered: number | null;
      goodwill: number | null;
    }>
  ) {
    const remaningLiability = (item.liabilityInfo?.remainingLiabilityLimit || 0) / 100;
    let maxCovered;
    let minimum;

    if (typeof value?.cost === 'number' && typeof value?.covered === 'number' && item.liabilityInfo) {
      maxCovered = value.cost - item?.liabilityInfo.deductible / 100;

      minimum = Math.min(remaningLiability, maxCovered || 0);
      minimum = Math.max(0, minimum);

      if (minimum < (value?.covered || 0)) {
        this.form.controls.covered.setErrors({
          title: 'Insuficient funds',
        });

        this.form.controls.covered.markAllAsTouched();
        return null;
      }

      return minimum;
    }

    return null;
  }

  validateRejected() {
    this.form.controls.title.valid;
    this.form.controls.description.valid;
    this.form.controls.covered.valid;
    this.form.controls.goodwill.valid;

    this.form.controls.title.disable();
    this.form.controls.description.disable();
    this.form.controls.covered.disable();
    this.form.controls.goodwill.disable();
  }

  navigateTo(path?: string) {
    this.router.navigate([path ?? `../resolve`], { relativeTo: this.route });
  }
}
