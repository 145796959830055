import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { TextCellComponent } from './text.component';

@NgModule({
  imports: [CommonModule, IconsModule, TooltipModule, ButtonModule, ThemingModule],
  declarations: [TextCellComponent],
})
export class TextColumnModule {}
