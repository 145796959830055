import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { DistributorService } from '../../services';

@Component({
  selector: 'ws-break-distributor-onboarding-link-dialog',
  templateUrl: './break-distributor-onboarding-link.dialog.html',
})
export class BreakDistributorOnboardingLinkDialog {
  constructor(private distributorSvc: DistributorService, private toastSvc: ToastService) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() dialogConfig?: DialogConfig;
  @Input() linkId!: string;

  @Output() closed = new EventEmitter<boolean>();

  saving = false;

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.dialog.close();
  }

  cancel() {
    this.closed.emit(false);
    this.closeDialog();
  }

  async breakLink() {
    const brokenLink = await this.distributorSvc.breakOnboardingLink(this.linkId).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something Went Wrong',
        text: error.message,
      });
    });

    if (brokenLink) {
      this.toastSvc.show({
        type: 'success',
        title: 'Link broken',
        text: 'The onboarding link has successfully been broken.',
      });
    }

    if (brokenLink) this.closed.emit(true);
    this.closeDialog();
  }
}
