/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Address } from '@vsolv/core/address/domain';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Customer } from '@wsphere/customers/domain';
import { CustomerService } from '@wsphere/customers/web';
import { Distributor } from '@wsphere/distributors/domain';
import { Provisioning } from '@wsphere/warranties/domain';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  BehaviorSubject,
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { CheckoutCustomerExistsSignInDialog } from '../../dialogs';
import { ProvisioningSessionService } from '../../services';

interface CustomerInfo {
  name: string;
  email: string;
  phone: string | null;
}

@Component({
  selector: 'ws-provision-customer-info',
  templateUrl: './provision-customer-info.component.html',
})
export class ProvisionCustomerInfoComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private firebaseSvc: FirebaseService,
    private customerSvc: CustomerService,
    private sessionSvc: ProvisioningSessionService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @HostBinding('class') _class = 'pb-24 px-6 w-full flex flex-col items-center';

  @ViewChild('customerExistsDialog') customerExistsDialog!: CheckoutCustomerExistsSignInDialog;

  private destroy$ = new Subject<void>();

  @PropertyListener('queryParams') queryParams$ = new BehaviorSubject<ParamMap | undefined>(undefined);
  queryParams!: ParamMap;

  readonly staffView$ = this.route.data.pipe(map(data => data['staffView'] as boolean));

  protected showFlow = true;
  protected isProcessing = false;

  protected dataCustomer: Customer.Model | null = null;

  protected session$: Observable<Provisioning.ProvisioningSession | null> = combineLatest([
    this.sessionSvc.getCurrentSession(),
    this.sessionSvc.data$.asObservable(),
    this.staffView$,
  ]).pipe(
    tap(async ([session, data, staffView]) => {
      this.queryParams = await firstValueFrom(this.route.queryParamMap);
      this.dataCustomer = session?.customer || data.customer || null;
      this.customer.patchValue(session?.customer || data.customer || null);
      this.customerInfo.patchValue({
        name: session?.customer?.name || data.customerInfo?.name || this.queryParams?.get('name') || '',
        email: session?.customer?.email || data.customerInfo?.email || this.queryParams?.get('email') || '',
        phone: session?.customer?.phone || data.customerInfo?.phone || this.queryParams?.get('phone') || null,
      });
      this.addressControl.setValue(
        session?.customer?.address ??
          data.customer?.address ??
          (this.queryParams?.get('line1')
            ? ({
                line1: this.queryParams?.get('line1'),
                line2: this.queryParams?.get('line2'),
                zip: this.queryParams?.get('zip'),
                city: this.queryParams?.get('city'),
                state: this.queryParams?.get('state'),
                country: this.queryParams?.get('country'),
              } as Address.Model)
            : null)
      );
      this.distributorId = data.distributorId || undefined;
      this.distributorData = data.distributor;
      this.viewablePlansAndTerms = data.viewablePlansAndTerms;
      this.expiryDate = data.expiryDate;
      if (this.customer.valid && this.customerInfo.valid) this.stepCompleted = true;

      const email = this.queryParams?.get('email');
      this.emailControl.setValue(data?.customer?.email || email);
      if (!staffView && !this.customerInfo.valid && !session && !email) this.showFlow = false;
      else this.showFlow = true;
      if (!data.customer && email) await this.redirectIfExists(email);
    }),
    switchMap(async ([session, _]) => session)
  );

  protected distributor$ = this.sessionSvc.distributor$.asObservable();

  protected emailControl = new FormControl('', [Validators.required, Validators.email]);
  protected customerInfo = new FormControl(null as CustomerInfo | null);
  protected addressControl = new FormControl(null as Address.Model | null, [Validators.required]);
  protected customer = new FormControl(null as Customer.Model | null);

  stepCompleted = false;

  distributorId?: string;
  distributorData?: any;

  viewablePlansAndTerms?: Provisioning.ViewablePlansAndTerms;
  expiryDate?: Date;

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  customerSelected(customer: Customer.Model, session: Provisioning.ProvisioningSession | null) {
    if (session?.customerId && session.customerId !== customer.id) {
      throw new Error('Session already has a customer');
    } else {
      this.customer.patchValue(customer);
      this.customerInfo.patchValue({
        name: customer.name,
        email: customer.email,
        phone: customer.phone,
      });
      this.addressControl.setValue(customer.address);
      this.stepCompleted = this.validateCustomerStep();
    }
  }

  private validateCustomerStep() {
    const phone: string | null = this.customerInfo.value?.phone || null;
    if (phone) {
      const valid = isValidPhoneNumber(phone, 'US');
      if (!valid) return false;
    }
    return this.customerInfo.valid && this.addressControl.valid;
  }

  previousPage(staffView: boolean) {
    if (staffView) this.navigateTo('/provisioning');
    else this.navigateTo('/checkout');
  }

  async nextPage(
    session: Provisioning.ProvisioningSession | null,
    staffView: boolean,
    distributor: Distributor.Model | null
  ) {
    if (this.stepCompleted) {
      this.isProcessing = true;
      if (!session) await this.createSession(session, staffView, distributor);
      else {
        if (this.customerInfo.value)
          await this.sessionSvc.updateSession({
            sessionId: session.id,
            data: { customerInfo: this.customerInfo.value },
          });
      }
      this.isProcessing = false;
      if (staffView) {
        this.navigateTo('/provisioning/policy');
      } else this.navigateTo('/checkout/policy');
    }
  }

  private navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }

  async next() {
    const email = this.emailControl.value;
    if (!email) return;

    if (this.dataCustomer && email !== this.dataCustomer.email) {
      throw new Error('Email is invalid');
    }
    const exists = await this.redirectIfExists(email);
    if (exists) return;
    this.customerInfo.patchValue({
      email: email || '',
      name: '',
      phone: null,
    });
    this.addressControl.setValue(null);
    this.showFlow = true;
    this.stepCompleted = false;
  }

  async redirectIfExists(email: string) {
    if (!this.dataCustomer) {
      const exists = await this.customerSvc.checkIfCustomerExists(email);
      if (exists) {
        let redirect = 'checkout';
        const keys = this.queryParams.keys;
        for (let index = 0; index < keys.length; index++) {
          if (index === 0) redirect += '?';
          const key = keys[index];
          const params = this.queryParams.getAll(key).join(`&${key}=`);
          redirect += `${key}=${params}`;
          if (index < keys.length - 1) redirect += '&';
        }
        await this.router.navigate(['login'], { queryParams: { email, autosend: true, redirect } });
        return true;
      }
    }
    return false;
  }

  private async createSession(
    session: Provisioning.ProvisioningSession | null,
    staffView: boolean,
    distributor: Distributor.Model | null
  ) {
    const values = this.customerInfo.value;
    const address = this.addressControl.value;
    let email: string | null = values?.email || this.customer.value?.email || null;
    if (!staffView) {
      const authUser = await firstValueFrom(this.firebaseSvc.authUser$);
      if (authUser) {
        email = authUser.email;
      }
    }

    if (session || !values || !values.name || !email || !address?.line1) return;
    try {
      const session = await this.sessionSvc.create({
        distributorId: this.distributorId || distributor?.id,
        distributor: this.distributorData,
        customerId: this.customer.value?.id,
        customerName: values.name,
        customerEmail: email,
        customerPhoneNumber: values.phone,
        customerAddress: address,
        viewablePlansAndTerms: this.viewablePlansAndTerms,
        expiryDate: this.expiryDate,
      });
      this.sessionSvc.refreshSession(session);
      // FIXME: Provisioning session local storage
      // if (!staffView) localStorage.setItem('provisioning-session', session.id);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.error.message[0].includes('must be an email')) {
        this.toastSvc.show({
          type: 'error',
          title: 'Invalid email address',
          text: `Sorry, looks like your email address isn't formatted correctly! Please try again.`,
        });
      } else if (err.status === 409) {
        if (staffView) {
          this.toastSvc.show({
            type: 'error',
            title: 'This email address is already in use',
            text: `Please try a different email address ${
              !staffView ? ' or signing in!' : 'or select the matching customer!'
            }`,
          });
        } else {
          //open sign in dialog
          this.customerExistsDialog?.open(email);
        }
      } else {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong!',
        });
      }
      console.error(err);
      this.isProcessing = false;
      throw new Error();
    }
  }

  ngOnInit(): void {
    combineLatest([this.customerInfo.valueChanges, this.addressControl.valueChanges])
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => (this.stepCompleted = this.validateCustomerStep()));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
