import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: 'a[vsLink], button[vsLink]',
})
export class LinkDirective {
  @HostBinding('class') get styles() {
    return `text-themed font-medium hover:text-themed-700 active:text-themed-400 focus:outline-none focus:text-themed-700 ${
      this.disabled ? 'opacity-50 pointer-events-none select-none' : ''
    }`;
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;
}
