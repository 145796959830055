export enum Status {
  /** Has been opened */
  OPEN = 'OPEN',

  /** End state (manually canceled by user or staff) (or "timeout") */
  CANCELLED = 'CANCELLED',

  /** End state (cancelled by user) */
  CLOSED = 'CLOSED',

  /** End state (past expiration date) */
  EXPIRED = 'EXPIRED',
}
