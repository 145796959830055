import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddressModule } from '@vsolv/core/address/web';
import { PropertiesWebModule } from '@vsolv/packages/properties/web';
import { ContentCardModule } from '@vsolv/ui-kit/card';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { ToastModule } from '@vsolv/ui-kit/toast';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DatePickerModule } from '@vsolv/vectors-ui/date-picker';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule as VsFormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InfoCardModule } from '@vsolv/vectors-ui/info-card';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { ProgressBarModule } from '@vsolv/vectors-ui/progress-bar';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { TableModule } from '@vsolv/vectors/table';
import { AssetsWebModule } from '@wsphere/assets/web';
import { CustomerWebModule } from '@wsphere/customers/web';
import { NgxCurrencyModule } from 'ngx-currency';
import { NgPipesModule } from 'ngx-pipes';
import { SwiperModule } from 'swiper/angular';
import { ClaimModule } from '../claim';
import { WarrantyCoverageModule } from '../coverage';
import { PolicyModule } from '../policy';
import { WarrantyWebService } from '../warranty/services';
import {
  CoverageCardComponent,
  CoverageCarouselComponent,
  CoverageLiabilityLimitComponent,
  WarrantyAssetCardComponent,
  WarrantyCustomerCardComponent,
  WarrantyOverviewCardComponent,
  WarrantyPolicyCardComponent,
  WarrantyRecentClaimsComponent,
  WarrantyStatusPickerComponent,
} from './components';
import { CancelWarrantyDialog } from './dialogs';
import { WarrantyGuard, WarrantyResolver } from './guards';
import { WarrantyPageComponent, WarrantyTableComponent } from './pages';

@NgModule({
  exports: [
    WarrantyTableComponent,
    WarrantyStatusPickerComponent,
    WarrantyPageComponent,
    CoverageCarouselComponent,
    CancelWarrantyDialog,
  ],
  providers: [WarrantyWebService, WarrantyGuard, WarrantyResolver, AppPageModule, CurrencyPipe],
  declarations: [
    WarrantyTableComponent,
    WarrantyStatusPickerComponent,
    WarrantyPageComponent,
    WarrantyOverviewCardComponent,

    WarrantyRecentClaimsComponent,
    WarrantyAssetCardComponent,
    CoverageCarouselComponent,
    CoverageCardComponent,
    WarrantyCustomerCardComponent,
    WarrantyPolicyCardComponent,
    CoverageLiabilityLimitComponent,

    // Dialogs
    CancelWarrantyDialog,
  ],
  imports: [
    DialogModule,
    BreadcrumbsModule,
    UtilsModule,
    BadgeModule,
    IconsModule,
    InputModule,
    TableModule,
    AppPageModule,
    RouterModule,
    CommonModule,
    ContentCardModule,
    CardModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    ToastModule,
    SelectModule,
    CustomerWebModule,
    ButtonModule,
    WarrantyCoverageModule,
    ModalModule,
    AddressModule,
    TooltipModule,
    NgPipesModule,
    SwiperModule,
    TabsModule,
    ClaimModule,
    ThemingModule,
    PropertiesWebModule,
    DividerModule,
    PolicyModule,
    DatePickerModule,
    AssetsWebModule,
    InfoCardModule,
    ProgressBarModule,
    VsFormsModule,
    InputModule,
    NgxCurrencyModule,
    MenuModule,
  ],
})
export class WarrantyManagementModule {}
