import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-edit-email-template-dialog',
  templateUrl: './edit-email-template.dialog.html',
})
export class EditEmailTemplateDialog {
  constructor(private formBuilder: FormBuilder, private policySvc: PolicyService, private toastSvc: ToastService) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;
  @Input() dialogConfig?: DialogConfig;
  @Input() policy?: Policy.Model;

  saving = false;

  email!: Policy.Email | null;

  form = this.formBuilder.group({
    enabled: [true, Validators.required],
    subject: [''],
    byline: [''],
    content: ['', []],
  });

  resetFormValue = {
    enabled: true,
    subject: '',
    byline: '',
    content: '',
  };

  @Input() set policyEmail(policyEmail: Policy.Email | null) {
    if (policyEmail) {
      this.email = policyEmail;

      this.resetFormValue = {
        enabled: policyEmail.enabled ?? true,
        subject: policyEmail.subject || '',
        byline: policyEmail.byline || '',
        content: policyEmail.content || '',
      };
      this.resetForm();
    }
  }

  async resetForm() {
    try {
      this.form.reset(this.resetFormValue);
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  cancel() {
    this.form.reset(this.resetFormValue);
    this.form.markAsPristine();
    this.close();
  }

  async submit() {
    this.saving = true;
    if (!this.policy) return;

    if (this.email) {
      const dto: Policy.UpdatePolicyEmailRequest = {
        enabled: this.form.value.enabled ?? undefined,
        type: this.email.type,
        subject: this.form.value.subject,
        byline: this.form.value.byline,
        content: this.form.value.content,
      };

      const updatedEmail = await this.policySvc.updateEmail(this.email.id, this.policy.id, dto).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
        this.saving = false;
      });

      if (updatedEmail?.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Updated email template',
          text: '<strong>' + this.email?.type + '</strong> has been successfully updated.',
        });

        this.close();
        this.policySvc.refreshPolicy();
        this.saving = false;
      }
    }
  }
}
