/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Customer } from '@wsphere/customers/domain';
import { CustomerService } from '@wsphere/customers/web';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, Observable, ReplaySubject, combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-provisioning-customer-picker',
  templateUrl: './customer-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ProvisioningCustomerPickerComponent,
    },
  ],
})
export class ProvisioningCustomerPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(
    public elementRef: ElementRef,
    private customerSvc: CustomerService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('value') value$ = new ReplaySubject<Customer.Model>(1);
  @Input() value: Customer.Model | null = null;

  @PropertyListener('permissionKey') permissionKey$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() permissionKey?: string;

  @PropertyListener('distributor') distributor$ = new ReplaySubject<Distributor.Model>(1);
  @Input() distributor?: Distributor.Model | null = null;

  @Input() placeholder = 'Find a customer...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<Customer.Model | null>();

  touched = false;
  overlayOpen = false;

  readonly searchQuery$ = new BehaviorSubject<string>('');

  customers$: Observable<Customer.Model[]> = combineLatest([
    this.searchQuery$,
    this.distributor$,
    this.securitySvc.globalDistributors$,
    this.permissionKey$,
  ]).pipe(
    switchMap(async ([search, distributor, globalDistributors]) => {
      const distributorIds: string[] = [];
      if (distributor) distributorIds.push(distributor.id);
      globalDistributors?.forEach(distributor => distributorIds.push(distributor.id));

      return this.customerSvc.listCustomersForSales({
        search,
        distributorIds: distributorIds?.length ? distributorIds : undefined,
      });
    }),
    map(customers => customers.items)
  );

  onChange = (_value: Customer.Model | null) => {};
  onTouched = () => {};

  selectValueChanged(value: Customer.Model) {
    this.markAsTouched();
    this.value?.userId === value?.userId ? (this.value = null) : (this.value = value);
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: Customer.Model | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: Customer.Model | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
