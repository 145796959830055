import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

@Injectable()
export class EmailResolver implements Resolve<string | null> {
  email: string | null = null;

  resolve() {
    return this.email;
  }
}
