import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiKey } from '@vsolv/packages/api-key';
import { ApiKeyService } from '@vsolv/packages/api-key/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { MenuTriggerForDirective } from '@vsolv/vectors-ui/menu';
import moment from 'moment';

@Component({
  selector: 'ws-edit-api-key-dialog',
  templateUrl: './edit-api-key.dialog.html',
})
export class EditApiKeyDialog {
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Output() keyUpdated = new EventEmitter();

  _apiKeyItem: ApiKey.ListApiKeysResponseType | null = null;
  @Input() set apiKeyItem(apiKeyItem: ApiKey.ListApiKeysResponseType | null) {
    if (apiKeyItem) {
      this._apiKeyItem = apiKeyItem;

      this.resetFormValue = {
        name: apiKeyItem.name || '',
        expired: apiKeyItem.expired ? formatDate(apiKeyItem.expired, 'yyyy-MM-dd', 'en') : '',
      };
      this.resetForm();
    }
  }
  _submitting = false;
  resetFormValue = {
    name: '',
    expired: '',
  };

  readonly form = this.fb.group({
    name: ['', [Validators.required]],
    expired: [''],
  });

  constructor(private fb: FormBuilder, private apiKeySvc: ApiKeyService, private toastSvc: ToastService) {}

  inputIsValid() {
    return this.form.valid;
  }

  async resetForm() {
    try {
      this.form.reset(this.resetFormValue);
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  async confirm() {
    if (!this._apiKeyItem?.id) return;
    const { name, expired } = this.form.getRawValue();
    this._submitting = true;
    this.form.disable();

    const validateExpired = expired != '' && expired != null ? new Date(expired) : null;
    const expiredDate = validateExpired ? moment(validateExpired?.toISOString().split('T')[0]).toDate() : null;

    if (expiredDate && expiredDate.getTime() <= Date.now()) {
      this.toastSvc.show({
        type: 'error',
        title: 'Invalid Date',
        text: 'Please select a date in the future and try again!',
      });
      this.form.enable();
      this._submitting = false;
      return;
    }

    try {
      await this.apiKeySvc.update(this._apiKeyItem.id, {
        name: name,
        expired: expiredDate,
      });
      this.resetForm();
      this.toastSvc.show({
        type: 'success',
        title: 'Api key successfully updated!',
        text: `Key ${this._apiKeyItem?.name || this._apiKeyItem?.details} has been updated in the table.`,
      });
    } catch (e) {
      console.error(e);
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: 'Something went wrong and the api-key was not updated. Please try again.',
      });
    }

    this.form.enable();
    this._submitting = false;
    this.closeDialog();
  }

  populateDate(event: Date, menu: MenuTriggerForDirective) {
    if (event.getTime() <= Date.now()) {
      this.toastSvc.show({
        type: 'error',
        title: 'Invalid Date',
        text: 'Please select a date in the future and try again!',
      });
    } else {
      this.form.patchValue({
        expired: event.toISOString().split('T')[0],
      });
      this.form.markAsDirty();
      menu.close();
    }
  }

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.apiKeyItem = null;
    this.resetForm();
    this.keyUpdated.emit();
    this.dialog.close();
  }
}
