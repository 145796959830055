import { Component, ViewChild } from '@angular/core';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-email-preview-dialog',
  templateUrl: './email-preview.dialog.html',
})
export class EmailPreviewDialog {
  @ViewChild(DialogComponent) dialog?: DialogComponent;
  protected email: Policy.Email | null = null;
  protected emailButton: string | null = null;

  private readonly emailButtons = new Map<string, string>([
    [Policy.EmailType.warrantyProvisioned, 'Web Portal'],
    [Policy.EmailType.claimApproved, 'Upload Proof of Repair'],
  ]);

  open(email: Policy.Email) {
    this.email = email;
    this.emailButton = this.emailButtons.get(email.type) ?? null;
    this.dialog?.open();
  }
}
