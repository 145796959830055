<vs-dialog
  *ngIf="item$ | async as item"
  [config]="{ dialogContainerClass: 'max-w-[700px]', startOpen: true }"
  (closed)="navigateTo()"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'file-plus-03',
      iconTheme: 'primary',

      title: (item?.cure?.title ? 'Edit ' + (item?.coverage?.title ?? 'Other') : 'Add ' + (item?.coverage?.title ?? '')) + ' Cure',
      subtitle: (item?.cure?.title ? 'Edit' : 'Set') + ' the cure for this claim item to adjudicate it.'
    }"
  ></vs-dialog-header>

  <form *ngIf="item" class="flex flex-col gap-4 p-4" [formGroup]="form">
    <vs-form-field>
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 basis-1/4">Title</span>
        <input vsInput type="text" formControlName="title" placeholder="Enter cure title" />
      </div>
      <div class="text-center">
        <span
          class="text-sm font-normal text-danger"
          *ngIf="form.controls.title.touched && form.controls.title.invalid"
          slot="hint"
        >
          Title is required and max # of characters is 255
        </span>
      </div>
    </vs-form-field>

    <vs-form-field>
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 self-start basis-1/4">Description</span>
        <textarea
          vsInput
          min="2"
          max="5"
          type="text"
          formControlName="description"
          placeholder="Describe the cure related to the issue for this coverage"
        ></textarea>
      </div>
      <div class="text-center">
        <span
          class="text-sm font-normal text-danger"
          *ngIf="form.controls.description.touched && form.controls.description.invalid"
          slot="hint"
        >
          Descriptions required
        </span>
      </div>
    </vs-form-field>

    <vs-form-field>
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 basis-1/4"> Estimated Cost</span>
        <vs-input-container>
          <p class="text-gray-600" slot="start">$</p>
          <input type="number" formControlName="cost" placeholder="Enter estimated cost" />
        </vs-input-container>
      </div>
      <div class="text-center">
        <span
          class="text-sm font-normal text-danger"
          *ngIf="form.controls.cost.touched && form.controls.cost.invalid"
          slot="hint"
        >
          Cost is required and cannot be $0
        </span>
      </div>
    </vs-form-field>
  </form>

  <div class="flex gap-2 justify-end">
    <button vs-button appearance="outline" (click)="navigateTo()">Cancel</button>
    <button vs-button theme="primary" [disabled]="!form.valid" (click)="save(item)">Save</button>
  </div>
</vs-dialog>
