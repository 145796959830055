import { Component, Input } from '@angular/core';
import { WarrantyCoverageWithLiabilityInfo } from '../coverage-card/coverage-card.component';

@Component({
  selector: 'ws-coverage-liability-limit',
  templateUrl: './coverage-liability-limit.component.html',
})
export class CoverageLiabilityLimitComponent {
  @Input() item: WarrantyCoverageWithLiabilityInfo | null = null;
}
