<div class="w-min p-4">
  <!-- Month and buttons -->
  <div class="flex justify-between">
    <button vs-icon-button [size]="'lg'" [appearance]="'clear'" (click)="prevMonth()">
      <vs-icon name="chevron-left"></vs-icon>
    </button>

    <p class="flex mt-3 font-medium">
      <span class="cursor-pointer mr-2" [vsMenuTriggerFor]="monthMenu" #mnthMenu="vsMenuTriggerFor">
        {{ firstDay.toLocaleString('default', { month: 'long' }) }}
      </span>
      <span class="cursor-pointer" [vsMenuTriggerFor]="yearMenu" #yrMenu="vsMenuTriggerFor">
        {{ firstDay.toLocaleString('default', { year: 'numeric' }) }}
      </span>
    </p>

    <button vs-icon-button [size]="'lg'" [appearance]="'clear'" (click)="nextMonth()">
      <vs-icon name="chevron-right"></vs-icon>
    </button>
  </div>

  <ng-template #monthMenu>
    <vs-menu class="max-h-[300px]">
      <button
        vs-button
        class="flex"
        [appearance]="'clear'"
        *ngFor="let month of months; index as index"
        (click)="selectMonth(index); mnthMenu.close()"
      >
        {{ month }}
      </button>
    </vs-menu>
  </ng-template>

  <ng-template #yearMenu>
    <vs-menu class="max-h-[300px]">
      <button
        vs-button
        class="flex"
        [appearance]="'clear'"
        *ngFor="let year of years"
        (click)="selectYear(year); yrMenu.close()"
      >
        {{ year }}
      </button>
    </vs-menu>
  </ng-template>

  <form *ngIf="range" class="flex gap-2 justify-between" [formGroup]="form">
    <ng-container *vsLet="backwardsDays() as backwardsDays">
      <vs-input-container class="ml-3" [theme]="backwardsDays ? 'danger' : null">
        <input
          #startDateInput
          placeholder="Start"
          class="w-32 text-sm"
          formControlName="startDate"
          (keydown.enter)="selectDayFromInput(startDateInput.value, null)"
        />
      </vs-input-container>

      <p class="self-center">-</p>

      <vs-input-container class="mr-3" [theme]="backwardsDays ? 'danger' : null">
        <input
          #endDateInput
          placeholder="End"
          class="w-32 text-sm"
          formControlName="endDate"
          (keydown.enter)="selectDayFromInput(null, endDateInput.value)"
        />
      </vs-input-container>
    </ng-container>
  </form>

  <!-- Last X time -->
  <div *ngIf="range" class="flex justify-between pt-3 pb-1">
    <button vs-button appearance="clear" theme="primary" (click)="selectLast('week')">Last week</button>
    <button vs-button appearance="clear" theme="primary" (click)="selectLast('month')">Last month</button>
    <button vs-button appearance="clear" theme="primary" (click)="selectLast('year')">Last year</button>
  </div>

  <!-- Days of week -->
  <div class="flex font-light mt-2 -mb-2 ml-3.5 -mr-2">
    <div *ngFor="let day of weekDays" class="w-11 h-11">{{ day }}</div>
  </div>

  <!-- Days of month -->
  <div *ngFor="let week of days" class="flex flex-row mb-2">
    <ng-container *ngFor="let day of week; index as index">
      <ng-container *vsLet="daySelected(day) as selected">
        <div
          *vsLet="dayInRange(day) as dayInRange"
          class="w-11 h-11 relative"
          [class.bg-gray-50]="lastSelectedDay && (dayInRange || selected)"
          [class.rounded-l-full]="
            (dayInRange && index === 0) || (selected && day.getTime() !== lastSelectedDay?.getTime())
          "
          [class.rounded-r-full]="
            (dayInRange && index === 6) || (selected && day.getTime() === lastSelectedDay?.getTime())
          "
        >
          <button
            vs-button
            rounded="true"
            class="w-11 h-11"
            [disabled]="
              (day && minDate && day.getTime() <= minDate.getTime()) || (maxDate && day.getTime() > maxDate.getTime())
            "
            [appearance]="selected ? 'default' : 'clear'"
            [theme]="selected ? 'primary' : 'default'"
            [class.font-normal]="!dayInMonth(day)"
            (click)="selectDay(day)"
          >
            {{ day.getDate() }}
          </button>

          <!-- Today marker -->
          <div *ngIf="today(day)" class="absolute top-8 w-full flex justify-center">
            <div class="w-1.5 h-1.5 rounded-full" [class.bg-primary]="!selected" [class.bg-base]="selected"></div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <button class="float-right m-3" *ngIf="!range" (click)="clearSelection()">Clear Selection</button>
</div>
