import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { Staff } from '@wsphere/staff/domain';
import { StaffService } from '@wsphere/staff/web';
import { StaffResolver } from '../resolvers';

@Injectable()
export class AccountSetupGuard implements CanActivate {
  constructor(
    private firebaseSvc: FirebaseService,
    private resolver: StaffResolver,
    private staffSvc: StaffService,
    private router: Router
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    try {
      const staff = await this.staffSvc.retrieveSelf();

      if (!staff || staff.status === Staff.Status.DEACTIVATED) {
        await this.firebaseSvc.signOut();
        return this.notFound();
      } else if (staff.status === Staff.Status.ACTIVE) {
        return this.router.createUrlTree(['..']);
      }

      this.resolver.staff = staff;
      return true;
    } catch (err) {
      console.error(err);
      await this.firebaseSvc.signOut();
      return this.notFound();
    }
  }

  private notFound() {
    return this.router.createUrlTree(['../sign-in']);
  }
}
