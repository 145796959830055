/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { User } from '@vsolv/core/users/domain';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Activity } from '@vsolv/packages/activities/domain';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { Claim } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, ReplaySubject, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-timeline-activity',
  templateUrl: './claim-timeline-activity.component.html',
})
export class ClaimTimelineActivityComponent {
  constructor(private linkSvc: LinksWebService, private claimSvc: ClaimService) {}

  @PropertyListener('activity') activity$ = new ReplaySubject<Activity.Model>(1);
  @Input() activity: Activity.Model | null = null;

  @PropertyListener('claim') claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim: Claim.Model | null = null;

  @Input() hideLine = false;
  @Input() isCustomerPortal = false;
  @Input() displayType: Link.ObjectType | null = null;

  open = false;

  note = Link.ObjectType.NOTE;
  claimType = Link.ObjectType.CLAIM;
  document = Link.ObjectType.STORAGE_ITEM;
  claimItem = Link.ObjectType.CLAIM_ITEM;
  payment = Link.ObjectType.PAYMENT;

  yellow = '#EAAA08';
  green = '#669F2A';
  blue = '#2970FF';

  leadingText = '';
  trailingText = '';

  badgeText = '';
  badgeColor: ThemeColor | null = null;

  object: unknown | null = null;
  type: Link.ObjectType | null = null;
  config: InfoCardConfig | null = null;

  badgeTexts = ['approved', 'rejected', 'resolved', 'updated the status to draft for', 'voided', 'failed', 'completed'];

  link$ = combineLatest([this.claim$, this.activity$]).pipe(
    switchMap(async ([claim, activity]) =>
      claim && activity
        ? await this.linkSvc.list(
            { id: claim.id, objectType: Link.ObjectType.CLAIM },
            { owner: { id: activity.id, objectType: Link.ObjectType.ACTIVITY } }
          )
        : null
    ),
    map(data => data?.items ?? []),
    map(links => (links.length > 1 ? links.find(link => !link.claimId_1) : links[0])),
    tap(link => (link ? (this.type = Link.getLinkType(link, Link.ObjectType.ACTIVITY)) : '')),
    tap(link => {
      if (link && this.type) {
        this.object = Link.getLinkObject(link, this.type);
        this.config = Link.getLinkInfoCardConfig(link, this.type);

        // Add badges
        if (this.badgeTexts.includes(this.activity?.text ?? '')) {
          if (this.activity?.text === this.badgeTexts[0]) {
            this.badgeText = this.badgeTexts[0];
            this.badgeColor = 'success';
          } else if (this.activity?.text === this.badgeTexts[1]) {
            this.badgeText = this.badgeTexts[1];
            this.badgeColor = 'danger';
          } else if (this.activity?.text === this.badgeTexts[2]) {
            this.badgeText = this.badgeTexts[2];
            this.badgeColor = 'success';
          } else if (this.activity?.text === this.badgeTexts[3]) {
            this.leadingText = 'updated the status to ';
            this.badgeText = 'Reviewing';
            this.badgeColor = 'default';
            this.trailingText = 'for';
          } else if (this.activity?.text === this.badgeTexts[4]) {
            this.badgeText = this.badgeTexts[4];
            this.badgeColor = 'default';
          } else if (this.activity?.text === this.badgeTexts[5]) {
            this.badgeText = this.badgeTexts[5];
            this.badgeColor = 'danger';
          } else if (this.activity?.text === this.badgeTexts[6]) {
            this.badgeText = this.badgeTexts[6];
            this.badgeColor = 'success';
          }
        }

        // Make router links
        if (!(this.object as any)?.deleted) {
          if (this.type === Link.ObjectType.CLAIM_ITEM) {
            this.config.routerLink = link.claimItem_1?.id;

            if (this.activity?.text.includes('resol')) this.config.routerLink += '/resolve';
            else if (this.activity?.text.includes('approv') && !this.isCustomerPortal) {
              this.config.routerLink += '/adjudicate';
            } else if (this.activity?.text.includes('cure') || this.activity?.text.includes('amount')) {
              this.config.routerLink += '/cure';
            }
          } else if (this.type === Link.ObjectType.NOTE) {
            this.config.routerLink = 'notes';
            this.config.queryParams = { noteId: (this.object as any)?.id };
          } else if (this.type === Link.ObjectType.STORAGE_ITEM) {
            this.config.routerLink = 'attachments';
            this.config.queryParams = { documentId: (this.object as any)?.id };
          } else if (this.type === Link.ObjectType.PAYMENT) {
            this.config.routerLink = `payments/${(this.object as any)?.id}`;
          }
        }
      }
    })
  );

  getInitials(user?: User.Model | null) {
    if (user) {
      const name = user.displayName?.split(' ');
      if (!name?.length) return 'WS';

      const first = name[0]?.charAt(0);
      const last = name[1]?.charAt(0);
      return (first ?? '') + (last ?? '');
    } else return 'WS';
  }

  getItemTheme(item?: Claim.ClaimItem.Model) {
    return item ? (item.deleted ? 'info' : Claim.ClaimItem.getTheme(item.status)) : 'default';
  }

  getNoteContent(content: string) {
    return content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '');
  }

  getTypeColor(type: Link.ObjectType | null) {
    return type === this.note
      ? this.yellow
      : type === this.document
      ? this.green
      : type === this.payment
      ? this.blue
      : '';
  }
}
