import { Component, ElementRef, Injector, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NgControl, NG_VALIDATORS, Validator } from '@angular/forms';
import { ThemeUtils } from '@vsolv/vectors-ui/theming';

@Component({
  selector: 'vs-color-input',
  templateUrl: './color-input.component.html',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ColorInputComponent,
    },
  ],
})
export class ColorInputComponent implements OnInit, ControlValueAccessor, Validator {
  @Input() colorPicker?: HTMLInputElement;
  @ViewChild('colorInput') colorInput?: ElementRef;

  color?: string | null;
  touched = false;
  disabled = false;
  dispatched = false;
  openPicker = false;

  validate = ThemeUtils.validateColor;
  getHex = ThemeUtils.getHex;
  control?: NgControl;

  constructor(private injector: Injector, private renderer: Renderer2, private fb: FormBuilder) {}

  inputUpdate(event: Event) {
    const currentValue = (event.target as HTMLInputElement)?.value;
    this.partialInputUpdate(currentValue);
  }

  partialInputUpdate(currentValue: string) {
    this.onChange(ThemeUtils.valid(currentValue) ? ThemeUtils.getColor(currentValue).hex('rgb') : currentValue);
    if (this.colorPicker && ThemeUtils.valid(currentValue)) {
      this.colorPicker.value = ThemeUtils.getColor(currentValue).hex('rgb');
      this.dispatched = true;
      this.colorPicker.dispatchEvent(new Event('input', { bubbles: true }));
    }
    this.markAsTouched();
  }

  ngOnInit() {
    this.control = this.injector.get(NgControl);
    this.control.valueAccessor = this;
    this.renderer.listen(this.colorPicker, 'input', () => {
      const pickerColor = this.colorPicker?.value;
      if (!this.dispatched && pickerColor) {
        this.color = pickerColor;
        this.onChange(this.color);
      }
      this.dispatched = false;
    });
  }

  onChange = (_color: string) => {
    /* noop */
  };

  onTouched = () => {
    /* noop */
  };

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  writeValue(color: string): void {
    this.color = null;
    this.color = color;
    this.partialInputUpdate(color);
  }

  registerOnChange(onChange: (color: string | null) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }
}
