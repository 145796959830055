<div class="flex flex-col justify-center items-center w-full gap-4 mt-16">
  <div class="flex flex-col justify-center items-center w-full gap-4" *ngIf="!success">
    <vs-featured-icon name="key-01" theme="primary"></vs-featured-icon>
    <h1 class="text-3xl">Set new password</h1>
    <p class="text-gray max-w-[360px] text-center">A strong password is important for keeping your account safe.</p>
    <form [formGroup]="formGroup" class="flex justify-center w-full">
      <div class="flex flex-col flex-wrap gap-4 justify-center items-center w-full max-w-[360px]">
        <vs-form-field [config]="{ label: 'Password' }">
          <input vsInput #passwordInput type="password" placeholder="Password" formControlName="password" />
          <span
            class="text-danger"
            slot="hint"
            *ngIf="(passwordInput.value.length > 0 && formGroup.get('password')?.touched && formGroup.get('password')?.errors ? 'Your new password must be at least 8 characters. It must also contain uppercase letters, lowercase letters, and numbers.' : null) as error"
            >{{error}}</span
          >
        </vs-form-field>

        <vs-form-field [config]="{ label: 'Confirm password' }">
          <input
            vsInput
            #confirmInput
            type="password"
            placeholder="Confirm password"
            formControlName="confirmPassword"
          />
          <span
            class="text-danger"
            slot="hint"
            *ngIf="(passwordInput.value !== confirmInput.value && formGroup.get('confirmPassword')?.touched ||  passwordInput.value !== confirmInput.value && passwordInput.value.length === confirmInput.value.length ? 'Passwords do not match!' : null) as error"
            >{{error}}</span
          >
        </vs-form-field>

        <button
          class="w-full"
          theme="primary"
          [disabled]="!inputIsValid() || passwordInput.value !== confirmInput.value"
          vs-button
          appearance="default"
          size="md"
          (click)="submit()"
        >
          <span>Reset password</span>
        </button>
      </div>
    </form>
  </div>
  <div class="flex flex-col justify-center items-center w-full gap-4" *ngIf="success">
    <vs-featured-icon name="check-circle" theme="success"></vs-featured-icon>
    <h1 class="text-4xl">Password Reset</h1>
    <p class="text-gray-500">Your password has been successfully reset.</p>
    <p class="text-gray-500">Click below to enter the site.</p>
    <a
      class="w-full max-w-[360px]"
      theme="primary"
      [disabled]="!inputIsValid()"
      vs-button
      appearance="default"
      size="md"
      routerLink="../"
    >
      Enter portal
    </a>
  </div>
  <a class="text-gray-600 flex justify-center items-center gap-1" routerLink="/sign-in">
    <vs-icon name="arrow-left" type="solid"></vs-icon> Back to log in
  </a>
</div>
