import { User } from '@vsolv/core/users/domain';
import { Staff } from './index';

export enum IdField {
  id = 'id',
  email = 'email',
  userId = 'userId',
  referenceId = 'referenceId',
}

export enum Status {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

export interface Model {
  id: string;
  referenceId: string | null;

  created: Date;
  modified: Date;

  status: Status;

  email: string;
  name: string | null;
  phone: string | null;

  userId: string | null;
  user?: User.Model;

  serviceAccount: boolean;

  roles?: Staff.Security.RoleAssignment[];
}
