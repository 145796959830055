import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

@Component({
  selector: 'ws-condition-property-picker',
  templateUrl: './condition-property-picker.component.html',
  styleUrls: ['./condition-property-picker.component.scss'],
})
export class ConditionPropertyPickerComponent {
  @Input() roundRightBorder = true;

  @PropertyListener('propertySet') propertySet$ = new BehaviorSubject<PropertySet.Model | null>(null);
  @Input() propertySet!: PropertySet.Model;

  overlayOpen = false;

  @PropertyListener('selectedPropertyPath') selectedPropertyPath$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() selectedPropertyPath?: string | null;

  @Input() value: {
    property: Property.Model<Property.PropertyType> | Conditions.Property;
    propertyPath: string;
  } | null = null;

  @Output() selectionChanged = new EventEmitter<{
    property: {
      property: Property.Model<Property.PropertyType> | Conditions.Property;
      propertyPath: string;
    } | null;
    clearValue: boolean;
  }>();

  properties$ = this.propertySet$.pipe(
    map(set => {
      const hardcodedProperties = Conditions.getProperties();

      const setProperties =
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        set?.properties?.sort((a, b) => a.order - b.order).map(assignment => assignment.property!) ?? [];

      return [...hardcodedProperties, ...setProperties];
    })
  );

  selectedProperty$ = combineLatest([this.properties$, this.selectedPropertyPath$]).pipe(
    map(([properties, selectedPropertyPath]) => {
      if (selectedPropertyPath && selectedPropertyPath !== this.value?.propertyPath) {
        const property = this.getProperty(selectedPropertyPath, properties);
        if (property) {
          this.setValue({ property, propertyPath: selectedPropertyPath }, false);
        }
      }
    })
  );

  private getProperty(
    propertyPath: string,
    properties: (Property.Model<Property.PropertyType> | Conditions.Property)[]
  ): Property.Model<Property.PropertyType> | Conditions.Property | undefined {
    for (const property of properties) {
      if (property.valueKey === propertyPath) {
        return property;
      } else if (propertyPath.startsWith(property.valueKey)) {
        return this.getProperty(
          propertyPath.replace(property.valueKey + '.', ''),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (property as Property.ObjectModel).properties?.map(assignment => assignment.property!) ?? []
        );
      }
    }
    return undefined;
  }

  setValue(
    value: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null,
    clearValue: boolean
  ) {
    this.value = value;
    this.selectionChanged.emit({ property: value, clearValue });
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }
}
