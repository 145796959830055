<vs-dialog
  [config]="{ 
    disableBackdropDismiss: submitting,
    dialogContainerClass: 'w-[80vw] max-w-lg'
  }"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      hideCloseButton: submitting,

      icon: 'bar-chart-square-plus',
      iconTheme: 'primary',

      title: 'Update dashboard details',
      subtitle: 'Update the dashoard\'s title, description and icon'
    }"
  ></vs-dialog-header>

  <form class="pt-4 flex flex-col gap-3" [formGroup]="form">
    <vs-form-field [config]="{ label: 'Dashboard Title' }">
      <input required vsInput type="text" placeholder="My Dashboard" formControlName="title" />
    </vs-form-field>

    <vs-form-field [config]="{ label: 'Dashboard Description' }">
      <textarea required vsInput placeholder="My dashboard's description" formControlName="description"></textarea>
    </vs-form-field>

    <vs-form-field [config]="{ label: 'Embed URL' }" class="-mt-1">
      <input required vsInput type="url" placeholder="https://..." formControlName="embedUrl" />
    </vs-form-field>

    <vs-form-field
      *ngIf="form.controls.isDefault.enabled"
      class="mt-1"
      appearance="horizontal"
      [config]="{
        label: 'Default Dashboard',
        description: 'Make this the default dashboard for all users'
      }"
    >
      <div class="flex items-center gap-2">
        <input vsInput id="is-default-checkbox" type="checkbox" appearance="toggle" formControlName="isDefault" />
        <label vsCheckboxLabel for="is-default-checkbox">Default</label>
      </div>
    </vs-form-field>
  </form>

  <div class="mt-4 flex justify-end gap-3">
    <button vs-button appearance="outline" [disabled]="submitting" (click)="close()">Cancel</button>
    <button vs-button theme="primary" [disabled]="submitting || form.invalid" (click)="submit()">
      Update dashboard
    </button>
  </div>
</vs-dialog>
