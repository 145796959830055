import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Distributor } from '@wsphere/distributors/domain';
import { SftpService } from '@wsphere/sftp/web';
import { SecurityService, StaffService } from '@wsphere/staff/web';
import { BehaviorSubject, combineLatest, from, map, Observable, shareReplay, switchMap } from 'rxjs';
import {
  ArchiveDistributorDialog,
  CreateDistributorOnboardingLinkDialog,
  DeactivateDistributorDialog,
  ReactivateDistributorDialog,
  SelectedPageType,
} from '../../dialogs';
import { DistributorService } from '../../services';

@Component({ templateUrl: './distributor.page.html' })
export class DistributorPage {
  constructor(
    private router: Router,
    private clipboard: Clipboard,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private staffSvc: StaffService,
    private portalSvc: PortalService,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService,
    private sftpSvc: SftpService
  ) {}

  @ViewChild('archiveDistributorDialog') archiveDistributor?: ArchiveDistributorDialog;
  @ViewChild('deactivateDistributorDialog') deactivateDistributor?: DeactivateDistributorDialog;
  @ViewChild('reactivateDistributorDialog') reactivateDistributor?: ReactivateDistributorDialog;
  @ViewChild('createLinkDialog') createLinkDialog?: CreateDistributorOnboardingLinkDialog;

  staff$ = from(this.staffSvc.retrieveSelf()).pipe(shareReplay(1));

  private distributorUpdated$ = new BehaviorSubject<Distributor.Model | null>(null);
  readonly distributor$ = combineLatest([this.route.data, this.distributorUpdated$]).pipe(
    map(([data]) => {
      const hideBreadcrumbs = data['settings'] as boolean;
      this.hideBreadcrumbs = hideBreadcrumbs;

      const distributor = data['distributor'] as Distributor.Model;
      return distributor;
    }),
    switchMap(distributor =>
      this.distributorUpdated$.pipe(map(updatedDistributor => updatedDistributor ?? distributor))
    )
  );

  readonly checkoutLink$ = this.distributor$.pipe(
    switchMap(async distributor => {
      const { url } = await this.portalSvc.getPortalUrl();
      return `${url}/checkout?distributorId=${distributor.id}`;
    })
  );

  readonly canOnboardDistributor$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(
      async ([distributor]) =>
        await this.securitySvc.hasAccess(
          'dist_OnboardDistributor',
          distributor?.permissionKey ? [distributor.permissionKey] : null
        )
    )
  );

  readonly canEditDistributor$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(
      async ([distributor]) =>
        await this.securitySvc.hasAccess(
          'dist_EditDetails',
          distributor.permissionKey ? [distributor.permissionKey] : null
        )
    )
  );

  readonly canViewDistributor$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(
      async ([distributor]) =>
        await this.securitySvc.hasAccess(
          'dist_ViewDistributor',
          distributor.permissionKey ? [distributor.permissionKey] : null
        )
    )
  );

  readonly canUpdateStatus$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(async ([distributor]) =>
      this.securitySvc.hasAccess('dist_EditStatus', distributor?.permissionKey ? [distributor.permissionKey] : null)
    )
  );

  readonly canViewStaff$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(async ([distributor]) =>
      this.securitySvc.hasAccess('dist_ViewStaff', distributor?.permissionKey ? [distributor.permissionKey] : null)
    )
  );

  readonly sftpEnabled$ = this.distributor$.pipe(
    switchMap(async dist =>
      dist && dist.status !== Distributor.Status.ARCHIVED
        ? (await this.sftpSvc.getSettings())?.distributorEnabled
        : false
    )
  );

  hideBreadcrumbs = true;

  readonly archived = Distributor.Status.ARCHIVED;
  readonly activated = Distributor.Status.ACTIVE;
  readonly deactivated = Distributor.Status.DEACTIVATED;

  manageLinks = SelectedPageType.MANAGE_LINKS;

  async refreshDistributor(distributorId: string) {
    const distributor = await this.distributorSvc.getOne(distributorId);
    this.distributorUpdated$.next(distributor);
  }

  navigateTo(distributorId?: string) {
    if (distributorId) this.router.navigate([`../${distributorId}`], { relativeTo: this.route });
    else this.router.navigate([`../`], { relativeTo: this.route });
  }

  copyCheckoukLink(checkoutLink: string | null) {
    if (checkoutLink) {
      this.clipboard.copy(checkoutLink);
      this.toastSvc.show({
        type: 'success',
        title: 'Copied to clipboard!',
        text: `Link copied to clipboard or <a class="text-primary" href="${checkoutLink}">click here</a>.`,
      });
    }
  }
}
