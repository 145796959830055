import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CreateCustomerOptions } from '@vsolv/dwolla/domain';
import { BehaviorSubject } from 'rxjs';

export type AddBankDetailsComponentState = 'customer' | 'fundingSource';

@Component({
  selector: 'vs-add-bank-details',
  templateUrl: './add-bank-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddBankDetailsComponent {
  @Output() closeDialog = new EventEmitter<void>();
  @Output() bankDetails = new EventEmitter<{ fundingSourceId: string }>();

  @Input() customer: CreateCustomerOptions | null = null;

  protected readonly refresh$ = new BehaviorSubject<boolean>(true);

  readonly _customerId$ = new BehaviorSubject<string | null>(null);
  @Input() set customerId(customerId: string | null) {
    this.state = customerId ? 'fundingSource' : 'customer';
    this._customerId$.next(customerId);
  }
  get customerId() {
    return this._customerId$.value;
  }

  @Input() isCustomerRequest = false;

  protected state: AddBankDetailsComponentState = 'customer';

  close() {
    this.closeDialog.emit();
  }

  confirmDetails(fundingSourceId: string) {
    this.bankDetails.emit({ fundingSourceId });
    this.close();
    if (this.state === 'fundingSource') this.refreshState();
  }

  private refreshState() {
    this.refresh$.next(false);
    setTimeout(() => {
      this.refresh$.next(true);
    }, 1);
  }
}
