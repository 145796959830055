import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

export interface PropertyWithSameType {
  property: Property.Model<Property.PropertyType> | Conditions.Property;
  children?: PropertyWithSameType[];
  isType: boolean;
}

@Component({
  selector: 'ws-condition-value-property-picker',
  templateUrl: './condition-value-property-picker.component.html',
})
export class ConditionValuePropertyPickerComponent {
  @Input() disabled = false;

  @PropertyListener('propertySet') propertySet$ = new BehaviorSubject<PropertySet.Model | null>(null);
  @Input() propertySet: PropertySet.Model | null = null;

  @PropertyListener('type') type$ = new BehaviorSubject<Conditions.PropertyType>(Conditions.PropertyType.TEXT);
  @Input() type!: Conditions.PropertyType;

  @PropertyListener('format') format$ = new BehaviorSubject<string | null>(null);
  @Input() format: string | null = null;

  overlayOpen = false;

  @PropertyListener('selectedPropertyPath') selectedPropertyPath$ = new BehaviorSubject<string | undefined>(undefined);
  @Input() selectedPropertyPath?: string | null;

  @Input() value: {
    property: Property.Model<Property.PropertyType> | Conditions.Property;
    propertyPath: string;
  } | null = null;

  @Output() selectionChanged = new EventEmitter<{
    property: {
      property: Property.Model<Property.PropertyType> | Conditions.Property;
      propertyPath: string;
    } | null;
  }>();

  properties$ = combineLatest([this.propertySet$, this.type$, this.format$]).pipe(
    switchMap(async ([set]) => {
      if (!set) return [];

      return this.mapProperties([
        ...Conditions.getProperties(),
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...set.properties!.map(assignment => assignment.property!),
      ]);
    })
  );

  selectedProperty$ = combineLatest([this.properties$, this.selectedPropertyPath$]).pipe(
    map(([properties, selectedPropertyPath]) => {
      if (selectedPropertyPath && selectedPropertyPath !== this.value?.propertyPath) {
        const property = this.getProperty(
          selectedPropertyPath,
          properties.map(prop => prop.property)
        );
        if (property) {
          this.setValue({ property, propertyPath: selectedPropertyPath });
        }
      }
    })
  );

  private getProperty(
    propertyPath: string,
    properties: (Property.Model<Property.PropertyType> | Conditions.Property)[]
  ): Property.Model<Property.PropertyType> | Conditions.Property | undefined {
    for (const property of properties) {
      if (property.valueKey === propertyPath) {
        return property;
      } else if (propertyPath.startsWith(property.valueKey)) {
        return this.getProperty(
          propertyPath.replace(property.valueKey + '.', ''),
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (property as Property.ObjectModel).properties?.map(assignment => assignment.property!) ?? []
        );
      }
    }
    return undefined;
  }

  setValue(
    value: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null
  ) {
    this.value = value;
    this.selectionChanged.emit({ property: value });
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  isType(property: Property.Model<Property.PropertyType> | Conditions.Property) {
    return property.type === this.type && (this.format ? property.config.format === this.format : true);
  }

  containsType(property: Property.Model<Property.PropertyType> | Conditions.Property) {
    if (this.isType(property)) return true;

    if (property.type !== Property.PropertyType.OBJECT) return false;

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (const child of (property as Property.ObjectModel).properties!) {
      const containsType = this.containsType(child.property as Property.ObjectModel);
      if (containsType) return true;
    }

    return false;
  }

  private mapProperties(properties: (Property.Model<Property.PropertyType> | Conditions.Property)[]) {
    const validProperties: PropertyWithSameType[] = [];
    for (const property of properties) {
      if (this.isType(property)) {
        validProperties.push({ property: property as Property.ObjectModel, isType: true });
      } else if (this.containsType(property)) {
        const children = this.mapProperties(
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          (property as Property.ObjectModel).properties!.map(assignment => assignment.property!)
        );
        validProperties.push({ property: property, children, isType: false });
      }
    }
    return validProperties;
  }
}
