<form [formGroup]="form" class="flex gap-2">
  <vs-input-group *vsLet="form.value as condition" class="flex-1">
    <ws-condition-property-picker
      vsInput
      class="w-full"
      [propertySet]="propertySet"
      [selectedPropertyPath]="form.value.propertyId"
      (selectionChanged)="setPropertyId($event.property, $event.clearValue)"
      [roundRightBorder]="!form.value.propertyId"
    ></ws-condition-property-picker>

    <vs-select
      *ngIf="selectedProperty?.type === date || selectedProperty?.type === num"
      vsInput
      class="!max-w-[33.33%] !min-w-[33.33%]"
      formControlName="operator"
    >
      <li *ngFor="let operator of generalOperators" vs-select-option [value]="operator">
        {{ operator }}
      </li>
    </vs-select>

    <vs-select
      *ngIf="selectedProperty?.type === bool"
      vsInput
      class="!max-w-[33.33%] !min-w-[33.33%]"
      formControlName="operator"
    >
      <li *ngFor="let operator of booleanOperators" vs-select-option [value]="operator">
        {{ operator }}
      </li>
    </vs-select>

    <vs-select
      *ngIf="selectedProperty?.type === text"
      vsInput
      class="!max-w-[33.33%] !min-w-[33.33%]"
      formControlName="operator"
    >
      <li *ngFor="let operator of textOperators" vs-select-option [value]="operator">
        {{ operator }}
      </li>
    </vs-select>

    <vs-input-container
      *ngIf="selectedProperty && form.value.operator !== isNull && form.value.operator !== isNotNull"
      class="!max-w-[33.33%] !min-w-[33.33%]"
    >
      <!-- Value Property Badge -->
      <div *ngIf="form.value.valuePropertyId" class="w-fit max-w-[96px] pt-[5px]">
        <vs-badge
          theme="default"
          [vsTooltip]="form.value.valuePropertyId"
          tooltipPosition="top"
          class="w-full h-4"
          (click)="setValuePropertyId(null); valuePropertyPicker.setValue(null)"
        >
          <span class="w-full truncate">{{ form.value.valuePropertyId }}</span>
          <vs-icon name="x-close" class="cursor-pointer"></vs-icon>
        </vs-badge>
      </div>

      <ws-condition-value-property-picker
        #valuePropertyPicker
        class="pt-1"
        slot="end"
        [propertySet]="propertySet"
        [selectedPropertyPath]="form.value.valuePropertyId"
        [type]="selectedProperty.type"
        [format]="selectedProperty.config.format"
        (selectionChanged)="setValuePropertyId($event.property)"
      ></ws-condition-value-property-picker>

      <!-- Manual Inputs -->
      <div *ngIf="!form.value.valuePropertyId" class="w-full">
        <!-- Text Property -->
        <vs-text-input
          class="w-full"
          *ngIf="selectedProperty?.type === text"
          [property]="$any(selectedProperty)"
          [formControl]="$any(form.get('value'))"
          [allowBlockFormat]="false"
        ></vs-text-input>

        <!-- Date Property -->
        <vs-date-property-input
          class="w-full"
          *ngIf="selectedProperty?.type === date"
          [property]="$any(selectedProperty)"
          [formControl]="$any(form.get('value'))"
          [showPicker]="false"
        ></vs-date-property-input>

        <!-- Number Property -->
        <vs-number-input
          class="w-full"
          *ngIf="selectedProperty?.type === num"
          [property]="$any(selectedProperty)"
          [formControl]="$any(form.get('value'))"
        ></vs-number-input>

        <!-- Boolean Property -->
        <div *ngIf="selectedProperty?.type === bool" [vsMenuTriggerFor]="boolMenu" class="pt-2">
          <input #propertyInput type="text" [value]="form.value.value?.toString() | titlecase" />

          <ng-template #boolMenu>
            <vs-menu vsInput class="flex flex-col max-h-[600px]">
              <button vs-menu-item appearance="clear" class="p-2 w-full text-left" (click)="updateValue(true)">
                True
              </button>

              <button vs-menu-item appearance="clear" class="p-2 w-full text-left" (click)="updateValue(false)">
                False
              </button>
            </vs-menu>
          </ng-template>
        </div>
      </div>
    </vs-input-container>
  </vs-input-group>

  <button vs-icon-button appearance="clear" class="mb-2 h-[40px] w-[40px]" (click)="removeCondition()">
    <vs-icon name="x"></vs-icon>
  </button>
</form>
