/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Claim } from '@wsphere/warranties/domain';
import { map, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-item-cure-dialog',
  templateUrl: './claim-item-cure.dialog.html',
})
export class ClaimItemCureDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private formBuilder: FormBuilder
  ) {}

  readonly claim$ = this.claimSvc.getClaim();

  readonly item$ = this.route.data.pipe(
    map(data => data['item'] as Claim.ClaimItem.Model),
    tap(item => {
      if (item) {
        this.form.patchValue({
          title: item.cure?.title,
          description: item.cure?.description,
          cost:
            item.cure?.estimatedCost !== null && item.cure?.estimatedCost !== undefined
              ? (item.cure?.estimatedCost || 0) / 100
              : null,
        });
      }
    })
  );

  form = this.formBuilder.group({
    title: ['', [Validators.required, Validators.maxLength(255)]],
    description: ['', Validators.required],
    cost: [null as number | null, [Validators.required, Validators.min(0)]],
  });

  async save(item: Claim.ClaimItem.Model) {
    const value = this.form.value;

    const itemId = await this.claimSvc
      .updateItem(item.claimId, item.id, {
        cure: {
          title: value.title,
          description: value.description,
          estimatedCost: (value.cost || 0) * 100,
        },
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.navigateTo();
      });

    if (itemId) {
      this.toastSvc.show({
        type: 'success',
        title: (item.coverage?.title ?? 'Other') + ' Cure ' + (item.cure?.title ? 'Edited' : 'Added'),
        text: item.cure?.title
          ? 'The cure has been successfully updated.'
          : "The claim item has had it's cure successfully added.",
      });

      this.navigateTo();
    }

    this.navigateTo();
  }

  navigateTo(path?: string) {
    this.router.navigate([path ?? `../cure`], { relativeTo: this.route });
  }
}
