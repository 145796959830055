import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Staff } from '@wsphere/staff/domain';

@Injectable()
export class StaffResolver implements Resolve<Staff.Model | null> {
  staff: Staff.Model | null = null;

  resolve() {
    return this.staff;
  }
}
