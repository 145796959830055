/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'vs-property-value-item',
  templateUrl: './property-value-item.component.html',
})
export class PropertyValueItemComponent {
  @PropertyListener('assignment') assignment$ = new BehaviorSubject<
    Property.ObjectPropertyAssignment | PropertySet.PropertyAssignment | null
  >(null);
  @Input() assignment!: Property.ObjectPropertyAssignment | PropertySet.PropertyAssignment;

  @PropertyListener('propertyValue') propertyValue$ = new BehaviorSubject<any>(null);
  @Input() propertyValue: any;
  @Input() showHidden = false;
  @Input() showBadge = true;
  @Input() showLiteralBoolean = false;
  expanded = false;

  childObjects$ = this.assignment$.pipe(
    map(assignment => {
      if (!assignment) return [];
      const property = assignment.property!;
      if (property.type !== Property.PropertyType.OBJECT) return [];

      return (
        (property as Property.ObjectModel).properties?.filter(
          assignment => assignment.property?.type === Property.PropertyType.OBJECT
        ) ?? []
      );
    })
  );

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}
