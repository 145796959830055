import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PortalService } from '@vsolv/packages/portal-config/web';

@Component({
  selector: 'ws-no-plans-available-card',
  templateUrl: './no-plans-available-card.component.html',
})
export class NoPlansAvailableCardComponent {
  constructor(private portalSvc: PortalService) {}
  @Input() propertySetName = 'asset';
  @Input() hideBack = false;

  @Output() back = new EventEmitter();

  contactEmail = this.portalSvc.config.contactEmail;

  goBack() {
    this.back.emit();
  }
}
