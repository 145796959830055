<vs-dialog
  *vsLet="documentUpdate$ | async"
  #dialog
  [config]="{ disableBackdropDismiss: submitting, dialogContainerClass: 'w-[584px] max-w-[600px] max-h-[90%]' }"
  (closed)="closed.emit()"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,

      icon: 'attachment-02', 
      iconTheme: 'primary', 

      title: 'Edit ' + document?.title + ' Attachment', 
      subtitle: 'Edit the details of an attachment for your claim.', 

      hideCloseButton: submitting
    }"
  ></vs-dialog-header>

  <ng-container *ngIf="document">
    <ng-container *vsLet="isMobile$ | async as isMobile">
      <form [formGroup]="form" class="flex flex-col gap-4 px-4 mt-4">
        <!-- Title -->
        <vs-form-field appearance="responsive" [config]="{label: 'Title'}">
          <input vsInput type="text" formControlName="title" />
        </vs-form-field>

        <!-- Description -->
        <vs-form-field appearance="responsive" [config]="{label: 'Description'}" class="w-full">
          <textarea vsInput formControlName="description"></textarea>
        </vs-form-field>

        <!-- Document preview and Delete -->
        <div class="w-full rounded-lg border flex flex-row gap-5 pl-6 pr-6 pt-4 pb-4">
          <ws-claim-document-preview [document]="document" size="xs" class="w-12 h-12"></ws-claim-document-preview>
          <small class="self-center text-start w-full truncate">{{ document.originalFilename }} </small>

          <div class="self-center w-9 h-9">
            <button
              vs-icon-button
              appearance="outline"
              *ngIf="isCustomerPortal || (canDelete$ | async)"
              (click)="openDeleteDialog()"
            >
              <vs-icon name="trash-01" size="20" class="text-gray-500 p-2"></vs-icon>
            </button>
          </div>
        </div>

        <!-- Links -->
        <ws-claim-link-picker
          *ngIf="!isCustomerPortal"
          formControlName="links"
          [claim]="claim"
          [id]="document.id"
        ></ws-claim-link-picker>

        <!-- Customer Visibility -->
        <div class="flex flex-row gap-2" *ngIf="!isCustomerPortal">
          <input vsInput type="checkbox" class="self-center" formControlName="visibleToCustomer" />
          <small class="self-center">Make this attachment visible to customer</small>
        </div>
      </form>

      <!-- Buttons -->
      <div class="flex flex-row justify-end gap-2 mx-4 my-4" *ngIf="!isMobile">
        <button vs-button appearance="outline" [disabled]="submitting" (click)="close()">Cancel</button>
        <button
          vs-button
          theme="primary"
          [disabled]="!form.dirty || form.invalid || submitting"
          (click)="submit(document)"
        >
          Save
        </button>
      </div>

      <div class="flex flex-row justify-center gap-3 mt-4" *ngIf="isMobile">
        <button vs-button appearance="outline" class="flex-grow" [disabled]="submitting" (click)="close()">
          Cancel
        </button>

        <button
          vs-button
          theme="primary"
          class="flex-grow"
          [disabled]="!form.dirty || form.invalid || submitting"
          (click)="submit(document)"
        >
          Save
        </button>
      </div>
    </ng-container>
  </ng-container>
</vs-dialog>

<vs-dialog #deleteDialog [config]="{ dialogContainerClass: 'max-w-[600px]'}">
  <vs-dialog-header
    [config]="{
      mode: 'simple',

      icon: 'attachment-02',
      iconTheme: 'warn',

      title: 'Remove Attachment',
      subtitle: 'Are you sure you want to remove <strong>' + (document?.title ?? 'this Attachment') + '</strong>? This action cannot be undone.'
    }"
  ></vs-dialog-header>

  <div class="flex gap-2">
    <button vs-button appearance="outline" class="flex-1" (click)="closeDeleteDialog()">Back</button>
    <button vs-button theme="danger" class="flex-1" (click)="delete()">Remove</button>
  </div>
</vs-dialog>
