import { Directive, HostBinding } from '@angular/core';

@Directive({
  standalone: true,
  selector: `
    [vsRadioLabel],
    [vsCheckboxLabel]
  `,
})
export class CheckboxLabelDirective {
  @HostBinding('class') get styles() {
    const text = `text-sm text-gray-700 font-medium`;
    const disabled = `peer-disabled/checkbox:opacity-50 peer-disabled/checkbox:cursor-not-allowed`;
    return `${text} ${disabled}`;
  }
}
