import { Component, Inject, inject } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { API_MODE, TenantService } from '@vsolv/core/multi-tenant/web';
import { API_BASE_URL } from '@vsolv/dev-kit/ngx';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, combineLatest, map, switchMap } from 'rxjs';

@Component({
  selector: 'ws-reporting-page',
  templateUrl: './reporting.page.html',
})
export class ReportingPage {
  constructor(
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    private tenantSvc: TenantService,
    private ngFireAuth: AngularFireAuth,
    private securitySvc: SecurityService,
    @Inject(API_MODE) private apiMode: string
  ) {}

  private apiBaseUrl = inject(API_BASE_URL);

  startDate$ = new BehaviorSubject<Date | null>(null);
  endDate$ = new BehaviorSubject<Date | null>(null);

  canViewReports$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('rep_ViewReports', permissionKeys);
    })
  );

  canGenerateReports$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(globalDistributors => {
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      return this.securitySvc.hasAccess('rep_GenerateReports', permissionKeys);
    })
  );

  protected exportUrl$ = combineLatest([
    this.canGenerateReports$,
    this.ngFireAuth.idToken,
    this.startDate$,
    this.endDate$,
  ]).pipe(
    map(([canGenerateReports, idToken, startDate, endDate]) => {
      if (!canGenerateReports) return null;

      const params = [
        `tenantId=${this.tenantSvc.tenantId}`,
        `mode=${this.apiMode}`,
        `idToken=${idToken}`,
        `from=${startDate}`,
        `to=${endDate}`,
      ].join('&');

      return `${this.apiBaseUrl}/v1/admin-dashboard/reporting/remittance-report?${params}`;
    })
  );
}
