<ng-container *vsLet="payment$ | async as payment">
  <vs-dialog
    (closed)="paymentForm.reset(); isEditing = false"
    #dialog
    [config]="{ dialogContainerClass: 'w-[500px] max-w-[800px]' }"
  >
    <vs-dialog-header
      [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'bank-note-01',
      iconTheme: 'primary',
      
      title: payment ? 'Edit ' + (payment.title) : 'Create new payment',
      subtitle: payment ? 'Edit the details of ' + (payment.title) + '.' : 'Fill out the details below to create a payment for this claim.'
    }"
    ></vs-dialog-header>

    <ng-container *ngIf="claim$ | async as claim">
      <ng-container *ngIf="lazyLoad$ | async; else isLoading">
        <form class="flex flex-col gap-4 p-4" [formGroup]="paymentForm">
          <vs-form-field
            [config]="{ label: 'Title', labelClass: '!w-[105px]'}"
            [theme]="paymentForm.controls.title.touched && paymentForm.controls.title.invalid ? 'danger' : 'primary'"
            appearance="horizontal"
          >
            <input vsInput type="text" formControlName="title" placeholder="Enter payment title" class="w-full" />
            <div
              *ngIf="paymentForm.controls.title.touched && paymentForm.controls.title.invalid"
              class="text-danger-600"
              slot="hint"
            >
              Title is required and max # of characters is 255
            </div>
          </vs-form-field>

          <vs-form-field
            [config]="{ label: 'Description', labelClass: '!w-[105px]'}"
            [theme]="paymentForm.controls.description.touched && paymentForm.controls.description.invalid ? 'danger' : 'primary'"
            appearance="horizontal"
          >
            <textarea
              vsInput
              min="2"
              max="5"
              type="text"
              formControlName="description"
              placeholder="Describe payment details"
            ></textarea>

            <div
              *ngIf="paymentForm.controls.description.touched && paymentForm.controls.description.invalid"
              class="text-danger-600"
              slot="hint"
            >
              Description is required
            </div>
          </vs-form-field>

          <vs-form-field
            [config]="{ label: 'Destination', labelClass: '!w-[105px]'}"
            appearance="horizontal"
            *vsLet="destinations$ | async as destinations"
          >
            <vs-claim-payment-method-picker
              class="flex-grow"
              [destinations]="destinations"
              [customer]="customer"
              [customerId]="customerId"
              [canAddPaymentMethod]="canManagePaymentMethods"
              formControlName="destination"
              (refresh)="refreshDestinations()"
            ></vs-claim-payment-method-picker>
          </vs-form-field>

          <vs-form-field
            [config]="{ label: 'Amount', labelClass: '!w-[105px]'}"
            [theme]="paymentForm.controls.amount.touched && paymentForm.controls.amount.invalid ? 'danger' : 'primary'"
            appearance="horizontal"
          >
            <input
              vsInput
              type="text"
              formControlName="amount"
              placeholder="Enter payment amount"
              currencyMask
              [options]="currencyMaskConfig"
            />
            <div
              class="text-danger-600"
              *ngIf="paymentForm.controls.amount.touched && paymentForm.controls.amount.invalid"
              slot="hint"
            >
              Amount is required
            </div>
          </vs-form-field>

          <vs-form-field [config]="{ label: 'Links (optional)', labelClass: '!w-[105px]'}" appearance="horizontal">
            <ws-claim-link-picker
              [claim]="claim"
              [id]="payment?.id ?? null"
              placeholder="Add links to this payment"
              class="flex flex-row gap-4 justify-center w-full"
              formControlName="links"
            ></ws-claim-link-picker>
          </vs-form-field>
        </form>

        <div class="flex gap-2 justify-end mt-4">
          <button vs-button appearance="outline" (click)="close()">Cancel</button>
          <button
            vs-button
            theme="primary"
            [disabled]="!paymentForm.valid"
            (click)="save(claim, isEditing, payment?.id)"
          >
            Save
          </button>
        </div>
      </ng-container>
      <ng-template #isLoading>
        <div class="flex items-center justify-center min-h-[350px]">
          <vs-icon name="loading-01" class="animate-spin text-primary-500" size="40"></vs-icon>
        </div>
      </ng-template>
    </ng-container>
  </vs-dialog>
</ng-container>
