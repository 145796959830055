<div *ngIf="item" class="flex flex-col gap-2 p-6">
  <div class="flex gap-2">
    <p class="font-semibold text-gray-600 max-w-[200px] overflow-hidden overflow-ellipsis whitespace-nowrap">
      {{ item.coverage?.title ?? 'Other' }}
    </p>
    <p class="font-normal text-gray-500">| {{ item.coverage?.group }}</p>

    <vs-badge *vsLet="checkIfIncluded() as included" class="self-center" [theme]="included ? 'success' : 'default'">
      {{ item.coverage?.title ? (included ? 'Included' : 'Excluded') : 'None' }}
    </vs-badge>
  </div>

  <p class="text-sm font-normal text-gray-600">{{ item.coverage?.description ?? item.description }}</p>

  <p class="text-sm font-normal text-gray-600 flex cursor-pointer" (click)="learnMore.open()">
    Learn More <vs-icon size="18" name="chevron-right" class="self-center"></vs-icon>
  </p>

  <vs-divider class="my-2"></vs-divider>

  <ws-claim-item-liability-limit
    [item]="{ coverage: item.coverage, liabilityInfo: item.liabilityInfo }"
  ></ws-claim-item-liability-limit>
</div>

<ws-claim-coverage-dialog #learnMore [item]="item"></ws-claim-coverage-dialog>
