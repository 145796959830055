import { Staff } from '../lib/staff';

export type DistributorPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'dist_ViewDistributors',
    display: {
      title: 'View Distributors',
      description: 'View the table of distributors (parents or children)',
    },
  },
  {
    id: 'dist_ViewDistributor',
    display: {
      title: 'View Distributor',
      description: 'View individual distributor details/user list/SFTP credentials',
    },
  },
  {
    id: 'dist_CreateDistributor',
    display: {
      title: 'Create Distributor',
      description: 'Create a new distributor',
    },
  },
  {
    id: 'dist_EditDetails',
    display: {
      title: 'Edit Distributor Details',
      description: 'Edit the details of a distributor ',
    },
  },
  {
    id: 'dist_ResetSFTP',
    display: {
      title: 'Reset/Send the SFTP credentials',
      description: 'Reset the SFTP credentials or send the credentials via email to a team member ',
    },
  },
  {
    id: 'dist_RemoveStaff',
    display: {
      title: 'Remove Staff',
      description: 'Remove staff (team members) from a distributor',
    },
  },
  {
    id: 'dist_OnboardDistributor',
    display: {
      title: 'Onboard Distributor',
      description: 'Onboard a distributor on the platform manually',
    },
  },
  {
    id: 'dist_CreateOnboardingLink',
    display: {
      title: 'Create Distributor Onboarding Link',
      description: 'Create an invite link for self-onboarding a distributor',
    },
  },
  {
    id: 'dist_BreakOnboardingLink',
    display: {
      title: 'Break Distributor Onboarding Link',
      description: 'Expires an invite link for self-onboarding a distributor',
    },
  },
  {
    id: 'dist_ViewOnboardingLinks',
    display: {
      title: 'View onboarding links created for distributor',
      description:
        'View onboarding links created for distributor, as well as copy the sharing link to send out to distributors for self-onboarding',
    },
  },
  {
    id: 'dist_EditStatus',
    display: {
      title: 'Edit Distributor Status',
      description: 'Edit status (deactivate, archive, reactivate)',
    },
  },
  {
    id: 'dist_InviteCoworkers',
    display: {
      title: 'Invite Coworker Distributor',
      description: 'Assign permissions and invite coworkers to a distributor during the distributor onboarding process',
    },
  },
  {
    id: 'dist_InviteStaff',
    display: {
      title: 'Invite Staff Distributor',
      description: 'Assign permissions and invite users to a distributor on the admin dashboard',
    },
  },
  {
    id: 'dist_ViewStaff',
    display: {
      title: 'View Distributor Staff Members',
      description: 'View all team members associated with the distributor',
    },
  },
  {
    id: 'dist_EditStaffDetails',
    display: {
      title: 'Edit Distributor Staff Details',
      description: 'Edit the details and permissions of team members on a distributor',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
