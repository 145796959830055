import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, HostBinding, Input } from '@angular/core';
import { IconName } from '../../icon-name';
import { IconStyle } from '../../icon-style';

@Component({
  selector: 'vs-featured-icon',
  templateUrl: './featured-icon.component.html',
})
export class FeaturedIconComponent {
  @HostBinding('class') styles = 'relative';
  @Input() type?: IconStyle = 'line';
  @Input() name!: IconName;
  @Input() borderStyle: 'double' | 'full' = 'double';
  @Input()
  get animated() {
    return this._animated;
  }
  set animated(value: BooleanInput) {
    this._animated = coerceBooleanProperty(value);
  }
  private _animated = false;
}
