<!-- Title -->
<p class="text-sm font-medium text-gray-900 mt-4">Asset Details</p>
<p *ngIf="!editing" class="text-sm text-gray-600 mb-4">
  Select a property set to determine what information this policy needs to collect in order to create an asset.
</p>
<p *ngIf="editing" class="text-sm text-gray-600 mb-4">
  Choose a property to automatically populate the shipping address of new warranties
</p>

<!-- Form -->
<form *vsLet="patchValue$ | async" class="flex flex-col gap-4" [formGroup]="form">
  <!-- Property Set -->
  <vs-form-field *ngIf="!editing">
    <div class="flex gap-4 items-center">
      <span class="text-sm font-medium text-gray-700 basis-1/5"> Property Set </span>

      <vs-property-set-picker
        #propertyPicker
        class="w-full"
        formControlName="propertySet"
        [value]="value?.propertySet ?? null"
      ></vs-property-set-picker>
    </div>
  </vs-form-field>
</form>

<!-- Buttons -->
<div
  class="flex gap-2 flex-wrap sm:flex-row mt-4"
  [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
>
  <div
    *ngIf="!editing"
    class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
  >
    <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
  </div>

  <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
    <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
    <span class="self-center"> Saving will create a new policy version, the current policy will not be modified </span>
  </div>

  <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
    <button
      vs-button
      appearance="outline"
      class="flex-grow sm:flex-grow-0"
      [class.flex-1]="editing"
      (click)="editing ? close() : goBack()"
    >
      {{ editing ? 'Cancel' : 'Back' }}
    </button>

    <button
      vs-button
      size="md"
      theme="primary"
      class="flex-grow sm:flex-grow-0"
      [class.flex-1]="editing"
      [disabled]="saving || form.invalid"
      (click)="pageComplete()"
    >
      <!-- This is a placeholder for is assets can be updated -->
      <!-- (click)="editing ? save() : pageComplete()" -->
      {{ editing ? 'Save' : 'Next' }}
    </button>
  </div>
</div>
