/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-edit-claim-document-dialog',
  templateUrl: './edit-document.dialog.html',
})
export class EditClaimDocumentDialog {
  constructor(
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private linkSvc: LinksWebService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @Input() isCustomerPortal: boolean | null = null;

  @ViewChild('dialog') dialog?: DialogComponent;
  @ViewChild('deleteDialog') deleteDialog?: DialogComponent;

  @PropertyListener('document') document$ = new BehaviorSubject<StorageItem.Model | null>(null);
  document?: StorageItem.Model;

  @PropertyListener('claim') claim$ = this.claimSvc.getClaim();
  claim!: Claim.Model;

  @Output() closed = new EventEmitter<string>();
  @Output() deleted = new EventEmitter();
  @Output() updated = new EventEmitter();

  submitting = false;
  links: Link.Model[] | null = null;
  originalLinks: Link.FormLink[] | null = null;

  form = this.formBuilder.group({
    title: [null as string | null],
    description: [null as string | null],
    visibleToCustomer: [false],
    links: [[] as Link.FormLink[]],
  });

  canDelete$ = this.claim$.pipe(
    switchMap(async claim => {
      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim.warranty.distributor.permissionKey]
        : null;

      return await this.securitySvc.hasAccess('clm_RemoveAttachments', permissionKey);
    })
  );

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  refresh$ = new BehaviorSubject(null);

  links$ = combineLatest([this.claim$, this.document$, this.refresh$]).pipe(
    switchMap(async ([claim, document]) =>
      document && claim
        ? await this.linkSvc.list(
            { id: claim.id, objectType: Link.ObjectType.CLAIM },
            { owner: { id: document.id, objectType: Link.ObjectType.STORAGE_ITEM } }
          )
        : null
    ),
    map(data => data?.items),
    tap(links => (this.links = links ?? null))
  );

  documentUpdate$ = combineLatest([this.document$, this.links$, this.refresh$]).pipe(
    tap(([document, links]) => {
      if (document && links?.length) {
        const originalLinks = Link.getPickerLinks(links, Link.ObjectType.STORAGE_ITEM, document.id);
        if (originalLinks?.length) {
          this.originalLinks = originalLinks;

          this.form.patchValue({
            links: originalLinks.map(link => ({ id: link.id, object: link.object, type: link.type })),
          });
        }
      }

      this.form.patchValue({
        title: document?.title,
        description: document?.description,
        visibleToCustomer: document?.feed.includes('customer'),
      });
    })
  );

  open(props: { claim: Claim.Model; document: StorageItem.Model }) {
    this.claim = props.claim;
    this.document = props.document;
    this.dialog?.open();
  }

  close(command?: string) {
    this.closed.emit(command ?? 'cancel');
    this.form.reset();
    this.dialog?.close();
  }

  refresh() {
    this.refresh$.next(null);
  }

  openDeleteDialog() {
    this.deleteDialog?.open();
    this.dialog?.close();
  }

  closeDeleteDialog(action?: string) {
    this.deleteDialog?.close();

    if (action === 'deleted') this.deleted.emit();
    else this.dialog?.open();
  }

  async delete() {
    if (!this.document) return;

    try {
      const deleted = await this.claimSvc.removeDocument(this.claim.id, this.document.id).catch(error => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

      if (deleted) {
        this.toastSvc.show({
          type: 'success',
          title: 'Attachment removed',
          text:
            (this.document.title ? `<strong>${this.document.title}</strong>` : 'The attachment') +
            ' has been successfully removed.',
        });

        this.closeDeleteDialog('deleted');
      }
    } catch (error) {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: (error as Error).message,
      });
    }
  }

  async submit(document: StorageItem.Model) {
    if (!this.document || this.submitting) return;
    this.submitting = true;

    try {
      const title = this.form.value.title ?? undefined;
      const description = this.form.value.description;
      const visibleToCustomer = this.form.value.visibleToCustomer;

      const result = await this.claimSvc
        .updateDocument(this.claim.id, this.document.id, {
          title: title !== document.title ? title : undefined,
          description: description !== document.description ? description : undefined,
          visibleToCustomer: visibleToCustomer ?? undefined,
        })
        .catch(error => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });
        });

      if (result) {
        this.updateLinks(result.id);

        this.toastSvc.show({
          type: 'success',
          title: 'Document updated',
          text: 'The document has been successfully updated.',
        });

        this.submitting = false;
        this.updated.emit();
        this.close(result.id);
      }
    } catch (error) {
      this.submitting = false;
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: (error as Error).message,
      });
    }
  }

  async updateLinks(documentId: string) {
    const links = this.form.value.links;

    // Add new links
    if (links?.length) {
      const originalLinkIds = this.originalLinks?.map(link => link.id);

      await Promise.all(
        links.map(async link => {
          if (!originalLinkIds?.includes(link.id)) {
            await this.linkSvc.create(
              { id: this.claim.id, objectType: Link.ObjectType.CLAIM },
              {
                object1: { id: (link.object as any).id, objectType: link.type },
                object2: { id: documentId, objectType: Link.ObjectType.STORAGE_ITEM },
              }
            );
          }
        })
      );
    }

    // Remove links
    if (this.originalLinks?.length) {
      const newLinks = links?.map(link => (link.object as any)?.id);

      await Promise.all(
        this.originalLinks?.map(async link => {
          if (link.id && !newLinks?.includes((link.object as any)?.id)) {
            await this.linkSvc.delete({ id: this.claim.id, objectType: Link.ObjectType.CLAIM }, link.id);
          }
        })
      );
    }
  }
}
