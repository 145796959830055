import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Provisioning } from '@wsphere/warranties/domain';
import { map, tap } from 'rxjs';
import { ProvisioningSessionService } from '../../services';

@Component({
  selector: 'ws-provision-addon',
  templateUrl: './provision-addon.component.html',
})
export class ProvisionAddonComponent {
  @HostBinding('class') _class = 'block max-w-full pb-24 px-6';

  private sessionSvc = inject(ProvisioningSessionService);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private breakpointObserver = inject(BreakpointObserver);

  protected addon$ = this.sessionSvc.addons$.asObservable();

  isLargeScreen$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => !state.matches));

  protected session$ = this.sessionSvc.getCurrentSession().pipe(
    tap(session => {
      if (session?.addonIds) {
        this.addon.patchValue(session.addonIds);
      }
    })
  );

  protected addon = new FormControl(null as string[] | null);

  readonly staffView$ = this.route.data.pipe(map(data => data['staffView'] as boolean));

  private navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
    });
  }

  previousPage(staffView: boolean) {
    if (staffView) this.navigateTo('/provisioning/plan');
    else this.navigateTo('/checkout/plan');
  }

  async nextPage(session: Provisioning.ProvisioningSession | null, staffView: boolean) {
    if (session && this.addon.value) {
      await this.sessionSvc.updateSession({
        sessionId: session.id,
        data: { addonIds: this.addon.value },
      });
    }
    if (staffView) this.navigateTo('/provisioning/checkout');
    else this.navigateTo('/checkout/checkout');
  }
}
