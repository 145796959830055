import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { Config } from '@vsolv/packages/portal-config/domain';
import { NavItem } from '@vsolv/vectors-ui/app-nav';
import { SlideOutComponent } from '@vsolv/vectors-ui/slide-out';
import { SecurityService } from '@wsphere/staff/web';
import { combineLatest, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

export interface AppConfig {
  logo: string;
  production: boolean;
}
@Component({
  templateUrl: './left-nav.component.html',
  styleUrls: ['./left-nav.component.scss'],
})
export class LeftNavLayoutComponent implements OnDestroy {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private firebaseSvc: FirebaseService,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(e => e instanceof NavigationStart)
      )
      .subscribe(_ => this.slideOut?.close());
  }

  @HostBinding('class') styles = 'overscroll-contain';
  @ViewChild('slideOut') slideOut?: SlideOutComponent;

  config: AppConfig = {
    logo: 'assets/logo.svg',
    production: environment.production,
  };

  userData$ = this.route.data.pipe(map(data => data['currentUser']));
  readonly config$ = this.route.data.pipe(map(data => data['config'] as Config.PortalConfig));
  private readonly globalDistributors$ = this.securitySvc.globalDistributors$;

  destroy$ = new Subject<void>();
  reroute$ = this.globalDistributors$
    .pipe(takeUntil(this.destroy$), distinctUntilChanged())
    .subscribe(globalDistributors => {
      if (!this.compareGlobalDistributors(globalDistributors?.map(dist => dist.permissionKey) || null)) {
        const currentUrl = this.router.url;
        this.router.navigateByUrl('/refresh', { skipLocationChange: true, state: { isLoading: true } }).then(() => {
          setTimeout(() => {
            this.router.navigateByUrl(currentUrl);
          }, 200);
        });
      }
    });
  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  open = false;
  menuBtnHover = false;

  private homeNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'dsh_ViewDashboard',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return { icon: 'home-line', label: 'Home', routerLink: '' } as NavItem;
    })
  );

  private policiesNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'pol_View',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return {
        icon: 'file-shield-02',
        label: 'Policies',
        routerLink: 'policies',
      } as NavItem;
    })
  );

  private distributorsNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'dist_ViewDistributors',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return {
        icon: 'package',
        label: 'Distributors',
        routerLink: 'distributors',
      } as NavItem;
    })
  );

  private claimNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'clm_ViewClaim',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return {
        icon: 'file-06',
        label: 'Claims',
        routerLink: 'claims',
      } as NavItem;
    })
  );

  private salesNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'py_ViewSales',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return {
        label: 'Sales',
        icon: 'wallet-03',
        routerLink: 'sales',
      } as NavItem;
    })
  );

  private customersNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'cus_ViewDetails',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return {
        label: 'Customers',
        icon: 'users-01',
        routerLink: 'customers',
      } as NavItem;
    })
  );

  private warrantiesNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'wrt_ViewWarranty',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return {
        label: 'Warranties',
        icon: 'folder-shield',
        routerLink: 'warranty',
      } as NavItem;
    })
  );

  private documentsNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async _globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess('str_View', null);
      if (!hasAccess) return null;

      return {
        label: 'Documents',
        icon: 'upload-cloud-01',
        routerLink: 'documents',
      } as NavItem;
    })
  );
  private reportsNavItem$: Observable<NavItem | null> = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const hasAccess = await this.securitySvc.hasAccess(
        'rep_ViewReports',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );
      if (!hasAccess) return null;

      return {
        label: 'Reports',
        icon: 'table',
        routerLink: 'reporting',
      } as NavItem;
    })
  );

  navItems$: Observable<NavItem[]> = combineLatest([
    this.homeNavItem$,
    this.policiesNavItem$,
    this.distributorsNavItem$,
    this.claimNavItem$,
    this.salesNavItem$,
    this.customersNavItem$,
    this.warrantiesNavItem$,
    this.documentsNavItem$,
    this.reportsNavItem$,
  ]).pipe(
    map(
      ([
        homeNavItem,
        policiesNavItem,
        distributorsNavItem,
        claimNavItem,
        salesNavItem,
        customersNavItem,
        warrantiesNavItem,
        documentsNavItem,
        reportsNavItem,
      ]) => {
        return [
          ...(homeNavItem ? [homeNavItem] : []),
          ...(policiesNavItem ? [policiesNavItem] : []),
          ...(distributorsNavItem ? [distributorsNavItem] : []),
          ...(claimNavItem ? [claimNavItem] : []),
          ...(salesNavItem ? [salesNavItem] : []),
          ...(customersNavItem ? [customersNavItem] : []),
          ...(warrantiesNavItem ? [warrantiesNavItem] : []),
          ...(documentsNavItem ? [documentsNavItem] : []),
          ...(reportsNavItem ? [reportsNavItem] : []),
        ];
      }
    )
  );

  canSellWarranty$ = this.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      let hasAccess = await this.securitySvc.hasAccess(
        'wrt_CreateWarranty',
        globalDistributors?.map(dist => dist.permissionKey) || null
      );

      if (!hasAccess) {
        hasAccess = await this.securitySvc.hasAccess(
          'wrt_CreateDraftWarranty',
          globalDistributors?.map(dist => dist.permissionKey) || null
        );
      }

      return hasAccess;
    })
  );

  canOpenSettings$: Observable<boolean> = this.globalDistributors$.pipe(
    switchMap(async () => await this.securitySvc.hasAccess('stf_ViewSettings', null))
  );

  showDistributorSettings$ = this.globalDistributors$.pipe(
    switchMap(async distributors => {
      if (distributors?.length)
        return await this.securitySvc.hasAccess('dist_ViewDistributor', [distributors[0].permissionKey]);
      else return false;
    })
  );

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async signOut() {
    await this.firebaseSvc.signOut();
    await this.router.navigate(['sign-in']);
  }

  private compareGlobalDistributors(globalDistributors: string[] | null) {
    const storedKeys: string[] = JSON.parse(sessionStorage.getItem('globalDistributors') || '[]');
    let same = true;
    if (!globalDistributors || !globalDistributors.length) {
      if (!storedKeys.length) return true;
      else return false;
    }
    if (storedKeys.length !== globalDistributors.length) return false;

    storedKeys.forEach(stored => {
      if (!globalDistributors.some(dist => dist === stored)) {
        same = false;
        return;
      }
    });

    return same;
  }
}
