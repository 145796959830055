<div *vsLet="addTypeUpdated$ | async" class="flex flex-col border rounded-xl bg-base">
  <div *ngIf="!addType" class="flex border-b">
    <div
      *vsLet="type === note as selected"
      class="flex gap-1 justify-center flex-1 border-r p-2 cursor-pointer"
      (click)="type = note"
    >
      <vs-icon
        name="pen-tool-02"
        class="self-center"
        [class]="selected && !disabled ? 'text-gray-700' : 'text-gray-300'"
      ></vs-icon>
      <p class="text-sm font-semibold" [class]="selected && !disabled ? 'text-gray-700' : 'text-gray-300'">Add Note</p>
    </div>

    <div
      *vsLet="type === document as selected"
      class="flex gap-1 justify-center flex-1 p-2 cursor-pointer"
      (click)="type = document"
    >
      <vs-icon
        name="attachment-02"
        class="self-center"
        [class]="selected && !disabled ? 'text-gray-700' : 'text-gray-300'"
      ></vs-icon>
      <p
        class="text-sm font-semibold text-gray-700"
        [class]="selected && !disabled ? 'text-gray-700' : 'text-gray-300'"
      >
        Add Attachment
      </p>
    </div>
  </div>

  <ng-container *vsLet="claim$ | async as claim">
    <form *ngIf="(canAddNotes$ | async) && type === note" [formGroup]="noteForm">
      <vs-input-container
        class="h-14 border-none !shadow-none border-b rounded-b-sm mb-1 px-3"
        [class]="addType ? 'rounded-t-xl' : 'rounded-t-sm'"
      >
        <input #noteTitle formControlName="title" placeholder="Note title (optional)" [disabled]="disabled" />

        <ws-claim-link-picker
          *ngIf="claim && !isCustomerPortal"
          #noteLinks
          slot="end"
          formControlName="links"
          [claim]="claim"
          [asButton]="true"
          [disabled]="disabled"
          (linksAndConfigs)="this.linksAndConfigs = $event"
        ></ws-claim-link-picker>
      </vs-input-container>

      <div *ngIf="!saving" class="border-t" [class.border-b]="!noteForm.value.links?.length">
        <vs-rich-text-editor
          formControlName="content"
          class="!border-none rounded-sm !shadow-none -mb-1"
          [disabled]="disabled"
          [config]="{ hideBorder: true, showContentTopBorder: true, squareContentBottomBorder: true }"
        ></vs-rich-text-editor>
      </div>

      <div *ngIf="noteForm.value.links?.length" class="flex gap-1 p-2 border-b border-t overflow-x-auto scrollbar-thin">
        <vs-info-card
          *ngFor="let link of noteForm.value.links"
          theme="default"
          class="max-w-[256px]"
          [config]="getLinkConfig(link)"
        >
          <button
            vs-icon-button
            slot="end"
            appearance="clear"
            (click)="removeLink($any(link?.object)?.id || '', 'note')"
          >
            <vs-icon name="x" size="lg"></vs-icon>
          </button>
        </vs-info-card>
      </div>

      <div class="flex justify-end p-2 overflow-hidden">
        <button
          vs-button
          theme="default"
          appearance="outline"
          [disabled]="disabled || !noteForm.value.content"
          (click)="addNote(claim?.id)"
        >
          Add Note
        </button>
      </div>
    </form>

    <form *ngIf="(canAddDocuments$ | async) && type === document" [formGroup]="documentForm">
      <!-- Title -->
      <vs-input-container
        class="h-14 border-none !shadow-none border-b rounded-b-sm mb-1 px-3"
        [class]="addType ? 'rounded-t-xl' : 'rounded-t-sm'"
      >
        <input #documentTitle formControlName="title" placeholder="Attachment title (optional)" [disabled]="disabled" />

        <div class="text-center">
          <span
            class="text-sm font-normal text-danger"
            *ngIf="documentForm.controls.title.touched && documentForm.controls.title.invalid"
            slot="hint"
          >
            Max length 255 characters
          </span>
        </div>
        <div slot="end" class="flex gap-2">
          <button vs-button theme="default" appearance="outline" (click)="showDescription = !showDescription">
            <vs-icon name="align-right"></vs-icon>
          </button>

          <ws-claim-link-picker
            *ngIf="claim && !isCustomerPortal"
            #documentLinks
            formControlName="links"
            [claim]="claim"
            [asButton]="true"
            [disabled]="disabled"
            (linksAndConfigs)="this.linksAndConfigs = $event"
          ></ws-claim-link-picker>
        </div>
      </vs-input-container>

      <!-- Description -->
      <div class="border-b" [class.border-t]="showDescription">
        <textarea
          *ngIf="showDescription"
          #description
          vsInput
          min="3"
          max="5"
          formControlName="description"
          placeholder="Description (optional)"
          class="!border-none rounded-sm !shadow-none -mb-1"
          [disabled]="disabled"
        ></textarea>
      </div>

      <!-- Document -->
      <div class="border-b">
        <vs-file-input
          formControlName="file"
          [hideBorder]="true"
          [moreInfo]="'max. 800x400px'"
          [accept]="'.PNG, .JPG, .JPEG, .GIF, .PDF'"
        ></vs-file-input>
      </div>

      <div *ngIf="documentForm.value.links?.length" class="flex gap-1 p-2 border-b overflow-x-auto scrollbar-thin">
        <vs-info-card
          *ngFor="let link of documentForm.value.links"
          theme="default"
          class="max-w-[256px]"
          [config]="getLinkConfig(link)"
        >
          <button
            vs-icon-button
            slot="end"
            appearance="clear"
            (click)="removeLink($any(link?.object)?.id || '', 'note')"
          >
            <vs-icon name="x" size="lg"></vs-icon>
          </button>
        </vs-info-card>
      </div>

      <div class="flex justify-end p-2 overflow-hidden">
        <button
          vs-button
          theme="default"
          appearance="outline"
          [disabled]="disabled || !documentForm.value.file || documentForm.invalid"
          (click)="addAttachment(claim?.id)"
        >
          Add Attachment
        </button>
      </div>
    </form>
  </ng-container>
</div>
