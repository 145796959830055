import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { StaffRoleGuard, STAFF_PERMISSION_FALLBACK_ROUTE_DATA, STAFF_PERMISSION_ROUTE_DATA } from '@wsphere/staff/web';
import { DashboardModule } from './dashboard.module';
import { DashboardGuard } from './guards';
import { CallWithInjectorPipe, DashboardPage, DashboardsPage, InjectorWithInputs } from './pages';
import { DashboardResolver, DefaultDashboardResolver } from './resolvers';

@NgModule({
  imports: [
    CommonModule,
    DashboardModule,

    UtilsModule,
    AppPageModule,
    ThemingModule,
    DividerModule,
    ButtonModule,
    IconsModule,
    AppNavModule,

    RouterModule.forChild([
      {
        path: '',
        component: DashboardsPage,
        children: [
          {
            path: '',
            component: DashboardPage,
            data: {
              [STAFF_PERMISSION_ROUTE_DATA]: 'dsh_ViewDashboard',
              [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [
                { permissionId: 'pol_View', route: 'policies' },
                { permissionId: 'clm_ViewDetails', route: 'claims' },
                { permissionId: 'py_ViewSales', route: 'sales' },
              ],
            },
            canActivate: [StaffRoleGuard],
            resolve: {
              dashboard: DefaultDashboardResolver,
            },
          },
          {
            path: ':id',
            component: DashboardPage,
            data: {
              [STAFF_PERMISSION_ROUTE_DATA]: 'dsh_ViewDashboard',
              [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'pol_Viewer', route: '/polices' }],
            },
            canActivate: [DashboardGuard, StaffRoleGuard],
            resolve: {
              dashboard: DashboardResolver,
            },
          },
        ],
      },
    ]),
  ],
  providers: [DefaultDashboardResolver, DashboardResolver, DashboardGuard],
  declarations: [DashboardsPage, DashboardPage, CallWithInjectorPipe, InjectorWithInputs],
})
export class DashboardRoutingModule {}
