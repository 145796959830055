import { Staff } from '../lib/staff';

export type SalePermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'py_ViewSales',
    display: {
      title: 'View Sales',
      description: 'View the table of all sales transactions (with the individual transaction details)',
    },
  },
  {
    id: 'py_UpdateBilling',
    display: {
      title: 'Update Billing Information',
      description: 'Update billing information',
    },
  },
  {
    id: 'py_RetryPayment',
    display: {
      title: 'Retry Failed Payments/Add New Payment Method',
      description: 'Retry failed payments, and add payment method information to a transaction',
    },
  },
  {
    id: 'py_ViewPaymentMethods',
    display: {
      title: 'View list of payment details (such as card info, billing info) ',
      description:
        'If scoped to a distributor, the only payment methods shown would be those pertaining to customers who have warranties/payments via the scoped distributor.',
    },
  },
  {
    id: 'py_AddPayments',
    display: {
      title: 'Add payment info for user',
      description: 'Add payment info for user',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
