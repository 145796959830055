<ng-container *vsLet="globalDistributors$ | async">
  <ng-container *ngIf="distributors$ | async as distributors">
    <input
      type="text"
      vsInput
      #distributorInfo
      cdkOverlayOrigin
      (click)="$event.preventDefault(); $event.stopImmediatePropagation(); toggle()"
      [disabled]="disabled"
      [value]="value?.name"
      [placeholder]="placeholder"
      #trigger="cdkOverlayOrigin"
      (input)="searchFor($any($event.target).value)"
    />

    <ng-container *ngIf="distributors.length">
      <ng-template
        cdkConnectedOverlay
        [cdkConnectedOverlayOrigin]="trigger"
        [cdkConnectedOverlayOpen]="overlayOpen"
        (detach)="overlayOpen = false; openChanged.emit(false)"
        (overlayOutsideClick)="$event.target !== distributorInfo ? (overlayOpen = false) : ''"
        (attach)="openChanged.emit(true)"
      >
        <vs-card
          cdkTrapFocus
          cdkTrapFocusAutoCapture
          [theme]="themeColor"
          class="my-3 !rounded-md !shadow-md min-w-fit"
          [style.width]="elementRef.nativeElement.offsetWidth + 'px'"
        >
          <div class="px-2 py-3 max-h-60 overflow-auto scrollbar-thin">
            <ul
              vs-select-options-list
              [compareWith]="compareWith"
              (selectValueChange)="selectValueChanged($event.value[0])"
              [selectValue]="value ? [value] : []"
              [disabled]="disabled"
            >
              <li
                vs-select-option
                *ngFor="let distributor of distributors"
                [value]="distributor"
                class="overflow-ellipsis"
              >
                <div class="flex items-center gap-2">
                  <span class="text-gray-900 font-medium">{{ distributor.name }}</span>
                  <span class="text-gray-600">{{ distributor.email }}</span>
                </div>
              </li>
            </ul>
          </div>
        </vs-card>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
