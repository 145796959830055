<ng-container *vsLet="selectedTerm$ | async as selectedTerm">
  <ng-container *ngIf="state === 'MOBILE'; else desktop">
    <div
      class="absolute bottom-0 h-5/6 rounded-t-xl w-full bg-base border-t border-l border-r border-gray-200 transition-transform duration-500"
      [style]="open ? 'transform: translateY(0px)' : 'transform: translateY(calc(' + innerHeight + 'px - 68px))'"
      (window:resize)="resize()"
    >
      <button
        vs-button
        appearance="clear"
        theme="default"
        (click)="open = !open"
        class="justify-between !py-3 !px-4 !w-full"
        [class]="open ? 'min-h-[56px]' : 'min-h-[68px]'"
      >
        <span class="text-lg font-semibold">Order Summary</span>

        <div class="flex gap-2 items-center">
          <vs-icon [name]="open ? 'chevron-down' : 'chevron-up'" size="24"></vs-icon>
          <ng-container *ngIf="currentStep === finalStep">
            <div *ngIf="_preview?.purchase?.payments?.[0]?.amount as amount" class="text-2xl font-semibold">
              {{ amount / 100 | currency : 'USD' : 'symbol' : (amount % 100 === 0 ? '1.0' : '1.2') }}
            </div>

            <ng-template #loading>
              <div class="flex items-center justify-center">
                <vs-icon name="loading-01" class="animate-spin text-primary-500" size="24"></vs-icon>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </button>

      <div
        class="py-3 px-4 max-h-[calc(100%-68px-72px)] overflow-y-auto no-scrollbar"
        *ngIf="distributor || session; else noSelection"
      >
        <ng-container [ngTemplateOutlet]="summary"></ng-container>
      </div>

      <ng-template #noSelection>
        <div class="py-3 px-4 text-gray-600">
          After proceeding through the checkout process, you will be able to thoroughly review the sale details here.
        </div>
      </ng-template>

      <ng-container [ngTemplateOutlet]="footer" [ngTemplateOutletContext]="{ mobileView: true }"></ng-container>
    </div>
  </ng-container>

  <ng-template #desktop>
    <div class="flex flex-col items-stretch border-l border-gray-200 h-full w-full bg-base py-8 px-6 relative">
      <ng-container *ngIf="distributor || session; else noSelection">
        <ng-container [ngTemplateOutlet]="summary"></ng-container>
      </ng-container>

      <ng-template #noSelection>
        <div class="flex flex-col gap-4">
          <div class="text-lg font-semibold">Order Summary</div>
          <vs-divider></vs-divider>
          <div class="text-gray-600">
            After proceeding through the checkout process, you will be able to thoroughly review the sale details here.
          </div>
        </div>
      </ng-template>

      <ng-container [ngTemplateOutlet]="footer"></ng-container>
    </div>
  </ng-template>

  <ng-template #summary>
    <div
      class="flex flex-col gap-4 h-full overflow-y-auto no-scrollbar"
      [class]="state === 'DESKTOP' ? 'max-h-[calc(100%-72px)]' : ''"
    >
      <ng-container *ngIf="state === 'DESKTOP' && session?.asset; else listView">
        <vs-stack-group [config]="{ hoverPriority: true, coverDistance: 220 }">
          <ng-container [ngTemplateOutlet]="widgets" [ngTemplateOutletContext]="{ listView: false }"></ng-container>
        </vs-stack-group>
      </ng-container>

      <ng-template #listView>
        <ng-container [ngTemplateOutlet]="widgets" [ngTemplateOutletContext]="{ listView: true }"></ng-container>
      </ng-template>

      <div *ngIf="session?.policy as policy" class="flex flex-col py-4 gap-1 border-b">
        <div class="text-lg font-semibold text-primary-500">{{ policy.friendlyTitle ?? policy.title }}</div>
        <div class="text-xs">{{ policy.tagline }}</div>
      </div>

      <ng-container>
        <ng-container *ngIf="!loading; else loadingPreview">
          <ng-container *ngIf="coverages">
            <!--Total-->
            <div class="flex text-gray-600 items-end gap-1" *ngIf="_preview">
              <span class="text-6xl text-gray-900 leading-none">
                {{
                  getTotalAmount(_preview) / 100
                    | currency : 'USD' : 'symbol' : (getTotalAmount(_preview) % 100 === 0 ? '1.0' : '1.2')
                }}
              </span>
              <span class="text-sm leading-none pb-2">
                {{ $any(_preview)?.session?.paymentSchedule?.installments | installment : selectedTerm?.duration }}
              </span>
            </div>

            <div *ngIf="session?.plan as plan" class="flex flex-col gap-4">
              <div class="flex justify-between">
                <div class="flex flex-col gap-2 text-xl font-semibold text-gray-900">
                  <!--Specifically-->
                  <div *ngIf="coverages.length" class="flex gap-2.5 items-center flex-wrap">
                    <span class="text-gray-500 text-xs">{{ coverages[0].coverage?.title }} </span>
                    <vs-badge
                      *ngIf="coverages.length > 1"
                      theme="primary"
                      [vsTooltip]="{ template: coveragesTooltip }"
                      tooltipStyle="light"
                    >
                      +{{ coverages.length - 1 }}
                      {{ coverages.length - 1 > 1 ? 'Coverages' : 'Coverage' }}
                    </vs-badge>

                    <ng-template #coveragesTooltip>
                      <div class="flex flex-col gap-1 text-xs text-gray-500 justify-center items-center">
                        <ng-container *ngFor="let coverage of coverages; let first = first">
                          <span class="max-w-[25ch] truncate" *ngIf="!first">{{ coverage.coverage?.title }}</span>
                          <span></span>
                        </ng-container>
                      </div>
                    </ng-template>
                  </div>
                </div>

                <!-- base price-->
                <div class="flex flex-col" *ngIf="_preview?.basePrice as base">
                  <div class="text-2xl text-gray-900 self-end">
                    +{{ base / 100 | currency : 'USD' : 'symbol' : (base % 100 === 0 ? '1.0' : '1.2') }}
                  </div>

                  <span class="text-xs text-gray-700 text-right">{{
                    _preview?.session?.paymentSchedule?.installments | installment : selectedTerm?.duration
                  }}</span>
                </div>
              </div>
            </div>

            <vs-divider></vs-divider>

            <div class="flex flex-col gap-2" *ngIf="addons">
              <ng-container *ngFor="let addon of addons">
                <div class="flex justify-between gap-2 overflow-hidden">
                  <ng-container *ngIf="addon.addon.id.startsWith('cov'); else group">
                    <div class="flex gap-2 items-center overflow-hidden">
                      <button
                        *ngIf="session?.id as sessionId"
                        vs-icon-button
                        appearance="clear"
                        theme="primary"
                        size="xs"
                        (click)="removeAddon(addon.addon, addons, sessionId)"
                      >
                        <vs-icon name="x-close" size="20" class="text-primary-500"></vs-icon>
                      </button>

                      <div
                        [vsTooltip]="addon.addon.title"
                        tooltipStyle="light"
                        tooltipOpenDelay="250"
                        class="text-sm font-semibold truncate"
                      >
                        {{ addon.addon.title }}
                      </div>
                    </div>
                  </ng-container>

                  <ng-template #group>
                    <div class="flex flex-col gap-2.5">
                      <div class="flex gap-2 items-center">
                        <button
                          *ngIf="session?.id as sessionId"
                          vs-icon-button
                          appearance="clear"
                          theme="primary"
                          size="xs"
                          (click)="removeAddon(addon.addon, addons, sessionId)"
                        >
                          <vs-icon name="x-close" size="20" class="text-primary-500"></vs-icon>
                        </button>
                        <div class="text-sm font-semibold">
                          {{ addon.addon.title }}
                        </div>
                      </div>

                      <div *ngIf="addon.coverages as coverages" class="flex gap-2.5 items-center flex-wrap">
                        <span class="text-gray-500 text-xs">{{ coverages[0].coverage?.title }}</span>
                        <vs-badge
                          *ngIf="coverages.length > 1"
                          theme="success"
                          [vsTooltip]="{ template: coveragesTooltip }"
                          tooltipStyle="light"
                        >
                          +{{ coverages.length - 1 }}
                          {{ coverages.length - 1 > 1 ? 'Coverages' : 'Coverage' }}
                        </vs-badge>

                        <ng-template #coveragesTooltip>
                          <div class="flex flex-col gap-1 text-xs text-gray-500 justify-center items-center">
                            <ng-container *ngFor="let coverage of coverages; let first = first">
                              <span class="max-w-[25ch] truncate" *ngIf="!first"
                                >{{ coverage.coverage?.title }}
                                {{
                                  coverage.price
                                    | currency : 'USD' : 'symbol' : (coverage.price % 100 === 0 ? '1.0' : '1.2')
                                }}</span
                              >
                            </ng-container>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>

                  <div class="flex flex-col whitespace-nowrap" *ngIf="addon.price > 0">
                    <div class="text-sm text-gray-900 self-end whitespace-nowrap">
                      +
                      {{ addon.price / 100 | currency : 'USD' : 'symbol' : (addon.price % 100 === 0 ? '1.0' : '1.2') }}
                    </div>

                    <span class="text-xs text-gray-700 text-right whitespace-nowrap">{{
                      _preview?.session?.paymentSchedule?.installments | installment : selectedTerm?.duration
                    }}</span>
                  </div>
                </div>

                <vs-divider></vs-divider>
              </ng-container>
            </div>

            <!-- Taxes-->
            <ng-container *ngIf="_preview as preview">
              <div class="flex flex-col gap-2" *ngIf="preview.subtotal > 0 && preview.basePrice > 0">
                <div class="flex justify-between">
                  <span>Subtotal</span>
                  <span>{{
                    preview.subtotal / 100
                      | currency : 'USD' : 'symbol' : (preview.subtotal % 100 === 0 ? '1.0' : '1.2')
                  }}</span>
                </div>

                <div class="flex justify-between" *ngFor="let tax of preview.taxes">
                  <span>{{ tax.label }} {{ tax.rate * 100 }}%</span>
                  <span>{{
                    tax.amount / 100 | currency : 'USD' : 'symbol' : (tax.amount % 100 === 0 ? '1.0' : '1.2')
                  }}</span>
                </div>

                <div class="flex justify-between font-semibold text-primary-500">
                  <span>Billing total</span>
                  <span>{{
                    getTotalAmount(preview) / 100
                      | currency : 'USD' : 'symbol' : (preview.subtotal % 100 === 0 ? '1.0' : '1.2')
                  }}</span>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-template #loadingPreview>
          <div class="flex items-center justify-center h-96">
            <vs-icon name="loading-01" class="animate-spin text-primary-500" size="27"></vs-icon>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #widgets let-listView="listView">
    <vs-info-card
      *ngIf="session?.distributor || distributor"
      [class]="!listView ? 'max-w-[250px] min-w-[250px]' : 'max-w-[256px] min-w-[256px]'"
      [config]="{
        icon: 'package',

        title: session?.distributor?.name ?? distributor?.name,
        subtitle: session?.distributor?.id ?? distributor?.id
      }"
    ></vs-info-card>

    <vs-info-card
      *ngIf="session?.customer as customer"
      [class]="
        !listView
          ? session?.distributor || distributor
            ? 'max-w-[250px] min-w-[250px]'
            : 'max-w-[256px] min-w-[256px]'
          : ''
      "
      [config]="{
        imageUrl: customer.user?.photoURL || undefined,
        icon: customer.user?.photoURL ? undefined : 'user-01',

        title: customer.name,
        subtitle: customer.email,
        subtitleIcon: 'mail-01'
      }"
    ></vs-info-card>

    <ws-asset-details-card
      *ngIf="session?.asset as asset"
      [asset]="asset"
      [minimized]="true"
      [session]="session"
      [class]="
        !listView
          ? session?.distributor || distributor
            ? 'max-w-[250px] min-w-[250px]'
            : 'max-w-[256px] min-w-[256px]'
          : ''
      "
    ></ws-asset-details-card>
  </ng-template>

  <ng-template #footer let-mobileView="mobileView">
    <div
      class="absolute bottom-0 right-0 grid place-items-end w-full bg-base px-6"
      [class]="mobileView ? 'py-4' : 'py-8'"
    >
      <button
        vs-button
        theme="danger"
        [disabled]="loading"
        [class.mb-16]="mobileView"
        [class.!w-full]="mobileView"
        (click)="confirmCancellation.open()"
      >
        <vs-icon name="x-close"></vs-icon>
        Cancel {{ staffView ? 'sale' : 'purchase' }}
      </button>
    </div>
  </ng-template>

  <vs-dialog #confirmCancellation [config]="{ dialogContainerClass: 'max-w-[425px]' }">
    <vs-dialog-header
      [config]="{
        mode: 'simple',

        icon: 'wallet-03',
        iconTheme: 'warn',

        title: staffView ? 'Cancel sale' : 'Cancel purchase',
        subtitle: staffView
          ? 'Are you sure you want to cancel the sale? You will lose your progress and this action cannot be undone.'
          : 'Are you sure you want to cancel the purchase You will lose your progress and this action cannot be undone.'
      }"
    ></vs-dialog-header>

    <div class="flex gap-2">
      <button vs-button appearance="outline" class="flex-1" (click)="confirmCancellation.close()">Back</button>
      <button vs-button theme="danger" class="flex-1" (click)="cancel(); confirmCancellation.close()">Confirm</button>
    </div>
  </vs-dialog>
</ng-container>
