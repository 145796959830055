import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Coverage } from '@wsphere/warranties/domain';

@Injectable()
export class CoverageResolver implements Resolve<Coverage.Model | null> {
  coverage!: Coverage.Model | null;

  async resolve(): Promise<Coverage.Model | null> {
    return this.coverage || null;
  }
}
