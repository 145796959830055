import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, firstValueFrom, tap } from 'rxjs';
import { PolicyDialogPages } from '../../dialogs';
import { PolicyService } from '../../services';

export interface PolicyDocument {
  legalIntroduction: string;
  welcomeIntroduction: string;
  definitions: string;
  file?: File | null;
}

export interface PolicyDocuments {
  termsAndConditions: PolicyDocument | null;
  coverageSummary: PolicyDocument | null;
}

@Component({
  selector: 'ws-policy-documents',
  templateUrl: './policy-documents.component.html',
})
export class PolicyDocumentsComponent {
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder
  ) {}

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('editing') editing$ = new BehaviorSubject<boolean>(false);
  @Input() editing = false;

  @Input() value: PolicyDocuments | null = null;

  @Input() page: PolicyDialogPages = PolicyDialogPages.TERMS_AND_CONDITIONS;

  @Output() closed = new EventEmitter();
  @Output() prevPage = new EventEmitter<PolicyDocuments>();
  @Output() pageCompleted = new EventEmitter<PolicyDocuments>();

  saving = false;

  pages = PolicyDialogPages;
  draft = Policy.Status.DRAFT;

  termsAndConditionsForm = this.formBuilder.group({
    legalIntroduction: [''],
    welcomeIntroduction: [''],
    definitions: [''],
    file: [null as File | null],
  });

  coverageSummaryForm = this.formBuilder.group({
    legalIntroduction: [''],
    welcomeIntroduction: [''],
    definitions: [''],
    file: [null as File | null],
  });

  richTextForm = this.formBuilder.group({
    text: ['', []],
  });

  patchValue$ = combineLatest([this.policy$, this.editing$]).pipe(
    tap(([policy, editing]) => {
      if (!editing) {
        if (this.value) {
          if (this.value.termsAndConditions) {
            this.termsAndConditionsForm.patchValue(this.value.termsAndConditions);
            this.termsAndConditionsForm.markAsDirty();
          }

          if (this.value.coverageSummary) {
            this.coverageSummaryForm.patchValue(this.value.coverageSummary);
            this.coverageSummaryForm.markAsDirty();
          }
        }
      } else {
        if (!this.policy) return;

        if (this.page === PolicyDialogPages.TERMS_AND_CONDITIONS) {
          this.termsAndConditionsForm.patchValue({ ...policy?.termsAndConditions });
        } else {
          this.coverageSummaryForm.patchValue({ ...policy?.coverageSummary });
        }
      }
    })
  );

  close() {
    this.termsAndConditionsForm.reset();
    this.coverageSummaryForm.reset();
    this.closed.emit();
  }

  goBack() {
    this.prevPage.emit({
      termsAndConditions: {
        welcomeIntroduction: this.termsAndConditionsForm.value.welcomeIntroduction || '',
        legalIntroduction: this.termsAndConditionsForm.value.legalIntroduction || '',
        definitions: this.termsAndConditionsForm.value.definitions || '',
        file: this.termsAndConditionsForm.value.file,
      },
      coverageSummary: {
        welcomeIntroduction: this.coverageSummaryForm.value.welcomeIntroduction || '',
        legalIntroduction: this.coverageSummaryForm.value.legalIntroduction || '',
        definitions: this.coverageSummaryForm.value.definitions || '',
        file: this.coverageSummaryForm.value.file,
      },
    });
  }

  pageComplete() {
    this.pageCompleted.emit({
      termsAndConditions: {
        welcomeIntroduction: this.termsAndConditionsForm.value.welcomeIntroduction || '',
        legalIntroduction: this.termsAndConditionsForm.value.legalIntroduction || '',
        definitions: this.termsAndConditionsForm.value.definitions || '',
        file: this.termsAndConditionsForm.value.file,
      },
      coverageSummary: {
        welcomeIntroduction: this.coverageSummaryForm.value.welcomeIntroduction || '',
        legalIntroduction: this.coverageSummaryForm.value.legalIntroduction || '',
        definitions: this.coverageSummaryForm.value.definitions || '',
        file: this.coverageSummaryForm.value.file,
      },
    });
  }

  goToPage(page: PolicyDialogPages) {
    this.page = page;
    this.setRichText();
  }

  setRichText() {
    switch (this.page) {
      case this.pages.TC_LEGAL:
        this.richTextForm.patchValue({ text: this.termsAndConditionsForm.value.legalIntroduction });
        break;
      case this.pages.TC_WELCOME:
        this.richTextForm.patchValue({ text: this.termsAndConditionsForm.value.welcomeIntroduction });
        break;
      case this.pages.TC_DEFINITIONS:
        this.richTextForm.patchValue({ text: this.termsAndConditionsForm.value.definitions });
        break;
      case this.pages.CS_LEGAL:
        this.richTextForm.patchValue({ text: this.coverageSummaryForm.value.legalIntroduction });
        break;
      case this.pages.CS_WELCOME:
        this.richTextForm.patchValue({ text: this.coverageSummaryForm.value.welcomeIntroduction });
        break;
      case this.pages.CS_DEFINITIONS:
        this.richTextForm.patchValue({ text: this.coverageSummaryForm.value.definitions });
        break;
    }
  }

  saveRichText() {
    const rich = this.richTextForm.value.text;
    if (!rich) return;

    switch (this.page) {
      case this.pages.TC_LEGAL:
        this.termsAndConditionsForm.patchValue({ legalIntroduction: rich });
        break;
      case this.pages.TC_WELCOME:
        this.termsAndConditionsForm.patchValue({ welcomeIntroduction: rich });
        break;
      case this.pages.TC_DEFINITIONS:
        this.termsAndConditionsForm.patchValue({ definitions: rich });
        break;
      case this.pages.CS_LEGAL:
        this.coverageSummaryForm.patchValue({ legalIntroduction: rich });
        break;
      case this.pages.CS_WELCOME:
        this.coverageSummaryForm.patchValue({ welcomeIntroduction: rich });
        break;
      case this.pages.CS_DEFINITIONS:
        this.coverageSummaryForm.patchValue({ definitions: rich });
        break;
    }
  }

  async saveTermsAndConditions() {
    if (!this.policy || !this.policy.termsAndConditionsId) return;
    this.saving = true;

    const tc = this.termsAndConditionsForm.value;
    const dto = {
      canCreateNewVersion: true,
      legalIntroduction: tc.legalIntroduction || null,
      welcomeIntroduction: tc.welcomeIntroduction || null,
      definitions: tc.definitions || null,
      uploadFile: !!tc.file,
    };

    const policyId = this.policy.id;
    const documentId = this.policy.termsAndConditionsId;

    const document = await this.policySvc.updateDocument(documentId, policyId, { ...dto }).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
    });

    if (document) {
      if (document.policyId === policyId) {
        this.toastSvc.show({
          type: 'success',
          title: 'Document updated',
          text: '<strong>Terms & Conditions</strong> has been successfully updated.',
        });

        this.policySvc.refreshPolicy();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text:
            'A new version of <strong>' +
            this.policy?.title +
            '</strong> has been created with an updated <strong>Terms & Conditions</strong> document.',
        });

        this.policySvc.refreshPolicy(document.policyId);
        this.policySvc.clearPlan();

        this.navigateTo('../../' + document.policyId + '/documents');
      }

      if (tc.file) this.uploadDocumentsToGCP({ tcFile: tc.file, tcUploadUrl: document.uploadUrl });

      this.close();
      this.saving = false;
    }
  }

  async saveCoverageSummary() {
    if (!this.policy || !this.policy.coverageSummaryId) return;
    this.saving = true;

    const cs = this.coverageSummaryForm.value;
    const dto = {
      canCreateNewVersion: true,
      legalIntroduction: cs.legalIntroduction || null,
      welcomeIntroduction: cs.welcomeIntroduction || null,
      definitions: cs.definitions || null,
      uploadFile: !!cs.file,
    };

    const policyId = this.policy.id;
    const documentId = this.policy.coverageSummaryId;

    const document = await this.policySvc.updateDocument(documentId, policyId, { ...dto }).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
    });

    if (document) {
      if (document.policyId === policyId) {
        this.toastSvc.show({
          type: 'success',
          title: 'Document updated',
          text: '<strong>Coverage Summary</strong> has been successfully updated.',
        });

        this.policySvc.refreshPolicy();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text:
            'A new version of <strong>' +
            this.policy?.title +
            '</strong> has been created with an updated <strong>Coverage Summary</strong> document.',
        });

        this.policySvc.refreshPolicy(document.policyId);
        this.policySvc.clearPlan();

        this.navigateTo('../../' + document.policyId + '/documents');
      }

      if (cs.file) this.uploadDocumentsToGCP({ csFile: cs.file, csUploadUrl: document.uploadUrl });

      this.close();
      this.saving = false;
    }
  }

  private async uploadDocumentsToGCP(data: {
    tcUploadUrl?: string;
    csUploadUrl?: string;
    tcFile?: File | null;
    csFile?: File | null;
  }) {
    if (data.tcUploadUrl && data.tcFile) {
      this.uploadFileToGCP(data.tcFile, data.tcUploadUrl);
    }

    if (data.csUploadUrl && data.csFile) {
      this.uploadFileToGCP(data.csFile, data.csUploadUrl);
    }
  }

  private async uploadFileToGCP(file: File, uploadUrl: string) {
    let buffer;
    if (file) buffer = await this.readFileBuffer(file);

    await firstValueFrom(this.http.put(uploadUrl, buffer, { headers: { 'content-type': 'application/pdf' } }));
  }

  private async readFileBuffer(file: File) {
    return new Promise(res => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = event => res(event?.target?.result);
    }) as unknown as ArrayBuffer;
  }

  navigateTo(path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
