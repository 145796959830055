<vs-input-container #trigger="cdkOverlayOrigin" cdkOverlayOrigin (click)="toggle()">
  <vs-icon slot="start" name="user-01"></vs-icon>

  <input
    #input
    class="!w-full min-h-[40px] max-h-[40px]"
    [value]="value?.name"
    [placeholder]="placeholder"
    (keyup)="searchQuery$.next(input.value)"
  />

  <vs-icon slot="end" [name]="overlayOpen ? 'chevron-up' : 'chevron-down'"></vs-icon>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayOpen"
    (overlayOutsideClick)="$event.target !== input ? (overlayOpen = false) : null"
  >
    <vs-menu *vsLet="customers$ | async as customers">
      <ng-container *ngIf="customers?.length; else noCustomers">
        <button
          *ngFor="let customer of customers"
          vs-menu-item
          (click)="selectValueChanged(customer); input.value = ''"
        >
          <div class="flex gap-2 justify-between">
            <div class="flex gap-4">
              <vs-user-avatar
                class="h-8 w-8 self-center"
                [config]="{ photoUrl: customer?.user?.photoURL ?? '' }"
              ></vs-user-avatar>

              <div class="flex flex-col">
                <p class="text-sm font-medium text-gray-900" [class.mt-1]="!customer.name">
                  {{ customer.name }}
                </p>
                <p *ngIf="customer.name" class="text-xs font-normal text-gray-600">
                  {{ customer.email }}
                </p>
              </div>
            </div>

            <div class="flex justify-end w-6">
              <vs-icon *ngIf="value?.id === customer.id" size="18" name="check" class="self-center"></vs-icon>
            </div>
          </div>
        </button>
      </ng-container>

      <ng-template #noCustomers>
        <p class="px-2 py-1 text-sm font-medium text-gray-900">No customers were found</p>
      </ng-template>
    </vs-menu></ng-template
  >
</vs-input-container>
