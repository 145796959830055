<vs-input-container *vsLet="distributors$ | async as distributors" class="h-auto min-h-[40px]">
  <vs-icon slot="start" name="search-md"></vs-icon>

  <input
    #distributorInfo
    #trigger="cdkOverlayOrigin"
    cdkOverlayOrigin
    type="text"
    [disabled]="disabled"
    [value]="value?.name"
    [placeholder]="placeholder"
    (keydown.enter)="distributorInfo.value = ''"
    (input)="searchFor($any($event.target).value)"
    (click)="$event.preventDefault(); $event.stopImmediatePropagation(); toggle()"
  />

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="overlayOpen"
    (detach)="overlayOpen = false; openChanged.emit(false)"
    (overlayOutsideClick)="$event.target !== distributorInfo ? (overlayOpen = false) : ''"
    (attach)="openChanged.emit(true)"
  >
    <vs-card
      cdkTrapFocus
      cdkTrapFocusAutoCapture
      class="-ml-10 my-3 !rounded-md !shadow-md min-w-fit"
      [theme]="themeColor"
      [style.width]="elementRef.nativeElement.offsetWidth + 'px'"
    >
      <div *ngIf="distributors?.length; else noDistributors" class="px-2 py-3 max-h-60 overflow-auto scrollbar-thin">
        <ul
          vs-select-options-list
          [disabled]="disabled"
          [compareWith]="compareWith"
          [selectValue]="value ? [value] : []"
          (selectValueChange)="selectValueChanged($event.value[0])"
        >
          <li vs-select-option *ngFor="let distributor of distributors" [value]="distributor" class="overflow-ellipsis">
            <div class="flex items-center gap-2">
              <span class="text-gray-900 font-medium">{{ distributor.name }}</span>
              <span class="text-gray-600">{{ distributor.email }}</span>
            </div>
          </li>
        </ul>
      </div>

      <ng-template #noDistributors>
        <p class="p-4 text-sm font-medium text-gray-900">No distributors were found using that search</p>
      </ng-template>
    </vs-card>
  </ng-template>
</vs-input-container>
