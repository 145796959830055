import { Component, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Property } from '@vsolv/packages/properties/domain';
import { PropertyInputComponent } from '../abstract-property-input.component';

@Component({
  selector: 'vs-boolean-input-field',
  templateUrl: './boolean-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: BooleanInputFieldComponent,
    },
    { provide: NG_VALIDATORS, useExisting: BooleanInputFieldComponent, multi: true },
  ],
})
export class BooleanInputFieldComponent extends PropertyInputComponent {
  @Input() override property!: Property.BooleanModel;
}
