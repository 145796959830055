import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MenuTriggerForDirective } from '@vsolv/vectors-ui/menu';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'ws-new-fee-button',
  templateUrl: './new-fee-button.component.html',
})
export class NewFeeButtonComponent {
  @ViewChild('trigger') trigger?: MenuTriggerForDirective;

  @Output() addedFees = new EventEmitter<Policy.PlanCoverageFee.Fee[]>();

  feesToAdd: Policy.PlanCoverageFee.Fee[] = [];

  selectedFeeGroup: { title: string; fees: Policy.PlanCoverageFee.Fee[] } | null = null;

  positions = [new ConnectionPositionPair({ originX: 'end', originY: 'bottom' }, { overlayX: 'end', overlayY: 'top' })];

  search$ = new BehaviorSubject<string>('');

  feesAndGroups$ = this.search$.pipe(
    map(search => {
      const feesAndGroups: { title: string; fees: Policy.PlanCoverageFee.Fee[] }[] = [];

      Policy.PlanCoverageFee.FeesAndGroups.forEach(group => {
        const fees: Policy.PlanCoverageFee.Fee[] = [];

        group.fees.forEach(fee => {
          if (fee.toLowerCase().includes(search.toLowerCase())) fees.push(fee);
        });

        if (fees.length) feesAndGroups.push({ title: group.title, fees });
      });

      return feesAndGroups;
    })
  );

  getFeeName(name: string) {
    return name[0] + name.substring(1).replace(/_/g, ' ').toLowerCase();
  }

  getFeesInGroup(title: string) {
    const group = Policy.PlanCoverageFee.FeesAndGroups.find(group => group.title === title);
    const feesInGroup = group?.fees.filter(fee => this.feesToAdd.includes(fee));
    return feesInGroup?.length || 0;
  }

  selectFee(fee: Policy.PlanCoverageFee.Fee) {
    this.feesToAdd.push(fee);
  }

  removeFee(fee: Policy.PlanCoverageFee.Fee) {
    this.feesToAdd = this.feesToAdd.filter(f => f !== fee);
  }

  addFees() {
    this.addedFees.emit(this.feesToAdd);
    this.selectedFeeGroup = null;
    this.close();
  }

  close() {
    this.feesToAdd = [];
    this.trigger?.close();
  }
}
