import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Coverage, Warranty } from '@wsphere/warranties/domain';
import { BehaviorSubject, ReplaySubject, combineLatest } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { PolicyService } from '../../../policy';
import { WarrantyWebService } from '../../../warranty/services';

SwiperCore.use([Pagination, Navigation]);

@Component({
  selector: 'ws-coverage-carousel',
  templateUrl: './coverage-carousel.component.html',
  styleUrls: ['coverage-carousel.component.scss'],
})
export class CoverageCarouselComponent {
  constructor(
    private breakpointObserver: BreakpointObserver,
    private policySvc: PolicyService,
    private warrantySvc: WarrantyWebService
  ) {}

  @PropertyListener('warranty') warranty$ = new ReplaySubject<Warranty.Model>();
  @Input() warranty?: Warranty.Model;

  @Input() isCustomerPortal = false;

  @PropertyListener('selectedGroup') selectedGroup$ = new BehaviorSubject<string | null>(null);
  selectedGroup: Coverage.Model['group'] | null = null;

  // FIXME(adaoust): There is a bug when the screenchanges from large to small, swiper fails to recognize the slides that were created as loop slides and leaves them there.
  isLargeScreen$ = this.breakpointObserver.observe('(min-width: 768px)').pipe(map(r => r.matches));

  readonly showDisabledCoverages$ = new BehaviorSubject(false);

  readonly hasDisabledCoverages$ = this.warranty$.pipe(
    map(warranty => warranty?.coverages?.some(coverage => coverage.requirement === 'NOT_APPLICABLE') ?? false)
  );

  readonly evaluatedLiabilityGroups$ = this.warranty$.pipe(
    switchMap(async warranty => {
      if (!warranty) return null;

      return await this.policySvc.getPlanLiabilityGroups(
        warranty.policyId,
        warranty.planId,
        warranty.propertySetSubmissionId
      );
    })
  );

  readonly allCoverages$ = combineLatest([
    this.warranty$,
    this.showDisabledCoverages$.pipe(tap(() => (this.selectedGroup = null))),
    this.hasDisabledCoverages$,
  ]).pipe(
    map(([warranty, showDisabledCoverages, hasDisabledCoverages]) => {
      if (!warranty?.coverages) return [];

      return warranty?.coverages
        ?.filter(coverage =>
          hasDisabledCoverages
            ? showDisabledCoverages
              ? coverage.requirement === 'NOT_APPLICABLE'
              : coverage.requirement !== 'NOT_APPLICABLE'
            : true
        )
        .map(cvg => {
          return {
            ...cvg,
            coverage: this.warranty?.policy?.coverages?.find(cov => cov.id === cvg.coverageId),
          };
        });
    })
  );

  readonly filteredCoverages$ = combineLatest([this.allCoverages$, this.selectedGroup$, this.warranty$]).pipe(
    switchMap(async ([coverages, selectedGroup, warranty]) => {
      const filteredCoverages = coverages.filter(coverage =>
        selectedGroup ? coverage.coverage?.group === selectedGroup : true
      );

      const coverageIds = filteredCoverages.map(coverage => coverage.coverageId);

      const filteredCoveragesWithLiabilityInfo = await this.warrantySvc.calculateLiabilityLimit(
        warranty.id,
        coverageIds
      );
      const items = filteredCoverages.map(item => {
        const liabilityInfo =
          filteredCoveragesWithLiabilityInfo.find(i => i.coverageId === item.coverageId)?.liabilityInfo ?? null;

        return {
          ...item,
          liabilityInfo,
        };
      });

      return items;
    })
  );
}
