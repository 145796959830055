import { Directive, Host, HostBinding, inject, InjectionToken, Input, Self } from '@angular/core';

class ThemeService {
  color?: ThemeColor;
}

export const THEME_COLOR = new InjectionToken<ThemeColor | null | undefined>('ThemeColor');

export type ThemeColor = 'default' | 'primary' | 'accent' | 'info' | 'success' | 'warn' | 'danger';

export enum ThemeColorEnum {
  default = 'default',
  primary = 'primary',
  accent = 'accent',
  info = 'info',
  success = 'success',
  warn = 'warn',
  danger = 'danger',
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[theme]',
  providers: [
    ThemeService,
    {
      provide: THEME_COLOR,
      useFactory: (): ThemeColor => inject(ThemeService).color ?? 'default',
    },
  ],
})
export class ThemeDirective {
  @HostBinding('class') _theme: `theme-${ThemeColor}` | null | undefined = 'theme-default';

  @Input() set theme(value: ThemeColor | null | undefined) {
    this.themeSvc.color = value ?? undefined;
    this._theme = value ? `theme-${value}` : undefined;
  }
  get theme() {
    return this._theme?.replace('theme-', '') as ThemeColor;
  }

  constructor(@Host() @Self() private themeSvc: ThemeService) {}
}
