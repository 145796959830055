<ng-container *ngIf="claim$ | async as claim">
  <vs-card
    *ngIf="(customerCanView$ | async) || (canViewAttachment$ | async)"
    theme="default"
    class="flex flex-col gap-4"
  >
    <ng-container *vsLet="documents$ | async as documents">
      <vs-card-header
        [config]="{ title: 'Attachments', subtitle: 'Manage and view all attachments for this claim below.' }"
      >
        <vs-badge slot="after-title" theme="default">
          {{ documents?.length || 0 }} Attachment{{ documents?.length !== 1 ? 's' : '' }}
        </vs-badge>

        <div *vsLet="isCustomerPortal$ | async as isCustomerPortal" slot="end" class="flex gap-2 w-full">
          <button
            *ngIf="(isCustomerPortal || (canManageAttachment$ | async)) && documents?.length"
            vs-button
            slot="end"
            theme="primary"
            class="flex-grow"
            (click)="openAttachmentDialog(claim.id)"
          >
            <vs-icon name="attachment-02"></vs-icon>
            New Attachment
          </button>

          <button
            *ngIf="(!isCustomerPortal && (canManageAttachment$ | async))"
            vs-icon-button
            appearance="outline"
            [vsMenuTriggerFor]="linkMenu"
          >
            <vs-icon name="link-external-01" size="20"></vs-icon>

            <ng-template #linkMenu>
              <vs-menu *vsLet="linkButtonInfo$ | async as linkButtonInfo" class="mt-2">
                <button *ngFor="let link of linkButtonInfo" vs-menu-item (click)="copyLink(link, claim)">
                  {{ link.text }}
                </button>
              </vs-menu>
            </ng-template>
          </button>
        </div>
      </vs-card-header>

      <vs-card-content *vsLet="routedDocument$ | async">
        <div
          *ngIf="documents?.length; else noAttachments"
          class="grid grid-cols-[repeat(auto-fill,minmax(96px,1fr))] gap-y-2 gap-x-2"
        >
          <ws-claim-document-preview
            *ngFor="let document of documents"
            size="md"
            class="cursor-pointer drop-shadow-md"
            [document]="document"
            (click)="openViewDialog(document, claim)"
          ></ws-claim-document-preview>
        </div>

        <ng-template #noAttachments>
          <div class="flex flex-col justify-center gap-2 p-6">
            <p class="text-xl font-semibold text-gray-900 self-center">No attachments have been added to this claim.</p>
            <p class="font-normal text-gray-600 self-center mb-4">
              Once an attachment has been added, its details will appear here.
            </p>

            <div class="flex gap-2 justify-center">
              <button
                *ngIf="((isCustomerPortal$ | async) || (canManageAttachment$ | async))"
                vs-button
                appearance="outline"
                class="self-center"
                (click)="openAttachmentDialog(claim.id)"
              >
                <vs-icon name="attachment-02"></vs-icon> New Attachment
              </button>
            </div>
          </div>
        </ng-template>
      </vs-card-content>
    </ng-container>

    <ws-upload-claim-document-dialog
      #attachmentDialog
      [isCustomerPortal]="isCustomerPortal$ | async"
      (uploaded)="refresh()"
    ></ws-upload-claim-document-dialog>

    <ws-view-claim-document-dialog
      #documentViewDialog
      [isCustomerPortal]="isCustomerPortal$ | async"
      (updated)="refresh()"
    ></ws-view-claim-document-dialog>
  </vs-card>
</ng-container>
