import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { CoverageService, PolicyService } from '../../services';

@Component({
  selector: 'ws-delete-coverage-dialog',
  templateUrl: './delete-coverage.dialog.html',
})
export class DeleteCoverageDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private coverageSvc: CoverageService
  ) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy: Policy.Model | null = null;
  @Input() coverage: Coverage.Model | null = null;
  @Input() dialogConfig?: DialogConfig;

  saving = false;
  draft = Policy.Status.DRAFT;

  open() {
    this.dialog.open();
  }

  close() {
    this.saving = false;
    this.dialog.close();
  }

  async delete() {
    this.saving = true;

    if (this.policy && this.coverage) {
      const coverage = await this.coverageSvc.delete(this.coverage.id).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
        this.saving = false;
      });

      if (coverage) {
        if (coverage.policyId === this.policy.id) {
          this.toastSvc.show({
            type: 'success',
            title: 'Created coverage',
            text: '<strong>' + this.coverage.title + '</strong> has been successfully created.',
          });

          this.policySvc.refreshPolicy();
        } else {
          this.toastSvc.show({
            type: 'success',
            title: 'New policy version created',
            text:
              '<strong>' +
              this.coverage?.title +
              '</strong> has been successfully deleted and a new version of <strong>' +
              this.policy.title +
              '</strong> has been created.',
          });

          const onCoveragePage = (this.route.snapshot.routeConfig?.path?.split('/')?.length || 1) === 3;

          this.router.navigate([(onCoveragePage ? '../../../' : '../') + coverage.policyId], {
            relativeTo: this.route,
          });
        }

        this.close();
        this.saving = false;
      }
    }
  }
}
