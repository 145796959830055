import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, firstValueFrom, of } from 'rxjs';
import type { AppPortalAccessOut } from 'svix';

@Injectable()
export class WebhookService {
  constructor(private http: HttpClient) {}

  async login(): Promise<AppPortalAccessOut | null> {
    return await firstValueFrom(
      this.http.get<AppPortalAccessOut | null>(`/v1/webhooks/login`).pipe(
        catchError(err => {
          console.error(`Error on fetching Svix login link ${err}`);
          return of(null);
        })
      )
    ).catch(err => {
      console.error(`Error fetching Svix login link: ${err}`);
      return null;
    });
  }
}
