import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@vsolv/core/address/domain';

@Pipe({
  name: 'address',
})
export class AddressPipe implements PipeTransform {
  transform(address: Address.Model): string {
    const formattedAddress =
      (address.line1 ? address.line1 : '') +
      (address.line1 ? ',' : '') +
      (address.line2 ? address.line2 : '') +
      (address.line2 ? ',' : '') +
      (address.city ? address.city : '') +
      (address.city ? ',' : '') +
      (address.state ? address.state : '') +
      (address.state ? (address.zip ? ' ' : ',') : '') +
      (address.zip ? address.zip : '') +
      (address.zip ? ',' : '') +
      (address.country ? address.country : '');
    return formattedAddress;
  }
}
