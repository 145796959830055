import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Policy } from '@wsphere/warranties/domain';
import { EChartsOption } from 'echarts';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest, map, switchMap } from 'rxjs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-policy-stats',
  templateUrl: './policy-stats.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PolicyStatsComponent {
  constructor(private policySvc: PolicyService) {}

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy?: Policy.Model | null = null;

  @PropertyListener('duration') duration$ = new BehaviorSubject<number>(14);
  duration = 14;

  msInDay = 24 * 60 * 60 * 1000;
  totalWarrantiesProvisioned = 0;

  types = [
    'Activated Warranties',
    'Cancelled Warranties',
    'Registered Warranites',
    'Expired Warranties',
    'Open Claims',
    'Cancelled Claims',
    'Closed Claims',
    'Expired Claims',
  ];

  data$: Observable<EChartsOption | null> = combineLatest([this.policy$, this.duration$]).pipe(
    switchMap(async ([policy, duration]) => {
      if (!policy) return null;
      const firstDay = new Date(Date.now() - duration * this.msInDay);
      return await this.policySvc.getStats(policy.id, { date: firstDay });
    }),
    map(stats => {
      if (!stats?.length) return null;

      const newestStat = stats[stats.length - 1];
      this.totalWarrantiesProvisioned =
        newestStat.activatedWarranties + newestStat.registeredWarranties + newestStat.expiredWarranties;

      const xAxis: string[] = [];
      stats.forEach(stat => xAxis.push(moment(stat.created).format('DD MMM, YYYY')));

      const options: EChartsOption = {
        tooltip: {
          trigger: 'item',
        },
        legend: {
          data: this.types,
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: xAxis,
        },
        yAxis: {
          type: 'value',
        },
        series: [
          // Some stats are commented out in case we want them later
          // {
          //   name: 'Expired Warranties',
          //   type: 'line',
          //   stack: 'Warranties',
          //   data: stats.map(stat => stat.expiredWarranties),
          // },
          {
            name: 'Registered Warranites',
            type: 'line',
            stack: 'Warranties',
            data: stats.map(stat => stat.registeredWarranties),
          },
          {
            name: 'Activated Warranties',
            type: 'line',
            stack: 'Warranties',
            data: stats.map(stat => stat.activatedWarranties),
          },
          // {
          //   name: 'Cancelled Warranties',
          //   type: 'line',
          //   stack: 'Warranties',
          //   data: stats.map(stat => stat.cancelledWarranties),
          // },
          // {
          //   name: 'Open Claims',
          //   type: 'line',
          //   data: stats.map(stat => stat.openClaims),
          // },
          // {
          //   name: 'Cancelled Claims',
          //   type: 'line',
          //   data: stats.map(stat => stat.cancelledClaims),
          // },
          // {
          //   name: 'Closed Claims',
          //   type: 'line',
          //   data: stats.map(stat => stat.closedClaims),
          // },
          // {
          //   name: 'Expired Claims',
          //   type: 'line',
          //   data: stats.map(stat => stat.expiredClaims),
          // },
        ],
      };

      return options;
    })
  );
}
