<vs-dialog *vsLet="plan$ | async as plan" [config]="{ dialogContainerClass: 'min-w-[535px] max-w-[800px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple', 
      hideCloseButton: false,
      showDivider: true,
      
      icon: 'copy-04', 
      iconTheme: 'primary', 
      
      title: plan ? 'Edit Plan' : 'Create Plan',
      subtitle: plan 
        ? ('Edit <strong><i>' + plan.title + '</i></strong> for <strong><i>' + policy?.title + '</i></strong>') 
        : ('Create a plan for <strong><i>' + policy?.title + '</i></strong>')
    }"
  ></vs-dialog-header>

  <div *ngIf="page === 1" class="p-4">
    <p class="text-sm font-medium text-gray-900 mt-4">Plan Details</p>
    <p class="text-sm font-normal text-gray-600">
      Add a name {{ plan ? ' and description.' : ', description, and set this plan\'s default visibility.'}}
    </p>

    <form class="flex flex-col gap-4 mt-4" [formGroup]="form">
      <div class="flex gap-4 flex-wrap sm:flex-nowrap justify-between">
        <label for="title-input" class="text-sm font-medium text-gray-700 basis-1/4">Title</label>
        <input vsInput id="title-input" type="text" formControlName="title" />
      </div>

      <div class="flex gap-4 flex-wrap sm:flex-nowrap justify-between">
        <label for="text-input" class="text-sm font-medium text-gray-700 basis-1/4">Description</label>
        <textarea vsInput id="text-input" type="text" formControlName="description"></textarea>
      </div>

      <div class="flex gap-4 flex-wrap sm:flex-nowrap justify-between">
        <label for="order-input" class="text-sm font-medium text-gray-700 basis-1/4">Order</label>
        <input vsInput id="order-input" type="number" formControlName="order" />
      </div>

      <div *ngIf="!plan" class="flex gap-4">
        <input vsInput type="checkbox" id="visible-input" appearance="toggle" formControlName="visible" />

        <div class="flex flex-col">
          <label for="visible-input" class="text-sm font-medium text-gray-700">Plan visibility</label>
          <label for="visible-input" class="text-sm text-gray-600">
            This plan will {{ form.value.visible ? '' : ' not ' }} be visible to qualifying customers.
          </label>
        </div>
      </div>
    </form>

    <div class="flex flex-col gap-2 mt-4">
      <p *ngIf="policy?.status !== draft" class="flex gap-2 text-danger-500 text-sm">
        <vs-icon theme="danger" class="self-center" name="alert-circle" size="lg"></vs-icon>
        <span class="self-center">
          Saving will create a new policy version, the current policy will not be modified
        </span>
      </p>

      <div class="flex justify-end">
        <button vs-button size="md" theme="primary" [disabled]="!form.value.title" (click)="page = 2">Next</button>
      </div>
    </div>
  </div>

  <div *ngIf="page === 2" class="p-4">
    <p class="text-sm font-medium text-gray-900 mt-4 mb-2">Configure Qualifying Conditions</p>
    <p class="text-sm font-normal text-gray-600 mb-2">
      Add conditions to this plan to determine which customers can view the plan when purchasing a warranty, based on
      the information they submit. The properties available to create conditions for a plan are configured on the policy
      level.
    </p>
    <p class="text-sm font-normal text-gray-600 mb-2">If no conditions are set, all customers may view this plan.</p>

    <div *ngIf="!addCondition" class="w-full my-4 p-4 flex gap-4 justify-between">
      <div>
        <p class="text-sm font-semibold text-primary-700">No conditions created for this plan</p>
        <p class="text-xs font-regular text-gray-600 mt-1">Create a new condition</p>
      </div>

      <button vs-button class="self-center" size="md" theme="primary" (click)="addCondition = true;">
        <vs-icon name="plus"></vs-icon>
        Condition
      </button>
    </div>

    <form *ngIf="addCondition" [formGroup]="form">
      <ws-condition-group-builder
        *ngIf="propertySet$ | async as propertySet"
        class="pl-4 pr-4"
        formControlName="conditions"
        [hideClose]="true"
        [propertySet]="propertySet"
      >
      </ws-condition-group-builder>
    </form>

    <div class="footer">
      <p *ngIf="policy?.status !== draft" class="flex gap-2 text-danger-500 text-sm">
        <vs-icon theme="danger" class="self-center" name="alert-circle" size="lg"></vs-icon>
        <span class="self-center">
          Saving will create a new policy version, the current policy will not be modified
        </span>
      </p>

      <div class="flex gap-2 justify-end">
        <button vs-button size="md" appearance="outline" (click)="page = 1">Back</button>
        <button
          vs-button
          size="md"
          theme="primary"
          [disabled]="saving || !form.valid"
          (click)="plan ? update() : create()"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</vs-dialog>
