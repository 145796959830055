import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiKey } from '@vsolv/packages/api-key';
import { ApiKeyService } from '@vsolv/packages/api-key/web';
import { FirebaseService } from '@vsolv/packages/firebase/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { DecodedKeyPreviewDialog } from '../decoded-key-preview';

@Component({
  selector: 'ws-roll-api-key-dialog',
  templateUrl: './roll-api-key.dialog.html',
})
export class RollApiKeyDialog {
  constructor(
    private fb: FormBuilder,
    private apiKeySvc: ApiKeyService,
    private firebaseSvc: FirebaseService,
    private toastSvc: ToastService
  ) {}

  @Input() apiKeyItem: ApiKey.ListApiKeysResponseType | null = null;
  @Input() apiKey?: ApiKey.ListApiKeysResponseType;
  @ViewChild(DialogComponent) dialog!: DialogComponent;
  @ViewChild(DecodedKeyPreviewDialog) decodedPreview!: DecodedKeyPreviewDialog;
  @Output() refresh = new EventEmitter();
  todaysDate = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  isValidPassword = true;
  resetFormValue = {
    confirmPassword: '',
  };
  _submitting = false;

  form = this.fb.group({
    confirmPassword: ['', [Validators.required]],
  });

  inputIsValid() {
    return this.form.valid;
  }

  async resetForm() {
    try {
      this.form.reset(this.resetFormValue);
      this.form.markAsPristine();
    } catch (e) {
      console.error(e);
    }
  }

  async confirm() {
    if (!this.apiKeyItem?.id) return;
    const { confirmPassword } = this.form.getRawValue();
    if (!confirmPassword) return;

    this._submitting = true;
    this.form.disable();

    try {
      const isAuthenticated = await this.firebaseSvc.reauthenticate(confirmPassword);
      if (isAuthenticated) {
        this.isValidPassword = true;
        const response = await this.apiKeySvc.roll(this.apiKeyItem.id);
        this.closeDialog();
        this.resetForm();
        this.toastSvc.show({
          type: 'success',
          title: 'API Key successfully rerolled!',
          text: 'Your API key has been successfully updated.',
        });
        this.decodedPreview.openDialog(response.key);
      } else this.isValidPassword = false;
    } catch (e) {
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: 'Something went wrong and the api-key was not rolled. Please try again.',
      });
    }

    this.form.enable();
    this._submitting = false;
  }

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.refresh.emit();
    this.dialog.close();
  }
}
