/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { UserService, UserStorageService } from '@vsolv/core/users/web';
import {
  EmailAuthProvider,
  getAuth,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithEmailLink,
  updatePassword,
} from 'firebase/auth';
import { BehaviorSubject, Observable, Subject, firstValueFrom } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Injectable()
export class FirebaseService implements OnDestroy {
  public refresh$: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);

  get authUser$() {
    return this.ngAuth.user;
  }

  private _onDestroy$ = new Subject<void>();

  constructor(
    private ngAuth: AngularFireAuth,
    private http: HttpClient,
    private userStorageSvc: UserStorageService,
    private userSvc: UserService
  ) {
    this.authUser$.pipe(takeUntil(this._onDestroy$)).subscribe(async _user => {
      // if (user) await firstValueFrom(this.http.put(`/api/users/${user.uid}`, {}));
    });
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  getAuthId$(): Observable<string | undefined> {
    return this.ngAuth.user.pipe(map(authUser => authUser?.uid));
  }

  isSignedIn$() {
    return this.ngAuth.authState.pipe(map(state => !!state));
  }

  getAuthToken$() {
    return this.ngAuth.idToken;
  }

  async emailAndPasswordSignIn(email: string, password: string) {
    const tenantId = (await this.getCurrentFirebaseTenant()).firebaseTenantId;
    if (!tenantId) throw new Error('Something went wrong establishing a connection to the identity server');

    const auth = this.getFirebaseTenantAuth(tenantId);

    const user = await signInWithEmailAndPassword(auth, email, password);
    await this.userSvc.updateLastSignedIn();

    return user;
  }

  async generateSignInLink(email: string, redirect?: string) {
    this.userStorageSvc.saveEmail(email);
    return await firstValueFrom(this.http.post(`/v1/auth/send-login-link-by-email`, { email, redirect }));
  }

  async forgotPassword(email: string) {
    return await firstValueFrom(this.http.post<void>(`/v1/auth/send-forgot-password-email`, { email }));
  }

  async updatePassword(password: string) {
    const tenantId = (await this.getCurrentFirebaseTenant()).firebaseTenantId;
    if (!tenantId) throw new Error('Something went wrong establishing a connection to the identity server');

    const auth = this.getFirebaseTenantAuth(tenantId);

    const user = auth.currentUser;
    if (!user) throw new Error('Something went wrong establishing a connection to the identity server');

    await updatePassword(user, password);
  }

  async passwordlessSignIn(tenantId: string, email: string) {
    if (!email) return;
    this.userStorageSvc.clearEmail();

    const auth = this.getFirebaseTenantAuth(tenantId);

    return await signInWithEmailLink(auth, email);
  }

  async customTokenSignIn(token: string, tenantId: string) {
    const auth = this.getFirebaseTenantAuth(tenantId);

    const user = await signInWithCustomToken(auth, token);
    await this.userSvc.updateLastSignedIn();
    return user;
  }

  async getCurrentFirebaseTenant() {
    return await firstValueFrom(this.http.get<{ firebaseTenantId?: string }>(`/v1/auth/firebase`));
  }

  async signOut() {
    await getAuth().signOut();
    this.userStorageSvc.clearSignedInUser();
    sessionStorage.clear();
  }

  async validateCurrentUser() {
    const user = await firstValueFrom(this.authUser$);
    if (user) {
      const firebase = await this.getCurrentFirebaseTenant();
      if (user.tenantId !== firebase.firebaseTenantId) {
        await this.signOut();
        return false;
      }
    }
    return true;
  }

  async exchangeToken(email: string, token: string) {
    return await firstValueFrom(
      this.http.put<{ signInToken: string; tenantId: string } | undefined>(`/v1/auth/exchangeVsolvToken`, {
        email,
        token,
      })
    );
  }

  async reauthenticate(password: string) {
    try {
      const currentUser = await this.ngAuth.currentUser;

      if (!currentUser || !currentUser.email) return false;

      //switch the auth to the tenant id of the current user
      this.getFirebaseTenantAuth(currentUser.tenantId);

      const credential = EmailAuthProvider.credential(currentUser.email, password);
      const result = await currentUser.reauthenticateWithCredential(credential);

      if (result) return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  }

  private getFirebaseTenantAuth(tenantId: string | null) {
    const auth = getAuth();
    auth.tenantId = tenantId;
    return auth;
  }
}
