<ng-container *vsLet="isMobile$ | async as isMobile">
  <ng-container *ngIf="plansAndAddons$ | async as plansAndAddons">
    <!-- Policy Term Picker -->
    <div class="flex justify-center pb-6">
      <ws-policy-term-picker
        [policy]="policy"
        [selected]="selectedTerm?.id ?? null"
        [viewablePlansAndTerms]="viewablePlansAndTerms"
        (valueChanges)="selectTerm($event)"
      >
      </ws-policy-term-picker>
    </div>

    <div *ngIf="plansAndAddons.length; else noPlans" class="max-w-min mx-auto overflow-x-auto scrollbar-thin">
      <div *ngIf="totalPrices$ | async as totalPrices">
        <!-- Plan and Addon Picker -->
        <div *ngIf="!isMobile; else mobile" class="flex gap-4 mx-auto max-w-[90vw]">
          <ng-container *ngFor="let data of plansAndAddons; let planIndex = index">
            <ng-container [ngTemplateOutlet]="plan" [ngTemplateOutletContext]="{ data, totalPrices }"></ng-container>
          </ng-container>
        </div>

        <ng-template #mobile>
          <div *vsLet="navigation$ | async as current" class="flex justify-center px-1">
            <ng-container *ngIf="plansAndAddons.length >= 1">
              <ng-container
                [ngTemplateOutlet]="plan"
                [ngTemplateOutletContext]="{
                  data: plansAndAddons[current ?? 0],
                  totalPrices,
                  current: current,
                  last: plansAndAddons.length - 1
                }"
              ></ng-container>
            </ng-container>
          </div>
        </ng-template>
      </div>
    </div>
  </ng-container>

  <!-- No Plans template -->
  <ng-template #noPlans>
    <ws-no-plans-available-card [hideBack]="false" (back)="goBack()"></ws-no-plans-available-card>
  </ng-template>

  <ng-template #plan let-data="data" let-totalPrices="totalPrices" let-last="last" let-current="current">
    <!-- Plan Card -->
    <div
      *vsLet="totalPrices.totals.get(data.planId) as prices"
      class="flex flex-col gap-4 px-4 pt-4 pb-6 bg-base-white rounded-xl shadow-sm h-fit"
      [class]="isMobile ? 'w-full' : 'w-[429px]'"
    >
      <!-- Header -->
      <div class="flex flex-col gap-4 items-center">
        <div class="flex gap-2 px-4 py-1 border-b w-full items-center">
          <div class="w-12 h-12">
            <button
              *ngIf="isMobile && current !== 0"
              vs-icon-button
              size="lg"
              theme="default"
              appearance="clear"
              (click)="navigation$.next(current - 1)"
            >
              <vs-icon name="chevron-left" size="20"></vs-icon>
            </button>
          </div>

          <div class="text-xl flex-grow font-semibold text-gray-900 text-center truncate" [vsTooltip]="data.plan.title">
            {{ data.plan.title }}
          </div>

          <div class="w-12 h-12">
            <button
              *ngIf="isMobile && current !== last"
              vs-icon-button
              size="lg"
              theme="default"
              appearance="clear"
              (click)="navigation$.next(current + 1)"
            >
              <vs-icon name="chevron-right" size="20"></vs-icon>
            </button>
          </div>
        </div>

        <ng-container [ngTemplateOutlet]="planPricesAndDescription"></ng-container>

        <ng-template #planPricesAndDescription>
          <div class="flex flex-col gap-1 px-4 text-center" [class]="data.hasDescription ? 'h-[124px]' : 'h-[80px]'">
            <div class="text-5xl font-semibold h-[80px]">
              <ng-container *ngIf="prices.total && prices.total > 0; else freeTemplate">
                <p
                  *ngIf="!prices.hasOneTimePayment && prices.installments.length > 1"
                  class="!font-normal !text-sm text-center text-gray-600"
                >
                  starting at
                </p>

                <span class="text-center">
                  {{ prices.total / 100 | currency : 'USD' : 'symbol' : (prices.total % 100 === 0 ? '1.0' : '1.2') }}
                </span>

                <p class="!font-normal !text-sm text-center text-gray-600">
                  <span *ngIf="prices.hasOneTimePayment && !prices.installments.length"> one-time payment </span>
                  <span *ngIf="prices.hasOneTimePayment && prices.installments.length">
                    or {{ prices.installments.length === 1 ? '' : 'starting at ' }}
                    <span class="!font-bold">
                      {{
                        prices.installments[0].total / 100
                          | currency : 'USD' : 'symbol' : (prices.installments[0].total % 100 === 0 ? '1.0' : '1.2')
                      }}
                    </span>
                    / {{ prices.installments[0].count | installment : selectedTerm?.duration || 0 }}
                  </span>

                  <span *ngIf="!prices.hasOneTimePayment && prices.installments.length">
                    per {{ prices.installments[0].count | installment : selectedTerm?.duration || 0 }}
                  </span>
                </p>
              </ng-container>

              <ng-template #freeTemplate>
                <span class="text-center text-black-300">
                  {{ prices.price / 100 | currency : 'USD' : 'symbol' : (prices.price % 100 === 0 ? '1.0' : '1.2') }}
                </span>
              </ng-template>
            </div>

            <div
              class="flex items-center justify-center h-10 max-w-[30ch] gap-1"
              *vsLet="data.plan?.description as description"
            >
              <span
                class="text-sm text-gray-600 overflow-hidden overflow-ellipsis !whitespace-normal max-h-10 text-center"
              >
                {{ description?.length > 80 ? description?.substring(0, 80) + '...' : description }}
              </span>

              <vs-icon
                *ngIf="description?.length > 80"
                name="info-circle"
                tooltipStyle="light"
                tooltipPosition="start"
                class="text-sm text-gray-600"
                [vsTooltip]="{ template: descriptionTooltip }"
              ></vs-icon>

              <ng-template #descriptionTooltip>
                <div class="max-w-[30ch]">
                  {{ description }}
                </div>
              </ng-template>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <button
              *ngIf="prices.total !== null && prices.total !== undefined && (prices.total > 0 || staffView)"
              vs-button
              size="lg"
              class="!w-56"
              theme="primary"
              (click)="selectPlan(data.plan, true)"
              [disabled]="!data.hasBaseCoverages && (!prices.total || prices.total === 0)"
            >
              {{ prices.total > 0 ? 'Buy now' : 'Confirm' }}
            </button>

            <a
              *ngIf="prices.total !== null && prices.total !== undefined && prices.total <= 0 && !staffView"
              vs-button
              size="lg"
              class="!w-56"
              theme="primary"
              href="mailto:{{ contactEmail }}"
            >
              Get in touch
            </a>
          </div>
        </ng-template>
      </div>

      <vs-accordion #coveragesAccordion [open]="true">
        <ws-coverage-accordion-header
          slot="header"
          header="Coverages"
          [open]="coveragesAccordion.open"
          (toggled)="coveragesAccordion.toggle()"
        >
          <vs-badge slot="post-title" theme="primary">{{ data.baseCoveragesCount }} Included</vs-badge>
        </ws-coverage-accordion-header>

        <div slot="content" class="flex flex-col py-4">
          <ng-container *ngFor="let group of data.baseGroups; let first = first">
            <div class="flex justify-between font-bold pb-0 pl-4 h-10 items-center">
              <p *ngIf="group.coverages.length">{{ group.group }}</p>
              <p
                *ngIf="group.coverages.length && first"
                vsTooltip="The maximum coverage amount for repairs or replacements that we've got you covered for. Please check your Terms & Conditions after purchasing for the complete details."
                tooltipMaxWidth="xs"
                tooltipStyle="light"
              >
                Limit of Liability
              </p>
            </div>

            <ng-container *ngFor="let coverage of group.coverages">
              <ng-container *ngIf="coverage.requirement === 'BASE'">
                <button
                  *vsLet="coverage.liabilityLimit as liabilityLimit"
                  vs-button
                  appearance="clear"
                  theme="default"
                  [rounded]="false"
                  class="!justify-between !w-full !text-start !font-normal"
                  size="md"
                  (click)="CoverageDialog.open()"
                >
                  <div class="flex gap-2">
                    <span class="truncate overflow-hidden w-[220px]">{{ coverage.coverage?.title }}</span>
                  </div>

                  <span
                    *ngIf="liabilityLimit"
                    [vsTooltip]="coverage.liabilityGroupObjects.length ? { template: BaseAggLimit } : undefined"
                    tooltipStyle="light"
                    tooltipMaxWidth="md"
                  >
                    {{
                      liabilityLimit / 100 | currency : 'USD' : 'symbol' : (liabilityLimit % 100 === 0 ? '1.0' : '1.2')
                    }}
                  </span>
                  <ng-template #BaseAggLimit>
                    <ng-container [ngTemplateOutlet]="AggLimit" [ngTemplateOutletContext]="{coverage}"></ng-container>
                  </ng-template>
                </button>

                <ws-claim-coverage-dialog
                  #CoverageDialog
                  [coverage]="coverage.coverage"
                  [deductible]="coverage.deductible"
                  [liabilityLimit]="coverage.liabilityLimit"
                  [notApplicable]="coverage.requirement === 'NOT_APPLICABLE'"
                ></ws-claim-coverage-dialog>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </vs-accordion>

      <vs-accordion #addonsAccordion *ngIf="data.addons.length">
        <ws-coverage-accordion-header
          slot="header"
          header="Add-ons"
          [open]="addonsAccordion.open"
          (toggled)="addonsAccordion.toggle()"
        >
          <vs-badge slot="post-title" theme="success" *ngIf="totalPrices.addon_hash[data.planId]?.total as addonTotal">
            +
            {{ addonTotal / 100 | currency : 'USD' : 'symbol' : (addonTotal % 100 === 0 ? '1.0' : '1.2') }}
          </vs-badge>
        </ws-coverage-accordion-header>

        <div slot="content" class="flex flex-col gap-2 py-4 border-b" *vsLet="value[selectedTerm?.id || ''] as value">
          <ng-container *ngFor="let addons of data.addons; let i = index; let last = last">
            <ng-container *ngIf="addons.addonId.startsWith('cov'); else group">
              <div class="flex justify-between w-full items-center">
                <div class="flex items-center gap-1">
                  <input
                    #checkbox
                    vsInput
                    type="checkbox"
                    [checked]="value?.addonIds?.includes(addons.addonId) && value?.plan?.id === data.planId"
                    (change)="
                      checkbox.checked ? selectAddon(data.plan, addons.addonId) : deselectAddon(addons.addon.id)
                    "
                  />

                  <button (click)="CoverageDialog.open()" class="flex items-center gap-1">
                    <span class="text-start truncate overflow-hidden max-w-[220px]">{{ addons.addon.title }}</span>
                  </button>
                </div>

                <span *ngIf="addons.coverages[0].price">
                  +
                  {{
                    addons.coverages[0].price / 100
                      | currency : 'USD' : 'symbol' : (addons.coverages[0].price % 100 === 0 ? '1.0' : '1.2')
                  }}
                </span>
              </div>

              <ng-template #AddonAggLimit>
                <ng-container
                  [ngTemplateOutlet]="AggLimit"
                  [ngTemplateOutletContext]="{ coverage: addons.coverages[0] }"
                ></ng-container>
              </ng-template>

              <ws-preview-group-addons
                #CoverageDialog
                [addon]="addons"
                [checked]="!!value?.addonIds?.includes(addons.addonId) && value?.plan?.id === data.planId"
                (selected)="$event ? selectAddon(data.plan, addons.addonId) : deselectAddon(addons.addon.id)"
              ></ws-preview-group-addons>
            </ng-container>

            <ng-template #group>
              <vs-accordion #addonGroupAccordion>
                <ws-coverage-accordion-header
                  slot="header"
                  [header]="addons.addon.title"
                  [minimize]="true"
                  [checkbox]="true"
                  [select]="!!(value?.addonIds?.includes(addons.addonId) && value?.plan?.id)"
                  [open]="addonGroupAccordion.open"
                  (toggled)="addonGroupAccordion.toggle()"
                  (selected)="$event ? selectAddon(data.plan, addons.addon.id) : deselectAddon(addons.addon.id)"
                >
                  <!-- <vs-badge
                    slot="post-title"
                    theme="success"
                    *vsLet="totalPrices?.addonPrices?.get(data.planId + '_' + addons.addonId) as addonPrice"
                  >
                    {{
                      addonPrice.total / 100
                        | currency : 'USD' : 'symbol' : (addonPrice.total % 100 === 0 ? '1.0' : '1.2')
                    }}
                  </vs-badge> -->

                  <vs-icon
                    slot="header-end"
                    [vsTooltip]="
                      'To view the limits of liability for ' +
                      addons.addon.title +
                      ', expand this bundle to view the individual coverages'
                    "
                    tooltipMaxWidth="xs"
                    tooltipStyle="light"
                    name="minus"
                  ></vs-icon>
                </ws-coverage-accordion-header>

                <div slot="content" class="flex flex-col py-2 pl-5">
                  <ng-container *ngFor="let coverage of addons.coverages; let index = index">
                    <button
                      vs-button
                      size="md"
                      theme="default"
                      appearance="clear"
                      class="!justify-between !w-full !text-start !font-normal !px-0"
                      [rounded]="false"
                    >
                      <vs-icon
                        vs-icon-button
                        name="info-circle"
                        (click)="CoverageDialog.open()"
                        [vsTooltip]="'View additional addon information'"
                      ></vs-icon>
                      <span class="truncate overflow-hidden w-[220px] mr-20">{{ coverage.coverage?.title }}</span>
                      <span *ngIf="coverage.price">
                        +
                        {{
                          coverage.price / 100
                            | currency : 'USD' : 'symbol' : (coverage.price % 100 === 0 ? '1.0' : '1.2')
                        }}
                      </span>
                    </button>

                    <ng-template #GroupAddonAggLimit>
                      <ng-container [ngTemplateOutlet]="AggLimit" [ngTemplateOutletContext]="{coverage}"></ng-container>
                    </ng-template>

                    <ws-preview-group-addons
                      #CoverageDialog
                      [addon]="addons"
                      [selectedAddon]="index"
                      [checked]="!!value?.addonIds?.includes(addons.addonId) && value?.plan?.id === data.planId"
                      (selected)="$event ? selectAddon(data.plan, addons.addonId) : deselectAddon(addons.addon.id)"
                    ></ws-preview-group-addons>
                  </ng-container>
                </div>
              </vs-accordion>
            </ng-template>
          </ng-container>
        </div>
      </vs-accordion>

      <div class="flex justify-center items-center">
        <button
          *ngIf="prices.total !== null && prices.total !== undefined && (prices.total > 0 || staffView)"
          vs-button
          size="lg"
          class="!w-56"
          theme="primary"
          [disabled]="!data.hasBaseCoverages && (!prices.total || prices.total === 0)"
          (click)="selectPlan(data.plan, true)"
        >
          {{ prices.total > 0 ? 'Buy now' : 'Confirm' }}
        </button>
        <a
          *ngIf="prices.total !== null && prices.total !== undefined && prices.total <= 0 && !staffView"
          vs-button
          size="lg"
          class="!w-56"
          theme="primary"
          href="mailto:{{ contactEmail }}"
        >
          Get in touch
        </a>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #AggLimit let-coverage="coverage">
  <div class="flex flex-col gap-2 pb-2">
    <div class="text-xs">Aggregate limits for {{ coverage.coverage?.title }}</div>
    <div class="flex flex-col min-w-[200px] outline outline-gray-200 rounded-lg">
      <div
        class="flex justify-between py-3 px-6 text-left text-xs font-medium text-gray-600 bg-base border-b border-gray-200 whitespace-nowrap"
      >
        <div>Title</div>
        <div>Limit</div>
      </div>
      <div class="flex flex-col">
        <ng-container *ngFor="let group of coverage.liabilityGroupObjects; let index = index">
          <div
            class="flex justify-between bg-base py-2 px-6 border-b border-gray-200"
            [class.bg-gray-50]="index % 2 === 1"
          >
            <div>{{ group.title }}</div>
            <div>{{ group.limit / 100 | currency }}</div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
