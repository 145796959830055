import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';
import { v4 } from 'uuid';
import { PolicyService } from '../../services';

export interface PolicyTerm {
  id: string;
  title: string;

  years: number;
  months: number;
  days: number;

  order: number;

  installments: 0;
  percentage: number;
  installmentName: string;
  installmentsCheckBox: boolean;
  oneTimeCheckBox: boolean;
}

@Component({
  selector: 'ws-policy-term',
  templateUrl: './policy-term.component.html',
})
export class PolicyTermComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder
  ) {}

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('editing') editing$ = new BehaviorSubject<boolean>(false);
  @Input() editing = false;

  @Input() value: Policy.PolicyTerm[] | null = null;

  @Output() closed = new EventEmitter();
  @Output() prevPage = new EventEmitter<Policy.PolicyTerm[]>();
  @Output() pageCompleted = new EventEmitter<Policy.PolicyTerm[]>();

  saving = false;

  draft = Policy.Status.DRAFT;

  terms: FormGroup[] = [];

  patchValue$ = combineLatest([this.policy$, this.editing$]).pipe(
    tap(([policy, editing]) => {
      if (!editing) {
        this.mapTermsToForm([]);

        if (this.value) {
          this.mapTermsToForm(this.value);
        }
      } else {
        if (!policy) return;

        this.mapTermsToForm(policy?.terms ?? []);
      }
    })
  );

  close() {
    this.policy = null;
    this.editing = false;

    this.closed.emit();
  }

  goBack() {
    const terms = this.mapFormToTerms();
    this.prevPage.emit(terms);
  }

  pageComplete() {
    const terms = this.mapFormToTerms();
    this.pageCompleted.emit(terms);
  }

  newTerm() {
    this.terms.push(
      this.formBuilder.group({
        id: [null],
        title: [null as string | null, [Validators.required]],
        years: [0, [Validators.min(0)]],
        months: [0, [Validators.min(0)]],
        days: [0, [Validators.min(0)]],
        order: [(this.terms.length ? null : 0) as number | null, [Validators.required]],
        oneTimeCheckBox: [false],
        installmentsCheckBox: [false],
        schedules: this.formBuilder.array([]),
        expanded: [true],
      })
    );
  }

  scheduleFormControl(installments: number, percentage: number, installmentName: string) {
    return this.formBuilder.group({
      installments: [installments, [Validators.min(0)]],
      percentage: [percentage, [Validators.min(-100), Validators.max(100)]],
      installmentName: [installmentName, [Validators.maxLength(25)]],
    });
  }

  toggleTerm(index: number) {
    this.terms[index].patchValue({ expanded: !(this.terms[index].value.expanded ?? false) });
  }

  trackByFn = (index: number) => index;

  isTermValid(index: number) {
    const policyTermForm = this.terms[index];

    const value = policyTermForm.value;
    if (
      // policyTermForm.invalid ||
      !value.title ||
      value.order === null ||
      value.order === undefined ||
      value.order === '' ||
      (!value?.years && !value?.months && !value?.days) ||
      (value?.installmentsCheckBox &&
        value.schedules.some(
          (schedule: { installments: number; percentage: number; installmentName: string }) =>
            !schedule.installments ||
            schedule.installments < 2 ||
            schedule.percentage < -100 ||
            schedule.percentage > 100 ||
            !schedule.installmentName ||
            schedule.installmentName.length > 25
        ))
    ) {
      return false;
    }
    return true;
  }

  areTermsValid() {
    let touched = false;

    if (this.policy && this.policy.terms.length !== this.terms.length) {
      touched = true;
    }

    for (let index = 0; index < this.terms.length; index++) {
      const policyTermForm = this.terms[index];

      if (policyTermForm.dirty || policyTermForm.touched) touched = true;

      const valid = this.isTermValid(index);

      if (!valid) return false;
    }

    return touched;
  }

  deleteTerm(index: number) {
    this.terms.splice(index, 1);
  }

  oneTimeCheckBox(index: number) {
    const termForm = this.terms[index];
    termForm.patchValue({ oneTimeCheckBox: !termForm.value.oneTimeCheckBox });
    termForm.markAsDirty();
  }

  installmentsCheckBox(index: number) {
    const termForm = this.terms[index];
    termForm.patchValue({ installmentsCheckBox: !termForm.value.installmentsCheckBox });
    termForm.markAsDirty();
  }

  getInstallments(index: number, installments: number) {
    const value = this.terms[index]?.value;
    return Math.floor(((value.years || 0) * 365 + (value.months || 0) * 30 + (value.days || 0)) / (installments || 1));
  }

  async save() {
    if (!this.policy) return;

    this.saving = true;

    const terms = this.mapFormToTerms();

    const policy = await this.policySvc
      .update(this.policy.id, {
        canCreateNewVersion: true,
        terms,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
      });

    if (policy) {
      if (this.policy.id === policy.id) {
        this.toastSvc.show({
          type: 'success',
          title: 'Updated policy',
          text: '<strong>' + this.policy.title + '</strong> has been successfully updated.',
        });

        this.policySvc.refreshPolicy();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text: 'An updated version of <strong>' + this.policy.title + '</strong> has been successfully created.',
        });

        this.policySvc.refreshPolicy(policy.id);
        this.policySvc.clearPlan();

        this.navigateTo('../' + policy.id);
      }

      this.saving = false;
      this.close();
    }
  }

  addSchedule(term: FormGroup) {
    const schedules = term.get('schedules') as FormArray;
    schedules.push(this.scheduleFormControl(0, 0, ''));
  }

  removeSchedule(term: FormGroup, schedule: FormGroup) {
    const schedules = term.get('schedules') as FormArray;
    schedules.removeAt(schedules.controls.indexOf(schedule));
  }

  private mapFormToTerms() {
    const terms: Policy.PolicyTerm[] = [];

    for (const termForm of this.terms) {
      const policyTerm = termForm.value;
      const paymentSchedules: Policy.PaymentSchedule[] = [];

      if (policyTerm.installmentsCheckBox) {
        for (const schedule of policyTerm.schedules) {
          if (schedule.installments) {
            paymentSchedules.push({
              id: v4().replace(/-/g, ''),
              installments: schedule.installments,
              rate: schedule.percentage || 0,
              title: schedule.installmentName,
            });
          }
        }
      }

      if (policyTerm.oneTimeCheckBox && policyTerm.oneTimeCheckBox) {
        paymentSchedules.push({ id: v4().replace(/-/g, ''), installments: 1, rate: 0, title: 'One Time Payment' });
      }

      const duration = (policyTerm.years || 0) * 365 + (policyTerm.months || 0) * 30 + (policyTerm.days || 0);

      terms.push({
        id: policyTerm.id ?? undefined,
        duration,
        paymentSchedules,
        title: policyTerm.title,
        order: policyTerm.order,
      });
    }

    return terms;
  }

  private mapTermsToForm(terms: Policy.PolicyTerm[]) {
    if (terms.length > 0) {
      const expanded = terms.length < 2;
      this.terms = terms.map(term => {
        const years = Math.floor(term.duration / 365);
        const months = Math.floor((term.duration - 365 * years) / 30);
        const days = Math.floor(term.duration - 365 * years - 30 * months);

        return this.formBuilder.group({
          id: term.id,
          title: [term.title || null, [Validators.required]],
          years: [years, [Validators.min(0)]],
          months: [months, [Validators.min(0)]],
          days: [days, [Validators.min(0)]],
          order: [term.order as number | null, [Validators.required]],
          installmentsCheckBox: term.paymentSchedules.find(schedule => schedule.title !== 'One Time Payment')
            ? true
            : false,
          schedules: this.formBuilder.array(
            term.paymentSchedules
              .filter(schedule => schedule.title !== 'One Time Payment')
              .map(schedule => {
                return this.scheduleFormControl(schedule.installments, schedule.rate, schedule.title);
              })
          ),
          oneTimeCheckBox: term.paymentSchedules.find(schedule => schedule.title === 'One Time Payment') ? true : false,
          expanded,
        });
      });
    } else {
      this.terms = [];
      this.newTerm();
    }
  }

  navigateTo(path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
