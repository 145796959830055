/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CdkMenuItem } from '@angular/cdk/menu';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
  selector: 'button[vs-menu-item], a[vs-menu-item]',
  templateUrl: 'menu-item.component.html',
  hostDirectives: [
    {
      directive: CdkMenuItem,
      inputs: ['cdkMenuItemDisabled: disabled'],
      outputs: ['cdkMenuItemTriggered: triggered'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemComponent {
  constructor(menuItem: CdkMenuItem) {
    const trigger = menuItem.trigger.bind(menuItem);
    menuItem.trigger = () => trigger({ keepOpen: this._keepOpen });
  }

  @HostBinding('class') private get _class() {
    const base = `group/menuitem block w-full py-1 px-1.5 text-sm font-medium text-themed-700 cursor-pointer select-none whitespace-nowrap`;
    const focus = `focus:outline-none`;
    const disabled = `disabled:opacity-50 disabled:pointer-events-none`;
    return `${base} ${focus} ${disabled}`;
  }

  get _containerClasses() {
    const base = 'p-2.5 flex items-center gap-3 w-full group-hover/menuitem:bg-themed-50 rounded-md ring-themed-100';
    const active = `group-active/menuitem:ring-4`;
    const focus = `group-focus/menuitem:ring-4`;

    return `${base} ${active} ${focus}`;
  }

  @Output() readonly triggered = new EventEmitter();

  private _disabled = false;
  @Input() get disabled() {
    return this._disabled;
  }
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _keepOpen = false;
  @Input() get keepOpen() {
    return this._keepOpen;
  }
  set keepOpen(value: BooleanInput) {
    this._keepOpen = coerceBooleanProperty(value);
  }
}
