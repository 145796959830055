<vs-dialog [config]="{ dialogContainerClass: 'max-w-[500px]' }" (closed)="step = null">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      
      icon: 'trash-01',
      iconTheme: 'warn',

      title: 'Delete claim lifecycle step?',
      subtitle: 'Are you sure you want to remove step <strong>' + step?.title + '</strong> from the claim lifecycle? This action can not be undone.'
    }"
  ></vs-dialog-header>

  <div class="px-2 pt-4 pb-2">
    <div class="flex gap-2 justify-between">
      <button vs-button size="md" appearance="outline" class="flex-grow" (click)="cancel()">Cancel</button>

      <button vs-button size="md" theme="danger" class="flex-grow" (click)="deleteStep()">Delete step</button>
    </div>
  </div>
</vs-dialog>
