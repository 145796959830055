import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { BehaviorSubject, firstValueFrom, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StorageService {
  private http = inject(HttpClient);

  _openedItem = new BehaviorSubject<StorageItem.Model | null>(null);

  get openedItem() {
    return this._openedItem.pipe(
      map(item => (item ? { ...item, processingResults: [...(item.processingResults ?? [])] } : null))
    );
  }

  async uploadFile(data: StorageItem.UploadCommandRequest<File>): Promise<StorageItem.UploadCommandResponse> {
    const formData = new FormData();
    for (const key in data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value = (data as any)[key];
      if (value !== undefined && value !== null) formData.set(key, value);
    }

    return await firstValueFrom(this.http.post<StorageItem.UploadCommandResponse>('/api/storage', formData));
  }

  list(dto?: StorageItem.ListRequest): Promise<StorageItem.ListResponse> {
    return firstValueFrom(this.http.get<StorageItem.ListResponse>('/api/storage', { params: { ...dto } }));
  }

  retrieve(id: string): Promise<StorageItem.Model> {
    return firstValueFrom(this.http.get<StorageItem.Model>(`/api/storage/${id}`));
  }

  delete(id: string): Promise<void> {
    return firstValueFrom(this.http.delete<void>(`/api/storage/${id}`));
  }

  update(id: string, dto: Omit<StorageItem.UpdateRequest, 'itemId'>) {
    return firstValueFrom(this.http.patch<StorageItem.UpdateResponse>(`/api/storage/${id}`, dto));
  }
}
