import { ObjectPropertyAssignment } from './object-property-assignment';

export enum PropertyType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  OBJECT = 'OBJECT',
}
export interface PropertyConfig {
  format: string | null;
}

export interface Model<T extends PropertyType> {
  id: string;
  type: T;

  name: string;
  valueKey: string;
  formId: string | null;

  config: PropertyConfig;

  created: Date;
  modified: Date;
}

export type BooleanFormat = 'checkbox' | 'radio' | null;
export interface BooleanPropertyConfig extends PropertyConfig {
  format: BooleanFormat;
  requiredValue?: boolean; //true means requiredValue is 'true', false means requiredValue is 'false'
}

export interface BooleanModel extends Model<PropertyType.BOOLEAN> {
  type: PropertyType.BOOLEAN;
  config: BooleanPropertyConfig;
}

export type DateFormat = 'date' | 'datetime' | null;
export interface DatePropertyConfig extends PropertyConfig {
  format: DateFormat;
  min?: Date;
  max?: Date;
}

export interface DateModel extends Model<PropertyType.DATE> {
  type: PropertyType.DATE;
  config: DatePropertyConfig;
}

export type NumberFormat = 'number' | 'currency' | null;
export interface NumberPropertyConfig extends PropertyConfig {
  format: NumberFormat;
  allowedValues?: number[];
  min?: number;
  max?: number;
}
export interface NumberModel extends Model<PropertyType.NUMBER> {
  type: PropertyType.NUMBER;
  config: NumberPropertyConfig;
}

export type ObjectFormat = null;
export interface ObjectPropertyConfig extends PropertyConfig {
  format: ObjectFormat;
}

export interface ObjectModel extends Model<PropertyType.OBJECT> {
  type: PropertyType.OBJECT;
  properties?: ObjectPropertyAssignment[];
  config: ObjectPropertyConfig;
}

export type TextFormat = 'text' | 'block' | 'email' | 'phone' | null;

export interface TextPropertyConfig extends PropertyConfig {
  format: TextFormat;
  allowedValues?: string[];
  minLength?: number;
  maxLength?: number;
}

export interface TextModel extends Model<PropertyType.TEXT> {
  type: PropertyType.TEXT;
  config: TextPropertyConfig;
}
