<ng-container *vsLet="permissionChecks$ | async as permissionChecks">
  <vs-dialog
    #dialog
    *ngIf="claim$ | async as claim"
    (closed)="navigateBack(claim.id)"
    [config]="{ dialogContainerClass: 'w-[500px] max-w-[800px]', startOpen: true }"
  >
    <vs-dialog-header
      [config]="{
          mode: 'simple',
          showDivider: true,
          
          icon: 'bank-note-01',
          iconTheme: 'primary',
          
          title: 'Manage payment',
          subtitle: 'Review the details below to issue this payment.'
        }"
    ></vs-dialog-header>

    <ng-container *ngIf="payment$ | async as payment; else loading">
      <div *ngIf="payment.status === 'FAILED'" class="mb-4 mt-8 text-danger-600 mx-2 text-sm">
        Sorry, the payment for {{payment.title}} has failed. Please retry again.
      </div>

      <div *ngIf="!payment?.destination || payment.amount <= 0" class="mb-4 mt-8 text-danger-600 mx-2 text-sm">
        <div class="flex flex-col gap-1">
          <span>to be able to issue this payment, please complete all the details of the payment.</span>
          <ul class="list-disc ml-8">
            <li *ngIf="!payment?.destination">Destination is Missing</li>
            <li *ngIf="payment.amount <= 0">Amount should be more than 0</li>
          </ul>
        </div>
      </div>

      <div class="border rounded-xl mb-4 mx-2 w-fit" [class.mt-8]="payment.status !== 'FAILED'">
        <div class="flex flex-col gap-4 p-6">
          <div class="flex flex-col gap-2.5">
            <div class="flex text-lg justify-between text-gray-700 items-center">
              <div class="flex items-center gap-2">
                {{ payment.title }}

                <vs-badge [theme]="payment.destination === pending ? 'warn' : status[payment.status]">
                  {{ payment.destination === pending ? 'Pending customer' : payment.status | titlecase }}
                </vs-badge>
              </div>

              <span>{{ (payment.amount / 100) | currency }}</span>
            </div>

            <div class="text-gray-500 text-sm" *ngIf="payment.description">{{ payment.description }}</div>

            <div class="text-sm">{{ payment.destinationName }}</div>
            <div class="text-sm">{{ payment.referenceId ?? '' }}</div>

            <ng-container *ngIf="payment.links.length">
              <ng-container *ngFor="let link of payment.links">
                <vs-info-card
                  *ngIf="link.link.claimId_1 !== payment.claimId && link.link.claimId_2 !== payment.claimId && !link.link.activityId_1"
                  class="flex-grow max-w-[50%]"
                  [config]="link.card"
                >
                  <vs-badge
                    *ngIf="link.link.claimItem_1 || link.link.claimItem_2"
                    slot="header-end"
                    [theme]="getItemTheme(link.link.claimItem_1 || link.link.claimItem_2 || undefined)"
                  >
                    {{ link.link.claimItem_1?.status || link.link.claimItem_2?.status | titlecase }}
                  </vs-badge>
                </vs-info-card>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="px-2 pb-2 flex justify-between">
        <vs-input-group>
          <button
            *ngIf="permissionChecks?.canVoid && [paymentStatus.DRAFT, paymentStatus.FAILED].includes(payment.status)"
            vs-button
            appearance="outline"
            (click)="confirmVoid.open()"
          >
            Void
          </button>

          <ng-container *ngIf="claim$ | async as claim">
            <button
              *ngIf="permissionChecks?.canEdit && [paymentStatus.DRAFT, paymentStatus.FAILED].includes(payment.status)"
              vs-button
              appearance="outline"
              (click)="update(payment, claim)"
            >
              Edit
            </button>
          </ng-container>
        </vs-input-group>

        <div class="flex gap-3 items-center">
          <button vs-button appearance="outline" (click)="dialog.close()">Close</button>
          <button
            *ngIf="permissionChecks?.canIssue && [paymentStatus.DRAFT, paymentStatus.FAILED].includes(payment.status)"
            vs-button
            theme="primary"
            appearance="default"
            [disabled]="payment.amount === 0 || !payment.destination || payment.destination === pending"
            (click)="issue(payment.id, payment.claimId, payment.title)"
          >
            {{ payment.status === 'FAILED' ? 'Retry Payment' : 'Issue Payment' }}
          </button>
        </div>
      </div>

      <vs-dialog #confirmVoid [config]="{ dialogContainerClass: 'w-[500px] max-w-[800px]' }">
        <vs-dialog-header
          [config]="{
                  mode: 'simple',
                  showDivider: true,
                  
                  icon: 'bank-note-01',
                  iconTheme: 'warn',
                  
                  title: 'Void payment',
                  subtitle: 'Are you sure you want to void this payment? This action cannot be undone.'
                }"
        ></vs-dialog-header>

        <div class="flex gap-3 pt-3 px-2 pb-2">
          <button class="basis-1/2" vs-button appearance="outline" (click)="confirmVoid.close()">Cancel</button>
          <button
            class="basis-1/2"
            vs-button
            appearance="default"
            theme="danger"
            (click)="void(payment.id, payment.claimId, payment.title);confirmVoid.close()"
          >
            Void Payment
          </button>
        </div>
      </vs-dialog>
    </ng-container>

    <ng-container *ngIf="permissionChecks?.canEdit">
      <ng-container *vsLet="destinations$ | async as destinations">
        <ng-container *vsLet="customer$ | async as customer">
          <ng-container *vsLet="customerId$ | async as customerId">
            <ws-claim-payment-dialog
              [destinations]="destinations"
              [customer]="customer"
              [customerId]="customerId?.id ?? null"
              [canManagePaymentMethods]="permissionChecks?.canManagePaymentMethods ?? false"
              (refresh)="dialog.close()"
            ></ws-claim-payment-dialog>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <div class="flex justify-center items-center h-60">
        <vs-icon name="loading-01" size="25" class="animate-spin text-primary"></vs-icon>
      </div>
    </ng-template>
  </vs-dialog>
</ng-container>
