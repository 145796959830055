import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { ProgressBarModule } from '@vsolv/vectors-ui/progress-bar';
import { RichTextEditorModule } from '@vsolv/vectors-ui/rich-text-editor';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { TableModule } from '@vsolv/vectors/table';
import { ClaimLifecycleStepsTableComponent } from './components';
import { ClaimLifecycleStepDialog, DeleteClaimLifecycleStepDialog } from './dialogs';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UtilsModule,
    BadgeModule,
    IconsModule,
    ButtonModule,
    ThemingModule,
    TableModule,
    DialogModule,
    DividerModule,
    CardModule,
    InputModule,
    FormsModule,
    SelectModule,
    RichTextEditorModule,
    MenuModule,
    ProgressBarModule,
    TooltipModule,
    AlertModule,
    IconsModule,
    TabsModule,
    OverlayModule,
  ],
  declarations: [
    // Components
    ClaimLifecycleStepsTableComponent,

    //Dialogs
    ClaimLifecycleStepDialog,
    DeleteClaimLifecycleStepDialog,
  ],
  exports: [ClaimLifecycleStepsTableComponent, DeleteClaimLifecycleStepDialog],
})
export class ClaimLifecycleStepsModule {}
