<div *vsLet="isLargeScreen$ | async as isLargeScreen">
  <form [formGroup]="form" [class]="!isLargeScreen ? 'flex flex-col gap-5 mt-4' : ''">
    <!-- Current Password -->
    <vs-form-field
      [appearance]="isLargeScreen ? 'horizontal' : 'vertical'"
      theme="primary"
      [config]="{ label: 'Current password' }"
      [class]="isLargeScreen ? 'pt-6 pb-6' : ''"
    >
      <input
        vsInput
        placeholder="Enter your current password"
        type="password"
        formControlName="currentPassword"
        autocomplete="on"
      />
    </vs-form-field>

    <vs-divider></vs-divider>

    <!-- New Password -->
    <vs-form-field
      [appearance]="isLargeScreen ? 'horizontal' : 'vertical'"
      theme="primary"
      [config]="{
        hint: 'Your new password must be at least 8 characters. It must also contain uppercase letters, lowercase letters, and numbers.',
        label: 'New password'
      }"
      [class]="isLargeScreen ? 'pt-6 pb-6 ' : ''"
    >
      <input
        vsInput
        placeholder="Enter your new password"
        #passwordInput
        type="password"
        formControlName="newPassword"
        autocomplete="on"
      />
    </vs-form-field>

    <!-- Confirm New Password -->
    <vs-form-field
      theme="primary"
      [appearance]="isLargeScreen ? 'horizontal' : 'vertical'"
      [config]="{ label: 'Confirm new password' }"
      [class]="isLargeScreen ? 'pt-6 pb-6' : ''"
    >
      <input
        vsInput
        placeholder="Re-type your new password"
        #confirmInput
        type="password"
        formControlName="confirmNewPassword"
        autocomplete="on"
      />
      <span class="text-danger" slot="hint">{{
        (passwordInput.value !== confirmInput.value && form.get('confirmNewPassword')?.touched) ||
        (passwordInput.value !== confirmInput.value && passwordInput.value.length === confirmInput.value.length)
          ? 'Passwords do not match!'
          : ''
      }}</span>
    </vs-form-field>

    <vs-divider [class]="!isLargeScreen ? 'block' : 'hidden'"></vs-divider>

    <!-- Form Save buttons -->
    <div class="flex gap-3 justify-end">
      <button
        [class]="!isLargeScreen ? 'grow-[1] max-w-[50%]' : ''"
        vs-button
        size="md"
        type="button"
        appearance="outline"
        [disabled]="!form.dirty"
        (click)="reset()"
      >
        Cancel
      </button>
      <button
        [class]="!isLargeScreen ? 'grow-[1] max-w-[50%]' : ''"
        vs-button
        size="md"
        theme="primary"
        type="button"
        [disabled]="!inputIsValid() || !form.dirty"
        (click)="submit()"
      >
        Update password
      </button>
    </div>
    <!-- TODO currently not supported -->
    <!-- SSO -->
    <!-- <div class="border-b border-gray-200 pb-5">
      <div>
        <p class="font-[600] text-[18px]">Single Sign On</p>
        <p class="text-[14px]">Below are the service providers that you have linked to this account.</p>
      </div>
    </div> -->

    <!-- <div class="table border-b border-gray-200 pb-5">Table to be rendered</div> -->

    <!-- Action Button -->
    <!-- <div>
      <button vs-button size="md" theme="primary">Delete Account</button>
    </div> -->
  </form>
</div>
