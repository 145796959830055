import { Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: 'img[vsImagePreview]',
})
export class ImagePreviewDirective {
  @HostBinding('class') get styles() {
    const visible = this.elementRef.nativeElement.src ? `block` : `hidden`;
    const border = this.showBorder ? `border border-themed-300 rounded-md shadow-sm bg-themed-200` : ``;
    return `${visible} ${border} relative object-cover`;
  }

  @Input() set vsImagePreview(file: File | File[] | null | string) {
    const url =
      file && typeof file !== 'string' && !Array.isArray(file)
        ? URL.createObjectURL(file)
        : typeof file === 'string'
        ? file
        : null;

    if (url) this.renderer.setAttribute(this.elementRef.nativeElement, 'src', url);
    else this.renderer.removeAttribute(this.elementRef.nativeElement, 'src');
  }

  @Input() showBorder = true;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {}
}
