<ng-container *ngIf="claim$ | async as claim">
  <vs-app-page
    *vsLet="isMobile$ | async as isMobile"
    [config]="{
      icon: 'file-06',
      title: 'Claim #'+ claim.claimNumber,
      description: 'Opened: ' + (claim.created | date) + ' | Last updated: ' + (claim.modified | amTimeAgo: true) + ' ago'
    }"
  >
    <vs-badge slot="title-end" [theme]="claimTheme(claim)" [config]="{ displayStatusIcon: true }">
      {{ claim.status.replace('CANCELLED', 'CANCELED') | titlecase }}
    </vs-badge>

    <vs-breadcrumbs *vsLet="permissionKey$ | async" slot="breadcrumbs">
      <vs-breadcrumbs-item [config]="{ label: 'Claims', routerLink: '..' }"></vs-breadcrumbs-item>
      <vs-breadcrumbs-item [config]="{ label: 'Claim #' + claim.claimNumber }"></vs-breadcrumbs-item>
    </vs-breadcrumbs>

    <div *ngIf="claim.status === open" class="flex gap-2 flex-wrap" slot="header-end">
      <vs-input-group theme="danger">
        <button
          *ngIf="canMarkClaimAsExpired$ | async"
          vs-button
          tooltipStyle="light"
          appearance="outline"
          [vsTooltip]="'Expire' + ((claim.warranty?.expiryDate || 0) < now ? 'd' : 's') + ': ' + (claim.warranty?.expiryDate | date)"
          (click)="openExpireDialog()"
        >
          <vs-icon name="hourglass-03"></vs-icon> Mark as Expired
        </button>

        <button *ngIf="canCancelClaim$ | async" vs-button appearance="outline" (click)="openCancelDialog()">
          <vs-icon name="file-x-02"></vs-icon> Cancel Claim
        </button>
      </vs-input-group>

      <button
        *ngIf="canCloseClaim$ | async"
        vs-button
        appearance="outline"
        [class.flex-grow]="isMobile"
        (click)="openCloseDialog()"
      >
        Close
      </button>
    </div>

    <div class="flex justify-between gap-2 sm:overflow-x-auto scrollbar-thin sm:flex-nowrap flex-wrap mb-4">
      <vs-info-card
        class="basis-[99.8%] sm:min-w-[256px] min-w-[99.8%]"
        [class]="claim.warranty?.distributor ? 'sm:basis-[24.5%] sm:max-w-[24.5%]' : 'sm:basis-[32.8%] sm:min-w-[32.8%]' "
        [config]="{
          imageUrl: claim.warranty?.customer?.user?.photoURL || undefined,
          icon: claim.warranty?.customer?.user?.photoURL ? undefined : 'user-01',

          title: claim.warranty?.customer?.name,
          subtitle: claim.warranty?.customer?.email,
          subtitleIcon: 'mail-01',

          routerLink: (canViewCustomerDetails$ | async) ? '../../customers/' + claim.warranty?.customer?.id : undefined
        }"
      ></vs-info-card>

      <vs-info-card
        *ngIf="claim.warranty?.asset as asset"
        class="basis-[99.8%] sm:min-w-[256px] min-w-[99.8%]"
        [class]="claim.warranty?.distributor ? 'sm:basis-[24.5%] sm:max-w-[24.5%]' : 'sm:basis-[32.8%] sm:min-w-[32.8%]' "
        [config]="{
          icon: $any(asset.propertySet?.icon || 'home-03'),
          title: asset.name,
          subtitleStyles: '-mt-0.5'
        }"
      >
        <vs-property-set-values
          slot="subtitle"
          [propertySetId]="asset.propertySetId"
          [propertyValues]="claim.warranty?.propertySetSubmission?.value || {}"
          [showHidden]="true"
          [firstOnly]="true"
        ></vs-property-set-values>
      </vs-info-card>

      <vs-info-card
        *ngIf="claim.warranty as warranty"
        class="basis-[99.8%] sm:min-w-[256px] min-w-[99.8%]"
        [class]="claim.warranty.distributor ? 'sm:basis-[24.5%] sm:max-w-[24.5%]' : 'sm:basis-[32.8%] sm:min-w-[32.8%]' "
        [config]="{
          icon: 'folder-shield',

          title: warranty.plan?.title,
          subtitle: warranty.contractNumber,

          routerLink: (canViewWarranty$ | async) ? '../../warranty/' + claim.warranty.id : undefined
        }"
      >
        <vs-badge *vsLet="warrantyTheme(claim) as theme" slot="header-end" [theme]="theme">
          <vs-icon name="circle" type="solid" size="8" [class]="'text-' + theme + '-500'"></vs-icon>
        </vs-badge>
      </vs-info-card>

      <vs-info-card
        *ngIf="claim.warranty?.distributor as distributor"
        class="sm:basis-[24.5%] sm:max-w-[24.5%] basis-[99.8%] sm:min-w-[256px] min-w-[99.8%]"
        [config]="{
          icon: 'package',

          title: distributor.name,
          subtitle: distributor.email ?? distributor.phone ?? distributor.id,

          routerLink: (canViewDistributor$ | async) ? '../../distributors/' + distributor?.id : undefined
        }"
      ></vs-info-card>
    </div>

    <vs-divider></vs-divider>

    <div *ngIf="canViewLifecycle$ | async" class="my-4">
      <ws-claim-lifecycle [claim]="claim"></ws-claim-lifecycle>
    </div>

    <div class="flex max-w-full" [class.flex-col]="isMobile" [class.overflow-x-hidden]="!timeline.open">
      <div class="transition-all" [style.width]="!isMobile ? timeline.open ? '55%' : '98%' : '100%'">
        <div class="mb-4">
          <vs-tab-nav *vsLet="tabs$ | async as tabs" theme="primary" [config]="{ items: tabs ?? [] }"> </vs-tab-nav>
        </div>

        <router-outlet></router-outlet>
      </div>

      <div class="w-full transition-all" [style.max-width]="!isMobile ? timeline.open ? '45%' : '42px' : '100%'">
        <ws-claim-timeline #timeline [claim]="claim"></ws-claim-timeline>
      </div>
    </div>

    <vs-dialog #expireClaimDialog [config]="{ dialogContainerClass: 'max-w-[600px]'}">
      <vs-dialog-header
        [config]="{
          mode: 'simple',

          icon: 'file-x-02',
          iconTheme: 'warn',

          title: 'Mark Claim as Expired',
          subtitle: 'Are you sure you want to mark <strong>Claim #' + claim.claimNumber + '</strong> as expired? This action cannot be undone.'
        }"
      ></vs-dialog-header>

      <div class="flex gap-2">
        <button vs-button appearance="outline" class="flex-1" (click)="expireClaimDialog.close()">Back</button>
        <button vs-button theme="danger" class="flex-1" (click)="expire(claim)">Expire Claim</button>
      </div>
    </vs-dialog>

    <vs-dialog #cancelClaimDialog [config]="{ dialogContainerClass: 'max-w-[600px]'}">
      <vs-dialog-header
        [config]="{
          mode: 'simple',

          icon: 'file-x-02',
          iconTheme: 'warn',

          title: 'Cancel Claim',
          subtitle: 'Are you sure you want to cancel <strong>Claim #' + claim.claimNumber + '</strong>? This action cannot be undone.'
        }"
      ></vs-dialog-header>

      <div class="flex gap-2">
        <button vs-button appearance="outline" class="flex-1" (click)="cancelClaimDialog.close()">Back</button>
        <button vs-button theme="danger" class="flex-1" (click)="cancel(claim)">Cancel Claim</button>
      </div>
    </vs-dialog>

    <vs-dialog #closeClaimDialog [config]="{ dialogContainerClass: 'max-w-[600px]'}">
      <vs-dialog-header
        [config]="{
          mode: 'simple',

          icon: 'file-x-02',
          iconTheme: 'warn',

          title: 'Close Claim',
          subtitle: 'Are you sure you want to close <strong>Claim #' + claim.claimNumber + '</strong>? This action cannot be undone.'
        }"
      ></vs-dialog-header>

      <div class="flex gap-2">
        <button vs-button appearance="outline" class="flex-1" (click)="closeClaimDialog.close()">Back</button>
        <button vs-button theme="danger" class="flex-1" (click)="close(claim)">Close Claim</button>
      </div>
    </vs-dialog>

    <ws-claim-item-details-dialog #addClaimItem></ws-claim-item-details-dialog>
  </vs-app-page>
</ng-container>
