import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateCustomerOptions } from '@vsolv/dwolla/domain';
import { AddNewPaymentMethodDialog, DwollaService } from '@vsolv/packages/reimbursement/web';
import { BehaviorSubject, Observable, catchError, combineLatest, map, of, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-manage-payment-dialog',
  templateUrl: './manage-payment-methods.dialog.html',
})
export class ManagePaymentMethodsDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private dwollaSvc: DwollaService
  ) {}

  @ViewChild(AddNewPaymentMethodDialog) addNewPaymentMethodDialog?: AddNewPaymentMethodDialog;

  protected maxNumberOfDestinations = 6; // Number of funding sources allowed for each customer in dwolla

  protected refresh$ = new BehaviorSubject<null>(null);
  protected loadingMethods$ = new BehaviorSubject<boolean>(false);

  protected readonly claim$ = this.claimSvc.getClaim();

  protected destinations$ = combineLatest([this.claim$, this.refresh$]).pipe(
    tap(() => this.loadingMethods$.next(true)),
    switchMap(async ([claim]) =>
      claim?.warranty?.customer ? this.dwollaSvc.listFundingSource(claim.warranty.customer.email) : null
    ),
    tap(() => this.loadingMethods$.next(false))
  );

  protected readonly customer$: Observable<CreateCustomerOptions | null> = this.claim$.pipe(
    map(claim => {
      return {
        email: claim?.warranty?.customer?.email,
        lastName:
          claim?.warranty?.customer?.name.substring(claim?.warranty?.customer?.name.indexOf(' ') + 1) ||
          claim?.warranty?.customer?.name.substring(0, claim?.warranty?.customer?.name.indexOf(' ')),
        firstName:
          claim?.warranty?.customer?.name.substring(0, claim?.warranty?.customer?.name.indexOf(' ')) ||
          claim?.warranty?.customer?.name.substring(claim?.warranty?.customer?.name.indexOf(' ') + 1),
        correlationId: claim?.warranty?.customer?.id,
      } as CreateCustomerOptions;
    }),
    catchError(() => of(null))
  );

  protected readonly customerId$ = this.customer$.pipe(
    switchMap(async customer => (customer ? this.dwollaSvc.findCustomer(customer.email) : null))
  );

  protected navigateTo() {
    this.router.navigate([`..`], { relativeTo: this.route });
  }
}
