import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContentCardComponent } from './components/content-card/content-card.component';

@NgModule({
  imports: [CommonModule],
  declarations: [ContentCardComponent],
  exports: [ContentCardComponent],
})
export class ContentCardModule {}
