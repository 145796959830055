import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ProvisioningSessionService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class SessionAddonGuard implements CanActivate {
  private sessionSvc = inject(ProvisioningSessionService);
  private router = inject(Router);

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    const session = await firstValueFrom(this.sessionSvc.getCurrentSession());
    if (!session?.planId || !session.policyTermId) {
      if (route.data['staffView']) return this.router.createUrlTree(['/provisioning/plan']);
      else return this.router.createUrlTree(['/checkout/plan']);
    }
    return true;
  }
}
