<div class="flex flex-col gap-6">
  <form [formGroup]="form">
    <div *ngIf="!config.tenantEnabled" class="flex flex-col gap-4" [formGroup]="form">
      <p>
        Once enabled, administrators with access to the organization settings will be able to view and manage SFTP
        credentials. With these credentials, administrators can use their SFTP application to upload CSVs - securely
        bulk provisioning warranties.
      </p>
      <p>
        To enable this app, enter an email below to receive important notifications regarding the status of uploaded
        files during SFTP.
      </p>
      <label class="flex flex-col gap-2">
        <span class="text-gray-700 font-medium text-sm">Notification email</span>
        <input vsInput type="text" formControlName="email" class="mr-2" />
        <span class="text-gray-600 text-sm"
          >This email will receive updates on the processing status of uploaded files.</span
        >
      </label>
    </div>

    <vs-tabs *ngIf="config.tenantEnabled" theme="primary">
      <vs-tab [config]="{label: 'Settings'}">
        <div class="flex flex-col gap-4" [formGroup]="form">
          <p>
            Administrators with access to the organization settings will be able to view and manage SFTP credentials.
            With these credentials, administrators can use their SFTP application to upload CSVs - securely bulk
            provisioning warranties.
          </p>
          <label class="flex flex-col gap-2">
            <span class="text-gray-700 font-medium text-sm">Notification email</span>
            <input vsInput type="text" formControlName="email" class="mr-2" />
            <span class="text-gray-600 text-sm">
              This email will receive updates on the processing status of uploaded files.
            </span>
          </label>
          <label class="flex flex-row items-center gap-2">
            <input vsInput type="checkbox" appearance="toggle" formControlName="distEnabled" class="mr-2" />
            <div class="flex flex-col">
              <span class="text-gray-700 font-medium text-sm">Enable SFTP credentials for distributors</span>
              <span class="text-gray-600 text-sm">
                Distributors will find their SFTP credentials on their settings page to enable bulk CSV warranty
                provisioning
              </span>
            </div>
          </label>
        </div>
      </vs-tab>
      <vs-tab [config]="{label: 'Credentials'}">
        <ws-sftp-key-management [checkStaffPermissions]="true" #keyManager></ws-sftp-key-management>
      </vs-tab>
    </vs-tabs>
  </form>

  <div
    class="flex flex-col sm:flex-row self-end gap-4 w-full sm:w-fit [&>button]:sm:w-fit [&>button]:w-full"
    *ngIf="canManageSFTP$ | async"
  >
    <button
      *ngIf="config.tenantEnabled"
      vs-button
      appearance="default"
      theme="primary"
      class="sm:order-2"
      [disabled]="disableUpdate() || updating"
      (click)="updateConfig()"
    >
      Update
    </button>
    <button
      vs-button
      [appearance]="config.tenantEnabled ? 'outline' : 'default'"
      [disabled]="(!config.tenantEnabled && form.invalid) || updating"
      theme="primary"
      class="sm:order-1"
      (click)="config.tenantEnabled ? deleteDialog.open() : enable()"
    >
      {{config.tenantEnabled ? 'Disable' : 'Enable'}}
    </button>
  </div>
</div>

<vs-dialog #deleteDialog [config]="{dialogContainerClass: 'w-full md:w-96'}">
  <vs-dialog-header
    [config]="{
      icon: 'x-circle',
      iconTheme:'primary',
      title: 'Disable SFTP?',
      subtitle: 'Are you sure you want to disable this app? Administrators and other parties will no longer be able to access SFTP.',
      mode: 'simple',
      hideCloseButton: true
    }"
  ></vs-dialog-header>
  <div class="w-full flex flex-col sm:flex-row gap-2 justify-between [&>button]:sm:w-1/2 [&>button]:w-full">
    <button vs-button class="sm:order-2" [disabled]="updating" theme="danger" (click)="disable()">
      Disable Application
    </button>
    <button vs-button class="sm:order-1" [disabled]="updating" appearance="outline" (click)="deleteDialog.close()">
      Cancel
    </button>
  </div>
</vs-dialog>
