<ng-container *vsLet="distributor$ | async as distributor">
  <ng-container *vsLet="session$ | async as session">
    <ng-container *vsLet="isLargeScreen$ | async as isLargeScreen">
      <ng-container *vsLet="staffView$ | async as staffView">
        <ng-container *ngIf="!showFlow; else flow">
          <div class="flex flex-col justify-center gap-4">
            <div class="relative flex flex-col items-center gap-4 mb-3">
              <div name="icon"><vs-featured-icon name="user-01" theme="primary"></vs-featured-icon></div>
              <div name="title" class="text-3xl font-semibold leading-[38px]">Let's get you covered!</div>
              <div name="subtitle" class="text-sm text-gray-600">Please tell us about yourself.</div>
            </div>

            <vs-form-field
              class="w-[360px]"
              appearance="responsive"
              [config]="{ label: 'Email*' }"
              [theme]="emailControl.hasError('required') ? 'danger' : 'primary'"
            >
              <input vsInput type="email" placeholder="johndoe@gmail.com" [formControl]="emailControl" />

              <span *ngIf="emailControl.touched && emailControl.hasError('required')" class="text-danger" slot="hint">
                Email is required
              </span>

              <span *ngIf="emailControl.hasError('email')" class="text-danger" slot="hint">
                Please enter a valid email
              </span>
            </vs-form-field>

            <button vs-button theme="primary" [disabled]="emailControl.invalid" (click)="next()">Next</button>
          </div>
        </ng-container>

        <ng-template #flow>
          <div class="relative flex flex-col items-center gap-4 mb-2">
            <div class="flex gap-2 w-full items-center">
              <div class="w-12 h-12">
                <button
                  *ngIf="!isLargeScreen"
                  vs-icon-button
                  appearance="clear"
                  theme="default"
                  size="lg"
                  (click)="previousPage(staffView ?? false)"
                >
                  <vs-icon name="chevron-left" size="20"></vs-icon>
                </button>
              </div>

              <div class="flex justify-center items-center flex-grow" name="icon">
                <vs-featured-icon name="user-01" theme="primary"></vs-featured-icon>
              </div>

              <div class="w-12 h-12">
                <button
                  *ngIf="!isLargeScreen"
                  vs-icon-button
                  appearance="clear"
                  theme="default"
                  size="lg"
                  [disabled]="!stepCompleted"
                  (click)="nextPage(session, staffView ?? false, distributor)"
                >
                  <vs-icon name="chevron-right" size="20"></vs-icon>
                </button>
              </div>
            </div>

            <div name="title" class="text-3xl font-semibold leading-[38px] text-center">
              {{ staffView ? "Let's provision a warranty!" : "Let's get you covered!" }}
            </div>

            <div name="subtitle" class="text-sm text-gray-600 mb-8">
              {{ staffView ? 'Please select a customer.' : 'Please tell us about yourself.' }}
            </div>
          </div>

          <div class="flex gap-4 justify-center items-stretch w-full max-w-[1057px]" [class.flex-col]="!isLargeScreen">
            <!-- customer picker and details -->
            <div class="w-full max-w-[512px] flex flex-col gap-2">
              <p class="font-semibold text-md">{{ staffView ? 'Customer' : 'Personal' }} information</p>

              <!-- customer picker -->
              <div *ngIf="staffView">
                <vs-form-field [config]="{ label: 'Select an existing customer' }" appearance="vertical">
                  <ws-provisioning-customer-picker
                    [distributor]="distributor"
                    [disabled]="!!session?.customer"
                    [value]="session?.customer || customer.value || dataCustomer || null"
                    [permissionKey]="session?.distributor?.permissionKey ?? distributorId"
                    (valueChanges)="$event ? customerSelected($event, session) : ''"
                  ></ws-provisioning-customer-picker>
                </vs-form-field>

                <div *ngIf="!customer.value" class="flex justify-evenly place-items-center mt-8">
                  <vs-divider></vs-divider>
                  <span class="text-gray-600 text-xs self-center">Or</span>
                  <vs-divider></vs-divider>
                </div>
              </div>

              <p *ngIf="staffView" class="font-semibold text-md mt-8 mb-6 text-gray-700">
                {{ !customer.value ? 'Register a new customer' : 'Customer Details' }}
              </p>

              <!-- customer details -->

              <ws-customer-details-input
                [distributor]="distributor"
                [formControl]="customerInfo"
                [staffView]="staffView ?? false"
                (customerAdded)="customerSelected($event, session)"
              ></ws-customer-details-input>
            </div>

            <!-- vertical divider -->
            <div *ngIf="isLargeScreen" class="w-[1px] !h-full bg-gray-300"></div>

            <!-- address -->
            <div class="w-full max-w-[512px] flex flex-col gap-2">
              <p class="font-semibold text-md">Home address</p>

              <vs-address-input
                class="w-full"
                [formControl]="addressControl"
                [staffView]="staffView ?? false"
              ></vs-address-input>
            </div>
          </div>

          <div class="w-full max-w-[1057px]">
            <!-- buttons -->
            <div class="flex gap-3 mt-4" [class.flex-col]="!isLargeScreen">
              <button
                vs-button
                theme="primary"
                [disabled]="!stepCompleted || isProcessing"
                [class]="!isLargeScreen ? 'min-w-full' : ''"
                (click)="nextPage(session, staffView ?? false, distributor)"
              >
                <div class="flex gap-3 items-center">
                  <span>{{ staffView || dataCustomer ? 'Continue' : 'Register' }}</span>
                  <vs-icon *ngIf="stepCompleted" name="check"></vs-icon>
                </div>
              </button>

              <button
                *ngIf="staffView"
                vs-button
                appearance="outline"
                [class]="!isLargeScreen ? 'min-w-full' : ''"
                (click)="previousPage(staffView ?? false)"
              >
                Back
              </button>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ws-checkout-customer-exists-sign-in-dialog #customerExistsDialog></ws-checkout-customer-exists-sign-in-dialog>
