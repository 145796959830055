/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, tap } from 'rxjs';

@Component({
  selector: 'ws-policy-term-picker',
  templateUrl: './policy-term-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PolicyTermPickerComponent,
    },
  ],
})
export class PolicyTermPickerComponent implements ControlValueAccessor {
  @Input() policy: Policy.Model | null = null;
  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(this.policy);

  @Input() viewablePlansAndTerms?: Record<string, string[] | null>;
  @PropertyListener('viewablePlansAndTerms') viewablePlansAndTerms$ = new BehaviorSubject<
    Record<string, string[] | null> | undefined
  >(undefined);

  @Output() valueChanges = new EventEmitter();

  @Input() selected!: string | null;
  disabled = false;
  touched = false;

  terms$ = combineLatest([this.policy$, this.viewablePlansAndTerms$]).pipe(
    map(([policy, viewablePlansAndTerms]) => {
      let terms = policy?.terms || [];

      let termFilters: string[] = [];

      if (viewablePlansAndTerms) {
        for (const [_planId, termIds] of Object.entries(viewablePlansAndTerms)) {
          if (termIds?.length) {
            termFilters = [...termFilters, ...termIds];
          } else {
            termFilters = [];
            break;
          }
        }
      }

      if (termFilters.length && terms.length) {
        terms = terms.filter(term => termFilters.includes(term.id));

        if (this.selected && !termFilters.includes(this.selected)) {
          this.selectTerm(terms[0].id);
        }
      }

      const configuredTerms: Policy.PolicyTerm[] = [];

      for (const plan of policy?.plans || []) {
        for (const term of terms) {
          if (configuredTerms.some(ct => ct.id === term.id)) {
            //already in the array
            continue;
          }

          const planConfig = plan.coverageConfigs[term.id];
          if (planConfig) {
            //term is configured, add it to array
            configuredTerms.push(term);
          } else {
            //term is not configured, continue to next term
            continue;
          }
        }
      }

      return configuredTerms;
    }),
    tap(res => {
      if (!this.selected && res.length) this.selectTerm(res[0].id);
    })
  );

  onChange = (_selected: string) => {};

  onTouched = () => {};

  selectTerm(termId: string) {
    this.onTouched(); // <-- mark as touched
    this.selected = termId;
    this.onChange(this.selected); // <-- call function to let know of a change
    this.valueChanges.emit(termId);
  }
  writeValue(termId: string): void {
    this.selected = termId;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn; // <-- save the function
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }
}
