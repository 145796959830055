<ng-container *vsLet="warrantyCoverage?.requirement === 'NOT_APPLICABLE' as isExcluded">
  <vs-card *ngIf="!isExcluded; else notApplicableTemplate" class="w-full p-3 flex flex-col gap-3 h-full">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </vs-card>

  <ng-template #notApplicableTemplate>
    <div
      class="p-3 flex flex-col gap-3 h-full rounded-xl bg-base border-dashed border-2 border-gray-300 w-full"
      [vsTooltip]="'This coverage is excluded from ' + (isCustomerPortal ? 'your' : 'your customer\'s') + ' plan.'"
    >
      <ng-container [ngTemplateOutlet]="content"></ng-container>
    </div>
  </ng-template>

  <ng-template #content>
    <span class="text-md font-semibold truncate">{{ warrantyCoverage?.coverage?.title }}</span>
    <vs-badge class="w-fit -mt-2">{{ warrantyCoverage?.coverage?.group }}</vs-badge>
    <p class="text-md font-normal line-clamp-3">{{ warrantyCoverage?.coverage?.description }}</p>

    <button
      vs-button
      appearance="clear"
      class="flex items-center text-sm text-gray-600 !p-2 mt-auto !-ml-2 !h-6"
      (click)="learnMore.open()"
      [disabled]="isCustomerPortal && isExcluded"
    >
      Learn More <vs-icon name="chevron-right"></vs-icon>
    </button>

    <ws-coverage-liability-limit
      *ngIf="!isExcluded || warrantyCoverage?.liabilityInfo"
      class="overflow-auto max-w-full"
      [item]="warrantyCoverage"
    ></ws-coverage-liability-limit>

    <!-- New Claim -->
    <div *vsLet="$any(remainingLiabilityLimit$ | async) as remainingLiabilityLimit">
      <div *vsLet="isPastWaitingPeriod$ | async as isPastWaitingPeriod">
        <div *ngIf="(canCreateClaim$ | async) && !(isCustomerPortal && isExcluded)" class="flex justify-end">
          <div
            [vsTooltip]="
              remainingPeriod > 0 && isPastWaitingPeriod === false
                ? remainingPeriod + ' ' + period + ' remaining in the waiting period.'
                : ''
            "
            tooltipStyle="light"
          >
            <button
              vs-button
              appearance="clear"
              class="flex items-center text-sm text-gray-600 !p-2 !h-6"
              [disabled]="
                warranty?.frozen ||
                warranty?.status !== activated ||
                remainingLiabilityLimit <= 0 ||
                !isPastWaitingPeriod
              "
              (click)="newClaim()"
            >
              New Claim
              <vs-icon name="arrow-right" class="mr-1"></vs-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ws-claim-coverage-dialog
    #learnMore
    [coverage]="warrantyCoverage?.coverage"
    [warrantyCoverage]="warrantyCoverage"
    [deductible]="warrantyCoverage?.deductible ?? 0"
    [liabilityLimit]="warrantyCoverage?.liabilityLimit ?? 0"
  ></ws-claim-coverage-dialog>

  <ws-open-claim-dialog #claimDialog></ws-open-claim-dialog>
</ng-container>

<ng-template #remainingLiability>
  <div class="flex flex-col mb-2">
    <p class="text-xs font-semibold text-gray-600 p-1 min-w-[64px] max-w-[241px] text-center">
      The aggregate limit has been reached. No new claims can be initiated at this time. Please review your coverage
      details or contact customer support.
    </p>
  </div>
</ng-template>
<ng-template #other> </ng-template>
