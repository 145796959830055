import { Directive, Input } from '@angular/core';
import { InputBoxDirective } from './input-box.directive';
import { CheckBoxAppearance, CheckboxInputDirective } from './input-checkbox.directive';

@Directive({
  standalone: true,
  selector: `
    input[type="radio"][vsInput],
  `,
  hostDirectives: [InputBoxDirective],
})
export class RadioInputDirective extends CheckboxInputDirective {
  @Input() override appearance: CheckBoxAppearance = 'radio';
}
