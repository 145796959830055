import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CreateCustomerOptions } from '@vsolv/dwolla/domain';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';

@Component({
  selector: 'vs-add-new-payment-method',
  templateUrl: './add-new-payment-method.dialog.html',
})
export class AddNewPaymentMethodDialog {
  @ViewChild(DialogComponent) private readonly dialog?: DialogComponent;
  @Input() customer: CreateCustomerOptions | null = null;

  @Input() customerId: string | null = null;

  @Output() destination = new EventEmitter<{ destination: string }>();

  confirmDestination({ fundingSourceId }: { fundingSourceId: string }) {
    this.destination.emit({ destination: fundingSourceId });
    this.dialog?.close();
  }

  open() {
    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }
}
