<div *vsLet="hasGlobalAccess$ | async as hasGlobalAccess" class="flex flex-col gap-16">
  <div class="flex flex-col gap-5 items-center">
    <vs-featured-icon name="package" theme="primary" class="w-14 h-14"></vs-featured-icon>
    <div class="text-3xl font-semibold leading-[38px]">Selling for a distributor?</div>
    <div class="text-gray-600">Find a distributor below or skip to continue provisioning a warranty.</div>
  </div>

  <form class="flex flex-col gap-4">
    <ws-provisioning-distributor-picker
      *vsLet="session$ | async as session"
      [session]="session"
      [formControl]="distributorControl"
      (valueChanges)="complete()"
    ></ws-provisioning-distributor-picker>

    <div class="flex flex-row gap-2">
      <button *ngIf="hasGlobalAccess" vs-button appearance="outline" (click)="skip()">Skip</button>

      <button vs-button theme="primary" [disabled]="!distributorControl.value" (click)="complete()">
        Continue
        <vs-icon *ngIf="distributorControl.value" name="check"></vs-icon>
      </button>
    </div>
  </form>
</div>
