<vs-dialog
  *vsLet="isMobile$ | async as isMobile"
  [config]="{ dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]' }"
>
  <vs-dialog-header
    [config]="{
      mode: isMobile ? 'default' : 'simple',
      hideCloseButton: false,
      
      icon: 'package',
      iconTheme: 'primary',

      title: editing ? 'Update ' + _distributor?.name : 'Register New Distributor',
      subtitle: editing ? 'Update ' + _distributor?.name + '\'s name and contact information' : 'Enter the details of the distributor you would like to register to the dashboard.',

      showDivider: true
    }"
  ></vs-dialog-header>

  <form [formGroup]="form">
    <ws-distributor-details-form formControlName="distributorDetails"></ws-distributor-details-form>

    <div class="flex flex-col-reverse sm:flex-row sm:justify-end gap-3 pt-4">
      <button vs-button appearance="outline" class="w-full sm:w-auto" [disabled]="saving" (click)="closeDialog()">
        Cancel
      </button>

      <button
        vs-button
        theme="primary"
        class="w-full sm:w-auto"
        [disabled]="saving || form.invalid || (editing && !form.dirty)"
        (click)="submit()"
      >
        {{ editing ? 'Update' : 'Create' }}
      </button>
    </div>
  </form>
</vs-dialog>
