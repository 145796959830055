import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2 } from '@angular/core';
import { CustomThemeDirective } from '@vsolv/vectors-ui/theming';
import { InputBoxDirective } from './input-box.directive';

@Directive({
  standalone: true,
  selector: `
    input[type="color"][vsInput],
  `,
  hostDirectives: [InputBoxDirective, CustomThemeDirective],
})
export class ColorInputDirective implements OnInit {
  @HostBinding('class') get styles() {
    const clear = `appearance-none`;
    const swatch = `
      bg-transparent
      cursor-pointer
      overflow-hidden
      [&::-webkit-color-swatch]:border-none
      [&::-webkit-color-swatch-wrapper]:p-0
    `;
    const box = this.size === 'default' ? `h-10 w-10 min-w-[theme(space.10)]` : `w-20 h-20`;
    return `theme-primary-inherit ${clear} ${box} ${swatch}`;
  }

  @Input() size: 'default' | 'lg' = 'default';

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private customTheme: CustomThemeDirective) {
    ['input', 'focus', 'mouseenter'].forEach(eventName => {
      this.renderer.listen(
        this.elementRef.nativeElement,
        eventName,
        event => (this.customTheme.customTheme = event.target.value)
      );
    });
  }

  ngOnInit() {
    this.customTheme.customTheme = this.elementRef.nativeElement.value;
  }
}
