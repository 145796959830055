import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(duration: number): string {
    if (!duration) return '0 Days';

    const years = Math.floor(duration / 365);
    const months = Math.floor((duration - 365 * years) / 30);
    const days = Math.floor(duration - 365 * years - 30 * months);

    return (
      (years > 0 ? years + ' Year' + (years > 1 ? 's ' : ' ') : '') +
      (months > 0 ? months + ' Month' + (months > 1 ? 's ' : ' ') : '') +
      (days > 0 ? days + ' Day' + (days > 1 ? 's' : '') : '')
    );
  }
}
