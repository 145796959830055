import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { PropertiesWebModule } from '@vsolv/packages/properties/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import {
  AssetDetailsCardComponent,
  AssetDetailsInputComponent,
  AssetPickerComponent,
  AssetTypePickerComponent,
  ContractAssetAddressComponent,
  SquareFootageInputComponent,
} from './components';
import { CreateAssetDialog, EditAssetDialog } from './dialogs';
import { AssetService } from './services';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InputModule,
    ThemingModule,
    ReactiveFormsModule,
    CardModule,
    IconsModule,
    SelectModule,
    UtilsModule,
    BadgeModule,
    TooltipModule,
    PropertiesWebModule,
    DialogModule,
    DividerModule,
    ButtonModule,
    AlertModule,
  ],
  declarations: [
    ContractAssetAddressComponent,
    AssetDetailsInputComponent,
    AssetPickerComponent,
    AssetTypePickerComponent,
    SquareFootageInputComponent,
    AssetDetailsCardComponent,

    //Dialogs
    CreateAssetDialog,
    EditAssetDialog,
  ],
  exports: [
    ContractAssetAddressComponent,
    AssetDetailsInputComponent,
    AssetPickerComponent,
    AssetTypePickerComponent,
    SquareFootageInputComponent,
    AssetDetailsCardComponent,
    CreateAssetDialog,
    EditAssetDialog,
  ],
  providers: [AssetService],
})
export class AssetsWebModule {}
