<vs-dialog [config]="dialogConfig ? dialogConfig :{ dialogContainerClass: 'min-w-[75vw]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'download-01',
      iconTheme: 'primary',

      title: 'Download Document',
      subtitle: 'Click the button below to begin your download'
    }"
  ></vs-dialog-header>

  <object
    *ngIf="document?.downloadUrl"
    class="w-full h-[75vh] rounded-lg"
    frameBorder="0"
    scrolling="auto"
    [data]="(document && document.downloadUrl ? document.downloadUrl : '') | safe : 'resourceUrl'"
  ></object>

  <div class="flex justify-end mt-4">
    <button vs-button theme="primary" (click)="downloadFile()">Download</button>
  </div>
</vs-dialog>
