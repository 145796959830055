<a
  #link="routerLinkActive"
  routerLinkActive="text-themed"
  class="cursor-pointer focus:outline-none flex gap-1 items-center px-2 py-1"
  [routerLink]="config.routerLink"
  [class.pointer-events-none]="link.isActive"
  [routerLinkActiveOptions]="{ exact: config.exact ?? true }"
>
  <vs-icon *ngIf="config.icon" [name]="config.icon" class="align-text-bottom"></vs-icon>

  <p class="text-sm font-semibold select-none whitespace-nowrap">{{ config.label }}</p>

  <vs-badge
    *ngIf="config.badge"
    [config]="config.badge"
    [theme]="link.isActive ? config.badge.activeTheme ?? config.badge.theme : config.badge.theme"
  >
    {{ config.badge.text }}
  </vs-badge>

  <div
    class="absolute -bottom-1 left-0 h-2 -mb-[1px] w-full group-hover:border-b-2 group-hover:border-gray-300"
    [class.border-b-2]="link.isActive"
    [class.!border-themed]="link.isActive"
  ></div>
</a>
