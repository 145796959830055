<div *vsLet="routeChange$ | async" class="flex flex-col gap-2 pt-8 px-8 -mb-4">
  <vs-breadcrumbs class="mb-4" [config]="{ items: [{ label: 'Distributor Settings' }] }"> </vs-breadcrumbs>

  <vs-tabs *vsLet="distributors$ | async" theme="primary">
    <vs-tab
      *ngFor="let distributor of selectedDistributors"
      [config]="{ label: distributor.name, routerLink: distributor.id }"
    >
    </vs-tab>
  </vs-tabs>
</div>

<router-outlet></router-outlet>
