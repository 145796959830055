<ng-container *vsLet="canCreate$ | async as canCreate">
  <ng-container *ngIf="pageData$ | async as pageData">
    <ng-container *ngIf="pageData.dashboard; else empty">
      <vs-app-split-page
        [config]="{
        appPageClass: 'flex flex-col',
        icon: 'bar-chart-square-01',
        title: pageData.dashboard.title,
        description: pageData.dashboard.description,
        left: pageData.isMobile ? undefined : { width: 260, maxWidth: 320, collapseThreshold: 160 }
      }"
      >
        <vs-divider slot="sticky" class="hidden sm:block"></vs-divider>

        <button
          *ngIf="(canEdit$ | async) && pageData.dashboard.embedUrl"
          vs-button
          theme="primary"
          slot="header-end"
          (click)="update_dialog.open(pageData.dashboard, !pageData.dashboards.length || pageData.dashboard.isDefault)"
        >
          <vs-icon name="edit-01"></vs-icon>
          <span>Edit</span>
        </button>

        <div slot="left" class="flex flex-col gap-2 px-4 py-8">
          <vs-sidebar-nav-item [config]="{ icon: 'home-line', label: 'Home', routerLink: '/' }"></vs-sidebar-nav-item>

          <vs-divider></vs-divider>
          <p class="text-gray-400 text-sm my-4">Common dashboards</p>

          <ng-container *vsLet="canSetFavourite$ | async as canSetFavourite">
            <vs-sidebar-nav-item
              *ngFor="let dashboard of pageData.dashboards"
              class="flex"
              [config]="{
                hideDetailArrow: true,
                label: dashboard.title,
                icon: $any(dashboard.icon),
                routerLink: '/' + dashboard.id,
                showDefaultIcon: dashboard.isDefault
              }"
            >
              <!-- Favouriting a dashboard has been removed for now, it might be brought back later -->
              <!-- <vs-icon
                type="solid"
                name="star-01"
                class="self-center text-gray-300 hover:text-gray-400"
                [class.!text-gray-400]="dashboard.isDefault"
                (click)="changeIconSelection(dashboard, canSetFavourite)"
              ></vs-icon> -->
            </vs-sidebar-nav-item>
          </ng-container>

          <ng-container *ngIf="canCreate$ | async">
            <vs-divider class="my-4"></vs-divider>

            <button vs-button appearance="clear" class="w-full" (click)="create_dialog.open(false)">
              <vs-icon name="plus"></vs-icon>
              <span>New Dashboard</span>
            </button>
          </ng-container>
        </div>

        <div class="mt-2 sm:mt-8 flex-1 flex flex-col">
          <router-outlet></router-outlet>
          <ng-container *ngIf="pageData.dashboard">
            <iframe
              *ngIf="pageData.dashboard.embedUrl"
              [src]="pageData.dashboard.embedUrl | safe: 'resourceUrl'"
              class="border-0 flex-1"
            ></iframe>

            <ng-container *ngIf="$any(pageData.dashboard).component">
              <ng-container *ngIf="$any(pageData.dashboard).component | callWithInjector | async as component">
                <ng-container
                  *ngComponentOutlet="$any(component).type; injector: ($any(component).inputs | injectorWithInputs)"
                ></ng-container>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </vs-app-split-page>
    </ng-container>

    <ng-template #empty>
      <vs-app-page
        class="flex flex-col"
        [config]="{
        title: 'Home',
        icon: 'bar-chart-square-01',
        description: 'Custom dashboards help personalize your WarrantySphere experience'
      }"
      >
        <vs-divider slot="sticky" class="hidden sm:block"></vs-divider>

        <div class="flex-1 flex flex-col items-center justify-center gap-6">
          <img src="assets/dashboarding/empty.svg" class="w-[50vw] max-w-[250px]" />

          <div class="text-center text-gray">
            <p class="text-2xl">Nothing here yet!</p>
            <p *ngIf="canCreate$ | async">Create a dashboard to get started.</p>
          </div>

          <button *ngIf="canCreate" vs-button theme="primary" (click)="create_dialog.open(!pageData.dashboard)">
            <vs-icon name="plus"></vs-icon>
            <span>New Dashboard</span>
          </button>
        </div>
      </vs-app-page>
    </ng-template>
  </ng-container>
</ng-container>

<vs-create-dashboard-dialog #create_dialog (created)="navigateTo($event.id)"></vs-create-dashboard-dialog>
<vs-update-dashboard-dialog #update_dialog (updated)="refresh()"></vs-update-dashboard-dialog>
