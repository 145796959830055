import { Staff } from '../lib/staff';

export type DocumentPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'doc_ViewDocuments',
    display: {
      title: 'View Platform Documents',
      description: 'View documents across the platform',
    },
  },
  {
    id: 'doc_UpdateDocuments',
    display: {
      title: 'Edit Platform Documents',
      description: 'Edit documents from the platform',
    },
  },
  {
    id: 'doc_RemoveDocuments',
    display: {
      title: 'Delete Platform Documents',
      description: 'Delete documents from the platform',
    },
  },
  {
    id: 'doc_UploadDocuments',
    display: {
      title: 'Upload Platform Documents',
      description: 'Upload documents to the platform',
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
