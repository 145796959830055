<ng-container *ngIf="address">
  <ng-container *vsLet="address | address as location">
    <object
      class="map w-full rounded-xl"
      type="text/html"
      *ngIf="googleMapsKey && location"
      [data]="
        'https://www.google.com/maps/embed/v1/place?q=' + location.split(' ').join('+') + '&key=' + googleMapsKey
          | safe : 'resourceUrl'
      "
    ></object>
  </ng-container>
</ng-container>
