import { ChangeDetectionStrategy, Component, HostBinding, Inject, Input, Optional } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';
import { BREADCRUMBS_INJECTOR, BreadcrumbsComponent } from '../breadcrumbs/breadcrumbs.component';

export interface BreadcrumbsItemConfig {
  label?: string;
  routerLink?: string | string[];

  iconEnd?: IconName;
  iconStart?: IconName;

  class?: string;
}

@Component({
  selector: 'vs-breadcrumbs-item',
  templateUrl: './breadcrumbs-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsItemComponent {
  @HostBinding('class') private _class = 'group flex items-center gap-3 cursor-default ';

  @Input() config?: BreadcrumbsItemConfig;

  get _separatorIcon(): IconName {
    return this.container?.config?.separatorIcon || 'chevron-right';
  }

  readonly _anchorBaseClasses = 'hover:text-gray-900 active:text-gray-500 focus:text-gray-900';
  readonly _anchorPrimaryClasses =
    'group-last:hover:text-primary-600 group-last:active:text-primary-400 group-last:focus:text-primary-600';

  constructor(@Optional() @Inject(BREADCRUMBS_INJECTOR) private container?: BreadcrumbsComponent) {}
}
