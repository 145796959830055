/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ApiMode } from '@vsolv/core/multi-tenant/domain';
import { isVectorSolvRequest } from '@vsolv/dev-kit/ngx';
import { Observable, switchMap } from 'rxjs';

export const API_MODE = new InjectionToken<ApiMode>('API_MODE');

/** This interceptor attempts to add the bearer token to request headers*/
@Injectable()
export class FirebaseInterceptor implements HttpInterceptor {
  constructor(private ngFireAuth: AngularFireAuth) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!isVectorSolvRequest(req)) {
      return next.handle(req);
    }
    return this.ngFireAuth.idToken.pipe(switchMap(idToken => next.handle(this.handleRequest(req, idToken))));
  }

  /** Interceptor attempts to retrieve the tenant id from local storage and add it to the outgoing vectorsolv api request's headers  */
  private handleRequest(request: HttpRequest<any>, idToken: string | null) {
    const outgoing = request.clone({ setHeaders: { Authorization: `Bearer ${idToken}` } });
    return outgoing;
  }
}
