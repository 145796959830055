<div id="teamMemberTable" class="flex flex-col" *vsLet="canManageAPIKeys$ | async">
  <div *ngIf="apiKeys$ | async as apiKeys">
    <div class="rounded-xl overflow-hidden border border-gray-200 shadow-sm">
      <vs-table [columns]="(columns$ | async) ?? []" [data]="apiKeys.items"></vs-table>
      <vs-pagination
        [config]="{
          totalItems: apiKeys.meta.totalItems,
          itemsPerPage: apiKeys.meta.itemsPerPage,
          currentPage: apiKeys.meta.currentPage
        }"
        (pagination)="paginationQueryRequest$.next({ page: $event.currentPage, limit: $event.itemsPerPage })"
      ></vs-pagination>
    </div>
  </div>
</div>

<ws-roll-api-key-dialog
  [apiKeyItem]="currentApiKeyItem"
  #rollKeyDialog
  (refresh)="refreshTable()"
></ws-roll-api-key-dialog>

<ws-delete-api-key-dialog
  [apiKeyItem]="currentApiKeyItem"
  #deleteKeyDialog
  (refresh)="refreshTable()"
></ws-delete-api-key-dialog>

<ws-edit-api-key-dialog
  [apiKeyItem]="currentApiKeyItem"
  #editKeyDialog
  (keyUpdated)="refreshTable()"
></ws-edit-api-key-dialog>

<!-- class="shadow-md hover:shadow-lg box-border border-[1px] border-gray-200/50 rounded-lg" -->
