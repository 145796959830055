import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InfoCardModule } from '@vsolv/vectors-ui/info-card';
import { InputModule } from '@vsolv/vectors-ui/input';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { LinksWebService } from './services';

@NgModule({
  imports: [
    CommonModule,
    InputModule,
    CardModule,
    OverlayModule,
    BadgeModule,
    ThemingModule,
    IconsModule,
    ButtonModule,
    UtilsModule,
    InfoCardModule,
  ],
  providers: [LinksWebService],
  declarations: [],
  exports: [],
})
export class LinksWebModule {}
