import { Component } from '@angular/core';
import { UserService } from '@vsolv/core/users/web';
import { from } from 'rxjs';
@Component({
  templateUrl: './security.page.html',
})
export class SecurityPage {
  user$ = from(this.userSvc.getSelf());

  constructor(private userSvc: UserService) {}
}
