/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Documents } from '@vsolv/packages/documents/domain';
import { Note } from '@vsolv/packages/notes/domain';
import { Payment } from '@vsolv/packages/payments/domain';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { Claim } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, Observable, ReplaySubject, switchMap, tap } from 'rxjs';

@Component({
  selector: 'ws-claim-link-picker',
  templateUrl: './link-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ClaimLinkPickerComponent,
    },
  ],
})
export class ClaimLinkPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(private linkSvc: LinksWebService) {}

  @PropertyListener('value') value$ = new ReplaySubject<Link.FormLink[] | null>();
  @Input() value: Link.FormLink[] | null = null;

  @PropertyListener('claim') claim$ = new ReplaySubject<Claim.Model>();
  @Input() claim: Claim.Model | null = null;

  @PropertyListener('id') id$ = new ReplaySubject<string>();
  @Input() id: string | null = null;

  @Input() asButton = false;
  @Input() disabled = false;
  @Input() placeholder = 'Add Link';

  @Output() valueChanges = new EventEmitter<Link.FormLink[] | null>();
  @Output() linksAndConfigs = new EventEmitter<{ link: Link.FormLink; config: InfoCardConfig }[] | undefined>();

  touched = false;
  overlayOpen = false;
  selectedGroup: { type: Link.ObjectType; objects: unknown[] } | null = null;

  search$ = new BehaviorSubject<string>('');

  refresh$ = new BehaviorSubject(null);

  typesAndObjects$: Observable<{ type: Link.ObjectType; objects: unknown[] }[]> = combineLatest([
    this.claim$,
    this.search$,
    this.id$,
  ]).pipe(
    switchMap(async ([claim, search]) => {
      const typesAndObjects: { type: Link.ObjectType; objects: unknown[] }[] = [];

      if (claim) {
        // Claim Items
        const filteredItems = await this.getItems(claim, search);
        if (filteredItems?.length) typesAndObjects.push({ type: Link.ObjectType.CLAIM_ITEM, objects: filteredItems });

        // Notes
        const filteredNotes = await this.getNotes(claim, search);
        if (filteredNotes?.length) typesAndObjects.push({ type: Link.ObjectType.NOTE, objects: filteredNotes });

        // Documents
        const filteredDocuments = await this.getDocuments(claim, search);
        if (filteredDocuments?.length) {
          typesAndObjects.push({ type: Link.ObjectType.DOCUMENT, objects: filteredDocuments });
        }

        // Payments
        const filteredPayments = await this.getPayments(claim, search);
        if (filteredPayments?.length) {
          typesAndObjects.push({ type: Link.ObjectType.PAYMENT, objects: filteredPayments });
        }

        return typesAndObjects;
      } else {
        return [];
      }
    })
  );

  linksAndConfigs$: Observable<{ link: Link.FormLink; config: InfoCardConfig }[] | undefined> = combineLatest([
    this.value$,
    this.refresh$,
  ]).pipe(
    map(([value]) =>
      value?.filter(link => link.type !== Link.ObjectType.ACTIVITY && (link.object as any)?.id !== this.claim?.id)
    ),
    map(value =>
      value?.map(link => ({
        link: link,
        config: {
          icon: Link.getLinkIcon(link.type),
          title: Link.getObjectTitle(link.object, link.type),
          subtitle: Link.getObjectSubtitle(link.object, link.type),
        },
      }))
    ),
    tap(linksAndConfigs => this.linksAndConfigs.emit(linksAndConfigs))
  );

  onChange = (_value: Link.FormLink[] | null) => {};
  onTouched = () => {};

  selectValueChanged(object: unknown, type: Link.ObjectType) {
    this.markAsTouched();

    if (this.value?.map(item => (item.object as any)?.id).includes((object as any)?.id)) {
      this.value = this.value.filter(item => (item.object as any)?.id !== (object as any)?.id);
    } else {
      if (!this.value?.length) this.value = [];
      this.value?.push({ object, type });
    }

    this.onChange(this.value);
    this.valueChanges.next(this.value);

    this.refresh$.next(null);
  }

  writeValue(value: Link.FormLink[] | null): void {
    if (value?.length) {
      this.value = value;
    } else this.value = null;
  }

  registerOnChange(onChange: (_value: Link.FormLink[] | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
    this.selectedGroup = null;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  selectedCheck(object: unknown) {
    return this.value?.map(item => (item.object as any)?.id).includes((object as any)?.id);
  }

  getObjectTitle(object: unknown, type: Link.ObjectType) {
    switch (type) {
      case Link.ObjectType.CLAIM_ITEM:
        return (object as Claim.ClaimItem.Model).coverage?.title || 'Other';
      case Link.ObjectType.NOTE:
        return (
          (object as Note.Model)?.title ||
          (object as Note.Model)?.content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '') ||
          'Note'
        );
      case Link.ObjectType.DOCUMENT:
        return (object as Documents.Model)?.title || (object as Documents.Model)?.description || 'Document';
      case Link.ObjectType.PAYMENT:
        return (object as Payment.Model)?.title || (object as Payment.Model)?.description || 'Payment';
      default:
        return type;
    }
  }

  getItemTheme(item?: Claim.ClaimItem.Model) {
    return item ? Claim.ClaimItem.getTheme(item.status) : 'default';
  }

  async getItems(claim: Claim.Model, search: string) {
    const filteredItems = claim.items?.filter(
      item =>
        !item.deleted &&
        item.id !== this.id &&
        (item.coverage?.title.toLowerCase().includes(search.toLowerCase()) ||
          (!item.coverage?.title && 'other'.includes(search.toLowerCase())))
    );

    return filteredItems;
  }

  async getNotes(claim: Claim.Model, search: string) {
    const notes = await this.linkSvc.list(
      { id: claim.id, objectType: Link.ObjectType.CLAIM },
      {
        owner: { id: claim.id, objectType: Link.ObjectType.CLAIM },
        linkType: Link.ObjectType.NOTE,
        limit: 100,
      }
    );

    return notes.items?.reduce((acc, link) => {
      if (
        link.note_1 &&
        !link.note_1.deleted &&
        link.noteId_1 !== this.id &&
        (link?.note_1?.title || '')?.toLowerCase()?.includes(search.toLowerCase())
      ) {
        acc.push(link.note_1);
      }

      return acc;
    }, [] as Note.Model[]);
  }

  async getDocuments(claim: Claim.Model, search: string) {
    const claimDocuments = await this.linkSvc.list(
      { id: claim.id, objectType: Link.ObjectType.CLAIM },
      {
        owner: { id: claim.id, objectType: Link.ObjectType.CLAIM },
        linkType: Link.ObjectType.DOCUMENT,
        limit: 100,
      }
    );

    return claimDocuments.items?.reduce((acc, link) => {
      if (
        link.document_1 &&
        !link.document_1.deleted &&
        link.documentId_1 !== this.id &&
        link.document_1.title?.toLowerCase()?.includes(search.toLowerCase())
      ) {
        acc.push(link.document_1);
      }

      return acc;
    }, [] as Documents.Model[]);
  }

  async getPayments(claim: Claim.Model, search: string) {
    const claimPayments = await this.linkSvc.list(
      { id: claim.id, objectType: Link.ObjectType.CLAIM },
      {
        owner: { id: claim.id, objectType: Link.ObjectType.CLAIM },
        linkType: Link.ObjectType.PAYMENT,
        limit: 100,
      }
    );
    return claimPayments.items?.reduce((acc, link) => {
      if (
        link.payment_1 &&
        !link.payment_1.deleted &&
        link.paymentId_1 !== this.id &&
        link.payment_1.title?.toLowerCase()?.includes(search.toLowerCase())
      ) {
        acc.push(link.payment_1);
      }

      return acc;
    }, [] as Claim.ClaimPayment.Model[]);
  }

  ngOnDestroy(): void {
    this.value$.complete();
  }
}
