import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class PropertyService {
  constructor(private http: HttpClient) {}

  // Properties
  async retrieveProperty(propertyId: string) {
    return await firstValueFrom(this.http.get<Property.Model<Property.PropertyType>>(`/api/properties/${propertyId}`));
  }

  async listProperties(query: Property.ListPropertiesQueryRequest) {
    return await firstValueFrom(
      this.http.get<Property.ListPropertiesQueryResponse>(`/api/properties`, {
        params: {
          page: query.page || 1,
          limit: query.limit || 10,
          ...(query.type && { type: query.type }),
          ...(query.search && { search: query.search }),
        },
      })
    );
  }

  async createBoolean(dto: Property.CreateBooleanPropertyDto) {
    return await firstValueFrom(this.http.post<Property.CreatePropertyResponse>(`/api/properties/boolean`, dto));
  }

  async createDate(dto: Property.CreateDatePropertyDto) {
    return await firstValueFrom(this.http.post<Property.CreatePropertyResponse>(`/api/properties/date`, dto));
  }

  async createNumber(dto: Property.CreateNumberPropertyDto) {
    return await firstValueFrom(this.http.post<Property.CreatePropertyResponse>(`/api/properties/number`, dto));
  }

  async createObject(dto: Property.CreateObjectPropertyDto) {
    return await firstValueFrom(this.http.post<Property.CreatePropertyResponse>(`/api/properties/object`, dto));
  }

  async createText(dto: Property.CreateTextPropertyDto) {
    return await firstValueFrom(this.http.post<Property.CreatePropertyResponse>(`/api/properties/text`, dto));
  }

  //Property Sets
  async retrievePropertySet(setId: string) {
    return await firstValueFrom(this.http.get<PropertySet.Model>(`/api/property-sets/${setId}`));
  }

  async listPropertySets(query: PropertySet.ListPropertySetsQueryRequest) {
    return await firstValueFrom(
      this.http.get<PropertySet.ListPropertySetsQueryResponse>(`/api/property-sets`, {
        params: {
          page: query.page || 1,
          limit: query.limit || 10,
          ...(query.search && { search: query.search }),
        },
      })
    );
  }

  async createPropertySet(dto: PropertySet.CreatePropertySetRequest) {
    return await firstValueFrom(this.http.post<PropertySet.CreatePropertySetResponse>(`/api/property-sets`, dto));
  }
}
