<vs-form-field [config]="{ label: property.name + (required ? '' : ' (optional)') + ':' }" appearance="vertical">
  <vs-input-container>
    <vs-date-property-input
      class="w-full"
      [property]="property"
      [formControl]="formControl"
      [disabled]="disabled"
      [required]="required"
    ></vs-date-property-input>
  </vs-input-container>
  <span class="text-danger" slot="hint" *ngIf="formControl.touched && formControl.hasError('required')">
    {{ property.name }} is required
  </span>
  <span class="text-danger" slot="hint" *ngIf="formControl.touched && formControl.hasError('minDate')">
    {{ property.name }} must be {{ property.config.min | date }} or after
  </span>
  <span class="text-danger" slot="hint" *ngIf="formControl.touched && formControl.hasError('maxDate')">
    {{ property.name }} must be than {{ property.config.max | date }} or before
  </span>
</vs-form-field>
