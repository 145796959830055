/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, tap } from 'rxjs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-copy-term-config-dialog',
  templateUrl: './copy-term-config.dialog.html',
})
export class CopyTermConfigDialog {
  constructor(private formBuilder: FormBuilder, private policySvc: PolicyService) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('selectedTermId') selectedTermId$ = new BehaviorSubject<string | null>(null);
  @Input() selectedTermId: string | null = null;

  @PropertyListener('coverageConfigs') coverageConfigs$ = new BehaviorSubject<Policy.Plan.PlanCoverageConfigs | null>(
    null
  );
  @Input() coverageConfigs: Policy.Plan.PlanCoverageConfigs | null = null;

  @Output() closed = new EventEmitter();

  @Output() configure = new EventEmitter<{
    configs?: Policy.Plan.CoverageConfig[];
    fees?: Policy.PlanCoverageFee.Model[];
  }>();

  plan: Policy.Plan.Model | null = null;

  completedCount = 0;

  form = this.formBuilder.group({
    termId: [null as string | null, Validators.required],
  });

  completedTerms$ = combineLatest([this.policy$, this.coverageConfigs$, this.selectedTermId$]).pipe(
    map(([policy, coverageConfigs, selectedTermId]) => {
      if (!policy || !coverageConfigs) return [];

      const completedTerms: Policy.PolicyTerm[] = [];

      for (const term of policy.terms) {
        const configs = coverageConfigs[term.id];
        if (!configs || term.id === selectedTermId) continue;
        let completed = true;

        if (configs.length) {
          for (const config of configs) {
            if (
              !config.deductible ||
              ((config.deductible.defaultValue === null || config.deductible.defaultValue === undefined) &&
                !config.deductible.valuePropertyPath)
            ) {
              completed = false;
              break;
            }

            if (
              !config.liabilityLimit ||
              ((config.liabilityLimit.defaultValue === null || config.liabilityLimit.defaultValue === undefined) &&
                !config.liabilityLimit.valuePropertyPath)
            ) {
              completed = false;
              break;
            }

            if (
              !config.liabilityGroups ||
              ((config.liabilityGroups.defaultValue === null || config.liabilityGroups.defaultValue === undefined) &&
                !config.liabilityGroups.valuePropertyPath)
            ) {
              completed = false;
              break;
            }

            if (
              !config.requirement ||
              ((config.requirement.defaultValue === null || config.requirement.defaultValue === undefined) &&
                !config.requirement.valuePropertyPath)
            ) {
              completed = false;
              break;
            }

            if (
              term.paymentSchedules.length &&
              (!config.price ||
                ((config.price.defaultValue === null || config.price.defaultValue === undefined) &&
                  !config.price.valuePropertyPath))
            ) {
              completed = false;
              break;
            }
          }
        } else {
          completed = false;
        }

        if (completed) completedTerms.push(term);
      }

      return completedTerms;
    }),
    tap(completed => (this.completedCount = completed.length))
  );

  open(plan: Policy.Plan.Model) {
    this.plan = plan;

    this.form.reset({ termId: null });

    if (this.completedCount) {
      this.dialog.open();
    } else {
      //start from scratch
      this.submit();
    }
  }

  close() {
    this.form.reset({ termId: null });
    this.closed.emit();
    this.dialog.close();
  }

  submit(configs?: Policy.Plan.CoverageConfig[], fees?: Policy.PlanCoverageFee.Model[]) {
    this.configure.emit({
      configs: configs?.length ? [...configs] : undefined,
      fees: fees?.length ? [...fees] : undefined,
    });

    this.close();
  }

  async submitWithTermConfig() {
    if (!this.selectedTermId) return;

    const termId = this.form.value.termId;

    if (!termId || !this.coverageConfigs) return;

    const coverageConfigs = this.coverageConfigs[termId];

    const feesToSelect = [];

    if (this.plan) {
      let fees = await this.policySvc.getPlanFees(this.plan.policyId, this.plan.id, termId);

      if (fees?.meta.currentPage !== fees?.meta.totalPages && (fees?.meta.totalPages || 0) > 0) {
        if (fees) feesToSelect.push(...fees.items);

        while (fees?.meta.currentPage !== fees?.meta.totalPages) {
          fees = await this.policySvc.getPlanFees(this.plan.policyId, this.plan.id, termId, {
            page: (fees?.meta.currentPage || 1) + 1,
            limit: 100,
          });

          if (fees && fees.items) feesToSelect.push(...fees.items);
        }
      } else if (fees) feesToSelect.push(...fees.items);
    }

    this.submit(coverageConfigs ?? undefined, feesToSelect);
  }
}
