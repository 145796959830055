/* eslint-disable @typescript-eslint/no-unused-vars */
import { Conditions } from '..';
import {
  Block,
  Comparison,
  Condition,
  ConditionGroup,
  getPropertyOperatorsForType,
  Operator,
  PropertyType,
  PropertyValueType,
  Rule,
} from './models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getPropertyValue(propertyPath: string, propertyValues: Record<string, any>): PropertyValueType | null {
  if (!propertyValues) return null;

  const valueKeys = Object.keys(propertyValues);

  for (const key of valueKeys) {
    if (key === propertyPath) {
      return propertyValues[propertyPath];
    } else if (propertyPath.startsWith(key)) {
      return getPropertyValue(propertyPath.replace(key + '.', ''), propertyValues[key]);
    }
  }

  return null;
}

function evaluateNumberCondition(
  condition: Condition<number>,
  propertyValues: Record<string, Record<string, PropertyValueType>>
): boolean {
  if (!getPropertyOperatorsForType(PropertyType.NUMBER).includes(condition.operator)) return false; //cannot evaluate

  const propertyValue = getPropertyValue(condition.propertyId, propertyValues);

  if (condition.operator === Operator.IS_NULL) {
    if (propertyValue === null || propertyValue === undefined || propertyValue === '') {
      return true;
    } else {
      return false;
    }
  }

  if (condition.operator === Operator.IS_NOT_NULL) {
    if (propertyValue !== null && propertyValue !== undefined && propertyValue !== '') {
      return true;
    } else {
      return false;
    }
  }

  if (propertyValue === null || propertyValue === undefined || typeof propertyValue !== 'number') return false;

  let value = condition.value;

  if (condition.valuePropertyId) {
    const conditionValue = getPropertyValue(condition.valuePropertyId, propertyValues);
    if (conditionValue === null || conditionValue === undefined || typeof conditionValue !== 'number') return false;
    value = conditionValue;
  }

  if (value === null || value === undefined) {
    return false;
  }

  switch (condition.operator) {
    case Operator.EQUALS:
      return propertyValue === value;
    case Operator.NOT_EQUALS:
      return propertyValue !== value;
    default:
      return eval(`${propertyValue} ${condition.operator} ${value}`);
  }
}

function evaluateTextCondition(
  condition: Condition<string>,
  propertyValues: Record<string, Record<string, PropertyValueType>>
): boolean {
  if (!getPropertyOperatorsForType(PropertyType.TEXT).includes(condition.operator)) return false; //cannot evaluate

  const propertyValue = getPropertyValue(condition.propertyId, propertyValues);

  if (condition.operator === Operator.IS_NULL) {
    if (propertyValue === null || propertyValue === undefined || propertyValue === '') {
      return true;
    } else {
      return false;
    }
  }

  if (condition.operator === Operator.IS_NOT_NULL) {
    if (propertyValue !== null && propertyValue !== undefined && propertyValue !== '') {
      return true;
    } else {
      return false;
    }
  }

  if (propertyValue === null || propertyValue === undefined || typeof propertyValue !== 'string') return false;

  let value = condition.value;

  if (condition.valuePropertyId) {
    const conditionValue = getPropertyValue(condition.valuePropertyId, propertyValues);
    if (conditionValue === null || conditionValue === undefined || typeof conditionValue !== 'string') return false;
    value = conditionValue;
  }

  if (value === null || value === undefined) {
    return false;
  }

  switch (condition.operator) {
    case Operator.CONTAINS:
      return propertyValue.toLowerCase().includes(value.toLowerCase());
    case Operator.EQUALS:
      return propertyValue.toLowerCase() === value.toLowerCase();
    case Operator.NOT_EQUALS:
      return propertyValue.toLowerCase() !== value.toLowerCase();
    case Operator.STARTS_WITH:
      return propertyValue.toLowerCase().startsWith(value.toLowerCase());
    case Operator.NOT_STARTS_WITH:
      return !propertyValue.toLowerCase().startsWith(value.toLowerCase());
    default:
      return eval(`${propertyValue.toLowerCase()} ${condition.operator} ${value.toLowerCase()}`);
  }
}

function evaluateBooleanCondition(
  condition: Condition<boolean>,
  propertyValues: Record<string, Record<string, PropertyValueType>>
): boolean {
  if (!getPropertyOperatorsForType(PropertyType.BOOLEAN).includes(condition.operator)) return false; //cannot evaluate
  const propertyValue = getPropertyValue(condition.propertyId, propertyValues);

  if (condition.operator === Operator.IS_NULL) {
    if (propertyValue === null || propertyValue === undefined || propertyValue === '') {
      return true;
    } else {
      return false;
    }
  }

  if (condition.operator === Operator.IS_NOT_NULL) {
    if (propertyValue !== null && propertyValue !== undefined && propertyValue !== '') {
      return true;
    } else {
      return false;
    }
  }

  if (propertyValue === null || propertyValue === undefined || typeof propertyValue !== 'boolean') return false;

  let value = condition.value;

  if (condition.valuePropertyId) {
    const conditionValue = getPropertyValue(condition.valuePropertyId, propertyValues);
    if (conditionValue === null || conditionValue === undefined || typeof conditionValue !== 'boolean') return false;
    value = conditionValue as boolean;
  }

  if (value === null || value === undefined) {
    return false;
  }

  if (condition.operator === Operator.EQUALS) return propertyValue === value;
  else return propertyValue !== value;
}

function evaluateDateCondition(
  condition: Condition<Date>,
  propertyValues: Record<string, Record<string, PropertyValueType>>
): boolean {
  if (!getPropertyOperatorsForType(PropertyType.DATE).includes(condition.operator)) return false; //cannot evaluate
  try {
    let propertyValue = getPropertyValue(condition.propertyId, propertyValues);

    if (condition.operator === Operator.IS_NULL) {
      if (propertyValue === null || propertyValue === undefined || propertyValue === '') {
        return true;
      } else {
        return false;
      }
    }

    if (condition.operator === Operator.IS_NOT_NULL) {
      if (propertyValue !== null && propertyValue !== undefined && propertyValue !== '') {
        return true;
      } else {
        return false;
      }
    }

    const type = typeof propertyValue;
    if (
      propertyValue === null ||
      propertyValue === undefined ||
      (type !== 'string' && type !== 'number' && type !== 'object')
    )
      return false;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    propertyValue = new Date(propertyValue as any);

    let value = condition.value;

    if (condition.valuePropertyId) {
      const conditionValue = getPropertyValue(condition.valuePropertyId, propertyValues);
      const conditionType = typeof conditionValue;
      if (
        conditionValue === null ||
        conditionValue === undefined ||
        (conditionType !== 'string' && conditionType !== 'number' && conditionType !== 'object')
      )
        return false;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value = new Date(conditionValue as any);
    }

    if (value === null || value === undefined) {
      return false;
    }

    const propertyValueTime = (propertyValue as Date).getTime();
    const conditionValueTime = value.getTime();

    switch (condition.operator) {
      case Operator.EQUALS:
        return propertyValueTime === conditionValueTime;
      case Operator.NOT_EQUALS:
        return propertyValueTime !== conditionValueTime;
      default:
        return eval(`${propertyValueTime} ${condition.operator} ${conditionValueTime}`);
    }
  } catch (err) {
    console.error(err);
    return false;
  }
}

export function evaluateCondition<T extends PropertyValueType>(
  condition: Condition<T>,
  propertyValues: Record<string, Record<string, PropertyValueType>>
): boolean {
  switch (condition.type) {
    case Conditions.PropertyType.TEXT:
      return evaluateTextCondition(condition as Condition<string>, propertyValues);
    case Conditions.PropertyType.NUMBER:
      return evaluateNumberCondition(condition as Condition<number>, propertyValues);
    case Conditions.PropertyType.BOOLEAN:
      return evaluateBooleanCondition(condition as Condition<boolean>, propertyValues);
    default:
      return evaluateDateCondition(condition as Condition<Date>, propertyValues);
  }
}

export function evaluateConditionGroup(
  group: ConditionGroup,
  propertyValues: Record<string, Record<string, PropertyValueType>>
): boolean {
  if (group.comparison === Comparison.AND) {
    //if any result is false, fail.
    for (const conditionOrGroup of group.conditions) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((conditionOrGroup as any).propertyId) {
        const condition = conditionOrGroup as Condition<PropertyValueType>;
        const result = evaluateCondition(condition, propertyValues);
        if (result === false) {
          return false;
        } else continue;
      } else {
        const result = evaluateConditionGroup(conditionOrGroup as ConditionGroup, propertyValues);
        if (result === false) {
          return false;
        } else continue;
      }
    }
    return true;
  } else {
    //if any result is true, succeed.
    for (const conditionOrGroup of group.conditions) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((conditionOrGroup as any).propertyId) {
        const condition = conditionOrGroup as Condition<PropertyValueType>;
        const result = evaluateCondition(condition, propertyValues);
        if (result === true) {
          return true;
        } else continue;
      } else {
        const result = evaluateConditionGroup(conditionOrGroup as ConditionGroup, propertyValues);
        if (result === true) {
          return true;
        } else continue;
      }
    }
    return false;
  }
}

export function evaluateBlock<T>(
  block: Block<T>,
  propertyValues: Record<string, Record<string, PropertyValueType>>
): boolean {
  return evaluateConditionGroup(block.condition, propertyValues);
}

export function evaluateRule<T>(rule: Rule<T>, propertyValues: Record<string, Record<string, PropertyValueType>>): T {
  for (const block of rule.blocks) {
    const result = evaluateBlock(block, propertyValues);
    let value = block.value;

    if (block.valuePropertyPath) {
      value = getPropertyValue(block.valuePropertyPath, propertyValues) as T;
    }
    if (result) return value;
  }
  let defaultValue = rule.defaultValue;

  if (rule.valuePropertyPath) {
    defaultValue = getPropertyValue(rule.valuePropertyPath, propertyValues) as T;
  }

  return defaultValue;
}
