/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Address } from '@vsolv/core/address/domain';
import { UserService } from '@vsolv/core/users/web';
import { PaymentMethodService } from '../../services';
import { BillingDetailsInputValue } from '../billing-details-input/billing-details-input.component';
import { CreditCardInputValue } from '../credit-card-input/credit-card-input.component';

@Component({
  selector: 'vs-payment-method-input',
  templateUrl: './payment-method-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// Create a new payment method (display billing details form and CC or bank based on "type" input)
export class PaymentMethodInputComponent implements OnDestroy, OnInit {
  constructor(
    private userSvc: UserService,
    private formBuilder: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    private paymentMethodSvc: PaymentMethodService
  ) {}

  @HostBinding('class') private _classes = 'block';

  @Output() adding = new EventEmitter<void>();
  @Output() failed = new EventEmitter<void>(true);
  @Output() added = new EventEmitter<{ id: string }>(true);
  @Output() isPaymentMethodInputPage = new EventEmitter<boolean>(false);

  @Input() userId: string | null = null;
  @Input() userDetails: Pick<NonNullable<BillingDetailsInputValue>, 'name' | 'email' | 'phone'> | null = null;

  @Input() address: Address.Model | null = null;

  private _type: 'credit-card' | 'bank' = 'credit-card';
  @Input() set type(type: 'credit-card' | 'bank') {
    this._type = type;
    this.updateFormStatus();
  }
  get type() {
    return this._type;
  }

  @Input() staffView = false;

  protected errors: string[] = [];

  protected form = this.formBuilder.group({
    billingDetails: [null as BillingDetailsInputValue, [Validators.required]],
    creditCard: [null as CreditCardInputValue, [Validators.required]],
    bankAccount: [null, [Validators.required]],
  });

  protected processing = false;

  async addCreditCard() {
    if (this.form.invalid) return;

    const { creditCard, billingDetails } = this.form.value;
    this.form.disable();

    this.adding.emit();
    this.processing = true;
    this.changeDetector.markForCheck();

    try {
      if (!creditCard || !billingDetails) throw new Error('Unexpected error');

      const { address, ...details } = billingDetails;
      const result = await this.paymentMethodSvc.addCreditCard({
        cardData: {
          month: creditCard.card.expiration.substring(0, 2),
          year: creditCard.card.expiration.substring(2),
          cardNumber: creditCard.card.number,
          cardCode: creditCard.card.cvc,
          fullName: creditCard.name,
          zip: address.zip,
        },

        billingAddress: { ...address, country: 'USA', line2: address.line2 || null },
        billingDetails: details,

        userId: this.userId ?? undefined,
      });

      this.added.emit(result);
    } catch (err) {
      console.error(err);
      this.errors = [];

      if (err && typeof err === 'object' && 'messages' in err) {
        const messages = (err as any).messages;
        if (typeof messages === 'object' && 'message' in messages) {
          const message = messages.message;
          if (Array.isArray(message)) {
            this.errors = message.map(msg => ('text' in msg ? msg.text : '')).filter(Boolean);
          }
        }
      } else if ((err as any)?.error?.message) {
        const message = (err as any).error.message;
        this.errors = [message];
      }

      this.failed.emit();
    }

    this.processing = false;
    this.changeDetector.markForCheck();

    this.form.enable();
    this.updateFormStatus();
  }

  private updateFormStatus() {
    if (this.type === 'credit-card') {
      this.form.controls.bankAccount.disable();
      this.form.controls.creditCard.enable();
    } else {
      this.form.controls.bankAccount.enable();
      this.form.controls.creditCard.disable();
    }
  }

  async fillUser() {
    if (!this.userDetails) {
      const user = await this.userSvc.getSelf();
      if (user.id === this.userId) {
        this.userDetails = { email: user.email || '', name: user.displayName || '', phone: user.phoneNumber || '' };
      }
    }

    if (this.userDetails) {
      this.form.controls.billingDetails.patchValue({
        name: this.userDetails?.name ?? '',
        email: this.userDetails?.email ?? '',
        phone: this.userDetails?.phone ?? '',
        address: this.form.value.billingDetails?.address ?? {
          line1: this.form.value.billingDetails?.address.line1 || '',
          line2: this.form.value.billingDetails?.address.line2 || '',
          city: this.form.value.billingDetails?.address.city || '',
          state: this.form.value.billingDetails?.address.state || '',
          zip: this.form.value.billingDetails?.address.zip || '',
        },
      });
    }
  }

  async removeUser() {
    this.form.controls.billingDetails.patchValue({
      name: '',
      email: '',
      phone: '',
      address: this.form.value.billingDetails?.address ?? {
        line1: this.form.value.billingDetails?.address.line1 || '',
        line2: this.form.value.billingDetails?.address.line2 || '',
        city: this.form.value.billingDetails?.address.city || '',
        state: this.form.value.billingDetails?.address.state || '',
        zip: this.form.value.billingDetails?.address.zip || '',
      },
    });
  }

  fillShipping() {
    if (this.address) {
      this.form.controls.billingDetails.patchValue({
        name: this.form.value.billingDetails?.name ?? '',
        email: this.form.value.billingDetails?.email ?? '',
        phone: this.form.value.billingDetails?.phone ?? '',
        address: { ...this.address, line2: this.address.line2 || '' },
      });
    }
  }

  removeShippingAddress() {
    this.form.controls.billingDetails.patchValue({
      name: this.form.value.billingDetails?.name ?? '',
      email: this.form.value.billingDetails?.email ?? '',
      phone: this.form.value.billingDetails?.phone ?? '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      },
    });
  }

  ngOnInit(): void {
    this.isPaymentMethodInputPage.emit(false);
    this.fillUser();
  }

  ngOnDestroy(): void {
    this.isPaymentMethodInputPage.emit(true);
  }
}
