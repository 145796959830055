import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Payment } from '@vsolv/packages/payments/domain';
import { PaymentService } from '@vsolv/packages/payments/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Claim, Warranty } from '@wsphere/warranties/domain';
import moment from 'moment';
import { CurrencyMaskConfig } from 'ngx-currency';
import { BehaviorSubject, combineLatest, map, shareReplay, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../../claim';
import { WarrantyWebService } from '../../../warranty/services';

@Component({
  selector: 'ws-cancel-warranty-dialog',
  templateUrl: './cancel-warranty.dialog.html',
})
export class CancelWarrantyDialog {
  today = new Date();

  constructor(
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private paymentSvc: PaymentService,
    private warrantySvc: WarrantyWebService
  ) {}

  protected currencyMaskConfig: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    allowZero: false,
    decimal: '.',
    nullable: true,
    precision: 2,
    prefix: '$',
    suffix: '',
    thousands: ',',
    inputMode: 1,
    min: 0,
    max: 0,
  };

  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() readonly cancelled = new EventEmitter<Warranty.CancelWarrantyResponse>();

  @Input() warranty?: Warranty.Model;
  @PropertyListener('warranty') private warranty$ = new BehaviorSubject<Warranty.Model | undefined>(undefined);

  protected refundControl = new FormControl({ value: null as number | null, disabled: false }, [Validators.min(0)]);

  protected saving = false;
  protected effectiveDate = new Date();
  @PropertyListener('effectiveDate') private effectiveDate$ = new BehaviorSubject<Date>(this.effectiveDate);

  protected payments$ = this.warranty$.pipe(
    switchMap(async warranty => {
      return warranty ? await this.paymentSvc.list({ referenceId: warranty.contractNumber, limit: 100 }) : null;
    }),
    map(data => data?.items ?? []),
    shareReplay(1)
  );

  protected totalPaidToDate$ = this.payments$.pipe(
    map(payments => {
      return {
        installments: payments.filter(payment => payment.status === Payment.Status.PAID).length,
        amount: payments.reduce(
          (acc, payment) =>
            acc +
            ([Payment.Status.PAID, Payment.Status.REFUNDED, Payment.Status.PARTIALLY_REFUNDED].includes(payment.status)
              ? payment.amount
              : 0),
          0
        ),
      };
    }),

    tap(({ amount }) => {
      this.currencyMaskConfig.max = amount / 100;
      this.refundControl.setValidators([Validators.min(0), Validators.max(amount / 100)]);
      this.refundControl.updateValueAndValidity();
    })
  );

  protected totalTaxPaid$ = this.payments$.pipe(
    map(payments => {
      const totalAmount = payments.reduce(
        (acc, payment) => acc + (payment.status === Payment.Status.PAID ? payment.amount : 0),
        0
      );

      const totalTaxAmount = payments.reduce(
        (acc, payment) => acc + (payment.status === Payment.Status.PAID ? payment.taxAmount : 0),
        0
      );

      return {
        percentage: Math.round((totalTaxAmount / (totalAmount - totalTaxAmount || 1)) * 100),
        amount: totalTaxAmount,
      };
    })
  );

  protected claims$ = this.warranty$.pipe(
    switchMap(async warranty => (warranty ? await this.claimSvc.list({ warrantyId: warranty.id, limit: 100 }) : null)),
    map(data => data?.items ?? []),
    shareReplay(1)
  );

  protected openClaims$ = this.claims$.pipe(map(claims => claims.filter(claim => claim.status === Claim.Status.OPEN)));

  protected totalClaimed$ = this.claims$.pipe(
    map(claims => {
      return {
        claims: claims.length,
        amount: claims.reduce((acc, claim) => acc + (claim.amount?.total ?? 0), 0),
      };
    })
  );

  protected durationStats$ = combineLatest([this.warranty$, this.effectiveDate$]).pipe(
    switchMap(async ([warranty, effectiveDate]) => {
      if (!warranty) return null;
      const stats = await this.warrantySvc.getDurationStats(warranty.id);
      stats.lastDay = effectiveDate;
      stats.numberOfDaysCompleted = stats.numberOfDaysCompleted - moment().diff(effectiveDate, 'days');
      stats.percentage = Math.round((stats.numberOfDaysCompleted / stats.termDuration) * 100);
      return stats;
    })
  );

  protected async confirm() {
    this.saving = true;

    try {
      const warrantyId = this.warranty?.id;
      if (!warrantyId) throw new Error('Unable to cancel warranty.');

      this.refundControl.disable();
      const refundAmount = this.refundControl.getRawValue() ?? undefined;

      const result = await this.warrantySvc.cancelWarranty(
        warrantyId,
        this.effectiveDate,
        refundAmount ? Math.floor(refundAmount * 100) : undefined
      );
      this.cancelled.emit(result);

      this.toastSvc.show({
        type: 'success',
        title: 'Warranty successfully cancelled',
        text: `The warranty has been cancelled${
          refundAmount ? ` and a $${refundAmount.toFixed(2)} refund issued to the customer` : ''
        }.`,
      });

      this.close();
    } catch (err) {
      console.error(err);
      this.toastSvc.show({
        type: 'error',
        title: 'Unable to cancel warranty', // TODO: localize
        text:
          err instanceof Error
            ? err.message
            : (err as { error: { message?: string } }).error.message
            ? (err as { error: { message?: string } }).error.message
            : 'Unknown Error',
      });
      this.close();
    } finally {
      this.saving = false;
      this.refundControl.enable();
    }
  }

  open() {
    this.reset();
    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }

  private reset() {
    this.refundControl.reset({ value: null, disabled: false });
  }
}
