import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FirebaseOptions } from '@angular/fire/app';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { AuthWebModule } from '@vsolv/core/auth/web';
import { UsersWebModule } from '@vsolv/core/users/web';
import { ContentCardModule } from '@vsolv/ui-kit/card';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { InputModule } from '@vsolv/vectors-ui/input';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { SignInFormComponent, UserSecurityFormComponent } from './components';
import { SignOutButtonComponent } from './components/sign-out-button/sign-out-button.component';
import { FirebaseInterceptor } from './interceptors';
import { SendEmailModalComponent } from './modals';
import { FirebaseService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatDividerModule,
    MatTabsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatSnackBarModule,

    AngularFireModule,
    AngularFireAnalyticsModule,

    AuthWebModule,
    UsersWebModule,
    ModalModule,
    ContentCardModule,
    OverlayModule,
    UtilsModule,
    // ToastModule,
    MatTooltipModule,
    FormsModule,
    InputModule,
    ButtonModule,
    ThemingModule,
    DividerModule,
    AlertModule,
    ButtonModule,
  ],
  providers: [FirebaseService],
  declarations: [SendEmailModalComponent, SignOutButtonComponent, SignInFormComponent, UserSecurityFormComponent],
  exports: [SignOutButtonComponent, SignInFormComponent, UserSecurityFormComponent, SendEmailModalComponent],
})
export class FirebaseWebModule {
  static forRoot(firebaseOptions: FirebaseOptions): ModuleWithProviders<FirebaseWebModule> {
    return {
      ngModule: FirebaseWebModule,
      providers: [
        ...(AngularFireModule.initializeApp(firebaseOptions).providers || []),
        { provide: HTTP_INTERCEPTORS, useClass: FirebaseInterceptor, multi: true },

        ScreenTrackingService,
        UserTrackingService,
      ],
    };
  }
}
