/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { PropertySet } from '@vsolv/packages/properties/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { SecurityService } from '@wsphere/staff/web';
import { Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, switchMap, tap } from 'rxjs';
import {
  CopyTermConfigDialog,
  CreateNewVersionDialog,
  EditPlanConditionDialog,
  ExportPlanCoverageConfigsDialog,
  ManagePlanGroupsDialog,
} from '../../dialogs';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-plan-pricing-table',
  templateUrl: './plan-pricing-table.component.html',
})
export class PlanPricingTableComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private securitySvc: SecurityService,
    private breakpointObserver: BreakpointObserver
  ) {}

  @ViewChild('exportCoverageConfigsDialog') exportCoverageConfigsDialog?: ExportPlanCoverageConfigsDialog;

  @ViewChild('pricingMissingInfoDialog') pricingMissingInfoDialog?: DialogComponent;
  @ViewChild('createVersionDialog') createVersionDialog?: CreateNewVersionDialog;

  @ViewChild('configureTermConfigDialog') configureTermConfigDialog?: CopyTermConfigDialog;
  @ViewChild('discardTermConfigDialog') discardTermConfigDialog?: DialogComponent;

  @ViewChild('editConditionDialog') editConditionDialog?: EditPlanConditionDialog;
  @ViewChild('manageGroupsDialog') manageGroupsDialog?: ManagePlanGroupsDialog;

  @ViewChild('duplicateTitleDialog') duplicateTitleDialog?: DialogComponent;
  @ViewChild('removeFeeDialog') removeFeeDialog?: DialogComponent;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('plan') plan$ = new BehaviorSubject<Policy.Plan.Model | null>(null);
  @Input() plan: Policy.Plan.Model | null = null;

  @PropertyListener('propertySet') propertySet$ = new BehaviorSubject<PropertySet.Model | null>(null);
  @Input() propertySet: PropertySet.Model | null = null;

  @Input() canEditPlans = false;

  @PropertyListener('selectedTermId') selectedTermId$ = new BehaviorSubject<string | null>(null);
  selectedTermId: string | null = null;

  @PropertyListener('selectedFees') selectedFees$ = new BehaviorSubject<Policy.PlanCoverageFee.Model[] | null>(null);
  selectedFees: Policy.PlanCoverageFee.Model[] | null = null;

  editing = false;
  saving = false;

  selectedPlanId = '';

  savePricingList = '';

  actionFeeName = '';
  actionFees: Policy.PlanCoverageFee.Model[] | null = null;

  refreshCosts$ = new BehaviorSubject(null);

  readonly isSmall$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => state.matches));

  selectedTerm$ = combineLatest([this.selectedTermId$, this.policy$]).pipe(
    map(([termId, policy]) => {
      if (!policy) return null;

      if (!termId) {
        this.selectedTermId = policy.terms[0].id;
        return null;
      } else return policy.terms.find(term => term.id === termId) ?? null;
    })
  );

  canEditFees = false;
  canEditFees$ = this.securitySvc.globalDistributors$.pipe(
    switchMap(async globalDistributors => {
      const globalDistIds = globalDistributors ? globalDistributors?.map(dist => dist.id) : null;
      return await this.securitySvc.hasAccess('pol_FeeSetup', globalDistIds);
    }),
    tap(canEditFees => (this.canEditFees = canEditFees))
  );

  fees$ = combineLatest([this.plan$, this.selectedTermId$]).pipe(
    switchMap(async ([plan, termId]) => {
      if (!plan || !plan.coverageConfigs || !termId) return [];

      if (!this.selectedPlanId.length) this.selectedPlanId = plan.id;
      else if (this.selectedPlanId !== plan?.id) {
        this.editing = false;
        this.selectedPlanId = plan.id;
      }

      let fees = await this.policySvc.getPlanFees(plan.policyId, plan.id, termId);
      if (!fees || !fees?.items?.length) {
        this.refreshCosts$.next(null);
        this.selectedFees = [];
        return [];
      }

      const feesToSelect = [];

      if (fees.meta.currentPage !== fees.meta.totalPages && (fees.meta.totalPages || 0) > 0) {
        feesToSelect.push(...fees.items);

        while (fees?.meta.currentPage !== fees?.meta.totalPages) {
          fees = await this.policySvc.getPlanFees(plan.policyId, plan.id, termId, {
            page: (fees?.meta.currentPage || 1) + 1,
            limit: 100,
          });

          if (fees && fees.items) feesToSelect.push(...fees.items);
        }
      } else feesToSelect.push(...fees.items);

      this.selectedFees = feesToSelect;
      this.refreshCosts$.next(null);

      return this.selectedFees;
    })
  );

  feesAndTypes$ = combineLatest([this.selectedFees$]).pipe(
    map(([selectedFees]) => {
      if (!this.plan) return;

      const feeTypes = selectedFees?.reduce((acc, fee) => {
        if (!acc.includes(fee.feeType + fee.title)) acc.push(fee.feeType + fee.title);
        return acc;
      }, [] as string[]);

      const coverageConfigs = Object.entries(this.plan.coverageConfigs);
      const termConfig = coverageConfigs.find(config => config[0] === this.selectedTermId);
      if (!termConfig) return [];

      const coverageIds = termConfig[1]?.map(config => config.coverageId);

      const feesAndTypes: { type: string; fees: Policy.PlanCoverageFee.Model[] }[] = [];

      feeTypes?.forEach(type => {
        const fees: Policy.PlanCoverageFee.Model[] = [];

        coverageIds?.forEach(coverageId => {
          const fee = selectedFees?.find(
            fee =>
              fee.feeType + fee.title === type && fee.coverageId === coverageId && fee.termId === this.selectedTermId
          );
          if (fee) fees.push(fee);
        });

        if (fees.length) feesAndTypes.push({ type, fees });
      });

      feesAndTypes.sort((a, b) => a.type.localeCompare(b.type));

      return feesAndTypes;
    })
  );

  costs$ = combineLatest([this.plan$, this.selectedTerm$, this.refreshCosts$]).pipe(
    map(([plan, term]) => {
      if (!plan || !plan.coverageConfigs || !term) return [];

      const coverageConfigs = Object.entries(plan.coverageConfigs);
      const termConfig = coverageConfigs.find(config => config[0] === term.id);
      if (!termConfig) return [];

      const coverageIds = termConfig[1]?.map(config => config.coverageId);

      const costsAndIds: { coverageId: string; cost: number }[] = [];

      coverageIds?.forEach(coverageId => {
        const fees = this.selectedFees?.filter(fee => fee.coverageId === coverageId && fee.termId === term.id);
        const cost = fees?.reduce((acc, fee) => (acc += fee.rule?.defaultValue || 0), 0) || 0;
        costsAndIds.push({ coverageId, cost });
      });

      return costsAndIds;
    })
  );

  openManageGroupsDialog() {
    this.manageGroupsDialog?.open();
  }

  openExportCoverageConfigsDialog() {
    this.exportCoverageConfigsDialog?.open();
  }

  openConfigureTermDialog() {
    if (this.plan) this.configureTermConfigDialog?.open(this.plan);
  }

  openDiscardTermConfigDialog() {
    this.discardTermConfigDialog?.open();
  }

  async configureTerm(termId: string, configs?: Policy.Plan.CoverageConfig[], fees?: Policy.PlanCoverageFee.Model[]) {
    if (!this.policy || !this.policy.coverages || !this.plan) return;

    this.editing = true;

    this.plan.coverageConfigs[termId] =
      configs ??
      this.policy.coverages.map(coverage => {
        const config: Policy.Plan.CoverageConfig = {
          coverageId: coverage.id,
          requirement: { defaultValue: null, blocks: [] } as Conditions.Rule<any>,
          deductible: { defaultValue: null, blocks: [] } as Conditions.Rule<any>,
          liabilityLimit: { defaultValue: null, blocks: [] } as Conditions.Rule<any>,
          price: { defaultValue: null, blocks: [] } as Conditions.Rule<any>,
          liabilityGroups: { defaultValue: [], blocks: [] } as Conditions.Rule<any>,
        } as any;
        return config;
      });

    if (fees) this.selectedFees = fees.map(fee => ({ ...fee, termId, id: '' }));
    else this.selectedFees = [];

    if (configs) await this.save();
  }

  async removeTermConfig(termId: string) {
    if (!this.plan) return;

    this.plan.coverageConfigs[termId] = null;
    this.selectedFees = this.selectedFees?.filter(fee => fee.termId !== termId) ?? null;

    await this.save();
  }

  editPricing() {
    if (this.policy?.status === Policy.Status.DRAFT) this.editing = true;
    else this.createVersionDialog?.open();
  }

  findCoverage(config: Policy.Plan.CoverageConfig) {
    return this.policy?.coverages?.find(coverage => coverage.id === config.coverageId);
  }

  updateConfig(
    termId: string,
    coverageId: string,
    rule: Conditions.Rule<string | number | string[]> | null,
    type: string
  ) {
    if (!rule || !coverageId || !this.plan || !this.selectedTermId || !this.plan.coverageConfigs[termId]?.length) {
      return;
    }

    const selectedConfig = this.plan?.coverageConfigs[termId];

    if (!selectedConfig) return;
    const configIndex = selectedConfig.findIndex(config => config.coverageId === coverageId);

    switch (type) {
      case 'requirement':
        selectedConfig[configIndex].requirement = rule as Conditions.Rule<string>;
        break;
      case 'deductible':
        selectedConfig[configIndex].deductible = rule as Conditions.Rule<number>;
        break;
      case 'liabilityLimit':
        selectedConfig[configIndex].liabilityLimit = rule as Conditions.Rule<number>;
        break;
      case 'price':
        selectedConfig[configIndex].price = rule as Conditions.Rule<number>;
        break;
      case 'liabilityGroups':
        selectedConfig[configIndex].liabilityGroups = rule as Conditions.Rule<string[]>;
        break;
    }

    this.plan.coverageConfigs[termId] = [...selectedConfig];
  }

  findCost(costs: { coverageId: string; cost: number }[] | null, coverageId: string) {
    if (!costs?.length) return 0;
    else return (costs.find(cost => cost.coverageId === coverageId)?.cost || 0) / 100;
  }

  getFeeName(name: string) {
    return name[0] + name.substring(1).replace(/_/g, ' ').toLowerCase();
  }

  updateFeeTitle(type: string, oldTitle: string, newTitle: string, termId: string) {
    const currentTitles = this.selectedFees?.reduce((acc, fee) => {
      if (!acc.includes(fee.feeType + fee.title) && fee.termId === termId) acc.push(fee.feeType + fee.title);
      return acc;
    }, [] as string[]);

    if (currentTitles?.includes(type + newTitle)) {
      this.actionFeeName = newTitle;
      this.duplicateTitleDialog?.open();
    } else {
      this.selectedFees?.forEach(fee => {
        if (fee.feeType + fee.title === type + oldTitle && fee.termId === termId) fee.title = newTitle;
      });
    }
  }

  addFees(fees: Policy.PlanCoverageFee.Fee[], termId: string) {
    if (this.plan) {
      const coverageConfigs = Object.entries(this.plan.coverageConfigs);
      const termConfig = coverageConfigs.find(config => config[0] === termId);

      if (termConfig) {
        const coverageIds = termConfig[1]?.map(config => config.coverageId);

        fees.forEach(fee => {
          coverageIds?.forEach(coverageId => {
            if (this.policy && this.plan) {
              if (!this.selectedFees?.length) this.selectedFees = [];

              this.selectedFees?.push({
                id: '',

                policyId: this.policy.id,
                planId: this.plan.id,

                termId,
                coverageId,

                feeType: fee,
                title: fee[0] + fee.substring(1).replace(/_/g, ' ').toLowerCase(),

                rule: { defaultValue: 0, blocks: [] },
              });
            }
          });
        });
      }
    }

    if (this.selectedFees) this.selectedFees = [...this.selectedFees];
  }

  updateFeeRule(updatedFee: Policy.PlanCoverageFee.Model, rule: Conditions.Rule<any> | null) {
    if (!this.plan) return;

    const planFee = this.selectedFees?.find(
      fee =>
        fee.id === updatedFee.id &&
        fee.termId === updatedFee.termId &&
        fee.coverageId === updatedFee.coverageId &&
        fee.feeType === updatedFee.feeType
    );
    if (!planFee) return;

    planFee.rule = rule;
  }

  removeFeeGroup() {
    if (!this.plan || !this.actionFees?.length) return;

    const feeIds = this.actionFees.map(fee => fee.id);
    if (!feeIds.includes('')) {
      this.selectedFees = this.selectedFees?.filter(fee => !feeIds.includes(fee.id)) ?? null;
    } else {
      this.selectedFees =
        this.selectedFees?.filter(
          fee => fee.feeType + fee.title !== (this.actionFees?.[0].feeType || '') + this.actionFees?.[0].title
        ) ?? null;
    }

    this.actionFeeName = '';
    this.actionFees = null;
    this.removeFeeDialog?.close();
  }

  saveCheck() {
    if (!this.selectedTermId) return;
    if (this.policy?.status !== Policy.Status.DRAFT) return;

    this.savePricingList = '';

    let requirement = false;
    let deductible = false;
    let liability = false;
    let price = false;

    this.plan?.coverageConfigs[this.selectedTermId]?.forEach(config => {
      if (!config.requirement.defaultValue) requirement = true;
      if (!config.deductible.defaultValue && config.deductible.defaultValue !== 0) deductible = true;
      if (!config.liabilityLimit.defaultValue && config.liabilityLimit.defaultValue !== 0) liability = true;
      if (!config.price.defaultValue && config.price.defaultValue !== 0) price = true;
    });

    if (requirement || deductible || liability || price) {
      this.savePricingList += '<br><br><ul>';
      if (requirement) this.savePricingList += '<li>Included in</li>';
      if (deductible) this.savePricingList += '<li>Deductible</li>';
      if (liability) this.savePricingList += '<li>Limit of liability</li>';
      if (price) this.savePricingList += '<li>Price</li>';
      this.savePricingList += '</ul><br>';

      this.pricingMissingInfoDialog?.open();
    } else this.save();
  }

  async save() {
    if (
      !this.editing ||
      !this.plan ||
      !this.policy ||
      !this.selectedTermId ||
      this.policy.status !== Policy.Status.DRAFT
    ) {
      return;
    }

    this.saving = true;

    const plan = await this.policySvc
      .updatePlanTerm(this.plan.id, this.selectedTermId, this.policy.id, {
        canCreateNewVersion: false,
        coverages: this.plan.coverageConfigs[this.selectedTermId] || null,
        fees: this.canEditFees ? this.selectedFees || [] : undefined,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

    if (plan?.id) {
      if (this.policy.id === plan.policyId) {
        this.toastSvc.show({
          type: 'success',
          title: 'Updated <strong>' + this.plan.title + '</strong>',
          text: '<strong>' + this.plan.title + '</strong> has been successfully updated.',
        });

        this.policySvc.refreshPlan();
        this.policySvc.refreshPolicy();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'Updated plan',
          text:
            'A new version of <strong>' +
            this.policy.title +
            '</strong> has been created with an updated version of <strong>' +
            this.plan.title +
            '</strong>.',
        });

        this.policySvc.refreshPolicy(plan.policyId);

        const newPolicy = await this.policySvc.getOne(plan.policyId);
        if (!newPolicy) throw new Error(`Unable to find policy with id: ${plan.policyId}`);
        const newPlanId =
          newPolicy.plans?.find(plan => plan.id.startsWith(this.plan?.id?.split('-')[0] || ''))?.id || '';

        this.navigateTo('../' + plan.policyId + '/plans/' + newPlanId);
      }

      this.pricingMissingInfoDialog?.close();

      this.editing = false;
      this.saving = false;
    }
  }

  navigateTo(path?: any) {
    if (path) this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
