import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostBinding, OnDestroy } from '@angular/core';
import { map, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'vs-toolbar',
  templateUrl: './toolbar.component.html',
})
export class ToolbarComponent implements OnDestroy {
  private _baseStyles = 'flex items-center gap-4 border-b border-gray-200';
  @HostBinding('class') styles = this._baseStyles + ' ' + 'h-12 px-4';

  destroy$ = new Subject<void>();

  constructor(private breakpointObserver: BreakpointObserver) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(
        takeUntil(this.destroy$),
        map(state => state.matches)
      )
      .subscribe(small => {
        this.styles = this._baseStyles + ' ' + (small ? 'h-12 px-4' : 'h-14 px-4');
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
