<ng-container *vsLet="destinations$ | async as _destinations">
  <div class="rounded-xl overflow-hidden border border-gray-200 shadow-sm mt-4">
    <vs-table [columns]="(columns$ | async) ?? []" [data]="payments" (rowClicked)="navigateTo($event.id)"></vs-table>
    <vs-pagination
      [config]="paginationConfig"
      (pagination)="paginationQueryRequest.emit({ page: $event.currentPage, limit: $event.itemsPerPage })"
      class="items-end"
    ></vs-pagination>
  </div>

  <ng-container *ngIf="canEdit">
    <ws-claim-payment-dialog
      [destinations]="_destinations"
      [customer]="customer"
      [customerId]="customerId"
      [canManagePaymentMethods]="canManagePaymentMethods"
      (refresh)="refresh.emit()"
      (refreshPaymentMethods)="refreshPaymentMethods.emit()"
    ></ws-claim-payment-dialog>
  </ng-container>
</ng-container>
