import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKey } from '@vsolv/packages/api-key';
import { firstValueFrom } from 'rxjs';

const baseUrl = '/api/api-keys';

@Injectable()
export class ApiKeyService {
  constructor(private http: HttpClient) {}

  async retrieve(uniqueId: string, idField?: ApiKey.IdField): Promise<ApiKey.Model> {
    return await firstValueFrom(
      this.http.get<ApiKey.Model>(`${baseUrl}/${uniqueId}`, { params: { idField: idField as string } })
    );
  }

  async list(page?: number, limit?: number): Promise<ApiKey.ListApiKeysQueryResponse> {
    //TODO: filters
    return await firstValueFrom(
      this.http.get<ApiKey.ListApiKeysQueryResponse>(baseUrl, {
        params: { ...(page && { page }), ...(limit && { limit }) },
      })
    );
  }

  async create(body: ApiKey.CreateApiKeyRequest) {
    return await firstValueFrom(this.http.post<ApiKey.CreateApiKeyResponse>(baseUrl, body));
  }

  async roll(id: string) {
    return await firstValueFrom(this.http.post<ApiKey.RollApiKeyResponse>(`${baseUrl}/${id}/roll`, {}));
  }

  async update(id: string, body: ApiKey.UpdateApiKeyRequest) {
    return await firstValueFrom(this.http.patch<ApiKey.UpdateApiKeyResponse>(`${baseUrl}/${id}`, body));
  }

  async disable(id: string) {
    return await firstValueFrom(this.http.post<ApiKey.Model>(`${baseUrl}/${id}/disable`, {}));
  }
}
