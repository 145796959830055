/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { PropertyService } from '@vsolv/packages/properties/web';

@Component({
  selector: 'ws-condition-preview',
  templateUrl: './condition-preview.component.html',
})
export class ConditionPreviewComponent implements OnInit {
  constructor(private propertySvc: PropertyService) {}

  @Input() propertySet!: PropertySet.Model;

  group: Conditions.ConditionGroup | null = null;
  @Input() set groupValue(group: Conditions.ConditionGroup | null) {
    this.group = group;
  }

  comparisons = [Conditions.Comparison.AND, Conditions.Comparison.OR];
  properties: (Property.Model<Property.PropertyType> | Conditions.Property)[] = [];

  getConditions() {
    return this.group?.conditions.filter(cond => (cond as any)?.operator) as Conditions.Condition<
      string | number | boolean
    >[];
  }

  getGroups() {
    return this.group?.conditions.filter(cond => (cond as any)?.comparison) as Conditions.ConditionGroup[];
  }

  async ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const setProperties = this.propertySet.properties?.map(assignment => assignment.property!) ?? [];
    this.properties = [...Conditions.getProperties(), ...setProperties];
  }
}
