<vs-dialog
  #rollKeyDialog
  [config]="{
      dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]',
      disableBackdropDismiss: false,
      hideBackdrop: false
    }"
  class="z-50"
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,
        icon: 'dice-2',
        iconTheme: 'primary',
        title: 'Roll API Key',
        subtitle: '',
        showDivider: true
      }"
  ></vs-dialog-header>

  <form [formGroup]="form">
    <div id="dialog-content" class="pt-6">
      <div class="grid grid-cols-3 gap-4">
        <p class="col-span-1 text-sm text-gray-600 font-semibold">User</p>
        <p class="col-span-2 text-sm text-gray-400 font-normal">{{apiKeyItem?.user?.displayName | titlecase}}</p>
        <p class="col-span-1 text-sm text-gray-600 font-semibold">Key Name</p>
        <p class="col-span-2 text-sm text-gray-400 font-normal truncate">{{(apiKeyItem?.name | titlecase) || '-'}}</p>
        <p class="col-span-1 text-sm text-gray-600 font-semibold">Last Used</p>
        <vs-badge class="col-span-2 justify-self-start" theme="info"
          >{{(apiKeyItem?.lastUsed | date) || 'Key has not been used yet'}}</vs-badge
        >
        <p class="col-span-1 text-sm text-gray-600 font-medium">Expiration</p>
        <p class="col-span-2 text-sm text-gray-400 font-normal">
          {{(apiKeyItem?.expired | date) || 'Key has no expiration date'}}
        </p>
      </div>
      <vs-divider class="flex my-6"></vs-divider>
      <div class="">
        <p class="text-md text-gray-900 font-semibold">Authentication required</p>
        <p class="text-sm text-gray-600 font-normal pb-4">To roll this API key, please enter your password.</p>
        <vs-form-field
          theme="primary"
          [config]="{
      label: 'Confirm password',
      hint: '',
      description: ''
    }"
          appearance="responsive"
          ><vs-input-container class="group">
            <vs-icon name="lock-keyhole-square" slot="start"></vs-icon>
            <input placeholder="Confirm password" type="password" formControlName="confirmPassword" />
            <vs-icon
              class="hidden text-danger group-invalid-within:inline-block"
              name="info-circle"
              slot="end"
            ></vs-icon>
          </vs-input-container>
          <span
            class="text-danger"
            slot="hint"
            *ngIf="(!form.get('confirmPassword')?.valid && (form.get('confirmPassword')?.dirty || form.get('confirmPassword')?.touched)) || !isValidPassword"
            >Please enter a valid password.</span
          >
        </vs-form-field>
      </div>
    </div>

    <div class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pt-6">
      <button vs-button class="w-full sm:w-auto" appearance="outline" [disabled]="_submitting" (click)="closeDialog()">
        Cancel
      </button>
      <button
        vs-button
        class="w-full sm:w-auto"
        theme="primary"
        [disabled]="!inputIsValid() || !form.dirty || _submitting"
        (click)="confirm()"
      >
        Roll API Key
        <vs-icon *ngIf="_submitting" name="refresh-cw-02" class="animate-spin"></vs-icon>
      </button>
    </div>
  </form>
</vs-dialog>
<ws-decoded-key-preview-dialog></ws-decoded-key-preview-dialog>
