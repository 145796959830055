import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { BadgeComponent } from './components';

@NgModule({
  imports: [CommonModule, IconsModule, ThemingModule],
  declarations: [BadgeComponent],
  exports: [BadgeComponent],
})
export class BadgeModule {}
