import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';
import { AppCardDialog } from '../../dialogs';

export interface AppCardConfig {
  title: string;
  body: string;
  icon: IconName;
  poweredBy: {
    name: string;
    icon: string;
  };
}

@Component({ selector: 'ws-app-card[config]', templateUrl: './app-card.component.html' })
export class AppCardComponent {
  @Input() config!: AppCardConfig;

  @Input() enabled!: boolean;

  @Output() dialogOpened = new EventEmitter<void>();

  @Output() dialogClosed = new EventEmitter<void>();

  @ViewChild('dialog') dialog?: AppCardDialog;

  openDialog() {
    this.dialog?.openDialog();
  }

  closeDialog() {
    this.dialog?.closeDialog();
  }
}
