<ng-container *ngIf="!property.config.allowedValues?.length; else enum">
  <input
    *ngIf="property.config.format !== 'currency'; else currencyTemplate"
    type="number"
    [required]="required"
    [formControl]="control"
    [min]="property.config.min || null"
    [max]="property.config.max || null"
    [placeholder]="'Please enter ' + property.name + '...'"
  />

  <ng-template #currencyTemplate>
    <div class="flex flex-row w-full h-full gap-2 justify-center align-middle items-center">
      <span>$</span>
      <input
        type="text"
        mask="separator.2"
        thousandSeparator=","
        [required]="required"
        [formControl]="control"
        [min]="property.config.min || null"
        [max]="property.config.max || null"
      />
    </div>
  </ng-template>
</ng-container>

<span class="text-danger" slot="hint" *ngIf="control.touched && control.hasError('required')">
  {{ property.name }} must have a value
</span>

<span class="text-danger" slot="hint" *ngIf="control.touched && control.hasError('min')">
  {{ property.name }} must be {{ property.config.min }} or greater
</span>

<span class="text-danger" slot="hint" *ngIf="control.touched && control.hasError('max')">
  {{ property.name }} must be {{ property.config.max }} or less
</span>

<span class="text-danger" slot="hint" *ngIf="control.touched && control.hasError('allowedValues')">
  {{ property.name }} must be one of {{ property.config.allowedValues!.join(', ') }}
</span>

<ng-template #enum>
  <vs-select class="!w-full pt-[6px]" [formControl]="control">
    <li vs-select-option *ngFor="let option of property.config.allowedValues" [value]="option">
      {{ option }}
    </li>
  </vs-select>
</ng-template>
