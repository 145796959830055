<vs-dialog
  #editEmailTemplateDialog
  [config]="dialogConfig ?? { dialogContainerClass: 'max-w-[525px]' }"
  (closed)="email = null"
>
  <vs-dialog-header
    *ngIf="email"
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
  
      icon: 'edit-01',
      iconTheme: 'primary',

      showDivider: true,

      title: email.type,
      subtitle: email.subject ?? ''
    }"
  ></vs-dialog-header>

  <form [formGroup]="form" class="mt-3">
    <div class="grid grid-cols-[1fr_auto] gap-6">
      <div>
        <p class="text-sm text-gray-700 font-medium">Enabled</p>
        <p class="text-sm text-gray-600">Control whether this email is automatically sent to customers</p>
      </div>

      <input vsInput type="checkbox" appearance="toggle" class="mt-1 mr-1" formControlName="enabled" />
    </div>

    <vs-form-field theme="primary" [config]="{ label: 'Subject' }" class="mt-4">
      <input vsInput id="basic-text-input" type="text" formControlName="subject" />
    </vs-form-field>

    <vs-form-field theme="primary" [config]="{ label: 'Byline' }" class="mt-4">
      <input vsInput id="basic-text-input" type="text" formControlName="byline" />
    </vs-form-field>

    <label class="text-gray-700 font-medium" style="font-size: 0.875rem; line-height: 2.25rem">Content</label>
    <vs-rich-text-editor *ngIf="email" formControlName="content"></vs-rich-text-editor>
  </form>

  <div class="flex footer mt-5 gap-2 justify-end">
    <button vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">Cancel</button>
    <button vs-button size="md" theme="primary" [disabled]="saving || !form.dirty" (click)="submit()">Update</button>
  </div>
</vs-dialog>
