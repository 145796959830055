import { LayoutModule } from '@angular/cdk/layout';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AddressModule } from '@vsolv/core/address/web';
import { DowntimeWebModule } from '@vsolv/core/downtime/web';
import { MultiTenancyWebModule } from '@vsolv/core/multi-tenant/web';
import { UsersWebModule } from '@vsolv/core/users/web';
import { ApiInterceptorModule } from '@vsolv/dev-kit/ngx';
import { ApiKeyWebModule } from '@vsolv/packages/api-key/web';
import { FirebaseWebModule } from '@vsolv/packages/firebase/web';
import { PaymentMethodModule, PaymentModule } from '@vsolv/packages/payments/web';
import {
  AddressPropertyInputComponent,
  HomeInspectionPropertyInputComponent,
  PropertiesWebModule,
  VehiclePropertyInputComponent,
} from '@vsolv/packages/properties/web';
import { ReimbursementWebModule } from '@vsolv/packages/reimbursement/web';
import { StorageSecurityService } from '@vsolv/packages/storage/web';
import { WebhooksWebModule } from '@vsolv/packages/webhooks/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { AppScaffoldModule } from '@vsolv/vectors-ui/app-scaffold';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DatePickerModule } from '@vsolv/vectors-ui/date-picker';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { DropdownModule } from '@vsolv/vectors-ui/dropdown';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewModule } from '@vsolv/vectors-ui/image-preview';
import { InputModule } from '@vsolv/vectors-ui/input';
import { LinkModule } from '@vsolv/vectors-ui/link';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { SlideOutModule } from '@vsolv/vectors-ui/slide-out';
import { StackGroupModule } from '@vsolv/vectors-ui/stack-group';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { ToolbarModule } from '@vsolv/vectors-ui/toolbar';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { BadgeColumnModule, TableModule } from '@vsolv/vectors/table';
import { DistributorsWebModule } from '@wsphere/distributors/web';
import { LinksWebModule } from '@wsphere/links/web';
import { SftpWebModule } from '@wsphere/sftp/web';
import { SecurityService, StaffWebModule } from '@wsphere/staff/web';
import { TenantsWebModule } from '@wsphere/tenants/web';
import { ProvisioningWebModule } from '@wsphere/warranties/web';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';
import { NgPipesModule, NgStringPipesModule } from 'ngx-pipes';
import { from } from 'rxjs';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import {
  AccountSetupGuard,
  IsStaffGuard,
  OnboardDistributorGuard,
  OneTimeTokenGuard,
  SignedInUserGuard,
  SignedOutGuard,
  TenantConfigGuard,
} from './guards';
import { LeftNavLayoutComponent } from './layouts';
import {
  AccountSetupPage,
  ComponentsPage,
  ForgotPasswordPage,
  NotFoundPage,
  PasswordResetPage,
  ProfilePage,
  RefreshPage,
  ReportingPage,
  SettingsPage,
  SignInPage,
  UserSettingsPage,
} from './pages';

import {
  DistributorOnBoardingLinkResolver,
  EmailResolver,
  OneTimeTokenResolver,
  SignedInUserResolver,
  StaffResolver,
  TenantConfigResolver,
} from './resolvers';
import { routes } from './routes';

@NgModule({
  bootstrap: [AppComponent],
  providers: [
    TenantConfigGuard,
    TenantConfigResolver,
    OneTimeTokenGuard,
    OneTimeTokenResolver,
    SignedInUserGuard,
    SignedInUserResolver,
    SignedOutGuard,
    EmailResolver,
    IsStaffGuard,
    AccountSetupGuard,
    StaffResolver,
    OnboardDistributorGuard,
    DistributorOnBoardingLinkResolver,
    provideEnvironmentNgxMask(),
    {
      //Storage Security Service
      provide: StorageSecurityService,
      useFactory: (securitySvc: SecurityService) =>
        new StorageSecurityService(
          from(securitySvc.hasAccess('str_Upload', null)),
          from(securitySvc.hasAccess('str_Update', null)),
          from(securitySvc.hasAccess('str_Delete', null)),
          from(securitySvc.hasAccess('str_View', null)),
          from(securitySvc.hasAccess('str_ViewProcessors', null)),
          from(securitySvc.hasAccess('str_ViewProcessorRuns', null)),
          from(securitySvc.hasAccess('str_CreateProcessorRun', null)),
          from(securitySvc.hasAccess('str_DeleteProcessorRun', null))
        ),
      deps: [SecurityService],
    },
  ],
  declarations: [
    AppComponent,
    NotFoundPage,
    LeftNavLayoutComponent,
    SettingsPage,
    ProfilePage,
    SignInPage,
    ForgotPasswordPage,
    PasswordResetPage,
    AccountSetupPage,
    UserSettingsPage,
    ComponentsPage,
    RefreshPage,
    ReportingPage,
  ],
  imports: [
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    BadgeModule,
    BreadcrumbsModule,
    BrowserModule,
    HttpClientModule,
    ScrollingModule,
    UtilsModule,
    NgStringPipesModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    PortalModule,
    IconsModule,
    LayoutModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DividerModule,
    AppPageModule,
    ToolbarModule,
    SlideOutModule,
    UserAvatarModule,
    AppScaffoldModule,
    ThemingModule, //This module is required in any other web module looking to apply the tenant themes
    AppScaffoldModule,
    AppNavModule,
    ToolbarModule,
    AppPageModule,
    CardModule,
    DatePickerModule,
    SlideOutModule,
    DividerModule,
    UserAvatarModule,
    LinkModule,
    StackGroupModule,
    ButtonModule,
    BadgeModule,
    DropdownModule,
    NgPipesModule,
    ImagePreviewModule,
    TenantsWebModule,

    ApiInterceptorModule.forRoot(environment.apiBaseUrl, 'admin-dashboard'),
    DowntimeWebModule.forRoot(),
    MultiTenancyWebModule.forRoot(environment.apiMode),
    FirebaseWebModule.forRoot(environment.firebase),
    UsersWebModule,
    StaffWebModule.forRoot(),
    DistributorsWebModule,
    SftpWebModule,

    // OverlayModule,
    RouterModule.forRoot(routes),
    BadgeModule,
    TableModule,
    BadgeColumnModule,
    SelectModule,
    TooltipModule,
    MenuModule,
    DialogModule,
    TabsModule,
    FormsModule,
    InputModule,
    ReimbursementWebModule.forRoot({ mode: environment.dwollaMode }),
    AddressModule.forRoot({ apikey: environment.googleMapsKey }),
    ApiKeyWebModule,
    ProvisioningWebModule,
    PaymentModule.forRoot(),
    PaymentMethodModule,

    LinksWebModule,
    PropertiesWebModule.forRoot([
      {
        id: 'address',
        title: 'Address',
        component: AddressPropertyInputComponent,
      },
      {
        id: 'home_inspection',
        title: 'Home Inspection',
        component: HomeInspectionPropertyInputComponent,
      },
      {
        id: 'road_vehicle',
        title: 'Road Vehicle',
        component: VehiclePropertyInputComponent, // create new input component for cars and use it here
      },
    ]),

    // Webhooks
    WebhooksWebModule,
  ],
})
export class AppModule {}
