import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Dashboard } from '@vsolv/packages/dashboarding/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { DashboardService } from '../../services';

@Component({
  selector: 'vs-update-dashboard-dialog',
  templateUrl: './update-dashboard.dialog.html',
})
export class UpdateDashboardDialog {
  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() updated = new EventEmitter<Dashboard.UpdateDashboardResponse>(true);

  private _submitting = false;
  get submitting() {
    return this._submitting;
  }

  private _forceIsDefault = false;
  private _dashboard?: Dashboard.Model;

  readonly form = this.formBuilder.group({
    title: [null as string | null, [Validators.required]],
    description: [null as string | null, [Validators.required]],
    embedUrl: [null as string | null, [Validators.required]],
    isDefault: [false, [Validators.required]],
  });

  constructor(
    private toastSvc: ToastService,
    private formBuilder: FormBuilder,
    private dashboardSvc: DashboardService
  ) {}

  open(dashboard: Dashboard.Model, forceIsDefault?: boolean) {
    this._dashboard = dashboard;
    this._forceIsDefault = forceIsDefault ?? false;

    this.reset(dashboard);
    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }

  reset(dashboard: Dashboard.Model) {
    this.form.reset({
      isDefault: dashboard.isDefault,

      title: dashboard.title,
      description: dashboard.description,

      embedUrl: dashboard.embedUrl,
    });

    if (this._forceIsDefault) {
      this.form.controls.isDefault.reset(true);
      this.form.controls.isDefault.disable();
    } else {
      this.form.controls.isDefault.enable();
    }
  }

  async submit() {
    this._submitting = true;
    this.form.disable();

    const { title, description, embedUrl, isDefault } = this.form.getRawValue();

    try {
      if (!this._dashboard || !title || !description || !embedUrl) throw new Error('Invalid form data');

      const response = await this.dashboardSvc.update({
        isDefault: isDefault ?? false,
        icon: 'bar-chart-square-02',
        id: this._dashboard.id,
        description,
        embedUrl,
        title,
      });

      this.toastSvc.show({
        type: 'success',
        title: 'Dashboard details updated!',
        text: `Your dashboard "<strong>${title}</strong>" has been successfully update.`,
      });

      this.close();

      this.updated.emit(response);
    } catch (err) {
      console.error(err);
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: `Something went wrong when updating your dashboard "<strong>${
          this._dashboard?.title || title
        }</strong>", please try again.`,
      });
    }

    this._submitting = false;

    this.form.enable();
    if (this._forceIsDefault) {
      this.form.controls.isDefault.reset(true);
      this.form.controls.isDefault.disable();
    }
  }
}
