import { Component, HostBinding, Input } from '@angular/core';
import { BadgeConfig } from '@vsolv/vectors-ui/badge';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';

export interface TabNavItemConfig {
  icon?: IconName;
  label: string;
  exact?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routerLink?: string | any[] | null;
  badge?: BadgeConfig & { text: string; theme?: ThemeColor; activeTheme?: ThemeColor };
}

@Component({
  selector: 'vs-tab-nav-item',
  templateUrl: './tab-nav-item.component.html',
})
export class TabNavItemComponent {
  @HostBinding('class') styles =
    'group flex mb-1 align-center cursor-pointer rounded-md text-gray hover:bg-gray-50 focus-within:bg-gray-50 focus-within:text-gray-700 relative hover:text-gray-700 active:bg-transparent hover:active:bg-transparent';

  @Input() config!: TabNavItemConfig;
}
