import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent, DialogConfig } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { map } from 'rxjs';
import { DistributorService } from '../../services';

@Component({
  selector: 'ws-archive-distributor-dialog',
  templateUrl: './archive-distributor.dialog.html',
})
export class ArchiveDistributorDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private distributorSvc: DistributorService,
    private breakpointObserver: BreakpointObserver
  ) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() dialogConfig?: DialogConfig;
  @Input() distributor?: Distributor.Model | null;

  @Output() closed = new EventEmitter<string>();

  saving = false;

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  openDialog() {
    this.dialog.open();
  }

  closeDialog() {
    this.dialog.close();
  }

  cancel() {
    this.closeDialog();
  }

  async archiveDistributor() {
    this.saving = true;

    if (!this.distributor) throw new Error('Unable to archive distributor, distributor not found');

    const archivedDistributor = await this.distributorSvc.archiveDistributor(this.distributor.id).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.closeDialog();
      this.saving = false;
    });

    if (archivedDistributor?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Distributor archived',
        text: '<strong>' + this.distributor?.name + '</strong> has been successfully archived.',
      });

      const currentUrl = this.router.url;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([currentUrl]);
      });

      this.closeDialog();
      this.closed.emit(archivedDistributor.id);
      this.saving = false;
    }
  }
}
