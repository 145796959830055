<div *ngIf="group" class="flex flex-col max-w-max">
  <ng-container *vsLet="getGroups() as groups">
    <div class="flex gap-2">
      <div class="flex flex-col max-w-[28px]">
        <div class="flex max-h-min mb-2">
          <span>{{ group.comparison | titlecase }}</span>
        </div>

        <div *ngIf="groups?.length" class="flex h-full w-full">
          <div class="h-full w-[24px]"></div>
          <div class="h-full w-[24px] border-l border-1"></div>
        </div>
      </div>

      <div class="flex flex-wrap gap-1 mb-2">
        <div
          *ngFor="let condition of getConditions()"
          class="flex flex-row gap-1 max-w-max px-2 bg-gray-100 rounded-full"
        >
          <ng-container *vsLet="condition.propertyId | conditionProperty : propertySet as property">
            <p class="italic">{{ condition.propertyId }}</p>
            <p>{{ condition.operator }}</p>
            <p [class]="condition.valuePropertyId ? 'italic' : ''">
              {{
                condition.valuePropertyId
                  ? condition.valuePropertyId
                  : (condition.value | transformPropertyValue : { property: property!, showLiteralBoolean: true })
              }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>

    <div *ngFor="let group of groups; index as index" class="flex gap-2 max-w-max">
      <div class="flex flex-col flex-1 w-[32px]">
        <div class="flex h-[12px] w-full">
          <div class="h-full w-[16px]"></div>
          <div
            class="h-full w-[16px] border-l border-b border-1"
            [class.rounded-bl-lg]="index === (groups?.length || 0) - 1"
          ></div>
        </div>

        <div *ngIf="index !== (groups?.length || 0) - 1" class="flex min-h-[20px] h-full w-full">
          <div class="h-full w-[16px]"></div>
          <div class="min-h-[12px] h-full w-[16px] border-l border-1"></div>
        </div>
      </div>

      <ws-condition-preview [groupValue]="group" [propertySet]="propertySet"> </ws-condition-preview>
    </div>
  </ng-container>
</div>

<div *ngIf="!group" class="flex max-w-max px-2 bg-gray-100 rounded-full">
  <i>No conditions configured</i>
</div>
