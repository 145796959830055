import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkTable } from '@angular/cdk/table';
import {
  AfterContentChecked,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { IconButtonColumn } from '../../columns';
import { TableColumn } from '../../models';

export interface TableConfig {
  banding?: boolean;
  draggable?: boolean;
}

@Component({
  selector: 'vs-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent<T> implements AfterContentChecked {
  @HostBinding('class') private get _classes() {
    return 'block overflow-auto scrollbar-thin';
  }

  @ViewChild(CdkTable) private table?: CdkTable<T>;

  @Output() rowClicked = new EventEmitter<T>();

  @Input() config?: TableConfig;

  @Input() data?: T[] | null = [];

  @Input() columns: TableColumn<unknown>[] = [];

  @Output() rowDragged = new EventEmitter<T[]>();

  draggableColumn = new IconButtonColumn({ fitContent: true }, () => ({
    type: 'clear',
    rounded: true,
    icon: 'menu-01',
  }));

  @Input() trackBy: TrackByFunction<T> = index => index;

  ngAfterContentChecked() {
    this.table?.updateStickyColumnStyles();
  }

  toggleSort(columnId: string) {
    this.columns.forEach(column => {
      if (column.id === columnId) column.toggleSort();
      else column.clearSort();
    });
  }

  rowClick(rowData: T) {
    this.rowClicked.emit(rowData);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (this.data) {
      moveItemInArray(this.data, event.previousIndex, event.currentIndex);
      this.refresh();

      this.rowDragged.emit(this.data);
    }
  }

  refresh() {
    this.table?.renderRows();
  }
}
