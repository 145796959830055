<vs-dialog
  *ngIf="item$ | async as item"
  [config]="{ dialogContainerClass: 'w-[550px] max-w-[800px]', startOpen: true }"
  (closed)="navigateTo(!item.adjudicationReason ? '../cure' : undefined)"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'file-plus-03',
      iconTheme: 'primary',

      title: (item?.status !== reviewing ? 'Edit ' + (item?.coverage?.title ?? 'Other') : 'Add ' + (item?.coverage?.title ?? '')) + ' Adjudication',
      subtitle: 'Review the details of this claim item to adjudicate it.'

    }"
  ></vs-dialog-header>

  <form *ngIf="item" class="flex flex-col gap-4 p-4" [formGroup]="form">
    <ng-container *ngIf="claim$ | async as claim">
      <vs-input-container [vsMenuTriggerFor]="complaint">
        <p slot="start" class="font-medium text-gray-500">Complaint</p>
        <vs-icon slot="end" name="chevron-down"></vs-icon>
      </vs-input-container>

      <ng-template #complaint>
        <vs-menu class="w-[486px]">
          <div class="flex gap-2 px-6 py-2">
            <div class="basis-1/4">
              <p class="text-sm font-medium text-gray-700 whitespace-nowrap">Customer's Complaint</p>
            </div>

            <div class="basis-3/4">
              <p class="text-sm font-normal text-gray-900">{{ claim?.complaint }}</p>
            </div>
          </div>
        </vs-menu>
      </ng-template>

      <vs-input-container [vsMenuTriggerFor]="details">
        <p slot="start" class="font-medium text-gray-500">Item Details</p>
        <vs-icon slot="end" name="chevron-down"></vs-icon>
      </vs-input-container>

      <ng-template #details>
        <vs-menu class="w-[486px]">
          <ws-claim-item-details-card [claim]="claim" [item]="item"></ws-claim-item-details-card>
        </vs-menu>
      </ng-template>

      <vs-input-container [vsMenuTriggerFor]="cure">
        <p slot="start" class="font-medium text-gray-500">Cure Details</p>
        <vs-icon slot="end" name="chevron-down"></vs-icon>
      </vs-input-container>

      <ng-template #cure>
        <vs-menu class="w-[486px]">
          <ws-claim-item-cure-card [item]="item"></ws-claim-item-cure-card>
        </vs-menu>
      </ng-template>
    </ng-container>

    <vs-divider></vs-divider>

    <p class="font-medium text-gray-500">Adjudicate Claim Item</p>

    <vs-input-group theme="primary">
      <button
        vs-button
        class="grow"
        appearance="outline"
        [class.bg-themed-50]="form.value.status === reviewing"
        (click)="changeStatus(reviewing)"
      >
        <input vsInput type="radio" class="cursor-pointer" formControlName="status" [value]="reviewing" />
        Reviewing
      </button>

      <button
        vs-button
        class="grow"
        appearance="outline"
        [class.bg-themed-50]="form.value.status === approved"
        (click)="changeStatus(approved)"
      >
        <input vsInput type="radio" class="cursor-pointer" formControlName="status" [value]="approved" />
        Approved
      </button>

      <button
        vs-button
        class="grow"
        appearance="outline"
        [class.bg-themed-50]="form.value.status === rejected"
        (click)="changeStatus(rejected)"
      >
        <input vsInput type="radio" class="cursor-pointer" formControlName="status" [value]="rejected" />
        Rejected
      </button>
    </vs-input-group>

    <vs-form-field>
      <div class="flex flex-col gap-1">
        <span class="text-sm font-medium text-gray-700 self-start">Reasoning</span>
        <textarea
          vsInput
          min="2"
          max="5"
          type="text"
          formControlName="reason"
          placeholder="Please enter the reason why you are approving or rejecting this claim item."
          [disabled]="form.value.status === reviewing"
        ></textarea>
      </div>
    </vs-form-field>
  </form>

  <div class="flex gap-2 justify-end">
    <button vs-button appearance="outline" (click)="navigateTo(!item.adjudicationReason ? '../cure' : undefined)">
      Cancel
    </button>
    <button
      vs-button
      theme="primary"
      [disabled]="form.value.status !== reviewing && !form.value.reason"
      (click)="save(item)"
    >
      Save
    </button>
  </div>
</vs-dialog>
