import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiKeyService } from '@vsolv/packages/api-key/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { MenuTriggerForDirective } from '@vsolv/vectors-ui/menu';
import { Staff } from '@wsphere/staff/domain';
import { StaffService } from '@wsphere/staff/web';
import { DecodedKeyPreviewDialog } from '../decoded-key-preview';

@Component({
  selector: 'ws-generate-api-key-dialog',
  templateUrl: './generate-api-key.dialog.html',
})
export class GenerateApiKeyDialog implements OnInit {
  constructor(
    private fb: FormBuilder,
    private apiKeySvc: ApiKeyService,
    private staffSvc: StaffService,
    private toastSvc: ToastService
  ) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;
  @ViewChild(DecodedKeyPreviewDialog) decodedPreview!: DecodedKeyPreviewDialog;

  @Input() permissionKey: string | null = null;

  @Output() refresh = new EventEmitter();

  userId!: string;
  _submitting = false;
  staffList!: Staff.Model[];

  form = this.fb.group({
    user: [null as Staff.Model | null, Validators.required],
    name: [null as string | null, [Validators.required]],
    expired: [null as string | null, []],
  });

  inputIsValid() {
    return this.form.valid;
  }

  async confirm() {
    this.form.disable();
    this._submitting = true;
    const { user, name, expired } = this.form.getRawValue();
    if (!user?.userId || !name) return;

    const expiredDate = expired != '' && expired != null ? new Date(expired) : null;
    if (expiredDate && expiredDate.getTime() <= Date.now()) {
      this.toastSvc.show({
        type: 'error',
        title: 'Invalid Date',
        text: 'Please select a date in the future and try again!',
      });
      this.form.enable();
      this._submitting = false;
      return;
    }

    try {
      const response = await this.apiKeySvc.create({
        userId: user?.userId,
        name: name,
        expired: expiredDate,
      });
      this._submitting = false;
      this.form.enable();
      this.closeDialog();
      this.form.reset();
      this.toastSvc.show({
        type: 'success',
        title: 'API key successfully created',
        text: 'Your new key has been added to the table.',
      });
      this.decodedPreview.openDialog(response.key);
      this.refresh.emit();
    } catch (e) {
      console.error(e);
      this._submitting = false;
      this.form.enable();
      this.closeDialog();
      this.form.reset();
      this.toastSvc.show({
        type: 'error',
        title: 'Unexpected error',
        text: 'Something went wrong and the api-key was not generated. Please try again.',
      });
    }
  }

  populateDate(event: Date, menu: MenuTriggerForDirective) {
    if (event.getTime() <= Date.now()) {
      this.toastSvc.show({
        type: 'error',
        title: 'Invalid Date',
        text: 'Please select a date in the future and try again!',
      });
    } else {
      this.form.patchValue({
        expired: event.toISOString().split('T')[0],
      });
      menu.close();
    }
  }

  async openDialog() {
    await this.getStaff();
    this.dialog.open();
    this.form.reset();
  }

  closeDialog() {
    this.refresh.emit();
    this.dialog.close();
  }

  private async getStaff() {
    this.staffList = (
      await this.staffSvc.list({
        page: 1,
        limit: 100,
        permissionKeys: this.permissionKey ? [this.permissionKey] : null,
      })
    ).items.filter(staff => staff.status !== Staff.Status.DEACTIVATED);
  }

  async ngOnInit() {
    await this.getStaff();
  }

  resetForm() {
    this.form.reset();
    this.form.markAsUntouched();
    this.form.markAsPristine();
  }
}
