/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Property } from '@vsolv/packages/properties/domain';
import { takeUntil } from 'rxjs';
import { PropertyInputComponent, createPropertyControl } from '../../abstract-property-input.component';

@Component({
  selector: 'vs-number-input',
  templateUrl: './number-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: NumberInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: NumberInputComponent,
      multi: true,
    },
  ],
})
export class NumberInputComponent extends PropertyInputComponent implements OnInit {
  @Input() override property!: Property.NumberModel;

  form!: FormGroup;
  control: FormControl = new FormControl<number | null>(null);

  override writeValue(value: number | null): void {
    super.writeValue(value);
    if (this.property.config.format === 'currency') {
      this.form.patchValue({ 0: (this.value as number) >= 0 ? (this.value as number) / 100 : null });
    } else this.form.patchValue({ 0: this.value as any });
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled) this.form.disable();
    else this.form.enable();
  }

  override ngOnInit() {
    super.ngOnInit();
    this.control = createPropertyControl(this.property, this.required);
    this.form = new FormGroup([this.control]);
    if (this.property.config.format === 'currency') {
      this.form.patchValue({ 0: (this.value as number) >= 0 ? (this.value as number) / 100 : null });
    } else this.form.patchValue({ 0: this.value as any });

    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(value =>
        this.formControl.patchValue(this.property.config.format === 'currency' ? value[0] * 100 : value[0])
      );

    if (this.disabled) {
      this.form.disable();
    }
  }
}
