<div class="flex {{ getAppearanceClasses() }}">
  <div
    [class.@2xl:w-72]="appearance === 'responsive'"
    [class.w-72]="appearance === 'horizontal'"
    [class]="config?.labelClass ?? ''"
  >
    <div class="text-sm text-gray-700 font-medium">
      <ng-content select="[slot='before-label']"></ng-content>

      <label *ngIf="config?.label" [for]="$any(config?.label)?.for ?? _inputElId">
        {{ (config?.label | isString) ? $any(config?.label) : $any(config?.label).text }}
      </label>

      <ng-content select="label"></ng-content>
      <ng-content select="[slot='after-label']"></ng-content>
    </div>

    <div class="text-sm text-gray-600">
      <span *ngIf="config?.description">{{ config?.description }}</span>
      <ng-content select="[slot='description']"></ng-content>
    </div>
  </div>

  <div class="relative flex gap-2 flex-1 min-w-0 max-w-lg">
    <ng-content select="[slot='before-content']"></ng-content>

    <div class="flex-1 max-w-full">
      <ng-content></ng-content>

      <div class="empty:hidden mt-1 ml-1 text-sm text-gray-600">
        <span *ngIf="config?.hint">{{ config?.hint }}</span>
        <ng-content select="[slot='hint']"></ng-content>
      </div>
    </div>

    <ng-content select="[slot='after-content']"></ng-content>
  </div>
</div>
