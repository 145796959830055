<vs-app-page
  [config]="{
    icon: 'settings-01',
    title: 'Settings',
    description: 'Here you will find the settings for your organization.'
  }"
>
  <vs-tab-nav
    slot="sticky"
    theme="primary"
    [config]="{
    items: [
      { 
        label: 'Organization',
        routerLink: '.'
      },
      { 
        label: 'Team',
        routerLink: 'team'
      },
      { 
        label: 'Apps',
        routerLink: 'apps'
      },
      { 
        label: 'Developer',
        routerLink: 'developer'
      }
    ]
  }"
  ></vs-tab-nav>
  <router-outlet></router-outlet>
</vs-app-page>
<!-- TODO: add this routes b/w team & developer route in future whenever needed.
 { 
  label: 'Billing',
  routerLink: 'billing'
},
{ 
  label: 'Integrations',
  routerLink: 'integrations'
}, -->
