import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TabsComponent } from '@vsolv/vectors-ui/tabs';
import { Distributor } from '@wsphere/distributors/domain';
import { GLOBAL_DISTRIBUTORS_STORAGE_KEY, SecurityService } from '@wsphere/staff/web';
import { tap } from 'rxjs';
import { DistributorService } from '../../services';

@Component({
  templateUrl: './distributor-settings.page.html',
})
export class DistributorSettingsPage {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService
  ) {}

  @ViewChild(TabsComponent) tabs?: TabsComponent;

  selectedDistributors: Distributor.PartialModel[] = [];

  routeChange$ = this.router.events.pipe(
    tap(event => {
      if (event instanceof NavigationStart && event.url === '/distributor-settings') {
        if (this.selectedDistributors.length) {
          this.router.navigate([`${this.selectedDistributors[0].id}`], { relativeTo: this.route });
          this.tabs?.switchTo(0);
        }
      }
    })
  );

  readonly distributors$ = this.securitySvc.globalDistributors$.pipe(
    tap(async distributors => {
      this.selectedDistributors = [];

      if (distributors) {
        this.selectedDistributors = distributors;

        if (this.selectedDistributors?.length) {
          this.router.navigate([`${this.selectedDistributors[0].id}`], { relativeTo: this.route });
        }
      } else if (distributors === null) {
        const stored = sessionStorage.getItem(GLOBAL_DISTRIBUTORS_STORAGE_KEY);
        if (stored === null) this.router.navigate([`../distributors`], { relativeTo: this.route });

        let distributors: string[] = JSON.parse(stored || '');
        distributors = distributors.map(distributor => 'dist_' + distributor.split('dist_').pop());

        await Promise.all(
          distributors.map(async distributorId => {
            const distributor = await this.distributorSvc.getOne(distributorId);
            if (distributor) this.selectedDistributors.push(distributor);
          })
        );

        this.router.navigate([`distributor-settings/${distributors[0]}`], { relativeTo: this.route });
      }
    })
  );
}
