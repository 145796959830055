import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PropertyService } from '@vsolv/packages/properties/web';
import { Asset } from '@wsphere/assets/domain';
import { Provisioning } from '@wsphere/warranties/domain';
import { BehaviorSubject, Observable, combineLatest, switchMap } from 'rxjs';
import { AssetService } from '../../services';

@Component({
  selector: 'ws-asset-details-card',
  templateUrl: './asset-details-card.component.html',
})
export class AssetDetailsCardComponent {
  constructor(private propertySvc: PropertyService, private assetSvc: AssetService) {}
  @PropertyListener('asset') asset$ = new BehaviorSubject<Asset.Model | null>(null);
  @Input() asset!: Asset.Model;

  @PropertyListener('staffView') staffView$ = new BehaviorSubject<boolean>(false);
  @Input() staffView = false;

  @Input() selected = false;
  @Input() disabled = false;
  @Input() minimized = false;

  @PropertyListener('session') session$ = new BehaviorSubject<Provisioning.ProvisioningSession | null>(null);
  @Input() session?: Provisioning.ProvisioningSession | null;

  @Output() clicked = new EventEmitter();

  propertySet$ = this.asset$.pipe(
    switchMap(async asset => {
      if (!asset) return null;
      if (asset.propertySet) return asset.propertySet;
      return await this.propertySvc.retrievePropertySet(asset.propertySetId);
    })
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  propertyValues$: Observable<Record<string, any>> = combineLatest([this.asset$, this.staffView$, this.session$]).pipe(
    switchMap(async ([asset, staffView, session]) => {
      if (!asset) return {};
      if (asset.values) return asset.values;

      const submission =
        staffView && session
          ? await this.assetSvc.getAssetValuesForSales(asset.id, session.id)
          : await this.assetSvc.getAssetValues(asset.id);
      return submission.value ?? {};
    })
  );

  selectAsset() {
    this.clicked.emit(this.asset);
  }
}
