/* eslint-disable @typescript-eslint/no-explicit-any */
import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { heightExpansion } from '@vsolv/vectors-ui/animations';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { SecurityService } from '@wsphere/staff/web';
import { Claim, ClaimLifecycleStep } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map, switchMap, tap } from 'rxjs';
import { ClaimLifecycleStepsTableComponent } from '../../../claim-lifecycle-steps';
import { ClaimService } from '../../services';

@Component({
  selector: 'ws-claim-lifecycle',
  styleUrls: ['claim-lifecycle.component.scss'],
  templateUrl: './claim-lifecycle.component.html',
  animations: [heightExpansion],
})
export class ClaimLifecycleComponent implements AfterViewInit {
  constructor(
    private toastSvc: ToastService,
    private claimSvc: ClaimService,
    private formBuilder: FormBuilder,
    private securitySvc: SecurityService
  ) {}

  @ViewChild('table') table?: ClaimLifecycleStepsTableComponent;

  @PropertyListener('claim') claim$ = new BehaviorSubject<Claim.Model | null>(null);
  @Input() claim: Claim.Model | null = null;

  @PropertyListener('isCustomerPortal') isCustomerPortal$ = new BehaviorSubject<boolean | null>(null);
  @Input() isCustomerPortal = false;

  editing = false;
  expanded = false;
  overlayOpen = false;

  currentStep = 1;
  selectedStepIndex: number | null = null;

  expired = Claim.Status.EXPIRED;
  done = ClaimLifecycleStep.Status.DONE;
  skipped = ClaimLifecycleStep.Status.SKIPPED;
  statuses = Object.keys(ClaimLifecycleStep.Status);

  form = this.formBuilder.group({
    steps: [[] as ClaimLifecycleStep.ClaimLifecycleStepDto[]],
  });

  refresh$ = new BehaviorSubject(null);

  canEdit$ = combineLatest([this.claim$, this.isCustomerPortal$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey;
      return await this.securitySvc.hasAccess('clm_SetCustomerLifecycle', permissionKey ? [permissionKey] : null);
    })
  );

  steps$ = combineLatest([this.claim$, this.refresh$]).pipe(
    map(([claim]) => ({ claim, steps: claim?.claimLifecycleSteps ?? [] })),
    map(({ claim, steps }) => ({ claim, steps: this.isCustomerPortal ? steps.filter(step => step.visible) : steps })),
    map(({ claim, steps }) =>
      steps.map(step => ({
        ...step,
        description: step.description
          .replace(/%3A/g, ':')
          .replace(/:claimId/g, claim?.id || '')
          .replace(/:contractNumber/g, claim?.warranty?.contractNumber || ''),
      }))
    ),
    tap(steps => {
      this.form.patchValue({ steps: steps.map(step => ({ ...step })) });
    }),
    tap(steps => {
      this.currentStep = steps.findIndex(
        step =>
          (this.isCustomerPortal ? step.visible === true : true) &&
          step.status !== ClaimLifecycleStep.Status.DONE &&
          step.status !== this.skipped
      );

      if (this.currentStep === -1) this.currentStep = steps.length - 1;
    })
  );

  edit() {
    this.editing = !this.editing;

    if (this.editing) {
      this.expanded = false;
      this.table?.edit();
    }
  }

  expand() {
    this.expanded = !this.expanded;
  }

  openStatusPicker(index: number) {
    this.overlayOpen = true;
    this.selectedStepIndex = index;
  }

  closeStatusPicker() {
    this.overlayOpen = false;
  }

  discard() {
    this.editing = false;
    this.table?.edit();
    this.refresh$.next(null);
  }

  async save() {
    if (!this.claim) return;

    const policy = await this.claimSvc
      .updateClaimLifecycleSteps(this.claim.id, { steps: this.form.value.steps || [] })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

    if (policy?.id) {
      this.toastSvc.show({
        type: 'success',
        title: 'Steps updated',
        text: 'The steps for <strong>Claim #' + this.claim?.claimNumber + '</strong> have been successfully updated.',
      });

      this.claimSvc.refreshClaim();
      this.refresh$.next(null);
      this.table?.edit();
      this.editing = false;
    }
  }

  async updateStatus(status: ClaimLifecycleStep.Status) {
    this.form.patchValue({
      steps: this.form.value.steps?.map(step => {
        if (step.order === this.selectedStepIndex) step.status = status;
        return step;
      }),
    });

    this.save();
    this.closeStatusPicker();
  }

  getTheme(status: ClaimLifecycleStep.Status): ThemeColor {
    switch (status) {
      case ClaimLifecycleStep.Status.DONE:
        return 'success';
      case ClaimLifecycleStep.Status.IN_PROGRESS:
        return 'info';
      case ClaimLifecycleStep.Status.PENDING_CUSTOMER:
        return 'warn';
      case ClaimLifecycleStep.Status.UNSUCCESSFUL:
        return 'danger';
      default:
        return 'default';
    }
  }

  getTooltip(status: ClaimLifecycleStep.Status): { title: string; subtitle: string } {
    switch (status) {
      case ClaimLifecycleStep.Status.DONE:
        return { title: 'Done', subtitle: 'This step has been completed.' };
      case ClaimLifecycleStep.Status.IN_PROGRESS:
        return {
          title: 'In progress',
          subtitle: (this.isCustomerPortal ? "We're" : 'An administrator is') + ' currently working on this step.',
        };
      case ClaimLifecycleStep.Status.PENDING_CUSTOMER:
        return {
          title: 'Pending customer',
          subtitle:
            "We're waiting on " +
            (this.isCustomerPortal ? 'you' : 'the customer') +
            " to complete this step. An outline of what needs to be done can be found in the step's description.",
        };
      case ClaimLifecycleStep.Status.UNSUCCESSFUL:
        return {
          title: 'Unsuccessful',
          subtitle:
            'Something went wrong with this step. ' +
            (this.isCustomerPortal
              ? 'We may contact you shortly.'
              : 'Please review it further or contact your customer.'),
        };
      case ClaimLifecycleStep.Status.NOT_STARTED_YET:
        return { title: 'Not started yet', subtitle: "This step hasn't been started yet." };
      default:
        return {
          title: 'Skipped',
          subtitle: "We've skipped this step - it longer needs to be completed.",
        };
    }
  }

  getChildClasses() {
    const a = '[&>p>a]:underline [&>p>a]:text-info-600';
    const ol = '[&>ol]:block [&>ol]:list-decimal [&>ol]:mt-4 [&>ol]:mb-4 [&>ol]:ml-0 [&>ol]:mr-0 [&>ol]:pl-3';
    const ul = '[&>ul]:block [&>ul]:list-disc [&>ul]:mt-4 [&>ul]:mb-4 [&>ul]:ml-0 [&>ul]:mr-0 [&>ul]:pl-3';

    return `${a} ${ol} ${ul}`;
  }

  ngAfterViewInit() {
    const activeStep = document.getElementById('step_' + this.currentStep);
    activeStep?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
    this.selectedStepIndex = +(activeStep?.id.split('_')?.[1] || 0);
  }
}
