<div
  class="flex w-full max-w-[256px] h-full min-h-[36px] max-h-[36px] px-4 py-2 relative overflow-hidden"
  [class.bg-gray-50]="config?.darkBackground"
  [class.bg-base-white]="!config?.darkBackground"
  [class.px-4]="!editing || (editing && rule?.defaultValue)"
  [class.py-2]="!editing || (editing && rule?.defaultValue)"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
>
  <div class="flex gap-2 w-full max-w-full">
    <p
      *ngIf="config?.text"
      tooltipStyle="light"
      tooltipOpenDelay="400"
      class="text-sm text-gray-900 truncate"
      [vsTooltip]="config?.text"
    >
      {{ config?.text }}
    </p>

    <div
      *ngIf="rule"
      class="flex gap-2 w-full max-w-full min-h-[40px] overflow-x-auto scrollbar-thin"
      [class.pointer-events-none]="!editing"
    >
      <ng-container *ngIf="!editing || (editing && rule?.defaultValue)">
        <ng-container *vsLet="getAddonName(rule.defaultValue) as default">
          <vs-badge
            *ngIf="default"
            class="min-w-max max-h-[20px]"
            [theme]="rule.defaultValue === base ? 'primary' : rule.defaultValue === notApplicable ? 'danger' : 'info'"
            (click)="openEditConditionDialog()"
          >
            {{ default | titlecase }}

            <vs-icon
              *ngIf="editing"
              name="x"
              class="flex self-center"
              (click)="removeValue(); $event.stopImmediatePropagation()"
            ></vs-icon>
          </vs-badge>
        </ng-container>
      </ng-container>

      <vs-input-container
        *ngIf="editing && !rule.defaultValue"
        #trigger="cdkOverlayOrigin"
        cdkOverlayOrigin
        style="background: none"
        class="text-sm text-gray-900 border-none h-full w-full !px-4 py-2 rounded-none shadow-none gap-0"
        (click)="overlayOpen = !overlayOpen"
      >
        <input #input type="text" (input)="this.search$.next(input.value)" />

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="overlayOpen"
          (overlayOutsideClick)="$event.target !== input ? (overlayOpen = false) : null"
        >
          <vs-menu>
            <button vs-menu-item (click)="selectValue('BASE')">
              <vs-badge theme="primary"> Base </vs-badge>
            </button>

            <button vs-menu-item (click)="selectAddon()">
              <vs-badge theme="info"> Individual Add-on </vs-badge>
            </button>

            <button *ngFor="let group of filteredGroups$ | async" vs-menu-item (click)="selectValue(group.id)">
              <vs-badge theme="info"> {{ group.title }} </vs-badge>
            </button>

            <button vs-menu-item (click)="selectValue(notApplicable)">
              <vs-badge theme="danger"> Not Applicable </vs-badge>
            </button>

            <button vs-menu-item class="flex" (click)="manageGroupsDialog.openAddonDialog(); overlayOpen = false">
              <vs-icon name="plus" size="18" class="-mb-1"></vs-icon> Add Included In Group
            </button>
          </vs-menu>
        </ng-template>
      </vs-input-container>

      <ng-container *ngFor="let block of rule?.blocks; index as index">
        <vs-badge
          *ngIf="getAddonName(block.value) as name"
          theme="info"
          class="min-w-max max-h-[20px]"
          [theme]="block.value === base ? 'primary' : block.value === notApplicable ? 'danger' : 'info'"
          (click)="openEditConditionDialog()"
        >
          {{ name }}

          <vs-icon
            *ngIf="editing"
            name="x"
            class="flex self-center"
            (click)="removeValue(index); $event.stopImmediatePropagation()"
          ></vs-icon>
        </vs-badge>
      </ng-container>
    </div>

    <div *ngIf="!rule && !config?.text" class="w-[30%] border"></div>
  </div>

  <button
    *ngIf="editing && hovered && plan && !disabled"
    vs-icon-button
    size="sm"
    appearance="clear"
    class="absolute top-0 right-0 m-1"
    (click)="openEditConditionDialog()"
  >
    <vs-icon name="dataflow-02"></vs-icon>
  </button>
</div>

<ws-edit-plan-condition-dialog
  *ngIf="plan && coverage && type && rule"
  #editConditionDialog
  [plan]="plan"
  [type]="type"
  [rule]="rule"
  [coverage]="coverage"
  [propertySet]="propertySet"
  (closed)="updatedRule($event)"
>
</ws-edit-plan-condition-dialog>

<ws-manage-plan-groups-dialog #manageGroupsDialog [plan]="plan" [policy]="policy"></ws-manage-plan-groups-dialog>
