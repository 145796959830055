import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Distributor } from '@wsphere/distributors/domain';

@Injectable()
export class DistributorResolver implements Resolve<Distributor.Model | null> {
  distributor!: Distributor.Model | null;

  async resolve(): Promise<Distributor.Model | null> {
    return this.distributor || null;
  }
}
