import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, distinctUntilChanged, firstValueFrom, map, of, shareReplay } from 'rxjs';

import { PropertyListener } from '@vsolv/dev-kit/rx';

@Injectable({ providedIn: 'root' })
export class TenantService {
  constructor(private http: HttpClient) {}
  baseUrl = '/v1';

  @PropertyListener('tenantId') private _tenantId$ = new BehaviorSubject<string | undefined>(undefined);
  readonly tenantId$ = this._tenantId$.pipe(distinctUntilChanged(), shareReplay(1));

  tenantId?: string;

  async setTenantId(id?: string) {
    this.tenantId = id;
    return this.tenantId;
  }

  getTenantIdFromDomain(): Promise<string | null> {
    return firstValueFrom(
      this.http.get<{ tenantId: string }>(`${this.baseUrl}/tenants/current`).pipe(
        catchError(() => of({ tenantId: null })),
        map(data => data.tenantId)
      )
    );
  }
}
