import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { map } from 'rxjs';

@Component({
  selector: 'vs-notification-settings',
  templateUrl: './notification-settings.component.html',
})
export class UserNotificationSettingsComponent {
  isLargeScreen$ = this.breakpointObserver.observe([Breakpoints.XSmall]).pipe(map(state => !state.matches));
  constructor(private breakpointObserver: BreakpointObserver) {}
}
