<vs-input-container
  [theme]="control && control.invalid ? 'danger' : undefined"
  [vsCustomTheme]="control?.invalid ? null : colorPicker?.value || null"
>
  <input
    #colorInput
    type="text"
    (input)="inputUpdate($event)"
    [value]="color"
    [disabled]="disabled"
    placeholder="Paste or pick a color..."
  />
  <ng-template #colorInfo>
    <span>
      Hex codes or colors from the
      <a class="text-primary-200" href="https://www.w3schools.com/colors/colors_x11.asp" target="_blank">
        W3C/X11 specification <vs-icon name="link-external-01"></vs-icon>
      </a>
      are recognized.
    </span>
  </ng-template>
  <vs-icon
    [vsTooltip]="{ template: colorInfo }"
    [tooltipCloseDelay]="1000"
    [class.text-danger]="control && control.invalid"
    name="info-circle"
    slot="end"
  ></vs-icon>
</vs-input-container>
