<vs-dialog
  *ngIf="item$ | async as item"
  [config]="{ dialogContainerClass: 'w-[550px] max-w-[800px]', startOpen: true }"
  (closed)="navigateTo()"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      
      icon: 'file-plus-03',
      iconTheme: 'primary',

      title: (item?.resolution?.title ? 'Edit ' + (item?.coverage?.title ?? 'Other') : 'Add ' + (item?.coverage?.title ?? '')) + ' item resolution',
      subtitle: 'Edit the details of how the problem was solved for this claim item.'
    }"
  ></vs-dialog-header>

  <form *ngIf="item" class="flex flex-col gap-4 p-4" [formGroup]="form">
    <vs-form-field>
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 basis-1/4">Resolution</span>
        <input vsInput type="text" formControlName="title" placeholder="Enter resolution title" />
      </div>
      <div class="text-center">
        <span
          class="text-sm font-normal text-danger"
          *ngIf="form.controls.title.touched && form.controls.title.invalid"
          slot="hint"
        >
          Title is required and max # of characters is 255
        </span>
      </div>
    </vs-form-field>

    <vs-form-field>
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 self-start basis-1/4">Description</span>
        <textarea
          vsInput
          min="2"
          max="5"
          type="text"
          formControlName="description"
          placeholder="Describe how the issue was resolved"
        ></textarea>
      </div>
      <div class="text-center">
        <span
          class="text-sm font-normal text-danger"
          *ngIf="form.controls.description.touched && form.controls.description.invalid"
          slot="hint"
        >
          Description is required
        </span>
      </div>
    </vs-form-field>

    <vs-divider></vs-divider>

    <ws-claim-item-liability-limit [item]="item"></ws-claim-item-liability-limit>

    <vs-divider></vs-divider>

    <vs-form-field>
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 basis-1/2 flex gap-2">
          Actual Cost
          <vs-icon
            name="info-circle"
            tooltipStyle="light"
            class="self-center text-lg"
            [vsTooltip]="{
              title: 'Actual Cost',
              subtitle:
                'This is the amount that was needed to complete the cure for this claim item. Usually this value comes from the receipt of the customer\'s repair.',
              styled: true
            }"
          ></vs-icon>
        </span>

        <vs-input-container>
          <p class="text-gray-600" slot="start">$</p>
          <input #cost type="number" formControlName="cost" placeholder="Enter actual cost" />
        </vs-input-container>
      </div>
    </vs-form-field>

    <vs-form-field [theme]="form.controls.covered.touched && form.controls.covered.invalid ? 'danger' : 'primary'">
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 basis-1/2 flex gap-2">
          Covered Amount
          <vs-icon
            name="info-circle"
            tooltipStyle="light"
            class="self-center text-lg"
            [vsTooltip]="{
              title: 'Covered Amount',
              subtitle:
                'This is the amount that will be covered by the customer\'s warranty. This amount is calculated by taking the lower value of the remaining limit of liability or the actual cost minus the deductible.',
              styled: true
            }"
          ></vs-icon>
        </span>

        <vs-input-container>
          <p class="text-gray-600" slot="start">$</p>
          <input
            #input
            min="0"
            type="number"
            formControlName="covered"
            placeholder="Enter covered amount"
            [max]="maxCovered($any(cost.value), item)"
          />
        </vs-input-container>
      </div>

      <div class="flex gap-4 items-center">
        <span
          class="text-sm font-normal text-danger"
          *ngIf="form.controls.covered.touched && form.controls.covered.invalid"
          slot="hint"
        >
          Covered amount cannot exceed the remaining limit of liability or the actual cost minus the deductible
          requested by the customer.
        </span>
      </div>
    </vs-form-field>

    <vs-form-field>
      <div class="flex gap-4 items-center">
        <span class="text-sm font-medium text-gray-700 basis-1/2 flex gap-2">
          Goodwill Amount
          <vs-icon
            name="info-circle"
            tooltipStyle="light"
            class="self-center text-lg"
            [vsTooltip]="{
              styled: true,
              title: 'Goodwill Amount',
              subtitle:
                'This is the amount that will be covered by the administrator as an act of goodwill towards this customer.'
            }"
          ></vs-icon>
        </span>

        <vs-input-container>
          <p class="text-gray-600" slot="start">$</p>
          <input type="number" formControlName="goodwill" placeholder="Enter goodwill amount" />
        </vs-input-container>
      </div>
    </vs-form-field>
  </form>

  <div class="flex gap-2 justify-end">
    <button vs-button appearance="outline" (click)="navigateTo()">Cancel</button>
    <button vs-button theme="primary" [disabled]="form.invalid" (click)="save(item)">Save</button>
  </div>
</vs-dialog>
