import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { CardModule } from '@vsolv/vectors-ui/card';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewModule } from '@vsolv/vectors-ui/image-preview';
import { InputModule } from '@vsolv/vectors-ui/input';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { FileInputComponent } from './components';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule,
    CardModule,
    IconsModule,
    InputModule,
    ImagePreviewModule,
    ReactiveFormsModule,
    TooltipModule,
  ],

  declarations: [FileInputComponent],
  exports: [FileInputComponent],
})
export class FileUploadModule {}
