import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

@Injectable()
export class TenantConfigResolver implements Resolve<{ tenantId: string } | null> {
  config: { tenantId: string } | null = null;

  resolve() {
    return this.config;
  }
}
