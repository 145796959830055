/* eslint-disable @angular-eslint/no-output-rename */
/* eslint-disable @angular-eslint/no-outputs-metadata-property */
/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable @angular-eslint/no-inputs-metadata-property */

import { CdkListbox } from '@angular/cdk/listbox';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ul[vs-select-options-list]',
  templateUrl: './select-options-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  hostDirectives: [
    {
      directive: CdkListbox,
      inputs: [
        'cdkListboxUseActiveDescendant:useActiveDescendant',
        'cdkListboxCompareWith:compareWith',
        'cdkListboxValue:selectValue',
        'cdkListboxMultiple:selectMultiple',
        'cdkListboxDisabled:disabled',
      ],
      outputs: ['cdkListboxValueChange:selectValueChange'],
    },
  ],
})
export class SelectOptionsListComponent {
  @HostBinding('class') styles = 'flex text-sm flex-col gap-1 list-none';
}
