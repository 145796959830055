/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { Claim } from '@wsphere/warranties/domain';

@Component({
  selector: 'ws-claim-item-cure-card',
  templateUrl: './claim-item-cure-card.component.html',
})
export class ClaimItemCureCardComponent {
  @Input() item: Claim.ClaimItem.Model | null = null;
}
