import type { ApiMode } from '@vsolv/core/multi-tenant/domain';

export function getBucketName(bucketPrefix: string | null, tenantId: string, apiMode: ApiMode) {
  const prefix = 'ws' + (bucketPrefix ? '-' + bucketPrefix : '');
  return `${prefix}-${tenantId}-${apiMode}`;
}

export function getValidFilename(title: string, extension: string | null) {
  const filename = (title || '_').slice(0, 250).replace(/\n|\r|(\s{2,})|\.|<|>|:|"|\/|\\|\||\?|\*|\^|\[|\]|#/gi, '_');
  return filename + (extension ? (extension.startsWith('.') ? extension : '.' + extension) : '');
}

export function getFileExtension(filename?: string | null): string | null {
  return filename?.match(/\.[0-9a-z]+$/i)?.[0] ?? null;
}
