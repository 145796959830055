import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ConfigWebModule } from '@vsolv/config/web';
import { UnderMaintenanceGuard } from './guards/under-maintenance.guard';

import { DowntimeInterceptor } from './interceptors';
import { UnderMaintenancePage } from './pages';

@NgModule({
  imports: [CommonModule, ConfigWebModule],
  providers: [UnderMaintenanceGuard],
  declarations: [UnderMaintenancePage],
  exports: [UnderMaintenancePage],
})
export class DowntimeWebModule {
  static forRoot(): ModuleWithProviders<DowntimeWebModule> {
    return {
      ngModule: DowntimeWebModule,
      providers: [{ provide: HTTP_INTERCEPTORS, useClass: DowntimeInterceptor, multi: true }],
    };
  }
}
