<ng-container>
  <div class="flex flex-col gap-2 mb-4">
    <div class="pb-2">
      <div class="flex flex-col gap-1">
        <h1 class="text-lg font-bold">Document processing</h1>
        <p class="text-sm text-gray-700">
          Manage apps related to ingesting, storing, and analyzing documents for your dashboard
        </p>
      </div>
      <vs-divider class="my-4"></vs-divider>
      <div class="flex flex-row gap-2 flex-wrap">
        <ws-app-card
          class="w-full sm:max-w-xs"
          [config]="{title: 'Email document ingestion', body: 'Your team members can submit receipts, invoices, and more the with the click of an email - no dashboard sign-in needed', icon: 'inbox-01', poweredBy: {name: 'Obligo', icon: 'assets/apps/obligo.png'}}"
          [enabled]="emailEnabled"
          (dialogClosed)="documentDialog.reset()"
        >
          <ws-email-document-dialog #documentDialog (enabledChanged)="emailEnabled = $event"></ws-email-document-dialog>
        </ws-app-card>
        <ws-app-card
          class="w-full sm:max-w-xs"
          [config]="{
            title: 'Secure File Transfer Protocol',
            body: 'To ensure the secure and speedy provisioning of warranties via CSV, activate Secure File Transfer Protocol (SFTP) for your tenant',
            icon: 'upload-cloud-01',
            poweredBy: {
              name: 'Obligo',
              icon: 'assets/apps/obligo.png'
            }
          }"
          [enabled]="sftpEnabled"
          (dialogClosed)="sftpDialog.reset()"
        >
          <ws-sftp-dialog #sftpDialog (enabledChanged)="sftpEnabled = $event"></ws-sftp-dialog>
        </ws-app-card>
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <div class="pb-2">
        <div class="flex flex-col gap-1">
          <h1 class="text-lg font-bold">Customer portal settings</h1>
          <p class="text-sm text-gray-700">Enable apps to enhance your customer’s experience on the Customer Portal.</p>
        </div>
        <vs-divider class="my-4"></vs-divider>
        <div class="flex flex-row gap-2 flex-wrap">
          <ws-app-card
            #interconCard
            class="w-full sm:max-w-xs"
            [config]="
              {
                title: 'Custom Bot integration', 
                body: 'Experience automated, code-free customer support and lead qualification, tailored for enhanced business efficiency.', 
                icon: 'message-smile-square', 
                poweredBy: 
                  {
                    name: 'Intercom',
                    icon: 'assets/apps/intercom.png'
                  }
              }"
            [enabled]="intercomEnabled"
            (dialogClosed)="intercomDialog.updateStatus()"
            (dialogOpened)="intercomDialog.dialogOpened()"
          >
            <ws-chatbot-dialog
              #intercomDialog
              [type]="chatBotType.INTERCOM"
              (enabledChanged)="intercomEnabled=$event.intercomEnabled;chatBotEnabled=$event.chatBotEnabled"
            ></ws-chatbot-dialog>
          </ws-app-card>
          <ws-app-card
            #typeformCard
            class="w-full sm:max-w-xs"
            [config]="{
              title: 'Chatbot integration', 
              body: 'Transform forms into engaging, no-code conversational experiences, perfect for diverse website interactions.',
              icon: 'message-smile-square', 
              poweredBy: 
                {
                  name: 'Typeform', 
                  icon: 'assets/apps/typeform.png'
                }
              }"
            [enabled]="chatBotEnabled"
            (dialogClosed)="typeformDialog.updateStatus()"
            (dialogOpened)="typeformDialog.dialogOpened()"
          >
            <ws-chatbot-dialog
              #typeformDialog
              [type]="chatBotType.TYPEFORM"
              (enabledChanged)="intercomEnabled=$event.intercomEnabled;chatBotEnabled=$event.chatBotEnabled;"
            ></ws-chatbot-dialog>
          </ws-app-card>
        </div>
      </div>
    </div>

    <div class="pb-2">
      <div class="flex flex-col gap-1">
        <h1 class="text-lg font-bold">Payment configurations</h1>
        <p class="text-sm text-gray-700">
          Use your personal key to customize and control your transactions with Authorize.net
        </p>
      </div>
      <vs-divider class="my-4"></vs-divider>
      <div class="flex flex-row gap-2 flex-wrap">
        <ws-app-card
          class="w-full sm:max-w-xs"
          [config]="{
            title: 'Authorize.net', 
            body: 'Use your personal key to customize and control your transactions with Authorize.net ', 
            icon: 'bank-note-01', 
            poweredBy: 
                {
                name: 'Authorize.net', 
                icon: 'assets/apps/authorizeNet.png'
                }
            }"
          [enabled]="authorizeNetEnabled"
          (dialogClosed)="authorizeNetDialog.closeDialog()"
        >
          <ws-authorize-net-dialog
            #authorizeNetDialog
            (enabledChanged)="authorizeNetEnabled = $event"
          ></ws-authorize-net-dialog>
        </ws-app-card>
      </div>
    </div>
  </div>

  <div class="flex flex-col gap-2 mt-4">
    <div class="pb-2">
      <div class="flex flex-col gap-1">
        <h1 class="text-lg font-bold">Financial tools</h1>
        <p class="text-sm text-gray-700">
          Enhance your financial operations with specialized apps tailored for effective fiscal management and
          compliance.
        </p>
      </div>
      <vs-divider class="my-4"></vs-divider>
      <div class="flex flex-row gap-2 flex-wrap">
        <ws-app-card
          class="w-full sm:max-w-xs"
          [config]="{title: 'TaxJar custom key', body: 'Enable personalized tax management by using your own TaxJar API key for accurate and seamless tax recording on transactions.', icon: 'coins-stacked-03', poweredBy: {name: 'TaxJar', icon: 'assets/apps/taxjar.jpeg'}}"
          [enabled]="taxJar.isEnabled"
        >
          <ws-tax-jar-dialog #taxJar></ws-tax-jar-dialog>
        </ws-app-card>
      </div>
    </div>
  </div>
</ng-container>
