<vs-dialog [config]="{ dialogContainerClass: 'w-[542px] max-h-full' }" (closed)="close(true)">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,

      icon: 'file-plus-03',
      iconTheme: 'primary',

      showDivider: true,

      title: editType === editPolicyType.POLICY_DETAILS
        ? 'Edit <i>' + policy?.title +'</i> details' 
        : editType === editPolicyType.POLICY_TERM
        ? 'Edit <i>' + policy?.title +'</i> terms'
        : editType === editPolicyType.COVERAGE
        ? coverage  
        ? 'Edit <i>' + coverage.title + '</i>' 
        : 'Create New Coverage'
        : editType === editPolicyType.TERMS_AND_CONDITIONS
        ? 'Edit <i>Terms & Conditions</i>'
        : editType === editPolicyType.COVERAGE_SUMMARY
        ? 'Edit <i>Coverage Summary</i>'
        : editType === editPolicyType.CREATE_COVERAGES
        ? 'Create New Coverage'
        : 'Create New Policy',
        
      subtitle: editType === editPolicyType.POLICY_DETAILS
        ? 'Edit the details of <strong>' + policy?.title +'</strong>' 
        : editType === editPolicyType.POLICY_TERM
        ? 'Edit the term details of <strong>' + policy?.title +'</strong>'
        : editType === editPolicyType.COVERAGE
        ? coverage 
        ? 'Edit the details of the <strong>' + coverage.title + '</strong> coverage for the policy' 
        : 'Fill in the required fields to create a new coverage for <strong>' + policy?.title +'</strong>'
        : editType === editPolicyType.TERMS_AND_CONDITIONS
        ? 'Edit the <strong>Terms & Conditions</strong> document for this policy'
        : editType === editPolicyType.COVERAGE_SUMMARY
        ? 'Edit the <strong>Coverage Summary</strong> document for this policy'
        : editType === editPolicyType.CREATE_COVERAGES
        ? 'Follow steps to create a new coverage.'
        : 'Follow these steps to create a new draft policy. This policy will not be visible to customers until it is published.'
    }"
  ></vs-dialog-header>

  <div *ngIf="page === pages.POLICY_DETAILS" class="p-4">
    <ws-policy-details
      [policy]="policy"
      [editing]="!!editType"
      [value]="policyDetails"
      (closed)="close()"
      (pageCompleted)="policyDetails = $event; page = pages.POLICY_ASSET"
    ></ws-policy-details>
  </div>

  <div *ngIf="page === pages.POLICY_ASSET" class="p-4">
    <ws-policy-asset
      [policy]="policy"
      [editing]="!!editType"
      [value]="policyAsset"
      (closed)="close()"
      (prevPage)="policyAsset = $event; page = pages.POLICY_DETAILS"
      (pageCompleted)="policyAsset = $event; page = pages.POLICY_TERM"
    ></ws-policy-asset>
  </div>

  <div *ngIf="page === pages.POLICY_TERM" class="py-4 pl-4 h-full">
    <ws-policy-term
      [policy]="policy"
      [editing]="!!editType"
      [value]="policyTerms"
      (closed)="close()"
      (prevPage)="policyTerms = $event; page = pages.POLICY_ASSET"
      (pageCompleted)="policyTerms = $event; page = pages.COVERAGES"
    ></ws-policy-term>
  </div>

  <div *ngIf="page === pages.COVERAGES || page === pages.COVERAGE_DETAILS_1" class="p-4">
    <ws-policy-coverages
      [policy]="policy"
      [value]="coverages"
      [coverage]="coverage"
      [editing]="!!editType"
      (closed)="close()"
      (prevPage)="coverages = $event; page = pages.POLICY_TERM"
      (pageCompleted)="coverages = $event; page = pages.TERMS_AND_CONDITIONS"
    ></ws-policy-coverages>
  </div>

  <div *ngIf="page === pages.TERMS_AND_CONDITIONS || page === pages.COVERAGE_SUMMARY" class="p-4">
    <ws-policy-documents
      [page]="page"
      [policy]="policy"
      [editing]="!!editType"
      [value]="{ termsAndConditions: policyTermsAndConditions, coverageSummary: policyCoverageSummary}"
      (closed)="close()"
      (prevPage)="policyTermsAndConditions = $event.termsAndConditions; policyCoverageSummary = $event.coverageSummary; page = pages.COVERAGES"
      (pageCompleted)="policyTermsAndConditions = $event.termsAndConditions; policyCoverageSummary = $event.coverageSummary; save()"
    ></ws-policy-documents>
  </div>
</vs-dialog>
