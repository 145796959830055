import { Component, ViewChild } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { StaticPropertyFormComponent } from '../static-property-form.component';

@Component({
  selector: 'vs-home-inspection-property-input',
  templateUrl: './home-inspection-property-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: HomeInspectionPropertyInputComponent,
    },
    { provide: NG_VALIDATORS, useExisting: HomeInspectionPropertyInputComponent, multi: true },
  ],
})
export class HomeInspectionPropertyInputComponent extends StaticPropertyFormComponent {
  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  close() {
    this.dialog?.close();
  }
}
