import { Component, EventEmitter, inject, Input, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Payment } from '@vsolv/packages/payments/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { PaymentService } from '../../services';

@Component({
  selector: 'vs-retry-payment-dialog',
  templateUrl: './retry-payment.dialog.html',
})
export class RetryPaymentDialog {
  private toastSvc = inject(ToastService);
  private paymentSvc = inject(PaymentService);

  @ViewChild(DialogComponent) private dialog?: DialogComponent;

  @Output() readonly saved = new EventEmitter<Payment.RetryPaymentResponse>();

  protected _payment?: Payment.Model;
  @Input() set payment(payment: Payment.Model | undefined) {
    this._payment = payment;
    this.reset();
  }
  get payment() {
    return this._payment;
  }

  protected paymentMethodControl = new FormControl(
    { value: this.payment?.paymentMethodId ?? null, disabled: false },
    Validators.required
  );

  protected saving = false;

  open() {
    this.reset();
    this.dialog?.open();
  }

  close() {
    this.dialog?.close();
  }

  protected async save() {
    this.saving = true;

    try {
      const paymentId = this._payment?.id;
      const paymentMethodId = this.paymentMethodControl.value;
      if (!paymentId || !paymentMethodId) throw new Error('Unable to retry payment.');

      const result = await this.paymentSvc.retryPayment(paymentId, paymentMethodId);
      this.saved.emit(result);

      this.toastSvc.show({
        type: 'success',
        title: 'Payment successfully completed',
        text: 'The payment has been paid successfully.',
      });

      this.close();
    } catch (err) {
      console.error(err);
      this.toastSvc.show({
        title: 'Unable to complete payment',
        type:
          err instanceof Error && err.message === 'Make sure to select a different payment method or add a new one.'
            ? 'warning'
            : 'error',
        text: err instanceof Error ? err.message : 'Unknown Error',
      });
    } finally {
      this.saving = false;
    }
  }

  private reset() {
    this.paymentMethodControl.reset({ value: this.payment?.paymentMethodId ?? null, disabled: false });
  }
}
