import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { User } from '@vsolv/core/users/domain';
import { Warranty } from '@wsphere/warranties/domain';
import { WarrantyWebService } from '../../../warranty/services';

@Component({
  selector: 'ws-warranty-overview-card',
  templateUrl: './warranty-overview-card.component.html',
})
export class WarrantyOverviewCardComponent implements OnInit {
  @Input() warranty: Warranty.Model | null = null;

  emptyUsers: User.Model | null = null;
  @Input() customerId?: string;

  constructor(private warrantySvc: WarrantyWebService, private http: HttpClient, private route: ActivatedRoute) {}

  contractNumber?: string;
  policyNumber?: string;
  warranties: unknown[] = [];
  isOpen = false;
  hover = false;

  async ngOnInit() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.route.paramMap.subscribe(async (params: { get: (arg0: string) => any }) => {
      const warrantyId = params.get('warrantyId');

      if (warrantyId) {
        const warranty = await this.warrantySvc.getOneById(warrantyId);
        this.warranty = warranty;
      }
    });
  }
}
