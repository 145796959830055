import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { Customer } from '@wsphere/customers/domain';
import { BehaviorSubject, map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { CustomerService } from '../../services';

@Component({
  selector: 'ws-customer-picker',
  templateUrl: './customer-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomerPickerComponent,
    },
  ],
})
export class CustomerPickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef, private customerSvc: CustomerService) {}

  @Input() openClaim = false;

  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<Customer.Model | null>();

  readonly searchQuery$ = new BehaviorSubject<string>('');

  overlayOpen = false;

  @PropertyListener('value') value$ = new ReplaySubject<Customer.Model>(1);
  @Input() value: Customer.Model | null = null;

  customers$: Observable<Customer.Model[]> = this.searchQuery$.pipe(
    switchMap(search => this.customerSvc.listCustomers({ search }, this.openClaim)),
    map(customers => customers.items),
    switchMap(async customers => {
      if (this.value) {
        let selectedCustomer = customers.find(customer => customer.userId === this.value?.userId);

        if (!selectedCustomer) {
          selectedCustomer = (await this.customerSvc.listCustomers({ search: this.value.name }, this.openClaim))
            .items[0];
          return [selectedCustomer, ...customers];
        }
      }

      return customers;
    })
  );

  @Input() placeholder = 'Find a customer...';
  @Input() required = false;
  @Input() disabled = false;
  @Input() themeColor: ThemeColor = 'primary';
  touched = false;

  @Input() compareWith: (v0: Customer.Model | null, v1: Customer.Model | null) => boolean = (
    v0: Customer.Model | null,
    v1: Customer.Model | null
  ) => {
    switch (typeof v0) {
      case 'object':
        return JSON.stringify(v0) === JSON.stringify(v1);
      default:
        return v0 === v1;
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange = (_value: Customer.Model | null) => {};
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onTouched = () => {};

  selectValueChanged(value: Customer.Model) {
    this.markAsTouched();
    this.value?.userId === value?.userId ? (this.value = null) : (this.value = value);
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  writeValue(value: Customer.Model | null): void {
    this.value = value;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnChange(onChange: (_value: Customer.Model | null) => {}): void {
    this.onChange = onChange;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
    this.searchQuery$.complete();
  }
}
