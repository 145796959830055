<vs-dialog>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      hideCloseButton: saving,

      icon: 'bank',
      iconTheme: 'primary',
      
      title: 'Retry payment',
      subtitle: 'Update the billing information and retry this payment.'
    }"
  ></vs-dialog-header>

  <div *ngIf="payment" class="px-2 pt-4 pb-2 max-h-[80vh] overflow-auto">
    <vs-select-or-create-payment-method
      #input
      [address]="payment.shippingAddress"
      [userId]="payment.userId"
      [formControl]="paymentMethodControl"
    ></vs-select-or-create-payment-method>

    <div *ngIf="input._state === 'select'" class="flex items-center justify-end gap-3 mt-8">
      <button vs-button appearance="outline" [disabled]="saving" (click)="close()">Back</button>
      <button vs-button theme="primary" [disabled]="paymentMethodControl.invalid || saving" (click)="save()">
        Retry payment
      </button>
    </div>
  </div>
</vs-dialog>
