import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';

export interface InfoCardConfig {
  imageUrl?: string;
  icon?: IconName;

  title?: string;
  titleStyles?: string;
  titleIcon?: IconName;

  subtitle?: string;
  subtitleStyles?: string;
  subtitleIcon?: IconName;

  routerLink?: string;
  queryParams?: Params;
}

@Component({
  selector: 'vs-info-card',
  templateUrl: './info-card.component.html',
})
export class InfoCardComponent {
  constructor(private router: Router, private route: ActivatedRoute) {}

  @Input() theme?: ThemeColor;
  @Input() config?: InfoCardConfig;

  get imageUrl() {
    return this.config?.imageUrl;
  }

  get icon() {
    return this.config?.icon;
  }

  get title() {
    return this.config?.title;
  }

  get titleStyles() {
    return this.config?.titleStyles;
  }

  get titleIcon() {
    return this.config?.titleIcon;
  }

  get subtitle() {
    return this.config?.subtitle;
  }

  get subtitleStyles() {
    return this.config?.subtitleStyles;
  }

  get subtitleIcon() {
    return this.config?.subtitleIcon;
  }

  get routerLink() {
    return this.config?.routerLink;
  }

  navigate() {
    if (this.routerLink)
      this.router.navigate([`${this.routerLink}`], {
        relativeTo: this.route,
        ...(this.config?.queryParams ? { queryParams: this.config.queryParams } : {}),
      });
  }
}
