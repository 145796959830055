import { Property as Properties } from '@vsolv/packages/properties/domain';
export type PropertyValueType = number | string | boolean | Date;

export enum PropertyType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  OBJECT = 'OBJECT',
}

export interface PropertyDisplay {
  name: string;
  format?: 'email' | 'phone';
  allowedValues?: string[];
}

export interface IProperty {
  id: string;
  type: PropertyType;
  display: PropertyDisplay;
  valueKey: string;
  isChild: boolean;
  properties?: PropertyAssignment[];
}

export class PropertyAssignment
  implements
    Omit<
      Properties.ObjectPropertyAssignment,
      'object' | 'objectId' | 'propertyId' | 'property' | 'required' | 'hidden'
    >
{
  readonly property: Property;
  readonly order: number;

  constructor(props: { property: Property; order: number }) {
    this.property = props.property;
    this.order = props.order;
  }
}

export class Property implements Omit<Properties.Model<Properties.PropertyType>, 'formId' | 'created' | 'modified'> {
  readonly id: string;
  readonly type: PropertyType;
  readonly name: string;
  readonly valueKey: string;
  readonly config: Properties.PropertyConfig;
  readonly properties?: PropertyAssignment[];
  readonly isChild: boolean;

  constructor(props: IProperty) {
    this.id = props.id;
    this.type = props.type;
    this.name = props.display.name;
    this.valueKey = props.valueKey;
    this.config = {
      format: props.display.format ?? null,
      allowedValues: props.display.allowedValues,
    } as Properties.PropertyConfig;
    this.properties = props.properties;
    this.isChild = props.isChild ?? false;

    registerProperty(this);
  }
}

const properties = new Map<string, Property>();
function registerProperty(property: Property) {
  if (properties.has(property.id)) {
    throw new Error(property.id + ' is already registered');
  }

  properties.set(property.id, property);

  return property;
}

export function getProperty(propertyId: string) {
  return properties.get(propertyId);
}

export function getProperties() {
  const values = Array.from(properties.values());
  return values.filter(prop => !prop.isChild);
}
