import { Component, EventEmitter, Input, Output } from '@angular/core';

interface ProvisionFormStepperConfig {
  customerCompleted?: boolean; //1
  policyCompleted?: boolean; //2
  assetCompleted?: boolean; //3
  planCompleted?: boolean; //5
  checkoutCompleted?: boolean; //6
}

@Component({
  selector: 'ws-provision-form-stepper',
  templateUrl: './provision-form-stepper.component.html',
  styleUrls: ['./provision-form-stepper.component.scss'],
})
export class ProvisionFormStepperComponent {
  @Input() currentStep = 1;
  @Input() config?: ProvisionFormStepperConfig;
  @Output() stepClicked = new EventEmitter(); //output the number

  goToStep(step: number) {
    this.stepClicked.emit(step);
    this.currentStep = step;
  }
}
