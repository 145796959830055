<ng-container *vsLet="staffView$ | async as staffView">
  <ng-container *vsLet="isLargeScreen$ | async as isLargeScreen">
    <ng-container *vsLet="session$ | async as session">
      <div class="relative flex flex-col items-center gap-4 mb-3">
        <div class="flex gap-2 w-full items-center">
          <div class="w-12 h-12">
            <button
              vs-icon-button
              appearance="clear"
              theme="default"
              size="lg"
              *ngIf="!isLargeScreen"
              (click)="previousPage(staffView ?? false)"
            >
              <vs-icon name="chevron-left" size="20"></vs-icon>
            </button>
          </div>
          <div class="flex justify-center items-center flex-grow" name="icon">
            <vs-featured-icon name="check-verified-02" theme="primary"></vs-featured-icon>
          </div>
          <div class="w-12 h-12">
            <button
              vs-icon-button
              appearance="clear"
              theme="default"
              size="lg"
              *ngIf="!isLargeScreen"
              (click)="nextPage(session, staffView ?? false)"
            >
              <vs-icon name="chevron-right" size="20"></vs-icon>
            </button>
          </div>
        </div>
        <div name="title" class="text-3xl font-semibold text-center">Choose add-ons</div>
        <div name="subtitle" class="text-sm text-gray-500">
          Please compare our add-ons and find the best fit for your {{ staffView ? "customer's" : '' }} needs.
        </div>
      </div>
      <ws-provision-addon-form
        *vsLet="addon$ | async as addons"
        [session]="session!"
        [addons]="addons"
        [formControl]="addon"
        class="w-full"
      ></ws-provision-addon-form>
      <div class="flex gap-3 mt-4" [class.flex-col]="!isLargeScreen">
        <button
          [class]="!isLargeScreen ? 'min-w-full' : ''"
          vs-button
          theme="primary"
          (click)="nextPage(session, staffView ?? false)"
          [disabled]="!session"
        >
          <div class="flex gap-3 items-center">
            <span>Continue</span>
            <vs-icon name="check"></vs-icon>
          </div>
        </button>

        <button
          [class]="!isLargeScreen ? 'min-w-full' : ''"
          vs-button
          (click)="previousPage(staffView ?? false)"
          appearance="outline"
        >
          Back
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
