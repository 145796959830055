import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[vsInputBox]',
})
export class InputBoxDirective {
  @HostBinding('class') get styles() {
    const clear = `focus:outline-none read-only-within:bg-transparent disabled-within:bg-transparent`;
    const base =
      'transition relative bg-base border border-gray-300 hover:border-themed-300 ring-themed-100 rounded-md shadow-sm';
    const placeholder = 'placeholder:-400';
    const autofill = 'autofill:shadow-fill-themed-50';

    return `${clear} ${base} ${placeholder} ${autofill} ${
      this.forceFocus ? 'ring-4 border-themed-300' : 'focus-within:ring-4 focus-within:border-themed-300'
    } ${
      this.forceDisabled
        ? 'cursor-not-allowed border-gray-200 bg-gray-50 placeholder:opacity-50'
        : 'disabled-within:cursor-not-allowed disabled-within:border-gray-200 disabled-within:bg-gray-50 disabled-within:placeholder:opacity-50'
    } ${
      this.forceReadonly ? 'text-gray-600 bg-gray-50' : 'read-only-within:text-gray-600 read-only-within:bg-gray-50'
    } ${
      this.forceInvalid
        ? '!border-danger-300 !ring-danger-100'
        : 'invalid-within:!border-danger-300 invalid-within:!ring-danger-100'
    }`;
  }

  @Input() forceFocus?: boolean;
  @Input() forceDisabled?: boolean;
  @Input() forceReadonly?: boolean;
  @Input() forceInvalid?: boolean;
}
