import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { BehaviorSubject, combineLatest, map } from 'rxjs';

interface EvaluatedCoverage {
  coverageId: string;
  coverage?: Coverage.Model;
  requirement: string;
  price: number;
  deductible: number;
  liabilityLimit: number;
  liabilityGroups: string[];
  liabilityGroupObjects?: Policy.Plan.LiabilityGroup[];
}

interface EvaluatedPlan {
  planId: string;
  plan: Policy.Plan.Model;
  coverages: EvaluatedCoverage[];
}

export interface EvaluatedAddon {
  addonId: string;
  addon: Policy.Plan.Addon;
  price: number;
  coverages: EvaluatedCoverage[];
}

interface PlanFormValues {
  plan: Policy.Plan.Model | null;
  addonIds: string[] | null;
  termId: string | null;
}

@Component({
  selector: 'ws-plan-picker',
  templateUrl: './plan-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PlanPickerComponent,
    },
  ],
})
export class PlanPickerComponent implements ControlValueAccessor {
  constructor(private breakpointObserver: BreakpointObserver, private portalSvc: PortalService) {}

  contactEmail = this.portalSvc.config.contactEmail;

  hasAddons = false;

  @PropertyListener('selectedTerm') selectedTerm$ = new BehaviorSubject<Policy.PolicyTerm | undefined>(undefined);
  selectedTerm?: Policy.PolicyTerm;

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  @Output() buyNow = new EventEmitter();
  @Output() back = new EventEmitter();

  @Output() termChanged = new EventEmitter<string>();

  @Input() viewablePlansAndTerms?: Record<string, string[] | null>;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;
  @Input() staffView = false;

  @PropertyListener('evaluatedPlans') evaluatedPlans$ = new BehaviorSubject<EvaluatedPlan[]>([]);
  @Input() evaluatedPlans?: EvaluatedPlan[];
  @Input() disabled = false;

  @Input() value: Record<string, PlanFormValues> = {};
  @PropertyListener('value')
  value$ = new BehaviorSubject<Record<string, PlanFormValues>>(this.value);

  @Output() addons = new EventEmitter<{ planId: string; addons: EvaluatedAddon[] }[]>();

  touched = false;

  navigation$ = new BehaviorSubject<number>(0);

  plansAndAddons$ = this.evaluatedPlans$.pipe(
    map(plans => {
      if (!plans) return [];
      const planAddons: {
        planId: string;
        plan: Policy.Plan.Model;
        basePrice: number;
        coverages: EvaluatedCoverage[];
        addons: EvaluatedAddon[];
        hasBaseCoverages: boolean;
        hasPrice: boolean;
        hasDescription: boolean;
        baseCoveragesCount: number;
        baseGroups: { group: string; coverages: EvaluatedCoverage[] }[];
      }[] = [];
      const availableAddons: {
        planId: string;
        addons: EvaluatedAddon[];
      }[] = [];
      let hasPrice = false;
      let hasDescription = false;
      for (const plan of plans) {
        let hasBaseCoverages = false;
        const addons: EvaluatedAddon[] = [];
        let basePrice = 0;
        let baseCoveragesCount = 0;
        const groups: { group: string; coverages: EvaluatedCoverage[] }[] = [];
        for (const coverage of plan.coverages) {
          if (coverage.requirement === 'NOT_APPLICABLE') continue;
          const price = coverage.price;
          if (!hasPrice && price) hasPrice = true;

          if (coverage.requirement === 'BASE') {
            basePrice += price;
            hasBaseCoverages = true;
            baseCoveragesCount++;
            if (groups.some(group => group.group === coverage.coverage?.group ?? 'Other')) {
              groups.find(group => group.group === coverage.coverage?.group ?? 'Other')?.coverages.push(coverage);
            } else {
              groups.push({ group: coverage.coverage?.group || 'Other', coverages: [coverage] });
            }
            continue;
          }

          if (addons.find(addon => addon.addonId === coverage.requirement)) {
            const index = addons.findIndex(addon => addon.addonId === coverage.requirement);
            addons[index].coverages.push(coverage);
            addons[index].price += price;
          } else {
            const addon = plan.plan.addons?.find(add => add.id === coverage.requirement);
            if (!addon) continue;
            addons.push({
              addonId: addon.id,
              addon,
              price,
              coverages: [coverage],
            });
          }
        }
        if (addons.length) this.hasAddons = true;
        if (!hasDescription && plan.plan.description) hasDescription = true;
        availableAddons.push({ planId: plan.planId, addons });

        // if (!hasBaseCoverages && !addons.length) continue;

        planAddons.push({
          ...plan,
          hasBaseCoverages,
          hasPrice,
          hasDescription,
          basePrice,
          baseCoveragesCount,
          addons,
          baseGroups: groups,
        });
      }

      planAddons.forEach(item => {
        item.coverages.sort(
          (a, b) =>
            (a.coverage?.order ?? 0) - (b.coverage?.order ?? 0) ||
            (a.coverage?.title ?? '').localeCompare(b.coverage?.title ?? '')
        );

        item.addons.forEach(addon => {
          addon.coverages.sort(
            (a, b) =>
              (a.coverage?.order ?? 0) - (b.coverage?.order ?? 0) ||
              (a.coverage?.title ?? '').localeCompare(b.coverage?.title ?? '')
          );
        });

        item.addons.sort((a, b) => {
          if (a.addonId.startsWith('cov_') && b.addonId.startsWith('cov_')) {
            const aCov = a.coverages[0]?.coverage;
            const bCov = b.coverages[0]?.coverage;
            return (aCov?.order ?? 0) - (bCov?.order ?? 0) || a.addon.title.localeCompare(b.addon.title);
          }

          if (a.addonId.startsWith('cov_')) return 1;
          else if (b.addonId.startsWith('cov_')) return -1;
          else return a.addon.title.localeCompare(b.addon.title);
        });
      });

      this.addons.emit(availableAddons);
      return planAddons;
    }),
    map(plans => plans.filter(plan => plan.hasBaseCoverages || plan.addons.length))
  );

  totalPrices$ = combineLatest([this.policy$, this.selectedTerm$, this.value$, this.plansAndAddons$]).pipe(
    map(([policy, selectedTerm, value, plansAndAddons]) => {
      if (!policy) return null;

      const term = policy.terms.find(term => term.id === selectedTerm?.id) ?? policy.terms[0];
      const termValue: PlanFormValues = value[term.id] ?? { termId: term.id, plan: null, addonIds: null };
      const installmentSchedules = term.paymentSchedules
        .filter(schedule => schedule.installments !== 1)
        .sort((a, b) => b.installments - a.installments);
      const onetimePayment = term.paymentSchedules.some(schedule => schedule.installments === 1);

      const prices = new Map<
        string,
        { hasOneTimePayment: boolean; price: number; total: number; installments: { total: number; count: number }[] }
      >();
      const addonPrices = new Map<string, { total: number; installments: { total: number; count: number }[] }>();
      const addon = new Map<string, { total: number; installments: { total: number; count: number }[] }>();

      //for every plan
      plansAndAddons.forEach(plan => {
        let fullPrice = plan.basePrice;
        let addonTotal = 0;
        plan.addons.forEach(addon => {
          //add addon price to total if selected
          if (plan.planId === termValue.plan?.id && termValue.addonIds?.includes(addon.addonId)) {
            fullPrice += addon.price;
            addonTotal += addon.price;
          }

          //calculate addon price to show in table
          addonPrices.set(plan.planId + '_' + addon.addonId, {
            total: onetimePayment
              ? addon.price
              : installmentSchedules.length
              ? this.calculatePrice(addon.price, installmentSchedules[0].installments, installmentSchedules[0].rate)
              : 0,
            installments: installmentSchedules.map(schedule => {
              return {
                total: this.calculatePrice(addon.price, schedule.installments, schedule.rate),
                count: schedule.installments,
              };
            }),
          });
        });

        prices.set(plan.planId, {
          hasOneTimePayment: onetimePayment,
          price: installmentSchedules.length
            ? this.calculatePrice(fullPrice, installmentSchedules[0].installments, installmentSchedules[0].rate)
            : fullPrice,
          total: onetimePayment
            ? fullPrice
            : installmentSchedules.length
            ? this.calculatePrice(fullPrice, installmentSchedules[0].installments, installmentSchedules[0].rate)
            : 0,
          installments: installmentSchedules.map(schedule => {
            return {
              total: this.calculatePrice(fullPrice, schedule.installments, schedule.rate),
              count: schedule.installments,
            };
          }),
        });

        addon.set(plan.planId, {
          total: onetimePayment
            ? addonTotal
            : installmentSchedules.length
            ? this.calculatePrice(addonTotal, installmentSchedules[0].installments, installmentSchedules[0].rate)
            : addonTotal,
          installments: installmentSchedules.map(schedule => {
            return {
              total: this.calculatePrice(addonTotal, schedule.installments, schedule.rate),
              count: schedule.installments,
            };
          }),
        });
      });

      return { totals: prices, addonPrices, addon, addon_hash: Object.fromEntries(addon) };
    })
  );

  private _onChange?: (value: PlanFormValues) => void;
  private _onTouched?: () => void;

  selectTerm(termId: string) {
    let termValue = this.value[termId];
    if (termValue) {
      this.selectedTerm = this.policy?.terms.find(term => term.id === termId);

      this.markAsTouched();
      this._onChange?.(termValue);
      this.termChanged.emit(termId);

      return;
    }

    this.markAsTouched();
    termValue = { termId, plan: null, addonIds: null };
    this.value = { ...this.value, [termId]: termValue };
    this._onChange?.(termValue);

    this.selectedTerm = this.policy?.terms.find(term => term.id === termId);

    this.termChanged.emit(termId);
  }

  selectPlan(plan: Policy.Plan.Model, emitEvent = false) {
    if (!this.selectedTerm) {
      return;
    }
    let termValue = this.value[this.selectedTerm.id];

    if (!termValue) {
      termValue = { termId: this.selectedTerm.id, plan, addonIds: null };
      this.value = { ...this.value, [this.selectedTerm.id]: termValue };
      this.markAsTouched();
      this._onChange?.(termValue);
    }

    if (termValue.plan?.id !== plan.id) {
      this.markAsTouched();
      termValue = { termId: termValue.termId, addonIds: null, plan };
      this.value = { ...this.value, [this.selectedTerm.id]: termValue };
      this._onChange?.(termValue);
    }

    if (emitEvent && termValue.plan) {
      this.buyNow.emit();
    }
  }

  selectAddon(plan: Policy.Plan.Model, addonId: string) {
    if (!this.selectedTerm) {
      return;
    }

    let termValue = this.value[this.selectedTerm.id];

    if (!termValue) {
      termValue = { termId: this.selectedTerm.id, plan, addonIds: [addonId] };
      this.value = { ...this.value, [this.selectedTerm.id]: termValue };
      this.markAsTouched();
      this._onChange?.(termValue);
      return;
    }

    if (termValue.plan?.id === plan.id && termValue.addonIds?.includes(addonId)) return; //nothing to update
    this.markAsTouched();
    let addonIds: string[] = [];

    if (termValue.plan?.id === plan.id) {
      addonIds = [...(termValue.addonIds || [])];
    } else {
      this.selectPlan(plan, false);
      addonIds = [];
    }
    addonIds = [...addonIds, addonId];
    termValue = { plan, addonIds, termId: this.selectedTerm.id };

    this.value = { ...this.value, [this.selectedTerm.id]: termValue };
    this._onChange?.(termValue);
  }

  deselectAddon(addonId: string) {
    if (!this.selectedTerm) {
      return;
    }
    let termValue = this.value[this.selectedTerm.id];

    if (termValue.addonIds?.includes(addonId)) {
      this.markAsTouched();
      termValue = { ...termValue, addonIds: termValue.addonIds?.filter(id => id !== addonId) || null };
      this.value = { ...this.value, [this.selectedTerm.id]: termValue };
      this._onChange?.(termValue);
    }
  }

  writeValue(value: PlanFormValues | null): void {
    if (!value?.termId) return;

    this.value = { [value.termId]: value };
    if (!this.selectedTerm) this.selectTerm(value.termId);
  }

  registerOnChange(onChange: (value: PlanFormValues) => void): void {
    this._onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this._onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this._onTouched?.();
      this.touched = true;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  getEvaluatedCoverage(coverageId: string, coverages: EvaluatedCoverage[]) {
    return coverages?.find(cov => cov.coverageId === coverageId);
  }

  getEvaluatedLiabilityGroupInfo(
    planLiabilityGroups: Policy.Plan.LiabilityGroup[] | null,
    coverageLiabilityGroups: string[]
  ) {
    return planLiabilityGroups?.filter(group => coverageLiabilityGroups.indexOf(group.id) !== -1);
  }

  calculatePrice(amount: number, installments: number, rate: number) {
    let rateIncrease = 0;
    if (rate !== 0) {
      rateIncrease = (amount * rate) / 100;
    }
    return Math.floor((amount + rateIncrease) / installments);
  }

  goBack() {
    this.back.emit();
  }
}
