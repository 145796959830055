<ng-container *ngIf="isSmall$ | async; else tabs">
  <vs-dropdown #dropdown class="group">
    <ng-container slot="selected">
      <ng-container *ngFor="let item of config.items">
        <span
          #link="routerLinkActive"
          routerLinkActive="!flex"
          class="hidden gap-1 items-center pointer-events-none focus:outline-none"
          [routerLink]="item.routerLink"
          [routerLinkActiveOptions]="{ exact: item.exact ?? true }"
        >
          <vs-icon
            *ngIf="item.icon"
            size="20"
            class="text-gray-500 mr-1 align-text-bottom"
            [name]="item.icon"
          ></vs-icon>

          {{ item.label }}

          <vs-badge
            *ngIf="item.badge"
            [config]="item.badge"
            [theme]="link.isActive ? item.badge.activeTheme ?? item.badge.theme : item.badge.theme"
          >
            {{ item.badge.text }}
          </vs-badge>
        </span>
      </ng-container>
    </ng-container>

    <vs-sidebar-nav-item
      *ngFor="let item of config.items; let index = index"
      class="mx-2 my-1 relative w-auto"
      [config]="item"
      (click)="dropdown.close()"
    ></vs-sidebar-nav-item>
  </vs-dropdown>
</ng-container>

<ng-template #tabs>
  <div class="scrollbar-thin w-full overflow-x-auto overflow-y-hidden">
    <div class="flex gap-2 border-b border-gray-200">
      <vs-tab-nav-item *ngFor="let item of config.items" [config]="item"></vs-tab-nav-item>
    </div>
  </div>
</ng-template>
