import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';

@NgModule({
  imports: [CommonModule, ThemingModule],
  exports: [ProgressBarComponent],
  declarations: [ProgressBarComponent],
})
export class ProgressBarModule {}
