<ng-container *ngIf="selectedCoverageIndex === null && !coverage">
  <ng-container *ngIf="loadedCoverages$ | async; else isLoadingCoverages">
    <p class="text-sm font-medium text-gray-900 mt-4">{{ editing ? 'Add coverage' : 'Coverage Details' }}</p>
    <p class="text-sm text-gray-600 mb-4">
      {{
        editing
          ? 'Please add coverages for this policy. Make sure not to add any pre-existing coverages so no duplicate are made.'
          : 'Add coverages to this policy.'
      }}
    </p>

    <div *ngIf="coverages.length" class="mt-4 overflow-auto max-h-32 scrollbar-thin">
      <div
        *ngFor="let coverage of coverages; index as index"
        class="flex gap-4 px-2 justify-between border-b rounded-lg"
      >
        <div class="flex flex-row items-center gap-4 p-2">
          <div class="flex flex-col items-center">
            <p class="text-sm font-medium text-gray-900 self-center max-w-[160px] truncate">{{ coverage.title }}</p>
          </div>

          <vs-badge theme="primary">{{ coverage.group }}</vs-badge>
        </div>

        <div class="flex p-1">
          <button vs-icon-button class="rounded-md" appearance="clear" (click)="selectCoverage(coverage, index)">
            <vs-icon name="edit-01"></vs-icon>
          </button>

          <button vs-icon-button class="rounded-md" appearance="clear" (click)="copyCoverage(coverage)">
            <vs-icon name="copy-03"></vs-icon>
          </button>

          <button vs-icon-button class="rounded-md" appearance="clear" (click)="removeCoverage(index)">
            <vs-icon name="trash-01"></vs-icon>
          </button>
        </div>
      </div>
    </div>

    <div class="w-full mt-4 p-3 flex gap-4 justify-between bg-gray-50 rounded-lg">
      <div>
        <p class="text-sm font-semibold text-primary-700">
          {{ coverages.length ? 'Add another coverage' : 'No coverages listed' }}
        </p>
        <p
          class="text-xs font-regular text-gray-600 mt-1"
          [innerHtml]="
            coverages.length
              ? 'Add an additional coverage to this policy'
              : 'The coverages you create will be listed here'
          "
        ></p>
      </div>

      <button vs-button size="md" class="self-center" appearance="outline" (click)="createCoverage()">
        <vs-icon name="plus"></vs-icon>
        Coverage
      </button>
    </div>

    <div class="flex mt-4">
      <vs-divider class="mt-2"></vs-divider>
      <p class="self-center ml-2 mr-2 text-xs font-normal text-gray-600">Or</p>
      <vs-divider class="mt-2"></vs-divider>
    </div>

    <form class="mt-2" [formGroup]="csvForm">
      <vs-form-field>
        <vs-file-input
          formControlName="file"
          [accept]="'.CSV'"
          [moreInfo]="'Drag a CSV file here or browse to upload.'"
        ></vs-file-input>
      </vs-form-field>
    </form>
  </ng-container>

  <ng-template #isLoadingCoverages>
    <div class="flex flex-col gap-4 justify-center items-center min-h-[350px]">
      <vs-icon name="loading-01" class="text-primary-500 animate-spin" size="35"></vs-icon>
      <div class="text-gray-700 text-sm">{{ saving ? 'Adding' : 'Creating' }} coverages...</div>
    </div>
  </ng-template>

  <div class="flex justify-center w-full mt-4">
    <button vs-button class="flex justify-center" appearance="clear" theme="primary" (click)="getCoverageTemplate()">
      <vs-icon name="download-01"></vs-icon>
      Download template
    </button>
  </div>

  <div class="flex gap-2 flex-wrap sm:flex-row mt-4" [class]="editing ? 'justify-end' : 'justify-between'">
    <div
      *ngIf="!editing"
      class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
    >
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
    </div>

    <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-xs text-danger-500">
      <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
      <span class="self-center">
        Adding coverages will create a new policy version, the current policy will not be modified
      </span>
    </div>

    <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
      <button
        vs-button
        appearance="outline"
        class="flex-grow sm:flex-grow-0"
        [class.flex-1]="editing"
        (click)="editing ? close() : goBack()"
      >
        {{ editing ? 'Cancel' : 'Back' }}
      </button>

      <vs-input-group>
        <button
          *ngIf="canReplaceExisting && coverages.length"
          vs-button
          appearance="outline"
          (click)="replaceExistingDialog.open()"
        >
          Replace Existing
        </button>

        <button
          vs-button
          class="flex-grow sm:flex-grow-0"
          [appearance]="editing || coverages.length ? 'default' : 'outline'"
          [theme]="editing || coverages.length ? 'primary' : undefined"
          [disabled]="(loadedCoverages$ | async) === false || (editing && !coverages.length) || saving"
          (click)="editing ? createBulk() : pageComplete()"
        >
          {{ editing ? 'Add Coverages' : coverages.length ? 'Next' : 'Skip' }}
        </button>
      </vs-input-group>
    </div>
  </div>
</ng-container>

<ng-container *vsLet="patchValue$ | async">
  <ng-container *ngIf="(selectedCoverageIndex !== null || (editing && coverage)) && firstPage">
    <p class="text-sm font-medium text-gray-900 mt-4">Coverage Details</p>
    <p class="text-sm text-gray-600 mb-4">Add a title, description, and group to this coverage.</p>

    <form class="flex flex-col gap-4" [formGroup]="form">
      <vs-form-field>
        <div class="flex gap-4 items-center">
          <label class="text-sm font-medium text-gray-700 basis-1/4">Title</label>
          <input vsInput id="title-input" type="text" formControlName="title" />
        </div>
      </vs-form-field>

      <vs-form-field>
        <div class="flex gap-4 items-center">
          <label class="text-sm font-medium text-gray-700 basis-1/4">Description</label>
          <input vsInput id="description-input" type="text" formControlName="description" />
        </div>
      </vs-form-field>

      <vs-form-field>
        <div class="flex gap-4 items-center">
          <label class="text-sm font-medium text-gray-700 basis-1/4">Group</label>
          <input vsInput id="group-input" type="text" formControlName="group" />
        </div>
      </vs-form-field>

      <vs-form-field>
        <div class="flex gap-4 items-center">
          <label class="flex gap-2.5 items-center text-sm font-medium text-gray-700 basis-1/4">
            <span>Order</span>
            <vs-icon
              size="14"
              name="help-circle"
              tooltipStyle="light"
              tooltipMaxWidth="xs"
              class="flex justify-center items-center text-gray-400"
              vsTooltip="Specify the order coverages will appear in when shown to a customer on a plan."
            ></vs-icon>
          </label>

          <input vsInput id="group-input" type="number" formControlName="order" />
        </div>
      </vs-form-field>
    </form>

    <div
      class="flex gap-2 flex-wrap sm:flex-row mt-4"
      [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
    >
      <div
        *ngIf="!editing"
        class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
      >
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      </div>

      <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
        <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
        <span class="self-center">
          Saving will create a new policy version, the current policy will not be modified
        </span>
      </div>

      <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
        <button
          vs-button
          appearance="outline"
          class="flex-grow sm:flex-grow-0"
          [class.flex-1]="editing"
          (click)="editing && coverage ? close() : backRemove()"
        >
          {{ editing && coverage ? 'Cancel' : 'Back' }}
        </button>

        <button
          vs-button
          theme="primary"
          class="flex-grow sm:flex-grow-0"
          [class.flex-1]="editing"
          [disabled]="form.invalid"
          (click)="firstPage = false"
        >
          Next
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(selectedCoverageIndex !== null || (editing && coverage)) && !firstPage">
    <p class="text-sm font-medium text-gray-900 mt-4">Coverage Details</p>
    <p class="text-sm text-gray-600 mb-4">Add inclusions and exclusions to this coverage.</p>

    <form [formGroup]="form">
      <vs-form-field>
        <div class="flex gap-4">
          <label class="text-sm font-medium text-gray-700 basis-1/4">Inclusions</label>
          <textarea vsInput id="inclusions-input" type="text" formControlName="inclusions"></textarea>
        </div>
      </vs-form-field>

      <vs-form-field>
        <div class="flex gap-4">
          <label class="text-sm font-medium text-gray-700 basis-1/4">Exclusions</label>
          <textarea vsInput id="exclusions-input" type="text" formControlName="exclusions"></textarea>
        </div>
      </vs-form-field>
    </form>

    <div
      class="flex gap-2 flex-wrap sm:flex-row mt-4"
      [class]="editing && policy?.status === draft ? 'justify-end' : 'justify-between'"
    >
      <div
        *ngIf="!editing"
        class="flex gap-2 self-center flex-grow sm:flex-grow-0 -mb-14 max-w-full sm:max-w-max w-full sm:w-max"
      >
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-primary-500 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
        <div class="self-center rounded-lg h-2 bg-gray-100 flex-grow sm:flex-grow-0 w-full sm:w-10"></div>
      </div>

      <div *ngIf="editing && policy?.status !== draft" class="flex gap-2 text-sm text-danger-500">
        <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
        <span class="self-center">
          Saving will create a new policy version, the current policy will not be modified
        </span>
      </div>

      <div class="flex gap-2 justify-end self-center w-full mt-2 sm:mt-0 flex-grow sm:flex-grow-0">
        <button
          vs-button
          appearance="outline"
          class="flex-grow sm:flex-grow-0"
          [class.flex-1]="editing"
          (click)="firstPage = true"
        >
          Back
        </button>

        <button
          vs-button
          theme="primary"
          class="flex-grow sm:flex-grow-0"
          [class.flex-1]="editing"
          [disabled]="saving || !form.dirty"
          (click)="editing && coverage ? save() : addCoverage()"
        >
          {{ editing && coverage ? 'Save' : 'Next' }}
        </button>
      </div>
    </div>
  </ng-container>
</ng-container>

<vs-dialog #replaceExistingDialog [config]="{ dialogContainerClass: 'w-[500px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,

      icon: 'file-plus-03',
      iconTheme: 'primary',

      title: 'Replace all existing coverages?',
      subtitle: 'Are you sure you want to replace all coverages for this policy with your newly created coverages? '
    }"
  ></vs-dialog-header>

  <div *ngIf="policy?.status !== draft" class="flex gap-2 text-danger-500 text-sm mb-4">
    <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
    <span class="self-center"> Saving will create a new policy version, the current policy will not be modified </span>
  </div>

  <div class="buttons flex gap-4">
    <button
      class="flex-grow"
      vs-button
      size="md"
      appearance="outline"
      [disabled]="saving"
      (click)="replaceExistingDialog.close()"
    >
      Cancel
    </button>

    <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="replaceExisting()">
      Replace
    </button>
  </div>
</vs-dialog>
