import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PortalService } from '@vsolv/packages/portal-config/web';
@Component({ templateUrl: './not-found.page.html' })
export class NotFoundPage implements OnInit {
  private portalSvc = inject(PortalService);
  private router = inject(Router);

  ngOnInit(): void {
    this.loadConfig();
  }

  loadConfig() {
    const localConfig = localStorage.getItem('config');
    if (localConfig) {
      this.portalSvc.setTheming(JSON.parse(localConfig));
    }
  }
  returnToHome() {
    this.router.navigate(['/']);
  }
}
