<div
  class="flex w-full max-w-[256px] h-full min-h-[36px] max-h-[36px] px-4 py-2 whitespace-nowrap relative overflow-hidden"
  [class.bg-gray-50]="config?.darkBackground"
  [class.px-4]="!editing || (editing && rule?.blocks?.length)"
  [class.py-2]="!editing || (editing && rule?.blocks?.length)"
  (mouseenter)="hovered = true"
  (mouseleave)="hovered = false"
>
  <ng-container *vsLet="numberRule(rule) as numberRule">
    <p *ngIf="config?.text" class="text-sm text-gray-400">{{ config?.text | currency }}</p>

    <span *ngIf="numberRule" class="leading-5 w-full max-w-full min-h-[40px] overflow-x-auto scrollbar-thin">
      <span
        *ngIf="
          (!editing && (numberRule.defaultValue || numberRule.defaultValue === 0 || numberRule.valuePropertyPath)) ||
          (editing && numberRule.blocks.length)
        "
        theme="default"
        class="text-sm text-gray-900"
        [class.text-gray-300]="disabled"
      >
        <vs-badge *ngIf="numberRule.valuePropertyPath" class="text-sm text-gray-900" theme="default">
          {{ numberRule.valuePropertyPath }}
        </vs-badge>

        <span *ngIf="!numberRule.valuePropertyPath"> {{ numberRule.defaultValue / 100 | currency }} </span>
        <span [class]="numberRule.valuePropertyPath ? '' : '-ml-1'"> {{ numberRule.blocks.length ? ', ' : '' }} </span>
      </span>

      <vs-input-container
        *ngIf="editing && !numberRule.blocks?.length"
        style="background: none"
        class="text-sm text-gray-900 border-none h-full w-full max-h-10 !px-4 py-2 rounded-none shadow-none gap-0"
      >
        <span *ngIf="numberRule.valuePropertyPath">
          <vs-badge class="text-sm text-gray-900" theme="default" (click)="openEditConditionDialog()">
            {{ numberRule.valuePropertyPath }}
            <vs-icon
              *ngIf="editing"
              name="x"
              class="flex self-center"
              (click)="removeValuePropertyPath(); $event.stopImmediatePropagation()"
            ></vs-icon>
          </vs-badge>
        </span>

        <ng-container *ngIf="!numberRule.valuePropertyPath">
          <span slot="start" class="-mb-px">$</span>
          <input
            #default
            type="number"
            min="0.00"
            step="0.01"
            class="-mt-1 -ml-2 max-h-[20px] self-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            [value]="numberRule.defaultValue || numberRule.defaultValue === 0 ? numberRule.defaultValue / 100 : null"
            (input)="!isNotANumber(+default.value) && updateDefault(+default.value * 100)"
          />
        </ng-container>
      </vs-input-container>

      <span
        *ngFor="let block of numberRule.blocks; index as index"
        class="text-sm text-gray-900"
        [class.text-gray-300]="disabled"
      >
        <vs-badge *ngIf="block.valuePropertyPath" theme="default">
          {{ block.valuePropertyPath }}
        </vs-badge>

        <span *ngIf="!block.valuePropertyPath">{{ block.value / 100 || 0 | currency }}</span>
        <span [class]="numberRule.valuePropertyPath ? '' : '-ml-1'">
          {{ index < numberRule.blocks.length - 1 ? ', ' : '' }}
        </span>
      </span>
    </span>

    <p *ngIf="!numberRule && disabled" class="text-gray-300">{{ 0 | currency }}</p>
  </ng-container>

  <button
    *ngIf="editing && hovered && plan && !disabled"
    vs-icon-button
    size="sm"
    appearance="clear"
    class="absolute top-0 right-0 m-1"
    (click)="openEditConditionDialog()"
  >
    <vs-icon name="dataflow-02"></vs-icon>
  </button>
</div>

<ws-edit-plan-condition-dialog
  *ngIf="plan && type && rule"
  #editConditionDialog
  [plan]="plan"
  [type]="type"
  [rule]="rule"
  [propertySet]="propertySet"
  (closed)="updatedRule($event)"
>
</ws-edit-plan-condition-dialog>
