<vs-select
  vsInput
  class="min-w-[200px]"
  [value]="value"
  [config]="{ multiple: true, placeholder: placeholder }"
  (valueChanges)="selectValueChanged($event)"
>
  <li *ngFor="let status of _status" vs-select-option [value]="status.value">
    <vs-badge [theme]="status.theme">
      {{ status.value | titlecase }}
    </vs-badge>
  </li>
</vs-select>
