<div
  id="toast-default"
  class="flex items-center p-4 w-full max-w-xs text-gray-500 bg-base rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 absolute top-5 right-5 ml-auto"
  role="alert"
>
  <ngTemplate [ngSwitch]="type">
    <ng-container *ngSwitchCase="'error'">
      <div
        class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-600 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
      <div
        class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-600 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200"
      >
        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
    </ng-container>
  </ngTemplate>
  <div class="ml-3 text-sm font-normal">{{ message }}</div>
  <button
    type="button"
    class="ml-auto -mx-1.5 -my-1.5 bg-base text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex h-8 w-8 dark:text-gray-500 dark:hover:text-base dark:bg-gray-800 dark:hover:bg-gray-700"
    data-dismiss-target="#toast-default"
    aria-label="Close"
  >
    <span class="sr-only">Close</span>
    <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
        clip-rule="evenodd"
      ></path>
    </svg>
  </button>
</div>
