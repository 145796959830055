<vs-dialog #deleteDialog [config]="dialogConfig ?? { dialogContainerClass: 'max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,
      
      icon: 'trash-01',
      iconTheme: 'primary',

      title: 'Delete ' + plan?.title || 'plan',
      subtitle: 'Are you sure you want to delete <i>' + plan?.title + '</i>? This action can not be undone.'
    }"
  ></vs-dialog-header>

  <div *ngIf="policy?.status !== draftStatus" class="flex gap-2 text-sm text-danger-500 mb-4">
    <vs-icon class="self-center" name="alert-circle" size="md"></vs-icon>
    <span class="self-center"> Saving will create a new policy version, the current policy will not be modified </span>
  </div>

  <!-- Buttons -->
  <div class="footer">
    <div class="buttons flex space-x-4">
      <button class="flex-grow" vs-button size="md" appearance="outline" [disabled]="saving" (click)="cancel()">
        Cancel
      </button>
      <button class="flex-grow" vs-button size="md" theme="danger" [disabled]="saving" (click)="deletePlan()">
        Delete
      </button>
    </div>
  </div>
</vs-dialog>
