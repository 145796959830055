import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContentCardModule } from '@vsolv/ui-kit/card';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { AppScaffoldModule } from '@vsolv/vectors-ui/app-scaffold';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { DropdownModule } from '@vsolv/vectors-ui/dropdown';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { ProgressBarModule } from '@vsolv/vectors-ui/progress-bar';
import { RichTextEditorModule } from '@vsolv/vectors-ui/rich-text-editor';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { SlideOutModule } from '@vsolv/vectors-ui/slide-out';
import { StepperModule } from '@vsolv/vectors-ui/stepper';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { ToolbarModule } from '@vsolv/vectors-ui/toolbar';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import { CustomerWebModule } from '@wsphere/customers/web';
import { SftpWebModule } from '@wsphere/sftp/web';
import { StaffWebModule } from '@wsphere/staff/web';
import {
  DistributorDetailsFormComponent,
  DistributorOnboardCompleteComponent,
  DistributorPickerComponent,
  DistributorTableComponent,
} from './components';
import {
  ArchiveDistributorDialog,
  BreakDistributorOnboardingLinkDialog,
  CreateDistributorDialog,
  CreateDistributorOnboardingLinkDialog,
  DeactivateDistributorDialog,
  InviteMemberDialog,
  ReactivateDistributorDialog,
} from './dialogs';
import { DistributorGuard } from './guards';
import {
  DistributorOverviewPage,
  DistributorPage,
  DistributorSFTPPage,
  DistributorSettingsPage,
  DistributorTeamPage,
  DistributorsPage,
  ExpiredDistributorLinkPage,
  OnboardDistributorPage,
} from './pages';
import { DistributorResolver, DistributorSettingsResolver } from './resolvers';
import { DistributorService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ContentCardModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UtilsModule,
    StaffWebModule,
    AppPageModule,
    BadgeModule,
    IconsModule,
    ButtonModule,
    ThemingModule,
    TableModule,
    DialogModule,
    DividerModule,
    AppNavModule,
    CardModule,
    BreadcrumbsModule,
    InputModule,
    FormsModule,
    SelectModule,
    RichTextEditorModule,
    MenuModule,
    ProgressBarModule,
    StepperModule,
    TooltipModule,
    AlertModule,
    IconsModule,
    TabsModule,
    CustomerWebModule,
    DropdownModule,
    BadgeModule,
    OverlayModule,
    AppScaffoldModule,
    ToolbarModule,
    SlideOutModule,
    UserAvatarModule,
    ClipboardModule,
    SftpWebModule,
  ],
  providers: [DistributorService, DistributorResolver, DistributorGuard, DistributorSettingsResolver],
  declarations: [
    // Pages
    DistributorsPage,
    DistributorPage,
    DistributorOverviewPage,
    DistributorTeamPage,
    DistributorSFTPPage,
    OnboardDistributorPage,
    ExpiredDistributorLinkPage,
    DistributorSettingsPage,

    // Components
    DistributorPickerComponent,
    DistributorTableComponent,
    DistributorDetailsFormComponent,
    DistributorOnboardCompleteComponent,

    // Dialogs
    InviteMemberDialog,
    CreateDistributorDialog,
    ArchiveDistributorDialog,
    CreateDistributorOnboardingLinkDialog,
    DeactivateDistributorDialog,
    ReactivateDistributorDialog,
    BreakDistributorOnboardingLinkDialog,
    DeactivateDistributorDialog,
    ReactivateDistributorDialog,
  ],
  exports: [
    // Pages
    DistributorsPage,
    DistributorPage,
    DistributorOverviewPage,
    DistributorTeamPage,
    DistributorSFTPPage,
    OnboardDistributorPage,
    ExpiredDistributorLinkPage,
    DistributorSettingsPage,

    // Components
    DistributorPickerComponent,
    DistributorTableComponent,
    DistributorDetailsFormComponent,
    DistributorOnboardCompleteComponent,

    // Dialogs
    InviteMemberDialog,
    CreateDistributorDialog,
    ArchiveDistributorDialog,
    DeactivateDistributorDialog,
    ReactivateDistributorDialog,
  ],
})
export class DistributorsWebModule {}
