<div class="w-full empty:hidden">
  <ng-content select="[slot='top']"></ng-content>
</div>
<div class="flex w-full h-full overflow-y-auto">
  <div class="h-full empty:hidden">
    <ng-content select="[slot='left']"></ng-content>
  </div>
  <div class="flex-1 flex flex-col w-full h-full overflow-y-auto">
    <div class="w-full empty:hidden">
      <ng-content select="[slot='before-content']"></ng-content>
    </div>
    <div cdkScrollable class="flex-1 overflow-y-auto overscroll-contain scrollbar-thin bg-base-light">
      <ng-content></ng-content>
    </div>
    <div class="w-full empty:hidden">
      <ng-content select="[slot='after-content']"></ng-content>
    </div>
  </div>
  <div class="h-full empty:hidden">
    <ng-content select="[slot='right']"></ng-content>
  </div>
</div>
<div class="w-full empty:hidden">
  <ng-content select="[slot='bottom']"></ng-content>
</div>
