<vs-dialog>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,

      icon: 'bank',
      iconTheme: 'primary',
      
      title: 'Update billing information',
      subtitle: 'Update the billing information to be used for this payment.'
    }"
  ></vs-dialog-header>

  <div *ngIf="_payment" class="px-2 pt-4 pb-2 max-h-[80vh] overflow-auto">
    <vs-select-or-create-payment-method
      #input
      [formControl]="control"
      [address]="_payment.shippingAddress || _payment.user?.address"
      [userId]="_payment.userId"
    ></vs-select-or-create-payment-method>

    <div *ngIf="input._state === 'select'" class="flex items-center justify-end gap-3 mt-8">
      <button vs-button appearance="outline" (click)="close()">Back</button>
      <button
        vs-button
        theme="primary"
        [disabled]="control.invalid || saving || control.value === _payment.paymentMethodId"
        (click)="save()"
      >
        Update billing info
      </button>
    </div>
  </div>
</vs-dialog>
