<vs-dialog #copyCoverageDialog [config]="dialogConfig ?? { dialogContainerClass: 'max-w-[525px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple', 
      hideCloseButton: false, 
      
      icon: 'copy-04', 
      iconTheme: 'primary', 
      
      title: 'Copy Coverage',
      subtitle: 'Edit the details for the coverage you are copying'
    }"
  ></vs-dialog-header>

  <div *ngIf="page === 1">
    <form class="flex flex-col gap-4" [formGroup]="coverageDetailsForm">
      <vs-form-field [config]="{ label: { text: 'Title', for: 'text-input' } }">
        <input vsInput id="text-input" type="text" formControlName="title" />
      </vs-form-field>

      <vs-form-field [config]="{ label: { text: 'Description', for: 'text-input' } }">
        <input vsInput id="description-input" type="text" formControlName="description" />
      </vs-form-field>

      <vs-form-field [config]="{ label: { text: 'Group', for: 'group-input' } }">
        <input vsInput id="group-input" type="text" formControlName="group" />
      </vs-form-field>

      <vs-form-field [config]="{ label: { text: 'Order', for: 'order-input' } }">
        <input vsInput id="order-input" type="number" formControlName="order" />
      </vs-form-field>
    </form>

    <div [class]="(policy?.status === draftStatus ? 'justify-end' : 'justify-between') + ' flex mt-4'">
      <p *ngIf="policy?.status !== draftStatus" class="text-danger-500 text-sm flex gap-2">
        <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
        <span class="self-center">
          Saving will create a new policy version, the current policy will not be modified
        </span>
      </p>

      <button vs-button size="md" theme="primary" (click)="page = 2">Next</button>
    </div>
  </div>

  <div *ngIf="page === 2">
    <form class="flex flex-col gap-4" [formGroup]="coverageDetailsForm">
      <vs-form-field [config]="{ label: { text: 'Inclusions', for: 'liability-limit-input' } }">
        <textarea vsInput id="inclusions-input" type="text" formControlName="inclusions"></textarea>
      </vs-form-field>

      <vs-form-field [config]="{ label: { text: 'Exclusions', for: 'liability-limit-input' } }">
        <textarea vsInput id="exclusions-input" type="text" formControlName="exclusions"></textarea>
      </vs-form-field>
    </form>

    <div [class]="(policy?.status === draftStatus ? 'justify-end' : 'justify-between') + ' flex mt-4'">
      <p *ngIf="policy?.status !== draftStatus" class="text-danger-500 text-sm flex gap-2">
        <vs-icon class="self-center" name="alert-circle" size="lg"></vs-icon>
        <span class="self-center">
          Saving will create a new policy version, the current policy will not be modified
        </span>
      </p>

      <div class="flex gap-2">
        <button vs-button size="md" appearance="outline" [disabled]="saving" (click)="page = 1">Back</button>
        <button vs-button size="md" theme="primary" [disabled]="saving" (click)="copyCoverage()">Copy</button>
      </div>
    </div>
  </div>
</vs-dialog>
