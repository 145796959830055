<form [formGroup]="form">
  <div class="flex flex-col overflow-y-auto max-h-[350px] max-w-full">
    <div class="flex justify-between mb-2">
      <vs-select vsInput formControlName="comparison">
        <li class="mb-2" *ngFor="let comparison of comparisons" vs-select-option [value]="comparison">
          {{ comparison }}
        </li>
      </vs-select>

      <div class="flex gap-2">
        <button vs-button appearance="clear" theme="primary" (click)="addCondition()">
          <vs-icon name="git-commit"></vs-icon> Condition
        </button>

        <button vs-button appearance="clear" theme="primary" (click)="addGroup()">
          <vs-icon name="git-branch-01"></vs-icon> Condition Block
        </button>

        <button
          *ngIf="!hideClose"
          vs-icon-button
          appearance="clear"
          class="mb-2 h-[40px] w-[40px]"
          (click)="removeGroup()"
        >
          <vs-icon name="x"></vs-icon>
        </button>
      </div>
    </div>

    <div class="flex flex-col" formArrayName="conditions">
      <div
        *ngFor="let condition of conditions?.controls; index as index"
        class="flex justify-between gap-2 items-stretch"
      >
        <div class="flex flex-col flex-1 w-[48px]">
          <div class="flex h-[24px] w-full">
            <div class="h-full w-[50%]"></div>
            <div
              class="h-full w-[50%] border-l border-b border-1"
              [class.rounded-bl-lg]="index === (conditions.length || 0) - 1"
            ></div>
          </div>

          <div *ngIf="index !== (conditions.length || 0) - 1" class="flex min-h-[20px] h-full w-full">
            <div class="h-full w-[50%]"></div>
            <div class="min-h-[24px] h-full w-[50%] border-l border-1"></div>
          </div>
        </div>

        <ng-container *vsLet="getFormCondition(condition) as condition">
          <ws-condition-builder
            *ngIf="condition"
            class="flex-[8] mb-2"
            [propertySet]="propertySet"
            [formControlName]="index"
            (conditionRemoved)="removeCondition(index)"
          ></ws-condition-builder>
        </ng-container>

        <ng-container *vsLet="getFormGroup(condition) as group">
          <ws-condition-group-builder
            *ngIf="group"
            class="flex-[8] mb-2"
            [propertySet]="propertySet"
            [formControlName]="index"
            (conditionRemoved)="removeCondition(index)"
          ></ws-condition-group-builder>
        </ng-container>
      </div>
    </div>
  </div>
</form>
