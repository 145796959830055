<ng-container *ngIf="config">
  <a
    #link="routerLinkActive"
    (click)="click()"
    (mousedown)="$event.preventDefault()"
    (mouseenter)="linkHover = true; showIcon = true"
    (mouseleave)="linkHover = false; showIcon = false"
    [routerLink]="config.routerLink"
    [routerLinkActiveOptions]="{ exact: config.exact ?? true }"
    class="group h-10 rounded-md cursor-pointer flex items-center gap-3 px-3 w-full hover:bg-gray-50 hover:text-gray-900 active:bg-transparent hover:active:bg-transparent ring-gray-100 active:ring-4 focus:ring-4 focus:outline-none"
    routerLinkActive="bg-gray-50 text-gray-900 hover:!bg-gray-100 focus:hover:!bg-gray-50 active:!bg-transparent focus:active:!bg-transparent"
  >
    <vs-icon *ngIf="config.icon" [name]="config.icon" size="24" class="text-gray"></vs-icon>
    <span class="truncate overflow-ellipsis">{{ config.label }}</span>
    <vs-badge
      *ngIf="config.badge"
      [theme]="link.isActive ? config.badge.activeTheme ?? config.badge.theme : config.badge.theme"
      [config]="config.badge"
    >
      {{ config.badge.text }}
    </vs-badge>

    <span *ngIf="showIcon || config.showDefaultIcon" class="flex ml-auto mr-[2px]">
      <ng-content></ng-content>
    </span>

    <ng-container *ngIf="!config.hideDetailArrow">
      <vs-icon
        *ngIf="!config.children?.length && linkHover"
        name="chevron-right"
        size="20"
        class="text-gray-400 ml-auto mr-[2px] invisible group-hover:visible group-active:text-gray"
      ></vs-icon>
    </ng-container>

    <vs-icon
      *ngIf="config.children?.length"
      name="chevron-down"
      size="20"
      class="text-gray ml-auto mr-[2px] transition-transform"
      [class.rotate-180]="open"
    ></vs-icon>
  </a>

  <div [@heightExpansion]="open ? 'expanded' : 'collapsed'" class="flex flex-col gap-2 pt-2 empty:hidden">
    <div *ngFor="let item of config.children; let first = first" class="flex w-full relative">
      <div
        class="ml-[calc(theme(space.6)-1px)] w-[calc(theme(space.3)+1px)] -mt-10 h-16 border-l-2 border-b-2 border-gray-200 rounded-bl"
        [class.mt-0]="first"
        [class.!h-6]="first"
      ></div>

      <vs-sidebar-nav-item [config]="item"></vs-sidebar-nav-item>
    </div>
  </div>
</ng-container>
