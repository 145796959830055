/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { CreateCustomerOptions, FundingSource } from '@vsolv/dwolla/domain';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'vs-claim-payment-method-picker',
  templateUrl: './payment-method-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PaymentMethodPickerComponent,
    },
  ],
})
export class PaymentMethodPickerComponent implements ControlValueAccessor, OnDestroy {
  protected loading = false;

  protected readonly _destinations$ = new BehaviorSubject<FundingSource[] | null>(null);
  @Input() set destinations(destinations: FundingSource[] | null) {
    this.loading = false;
    this._destinations$.next(destinations);
  }
  get destinations() {
    return this._destinations$.value;
  }

  @Input() canAddPaymentMethod: boolean | null = null;

  protected maxNumberOfDestinations = 6; // Number of funding sources allowed for each customer in dwolla

  @Input() customer: CreateCustomerOptions | null = null;

  @Input() customerId: string | null = null;

  @Input() placeholder = 'Select a destination...';
  @Input() required = false;
  @Input() disabled = false;

  touched = false;

  @Output() refresh = new EventEmitter<void>();
  @Output() openChanged = new EventEmitter<boolean>();
  @Output() valueChanges = new EventEmitter<FundingSource | string | null | undefined>();

  @Output() destination = new EventEmitter<{ destination: string }>();

  overlayOpen = false;

  pending = 'PENDING';

  @PropertyListener('value') value$ = new BehaviorSubject<FundingSource | string | null>(null);
  @Input() value: FundingSource | string | null = null;

  onChange = (_value: FundingSource | string | null | undefined) => {};
  onTouched = () => {};

  selectValueChanged(value: FundingSource | string | null) {
    this.markAsTouched();
    this.value = value;
    this.onChange(this.value);
    this.valueChanges.next(this.value);
    this.close();
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  select(value: FundingSource | string) {
    this.selectValueChanged(value);
  }

  remove(value: FundingSource | string) {
    if (this.value) {
      if (typeof value === 'string' || (typeof value !== 'string' && (this.value as FundingSource).id === value?.id)) {
        this.selectValueChanged(null);
      }
    }
  }

  writeValue(value: FundingSource | null): void {
    this.value = value;
  }

  registerOnChange(onChange: (_value: FundingSource | string | null | undefined) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  protected async emitSavedDestination(payment: { destination: string }) {
    this.loading = true;
    this.overlayOpen = true;
    this.refresh.emit();
    this.destination.emit(payment);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  ngOnDestroy(): void {
    this.value$.complete();
  }
}
