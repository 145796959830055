import { Staff } from '../lib/staff';

export type PolicyPermissionId = (typeof Permissions)[number]['id'];
export const Permissions = <const>[
  {
    id: 'pol_View',
    display: {
      title: 'View Policy Details',
      description: 'View policies table, details documents, plans, and email templates of an individual policy',
    },
  },
  {
    id: 'pol_Create',
    display: {
      title: 'Create New Policy',
      description: 'Create a new policy in a draft state',
    },
  },
  {
    id: 'pol_Edit',
    display: {
      title: 'Edit Policy Details',
      description: 'Edit the details of a policy (while policy is in draft)',
    },
  },
  {
    id: 'pol_ManageStatus',
    display: {
      title: 'Archive/Publish/Delete Policy',
      description: 'Edit policy status (archive, publish, or delete)',
    },
  },
  {
    id: 'pol_CreatePolicyLink',
    display: {
      title: 'Create Policy Link',
      description: 'Create a sharing link to a policy',
    },
  },
  {
    id: 'pol_CreatePlanLink',
    display: {
      title: 'Create Plan Link',
      description: 'Create a sharing link to a plan',
    },
  },
  {
    id: 'pol_CreatePlan',
    display: {
      title: 'Create New Plan',
      description: 'Create a new plan',
    },
  },
  {
    id: 'pol_EditPlan',
    display: {
      title: 'Edit Plan',
      description: 'Edit and fill out the values of plan tables',
    },
  },
  {
    id: 'pol_EditVisibility',
    display: {
      title: 'Edit Plan Visibility',
      description: 'Edit the details of visibility settings',
    },
  },
  {
    id: 'pol_DeletePlan',
    display: {
      title: 'Delete Plan',
      description: 'Delete the plan from policy',
    },
  },
  {
    id: 'pol_FeeSetup',
    display: {
      title: 'Configure Fees',
      description: 'can view and set fees on policy plans',
    },
  },
  {
    id: 'pol_ViewLifecycle',
    display: {
      title: 'View Lifecycle',
      description: 'Ability to view the policy claim lifecycle tab.',
    },
  },
  {
    id: 'pol_EditLifecycle',
    display: {
      title: 'Delete Plan',
      description: "Update a policy's claim lifecycle steps",
    },
  },
];
Permissions.forEach(p => new Staff.Security.Permission(p));
