import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Policy } from '@wsphere/warranties/domain';
import { map, ReplaySubject } from 'rxjs';

@Component({
  selector: 'ws-policy-details-card',
  templateUrl: './policy-details-card.component.html',
})
export class PolicyDetailsCardComponent {
  @Input() policy!: Policy.Model;
  @PropertyListener('policy') protected readonly policy$ = new ReplaySubject<Policy.Model>(1).pipe(
    map(policy => ({
      ...policy,
      friendlyTitle: policy.friendlyTitle && policy.friendlyTitle.trim().length ? policy.friendlyTitle : undefined,
    }))
  );

  @Input() selected = false;
  @Output() clicked = new EventEmitter();

  hovering = false;

  @Input() staffView = false;

  selectPolicy() {
    this.clicked.emit(this.policy);
  }
}
