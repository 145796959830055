<ng-container *ngIf="!minify; else minified">
  <div class="flex items-center gap-4 py-0.5">
    <div *ngIf="paymentMethodSvg as svg" [innerHTML]="svg | safe : 'html'"></div>

    <div *ngIf="paymentMethod && paymentMethod.creditCard" class="text-start">
      <div class="text-gray-900 text-sm">
        {{ paymentMethod.creditCard.cardType }} (••••{{ paymentMethod.creditCard.cardNumber | match : '[\\d]{4}$' }})
      </div>
      <div class="text-gray-500 text-xs">Exp. {{ paymentMethod.creditCard.expirationDate }}</div>
    </div>

    <div *ngIf="!paymentMethod" class="italic text-gray-900 text-sm">No payment method selected</div>
  </div>
</ng-container>
<ng-template #minified>
  <ng-container *ngIf="paymentMethod && paymentMethod.creditCard">
    <div class="flex items-center gap-1">
      <i *ngIf="paymentMethodSvg as svg" [innerHTML]="svg | safe : 'html'"></i>
      <vs-icon name="minus"></vs-icon>
      {{ paymentMethod.creditCard.cardNumber | match : '[\\d]{4}$' }}
    </div>
  </ng-container>
</ng-template>
