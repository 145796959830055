import { Component, HostBinding, Input } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';

export interface AppPageConfig {
  icon?: IconName;
  title: string;
  description?: string;

  isSubPage?: boolean;
  showDivider?: boolean;
}

@Component({
  selector: 'vs-app-page',
  templateUrl: './app-page.component.html',
})
export class AppPageComponent {
  _baseStyles = 'block w-full min-h-full';
  @HostBinding('class') styles = `${this._baseStyles} p-4 sm:p-8`;

  @Input() config?: AppPageConfig;
}
