import { Platform } from '@angular/cdk/platform';
import { Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { FileType } from '../document-preview';

@Component({
  selector: 'ws-claim-document-viewer',
  templateUrl: './claim-document-viewer.component.html',
})
export class ClaimDocumentViewerComponent {
  constructor(public platform: Platform) {}

  @PropertyListener('document') document$ = new BehaviorSubject<StorageItem.Model | null>(null);
  @Input() document: StorageItem.Model | null = null;

  @Input() scrollable = false;

  isSafari = this.platform.SAFARI;

  protected downloadUrl$ = this.document$.pipe(
    map(item => {
      if (!item?.downloadUrl) return null;

      const url = new URL(item.downloadUrl);
      url.searchParams.set('embedded', 'true');
      url.hash = '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0';

      return url.toString();
    })
  );

  protected filename$ = this.document$.pipe(
    map(item => (item ? StorageItem.getValidFilename(item.title, StorageItem.getFileExtension(item.externalId)) : ''))
  );

  fileType$: Observable<FileType> = this.document$.pipe(
    map(document => {
      if (!document?.extension) return null;

      if (document.extension.toLowerCase() === '.pdf') return 'pdf';

      const imageTypes = ['.gif', '.jpg', '.jpeg', '.jfif', '.pjpeg', '.pjp', '.png', '.svg'];
      if (imageTypes.includes(document.extension.toLowerCase())) return 'image';

      return null;
    })
  );
}
