import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { PermissionId, Staff } from '@wsphere/staff/domain';
import { SecurityService, StaffService } from '../services';

export interface RoutePermissionFallback {
  permissionId: PermissionId;
  route: string;
}

export const STAFF_PERMISSION_ROUTE_DATA = 'permission';
export const STAFF_PERMISSION_FALLBACK_ROUTE_DATA = 'permissionFallback';
// export type StaffRoleRouteData = Staff.RoleEnum[];

@Injectable()
export class StaffRoleGuard implements CanActivate {
  constructor(private staffSvc: StaffService, private securitySvc: SecurityService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    try {
      const staff = this.findStaff(route) || (await this.staffSvc.retrieveSelf());
      if (!staff || staff.status != Staff.Status.ACTIVE) return false;

      const permissionId = route.data[STAFF_PERMISSION_ROUTE_DATA];
      if (!permissionId) throw new Error('A permission id needs to be provided to the route data!');

      const globalDistributors = this.securitySvc.globalDistributors$.value?.map(dist => dist.permissionKey) || null;

      const fallBack: RoutePermissionFallback[] | null = route.data[STAFF_PERMISSION_FALLBACK_ROUTE_DATA];

      //grab the stored permission keys of the global distributor picker in case guard activates before the picker loads the saved value
      let storedKeys: string[] | null = sessionStorage.getItem('globalDistributors')
        ? JSON.parse(sessionStorage.getItem('globalDistributors') as string)
        : null;
      if (storedKeys && !storedKeys.length) storedKeys = null;

      const hasAccess = await this.securitySvc.hasAccess(permissionId, globalDistributors || storedKeys);

      if (hasAccess) return true;
      else {
        if (!fallBack || !fallBack.length) return this.router.navigate(['user-settings', 'my-profile']);

        for (let index = 0; index < fallBack.length; index++) {
          const element = fallBack[index];
          const hasAccess = await this.securitySvc.hasAccess(element.permissionId, globalDistributors || storedKeys);

          if (hasAccess) return this.router.createUrlTree([element.route]);
        }
      }
    } catch (err) {
      console.error(err);
      return false;
    }
    return this.router.navigate(['user-settings', 'my-profile']);
  }

  private findStaff(route: ActivatedRouteSnapshot): Staff.Model | null {
    return route.data['staff'] || (route.parent ? this.findStaff(route.parent) : null);
  }
}
