import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { StackGroupComponent } from './components/stack-group/stack-group.component';

@NgModule({
  imports: [CommonModule, UtilsModule],
  exports: [StackGroupComponent],
  declarations: [StackGroupComponent],
})
export class StackGroupModule {}
