/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { BehaviorSubject, ReplaySubject, combineLatest, debounceTime, map, tap } from 'rxjs';
import { IconName, IconNames } from '../../icon-name';

@Component({
  selector: 'vs-icon-picker',
  templateUrl: './icon-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: IconPickerComponent,
    },
  ],
})
export class IconPickerComponent implements ControlValueAccessor, OnDestroy {
  @PropertyListener('value') value$ = new ReplaySubject<string | null>();
  @Input() value: string | null = null;

  @Input() disabled = false;

  @Output() valueChanges = new EventEmitter<string | null>();

  page = 1;
  pageSize = 50;
  totalIcons = 0;
  totalPages = 1;
  lastSearch = '';

  icons: IconName[] = [...IconNames];

  touched = false;

  search$ = new BehaviorSubject('');
  refresh$ = new BehaviorSubject(null);

  filteredIcons$ = combineLatest([this.search$, this.refresh$]).pipe(
    debounceTime(300),
    tap(([search]) => {
      if (search && search !== this.lastSearch) {
        this.lastSearch = search;
        this.page = 1;
      }
    }),
    map(([search]) => this.icons.filter(name => name.toLowerCase().includes(search.toLowerCase()))),
    map(icons => {
      this.totalIcons = icons.length;
      this.totalPages = Math.max(Math.ceil(this.totalIcons / this.pageSize), 1);
      return icons.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    })
  );

  onChange = (_value: string | null) => {};
  onTouched = () => {};

  selectValueChanged(value: string | null) {
    this.markAsTouched();
    this.value === value ? (this.value = null) : (this.value = value);
    this.onChange(this.value);
    this.valueChanges.next(this.value);
  }

  writeValue(value: string | null): void {
    if (value) this.value = value;
    else this.value = null;
  }

  registerOnChange(onChange: (_value: string | null) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  nextPage() {
    if (this.page < this.totalPages) this.page += 1;
    this.refresh$.next(null);
  }

  prevPage() {
    if (this.page > 1) this.page -= 1;
    this.refresh$.next(null);
  }

  getInputClasses() {
    const clear = `focus:outline-none read-only-within:bg-transparent disabled-within:bg-transparent`;
    const base = 'transition relative bg-base border hover:border-primary-300 ring-primary-100';
    const placeholder = 'placeholder:-400';
    const autofill = 'autofill:shadow-fill-primary-50';

    const box = `h-10 px-3 w-full rounded-tl-xl`;
    const text = `text-md text-gray-900 truncate`;

    return `${box} ${text} ${clear} ${base} ${placeholder} ${autofill} focus-within:ring-4 focus-within:border-primary-300`;
  }

  ngOnDestroy(): void {
    this.value$.complete();
  }
}
