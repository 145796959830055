import { Component, Input, Renderer2 } from '@angular/core';
import { Warranty } from '@wsphere/warranties/domain';
@Component({
  selector: 'ws-warranty-documents-card',
  templateUrl: './documents-card.component.html',
})
export class WarrantyDocumentsCardComponent {
  @Input()
  warranty?: Warranty.Model;

  constructor(private renderer: Renderer2) {}

  downloadCS() {
    const url = this.warranty?.policy?.coverageSummary?.downloadUrl;
    if (!url) return;

    this.startDownload(url);
  }

  downloadTC() {
    const url = this.warranty?.policy?.termsAndConditions?.downloadUrl;
    if (!url) return;

    this.startDownload(url);
  }

  startDownload(url: string | undefined) {
    const link = this.renderer.createElement('a');
    link.setAttribute('type', 'hidden');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url);
    link.setAttribute('download', `Download Coverage sheet`);
    link.click();
    link.remove();
  }
}
