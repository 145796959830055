/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { StepperStepConfig } from '@vsolv/vectors-ui/stepper';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { combineLatest, map, switchMap } from 'rxjs';
import { ClaimService } from '../../services';

enum pages {
  DETAILS = 1,
  CURE = 2,
  ADJUDICATE = 3,
  RESOLVE = 4,
}

@Component({
  selector: 'ws-claim-item-dialog',
  templateUrl: './claim-item.dialog.html',
})
export class ClaimItemDialog {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private claimSvc: ClaimService,
    private securitySvc: SecurityService
  ) {
    router.events.subscribe(() => this.setPage());
  }

  @ViewChild('detailsDialog') detailsDialog?: DialogComponent;

  readonly item$ = this.route.data.pipe(map(data => data['item'] as Claim.ClaimItem.Model));
  readonly claim$ = this.claimSvc.getClaim();

  draft = Claim.ClaimItem.Status.DRAFT;

  readonly isCustomerPortal$ = this.route.data.pipe(map(data => data['isCustomerPortal'] as boolean));

  canAdjudicateClaimItem$ = combineLatest([this.claim$, this.isCustomerPortal$]).pipe(
    switchMap(async ([claim, isCustomerPortal]) => {
      if (isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_AdjudicateClaimItem', permissionKey);
    })
  );

  steps$ = combineLatest([this.item$, this.canAdjudicateClaimItem$, this.isCustomerPortal$]).pipe(
    map(([item, canAdjudicateItem, isCustomerPortal]) => {
      const steps: StepperStepConfig[] = [];

      steps.push({ title: 'Details', subtitle: "Review the item's details", allowJumpToStep: true, routerLink: '' });
      steps.push({ title: 'Cure', subtitle: "Review the item's cure", allowJumpToStep: true, routerLink: 'cure' });

      if (!isCustomerPortal) {
        steps.push({
          title: 'Adjudicate',
          subtitle: 'Approve or reject this item',
          allowJumpToStep: canAdjudicateItem ? !!item?.cure?.title : false,
          routerLink: canAdjudicateItem
            ? !item?.cure?.title
              ? undefined
              : item?.adjudicationReason
              ? 'adjudicate'
              : 'manage-adjudication'
            : undefined,
        });
      }

      steps.push({
        title: 'Resolve',
        subtitle: 'Confirm the issue was resolved',
        allowJumpToStep: !!item?.adjudicationReason && item?.status !== Claim.ClaimItem.Status.DRAFT,
        routerLink: !item?.adjudicationReason || item?.status === Claim.ClaimItem.Status.DRAFT ? undefined : 'resolve',
      });

      return steps;
    })
  );

  page = 1;
  pages = pages;

  menuOpen = false;

  setPage() {
    switch (this.route.snapshot.children?.[0].routeConfig?.path) {
      case 'cure':
        this.page = pages.CURE;
        break;
      case 'adjudicate':
        this.page = pages.ADJUDICATE;
        break;
      case 'resolve':
        this.page = pages.RESOLVE;
        break;
      default:
        this.page = pages.DETAILS;
    }
  }

  checkIfIncluded(claim: Claim.Model, item: Claim.ClaimItem.Model) {
    if (!claim || !item?.coverageId) return false;

    return Claim.ClaimItem.included(claim, item.coverageId);
  }

  switchItems(itemId: string) {
    this.menuOpen = false;

    let page = '';
    switch (this.page) {
      case pages.CURE:
        page = 'cure';
        break;
      case pages.ADJUDICATE:
        page = 'adjudicate';
        break;
      case pages.RESOLVE:
        page = 'resolve';
        break;
    }

    this.navigateTo('../' + itemId + (page ? '/' + page : ''));
  }

  navigateTo(path?: string) {
    this.router.navigate([path ?? `..`], {
      relativeTo: this.route,
      ...(!path ? { queryParams: { refresh: true } } : {}),
    });
  }
}
