<vs-app-page
  *vsLet="(table?.paginationConfig$ | async)?.totalItems as totalDistributors"
  [config]="{ 
    title: 'Distributors', 
    icon: 'package',
    showDivider: true,
    description: 'This is an overview of all distributors configured for your organization.'
  }"
>
  <vs-breadcrumbs slot="breadcrumbs" [config]="{ items: [{ label: 'Distributors' }] }"></vs-breadcrumbs>

  <vs-badge slot="title-end">
    {{ totalDistributors }} {{ totalDistributors === 1 ? 'Distributor' : 'Distributors' }}
  </vs-badge>

  <!-- Buttons -->
  <div slot="header-end" class="flex justify-between gap-2 w-full sm:max-w-min flex-row">
    <ng-template #newDistributor>
      <button
        *ngIf="canCreateDistributor$ | async"
        vs-button
        theme="primary"
        class="flex-grow"
        (click)="openCreateDistributorDialog()"
      >
        <vs-icon name="package-plus"></vs-icon>
        New Distributor
      </button>
    </ng-template>
    <ng-container
      *ngIf="(totalDistributors || 0) > 0"
      slot="header-end"
      [ngTemplateOutlet]="newDistributor"
    ></ng-container>

    <button
      *ngIf="canOnboardDistributor$ | async"
      vs-icon-button
      class="px-3 py-2"
      appearance="outline"
      vsTooltip="Generate Distributor Onboarding Link"
      (click)="createLinkDialog.openDialog()"
    >
      <vs-icon name="link-01"></vs-icon>
    </button>
  </div>

  <ws-distributor-table #table class="mt-4">
    <ng-container slot="button" [ngTemplateOutlet]="newDistributor"></ng-container>
  </ws-distributor-table>
</vs-app-page>

<!-- Dialogs -->
<ws-create-distributor-dialog #createDistributorDialog (closed)="navigateTo($event)"> </ws-create-distributor-dialog>
<ws-create-distributor-onboarding-link-dialog #createLinkDialog> </ws-create-distributor-onboarding-link-dialog>
