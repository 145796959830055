/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Staff } from '@wsphere/staff/domain';
import { catchError, firstValueFrom, of } from 'rxjs';

@Injectable()
export class RoleAssignmentsService {
  constructor(private http: HttpClient) {}

  async inviteStaffAndGrantRoles(dto: Staff.Security.InviteStaffAndGrantRolesRequest) {
    return await firstValueFrom(this.http.post<Staff.Model>(`/api/staff/roles/invite`, dto));
  }

  async createRoleAssignment(dto: Staff.Security.CreateRoleAssignmentRequest) {
    return await firstValueFrom(this.http.post<Staff.Security.CreateRoleAssignmentResponse>(`/api/staff/roles`, dto));
  }

  async removeRoleAssignment(dto: Staff.Security.RemoveRoleAssignmentRequest) {
    return await firstValueFrom(
      this.http.delete<Staff.Security.RemoveRoleAssignmentResponse>(`/api/staff/roles`, { params: { ...dto } })
    );
  }

  async getStaffRoles(staffId?: string) {
    return await firstValueFrom(
      this.http
        .get<Staff.Security.RoleAssignment[]>(`/api/staff/roles/${staffId ? staffId : 'self'}`)
        .pipe(catchError(() => of([] as Staff.Security.RoleAssignment[])))
    );
  }
}
