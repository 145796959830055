import { Component, HostBinding, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavItem } from '../nav-item';

export interface SidebarNavConfig {
  logo?: string;
  logoRouterLink?: string;
  items?: NavItem[];
}

@Component({
  selector: 'vs-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
})
export class SidebarNavComponent {
  constructor(private route: ActivatedRoute) {}
  @HostBinding('class') styles = 'flex flex-col gap-6 w-80 h-full pt-8 pb-4 px-4';

  @Input() config: SidebarNavConfig = {};
  openedIndex: number | null = null;
}
