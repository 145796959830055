import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppScaffoldComponent } from './components';

export * from './components';

@NgModule({
  imports: [CommonModule, ScrollingModule],
  declarations: [AppScaffoldComponent],
  exports: [AppScaffoldComponent],
})
export class AppScaffoldModule {}
