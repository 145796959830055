<vs-dialog
  [config]="{
      dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]'
    }"
>
  <vs-dialog-header
    [config]="{
        mode: 'simple',
        hideCloseButton: false,
        icon: 'trash-01',
        iconTheme: 'danger',
        title: 'Delete API key',
        subtitle: 'Are you sure you want to remove this key? Once it is removed from your database you will no longer be able to use it.',
        showDivider: true
      }"
  ></vs-dialog-header>

  <div id="dialog-content" class="pt-6">
    <div class="grid grid-cols-3 gap-4 pb-4">
      <p class="col-span-1 text-sm text-gray-600 font-semibold">Key</p>
      <p class="col-span-2 text-sm text-gray-400 font-normal">{{apiKeyItem?.name | titlecase}}</p>
      <p class="col-span-1 text-sm text-gray-600 font-semibold">Last Used</p>
      <vs-badge class="col-span-2 justify-self-start" theme="info"
        >{{(apiKeyItem?.lastUsed | date) || 'Key has not been used yet'}}</vs-badge
      >
    </div>
  </div>

  <div class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pt-6">
    <button vs-button class="w-full sm:w-auto" appearance="outline" [disabled]="_submitting" (click)="closeDialog()">
      Cancel
    </button>
    <button vs-button class="w-full sm:w-auto" theme="danger" [disabled]="_submitting" (click)="confirm()">
      Delete API Key
      <vs-icon *ngIf="_submitting" name="refresh-cw-02" class="animate-spin"></vs-icon>
    </button>
  </div>
</vs-dialog>
