import type { PaginationQueryRequest, PaginationQueryResponse } from '@vsolv/dev-kit/nest';
import { Model } from '../model';

export interface ListRequest extends PaginationQueryRequest {
  search?: string;
}

export type ListResponse = PaginationQueryResponse<Model>;

export const ListDefaultResponse: ListResponse = {
  meta: { currentPage: 1, itemCount: 0, itemsPerPage: 10 },
  items: [],
};
