<vs-form-field [config]="{ label: property.name + (required ? '' : ' (optional)') + ':' }" appearance="vertical">
  <vs-input-container>
    <vs-number-input
      class="w-full"
      #input
      [property]="property"
      [formControl]="formControl"
      [disabled]="disabled"
      [required]="required"
    ></vs-number-input>
  </vs-input-container>

  <span class="text-danger" slot="hint" *ngIf="input.control.touched && input.control.hasError('required')">
    {{ property.name }} must have a value
  </span>

  <span class="text-danger" slot="hint" *ngIf="input.control.touched && input.control.hasError('min')">
    {{ property.name }} must be {{ property.config.min }} or greater
  </span>

  <span class="text-danger" slot="hint" *ngIf="input.control.touched && input.control.hasError('max')">
    {{ property.name }} must be {{ property.config.max }} or less
  </span>

  <span class="text-danger" slot="hint" *ngIf="input.control.touched && input.control.hasError('allowedValues')">
    {{ property.name }} must be one of {{ property.config.allowedValues!.join(', ') }}
  </span>
</vs-form-field>
