import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';

import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';

export interface AlertConfig {
  /** Default: info-circle */
  icon?: IconName;
  /** Default: primary */
  iconTheme?: ThemeColor;

  /** Default: EMPTY */
  title?: string;
  /** Default: EMPTY */
  text?: string;

  /** Default: true */
  autoClose?: boolean;
  /** Default: 5000 */
  closeDelay?: number;
  /** Default: false */
  hideCloseButton?: boolean;
}

export const ALERT_CONFIG_TOKEN = new InjectionToken<AlertConfig>('ALERT_CONFIG_TOKEN');

@Component({
  selector: 'vs-alert',
  templateUrl: './alert.component.html',
})
export class AlertComponent implements OnInit, OnDestroy {
  @HostBinding('class') private get _class() {
    const display = 'block bg-base overflow-hidden';
    const border = 'border border-gray-100 rounded-2xl shadow-lg';
    const size = 'min-w-[300px] max-w-md';

    return `${display} ${border} ${size}`;
  }

  @Output() readonly _delayCompleted = new EventEmitter();
  @Output() readonly _closeRequested = new EventEmitter();

  private _config: AlertConfig = {
    icon: 'info-circle',
    iconTheme: 'primary',

    autoClose: true,
    closeDelay: 5000,
    hideCloseButton: false,
  };

  set config(config: AlertConfig) {
    this._config = {
      icon: config.icon ?? 'info-circle',
      iconTheme: config.iconTheme ?? 'primary',

      title: config.title,
      text: config.text,

      autoClose: config.autoClose ?? true,
      closeDelay: config.closeDelay ?? 5000,
      hideCloseButton: config.hideCloseButton ?? false,
    };
  }
  get config() {
    return this._config;
  }

  _approximateElapsedTime = 0;

  private timeLeft: number;
  private timeStarted?: number;

  private timer?: ReturnType<typeof setTimeout>;
  private interval?: ReturnType<typeof setInterval>;

  constructor(@Inject(ALERT_CONFIG_TOKEN) config: AlertConfig) {
    this.config = config;
    this.timeLeft = this.config.closeDelay ?? 5000;
  }

  ngOnInit() {
    this.initTimer();
  }

  ngOnDestroy() {
    this.disposeTimers();
  }

  close() {
    this.disposeTimers();
    this._closeRequested.emit();
  }

  @HostListener('mouseenter')
  private _mouseenter() {
    this.disposeTimers();
    this.timeLeft -= Date.now() - (this.timeStarted ?? 0);
  }

  @HostListener('mouseleave')
  private _mouseleave() {
    this.initTimer();
  }

  private disposeTimers() {
    if (this.timer) clearTimeout(this.timer);
    if (this.interval) clearInterval(this.interval);
  }

  private initTimer() {
    if (this.config.autoClose) {
      this.timeStarted = Date.now();

      this.timer = setTimeout(() => {
        this.disposeTimers();
        this._delayCompleted.emit();
      }, this.timeLeft);

      this.interval = setInterval(() => (this._approximateElapsedTime += 10), 10);
    }
  }
}
