<div class="flex items-start gap-4">
  <div class="flex w-16 h-16 p-4 bg-gray-100 rounded-full" [vsTooltip]="value || ''">
    <vs-icon size="32" class="text-gray-700" [name]="$any(value || 'circle')"></vs-icon>
  </div>

  <div class="flex flex-col min-w-[244px] lg:min-w-[360px]">
    <div class="flex justify-between">
      <input
        #input
        vsInput
        type="text"
        placeholder="Search icons"
        [class]="getInputClasses()"
        (input)="search$.next(input.value)"
      />

      <div class="flex gap-1 h-10 px-2 self-center bg-base rounded-tr-xl border border-l-0">
        <div
          class="flex text-gray-600 p-2 transition-all"
          [class]="page > 1 ? 'hover:text-primary-500 cursor-pointer' : '!text-gray-100 cursor-default'"
          (click)="prevPage()"
        >
          <vs-icon size="24" name="chevron-left" class="self-center -ml-px"></vs-icon>
        </div>

        <div class="flex gap-1 self-center">
          <span class="text-sm font-medium text-gray-500"> {{ page }} </span>
          <span class="text-sm font-normal text-gray-500"> of </span>
          <span class="text-sm font-medium text-gray-500"> {{ totalPages }} </span>
        </div>

        <div
          class="flex text-gray-600 p-2 transition-all"
          [class]="page < totalPages ? 'hover:text-primary-500 cursor-pointer' : '!text-gray-100 cursor-default'"
          (click)="nextPage()"
        >
          <vs-icon size="24" name="chevron-right" class="self-center ml-px"></vs-icon>
        </div>
      </div>
    </div>

    <div class="w-full px-6 py-4 bg-base rounded-b-xl border border-t-0 overflow-hidden scrollbar-thin">
      <div class="max-h-[64px] w-full overflow-hidden">
        <div class="max-h-[64px] flex flex-wrap overflow-y-auto scrollbar-thin">
          <vs-icon
            *ngFor="let icon of filteredIcons$ | async"
            size="24"
            class="text-gray-700 rounded-full m-0.5 p-0.5 hover:p-1 hover:m-0 hover:bg-gray-50 cursor-pointer transition-all"
            [name]="icon"
            [class]="value === icon ? 'text-primary-500 !bg-primary-50' : ''"
            (click)="selectValueChanged(icon)"
          ></vs-icon>

          <span *ngIf="totalIcons === 0" class="text-sm font-normal text-gray-500">
            No icons found using that search
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
