import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgPipesModule } from 'ngx-pipes';
import { FormFieldComponent } from './form-components';

@NgModule({
  imports: [CommonModule, NgPipesModule],
  declarations: [FormFieldComponent],
  exports: [FormFieldComponent],
})
export class FormsModule {}
