import { Pipe, PipeTransform } from '@angular/core';
import { Distributor } from '@wsphere/distributors/domain';

@Pipe({
  name: 'isSelected',
})
export class IsSelectedPipe implements PipeTransform {
  transform(distributor: Distributor.PartialModel, list: Distributor.PartialModel[]): boolean {
    return list.some(dist => dist.id === distributor.id);
  }
}
