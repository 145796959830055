<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayPanelClass="pointer-events-none"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="{ x: 0, y: 0 }"
  [cdkConnectedOverlayOpen]="_open"
  [cdkConnectedOverlayBackdropClass]="config?.hideBackdrop ? 'bg-opacity-0' : 'backdrop-blur-sm'"
  (backdropClick)="!config?.disableBackdropDismiss && close()"
  (overlayKeydown)="$event.key === 'Escape' && close()"
>
  <div *ngIf="_open" class="relative w-screen h-screen flex items-center justify-center pointer-events-none">
    <div *ngIf="!config?.hideBackdrop" class="absolute w-full h-full bg-gray opacity-20 pointer-events-none"></div>

    <div
      class="flex flex-col max-h-[98vh] shadow-xl z-10 bg-base p-4 rounded-2xl overflow-hidden pointer-events-auto"
      [class]="config?.dialogContainerClass"
    >
      <ng-content select="vs-dialog-header"></ng-content>

      <div class="-m-1 p-1 overflow-y-auto scrollbar-thin" [class]="config?.contentContainerClass">
        <ng-content></ng-content>
      </div>

      <ng-content select="[slot='buttons']"></ng-content>
    </div>
  </div>
</ng-template>
