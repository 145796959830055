import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AddressModule } from '@vsolv/core/address/web';
import { PaymentMethodModule } from '@vsolv/packages/payments/web';
import { PropertiesWebModule } from '@vsolv/packages/properties/web';
import { ContentCardModule } from '@vsolv/ui-kit/card';
import { ModalModule } from '@vsolv/ui-kit/modal';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { BreadcrumbsModule } from '@vsolv/vectors-ui/breadcrumbs';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { InfoCardModule } from '@vsolv/vectors-ui/info-card';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { ProgressBarModule } from '@vsolv/vectors-ui/progress-bar';
import { RichTextEditorModule } from '@vsolv/vectors-ui/rich-text-editor';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { StackGroupModule } from '@vsolv/vectors-ui/stack-group';
import { StepperModule } from '@vsolv/vectors-ui/stepper';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import { AssetsWebModule } from '@wsphere/assets/web';
import { CustomerWebModule } from '@wsphere/customers/web';
import { DistributorsWebModule } from '@wsphere/distributors/web';
import { ClaimModule } from '../claim';
import { WarrantyCoverageModule } from '../coverage';
import { PolicyModule } from '../policy';
import {
  OrderSummaryComponent,
  ProvisionAddonComponent,
  ProvisionAddonFormComponent,
  ProvisionAssetComponent,
  ProvisionConfirmationComponent,
  ProvisionCustomerInfoComponent,
  ProvisionDistributorComponent,
  ProvisionFormStepperComponent,
  ProvisioningDistributorPickerComponent,
  ProvisionPlanComponent,
  ProvisionPlanFormComponent,
  ProvisionPolicyComponent,
  WarrantyPreviewCardComponent,
} from './components';
import { AdminProvisionWarrantyCompleteComponent } from './components/admin-provision-warranty-complete/admin-provision-warranty-complete.component';
import { ProvisioningCustomerPickerComponent } from './components/customer-picker/customer-picker.component';
import { ProvisionFaqComponent } from './components/provision-faq/provision-faq.component';
import { CheckoutCustomerExistsSignInDialog, CoveragesDialog } from './dialogs';
import {
  SessionAddonGuard,
  SessionAssetGuard,
  SessionCheckoutGuard,
  SessionGuard,
  SessionPlanGuard,
  SessionPolicyGuard,
  SessionShippingAddressGuard,
} from './guards';
import { ProvisionPage } from './pages';
import { ProvisioningSessionService } from './services';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    ContentCardModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UtilsModule,
    AppPageModule,
    BadgeModule,
    IconsModule,
    ButtonModule,
    ThemingModule,
    TableModule,
    DialogModule,
    DividerModule,
    AppNavModule,
    CardModule,
    BreadcrumbsModule,
    WarrantyCoverageModule,
    InputModule,
    FormsModule,
    ClaimModule,
    SelectModule,
    RichTextEditorModule,
    MenuModule,
    ProgressBarModule,
    TooltipModule,
    AlertModule,
    IconsModule,
    TabsModule,
    ClaimModule,
    CustomerWebModule,
    PolicyModule,
    AssetsWebModule,
    UserAvatarModule,
    DistributorsWebModule,
    PaymentMethodModule,
    AddressModule,
    OverlayModule,
    PropertiesWebModule,
    StepperModule,
    InfoCardModule,
    StackGroupModule,
  ],
  providers: [
    ProvisioningSessionService,
    SessionGuard,
    SessionPolicyGuard,
    SessionAssetGuard,
    SessionShippingAddressGuard,
    SessionPlanGuard,
    SessionAddonGuard,
    SessionCheckoutGuard,
  ],
  declarations: [
    ProvisionPlanFormComponent,
    WarrantyPreviewCardComponent,
    CoveragesDialog,
    ProvisionFormStepperComponent,
    AdminProvisionWarrantyCompleteComponent,
    ProvisionFaqComponent,
    ProvisionDistributorComponent,
    ProvisioningDistributorPickerComponent,
    ProvisioningCustomerPickerComponent,
    ProvisionPage,
    OrderSummaryComponent,
    ProvisionAddonFormComponent,
    ProvisionCustomerInfoComponent,
    ProvisionPolicyComponent,
    ProvisionAssetComponent,
    ProvisionConfirmationComponent,
    ProvisionPlanComponent,
    ProvisionAddonComponent,
    CheckoutCustomerExistsSignInDialog,
  ],
  exports: [
    WarrantyPreviewCardComponent,
    ProvisionFormStepperComponent,
    ProvisionFaqComponent,
    ProvisionAddonFormComponent,
    OrderSummaryComponent,
    ProvisionCustomerInfoComponent,
    ProvisionPolicyComponent,
    ProvisionAssetComponent,
    ProvisionConfirmationComponent,
    ProvisionPlanComponent,
    ProvisionAddonComponent,
  ],
})
export class ProvisioningWebModule {}
