/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, HostBinding, Input } from '@angular/core';
import { IconName } from '../../icon-name';

export interface IconEmptyStateConfig {
  title?: string;
  subtitle?: string;

  full?: boolean;
}

@Component({
  selector: 'vs-icon-empty-state',
  templateUrl: './icon-empty-state.component.html',
})
export class IconEmptyStateComponent {
  @HostBinding('class') get styles(): string {
    return 'w-full';
  }

  @Input() name: IconName | null = null;

  @Input() config?: IconEmptyStateConfig;
}
