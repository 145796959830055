<button
  #trigger="vsMenuTriggerFor"
  vs-button
  theme="default"
  appearance="outline"
  tooltipStyle="light"
  tooltipOpenDelay="400"
  class="h-5 w-5 !p-0 rounded-full absolute left-0 top-1 translate-x-[-50%] z-20 shadow-none border-r-transparent border-b-transparent -rotate-45"
  [vsMenuPosition]="positions"
  [vsMenuTriggerFor]="feeMenu"
  [vsTooltip]="{
    title: 'Add fee columns',
    subtitle:
      'Fee columns are used to split up the base cost of a warranty so administration, insurance, and other services are paid accurately per sale.'
  }"
>
  <vs-icon size="12" name="add-column" class="text-gray-500 h-3 w-3 rotate-45"></vs-icon>

  <ng-template #feeMenu>
    <vs-menu>
      <vs-input-container class="min-w-[400px] max-w-[400px] !border-x-0 !border-t-0 !rounded-none !shadow-none">
        <vs-icon slot="start" name="search-md"></vs-icon>
        <input #input placeholder="Select fee type" (input)="search$.next(input.value)" />
      </vs-input-container>

      <ng-container *ngIf="!selectedFeeGroup">
        <div class="flex flex-col p-2">
          <div
            *ngFor="let group of feesAndGroups$ | async"
            class="flex justify-between w-full px-2 py-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
            (click)="selectedFeeGroup = group"
          >
            <p class="text-sm font-semibold text-gray-700">{{ group.title }}</p>

            <div *vsLet="getFeesInGroup(group.title) as feesInGroup" class="flex gap-2">
              <vs-badge *ngIf="feesInGroup" theme="default"> {{ feesInGroup }} selected </vs-badge>

              <vs-icon name="chevron-right" class="self-center"></vs-icon>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedFeeGroup">
        <div class="flex flex-col p-2">
          <div
            class="flex gap-2 px-2 py-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
            (click)="selectedFeeGroup = null"
          >
            <vs-icon name="chevron-left" class="self-center"></vs-icon>
            <p class="text-sm font-semibold text-gray-700">{{ selectedFeeGroup.title }}</p>
          </div>

          <div
            *ngFor="let fee of selectedFeeGroup.fees"
            class="flex gap-2 px-2 py-3 rounded-lg hover:bg-gray-50 transition-all cursor-pointer"
            (click)="
              feesToAdd.includes(fee) ? removeFee($any(fee)) : selectFee($any(fee)); $event.stopImmediatePropagation()
            "
          >
            <input vsInput type="checkbox" class="self-center cursor-pointer" [checked]="feesToAdd.includes(fee)" />

            <p class="text-sm font-semibold text-gray-700 self-center">{{ getFeeName(fee) }}</p>
          </div>
        </div>
      </ng-container>

      <div class="flex gap-2 justify-between mt-4 px-2">
        <div class="flex gap-2 flex-wrap self-center">
          <ng-container *ngIf="feesToAdd.length < 3; else more">
            <div *ngFor="let fee of feesToAdd" class="flex gap-1 max-h-[26px] border rounded-md px-2 py-1">
              <p class="text-xs font-medium text-gray-700">{{ getFeeName(fee) }}</p>

              <button vs-button appearance="clear" class="!p-0.5 self-center max-h-[20px]" (click)="removeFee(fee)">
                <vs-icon name="x"></vs-icon>
              </button>
            </div>
          </ng-container>

          <ng-template #more>
            <div *vsLet="feesToAdd[0] as fee" class="flex gap-1 max-h-[26px] border rounded-md px-2 py-1">
              <p class="text-xs font-medium text-gray-700">{{ getFeeName(fee || '') }}</p>

              <button
                *ngIf="fee"
                vs-button
                appearance="clear"
                class="!p-0.5 self-center max-h-[20px]"
                (click)="removeFee(fee)"
              >
                <vs-icon name="x"></vs-icon>
              </button>
            </div>

            <div *vsLet="feesToAdd[1] as fee" class="flex gap-1 max-h-[26px] border rounded-md px-2 py-1">
              <p class="text-xs font-medium text-gray-700">{{ getFeeName(fee || '') }}</p>

              <button
                *ngIf="fee"
                vs-button
                appearance="clear"
                class="!p-0.5 self-center max-h-[20px]"
                (click)="removeFee(fee)"
              >
                <vs-icon name="x"></vs-icon>
              </button>
            </div>

            <div class="h-[26px] border rounded-md px-2 py-1">
              <p class="text-xs font-medium text-gray-700">+ {{ feesToAdd.length - 2 }}</p>
            </div>
          </ng-template>
        </div>

        <button vs-button theme="primary" (click)="addFees()">Add Fees</button>
      </div>
    </vs-menu>
  </ng-template>
</button>
