/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Observable, map } from 'rxjs';

import { API_BASE_URL, API_PARENT_ROUTE } from './api-interceptor.token';
import { ORIGIN_HEADER } from './origin-header.token';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private _isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?Z$/;

  private readonly baseUrl: string;
  private readonly parentRoute: string;

  constructor(@Inject(API_BASE_URL) baseUrl: string, @Inject(API_PARENT_ROUTE) parentRoute: string) {
    this.baseUrl = baseUrl.endsWith('/') ? baseUrl.substring(0, baseUrl.length - 1) : baseUrl;
    this.parentRoute = parentRoute;
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (req.url.startsWith('/api') || req.url.startsWith('/v1')) {
      const request = this.cloneRequestWithFullUrl(this.baseUrl, req);
      return next.handle(request).pipe(
        map((val: HttpEvent<unknown>) => {
          // Convert the body of responses to make strings into dates
          if (val instanceof HttpResponse) {
            const body = val.body;
            this.convert(body);
          }
          return val;
        })
      );
    } else {
      return next.handle(req);
    }
  }

  private cloneRequestWithFullUrl(
    baseUrl: string,
    request: HttpRequest<unknown>,
    originHeader: 'vectorsolv' | 'vectorsolv-platform' = 'vectorsolv'
  ) {
    return request.clone({
      url: baseUrl + request.url.replace('/api', `/v1/${this.parentRoute}`),
      headers: request.headers
        .set(ORIGIN_HEADER, originHeader) // This header is to distinguish our api calls for other multi-tenant interceptors
        .set('X-VSOLV-TZ', moment.tz.guess()), // This header is to have access to the user's timezone in the server
    });
  }

  private isIsoDateString(value: any): boolean {
    if (value === null || value === undefined) return false;
    if (typeof value === 'string') return this._isoDateFormat.test(value);
    return false;
  }

  private convert(body: any) {
    if (body === null || body === undefined) return body;
    if (typeof body !== 'object') return body;
    for (const key of Object.keys(body)) {
      const value = body[key];
      if (this.isIsoDateString(value)) body[key] = new Date(value);
      else if (typeof value === 'object') this.convert(value);
    }
  }
}

export function isVectorSolvRequest(req: HttpRequest<unknown>) {
  return req.headers.get(ORIGIN_HEADER) === 'vectorsolv';
}
