<vs-dialog
  [config]="{
    dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]'
  }"
>
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      hideCloseButton: false,

      icon: 'users-edit',
      iconTheme: 'primary',
      
      title: 'Manage member roles' + (distributor ? ' and distributors' : ''),
      subtitle: 'Manage the roles and associated distributors for ' + (teamMember?.name ?? 'this user') + '.',
      
      showDivider: true
    }"
    (closed)="memberUpdated.emit()"
  ></vs-dialog-header>

  <form [formGroup]="form">
    <div *vsLet="roles$ | async as roles" class="mt-6">
      <div class="flex justify-between">
        <p class="font-medium text-gray-700">Member Details</p>

        <button
          *ngIf="distributor"
          vs-button
          appearance="outline"
          class="text-sm font-semibold"
          (click)="removeMemberDialog.openDialog()"
        >
          <vs-icon name="trash-01"></vs-icon>
          Remove Member
        </button>
      </div>

      <div class="grid grid-cols-3 gap-4 my-4">
        <p class="col-span-1 text-sm text-gray-700">Name</p>
        <p class="col-span-2 text-sm text-gray-900 truncate">{{ teamMember?.name }}</p>

        <p class="col-span-1 text-sm text-gray-700">Last activity</p>
        <p class="col-span-2 text-sm text-gray-900 truncate">{{ teamMember?.created | date }}</p>

        <p class="col-span-1 text-sm text-gray-700">Status</p>
        <vs-badge class="col-span-2 justify-self-start">{{ teamMember?.status| titlecase }}</vs-badge>
      </div>

      <vs-divider class="my-2"></vs-divider>

      <div class="pt-4">
        <vs-form-field
          theme="primary"
          appearance="responsive"
          [config]="{ label: 'Manage team member\'s roles for '+(distributor?.name ?? 'Platform') }"
        >
          <ws-role-picker
            [rolesOutside]="true"
            [value]="(roleIds$ | async) || []"
            [permissionKey]="permissionKey"
            (roleAdded)="addRole($event)"
            (roleRemoved)="removeRole($event)"
          ></ws-role-picker>
        </vs-form-field>
      </div>

      <ws-delete-team-member-dialog
        #removeMemberDialog
        [teamMember]="teamMember"
        [distributor]="distributor"
        (memberRemoved)="closeDialog()"
      ></ws-delete-team-member-dialog>
    </div>

    <div class="flex flex-col-reverse sm:flex-row sm:justify-end items-center gap-3 pt-6">
      <button vs-button class="w-full sm:w-auto" appearance="outline" [disabled]="_submitting" (click)="closeDialog()">
        Close
      </button>
    </div>
  </form>
</vs-dialog>
<ws-full-admin-warning-dialog></ws-full-admin-warning-dialog>
