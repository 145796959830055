import { Pipe, PipeTransform } from '@angular/core';
import { Distributor } from '@wsphere/distributors/domain';

@Pipe({
  name: 'joinDistributors',
})
export class JoinDistributorsPipe implements PipeTransform {
  transform(input: Distributor.Model[], sep = ', '): string {
    return input.map(dist => dist.name).join(sep);
  }
}
