import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, Routes, UrlTree } from '@angular/router';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { firstValueFrom } from 'rxjs';
import { StorageSecurityService, StorageService } from './services';

export const STORAGE_ROUTES: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/items').then(mod => mod.ItemsPage),
    canActivate: [
      async (): Promise<boolean | UrlTree> => {
        const router = inject(Router);
        const securitySvc = inject(StorageSecurityService);

        const hasAccess = await firstValueFrom(securitySvc.canView$);

        if (!hasAccess) return router.createUrlTree([], { relativeTo: null });

        return true;
      },
    ],
  },
  {
    path: ':itemId',
    loadChildren: () => import('./pages/item').then(mod => mod.ROUTES),
    canActivate: [
      async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> => {
        const router = inject(Router);
        const storageSvc = inject(StorageService);
        const securitySvc = inject(StorageSecurityService);

        storageSvc._openedItem.next(null);

        const id = route.paramMap.get('itemId');
        if (!id) return router.createUrlTree(['/']);

        const hasAccess = await firstValueFrom(securitySvc.canView$);

        if (!hasAccess) return router.createUrlTree([], { relativeTo: null });

        const givenItem = router.getCurrentNavigation()?.extras.state as StorageItem.Model | null;
        if (givenItem?.id === id) {
          storageSvc._openedItem.next(givenItem);
          storageSvc
            .retrieve(id)
            .catch(err => {
              if (err.status === 404) router.navigate(['/404']);
              return null;
            })
            .then(item => {
              storageSvc._openedItem.next(item);
              if (!item) router.navigate([state.url.replace(new RegExp(`/${id}.*`), '')]);
            });
        } else {
          const item = await storageSvc.retrieve(id).catch(err => {
            if (err.status === 404) router.navigate(['/404']);
            return null;
          });
          if (!item) return router.createUrlTree([state.url.replace(new RegExp(`/${id}.*`), '')]);
          storageSvc._openedItem.next(item);
        }

        return true;
      },
    ],
  },
];
