<vs-dialog #dialog [config]="{ dialogContainerClass: 'max-w-[528px]' }">
  <vs-dialog-header
    [config]="{
      showDivider: true, 
      mode: 'simple', 
      
      icon: 'copy-05', 
      iconTheme: 'primary', 
      
      title: 'Pricing table quick fill', 
      subtitle: 'If you want to use an existing table to pre-fill the term\'s pricing table values, select the completed term from the dropdown then continue. Otherwise, start this term\'s pricing table from scratch.'
    }"
  ></vs-dialog-header>

  <form class="mt-4 mb-8" [formGroup]="form">
    <vs-form-field [config]="{label: 'Completed terms'}">
      <vs-input-container>
        <vs-select class="w-full" formControlName="termId">
          <li *ngFor="let term of ((completedTerms$ | async) || [])" vs-select-option class="w-full" [value]="term.id">
            {{ term.title }}
          </li>
        </vs-select>
      </vs-input-container>
    </vs-form-field>
  </form>

  <div class="flex justify-between">
    <button vs-button appearance="outline" (click)="close()">Cancel</button>

    <div class="flex gap-2">
      <button vs-button appearance="outline" (click)="submit()">Start from scratch</button>

      <button vs-button theme="primary" [disabled]="form.invalid" (click)="submitWithTermConfig()">
        Pre-fill table
      </button>
    </div>
  </div>
</vs-dialog>
