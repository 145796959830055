import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { Papa } from 'ngx-papaparse';
import { BehaviorSubject, combineLatest, tap } from 'rxjs';
import { CoverageService, PolicyService } from '../../services';

@Component({
  selector: 'ws-policy-coverages',
  templateUrl: './policy-coverages.component.html',
})
export class PolicyCoveragesComponent implements OnInit {
  constructor(
    private router: Router,
    private fileParser: Papa,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService,
    private formBuilder: FormBuilder,
    private coverageSvc: CoverageService
  ) {}

  @ViewChild('replaceExistingDialog') replaceExistingDialog?: DialogComponent;

  @PropertyListener('policy') policy$ = new BehaviorSubject<Policy.Model | null>(null);
  @Input() policy: Policy.Model | null = null;

  @PropertyListener('coverage') coverage$ = new BehaviorSubject<Coverage.Model | null>(null);
  @Input() coverage: Coverage.Model | null = null;

  @PropertyListener('editing') editing$ = new BehaviorSubject<boolean>(false);
  @Input() editing = false;

  @Input() value: Coverage.Model[] | null = null;

  @Output() closed = new EventEmitter();
  @Output() prevPage = new EventEmitter<Coverage.Model[]>();
  @Output() pageCompleted = new EventEmitter<Coverage.Model[]>();

  saving = false;
  firstPage = true;
  canReplaceExisting = false;

  draft = Policy.Status.DRAFT;

  selectedCoverageIndex: number | null = null;
  coverages: Coverage.Model[] = [];

  csvForm = this.formBuilder.group({
    file: [null as File | null],
  });

  form = this.formBuilder.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
    group: ['', [Validators.required]],
    inclusions: ['', null],
    exclusions: ['', null],
    order: [0, [Validators.required]],
  });

  protected loadedCoverages$ = new BehaviorSubject<boolean>(true);

  patchValue$ = combineLatest([this.coverage$, this.editing$]).pipe(
    tap(([coverage, editing]) => {
      if (!editing) {
        if (this.value) {
          this.coverages = this.value;
          this.form.markAsDirty();
        } else {
          this.form.reset();
          this.form.markAsPristine();
        }
      } else {
        if (!coverage) return;
        this.firstPage = true;

        this.form.patchValue({
          title: coverage.title,
          description: coverage.description,
          group: coverage.group,
          inclusions: coverage.inclusions,
          exclusions: coverage.exclusions,
          order: coverage.order,
        });
      }
    })
  );

  close() {
    this.form.reset();
    this.coverages = [];
    this.firstPage = false;
    this.canReplaceExisting = false;
    this.loadedCoverages$.next(true);

    this.closed.emit();
  }

  goBack() {
    this.prevPage.emit(this.coverages);
  }

  pageComplete() {
    this.pageCompleted.emit(this.coverages);
  }

  getCoverageTemplate() {
    const data = 'title,description,group,exclusions,inclusions';
    const encodedData = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data);
    const link = document.createElement('a');
    link.setAttribute('href', encodedData);
    link.setAttribute('download', 'Coverage_Template.csv');
    link.click();
  }

  createCoverage() {
    this.addCoverage();
    this.selectCoverage(this.coverages[this.coverages.length - 1], this.coverages.length - 1);
  }

  backRemove() {
    this.firstPage = false;
    this.selectedCoverageIndex = null;
    this.removeCoverage(this.coverages.length - 1);
  }

  addCoverage() {
    const coverageDetails = this.form.value;

    const baseCoverage = {
      order: Number(coverageDetails.order ?? 0),
      title: coverageDetails.title?.trim() || '',
      description: coverageDetails.description || '',
      modified: new Date(),
      deleted: null,
      group: coverageDetails.group?.trim() || '',
      inclusions: coverageDetails.inclusions || '',
      exclusions: coverageDetails.exclusions || '',
    };

    if (this.selectedCoverageIndex !== null && this.selectedCoverageIndex >= 0) {
      this.coverages[this.selectedCoverageIndex] = {
        ...this.coverages[this.selectedCoverageIndex],
        ...baseCoverage,
      };
    } else {
      const coverage: Coverage.Model = {
        ...baseCoverage,
        id: '',
        referenceId: '',
        created: new Date(),
        policyId: this.policy?.id || '',
      };
      this.coverages.push(coverage);
    }

    this.selectedCoverageIndex = null;
    this.firstPage = true;
    this.form.reset();
  }

  selectCoverage(coverage: Coverage.Model, index: number) {
    this.selectedCoverageIndex = index;
    this.firstPage = true;

    this.form.patchValue({
      order: coverage.order,
      title: coverage.title,
      description: coverage.description,
      group: coverage.group,
      inclusions: coverage.inclusions,
      exclusions: coverage.exclusions,
    });
  }

  copyCoverage(coverage: Coverage.Model) {
    const coverageCopy = { ...coverage, id: '', title: coverage.title + ' - copy' };
    this.coverages.push(coverageCopy);
  }

  removeCoverage(index: number) {
    this.coverages.splice(index, 1);
    this.coverages = [...this.coverages];
  }

  async save() {
    if (!this.policy) return;

    const value = this.form.value;

    const dto: Coverage.CreateCoverageRequest = {
      canCreateNewVersion: true,
      policyId: this.policy.id,
      order: Number(value.order ?? 0),
      title: value.title || '',
      description: value.description || '',
      group: value.group || '',
      exclusions: value.exclusions || '',
      inclusions: value.inclusions || '',
    };

    if (this.coverage?.id) {
      const coverage = await this.coverageSvc
        .update(this.coverage.id, { ...dto, referenceId: undefined })
        .catch(({ error }) => {
          this.toastSvc.show({
            type: 'error',
            title: 'Something went wrong',
            text: error.message,
          });

          this.close();
        });

      if (coverage?.id) {
        if (coverage.policyId === this.policy.id) {
          this.toastSvc.show({
            type: 'success',
            title: 'Updated coverage',
            text: '<strong>' + value.title + '</strong> has been successfully updated.',
          });

          this.policySvc.refreshPolicy();
          this.coverageSvc.refreshCoverage();
        } else {
          this.toastSvc.show({
            type: 'success',
            title: 'New policy version created',
            text:
              'A new version of <strong>' +
              this.policy.title +
              '</strong> has been created with <strong>' +
              value.title +
              '</strong>.',
          });
          this.policySvc.refreshPolicy(coverage.policyId);
          this.coverageSvc.refreshCoverage();
          this.policySvc.clearPlan();

          let newCoverageId = '';
          const onCoveragePage = (this.route.snapshot.routeConfig?.path?.split('/')?.length || 1) === 3;
          if (onCoveragePage) {
            const newPolicy = await this.policySvc.getOne(coverage.policyId);

            if (!newPolicy) throw new Error(`Unable to find policy with id: ${coverage.policyId}`);
            newCoverageId =
              newPolicy.coverages?.find(coverages => coverages.id.startsWith(coverage?.id?.split('-')[0] || ''))?.id ||
              '';
          }

          this.navigateTo(
            (onCoveragePage ? '../../../' : '../') +
              (coverage.policyId + (onCoveragePage ? '/coverages/' + newCoverageId : ''))
          );
        }

        this.close();
      }
    } else {
      const coverage = await this.coverageSvc.create(dto).catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
      });

      if (coverage?.id) {
        if (coverage.policyId === this.policy.id) {
          this.toastSvc.show({
            type: 'success',
            title: 'Created coverage',
            text: '<strong>' + dto?.title + '</strong> has been successfully created.',
          });

          this.policySvc.refreshPolicy();
          this.coverageSvc.refreshCoverage();
        } else {
          this.toastSvc.show({
            type: 'success',
            title: 'New policy version created',
            text:
              'A new version of <strong>' +
              this.policy?.title +
              '</strong> has been created with <strong>' +
              value.title +
              '</strong>.',
          });
          this.policySvc.refreshPolicy(coverage.policyId);
          this.coverageSvc.refreshCoverage();
          this.policySvc.clearPlan();

          let newCoverageId = '';
          const onCoveragePage = (this.route.snapshot.routeConfig?.path?.split('/')?.length || 1) === 3;
          if (onCoveragePage) {
            const newPolicy = await this.policySvc.getOne(coverage.policyId);

            if (!newPolicy) throw new Error(`Unable to find policy with id: ${coverage.policyId}`);
            newCoverageId =
              newPolicy.coverages?.find(coverages => coverages.id.startsWith(coverage?.id?.split('-')[0] || ''))?.id ||
              '';
          }

          this.navigateTo(
            (onCoveragePage ? '../../../' : '../') +
              (coverage.policyId + (onCoveragePage ? '/coverages/' + newCoverageId : ''))
          );
        }

        this.close();
      }
    }
  }

  async createBulk() {
    if (!this.policy || !this.coverages || !this.coverages.length) return;

    this.loadedCoverages$.next(false);

    const coverages = this.coverages.map(coverage => ({ ...coverage, policyId: this.policy?.id || '' }));

    const coverage = await this.coverageSvc
      .createMany({ canCreateNewVersion: true, policyId: this.policy.id, coverages })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
      });

    if (coverage?.policyId) {
      const lastCoverage = coverages.pop();
      const newCoverageTitles = coverages.map(coverage => coverage.title).join(', ');

      if (this.policy.id === coverage.policyId) {
        this.toastSvc.show({
          type: 'success',
          title: 'Created coverage',
          text:
            '<strong>' +
            (newCoverageTitles.length !== 0 ? newCoverageTitles + ' and ' + lastCoverage?.title : lastCoverage?.title) +
            '</strong> ' +
            (newCoverageTitles.length !== 0 ? 'have' : 'has') +
            ' been successfully created.',
        });

        this.policySvc.refreshPolicy();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text:
            'A new version of <strong>' +
            this.policy?.title +
            '</strong> has been created with <strong>' +
            newCoverageTitles +
            '</strong>.',
        });

        this.policySvc.refreshPolicy(coverage.policyId);
        this.coverageSvc.refreshCoverage();
        this.policySvc.clearPlan();

        this.navigateTo('../' + coverage.policyId);
      }

      this.close();
      this.saving = false;
    }

    this.loadedCoverages$.next(true);
  }

  async replaceExisting() {
    if (!this.policy || !this.coverages || !this.coverages.length) return;

    this.loadedCoverages$.next(false);
    this.replaceExistingDialog?.close();

    const countOfExistingCoverages = this.policy?.coverages?.length || 0;
    const coverages = this.coverages.map(coverage => ({
      ...coverage,
      order: coverage.order - countOfExistingCoverages,
      policyId: this.policy?.id || '',
    }));

    const coverage = await this.coverageSvc
      .replaceExisting(this.policy.id, { canCreateNewVersion: true, coverages })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });

        this.close();
      });

    if (coverage?.policyId) {
      const lastCoverage = coverages.pop();
      const newCoverageTitles = coverages.map(coverage => coverage.title).join(', ');

      if (this.policy.id === coverage.policyId) {
        this.toastSvc.show({
          type: 'success',
          title: 'Created coverage',
          text:
            '<strong>' +
            (newCoverageTitles.length !== 0 ? newCoverageTitles + ' and ' + lastCoverage?.title : lastCoverage?.title) +
            '</strong> ' +
            (newCoverageTitles.length !== 0 ? 'have' : 'has') +
            ' been successfully created.',
        });

        this.policySvc.refreshPolicy();
      } else {
        this.toastSvc.show({
          type: 'success',
          title: 'New policy version created',
          text:
            'A new version of <strong>' +
            this.policy?.title +
            '</strong> has been created with <strong>' +
            newCoverageTitles +
            '</strong>.',
        });

        this.policySvc.refreshPolicy(coverage.policyId);
        this.coverageSvc.refreshCoverage();
        this.policySvc.clearPlan();

        this.navigateTo('../' + coverage.policyId);
      }

      this.close();
      this.saving = false;
    }

    this.loadedCoverages$.next(true);
  }

  navigateTo(path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }

  ngOnInit() {
    this.csvForm.valueChanges.subscribe(value => {
      if (value.file) {
        this.loadedCoverages$.next(false);
        if (this.editing && this.policy?.coverages?.length) this.canReplaceExisting = true;

        this.fileParser.parse(value.file, {
          complete: file => {
            const currentOrder = this.policy?.coverages?.length;
            const newOrder = this.coverages.length;

            if (file.data.length === 5 && Array.isArray(file.data[0])) {
              const details = file.data;

              if (details[0][0] !== 'title') {
                this.toastSvc.show({
                  type: 'error',
                  title: 'Invalid CSV',
                  text: 'The CSV file you uploaded is invalid. Please download the template and try again.',
                });
              } else {
                for (let index = 0; index < details[0].length; index++) {
                  this.form.patchValue({
                    title: details[0][index],
                    description: details[1][index],
                    group: details[2][index],
                    exclusions: details[3][index],
                    inclusions: details[4][index],
                    order: (currentOrder ?? 0) + newOrder + index,
                  });

                  this.form.markAsDirty();
                  this.addCoverage();
                }
              }
            } else {
              if (file.data[0][0] !== 'title') {
                this.toastSvc.show({
                  type: 'error',
                  title: 'Invalid CSV',
                  text: 'The CSV file you uploaded is invalid. Please download the template and try again.',
                });
              } else {
                for (let index = 1; index < file.data.length; index++) {
                  const details = file.data[index];

                  if (details[0]) {
                    this.form.patchValue({
                      title: details[0],
                      description: details[1],
                      group: details[2],
                      exclusions: details[3],
                      inclusions: details[4],
                      order: (currentOrder ?? 0) + newOrder + index,
                    });

                    this.form.markAsDirty();
                    this.addCoverage();
                  }
                }
              }
            }
          },
        });

        this.csvForm.reset();

        setTimeout(() => {
          this.loadedCoverages$.next(true);
        }, 500);
      }
    });
  }
}
