<ng-container *ngIf="policy$ | async as policy">
  <vs-card class="overflow-hidden">
    <vs-card-header
      [config]="{
      title: 'Default Emails',
      subtitle: 'Review and manage the default emails sent out as part of this policy life cycle.'
    }"
    ></vs-card-header>

    <vs-table *ngIf="policy && policy?.emails as emails" [columns]="(columns$ | async) ??[]" [data]="emails"></vs-table>
  </vs-card>

  <ws-edit-email-template-dialog
    *ngIf="policy"
    #editEmailTemplateDialog
    [policy]="policy"
    [policyEmail]="selectedPolicyEmail"
  ></ws-edit-email-template-dialog>

  <ws-email-preview-dialog></ws-email-preview-dialog>
</ng-container>
