<ng-container *vsLet="propertySet$ | async as propertySet">
  <ng-container *vsLet="propertyValues$ | async as propertyValues">
    <ng-container *ngIf="!minimized; else isMinimized">
      <button
        class="rounded-lg p-4 bg-base border-2 w-full hover:ring-2 hover:ring-primary-200 transition-all flex flex-row"
        [class]="selected ? 'border-primary' : 'border-gray-200'"
        (click)="selectAsset()"
      >
        <div class="flex gap-2 w-full">
          <vs-icon
            class="self-center"
            [name]="$any(propertySet?.icon) || 'home-03'"
            class="text-primary-500"
            size="24"
          ></vs-icon>

          <div class="flex flex-col gap-2 pt-1">
            <span class="text-gray-600 text-start text-xs self-center w-full truncate">{{ asset.name }}</span>

            <vs-property-set-values
              class="w-full"
              [showHidden]="staffView"
              [propertySetId]="asset.propertySetId"
              [propertyValues]="propertyValues || {}"
            ></vs-property-set-values>
          </div>
        </div>
        <div class="flex flex-row gap-1 max-h-5 -mr-1">
          <vs-icon
            class="text-primary-500 rounded-full self-center hover:ring-2 hover:ring-primary-200 transition-all"
            [type]="selected ? 'solid' : 'line'"
            [name]="selected ? 'check-circle' : 'circle'"
          >
          </vs-icon>
        </div>
      </button>
    </ng-container>

    <ng-template #isMinimized>
      <div class="flex flex-row items-center h-14 p-2 gap-2.5 border shadow-sm bg-base rounded-lg">
        <vs-icon
          class="self-start"
          [name]="$any(propertySet?.icon) || 'home-03'"
          class="text-primary-500"
          size="24"
        ></vs-icon>

        <div class="flex flex-col gap-1 flex-grow truncate">
          <span class="text-gray-900 text-sm font-semibold">{{ asset.name }}</span>
          <vs-property-set-values
            class="w-full"
            [firstOnly]="true"
            [showHidden]="staffView"
            [propertySetId]="asset.propertySetId"
            [propertyValues]="propertyValues || {}"
          ></vs-property-set-values>
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
