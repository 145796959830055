import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContentCardModule } from '@vsolv/ui-kit/card';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, OverlayModule, ContentCardModule],
  exports: [GenericModalComponent, AlertModalComponent],
  declarations: [GenericModalComponent, AlertModalComponent],
})
export class ModalModule {}
