<ng-container *vsLet="claim$ | async as claim">
  <vs-dialog #dialog [config]="{ dialogContainerClass: 'max-w-[840px] max-h-[90%]' }" (closed)="refreshActivity()">
    <vs-dialog-header
      [config]="{
      mode: 'simple', 
      showDivider: true,

      icon: 'attachment-02',  
      iconTheme: 'primary', 
      
      title: 'Attachment Details', 
      subtitle: 'Preview all the details of the uploaded attachment'
    }"
    ></vs-dialog-header>

    <ng-container *ngIf="isLoading$ | async  else notLoading">
      <div class="flex justify-center items-center h-96">
        <vs-icon name="loading-01" size="35" class="text-primary animate-spin"></vs-icon>
      </div>
    </ng-container>

    <ng-template #notLoading>
      <div *ngIf="document$ | async as document" class="pt-6 px-3 relative">
        <div class="flex flex-col gap-4 max-h-[600px] overflow-y-auto scrollbar-thin">
          <div class="flex flex-col gap-1">
            <div class="flex flex-row gap-2">
              <p>{{ document.title }}</p>

              <vs-badge
                *ngIf="!isCustomerPortal"
                theme="default"
                class="self-center"
                tooltipStyle="light"
                [config]="{ iconStart: (document.feed.includes('customer')) ? 'eye' : 'eye-off' }"
                [vsTooltip]="{ 
                  title: 'This attachment is visible to customers', 
                  subtitle: 
                    ($any(document).feed?.includes('customer')) 
                      ? 'You can hide this attachment from customers on their portal by editing its visibility settings.' 
                      : 'You can show this attachment to customers on their portal by editing its visibility settings.' 
                }"
              >
                {{ (document.feed.includes('customer')) ? 'Visible to customer' : 'Not visible to customer'}}
              </vs-badge>
            </div>

            <small class="text-gray-500">{{ document.created | date }}</small>

            <p><small> {{ document.description }} </small></p>
          </div>

          <div *ngIf="document" class="flex justify-center max-h-[55vh]">
            <ws-claim-document-viewer [document]="document" class="w-full"></ws-claim-document-viewer>
          </div>

          <div class="flex flex-wrap gap-2">
            <ng-container *ngFor="let linkAndConfig of links$ | async">
              <ng-container *vsLet="linkAndConfig.link as link">
                <vs-info-card
                  *ngIf="link?.claimId_1 !== claim?.id && link?.claimId_2 !== claim?.id && !link?.activityId_1"
                  class="flex-grow max-w-[50%]"
                  [config]="linkAndConfig.config"
                >
                  <vs-badge
                    *ngIf="link?.claimItem_1 || link?.claimItem_2"
                    slot="header-end"
                    [theme]="getItemTheme(link?.claimItem_1 || link?.claimItem_2 || undefined)"
                  >
                    {{ link?.claimItem_1?.status || link?.claimItem_2?.status | titlecase }}
                  </vs-badge>
                </vs-info-card>
              </ng-container>
            </ng-container>
          </div>
        </div>

        <ng-container *vsLet="canEdit$ | async as canEdit">
          <ng-container *ngIf="claim">
            <div *vsLet="isMobile$ | async as isMobile " class="w-full pt-4 bg-base sticky bottom-0">
              <div *ngIf="!isMobile; else mobileButtons" class="flex flex-row justify-start w-full">
                <button *ngIf="canEdit" vs-button appearance="outline" (click)="openEditDialog({ claim, document })">
                  Edit
                </button>

                <div class="flex flex-row gap-3 w-full justify-end">
                  <button vs-button appearance="outline" (click)="close()">Cancel</button>
                  <a
                    vs-button
                    theme="primary"
                    target="_blank"
                    [href]="document.downloadUrl"
                    [download]="document.id + '.' + document.extension"
                  >
                    Download
                  </a>
                </div>
              </div>

              <ng-template #mobileButtons>
                <div class="flex flex-col gap-2 justify-start w-full">
                  <a
                    vs-button
                    theme="primary"
                    target="_blank"
                    class="w-full"
                    [href]="document.downloadUrl"
                    [download]="document.id + '.' + document.extension"
                  >
                    Download
                  </a>

                  <div class="flex flex-row gap-2 w-full">
                    <button vs-button appearance="outline" class="basis-1/2" (click)="close()">Cancel</button>

                    <button
                      *ngIf="canEdit"
                      vs-button
                      appearance="outline"
                      class="basis-1/2"
                      (click)="openEditDialog({ claim, document })"
                    >
                      Edit
                    </button>
                  </div>
                </div>
              </ng-template>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-template>
  </vs-dialog>

  <ws-edit-claim-document-dialog
    #editDialog
    [isCustomerPortal]="isCustomerPortal"
    (closed)="refresh()"
    (deleted)="close()"
    (updated)="refresh()"
    (closed)="editDialogClosed($event)"
  ></ws-edit-claim-document-dialog>
</ng-container>
