/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { BadgeColumn, PaginationConfig, TableColumn, TextColumn } from '@vsolv/vectors/table';
import { Distributor } from '@wsphere/distributors/domain';
import { SecurityService, StaffService } from '@wsphere/staff/web';
import moment from 'moment';
import {
  BehaviorSubject,
  combineLatest,
  from,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs';
import { CreateDistributorDialog } from '../../dialogs';
import { DistributorService } from '../../services';

export type DistributorTableFilters = Pick<
  Distributor.ListDistributorsQueryRequest,
  'search' | 'status' | 'includeSubDistributors'
>;

@Component({
  selector: 'ws-distributor-table',
  templateUrl: './distributor-table.component.html',
})
export class DistributorTableComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private staffSvc: StaffService,
    private securitySvc: SecurityService,
    private distributorSvc: DistributorService
  ) {}

  @ViewChild('addDistributorDialog') addDistributorDialog?: CreateDistributorDialog;

  _distributor?: Distributor.Model;
  @Input() set distributor(distributor: Distributor.Model) {
    if (distributor) {
      if (distributor.id !== this._distributor?.id) this.distributorCount = 0;
      this._distributor = distributor;
    }
    this.refresh$.next(null);
  }

  @Input() onSettings = false;

  @PropertyListener('distributor') private distributor$ = new ReplaySubject<Distributor.Model | undefined>(undefined);
  staff$ = from(this.staffSvc.retrieveSelf()).pipe(shareReplay(1));

  readonly canCreateDistributor$: Observable<boolean> = combineLatest([this.distributor$, this.staff$]).pipe(
    switchMap(async ([distributor]) => {
      const permissionKeys = this.securitySvc.globalDistributors$.value?.map(dist => dist.permissionKey) ?? null;

      return await this.securitySvc.hasAccess(
        'dist_CreateDistributor',
        distributor?.permissionKey ? [distributor.permissionKey] : permissionKeys
      );
    })
  );

  distributorCount = 0;
  statuses = Object.keys(Distributor.Status);
  active = Distributor.Status.ACTIVE;

  private refresh$ = new BehaviorSubject<null>(null);

  filters: DistributorTableFilters | null = null;
  private filters$ = new BehaviorSubject(this.filters);

  readonly pagination$ = new BehaviorSubject({ currentPage: 1, itemsPerPage: 10 });

  private paginatedData$ = combineLatest([
    this.pagination$,
    this.filters$,
    this.securitySvc.globalDistributors$,
    this.refresh$,
  ]).pipe(
    switchMap(
      async ([pagination, filters]) =>
        await this.distributorSvc.listDistributors({
          page: pagination.currentPage,
          limit: pagination.itemsPerPage,
          search: filters?.search,
          status: filters?.status,
          includeSubDistributors: this._distributor?.id ? true : filters?.includeSubDistributors,
          distributorIds: this._distributor?.id ? [this._distributor.id] : [],
        })
    ),
    tap(data => (this.distributorCount === 0 ? (this.distributorCount = data?.meta?.totalItems || 0) : 0)),
    shareReplay(1)
  );

  readonly distributors$: Observable<Distributor.Model[]> = this.paginatedData$.pipe(
    map(data => data.items),
    startWith([])
  );

  readonly paginationConfig$: Observable<Partial<PaginationConfig>> = this.paginatedData$.pipe(
    map(data => data.meta),
    startWith(this.pagination$.value)
  );

  columns: TableColumn<unknown>[] = [
    new TextColumn<Distributor.Model>({ header: 'Distributor' }, distributor => ({
      text: distributor.name,
      classes: !distributor.id ? 'text-md font-semibold' : '',
    })),

    new TextColumn<Distributor.Model>({ header: 'No. of Members', align: 'center' }, async distributor => {
      const staff = await this.staffSvc.count({ permissionKey: distributor.permissionKey, includeChildren: true });
      const count = staff?.count || 0;
      return { text: count + '' };
    }),

    new TextColumn<Distributor.Model>({ header: 'No. of Sub-distributors', align: 'center' }, async distributor => {
      const distributors = await this.distributorSvc.count(distributor.permissionKey);
      // Reduce by one to remove self from count
      const count = distributors?.count ? distributors.count - 1 || 0 : 0;
      return { text: count + '' };
    }),

    new BadgeColumn<Distributor.Model>({ header: 'Status' }, distributor => ({
      text: distributor.status.charAt(0) + distributor.status.slice(1).toLowerCase(),
      displayStatusIcon: true,
      theme: this.getTheme(distributor.status),
    })),

    new TextColumn<Distributor.Model>({ header: 'Last activity' }, distributor => ({
      text: moment(distributor.modified as Date).format('MMM DD, YYYY'),
    })),

    new TextColumn<Distributor.Model>({ header: 'Date created' }, distributor => ({
      text: moment(distributor.created as Date).format('MMM DD, YYYY'),
    })),
  ];

  getTheme(status: Distributor.Status) {
    switch (status) {
      case Distributor.Status.ACTIVE:
        return 'success';
      case Distributor.Status.DEACTIVATED:
        return 'info';
      default:
        return 'default';
    }
  }

  openAddDistributorDialog() {
    this.addDistributorDialog?.openDialog();
  }

  async refreshTable(filters?: { search?: string; status?: Distributor.Status; includeSubDistributors?: boolean }) {
    if (filters?.search !== undefined) this.filters = { ...this.filters, search: filters.search };
    if (filters?.status !== undefined) this.filters = { ...this.filters, status: filters.status };
    if (filters?.includeSubDistributors !== undefined)
      this.filters = { ...this.filters, includeSubDistributors: filters.includeSubDistributors };
    this.filters$.next(this.filters);
  }

  navigateTo(distributorId: string) {
    if (this._distributor?.id) {
      this.router.navigate([this.onSettings ? `../../distributors/${distributorId}` : `../${distributorId}`], {
        relativeTo: this.route,
      });
    } else this.router.navigate([`${distributorId}`], { relativeTo: this.route });
  }
}
