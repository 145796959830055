<div class="border-gray-100 border rounded-lg flex flex-row bg-gray-50 gap-2 p-1">
  <span *ngFor="let term of terms$ | async">
    <button
      vs-button
      *ngIf="selected === term.id; else notSelected"
      (click)="selectTerm(term.id)"
      class="!bg-base-white hover:bg-themed-100 hover:ring-themed-100 !text-gray-700 !border-gray-200 !h-[44px] !shadow-sm"
    >
      {{ term.title }}
    </button>
    <ng-template #notSelected>
      <button
        vs-button
        appearance="clear"
        (click)="selectTerm(term.id)"
        class="hover:bg-themed-100 hover:ring-themed-100 !text-gray-500 !h-[44px]"
      >
        {{ term.title }}
      </button>
    </ng-template>
  </span>
</div>
