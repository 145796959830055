/* eslint-disable @typescript-eslint/no-explicit-any */
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { CustomerService } from '@wsphere/customers/web';
import { Link } from '@wsphere/links/domain';
import { LinksWebService } from '@wsphere/links/web';
import { SecurityService } from '@wsphere/staff/web';
import { Claim } from '@wsphere/warranties/domain';
import { combineLatest, map, ReplaySubject, switchMap, tap } from 'rxjs';
import { ClaimService } from '../../services';
import { ClaimQuickAddType } from '../claim-quick-add/claim-quick-add.component';

@Component({
  selector: 'ws-claim-timeline',
  templateUrl: './claim-timeline.component.html',
})
export class ClaimTimelineComponent {
  constructor(
    private linkSvc: LinksWebService,
    private securitySvc: SecurityService,
    private customerSvc: CustomerService,
    private breakpointObserver: BreakpointObserver,
    private claimSvc: ClaimService
  ) {}

  @PropertyListener('claim') claim$ = new ReplaySubject<Claim.Model>(1);
  @Input() claim: Claim.Model | null = null;

  @Input() isCustomerPortal = false;

  open = true;

  note = ClaimQuickAddType.NOTE;
  document = ClaimQuickAddType.DOCUMENT;

  linkType: Link.ObjectType | null = null;
  linkTypes = [
    Link.ObjectType.CLAIM,
    Link.ObjectType.CLAIM_ITEM,
    Link.ObjectType.NOTE,
    Link.ObjectType.DOCUMENT,
    Link.ObjectType.PAYMENT,
  ];

  isMobile$ = this.breakpointObserver
    .observe([Breakpoints.XSmall, Breakpoints.Small])
    .pipe(map(state => state.matches));

  readonly isMyClaim$ = this.claim$.pipe(
    switchMap(async claim => {
      if (this.isCustomerPortal) {
        const currentUser = await this.customerSvc.retrieveSelf();
        return claim?.warranty?.customerId === currentUser?.id;
      } else return false;
    })
  );

  canAddNotes$ = this.claim$.pipe(
    switchMap(async claim => {
      if (this.isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageNote', permissionKey);
    })
  );

  canAddDocuments$ = combineLatest([this.claim$, this.isMyClaim$]).pipe(
    switchMap(async ([claim, isMyClaim]) => {
      if (isMyClaim) return true;
      else if (this.isCustomerPortal) return false;

      const permissionKey = claim?.warranty?.distributor?.permissionKey
        ? [claim?.warranty?.distributor?.permissionKey]
        : null;
      return await this.securitySvc.hasAccess('clm_ManageAttachments', permissionKey);
    })
  );

  refresh$ = this.linkSvc.refreshActivity$;

  protected loading = false;

  activities$ = combineLatest([this.claim$, this.refresh$]).pipe(
    tap(() => (this.loading = true)),
    switchMap(async ([claim]) => {
      const activities = await this.linkSvc.list(
        { id: claim.id, objectType: Link.ObjectType.CLAIM },
        {
          limit: 100,
          owner: { id: claim.id, objectType: Link.ObjectType.CLAIM },
          linkType: Link.ObjectType.ACTIVITY,
        }
      );

      if (this.isCustomerPortal) {
        return {
          ...activities,
          items: activities.items.filter(activity => activity.activity_1?.feed.includes('customer')),
        };
      } else return activities;
    }),
    map(data => data.items),
    map(links => links.map(link => link.activity_1)),
    map(activities => activities.sort((a, b) => (b?.created.getTime() || 0) - (a?.created.getTime() || 0))),
    tap(() =>
      setTimeout(() => {
        this.loading = false;
      }, 500)
    )
  );

  setLinkType(type: Link.ObjectType) {
    this.linkType = type;
  }

  refresh() {
    this.refresh$.next(null);
  }
}
