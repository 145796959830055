<ng-container *ngIf="policy$ | async as policy">
  <vs-card class="mb-4">
    <vs-card-header
      [config]="{
        title: 'Customer Portal',
        subtitle: 'Claims experience as seen by customers on the customer portal.'
      }"
    >
      <vs-input-group *ngIf="customerPortalConfigFormChanged$ | async" slot="end">
        <button
          vs-icon-button
          appearance="outline"
          vsTooltip="Reset changes"
          [disabled]="savingCustomerPortalConfig"
          (click)="resetCustomerPortalConfigForm(policy)"
        >
          <vs-icon name="reverse-left"></vs-icon>
        </button>

        <button
          vs-button
          appearance="outline"
          vsTooltip="Save changes"
          [disabled]="savingCustomerPortalConfig"
          (click)="saveCustomerPortalConfig(policy.id)"
        >
          <vs-icon
            slot="start"
            [class.animate-spin]="savingCustomerPortalConfig"
            [name]="savingCustomerPortalConfig ? 'refresh-cw-04' : 'save-01'"
          ></vs-icon>
          Save
        </button>
      </vs-input-group>
    </vs-card-header>

    <vs-card-content class="overflow-hidden !rounded-b-xl">
      <form [formGroup]="customerPortalConfigForm">
        <vs-form-field
          appearance="horizontal"
          class="[&>div>div:last-child]:min-w-[unset]"
          [config]="{
            label: 'Online self-serve claims',
            description: 'Allow customers to self-serve open and manage their claims online via the customer portal.'
          }"
        >
          <input vsInput type="checkbox" appearance="toggle" class="block ml-auto" formControlName="selfServeClaims" />
        </vs-form-field>

        <vs-divider class="my-4"></vs-divider>

        <vs-form-field
          appearance="responsive"
          [config]="{
          label: 'Claims placeholder',
          description: 'Customizable placeholder shown to the customer when no claims have yet been opened.'
        }"
        >
          <textarea vsInput type="text" rows="5" formControlName="claimsPlaceholder"></textarea>
        </vs-form-field>
      </form>
    </vs-card-content>
  </vs-card>

  <form *vsLet="patchForm$ | async" [formGroup]="form">
    <ws-claim-lifecycle-table
      #table
      formControlName="steps"
      [canEdit]="canEdit$ | async"
      (save)="policy?.status === draft ? save(policy) : checkSave()"
    ></ws-claim-lifecycle-table>
  </form>

  <vs-dialog #checkSaveDialog [config]="{ dialogContainerClass: 'max-w-[500px]'}">
    <vs-dialog-header
      [config]="{
        mode: 'simple',

        icon: 'info-circle',
        iconTheme: 'primary',

        title: 'Updating lifecycle steps will impact all future claims.',
        subtitle: 'Are you sure you want to update the lifecycle steps for <strong>'
          + policy?.title + '</strong>? Existing claims will not be affected, but all claims opening in the future will reflect these changes. No new policy version is necessary to save these changes.'
      }"
    ></vs-dialog-header>

    <div class="flex gap-2">
      <button vs-button appearance="outline" class="flex-1" (click)="checkSaveDialog.close()">Cancel</button>
      <button vs-button theme="primary" class="flex-1" (click)="checkSaveDialog?.close(); save(policy)">Save</button>
    </div>
  </vs-dialog>
</ng-container>
