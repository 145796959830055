/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Conditions } from '@vsolv/packages/conditions/domain';
import { Property, PropertySet } from '@vsolv/packages/properties/domain';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Policy } from '@wsphere/warranties/domain';
import { v4 } from 'uuid';
import { PolicyService } from '../../services';

@Component({
  selector: 'ws-manage-plan-groups-dialog',
  templateUrl: './manage-plan-groups.dialog.html',
})
export class ManagePlanGroupsDialog {
  constructor(private toastSvc: ToastService, private formBuilder: FormBuilder, private policySvc: PolicyService) {}

  @ViewChild('createAddonDialog') createAddonDialog?: DialogComponent;
  @ViewChild('removeAddonDialog') removeAddonDialog?: DialogComponent;
  @ViewChild('createLiabilityGroupDialog') createLiabilityGroupDialog?: DialogComponent;
  @ViewChild('removeLiabilityGroupDialog') removeLiabilityGroupDialog?: DialogComponent;
  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() plan: Policy.Plan.Model | null = null;
  @Input() policy: Policy.Model | null = null;
  @Input() selectedTermId!: string;

  @Input() propertySet!: PropertySet.Model | null;

  selectedAddon: Policy.Plan.Addon | null = null;
  selectedLiabilityGroup: Policy.Plan.LiabilityGroup | null = null;

  num = Property.PropertyType.NUMBER;

  addonForm = this.formBuilder.group({
    title: ['', [Validators.required]],
    description: [''],
  });

  liabilityGroupForm = this.formBuilder.group({
    title: ['', [Validators.required]],
    description: [''],
    limit: [null as number | null],
    limitPropertyPath: [null as string | null],
  });

  open() {
    this.dialog.open();
  }

  close() {
    this.dialog.close();
  }

  openAddonDialog(addon?: Policy.Plan.Addon) {
    if (addon) {
      this.selectedAddon = addon;

      this.addonForm.patchValue({
        title: addon.title,
        description: addon.description,
      });
    }

    this.createAddonDialog?.open();
  }

  closeAddonDialog() {
    this.selectedAddon = null;
    this.addonForm.patchValue({ title: '', description: '' });
    this.createAddonDialog?.close();
  }

  async createAddon() {
    if (!this.plan) return;

    if (this.selectedAddon) {
      if (!this.plan.addons?.length) return;

      const addonIndex = this.plan.addons?.findIndex(addon => addon.id === this.selectedAddon?.id);

      if (addonIndex !== -1) {
        this.plan.addons[addonIndex] = {
          id: this.selectedAddon.id,
          title: this.addonForm.value.title ?? this.selectedAddon.title,
          description: this.addonForm.value.description ?? this.selectedAddon.description,
        };
      }
    } else {
      if (!this.plan.addons?.length) this.plan.addons = [];

      const id = 'add_' + v4().replace(/-/g, '');
      this.plan.addons.push({
        id,
        title: this.addonForm.value.title || '',
        description: this.addonForm.value.description || '',
      });
    }

    const plan = await this.policySvc
      .updatePlan(this.plan.id, this.plan.policyId, { canCreateNewVersion: true, addons: this.plan.addons })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

    if (plan) {
      this.toastSvc.show({
        type: 'success',
        title: this.selectedAddon
          ? 'Updated <strong>' + this.addonForm.value.title + '</strong>'
          : 'New <i>Included In</i> group created',
        text: this.selectedAddon
          ? 'The group <strong>' + this.addonForm.value.title + '</strong> has been successfully updated.'
          : 'The group <i>' + this.addonForm.value.title + '</i> has be successfully created.',
      });

      this.selectedAddon = null;
      this.closeAddonDialog();
    }
  }

  removeAddonCheck(addon: Policy.Plan.Addon) {
    if (!this.plan?.addons) return;
    this.selectedAddon = addon;

    let isUsed = false;
    Object.entries(this.plan.coverageConfigs)?.forEach(termConfig => {
      termConfig[1]?.forEach(config => {
        if (config.requirement.defaultValue === addon.id) isUsed = true;

        if (!isUsed) {
          config.requirement.blocks.forEach(block => {
            if (block.value === addon.id) isUsed = true;
          });
        }
      });
    });

    if (isUsed) this.removeAddonDialog?.open();
    else this.removeAddon();
  }

  async removeAddon() {
    if (!this.plan?.addons) return;

    const addonToRemove = this.selectedAddon;
    this.plan.addons = this.plan.addons.filter(add => add.id !== addonToRemove?.id);

    const configs = Object.entries(this.plan.coverageConfigs);
    configs.forEach(termConfig => {
      termConfig[1]?.forEach(config => {
        if (config.requirement.defaultValue === addonToRemove?.id) {
          config.requirement.defaultValue = 'NOT_APPLICABLE';
        }

        const blocksToKeep: Conditions.Block<string>[] = [];
        config.requirement.blocks.forEach(block => {
          if (block.value !== addonToRemove?.id) blocksToKeep.push(block);
        });
        config.requirement.blocks = blocksToKeep;
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.plan!.coverageConfigs[termConfig[0]] = termConfig[1];
    });

    const plan = await this.policySvc
      .updatePlan(this.plan.id, this.plan.policyId, {
        canCreateNewVersion: true,
        addons: this.plan.addons,
        coverages: this.plan.coverageConfigs
          ? Object.entries(this.plan.coverageConfigs).map(value => {
              return { termId: value[0], coverages: value[1] ?? [] };
            })
          : undefined,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

    if (plan) {
      this.toastSvc.show({
        type: 'success',
        title: 'Removed <strong>' + (addonToRemove?.title ?? 'the addon') + '</strong>',
        text:
          'The group <strong>' +
          (addonToRemove?.title ?? '') +
          '</strong> has been removed from all cells and conditions on this plan.',
      });

      this.selectedAddon = null;
      this.removeAddonDialog?.close();
    }
  }

  openLiabilityGroupDialog(liabilityGroup?: Policy.Plan.LiabilityGroup) {
    if (liabilityGroup) {
      this.selectedLiabilityGroup = liabilityGroup;

      this.liabilityGroupForm.patchValue({
        title: liabilityGroup.title,
        description: liabilityGroup.description,
        limit: liabilityGroup?.limitPropertyPath ? null : (liabilityGroup.limit ?? 0) / 100,
        limitPropertyPath: liabilityGroup.limitPropertyPath ?? null,
      });
    }

    this.createLiabilityGroupDialog?.open();
  }

  closeLiabilityGroupDialog() {
    this.selectedLiabilityGroup = null;
    this.liabilityGroupForm.patchValue({ title: '', description: '', limit: null, limitPropertyPath: null });
    this.createLiabilityGroupDialog?.close();
  }

  async createLiabilityGroup() {
    if (!this.plan?.liabilityGroups) return;

    if (this.selectedLiabilityGroup) {
      const groupIndex = this.plan.liabilityGroups?.findIndex(group => group.id === this.selectedLiabilityGroup?.id);

      if (groupIndex > -1) {
        this.plan.liabilityGroups[groupIndex] = {
          id: this.selectedLiabilityGroup.id,
          title: this.liabilityGroupForm.value.title ?? this.selectedLiabilityGroup.title,
          description: this.liabilityGroupForm.value.description ?? this.selectedLiabilityGroup.description,
          limit: this.liabilityGroupForm.value.limitPropertyPath
            ? null
            : (this.liabilityGroupForm.value.limit ?? this.selectedLiabilityGroup.limit ?? 0) * 100,
          limitPropertyPath: this.liabilityGroupForm.value.limitPropertyPath,
        };
      }
    } else {
      const id = 'llg_' + v4().replace(/-/g, '');
      this.plan.liabilityGroups.push({
        id,
        title: this.liabilityGroupForm.value.title || '',
        description: this.liabilityGroupForm.value.description || '',
        limit: (this.liabilityGroupForm.value.limit || 0) * 100,
        limitPropertyPath: this.liabilityGroupForm.value.limitPropertyPath,
      });
    }

    const plan = await this.policySvc
      .updatePlan(this.plan.id, this.plan.policyId, {
        canCreateNewVersion: true,
        liabilityLimitGroup: this.plan.liabilityGroups,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

    if (plan) {
      this.toastSvc.show({
        type: 'success',
        title: this.selectedLiabilityGroup
          ? 'Updated <strong>' + this.liabilityGroupForm.value.title + '</strong>'
          : 'New <i>Liability</i> group created',
        text: this.selectedLiabilityGroup
          ? 'The group <strong>' + this.liabilityGroupForm.value.title + '</strong> has been successfully updated.'
          : 'The group <i>' + this.liabilityGroupForm.value.title + '</i> has be successfully created.',
      });

      this.selectedLiabilityGroup = null;

      this.closeLiabilityGroupDialog();
    }
  }

  removeLiabilityGroupCheck(group: Policy.Plan.LiabilityGroup) {
    if (!this.plan?.liabilityGroups) return;
    this.selectedLiabilityGroup = group;

    let isUsed = false;
    Object.entries(this.plan.coverageConfigs).forEach(termConfig => {
      termConfig[1]?.forEach(config => {
        if (config.liabilityGroups.defaultValue.includes(group.id)) isUsed = true;

        if (!isUsed) {
          config.liabilityGroups.blocks.forEach(block => {
            if (block.value.includes(group.id)) isUsed = true;
          });
        }
      });
    });

    if (isUsed) this.removeLiabilityGroupDialog?.open();
    else this.removeLiabilityGroup();
  }

  async removeLiabilityGroup() {
    if (!this.plan?.liabilityGroups) return;
    const groupToRemove = this.selectedLiabilityGroup;
    this.plan.liabilityGroups = this.plan.liabilityGroups.filter(group => group.id !== groupToRemove?.id);

    const configs = Object.entries(this.plan.coverageConfigs);
    configs.forEach(termConfig => {
      termConfig[1]?.forEach(config => {
        if (config.liabilityGroups.defaultValue.includes(groupToRemove?.id || '')) {
          config.liabilityGroups.defaultValue = config.liabilityGroups.defaultValue.filter(
            groupId => groupId !== groupToRemove?.id
          );
        }

        const blocksToKeep: Conditions.Block<string[]>[] = [];
        config.liabilityGroups.blocks.forEach(block => {
          if (!block.value.includes(groupToRemove?.id || '')) blocksToKeep.push(block);
        });
        config.liabilityGroups.blocks = blocksToKeep;
      });
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.plan!.coverageConfigs[termConfig[0]] = termConfig[1];
    });

    const plan = await this.policySvc
      .updatePlan(this.plan.id, this.plan.policyId, {
        canCreateNewVersion: true,
        liabilityLimitGroup: this.plan.liabilityGroups,
        coverages: this.plan.coverageConfigs
          ? Object.entries(this.plan.coverageConfigs).map(value => {
              return { termId: value[0], coverages: value[1] ?? [] };
            })
          : undefined,
      })
      .catch(({ error }) => {
        this.toastSvc.show({
          type: 'error',
          title: 'Something went wrong',
          text: error.message,
        });
      });

    if (plan) {
      this.toastSvc.show({
        type: 'success',
        title: 'Removed <strong>' + (groupToRemove?.title ?? 'the group') + '</strong>',
        text:
          'The group <strong>' +
          (groupToRemove?.title ?? '') +
          '</strong> has been removed from all cells and conditions on this plan.',
      });

      this.selectedLiabilityGroup = null;
      this.removeLiabilityGroupDialog?.close();
    }
  }

  setLimitPropertyPath(
    property: { property: Property.Model<Property.PropertyType> | Conditions.Property; propertyPath: string } | null
  ) {
    this.liabilityGroupForm.patchValue({
      limitPropertyPath: property?.propertyPath ?? null,
      limit: null,
    });
  }
}
