/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Coverage, Policy } from '@wsphere/warranties/domain';
import { firstValueFrom } from 'rxjs';
import { PolicyAsset, PolicyDetails, PolicyDocument } from '../../components';
import { PolicyService } from '../../services';

export enum EditPolicyType {
  POLICY_DETAILS = 'POLICY_DETAILS',
  POLICY_TERM = 'POLICY_TERM',
  COVERAGE = 'COVERAGE',
  CREATE_COVERAGES = 'CREATE_COVERAGES',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  COVERAGE_SUMMARY = 'COVERAGE_SUMMARY',
  POLICY_ASSET = 'POLICY_ASSET',
}

export enum PolicyDialogPages {
  POLICY_DETAILS = 1,
  POLICY_ASSET = 2,
  POLICY_TERM = 3,
  COVERAGES = 4,
  COVERAGE_DETAILS_1 = 4.1,
  COVERAGE_DETAILS_2 = 4.2,
  TERMS_AND_CONDITIONS = 5,
  TC_LEGAL = 5.1,
  TC_WELCOME = 5.2,
  TC_DEFINITIONS = 5.3,
  COVERAGE_SUMMARY = 6,
  CS_LEGAL = 6.1,
  CS_WELCOME = 6.2,
  CS_DEFINITIONS = 6.3,
}

@Component({
  selector: 'ws-policy-dialog',
  templateUrl: './policy.dialog.html',
})
export class PolicyDialog {
  constructor(
    private router: Router,
    private http: HttpClient,
    private route: ActivatedRoute,
    private toastSvc: ToastService,
    private policySvc: PolicyService
  ) {}

  @ViewChild(DialogComponent) dialog!: DialogComponent;

  @Input() policy: Policy.Model | null = null;
  @Input() coverage: Coverage.Model | null = null;

  @Input() set editing(value: EditPolicyType | null) {
    this.saving = false;
    this.editType = value;

    switch (value) {
      case EditPolicyType.POLICY_TERM:
        this.page = this.pages.POLICY_TERM;
        break;
      case EditPolicyType.COVERAGE:
        this.page = this.pages.COVERAGE_DETAILS_1;
        break;
      case EditPolicyType.CREATE_COVERAGES:
        this.page = this.pages.COVERAGES;
        break;
      case EditPolicyType.TERMS_AND_CONDITIONS:
        this.page = this.pages.TERMS_AND_CONDITIONS;
        break;
      case EditPolicyType.COVERAGE_SUMMARY:
        this.page = this.pages.COVERAGE_SUMMARY;
        break;
      case EditPolicyType.POLICY_ASSET:
        this.page = this.pages.POLICY_ASSET;
        break;
      default:
        this.page = this.pages.POLICY_DETAILS;
    }
  }

  page = 1;
  pages = PolicyDialogPages;
  draft = Policy.Status.DRAFT;
  editType: EditPolicyType | null = null;
  protected editPolicyType = EditPolicyType;

  saving = false;

  policyDetails: PolicyDetails | null = null;
  policyAsset: PolicyAsset | null = null;
  policyTerms: Policy.PolicyTerm[] | null = null;
  coverages: Coverage.Model[] | null = null;
  policyTermsAndConditions: PolicyDocument | null = null;
  policyCoverageSummary: PolicyDocument | null = null;

  goToPage(number: number) {
    this.page = number;
  }

  async save() {
    if (!this.policyDetails || !this.policyAsset) return;
    this.saving = true;

    if (!this.policyAsset.propertySet) {
      this.toastSvc.show({
        type: 'error',
        title: 'Property Set Required!',
        text: 'Please select a property set and try again',
      });
      return;
    }

    const coverageDtos: Policy.CreatePolicyRequest['coverages'] =
      this.coverages?.map(coverage => {
        const dto: Policy.CreatePolicyRequest['coverages'][number] = {
          order: Number(coverage.order ?? 0),
          title: coverage.title,
          description: coverage.description,
          group: coverage.group,
          inclusions: coverage.inclusions,
          exclusions: coverage.exclusions,
        } as any;

        return dto;
      }) || [];

    const policyBody: Policy.CreatePolicyRequest = {
      title: this.policyDetails.title,
      tagline: this.policyDetails.tagline.trim(),
      description: this.policyDetails.description?.trim(),
      policyNumber: this.policyDetails.prefix + '-' + this.policyDetails.policyNumber,
      friendlyTitle: this.policyDetails.friendlyTitle.trim().length
        ? this.policyDetails.friendlyTitle.trim()
        : this.policyDetails.title,
      icon: this.policyDetails.icon || 'file-shield-02',
      requiresWarrantyActivation: this.policyDetails.requiresWarrantyActivation ?? false,

      claimWaitingPeriod: this.policyDetails.claimWaitingPeriod || 0,
      terms: this.policyTerms ?? [],

      propertySetId: this.policyAsset.propertySet.id,

      coverages: coverageDtos,

      termsAndConditions: {
        title: 'Terms & Conditions',
        legalIntroduction: this.policyTermsAndConditions?.legalIntroduction || '',
        welcomeIntroduction: this.policyTermsAndConditions?.welcomeIntroduction || '',
        definitions: this.policyTermsAndConditions?.definitions || '',
        uploadFile: !!this.policyTermsAndConditions?.file,
      },

      coverageSummary: {
        title: 'Coverage Summary',
        legalIntroduction: this.policyCoverageSummary?.legalIntroduction || '',
        welcomeIntroduction: this.policyCoverageSummary?.welcomeIntroduction || '',
        definitions: this.policyCoverageSummary?.definitions || '',
        uploadFile: !!this.policyCoverageSummary?.file,
      },
    };

    const result = await this.policySvc.create(policyBody).catch(({ error }) => {
      this.toastSvc.show({
        type: 'error',
        title: 'Something went wrong',
        text: error.message,
      });

      this.close();
    });

    if (result) {
      this.toastSvc.show({
        type: 'success',
        title: 'Created policy',
        text: '<strong>' + this.policyDetails?.title + '</strong> has been successfully created.',
      });

      this.uploadDocumentsToGCP({
        tcUploadUrl: result.termsAndConditionsUploadUrl,
        csUploadUrl: result.coverageSummaryUploadUrl,
        tcFile: this.policyTermsAndConditions?.file,
        csFile: this.policyCoverageSummary?.file,
      });

      this.navigateTo(result.id);
      this.close();
    }
  }

  close(internalClose: boolean = false) {
    this.editing = this.editType;

    this.saving = false;
    if (!internalClose) this.dialog.close();
  }

  open() {
    this.dialog.open();
  }

  private async uploadDocumentsToGCP(data: {
    tcUploadUrl?: string;
    csUploadUrl?: string;
    tcFile?: File | null;
    csFile?: File | null;
  }) {
    if (data.tcUploadUrl && data.tcFile) {
      this.uploadFileToGCP(data.tcFile, data.tcUploadUrl);
    }

    if (data.csUploadUrl && data.csFile) {
      this.uploadFileToGCP(data.csFile, data.csUploadUrl);
    }
  }

  private async uploadFileToGCP(file: File, uploadUrl: string) {
    let buffer;
    if (file) buffer = await this.readFileBuffer(file);

    await firstValueFrom(this.http.put(uploadUrl, buffer, { headers: { 'content-type': 'application/pdf' } }));
  }

  private async readFileBuffer(file: File) {
    return new Promise(res => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = event => res(event?.target?.result);
    }) as unknown as ArrayBuffer;
  }

  navigateTo(path: string) {
    this.router.navigate([`${path}`], { relativeTo: this.route });
  }
}
