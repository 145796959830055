/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Claim, Coverage } from '@wsphere/warranties/domain';
import { BehaviorSubject, ReplaySubject, combineLatest, map } from 'rxjs';

@Component({
  selector: 'ws-coverage-picker',
  templateUrl: './coverage-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CoveragePickerComponent,
    },
  ],
})
export class CoveragePickerComponent implements ControlValueAccessor, OnDestroy {
  constructor(public elementRef: ElementRef) {}

  @PropertyListener('value') value$ = new ReplaySubject<Coverage.Model>(1);
  @Input() value: Coverage.Model | null | undefined = undefined;

  @PropertyListener('claim') claim$ = new ReplaySubject<Claim.Model>(1);
  @Input() claim: Claim.Model | null = null;

  @Input() disabled = false;

  @Output() valueChanges = new EventEmitter<Coverage.Model | null>();

  touched = false;
  included = false;
  overlayOpen = false;
  selectedGroup: { coverage: Coverage.Model; included: boolean }[] | null = null;

  search$ = new BehaviorSubject<string>('');

  groupsAndCoverages$ = combineLatest([this.claim$, this.search$]).pipe(
    map(([claim, search]) => {
      const coverages = claim?.warranty?.policy?.coverages;
      if (!coverages?.length) return;

      const groups = coverages?.reduce((acc, coverage) => {
        if (!acc.includes(coverage.group)) acc.push(coverage.group);
        return acc;
      }, [] as string[]);

      const groupsAndCoverages = groups?.reduce((acc, group) => {
        const policyGroup = coverages?.filter(
          coverage => coverage.group === group && coverage.title.toLowerCase().includes(search.toLowerCase())
        );

        const groupCoverages = policyGroup?.map(coverage => ({
          coverage,
          included: Claim.ClaimItem.included(claim, coverage.id),
        }));

        if (groupCoverages?.length) acc.push({ group, coverages: groupCoverages });
        return acc;
      }, [] as { group: string; coverages: { coverage: Coverage.Model; included: boolean }[] }[]);

      if (this.selectedGroup) {
        this.selectedGroup = this.selectedGroup.filter(group =>
          group.coverage.title.toLowerCase().includes(search.toLowerCase())
        );
        if (!this.selectedGroup.length) this.selectedGroup = null;
      }

      return groupsAndCoverages;
    })
  );

  onChange = (_value: Coverage.Model | null | undefined) => {};
  onTouched = () => {};

  selectValueChanged(value: Coverage.Model | null, included?: boolean) {
    this.markAsTouched();
    this.value?.id === value?.id ? (this.value = null) : (this.value = value);
    this.close();
    this.onChange(this.value);
    this.valueChanges.next(this.value);

    this.included = included || false;
  }

  writeValue(value: Coverage.Model | null | string): void {
    if (value === null || value) {
      value === 'empty' ? (this.value = undefined) : (this.value = value as Coverage.Model);
    } else this.value = undefined;

    if (this.claim && this.value) this.included = Claim.ClaimItem.included(this.claim, this.value.id);
  }

  registerOnChange(onChange: (_value: Coverage.Model | null | undefined) => {}): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => {}): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  open() {
    this.overlayOpen = true;
  }

  close() {
    this.overlayOpen = false;
  }

  toggle() {
    this.overlayOpen = !this.overlayOpen;
  }

  ngOnDestroy(): void {
    this.value$.complete();
  }
}
