import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { TenantInfoService, TenantService } from '@vsolv/core/multi-tenant/web';
import { Config } from '@vsolv/packages/portal-config/domain';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { TenantConfigResolver } from '../resolvers';

@Injectable()
export class TenantConfigGuard implements CanActivate {
  constructor(
    private router: Router,
    private titleSvc: Title,
    private configSvc: PortalService,
    private tenantSvc: TenantService,
    private resolver: TenantConfigResolver,
    private tenantInfoSvc: TenantInfoService
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    this.resolver.config = null;
    this.tenantSvc.setTenantId(undefined);

    const tenantId = await this.extractTenantId();
    if (!tenantId) return this.notFound();

    const config = await this.configSvc.getConfig(tenantId);
    if (!config) return this.notFound();

    this.updatePage(config);

    this.resolver.config = { ...config, tenantId };
    this.tenantSvc.setTenantId(tenantId);

    this.configSvc.setTheming(config);

    return true;
  }

  private updatePage(config: Config.PortalConfig) {
    this.titleSvc.setTitle(`${config.brandName} Admin Dashboard`);

    const favicon = document.querySelector<HTMLLinkElement>('#favicon');
    if (favicon && config.avatar) favicon.href = config.avatar;
  }

  private notFound() {
    return this.router.createUrlTree(['404']);
  }

  private async extractTenantId(): Promise<string | null> {
    return this.tenantInfoSvc.getLocalTenantId() ?? (await this.tenantSvc.getTenantIdFromDomain());
  }
}
