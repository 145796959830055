import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { PortalService } from '@vsolv/packages/portal-config/web';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { SecurityService } from '@wsphere/staff/web';
import { BehaviorSubject, combineLatest, ReplaySubject, switchMap } from 'rxjs';

export enum Type {
  INTERCOM = 'INTERCOM',
  TYPEFORM = 'TYPEFORM',
}
@Component({ selector: 'ws-chatbot-dialog', templateUrl: './chatbot.dialog.html' })
export class ChatbotDialog implements OnInit {
  constructor(private portalSvc: PortalService, private toastSvc: ToastService, private securitySvc: SecurityService) {}

  intercomEnabled = false;
  chatBotEnabled = false;

  @Input() type!: Type;
  @PropertyListener('type') private readonly type$ = new ReplaySubject<Type>(1);

  @Output() enabledChanged = new EventEmitter<{ intercomEnabled: boolean; chatBotEnabled: boolean }>();

  @ViewChild('disableBotDialog') disableBotDialog!: DialogComponent;

  form = new FormGroup({
    secret_key: new FormControl({ value: '', disabled: false }, [Validators.required]),
  });

  private readonly canManageCustomBotIntegration$ = combineLatest([
    this.type$,
    this.securitySvc.globalDistributors$,
  ]).pipe(
    switchMap(async ([type, globalDistributors]) => {
      if (type !== Type.INTERCOM) return true;
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      const hasAccess = await this.securitySvc.hasAccess('stf_ManageCustomBotIntegrationApp', permissionKeys);
      if (!hasAccess && type === Type.INTERCOM) this.form.controls.secret_key.disable();
      return hasAccess;
    })
  );

  private readonly canManageChatbotIntegration$ = combineLatest([
    this.type$,
    this.securitySvc.globalDistributors$,
  ]).pipe(
    switchMap(async ([type, globalDistributors]) => {
      if (type !== Type.TYPEFORM) return true;
      const permissionKeys = globalDistributors?.map(dist => dist.permissionKey) ?? null;
      const hasAccess = await this.securitySvc.hasAccess('stf_ManageChatbotIntegrationApp', permissionKeys);
      if (!hasAccess && type === Type.TYPEFORM) this.form.disable();
      return hasAccess;
    })
  );

  protected readonly canManage$ = combineLatest([
    this.canManageCustomBotIntegration$,
    this.canManageChatbotIntegration$,
  ]).pipe(
    switchMap(async ([customBotIntegration, chatbotIntegration]) => {
      return {
        customBotIntegration,
        chatbotIntegration,
      };
    })
  );

  message = '';
  canEnable = new BehaviorSubject<boolean>(false);
  showEnabledButton = false;

  async enable(type: Type) {
    let intercomChatMessengerAppID = null;
    let chatBotTypeFormId = null;

    if (type === Type.INTERCOM) {
      intercomChatMessengerAppID = this.form.controls.secret_key.value;
      chatBotTypeFormId = null;
    }
    if (type === Type.TYPEFORM) {
      intercomChatMessengerAppID = null;
      chatBotTypeFormId = this.form.controls.secret_key.value;
    }

    const response = await this.portalSvc
      .updateConfig({
        intercomChatMessengerAppID: intercomChatMessengerAppID,
        chatBotTypeFormId: chatBotTypeFormId,
      })
      .catch(() => {
        this.toastSvc.show({
          type: 'error',
          title: 'Error',
          text: 'Unable to enable the chat',
        });
      });

    if (response) {
      this.toastSvc.show({
        type: 'success',
        title: type === Type.INTERCOM ? 'Custom bot' : 'Chatbot' + ' integration app enabled',
        text: 'This application has been enabled. All of its features will be accessible unless app is disabled.',
      });
    }
    this.showEnableButton();
    this.canEnableButton();
  }

  async disable(type: Type) {
    let response;
    if (type === Type.INTERCOM)
      response = await this.portalSvc
        .updateConfig({
          intercomChatMessengerAppID: null,
        })
        .catch(() => {
          this.toastSvc.show({
            type: 'error',
            title: 'Error',
            text: 'Unable to disable the chat',
          });
        });
    if (type === Type.TYPEFORM)
      response = await this.portalSvc
        .updateConfig({
          chatBotTypeFormId: null,
        })
        .catch(() => {
          this.toastSvc.show({
            type: 'error',
            title: 'Error',
            text: 'Unable to disable the chat',
          });
        });

    if (response) {
      this.toastSvc.show({
        type: 'success',
        title: type === Type.INTERCOM ? 'Custom bot' : 'Chatbot' + ' integration app disabled',
        text: 'This application has been disabled. None of its features will be accessible until enabled again.',
      });
    }

    this.canEnableButton();
    this.showEnableButton();
    this.closeDisableDialog();
  }

  updateStatus() {
    this.enabledChanged.emit({
      intercomEnabled: this.portalSvc.config.intercomChatMessengerAppID ? true : false,
      chatBotEnabled: this.portalSvc.config.chatBotTypeFormId ? true : false,
    });
  }

  dialogOpened() {
    this.setFormValue();
    this.canEnableButton();
    this.showEnableButton();
  }

  ngOnInit(): void {
    this.updateStatus();
    this.setFormValue();
  }

  private setFormValue() {
    if (this.type === Type.INTERCOM)
      this.form.setValue({ secret_key: this.portalSvc.config.intercomChatMessengerAppID ?? null });
    if (this.type === Type.TYPEFORM)
      this.form.setValue({ secret_key: this.portalSvc.config.chatBotTypeFormId ?? null });
  }

  canEnableButton() {
    // if the other chat has a value
    const chatbotEnabled = this.type === Type.INTERCOM && this.portalSvc.config.chatBotTypeFormId;
    const intercomeEnabled = this.type === Type.TYPEFORM && this.portalSvc.config.intercomChatMessengerAppID;

    if (chatbotEnabled || intercomeEnabled) {
      this.message =
        'Only one chatbot app can be enabled at a time. Please disable the active app before enabling this one.';
      this.canEnable.next(false);
    } else {
      this.canEnable.next(true);
      this.message = '';
    }
  }

  showEnableButton() {
    const chatbotEnabled = this.type === Type.TYPEFORM && this.portalSvc.config.chatBotTypeFormId;
    const intercomeEnabled = this.type === Type.INTERCOM && this.portalSvc.config.intercomChatMessengerAppID;
    if (chatbotEnabled || intercomeEnabled) {
      this.showEnabledButton = false;
    } else {
      this.showEnabledButton = true;
    }
  }

  closeDisableDialog() {
    this.disableBotDialog.close();
  }
}
