import { Component, ElementRef, ViewChild } from '@angular/core';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';

@Component({
  selector: 'ws-decoded-key-preview-dialog',
  templateUrl: './decoded-key-preview.dialog.html',
})
export class DecodedKeyPreviewDialog {
  constructor(private toastSvc: ToastService) {}
  @ViewChild(DialogComponent) dialog!: DialogComponent;
  @ViewChild('keyInput') keyInput!: ElementRef;

  key?: string;

  openDialog(key: string) {
    this.key = key;
    this.dialog.open();
  }

  closeDialog() {
    this.dialog.close();
  }

  copy() {
    this.toastSvc.show({
      type: 'success',
      text: 'Your secret key is now copied to your clipboard!',
    });
  }
}
