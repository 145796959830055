<vs-card class="h-full">
  <vs-card-header
    [config]="{
      title: 'Warranty Overview',
      subtitle: 'Information on this warranty\'s associated customer, policy, and asset.'
    }"
  >
  </vs-card-header>

  <vs-card-content *ngIf="warranty">
    <vs-tabs theme="primary">
      <vs-tab [config]="{ label: 'Customer' }">
        <ws-warranty-customer-card
          [customer]="warranty.customer ?? null"
          [warranty]="warranty"
        ></ws-warranty-customer-card>
      </vs-tab>

      <vs-tab [config]="{ label: 'Policy' }">
        <ws-warranty-policy-card [warranty]="warranty"></ws-warranty-policy-card>
      </vs-tab>

      <vs-tab [config]="{ label: warranty.asset?.propertySet?.name ?? 'Asset' | titlecase }">
        <ws-warranty-asset-card
          *ngIf="warranty.propertySetSubmission"
          [propertySetId]="warranty.propertySetSubmission.setId"
          [propertyValues]="warranty.propertySetSubmission.value"
        ></ws-warranty-asset-card>
      </vs-tab>
    </vs-tabs>
  </vs-card-content>
</vs-card>
