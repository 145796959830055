import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[wsScrollTracker]',
})
export class TrackScrollingDirective {
  @Output() scrollEnded = new EventEmitter<boolean>();

  @HostListener('scroll', ['$event'])
  onScroll(event: ScrollEvent) {
    const tracker = event.target;
    let end = false;
    const limit = tracker.scrollHeight - tracker.clientHeight;

    if (event.target.scrollTop === limit) {
      end = true;
    }

    this.scrollEnded.emit(end);
  }
}

interface ScrollEvent {
  target: {
    clientHeight: number;
    scrollHeight: number;
    scrollTop: number;
  };
}
