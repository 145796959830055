import { Component, Input } from '@angular/core';

@Component({
  selector: 'ws-coverage-tooltip',
  templateUrl: './coverage-tooltip.component.html',
})
export class CoverageTooltipComponent {
  @Input() title?: string = '';
  @Input() description?: string = '';
  @Input() amount?: number = 0;
}
