import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { IconName } from '@vsolv/vectors-ui/icons';
import { ThemeColor } from '@vsolv/vectors-ui/theming';

export interface BadgeConfig {
  iconStart?: IconName;
  iconEnd?: IconName;
  displayStatusIcon?: boolean;
  statusIconTheme?: ThemeColor;
}

@Component({
  selector: 'vs-badge',
  templateUrl: './badge.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  // eslint-disable-next-line @angular-eslint/no-output-rename, @angular-eslint/no-output-native
  @Output('click') private _click = new EventEmitter();

  @Input() config?: BadgeConfig;

  @HostBinding('class') get classes() {
    const clickable = this._click.observed
      ? 'cursor-pointer select-none hover:bg-themed-100 ring-themed-200 focus:outline-none focus:ring-2 active:ring-2 [&:is(.theme-default)]:hover:bg-themed-300 [&:is(.theme-default)]:hover:ring-themed-300'
      : '';

    const themed =
      'theme-primary bg-themed-50 text-themed-600 [&:is(.theme-default)]:bg-themed-200 [&:is(.theme-default)]:text-themed-700';

    return `inline-flex items-center gap-1 rounded-3xl py-0.5 px-2.5 text-xs font-medium whitespace-nowrap ${themed} ${clickable}`;
  }
}
