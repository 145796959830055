import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewDirective } from './directives/image-preview/image-preview.component';

export * from './directives';

@NgModule({
  imports: [CommonModule, IconsModule],
  declarations: [ImagePreviewDirective],
  exports: [ImagePreviewDirective],
})
export class ImagePreviewModule {}
