<vs-dialog [config]="{ dialogContainerClass: 'w-[350px] sm:w-[500px] max-w-[600px]' }">
  <vs-dialog-header
    [config]="{
      mode: 'simple',
      showDivider: true,
      hideCloseButton: false,

      icon: 'send-01',
      iconTheme: 'primary',
      
      title: 'Invite Member',
      subtitle: 'Add the details of your new member and an invite link will be sent to them.'
    }"
  ></vs-dialog-header>

  <form [formGroup]="_form" (keydown.enter)="$event.preventDefault()">
    <div id="dialog-content" class="pt-6 flex flex-col gap-4">
      <ng-container *ngIf="permissionKey">
        <vs-form-field [config]="{ label: 'Select an existing staff member' }">
          <vs-select
            *ngIf="staffList$ | async as staffList"
            (valueChanges)="staff=$any($event)"
            [value]="staff"
            placeholder="Select an existing staff member"
          >
            <li vs-select-option *ngFor="let staff of staffList.items" [value]="staff">
              {{ staff.name }} ({{ staff.email }})
            </li>
          </vs-select>
        </vs-form-field>
        <vs-divider></vs-divider> Or Create New
      </ng-container>

      <vs-form-field [config]="{ label: 'Name*' }" appearance="responsive">
        <vs-input-container>
          <input #nameInput required formControlName="name" type="text" placeholder="Name" />
          <vs-icon name="help-circle" slot="end" vsTooltip="Name of the new staff member"></vs-icon>
        </vs-input-container>

        <p *ngIf="_form.controls.name.touched && _form.controls.name.invalid" slot="hint" class="text-danger">
          Please enter a name.
        </p>
      </vs-form-field>

      <vs-form-field [config]="{ label: 'Email*' }" appearance="responsive">
        <vs-input-container>
          <input required formControlName="email" type="email" placeholder="example@email.com" />
          <vs-icon name="help-circle" slot="end" vsTooltip="Email address of the new staff member"></vs-icon>
        </vs-input-container>

        <p slot="hint">
          <span *ngIf="_form.controls.email.touched && _form.controls.email.invalid; else baseHint" class="text-danger">
            <ng-template>Please enter a valid email address (example@email.com).</ng-template>
          </span>
          <ng-template #baseHint>We will send an invitation email to this address.</ng-template>
        </p>
      </vs-form-field>

      <div *ngIf="canGrant$ | async">
        <vs-divider class="mb-2"></vs-divider>
        <p class="text-sm mb-1">Manage roles for the member</p>

        <ws-role-picker formControlName="roles" [permissionKey]="permissionKey"></ws-role-picker>
        <!-- (roleAdded)="roleAdded($event)" -->
      </div>

      <div class="flex flex-col-reverse sm:flex-row sm:justify-end gap-3 pt-4">
        <button vs-button appearance="outline" class="w-full sm:w-auto" [disabled]="_submitting" (click)="close()">
          Cancel
        </button>

        <button
          vs-button
          theme="primary"
          class="w-full sm:w-auto"
          [disabled]="_submitting || _form.invalid"
          (click)="submit()"
        >
          Send Invite
          <vs-icon *ngIf="_submitting" name="refresh-cw-02" class="animate-spin"></vs-icon>
        </button>
      </div>
    </div>
  </form>
</vs-dialog>

<ws-full-admin-warning-dialog (closed)="open(true)"></ws-full-admin-warning-dialog>
