import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface SidebarNavUserConfig {
  photoUrl?: string;
  displayName: string;
  email: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routerLink?: string | any[] | null;
}

@Component({
  // Use the button selector with routerLink to allow tab/enter navvigation
  selector: 'vs-sidebar-nav-user, button[vs-sidebar-nav-user]',
  templateUrl: './sidebar-nav-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavUserComponent {
  @HostBinding('class') get styles() {
    return `group flex text-left items-center gap-3 pl-2 pr-3 py-2 w-full select-none rounded-md cursor-pointer text-gray-700 hover:bg-gray-50 hover:text-gray-900 active:bg-transparent hover:active:bg-transparent active:ring-4 ring-gray-100 focus:outline-none focus:ring-4 `;
  }
  @Input() config!: SidebarNavUserConfig;

  constructor(private router: Router, private route: ActivatedRoute) {}

  @HostListener('click')
  routeOnClick() {
    if (this.config?.routerLink)
      this.router.navigate(
        typeof this.config?.routerLink === 'string' ? [this.config?.routerLink] : this.config?.routerLink,
        { relativeTo: this.route }
      );
  }

  isActive() {
    return this.config?.routerLink
      ? this.router.isActive(
          this.router.createUrlTree(
            typeof this.config?.routerLink === 'string' ? [this.config?.routerLink] : this.config?.routerLink,
            { relativeTo: this.route }
          ),
          true
        )
      : false;
  }
}
