/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, forwardRef, Input, OnDestroy } from '@angular/core';
import { ControlValueAccessor, FormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { Asset } from '@wsphere/assets/domain';

import { BehaviorSubject, distinctUntilChanged, map, Subscription, tap } from 'rxjs';

@Component({
  selector: 'ws-asset-details-input',
  templateUrl: './asset-details-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => AssetDetailsInputComponent),
    },
  ],
})
export class AssetDetailsInputComponent implements ControlValueAccessor, OnDestroy {
  constructor(private fb: FormBuilder) {}

  COUNTRIES = Asset.COUNTRIES;

  @PropertyListener('type') type$ = new BehaviorSubject<string | null>(null);
  @Input() type!: string;

  form = this.fb.group({
    line1: [{ value: null as string | null, disabled: false }, Validators.required],
    line2: [{ value: null as string | null, disabled: false }],
    zip: [{ value: null as string | null, disabled: false }, Validators.required],
    city: [{ value: null as string | null, disabled: false }, Validators.required],
    state: [{ value: null as string | null, disabled: false }, Validators.required],
    country: [{ value: null as string | null, disabled: false }, [Validators.required, Validators.pattern(/US|CA/)]],
  });

  stateValues$ = new BehaviorSubject<{ name: string; abbreviation: string }[]>([]);

  states$ = this.form.controls.country.valueChanges.pipe(
    distinctUntilChanged(),
    tap(() => {
      this.form.controls.zip.clearValidators();
    }),
    map(country => {
      this.validateZipAndState(country);
    }),
    tap(() => {
      this.form.controls.state.setValue(null);
      this.form.controls.zip.setValue(null);
    })
  );

  subscription?: Subscription;

  onChange = (_homeAsset: Asset.Model) => {
    /* noop */
  };

  onTouched = () => {
    /* noop */
  };

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  writeValue(value: any): void {
    //preload states so vs-select form control doesn't throw error
    let country = value?.country ?? value?.address?.country;
    if (country !== 'US' && country !== 'CA') country = null;
    let state = value?.state ?? value?.address?.state ?? null;
    if (
      state &&
      ((country === 'US' && !Asset.US_STATES.includes(state)) ||
        (country === 'CA' && !Asset.CA_PROVINCES.includes(state)) ||
        (country === null && (!Asset.US_STATES.includes(state) || !Asset.CA_PROVINCES.includes(state))))
    ) {
      state = null;
    }
    this.validateZipAndState(country);

    if (value) {
      this.form.setValue({
        line1: value.line1 || value.address?.line1 || null,
        line2: value.line2 || value.address?.line2 || null,
        zip: value.zip || value.address?.zip || null,
        city: value.city || value.address?.city || null,
        state: state,
        country: country,
      });
    }
  }
  registerOnChange(fn: any): void {
    this.subscription = this.form.valueChanges.subscribe(fn);
  }
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
      return;
    }
    this.form.enable();
  }

  //set the correct zip validators and load the correct states
  validateZipAndState(country: string | null) {
    if (country === 'CA') {
      this.form.controls.zip.addValidators([
        Validators.pattern('^(?!.*[DFIOQU|dfioqu])[A-VXY|a-vxy][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$'),
        Validators.required,
      ]);
      this.stateValues$.next([...Asset.CA_PROVINCES]);
    }
    if (country === 'US') {
      this.form.controls.zip.addValidators([Validators.pattern('^\\d{5}(?:[-\\s]\\d{4})?$'), Validators.required]);
      this.stateValues$.next(Asset.US_STATES);
    } else {
      this.stateValues$.next([...Asset.CA_PROVINCES, ...Asset.US_STATES]);
    }
  }
}
