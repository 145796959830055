<as-split
  #split
  unit="pixel"
  [gutterSize]="8"
  [useTransition]="true"
  (dragEnd)="checkClose($event)"
  (transitionEnd)="
    leftButtonOverlay.open && leftButtonOverlay.overlayRef.updatePosition();
    transitioningLeft = false;
    rightButtonOverlay.open && rightButtonOverlay.overlayRef.updatePosition();
    transitioningRight = false
  "
>
  <as-split-area
    class="relative bg-base"
    cdkOverlayOrigin
    #leftPane="cdkOverlayOrigin"
    [order]="0"
    [visible]="!(!config.left || leftCollapsed)"
    [size]="config.left?.width || null"
    [minSize]="0"
    [maxSize]="config.left?.maxWidth || config.left?.width || null"
  >
    <ng-content select="[slot='left']"></ng-content>
  </as-split-area>
  <ng-template
    #leftButtonOverlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayOrigin]="leftPane"
    [cdkConnectedOverlayDisableClose]="true"
    [cdkConnectedOverlayPositions]="[
      {
        originX: 'end',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: -9.5
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'start',
        overlayY: 'top',
        offsetX: 8
      }
    ]"
    [cdkConnectedOverlayOpen]="!!config.left"
  >
    <button
      (click)="leftCollapsed = !leftCollapsed; transitioningLeft = true"
      [class.opacity-0]="transitioningLeft"
      class="group transition-opacity flex items-center justify-center w-5 h-5 rounded-full bg-base hover:bg-gray-100 border border-gray-200 mt-2 z-[1000] active:bg-base focus:outline-none active:ring-4 ring-gray-100 focus:ring-4"
    >
      <vs-icon
        class="transition-transform delay-200 text-gray-700 group-hover:text-gray-900"
        name="chevron-left"
        size="14"
        [class.rotate-180]="leftCollapsed || leftShouldClose"
      ></vs-icon>
    </button>
  </ng-template>
  <as-split-area
    cdkScrollable
    class="relative scrollbar-thin"
    [order]="1"
    [class.border-l]="!!config.left && !leftCollapsed"
    [class.border-r]="!!config.right && !rightCollapsed"
  >
    <vs-app-page [config]="config" [class]="config.appPageClass">
      <div class="empty:hidden" slot="header-end">
        <ng-content select="[slot='header-end']"></ng-content>
      </div>

      <ng-content></ng-content>
    </vs-app-page>
  </as-split-area>
  <ng-template
    #rightButtonOverlay="cdkConnectedOverlay"
    cdkConnectedOverlay
    [cdkConnectedOverlayPush]="true"
    [cdkConnectedOverlayOrigin]="rightPane"
    [cdkConnectedOverlayDisableClose]="true"
    [cdkConnectedOverlayPositions]="[
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: 9.5
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
        offsetX: -8
      }
    ]"
    [cdkConnectedOverlayOpen]="!!config.right"
  >
    <button
      (click)="rightCollapsed = !rightCollapsed; transitioningRight = true"
      [class.opacity-0]="transitioningRight"
      class="group transition-opacity flex items-center justify-center w-5 h-5 rounded-full bg-base hover:bg-gray-100 border border-gray-200 mt-2 z-[1000] active:bg-base"
    >
      <vs-icon
        class="transition-transform delay-200 text-gray-700 group-hover:text-gray-900"
        name="chevron-right"
        size="14"
        [class.rotate-180]="rightCollapsed || rightShouldClose"
      ></vs-icon>
    </button>
  </ng-template>
  <as-split-area
    class="relative"
    cdkOverlayOrigin
    #rightPane="cdkOverlayOrigin"
    [order]="2"
    [size]="config.right?.width || null"
    [visible]="!!config.right && !rightCollapsed"
    [minSize]="0"
    [maxSize]="config.right?.maxWidth || config.right?.width || null"
  >
    <ng-content select="[slot='right']"></ng-content>
  </as-split-area>
</as-split>
