<ng-container *vsLet="claim$ | async as claim">
  <div *ngIf="item$ | async as item" class="flex flex-col gap-4">
    <p class="font-medium text-gray-500 mb-2">Adjudication Details</p>

    <div class="flex flex-col gap-4 border rounded-xl p-6">
      <div class="flex gap-2">
        <p class="text-sm font-medium text-gray-700 py-3 basis-1/4">Decision</p>
        <p
          theme="primary"
          class="font-semibold text-gray-700 self-center px-5 py-2 rounded-lg border border-themed-200 bg-themed-50"
        >
          {{ item.status === draft ? 'Reviewing' : (item.status | titlecase) }}
        </p>
      </div>

      <vs-divider></vs-divider>

      <div class="flex gap-2">
        <p class="text-sm font-medium text-gray-700 basis-1/4">Reasoning</p>
        <p
          *ngIf="item.adjudicationReason"
          class="text-sm font-normal text-gray-600 basis-3/4 p-3 rounded-lg border max-h-[100px] overflow-y-auto scrollbar-thin"
        >
          {{ item.adjudicationReason }}
        </p>
      </div>
    </div>

    <div
      *vsLet="canManageAdjudication$ | async as canManageAdjudication"
      class="flex gap-2"
      [class.justify-end]="claim?.status !== open || !canManageAdjudication || item.deleted"
      [class.justify-between]="claim?.status === open && canManageAdjudication && !item.deleted"
    >
      <div
        tooltipStyle="light"
        [vsTooltip]="
          !item.cure?.title
            ? {
                title: 'Cure Needed',
                subtitle: 'A fully finished cure is needed before you can adjudicate this claim item!'
              }
            : ''
        "
      >
        <button
          *ngIf="canManageAdjudication && claim?.status === open && !item.deleted"
          vs-button
          appearance="outline"
          [disabled]="!item.cure?.title"
          (click)="navigateTo('manage-adjudication')"
        >
          Edit
        </button>
      </div>

      <div class="flex gap-2">
        <button vs-button appearance="outline" (click)="navigateTo('cure')">Back</button>
        <button
          vs-button
          theme="primary"
          [disabled]="!item.adjudicationReason || item.status === draft"
          (click)="navigateTo('resolve')"
        >
          Next
        </button>
      </div>
    </div>
  </div>
</ng-container>
