import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'vs-date-picker',
  templateUrl: './date-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent implements OnInit {
  @Input() range = false;

  @Output() selectionChanged = new EventEmitter<Date | { startDate: Date | null; endDate: Date | null } | null>();

  @Input() value?: Date | null;
  currentDay: Date = new Date();

  @Input() minDate?: Date | null;
  @Input() maxDate?: Date | null;

  firstDay: Date = new Date(this.currentDay.getFullYear(), this.currentDay.getMonth(), 1);
  lastDay: Date = new Date(this.currentDay.getFullYear(), this.currentDay.getMonth() + 1, 0);
  firstSelectedDay: Date | null = null;
  lastSelectedDay: Date | null = null;

  reselectDay: Date | null = null;

  days: Date[][] = [];
  weekDays: string[] = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  years: number[] = [];

  form = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl(),
  });

  daySelected(date: Date) {
    const firstDaySelected =
      this.firstSelectedDay &&
      date.getFullYear() === this.firstSelectedDay.getFullYear() &&
      date.getMonth() === this.firstSelectedDay.getMonth() &&
      date.getDate() === this.firstSelectedDay.getDate();

    const lastDaySelected = this.range
      ? this.lastSelectedDay &&
        date.getFullYear() === this.lastSelectedDay.getFullYear() &&
        date.getMonth() === this.lastSelectedDay.getMonth() &&
        date.getDate() === this.lastSelectedDay.getDate()
      : false;

    return firstDaySelected || lastDaySelected;
  }

  today(date: Date) {
    return (
      date.getFullYear() === this.currentDay.getFullYear() &&
      date.getMonth() === this.currentDay.getMonth() &&
      date.getDate() === this.currentDay.getDate()
    );
  }

  dayInRange(date: Date) {
    if (!this.firstSelectedDay || !this.lastSelectedDay) return false;
    else {
      const dayTime = date.getTime();
      return this.firstSelectedDay?.getTime() < dayTime && dayTime < this.lastSelectedDay?.getTime();
    }
  }

  dayInMonth(date: Date) {
    return date.getFullYear() === this.firstDay.getFullYear() && date.getMonth() === this.firstDay.getMonth();
  }

  backwardsDays() {
    if (!this.lastSelectedDay) return false;
    return (this.firstSelectedDay?.getTime() || 0) > (this.lastSelectedDay?.getTime() || 0);
  }

  selectDay(date: Date) {
    // If a day to reselect has been chosen
    if (this.reselectDay) {
      if (
        (this.firstSelectedDay?.getTime() || 0) === this.reselectDay.getTime() &&
        (this.firstSelectedDay?.getTime() || 0) !== date.getTime()
      ) {
        this.firstSelectedDay = new Date(date);
      } else if (
        (this.lastSelectedDay?.getTime() || 0) === this.reselectDay.getTime() &&
        (this.lastSelectedDay?.getTime() || 0) !== date.getTime()
      ) {
        this.lastSelectedDay = new Date(date);
      }

      // Switch first and last days if first is after last
      if (
        this.firstSelectedDay &&
        this.lastSelectedDay &&
        (this.lastSelectedDay?.getTime() || 0) < (this.firstSelectedDay?.getTime() || 0)
      ) {
        const first = new Date(this.firstSelectedDay);
        this.firstSelectedDay = new Date(this.lastSelectedDay);
        this.lastSelectedDay = first;
      }

      // Update the range inputs
      this.form.patchValue({
        startDate: moment(this.firstSelectedDay).format('MMM DD, yyyy'),
        endDate: this.lastSelectedDay ? moment(this.lastSelectedDay).format('MMM DD, yyyy') : '',
      });

      this.reselectDay = null;
    } else if (
      // If you're selecting  day to rechoose
      this.range &&
      this.lastSelectedDay &&
      (date.getTime() === this.firstSelectedDay?.getTime() || date.getTime() === this.lastSelectedDay?.getTime())
    ) {
      this.reselectDay = new Date(date);
    } else {
      // If you're selecting a single date, setting a range, or resetting a range
      if (!this.range || !this.firstSelectedDay || this.lastSelectedDay) {
        this.firstSelectedDay = new Date(date);
        this.lastSelectedDay = null;
      } else {
        // Switch first and last days if first is after last
        if (date.getTime() < this.firstSelectedDay.getTime()) {
          this.lastSelectedDay = this.firstSelectedDay;
          this.firstSelectedDay = new Date(date);
        } else this.lastSelectedDay = new Date(date);
      }

      // Update the range inputs
      if (this.range) {
        this.form.patchValue({
          startDate: moment(this.firstSelectedDay).format('MMM DD, yyyy'),
          endDate: this.lastSelectedDay ? moment(this.lastSelectedDay).format('MMM DD, yyyy') : '',
        });
      }
    }

    // Emit new dates
    if (this.range) this.selectionChanged.emit({ startDate: this.firstSelectedDay, endDate: this.lastSelectedDay });
    else this.selectionChanged.emit(this.firstSelectedDay);
  }

  clearSelection() {
    this.selectionChanged.emit(null);
  }

  // Used to select the last week, month, or year
  selectLast(range: string) {
    this.firstSelectedDay = new Date(this.currentDay);
    this.lastSelectedDay = new Date(this.currentDay);

    // Find out how far back the first selected date must be
    let daysBack = 0;
    if (range === 'week') daysBack = 7;
    else if (range === 'month') {
      daysBack = new Date(this.currentDay.getFullYear(), this.currentDay.getMonth(), 0).getDate();
    } else {
      const year = this.lastSelectedDay.getFullYear();
      daysBack = (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
    }
    this.firstSelectedDay.setDate(this.firstSelectedDay.getDate() - daysBack);

    // Update the range inputs
    this.form.patchValue({
      startDate: moment(this.firstSelectedDay).format('MMM DD, yyyy'),
      endDate: moment(this.lastSelectedDay).format('MMM DD, yyyy'),
    });

    // Update the shown date based on the first selected day
    this.firstDay = new Date(this.firstSelectedDay.getFullYear(), this.firstSelectedDay.getMonth(), 1);
    this.lastDay = new Date(this.firstSelectedDay.getFullYear(), this.firstSelectedDay.getMonth() + 1, 0);
    this.renumberDates();

    // Emit new dates
    if (this.range) this.selectionChanged.emit({ startDate: this.firstSelectedDay, endDate: this.lastSelectedDay });
    else this.selectionChanged.emit(this.firstSelectedDay);
  }

  selectDayFromInput(start: string | null, end: string | null) {
    const date = new Date(start || end || 'Invalid');
    if (date.toString() !== 'Invalid Date') {
      if (start) {
        this.firstSelectedDay = date;
        this.form.patchValue({ startDate: moment(date).format('MMM DD, yyyy') });
      }
      if (end) {
        this.lastSelectedDay = date;
        this.form.patchValue({ endDate: moment(date).format('MMM DD, yyyy') });
      }

      // Update the shown date based on the first selected day
      this.firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      this.lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);
      this.renumberDates();

      // Emit new dates
      if (this.range) {
        if ((this.firstSelectedDay?.getTime() || 0) < (this.lastSelectedDay?.getTime() || 0)) {
          this.selectionChanged.emit({ startDate: this.firstSelectedDay, endDate: this.lastSelectedDay });
        } else this.selectionChanged.emit({ startDate: null, endDate: null });
      } else this.selectionChanged.emit(this.firstSelectedDay);
    }
  }

  prevMonth() {
    this.firstDay = new Date(this.firstDay.getFullYear(), this.firstDay.getMonth() - 1, 1);
    this.lastDay = new Date(this.lastDay.getFullYear(), this.lastDay.getMonth(), 0);
    this.renumberDates();
  }

  nextMonth() {
    this.firstDay = new Date(this.firstDay.getFullYear(), this.firstDay.getMonth() + 1, 1);
    this.lastDay = new Date(this.lastDay.getFullYear(), this.lastDay.getMonth() + 2, 0);
    this.renumberDates();
  }

  selectMonth(month: number) {
    const newFirstDay = new Date(this.firstDay);
    newFirstDay.setMonth(month);

    this.firstDay = new Date(newFirstDay.getFullYear(), newFirstDay.getMonth(), 1);
    this.lastDay = new Date(newFirstDay.getFullYear(), newFirstDay.getMonth() + 1, 0);
    this.renumberDates();
  }

  selectYear(year: number) {
    const newFirstDay = new Date(this.firstDay);
    newFirstDay.setFullYear(year);

    this.firstDay = new Date(newFirstDay.getFullYear(), newFirstDay.getMonth(), 1);
    this.lastDay = new Date(newFirstDay.getFullYear(), newFirstDay.getMonth() + 1, 0);
    this.renumberDates();
  }

  renumberDates() {
    this.days = [];
    let week = 0;
    const currentDay = new Date(this.firstDay);
    const lastDay = new Date(this.lastDay);

    // Start days on a sunday
    while (currentDay.getDay() > 0) {
      currentDay.setDate(currentDay.getDate() - 1);
    }

    // End days on a saturday
    while (lastDay.getDay() < 6) {
      lastDay.setDate(lastDay.getDate() + 1);
    }

    // Create weeks of days
    while (currentDay.getTime() !== lastDay.getTime()) {
      if ((this.days[week]?.length || 0) % 7 === 0) {
        if (this.days?.length) week += 1;
        this.days.push([]);
      }

      this.days[week].push(new Date(currentDay));
      currentDay.setDate(currentDay.getDate() + 1);
    }
    this.days[week].push(lastDay);
  }

  ngOnInit() {
    this.currentDay = this.value ?? new Date(moment(this.currentDay).startOf('day').toString());
    this.firstSelectedDay = new Date(this.currentDay);

    this.renumberDates();

    const year = this.currentDay.getFullYear();
    for (let i = year; i > 1900; i--) this.years.push(i);

    if (this.range) this.form.patchValue({ startDate: moment(this.currentDay).format('MMM DD, yyyy') });
  }
}
