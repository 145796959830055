import { Documents } from '@vsolv/packages/documents/domain';
import { Note } from '@vsolv/packages/notes/domain';
import { StorageItem } from '@vsolv/packages/storage/domain';
import { IconName } from '@vsolv/vectors-ui/icons';
import { InfoCardConfig } from '@vsolv/vectors-ui/info-card';
import { Claim } from '@wsphere/warranties/domain';
import { FormLink } from './form-link';
import { Model, ObjectType } from './models';

const maxLength = 25;

export function getLinkType(link: Model, owner: ObjectType) {
  if (owner !== ObjectType.ACTIVITY && (link.activityId_1 || link.activityId_2)) return ObjectType.ACTIVITY;
  if (owner !== ObjectType.ASSET && (link.assetId_1 || link.assetId_2)) return ObjectType.ASSET;
  if (owner !== ObjectType.CLAIM && (link.claimId_1 || link.claimId_2)) return ObjectType.CLAIM;
  if (owner !== ObjectType.CLAIM_ITEM && (link.claimItemId_1 || link.claimItemId_2)) return ObjectType.CLAIM_ITEM;
  if (owner !== ObjectType.CUSTOMER && (link.customerId_1 || link.customerId_2)) return ObjectType.CUSTOMER;
  if (owner !== ObjectType.DISTRIBUTOR && (link.distributorId_1 || link.distributorId_2)) return ObjectType.DISTRIBUTOR;
  if (owner !== ObjectType.DOCUMENT && (link.documentId_1 || link.documentId_2)) return ObjectType.DOCUMENT;
  if (owner !== ObjectType.NOTE && (link.noteId_1 || link.noteId_2)) return ObjectType.NOTE;
  if (owner !== ObjectType.PLAN && (link.planId_1 || link.planId_2)) return ObjectType.PLAN;
  if (owner !== ObjectType.POLICY && (link.policyId_1 || link.policyId_2)) return ObjectType.POLICY;
  if (owner !== ObjectType.STATUS_CHANGE && (link.statusChangeId_1 || link.statusChangeId_2))
    return ObjectType.STATUS_CHANGE;
  if (owner !== ObjectType.WARRANTY && (link.warrantyId_1 || link.warrantyId_2)) return ObjectType.WARRANTY;
  if (owner !== ObjectType.PAYMENT && (link.paymentId_1 || link.paymentId_2)) return ObjectType.PAYMENT;
  if (owner !== ObjectType.STORAGE_ITEM && (link.storageItemId_1 || link.storageItemId_2))
    return ObjectType.STORAGE_ITEM;
  else return owner;
}

export function getLinkObject(link: Model, type: ObjectType, excludeId?: string) {
  switch (type) {
    case ObjectType.ACTIVITY:
      return link.activityId_1 === excludeId ? link.activity_2 : link.activity_1;
    case ObjectType.ASSET:
      return link.assetId_1 === excludeId ? link.asset_2 : link.asset_1;
    case ObjectType.CLAIM:
      return link.claimId_1 === excludeId ? link.claim_2 : link.claim_1;
    case ObjectType.CLAIM_ITEM:
      return link.claimItemId_1 === excludeId ? link.claimItem_2 : link.claimItem_1;
    case ObjectType.CUSTOMER:
      return link.customerId_1 === excludeId ? link.customer_2 : link.customer_1;
    case ObjectType.DISTRIBUTOR:
      return link.distributorId_1 === excludeId ? link.distributor_2 : link.distributor_1;
    case ObjectType.DOCUMENT:
      return link.documentId_1 === excludeId ? link.document_2 : link.document_1;
    case ObjectType.NOTE:
      return link.noteId_1 === excludeId ? link.note_2 : link.note_1;
    case ObjectType.PLAN:
      return link.planId_1 === excludeId ? link.plan_2 : link.plan_1;
    case ObjectType.POLICY:
      return link.policyId_1 === excludeId ? link.policy_2 : link.policy_1;
    case ObjectType.STATUS_CHANGE:
      return link.statusChangeId_1 === excludeId ? link.statusChange_2 : link.statusChange_1;
    case ObjectType.WARRANTY:
      return link.warrantyId_1 === excludeId ? link.warranty_2 : link.warranty_1;
    case ObjectType.PAYMENT:
      return link.paymentId_1 === excludeId ? link.payment_2 : link.payment_1;
    case ObjectType.STORAGE_ITEM:
      return link.storageItemId_1 === excludeId ? link.storageItem_2 : link.storageItem_1;
    default:
      return null;
  }
}

export function getPickerLinks(links: Model[], owner: ObjectType, excludeId?: string) {
  return links.reduce((acc, link) => {
    const type = getLinkType(link, owner);
    if (!type) return acc;

    const object = getLinkObject(link, type, excludeId);
    if (!object) return acc;

    acc.push({ id: link.id, object, type });
    return acc;
  }, [] as FormLink[]);
}

export function getLinkIcon(type: ObjectType): IconName {
  switch (type) {
    case ObjectType.CLAIM_ITEM:
      return 'file-shield-03';
    case ObjectType.DOCUMENT:
      return 'attachment-02';
    case ObjectType.STORAGE_ITEM:
      return 'attachment-02';
    case ObjectType.NOTE:
      return 'sticker-square';
    case ObjectType.PAYMENT:
      return 'bank-note-01';
    default:
      return 'info-circle';
  }
}

export function getLinkTitle(link: Model, type: ObjectType, excludeId?: string) {
  switch (type) {
    case ObjectType.CLAIM:
      return (
        (excludeId !== link.claimId_1
          ? 'Claim #' + link.claim_1?.claimNumber
          : 'Claim #' + link.claim_2?.claimNumber) || 'Claim'
      );

    case ObjectType.CLAIM_ITEM:
      return (
        (excludeId !== link.claimItemId_1 ? link.claimItem_1?.coverage?.title : link.claimItem_2?.coverage?.title) ||
        'Other'
      );

    case ObjectType.DOCUMENT:
      return (excludeId !== link.documentId_1 ? link.document_1?.title : link.document_2?.title) || 'Document';

    case ObjectType.NOTE:
      return (excludeId !== link.noteId_1 ? link.note_1?.title : link.note_2?.title) || 'Note';

    case ObjectType.PAYMENT:
      return (excludeId !== link.paymentId_1 ? link.payment_1?.title : link.payment_2?.title) || 'Payment';

    case ObjectType.STORAGE_ITEM:
      return (excludeId !== link.storageItemId_1 ? link.storageItem_1?.title : link.storageItem_2?.title) || 'Document';

    default:
      return '';
  }
}

export function getLinkSubtitle(link: Model, type: ObjectType, excludeId?: string) {
  switch (type) {
    case ObjectType.CLAIM:
      return excludeId !== link.claimId_1
        ? (link.claim_1?.complaint?.length || 0) > maxLength
          ? link.claim_1?.complaint.substring(0, maxLength) + '...'
          : link.claim_1?.complaint || 'Claim'
        : (link.claim_2?.complaint?.length || 0) > maxLength
        ? link.claim_2?.complaint.substring(0, maxLength) + '...'
        : link.claim_2?.complaint || 'Claim';

    case ObjectType.CLAIM_ITEM:
      return excludeId !== link.claimItemId_1
        ? link.claimItem_1?.status !== Claim.ClaimItem.Status.RESOLVED
          ? 'Est $' + ((link.claimItem_1?.cure?.estimatedCost || 0) / 100).toFixed(2)
          : 'Act $' + ((link.claimItem_1?.amount?.total || 0) / 100).toFixed(2)
        : link.claimItem_2?.status !== Claim.ClaimItem.Status.RESOLVED
        ? 'Est $' + ((link.claimItem_2?.cure?.estimatedCost || 0) / 100).toFixed(2)
        : 'Act $' + ((link.claimItem_2?.amount?.total || 0) / 100).toFixed(2);

    case ObjectType.DOCUMENT:
      return excludeId !== link.documentId_1
        ? (link.document_1?.description?.length || 0) > maxLength
          ? link.document_1?.description?.substring(0, maxLength) + '...'
          : link.document_1?.description || 'Document'
        : (link.document_2?.description?.length || 0) > maxLength
        ? link.document_2?.description?.substring(0, maxLength) + '...'
        : link.document_2?.description || 'Document';

    case ObjectType.NOTE:
      return excludeId !== link.noteId_1
        ? (link.note_1?.content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '')?.length || 0) > maxLength
          ? link.note_1?.content
              .replace(/<[^>]*>/g, ' ')
              .replace('&nbsp;', '')
              .substring(0, maxLength) + '...'
          : link.note_1?.content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '') || 'Note'
        : (link.note_2?.content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '')?.length || 0) > maxLength
        ? link.note_2?.content
            .replace(/<[^>]*>/g, ' ')
            .replace('&nbsp;', '')
            .substring(0, maxLength) + '...'
        : link.note_2?.content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '') || 'Note';

    case ObjectType.PAYMENT:
      return getPaymentAmount(link, excludeId);

    case ObjectType.STORAGE_ITEM:
      return excludeId !== link.storageItemId_1
        ? (link.storageItem_1?.description?.length || 0) > maxLength
          ? link.storageItem_1?.description?.substring(0, maxLength) + '...'
          : link.storageItem_1?.description || 'Document'
        : (link.storageItem_2?.description?.length || 0) > maxLength
        ? link.storageItem_2?.description?.substring(0, maxLength) + '...'
        : link.storageItem_2?.description || 'Document';
    default:
      return '';
  }
}

function getPaymentAmount(link: Model, excludeId?: string) {
  if (excludeId === link.paymentId_1) {
    if (link.payment_2) {
      return (link.payment_2.amount / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    } else {
      return 'Payment';
    }
  } else {
    if (link.payment_1) {
      return (link.payment_1.amount / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    } else if (link.payment_2) {
      return (link.payment_2.amount / 100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      });
    } else {
      return 'Payment';
    }
  }
}

export function getLinkInfoCardConfig(link: Model, type: ObjectType, excludeId?: string): InfoCardConfig {
  const icon = getLinkIcon(type);
  const title = getLinkTitle(link, type, excludeId);
  const subtitle = getLinkSubtitle(link, type, excludeId);

  return { icon, title, subtitle };
}

export function getObjectTitle(object: unknown, type: ObjectType) {
  switch (type) {
    case ObjectType.CLAIM:
      return 'Claim #' + (object as Claim.Model)?.claimNumber;
    case ObjectType.CLAIM_ITEM:
      return (object as Claim.ClaimItem.Model)?.coverage?.title || 'Other';
    case ObjectType.DOCUMENT:
      return (object as Documents.Model)?.title || 'Document';
    case ObjectType.NOTE:
      return (object as Note.Model)?.title || 'Note';
    case ObjectType.PAYMENT:
      return (object as Claim.ClaimPayment.Model)?.title || 'Payment';
    case ObjectType.STORAGE_ITEM:
      return (object as StorageItem.Model)?.title || 'Document';
    default:
      return '';
  }
}

export function getObjectSubtitle(object: unknown, type: ObjectType) {
  switch (type) {
    case ObjectType.CLAIM:
      return ((object as Claim.Model)?.complaint?.length || 0) > maxLength
        ? (object as Claim.Model)?.complaint.substring(0, maxLength) + '...'
        : (object as Claim.Model)?.complaint || 'Claim';

    case ObjectType.CLAIM_ITEM:
      return (
        '$' +
        ((object as Claim.ClaimItem.Model)?.status !== Claim.ClaimItem.Status.RESOLVED
          ? ((object as Claim.ClaimItem.Model)?.cure?.estimatedCost || 0) / 100
          : ((object as Claim.ClaimItem.Model)?.amount?.total || 0) / 100
        ).toFixed(2)
      );

    case ObjectType.DOCUMENT:
      return (object as Documents.Model).description?.length || 0 > maxLength
        ? (object as Documents.Model)?.description?.substring(0, maxLength) + '...'
        : (object as Documents.Model)?.description || 'Document';

    case ObjectType.NOTE:
      return ((object as Note.Model).content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '')?.length || 0) > maxLength
        ? (object as Note.Model)?.content
            .replace(/<[^>]*>/g, ' ')
            .replace('&nbsp;', '')
            .substring(0, maxLength) + '...'
        : (object as Note.Model)?.content.replace(/<[^>]*>/g, ' ').replace('&nbsp;', '') || 'Note';

    case ObjectType.PAYMENT:
      return (object as Claim.ClaimPayment.Model).description?.length || 0 > maxLength
        ? (object as Claim.ClaimPayment.Model)?.description?.substring(0, maxLength) + '...'
        : (object as Claim.ClaimPayment.Model)?.description || 'Payment';
    case ObjectType.STORAGE_ITEM:
      return (object as StorageItem.Model).description?.length || 0 > maxLength
        ? (object as StorageItem.Model)?.description?.substring(0, maxLength) + '...'
        : (object as StorageItem.Model)?.description || 'Document';
    default:
      return '';
  }
}
