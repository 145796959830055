<ng-template #item>
  <div class="flex items-center gap-2" [class]="config?.class">
    <vs-icon *ngIf="config && config.iconStart" [name]="config.iconStart" size="20"></vs-icon>
    <p *ngIf="config && config.label" class="whitespace-nowrap text-sm font-medium">{{ config.label }}</p>
    <vs-icon *ngIf="config && config.iconEnd" [name]="config.iconEnd" size="20"></vs-icon>
  </div>
</ng-template>

<div class="text-gray-600 group-last:text-primary-500">
  <a
    *ngIf="config && config.routerLink; else item"
    [routerLink]="config.routerLink"
    class="focus:outline-none {{ _anchorBaseClasses }} {{ _anchorPrimaryClasses }}"
  >
    <ng-container *ngTemplateOutlet="item"></ng-container>
  </a>
</div>

<vs-icon [name]="_separatorIcon" size="16" class="text-gray-300 group-last:hidden"></vs-icon>
