import { ComponentType } from '@angular/cdk/portal';
import { Component, HostBinding } from '@angular/core';
import { PropertyListener } from '@vsolv/dev-kit/rx';

export type TableCellComponentData<T> = T | ((item: unknown) => T | Promise<T>);

@Component({ template: '' })
export abstract class TableCellComponent<T> {
  @HostBinding('class') private _classes = 'flex';

  _item?: unknown;
  _data?: TableCellComponentData<T>;
  _config?: TableColumnConfig;

  data: T | null = null;

  @PropertyListener('_config')
  private async _computeClasses() {
    const alignment =
      this._config?.align === 'center' ? 'justify-center' : this._config?.align === 'right' ? 'justify-end' : '';
    this._classes = `flex ${alignment}`;
  }

  @PropertyListener('_data', '_item')
  private async _computeData() {
    if (!this._data || !this._item) {
      this.data = null;
    } else {
      this.data =
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        typeof this._data === 'function' ? await Promise.resolve((this._data as any)(this._item)) : this._data;
    }
  }
}

export interface TableColumnConfig {
  header?: string;

  sticky?: boolean;
  stickyEnd?: boolean;

  fitContent?: boolean;

  sortable?: boolean;
  sort?: 'ASC' | 'DESC';
  sortChanged?(sort?: 'ASC' | 'DESC'): void;

  hidden?: boolean;

  align?: 'left' | 'center' | 'right';
  customCellClasses?: string;
}

export abstract class TableColumn<I, T = unknown> {
  readonly id = '' + Math.random();

  abstract component: ComponentType<TableCellComponent<T>>;

  constructor(public config: TableColumnConfig, public data: T | ((item: I) => T | Promise<T>)) {}

  toggleSort() {
    this.config.sort = !this.config.sort ? 'ASC' : this.config.sort === 'ASC' ? 'DESC' : undefined;
    this.config.sortChanged?.(this.config.sort);
  }

  clearSort() {
    if (this.config.sort) {
      this.config.sort = undefined;
      this.config.sortChanged?.(this.config.sort);
    }
  }
}
