import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardContentComponent } from './components';
import { CardHeaderComponent } from './components/card-header/card-header.component';
import { CardComponent } from './components/card/card.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CardComponent, CardHeaderComponent, CardContentComponent],
  exports: [CardComponent, CardHeaderComponent, CardContentComponent],
})
export class CardModule {}
