import { CdkMenuModule } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UsersWebModule } from '@vsolv/core/users/web';
import { FirebaseWebModule } from '@vsolv/packages/firebase/web';
import { UtilsModule } from '@vsolv/ui-kit/utils';
import { AlertModule } from '@vsolv/vectors-ui/alert';
import { AppNavModule } from '@vsolv/vectors-ui/app-nav';
import { AppPageModule } from '@vsolv/vectors-ui/app-page';
import { BadgeModule } from '@vsolv/vectors-ui/badge';
import { ButtonModule } from '@vsolv/vectors-ui/button';
import { CardModule } from '@vsolv/vectors-ui/card';
import { DialogModule } from '@vsolv/vectors-ui/dialog';
import { DividerModule } from '@vsolv/vectors-ui/divider';
import { FormsModule } from '@vsolv/vectors-ui/forms';
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { ImagePreviewModule } from '@vsolv/vectors-ui/image-preview';
import { InputModule } from '@vsolv/vectors-ui/input';
import { MenuModule } from '@vsolv/vectors-ui/menu';
import { SelectModule } from '@vsolv/vectors-ui/select';
import { StackGroupModule } from '@vsolv/vectors-ui/stack-group';
import { TabsModule } from '@vsolv/vectors-ui/tabs';
import { ThemingModule } from '@vsolv/vectors-ui/theming';
import { TooltipModule } from '@vsolv/vectors-ui/tooltip';
import { UserAvatarModule } from '@vsolv/vectors-ui/user-avatar';
import { TableModule } from '@vsolv/vectors/table';
import {
  DistributorFilterComponent,
  DistributorRolesCellComponent,
  GlobalDistributorsPickerComponent,
  RoleBadgeComponent,
  RolePickerComponent,
  StaffOrEmailPickerComponent,
  TeamMemberSearchFilterComponent,
  TeamMembersTableComponent,
  UserProfileFormComponent,
} from './components';
import { AddTeamMemberDialog, DeleteTeamMemberDialog, EditTeamMemberDialog, FullAdminWarningDialog } from './dialogs';
import { TrackScrollingDirective } from './directive';
import { StaffRoleGuard } from './guards';
import { GlobalDistributorsInterceptor } from './interceptors';
import { NotificationsPage, SecurityPage, TeamMembersPage, UserProfilePage, UserSettingsPage } from './pages';
import { IsSelectedPipe, JoinDistributorsPipe } from './pipes';
import { RoleAssignmentsService, SecurityService, StaffService } from './services';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CardModule,
    IconsModule,
    ThemingModule,
    BadgeModule,
    DialogModule,
    DividerModule,
    ReactiveFormsModule,
    FormsModule,
    InputModule,
    SelectModule,
    TableModule,
    ImagePreviewModule,
    ButtonModule,
    TabsModule,
    UtilsModule,
    AlertModule,
    TooltipModule,
    HttpClientModule,
    AppNavModule,
    AppPageModule,
    UsersWebModule,
    FirebaseWebModule,
    OverlayModule,
    StackGroupModule,
    CdkMenuModule,
    UserAvatarModule,
    MenuModule,
  ],
  providers: [StaffService, RoleAssignmentsService, StaffRoleGuard],
  declarations: [
    DistributorRolesCellComponent,
    TeamMembersPage,
    AddTeamMemberDialog,
    EditTeamMemberDialog,
    DeleteTeamMemberDialog,
    TeamMembersTableComponent,
    TeamMemberSearchFilterComponent,
    NotificationsPage,
    UserSettingsPage,
    UserProfilePage,
    SecurityPage,
    GlobalDistributorsPickerComponent,
    JoinDistributorsPipe,
    RoleBadgeComponent,
    RolePickerComponent,
    UserProfileFormComponent,
    IsSelectedPipe,
    StaffOrEmailPickerComponent,
    DistributorFilterComponent,
    TrackScrollingDirective,
    FullAdminWarningDialog,
  ],
  exports: [
    TeamMembersPage,
    NotificationsPage,
    UserSettingsPage,
    UserProfilePage,
    SecurityPage,
    GlobalDistributorsPickerComponent,
    TeamMembersTableComponent,
    RoleBadgeComponent,
    RolePickerComponent,
    StaffOrEmailPickerComponent,
    DistributorFilterComponent,
    FullAdminWarningDialog,
  ],
})
export class StaffWebModule {
  /** Registers the GlobalDistributorsInterceptor to handle requests */
  static forRoot(): ModuleWithProviders<StaffWebModule> {
    return {
      ngModule: StaffWebModule,
      providers: [
        { provide: HTTP_INTERCEPTORS, useClass: GlobalDistributorsInterceptor, multi: true },
        SecurityService,
      ],
    };
  }
}
