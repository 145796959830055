<div #inputContainer>
  <vs-input-container>
    <vs-icon slot="start" name="search-md"></vs-icon>

    <input
      #input
      #trigger="cdkOverlayOrigin"
      cdkOverlayOrigin
      class="w-full min-h-[40px] max-h-[40px]"
      [value]="(value$ | async)?.name"
      [placeholder]="placeholder"
      (click)="toggle()"
      (input)="searchQuery$.next(input.value)"
    />
  </vs-input-container>
</div>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="overlayOpen"
  [cdkConnectedOverlayOffsetX]="-input.offsetLeft"
  (overlayOutsideClick)="$event.target !== input ? (overlayOpen = false) : null"
>
  <vs-menu *vsLet="propertySets$ | async as propertySets" [style.width.px]="inputContainer.offsetWidth" class="my-2">
    <ng-container *ngIf="propertySets?.length; else noPropertySets">
      <button
        *ngFor="let propertySet of propertySets"
        vs-menu-item
        (click)="selectValueChanged(propertySet); input.value = ''"
      >
        <div class="flex gap-2 justify-between">
          <div class="flex gap-4">
            <div class="flex flex-col">
              <p class="text-sm font-medium text-gray-900" [class.mt-1]="!propertySet.name">
                {{ propertySet.name }}
              </p>
            </div>
          </div>

          <div class="flex justify-end w-6">
            <vs-icon *ngIf="value?.id === propertySet.id" size="18" name="check" class="self-center"></vs-icon>
          </div>
        </div>
      </button>
    </ng-container>

    <ng-template #noPropertySets>
      <p class="px-2 py-1 text-sm font-medium text-gray-900">No Property Sets were found</p>
    </ng-template>
  </vs-menu>
</ng-template>
