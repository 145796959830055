import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { IconsModule } from '@vsolv/vectors-ui/icons';
import { DropdownComponent } from './components';

export * from './components';

@NgModule({
  imports: [CommonModule, OverlayModule, RouterModule, IconsModule],
  declarations: [DropdownComponent],
  exports: [DropdownComponent],
})
export class DropdownModule {}
