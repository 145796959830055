import { Directive, HostBinding } from '@angular/core';
import { InputBoxDirective } from './input-box.directive';

@Directive({
  standalone: true,
  selector: `
    textarea[vsInput],
  `,
  hostDirectives: [InputBoxDirective],
})
export class TextareaInputDirective {
  @HostBinding('class') get styles() {
    const box = `box-content py-[calc(theme(space.2)-1px)] px-3 w-[calc(100%-2px-theme(space.6))] rounded-md`;
    const text = `text-md text-gray-900`;
    return `theme-primary-inherit scrollbar-thin ${box} ${text}`;
  }
}
