import { Component } from '@angular/core';
import { ThemeColor } from '@vsolv/vectors-ui/theming';
import { TableCellComponent } from '../../models';

export interface TagCellProps {
  text?: string;
  displayStatusIcon?: boolean;
  theme?: ThemeColor;
  displayToolTip?: boolean;
  showToolTip?: boolean;
  tooltip?: { header: string; text: string };
}

export interface MultiTagCellProps {
  limit?: number;
  wrap?: boolean;
  classes?: string;

  items: TagCellProps[];
}

@Component({
  template: `
    <ng-container *ngIf="data">
      <div
        class="flex gap-2"
        tooltipStyle="light"
        tooltipOpenDelay="300"
        [class]="data.classes"
        [class.flex-wrap]="data.wrap"
        [vsTooltip]="data.limit !== undefined && data.limit < data.items.length ? { template: tooltip } : undefined"
      >
        <ng-container *ngFor="let item of data?.items; index as index">
          <div
            *ngIf="data.limit === undefined || index < data.limit"
            class="flex gap-1 border rounded-md px-2 py-1 bg-base"
            [style]="
              data.limit !== undefined && data.limit < data.items.length
                ? 'max-width: calc(100% - 42px)'
                : 'max-width: 100%'
            "
            [vsTooltip]="item.tooltip ? { title: item.tooltip.header, subtitle: item.tooltip.text } : undefined"
            tooltipStyle="light"
          >
            <vs-icon
              *ngIf="item.displayStatusIcon"
              size="8"
              type="solid"
              name="circle"
              class="self-center text-themed-500"
              [theme]="item.theme"
            ></vs-icon>

            <p class="text-xs font-medium text-gray-600 whitespace-nowrap truncate">{{ item.text }}</p>
          </div>
        </ng-container>

        <ng-container *ngIf="data.limit !== undefined && data.limit < data.items.length">
          <div class="flex gap-2 max-w-max border rounded-md px-2 py-1 bg-base">
            <p class="text-xs font-medium text-gray-600 whitespace-nowrap">+{{ data.items.length - data.limit }}</p>
          </div>
        </ng-container>
      </div>

      <ng-template #tooltip>
        <ng-container *ngIf="_config?.header as header">
          <p>{{ header }}</p>
          <vs-divider class="mt-1 mb-2"></vs-divider>
        </ng-container>

        <div class="flex flex-wrap gap-2">
          <vs-badge *ngFor="let badge of data.items" class="cursor-default" [theme]="badge.theme" [config]="badge">
            <span class="max-w-[256px] truncate"> {{ badge.text }} </span>
          </vs-badge>
        </div>
      </ng-template>
    </ng-container>
  `,
})
export class MultiTagCellComponent extends TableCellComponent<MultiTagCellProps> {
  showRegularTemplate = true;

  get visibleItems(): TagCellProps[] {
    if (!this.data || !this.data.items) return [];

    if (this.data.items.length > 2) {
      this.showRegularTemplate = false;
      const remainingCount = this.data.items.length - 1;
      const toolTipText = `+${remainingCount}`;

      return [
        ...this.data.items.slice(0, 1),
        { text: toolTipText, theme: 'default', displayStatusIcon: false, showToolTip: true },
      ];
    }

    return this.data.items;
  }
}
