<ng-container *ngIf="state === 'customer'; else isCustomer">
  <div class="text-gray-600 text-sm pt-4 pb-1.5">
    The confirmation step is part of adding a new bank account for the first time. Once you add a payment method, you
    won't be asked again.
  </div>
  <vs-create-customer-dwolla
    [customer]="customer ?? null"
    (customerId)="customerId = $event"
    (switchState)="state = $event"
  ></vs-create-customer-dwolla>
</ng-container>
<ng-template #isCustomer>
  <ng-container *ngIf="refresh$ | async as refresh">
    <vs-create-funding-source-dwolla
      [customerId]="customerId"
      [refresh]="refresh"
      [isCustomerRequest]="isCustomerRequest"
      (closeDialog)="confirmDetails($event.id)"
    ></vs-create-funding-source-dwolla>
  </ng-container>
</ng-template>
