<vs-app-page
  [config]="{ 
    title: 'Policies', 
    icon: 'file-shield-02',
    showDivider: true,
    description: 'This is an overview of all policies configured for your organization.'
  }"
>
  <vs-breadcrumbs slot="breadcrumbs" [config]="{ items: [{ label: 'Policies' }] }"></vs-breadcrumbs>

  <vs-badge slot="title-end"> {{ policyCount }} {{ policyCount === 1 ? 'Policy' : 'Policies'}}</vs-badge>

  <!-- Buttons -->
  <div slot="header-end" class="flex max-w-full sm:max-w-min gap-2" *ngIf="canCreate$ | async">
    <button
      *ngIf="policyCount > 0"
      vs-button
      theme="primary"
      size="md"
      class="flex-grow"
      (click)="createPolicyDialog.open()"
    >
      <vs-icon name="plus"></vs-icon>
      New Policy
    </button>

    <button
      #downloadMenuTrigger
      vs-button
      size="md"
      class="px-3 py-2"
      appearance="outline"
      cdkMenuPosition="before"
      [vsMenuTriggerFor]="downloadMenu"
    >
      <vs-icon name="download-01"></vs-icon>
    </button>

    <ng-template #downloadMenu>
      <vs-menu class="mt-2">
        <button vs-menu-item (click)="downloadPolicies()">CSV file (.csv)</button>
      </vs-menu>
    </ng-template>
  </div>

  <div class="flex flex-col gap-4">
    <!-- Filters -->
    <div class="flex justify-between gap-4">
      <vs-select
        vsInput
        class="min-w-[20%] max-w-[25%]"
        [config]="{ placeholder: 'All statuses' }"
        (valueChanges)="refreshTable({ status: $event })"
      >
        <li *ngFor="let status of statuses" vs-select-option [value]="status">{{ status | titlecase }}</li>
      </vs-select>

      <vs-input-container class="max-w-[25%]">
        <vs-icon name="search-lg" slot="start"></vs-icon>
        <input #searchInput vsInput (keyup)="refreshTable({ search: searchInput.value })" placeholder="Search" />
      </vs-input-container>
    </div>

    <!-- Table -->
    <div
      *vsLet="policies$ | async as policies"
      class="rounded-xl overflow-hidden border border-gray-200 shadow-sm bg-base-white"
    >
      <ng-container *ngIf="policyCount > 0; else noPolicies">
        <vs-table [columns]="(columns$ | async) ?? []" [data]="policies" (rowClicked)="navigateTo($event.id)">
        </vs-table>
        <vs-pagination [config]="paginationConfig$ | async" (pagination)="pagination$.next($event)"></vs-pagination>
      </ng-container>

      <ng-template #noPolicies>
        <div class="flex flex-col items-center justify-center p-8">
          <vs-empty-state
            [config]="{
            title: 'No policies created.',
            subtitle: 'Once a policy has been added, details will appear here!',
            imgsrc: 'policy-documents'
          }"
          >
          </vs-empty-state>
          <button
            vs-button
            theme="primary"
            size="md"
            class="justify-center self-center"
            (click)="createPolicyDialog.open()"
          >
            <vs-icon name="plus"></vs-icon>
            New Policy
          </button>
        </div>
      </ng-template>
    </div>
  </div>
</vs-app-page>

<!-- Dialogs -->
<ws-policy-dialog #createPolicyDialog></ws-policy-dialog>

<ws-policy-dialog #editPolicyDialog [policy]="selectedPolicy" [editing]="editPolicyType"></ws-policy-dialog>

<ws-publish-policy-dialog #publishPolicyDialog [policy]="selectedPolicy"></ws-publish-policy-dialog>

<ws-archive-policy-dialog #archivePolicyDialog [policy]="selectedPolicy"></ws-archive-policy-dialog>

<ws-restore-policy-dialog #restorePolicyDialog [policy]="selectedPolicy"></ws-restore-policy-dialog>

<!-- Templates -->
<ng-template #buttonRef let-policyNumber="subtitle">
  <button
    vs-button
    size="sm"
    appearance="clear"
    class="px-2 py-2 m-[-10px]"
    [cdkCopyToClipboard]="policyNumber"
    (click)="copyPolicyNumber(policyNumber)"
  >
    <vs-icon name="clipboard"></vs-icon>Click to copy
  </button>
</ng-template>

<ng-template #draftTooltipRef>
  <p class="text-gray-700 text-xs font-semibold">This policy is a draft.</p>
  <p class="text-gray-500 text-xs font-medium">
    <span class="text-primary-700">Publish</span> this policy to activate it.
  </p>
</ng-template>

<ng-template #archiveTooltipRef>
  <p class="text-gray-700 text-xs font-semibold">This policy has been archived.</p>
  <p class="text-gray-500 text-xs font-medium">
    <span class="text-primary-700">Restore</span> this policy to create a new version it.
  </p>
</ng-template>

<ng-template #runoffTooltipRef>
  <p class="text-gray-700 text-xs font-semibold">This policy still has active warranties.</p>
  <p class="text-gray-500 text-xs font-medium">
    It will be archived once all associated warranties have expired. <span class="text-primary-700">Restore</span> this
    policy to create a new version it.
  </p>
</ng-template>
