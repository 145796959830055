import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { DistributorResolver } from '../resolvers';
import { DistributorService } from '../services';

@Injectable()
export class DistributorGuard implements CanActivate {
  constructor(
    private resolver: DistributorResolver,
    private distributorSvc: DistributorService,
    private router: Router
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    this.resolver.distributor = null;

    const distributorId = this.findDistributorId(route);
    if (!distributorId) return false;

    this.resolver.distributor = await this.distributorSvc.getOne(distributorId);
    if (this.resolver.distributor === null) return this.router.createUrlTree(['/404']);

    if (!this.resolver.distributor) return false;

    return true;
  }

  private findDistributorId(route: ActivatedRouteSnapshot): string {
    return route.params['distributorId'] || (route.parent ? this.findDistributorId(route.parent) : null);
  }
}
