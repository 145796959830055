<div *vsLet="allCoverages$ | async as coverages" class="flex flex-col gap-4 mx-6 my-4">
  <div class="flex justify-between gap-2 mt-1">
    <div class="flex flex-wrap gap-2">
      <vs-badge
        class="w-fit rounded-[0.3rem]"
        [class.bg-gray-100]="selectedGroup !== null"
        (click)="selectedGroup = null"
      >
        All
      </vs-badge>

      <vs-badge
        *ngFor="let group of coverages | pluck : 'coverage.group' | unique"
        class="w-fit rounded-[0.3rem]"
        [class.bg-gray-100]="selectedGroup !== group"
        (click)="selectedGroup = group"
      >
        {{ group }}
      </vs-badge>
    </div>

    <div *ngIf="hasDisabledCoverages$ | async" class="flex gap-2 items-center">
      <input
        vsInput
        id="checkbox"
        type="checkbox"
        [checked]="showDisabledCoverages$ | async"
        (click)="showDisabledCoverages$.next(!showDisabledCoverages$.value)"
      />

      <label for="checkbox" class="text-xs text-gray-500 dark:text-gray-300 whitespace-nowrap cursor-pointer">
        Show excluded coverages
      </label>
    </div>
  </div>

  <div *vsLet="evaluatedLiabilityGroups$ | async as liabilityGroups" class="flex items-stretch -mx-8 px-2">
    <swiper
      [slidesPerView]="'auto'"
      [slidesOffsetBefore]="24"
      [slidesOffsetAfter]="24"
      [spaceBetween]="12"
      [centeredSlides]="(isLargeScreen$ | async) ? false : true"
    >
      <ng-container *ngFor="let coverage of filteredCoverages$ | async">
        <ng-template swiperSlide>
          <ws-coverage-card
            [warranty]="warranty || null"
            [warrantyCoverage]="coverage"
            [isCustomerPortal]="isCustomerPortal"
            [evaluatedLiabilityGroups]="liabilityGroups ?? []"
          ></ws-coverage-card>
        </ng-template>
      </ng-container>
    </swiper>
  </div>
</div>
