<ng-container *ngIf="role && !role.hideRole">
  <vs-badge
    *vsLet="permissionKeyLabel$ | async as permissionKeyLabel"
    tooltipStyle="light"
    [class]="removable ? 'clickable' : ''"
    [theme]="role.display.theme || 'default'"
    [vsTooltip]="{ template: tooltip }"
    (click)="remove()"
  >
    {{ role.display.title }}

    <span *ngIf="showPermissionKeyLabel">{{ permissionKeyLabel }}</span>

    <vs-icon *ngIf="removable" slot="end" name="x-close" [theme]="role.display.theme || 'default'"></vs-icon>
  </vs-badge>

  <ng-template #tooltip>
    <p class="font-semibold text-gray-700">
      A <span class="underline"> {{ role.display.title }} </span> has the permissions to:
    </p>

    <ul class="pl-4 mt-1" style="list-style: disc">
      <li *ngFor="let permission of permissions" class="font-normal text-gray-700 pl-2">
        {{ permission.display.title }}
      </li>
    </ul>
  </ng-template>
</ng-container>
