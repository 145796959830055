import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { PropertyListener } from '@vsolv/dev-kit/rx';
import { ToastService } from '@vsolv/vectors-ui/alert';
import { DialogComponent } from '@vsolv/vectors-ui/dialog';
import { Distributor } from '@wsphere/distributors/domain';
import { Staff } from '@wsphere/staff/domain';
import { BehaviorSubject, combineLatest, switchMap } from 'rxjs';
import { RoleAssignmentsService, SecurityService, StaffService } from '../../services';
import { FullAdminWarningDialog } from '../full-administrator-warning-dialog';

@Component({
  selector: 'ws-add-team-member-dialog',
  templateUrl: './add-team-member.dialog.html',
})
export class AddTeamMemberDialog {
  constructor(
    private formBuilder: FormBuilder,
    private staffSvc: StaffService,
    private toastSvc: ToastService,
    private roleSvc: RoleAssignmentsService,
    private securitySvc: SecurityService
  ) {}

  @PropertyListener('permissionKey') permissionKey$ = new BehaviorSubject<string | null>(null);
  @Input() permissionKey: string | null = null;

  @Input() distributor?: Distributor.Model;

  @ViewChild(DialogComponent) private dialog?: DialogComponent;
  @ViewChild(FullAdminWarningDialog) private warningDialog?: FullAdminWarningDialog;

  @Output() readonly staffInvited = new EventEmitter<void>();

  private refresh$ = new BehaviorSubject(null);

  readonly canGrant$ = this.permissionKey$.pipe(
    switchMap(permissionKey =>
      permissionKey
        ? this.securitySvc.hasAccess('dist_InviteStaff', [permissionKey])
        : this.securitySvc.hasAccess('stf_GrantRoles', null)
    )
  );

  staffList$ = combineLatest([this.permissionKey$, this.refresh$]).pipe(
    switchMap(
      async ([permissionKey]) =>
        await this.staffSvc.list({ page: 1, limit: 50, permissionKeys: permissionKey ? [permissionKey] : null })
    )
  );

  private _staff$ = new BehaviorSubject<Staff.Model | undefined>(undefined);
  get staff() {
    return this._staff$.value;
  }
  set staff(staff: Staff.Model | undefined) {
    this._staff$.next(staff);
    if (staff) {
      this._form.setValue({ name: staff.name, email: staff.email, roles: this._form.value.roles || [] });
      this._form.get('name')?.disable();
      this._form.get('email')?.disable();
    } else {
      this._form.get('name')?.enable();
      this._form.get('email')?.enable();
    }
  }

  readonly _form = this.formBuilder.group({
    name: [null as string | null, Validators.required],
    email: [null as string | null, [Validators.required, Validators.email]],
    roles: [[] as string[]],
  });

  _submitting = false;

  open(internal?: boolean) {
    if (!internal) {
      this._form.reset({ name: null, email: null, roles: [] });
      this.staff = undefined;
      this.refresh$.next(null);
    }

    this.dialog?.open();
  }

  close(internal?: boolean) {
    if (!internal) {
      this._form.reset({ name: null, email: null, roles: [] });
      this.staff = undefined;
    }

    this.dialog?.close();
  }

  async submit() {
    const { name, email, roles } = this._form.getRawValue();
    if (!name || !email) return; // Impossible state due to validators. Simply checking for TS to be happy

    this._submitting = true;
    this._form.disable();

    let alreadyExists: Staff.Model | null = null;
    try {
      alreadyExists = await this.staffSvc.retrieve(email, Staff.IdField.email);
    } catch (e) {
      alreadyExists = null;
    }

    if (alreadyExists && alreadyExists.email === email) {
      if (roles && roles.length) {
        try {
          await this.roleSvc.inviteStaffAndGrantRoles({
            name: alreadyExists.name ?? name,
            email: alreadyExists.email,
            roles: roles,
            ...(this.permissionKey && { permissionKey: this.permissionKey }),
          });
          this.close();
          this.toastSvc.show({
            type: 'success',
            title: 'Roles added to staff',
          });
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          this.close();
          this.toastSvc.show({
            type: 'error',
            title: 'Error adding roles to user',
            text: e?.error?.message ? `${e.error.message}` : '',
          });
        }
      } else {
        this.close();
        this.toastSvc.show({
          type: 'error',
          title: 'Duplicate entry',
          text: 'Staff member already exists',
        });
      }
    } else {
      try {
        await this.roleSvc.inviteStaffAndGrantRoles({
          name,
          email,
          roles: roles || [],
          ...(this.permissionKey && { permissionKey: this.permissionKey }),
        });

        this.toastSvc.show({
          type: 'success',
          title: 'Invitation sent',
          text: 'Your colleague will be alerted via email with an invite to join your team.',
        });

        this.staffInvited.emit();
        this.close();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        this.close();
        if (err.error.statusCode === 409) {
          this.toastSvc.show({
            type: 'error',
            title: 'Duplicate entry',
            text: 'Staff member already exists',
          });
        } else {
          this.toastSvc.show({
            type: 'error',
            title: 'Unexpected error',
            text: 'Something went wrong and the invitation was not sent. Please try again.',
          });
        }
      }
    }

    this._submitting = false;
    this._form.enable();
  }

  roleAdded(roleId: string) {
    if (roleId === '*') {
      this.close(true);
      this.warningDialog?.open(this.distributor?.name);
    }
  }
}
