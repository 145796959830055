<vs-app-page
  *vsLet="(table?.paginationConfig$ | async)?.totalItems as totalClaims"
  [config]="{
    icon: 'file-06',
    title: 'Claims',
    showDivider: true,
    description: 'This is an overview of all claims filed across your organization.'
  }"
>
  <vs-breadcrumbs slot="breadcrumbs" [config]="{ items: [{ label: 'Claims' }] }"></vs-breadcrumbs>

  <vs-badge slot="title-end"> {{ totalClaims }} {{ totalClaims === 1 ? 'Claim' : 'Claims'}}</vs-badge>

  <div *ngIf="totalClaims" slot="header-end" class="flex gap-2 max-w-full sm:max-w-min">
    <button *ngIf="canOpenClaim$ | async" vs-button theme="primary" class="flex-grow" (click)="openClaim()">
      <vs-icon name="plus"></vs-icon> New Claim
    </button>
  </div>

  <ws-claims-table #table></ws-claims-table>

  <ws-open-claim-dialog #openClaimDialog (closed)="table.closeClaimDialog()"></ws-open-claim-dialog>
</vs-app-page>
