import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StaffRoleGuard, STAFF_PERMISSION_FALLBACK_ROUTE_DATA, STAFF_PERMISSION_ROUTE_DATA } from '@wsphere/staff/web';
import { ClaimModule } from './claim.module';
import {
  ClaimItemAdjudicateComponent,
  ClaimItemCureComponent,
  ClaimItemDetailsComponent,
  ClaimItemResolveComponent,
} from './components';
import {
  ClaimItemAdjudicateDialog,
  ClaimItemCureDialog,
  ClaimItemDetailsDialog,
  ClaimItemDialog,
  ClaimItemResolveDialog,
  ManagePaymentDialog,
} from './dialogs';
import {
  ClaimGuard,
  ClaimItemAdjudicationGuard,
  ClaimItemGuard,
  ClaimItemManageGuard,
  ClaimPaymentGuard,
} from './guards';
import {
  ClaimAttachmentsPage,
  ClaimNotesPage,
  ClaimOverviewPage,
  ClaimPage,
  ClaimPaymentsPage,
  ClaimsPage,
} from './pages';
import { ClaimItemResolver, ClaimPaymentResolver, ClaimResolver } from './resolver';

const routes: Routes = [
  {
    path: '',
    data: {
      [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ViewClaim',
      [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [
        { permissionId: 'dsh_ViewDashboard', route: '' },
        { permissionId: 'pol_View', route: 'policies' },
        { permissionId: 'py_ViewSales', route: 'sales' },
      ],
    },
    canActivate: [StaffRoleGuard],
    component: ClaimsPage,
  },
  {
    path: ':claimId',
    component: ClaimPage,
    data: {
      [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ViewClaim',
      [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/claims' }],
    },
    canActivate: [ClaimGuard, StaffRoleGuard],
    resolve: { claim: ClaimResolver },

    children: [
      {
        path: 'notes',
        component: ClaimNotesPage,
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ViewNote',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/claims' }],
        },
        canActivate: [StaffRoleGuard],
      },
      {
        path: 'attachments',
        component: ClaimAttachmentsPage,
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ViewAttachments',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/claims' }],
          isCustomerPortal: false,
        },
        canActivate: [StaffRoleGuard],
      },
      {
        path: 'payments',
        component: ClaimPaymentsPage,
        data: {
          [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ViewAttachments',
          [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/claims' }],
        },
        canActivate: [StaffRoleGuard],
        children: [
          {
            path: ':paymentId',
            component: ManagePaymentDialog,
            canActivate: [ClaimPaymentGuard],
            resolve: { payment: ClaimPaymentResolver },
          },
        ],
      },
      {
        path: '',
        component: ClaimOverviewPage,
        children: [
          {
            path: ':itemId',
            component: ClaimItemDialog,
            canActivate: [ClaimItemGuard, ClaimGuard],
            resolve: { item: ClaimItemResolver },
            children: [
              {
                path: '',
                component: ClaimItemDetailsComponent,
                resolve: { item: ClaimItemResolver },
              },
              {
                path: 'cure',
                component: ClaimItemCureComponent,
                resolve: { item: ClaimItemResolver },
              },
              {
                path: 'adjudicate',
                component: ClaimItemAdjudicateComponent,
                resolve: { item: ClaimItemResolver },
              },
              {
                path: 'resolve',
                component: ClaimItemResolveComponent,
                canActivate: [ClaimItemGuard, ClaimGuard, ClaimItemAdjudicationGuard],
                resolve: { item: ClaimItemResolver },
              },
            ],
          },
          {
            path: ':itemId/manage-details',
            component: ClaimItemDetailsDialog,
            data: {
              [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ManageClaimItemCoverage',
              [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/:itemId' }],
            },
            canActivate: [ClaimItemGuard, StaffRoleGuard, ClaimItemManageGuard],
            resolve: { item: ClaimItemResolver },
          },
          {
            path: ':itemId/manage-cure',
            component: ClaimItemCureDialog,
            data: {
              [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ManageClaimItemCure',
              [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/:itemId' }],
            },
            canActivate: [ClaimItemGuard, StaffRoleGuard, ClaimItemManageGuard],
            resolve: { item: ClaimItemResolver, claim: ClaimResolver },
          },
          {
            path: ':itemId/manage-adjudication',
            component: ClaimItemAdjudicateDialog,
            data: {
              [STAFF_PERMISSION_ROUTE_DATA]: 'clm_AdjudicateClaimItem',
              [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/:itemId' }],
            },
            canActivate: [ClaimItemGuard, StaffRoleGuard, ClaimItemManageGuard],
            resolve: { item: ClaimItemResolver },
          },
          {
            path: ':itemId/manage-resolution',
            component: ClaimItemResolveDialog,
            data: {
              [STAFF_PERMISSION_ROUTE_DATA]: 'clm_ManageClaimItemResolution',
              [STAFF_PERMISSION_FALLBACK_ROUTE_DATA]: [{ permissionId: 'clm_ViewClaim', route: '/:itemId' }],
            },
            canActivate: [ClaimItemGuard, StaffRoleGuard, ClaimItemManageGuard],
            resolve: { item: ClaimItemResolver, claim: ClaimResolver },
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes), ClaimModule],
})
export class ClaimRoutingModule {}
