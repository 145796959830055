<div
  *ngIf="warranty?.policy as policy"
  class="flex rounded-md cursor-pointer transition hover:bg-gray-50"
  (click)="navigateToPolicy(policy.id)"
>
  <div class="flex flex-col p-2 w-full">
    <div class="flex gap-4 my-3">
      <p class="text-sm font-medium text-gray-700 min-w-[112px]">Policy Name</p>
      <p tooltipStyle="light" class="text-sm self-center truncate whitespace-nowrap" [vsTooltip]="policy.title">
        {{ policy.title }}
      </p>
    </div>

    <vs-divider></vs-divider>

    <div class="flex gap-4 my-3">
      <p class="text-sm font-medium text-gray-700 min-w-[112px]">Policy Number</p>
      <p class="text-sm self-center truncate whitespace-nowrap">{{ policy.policyNumber }}</p>
    </div>

    <vs-divider></vs-divider>

    <div class="flex justify-between gap-4 py-3 w-full max-h-11 overflow-hidden">
      <div class="flex gap-4" style="max-width: calc(100% - 56px)">
        <p class="text-sm font-medium text-gray-700 min-w-[112px]">Start Date</p>
        <p class="text-sm self-center truncate whitespace-nowrap">{{ warranty?.termStart | date }}</p>
      </div>

      <button
        *ngIf="warranty?.status === draft"
        #trigger="cdkOverlayOrigin"
        vs-icon-button
        cdkOverlayOrigin
        class="-mt-[10px]"
        appearance="clear"
        (click)="toggle(); $event.stopImmediatePropagation()"
      >
        <vs-icon name="edit-01" class="m-2 text-gray-600"></vs-icon>

        <ng-template
          cdkConnectedOverlay
          [cdkConnectedOverlayOrigin]="trigger"
          [cdkConnectedOverlayOpen]="overlayOpen"
          (overlayOutsideClick)="overlayOpen ? (overlayOpen = false) : ''"
        >
          <vs-card>
            <vs-date-picker
              [value]="warranty?.termStart"
              (selectionChanged)="updateTermStart($any($event))"
            ></vs-date-picker>
          </vs-card>
        </ng-template>
      </button>
    </div>

    <vs-divider></vs-divider>

    <div class="flex gap-4 my-3">
      <p class="text-sm font-medium text-gray-700 min-w-[112px]">Expiry Date</p>
      <p class="text-sm self-center truncate whitespace-nowrap">{{ warranty?.expiryDate | date }}</p>
    </div>

    <ng-container *ngIf="warranty?.distributor as distributor">
      <vs-divider></vs-divider>

      <div class="flex gap-4 my-3">
        <p class="text-sm font-medium text-gray-700 min-w-[112px]">Distributor</p>
        <p class="text-sm self-center truncate whitespace-nowrap">{{ distributor.name }}</p>
      </div>
    </ng-container>

    <ng-container *ngIf="warranty?.referenceId as referenceId">
      <vs-divider></vs-divider>

      <div class="flex gap-4 my-3">
        <p class="text-sm font-medium text-gray-700 min-w-[112px]">Warranty Ref ID</p>
        <p class="text-sm self-center truncate whitespace-nowrap" [vsTooltip]="referenceId" tooltipStyle="light">
          {{ referenceId }}
        </p>
      </div>
    </ng-container>
  </div>
</div>
