<div class="flex justify-end mb-2">
  <button vs-button appearance="clear" theme="primary" (click)="addBlock()">
    <vs-icon name="dataflow-02"></vs-icon> If statement
  </button>
</div>

<form *vsLet="form.value as rule" [formGroup]="form">
  <div *ngIf="rule" class="flex flex-col gap-2 pb-4 max-h-[600px] overflow-y-auto scrollbar-thin">
    <ng-container formArrayName="blocks">
      <div
        *ngFor="let block of blocks?.controls; index as index"
        class="flex flex-col border border-1 rounded-lg pt-2 pb-2 pl-4 pr-4"
      >
        <div class="flex justify-between">
          <p
            class="min-w-fit text-gray-600 pt-2"
            [vsTooltip]="{
              title: (index === 0 ? 'If' : 'Else if') + ' statement',
              subtitle:
                'If this set of conditions is satisfied' +
                ', and none of the previous conditions were satisfied,' +
                ' then the value of this block will be shown to the customer.'
            }"
            tooltipStyle="light"
          >
            {{ index === 0 ? 'if' : 'else if' }}
          </p>
          <button vs-icon-button appearance="clear" (click)="removeBlock(index)"><vs-icon name="x"></vs-icon></button>
        </div>

        <ws-condition-block-builder
          [plan]="plan"
          [coverage]="coverage"
          [propertySet]="propertySet"
          [formControlName]="index"
        ></ws-condition-block-builder>
      </div>
    </ng-container>

    <div class="flex gap-2 border rounded-lg pt-2 pb-2 pl-4 pr-4" [class.border]="rule.blocks?.length">
      <p
        *ngIf="rule.blocks?.length"
        class="min-w-fit text-gray-600 pt-2"
        [vsTooltip]="{
          title: 'Else statement',
          subtitle: 'If no conditions are satisfied or provided, this is the value that will be shown to the customer.'
        }"
        tooltipStyle="light"
      >
        else
      </p>

      <vs-form-field *ngIf="type === 'string'" appearance="horizontal">
        <label *ngIf="!rule.blocks?.length">Default</label>

        <vs-select class="flex-1" vsInput formControlName="defaultValue" (valueChanges)="addIndividualAddon($event)">
          <li class="mb-2" vs-select-option value="BASE">
            <vs-badge theme="primary"> Base </vs-badge>
          </li>

          <li *ngIf="coverage" class="mb-2" vs-select-option [value]="coverage.id">
            <vs-badge theme="info"> Individual Add-on </vs-badge>
          </li>

          <ng-container *ngIf="plan && plan?.addons?.length">
            <li *ngFor="let addon of filteredAddons$ | async" vs-select-option class="mb-2" [value]="addon.id">
              <vs-badge theme="info"> {{ addon.title }} </vs-badge>
            </li>
          </ng-container>

          <li class="mb-2" vs-select-option value="NOT_APPLICABLE">
            <vs-badge theme="danger"> Not Applicable </vs-badge>
          </li>
        </vs-select>
      </vs-form-field>

      <vs-form-field *ngIf="type === 'number'" appearance="horizontal">
        <label *ngIf="!rule.blocks?.length">Default</label>

        <vs-input-container>
          <div
            *ngIf="!rule.valuePropertyPath"
            class="flex flex-row w-full h-full gap-2 justify-center align-middle items-center"
          >
            <p class="text-gray-600">$</p>
            <input type="text" mask="separator.2" thousandSeparator="," formControlName="defaultValue" />
          </div>

          <!-- Value Property Badge -->
          <div *ngIf="rule.valuePropertyPath" class="w-fit max-w-[128px] pt-[5px]">
            <vs-badge theme="default" [vsTooltip]="rule.valuePropertyPath" tooltipPosition="top" class="w-full h-4">
              <span class="w-full truncate">{{ rule.valuePropertyPath }}</span>
              <vs-icon
                name="x-close"
                slot="end"
                class="cursor-pointer"
                (click)="setValuePropertyPath(null); valuePropertyPicker.setValue(null)"
              ></vs-icon>
            </vs-badge>
          </div>

          <ws-condition-value-property-picker
            #valuePropertyPicker
            class="pt-1"
            slot="end"
            [propertySet]="propertySet"
            [type]="num"
            format="currency"
            [selectedPropertyPath]="form.value.valuePropertyPath"
            (selectionChanged)="setValuePropertyPath($event.property)"
          ></ws-condition-value-property-picker>
        </vs-input-container>
      </vs-form-field>

      <vs-form-field *ngIf="type === 'object'" appearance="horizontal">
        <label *ngIf="!rule.blocks?.length">Default</label>

        <vs-select class="flex-1" vsInput [config]="{ multiple: true }" formControlName="defaultValue">
          <ng-container *ngIf="plan && plan?.liabilityGroups?.length">
            <li *ngFor="let group of plan.liabilityGroups" vs-select-option class="mb-2" [value]="group.id">
              {{ group.title }}
            </li>
          </ng-container>

          <li class="mb-2" vs-select-option value="nogroup">No Group</li>
        </vs-select>
      </vs-form-field>
    </div>
  </div>
</form>
